//1.1
import * as Sentry from '@sentry/browser';
import { Angular } from '@sentry/integrations';

(()=>{
	try {
		if (__sentryUrl) Sentry.init({
			environment  : globalThis.location.host,
			dsn          : __sentryUrl,
			integrations : [ new Angular() ],
		}); else console.log('Sentry is not initialized.');
	} catch {
		console.warn('Sentry is not initialized.');
	}
})();

export const sentryMsg = msg => {
	setTimeout(()=> {
		try {
			if (msg instanceof Error) {
				Sentry.captureMessage(msg);
			} else {
				Sentry.captureMessage(new Error(JSON.stringify(msg)));
			}
		} catch {}
	}, 0);
};