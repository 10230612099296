/**
 * @author: sergeyu
 * Перехватчик запросов для продления сессии на клиенте.
 */
angular.module('uetp').factory('sessionInterceptor', ['$timeout', '$injector','constants',
    function ($timeout, $injector, constants) {
    /**
     * Таймер закрытия сессии
     */
    var closeSessionTimer,
        /**
         * Время когда надо запускать таймер -> closeSessionTimer
         */
        timeStart = 0,
        /**
         * Таймер отложенного запуска -> closeSessionTimer
         */
        defferedTimer = null;

    /**
     * Вывод сообщения после закрытия сессии
     */
    function dialogCloseSession() {
        var ngDialog = $injector.get('ngDialog');
        ngDialog.open({
            template: 'views/dialog/dialogCloseSession.html',
            className: 'ngdialog-theme-default',
            plain: false,
            overlay: true,
            showClose: true,
            disableAnimation: true
        });
    }

    /**
     * Инициализация времени таймера
     * (Выставляем время из кеша, если пользователь продолжил работу без выхода, иначе сбрасываем)
     */
    function initTimeStart() {
        if (timeStart === 0) {
            try {
                timeStart = localStorage.getItem('timeToLogoff') || 0;
            } catch {
                window.open("errorCookie.html", "_self");
            }
        }
        return timeStart;

    }

    /**
     * Запускаем и устанавливаем начальное время
     */
    function setSessionTimer(value) {
        timeStart = value;
        if (!closeSessionTimer && timeStart != 0) {
            closeSessionTimer = $timeout(function () {
                closeSession();
                var authSrv = $injector.get('authSrv');
                authSrv.exit();
                var loggerUiSrv = $injector.get('loggerUiSrv');
                loggerUiSrv.sendLog('TIME_OUT_LOGOFF');
                dialogCloseSession();
            }, timeStart);
        }
    }

    /**
     * Обновление таймера если идут запросы на сервер
     */
    function refreshSessionTimer() {
        if (closeSessionTimer) {
            $timeout.cancel(closeSessionTimer);
            closeSessionTimer = null;
        }
        setSessionTimer(timeStart);
    }

    /**
     * Закрытие сессии и обнуление всех данных.
     * (чтоб не писать список запросов которые не нужно обрабатывать вызвать ПЕРВЫМ перед logoff)
     */
    function closeSession() {
        timeStart = 0;
        localStorage.setItem('timeToLogoff', null);
        $timeout.cancel(closeSessionTimer);
        closeSessionTimer = null;

    }

    /**
     * Проверка ответа от сервера на его успешность
     */
    function validateResponse(response) {
        return response.status === 200 && response.data && response.data.success && response.config && response.config.url;
    }

    /**
     * Список каунтеров, которые надо отслеживать
     */
    var _counterList = [
        'rest/message/api/v2/countUnread',
        'rest/organizer/explreqs/count'
    ];

    initTimeStart();
    return {
        request: function (config) {
            if (config.headers) {
                config.headers['Type-Shop'] = constants.configProject.reqType;
            }
            if (config.url.indexOf('/auth/user/logoff') >= 0) {
                closeSession();
            } else if (config.url && _counterList.indexOf(config.url) < 0) {
                if (timeStart > 0) {
                    if (!defferedTimer) {
                        defferedTimer = $timeout(function () {
                            refreshSessionTimer();
                            defferedTimer = null;
                        }, 1000);
                    }

                }
            }
            return config;
        },
        response: function (response) {
            if (validateResponse(response)) {
                if (response.config.url.indexOf('/auth/user/login') >= 0
                    || response.config.url.indexOf('/auth/user/loginCert') >= 0) {
                    if (angular.isDefined(response.data.result.timeToLogoff)) {
                        setSessionTimer(response.data.result.timeToLogoff);
                    }
                }
            }
            return response;
        }
    };

}]);


