/**
 * @author: sergeysi
 * Сервис прав пользователя
 */
angular.module('uetp').service('permissionSrv', ['$injector',
    function ($injector) {
        /**
         * Получение прав
         */
        this.hasPermission = function () {
            const authSrv = $injector.get('authSrv'), user = authSrv.getUser();

            return (['organizer_admin', 'organizer_user'].includes(user.userRole) && user.userProperties && user.userProperties.procedureWorkflow === "true") ||
                !['organizer_admin', 'organizer_user'].includes(user.userRole);
        };

        this.hasSectionPermission = function (procSection) {
            const authSrv = $injector.get('authSrv'), user = authSrv.getUser();

            return (['organizer_admin', 'organizer_user'].includes(user.userRole) && user.userProperties && user.userProperties["allowed_"+procSection] !== "false") ||
                !['organizer_admin', 'organizer_user'].includes(user.userRole);
        };

        this.hasContractPermission = function () {
            const authSrv = $injector.get('authSrv'), user = authSrv.getUser();
            return (['organizer_admin', 'organizer_user'].includes(user.userRole) && user.userProperties &&
                user.userProperties.contractWorkflow==="true") ||
                    !['organizer_admin', 'organizer_user'].includes(user.userRole);
        };

        this.hasWorkflowOrContractPermission = function () {
            const authSrv = $injector.get('authSrv'), user = authSrv.getUser();
            return (['organizer_admin', 'organizer_user'].includes(user.userRole) && user.userProperties &&
                (user.userProperties.contractWorkflow==="true" || user.userProperties.procedureWorkflow === "true")) ||
                    !['organizer_admin', 'organizer_user'].includes(user.userRole);
        };

        this.hasPurchasePlanUpdatePermission = function () {
            const authSrv = $injector.get('authSrv'), user = authSrv.getUser();
            return (['organizer_admin', 'organizer_user'].includes(user.userRole) && user.purchasePlanVisible &&
                   user.userProperties && user.userProperties.procedureWorkflow === "true");
        };
        
        this.hasSignContractPermission = function () {
            const authSrv = $injector.get('authSrv'), user = authSrv.getUser();
            return (['organizer_admin', 'organizer_user'].includes(user.userRole) && user.userProperties &&
                user.userProperties.signContractEnabled==="true") ||
                    !['organizer_admin', 'organizer_user'].includes(user.userRole);
        };

        this.hasAnyPermission = function () {
            const authSrv = $injector.get('authSrv'), user = authSrv.getUser();
            return (['organizer_admin', 'organizer_user'].includes(user.userRole) && user.userProperties &&
                (user.userProperties.procedureWorkflow==="true" || user.userProperties.contractWorkflow==="true" || user.userProperties.signContractEnabled==="true")) ||
                    !['organizer_admin', 'organizer_user'].includes(user.userRole);
        };

        }]
);
