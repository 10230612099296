/**
 * @author: sergeyu
 * Организатор. Назначенные заказчики организатору
 */
angular.module('uetp')
    .component('customersOrg', {
        templateUrl: 'app/components/organizator/customersOrg/customersOrg.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'organizatorSrv', 'desktopSrv',
            function ($scope, $rootScope, $state, $stateParams, organizatorSrv, desktopSrv) {
                $scope.model = {dataPanel: {}};
                $scope.showPanel = false;

                $scope.goBack = goBackFn;

                this.$onInit = function () {
                    $scope.organizer = organizatorSrv.getOrganizatorInfo();
                    $scope.getDataOrg();
                    $scope.isReadOnly = isReadOnlyFn;
                };

                $scope.saveOrg = function (data) {
                    return organizatorSrv.saveOrgCustomer($scope.organizer.id, data)
                };

                function isReadOnlyFn() {
                    return ["accountant", "support"].indexOf(desktopSrv.getCabType())!=-1;
                }
                $scope.getDataOrg = function () {
                    organizatorSrv.getDataOrgCustomer($scope.organizer.id).then(function (response) {
                        if (response.data.success) {
                            $scope.model.dataPanel = {
                                dataLeft: response.data.result.allCustomer,
                                dataRight: response.data.result.assignedCustomer
                            };
                            $scope.showPanel = true;
                        }
                    });
                };

                function goBackFn() {
                    history.back();
                }


            }]
    });

