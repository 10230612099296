//общий ф-л для позиций. возможно, будет необходим компонент
angular.module('uetp').service('positionSrv', ['$rootScope', 'dialogSrv', '$filter', 'desktopSrv', 'dictionarySrv', 'httpSrv', 'propertiesService', 'systemMessages',
    function ($rootScope, dialogSrv, $filter, desktopSrv, dictionarySrv, httpSrv, propertiesService, systemMessages) {
        var posProcessing, positionScope, offerScope;//признак - редактируется позиция

        this.positionCopy = positionCopyFn;
        this.positionSave = positionSaveFn;
        this.positionEdit = positionEditFn;
        this.positionDelete = positionDeleteFn;
        this.positionShow = positionShowFn;
        this.getSum = getSumFn;
        this.addPositionsFromXls = addPositionsFromXlsFn;
        this.checkQuantity = checkQuantityFn;
        this.positionClose = closePositionFn;
        this.changePriceNds = changePriceNdsFn;
        this.checkPositionsVatRate = checkPositionsVatRateFn;
        this.downloadVatRateList = downloadVatRateListFn;
        this.checkPositionChanged = checkPositionChangedFn;
        this.setOfferScope = setOfferScopeFn;
        this.setPosProcessing = setPosProcessingFn;
        this.setPositionScope = setPositionScopeFn;
        this.getPositionScope = () => positionScope;
        this.isPosProcessing = isPosProcessingFn;
        this.createDraftPosition = createDraftPositionFn;
        this.copyOldOfferItem = copyOldOfferItemFn;
        this.hasVatRate = hasVatRateFn;
        this.checkDocNds = checkDocNdsFn;
        this.updateSum = updateSumFn;
        this.getTotal = getTotalFn;
        this.getPriceType = getPriceTypeFn;
        this.setPriceVatType = setPriceVatTypeFn;
        this.updatePriceFieldByVat = updatePriceFieldByVatFn;
        this.correlatePrice = correlatePriceFn;
        this.getPositionDetails = getPositionDetailsFn;
        this.getPositionDetailsHost = getPositionDetailsHostFn;


        function getPositionDetailsFn(id, site) {
            if (["ppk", "etp"].includes(site)) {
                return localRequest(id);
            } else {
                return new Promise(function (resolve, reject) {
                    propertiesService.getProperty("uem.url").then(function (host) {
                        remoteRequest(host, id, resolve, reject);
                    });
                });
            }
        }
        function localRequest(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/orders/api/v1/offer/position/' + id
            })
        }
        function remoteRequest(host, id, resolve, reject) {
            var XHR = ("onload" in new XMLHttpRequest()) ? XMLHttpRequest : XDomainRequest;
            var xhr = new XHR();

            xhr.open('GET', host + '/rest/ccwe/purchase/getOfferPosition/' + id, true);
            xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
            xhr.setRequestHeader('Type-Shop', 'rzd');


            xhr.send();

            xhr.onload = function () {
                var response = {data: JSON.parse(xhr.response)};
                resolve(response);
            };

            xhr.onError = function () {
                console.log(this);
                reject();
            };
        }
        function getPositionDetailsHostFn(id, host) {
            if (!host) {
                return localRequest(id);
            } else {
                return new Promise(function (resolve, reject) {
                    remoteRequest(host, id, resolve, reject);
                });
            }
        }


        function noVatFn($scope, docType) {
            return $scope[docType].nonNds;
        }


        function hasVatRateFn(element) {
            if (!element) return true;
            return element.vatRate > 0 || element.vatRate === 0;
        }

        function setPosProcessingFn(value) {
            posProcessing = value;
        }

        function isPosProcessingFn() {
            return posProcessing;
        }

        function setPositionScopeFn(scope) {
            positionScope = scope;
        }

        function setOfferScopeFn(scope) {
            offerScope = scope;
        }

        function positionCopyFn($scope, docType) {
            var oldOfferItem = angular.copy($scope.offerItem);
            positionShowFn($scope, docType, oldOfferItem);
        }

        function copyOldOfferItemFn($scope, oldOfferItem) {
            for (var key in oldOfferItem) {
                if (oldOfferItem.hasOwnProperty(key)) {
                    if (key !== 'id') {
                        if (key !== 'attachment' && key !== 'attachments')
                            $scope.offerItem[key] = oldOfferItem[key];
                        else {
                        }
                    }
                }
            }
            if ($scope.offerItem.stationInfo)
                delete $scope.offerItem.stationInfo.id;
            $scope.offerItem.needAddSaved = true;

            //Убрать после реф. селект сервиса
            setTimeout(function () {
                var field = document.querySelector('#Measure');
                if (field) angular.element(field).triggerHandler('click');
            });
            posProcessing = true;
        }

        function positionShowFn($scope, docType, oldOfferItem) {
            const itemType = docType + "Item";

            if ($scope.positionSeqNumber) closePositionFn($scope, itemType);

            positionCreateFn($scope, docType);
            if (docType === 'offer') createDraftPositionFn('offer').then(function (id) {
                $scope[itemType].id = id;
                if (oldOfferItem) copyOldOfferItemFn($scope, oldOfferItem)
            });
        }

        function positionCreateFn($scope, docType) {
            const itemType = docType + "Item";
            $scope.btnDelete = {};
            $scope[itemType] = {type: 'ESHOP_PRODUCT', params: ''}; //Значение модели для textAngular должно быть строковым
            $scope.offerItemType = 'ESHOP_PRODUCT';

            dictionarySrv.resetPaginatedDict($scope);

            dictionarySrv.refreshDictionary($scope, 'rOKPD2', null, true);
            dictionarySrv.refreshDictionary($scope, 'rSKMTR', null, true);

            $scope[itemType].vatRate = "null";
            $scope.positionStatus = 'new';
            posProcessing = true;
        }

        function positionSaveFn($scope, docType, saveFn) {
            $scope.positionSaveValidate = true;

            $rootScope.$emit('rejectFileEdit');

            if($scope.form.positionForm.$invalid) {
                if($scope.form.positionForm.$error?.required && $scope.form.positionForm.$error?.required.length===1 &&
                    $scope.form.positionForm.$error.required[0].$$attr.ngModel==='it.targetFile.description') {
                    $scope.form.positionForm.$error = {};
                    $scope.form.positionForm.$setValidity("", true);
                }
            }

            if ($scope.form.positionForm && $scope.form.positionForm.$invalid) {
                if ($scope.form.positionForm.$error.minimumPackageVolume) {
                    $scope.alertId = 'minimumPackageVolume';
                    return;
                }
                $scope.alertId = 'validPurchaseError';
                return;
            }
            const itemType = docType + "Item";
            if (!$scope[docType]) $scope[docType] = {};

            //проверка ставки ндс в позиции оферты
            if (noVatFn($scope, docType) && $scope[itemType].vatRate !== 'null') {
                $scope.alertId = 'validPricePosError';
                $scope.validPricePosErrorText = systemMessages.OFFER_NDS_ERROR;
                return;
            }

            let priceNdsBool = true;
            if (['offerItem'].includes(itemType)) {
                if (!$scope[docType].nonNds && $scope[itemType].vatRate !== 'null') {
                    priceNdsBool = $scope[itemType].vatRate != null;
                }
            }


            if ($scope[itemType].okpd2 != undefined && $scope[itemType].name != undefined && $scope[itemType].price != undefined && $scope[itemType].okpd2 != "" && $scope[itemType].name != "" && $scope[itemType].price != "" && priceNdsBool) {

                if (itemType == 'offerItem') {
                    if ($scope[itemType].params == "" || $scope[itemType].params == undefined) {
                        $scope.alertId = 'validPurchaseError';
                        return;
                    }
                }

                if ($scope[itemType].type == 'ESHOP_PRODUCT') {
                    if ($scope[itemType].volume) {
                        //проверяем размер общей суммы перед сохранением
                        const mask = /^[0-9]{0,13}(?:\.[0-9]{0,2})?$/,
                            summaTest = $scope[itemType].summa;
                        if (!mask.test(summaTest)) {
                            dialogSrv.showDialogMsg($scope, "Превышена максимально допустимая сумма документа! Сохранение позиции невозможно.");
                            return;
                        }
                    } else {
                        $scope.alertId = 'validPurchaseError';
                        return;
                    }
                }
                //если доп предложений не осталось, то удаляем и сам массив
                if ($scope[itemType].discounts === undefined || $scope[itemType].discounts.length == 0) {
                    delete $scope[itemType].discounts;
                }
                if (typeof saveFn == "function")
                    saveFn($scope[itemType]);
                closePositionFn($scope, itemType);
                $scope.positionSaveValidate = false;
                $scope.positionSeqNumber = '';
                if ($scope.form.mainForm) {
                    $scope.form.mainForm.$setDirty();
                }
                
                if(offerScope?.alertId==="offerSaveError") {
                    offerScope.alertId = 'dissmiss';
                }

            } else {
                $scope.alertId = 'validPurchaseError';
            }
        }


        function positionEditFn($scope, docType, item) {
            var itemType = docType + "Item";
            if ($scope.positionSeqNumber == item.seqNumber) {
                closePositionFn($scope, itemType);
            } else {

                dictionarySrv.resetPaginatedDict($scope);
                $scope.positionStatus = 'edit';
                $scope.positionSeqNumber = item.seqNumber;
                $scope[itemType] = angular.copy(item);
                if ($scope[itemType].type == 'ESHOP_PRODUCT') {
                    $scope.offerItemType = 'ESHOP_PRODUCT';
                } else {
                    $scope.offerItemType = 'ESHOP_SERVICE';
                }
                if (item.okpd2) {
                    var paramOkpd = {
                        searchByField: {
                            code: item.okpd2
                        },
                        itemsPerPage: 20,
                        page: 0
                    };
                    var paramSkmtr = {
                        searchByField: {
                            okpd2: item.okpd2
                        },
                        itemsPerPage: 20,
                        page: 0
                    };
                    dictionarySrv.loadPaginatedDict($scope, 'OKPD2', paramOkpd, 'rOKPD2');
                    dictionarySrv.loadPaginatedDict($scope, 'SKMTR', paramSkmtr, 'rSKMTR');
                }

                if (item.skmtr) {
                    var paramSkmtr = {
                        searchByField: {
                            skmtr: item.skmtr
                        },
                        itemsPerPage: 20,
                        page: 0
                    };
                    dictionarySrv.loadPaginatedDict($scope, 'SKMTR', paramSkmtr, 'rSKMTR');
                }

                if (item.measure) {
                    var paramOkei = {
                        searchByField: {
                            code: item.measure,
                            actual: true
                        },
                        itemsPerPage: 20,
                        page: 0
                    };
                    dictionarySrv.loadPaginatedDict($scope, 'OKEI', paramOkei, 'rOKEI')
                }
                posProcessing = true;
            }
        }

        function positionDeleteFn($scope, docType, item) {
            var positionArray = 'positions';
            if (!$scope[docType][positionArray]) $scope[docType][positionArray] = [];
            var index = $scope[docType][positionArray].indexOf(item);
            if (index > -1) {
                $scope[docType][positionArray].splice(index, 1);
            }

            $scope[docType].summa = getTotalFn($scope[docType][positionArray], 'summa');
            $scope[docType].summaNds = getTotalFn($scope[docType][positionArray], 'summaNds', 'summa');

            delete $scope.btnDelete[item.seqNumber];
            var formName = 'mainForm';
            if ($scope.form[formName]) {
                $scope.form[formName].$setDirty();
            }
            $scope.positionStatus = 'hide';
            var positionCounter = index > 0 ? $scope[docType][positionArray][index - 1].seqNumber : 0;
            _reCalcSeqNumber($scope[docType][positionArray], index, positionCounter);
            posProcessing = false;
        }

        function getSumFn($scope, docType) {
            if (docType == 'companyProfile') return;
            var itemType = docType + 'Item',
                price = $scope[itemType].price,
                priceNds = $scope[itemType].priceNds,
                volume = $scope[itemType].volume,
                percent = $scope[itemType].vatRate == 'null' ? 0 : $scope[itemType].vatRate;


            if (price && volume) {
                price = ((price * 100) * volume) / 100;
                $scope[itemType].summa = Math.round(price * 100) / 100;
                priceNds = (($scope[itemType].summa * 100) * ((1 + Number(percent) / 100) * 100)) / 10000;
                $scope[itemType].summaNds = Math.round(priceNds * 100) / 100;
            } else {
                delete $scope[itemType].summa;
                delete $scope[itemType].summaNds;
            }
        }

        function closePositionFn($scope, itemType) {
            $scope.positionStatus = 'hide';
            $scope[itemType] = {type: 'ESHOP_PRODUCT', params: ''}; //Значение модели для textAngular должно быть строковым
            $scope.positionSeqNumber = '';
            posProcessing = false;
        }

        /**
         * Перерасчет полей Цена за единицу ( price - цена без НДС, priceNds - цена с НДС)
         * @param $scope - $scope компонента
         * @param percent - ставка НДС
         * @param docType - тип поля в модели
         * @param nonNds
         * @param isNdsFiled - true если меняем поле priceNds, false если price
         * */
        function changePriceNdsFn($scope, percent, docType, nonNds, isNdsFiled) {
            var itemType = docType + 'Item';
            if (angular.isNumber(percent) || percent === 'null' || nonNds) {
                //выбрали нет НДС - ставим в значение без НДС
                if (percent === 'null' && itemType === 'offerItem') {
                    $scope[itemType].useVatPrice = false;
                    isNdsFiled = true;
                }

                if (percent === 'null' || nonNds) percent = 0;

                if (isNdsFiled) {
                    if ($scope[itemType].priceNds) {
                        //Цена за единицу (без НДС) расчитывется из цены с НДС
                        var priceNew = $scope[itemType].priceNds / (1 + Number(percent) / 100);
                        $scope[itemType].price = Number(Number(priceNew).toFixed(2));
                    }
                } else {
                    if ($scope[itemType].price) {
                        //Цена за единицу с НДС расчитывается из цены без НДС.
                        var priceNew = $scope[itemType].price * (1 + Number(percent) / 100);
                        $scope[itemType].priceNds = Number(Number(priceNew).toFixed(2));
                    }
                }
                $scope.getSum(docType);
            }
        }

        function checkPositionsVatRateFn($scope, docType) {
            var positions = 'positions';
            angular.forEach($scope[docType][positions], function (position) {
                if (!angular.isNumber(position.vatRate))
                    position.vatRate = "null";
            });
        }

        function downloadVatRateListFn($scope) {
            $scope.vatRateList = [{id: "null", name: "Без НДС"}];
            return httpSrv.http({
                method: 'GET',
                url: '/orders/api/v1/property/vatRate'
            }).then(function successCallback(response) {
                if (response.data.success) {
                    angular.forEach(response.data.result.split(','), function (item) {
                        $scope.vatRateList.push({id: Number(item), name: item});
                    });
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function getTotalFn(items, prop, propOptional) {
            if (!Array.isArray(items)) return 0;
            var result = 0;
            for (var i = 0, max = items.length; i < max; i++) {
                if (items[i][prop])
                    result += items[i][prop] * 100;
                else if (items[i][propOptional])
                    result += items[i][propOptional] * 100;
            }
            result = result / 100;
            return Number(Number(result).toFixed(2));
        }

        function addPositionsFromXlsFn($scope, response, object) {
            if (response.data.success) {
                $scope.alertId = '';
                if ($scope.form.mainForm) $scope.form.mainForm.$setDirty();
                $scope.btnDelete = {};
                $scope.alertId = 'dismiss';
                $scope.alertId2 = 'dismiss';
                if (!$scope[object]) $scope[object] = {};
                var positionsArrayName = 'positions',
                    documentObject = $scope[object];

                if (!documentObject[positionsArrayName]) {
                    documentObject[positionsArrayName] = [];
                }
                var i;
                var a = response.data.result.positions,
                    positionsArray = documentObject[positionsArrayName],
                    lastEl = positionsArray.length > 0 ? positionsArray[positionsArray.length - 1] : {},
                    lastElNumber = lastEl.seqNumber || 0;

                $scope.positionCounter = lastElNumber;

                _reCalcSeqNumber(a, 0, $scope.positionCounter);
                for (i = 0; i < a.length; ++i) {
                    if (a[i].vatRate >= 0) {
                        a[i].priceNds = Number((a[i].price * (1 + Number(a[i].vatRate) / 100)).toFixed(2));
                    } else {
                        a[i].vatRate = "null";
                        a[i].priceNds = Number(a[i].price.toFixed(2));
                    }
                }
                documentObject[positionsArrayName] = documentObject[positionsArrayName].concat(a);
                documentObject.summa = getTotalFn(documentObject[positionsArrayName], 'summa');
                documentObject.summaNds = getTotalFn(documentObject[positionsArrayName], 'summaNds', 'summa');
                $scope.addXLS = false;
                //ie 11 баг снятие фокуса. Иначе не нажат кнопки
                window.focus();
                if (document.activeElement) {
                    document.activeElement.blur();
                }
            } else {
                $scope.alertId = 'wrongExcel';
                $scope.alertMessage = response.data.message;
            }
        }

        function _reCalcSeqNumber(positions, firstReCalcIndex, positionCounter) {
            for (var i = firstReCalcIndex; i < positions.length; ++i) {
                positions[i].seqNumber = ++positionCounter;
            }
        }

        function checkQuantityFn($scope, docType) {
            //isOrder - tmp до изменения заказа
            var positionsArrayName = 'positions',
                documentObject = $scope[docType],
                canSave = true,
                item;
            if (Array.isArray(documentObject[positionsArrayName]) && documentObject[positionsArrayName].length > 0) {
                for (var idx in documentObject[positionsArrayName]) {
                    if (!documentObject[positionsArrayName].hasOwnProperty(idx)) continue;
                    item = documentObject[positionsArrayName][idx];
                    if (!item.volume) {
                        if (item.type != 'ESHOP_SERVICE') {
                            canSave = false;
                            if ($scope.positionSeqNumber != item.seqNumber) positionEditFn($scope, docType, item);
                            return canSave;
                        }
                    }
                    if (item) {
                        if (item.price) {
                            item.price = getFixed(item.price, 2);
                        }
                        if (item.priceNds) {
                            item.priceNds = getFixed(item.priceNds, 2);
                        }
                        if (item.summa) {
                            item.summa = getFixed(item.summa, 2);
                        }
                        if (item.summaNds) {
                            item.summaNds = getFixed(item.summaNds, 2);
                        }
                        if (item.volume) {
                            item.volume = getFixed(item.volume, 5);
                        }
                    }
                }
            }
            return canSave;

        }

        function getFixed(value, fractDigits) {
            if (value) {
                if ((typeof value.toFixed === 'function')) {
                    return Number(value.toFixed(fractDigits));
                } else {
                    var parseValue = parseFloat(value);
                    if (parseValue && typeof parseValue.toFixed === 'function') {
                        return Number(parseValue.toFixed(fractDigits));
                    }
                }
            }

            return 0;
        }


        function checkPositionChangedFn(posArray, currentPos) {
            //если создана новая позиция и еще не сохранена
            if (!angular.equals(currentPos, {type: 'ESHOP_PRODUCT'})
                && !angular.equals(currentPos, {type: 'ESHOP_PRODUCT', params: ''})
                && currentPos.seqNumber === undefined
            ) return true;

            if(positionScope?.form?.positionForm?.$dirty) {
                return true;
            }
            // Отдельно проверяем radio-buttons
            if(positionScope?.form?.positionForm?.inlineRadioOptions?.$modelValue!==currentPos?.type)
                return true;

            if (!Array.isArray(posArray) || !posArray.length) return false;

            var filteredBuffer = posArray.filter(function (el) { //.search
                return el.seqNumber === currentPos.seqNumber;
            });

            if (filteredBuffer.length > 0) {
                var copy = angular.copy(currentPos);

                //Убираем ЕЛС
                if (!filteredBuffer[0].els) delete copy.els;

                //Если не маркет игнорируем параметр доставки
                if ($rootScope.properties.market !== 'true') copy.isCalcTransportEnabled = filteredBuffer[0].isCalcTransportEnabled;

                return !angular.equals(filteredBuffer[0], copy);
            }

            return false;
        }

        function createDraftPositionFn() {
            return httpSrv.http({
                method: 'GET',
                url: '/orders/api/v1/offer/createDraftPosition'
            }).then(function successCallback(response) {
                if (response.data.success) {
                    return response.data.result;
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function checkDocNdsFn(positions) {
            if (!Array.isArray(positions) || !positions.length) return false;
            return positions.find(function (el) {
                return hasVatRateFn(el)
            }) == null;
        }

        function getPriceTypeFn(useVatPrice) {
            return useVatPrice ? 'с учетом НДС' : 'без учета НДС';
        }

        /**
         * Актуализировать значения цен
         * @param targetPosition - целевая позиция
         * */
        function correlatePriceFn(targetPosition) {
            var preset = [
                {convert: _toNds, from: 'price', to: 'priceNds'},
                {convert: _fromNds, from: 'priceNds', to: 'price'},
            ][+targetPosition.useVatPrice];

            if (!targetPosition[preset.from]) return;
            var vatRate = targetPosition.vatRate;
            if (vatRate === 'null') vatRate = 0;

            targetPosition[preset.to] = _floor100(preset.convert(targetPosition[preset.from], vatRate));

            updateSumFn(targetPosition);
        }

        /**
         * Обновить поле цены в позиции
         * @param targetPosition - целевая позиция
         * */
        function updatePriceFieldByVatFn(targetPosition) {
            if (targetPosition.vatRate === 'null') { //"Без НДС"
                if (targetPosition.useVatPrice) targetPosition.price = targetPosition.priceNds;
                targetPosition.useVatPrice = false;
            }

            correlatePriceFn(targetPosition);
        }

        /**
         * Пересчитать сумму по позиции
         * @param targetPosition - целевая позиция
         * */
        function updateSumFn(targetPosition) {
            var vatRate = targetPosition.vatRate === 'null' ? 0 : targetPosition.vatRate;

            var preset = [
                {srcPrice: targetPosition.price, base: 'summa', calc: 'summaNds', convert: _toNds},
                {srcPrice: targetPosition.priceNds, base: 'summaNds', calc: 'summa', convert: _fromNds},
            ][+targetPosition.useVatPrice];

            if (targetPosition.volume && preset.srcPrice) {
                targetPosition[preset.base] = _round100(preset.srcPrice * targetPosition.volume);
                targetPosition[preset.calc] = _round100(preset.convert(targetPosition[preset.base], vatRate));
            } else {
                delete targetPosition.summa;
                delete targetPosition.summaNds;
            }
        }

        /**
         * Выбор типа 'Цена за единицу' с учетом НДС / без учета НДС
         * @param targetPosition - целевая позиция
         * @param useVatPrice - базировать расчет на цене с учетом НДС (тип цены)
         * */
        function setPriceVatTypeFn(targetPosition, useVatPrice) {
            if (targetPosition.useVatPrice !== useVatPrice) {
                if (useVatPrice) targetPosition.priceNds = Number(targetPosition.price);
                else targetPosition.price = Number(targetPosition.priceNds);
            }
            targetPosition.useVatPrice = useVatPrice;
            correlatePriceFn(targetPosition);
        }

        function _floor100(v) {
            return Math.floor(v * 100) / 100;
        }

        function _round100(v) {
            return Math.round(v * 100) / 100;
        }

        function _toNds(price, vat) {
            return price * (1 + Number(vat) / 100);
        }

        function _fromNds(priceNds, vat) {
            return priceNds / (1 + Number(vat) / 100);
        }

    }]);
