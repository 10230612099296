/**
 * @author dmitrys
 */

angular.module('uetp').service('bankGuaranteeLokoSrv', ['$state', 'httpSrv', 'authSrv', 'cryptography', '$filter',
    function ($state, httpSrv, authSrv, cryptography, $filter) {
        var service = this;
        service.hashAlg;

        service.save = saveFn;
        service.deleteApp = deleteAppFn;
        service.getBankGuarantee = getBankGuaranteeFn;
        service.send = sendFn;
        service.startWork = startWorkFn;
        service.autoCreateTassParticipant = autoCreateTassParticipantFn;
        service.createTassParticipant = createTassParticipantFn;
        service.sendDecision = sendDecisionFn;
        service.dateForSign = dateForSignFn;
        service.getOrderProcess = getOrderProcessFn;
        service.payComission = payComissionFn;
        service.signDocument = signDocumentFn;
        service.getInvoker = getInvokerFn;
        service.getDocTypeList = getDocTypeListFn;
        service.getDefaultModel = getDefaultModelFn
        service.setDataFormPurchase = setDataFormPurchaseFn

        /*
        * Сохранение анкеты
        * refresh = true - обновление
        * */
        function saveFn(data, id, refresh, isCreateDocs) {
            var url = 'bankguarantee/lk/orders/create';
            if (refresh) {
                url = 'bankguarantee/lk/orders/store/' + id + (isCreateDocs ? '?isCreateDocs=true' : '')
            }
            return httpSrv.http({
                data: data,
                url: url
            });
        }

        /*
        * Удалить анкету
        * доступно только для черновика
        * */
        function deleteAppFn(orderId) {
            return httpSrv.http({
                method: 'DELETE',
                url: '/bankguarantee/lk/orders/remove/' + orderId
            });
        }

        /*
        * Получить анкету по id
        * */
        function getBankGuaranteeFn(id, isCopy) {
            return httpSrv.http({
                method: 'GET',
                url: 'bankguarantee/lk/orders/' + id + (isCopy ? '/copy' : '')
            });
        }

        /*
        * Отправка анкеты
        * orderId - внутрений id заявки
        * invoker
        * */
        function sendFn(orderId, invoker) {
            return httpSrv.http({
                method: 'POST',
                url: '/bankguarantee/lk/orders/sendToBank/' + orderId,
                data: invoker
            });
        }

        /*
        * Начало работы над анкетой Локо-Банка
        * orderId - внутрений id заявки
        * invoker
        * */
        function startWorkFn(orderId, invoker) {
            return httpSrv.http({
                method: 'POST',
                url: '/bankguarantee/lk/orders/start/' + orderId,
                data: invoker
            });
        }

        /**
         * Автоматическое создание файла с результатами проверки компании-участника для Локо-Банка
         * */
        function autoCreateTassParticipantFn(orderId, inn) {
            return httpSrv.http({
                method: 'GET',
                url: '/bankguarantee/lk/orders/autoCreateTassParticipant/' + inn + '/' + orderId
            });
        }

        /**
         * Создание файла с результатами проверки компании-участника для Локо-Банка
         * */
        function createTassParticipantFn(orderId, inn) {
            return httpSrv.http({
                method: 'GET',
                url: '/bankguarantee/lk/orders/createTassParticipant/' + inn + '/' + orderId
            });
        }

        /**
         * Отправить решение
         * @param orderId - внутрений id заявки
         * @param type - тип решения
         * finish - Выдача гарантии по анкете
         * reject - Отказ в выдаче по анкете
         * requestToClient - Запрос уточнения по анкете
         * agreement - Одобрение гарантии по анкете
         * @param interaction - параметры решения
         * */
        function sendDecisionFn(orderId, type, interaction) {
            return httpSrv.http({
                method: 'POST',
                url: '/bankguarantee/lk/orders/' + type + '/' + orderId,
                data: interaction
            });
        }

        /**
         * Получить данные для подписи
         */
        function dateForSignFn(orderId, certId) {
            return cryptography.getAlgoInfo(certId).then(function (data) {
                service.hashAlg = data.code;// Сохраняем алгоритм для подписи
                return httpSrv.http({
                    method: 'POST',
                    url: '/bankguarantee/lk/orders/dateForSign/' + orderId,
                    data: data.code
                });
            })
        }

        function getInvokerFn(user) {
            return {
                userName: user.lastName + ' ' + user.firstName + ' ' + user.middleName,
                orgName: user.fullName
            };
        }

        /**
         * Получение сообщений для чата по анкете
         * @param orderId - внутрений id заявки
         */
        function getOrderProcessFn(orderId) {
            return httpSrv.http({
                method: 'GET',
                url: '/bankguarantee/lk/orders/getOrderProcess/' + orderId
            });
        }

        function payComissionFn(orderId, interaction) {
            return httpSrv.http({
                method: 'POST',
                url: '/bankguarantee/lk/orders/payComission/' + orderId,
                data: interaction
            });
        }

        function _iteratorObject(array) {
            var nextIndex = 0;
            return {
                next: function () {
                    return nextIndex < array.length ?
                        {object: array[nextIndex++], done: false} :
                        {done: true};
                }
            }
        }

        /**
         * Подпись документа
         * @param model - данные для подписи
         * @param certHash - иентификатор сертификата
         * @param hashAlg - алгоритм шифрования
         * @returns {Promise}
         */
        function signDocumentFn(model, certHash, hashAlg) {
            var iterator = _iteratorObject(model);
            return new Promise(function (resolve, reject) {
                var recursiveSign = function (iterator, object) {
                    cryptography.signHash(object.hashValue, certHash, hashAlg).then(function (data) {
                        object.signature = data;
                        var iterate = iterator.next();
                        if (!iterate.done) {
                            recursiveSign(iterator, iterate.object);
                        } else {
                            resolve(model);
                        }
                    }, function (reason) {
                        reject(reason)
                    })
                };
                recursiveSign(iterator, iterator.next().object)
            });
        }

        /**
         * Модель по умолчанию в формате модели для отображения
         * userInfo - данные по участнику - если заполняет агент
         * */
        function getDefaultModelFn(userInfo) {
            var model = {};
            model.principal = {};
            var user = authSrv.getUser();
            if (userInfo) {
                user = userInfo;
            }
            model.principal.fullName = user.fullName;
            model.principal.inn = user.inn;
            model.principal.ogrn = user.ogrn;
            model.bankGuarantee = {};
            model.bankGuarantee.federalLaw = '223';

            model.principal.companyHead = {};
            model.principal.companyHead.personAttributes = {
                citizenship: 'RUS',
                citizenshipName: 'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
                countryOfResidence: 'RUS',
                countryOfResidenceName: 'РОССИЙСКАЯ ФЕДЕРАЦИЯ'
            };
            model.principal.companyHead.identityDocument = {
                identityDocumentType: 'passportRf'
            };

            model.principal.kpp = user.kpp;
            model.principal.addressesFac = {};
            model.principal.addressesUr = {};
            //нет фактического адреса, но есть юр - ставим везде юр
            if (!user.realAddress) {
                if (user.regAddress) {
                    model.principal.addressesFac.address = user.regAddress.addressAsString;
                    model.principal.addressesUr.address = user.regAddress.addressAsString;
                }
            } else {
                //есть оба
                if (user.regAddress) {
                    model.principal.addressesUr.address = user.regAddress.addressAsString;
                }
                model.principal.addressesFac.address = user.realAddress.addressAsString;
            }

            model.principal.bankAccounts = {};
            model.principal.bankAccounts.useInDocuments = false;

            if (user.requisites) {
                model.principal.bankAccounts.number = user.requisites.acc;
                model.principal.bankAccounts.bank = {};
                model.principal.bankAccounts.bank = {
                    bic: user.requisites.bicBank,
                    accounts: user.requisites.bankAcc,
                    participant: user.requisites.bank
                };
            }
            model.principal.additionalInfo = {
                currentCard: false,
                debtFederal: false,
                debtEmployees: false,
                officialMin: false,
                executiveChange: false,
                repeatedLoss: false,
                noAccountant: false,
                taxChange: false,
            }


            return model;
        }

        /*
        * Проставляем данны из закупки в формате модели для отображения
        * model - куда ставим (анкета)
        * purchasData - что ставим (закупка)
        *
        * type - тип откуда пришли данные (
        * UETP - закупки КОМИТА ЭТП (из БД КОМИТА ЭТП)
        * EIS - из Реестра закупок ЕИС (из ElasticSearch purchase_notice*, purchase_notification*)
        * )
        * */
        function setDataFormPurchaseFn(model, purchasData, type) {
            var currentDate = getServerTimeMsk().getTime();
            //однолотовая процедура
            var isOneLot = purchasData.lots && purchasData.lots.length === 1;
            if (!model.bankGuarantee)
                model.bankGuarantee = {};

            if (type === 'UETP') {
                model.bankGuarantee.bankGuaranteeType = purchasData.claimEnds > currentDate ? "participation" : "execution";
                if (purchasData.commercial) {
                    model.bankGuarantee.purchaseNumber = purchasData.number;
                } else {
                    model.bankGuarantee.purchaseNumber = purchasData.eisNumber;
                }
                //однолотовая процедура
                if (isOneLot) {
                    if (purchasData.claimEnds > currentDate) {
                        model.bankGuarantee.bankGuaranteeSum = purchasData.lots[0].applicationSupplySumm;
                    }
                }
                model.bankGuarantee.purchasePublishedDate = $filter('date')(purchasData.claimBegins, 'yyyy-MM-dd');
                //однолотовая процедура
                if (isOneLot) {
                    model.bankGuarantee.lotNumber = "1";
                    model.bankGuarantee.subject = purchasData.lots[0].name;
                }
                if (purchasData.customer) {
                    var fillAddress = function (address) {
                        var str = '';
                        if (address.addressNonResident) {
                            str += address.addressNonResident;
                        } else {
                            str += address.zip + getField(address.regionOKATOName) + getField(address.area) + getField(address.settlement) + getField(address.oksmName) + getField(address.street) + getField(address.building) + getField(address.subBuilding) + getField(address.office);
                        }
                        return str;
                    };

                    var getField = function (data) {
                        if (data) {
                            return ', ' + data
                        }
                        return ''
                    };

                    if (!model.principal) model.principal = {};
                    if (!model.principal.beneficiariesLoko) model.principal.beneficiariesLoko = [];
                    var beneficiariesLoko = {};
                    if (purchasData.customer.realAddress) {
                        beneficiariesLoko.actualAddress = fillAddress(purchasData.customer.realAddress);
                    } else {
                        if (purchasData.customer.regAddress) {
                            beneficiariesLoko.actualAddress = fillAddress(purchasData.customer.regAddress);
                        }
                    }
                    if (purchasData.customer.regAddress) {
                        beneficiariesLoko.legalAddress = fillAddress(purchasData.customer.regAddress);
                    }
                    beneficiariesLoko.inn = purchasData.customer.inn;
                    beneficiariesLoko.kpp = purchasData.customer.kpp;
                    beneficiariesLoko.ogrn = purchasData.customer.ogrn;
                    beneficiariesLoko.fullName = purchasData.customer.name;
                    model.principal.beneficiariesLoko.push(beneficiariesLoko);
                }

            } else if (type === 'EIS') {
                //заполняем данными из закупки ЕИС
                model.bankGuarantee.purchaseNumber = purchasData.purchase_number;
                if (purchasData.submission_close > currentDate) {
                    model.bankGuarantee.bankGuaranteeType = "participation";
                } else {
                    model.bankGuarantee.bankGuaranteeType = "execution";
                }
                //поля из однолотовой процедуры
                if (isOneLot) {
                    if (purchasData.submission_close > currentDate) {
                        model.bankGuarantee.bankGuaranteeSum = purchasData.lots[0].applicationSupplySumm;
                    } else {
                        model.bankGuarantee.bankGuaranteeSum = purchasData.lots[0].contractSupplySumm;
                    }
                }
                model.bankGuarantee.isCommercial = false;
                if (purchasData.publish_date_first) {
                    model.bankGuarantee.purchasePublishedDate = $filter('date')(purchasData.publish_date_first, 'yyyy-MM-dd');
                }
                if (purchasData.publish_date) {
                    model.bankGuarantee.purchasePublishedDate = $filter('date')(purchasData.publish_date, 'yyyy-MM-dd');
                }
                if (!model.principal) model.principal = {};
                model.principal.beneficiariesLoko = [];
                angular.forEach(purchasData.customers, function (item) {
                    var beneficiar = {};
                    beneficiar.inn = item.inn;
                    beneficiar.kpp = item.kpp;
                    beneficiar.ogrn = item.ogrn;
                    beneficiar.fullName = item.name;
                    model.principal.beneficiariesLoko.push(beneficiar);
                });
                //поля из однолотовой процедуры
                if (isOneLot) {
                    model.bankGuarantee.lotNumber = "1";
                    model.bankGuarantee.subject = purchasData.lots[0].lot_name;
                }
            }
        }
        /**
         * @param typeUser - тип личного кабинета пользователя
         * @returns
         * {
         * isRequired: boolean, // обязательность документа
         * typeUser: null,  // тип лк, в котором выводится документ ip, ul или all(для всех)
         * canEdit: boolean, // можем ли мы приклыдвать файл (если true появится в списках для выбора)
         *       в чате в списке
         * isMultiple: boolean, // доступен ли множественный выбор
         * label: string, // русское описание
         * type: string // тип документа на сервере
         * }
         *
         */
        function getDocTypeListFn(typeUser) {
            return [
                {
                    type: "copy_passport",
                    label: "Копия всех страниц паспорта РФ (включая пустые) учредителей, ЕИО и бенефициарных владельцев",
                    isRequired: true,
                    isMultiple: true,
                    canEdit: false,
                    typeUser: 'all'
                },
                {
                    type: "charter",
                    label: "Устав",
                    isRequired: true,
                    isMultiple: false,
                    canEdit: false,
                    typeUser: 'ul'
                },
                {
                    type: "extract_register",
                    label: "Выписка из реестра акционеров, выданная не ранее чем за 30 (Тридцать) календарных дней до даты предоставления Анкеты-Заявления на получение банковской гарантии",
                    isRequired: true,
                    isMultiple: false,
                    canEdit: false,
                    typeUser: 'ul'
                },
                {
                    type: "decision_approval",
                    label: "Решение уполномоченного органа об одобрении крупной сделки, а также решение/протокол об избрании коллегиального органа, одобрившего сделку",
                    isRequired: true,
                    isMultiple: false,
                    canEdit: false,
                    typeUser: 'ul'
                },
                {
                    type: "confirming_executive",
                    label: "Документы, подтверждающие полномочия единоличного исполнительного органа (протокол/решение об избрании/назначении и т.п.)",
                    isRequired: true,
                    isMultiple: true,
                    canEdit: false,
                    typeUser: 'ul'
                },
                {
                    type: "confirming_address",
                    label: "Документ, подтверждающий нахождение по фактическому адресу: договор аренды/субаренды/иной договор или свидетельство о праве собственности/выписка из Единого государственного реестра недвижимости",
                    isRequired: true,
                    isMultiple: true,
                    canEdit: false,
                    typeUser: 'ul'
                },
                {
                    type: "fin_report",
                    label: "Годовая бухгалтерская (финансовая) отчетность — бухгалтерский баланс и отчет о финансовых результатах — за последний завершенный год",
                    isRequired: true,
                    isMultiple: true,
                    canEdit: false,
                    typeUser: 'ul'
                },
                {
                    type: "quarter_report",
                    label: "Промежуточная бухгалтерская (финансовая) отчетность — бухгалтерский баланс и отчет о финансовых результатах — на последнюю календарную квартальную дату",
                    isRequired: true,
                    isMultiple: true,
                    canEdit: false,
                    typeUser: 'ul'
                },
                {
                    type: "tax_declaration",
                    label: "Налоговая декларация по налогу, уплачиваемому в связи с применением упрощенной системы налогообложения — за последний завершенный год",
                    isRequired: true,
                    isMultiple: true,
                    canEdit: false,
                    typeUser: 'ip'
                },
                {
                    type: "accounting_book",
                    label: "Книга учета доходов и расходов организаций и индивидуальных предпринимателей, применяющих упрощенную систему налогообложения — по последнюю календарную квартальную дату",
                    isRequired: true,
                    isMultiple: true,
                    canEdit: false,
                    typeUser: 'ip'
                },
            ].filter(item => item.typeUser === typeUser || item.typeUser === 'all')
        }
    }]);
