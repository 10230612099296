/*global getServerTimeMskPromise*/

/**
 * @author dmitrys
 */
angular.module('uetp')
    .component('notice', {
        templateUrl: 'app/components/notice/notice.html',
        bindings: {
            procData: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'noticeSrv', 'proceduresSrv', 'needConfirmSrv', 'alertSrv', 'extendFieldSrv', 'titleSrv', 'paginationSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, noticeSrv, proceduresSrv, needConfirmSrv, alertSrv, extendFieldSrv, titleSrv, paginationSrv) {

                $scope.setActivePanel = setActivePanelFn;
                $scope.getActivePanel = getActivePanelFn;
                $scope.isLoadTemplate = isLoadTemplateFn;
                $scope.getState = getStateFn;
                $scope.showMenu = showMenuFn;
                $scope.getForm = getFormFn;
                $scope.focusFirstInvalid = focusFirstInvalidFn;
                $scope.findParticipant = findParticipantFn;
                $scope.addParticipant = addParticipantFn;
                $scope.setParticipantList = setParticipantListFn;
                $scope.deleteParticipant = deleteParticipantFn;
                $scope.getTitleTab = getTitleTabFn;
                $scope.isShowExtendedFields = noticeSrv.isShowExtendedFields;
                $scope.isNoticeExtFields = noticeSrv.isNoticeExtFields;
                $scope.isNoticeIntegrationEIS = noticeSrv.isNoticeIntegrationEIS;
                $scope.getSectionName = noticeSrv.getSectionName;
                $scope.getSection = noticeSrv.getSection;
                $scope.getParticipantsDoc = getParticipantsDocFn;
                $scope.getPager = getPagerFn;

                this.$onInit = function () {
                    var ctrl = this;
                    $scope.cabType = desktopSrv.getCabType();
                    $scope.submitted = noticeSrv.isSubmitted;

                    $scope.model = {
                        showPanel: noticeSrv.getShowPanel(),
                        showProcedure: true, // Показать создание или изменение процедцры
                        showLots:true,//Показывать лоты
                        showDocumentation: true, //Показать  Документация
                        showParticipants: false, //Показать список участников
                        showExtendFields: true //Вкладка "Расширеные поля"
                    };
                    if(['limitedcontest','limitedauction','limitedrequestquotations','limitedrequestproposals','limitedcontestqualification'].includes($stateParams.type))
                    	$scope.model.showParticipants = true;
                    
                    extendFieldSrv.clearNoticeExtFieldTemplate();
                    extendFieldSrv.setLoadTemplate(false);
                    //создание или изменение процедцры
                    noticeSrv.setReadOnlyForm(false);
                    if (ctrl.procData) {
                        if (!ctrl.procData.data.result.actions || ctrl.procData.data.result.actions.length === 0) {
                            $scope.typeProcedure = 'Просмотр';
                            noticeSrv.setReadOnlyForm(true);
                        } else {
                            $scope.typeProcedure = 'Изменение';
                        }
                        noticeSrv.setEditForm(true);
                    } else {
                        $scope.typeProcedure = 'Создание';
                        noticeSrv.setEditForm(false);
                        noticeSrv.setNoticeInfo({
                            document: noticeSrv.defaultModel($stateParams.type),
                            type: {
                                id: $stateParams.type,
                                name: noticeSrv.initProcNameByType($stateParams.type)
                            }
                        });

                        let date = getServerTimeMsk();
                        if (date) {
                            date.setHours(-3 - new Date().getTimezoneOffset() / 60, 0, 0, 0);
                            noticeSrv.getNoticeInfo().created = date;
                            noticeSrv.getNoticeInfo().document.claimBegins = date;
                        } else {
                            getServerTimeMskPromise().then((date) => {
                                if (date)
                                    date.setHours(-3 - new Date().getTimezoneOffset() / 60, 0, 0, 0);
                                noticeSrv.getNoticeInfo().created = date;
                                noticeSrv.getNoticeInfo().document.claimBegins = date;
                            });
                        }
                    }
                    if ('cbz' === $scope.cabType)
                        noticeSrv.setReadOnlyForm(true);
                    
                    $scope.notice = noticeSrv.getNoticeInfo();
                    $scope.participantInn = {search:''};
                    $scope.dataTableParticipants = [];

                    if(!(noticeSrv.isNew223() || noticeSrv.isInitedNotice()) || $scope.notice.type.id === 'simplecontest') {
                        $scope.model.showExtendFields = false;
                        noticeSrv.setShowExtendedFields(false);
                    } else {
                     	$scope.model.showExtendFields = true;
                        noticeSrv.setShowExtendedFields(true);
                    }
                    /**
                     * Пагинатор
                     */
                    $scope.totalPages = 0;
                    $scope.paginator = {
                        itemsPerPage: 10,
                        page: 0
                    };
                    $stateParams.clearConfig = false;
                    paginationSrv.setPaginationStateConfig('pagination', 'participantsDoc', $scope.paginator);
                    
                    if($scope.model.showParticipants && noticeSrv.getEditForm()){
                        getParticipantsDocFn();
                    }
                    _initShowPanel();
                    _refreshView();

                    $scope.isReadOnly = noticeSrv.getReadOnlyForm();
                    if ($scope.notice.document.number && $scope.notice.type.name) {
                        titleSrv.setTitle($scope.notice.document.number + ' | ' + $scope.notice.type.name);
                    }
                };

                /**
                 * Инициализация панели по типу ЛК
                 * @private
                 */
                function _initShowPanel() {
                    var cabType = desktopSrv.getCabType();
                    if (cabType === 'admincab') {
                    }

                }
               
                /**
                 * Обновление состояния при перегрузке страницы по F5 или другим способом
                 * @private
                 */
                function _refreshView() {
                    var activePanel = $stateParams.setActivePanel;
                    if (activePanel && $scope.model.showPanel !== activePanel) {
                        setActivePanelFn(activePanel);
                    } else if(angular.isUndefined(activePanel) && $scope.model.showPanel==='extendField'){
                        setActivePanelFn('procedure');
                    }
                }

                /**
                 * Выставление активной вкладки
                 * @param type - тип вкладки
                 */
                function setActivePanelFn(type) {
                    if(type==='extendField' && !extendFieldSrv.isLoadTemplate()) return;
                    noticeSrv.setShowPanel(type);
                    $scope.$broadcast('uetp.notice', {data:null, dataType: type, type: 'refresh'});
                }

                /**
                 * Получить активную панель
                 */
                function getActivePanelFn() {
                    return noticeSrv.getShowPanel()
                }
                function isLoadTemplateFn() {
                    return extendFieldSrv.isLoadTemplate()
                }
                function getStateFn() {
                    return noticeSrv.getState();
                }
                function showMenuFn() {
                    var state = getStateFn();
                    if(state=='nomenclature'||state=='lot') return false;
                    return true;
                }
                function getFormFn(state) {
                    return noticeSrv.getForm(state);

                }
                function focusFirstInvalidFn(form, tab) {
                    noticeSrv.focusFirstInvalid({form:form, tab:tab});
                }
                function getParticipantsDocFn() {
                    if(!$scope.dataTableParticipants || $scope.dataTableParticipants.length===0) {
                        proceduresSrv.getParticipantsDoc($stateParams.id, $stateParams.type).then(function (response) {
                            if (response.data.success) {
                                $scope.dataTableParticipants = response.data.result;
                                $scope.totalPages = Math.ceil(response.data.result.length / $scope.getPager().itemsPerPage);
                            }
                        });
                    }

                }
                function getPagerFn(){
                    $stateParams.clearConfig = false;
                    return paginationSrv.getPaginationStateConfig('pagination', 'participantsDoc');
                }
                function findParticipantFn() {
                	if($scope.participantInn.search){
                 	  proceduresSrv.existParticipantByInn($scope.participantInn.search, $stateParams.type).then(function (response) {
                         if (response.data.success) {
                             if(response.data.result){
                            	 needConfirmSrv.call($scope.addParticipant,[],'Организация найдена. Добавить?');
                             }else{
                            	 $scope.alertFindParticipant = alertSrv.getErrorMsg('Организации с таким ИНН/УИН не найдено!');
                            	 $scope.participantInn = {search:''};
                             }
                         }
                      });	
                  	}
                }
                function addParticipantFn() {
                	if($scope.participantInn.search){
                   		 var innExists = [];
                		 innExists = $scope.dataTableParticipants.filter(function(el) {
                		    return el.inn === $scope.participantInn.search;
                	     });
                		 
                		 if(innExists.length > 0){
                			$scope.alertFindParticipant = alertSrv.getErrorMsg("Такой ИНН/УИН уже присутствует в документе");
                		 }else{
                		   $scope.dataTableParticipants.push({
                			 inn: angular.copy($scope.participantInn.search),
                			 statusName: 'не отправлялось',
                			 statusCode: 'notSended'
                			 }); 
                		 }
                		 $scope.participantInn = {search:''};
                	}
                    updatePaginatorFn();
                }

                function setParticipantListFn(innList) {
                    $scope.dataTableParticipants=[];
                    for(var i = 0; i<innList.length; i++){
                        $scope.dataTableParticipants.push({
                            inn: innList[i],
                            statusName: 'не отправлялось',
                            statusCode: 'notSended'
                        });
                    }
                	$scope.participantInn = {search:''};
                    updatePaginatorFn();
                }

                function deleteParticipantFn(inn) {
                 if(inn){
               	  $scope.dataTableParticipants = $scope.dataTableParticipants.filter(function(el) {
                		    return el.inn !== inn;
                	 });

                     if($scope.dataTableParticipants.length<=$scope.paginator.itemsPerPage)
                         $scope.paginator.page = 0;
                     updatePaginatorFn();
                  }
                }
                function getTitleTabFn(type) {
                    var value = '';
                    if (type === 'extendField') {
                        //шаблон не загружен -> проверяем наличие заказчика
                        if (!extendFieldSrv.isLoadTemplate())
                            if ($scope.notice.document && $scope.notice.document.customer && $scope.notice.document.customer.id) {
                                value = 'Расширенных полей нет';
                            } else {
                                value = 'Необходимо выбрать заказчика';
                            }
                    }
                    return value;
                }

                /**
                 * Обновление пагинатора
                 */
                function updatePaginatorFn() {
                    $scope.totalPages = ($scope.dataTableParticipants.length) ? Math.ceil($scope.dataTableParticipants.length / $scope.paginator.itemsPerPage) : null;
                }
            }]
    });
