angular.module('uetp')
    .component('placeholderCards', {
        templateUrl: 'app/components/placeholderCards/placeholderCards.html',
        bindings: {
            type: '@'   //Тип заглушки.
                        // l - большая карточка, многострочная.
                        // lnb - большая карточка, многострочная, без рамок.
                        // s - маленькая карточка, в строчку, без рамок.
                        // x - сплошная плашка.
                        // x2 - две сплошных плашки.
        },
    });
