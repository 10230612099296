/**
 * Создание новостей
 */
angular.module('uetp')
    .component('newsletterCreate', {
        templateUrl: 'app/components/newsletters/newsletterCreate/newsletterCreate.html',
        bindings: {
            newsletterData: '<'
        },
        controller: ['$scope', '$rootScope', 'constants', '$state', '$stateParams', 'newsletterCreateSrv', 'httpSrv', 'dialogSrv', 'alertSrv', 'dictionarySrv','needConfirmSrv','desktopSrv',
            function ($scope, $rootScope, constants, $state, $stateParams, newsletterCreateSrv, httpSrv, dialogSrv, alertSrv, dictionarySrv,needConfirmSrv,desktopSrv) {
                $scope.saveNewsletter = saveNewsletterFn;
                $scope.publicationNewsletter = publicationNewsletterFn;
                $scope.downloadFile = httpSrv.downloadFile;
                $scope.cancel = cancelFn;
                $scope.isReadOnly = isReadOnlyFn;
                if ($stateParams.successSave) {
                    $scope.alertObjNews = alertSrv.getSuccessMsg('Рассылка сохранена!');
                }


                // $scope.$watch("newsletter", function(){
                //     $scope.changedForm = true;
                // }, true);

                function saveNewsletterFn() {
                    $scope.submitted = true;
                    if (!$scope.form.mainForm.$valid) {
                        $scope.alertObjNews = alertSrv.getErrorMsg('Заполните все обязательные поля!');
                        return;
                    } else {
                        $scope.newsletter.status = 'draft';
                    }
                    if ($scope.newsletter.code === 'organizers') {
                        $scope.newsletter.recipients = {};
                        newsletterCreateSrv.getAllOrganizationsInfo(['organizer']).then(function (response) {
                            if (response.data.success) {
                                $scope.newsletter.recipients = response.data.result;
                                doSaveNewsletterFn();
                            }
                        })

                    }else if ($scope.newsletter.code === 'participants') {
                        $scope.newsletter.recipients = {};
                        newsletterCreateSrv.getAllOrganizationsInfo(['participant']).then(function (response) {
                            if (response.data.success) {
                                $scope.newsletter.recipients = response.data.result;
                                doSaveNewsletterFn();
                            }
                        })
                    }else if ($scope.newsletter.code === 'all') {
                        $scope.newsletter.recipients = {};
                        newsletterCreateSrv.getAllOrganizationsInfo(['organizer','participant']).then(function (response) {
                            if (response.data.success) {
                                $scope.newsletter.recipients = response.data.result;
                                doSaveNewsletterFn();
                            }
                        })
                    }else{
                        doSaveNewsletterFn();
                    }
                }
                function doSaveNewsletterFn() {
                    newsletterCreateSrv.saveNewsLetter($scope.newsletter).then(function (response) {
                        if (response.data.success) {
                            $scope.submitted = false;
                            if(!$scope.newsletterId){
                                $state.go('newsletters.view', {id:  response.data.result.id,successSave: true},{location :'replace'});
                            }
                            $scope.newsletterId = response.data.result.id;
                            //$scope.changedForm = false;
                            if ($scope.form.mainForm) {
                                $scope.form.mainForm.$setPristine();
                            }

                            $scope.alertObjNews = alertSrv.getSuccessMsg('Рассылка сохранена!');
                        }
                    }, function errorCallback() {
                        $scope.alertObjNews = alertSrv.getErrorMsg('Рассылка не сохранена!');
                    });
                    if (document.activeElement) {
                        document.activeElement.blur();
                    }
                }

                function publicationNewsletterFn() {
                    if ($scope.newsletter.isSendSelectOrg) {
                        newsletterCreateSrv.getEmailListUserForOrganizations($scope.newsletter.recipients).then(function (response) {
                            if (response.data.success) {
                                const portalUrl = $rootScope.properties && $rootScope.properties['email.url'] ? $rootScope.properties['email.url'] : null;
                                $scope.mails = {'mails':response.data.result, 'portalUrl': portalUrl};
                                _publicationNewsletter($scope.mails);
                            } else {
                                $scope.alertObjNews = alertSrv.getAlertObj(response,'Рассылка не отправлена!');
                            }
                        }, function errorCallback() {
                            $scope.alertObjNews = alertSrv.getErrorMsg('Рассылка не отправлена!');
                        });
                    } else {
                        _publicationNewsletter();
                    }
                }

                function _publicationNewsletter(mails) {
                    newsletterCreateSrv.publicationNewsletter($scope.newsletterId, mails).then(function (response) {
                        if (response.data.success) {
                            $scope.newsletter.status = 'sent';
                            $scope.alertObjNews = alertSrv.getSuccessMsg('Рассылка отправлена!');
                            $scope.model.disabledPanel = true;
                            setTimeout(function() { $state.go('newsletters'); }, 1000);
                        }else{
                            $scope.alertObjNews = alertSrv.getAlertObj(response,'Рассылка не отправлена!');
                        }
                    }, function errorCallback() {
                        $scope.alertObjNews = alertSrv.getErrorMsg('Рассылка не отправлена!');
                    });
                }

                // function loadNewslettersItemFn(id) {
                //     newsletterCreateSrv.loadNewslettersItem(id).then(function (response) {
                //         if (response.data.success) {
                //             $scope.newsletter = response.data.result;
                //             $scope.newsletterId = $scope.newsletter.id;
                //             if($scope.newsletter.code === "someOrganizers" || $scope.newsletter.code === "someParticipants") {
                //                 getDataPanel($scope.newsletter.code);
                //             }
                //             //setTimeout(function() { $scope.changedForm = false; }, 0);
                //         }
                //     }, function errorCallback(response) {
                //         console.log(response);
                //     })
                // }
                $scope.senderTypeChange = function (select) {
                    $scope.showPanel = false;
                    $scope.newsletter.recipients = {};
                    if ($scope.form.mainForm) {
                        $scope.form.mainForm.$setDirty();
                    }
                    getDataPanel(select);
                };

                function getDataPanel(code) {
                    if(code === 'someOrganizers') {
                        newsletterCreateSrv.getOrganizersList().then(function (response) {
                            if (response.data.success) {
                                $scope.model = {
                                    dataPanel: {
                                        dataLeft: response.data.items,
                                        dataRight: checkDataRight(response.data.items),
                                        type: 'organizer',
                                        sph: 'Поиск по наименованию и коду'
                                    },
                                    dataFilter: []
                                };
                                $scope.showPanel = true;
                                if($scope.newsletter.status === 'sent') $scope.model.disabledPanel = true;
                            } else {
                                dialogSrv.showDialogMsg($scope, response.data.message);
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        })
                    } else if(code === 'someParticipants') {
                        newsletterCreateSrv.getParticipantsListRecursion($scope.filter, {itemsPerPage:500, page:0}).then(function (response) {
                            if (response.data.success) {
                                if ($scope.dictionary.fias_region[0].code != 'all') {
                                    $scope.dictionary.fias_region.unshift({formalname: 'Все', code: 'all'});
                                }
                                $scope.model = {
                                    dataPanel: {
                                        dataLeft: response.data.items,
                                        dataRight: checkDataRight(response.data.items),
                                        type: 'participants',
                                        sph: 'Поиск по наименованию и ИНН/УИН'
                                    },
                                    dataFilter: [
                                        {
                                            name: 'Тип регистрации',
                                            code: 'org_type',
                                            items: [{name: 'Все', code: 'all'},
                                                {name: 'Юридическое лицо', code: 'ul'},
                                                {name: 'Индивидуальный предприниматель', code: 'ip'},
                                                {name: 'Физическое лицо', code: 'fl'},
                                                {name: 'Нерезидент', code: 'nr'}]
                                        },
                                        {
                                            name: 'Признак МСП',
                                            code: 'isMsp',
                                            items: [{name: 'Все', code: 'all'},
                                                {name: 'МСП', code: 'true'}]
                                        },
                                        {
                                            name: 'Регион регистрации',
                                            code: 'regionOKATOCode',
                                            items: $scope.dictionary.fias_region
                                        },
                                        {
                                            name: 'ОКВЭД',
                                            code: 'okvedList',
                                            items: $scope.dictionary.okved2,
                                            multiple: true
                                        },
                                        {
                                            name: 'Искать по всей группе',
                                            code: 'okvedNotExact',
                                            items: [{name: 'Нет', code: 'false'},
                                                {name: 'Да', code: 'true'}]
                                        }
                                    ]
                                };
                                $scope.showPanel = true;
                                if($scope.newsletter.status === 'sent') $scope.model.disabledPanel = true;
                            } else {
                                dialogSrv.showDialogMsg($scope, response.data.message);
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        })

                    }


                }

                function checkDataRight(allRecipients) {
                    var array= [];
                    if($scope.newsletter.recipients){
                        for (var key in $scope.newsletter.recipients) {
                            var item = {};
                            angular.forEach(allRecipients, function(recipient){
                                if(recipient.id === key){
                                    item = recipient
                                }
                            });
                            array.push(item)
                        }
                    }
                    return array;
                }

                $scope.saveData = function (data) {
                    if ($scope.form.mainForm) {
                        $scope.form.mainForm.$setDirty();
                    }
                    $scope.newsletter.recipients = {};
                    angular.forEach(data, function (item) {
                        $scope.newsletter.recipients[item.id] = item.fullName
                    });
                };
                $scope.isEmpty = function(obj) {
                    for(var prop in obj) {
                        if(obj.hasOwnProperty(prop))
                            return false;
                    }
                    return true;
                };
                /**
                 * Вложение
                 */

                $scope.showFilesNews = function (files) {
                    $scope.attachItem.name = files[0].name;
                };
                $scope.onSizeError = function (files) {
                    $scope.alertObjNewsFile = alertSrv.getErrorMsg("Документ " + files[0].name + " не добавлен. Превышен максимально разрешенный размер файла (30МБ)!");
                };
                $scope.onSizeEmpty = function (files) {
                    $scope.alertObjNewsFile = alertSrv.getErrorMsg("Документ " + files[0].name + " не добавлен. Невозможно добавить пустой файл!");
                };
                $scope.onFileNameError = function (files) {
                    $scope.alertObjNewsFile = alertSrv.getErrorMsg("Документ " + files[0].name + " не добавлен. Добавление файлов с расширением *.exe запрещено!");
                };
                
                $scope.uploadFileNewsletter = function (response) {
                    if (!$scope.newsletter.attachments) {
                        $scope.newsletter.attachments = [];
                    }
                    $scope.newsletter.attachments.push({
                        "id": response.data.result.id,
                        "fileType": 0,
                        "name": $scope.attachItem.name,
                        "description": $scope.attachItem.description,
                        "contentLenght": response.data.result.contentLenght
                    });
                    attachReset();
                    $scope.addAttach = '';
                    // if ($scope.newsletter.attachments.contentLenght) {
                    //     console.log($scope.newsletter.attachments.contentLength);
                    // }
                    //ie 11 баг снятие фокуса. Иначе не нажат кнопки
                    window.focus();
                    if (document.activeElement) {
                        document.activeElement.blur();
                    }

                    if ($scope.form.mainForm) {
                        $scope.form.mainForm.$setDirty();
                    }
                };

                $scope.deleteAttach = function (file, object) {
                    newsletterCreateSrv.deleteAttach(file.id).then(function (response) {
                        if (response.data.success) {
                            var index = object.attachments.indexOf(file);
                            if (index > -1) {
                                object.attachments.splice(index, 1);
                            }
                            if ($scope.form.mainForm) {
                                $scope.form.mainForm.$setDirty();
                            }
                        } else {
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    })
                };

                function attachReset() {
                    $scope.attachItem = {
                        name: '',
                        description: ''
                    };
                }

                $scope.addAttachShow = function () {
                    if ($scope.addAttach !== '') {
                        $scope.addAttach = '';
                    } else {
                        $scope.addAttach = 'show';
                    }
                };

                /**
                 * Обновление справочника
                 * @param object - тип справочника
                 * @param value - введенные значения
                 * @param refreshScrolling - обновление данных при прокрутке
                 */
                function refreshDictFn(object, value, refreshScrolling) {
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling)
                }

                function _initDict(dictList, reload) {
                    angular.forEach(dictList, function (name) {
                        dictionarySrv.getDictionaryRemote(name, reload);
                    })
                }

                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType();
                    $scope.newsletter = {};
                    $scope.newsletter.recipients = {};
                    $scope.newsletter.isIndividuallyMessage = false;
                    $scope.newsletter.isSendSelectOrg = false;
                    $scope.form = {mainForm: ''};
                    //$scope.changedForm = false;
                    $scope.newsletterId = null;
                    $scope.addAttach = '';
                    $scope.showPanel = false;
                    $scope.maxCountAttachments = 2;
                    $scope.model = {
                        dataPanel: {},
                        disabledPanel: false,
                        dataFilter: []
                    };

                    $scope.dictSrv = dictionarySrv;
                    $scope.refreshDict = refreshDictFn;
                    $scope.mspCategory = dictionarySrv.getStaticDictByType('mspCategory');
                    _initDict(['fias_region', 'okved2'], false);

                    $scope.dictionary = dictionarySrv.getRemoteData();
                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);

                    $scope.idDocTime = new Date().getTime();
                    attachReset();

                    if ($scope.form && $scope.form.mainForm) $scope.form.mainForm.$setPristine();
                    if ($scope.form && $scope.form.mainForm) $scope.form.mainForm.$setUntouched();
                    $scope.newsletterTypes = [
                        {name: 'Все организаторы и участники', code: 'all'},
                        {name: 'Все организаторы', code: 'organizers'},
                        {name: 'Все участники', code: 'participants'},
                        {name: 'Некоторые организаторы', code: 'someOrganizers'},
                        {name: 'Некоторые участники', code: 'someParticipants'}
                    ];
                    if(this.newsletterData){
                        $scope.newsletter = this.newsletterData.data.result;
                        $scope.newsletterId = $scope.newsletter.id;
                        if($scope.newsletter.code === "someOrganizers" || $scope.newsletter.code === "someParticipants") {
                            getDataPanel($scope.newsletter.code);
                        }
                    }
                }
                function goBackFn(){
                    history.back();
                }
                function cancelFn() {
                    if ($scope.form.mainForm.$dirty) {
                        needConfirmSrv.call(goBackFn, [], 'Внесенные изменения сохранены не будут');
                    } else {
                        goBackFn();
                    }
                }
                function isReadOnlyFn() {
                    return ["support"].indexOf(desktopSrv.getCabType())!=-1;
                }
            }]

    });

