/**
 * @author: sergeyu
 * Организатор. Реквизиты организации
 */
angular.module('uetp')
    .component('reqBgAgent', {
        templateUrl: 'app/components/bgAgent/bgAgent/reqOrganizator/reqOrganizator.html',
        bindings: {
            callback: '='
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'bgAgentSrv', 'authSrv', 'desktopSrv','alertSrv', 'dictionarySrv','needConfirmSrv', 'organizatorSrv',
            function ($scope, $rootScope, $state, $stateParams, bgAgentSrv, authSrv, desktopSrv,alertSrv, dictionarySrv,needConfirmSrv, organizatorSrv) {
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.form = {profileForm: {}};
                    $scope.orgTypes = dictionarySrv.getStaticDictByType('typeOrgUlIp');
                    $scope.model = {regAddress:{oksmCode:'643', oksmName:'РОССИЙСКАЯ ФЕДЕРАЦИЯ'}, realAddress:{oksmCode:'643', oksmName:'РОССИЙСКАЯ ФЕДЕРАЦИЯ'},
                        allowedCommercial:true, defaultCommercial:false, typeOrg:'ul'};
                    $scope.prevRealAddress = {oksmCode:'643', oksmName:'РОССИЙСКАЯ ФЕДЕРАЦИЯ'};
                    $scope.modelView = {isEdit: false};
                    $scope.authData = authSrv;
                    $scope.isReadOnly = isReadOnlyFn;
                    $scope.isChecked = false;
                    if ($stateParams.id) {
                        if ($stateParams.successSave) {
                            $scope.alertObjreqOrganizator = alertSrv.getSuccessMsg('Сохранение произведено успешно!');
                        }
                        $scope.modelView.isEdit = true;
                        $scope.model = bgAgentSrv.getOrganizatorInfo();
                        $scope.isChecked = organizatorSrv.equalsWithExceptions($scope.model.regAddress, $scope.model.realAddress, 'id') || !$scope.model.realAddress || !$scope.model.realAddress.oksmCode;
                    }
                    _initDictionaryBase();

                };

                $scope.createOrgUpdate = createOrgUpdateFn;
                $scope.cancel = cancelFn;
                $scope.refreshDict = refreshDictFn;
                $scope.changeTypeOrg = changeTypeOrgFn;
                /**
                 * Обновление реквизитов
                 */
                function createOrgUpdateFn() {
                    var dataObj = {
                    	id: $scope.model.id,
                    	code: $scope.model.code,
                    	fullName: $scope.model.fullName,
                        shortName : $scope.model.shortName,
                        inn : $scope.model.inn,
                        kpp : $scope.model.kpp,
                        ogrn : $scope.model.ogrn,
                        okpo : $scope.model.okpo,
                        phone : $scope.model.phone,
                        email : $scope.model.email,
                        realAddress : $scope.isChecked?angular.copy($scope.model.regAddress):$scope.model.realAddress,
                        regAddress : $scope.model.regAddress,
                        typeOrg: $scope.model.typeOrg,
                    };

                    bgAgentSrv.createOrUpdate(dataObj).then(function (response) {
                        if (response.data.success) {
                            $scope.form.profileForm.$setPristine();
                            if(['admincab','support'].includes($scope.cabType()))
                                $rootScope.$emit('setBgAgentViewName', dataObj.shortName ? dataObj.shortName : dataObj.name);
                            if (!dataObj.id) {
                            	dataObj.id = response.data.result;
                                $state.go('openBgAgent', {id: dataObj.id, successSave: true},{location :'replace'});
                            }
                            bgAgentSrv.setOrganizatorInfo(dataObj);
                            $scope.alertObjreqOrganizator = alertSrv.getAlertObj(response,'Сохранение произведено успешно!');
                        } else {
                            $scope.alertObjreqOrganizator = alertSrv.getAlertObj(response,'Организатор с указанным кодом уже зарегистрирован!');
                        }
                    })
                }

                /**
                 * Смена типа агента
                 */
                function changeTypeOrgFn() {
                    $scope.model.inn = '';
                    $scope.model.kpp = '';
                }

                function cancelFn() {
                    if($scope.form.profileForm.$dirty){
                        needConfirmSrv.call($state.go,['bgAgents'],'Внесенные изменения сохранены не будут');
                    }else{
                        $state.go('bgAgents');
                    }
                }

                function isReadOnlyFn() {
                    return authSrv.getAuth().userRole.some(r => ["bg_agent_user", 'operator_support_ro'].includes(r));
                }
                /**
                 * Совпадает ли адрес с Юр.адресом
                 * @param checked
                 */
                $scope.checkedAddress = function (checked) {
                    if (!checked) {
                        $scope.prevRealAddress = angular.copy($scope.model.realAddress);
                        $scope.model.realAddress = angular.copy($scope.model.regAddress);
                    } else {
                        $scope.model.realAddress = angular.copy($scope.prevRealAddress);
                    }
                    $scope.form.profileForm.$setDirty();
                };
                function _initDictionaryBase() {
                    $scope.dictionary = dictionarySrv.getRemoteData();
                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                    dictionarySrv.resetPaginatedDict($scope);
                    _initDict(['okcm', 'fias_region'], false);

                    function _initDict(dictList, reload) {
                        angular.forEach(dictList, function (name) {
                            dictionarySrv.getDictionaryRemote(name, reload);
                        })
                    }
                }

                /**
                 * Обновление справочника
                 * @param object - тип справочника
                 * @param value - введенные значения
                 * @param refreshScrolling - обновление данных при прокрутке
                 */
                function refreshDictFn(object, value, refreshScrolling) {
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling)
                }
            }]
    });

