angular.module('uetp').service('protocolSrv', ['$state', 'httpSrv', 'cryptography', 'desktopSrv', 'authSrv', 'propertiesService',
    function ($state, httpSrv, cryptography, desktopSrv, authSrv, propertiesService) {
        var service = this;

        service.getProtNamesByType = getProtNamesByTypeFn;
        
        function _getProtNamesDefault() {
            return {
            	OPEN: 'Протокол вскрытия заявок',
            	PARTS: 'Протокол рассмотрения заявок',
            	PART1: 'Протокол рассмотрения и оценки заявок',
            	PART2: 'Протокол вторых частей заявок',
            	REZ: 'Протокол конкурсной комиссии',
            	DOP: 'Протокол рассмотрения с учетом переторжки (для переторжки в заочной форме)',
            	DOPONLINE: 'Протокол проведения переторжки (для переторжки в режиме реального времени)',
            	REBIDDING_NOTICE: 'Уведомление о переторжке',
            	REBIDDING_OPEN: 'Протокол вскрытия переторжки',
            	REBIDDING_PART1: 'Протокол рассмотрения и оценки заявок с учетом переторжек',
                PURCHASE_REJECTION: 'Уведомление об отказе',
                ADDITIONAL: 'Дополнительный протокол',
                CHANGE_CONTRACT_ADDITIONAL: 'Протокол изменений условий договора'
            } //TODO: а здесь точно нужно?
        }
        function getProtNamesByTypeFn(type, stage) {
            var fields = _getProtNamesDefault();
            if (type === 'requestquotationssmb' || type === 'requestproposalssmb') {
                fields.PART1 = 'Протокол рассмотрения первых частей заявок';
            }
            if (['openauction','limitedauction','saleauction'].includes(type)) {
            	fields.OPEN = 'Протокол вскрытия заявок';
                fields.PARTS = 'Протокол рассмотрения заявок';
            	fields.REZ = 'Протокол проведения аукциона';
            }
            if (type==='openpublicoffer') {
                fields.OPEN = 'Протокол вскрытия заявок';
                fields.PARTS = 'Протокол рассмотрения заявок';
                fields.REZ = 'Протокол проведения открытого публичного предложения';
            }
            if (type === 'requestproposals' || type === 'limitedrequestproposals' || type === 'open2stagecontest') {
            	
            	if(!stage || stage.id === 'stage1'){
            	    if(type === 'requestproposals' || type === 'limitedrequestproposals')
            	        fields.PART1 = 'Протокол запроса предложений';
            	    else if(type === 'open2stagecontest'){
                        fields.PART1 = 'Протокол рассмотрения первоначальных заявок';
                        fields.OPEN ='Протокол вскрытия первоначальных заявок';
                    }
            	}else{
                    if(type === 'open2stagecontest') {
                        fields.OPEN = 'Протокол вскрытия окончательных заявок';
                        fields.PART1 = 'Протокол рассмотрения и оценки окончательных заявок';
                    } else {
                        fields.OPEN = 'Протокол вскрытия окончательных предложений';
                        fields.PART1 = 'Протокол рассмотрения и оценки окончательных предложений';
                    }
            	}
            }
            if (type === 'auctionsmb') {
            	fields.OPEN = 'Протокол рассмотрения заявок';
            }
            if(type === 'prequalification')
                fields.PART1 = 'Протокол рассмотрения заявок';
            if(type === 'unlimitedprequalification')
                fields.PART1 = 'Уведомление об итогах рассмотрения заявок';
            return fields;
        }
        
        /**
         * Получение предзаполненного протокола вскрытия
         */
        this.getDataForCreateOpeningProtocol = function (stateParams) {
            return httpSrv.http({
                method: 'GET',
                url: stateParams.procType + '/' + stateParams.procId + '/getDataForCreateOpeningProtocol'
            });
        };
        /**
         * Получение предзаполненного протокола первых частей заявок
         */
        this.getDataForCreateRatingFirstClaimPartsProtocol = function (stateParams) {
            return httpSrv.http({
                method: 'GET',
                url: stateParams.procType + '/' + stateParams.procId + '/getDataForCreateRatingFirstClaimPartsProtocol'
            });
        };
        this.getDataForCreateRebiddingRatingFirstClaimPartsProtocol = function (stateParams) {
            return httpSrv.http({
                method: 'GET',
                url: stateParams.procType + '/' + stateParams.procId + '/getDataForCreateRebiddingRatingFirstClaimPartsProtocol'
            });
        };
        /**
         * Получение предзаполненного протокола вторых частей заявок
         */
        this.getDataForCreateRatingSecondClaimPartsProtocol = function (stateParams) {
            return httpSrv.http({
                method: 'GET',
                url: stateParams.procType + '/' + stateParams.procId + '/getDataForCreateRatingSecondClaimPartsProtocol'
            });
        };
        this.getDataForCreateRebiddingRatingSecondClaimPartsProtocol = function (stateParams) {
            return httpSrv.http({
                method: 'GET',
                url: stateParams.procType + '/' + stateParams.procId + '/getDataForCreateRebiddingRatingSecondClaimPartsProtocol'
            });
        };
        /**
         * Получение предзаполненного протокола подведения итогов
         */
        this.getDataForCreateSummarizingProtocol = function (stateParams) {
            return httpSrv.http({
                method: 'GET',
                url: stateParams.procType + '/' + stateParams.procId + '/getDataForCreateSummarizingProtocol'
            });
        };
        /**
         * Получение предзаполненного протокола изменения условий договора
         */
        this.getDataForCreateChangeContractAdditionalProtocol = function (stateParams) {
            return httpSrv.http({
                method: 'GET',
                url: stateParams.procType + '/' + stateParams.procId + '/getDataForCreateChangeContractAdditionalProtocol'
            });
        };
        /**
         * Получение предзаполненного протокола отмены закупки
         */
        this.getDataForPurchaseRejection = function (stateParams) {
            return httpSrv.http({
                method: 'GET',
                url: stateParams.procType + '/' + stateParams.procId + '/getDataForPurchaseRejection'
            });
        };
        /**
         * Получение предзаполненного протокола отмены лотов закупки
         */
        this.getDataForCancelLotsProtocol = function (stateParams) {
            return httpSrv.http({
                method: 'GET',
                url: stateParams.procType + '/' + stateParams.procId + '/getDataForCancelLotsProtocol'
            });
        };
        /**
         * Получение предзаполненного дополнительного протокола
         */
        this.getDataForAdditionalProtocol = function (stateParams) {
            return httpSrv.http({
                method: 'GET',
                url: stateParams.procType + '/' + stateParams.procId + '/getDataForAdditionalProtocol'
            });
        };
        /**
         * Получение предзаполненного протокола о переносе торгов
         */
        this.getDataForCreateNoticePostponementProtocol = function (stateParams) {
            return httpSrv.http({
                method: 'GET',
                url: stateParams.procType + '/' + stateParams.procId + '/getDataForCreateNoticePostponementProtocol'
            });
        };
		/**
         * Получение предзаполненного протокола "Уведомление о переносе сроков"(protocolTransfer),
         *   "Уведомление о поступлении жалобы"(protocolComplaint) или "Уведомления"(protocolCustom)
         */
        this.getDataForNotificationProtocol = function (stateParams) {
            return httpSrv.http({
                method: 'GET',
                url: `${stateParams.procType}/${stateParams.procId}/getDataForNotificationProtocol/${stateParams.procType==='requestprices' ? 'CUSTOM' : 'COMPLAINT'}`
            });
        };
        
        /**
         * Получение предзаполненного протокола, когда делаем изменение уже опубликованного
         */
        this.getDataForEditPublishedProtocol = function (stateParams) {
            return httpSrv.http({
                method: 'GET',
                url: stateParams.procType + '/' + stateParams.procId + '/protocols/' + stateParams.protocolId + '/getDataForEditPublishedProtocol'
            });
        };
        /**
         * Получение предзаполненного уведомления о переторжке
         */
        this.getDataForCreateRebiddingNotice = function (stateParams) {

            return httpSrv.http({
                method: 'GET',
                url: stateParams.procType + '/' + stateParams.procId + '/lots/' + stateParams.lotId +  '/getDataForCreateRebiddingNotice'
            });
        };
        this.getDataForCreateRebiddingOpeningProtocol = function (stateParams) {
            return httpSrv.http({
                method: 'GET',
                url: stateParams.procType + '/' + stateParams.procId + '/lots/' + stateParams.lotId + '/getDataForCreateRebiddingOpeningProtocol'
            });
        };
        this.getDataForReturnClaimsProtocol = function (stateParams) {
            return httpSrv.http({
                method: 'GET',
                url: stateParams.procType + '/' + stateParams.procId + '/getDataForReturnClaimsProtocol'
            });
        };
        /**
         * Сохранить протокол нового протокола
         */
        this.saveProtocolFn = function (stateParams, data, type) {
            var typeUrl = service.getUrlTypeProtocol(type, data),
                url = '';
            if (typeUrl === 'rebidding' || typeUrl === 'rebiddingOpening') {
                url = stateParams.procType + '/' + stateParams.procId + '/lots/' + stateParams.lotId +  '/protocols/' + typeUrl
            } else {
                url = stateParams.procType + '/' + stateParams.procId + '/protocols/' + typeUrl
            }
            return httpSrv.http({
                url: url,
                method: 'POST',
                data: data
            })
        };

        /**
         * Сохранить изменения опубликованного протокола
         */
        this.saveProtocolPublicFn = function (stateParams, data) {
            return httpSrv.http({
                url: stateParams.procType + '/' + stateParams.procId + '/protocols/' + stateParams.protocolId + '/newVersion',
                method: 'POST',
                data: data
            })
        };
        /**
         * Сохранить протокол нового протокола
         */
        this.updateProtocolFn = function (stateParams, data) {
            return httpSrv.http({
                url: stateParams.procType + '/' + stateParams.procId + '/protocols/' + stateParams.protocolId,
                method: 'PUT',
                data: data
            })
        };

        /**
         * Сохранить изменения опубликованного протокола
         */
        this.changeStateProtocolFn = function (stateParams, state) {
            return httpSrv.http({
                url: stateParams.procType + '/' + stateParams.procId + '/protocols/' + stateParams.protocolId + '/changeState/' + state,
                method: 'GET'
            })
        };
        /**
         * Получение данных для подписи
         */
        this.getDataForSign = function (stateParams) {
            return httpSrv.http({
                url: stateParams.procType + '/' + stateParams.procId + '/protocols/' + stateParams.protocolId + '/getDataForSign'
            })
        };
        /**
         * Получение данных для подписи протокола и извещения для уведомления о переносе сроков торгов
         */
        this.getDataForSignProtocolAndNotice = function (stateParams) {
            return httpSrv.http({
                url: stateParams.procType + '/' + stateParams.procId + '/protocols/' + stateParams.protocolId + '/notices/'+ stateParams.noticeId + '/getDataForSign'
            })
        };
        /**
         * Отправка документа
         */
        this.sendSignData = function (stateParams, data) {
            return httpSrv.http({
                url: stateParams.procType + '/' + stateParams.procId + '/protocols/' + stateParams.protocolId + '/publish',
                data: data
            })
        };
        /**
         * Отправка протокола и извещения для уведомления о переносе сроков торгов
         */
        this.sendSignDataProtocolAndNotice = function (stateParams, data) {
            return httpSrv.http({
                url: stateParams.procType + '/' + stateParams.procId + '/protocols/' + stateParams.protocolId + '/notices/'+ stateParams.noticeId + '/publish',
                data: data
            })
        };
        this.signAndSend = function (stateParams, protocolData, certId) {
            return service.getDataForSign(stateParams).then(function (response) {
                var model = angular.copy(protocolData);
                if (response.data.success) {
                    if(authSrv.getAuth().withoutCertEnabled)
                        return service.sendSignData(stateParams, model);
                    else {
                        return cryptography.generateSignature(response.data.result, certId).then(function (value) {
                            model.signature = value;
                            return service.sendSignData(stateParams, model);
                        }, function (reason) {
                            if (typeof reason === 'string') {
                                return Promise.reject("Ошибка подписи документа. " + reason);
                            } else {
                                return Promise.reject("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                            }
                        })
                    }
                } else {
                    return Promise.resolve(response);
                }
            }, function (reason) {
                return Promise.resolve(reason);
            })
        };

        this.signAndSendProtocolAndNotice = function (stateParams, certId) {
            return service.getDataForSignProtocolAndNotice(stateParams).then(function (response) {
                if (response.data.success) {
                    let model = angular.copy(response.data.result);
                    if (authSrv.getAuth().withoutCertEnabled) {
                        return service.sendSignDataProtocolAndNotice(stateParams, model);
                    } else
                        return cryptography.generateSignature(model.protocol.signature, certId).then(function (value) {
                            model.protocol.signature = value;
                            return cryptography.generateSignature(model.notice.signature, certId).then(function (value) {
                                model.notice.signature = value;
                                return service.sendSignDataProtocolAndNotice(stateParams, model);
                            }, function (reason) {
                                if (typeof reason === 'string') {
                                    return Promise.reject("Ошибка подписи документа. " + reason);
                                } else {
                                    return Promise.reject("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                                }
                            })
                        }, function (reason) {
                            if (typeof reason === 'string') {
                                return Promise.reject("Ошибка подписи документа. " + reason);
                            } else {
                                return Promise.reject("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                            }
                        })
                } else {
                    return Promise.resolve(response);
                }
            }, function (reason) {
                return Promise.resolve(reason);
            })
        };
        this.signAndSendFromTable = function (stateParams, certId) {
            return service.getProtocolById(stateParams).then(function (response) {
                if (response.data.success) {
                    if(['saleauction','limitedauction','openauction','openpublicoffer'].includes(stateParams.procType) && response.data.result.type==="NOTICE_POSTPONEMENT") {
                        stateParams.protocolId = response.data.result.id;
                        service.getNoticeByProtocolId(stateParams).then(function (response) {
                            if (response.data.success) {
                                stateParams.noticeId = response.data.result.id; // id notice
                                return service.signAndSendProtocolAndNotice(stateParams, certId);
                            }
                        });
                    }
                    else
                        return service.signAndSend(stateParams, response.data.result, certId);
                }
            })
        };
        /**
         * Получение изменений извещения по id протокола
         */
        this.getNoticeByProtocolId = function (stateParams) {
            return httpSrv.http({
                url: stateParams.procType + '/' + stateParams.protocolId + '/getLastNoticeByProtocolId',
                method: 'GET'
            })
        };
        this.downloadByType = function (type, stateParams, data, protocolType, document) {
            var url = '';
            if (type === 'archive') {
            	if(stateParams.procType==='opencontest' || stateParams.procType==='requestquotations' || stateParams.procType==='limitedrequestquotations' || stateParams.procType==='limitedcontest' || stateParams.procType==='requestproposals'){
            	  url = 'fs/file/' + stateParams.procType + '/' + stateParams.procId + '/protocols/' + stateParams.protocolId + '/lots/' + data.lotInfo.id + '/stage/' +
                       (document.stage!==null ? document.stage :'stage1') + '/rebiddingNumber/' + (document.rebiddingNumber ? document.rebiddingNumber : 0) + '/archive';
            	}else{
                  url = 'fs/file/' + stateParams.procType + '/' + stateParams.procId + '/protocols/' + stateParams.protocolId + '/lots/' + data.lotInfo.id + '/stage/' +
                       (document.stage!==null ? document.stage :'stage1') + '/archive';
            	}
            } else if(type === 'excel') {
                if(['limitedauction','saleauction','openauction','openpublicoffer'].includes(stateParams.procType) && protocolType === 'REZ'){
                    url = stateParams.procType + '/' + stateParams.procId + '/protocols/' + stateParams.protocolId + '/lots/' + data.lotInfo.ordinalNumber + '/export/xlsx/openAuctionResultProtocol';
                } else {
                    url = stateParams.procType + '/' + stateParams.procId + '/protocols/' + stateParams.protocolId + '/lots/' + data.lotInfo.ordinalNumber + '/export/xlsx/' +
                        ((protocolType === 'OPEN' || protocolType === 'PARTS') ? 'openProtocol' : (protocolType === 'PART1' ? 'partOneProtocol' : (protocolType === 'PART2' ? 'partTwoProtocol' : (protocolType === 'REZ' ? 'resultProtocol' :
                            (protocolType === 'REBIDDING_NOTICE' ? 'rebiddingNoticeProtocol' : (protocolType === 'REBIDDING_OPEN' ? 'rebiddingOpeningProtocol' : (protocolType === 'REBIDDING_PART1' ? 'rebiddingPart1Protocol' : '')))))))
                }
            }
            httpSrv.downloadFile(url);

        };
        this.downloadByParticipant = function (type, stateParams, data, claimNumber, document, rebiddingNumber) {
            let url = '';
            if (type === 'archive') {
                if(stateParams.procType==='opencontest' || stateParams.procType==='requestquotations' || stateParams.procType==='limitedrequestquotations' || stateParams.procType==='limitedcontest' || stateParams.procType==='requestproposals'){
                    url = 'fs/file/' +  stateParams.procType + '/' + stateParams.procId + '/protocols/' + stateParams.protocolId + '/lots/' + data.lotInfo.id + '/claims/' + claimNumber +
                    '/stage/' + (document.stage!==null ? document.stage :'stage1') + '/rebiddingNumber/' + (rebiddingNumber ? rebiddingNumber : 0) + '/archive';
                }else{
                    url = 'fs/file/' + stateParams.procType + '/' + stateParams.procId + '/protocols/' + stateParams.protocolId + '/lots/' + data.lotInfo.id + '/claims/' + claimNumber +
                    '/stage/' + (document.stage!==null ? document.stage :'stage1') + '/archive';
                }
                httpSrv.downloadFile(url);
            } else if(type==='mchd') {
                propertiesService.getProperty("mchd.url").then((host) => {
                    if(!host.endsWith('/')) host += '/';
                    httpSrv.downloadFile(`${host}rest/mchd/api/v1/attorneys/download/${data}`, `POA_${stateParams}.zip`);
                });
            }
        };
        /**
         * Получение протокола
         */
        this.getProtocolById = function (stateParams) {
            return httpSrv.http({
                url: stateParams.procType + '/' + stateParams.procId + '/protocols/' + stateParams.protocolId,
                method: 'GET'
            })
        };
        /**
         * Удаление протокола
         */
        this.deleteProtocolById = function (stateParams) {
            return httpSrv.http({
                url: stateParams.procType + '/' + stateParams.procId + '/protocols/' + stateParams.protocolId,
                method: 'DELETE'
            })
        };
        /**
         * Повторно отправить в ЕИС
         */
        this.retryToEIS = function (stateParams) {
            return httpSrv.http({
            	url: '/organizer/procedures/retryToEIS/' + stateParams.protocolId,
                method: 'GET'
            })
        };
        /**
         * Экспорт протокола в Excel
         */
        this.exportProtocolById = function (procType,procId,protocolType,protocolId) {
            var urlPath = '';
            if(['openauction','saleauction','limitedauction','openpublicoffer'].includes(procType) && protocolType === 'REZ'){
                urlPath = procType + '/' + procId + '/protocols/' + protocolId + '/lots/all/export/xlsx/openAuctionResultProtocol';
            } else {
                urlPath = procType + '/' + procId + '/protocols/' + protocolId + '/lots/all/export/xlsx/' +
                    ((protocolType === 'OPEN' || protocolType === 'PARTS') ? 'openProtocol' : (protocolType === 'PART1' ? 'partOneProtocol' : (protocolType === 'PART2' ? 'partTwoProtocol' : (protocolType === 'REZ' ? 'resultProtocol' :
                            (protocolType === 'REBIDDING_NOTICE' ? 'rebiddingNoticeProtocol' : (protocolType === 'REBIDDING_OPEN' ? 'rebiddingOpeningProtocol' : (protocolType === 'REBIDDING_PART1' ? 'rebiddingPart1Protocol' : '')))
                    ))));
            }
            httpSrv.downloadFile(urlPath);
        };

        /**
         * Создание архива вложений из заявок по всем лотам
         */
        this.downloadProtocolById = function (stateParams, document) {
        	 var url = '';
        	if(stateParams.procType==='opencontest' || stateParams.procType==='requestquotations' || stateParams.procType==='limitedrequestquotations' || stateParams.procType==='limitedcontest' || stateParams.procType==='requestproposals'){
            	url = 'fs/file/' + stateParams.procType + '/' + stateParams.procId + '/protocols/' + stateParams.protocolId + '/stage/' +
                  (document.stage!==null ? document.stage :'stage1') + '/rebiddingNumber/' + (document.rebiddingNumber ? document.rebiddingNumber : 0) + '/archivealllots';
        	}else{
            	url = 'fs/file/' + stateParams.procType + '/' + stateParams.procId + '/protocols/' + stateParams.protocolId + '/stage/' +
               (document.stage!==null ? document.stage :'stage1') +'/archivealllots';
         	}
        	httpSrv.downloadFile(url);
        };

       this.downloadTassParticipants = function (stateParams, file) {
	       var url = 'fs/file/' + stateParams.procType + '/' + stateParams.procId + '/files/' + file.id;
           httpSrv.downloadFile(url);
       };
       
       this.createTassParticipants = function (stateParams, isAuto, inn) {
    	   var url = stateParams.procType + '/' + (isAuto ? 'autoCreateTassParticipants' : 'createTassParticipants' ) + '/' + stateParams.procId + (inn ? '/' + inn : '');
           return httpSrv.http({
               url: url,
               method: 'GET'
           })
       };
       
       this.getUrlTypeProtocol = function (type, data) {
            if (type === 'PART1') {
                return 'ratingFirstClaimParts'
            } else if (type === 'PART2') {
                return 'ratingSecondClaimParts'
            } else if (type === 'REZ') {
                return 'summarizing'
            } else if (type === 'OPEN'||type==='PARTS') {
                return 'opening'
            } else if (type === 'REBIDDING_NOTICE') {
                return 'rebidding'
            } else if (type === 'REBIDDING_OPEN') {
                return 'rebiddingOpening'
            } else if (type === 'REBIDDING_PART1') {
                if(data.document.stage==null)
                    return 'rebiddingRatingFirstClaimParts';
                else if(data.document.stage==='stage2')
                    return 'rebiddingRatingSecondClaimParts'
            } else if (type === 'PURCHASE_REJECTION') {
                return 'purchaseRejection'
            } else if (type === 'NOTICE_POSTPONEMENT') {
                return 'noticePostponementAndNotice'
            } else if (type === 'TRANSFER') {
                return 'notification/TRANSFER'
            } else if (type === 'COMPLAINT') {
                return 'notification/COMPLAINT'
            } else if (type === 'CUSTOM') {
                return 'notification/CUSTOM'
            } else if (type === 'PURCHASE_LOT_CANCELLATION') {
                return 'cancelLots'
            } else if (type === 'ADDITIONAL') {
                return 'additionalProtocol'
            } else if (type === 'RETURN_CLAIMS') {
                return 'returnClaims'
            } else if (type === 'CHANGE_CONTRACT_ADDITIONAL') {
                return 'changeContract'
            }
        };

        this.getTemplateProtocol = function (procType, type) {
            let template = '';
            // пока отдельный вывод протокола для аукционов
            if (type === 'CHANGE_CONTRACT_ADDITIONAL') {
                template = 'app/components/protocol/msp223/protocolADDITIONAL.html';
            } else if (['openauction','limitedauction','saleauction','auctionsmb','openpublicoffer'].includes(procType)) {
                template = 'app/components/protocol/protocolAuction.html';
            // отдельный вывод правильных протоколов по 223
            } else if (procType === 'requestquotationssmb' || procType === 'requestproposalssmb' ) {
                template = 'app/components/protocol/msp223/protocol' + type + '.html';
            // отдельный вывод правильных протоколов по переторжке. Может потом все объединим
            } else if (type === 'REBIDDING_NOTICE') {
                template = 'app/components/protocol/msp223/protocol' + type + '.html';
            } else if (['TRANSFER', 'COMPLAINT', 'CUSTOM'].includes(type)) {
                template = 'app/components/procedures/protocol/notificationProtocol.html';
            } else if (['ADDITIONAL'].includes(type)) {
                template = 'app/components/procedures/protocol/additional/additionalProtocol.html';
            // пока отдельный вывод протокола для остальных закупок
            } else {
                template = 'app/components/protocol/protocolAll.html';
            }
            return template
        };
        /**
         * Несостоявшаяся закупка
         */
        this.cancelProcedure = function (protocol) {
            let result = true;
            if (protocol.document.lots?.protocolLotApplication?.protocolLotApplications) {
                angular.forEach(protocol.document.lots.protocolLotApplication.protocolLotApplications, function (lot) {
                    if (lot.application?.length > 0) {
                        result = false;
                    }
                });
            }
            return result;

        };
        /**
         * Несостоявшаяся закупка с учетом допуска
         */
        this.cancelProcedureOnAccepted = function (protocol) {
            let result = true;
            if (protocol.document.lots?.protocolLotApplication?.protocolLotApplications) {
                angular.forEach(protocol.document.lots.protocolLotApplication.protocolLotApplications, function (lot) {
                	if (lot.application?.length > 0 && result) {
                		var countAccepted = 0;
                        angular.forEach(lot.application, function (application) {
                            if (application.accepted.code === 'T') {
                            	countAccepted++;
                            }
                        });
                        if(countAccepted>=2){
                           result = false;
                        }
                    }
                });
            }
            return result;

        };

        this.isCancelProc=function(typeProtocol, procType,protocol){
            if(typeProtocol ==='REZ'&&['openauction','saleauction','limitedauction','openpublicoffer'].includes(procType)&&service.cancelProcedure(protocol))
                return true;
            return (typeProtocol ==='OPEN' || typeProtocol ==='PARTS')
            ? (['openpublicoffer','openauction','saleauction','limitedauction'].includes(procType) ? service.cancelProcedureOnAccepted(protocol):service.cancelProcedure(protocol))
            : false;
        }

    }]);
