angular.module('uetp').service('cartSrv', ['httpSrv', '$rootScope', '$q',
    function (httpSrv, $rootScope, $q) {
        var cart,
            cartArray = [],
            service = this;

        service.count = {
            amount: 0
        };
        this.countPositions = countPositionsFn;
        this.loadCart = loadCartFn;
        this.isInCart = isInCartFn;
        this.addToCartPosition = addToCartPositionFn;
        this.addToCartPositionCount = addToCartPositionCountFn;
        this.deleteCartPosition = deleteCartPositionFn;
        this.cleanCartPositions = cleanCartPositionsFn;
        this.cleanUpdateCartPositions = cleanUpdateCartPositionsFn;

        function deleteCartPositionFn(id) {
            return httpSrv.http({
                method: 'DELETE',
                url: '/orders/api/v1/cart/' + id
            });
        }

        function cleanCartPositionsFn(cleanListIds) {
            return httpSrv.http({
                method: 'POST',
                url: '/orders/api/v1/cart/cleanList',
                data: cleanListIds
            });
        }

        function cleanUpdateCartPositionsFn(cleanListIds, updateListIds) {
            return httpSrv.http({
                method: 'POST',
                url: '/orders/api/v1/cart/cleanUpdateList',
                data: {
                    "deleteIds": cleanListIds,
                    "updateIds": updateListIds
                }
            });
        }

        async function countPositionsFn() {
            let response;
            try {
                response = await httpSrv.http({
                    method: 'GET',
                    url: '/orders/api/v1/cart/count'
                })
            } catch (response) {
                console.warn(response);
            }

            if (response) {
                if (!Number.isNaN(parseInt(response.data))) service.count.amount = response.data;
                else console.error('Incorrect response: "/orders/api/v1/cart/count"', response);
            }
        }

        function loadCartFn() {
            return httpSrv.http({
                method: 'GET',
                url: '/orders/api/v1/cart'
            }).then(function successCallback(response) {
                if (response) {
                    if (typeof response.data === 'object') {
                        cart = response.data;
                        cartArray = [];
                        for (var i = 0; i < cart.length; i++) {
                            if (cart[i].cartsOrganization) for (var j = 0; j < cart[i].cartsOrganization.length; j++) {
                                cart[i].cartsOrganization[j].checkbox = true;
                                cartArray.push(cart[i].cartsOrganization[j].parent.id);
                            } else console.warn('cartsOrganization is undefined');
                        }
                    } else console.error('Incorrect response: "/orders/api/v1/cart"', response);
                }

                return response;
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function isInCartFn(id) {
            return cartArray.length > 0 && cartArray.includes(id);
        }

        function addToCartPositionFn(id) {
            return httpSrv.http({
                method: 'PUT',
                url: '/orders/api/v1/cart/' + id + '/1'
            }).then(function successCallback() {
                countPositionsFn();
                loadCartFn();
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function addToCartPositionCountFn(id, count) {
            var posInfo = $q.defer();
            return httpSrv.http({
                method: 'PUT',
                url: '/orders/api/v1/cart/' + id + '/' + count
            }).then(function successCallback(response) {
                countPositionsFn();
                loadCartFn();
                posInfo.resolve(response);
            }, function errorCallback(response) {
                console.log(response);
                posInfo.reject(response);
            });
        }
    }])
;
