angular.module('uetp').constant('paymentOrdersReport', {
    title    : 'Реестр платежных поручений',
    access   : ['admincab', 'accountant'],
    url      : '/auth/tariff/downloadPaymentOrdersXls',
    template : 'app/components/reports/types/paymentOrdersReport.html',
    data     : {
        endDate   : null,
        startDate : null,
        accountType : {
            id: 'atcom',
            param: "Атком"
        }
    },
    toMSK    : ['startDate', 'endDate'],
    minMaxOpt: {
        date: {
            minRequired: true,
            maxRequired: true
        }
    }
});