/**
 * @author dmitrys
 */
angular.module('uetp')
    .component('nomenclature', {
        templateUrl: 'app/components/notice/nomenclature/nomenclature.html',
        controller: ['$scope', '$rootScope', '$state','$stateParams','noticeSrv','dictionarySrv','dataSrv','dialogSrv','authSrv','desktopSrv','alertSrv',
            function ($scope, $rootScope, $state,$stateParams,noticeSrv,dictionarySrv,dataSrv,dialogSrv,authSrv,desktopSrv,alertSrv) {

                $scope.goBack = goBackFn;
                $scope.save = saveFn;
                $scope.refreshDict = refreshDictFn;
                $scope.autoFillSkmtr = autoFillSkmtrFn;
                $scope.autoFillByTypeDict = autoFillByTypeDictFn;
                $scope.getMethodPriceSet = getMethodPriceSetFn;
                $scope.getCurrency = getCurrencyFn;
                $scope.getPriceForItem = getPriceForItemFn;
                $scope.checkOnProcType = checkOnProcTypeFn;
                $scope.getActivePanel = noticeSrv.getShowPanel;
                $scope.hasPriceInNotice = noticeSrv.hasPriceInNotice;
                $scope.model = {};
                $scope.submitted = false;
                $scope.showDeliveryPlaceBlock = true;
                $scope.isNew223 = noticeSrv.isNew223;
                $scope.isNew44 = noticeSrv.isNew44;
                $scope.isNewCommercial = noticeSrv.isNewCommercial;
                $scope.changePrice = changePriceFn;
                $scope.isNoticeIntegrationEIS = noticeSrv.isNoticeIntegrationEIS;
                $scope.nonCompetitive = false;

                $scope.dictionary = dictionarySrv.getRemoteData();
                this.$onInit = function () {
                    $scope.nomenclature = {};
                    $scope.isReadOnly = noticeSrv.getReadOnlyForm();
                    /**
                     * Получение справочников
                     */
                    _initDictionaryBase();
                };
                function _initDictionaryBase() {
                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                    dictionarySrv.resetPaginatedDict($scope);
                }
                /**
                 * Получение данных для изменения или создания
                 */
                $scope.$on('uetp.notice',function(event,data){
                    _initDictionaryBase(); //UETP-4112
                    if(data.dataType==='nomenclature' && data.type==='createOrEdit') {
                        $scope.model = data.data;
                        if($scope.isNew223() || $scope.isNewCommercial()) {
                            $scope.nonCompetitive = data.nonCompetitive ? data.nonCompetitive : false;
                            $scope.model.noQty = data.isNewPosition ? !data.isNewPosition : !($scope.model.qty && $scope.model.okei);
                        }
                        initModel();
                        window.scrollTo(0, 0);
                        if(data.formVerifying) $scope.focusFirstInvalid($scope.nomenclature.form)
                        //initDict();
                    }
                });

                /**
                 * Назад в лот
                 */
                function goBackFn() {
                    if ($scope.isReadOnly) {
                        noticeSrv.setState('lot')
                    } else {
                        dialogSrv.showDialogPrompt($scope, "Возможно, внесенные изменения не сохранятся.",
                            {text: "Ок", action:   noticeSrv.setState, param:'lot'}
                        );
                    }

                }

                /**
                 * Сохранить лот
                 */
                function saveFn() {
                    if(noticeSrv.isNewCommercial() && $scope.priceForItemProc()) {
                        clearPriceValidity();
                    }

                    if($scope.nomenclature.form.$invalid){
                        $scope.alertObjSaveNomer = alertSrv.getErrorMsgWithBtn('Необходимо заполнить обязательные поля на форме. ','Перейти', $scope.focusFirstInvalid,$scope.nomenclature.form);
                        $scope.submitted = true;
                        return;
                    }
                    if($scope.model.okei){
                        if($scope.model.okei.code && $scope.model.okei.fullname){
                            const data = $scope.model.okei;
                            $scope.model.okei = {};
                            $scope.model.okei.code = data.code;
                            $scope.model.okei.name = data.fullname;
                        }
                    }
                    if($scope.model.okved2){
                        if($scope.model.okved2.id && $scope.model.okved2.description){
                            const data = $scope.model.okved2;
                            $scope.model.okved2 = {};
                            $scope.model.okved2.code = data.id;
                            $scope.model.okved2.name = data.description;
                        }
                    }
                    if($scope.model.okpd2){
                        if($scope.model.okpd2.code && $scope.model.okpd2.name){
                            const data = $scope.model.okpd2;
                            $scope.model.okpd2 = {};
                            $scope.model.okpd2.code = data.code;
                            $scope.model.okpd2.name = data.name;
                        }
                    }
                    if ($scope.model.commodityItemPriceWithNDS && !validateSumFn('commodityItemPriceWithNDS', 'commodityItemPriceNoNDS')) {
                        $scope.alertObjSaveNomer = alertSrv.getErrorMsgWithBtn('Предложенная цена единицы товара без НДС не должна превышать предложенную цену единицы товара с НДС. ', 'Перейти', $scope.clickRow, $scope.model.commodityItemPriceWithNDS);
                        return;
                    } else if ($scope.model.commodityItemPriceRubWithNDS && !validateSumFn('commodityItemPriceRubWithNDS', 'commodityItemPriceRubNoNDS')) {
                        $scope.alertObjSaveNomer = alertSrv.getErrorMsgWithBtn('Предложенная цена единицы товара без НДС не должна превышать предложенную цену единицы товара с НДС. ', 'Перейти', $scope.clickRow, $scope.model.commodityItemPriceRubWithNDS);
                        return;
                    }
                    if(($scope.isNew223() || $scope.isNewCommercial()) && !$scope.isNew223() && $scope.isPrequalification()) {
                        if($scope.getPriceForItem() && !$scope.isShowPriceForItem()) {
                            $scope.model.okei = {};
                            delete $scope.model.qty;
                        }
                    }

                    if($scope.isNew223() && $scope.model.noQty) {
                        $scope.model.okei = {};
                        delete $scope.model.qty;
                    }
                    delete $scope.model.noQty;

                    $scope.$parent.$parent.$broadcast('uetp.notice',{data:$scope.model,dataType:'nomenclature',type:'update'});
                    noticeSrv.setState('lot');
                }
                
                $scope.isSimpleContest = function(){
                    return $stateParams.type === 'simplecontest';
                };

                function validateSumFn(name, name2){
                    return ($scope.model[name+'_error']===null || $scope.model[name+'_error']===undefined) &&
                        ($scope.model[name2+'_error']===null || $scope.model[name2+'_error']===undefined);
                }

                function clearPriceValidity() {
                    $scope.nomenclature.form['commodityItemPriceNoNDS'].$error = {};
                    $scope.nomenclature.form['commodityItemPriceWithNDS'].$error = {};
                    $scope.nomenclature.form['commodityItemPriceNoNDS'].$setValidity('commodityItemPriceNoNDS', true);
                    $scope.nomenclature.form['commodityItemPriceWithNDS'].$setValidity('commodityItemPriceWithNDS', true);
                    if($scope.nomenclature.form.$error.parse) {
                        $scope.nomenclature.form.$error.parse = $scope.nomenclature.form.$error?.parse?.filter(errEl => !['commodityItemPriceNoNDS', 'commodityItemPriceWithNDS', undefined].includes(errEl.$name));
                    }
                    if($scope.nomenclature.form.$error?.parse?.length === 0) {
                        delete $scope.nomenclature.form.$error.parse;
                    }
                    $scope.nomenclature.form.$setPristine();
                }

                function changePriceFn(ndsName, noNdsName) {

                    const model = $scope.model;
                    let ndsValue = model[ndsName] ? model[ndsName] : undefined,
                        noNdsValue = model[noNdsName] ? model[noNdsName] : undefined,
                        isEmptyOrNull = false;

                    if(noticeSrv.isNewCommercial() && $scope.priceForItemProc()) {
                        clearPriceValidity();

                        if (isNaN(ndsValue) || ndsValue === 0) {
                            model[ndsName] = null;
                        }
                        if (isNaN(noNdsValue) || noNdsValue === 0) {
                            model[noNdsName] = null;
                        }

                        ndsValue = model[ndsName] ? model[ndsName] : undefined;
                        noNdsValue = model[noNdsName] ? model[noNdsName] : undefined;

                        if (!isNaN(ndsValue) && !isNaN(noNdsValue)) {
                            if (model[noNdsName] * 1 > model[ndsName] * 1) {
                                model[ndsName + '_error'] = 'Предложенная цена единицы товара без НДС не должна превышать предложенную цену единицы товара с НДС.'
                            } else
                                model[ndsName + '_error'] = null;
                            model[noNdsName + '_error'] = null;
                        } else {
                            model[ndsName + '_error'] = null;
                            model[noNdsName + '_error'] = null;
                        }

                        return true;
                    }
                    if($scope.isSimpleContest44())
                        $scope.model.commodityItemPriceNoNDS = $scope.model.commodityItemPriceWithNDS;

                    if (isNaN(ndsValue)) {
                        model[ndsName] = null;
                        model[ndsName + '_error'] = 'Поле не заполнено.';
                        isEmptyOrNull = true;
                    } else if (ndsValue === 0) {
                        model[ndsName + '_error'] = 'Цена должна быть больше 0.';
                        isEmptyOrNull = true;
                    } else {
                        model[ndsName + '_error'] = null;
                    }
                    if (isNaN(noNdsValue)) {
                        model[noNdsName] = null;
                        model[noNdsName + '_error'] = 'Поле не заполнено.';
                        isEmptyOrNull = true;
                    } else if (noNdsValue === 0) {
                        model[noNdsName + '_error'] = 'Цена должна быть больше 0.';
                        isEmptyOrNull = true;
                    } else {
                        model[noNdsName + '_error'] = null;
                    }

                    if (!isEmptyOrNull) {
                        if (model[noNdsName] * 1 > model[ndsName] * 1) {
                            model[ndsName + '_error'] = 'Предложенная цена единицы товара без НДС не должна превышать предложенную цену единицы товара с НДС.'
                        } else
                            model[ndsName + '_error'] = null;
                        model[noNdsName + '_error'] = null;
                    }
                }

                /**
                 * Обновить словарь
                 */
                function refreshDictFn(object, value, refreshScrolling) {
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling);
                }

                /**
                 * Автозаполнение модели из  SKMTR
                 * @param object
                 * @param select
                 */
                function autoFillSkmtrFn(object, select) {
                    dictionarySrv.autoFillSkmtr($scope, object, select);
                }
                /**
                 * Авто заполнение полей
                 * @param model - модель
                 * @param object - объект в модели
                 * @param select - выбранное значение
                 */
                function autoFillByTypeDictFn(model,object, select){
                    dictionarySrv.autoFillByTypeDict($scope,model, object, select);
                }
                function getMethodPriceSetFn(){
                    return $scope.model.deff__methodPriceSet;
                }
                function getCurrencyFn(){
                    return $scope.model.deff__currency ? (""+$scope.model.deff__currency.digitalCode) : "";
                }
                function getPriceForItemFn(){
                    return $scope.model.deff__priceForItem;
                }
                function checkOnProcTypeFn() {
                    return $stateParams.type !== 'unlimitedprequalification' && $stateParams.type !== 'prequalification';
                }
                function initModel() {
                    if($scope.model.skmtr) {
                        if ($scope.model.skmtr.code) {
                            var param = {};
                            param.searchByField = {
                                skmtr: $scope.model.skmtr.code
                            };
                            dictionarySrv.loadPaginatedDict($scope, 'SKMTR', param, 'rSKMTR').then(function (data) {
                                if (data.total)
                                    autoFillByTypeDictFn('model', 'skmtr', data.items[0]);
                            });
                        }
                    }
                    if($scope.model.okved2){
                        if($scope.model.okved2.code) {
                            if(!$scope.model.okved2.name){
                                var param = {};
                                param.searchByField = {
                                    id: $scope.model.okved2Code
                                };
                                dictionarySrv.loadPaginatedDict($scope, 'OKVED2', param, 'rOKVED2').then(function (data) {
                                    if (data.total) {
                                        $scope.model.okved2.name = data.items[0].name;
                                        $scope.model.okved2.id = $scope.model.okved2.code;
                                        $scope.model.okved2.description = $scope.model.okved2.name;
                                        delete $scope.model.okved2.code;
                                        delete $scope.model.okved2.name;
                                    }
                                });
                            }else{
                                $scope.model.okved2.id = $scope.model.okved2.code;
                                $scope.model.okved2.description = $scope.model.okved2.name;
                                delete $scope.model.okved2.code;
                                delete $scope.model.okved2.name;

                            }
                        }
                    }
                    if($scope.model.okpd2){
                        if($scope.model.okpd2.code) {
                            if(!$scope.model.okpd2.name){
                                var param = {};
                                param.searchByField = {
                                    code: $scope.model.okpd2.code
                                };
                                dictionarySrv.loadPaginatedDict($scope, 'OKPD2', param, 'rOKPD2').then(function (data) {
                                    if (data.total)
                                        $scope.model.okpd2.name = data.items[0].name;
                                });
                            }
                        }
                    }
                    if($scope.model.okei && $scope.model.okei.code==null) {
                       	delete $scope.model.okei;
                    }
                    if($scope.model.okei) {
                        if ($scope.model.okei.code) {
                            if (!$scope.model.okei.name) {
                                var param = {};
                                param.searchByField = {
                                    code: $scope.model.okei.code
                                };
                                dictionarySrv.loadPaginatedDict($scope, 'OKEI', param, 'rOKEI').then(function (data) {
                                    if (data.total) {
                                        $scope.model.okei.name = data.items[0].fullname;
                                        $scope.model.okei.fullname = $scope.model.okei.name;
                                        delete $scope.model.okei.name;
                                    }
                                });
                            } else
                                $scope.model.okei.fullname = $scope.model.okei.name;
                        }
                    }
                    $scope.showDeliveryPlaceBlock = !$scope.model.deliveryPlace;

                    if($scope.model.showDeliveryPlace==="hide")
                        $scope.showDeliveryPlaceBlock = false;
                    else if($scope.model.showDeliveryPlace==="show")
                        $scope.showDeliveryPlaceBlock = true;
                }
                $scope.focusFirstInvalid = function (form) {
                    $scope.submitted = true;
                    setTimeout(function () {
                        if (form.$error && form.$error.required && form.$error.required[0]
                            && form.$error.required[0].$$element && form.$error.required[0].$$element[0]) {
                            desktopSrv.scollTop();
                            form.$error.required[0].$$element[0].focus();
                        }
                    }, 0)
                };
                $scope.isShowDeliveryPlaceBlock = function(){
                    return $scope.showDeliveryPlaceBlock;
                };
                $scope.isSimpleContest44 = function () {
                    return $scope.isSimpleContest() && noticeSrv.isNew44();
                }
                $scope.showPrices = () => { return ($scope.isNew223() || (($scope.isNewCommercial() || $scope.isNew44()) && $scope.isSimpleContest())) && $scope.hasPriceInNotice() && $scope.checkOnProcType() && $scope.getMethodPriceSet()==='method3'; }
                $scope.priceForItemProc = () => { return ($scope.isNewCommercial() || $scope.isNew223()) && !['simplecontest', 'prequalification', 'unlimitedprequalification'].includes($stateParams.type) && getPriceForItemFn();}
                $scope.isShowPriceForItem = () => { return !$scope.model.noQty || !($scope.isNew223() && ($scope.getPriceForItem() || $scope.isPrequalification()));}
                $scope.isPriceForItemRequired = () => { return $scope.isNew44() || $scope.isNew223() || $scope.getMethodPriceSet()==='method3';}
                $scope.isOld223Price = (field) => { return $scope.isNew223() && $scope.isReadOnly && !field;}
                $scope.isPrequalification = () => {
                    return ['prequalification', 'unlimitedprequalification'].includes($stateParams.type);
                }
                $scope.isEisKisEnabled = () => {
                    return $scope.isNew223() && ['opencontest', 'openauction', 'requestquotations','requestproposals',
                        'open2stagecontest', 'limitedcontest','limitedauction','limitedrequestquotations','limitedrequestproposals',
                        'prequalification','unlimitedprequalification'].includes($stateParams.type) && noticeSrv.getSelectedCustomer() && noticeSrv.getSelectedCustomer().eisKis;
                }
            }]
    });