angular.module('uetp').service('tablesSrv', ['$http', '$rootScope', 'dialogSrv', 'paginationSrv', '$stateParams', '$state','desktopSrv', 'filterService', 
    function ($http, $rootScope, dialogService, paginationSrv, $stateParams, $state, desktopSrv, filterService) {
        var service = this,
            tableSort = {};

        service.positionsDetails = {
            position: {}
        };
        service.positionsDetailsId = {
            id: ''
        };

        this.getFullName = getFullNameFn;
        this.setTableSort = setTableSortFn;
        this.getTableSort = getTableSortFn;
        this.changeSort   = changeSortTh;
        this.changeSortLocal   = changeSortLocalFn;
        this.sortBy = sortByFn;
        this.sortByOrder = sortByOrderFn;
        this.initSort = initSortFn;
        this.messageTextSearchField = messageTextSearchFieldFn;
        this.isDemo = isDemoFn;
        this.prepareTableParams = prepareTableParamsFn;


        function prepareTableParamsFn(paginator, filterType) {
            const filterParams = angular.copy(filterService.getFilterParams(filterType)),
                searchParam = angular.copy(filterService.getSearchParam(filterType));
            if (filterParams && Object.keys(filterParams).length > 0) {
                paginator.searchByField = filterParams;
            } else {
                delete paginator.searchByField
            }
            paginator.search = searchParam;
        }

        function setTableSortFn(state, obj) {
            tableSort[state] = obj;
        }

        function changeSortTh(paginator, th) {
            var orderBy  = th.getAttribute('st-sort'),
                orderAsc = th.classList.contains("sortAsc");

            [].forEach.call(document.querySelectorAll(".sortColumn"), function (column) {
                column.classList.remove("sortDesc");
                column.classList.remove("sortAsc");
            });
            orderAsc = !orderAsc;
            th.classList.add(orderAsc ? "sortAsc" : "sortDesc");

            if (orderBy) {
                //$scope.paginator.page = 0;   // если надо, то вернуть
                paginator.orderBy  = orderBy;
                paginator.orderAsc = orderAsc;
                var state = $state.current;
                setTableSortFn(state.name, {orderBy: orderBy, orderAsc: orderAsc});
                paginationSrv.setPaginationStateConfig('sortTh', $state.current.name, {
                    targetObject: {
                        name: orderBy,
                        orderAsc: orderAsc
                    }
                });
                return true
            }
            return false
        }

        function changeSortLocalFn(scope, th) {
            var orderBy  = th.getAttribute('st-sort'),
                orderAsc = th.classList.contains("sortAsc");

            [].forEach.call(document.querySelectorAll(".sortColumn"), function (column) {
                column.classList.remove("sortDesc");
                column.classList.remove("sortAsc");
            });
            orderAsc = !orderAsc;
            th.classList.add(orderAsc ? "sortAsc" : "sortDesc");
            if (orderBy) {
                scope.sortType  = orderBy;
                scope.sortReverse  = !orderAsc;
                return true
            }
            return false
        }

        function getTableSortFn(state) {
            return tableSort[state] || null;
        }
        function messageTextSearchFieldFn(type) {
            if (desktopSrv.cabType === 'inspector') {
                if (type === 'in') {
                    $rootScope.somePlaceholder = 'Тема, текст, отправитель, получатель';
                } else if (type === 'out') {
                    $rootScope.somePlaceholder = 'Тема, текст, отправитель, получатель';
                } else {
                    $rootScope.somePlaceholder = 'Тема, текст, получатель';
                }
            } else {
                if (type === 'in') {
                    $rootScope.somePlaceholder = 'Тема, текст, отправитель';
                } else if (type === 'out') {
                    $rootScope.somePlaceholder = 'Тема, текст, получатель';
                } else {
                    $rootScope.somePlaceholder = 'Тема, текст';
                }
            }
        }


        function getFullNameFn(item) {
            if (item) {
                if (angular.isUndefined(item.fullName)) {
                    return item.lastName + ' ' + item.firstName + ' ' + item.middleName;
                } else {
                    return item.fullName;
                }
            }
        }
        /**
         * Сменить сортировку и вызвать метод перегрузки
         * @param by - кого сортируем
         * @param sortByObject - объект сортировки
         * @param paginator - пагинатор
         * @param callback - метод перезагрузки
         */
        function sortByFn(by, sortByObject, paginator, callback) {
            sortChangeObject(by, null, sortByObject, paginator);
            callback();
        }

        /**
         * Сменить сортировку и вызвать метод перегрузки
         * @param by - кого сортируем
         * @param orderAsc - направление сортировки
         * @param sortByObject - объект сортировки
         * @param paginator - пагинатор
         * @param callback - метод перезагрузки
         */
        function sortByOrderFn(by, orderAsc, sortByObject, paginator, callback) {
            sortChangeObject(by, orderAsc, sortByObject, paginator);
            callback();
        }

        /**
         * Сменить параметры элементов сортировки по клику
         * @param by - кого меняем
         * @param sortByObject - объект сортировки
         * @param paginator - пагинатор
         */
        function sortChangeObject(by, orderAsc, sortByObject, paginator) {
            if (by) {
                angular.forEach(sortByObject, function (value, key) {
                    if (by !== key) {
                        sortByObject[key] = {
                            activate: false,
                            orderAsc: true
                        };
                    }
                });

                sortByObject[by] = {
                    activate: true,
                    orderAsc: orderAsc !== null ? orderAsc : !sortByObject[by].orderAsc
                };

                var sortBy;
                if (by.indexOf('__') >= 0) {
                    sortBy = by.replace(/__/g, '.')
                } else {
                    sortBy = by;
                }
                changeIcon(by,sortByObject);
                paginator.orderBy = sortBy;
                paginator.orderAsc = sortByObject[by].orderAsc;
                setTableSortFn($state.current.name, {orderBy: by, orderAsc: sortByObject[by].orderAsc});
                paginationSrv.setPaginationStateConfig('sortBy', $state.current.name, sortByObject);
            }
        }
        function changeIcon(by,sortByObject) {
            var sort = sortByObject[by];
            if (sort.orderAsc) {
                angular.extend(sort, {tag: 'asc'});
            } else {
                angular.extend(sort, {tag: 'desc'});
            }
        }
        /*
        * Инициализация сортировки при init компонента
        * */
        function initSortFn(sortByObject,paginator) {
            var sortBy = paginationSrv.getPaginationStateConfig('sortBy', $state.current.name) || {}; // память сортировки по кнопке
            var sortTh = paginationSrv.getPaginationStateConfig('sortTh', $state.current.name); // память сортировки по столбцу
            sortByObject = {
                summa: sortBy.summa ? sortBy.summa : {},
                price: sortBy.price ? sortBy.price : {},
                total_sum: sortBy.total_sum ? sortBy.total_sum : {},
                endDate: sortBy.endDate ? sortBy.endDate : {},
                startDate: sortBy.startDate ? sortBy.startDate : {},
                createdDate: sortBy.createdDate ? sortBy.createdDate : {},
                accreditationDate: sortBy.accreditationDate ? sortBy.accreditationDate : {},
                owner__fullName: sortBy.data ? sortBy.data : {},
                publish_date: sortBy.publish_date ? sortBy.publish_date : {},
                publish_date_first: sortBy.publish_date_first ? sortBy.publish_date_first : {},
                fullprice: sortBy.fullprice ? sortBy.fullprice : {},
                fullprice_cur: sortBy.fullprice_cur ? sortBy.fullprice_cur : {},
                publishDate: sortBy.publishDate ? sortBy.publishDate : {},
                signDate: sortBy.signDate ? sortBy.signDate : {},
                regnum: sortBy.regnum ? sortBy.regnum : {},
                minPrice: sortBy.minPrice ? sortBy.minPrice : {},
                maxPrice: sortBy.maxPrice ? sortBy.maxPrice : {},
                avgPrice: sortBy.avgPrice ? sortBy.avgPrice : {},
                medianPrice: sortBy.medianPrice ? sortBy.medianPrice : {}
            };
            if (Object.keys(sortBy).length) {
                sortChangeObject(getActiveSortObject(sortByObject), null,sortByObject,paginator)
            } else if (sortTh && angular.isDefined(sortTh.targetObject)) {
                var target = getThSortTarget(sortTh.targetObject);
                if (target) {
                    changeSortTh(paginator, target)
                }
            }
             return sortByObject;
        }
        /**
         * Получить имя по какой кнопке была сортировку
         * (это те кнопки где нет столбиков в таблицы , а только кнопки ввиде ссылок над таблицей)
         * @param object - данные по нажатой кнопке
         */
        function getActiveSortObject(object) {
            var by = null;
            angular.forEach(object, function (value, key) {
                if (value && value.activate === true) {
                    value.orderAsc = !value.orderAsc;
                    by = key;
                }
            });
            return by;
        }
        /**
         * Получить столбец сортировки из таблицы по st-sort плюс имя
         * @param object - данные какой столбец искать
         * @returns {*}
         */
        function getThSortTarget(object) {
            var target = null;
            if (object && object.name) {
                var elements = window.document.querySelectorAll('[st-sort="' + object.name + '"]');
                if (elements && elements.length > 0) {
                    target = elements[0];
                    // перевенутое присвоение, т.к метод changeSortTh идет от обратного th.classList.contains("sortAsc")
                    target.classList.add(object.orderAsc ? "sortDesc" : "sortAsc");
                }
            }
            return target;
        }

        function isDemoFn(){
            return $rootScope.properties && $rootScope.properties['demo.mode']==="true";
        }
        
    }]);