/**
 * @author: sergeyu
 * Протоколы
 */
angular.module('uetp')
    .component('protocolList', {
        template: '<div ng-include="ctrl.templateUrl">',
        bindings: {
            protocolTypes: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'proceduresSrv', 'permissionSrv', 'authSrv', 'needConfirmSrv','httpSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, proceduresSrv, permissionSrv, authSrv, needConfirmSrv, httpSrv) {
                var ctrl = this;
                $scope.ctrl = ctrl;
                $scope.ctrl.templateUrl = '';
                $scope.hasPermission = permissionSrv.hasPermission;
                $scope.isChangeContract = false;
                $scope.showConfirm = true;
                this.$onInit = function () {
                    $scope.showAction = true;
                    $scope.cabType = desktopSrv.getCabType;
                    _initTemplate();
                    $scope.procedure = angular.copy(proceduresSrv.getProcedureData().data.result);
                    $scope.isNew223 = $scope.procedure.section==='223';
                    if($scope.procedure.protocols.length>0 && $scope.ctrl.protocolTypes) {
                        $scope.isChangeContract = $scope.ctrl.protocolTypes && $scope.ctrl.protocolTypes[0]==='CHANGE_CONTRACT_ADDITIONAL';
                        $scope.procedure.protocols = $scope.isChangeContract && $scope.procedure.protocolChanges ? $scope.procedure.protocolChanges
                            :
                            $scope.procedure.protocols.filter(item=>$scope.ctrl.protocolTypes.includes(item.type.id));
                    }
                    $scope.sortReverse = true;
                    $scope.sortType = 'created';
                    $scope.proceduresSrv = proceduresSrv;
                    $scope.openProtocol = openProtocolFn;
                    $scope.user = authSrv.getUser();
                    $scope.openEisError = openEisErrorFn;

                    $scope.actions = [];
                    $scope.protocolStates = [];

                    if($scope.user.userRole === 'operator_admin') {
                        angular.forEach($scope.procedure.protocols, function(protocol){
                            $scope.protocolStates.push({id: protocol.id, state: protocol.state.id});
                        });
                    }

                    if ($scope.cabType() === 'organizer') {
                        // Добавляем действие для вкладки Протоколы изменения
                        if(isChangeContractProcedures() && $scope.ctrl.protocolTypes && $scope.ctrl.protocolTypes[0]==='CHANGE_CONTRACT_ADDITIONAL') {
                            const disabled = $scope.procedure.protocolChanges && $scope.procedure.protocolChanges.find(p => p.state.id === 'created');
                            $scope.showAction = $scope.procedure.state.id === 'finished';
                            $scope.actions.push({
                                id: $scope.procedure.type.id + '.createChangeContractAdditionalProtocol',
                                disabled: disabled,
                                name: 'Публикация протокола изменений условий договора'
                            });
                            if (disabled)
                                $scope.protocolActionError = 'Перед созданием нового протокола изменений условий договора нужно удалить или опубликовать уже созданный черновик протокола';
                            return;
                        }
                        $scope.actions.push({id: 'base.notificationProtocol', name: 'Публикация уведомления'});

                        const condition1 = ($scope.procedure.state.id === 'claiming' || ($scope.procedure.type.id === 'unlimitedprequalification' && $scope.procedure.state.id === 'ratingFirstClaimParts'));
                        const condition2 = $scope.procedure.type.id === 'requestprices';
                        const conditionStage2 = (_isStage2() && getServerTimeMsk().getTime() < $scope.procedure.claimEndsSecondStage);
                        const conditionStage1 = (_isStage1() && (getServerTimeMsk().getTime() < $scope.procedure.claimEnds
                                                            || ($scope.procedure.type.id === 'unlimitedprequalification' && getServerTimeMsk().getTime() < $scope.procedure.submissionClosePKODateTimeEnds)));
                        const isRZDCondition = (!proceduresSrv.isRZDOrChildRZD($scope.procedure) && !['finished','reject'].includes($scope.procedure.state.id));

                        if (((proceduresSrv.isRZDOrChildRZD($scope.procedure) && condition1 && (conditionStage2 || conditionStage1)) || isRZDCondition) && !condition2) {
                            $scope.actions.push({id: 'base.procedureReject', name: 'Отказ от проведения закупки'});
                            if ($scope.procedure.isCancelLotEnabled) {
                                $scope.actions.push({
                                    id: 'base.informationAboutCancelLots',
                                    name: 'Отказ от проведения лота закупки'
                                });
                            }
                        }
                        if ($scope.procedure.isNoticePostponementEnabled) {
                            const action = {
                                id: 'base.noticePostponementProcedure',
                                name: 'Уведомление о переносе сроков начала ' + ($scope.procedure.type.id === 'openpublicoffer' ? 'подачи предложений' : 'торгов')
                            }
                            if($scope.procedure.notices.find(notice => notice.state.id === 'created'))
                                action.confirmText = 'По закупке имеется неопубликованное извещение. Перед созданием текущего уведомления оно будет удалено. Продолжить';
                            $scope.actions.push(action);
                        }
                        if ($scope.procedure.state.id === 'finished') {
                            if (isChangeContractProcedures()) {
                                $scope.actions.push({
                                    id: 'base.additionalProtocol',
                                    name: 'Публикация дополнительного протокола'
                                });
                                // если нет протокола изменений условий в статусе Создан
                                if ($scope.procedure.protocols && !$scope.procedure.protocols.find(p => p.type.id === 'CHANGE_CONTRACT_ADDITIONAL' && p.state.id === 'created'))
                                    $scope.actions.push({
                                        id: $scope.procedure.type.id + '.createChangeContractAdditionalProtocol',
                                        name: 'Публикация протокола изменений условий договора'
                                    });
                            }
                        }
                        if ($scope.procedure.isReturnClaimsEnabled &&
                            !['published','claiming'].includes($scope.procedure.state.id) && !condition2) {
                            $scope.actions.push({
                                id: 'base.returnClaimsProtocol',
                                name: 'Возврат заявок участникам'
                            });
                        }
                    }
                    function isChangeContractProcedures() {
                        return !['unlimitedprequalification', 'prequalification', 'offerplacement', 'requestprices'].includes($scope.procedure.type.id);
                    }
                };

                /**
                 * Инициализация шаблона
                 */
                function _initTemplate() {
                    if ('cbz' === $scope.cabType()) {
                        $scope.ctrl.templateUrl = 'app/components/procedures/protocol/protocolForCbz.html'
                    } else {
                        $scope.ctrl.templateUrl = 'app/components/procedures/protocol/protocol.html'
                    }

                }

                function _isStage2() {
                    return $scope.procedure.stage && $scope.procedure.stage.id === 'stage2';
                }

                function _isStage1() {
                    return !$scope.procedure.stage || ($scope.procedure.stage && $scope.procedure.stage.id !== 'stage2');
                }
                
                function openEisErrorFn(eisError, protocol){
                    if(protocol?.id && protocol?.hasEDS && $scope.cabType() !== 'organizer') {
                        httpSrv.downloadFile(`/admin/eis/description/${protocol.id}/protocol`, `eis_description_protocol_${protocol.id}.xml`, undefined, undefined, $scope, 'Информация об ответе ЕИС отсутствует');
                    } else {
                        needConfirmSrv.openModal('_dialogMessage.html', {message: !eisError.endsWith('.') ? eisError + '.' : eisError});
                    }
                }
                function openProtocolFn(procedureId, procedureType, protocol) {
                    if (protocol.type.id === 'PURCHASE_REJECTION') {
                        $state.go('informationAboutRejectProcedureView', {
                            procId: procedureId,
                            procType: procedureType,
                            protocolId: protocol.id
                        });
                    } else if (protocol.type.id === 'PURCHASE_LOT_CANCELLATION') {
                        $state.go('informationAboutCancelLotsView', {
                            procId: procedureId,
                            procType: procedureType,
                            protocolId: protocol.id
                        });
                    } else if (protocol.type.id === 'ADDITIONAL') {
                        $state.go('additionalProtocolView', {
                            procId: procedureId,
                            procType: procedureType,
                            protocolId: protocol.id
                        });
                    } else if(protocol.type.id === 'NOTICE_POSTPONEMENT') {
                        $state.go('noticePostponementProcedureView', {
                            procId: procedureId,
                            procType: procedureType,
                            protocolId: protocol.id
                        });
                    }  else if(protocol.type.id === 'TRANSFER' || protocol.type.id === 'COMPLAINT' || protocol.type.id === 'CUSTOM') {
                        $state.go('notificationProtocolView', {
                            procId: procedureId,
                            procType: procedureType,
                            protocolId: protocol.id
                        });
                    } else if(protocol.type.id === 'RETURN_CLAIMS') {
                        $state.go('returnClaimsProtocolView', {
                            procId: procedureId,
                            procType: procedureType,
                            protocolId: protocol.id
                        });
                   } else {
                        if ('cbz' === $scope.cabType()) {
                            $state.go('openProtocolView', {
                                procId: procedureId,
                                procType: procedureType,
                                protocolId: protocol.id
                            });
                        } else {
                            $state.go('openProtocol', {
                                procId: procedureId,
                                procType: procedureType,
                                protocolId: protocol.id
                            });
                        }
                    }
                }

                function getProtocolState(items, id){
                    return items.filter(function (item) { return item.id===id; })[0];
                }

                $scope.changeState = function (el, prevState, selector) {
                    if(!$scope.showConfirm) {
                        $scope.showConfirm = true;
                        return;
                    }
                    if(prevState!=='' && prevState!==selector.$select.selected.id)
                        needConfirmSrv.callVariants(changeStateProcess, function (){ selector.$select.selected = getProtocolState(selector.$select.items, prevState); $scope.showConfirm = false; },['admin/changeProtocolState/'+el.id+'/'+selector.$select.selected.id], 'Статус протокола будет изменен на "'+el.state.name+'". Продолжить?');
                }

                function changeStateProcess(url){
                    return proceduresSrv.changeState(url);
                }
                $scope.isSimpleContest = function () {
                    return $scope.procedure && $scope.procedure.type.id ==='simplecontest';
                };
                $scope.isOrgOrAdmin = () => ['operator_admin', 'operator_manager'].includes($scope.user.userRole)  || $scope.cabType() === 'organizer';
                $scope.isOrgOrAdminSupport = () => ['operator_admin', 'operator_manager', 'operator_support_ro'].includes($scope.user.userRole)  || $scope.cabType() === 'organizer';
            }
        ]
    });

