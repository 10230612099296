angular.module('uetp').service('lkNotificationSrv', ['$sce', 'authSrv', 'sysMessageSrv',
    function ($sce, authSrv, sysMessageSrv) {
        var service = this;

        service.trustAsHtml = trustAsHtmlFn;

        function trustAsHtmlFn(html) {
            return $sce.trustAsHtml(html);
        }

        service.getNotificationCount = function(notificationCount){
            if(!notificationCount) return 0;
            if(notificationCount.individualMessages)
                return Object.keys(notificationCount).length + notificationCount.individualMessages - 1;
            else
                return Object.keys(notificationCount).length;
        }

        service.initIndividualMessage = function(context, counterOnly, callback, errCallback) {
            if (['participant_admin','user','organizer_admin','organizer_user'].includes(authSrv.getUser().userRole)) {
                sysMessageSrv.getIndividualMessages(counterOnly).then(function successCallback(response) {
                    if (response.data.success) {
                        context.imCounter = response.data.total;
                        if(!counterOnly) {
                            context.individualMessages = response.data.items;
                            context.individualMessages.forEach(im => {
                                im.isShowAllIndividualMessage = false;
                            });
                        }
                    } else if (!response.data.success && response.data.errorCode === "405") {
                        context.individualMessages = undefined;
                        if(errCallback) errCallback();
                    } else {
                        console.log(response);
                    }
                    if(callback) callback();
                }, function errorCallback(response) {
                    console.log(response);
                    if(errCallback) errCallback();
                });
            }
        }
    }
])