/*
* Компонет-список вида: "Элемент 1, Элемент2, Элемент3 и еще 12"
* content - полный список, строка вида "Элемент 1, Элемент2, Элемент3, Элемент4" и т.п.
* number-of-show - количество изначально отображаемых элементов
* delimiter - разделитель элементов, по умолчанию ", "
* inline - определяет будет ли компонент в стиле inline/inline-block
* */

//v 1.1
angular.module('uetp').component('extraList', {
	template:
	'<span>{{base}}</span>' +
	'<span ng-if="extra">{{showAll?(delimiter + extra.join(delimiter)):""}}' +
		'<span ng-if="!showAll"> <a class="cursorPointer ng-binding" ng-click="changeShown()">и еще {{extra.length}}</a></span>' +
		'<span ng-if="showAll" > <a class="cursorPointer ng-binding" ng-click="changeShown()">[скрыть]</a></span>' +
	'</span>',
	bindings: {
		content       : '<',
		numberOfShown : '<',
		delimiter     : '<',
		regions       : '<',
		inline        : '<'
	},
	controller: ['$scope', '$element', function ($scope, $element) {
		var it = this;

		$scope.changeShown = function () {
			$scope.showAll = !$scope.showAll;
		};

		this.$onChanges = function () {
			if (!it.delimiter) it.delimiter = ', ';
			$scope.delimiter = it.delimiter;
			$scope.showAll = false;

			$scope.extra = null;
			var content = it.content.split(it.delimiter);
			if (it.regions && (content.length === 86/* || content.length === 85 && content.indexOf('Байконур г') === -1*/)) {
				$scope.base = typeof it.regions === 'string' ? it.regions : 'Вся Россия';
				$element.css('display', it.inline === false?'block':'inline-block');
			} else {
				if (content.length > it.numberOfShown) $scope.extra = content.splice(it.numberOfShown);
				$scope.base = (it.content==='Все регионы' ? 'Вся Россия' : content.join(it.delimiter));
				$element.css('display', it.inline?'inline':'block');
			}
		};
	}]
});