/**
 * Заявление на банковскую гарантию ВТБ
 *
 * @author dmitrys
 */
angular.module('uetp')
    .component('bankGuaranteeAppVTB', {
        templateUrl: 'app/components/bankGuaranteeAppVTB/bankGuaranteeAppVTB.html',
        bindings: {
            bankGuaranteeAppData: '<',
            participantInfoData: '<'
        },
        controller: ['$scope', '$state', '$stateParams', 'constants', 'bankGuaranteeSrv', '$filter', 'dictionarySrv',
            'httpSrv', 'alertSrv', 'authSrv', 'desktopSrv', 'needConfirmSrv', 'propertiesService', 'onlyRequiredSrv',
            'proceduresSrv', 'bankGuaranteeVTBSrv', '$q',
            function ($scope, $state, $stateParams, constants, bankGuaranteeSrv, $filter, dictionarySrv,
                      httpSrv, alertSrv, authSrv, desktopSrv, needConfirmSrv, propertiesService, onlyRequiredSrv,
                      proceduresSrv, bankGuaranteeVTBSrv, $q) {

                const ctrl = this;

                $scope.goBack = goBackFn;
                $scope.clickRow = clickRowFn;
                $scope.delRow = delRowFn;
                $scope.save = saveFn;
                $scope.signSend = signSendFn;
                $scope.bikChangeHandler = bikChangeHandlerFn;
                $scope.refreshDict = refreshDictFn;
                $scope.addFile = addFileFn;
                $scope.addPassportDoc = bankGuaranteeVTBSrv.addPassportDoc;
                $scope.delPassportDoc = delPassportDocFn;
                $scope.getPassportDocName = bankGuaranteeVTBSrv.getPassportDocName;
                $scope.clearAdress = clearAdressFn;
                $scope.deleteOtherField = deleteOtherFieldFn;
                $scope.downloadFile = downloadFileFn;
                $scope.getProductType = getProductTypeFn;
                $scope.getActiveLevel = bankGuaranteeSrv.getActiveLevel;
                $scope.setActiveLevel = bankGuaranteeSrv.setActiveLevel;
                $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                $scope.getStaticDictByType = dictionarySrv.getStaticDictByType;
                $scope.getActiveTab = bankGuaranteeSrv.getActiveTab;
                $scope.setActiveTab = setActiveTabFn;
                $scope.setPDL = bankGuaranteeSrv.setPDL;
                $scope.getDict = bankGuaranteeSrv.getDict;
                $scope.getDictVtb = bankGuaranteeVTBSrv.getDict;
                $scope.clearDopInfoByProxy = bankGuaranteeSrv.clearDopInfoByProxy;
                $scope.checkAgreement = checkAgreementFn;
                $scope.checkDocOrder = checkDocOrderFn;
                $scope.showDecision = showDecisionFn;
                $scope.submitted = false;
                $scope.fillCheckbox = fillCheckboxFn;
                $scope.hasRelationsExtraFields = hasRelationsExtraFieldsFn;
                $scope.guaranteeReceivingWayExtraFields = guaranteeReceivingWayExtraFieldsFn;
                $scope.selectPurchaseEIS = selectPurchaseEISFn;
                $scope.fillPrincipalSignerFromPrincipal = fillPrincipalSignerFromPrincipalFn;
                $scope.getLinkPurchaseEIS = getLinkPurchaseEISFn;
                $scope.changeState = changeStateFn;
                $scope.setDecisionType = setDecisionTypeFn;
                $scope.getDecisionType = getDecisionTypeFn;
                $scope.sendDecision = sendDecisionFn;
                $scope.isReqComment = isReqCommentFn;
                $scope.addDecisionFile = addDecisionFileFn;
                $scope.assignAgent = assignAgentFn;
                $scope.isMaxFileNum = isMaxFileNumFn;
                $scope.modelView = {
                    agreementCheck: false,//согласие на обработку данных
                    docOrder: false,
                    dict: null,//справочники
                    isReadOnly: false,//форма редактирования
                    doingSaveOrSend: false,//процесс сохранения или отправки
                    doingSaveAndSend: false,//процесс сохранения и отправки
                    formCreate: false,// форма создания
                    isRefresh: false,// обновление или создание анкеты
                    isAgent: false,//Форма создания агентом
                    participantInfo: null, //Информация по пользователю, за которого заполняет данные агент
                    typeOrg: null, // Тип организации заполняющий анкету (Если агент - то тип того, за кого заполняет)
                    decisionType: null, // Тип решения по выдаче гарантии (reject, requestToClient, agreementClient)
                    decision: null, // Решение по выдаче гарантии
                    decisionFileName: null // Имя файла для решения
                };
                $scope.tooltipInfoPhone = "Телефон необходимо указывать вместе с кодом города. Пример формата ввода номера: +7 (123) 456-78-90";
                $scope.tooltipInfoAddress = "Формат указания адреса: 195112, г. Санкт-Петербург, Малоохтинский пр., д. 68";
                /*
                * Формы для валидации
                * Каждая вкладка в отдельной форме
                * */
                $scope.companyInfo = {};
                $scope.companyManagement = {};
                $scope.principalBuh = {};
                $scope.principalSigner = {};
                $scope.bankGuarantee = {};
                $scope.decisionForm = {};
                $scope.decisionFormSubmitted = false;

                $scope.constants = constants;

                $scope.dateOptions = {};
                $scope.dateOptionsGuaranteeStart = {};
                $scope.tomorrow = getServerTimeMsk();
                if ($scope.tomorrow) {
                    $scope.tomorrow.setDate($scope.tomorrow.getDate() + 1);
                    $scope.tomorrow.setHours(0, 0, 0, 0);
                    $scope.dateOptionsGuaranteeStart = {
                        minDate: $scope.tomorrow
                    };
                }

                $scope.decisionTypes = {
                    reject: 'Отказать в выдаче',
                    requestToClient: 'Запросить уточнение',
                    agreementClient: 'Гарантия одобрена'
                }

                $scope.paginator = {
                    itemsPerPage: 20,
                    page: 0
                };
                $scope.dateGuaranteeStart = {
                    opened: false
                };

                $scope.feedbackFormVisibility = function () {
                    $scope.feedbackForm = !$scope.feedbackForm;
                };

                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.auth = authSrv.getAuth();
                    $scope.user = authSrv.getUser();
                    $stateParams.bank = 'vtb';
                    $scope.feedbackForm = false;
                    $scope.modelView.maxFileSize = $scope.auth.maxSizeAttachmentsBguarantee / 1048576;
                    // Временно убираем проверку по комментарию
                    // https://jira.comita.lan/browse/UETP-5202?focusedCommentId=586469&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-586469
                    //propertiesService.getProperty("bankguarantee.enabled").then(function() {
                    //    if(!desktopSrv.isBGEnabled()) {
                    //        $state.go('home');
                    //    } else {
                    initComponent();
                    //    }
                    //});
                };

                function initComponent() {
                    //форма создания
                    const isAppCreate = getAppCreate();
                    //форма редактирования
                    const isAppOpen = getAppOpen();
                    //форма создания копии
                    const isAppCopy = getAppCopy();
                    //форма агента
                    const isAppAgent = getAppAgent();
                    let participant = null;
                    //Информация по пользователю, за которого заполняет данные агент
                    if (ctrl.participantInfoData && ctrl.participantInfoData.data && ctrl.participantInfoData.data.success) {
                        $scope.modelView.participantInfo = ctrl.participantInfoData.data.result;
                        $scope.modelView.participantSelected = $stateParams.participantSelected;
                    }
                    //если попали под участником на состояние агента - редирект на правильное состояние
                    if (!authSrv.isUserAgent()) {
                        if (isAppAgent) {
                            //для формы создания
                            if (isAppCreate) {
                                $state.go('bankGuaranteeAppVTBCreate')
                            } else if (isAppOpen) {
                                //для формы редактирования
                                $state.go('bankGuaranteeAppVTBOpen', {id: $stateParams.id.id, clearConfig: true})
                            } else if (isAppCopy) {
                                //для формы копирования
                                $state.go('bankGuaranteeAppVTBCopy', {id: $stateParams.id, clearConfig: true})
                            }
                        }
                    }
                    if (!(authSrv.canActiveBankGuarantee($scope.cabType(), $scope.user) || authSrv.isUserAgent() || ['admincab', 'bank', 'support'].includes($scope.cabType()))) {
                        $state.go('bankGuaranteesVTB');
                    }
                    $scope.certError = authSrv.isCertificateError;
                    dictionarySrv.resetPaginatedDict($scope);
                    bankGuaranteeSrv.setDictRemote(dictionarySrv.getRemoteData());
                    $scope.dictionary = bankGuaranteeSrv.getDictRemote();
                    _initDict(['okcm'], false);

                    $scope.refreshDict('rOKPD2EIS', null, true);
                    $scope.refreshDict('rOKVED2', null, true);

                    $scope.modelView.isAgent = isAppAgent;
                    if (isAppOpen) {
                        $scope.modelView.isRefresh = true;
                        $scope.modelView.formCreate = false;
                        if (ctrl.bankGuaranteeAppData && ctrl.bankGuaranteeAppData.data && ctrl.bankGuaranteeAppData.data.success) {
                            const appData = ctrl.bankGuaranteeAppData.data;
                            // Статус заявки
                            $scope.modelView.state = appData.state;
                            // Время создания
                            $scope.modelView.created = appData.created;
                            // Агент
                            $scope.modelView.agent = appData.agent;
                            // Последний ответ от банка
                            if (appData.responseContent) {
                                $scope.modelView.decisionOptions = appData.responseContent.decisionOptions;
                                $scope.modelView.taskDefinitionKey = appData.responseContent.taskDefinitionKey;
                            }
                            // Заявка
                            const data = JSON.parse(appData.result);
                            bankGuaranteeSrv.setData(getModelFromView(data));
                            // Главки согласия
                            $scope.modelView.agreementCheck = data.isAgreement;
                            $scope.modelView.docOrder = data.isDocOrder;
                            if ($stateParams.successSave) {
                                $scope.alertObjbankGuaranteeApp = alertSrv.getSuccessMsg('Заявка сохранена успешно.');
                                setTimeout(function () {
                                    desktopSrv.scrollBottom();
                                }, 1500);
                            }
                            // waitClient - Ожидает действий пользователя
                            // Анкета после отправки пришла с ошибками спустя время
                            if ($scope.modelView.state === 'waitClient' || $scope.modelView.state === 'rejected') {
                                if (appData.currentMessage) {
                                    initErrorMess(appData.currentMessage);
                                    setTimeout(function () {
                                        desktopSrv.scrollBottom();
                                    }, 1500);
                                }
                            }
                        }
                    }
                    /*
                    * Копируем анкету
                    * */
                    if (isAppCopy) {
                        $scope.modelView.formCreate = true;
                        $scope.modelView.isRefresh = false;
                        if (ctrl.bankGuaranteeAppData && ctrl.bankGuaranteeAppData.data && ctrl.bankGuaranteeAppData.data.success) {
                            //статус заявки
                            $scope.modelView.state = 'draft';
                            //заявка
                            const data = JSON.parse(ctrl.bankGuaranteeAppData.data.result);
                            //UETP-6047 data.documents = [];
                            delete data.agreement;
                            delete data.decision;
                            bankGuaranteeSrv.setData(getModelFromView(data));
                        }
                    }
                    if ($stateParams.clearConfig) {
                        if (isAppCreate) bankGuaranteeSrv.setData({principal: {}});
                        $scope.setActiveTab('bankGuarantee');
                        $scope.setActiveLevel('app');
                    }
                    if (isAppCreate) {
                        $scope.modelView.state = 'draft';
                        $scope.modelView.formCreate = true; // Форма создания
                        $scope.modelView.isRefresh = false;
                        const data = bankGuaranteeVTBSrv.getDefaultModel($scope.modelView.participantInfo);
                        bankGuaranteeSrv.setData(data);
                    }
                    // Если статус не черновик или ожидаются действия от пользователя, то можно редактировать

                    $scope.modelView.isReadOnly = !($scope.modelView.state === 'draft' && !['admincab', 'bank', 'support'].includes($scope.cabType()));

                    $scope.model = bankGuaranteeSrv.getData();

                    if ($stateParams.purchasData) {
                        bankGuaranteeVTBSrv.setDataFormPurchase($scope.model, $stateParams.purchasData, $stateParams.purchasDataType);
                    }
                    if(!isAppAgent){
                        participant = $scope.user;
                    }
                    if (participant) {
                        let typeOrg = participant.typeOrg;
                        if ($stateParams.participantId) {
                            typeOrg = $scope.modelView.participantInfo.typeOrg;
                        }
                        initDocument(typeOrg);
                        getDocumentFromView(angular.copy($scope.model));
                        $scope.modelView.typeOrg = typeOrg;
                    } else {
                        initDocument();
                        getDocumentFromView(angular.copy($scope.model));
                    }
                    $scope.modelView.isEdit = isAppOpen;

                    if ($scope.showDecision() && !$scope.model.decision) {
                        $scope.model.decision = {};
                    }
                    // Сохраняем признаки формы в сервис
                    bankGuaranteeSrv.setStateApp(false, isAppCreate, isAppOpen, isAppCopy, isAppAgent, $scope.modelView.isReadOnly);
                    if ($scope.cabType() === 'admincab' && $stateParams.participantId) {
                        bankGuaranteeSrv.loadParticipantAgentsList($stateParams.participantId).then(response => {
                            if (response.data.success) {
                                $scope.agentsList = response.data.result;
                            } else {
                                $scope.agentsList = [];
                            }
                        }, error => console.log(error));
                    }
                }

                /**
                 * Выбрать тип решения по выдаче гарантии
                 * @param type - тип решения
                 * reject - Отказать в выдаче
                 * requestToClient - Запросить уточнение
                 * agreementClient - Гарантия одобрена
                 */
                function setDecisionTypeFn(type) {
                    $scope.alertObjbankGuaranteeApp = null;
                    if(type === null){
                        $scope.modelView.decision = null;
                        $scope.decisionFormSubmitted = false;
                    }
                    $scope.modelView.decisionType = type;
                }

                function getDecisionTypeFn() {
                    return $scope.modelView.decisionType;
                }

                function isMaxFileNumFn(files) {
                    return files && files.length >= 10;
                }

                /**
                 * Выбрать решение
                 * reject - Отказать в выдаче
                 * requestToClient - Запросить уточнение
                 * agreementClient - Гарантия одобрена
                 */
                function sendDecisionFn(){
                    if('requestToClient' == $scope.getDecisionType()){
                        return sendRequestToClientLocalFn();
                    }
                }

                /**
                 * Отправить запрос на уточнение
                 */
                function sendRequestToClientLocalFn(){
                    if ($scope.decisionForm.form.$invalid) {
                        $scope.decisionFormSubmitted = true;
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');
                        return;
                    }
                    const data = $scope.modelView.decision;
                    if (data.files && data.files.length > 10) {
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Добавлено больше 10 вложений');
                        return;
                    }
                    bankGuaranteeVTBSrv.requestToClientLocal($stateParams.id, data).then(function (response) {
                        if (response.data && response.data.success) {
                            $state.go('bankvtb', {
                                saveParam: {
                                    message: `Запрошено уточнение по анкете по закупке № ${$scope.model.bankGuarantee.purchaseNumber}`,
                                    date: $scope.modelView.created
                                }
                            });

                        } else {
                            $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg(response.data.message || 'Ошибка!');
                            console.log(response)
                        }
                    }, function (reason) {
                        console.log(reason);
                    });
                }

                /**
                 * Условия обязательности коммента
                 * @returns {boolean}
                 */
                function isReqCommentFn() {
                    return ['requestToClient', 'agreementClient'].includes($scope.getDecisionType())
                }
                /*
                 * Добавить файл решения
                 * @param files - файлы
                 * */
                function addDecisionFileFn(files) {
                    if(files){
                        if(!$scope.modelView.decision) {
                            $scope.modelView.decision = {}
                        }
                        const decision = $scope.modelView.decision;
                        if(!decision.files){
                            decision.files = [];
                        }
                        angular.forEach(files, function (file) {
                            decision.files.push(
                                {
                                    fileName: file.fileName,
                                    id: file.id,
                                }
                            )
                        });
                    }
                }

                /**
                 * Заполнить анкету по закупке из ЕИС
                 * поиск по номеру закупки
                 * */
                function selectPurchaseEISFn() {
                    if ($scope.model.bankGuarantee && $scope.model.bankGuarantee.purchaseNumber) {
                        const param = {
                            searchByField: {purchase_number: $scope.model.bankGuarantee.purchaseNumber}
                        };
                        proceduresSrv.searchPurchases($scope, param).then(response => {
                            if (response && response.data && response.data.success) {
                                const purchaseData = response.data.items;
                                if (purchaseData && purchaseData.length > 0)
                                    bankGuaranteeVTBSrv.setDataFormPurchase($scope.model, purchaseData[0], "EIS");
                            }
                        })


                    }
                }

                /**
                 * Заполнить Данные о подписанте на вкладке Подписант
                 * данными о руководителе с вкладки Органы управления
                 * */
                function fillPrincipalSignerFromPrincipalFn() {
                    if ($scope.model.principal.companyHead) {
                        const companyHeadCopy = angular.copy($scope.model.principal.companyHead);
                        $q.when(companyHeadCopy.id ? bankGuaranteeVTBSrv.copyFileById(companyHeadCopy.id) : undefined).then(function(response){
                            if(response && response.data && response.data.success){
                                const fileData= response.data.result;
                                companyHeadCopy.id = fileData.id;
                                if(!fileData.type){
                                    let file = bankGuaranteeVTBSrv.findPassportDocById($scope.model.principal.companyHead.id);
                                    fileData.type = file.type;
                                }
                                bankGuaranteeVTBSrv.addPrincipalPassportDoc(fileData)
                            }
                            if($scope.model.principal.principalSigner.id)
                                bankGuaranteeVTBSrv.deletePrincipalPassportDoc($scope.model.principal.principalSigner.id)
                            $scope.model.principal.principalSigner = companyHeadCopy;
                            if ($scope.model.principal.principalSigner.eioProtocolDate) {
                                delete $scope.model.principal.principalSigner.eioProtocolDate
                            }
                            if ($scope.model.principal.principalSigner.eioProtocolNumber) {
                                delete $scope.model.principal.principalSigner.eioProtocolNumber
                            }
                            $scope.model.principal.principalSigner.byProxy = false;
                        });
                    }
                }

                /*
                * Ссылка на закупку ЕИС по ее номеру для всех ФЗ
                * */
                function getLinkPurchaseEISFn(purchaseNumber) {
                    return 'https://zakupki.gov.ru/epz/order/extendedsearch/results.html?searchString=' + purchaseNumber + '&morphology=on';
                }

                /*
                * Скрываем необязательные поля при открытии формы
                * необходима задержка для отработки ng-include
                * */
                $scope.$on("$includeContentLoaded", (event, templateName) => {
                    setTimeout(() => {
                        const name = templateName.slice(templateName.lastIndexOf('/') + 1, templateName.lastIndexOf('.'));
                        if (name === 'bankGuarantee') {
                            onlyRequiredSrv.showHide($scope.bankGuarantee.form.$$element[0], true);
                        } else if (name === 'companyInfo') {
                            onlyRequiredSrv.showHide($scope.companyInfo.form.$$element[0], true);
                        } else if (name === 'companyManagement') {
                            onlyRequiredSrv.showHide($scope.companyManagement.form.$$element[0], true);
                        } else if (name === 'principalBuh') {
                            onlyRequiredSrv.showHide($scope.principalBuh.form.$$element[0], true);
                        } else if (name === 'principalSigner') {
                            onlyRequiredSrv.showHide($scope.principalSigner.form.$$element[0], true);
                        }
                    }, 100);
                });

                function _initDict(dictList, reload) {
                    angular.forEach(dictList, name => {
                        dictionarySrv.getDictionaryRemote(name, reload);
                    })
                }

                /*
               * Получаем полный список всех возможных типов документов и проставляем тип блока (основной/дополнительный)
               * Заполняем основной блок документов (documents)
               * */
                function initDocument(typeOrg) {
                    $scope.modelView.documentsFull = angular.copy(bankGuaranteeVTBSrv.getDocTypeList(typeOrg));
                    $scope.modelView.documents = [];
                    angular.forEach($scope.modelView.documentsFull, item => {
                        add($scope.modelView.documents, item)
                    });

                    /*
                    * храним тип и список файлов для этого типа
                    * isMultiple - возможно несколько файлов
                    * isRequired - обязательные файлы
                    * */
                    function add(list, elem) {
                        list.push({
                            type: elem.type,
                            label: elem.label,
                            isMultiple: elem.isMultiple,
                            isRequired: elem.isRequired,
                            canEdit: elem.canEdit,
                            info: elem.info,
                            isShow: true,//отображение в списке
                            files: []
                        })
                    }
                }

                /*
                * Установить активную вкладку
                * */
                function setActiveTabFn(tab) {
                    bankGuaranteeSrv.setActiveTab(tab)
                }

                /*
                * Форма создания
                * */
                function getAppCreate() {
                    return $state.includes('bankGuaranteeAppVTBCreate') || $state.includes('bankGuaranteeAppVTBAgentCreate');
                }

                /*
                * Форма редактирования
                * */
                function getAppOpen() {
                    return $state.includes('bankGuaranteeAppVTBOpen') || $state.includes('bankGuaranteeAppVTBAgentOpen')|| $state.includes('bankGuaranteeAppVTBAdminOpen');
                }

                /*
                * Форма создания копии
                * */
                function getAppCopy() {
                    return $state.includes('bankGuaranteeAppVTBCopy') || $state.includes('bankGuaranteeAppVTBAgentCopy')
                }

                /*
                * Форма агента
                * */
                function getAppAgent() {
                    return $state.includes('bankGuaranteeAppVTBAgentCreate')
                        || $state.includes('bankGuaranteeAppVTBAgentOpen')
                        || $state.includes('bankGuaranteeAppVTBAgentCopy')
                }

                /*
                * Подготовляем документы для отображения
                * */
                function getDocumentFromView(model) {
                    /*
                    * Предзаполняем список пустыми значениями
                    * */
                    $scope.model.documents = angular.copy($scope.modelView.documents);
                    /**
                     * Вырезаем файлы копии паспортов
                     */
                    if (model && model.documents) {
                        const passportDocuments = model.documents.filter(item => item.type === "doc_principalPassport");
                        model.documents = model.documents.filter(item => item.type !== "doc_principalPassport");
                        bankGuaranteeVTBSrv.setPrincipalPassportDocument(passportDocuments);
                    }
                    /*
                    * Проставляем файлы
                    * */
                    if (model.documents) {
                        angular.forEach(model.documents, file => {
                            /**
                             * запоняем основные документы ($scope.model.documents)
                             * */
                            angular.forEach($scope.model.documents, item => {
                                if (file.type === item.type) {
                                    item.files.push(file)
                                }
                            });
                        })
                    }
                }

                /**
                 * Заполнить Номер корреспондентского счета банка из выбранного банка
                 */
                function bikChangeHandlerFn(selectObj, dataObj) {
                    const selected = selectObj.$select.selected;
                    dataObj.bic = selected ? selected.bic : "";
                    dataObj.corrNumber = selected ? selected.accounts : "";
                    dataObj.participant = selected ? selected.participant : "";
                }

                /**
                 * Обновление справочника
                 * @param object - тип справочника
                 * @param value - введенные значения
                 * @param refreshScrolling - обновление данных при прокрутке
                 * @param needToAdd - необходимо ли загрузить данные (fix переоткрытия спрочников)
                 * @param searchValue - переменная поиска
                 */
                function refreshDictFn(object, value, refreshScrolling, needToAdd, searchValue) {
                    if (!value) {
                        if (object === 'rBIK') {
                            value = searchValue;
                        }
                    }
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling, null, needToAdd)
                }

                /*
                 * Добавить файлы по типу вложения
                 * @param files - файлы
                 * @param type - тип вложения
                 * */
                function addFileFn(files, type) {
                    if (!$scope.model.documents) $scope.model.documents = [];
                    doAddFile($scope.model.documents, files)

                    /*
                    * Добавить файлы в модель (основные или дополнительные)
                    * model - модель куда добавить
                    * */
                    function doAddFile(model, files) {
                        angular.forEach(model, item => {
                            if (item.type === type) {
                                angular.forEach(files, file => {
                                    item.files.push(
                                        {
                                            created: file.created,
                                            fileName: file.fileName,
                                            id: file.id,
                                            mimeType: file.mimeType,
                                            sended: file.sended,
                                            size: file.size,
                                            state: file.state,
                                            type: file.type
                                        }
                                    )
                                })
                            }
                        })
                    }

                }

                /*
                * Подготовляем документы для сохранения
                * */
                function getDocumentFromSave(model) {
                    const documents = [];
                    /*
                    * Информация по основным документам
                    * */
                    doGetDocument(documents, model.documents);
                    /**
                     * Информация по копиям паспортов (паспорта с типом doc_principalPassport)
                     */
                    const principalPassportDocument = bankGuaranteeVTBSrv.getPrincipalPassportDocument();
                    documents.push(...principalPassportDocument);
                    /*
                    * Преобразовать документы для сохранения
                    * */
                    function doGetDocument(list, from) {
                        angular.forEach(from, item => {
                            angular.forEach(item.files, file => {
                                if (!file.type) file.type = item.type;
                                list.push(file)
                            })
                        })
                    }

                    return documents;
                }

                /*
                * Проверка документов
                * Можно добавить только 10 документов в один блок
                *
                * return {
                *   result:true, - результат проверки
                *   list:[] - список блоков в которых ошибка
                * }
                * */
                function doCheckCountDocument(data) {
                    const resultCheck = {result: true, list: []};
                    /*
                    * Проверка основных документов
                    * */
                    checkDocument(resultCheck, data.documents);

                    function checkDocument(resultCheck, from) {
                        angular.forEach(from, function (item) {
                            if (item.files && item.files.length > 10) {
                                resultCheck.result = false;
                                resultCheck.list.push(item.label);
                            }
                        });
                    }

                    return resultCheck;
                }

                /*
                * Проверяем кол-во вложений и формируем сообщение с ошибкой
                * */
                function checkCountDocument(data) {
                    const checkSizeDoc = doCheckCountDocument(data);
                    if (!checkSizeDoc.result) {
                        let msg = '';
                        if (checkSizeDoc.list.length === 1) {
                            msg = 'На вкладке "Документы" в разделе "' + checkSizeDoc.list[0] + '" добавлено больше 10 вложений ';
                        } else {
                            msg = 'На вкладке "Документы" в разделах: ';
                            angular.forEach(checkSizeDoc.list, item => {
                                msg += '<br>"' + item + '"';
                            });
                            msg += '<br>добавлено больше 10 вложений ';
                        }
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsgWithBtn(msg, 'Перейти', $scope.setActiveTab,
                            'documents');
                        $scope.modelView.doingSaveOrSend = false;
                    }
                    return checkSizeDoc.result;
                }


                /*
                * Очитсить модель адрес при смене типа
                * */
                function clearAdressFn(data) {
                    data.rent = {};
                    data.property = {};
                }

                /**
                 * Удаление вложения
                 * @param model
                 */
                function delPassportDocFn(model){
                    bankGuaranteeVTBSrv.delPassportDoc(model, $scope.model.principal);
                }

                /**
                 * Удаление доп поля если не выбрано "ИНОЕ"
                 * @param selectNameModel - имя поля в модели(key in model)
                 * @param deleteName - имя поля для удаления из модели(key in model)
                 * @param model - модель данных где будем удалять и искать
                 *
                 */
                function deleteOtherFieldFn(selectNameModel, deleteName, model) {
                    if (model[selectNameModel] !== 'other') {
                        delete model[deleteName];
                    }
                }

                function downloadFileFn(url, fileName) {
                    // TODO: поправить урл и поля для согласия если нужны
                    const model = $scope.model;
                    if (url === 'agreement') {
                        if ($scope.model.principal && $scope.model.principal.principalSigner && $scope.model.principal.principalSigner.lastName &&
                            $scope.model.principal.principalSigner.firstName && $scope.model.principal.principalSigner.secondName &&
                            $scope.model.principal.principalSigner.registrationAddress && $scope.model.principal.principalSigner.identityDocument &&
                            $scope.model.principal.principalSigner.identityDocument.number &&
                            $scope.model.principal.principalSigner.identityDocument.issuingAuthority &&
                            $scope.model.principal.principalSigner.identityDocument.issuingAuthorityCode &&
                            $scope.model.principal.principalSigner.identityDocument.series &&
                            $scope.model.principal.principalSigner.identityDocument.issuedDate) {
                            url = '/bankguarantee/vtb/orders/downloadOrderFile/doc_persDataAgree';
                            const dataForAgreement = getModelFromSave(angular.copy($scope.model));
                            httpSrv.downloadFile(url, 'согласие на обработку персональных данных.docx', dataForAgreement);
                        } else {
                            $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Заполненые не все поля для согласия');
                        }
                    } else if (url === 'doc_order') {
                            if (![model.principal, model.principal.companyHead, model.bankGuarantee, model.principal.contactPerson].some((e)=>!e) &&
                                ![model.principal.inn, model.principal.ogrn, model.principal.companyHead.identityDocument,
                                model.principal.companyHead.lastName, model.principal.companyHead.firstName,
                                model.principal.companyHead.identityDocument.series, model.principal.companyHead.identityDocument.number,
                                model.principal.companyHead.identityDocument.issuedDate, model.principal.companyHead.identityDocument.issuingAuthority,
                                model.principal.companyHead.identityDocument.issuingAuthorityCode, model.principal.companyHead.birthDate,
                                model.principal.companyHead.birthPlace, model.principal.companyHead.registrationAddress,
                                model.principal.companyHead.actualAddress, model.principal.bankAccounts,
                                model.principal.bankAccounts.bank, model.principal.bankAccounts.number,
                                model.principal.contactPerson.firstName, model.principal.contactPerson.lastName,
                                model.principal.contactPerson.cellPhone, model.principal.contactPerson.email,
                                model.bankGuarantee.subject, model.bankGuarantee.bankGuaranteeType, model.bankGuarantee.bankGuaranteeSum, model.bankGuarantee.requiredExecutionDate,
                                model.bankGuarantee.startDate, model.bankGuarantee.endDate, model.principal.companyBeneficiariesVTB].some((e)=>!e) &&
                                ![model.principal.bankAccounts.bank.bic, model.principal.bankAccounts.bank.participant].some((e)=>!e) &&
                                ((model.principal.inn.length===10 && model.principal.fullName &&
                                model.principal.kpp && checkAddress(model.principal.addressesFac) && checkAddress(model.principal.addressesUr) &&
                                model.principal.foundersVTB && checkFounders(model.principal.foundersVTB)) || model.principal.inn.length!==10) &&
                                checkBeneficiaries(model.principal.companyBeneficiariesVTB)
                            )
                        {
                            url = '/bankguarantee/vtb/orders/downloadOrderFile/doc_order';
                            const dataForAgreement = getModelFromSave(angular.copy($scope.model));
                            httpSrv.downloadFile(url, 'Заявление на банковскую гарантию.pdf', dataForAgreement);
                        } else {
                            $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Заполнены не все необходимые для Заявления поля');
                        }
                    } else {
                        httpSrv.downloadFile(url, fileName)
                    }
                }

                // Добавить полей для проверок
                function checkBeneficiaries(beneficiaries){
                    return !beneficiaries.find(b => !b.firstName || !b.secondName || !b.actualAddress);
                }
                
                function checkFounders(founders) {
                    let checkResult = false;

                    if(!founders || founders.length===0) return false;

                    angular.forEach(founders, founder=>{
                        checkResult |= founder.foundersTemp.type==='foundersPersons' ? checkPersons(founder) : checkCompanies(founder)
                    })
                    return !checkResult;
                    
                    function checkPersons(founder){
                        return [founder.personAttributes, founder.personAttributes.citizenship, founder.personAttributes.countryOfResidence,
                            founder.personAttributes.gender, founder.identityDocument,
                            founder.identityDocument.identityDocumentType, founder.identityDocument.issuingAuthorityCode,
                            founder.identityDocument.issuedDate, founder.identityDocument.issuingAuthority, founder.identityDocument.series,
                            founder.identityDocument.number, founder.lastName,
                            founder.firstName, founder.secondName, founder.birthPlace, founder.birthDate,
                            founder.registrationAddress, founder.actualAddress, founder.sharePercent,
                            founder.foundersTemp, founder.foundersTemp.type, founder.contacts, founder.contacts.email, founder.contacts.phone].some((e)=>!e);
                    }
                    function checkCompanies(founder){
                        return [founder.fullName, founder.inn, founder.kpp, founder.ogrn, founder.sharePercent].some((e)=>!e);
                    }
                }


                function checkAddress(address){
                    return address.address;
                }

                function getProductTypeFn(type) {
                    const productType = $scope.getDict('productType');

                    return (productType.find(function (name) {
                        return name.code === type
                    }) || {}).name
                }

                function fillCheckboxFn(data) {
                    return !!data;
                }

                function hasRelationsExtraFieldsFn(data) {
                    if (!data) {
                        delete $scope.model.principal.companyInfo.registrationPlace;
                        delete $scope.model.principal.companyInfo.relationsName;
                        delete $scope.model.principal.companyInfo.relationsShare;
                        delete $scope.model.principal.companyInfo.relationsType;
                    }
                }

                function guaranteeReceivingWayExtraFieldsFn(data) {
                    if (data !== 'dhl') {
                        delete $scope.model.bankGuarantee.gettingAddress;
                        delete $scope.model.bankGuarantee.gettingPhone;
                        delete $scope.model.bankGuarantee.gettingFio;
                        delete $scope.model.bankGuarantee.gettingInfo;
                    }
                }

                function goBackFn() {
                    if ($scope.modelView.isAgent) {
                        $state.go('bankGuaranteesVTB', {
                            participantInfo: $scope.modelView.participantInfo,
                            participantSelected: $scope.modelView.participantSelected
                        });
                    } else {
                        history.back();
                    }
                }

                /*
                * Открытие или создание новой строки
                * type - тип состояния
                * data - данные (если null - создание)
                * index - индекс в массиве
                * */
                function clickRowFn(type, data, index) {
                    //data пустая когда создание
                    const isNew = data === null;
                    bankGuaranteeSrv.setTableForm(data, type, isNew, $scope.modelView.isReadOnly, index);
                    $scope.setActiveLevel('table');
                }

                /*
                * Сохранение анкеты
                * */
                function saveFn(models) {
                    if (!$scope.modelView.doingSaveOrSend) {
                        $scope.modelView.doingSaveOrSend = true;
                    } else {
                        return;
                    }
                    //оишбки кол-ва вложений
                    if (!checkCountDocument(models)) {
                        $scope.modelView.doingSaveOrSend = false;
                        return;
                    }

                    //форма создания
                    const isAppCreate = getAppCreate();
                    //форма создания копии
                    const isAppCopy = getAppCopy();
                    //форма создания агентом
                    const isAppAgent = getAppAgent();
                    const model = getModelFromSave(angular.copy(models));
                    const agreement = getAgreement(model);
                    if (agreement) {
                        model.agreement = agreement;
                    }
                    model.isAgreement = $scope.modelView.agreementCheck;
                    model.isDocOrder = $scope.modelView.docOrder;
                    //форма создания агентом - проставляем id пользователя, за которого агент заполняем бг
                    if (isAppAgent) {
                        if ($stateParams.participantId) {
                            model.participantId = $stateParams.participantId;
                            model.participantName = $scope.modelView.participantInfo.fullName; //наименование участника - запрашиваем при открытии
                            model.participantInn = $scope.modelView.participantInfo.inn;
                            model.participantOgrn = $scope.modelView.participantInfo.ogrn;
                            model.participantType = $scope.modelView.participantInfo.typeOrg;
                            model.agentName = $scope.user.fullName;//наименование агента - берем из профиля
                        }
                        if ($stateParams.agent)
                            model.agent = $stateParams.agent;
                    } else {
                        model.participantName = $scope.user.fullName;
                        model.participantInn = $scope.user.inn;
                        model.participantOgrn = $scope.user.ogrn;
                        model.participantType = $scope.user.typeOrg;
                    }

                    // TODO: $stateParams.participantId) лишний параметр
                    bankGuaranteeVTBSrv.save(model, $stateParams.id, $scope.modelView.isRefresh, false, $stateParams.participantId).then(function (response) {
                        if (response.data && response.data.success) {
                            /*
                            * Была форма создания, после сохранения открываем по id
                            * */
                            if (isAppCreate || isAppCopy) {
                                if (isAppAgent) {
                                    $state.go('bankGuaranteeAppVTBAgentOpen', {
                                        id: response.data.result.id,
                                        participantId: $stateParams.participantId,
                                        participantSelected: $scope.modelView.participantSelected,
                                        successSave: true
                                    }, {location: 'replace'});
                                } else {
                                    $state.go('bankGuaranteeAppVTBOpen', {
                                        id: response.data.result.id,
                                        successSave: true
                                    }, {location: 'replace'});
                                }

                            } else {
                                $scope.alertObjbankGuaranteeApp = alertSrv.getSuccessMsg(response.data.result);
                            }
                            $scope.modelView.doingSaveOrSend = false;
                        } else {
                            $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Ошибка!');
                            $scope.modelView.doingSaveOrSend = false;
                            console.log(response)
                        }
                    }, function (reason) {
                        console.log(reason);
                        $scope.modelView.doingSaveOrSend = false;
                    })

                }

                /*
                * Перейи на первое не валидное поле
                * data.form - форма
                * data.tab - вкладка
                * data.type - тип
                * data.item - элемент в списке
                * data.index - номер позиции
                * */
                $scope.focusFirstInvalid = function (data) {
                    const form = data.form;
                    const tab = data.tab;
                    const type = data.type;
                    const item = data.item;
                    const index = data.index;

                    if ($scope.getActiveTab() !== tab)
                        $scope.setActiveTab(tab);
                    if(type && item && index) {
                        clickRowFn(type, item, index);
                    } else {
                        setTimeout(function () {//отрисовка
                            $scope.submitted = true;

                            if (form && form.$error && form.$error.required && form.$error.required[0]
                                && form.$error.required[0].$$element && form.$error.required[0].$$element[0]) {
                                desktopSrv.scollTop();
                                form.$error.required[0].$$element[0].focus();
                            }
                        }, 400)
                    }
                };

                /*
                * Подписать и отправить
                *
                **/

                function signSendFn(models) {
                    if (!$scope.modelView.doingSaveAndSend) {
                        $scope.modelView.doingSaveAndSend = true;
                    } else {
                        return;
                    }
                    if ($scope.bankGuarantee.form && $scope.bankGuarantee.form.$invalid) {
                        showRequireFieldErrorMessage($scope.bankGuarantee.form, 'bankGuarantee');
                        return;
                    }

                    /*
                    * На вкладке "Отчетность" необходимы два заполненых периуда
                    * */
                    let principalBuhCheck = true;
                    angular.forEach(models.principal.principalBuhPeriods, function (item) {
                        if (!item.date) principalBuhCheck = false;
                    });
                    if (!principalBuhCheck) {
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsgWithBtn('На вкладке "Отчетность" заполнены не все периоды. ', 'Перейти', $scope.setActiveTab,
                            'principalBuh');
                        $scope.modelView.doingSaveAndSend = false;
                        return;
                    }
                    /*
                    * Проверяем формы и показываем ошибки
                    * */
                    if ($scope.companyInfo.form && $scope.companyInfo.form.$invalid) {
                        showRequireFieldErrorMessage($scope.companyInfo.form, 'companyInfo');
                        return;
                    }
                    if ($scope.companyManagement.form && $scope.companyManagement.form.$invalid) {
                        showRequireFieldErrorMessage($scope.companyManagement.form, 'companyManagement');
                        return;
                    }
                    if ($scope.principalBuh.form && $scope.principalBuh.form.$invalid) {
                        showRequireFieldErrorMessage($scope.principalBuh.form, 'principalBuh');
                        return;
                    }
                    if ($scope.principalSigner.form && $scope.principalSigner.form.$invalid) {
                        showRequireFieldErrorMessage($scope.principalSigner.form, 'principalSigner');
                        return;
                    }
                    if (!models.principal.companyBeneficiariesVTB || models.principal.companyBeneficiariesVTB.length === 0) {
                        showRequireFieldErrorMessage($scope.bankGuarantee.form, 'companyBeneficiaries');
                        return;
                    }

                    let failed = models.principal.companyBeneficiariesVTB.find(item => item.id && !(bankGuaranteeVTBSrv.findPassportDocById(item.id) || bankGuaranteeVTBSrv.findPassportDocByFSId(item.id)));
                    if(failed) {
                        const index = models.principal.companyBeneficiariesVTB.findIndex(item => item.id && !(bankGuaranteeVTBSrv.findPassportDocById(item.id) || bankGuaranteeVTBSrv.findPassportDocByFSId(item.id)));
                        showRequirePassportErrorMessage($scope.bankGuarantee.form, 'companyBeneficiaries', 'companyBeneficiariesVTB', failed, index);
                        return;
                    }
                    
                    if (!models.principal.foundersVTB || models.principal.foundersVTB.length === 0) {
                        showRequireFieldErrorMessage($scope.bankGuarantee.form, 'founders');
                        return;
                    }

                    failed = models.principal.foundersVTB.find(item => item.id && !(bankGuaranteeVTBSrv.findPassportDocById(item.id) || bankGuaranteeVTBSrv.findPassportDocByFSId(item.id)));
                    if(failed) {
                        const index = models.principal.foundersVTB.findIndex(item => item.id && !(bankGuaranteeVTBSrv.findPassportDocById(item.id) || bankGuaranteeVTBSrv.findPassportDocByFSId(item.id)));
                        showRequirePassportErrorMessage($scope.bankGuarantee.form, 'founders', 'foundersVTB', failed, index);
                        return;
                    }
                    if (models.principal.principalSigner && !models.principal.principalSigner.id) {
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsgWithBtn(
                            'На вкладке "Подписант" необходимо добавить копию паспорта. ',
                            'Перейти',
                            $scope.setActiveTab,
                            'principalSigner');
                        $scope.modelView.doingSaveAndSend = false;
                        return;
                    }
                    if (models.principal.companyHead && !models.principal.companyHead.id) {
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsgWithBtn(
                            'На вкладке "Органы управления" необходимо добавить копию паспорта. ',
                            'Перейти',
                            $scope.setActiveTab,
                            'companyManagement');
                        $scope.modelView.doingSaveAndSend = false;
                        return;
                    }
                    // TODO: возможно стоит проверять списки foundersPersonsVTB и companyBeneficiariesVTB

                    //Для анкеты Order проверяем наличие обязательных вложений
                    //и заполненость всех обязательных полей в таблице 'Заказчики' на вкалдке 'Гарантия'
                    let checkDoc = true;
                    angular.forEach(models.documents, function (item) {
                        if (item.isRequired) {
                            if (!item.files || (item.files && item.files.length === 0)) checkDoc = false;
                        }
                    });
                    if (!checkDoc) {
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsgWithBtn('Добавьте все обязательные вложения. ', 'Перейти', $scope.setActiveTab,
                            'documents');
                        $scope.modelView.doingSaveAndSend = false;
                        return;
                    }

                    //оишбки кол-ва вложений
                    if (!checkCountDocument(models)) {
                        $scope.modelView.doingSaveAndSend = false;
                        return;
                    }
                    if ($scope.showDecision()) {
                        if (!$scope.model.decision.resultCode) {
                            $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Необходимо выбрать решение.');
                            $scope.modelView.doingSaveAndSend = false;
                            return;
                        }

                        let isCommentRequired = false;
                        angular.forEach($scope.modelView.decisionOptions, function (item) {
                            if (item.code === $scope.model.decision.resultCode) {
                                isCommentRequired = item.isCommentRequired;
                            }
                        });

                        if (isCommentRequired && !$scope.model.decision.comment) {
                            $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Для данного решения необходим комментарий.');
                            $scope.modelView.doingSaveAndSend = false;
                            return;
                        }

                        if ($scope.model.decision.comment === '') {
                            delete $scope.model.decision.comment;
                        }
                    }
                    const certId = $scope.auth.certId;
                    /*
                    * Сохраняем форму, а затем подписываем
                    * */
                    const model = getModelFromSave(angular.copy(models));
                    if (!$scope.modelView.agreementCheck) {
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Необходимо согласие на обработку персональных данных');
                        $scope.modelView.doingSaveAndSend = false;
                        return;
                    }
                    if (!$scope.modelView.docOrder) {
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Необходимо подтверждение информации, указанной в Заявлении о предоставлении Гарантии');
                        $scope.modelView.doingSaveAndSend = false;
                        return;
                    }
                    model.participantName = $scope.user.fullName;
                    model.participantInn = $scope.user.inn;
                    model.participantOgrn = $scope.user.ogrn;
                    model.participantType = $scope.user.typeOrg;
                    const agreement = getAgreement(model);
                    if (agreement) {
                        model.isAgreement = $scope.modelView.agreementCheck;
                        model.isDocOrder = $scope.modelView.docOrder;
                        model.agreement = agreement;
                        bankGuaranteeVTBSrv.save(model, $stateParams.id, $scope.modelView.isRefresh, true).then(function (response) {
                            if (response.data && response.data.success) {
                                /*
                                * Подписываем
                                * */
                                bankGuaranteeVTBSrv.getDocumentsDataForSign(certId, $stateParams.id).then(
                                    function (response) {
                                        if (response.data && response.data.success) {
                                            sendAndSign(response.data.result);
                                        }
                                    },
                                    function (reason) {
                                        console.log(reason);
                                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Ошибка! ' + reason);
                                        $scope.modelView.doingSaveAndSend = false;
                                    }
                                );
                            } else {
                                $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Ошибка!');
                                console.log(response);
                                $scope.modelView.doingSaveAndSend = false;
                            }
                        }, function (reason) {
                            console.log(reason);
                            $scope.modelView.doingSaveAndSend = false;
                        })
                    } else {
                        $scope.modelView.doingSaveAndSend = false;
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Заполненые не все поля для согласия');
                    }

                    function sendAndSign(data) {
                        bankGuaranteeVTBSrv.signDocument(data.dataList, $scope.auth.certId).then(
                            function (signData) {
                                bankGuaranteeVTBSrv.sendSignedDocuments(signData, $stateParams.id).then(function (result) {
                                    if (result.data && result.data.success) {
                                        bankGuaranteeVTBSrv.send($stateParams.id).then(function (response) {
                                            $scope.modelView.doingSaveAndSend = false;
                                            if (response.data && response.data.success) {
                                                const result = response.data.result;
                                                if (result.state === 'draft') {
                                                    //отправка с ошибками
                                                    if (result.currentMessage) {
                                                        initErrorMess(result.currentMessage);
                                                    }
                                                } else {
                                                    //успешная отправка
                                                    let param = {
                                                        saveParam: {
                                                            number: model.bankGuarantee.purchaseNumber,
                                                            date: $scope.modelView.created
                                                        }
                                                    }
                                                    // Под агентом сохраняем признак выбранной анкеты участником
                                                    if ($scope.modelView.isAgent) {
                                                        param.participantSelected = $scope.modelView.participantSelected;
                                                        param.participantInfo = $scope.modelView.participantInfo;
                                                    }
                                                    $state.go('bankGuaranteesVTB', param);
                                                }
                                            } else {
                                                $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Ошибка!');
                                                console.log(response)
                                            }
                                        }, function (reason) {
                                            $scope.modelView.doingSaveAndSend = false;
                                            console.log(reason);
                                        })
                                    } else {
                                        $scope.modelView.doingSaveAndSend = false;
                                    }
                                })
                            },
                            function (reason) {
                                if (typeof reason === 'string') {
                                    $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg("Ошибка подписи документа. " + reason);

                                } else {
                                    $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                                }
                                $scope.modelView.doingSaveAndSend = false;
                                console.log(reason)
                            }
                        );
                    }

                    /*
                    *  Сообщение о незаполенном поле
                    */
                    function showRequireFieldErrorMessage(form, tab) {
                        $scope.submitted = true;
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsgWithBtn('Необходимо заполнить обязательные поля на форме. ', 'Перейти', $scope.focusFirstInvalid,
                            {form: form, tab: tab});
                        $scope.modelView.doingSaveAndSend = false;
                    }

                    /*
                    *  Сообщение об отсутсвующем вложении
                    */
                    function showRequirePassportErrorMessage(form, tab, type, item, index) {
                        $scope.submitted = true;
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsgWithBtn('Необходимо добавить копию паспорта. ', 'Перейти', $scope.focusFirstInvalid,
                            {form: form, tab: tab, type: type, item: item, index: index});
                        $scope.modelView.doingSaveAndSend = false;
                    }
                }

                /*
                * Отобразить сообщение об ошибках
                * */
                function initErrorMess(data) {
                    let currentMessage, mess = '<br>';
                    try {
                        currentMessage = JSON.parse(data);
                        angular.forEach(currentMessage, function (value, key) {
                            mess += (key + ": " + value + '<br>');
                        });
                    } catch {
                        currentMessage = data.split(';');
                        if (typeof currentMessage === 'object') {
                            angular.forEach(currentMessage, function (value) {
                                mess += value + '<br>';
                            });
                        } else {
                            mess = data;
                        }
                    }
                    if (mess.length > 0) {
                        const errorMsg = 'В анкете обнаружены ошибки. Ознакомьтесь с ними, исправьте и повторно отправьте анкету на получение банковской гарантии.<br>';
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg(errorMsg + mess);
                    }
                }

                /*
                * Получить согласие
                * null если не все поля
                * */
                function getAgreement(model) {
                    //проверка заполненых обязательных полей для согласия и галка согласия (modelView.agreementCheck)
                    if (model.principal && model.principal.principalSigner && model.principal.principalSigner.lastName &&
                        model.principal.principalSigner.firstName && model.principal.principalSigner.secondName &&
                        model.principal.principalSigner.registrationAddress && model.principal.principalSigner.identityDocument &&
                        model.principal.principalSigner.identityDocument.issuingAuthority &&
                        model.principal.principalSigner.identityDocument.issuingAuthorityCode &&
                        model.principal.principalSigner.identityDocument.number &&
                        model.principal.principalSigner.identityDocument.series &&
                        model.principal.principalSigner.identityDocument.issuedDate) {
                        return {
                            signerName: model.principal.principalSigner.lastName + ' ' + model.principal.principalSigner.firstName + ' ' + model.principal.principalSigner.secondName,
                            signerAddress: model.principal.principalSigner.registrationAddress,
                            signerPassport: model.principal.principalSigner.identityDocument.number
                        }
                    } else {
                        $scope.modelView.agreementCheck = false;
                        return null;
                    }
                }

                function checkAgreementFn() {
                    const model = bankGuaranteeSrv.getData();
                    //проверка заполненых обязательных полей для согласия
                    if (model.principal && model.principal.principalSigner && model.principal.principalSigner.lastName &&
                        model.principal.principalSigner.firstName && model.principal.principalSigner.secondName &&
                        model.principal.principalSigner.registrationAddress && model.principal.principalSigner.identityDocument &&
                        model.principal.principalSigner.identityDocument.issuingAuthority &&
                        model.principal.principalSigner.identityDocument.issuingAuthorityCode &&
                        model.principal.principalSigner.identityDocument.number &&
                        model.principal.principalSigner.identityDocument.series &&
                        model.principal.principalSigner.identityDocument.issuedDate) {
                        return true
                    } else {
                        $scope.modelView.agreementCheck = false;
                        return false;
                    }
                }

                function checkDocOrderFn() {
                    const model = bankGuaranteeSrv.getData();
                    //проверка заполненых обязательных полей для согласия
                    if (![model.principal, model.principal.companyHead, model.bankGuarantee, model.principal.contactPerson].some((e)=>!e) &&
                        ![model.principal.inn, model.principal.ogrn, model.principal.companyHead.identityDocument,
                        model.principal.companyHead.lastName, model.principal.companyHead.firstName,
                        model.principal.companyHead.identityDocument.series, model.principal.companyHead.identityDocument.number,
                        model.principal.companyHead.identityDocument.issuedDate, model.principal.companyHead.identityDocument.issuingAuthority,
                        model.principal.companyHead.identityDocument.issuingAuthorityCode, model.principal.companyHead.birthDate,
                        model.principal.companyHead.birthPlace, model.principal.companyHead.registrationAddress,
                        model.principal.companyHead.actualAddress, model.principal.bankAccounts,
                        model.principal.bankAccounts.bank, model.principal.bankAccounts.number,
                        model.principal.contactPerson.firstName, model.principal.contactPerson.lastName,
                        model.principal.contactPerson.cellPhone, model.principal.contactPerson.email,
                        model.bankGuarantee.subject, model.bankGuarantee.bankGuaranteeType, model.bankGuarantee.bankGuaranteeSum, model.bankGuarantee.requiredExecutionDate,
                        model.bankGuarantee.startDate, model.bankGuarantee.endDate, model.principal.companyBeneficiariesVTB].some((e)=>!e) &&
                        ![model.principal.bankAccounts.bank.bic, model.principal.bankAccounts.bank.participant].some((e)=>!e) &&
                        ((model.principal.inn.length===10 && model.principal.fullName &&
                        model.principal.kpp && checkAddress(model.principal.addressesFac) && checkAddress(model.principal.addressesUr) &&
                        model.principal.foundersVTB && checkFounders(model.principal.foundersVTB)) || model.principal.inn.length!==10) &&
                        checkBeneficiaries(model.principal.companyBeneficiariesVTB)
                    )
                    {
                        return true
                    } else {
                        $scope.modelView.docOrder = false;
                        return false;
                    }
                }

                function showDecisionFn() {
                    return $scope.modelView.taskDefinitionKey &&
                        ($scope.modelView.taskDefinitionKey.indexOf('UserTaskInitiatorRework') >= 0 || $scope.modelView.taskDefinitionKey == 'UserTaskInitiatorInput') &&
                        $scope.modelView.decisionOptions && $scope.modelView.decisionOptions.length > 0;
                }

                /**
                 * Удалить элемент из таблицы
                 * Удалить вложение
                 * Если есть type необходимо восстановить отображение элемента в списке
                 * @param model - модель
                 * @param index - номер строки для удаления
                 * @param type - тип документа
                 */
                function delRowFn(model, index, type) {
                    if(type){
                        bankGuaranteeVTBSrv.deletePrincipalPassportDoc(model[index].id)
                    }
                    model.splice(index, 1);
                }

                /*
                * Получить модель для сохранения
                * */
                function getModelFromSave(model) {
                    desktopSrv.removeKeys(model, 'deff__');
                    if (model.principal) {
                        // Заполняем тип организации заполняющий анкету
                        model.principal.orgType = $scope.modelView.typeOrg;
                        /*
                        * * Преобразуем дату в формат yyyy-MM-dd и удаляем пустые поля из модели
                        * */
                        convertDateAndEmptyFields(model.principal);
                        convertDateAndEmptyFields(model.bankGuarantee);
                        if (model.principal.okved) {
                            model.principal.okvedName = model.principal.okved.description;
                            model.principal.okved = model.principal.okved.id;
                        }
                        if (model.principal.inn && model.principal.inn.length !== 10) {
                            //оставляем кпп только у ЮЛ
                            delete model.principal.kpp;
                        }
                        /*
                        * Банк
                        * */
                        if (model.principal.bankAccounts && model.principal.bankAccounts.bank) {
                            const bankObj = {
                                bik: model.principal.bankAccounts.bank.bic,
                                corrNumber: model.principal.bankAccounts.bank.corrNumber,
                                name: model.principal.bankAccounts.bank.participant
                            };
                            delete model.principal.bankAccounts.bank;
                            model.principal.bankAccounts.bank = bankObj;
                        }
                        if (model.principal.addressesUr && model.principal.addressesUr.rent && model.principal.addressesUr.rent.landlordBankReq) {
                            const bankObj = {
                                bik: model.principal.addressesUr.rent.landlordBankReq.bic,
                                corrNumber: model.principal.addressesUr.rent.landlordBankReq.corrNumber,
                                name: model.principal.addressesUr.rent.landlordBankReq.participant
                            };
                            delete model.principal.addressesUr.rent.landlordBankReq;
                            model.principal.addressesUr.rent.landlordBankReq = bankObj;
                        }
                        if (model.principal.addressesFac && model.principal.addressesFac.rent && model.principal.addressesFac.rent.landlordBankReq) {
                            const bankObj = {
                                bik: model.principal.addressesFac.rent.landlordBankReq.bic,
                                corrNumber: model.principal.addressesFac.rent.landlordBankReq.corrNumber,
                                name: model.principal.addressesFac.rent.landlordBankReq.participant
                            };
                            delete model.principal.addressesFac.rent.landlordBankReq;
                            model.principal.addressesFac.rent.landlordBankReq = bankObj;
                        }
                        if (model.principal.addressesPost && model.principal.addressesPost.rent && model.principal.addressesPost.rent.landlordBankReq) {
                            const bankObj = {
                                bik: model.principal.addressesPost.rent.landlordBankReq.bic,
                                corrNumber: model.principal.addressesPost.rent.landlordBankReq.corrNumber,
                                name: model.principal.addressesPost.rent.landlordBankReq.participant
                            };
                            delete model.principal.addressesPost.rent.landlordBankReq;
                            model.principal.addressesPost.rent.landlordBankReq = bankObj;
                        }
                        if(model.principal.companyHead && model.principal.companyHead.personAttributes){
                            model.principal.companyHead.personAttributes.isRpdl = model.principal.companyHead.personAttributes.isRpdl || false;
                        }
                        /*
                        Преобразуем:
                        model.principal.foundersVTB c типом foundersCompanies -> model.principal.founders.foundersCompanies
                        model.principal.foundersVTB с типом foundersPersons -> model.principal.founders.foundersPersons
                        * */
                        if (model.principal.foundersVTB) {
                            const founders = {};
                            angular.forEach(model.principal.foundersVTB, function (item) {
                                const data = angular.copy(item);
                                delete data.foundersTemp;
                                if (!founders[item.foundersTemp.type]) founders[item.foundersTemp.type] = [];
                                founders[item.foundersTemp.type].push(data);
                            });
                            delete model.principal.foundersVTB;
                            model.principal.founders = founders;
                        }
                        /*
                        Преобразуем:
                        model.principal.companyBeneficiariesVTB -> model.principal.companyBeneficiaries
                        */
                        if (model.principal.companyBeneficiariesVTB) {
                            model.principal.companyBeneficiaries = model.principal.companyBeneficiariesVTB;
                            delete model.principal.companyBeneficiariesVTB;
                        }
                        /*
                        Преобразуем:
                        model.principal.collegialExecutiveBodyMember -> model.principal.companyManagement.collegialExecutiveBodyMember
                        model.principal.supervisoryBoardMember -> model.principal.companyManagement.supervisoryBoardMember
                        model.principal.principalBuhPeriods -> model.principal.principalBuh.periods;
                        * */
                        if (model.principal.collegialExecutiveBodyMember) {
                            if (!model.principal.companyManagement) model.principal.companyManagement = {};
                            model.principal.companyManagement.collegialExecutiveBodyMember = model.principal.collegialExecutiveBodyMember
                            delete model.principal.collegialExecutiveBodyMember;
                        }
                        if (model.principal.supervisoryBoardMember) {
                            if (!model.principal.companyManagement) model.principal.companyManagement = {};
                            model.principal.companyManagement.supervisoryBoardMember = model.principal.supervisoryBoardMember
                            delete model.principal.supervisoryBoardMember;
                        }
                        if (model.principal.principalBuhPeriods) {
                            if (!model.principal.principalBuh) model.principal.principalBuh = {};
                            model.principal.principalBuh.periods = [];
                            angular.forEach(model.principal.principalBuhPeriods, item => {
                                //заполняем период если есть дата периода
                                if (item.date) {
                                    const period = {
                                        date: item.date,
                                        finReport: [item.list]
                                    };
                                    model.principal.principalBuh.periods.push(period);
                                }
                            });
                            delete model.principal.principalBuhPeriods;
                        }
                        /*
                       Преобразуем:
                       model.principal.beneficiaries -> model.bankGuarantee.beneficiaries[]
                       model.principal.bails -> model.bankGuarantee.bails
                       */
                        if (!model.bankGuarantee) model.bankGuarantee = {};
                        if (model.principal.beneficiaries) {
                            if (model.principal.beneficiaries.okved) {
                                model.principal.beneficiaries.okvedName = model.principal.beneficiaries.okved.description;
                                model.principal.beneficiaries.okved = model.principal.beneficiaries.okved.id;
                            }
                            model.bankGuarantee.beneficiaries = [model.principal.beneficiaries];
                            delete model.principal.beneficiaries;
                        }
                        if (model.principal.bails) {
                            model.bankGuarantee.bails = model.principal.bails;
                            delete model.principal.bails;
                        }
                        /*
                        * Оставляем в списке okpd только name и code
                        * */
                        if (model.bankGuarantee.okpd) {
                            angular.forEach(model.bankGuarantee.okpd, function (item) {
                                angular.forEach(item, function (value, key) {
                                    if (!(key === 'code' || key === 'name')) delete item[key]
                                })
                            })
                        }
                        convertToNumber(model.bankGuarantee, 'prepaymentAmount');
                        convertToNumber(model.bankGuarantee, 'securityForGuaranteePeriodDays');
                        convertToNumber(model.bankGuarantee, 'similarContractsNumber');
                        convertToString(model.bankGuarantee, 'lotNumber');
                        convertToString(model.bankGuarantee, 'purchaseNumber');
                        if (model.bankGuarantee.ikzList) {
                            convertToString(model.bankGuarantee, 'ikzList');
                            model.bankGuarantee.ikzList = [model.bankGuarantee.ikzList];
                        }

                        /*
                        * Приводим контакты
                        * */
                        if (model.principal.companyBeneficiaries && model.principal.companyBeneficiaries.length > 0) {
                            angular.forEach(model.principal.companyBeneficiaries, function (item) {
                                contractToArray(item);
                            })
                        }
                        if (model.principal.companyHead)
                            contractToArray(model.principal.companyHead);
                        if (model.principal.accountant)
                            contractToArray(model.principal.accountant);
                        if (model.principal.companyManagement && model.principal.companyManagement.collegialExecutiveBodyMember && model.principal.companyManagement.collegialExecutiveBodyMember.length > 0) {
                            angular.forEach(model.principal.companyManagement.collegialExecutiveBodyMember, function (item) {
                                contractToArray(item);
                            })
                        }
                        if (model.principal.companyManagement && model.principal.companyManagement.supervisoryBoardMember && model.principal.companyManagement.supervisoryBoardMember.length > 0) {
                            angular.forEach(model.principal.companyManagement.supervisoryBoardMember, function (item) {
                                contractToArray(item);
                            })
                        }
                        if (model.principal.founders && model.principal.founders.foundersPersons && model.principal.founders.foundersPersons.length > 0) {
                            angular.forEach(model.principal.founders.foundersPersons, function (item) {
                                contractToArray(item);
                            })
                        }
                        if (model.bankGuarantee.bails && model.bankGuarantee.bails.length > 0) {
                            angular.forEach(model.bankGuarantee.bails, function (item) {
                                if (item.bailType === 'company')
                                    contractToArray(item.signer);
                            })
                        }
                        if (model.principal.principalSigner) {
                            contractToArray(model.principal.principalSigner);
                            convertToNumber(model.principal.principalSigner, 'proxyGuaranteeAmount');
                            convertToNumber(model.principal.principalSigner, 'proxyAgreementAmount');
                        }
                        /*
                        * Преобразуем адрес два объекта в один массив
                        * */
                        if (model.principal.addressesUr || model.principal.addressesFac || model.principal.addressesPost)
                            model.principal.addresses = [];
                        if (model.principal.addressesUr) {
                            model.principal.addressesUr.addressType = 'legal';
                            model.principal.addresses.push(model.principal.addressesUr);
                            delete model.principal.addressesUr;
                        }
                        if (model.principal.addressesFac) {
                            model.principal.addressesFac.addressType = 'actual';
                            model.principal.addresses.push(model.principal.addressesFac);
                            delete model.principal.addressesFac;
                        }
                        if (model.principal.addressesPost) {
                            model.principal.addressesPost.addressType = 'postal';
                            model.principal.addresses.push(model.principal.addressesPost);
                            delete model.principal.addressesPost;
                        }
                        /*
                        * Преобразуем bankAccounts obj to array
                        * */
                        if (model.principal.bankAccounts) {
                            const obj = angular.copy(model.principal.bankAccounts);
                            delete model.principal.bankAccounts;
                            model.principal.bankAccounts = [obj];
                        }
                        if (model.principal.companyInfo) {
                            convertToNumber(model.principal.companyInfo, 'debtToBudgetAmount');
                            convertToNumber(model.principal.companyInfo, 'overdueDebtAmount');
                            convertToNumber(model.principal.companyInfo, 'relationsShare');
                        }

                        /*
                        * Убираем лишние поля из companyInfo.
                        * */
                        if (!model.principal.companyInfo.hasBeneficiariesInfo) {
                            delete model.principal.companyInfo.beneficiariesInfo;
                        }
                        if (!model.principal.companyInfo.isHolding) {
                            delete model.principal.companyInfo.holdingName;
                        }
                        if (!model.principal.companyInfo.hasRating) {
                            delete model.principal.companyInfo.ratingAgency;
                            delete model.principal.companyInfo.rating;
                        }
                        if (!model.principal.companyInfo.overdueDebt) {
                            delete model.principal.companyInfo.overdueDebtAmount;
                        }
                        if (!model.principal.companyInfo.debtToBudget) {
                            delete model.principal.companyInfo.debtToBudgetAmount;
                        }
                        if (!model.principal.companyInfo.regBefore01072002) {
                            delete model.principal.companyInfo.regNumberBefore01072002;
                        }
                        if (!model.principal.companyInfo.hasSalariesDebt && model.principal.staffInfo) {
                            delete model.principal.staffInfo.staffDebts;
                        }
                        if (model.principal.staffInfo) {
                            convertToNumber(model.principal.staffInfo, 'staffDebts');
                            convertToNumber(model.principal.staffInfo, 'averageNumber');
                        }
                        /*
                        * Убираем лишние поля из companyManagement при отсутствии соответствующих галочек
                        * */
                        if (model.principal.companyManagement) {
                            hasCollegialExecutiveBodyExtraFields(model.principal.companyManagement.hasCollegialExecutiveBody);
                            hasSupervisoryBoardExtraFields(model.principal.companyManagement.hasSupervisoryBoard);
                            managementCompanyExtraFields(model.principal.companyManagement.soleExecutiveBody);
                        }
                        if (!model.bankGuarantee.prepaymentExists) {
                            delete model.bankGuarantee.prepaymentAmount;
                        }
                        if (!model.bankGuarantee.isContractConcluded) {
                            delete model.bankGuarantee.tenderContractSubject;
                            delete model.bankGuarantee.contractType;
                            delete model.bankGuarantee.tenderContractRegNumber;
                            delete model.bankGuarantee.tenderContractNumber;
                            delete model.bankGuarantee.tenderContractAmount;
                            delete model.bankGuarantee.tenderContractSignedDate;
                            delete model.bankGuarantee.assessmentProtocolDate;
                            delete model.bankGuarantee.assessmentProtocolName;
                            delete model.bankGuarantee.assessmentProtocolNumber;
                            delete model.bankGuarantee.isBigDeal;
                            delete model.bankGuarantee.similarContractsNumber;
                        }

                    }
                    /*
                    * Проставляем в заявку обновленный список документов
                    * */
                    const documents = getDocumentFromSave(model);
                    delete model.documents;
                    model.documents = documents;
                    return model;

                    /*
                    * Преобразуем дату в формат yyyy-MM-dd и удаляем пустые поля из модели
                    * */
                    function convertDateAndEmptyFields(data) {
                        angular.forEach(data, function (value, key) {
                            if (value instanceof Date) {
                                data[key] = $filter('date')(value, 'yyyy-MM-dd');
                            } else if (value === '' || value === null || angular.isUndefined(value)) {
                                delete data[key];
                            } else if (value instanceof Object) {
                                convertDateAndEmptyFields(value);
                            }
                        })
                    }

                    /*
                    * Преобразуем объект в массив с одним элементом
                    * */
                    function contractToArray(data) {
                        if (data.contacts) {
                            const obj = angular.copy(data.contacts);
                            delete data.contacts;
                            data.contacts = [obj];
                        }
                    }

                    function convertToNumber(data, field) {
                        if (data && data[field]) {
                            data[field] = Number(data[field]);
                        }
                    }

                    function convertToString(data, field) {
                        if (data && data[field]) {
                            data[field] = "" + data[field];
                        }
                    }

                    function managementCompanyExtraFields(data) {
                        if (data !== 'managementCompany') {
                            delete model.principal.companyManagement.managementCompany;
                            delete $scope.model.principal.companyManagement.managementCompany;
                        }
                    }

                    function hasCollegialExecutiveBodyExtraFields(data) {
                        if (!data) {
                            delete model.principal.companyManagement.collegialExecutiveBodyName;
                            delete model.principal.companyManagement.supervisoryBoardName;
                            delete model.principal.collegialExecutiveBodyMember;
                            delete $scope.model.principal.companyManagement.collegialExecutiveBodyName;
                            delete $scope.model.principal.companyManagement.supervisoryBoardName;
                            delete $scope.model.principal.collegialExecutiveBodyMember;
                            delete model.principal.companyManagement.collegialExecutiveBodyMember;
                        }
                    }

                    function hasSupervisoryBoardExtraFields(data) {
                        if (!data) {
                            delete model.principal.supervisoryBoardMember;
                            delete $scope.model.principal.supervisoryBoardMember;
                            delete model.principal.companyManagement.supervisoryBoardMember;
                        }
                    }
                }

                /*
                * Получить модель для отображения
                * */
                function getModelFromView(model) {
                    if (model.principal) {
                        if (model.principal.okved) {
                            model.principal.okved = {
                                description: model.principal.okvedName,
                                id: model.principal.okved
                            }
                        }
                        delete model.principal.okvedName;
                        /*
                        Преобразуем:
                        model.principal.founders.foundersCompanies -> model.principal.foundersVTB c типом foundersCompanies
                        model.principal.founders.foundersPersons -> model.principal.foundersVTB с типом foundersPersons
                        * */
                        if (model.principal.founders) {
                            const foundersCompanies = angular.copy(model.principal.founders.foundersCompanies);
                            const foundersPersons = angular.copy(model.principal.founders.foundersPersons);
                            delete model.principal.founders;
                            model.principal.foundersVTB = [];
                            angular.forEach(foundersCompanies, function (item) {
                                item.foundersTemp = {type: 'foundersCompanies'};
                                model.principal.foundersVTB.push(item)
                            });
                            angular.forEach(foundersPersons, function (item) {
                                item.foundersTemp = {type: 'foundersPersons'};
                                model.principal.foundersVTB.push(item)
                            });
                        }
                        /*
                        * Приводим контакты
                        * */
                        if (model.principal.companyBeneficiaries && model.principal.companyBeneficiaries.length > 0) {
                            angular.forEach(model.principal.companyBeneficiaries, function (item) {
                                contractsToObj(item);
                            })
                        }
                        if (model.principal.companyHead)
                            contractsToObj(model.principal.companyHead);
                        if (model.principal.accountant)
                            contractsToObj(model.principal.accountant);
                        if (model.principal.companyManagement && model.principal.companyManagement.collegialExecutiveBodyMember && model.principal.companyManagement.collegialExecutiveBodyMember.length > 0) {
                            angular.forEach(model.principal.companyManagement.collegialExecutiveBodyMember, function (item) {
                                contractsToObj(item);
                            })
                        }
                        if (model.principal.companyManagement && model.principal.companyManagement.supervisoryBoardMember && model.principal.companyManagement.supervisoryBoardMember.length > 0) {
                            angular.forEach(model.principal.companyManagement.supervisoryBoardMember, function (item) {
                                contractsToObj(item);
                            })
                        }
                        if (model.principal.foundersVTB && model.principal.foundersVTB.length > 0) {
                            angular.forEach(model.principal.foundersVTB, function (item) {
                                if (item.foundersTemp.type === 'foundersPersons')
                                    contractsToObj(item);
                            })
                        }
                        if (model.principal.principalSigner)
                            contractsToObj(model.principal.principalSigner);
                        /*
                        * Преобразуем адрес массив в два объекта
                        * */
                        if (model.principal.addresses && model.principal.addresses.length > 0) {
                            const obj = angular.copy(model.principal.addresses);
                            delete model.principal.addresses;
                            angular.forEach(obj, function (item) {
                                if (item.addressType === 'legal') {
                                    model.principal.addressesUr = item;
                                } else if (item.addressType === 'actual') {
                                    model.principal.addressesFac = item;
                                } else if (item.addressType === 'postal') {
                                    model.principal.addressesPost = item;
                                }
                            })
                        }
                        /*
                        * Преобразуем bankAccounts array to obj
                        * */
                        if (model.principal.bankAccounts && model.principal.bankAccounts.length > 0) {
                            const obj = angular.copy(model.principal.bankAccounts[0]);
                            delete model.principal.bankAccounts;
                            model.principal.bankAccounts = obj;
                            /*
                            * Банк
                            * */
                            if (model.principal.bankAccounts && model.principal.bankAccounts.bank) {
                                const bankObj = {
                                    bic: model.principal.bankAccounts.bank.bik,
                                    corrNumber: model.principal.bankAccounts.bank.corrNumber,
                                    participant: model.principal.bankAccounts.bank.name
                                };
                                delete model.principal.bankAccounts.bank;
                                model.principal.bankAccounts.bank = bankObj;
                            }
                            if (model.principal.addressesUr && model.principal.addressesUr.rent && model.principal.addressesUr.rent.landlordBankReq) {
                                const bankObj = {
                                    bic: model.principal.addressesUr.rent.landlordBankReq.bik,
                                    corrNumber: model.principal.addressesUr.rent.landlordBankReq.corrNumber,
                                    participant: model.principal.addressesUr.rent.landlordBankReq.name
                                };
                                delete model.principal.addressesUr.rent.landlordBankReq;
                                model.principal.addressesUr.rent.landlordBankReq = bankObj;
                            }
                            if (model.principal.addressesFac && model.principal.addressesFac.rent && model.principal.addressesFac.rent.landlordBankReq) {
                                const bankObj = {
                                    bic: model.principal.addressesFac.rent.landlordBankReq.bik,
                                    corrNumber: model.principal.addressesFac.rent.landlordBankReq.corrNumber,
                                    participant: model.principal.addressesFac.rent.landlordBankReq.name
                                };
                                delete model.principal.addressesFac.rent.landlordBankReq;
                                model.principal.addressesFac.rent.landlordBankReq = bankObj;
                            }
                            if (model.principal.addressesPost && model.principal.addressesPost.rent && model.principal.addressesPost.rent.landlordBankReq) {
                                const bankObj = {
                                    bic: model.principal.addressesPost.rent.landlordBankReq.bik,
                                    corrNumber: model.principal.addressesPost.rent.landlordBankReq.corrNumber,
                                    participant: model.principal.addressesPost.rent.landlordBankReq.name
                                };
                                delete model.principal.addressesPost.rent.landlordBankReq;
                                model.principal.addressesPost.rent.landlordBankReq = bankObj;
                            }
                        }

                        /*
                        Преобразуем:
                        model.principal.companyManagement.collegialExecutiveBodyMember -> model.principal.collegialExecutiveBodyMember
                        model.principal.companyManagement.supervisoryBoardMember -> model.principal.supervisoryBoardMember
                        model.principal.principalBuh.periods -> model.principal.principalBuhPeriods -> model.principal.principalBuh.periods;
                        * */
                        if (model.principal.companyManagement && model.principal.companyManagement.collegialExecutiveBodyMember) {
                            model.principal.collegialExecutiveBodyMember = model.principal.companyManagement.collegialExecutiveBodyMember;
                            delete model.principal.companyManagement.collegialExecutiveBodyMember;
                        }
                        if (model.principal.companyManagement && model.principal.companyManagement.supervisoryBoardMember) {
                            model.principal.supervisoryBoardMember = model.principal.companyManagement.supervisoryBoardMember;
                            delete model.principal.companyManagement.supervisoryBoardMember;
                        }
                        /*
                        /*
                        Преобразуем:
                        model.principal.companyBeneficiaries -> model.principal.companyBeneficiariesVTB
                        * */
                        if (model.principal.companyBeneficiaries) {
                            model.principal.companyBeneficiariesVTB = model.principal.companyBeneficiaries;
                            delete model.principal.companyBeneficiaries;
                        }
                        if (model.principal.principalBuh) {
                            model.principal.principalBuhPeriods = [];
                            if (model.principal.principalBuh.periods && model.principal.principalBuh.periods.length > 0) {
                                angular.forEach(model.principal.principalBuh.periods, data => {
                                    if (data.date && data.finReport && data.finReport.length > 0)
                                        model.principal.principalBuhPeriods.push({
                                            date: data.date,
                                            list: data.finReport[0]
                                        })
                                });
                            }
                            /*
                            * Если не заполнен 1 или 2 периода - проставляем пустые значения
                            * Периодов должно быть всегда 2
                            * */
                            if (model.principal.principalBuhPeriods.length === 0) {
                                model.principal.principalBuhPeriods.push({});
                                model.principal.principalBuhPeriods.push({});
                            } else if (model.principal.principalBuhPeriods.length === 1) {
                                model.principal.principalBuhPeriods.push({});
                            }
                            delete model.principal.principalBuh.periods;

                        }
                    }
                    if (model.bankGuarantee) {
                        /*
                       Преобразуем:
                       model.bankGuarantee.beneficiaries[] -> model.principal.beneficiaries
                       model.bankGuarantee.bails -> model.principal.bails
                       */
                        if (model.bankGuarantee.beneficiaries && model.bankGuarantee.beneficiaries.length > 0) {
                            model.principal.beneficiaries = model.bankGuarantee.beneficiaries[0];
                            delete model.bankGuarantee.beneficiaries;
                            if (model.principal.beneficiaries.okved) {
                                model.principal.beneficiaries.okved = {
                                    description: model.principal.beneficiaries.okvedName,
                                    id: model.principal.beneficiaries.okved
                                }
                            }
                            delete model.principal.beneficiaries.okvedName;
                        }
                        if (model.bankGuarantee.bails) {
                            model.principal.bails = model.bankGuarantee.bails;
                            delete model.bankGuarantee.bails;
                        }
                        if (model.principal.bails && model.principal.bails.length > 0) {
                            angular.forEach(model.principal.bails, function (item) {
                                if (item.bailType === 'company')
                                    contractsToObj(item.signer);
                            })
                        }
                        if (model.bankGuarantee.ikzList && model.bankGuarantee.ikzList.length > 0) {
                            model.bankGuarantee.ikzList = model.bankGuarantee.ikzList[0];
                        }
                    }
                    return model;

                    /*
                    * Преобразуем массив в объект (в массиве всегда один контракт)
                    * */
                    function contractsToObj(data) {
                        if (data.contacts && data.contacts.length > 0) {
                            const obj = angular.copy(data.contacts[0]);
                            delete data.contacts;
                            data.contacts = obj;
                        }
                    }
                }

                function changeStateFn() {
                    if(!$scope.modelView.stateToChange)
                        return;
                    bankGuaranteeVTBSrv.changeState($stateParams.id, $scope.modelView.stateToChange).then(
                        function (response) {
                            if (response.data && response.data.success) {
                                $scope.alertObjbankGuaranteeApp = alertSrv.getSuccessMsg('Статус изменен.');
                            } else {
                                $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Ошибка при изменении статуса!');
                                console.log(response);
                            }
                        }, function (reason) {
                            $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Ошибка при изменении статуса!');
                            console.log(reason);
                        })
                }

                function assignAgentFn() {
                    if ($scope.modelView.agent) {
                        const agent = $scope.agentsList.find(a => a.id === $scope.modelView.agent);
                        if (agent) {
                            bankGuaranteeVTBSrv.assignAgent($stateParams.id, agent).then(
                                function (response) {
                                    if (response.data && response.data.success) {
                                        $scope.alertObjbankGuaranteeApp = alertSrv.getSuccessMsg('Агент назначен.');
                                    } else {
                                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Ошибка при назначении агента!');
                                        console.log(response);
                                    }
                                }, function (reason) {
                                    $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Ошибка при назначении агента!');
                                    console.log(reason);
                                })
                        }
                    }

                }
                const PDF_FILE_MSG = "Файл не добавлен. Можно добавлять только файлы с расширением pdf.";
                $scope.onPassCompanyHeadFileNameError = () =>
                    $scope.modelView.alertObjDocPassportCompanyHead = alertSrv.getErrorMsg(PDF_FILE_MSG);

                $scope.onPassPrincipalSignerFileNameError = () =>
                    $scope.modelView.alertObjDocPassportPrincipalSigner = alertSrv.getErrorMsg(PDF_FILE_MSG);

                $scope.onFileNameError = () =>
                    $scope.modelView.alertObjDocumentationBank = alertSrv.getErrorMsg(PDF_FILE_MSG);
            }]
    });
