angular.module('uetp').service('orderSrv', ['httpSrv', '$filter', 'dialogSrv', '$q',
    function (httpSrv, $filter, dialogSrv, $q) {
        var service = this,
            positions = [];
        service.showPanel = {type: 'order'};
        service.eventsLoad = {loaded: false};
        service.orderData = null;
        this.storeOrder = storeOrderFn;
        this.loadOrderById = loadOrderByIdFn;
        this.setShowPanel = setShowPanelFn;
        this.getShowPanel = getShowPanelFn;
        this.setData = setDataFn;
        this.getData = getDataFn;
        this.getOrderDataFromPos = getOrderDataFromPosFn;
        this.createDraftOrder = createDraftOrderFn;
        this.getAddressString = getAddressStringFn;
        this.checkOfferPosition = checkOfferPositionFn;
        this.actionButtonDisabled = actionButtonDisabledFn;
        //передача позиций для заказа из корзины
        this.setPositions = setPositionsFn;
        this.getPositions = getPositionsFn;
        this.changeStatus = changeStatusFn;
        this.deleteOrder = deleteOrderFn;

        function deleteOrderFn(id) {
            return httpSrv.http({
                method: 'DELETE',
                url: '/orders/api/v1/order/' + id
            })
        }

        function changeStatusFn(id, status, executorName) {
            return httpSrv.http({
                url: '/orders/api/v1/order/changeStatus/' + id + '/' + status,
                data: executorName,
                block: true
            })
        }

        function storeOrderFn(data) {
            return httpSrv.http({
                url: '/orders/api/v1/order/store',
                data: data,
                block: true
            })
        }


        function setShowPanelFn(type) {
            service.showPanel.type = type;
        }

        function getShowPanelFn() {
            return service.showPanel;
        }

        function setDataFn(orderData) {
            service.orderData = orderData;
        }

        function getDataFn() {
            return service.orderData
        }

        /**
         * Получение заказа
         */
        function loadOrderByIdFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/orders/api/v1/order/' + id
            })
        }

        function getAddressStringFn(data) {
            var addr = "";
            if (data.regAddress) {
                if (data.regAddress.zip) addr += data.regAddress.zip;
                if (data.regAddress.regionName) addr += ", " + data.regAddress.regionName;
                if (data.regAddress.area) addr += ", " + data.regAddress.area;
                if (data.regAddress.settlement) addr += ", " + data.regAddress.settlement;
                if (data.regAddress.street) addr += ", " + data.regAddress.street;
                if (data.regAddress.building) addr += ", " + data.regAddress.building;
                if (data.regAddress.subBuilding) addr += ", " + data.regAddress.subBuilding;
                if (data.regAddress.office) addr += ", " + data.regAddress.office;
            }
            return addr;
        }

        function getOrderDataFromPosFn(position, offer) {
            var orderData = {cartsOrganization: [], org: {}},
                posData = {
                    deliveryRegion: offer ? offer.deliveryRegion : position.deliveryRegion,
                    deliveryRegionName: offer ? offer.deliveryRegionName : position.deliveryRegionName,
                    count: 1,
                    position: angular.copy(position),
                    checkbox: true
                };
            orderData.org = offer ? offer.supplier : position.supplier;
            orderData.cartsOrganization.push(posData);

            return orderData;
        }

        function setPositionsFn(newPositions) {
            positions = newPositions;
        }

        function getPositionsFn() {
            return positions;
        }


        function createDraftOrderFn() {
            return httpSrv.http({
                method: 'GET',
                url: '/orders/api/v1/order/createDraft'
            }).then(function successCallback(response) {
                if (response.data.success) {
                    return response.data.result;
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function checkOfferPositionFn(ids, showDialog) {
            // если нет значения, то по-умолчанию true
            showDialog = typeof showDialog !== 'undefined' ? showDialog : true;
            var posInfo = $q.defer();
            httpSrv.http({
                url: '/orders/api/v1/order/positions/check',
                data: Array.isArray(ids) ? ids : [ids]
            }).then(function (response) {
                if (!response.data.success && showDialog) {
                    dialogSrv.openModal('_commonInfo.html', {message: response.data.message});
                }
                else posInfo.resolve(response.data.result);
            }, function (response) {
                console.log(response);
            });
            return posInfo.promise;
        }

        /**
         * Функция определения неактивности кнопок (по статусу позиции)
         * @param position позиция
         * @returns {boolean}
         */
        function actionButtonDisabledFn(position) {
            return position.statusEng !== 'eshop_published';
        }
    }]);
