angular.module('uetp').service('tassBusinessSrv', ['$state', 'httpSrv',
    function ($stateParams, httpSrv) {

        var service = this;
        /*
        * Данные по вкладкам
        * verification - Проверки
        * */
        var modelTab = {
            verification: null
        };
        //признак выполнения запроса на получение данных
        var requestProcessed ={
        	reporting: false,
        	verification: false
        }
        //данные для экспорта pdf
        var verifyResult = {};
        /*
        * Информация по компании
        * */
        service.getOrganizationInfo = function (scope,data,host) {
            return httpSrv.requestXHR(scope,{
                method: 'POST',
                url: host+'/rest/tassValid/search/getOrganizationInfo?skipLoyality=true',
                data: data
            })
        };
        /*
        * Отчетность организации
        * */
        service.getOrganizationFinance = function (scope, id, inn, host) {
            return httpSrv.requestXHR(scope,{
                method: 'GET',
                url: host + '/rest/tassValid/search/getOrganizationFinance/' + id + '/' + inn
            })
        };
        //проверки организации
        service.getOrganizationLoyality = function (scope, id, inn, host) {
            return httpSrv.requestXHR(scope,{
                method: 'GET',
                url: host + '/rest/tassValid/search/getOrganizationLoyality/' + id + '/' + inn
            })
        };
        //Проверка счетов организации
        service.getOrganizationVerification = function (scope, id, host, captcha, update) {
            let url = `${host}/rest/tassValid/search/getOrganizationVerification/${id}`;
            if(captcha) url += `?code=${encodeURIComponent(captcha)}`;
            if(update) url += `?update=true`;
            return httpSrv.requestXHR(scope,{
                method: 'GET',
                url: url
            })
        };
        /*
        * Контракты организации
        * */
        service.getOrganizationContracts = function (paginator, inn, host, scope) {
        	 return httpSrv.requestXHR(scope,{
                method: 'POST',
                url: host + '/rest/other-purchases/search/getOrganizationContracts/' + inn,
                data: paginator
            })
        };
        /*
        * Заказчики организации
        * */
        service.getCustomers = function (paginator, inn, host, scope) {
             return httpSrv.requestXHR(scope,{
                method: 'POST',
                url: host + '/rest/other-purchases/search/getCustomers/' + inn + '?aggregation=true',
                data: paginator
            })
        };
        /*
        * Поставщики организации
        * */
        service.getSuppliers = function (paginator, inn, host, scope) {
             return httpSrv.requestXHR(scope,{
                method: 'POST',
                url: host + '/rest/other-purchases/search/getSuppliers/' + inn + '?aggregation=true',
                data: paginator
            })
        };
        /*
        * Кол-во бесплатных попыток осталось у участника для демо-доступа к разделу "Проверка компании"
        * */
        service.getParticipantLimit = function (host, scope) {
            return httpSrv.requestXHR(scope,{
                method: 'GET',
                url: host + '/rest/tassValid/search/getParticipantLimit'
            })
        };
        /*
        * Кол-во бесплатных попыток осталось у участника для демо-доступа к разделу "Подписки на закупки Еис"
        * */
        service.subscribeParticipantLimit = function (host, scope) {
            return httpSrv.requestXHR(scope,{
                method: 'GET',
                url: host + '/rest/other-purchases/proc/subscribeParticipantLimit'
            })
        };
        /*
        * Кол-во бесплатных попыток осталось у участника для демо-доступа к разделу "Анализ цен"
        * */
        service.statPriceParticipantLimit = function (host, scope) {
            return httpSrv.requestXHR(scope,{
                method: 'GET',
                url: host + '/rest/other-purchases/proc/statPriceParticipantLimit'
            })
        };
        /*
        * Установить данные вкладки
        * */
        service.setModelTab = function(tab, data) {
            modelTab[tab] = data;
        };
        /*
        * Получить данные вкладки
        * */
        service.getModelTab = function (tab) {
            return modelTab[tab];
        }
        //получение признака выполнения запроса на получение данных
        service.getRequestProcessed = function(part) {
        	return requestProcessed[part];
        };
        //установка признака выполнения запроса на получение данных
        service.setRequestProcessed = function(part, data) {
        	requestProcessed[part] = data;
        };
        //сброс признака выполнения запроса на получение данных
        service.clearRequestProcessed = function() {
        	requestProcessed['reporting'] = false;
        	requestProcessed['verification'] = false;
        };
        service.setVerifyResult = function(data) {
        	verifyResult = data;
        };
        service.getVerifyResult = function () {
            return verifyResult;
        }
        //Выгрузка результатов проверки в PDF
        service.downloadVerifyResult = function (url, host) {
        	httpSrv.downloadFile(host + url, '', null, true);
        }
    }]);
