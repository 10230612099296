/**
 * @author: sergeyu
 * Таблица заявлений на разъяснения по процедуре
 */
angular.module('uetp')
    .component('explanations', {
        templateUrl: 'app/components/procedures/explanations/explanations.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'proceduresSrv', 'permissionSrv', 'dictionarySrv', 'needConfirmSrv', 'authSrv', 'httpSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, proceduresSrv, permissionSrv, dictionarySrv, needConfirmSrv, authSrv, httpSrv) {
                const ctrl = this;
                $scope.ctrl = ctrl;
                $scope.auth = authSrv.getAuth();
                $scope.isOperator = $scope.auth.userRole && $scope.auth.userRole[0].indexOf('operator_') !== -1;
                $scope.cabType = desktopSrv.getCabType();
                $scope.isCbz = $scope.cabType==='cbz'
                $scope.ctrl.templateUrl = '';
                $scope.hasPermission = permissionSrv.hasPermission;
                $scope.changeSort = changeSortFn;
                $scope.explanationStates = dictionarySrv.getStaticDictByType('explanationStates');
                $scope.getEisState = proceduresSrv.getEisState;
                $scope.procedureWorkflow = authSrv.getUser().userProperties?.procedureWorkflow === "true";

                this.$onInit = function () {

                    $scope.procedure = proceduresSrv.getProcedureData().data.result;
                    $scope.dataTable = $scope.procedure.explanations;
                    if(($scope.dataTable || []).length>0)
                        $scope.dataTable.forEach(e=>{
                            if(e?.state?.id==='sent_participant')
                                e.state.name='Направлено в ЛК';
                        })
                    $scope.orderProperty = 'created';
                    $scope.reverse = true;
                };
                $scope.createAnswerExp = function () {
                    $state.go('createAnswerExplanation', {
                        procType: $scope.procedure.type.id,
                        procId: $scope.procedure.id
                    })
                }
                
                function changeSortFn($event) {
                    let th = $event.currentTarget,
                        orderBy = th.getAttribute('st-sort'),
                        orderAsc = th.classList.contains("sortAsc");

                    [].forEach.call(document.querySelectorAll(".sortColumn"), function (column) {
                        column.classList.remove("sortDesc");
                        column.classList.remove("sortAsc");
                    });
                    orderAsc = !orderAsc;
                    th.classList.add(orderAsc ? "sortAsc" : "sortDesc");
                    if (orderBy) {
                        $scope.orderProperty = orderBy;
                        $scope.reverse = !orderAsc;
                    }
                }

                $scope.changeExplanationState = function (el, prevState, selector) {
                    if(prevState!=='' && prevState!==selector.$select.selected.id)
                        needConfirmSrv.callVariants(changeStateProcess, function (){
                            selector.$select.selected = getState(selector.$select.items, prevState);
                            el.state = selector.$select.selected;
                            },['admin/changeExplanationState/'+el.id+'/'+selector.$select.selected.id], 'Статус разъяснения будет изменён на "'+el.state.name+'". Продолжить?');
                }


                function changeStateProcess(url){
                    return proceduresSrv.changeState(url);
                }

                function getState(items, id){
                    return items.filter(function (item) {return item.id===id; })[0];
                }
                $scope.getStateDesc = (data) => {
                    if(data.state.id==='created') return "Создано";
                    if(data.state.id==='published') return "Опубликовано";
                    if(data.state.id==='sent_participant') return "Направлено в ЛК" + (data.participant ? " \n"+((data.participant.typeOrg === 'nr' ? "УИН " + data.participant.uin : "ИНН "+ data.participant.inn)+ ", " + data.participant.fullName) : "");
                }
                $scope.openEisError = (eisError, expl) => {
                    if(expl?.id && expl?.hasEDS && $scope.cabType !== 'organizer') {
                        httpSrv.downloadFile(`/admin/eis/description/${expl.id}/explanation`, `eis_description_explanation_${expl.id}.xml`, undefined, undefined, $scope, 'Информация об ответе ЕИС отсутствует');
                    } else {
                        needConfirmSrv.openModal('_dialogMessage.html', {message: !eisError.endsWith('.') ? eisError + '.' : eisError});
                    }
                }
            }]
    });

