angular.module('uetp')
    .component('bgAdvertising', {
        templateUrl: 'app/components/bgAgent/bgAdvertising/bgAdvertising.html',
        controller: ['$scope', 'loggerUiSrv',
            function ($scope, loggerUiSrv) {
                this.$onInit = function () {
                    loggerUiSrv.sendLog('BG_ADVERTISING_BANNER_CLICK');
                    $scope.activePanel = 'tab1';
                }
                $scope.setActivePanel = (type) => {
                    $scope.activePanel = type;
                };
            }
        ]});
