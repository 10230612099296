/**
 * Обрубаем пробелы в начале и конце строки
 */
angular.module('uetp').directive('trimBlur', [function () {
    return {
        require: 'ngModel',
        restrict: 'A',
        scope: {
            modelValue: '=ngModel'
        },
        link: function (scope, el) {
            el.bind('blur', function () {
                /**
                 * Если пришли данные из модели.(модель валидна)
                 */
                if (scope.modelValue) {
                    el.val(scope.modelValue.trim());
                /**
                 * Если не пришли данные модели, то берем из дом дерева для обрубания значения
                 */
                } else if (el && el[0] && el[0].value) {
                    el.val(el[0].value.trim());
                } else {
                    el.val(scope.modelValue);
                }
            });
        }
    }
}]);