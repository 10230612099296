/**
 * Диалог с банком
 *
 * @author dmitrys
 */
angular.module('uetp')
    .component('bankGuaranteeChat', {
        templateUrl: 'app/components/bankGuaranteeChat/bankGuaranteeChat.html',
        bindings: {
            bankGuaranteeChatData: '<',
            bankGuaranteeAppData: '<'
        },
        controller: ['$scope', '$state', '$stateParams', 'bankGuaranteeSrv', 'httpSrv', 'alertSrv', 'authSrv', 'desktopSrv', '$filter',
            function ($scope, $state, $stateParams, bankGuaranteeSrv, httpSrv, alertSrv, authSrv, desktopSrv, $filter) {

                const ctrl = this;
                $scope.downloadFile = httpSrv.downloadFile;
                $scope.selectDocType = selectDocTypeFn;
                $scope.reply = replyFn;
                $scope.addFile = addFileFn;
                $scope.delRow = delRowFn;
                $scope.send = sendFn;
                $scope.showGuarantee = showGuaranteeFn;
                $scope.refreshChat = refreshChatFn;
                $scope.goBack = goBackFn;
                $scope.model = {};
                $scope.bankGuaranteeParams = {};
                $scope.modelView = {
                    showReplyPanel: false,//показать панель ответа
                    doingSend: false,//процесс отправки
                    isOnlySign: false // требуется только подписать (нельзя прикладывать файлы, добавлять типы и удалять файлы)
                };
                $scope.dateOptions = {};
                $scope.tomorrow = getServerTimeMsk();
                if ($scope.tomorrow) {
                    $scope.tomorrow.setDate($scope.tomorrow.getDate() + 1);
                    $scope.tomorrow.setHours(0, 0, 0, 0);
                    $scope.dateOptionsGuaranteeStart = {
                        minDate: $scope.tomorrow,
                        maxDate: $scope.getDateOptionsGuaranteeStartMaxDate
                    };
                    $scope.dateOptionsGuaranteeEnd = {
                        minDate: $scope.getDateOptionsGuaranteeEndMinDate
                    };

                }

                $scope.dateGuaranteeStart = {
                    opened: false
                };
                $scope.dateGuaranteeEnd = {
                    opened: false
                };

                $scope.setDateOptionsGuaranteeStartMaxDate = setDateOptionsGuaranteeStartMaxDateFn;

                $scope.setDateOptionsGuaranteeEndMinDate = setDateOptionsGuaranteeEndMinDateFn;

                function setDateOptionsGuaranteeStartMaxDateFn(value) {
                    $scope.dateOptionsGuaranteeStart['maxDate'] = value;
                }

                function setDateOptionsGuaranteeEndMinDateFn(value) {
                    $scope.dateOptionsGuaranteeEnd['minDate'] = value;
                }

                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.auth = authSrv.getAuth();
                    if (!(authSrv.canActiveBankGuarantee($scope.cabType(), authSrv.getUser()) || authSrv.isAdminBGChatView($scope.cabType(), authSrv.getUser()))) {
                        $state.go('bankGuarantees');
                    }
                    if ($stateParams.message !== null) {
                        $scope.alertObjBankGuaranteeChat = $stateParams.message;
                    }
                    $scope.bankGuaranteeParams = ctrl.bankGuaranteeAppData;
                    $scope.bankGuaranteeParamsParse = JSON.parse($scope.bankGuaranteeParams.data.result);
                    $scope.modelView.created = $scope.bankGuaranteeParams.data.created;
                    $scope.modelView.number = $scope.bankGuaranteeParamsParse.bankGuarantee.purchaseNumber;
                    /*
                    * Список доступных докментов для отправки (изменяется при выборе)
                    * */
                    $scope.modelView.dopDocumentList = angular.copy(bankGuaranteeSrv.getDocTypeList());
                    angular.forEach($scope.modelView.dopDocumentList, item => item.isShow = true);
                    /*
                    * Список решений с расшифровкой
                    * */
                    $scope.modelView.decisionOptionsList = angular.copy(bankGuaranteeSrv.getDict('decisionOptions'));
                    $scope.modelView.orderId = $stateParams.id;
                    /*
                    * Полный список типов файлов
                    * */
                    $scope.modelView.fullDocList = angular.copy(bankGuaranteeSrv.getDocTypeList());

                    if (ctrl.bankGuaranteeChatData && ctrl.bankGuaranteeChatData.data && ctrl.bankGuaranteeChatData.data.success) {
                        $scope.model.list = getModelFromView(ctrl.bankGuaranteeChatData.data.result);
                    }

                    $scope.selectDecision = selectDecisionFn;
                    $scope.isCustomDecision = isCustomDecisionFn;
                    $scope.sendDisabled = sendDisabledFn;
                    $scope.isReworkChangeFields = isReworkChangeFieldsFn;
                };

                /*
                * Кнопка ответить - открытие панели ответа
                * Формируем предустановленные данные для ответа
                * если есть outbox со статусом draft - то это подготовленные данные которые надо подписать
                * */
                function replyFn(data) {
                    $scope.modelView.isOnlySign = false;
                    const inbox = data.inbox;
                    const outbox = data.outbox;
                    $scope.modelView.answerId = inbox.id;
                    angular.forEach($scope.modelView.dopDocumentList, item => item.isShow = true);
                    $scope.model.answer = {
                        documents: []
                    };
                    $scope.modelView.decisionOptions = inbox.decisionOptions;
                    $scope.modelView.taskDefinitionKey = inbox.taskDefinitionKey;
                    if ($scope.modelView.taskDefinitionKey == 'UserTaskInitiatorApproveDocs2') {
                        outbox.files = {};
                    }
                    if ($scope.cabType() == 'agent' && inbox.taskDefinitionKey == 'UserTaskInitiatorApproveDocs2' && $scope.modelView.decisionOptions) {
                        angular.forEach($scope.modelView.decisionOptions, (item, index) => {
                            if (item.code == 'REWORK') {
                                $scope.modelView.decisionOptions.splice(index, 1);
                            }
                        });
                    }
                    if (!outbox) {
                        /*
                        * Предустанавливаем выбраные типы исходя из типов файлов запроса
                        * */
                        angular.forEach(inbox.files, file => {
                            addFile(file, false);
                            /*
                            * Скрываем уже выбраные типы
                            * */
                            angular.forEach($scope.modelView.dopDocumentList, item => {
                                if (item.type === file.type) item.isShow = false;
                            });
                        });
                    }
                    if (outbox && outbox.state === 'draft') {
                        //требуется только подписать
                        $scope.modelView.isOnlySign = true;
                        angular.forEach(outbox.files, file => addFile(file, true))
                    }

                    /*
                    * Добавляем файл в $scope.model.answer.documents
                    * если есть такой тип документа до заполняем files
                    * если нет создаем новый тип
                    * needAddFile - если нужно записываем файл
                    * */
                    function addFile(file, needAddFile) {
                        let checkType = false;
                        angular.forEach($scope.model.answer.documents, item => {
                            if (item.type === file.type) {
                                checkType = true;
                                if (!item.files) item.files = [];
                                if (needAddFile) item.files.push(file);
                            }
                        });
                        if (!checkType) {
                            const item = {
                                type: file.type,
                                label: getLabelForType(file.type),
                                files: []
                            };
                            if (needAddFile) item.files.push(file);
                            $scope.model.answer.documents.push(item);
                        }
                    }

                    $scope.modelView.showReplyPanel = true;
                }

                /*
                * Получить имя типа документа по его типу
                * */
                function getLabelForType(type) {
                    let value = '';
                    angular.forEach($scope.modelView.fullDocList, item => {
                        if (item.type === type) value = item.label;
                    });
                    return value;
                }

                /*
                * Получить список для отображения
                        inbox: { - блок входящего сообщения
                            id: '' - id запроса
                            date: '',
                            comment: '',
                            decisionOptions:[] - доступные дейстивя
                            files: []
                        },
                        outbox: { - блок ответа
                            date: '',
                            decisionCode:'',
                            decisionName:'',
                            comment: '',
                            files: []
                        }
                * */
                function getModelFromView(models) {
                    const list = [];
                    angular.forEach(models, item => {
                        const newItem = {};
                        /*
                        * Входящее сообщение
                        * */
                        if (item.requestContent) {
                            newItem.inbox = getInbox(item)
                        }
                        /*
                        * Ответ
                        * */
                        if (item.clientResponse) {
                            newItem.outbox = getOutbox(item.clientResponse);
                        }
                        list.push(newItem)
                    });

                    /*
                    * Разбор входящего сообщения
                    * */
                    function getInbox(data) {
                        const inbox = {};
                        const content = JSON.parse(data.requestContent);
                        inbox.taskName = content.taskName;
                        inbox.comment = content?.requirements?.bank;
                        inbox.date = data.created;
                        inbox.id = data.id;
                        inbox.state = data.requestState;
                        getDocument(inbox, content.documents, true);
                        inbox.decisionOptions = content.decisionOptions;
                        inbox.taskDefinitionKey = content.taskDefinitionKey;
                        return inbox;
                    }

                    /*
                    * Разбор исходящего сообщения
                    * */
                    function getOutbox(data) {
                        const outbox = {};
                        const content = JSON.parse(data.clientResponseContent);
                        if (content.decision) {
                            outbox.comment = content.decision.comment;
                            outbox.decisionCode = content.decision.resultCode;
                            outbox.decisionName = getDecisionByCode(content.decision.resultCode);
                        }
                        outbox.date = data.sendDate;
                        outbox.state = data.responseState;
                        getDocument(outbox, content.documents, false);
                        return outbox;
                    }

                    /*
                    * Преобразуем блок документов
                    * */
                    function getDocument(model, documents, isInbox) {
                        //TODO: вернуть после приведения банковских файлов к нашей системе
                        if (!isInbox) {
                            model.files = documents;
                            angular.forEach(model.files, file => file.label = getLabelForType(file.type));
                        }
                        if (isInbox) {
                            model.files = [];
                            angular.forEach(documents, item => {
                                const label = getLabelForType(item.type);
                                angular.forEach(item.files, file => {
                                    const newFile = file;
                                    newFile.type = item.type;
                                    newFile.label = label;
                                    model.files.push(newFile);
                                })
                            });
                        }
                    }

                    return list;
                }

                /*
                * Выбрать тип документа для добавляения
                * */
                function selectDocTypeFn(selectObj) {
                    const selected = angular.copy(selectObj.$select.selected);
                    if (!$scope.model.answer.documents) $scope.model.answer.documents = [];
                    $scope.model.answer.documents.push({
                        type: selected.type,
                        label: selected.label,
                        isMultiple: selected.isMultiple,
                        isRequired: selected.isRequired,
                        canEdit: selected.canEdit,
                        files: []
                    });
                    //скрываем тип файла из списка доп докумнтов для выбора
                    angular.forEach($scope.modelView.dopDocumentList, doc => {
                        if (doc.type === selected.type) {
                            doc.isShow = false;
                        }
                    })
                    delete $scope.modelView.selectType;
                }

                function getDecisionByCode(code) {
                    let value = '';
                    angular.forEach($scope.modelView.decisionOptionsList, item => {
                        if (item.code === code) value = item.name;
                    })
                    return value;

                }

                /*
                * Добавить файлы по типу вложения
                * @param files - файлы
                * @param type - тип вложения
                * */
                function addFileFn(files, type) {
                    angular.forEach($scope.model.answer.documents, item => {
                        if (item.type === type) {
                            if (!item.files) item.files = [];
                            angular.forEach(files, file => {
                                item.files.push({
                                    created: file.created,
                                    fileName: file.fileName,
                                    id: file.id,
                                    size: file.size,
                                    type: file.type ? file.type : item.type
                                })
                            })
                        }
                    })
                }

                /**
                 * Удалить вложение
                 * Если есть type необходимо восстановить отображение элемента в списке
                 * @param model - модель
                 * @param index - номер строки для удаления
                 * @param type - тип документа
                 */
                function delRowFn(model, index, type) {
                    model.splice(index, 1);
                    if (type) {
                        angular.forEach($scope.modelView.dopDocumentList, doc => {
                            if (doc.type === type) {
                                doc.isShow = true;
                            }
                        })
                    }
                }

                /*
                * Отправить ответ
                * model - данные ответа
                * */
                function sendFn(model) {
                    if (!$scope.modelView.doingSend) {
                        $scope.modelView.doingSend = true;
                    } else {
                        return;
                    }
                    if (!$scope.modelView.answerId) {
                        $scope.alertObjBankGuaranteeChat = alertSrv.getErrorMsg('Ошибка! Не выбран запрос для ответа');
                        $scope.modelView.doingSend = false;
                        return;
                    }
                    if (($scope.modelView.decisionOptions && $scope.modelView.decisionOptions.length > 0 && !model.decision)) {

                        $scope.alertObjBankGuaranteeChat = alertSrv.getErrorMsg('Для отправки ответа в банк необходимо указать решение');
                        $scope.modelView.doingSend = false;
                        return;
                    }
                    if (!model.comment) {

                        $scope.alertObjBankGuaranteeChat = alertSrv.getErrorMsg('Для отправки ответа в банк необходимо указать комментарий');
                        $scope.modelView.doingSend = false;
                        return;
                    }
                    const data = {};
                    if (model.comment || model.decision) {
                        data.decision = {};
                        if (model.decision) data.decision.resultCode = model.decision;
                        if (model.comment) data.decision.comment = model.comment;
                    }
                    if (model.documents) {
                        data.documents = [];
                        angular.forEach(model.documents, item => {
                            angular.forEach(item.files, file => {
                                data.documents.push({
                                    fileName: file.fileName,
                                    type: file.type,
                                    id: file.id
                                })
                            });
                        })
                    }
                    if (model.bankGuarantee) {
                        data.bankGuarantee = angular.copy(model.bankGuarantee);
                        if (data.bankGuarantee.startDate) {
                            data.bankGuarantee.startDate = $filter('date')(data.bankGuarantee.startDate, 'yyyy-MM-dd')
                        }
                        if (data.bankGuarantee.endDate) {
                            data.bankGuarantee.endDate = $filter('date')(data.bankGuarantee.endDate, 'yyyy-MM-dd')
                        }
                    }
                    const certId = $scope.auth.certId;
                    bankGuaranteeSrv.answer($stateParams.id, $scope.modelView.answerId, data).then(response => {
                        if (response.data && response.data.success) {
                            if ($scope.cabType() == 'agent') {
                                bankGuaranteeSrv.sendToBank($stateParams.id, response.data.result.id).then(result => {
                                    if (result.data && result.data.success) {
                                        delete $scope.modelView.answerId;
                                        $scope.alertObjBankGuaranteeChat = alertSrv.getSuccessMsg('Ответ отправлен');
                                        console.log(result);
                                        $scope.modelView.doingSend = false;
                                        $scope.modelView.showReplyPanel = false;
                                        refreshChatFn($scope.alertObjBankGuaranteeChat);
                                    } else {
                                        $scope.modelView.doingSend = false;
                                        refreshChatFn();
                                    }
                                })
                            } else {
                                bankGuaranteeSrv.getDocumentsDataForSign(certId, response.data.result.id)
                                    .then(responseSign => {
                                            if (responseSign.data && responseSign.data.success) {
                                                sendAndSign(responseSign.data.result, response.data.result.id);
                                            } else {
                                                $scope.alertObjBankGuaranteeChat = alertSrv.getErrorMsg('Ошибка!');
                                                $scope.modelView.doingSend = false;
                                                console.log(responseSign);
                                            }
                                        },
                                        reason => {
                                            console.log(reason);
                                            $scope.alertObjBankGuaranteeChat = alertSrv.getErrorMsg('Ошибка!');
                                            $scope.modelView.doingSend = false;
                                        }
                                    );
                            }
                        } else {
                            console.log(response);
                            $scope.alertObjBankGuaranteeChat = alertSrv.getErrorMsg('Ошибка!');
                            $scope.modelView.doingSend = false;
                        }
                        //скрыть панель ответа
                    }, reason => {
                        $scope.alertObjBankGuaranteeChat = alertSrv.getErrorMsg('Ошибка!');
                        $scope.modelView.doingSend = false;
                        console.log(reason);
                    });

                    function sendAndSign(data, reqId) {
                        if (data && data.dataList) {
                            bankGuaranteeSrv.signDocument(data.dataList, $scope.auth.certId).then(signData => {
                                    bankGuaranteeSrv.sendSignedDocuments(signData, reqId).then(response => {
                                        if (response.data && response.data.success) {
                                            bankGuaranteeSrv.sendToBank($stateParams.id, reqId).then(result => {
                                                if (result.data && result.data.success) {
                                                    delete $scope.modelView.answerId;
                                                    $scope.alertObjBankGuaranteeChat = alertSrv.getSuccessMsg('Ответ отправлен');
                                                    console.log(result);
                                                    $scope.modelView.doingSend = false;
                                                    $scope.modelView.showReplyPanel = false;
                                                    refreshChatFn($scope.alertObjBankGuaranteeChat);
                                                } else {
                                                    $scope.modelView.doingSend = false;
                                                    refreshChatFn();
                                                }
                                            })
                                        } else {
                                            $scope.alertObjBankGuaranteeChat = alertSrv.getErrorMsg('Ошибка!');
                                            $scope.modelView.doingSend = false;
                                            console.log(response)
                                        }
                                    }, reason => {
                                        $scope.modelView.doingSend = false;
                                        console.log(reason);
                                    })

                                },
                                reason => {
                                    if (typeof reason === 'string') {
                                        $scope.alertObjBankGuaranteeChat = alertSrv.getErrorMsg("Ошибка подписи документа. " + reason);

                                    } else {
                                        $scope.alertObjBankGuaranteeChat = alertSrv.getErrorMsg("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                                    }
                                    $scope.modelView.doingSend = false;
                                    console.log(reason)
                                }
                            );
                        } else {
                            if ($scope.modelView.taskDefinitionKey == 'UserTaskInitiatorApproveDocs' ||
                                $scope.modelView.taskDefinitionKey == 'UserTaskInitiatorApproveDocs2' &&
                                $scope.model.answer && $scope.model.answer.decision !== 'REWORK') {
                                bankGuaranteeSrv.sendToBank($stateParams.id, reqId).then(result => {
                                    if (result.data && result.data.success) {
                                        delete $scope.modelView.answerId;
                                        $scope.alertObjBankGuaranteeChat = alertSrv.getSuccessMsg('Ответ отправлен');
                                        console.log(result);
                                        $scope.modelView.doingSend = false;
                                        $scope.modelView.showReplyPanel = false;
                                        refreshChatFn($scope.alertObjBankGuaranteeChat);
                                    } else {
                                        $scope.modelView.doingSend = false;
                                        refreshChatFn();
                                    }
                                })
                            } else {
                                console.log(data);
                                $scope.alertObjBankGuaranteeChat = alertSrv.getErrorMsg("Ошибка! Нет документов для подписи");
                                $scope.modelView.doingSend = false;

                            }
                        }
                    }
                }

                /*
                * Открыть анкету
                * */
                function showGuaranteeFn() {
                    $state.go('bankGuaranteeAppOpen', {id: $stateParams.id, clearConfig: true})
                }

                function refreshChatFn(message) {
                    $state.go($state.current, {id: $stateParams.id, message: message}, {reload: true});
                }

                function goBackFn() {
                    if (['admincab', 'support'].includes($scope.cabType()) && $stateParams.participantInfo?.id)
                        $state.go('bankGuaranteesAdmin', {
                            participantId: $stateParams.participantInfo.id,
                            clearConfig: true
                        });
                    else {
                        if ($stateParams.participantSelected) {
                            $state.go('bankGuarantees', {
                                participantInfo: $stateParams.participantInfo,
                                participantSelected: $stateParams.participantSelected
                            });
                        } else
                            history.back();
                    }
                }

                function selectDecisionFn() {
                    if ($scope.modelView.taskDefinitionKey == 'UserTaskInitiatorApproveDocs2') {
                        if ($scope.model.answer.decision == 'REWORK') {
                            $scope.model.answer.documents = [];
                            $scope.model.answer.documents.push({
                                type: "doc_guaranteeLot",
                                label: "Макет БГ",
                                isMultiple: false,
                                isRequired: true,
                                canEdit: true,
                                isShow: true,
                                files: []
                            });
                        } else {
                            $scope.model.answer.documents = [];
                        }
                    } else if ($scope.modelView.taskDefinitionKey == 'UserTaskInitiatorApproveDocs') {
                        if ($scope.model.answer.decision == 'REWORK') {
                            $scope.model.answer.bankGuarantee = {};
                        } else {
                            delete $scope.model.answer.bankGuarantee;
                        }
                    }
                }

                function isCustomDecisionFn() {
                    return $scope.modelView.taskDefinitionKey == 'UserTaskInitiatorApproveDocs2' &&
                        $scope.model.answer && $scope.model.answer.decision == 'REWORK';
                }

                function sendDisabledFn() {
                    let needGuaranteeLotFile = false;
                    if ($scope.isCustomDecision()) {
                        needGuaranteeLotFile = true;
                        angular.forEach($scope.model.answer.documents, item => {
                            if (item.type == 'doc_guaranteeLot' && item.files && item.files.length > 0) {
                                needGuaranteeLotFile = false;
                            }
                        })
                    }

                    return $scope.modelView.doingSend || needGuaranteeLotFile ||
                        $scope.modelView.taskDefinitionKey == 'UserTaskInitiatorApproveDocs2' && (!$scope.model.answer || !$scope.model.answer.decision) ||
                        $scope.modelView.taskDefinitionKey == 'UserTaskInitiatorApproveDocs' && (!$scope.model.answer || !$scope.model.answer.decision ||
                            $scope.model.answer.decision == 'REWORK' && (!$scope.model.answer.bankGuarantee || !$scope.model.answer.bankGuarantee.bankGuaranteeSum || !$scope.model.answer.bankGuarantee.startDate || !$scope.model.answer.bankGuarantee.endDate));
                }

                function isReworkChangeFieldsFn() {
                    return $scope.modelView.taskDefinitionKey == 'UserTaskInitiatorApproveDocs' &&
                        $scope.model.answer && $scope.model.answer.decision == 'REWORK';
                }
            }]
    });
