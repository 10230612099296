/**
 * @author: sergeyu
 * сервис по сохранению положения скроллов, пагинации, вкладок
 * clearConfig если указан true , то он сбросит все положения для состояния. Переходы по ссылкам должны быть с эти параметром
 * т.к явный переход по ссылке должен сбросить все и показать форму как по умолчанию
 * скролл и пагинатор обернут своими сервисами чтоб самому контролировать себя. В контроллере таблицы
 * вызывается получение пагинатора чтоб выставить нужыне параметры
 */
angular.module('uetp').service('storageSrv', ['$state','$stateParams',
    function ($state, $stateParams) {
        var service = this;
        /**
         * Удаление настроек формы
         */
        service.clearStateConfig = function (guid, type) {
            sessionStorage.removeItem(guid + $state.current.name + type);
            return null;

        };
        /**
         * Получение настроек формы
         * @param guid - уникальный id процедуры или еше чего.
         * @param type - тип состояния
         * @returns
         */
        service.getStateConfig = function (guid, type) {
            if($stateParams.clearConfig) {
                service.clearStateConfig(guid, type);
                return null;
            }
            return sessionStorage.getItem(guid + $state.current.name + type) ? JSON.parse(sessionStorage.getItem(guid + $state.current.name + type)) : null;
        };
        /**
         * Выставление параметров для формы
         * @param guid - уникальный id процедуры или еше чего.
         * @param type - тип состояния
         * @param params - параметры которые надо запомнить
         */
        service.setStateConfig = function (guid, type, params) {
            sessionStorage.setItem(guid + $state.current.name + type, JSON.stringify(params));
        };

    }]);