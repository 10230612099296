/**
 * Сервис Обновить план закупок
 */
angular.module('uetp').service('purchasePlanUpdateSrv', ['$state', 'httpSrv',
    function ($state, httpSrv) {
        this.loadPlanNumbers = loadPlanNumbersFn;
        this.loadCustomers = loadCustomersFn;
        this.updatePlan = updatePlanFn;
        
        function loadPlanNumbersFn(inn, host, scope) {
            return httpSrv.requestXHR(scope,{
                method: 'GET',
                url: host + '/rest/other-purchases/proc/getPurchasesPlanNumbers/' + inn
            })
        }

        function updatePlanFn(planNumber, customerId, eisKis, host, scope) {
            return httpSrv.requestXHR(scope,{
                method: 'GET',
                url: `${host}/rest/other-purchases/plan/223/${eisKis ? 'kisris/' : ''}${planNumber}/${customerId}`
            })
        }
        
        function loadCustomersFn(allCustomers = false) {
            return httpSrv.http({
                method: 'GET',
                url: `/organizer/customers/${allCustomers?'getAllCustomersPurchasePlanUpdate':'getCustomersPurchasePlanUpdate'}`
            });
        }

    }]);