/**
 * Таблица актов по оплаченным счетам
 */
angular.module('uetp')
    .component('completedWorksTable', {
        templateUrl: 'app/components/completedWorks/table/completedWorksTable.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'authSrv', 'httpSrv', 'desktopSrv', 'tablesSrv', 'filterService', 'paginationSrv', 'completedWorksSrv',
            function ($scope, $rootScope, $state, $stateParams, authSrv, httpSrv, desktopSrv, tablesSrv, filterService, paginationSrv, completedWorksSrv) {
                $scope.ctrl = this;
                /**
                 * В ЛК Бухгалтера выводим как таблицу (с фильтрами и поиском)
                 * В Лк Участника как вкладку
                 */
                $scope.isTable = $stateParams.isTable;

                $scope.completedWorksSrv = completedWorksSrv;
                $scope.downloadFile = httpSrv.downloadFile;

                $scope.paginator = {
                    itemsPerPage: 20,
                    page: 0,
                    search: ""
                };

                $scope.clickRow = clickRowFn;
                $scope.goBack = goBackFn;
                $scope.completedWorksLoad = completedWorksLoadFn;
                $scope.changeSort = changeSortFn;

                function goBackFn(){
                    history.back();
                }

                this.$onInit = function () {
                    $scope.auth = authSrv.getAuth();
                    $scope.cabType = desktopSrv.getCabType();
                    const config = paginationSrv.getPaginationStateConfig('pagination', $state.current.name); // память пагинации
                    if (config) {
                        $scope.paginator.page = config.page;
                        $scope.paginator.itemsPerPage = config.itemsPerPage;
                    }
                    if($scope.isTable){
                        filterService.initModelView('Поиск по номеру документа, ИНН покупателя, наименованию покупателя', 'Реестр актов');
                    }
                    $scope.completedWorksLoad();
                };

                function clickRowFn(row) {
                    $state.go('completedWorkView', {id: row.id, params: {clearConfig: true}});
                }


                function completedWorksLoadFn() {
                    if($scope.isTable){
                        tablesSrv.prepareTableParams($scope.paginator,'filterCompletedWorks');
                    }
                    completedWorksSrv.loadCompletedWorks($scope.paginator, $stateParams.isInvoiceParticipantView ? $stateParams.id : undefined).then(function successCallback(response) {
                        if(response.data.success){
                            $scope.completedWorks = response.data.items;
                            $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                        }
                    });
                }

                /**
                 * Сортировка
                 */
                function changeSortFn($event) {
                    if (tablesSrv.changeSort($scope.paginator, $event.currentTarget)) {
                        $scope.completedWorksLoad();
                    }
                }
            }
        ]
    });

