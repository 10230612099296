/**
 * @author: sergeyu
 * Сервис для получения полей которые надо отображать в зависимости от условий или типов документов
 */
angular.module('uetp').service('vfSrv', [
    function () {
        var service = this;
        service.getVisibleProcFields = getVisibleProcFieldsFn;
        service.getVisibleLotFields = getVisibleLotFieldsFn;
        service.getVisibleClaimFields = getVisibleClaimFieldsFn;
        service.getVisibleProtocolFields = getVisibleProtocolFieldsFn;
        service.getVisiblePartcicipateAttach = getVisiblePartcicipateAttachFn;

        function _getProcFields() {
            return {
                registrationNumber: true,
                russianPriority: true,
                forSmallOrMiddle: true,
                claimBegins: true,
                claimEnds: true,
                priceType: true,
                price: true,
                priceNoNDS: true,
                eisNumber: true,
                etzpQualificationNumber: false,
                simplifiedProcedure : false,
                firstStage : false,
                msp:true,
                pricingCondition:false,
                notDishonest: true
            }
        }

        function _getLotFields() {
            return {
                currency: true,
                contractSumNoNDS: true,
                contractSum: true,
                alternativeOffersSelect: true,
                alternativeOffers: true,
                showNomenclature: true,
                showExtendFields: true,
                auctionDateStart: false,
                contractExecutionDate:false
            }
        }

        function _getProtocolFields() {
            return {
            	claimNumber: true,
            	bidOrder: false,
            	winner: true,
            	bids: true,
            	claimEndsSecondStage: false,
                contractExecutionDate: false,
                pricingCondition: false,
                showNeedCheck: false
           }
        }
        function _getClaimFields() {
            return {
                agreement: false
            };
        }
        function _getParticipateAttach() {
            return {
                attach:true,
                openAttach: false,
                closeAttach: false,
                isReqOpenAttach: true,
                isReqCloseAttach: true
            };
        }

        function getVisibleProcFieldsFn(type) {
            const fields = _getProcFields();
            /**
             * 223-ФЗ
             */
            //Предварительный квалификационный отбор
            if (type === 'prequalification' || type === 'unlimitedprequalification') {
                fields.registrationNumber = false;
                fields.russianPriority = false;
                fields.forSmallOrMiddle = false;
                fields.priceType = false;
                fields.price = false;
                fields.priceNoNDS = false;
                fields.eisNumber = false;
            }
            //Конкурс с ограниченным участием
            if (type === 'limitedcontest') {
                fields.etzpQualificationNumber = true;
                fields.pricingCondition = true;
            }
            //Запрос котировок с ограниченным участием
            if (type === 'limitedrequestquotations') {
                fields.etzpQualificationNumber = true;
                fields.pricingCondition = true;
            }
            //Аукцион с ограниченным участием
            if (type === 'limitedauction') {
                fields.etzpQualificationNumber = true;
            }
            //Запрос котировок
            if(type ==='requestquotations'){
                fields.simplifiedProcedure = true;
                fields.pricingCondition = true;
            }
            if(['requestproposals', 'limitedrequestproposals'].includes(type)) {
                fields.simplifiedProcedure = true;
            }
            if(type ==='requestproposals' || type ==='limitedrequestproposals' || type ==='open2stagecontest'){
                fields.firstStage = true;
            }
            //Конкурс среди организаций, прошедших предварительный квалификационный отбор
            if (type === 'limitedcontestqualification') {
                fields.etzpQualificationNumber = true;
            }
            //Запрос котировок (МСП)
            if (type === 'requestquotationssmb' || type === 'requestproposalssmb') {
                fields.msp = false;
            }
            //Открытый двухэтапный конкурс
            if (type === 'open2stagecontest') {
                fields.msp = true;
                fields.pricingCondition = true;
            }
            //Открытый конкурс
            if(type ==='opencontest'){
                fields.pricingCondition = true;
            }
            //Запрос предложений
            if(type ==='requestproposals'){
                fields.pricingCondition = true;
            }
            //Запрос предложений с ограниченным участием
            if(type ==='limitedrequestproposals'){
                fields.pricingCondition = true;
                fields.etzpQualificationNumber = true;
            }
            /**
             * 44-ФЗ
             */
            // Электронный аукцион
            if(type ==='EAP44'){

            }
            //Открытый конкурс

            //Открытый конкурс с ограниченным участием

            //Открытый двухэтапный конкурс

            //Запрос котировок

            //Запрос предложений

            return fields;
        }

        function getVisibleLotFieldsFn(type) {
            const fields = _getLotFields();
            //prequalification: "Предварительный квалификационный отбор"
            //unlimitedprequalification: "Предварительный квалификационный отбор с неограниченным сроком"
            if (type === 'prequalification' || type === 'unlimitedprequalification') {
                fields.currency = false;
                fields.contractSumNoNDS = false;
                fields.contractSum = false;
                fields.alternativeOffersSelect = false;
                fields.alternativeOffers = false;
            }
            //Открытый аукцион
            if (type === 'openauction') {
                fields.alternativeOffersSelect = false;
                fields.alternativeOffers = false;
                fields.auctionDateStart = true;
            }
            //Доходный аукцион
            if (['openpublicoffer','saleauction'].includes(type)) {
                fields.alternativeOffersSelect = false;
                fields.alternativeOffers = false;
                fields.auctionDateStart = true;
            }
            //Аукцион (МСП)
            if (type === 'auctionsmb') {
                fields.alternativeOffersSelect = false;
                fields.alternativeOffers = false;
                fields.auctionDateStart = true;
            }
            //Открытый аукцион (МСП)
            if (type === 'openauctionsmb') {
                fields.alternativeOffersSelect = false;
                fields.alternativeOffers = false;
                fields.auctionDateStart = true;
            }
            //Аукцион с ограниченным участием
            if (type === 'limitedauction') {
                fields.alternativeOffersSelect = false;
                fields.alternativeOffers = false;
                fields.auctionDateStart = true;
            }
            //Открытый конкурс
            if (type === 'opencontest') {
                fields.contractExecutionDate = true;
            }
            //Конкурс с ограниченным участием
            if (type === 'limitedcontest') {
            // if (type === 'limitedcontestqualification') {
                fields.contractExecutionDate = true;
            }
            //Запрос котировок
            if (type === 'requestquotations') {
                fields.contractExecutionDate = true;
            }
            //Запрос котировок с ограниченным участием
            if (type === 'limitedrequestquotations') {
                fields.contractExecutionDate = true;
            }
            //Запрос предложений
            if(type ==='requestproposals'){
                fields.contractExecutionDate = true;
            }
            //Запрос предложений с ограниченным участием
            if(type ==='limitedrequestproposals'){
                fields.contractExecutionDate = true;
            }
            //Открытый двухэтапный конкурс
            if (type === 'open2stagecontest') {
                fields.contractExecutionDate = true;
            }
            return fields;
        }

        function getVisibleClaimFieldsFn(type) {
            const fields = _getClaimFields();
            if (type === 'requestquotationssmb') {
                fields.agreement = true;
            }
            if (type === 'EAP44') {
                fields.lotNumber = false;
            }
            return fields;
        }

        function getVisibleProtocolFieldsFn(type) {
            const fields = _getProtocolFields();
            if (type === 'openauction') {
                fields.claimNumber = false;
                fields.bidOrder = true;
                fields.winner = false;
            }
            if (['openpublicoffer','saleauction'].includes(type)) {
                fields.claimNumber = false;
                fields.bidOrder = true;
                fields.winner = false;
            }
            if (type === 'auctionsmb') {
                fields.claimNumber = false;
                fields.bidOrder = true;
                fields.winner = false;
            }
            if (type === 'openauctionsmb') {
                fields.claimNumber = false;
                fields.bidOrder = true;
                fields.winner = false;
            }
            if (type === 'limitedauction') {
                fields.claimNumber = false;
                fields.bidOrder = true;
                fields.winner = false;
            }
            if (type === 'prequalification' || type === 'unlimitedprequalification') {
                fields.bids = false;
            }
            if (type === 'offerplacement') {
                fields.bids = false;
            }
            //requestproposals - Запрос предложений
            if (type === 'requestproposals') {
                fields.claimEndsSecondStage = true;
                fields.contractExecutionDate = true;
                fields.pricingCondition = true;
                fields.showNeedCheck = true;
            }
            //limitedrequestproposals - Запрос предложений с ограниченным участием
            //open2stagecontest - Открытый двухэтапный конкурс
            if (type === 'limitedrequestproposals' || type === 'open2stagecontest') {
            	fields.claimEndsSecondStage = true;
                fields.contractExecutionDate = true;
                fields.pricingCondition = true;
            }
            //Открытый конкурс
            if (type === 'opencontest') {
                fields.pricingCondition = true;
                fields.contractExecutionDate = true;
                fields.showNeedCheck = true;
            }
            //Конкурс с ограниченным участием
            if (type === 'limitedcontest') {
                fields.pricingCondition = true;
                fields.contractExecutionDate = true;
            }
            //Запрос котировок
            if (type === 'requestquotations') {
                fields.contractExecutionDate = true;
                fields.pricingCondition = true;
                fields.showNeedCheck = true;
            }
            //Запрос котировок с ограниченным участием
            if (type === 'limitedrequestquotations') {
                fields.contractExecutionDate = true;
                fields.pricingCondition = true;
            }
            return fields;
        }
        function getVisiblePartcicipateAttachFn(type, stage, rebiddingNumber, hideOpenParts) {
            const fields = _getParticipateAttach();
            if (type === 'opencontest') {
                fields.openAttach = true;
                fields.closeAttach = true;
            }
            if (type === 'requestquotations') {
                fields.openAttach = true;
                fields.closeAttach = true;
                fields.isReqCloseAttach = false;
            }
            if (type === 'limitedcontest') {
                fields.openAttach = true;
                fields.closeAttach = true;
            }
            if (type === 'limitedrequestquotations') {
                fields.openAttach = true;
                fields.closeAttach = true;
            }
            if (type === 'requestproposals' ) {
                fields.openAttach = true;
                fields.closeAttach = true;
            }
            if (type === 'limitedrequestproposals' ) {
                fields.openAttach = true;
                fields.closeAttach = true;
            }
            if (type === 'open2stagecontest' ) {
                fields.openAttach = true;
                fields.closeAttach = true;
            }
            if (type === 'limitedcontestqualification') {
                fields.openAttach = false;
                fields.closeAttach = false;
            }
            if (type === 'unlimitedprequalification') {
                fields.openAttach = false;
                fields.closeAttach = false;
            }
            if ((type === 'requestproposals' || type === 'limitedrequestproposals' || type === 'open2stagecontest' )&& stage && stage == 'stage2') {
                fields.isReqCloseAttach = false;
            }
            if ((type === 'opencontest' || type === 'requestquotations') && stage && stage == 'stage2') {
                fields.isReqCloseAttach = false;
            }
            // переторжка
            if((type === 'opencontest' || type === 'requestquotations' || type === 'limitedrequestquotations' || type === 'limitedcontest' || type ==='requestproposals')&& rebiddingNumber && rebiddingNumber>0)
            {
                fields.isReqCloseAttach = false;
            }
            if (type === 'simplecontest') {
                fields.openAttach = true;
                fields.closeAttach = false;
                fields.isReqOpenAttach = false;
                fields.isReqCloseAttach = false;
            }

            if(hideOpenParts && !['openauction', 'limitedauction', 'simplecontest', 'prequalification', 'unlimitedprequalification'].includes(type)) {
                fields.openAttach = false;
                fields.closeAttach = false;
                fields.isReqCloseAttach = true;
            }

            return fields;
        }
    }]);