/**
 * @author dmitrys
 */
angular.module('uetp')
    .component('procedure', {
        templateUrl: 'app/components/notice/procedure/procedure.html',
        controller: ['$rootScope', '$scope', '$state', '$stateParams', '$parse', 'timeout', 'noticeSrv','organizatorSrv',
            'dictionarySrv', 'alertSrv', 'desktopSrv', 'cryptography', 'authSrv', 'needConfirmSrv','vfSrv','constants',
            'extendFieldSrv', 'permissionSrv', 'titleSrv', 'systemMessages', 'customerSrv',
            function ($rootScope, $scope, $state, $stateParams, $parse, timeout, noticeSrv, organizatorSrv,
                      dictionarySrv, alertSrv, desktopSrv, cryptography, authSrv, needConfirmSrv, vfSrv,constants,
                      extendFieldSrv, permissionSrv, titleSrv, systemMessages, customerSrv) {
                $scope.state = getStateFn;
                $scope.dateOptions = {
                };
                $scope.customerDict = [];
                $scope.deliveryPlacePurchase = [];
                $scope.qualificationDict = [];

                /**
                 * Пагинатор
                 */
                $scope.totalPages = 0;
                $scope.paginator = {
                    itemsPerPage: 50,
                    page: 0
                };
                $scope.submitted = false;
                $scope.setState = setStateFn;
                $scope.getState = getStateFn;
                $scope.validateTable = validateTableFn;
                $scope.refreshDict = refreshDictFn;
                $scope.save = saveFn;
                $scope.autoFillByTypeDict = autoFillByTypeDictFn;
                $scope.updateSum = updateSumFn;
                $scope.initButton = initButtonFn;
                $scope.saveModel = saveModelFn;
                $scope.closeForm = closeFormFn;
                $scope.getForm = getFormFn;
                $scope.changeDate = changeDateFn;
                $scope.checkDate = checkDateFn;
                $scope.englishSymbol = englishSymbolFn;
                $scope.changeCustomer = changeCustomerFn;
                $scope.changeQualificationSelect = changeQualificationSelectFn;
                $scope.getActivePanel = noticeSrv.getShowPanel;
                $scope.getProtocolNameByType = getProtocolNameByTypeFn;
                $scope.definedForProcedure = definedForProcedureFn;
                $scope.isNew223 = noticeSrv.isNew223;
                $scope.isNewCommercial = noticeSrv.isNewCommercial;
                $scope.isRequestPrices = isRequestPricesFn;
                $scope.changeDeliveryPlaceIndication = changeDeliveryPlaceIndicationFn;
                $scope.jointPurchaseChange = jointPurchaseChangeFn;
                $scope.isNoticeChange = isNoticeChangeFn;
                $scope.priceModeChange = priceModeChangeFn;
                $scope.priceTypeChange = priceTypeChangeFn;
                $scope.isSimpleContest = isSimpleContestFn;
                $scope.isShowNonCompetitive = isShowNonCompetitiveFn;
                $scope.lotRequiredFields=[];
                $scope.actionsInProcess={};
                $scope.purchaseCodeNameVisible = noticeSrv.isShowExtendedFields;
                $scope.isNoticeIntegrationEIS = noticeSrv.isNoticeIntegrationEIS;
                $scope.isNoticeShowCustomerEISError = noticeSrv.isNoticeShowCustomerEISError;
                $scope.hasPriceMode = noticeSrv.hasPriceMode;

                this.$onInit = function () {
                    noticeSrv.setNoticeShowCustomerEISError(false);
                	$scope.cabType = desktopSrv.getCabType();
                    $scope.procedureForm = {};
                    $scope.documentationForm = {};

                    $scope.auth = authSrv.getAuth();
                    $scope.user = authSrv.getUser();
                    $scope.pf = vfSrv.getVisibleProcFields($stateParams.type);
                    /**
                     * Конфигурация формы
                     */
                    $scope.isEdit = noticeSrv.getEditForm();
                    $scope.isReadOnly = noticeSrv.getReadOnlyForm() || !$scope.hasPermission();
                    $scope.notice = noticeSrv.getNoticeInfo();
                    $scope.model = $scope.notice.document || {};
                    $scope.modelView = {};
                    $scope.processing = false;
                    noticeSrv.setNoticeExtFields(true);
                    noticeSrv.setNoticeIntegrationEIS(false);
                    if($scope.notice){
                        noticeSrv.setNoticeIntegrationEIS($scope.notice.noticeIntegrationEIS);
                        noticeSrv.setInitNoticeIntegrationEIS($scope.notice.noticeIntegrationEIS);
                        if($scope.model && $scope.notice.customerBranch)
                            $scope.model.customerBranch = $scope.notice.customerBranch;
                        if($scope.model && $scope.notice.showpricends)
                            $scope.model.showpricends = $scope.notice.showpricends;
                    } 
                    noticeSrv.setSelectedCustomer(null);
                    
                    $scope.model.section = noticeSrv.isNew44() ? '44' : (noticeSrv.isNewCommercial() ? 'commercial' : '223');
                    
                    $scope.model.commercial = noticeSrv.isNewCommercial();

                    if(noticeSrv.isInitedNotice() && $stateParams.dataProc) {
                        noticeSrv.setNoticeShowCustomerEISError(true);
                        noticeSrv.setCustomerLoading(true);
                        $scope.createFromPurchasePlan = true;
                    }

                    if($scope.isEdit){
                        $scope.modelView.consentProcessing = !!$scope.model.consentProcessing;
                        $scope.modelView.consentDissemination = !!$scope.model.consentDissemination;
                        $scope.model.consentProcessing = ($scope.model.consentProcessing?.indexOf(".") < 0) ? ($scope.model.consentProcessing || systemMessages.CONSENT_PROCESSING) : systemMessages.CONSENT_PROCESSING;
                        $scope.model.consentDissemination = ($scope.model.consentDissemination?.indexOf(".") < 0) ? ($scope.model.consentDissemination || systemMessages.CONSENT_DISSEMINATION) : systemMessages.CONSENT_DISSEMINATION;
                    }

                    if(noticeSrv.isNew223() || $scope.isSimpleContest()) {
                        _initDict(['fias_region', 'purchase_category'], false);

                        function _initDict(dictList, reload) {
                            angular.forEach(dictList, function (name) {
                                dictionarySrv.getDictionaryRemote(name, reload);
                            })
                        }

                    }

                    /**
                     * Получение справочников
                     */
                    if(noticeSrv.isNew223() && $scope.cabType==='organizer'
                        && !['simplecontest','openauction','limitedauction','prequalification','unlimitedprequalification'].includes($stateParams.type) &&
                        $scope.notice && $scope.notice.document && $scope.notice.document.priceType!=='MIN_PRICE'){
                        $scope.typeSum = dictionarySrv.getStaticDictByType('typeSumNoMin');
                    } else if(['openauction','limitedauction','saleauction','openpublicoffer'].includes($stateParams.type)){
                       $scope.typeSum = dictionarySrv.getStaticDictByType('typeSumAuction');
                    } else {
                        $scope.typeSum = dictionarySrv.getStaticDictByType('typeSum');
                    }

                    $scope.whoPublishVariants = dictionarySrv.getStaticDictByType('whoPublishVariants');
                    $scope.tariffTypes = dictionarySrv.getStaticDictByType('tariffTypes');
                    $scope.deliveryPlaceIndicationVariants = dictionarySrv.getStaticDictByType('deliveryPlaceIndicationVariants');
                    $scope.priceModes = dictionarySrv.getStaticDictByType('priceModes');
                    $scope.showpricends = dictionarySrv.getStaticDictByType('showpricends');

                    $scope.dictionary = dictionarySrv.getRemoteData();
                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                    dictionarySrv.resetPaginatedDict($scope);

                    $scope.actions = $scope.notice.actions;
                    $scope.invalidDateText = "";
                    $scope.constants = constants;

                    if(!(['admincab','manager','cbz'].includes($scope.cabType) || ($scope.auth?.userRole||[]).includes('operator_support_ro')))
                        $scope.getDataOrg().then(function(){
                            initModel();
                            initTariffTypeFn();
                        });
                    else {
                        initOrganizer();
                        initSimpleCustomer();
                        if($scope.model.customer && $scope.model.customer.id)
                            noticeSrv.setSelectedCustomer($scope.model.customer);

                        if((noticeSrv.isNew223() || noticeSrv.isNewCommercial()) && ($scope.model.jointPurchase || $scope.model.customerBranch)) {
                            noticeSrv.getHeadDzoCustomersByNotice(noticeSrv.getSection(), $stateParams.id).then(function (resp) {
                                 setCustomerDict(resp.data.result);
                            });
                        }
                        customerSrv.getCustomerById($scope.model.customer.id).then(function(response){
                            $scope.model.customer = response.data.result;
                            initTariffTypeFn();
                        })
                    }

                    updateBothSum();

                    initPriceTypeByProcType();

                    $scope.initShowPriceNds();

                    initTariffTypeFn();

                    if ($stateParams.successSave) {
                        $scope.alertObjSaveProcedure = alertSrv.getSuccessMsg('Сохранение произведено успешно!');
                    } else {
                        window.scrollTo(0, 0);
                    }
                    
                    noticeSrv.setFocusFirstInvalidFun($scope.focusFirstInvalid);
                    setTimeout(function () {
                        $scope.procedureForm.form.$dirty = false;
                    }, 100)
                    if ($scope.model.number && $scope.model.typeName) {
                        titleSrv.setTitle($scope.model.number + ' | ' + $scope.model.typeName);
                    }
                };

                $scope.initShowPriceNds = () => {
                    if($scope.isShowPrices() && !$scope.model.showpricends){
                        $scope.model.showpricends = 'no';
                    }
                }

                function initTariffTypeFn() {
                    if($scope.hasWinnerTariff() && $scope.isWinnerTariffAllowed() && !$scope.model.tariffType && !$scope.isReadOnly){
                        $scope.model.tariffType = 'tariff';
                    }
                }

                function initSimpleCustomer() {
                    if ($scope.model.customerCode && $scope.model.customerName) {
                        $scope.model.customer = {
                            name: $scope.model.customerName,
                            id: $scope.model.customerCode
                        };
                        delete $scope.model.customerCode;
                        delete $scope.model.customerName;
                    }
                }

                function initOrganizer(){
                    if ($scope.model.organizerCode) {
                        if ($scope.model.organizerName) {

                            if(angular.isUndefined($scope.model.organizerNotificationControl)){
                                const user = JSON.parse(localStorage.getItem("userData"));
                                $scope.model.organizerNotificationControl = user.notificationControl;
                            }
                            $scope.model.organizer = {
                                name: $scope.model.organizerName,
                                id: $scope.model.organizerCode,
                                notificationControl: $scope.model.organizerNotificationControl
                            };
                            delete $scope.model.organizerCode;
                            delete $scope.model.organizerName;
                            delete $scope.model.organizerNotificationControl;
                        }
                    }
                }

                $scope.hasPermission = permissionSrv.hasPermission;

                /**
                 * Получить назначеных заказчиков
                 */
                $scope.getDataOrg = function () {
                    let convention;
                    if(['requestproposals','limitedrequestproposals','requestquotations'].includes($scope.model.typeCode))
                        convention = 'false';
                    if(noticeSrv.isNew223() || noticeSrv.isNewCommercial()) {
                        noticeSrv.getDataOrgHeadDzoCustomerBySection(noticeSrv.getSection(), convention).then(function (resp) {
                            setTimeout(function(){setCustomerDict(resp.data.result)}, 500);
                        });
                    }

                    return noticeSrv.getDataOrgCustomerBySection(noticeSrv.getSection(), convention).then(function (response) {
                        if (response.data.success) {
                            $scope.customerDict = response.data.result;

                            initSimpleCustomer();
                            // поиск по инн
                            const customer = $scope.customerDict.find(c => noticeSrv.isInitedNotice() && (($stateParams.dataProc?.customers?.length)||0) > 0 &&
                                    $stateParams.dataProc?.customers[0]?.inn === c.inn &&
                                    hasOneCustomerByInn(c.inn));
                            if(customer)
                                $scope.model.customer = angular.copy(customer);

                            if($scope.customerDict?.length===1 && !noticeSrv.isInitedNotice())
                                $scope.model.customer = angular.copy($scope.customerDict[0]);

                            if ($scope.model.customer?.id) {
                                // если не полный список полей в $scope.model.customer - поиск по id
                                if(!customer)
                                    $scope.model.customer = $scope.customerDict.find(cd => cd.id === $scope.model.customer.id);
                                const nonComp = $scope.notice.document.nonCompetitive;
                                changeCustomerFn($scope.notice && $scope.notice.document.id ? !noticeSrv.isInitedNotice() : !noticeSrv.isInitedNotice() && $scope.customerDict.length>1, true);
                                if(angular.isDefined(nonComp))
                                    $scope.model.nonCompetitive = nonComp;
                            }

                            if (noticeSrv.isNew223() || noticeSrv.isInitedNotice()) {
                                // refresh positionNumber
                                const param = {
                                    orderBy: 'lots.lot_number',
                                    itemsPerPage: 20,
                                    page: 0
                                };
                                param.searchByField = {
                                    state: 'PLANNED', section: '223', year: getServerTimeMsk().getFullYear()
                                };
                                if (noticeSrv.isInitedNotice() && $scope.notice && $scope.notice.document.purchaseMethodCode)
                                    param.searchByField.method_code = $scope.notice.document.purchaseMethodCode;

                                param.searchByGroup = {
                                    customer: getCustomerInn($scope.customerDict)
                                };
                                if (noticeSrv.isInitedNotice() && $stateParams.dataProc) {
                                    param.search = $stateParams.dataProc.lots[0].positionNumber;
                                    setCustomerDict($scope.customerDict);
                                }
                                dictionarySrv.loadPaginatedDict($scope, 'positionNumber', param, 'positionPlanNumbers');
                            }
                            return Promise.resolve(response);
                        }
                    });
                };

                function initPriceTypeByProcType(){
                    if($scope.model.typeCode ==='simplecontest'){
                        $scope.model.priceType = 'MAX_PRICE';
                        $scope.notice.document.priceType = $scope.model.priceType;
                    }
                    if($scope.model.typeCode ==='requestprices'){
                        $scope.model.priceType = 'NO_PRICE';
                        $scope.notice.document.priceType = $scope.model.priceType;
                    }

                    if(['openauction', 'limitedauction'].includes($scope.model.typeCode)){
                        $scope.model.priceType = 'MAX_PRICE';
                        $scope.notice.document.priceType = $scope.model.priceType;
                    }

                    if(['openpublicoffer','saleauction'].includes($scope.model.typeCode)){
                        $scope.model.priceType = 'MIN_PRICE';
                        $scope.notice.document.priceType = $scope.model.priceType;
                    }
                }

                function setCustomerDict(customerDict){
                    if($scope.model.lots)
                        angular.forEach($scope.model.lots.lot, function (lot) {
                            if (lot && !lot.customer && lot.jointLotData) {
                                lot.customer = [];
                                angular.forEach(lot.jointLotData.lotCustomers.lotCustomer, function (lotCustomer) {
                                    lot.customer.push(getCustomerByInn(customerDict, lotCustomer.customerInfo.inn));
                                });
                                delete lot.jointLotData;
                            }
                        });
                    $rootScope.$broadcast('uetp.notice', {data: customerDict, dataType: 'lot', type: 'setCustomerDict'});
                }

                function hasOneCustomerByInn(inn){
                    var cnt = 0;
                    for(var i = 0; i<$scope.customerDict.length; i++){
                        if($scope.customerDict[i].inn===inn) cnt++;
                    }
                    return cnt===1;
                }

                /**
                 * Получить ссылку на форму после построения DOM
                 */
                this.$postLink = function () {
                    noticeSrv.setForm('procedure', $scope.procedureForm.form);
                };

                $scope.$on('uetp.notice', function (event, data) {
                    if (data.dataType === 'lot' && data.type === 'update') {
                        checkPlanLot();
                        updateBothSum();
                        // перечитываем список обязательных полей
                        $rootScope.$broadcast('uetp.notice', {data: $scope.model, dataType: 'lot', type: 'getLotsRequired'});
                    }
                    //удалили лот
                    if(data.dataType === 'lot' && data.type === 'delete')
                        checkPlanLot();
                });

                $scope.$on('uetp.notice.checkDepositSumm', function (event, data) {
                	if(data){
                		$scope.checkDepositSaveAction = true;
                	}
                });
                
                function getCustomerByInn(customerDict, inn){
                    var result;
                    angular.forEach(customerDict, function (customer) {
                        if(customer.inn && inn===customer.inn){
                            result=customer;
                        }
                    });
                    return result;
                }

                function getCustomerInn(customerDict){
                    var result = [];
                    angular.forEach(customerDict, function (customer) {
                        if(customer.inn && result.indexOf(customer.inn)===-1){
                            result.push(customer.inn);
                        }
                    });
                    for(var i=0; i<result.length; i++){ result[i] = {"inn": result[i]}; }
                    return result;
                }


                /**
                 * Установить активное состояние вкладки
                 * @param value
                 */
                function setStateFn(value) {
                    noticeSrv.setState(value);
                }

                /**
                 * Вернуть активное состояние вкладки
                 */
                function getStateFn() {
                    return noticeSrv.getState();
                }

                /**
                 * Проверка полей во вкладке Документация
                 */
                function validateDocumentationFn() {
                    if (!$scope.notice.document.documentationDelivery) return false;
                    var result = checkDate($scope.notice.document.documentationDelivery.deliveryStartDateTime);
                    if (result) result = checkDate($scope.notice.document.documentationDelivery.deliveryEndDateTime);

                    return result;
                }

                /**
                 * Проверка таблицы Лот
                 * @param type - тип процедуры
                 */
                function validateTableFn(type) {
                    if (['opencontest','opencontestsmb','openauction','saleauction','openauctionsmb','requestquotations','offerplacement','offerplacementsmb',
                        'open2stagecontest', 'unlimitedprequalification','requestquotationssmb','prequalification','limitedcontest','limitedauction',
                        'limitedrequestquotations','requestproposals','limitedrequestproposals','requestproposalssmb','limitedcontestqualification',
                        'auctionsmb','simplecontest','openpublicoffer', 'requestprices'].includes(type))
                    {
                        if ($scope.model) {
                            if ($scope.model.lots.lot != null && $scope.model.lots.lot.length > 0) {
                                return false;
                            }
                        }
                    }
                    return true;
                }

                // TODO сделать доступ к функциям директивы валидатора
                function checkDate(field){
                    return !!(field && new Date(field));
                }

                /**
                 * Обновление поля Начальная (максимальная) цена договора c НДС
                 * Для однолотовых процедур заполняется значением из лота.
                 */
                function updateSumFn(byWithNds) {
                    if ($stateParams.type !== 'prequalification' && $stateParams.type !== 'unlimitedprequalification') {
                        if ($scope.model.lots.lot.length === 1) {
                            if($scope.isPercentPriceMode()){
                                if (byWithNds) {
                                    $scope.model.price = $scope.model.lots.lot[0].wholeLotPriceWithNDS;
                                } else {
                                    $scope.model.priceNoNDS = $scope.model.lots.lot[0].wholeLotPriceNoNDS;
                                }
                            } else {
                                if (byWithNds) {
                                    $scope.model.price = $scope.model.lots.lot[0].lotData && $scope.model.lots.lot[0].lotData.priceForItem ? $scope.model.lots.lot[0].wholeLotPriceWithNDS : $scope.model.lots.lot[0].contractSum;
                                } else {
                                    $scope.model.priceNoNDS = $scope.model.lots.lot[0].lotData && $scope.model.lots.lot[0].lotData.priceForItem ? $scope.model.lots.lot[0].wholeLotPriceNoNDS : $scope.model.lots.lot[0].contractSumNoNDS;
                                }
                            }
                        } else {
                            $scope.model.price = '';
                            $scope.model.priceNoNDS = '';
                        }
                    }
                    if($scope.isSimpleContest44())
                        $scope.model.priceNoNDS = $scope.model.price;
                }

                function updateBothSum() {
                    if ($stateParams.type !== 'prequalification' && $stateParams.type !== 'unlimitedprequalification') {
                        if ($scope.model.lots && $scope.model.lots.lot.length === 1) {
                            if($scope.isPercentPriceMode()){
                                $scope.model.price = $scope.model.lots.lot[0].wholeLotPriceWithNDS;
                                $scope.model.priceNoNDS = $scope.model.lots.lot[0].wholeLotPriceNoNDS;
                            } else {
                                $scope.model.price = $scope.model.lots.lot[0].lotData && $scope.model.lots.lot[0].lotData.priceForItem ? $scope.model.lots.lot[0].wholeLotPriceWithNDS : $scope.model.lots.lot[0].contractSum;
                                $scope.model.priceNoNDS = $scope.model.lots.lot[0].lotData && $scope.model.lots.lot[0].lotData.priceForItem ? $scope.model.lots.lot[0].wholeLotPriceNoNDS : $scope.model.lots.lot[0].contractSumNoNDS;
                            }
                        } else {
                            $scope.model.price = '';
                            $scope.model.priceNoNDS = '';
                        }
                    }
                    if($scope.isSimpleContest44())
                        $scope.model.priceNoNDS = $scope.model.price;
                }

                /**
                 * Обновление справочника
                 * @param object - тип справочника
                 * @param value - введенные значения
                 * @param refreshScrolling - обновление данных при прокрутке
                 */
                function refreshDictFn(object, value, refreshScrolling, searchByField) {
                    if(object === 'mailingLists') {
                        if($scope.auth.userRole && $scope.auth.userRole[0].indexOf('operator_') !== -1){
                            if (!searchByField) searchByField = {};
                            searchByField.organizerCode = $scope.model.organizerCode;
                        }
                    }
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling, searchByField);
                }

                /**
                 * Сохранение процедуры
                 * @param models
                 */
                function saveFn(models, action) {
                    delete $scope.winnerPaymentError;
                    //проверки
                    if ($scope.procedureForm.form.$invalid) {
                        $scope.alertObjSaveProcedure = alertSrv.getErrorMsgWithBtn('Необходимо заполнить обязательные поля на форме. ', 'Перейти', $scope.focusFirstInvalid,
                            {form:$scope.procedureForm.form, tab:'procedure'});
                        return Promise.reject();
                    }
                    let listErrorLot=[];
                    // check mutable lot fields
                    if(noticeSrv.isNew223() && $scope.isNoticeIntegrationEIS() && !$scope.isSimpleContest()) {
                        listErrorLot = models.lots.lot.filter(l =>
                            l.lotData && (((!l.lotData.excludePurchaseFromPlan && (!l.lotPlanInfo || !l.lotPlanInfo.planRegistrationNumber ||
                            !l.lotPlanInfo.lotPlanPosition || !l.lotPlanInfo.planGuid || !l.lotPlanInfo.positionGuid || !l.lotPlanInfo.positionNumber)) ||
                            ($scope.model.priceType!=='NO_PRICE' && $scope.isPercentPriceMode() && (typeof l.lotData.startPercent !== 'number' || typeof l.wholeLotPriceNoNDS !== 'number' || typeof l.wholeLotPriceWithNDS !== 'number')))
                            || !checkLotSupplyFields(l.lotData))
                        );

                    } else if($scope.model.priceType!=='NO_PRICE' && $scope.isPercentPriceMode() && !$scope.isSimpleContest()) {
                        listErrorLot = models.lots.lot.filter(l => l.lotData &&
                             typeof l.lotData.startPercent !== 'number' || typeof l.wholeLotPriceNoNDS !== 'number' || typeof l.wholeLotPriceWithNDS !== 'number');
                    }
                    if(listErrorLot.length>0) {
                        alertLotErrors(listErrorLot);
                        return Promise.reject();
                    }

                    if((angular.isDefined(noticeSrv.isNoticeShowCustomerEISError()) && noticeSrv.isNoticeShowCustomerEISError()) || (noticeSrv.isShowExtendedFields() && !$scope.notice.document.purchaseCodeName)) {
                        $scope.alertObjSaveProcedure = alertSrv.getErrorMsg('Необходимо обратиться в Службу поддержки Оператора для настройки интеграции для способа закупки ' + $scope.notice.document.typeName + '.');
                        return Promise.reject();
                    }

                    const extendNoticeFieldForm = noticeSrv.getForm('extendField');
                    if (extendNoticeFieldForm.form && extendNoticeFieldForm.form.$invalid) {
                        $scope.alertObjSaveProcedure = alertSrv.getErrorMsgWithBtn('Необходимо заполнить обязательные поля на форме. ', 'Перейти',$scope.focusFirstInvalid,
                            {form:extendNoticeFieldForm.form, tab:'extendField'});
                        return Promise.reject();
                    }
                    const documentationForm = noticeSrv.getForm('documentation');
                    if (documentationForm.form && documentationForm.form.$invalid) {
                        $scope.alertObjSaveProcedure = alertSrv.getErrorMsgWithBtn('Необходимо заполнить обязательные поля на форме. ', 'Перейти', $scope.focusFirstInvalid,
                            {form: documentationForm.form, tab: 'documentation'});
                        return Promise.reject();
                    }
                    //валидация расширеных полей на уровне лота (могут быть не заполенные обязательные поля)
                    if(noticeSrv.isShowExtendedFields() && $scope.isNoticeIntegrationEIS()) {
                        const lotTemplate = angular.copy(extendFieldSrv.getNoticeExtFieldTemplate('LOT'));
                        if (lotTemplate && lotTemplate.length > 0) {
                            //выбираем обязательные поля в шаблоне
                            const listRequired = lotTemplate.filter(function (item) {
                                return item.required
                            });
                            listErrorLot = [];
                            angular.forEach(models.lots.lot, function (item) {
                                let extendFiledLot = [];
                                if(item.extendFields) {
                                    extendFiledLot = extendFieldSrv.getExtendFieldForModel(item.extendFields);
                                }
                                if (!checkLotReqExtendFiled(extendFiledLot, listRequired)) {
                                    listErrorLot.push(item);
                                }
                            });
                            if(listErrorLot.length>0) {
                              alertLotErrors(listErrorLot);
                              return Promise.reject();
                            }
                        }
                    }

                    function alertLotErrors(listErrorLot){
                        if (listErrorLot.length > 0) {
                            let errMess = '';
                            if (listErrorLot.length === 1) {
                                errMess = 'По лоту №' + listErrorLot[0].ordinalNumber
                            } else {
                                errMess = 'По лотам №';
                                angular.forEach(listErrorLot, function (item) {
                                    errMess += item.ordinalNumber + ', '
                                });
                                errMess = errMess.slice(0, -2);
                            }
                            errMess += ' не заполнены все обязательные поля.';
                            $scope.alertObjSaveProcedure = alertSrv.getErrorMsg(errMess);
                        }
                    }

                    // Совместные закупки
                    if(models.jointPurchase){
                        listErrorLot=[];
                        angular.forEach(models.lots.lot, function (item) {
                            if(!item.jointLotData || item.jointLotData.lotCustomers.lotCustomer.length===0)
                                listErrorLot.push(item);
                        });
                        if(listErrorLot.length>0) {
                            alertLotErrors(listErrorLot);
                            return Promise.reject();
                        }
                    }

                    if (validateTableFn(models.typeCode)) {
                        $scope.alertObjSaveProcedure = alertSrv.getErrorMsgWithBtn('Необходимо заполнить таблицу "Лоты". ', 'Перейти', setActivePanelFn, 'lots');
                        return Promise.reject();
                    }
                    if (noticeSrv.isNew223() && !validateDocumentationFn()) {
                        $scope.alertObjSaveProcedure = alertSrv.getErrorMsgWithBtn('Необходимо заполнить поля во вкладке "Документация". ', 'Перейти', setActivePanelFn, 'documentation');
                        return Promise.reject();
                    }

                    let participantsDoc;
                    if($scope.$parent.$parent.model.showParticipants){
                        participantsDoc = {
                	 	    participants:$scope.$parent.$parent.dataTableParticipants
                 	    }
                    }
                    // TODO При сохранении
                    // Когда пользователь подтверждает действие, введенные ранее данные о различных местах поставки лотов удаляются, в окне
                    // формирования лота перестает отображаться блок deliveryPlace, начинает отображаться блок с полями "Единое место поставки лотов"
                    // --> при сохранении документа блоки с полями deliveryplace по каждому лоту закупки заполняются одинаковыми данными
                    if(noticeSrv.isNew223() || $scope.isSimpleContest()) {
                        if ($scope.model.deliveryPlaceIndication === 'SINGLE_PLACE') {
                            initLotDeliveryPlaceFromSinglePlaceModel(models);
                        } else {
                            initLotNomenclatureDeliveryPlace(models);
                        }
                    }

                    if($scope.isSimpleContestConfines()) {
                        models.deliveryPlaceIndication = 'DIFFERENT_PLACE';
                    }

                    // проверка заполненности обязательных полей в лотах и номенклатуре при импорте из плана закупок
                    if(noticeSrv.isNew223() && $scope.lotRequiredFields.length>0 || noticeSrv.isNewCommercial() || noticeSrv.isNew44()) {
                        const errors = checkRequiredFields($scope.model, $scope.lotRequiredFields, $scope.isNew223());
                        if (errors.length>0) {
                            $scope.alertObjSaveProcedure = alertSrv.getErrorMsg(errors.join('<br/>'));
                            return Promise.reject();
                        }
                    }
                    
                   	if(models.typeCode === 'simplecontest' && action === 'publishNotice' &&
                   			$scope.notice.document.version && $scope.notice.document.version > 1){
                   		return noticeSrv.getInitContractSum('simplecontest', $scope.notice.id).then(function (response) {
                            if(response.data.success && response.data.result) {
                            	const check = checkInitContractSumLotsFn(response.data.result);
                            	if(!check.result){
                            		$scope.alertObjSaveProcedure = alertSrv.getErrorMsg('По закупке уже поданы заявки на участие. Изменение начальной максимальной цены договора' + check.message + ' невозможно. По вопросу внесения изменений в эту процедуру рекомендуем обратиться в службу технический поддержки.');
                                    return Promise.reject();
                              	}
                            }
                            return noticeSrv.saveProc(models, participantsDoc);
                        });
                 	}else if((noticeSrv.isNewCommercial() || noticeSrv.showApplicationSupply()) && action === 'publishNotice' &&
                   			$scope.notice.document.version && $scope.notice.document.version > 1){

                 		return noticeSrv.getInitDepositNeeded(models.typeCode, $scope.notice.id).then(function (response) {
                            if(response.data.success && response.data.result) {
                                const fieldName = noticeSrv.showApplicationSupply() ? 'обеспечения' : 'задатка';
                            	let check = checkInitDepositPriceTypeFn(response.data.result);
                                if(!check.result){
                                    $scope.alertObjSaveProcedure = alertSrv.getErrorMsg('По процедуре уже поданы заявки на участие. Изменение типа начальной цены невозможно. По вопросу внесения изменений в эту процедуру рекомендуем обратиться в службу технический поддержки.');
                                    return Promise.reject();
                                } else{
                                    check = checkInitDepositNeededLotsFn(response.data.result);
                                    if(!check.result){
                                        $scope.alertObjSaveProcedure = alertSrv.getErrorMsg(`По процедуре уже поданы заявки на участие. Изменение способа внесения ${fieldName} ${check.message} невозможно. По вопросу внесения изменений в эту процедуру рекомендуем обратиться в службу технический поддержки.`);
                                        return Promise.reject();
                                    } else {
                                        check = checkInitDepositSumLotsFn(response.data.result);
                                        if(!check.result){
                                            $scope.alertObjSaveProcedure = alertSrv.getErrorMsg(`По процедуре уже поданы заявки на участие. Изменение суммы ${fieldName} ${check.message} невозможно. По вопросу внесения изменений в эту процедуру рекомендуем обратиться в службу технический поддержки.`);
                                            return Promise.reject();
                                        }
                                    }
                                }
                            }
                            return noticeSrv.saveProc(models, participantsDoc);
                        });
                 	  
                 	}else{	
                 		 return noticeSrv.saveProc(models, participantsDoc);
                 	}
                    
                    /*
                    * Проверка заполенения обязательных расширеных полей
                    * false если хотя бы одно не заполнено
                    * */
                    function checkLotReqExtendFiled(extendFiledLot, ReqFileds) {
                        //копируем обязательные поля и затем удаляем те что есть в лоте, если что-то осталось значит не заполнили
                        const data = angular.copy(ReqFileds);
                        angular.forEach(extendFiledLot, function (extendFiled) {
                            for( var i = 0; i < data.length; i++){
                                if (data[i].integrCode === extendFiled.integrCode) {
                                    data.splice(i, 1);
                                    i--;
                                }
                            }
                        });
                        return data.length===0;
                    }
                }

                function checkRequiredFields(model, fields, isNew223){
                    var errors = [], errorLot = [];
                    angular.forEach(model.lots.lot, function (lotData) {
                        var lotCheckResult = checkLotRequired(lotData, fields, isNew223);
                        if(!lotCheckResult){
                            errorLot.push(lotData.ordinalNumber);
                        }
                    });
                    if(errorLot.length>0){
                        if(errorLot.length>1)
                            errors.push("По лотам № "+errorLot.join(', ')+" заполнены не все обязательные поля.");
                        else
                            errors.push("По лоту № "+errorLot[0]+" заполнены не все обязательные поля.");
                    }

                    if(isNew223){
	                    angular.forEach(model.lots.lot, function (lotData) {
	                        var nomCheckResult = getNomenclCheckResult(lotData.lotItems.lotItem, lotData.lotData);

	                        if(nomCheckResult && nomCheckResult.length>0){
	                            if(nomCheckResult.length>1)
	                                errors.push("В позициях номенклатуры №" +nomCheckResult.join(', ')+ " по лоту №"+lotData.ordinalNumber+" заполнены не все обязательные поля.");
	                            else
	                                errors.push("В позиции номенклатуры №" +nomCheckResult[0]+ " по лоту №"+lotData.ordinalNumber+" заполнены не все обязательные поля.");
	                        }
	                    });
                    }
                    return errors;
                }

                //проверка на заполненность обязательных полей лота
                function checkLotRequired(lotData, requiredFields, isNew223){
                    let lotRequiredCorrect = true, methodPriceSet = $parse("lotData.methodPriceSet")(lotData);

                    if(isNew223){
	                    angular.forEach(requiredFields, function (item) {
	                        if(!(((item==='lotData.exchangeInfo.exchangeRate' || item==='lotData.exchangeInfo.exchangeRateDate') && lotData.currency.digitalCode=='643') ||
                                  (item==='lotData.method3Choice' && methodPriceSet === 'method3') ||
	                              ($scope.model.deliveryPlaceIndication===$scope.deliveryPlaceIndicationVariants[0].id && item==='deliveryPlaceIndication'))) {
	                            let val=$parse(item)(lotData);
	                            if((angular.isUndefined(val)||val===null) && item.indexOf('deff__') > 0)
	                               val=$parse(item.replace('deff__', ''))(lotData);

	                            if(angular.isUndefined(val)||val===null) {
	                                // check extend fields
                                    if(lotData.extendFields && lotData.extendFields.noticeExtendField && lotData.extendFields.noticeExtendField.length > 0 && !checkExtendFieldItems(lotData.extendFields.noticeExtendField, item))
	                                    lotRequiredCorrect = false;
	                            }
	                        }
	                    });
                    }
                    //проверка наличия цен в лотах на случай изменения поля 'Начальная цена'
                    if((!isNew223 || methodPriceSet === 'method1' || methodPriceSet === 'method3') &&
                        ($scope.model.priceType!=='NO_PRICE' && !['prequalification','unlimitedprequalification'].includes($stateParams.type) &&
                        !$scope.isPercentPriceMode())){
                    	let contractSumNoNDS = $parse("contractSumNoNDS")(lotData),
                     	    contractSum = $parse("contractSum")(lotData);
                    	if(angular.isUndefined(contractSumNoNDS)||contractSumNoNDS===null ||
                    	   angular.isUndefined(contractSum)||contractSum===null){
                    		 lotRequiredCorrect = false;
                    	}
                    }
                    // проверка заполненности Места поставки товара, работ, услуг лота
                    if(lotRequiredCorrect &&
                        ((noticeSrv.isNew223() && $scope.model.deliveryPlaceIndication==='DIFFERENT_PLACE' && lotData.deliveryPlaceIndication==='GL')
                        || $scope.isSimpleContestConfines()) && !$parse("lotData.deliveryPlace.address")(lotData)){
                            lotRequiredCorrect = false;
                    }
                    return lotRequiredCorrect;
                }

                function checkExtendFieldItems(items, it) {
                    let res = true;
                    angular.forEach(items, function (item) {
                        let ef = item.extendField[0];
                        if (ef.integrCode === it && ef.type && ef.type.length > 0) {
                            if (!ef.value)
                                res = false;
                            else if (ef.type.toLowerCase() === 'datetime') {
                                if (!ef.value.dateTime)
                                    res = false;
                            } else if (!ef.value[ef.type.toLowerCase()])
                                res = false;
                        }
                    });
                    return res;
                }

                function getNomenclCheckResult(items, lotData){
                    var result = [];
                    const priceForItem = $scope.isNew223() && lotData.priceForItem;
                    angular.forEach(items, function (item) {
                        const nonComp = !$scope.isPrequalification();
                        if((!(priceForItem || !nonComp) && (!item['okei'] || item['okei'].code==null )) || !item['okpd2']
                            || (!(priceForItem || !nonComp) && (item['qty'] == null || ((!$scope.isNew223() || $scope.isSimpleContest()) && !item['qty'])))
                            || !item['name']
                            || priceForItem && (!item['commodityItemPriceNoNDS'] || !item['commodityItemPriceWithNDS']))
                            result.push(item['ordinalNumber']);
                    });

                    return result;
                }

                $scope.focusFirstInvalid = function (data) {
                    var form = data.form;
                    var tab = data.tab;
                    if (noticeSrv.getShowPanel() !== tab) {
                        setActivePanelFn(tab);
                    }
                    setTimeout(function () {//отрисовка
                        $scope.submitted = true;
                        if (form && form.$error && form.$error.required && form.$error.required[0]
                            && form.$error.required[0].$$element && form.$error.required[0].$$element[0]) {
                            desktopSrv.scollTop();
                            if(form.$error.required[0].$$attr.focusOn){
                                $scope.$broadcast(form.$error.required[0].$$attr.focusOn);
                                desktopSrv.scrollIntoViewFn(form.$error.required[0].$$element[0],false);
                            }
                            else{
                                form.$error.required[0].$$element[0].focus();
                            }

                        }
                    }, 400)
                };

                $scope.focusFirstInvalidOnPanel = function (form,panel) {
                    setActivePanelFn(panel);

                    setTimeout(function(){//отрисовка
                        $scope.submitted = true;
                        if (form && form.$error && form.$error.required && form.$error.required[0]
                            && form.$error.required[0].$$element && form.$error.required[0].$$element[0]) {
                            desktopSrv.scollTop();
                            form.$error.required[0].$$element[0].focus();
                        }
                    }, 400)

                };

                function checkDates(model) {
                    const form = $scope.procedureForm.form;
                    if (!$scope.checkDateTimeGEMsk(model.claimEnds, model.claimBegins, true)) {
                        $scope.alertObjSaveProcedure = alertSrv.getErrorMsg('Дата окончания подачи заявок должна быть позже даты начала подачи заявок, дата и время окончания подачи заявок должны быть позже текущих даты и времени.');
                        form['claimEnds'].$setValidity("claimEnds", false);
                        return false;
                    } else
                        form['claimEnds'].$setValidity("claimEnds", true);
                    if($scope.isNew223() && !$scope.isSimpleContest()) {
                        if (!$scope.checkDateTimeGEMsk(model.placingProcedure?.examinationDateTime, model.claimEnds)) {
                            $scope.alertObjSaveProcedure = alertSrv.getErrorMsg("Рассмотрение заявок не может быть назначено раньше даты и времени окончания срока подачи заявок.");
                            form['examinationDateTime'].$setValidity("examinationDateTime", false);
                            return false;
                        } else
                            form['examinationDateTime'].$setValidity("examinationDateTime", true);
                        if (!$scope.checkDateTimeGEMsk(model.placingProcedure?.summingupDateTime, model.claimEnds)) {
                            $scope.alertObjSaveProcedure = alertSrv.getErrorMsg("Подведение итогов не может быть назначено раньше даты и времени окончания срока подачи заявок.");
                            form['summingupDateTime'].$setValidity("placingProcedure.summingupDateTime", false);
                            return false;
                        } else
                            form['summingupDateTime'].$setValidity("placingProcedure.summingupDateTime", true);
                        if (!($scope.checkDateTimeGEMsk(model.placingProcedure?.summingupDateTime, model.claimEnds) && $scope.checkDateTimeGEMsk(model.placingProcedure?.summingupDateTime, model.placingProcedure?.examinationDateTime))) {
                            $scope.alertObjSaveProcedure = alertSrv.getErrorMsg("Подведение итогов не может быть назначено раньше даты и времени рассмотрения заявок.");
                            form['summingupDateTime'].$setValidity("placingProcedure.summingupDateTime", false);
                            return false;
                        } else
                            form['summingupDateTime'].$setValidity("placingProcedure.summingupDateTime", true);
                    }
                    return true;
                }

                /**
                 * Преобразованнная модель
                 */
                function modelForSave(models) {
                    updateBothSum();

                    const model = angular.copy(models);
                    desktopSrv.removeKeys(model, 'deff__');
                    desktopSrv.removeKeys(model, '_error');
                    if (model.customer) {
                        if (model.customer.name && model.customer.id) {
                            model.customerName = model.customer.name;
                            model.customerCode = model.customer.id;
                            delete model.customer;
                        }
                    } else {
                        if (!(model.customerName && model.customerCode)) {
                            $scope.alertObjSaveProcedure = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');
                            return;
                        }
                    }

                    if(!checkDates(model))
                        return;
                    
                    if (model.organizer) {
                        if (model.organizer.name && model.organizer.id) {
                            model.organizerCode = model.organizer.id;
                            model.organizerName = model.organizer.name;
                            model.notificationControl = model.organizer.organizerNotificationControl;
                            delete model.organizer;
                        }
                    } else {
                        if (!(model.organizerName && model.organizerCode)) {
                            $scope.alertObjSaveProcedure = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');
                            return;
                        }
                    }

                    if(model.mailingList){
                        model.mailingListId = model.mailingList.id;
                        model.etzpQualificationNumber = model.mailingList.title;
                        delete model.mailingList;
                    }

                    for (let i = 0; i < model.lots.lot.length; i++) {
                        const lot = model.lots.lot[i];

                        if (['prequalification', 'unlimitedprequalification'].includes($stateParams.type)) {
                            delete model.lots.lot[i].contractSum;
                            delete model.lots.lot[i].contractSumNoNDS;
                            delete model.lots.lot[i].wholeLotPriceNoNDS;
                            delete model.lots.lot[i].wholeLotPriceWithNDS;
                            if(model.lots.lot[i].lotData != null) {
                                delete model.lots.lot[i].lotData.commodityPrice;
                                delete model.lots.lot[i].lotData.commodityPriceRub;
                                delete model.lots.lot[i].lotData.maxContractPrice;
                                delete model.lots.lot[i].lotData.maxContractPriceRub;
                                delete model.lots.lot[i].lotData.priceForItem;
                                delete model.lots.lot[i].lotData.priceFormula;
                                delete model.lots.lot[i].lotData.startingContractPriceRub;
                                delete model.lots.lot[i].lotData.applicationSupplySumm;
                                delete model.lots.lot[i].lotData.applicationSupplyCurrency;
                                delete model.lots.lot[i].lotData.methodPriceSet;
                            }

                        }

                        if (lot.lotItems.lotItem) {
                            for (let j = 0; j < lot.lotItems.lotItem.length; j++) {
                                delete model.lots.lot[i].lotItems.lotItem[j].showDeliveryPlace;

                                if (['prequalification', 'unlimitedprequalification'].includes($stateParams.type)) {
                                    delete model.lots.lot[i].lotItems.lotItem[j].commodityItemPriceNoNDS;
                                    delete model.lots.lot[i].lotItems.lotItem[j].commodityItemPriceRubNoNDS;
                                    delete model.lots.lot[i].lotItems.lotItem[j].commodityItemPriceWithNDS;
                                    delete model.lots.lot[i].lotItems.lotItem[j].commodityItemPriceRubWithNDS;
                                }
                            }
                        }
                        if(!model.jointPurchase){
                            delete model.lots.lot[i].jointLotData;
                            delete model.lots.lot[i].customer;
                        } else {
                            if (model.lots.lot[i].customer) {
                                delete model.lots.lot[i].jointLotData;
                                convertCustomersToJointLotData(model, i);
                                delete model.lots.lot[i].customer;
                            }
                        }

                        if(model.lots.lot[i].lotData != null && (model.priceType==='NO_PRICE' || !$scope.isPercentPriceMode())) {
                            delete model.lots.lot[i].lotData.startPercent;
                            delete model.lots.lot[i].lotData.percentMode;
                        }
                        if($scope.isPercentPriceMode()){
                            delete model.lots.lot[i].contractSum;
                            delete model.lots.lot[i].contractSumNoNDS;
                        }
                    }
                    if(model.priceType==='NO_PRICE' || !$scope.hasPriceMode())
                        delete model.priceMode;

                    if(!($scope.pf.russianPriority && !$scope.isSimpleContest() && !$scope.isSaleAucOrOpenPurlicOffer() && !$scope.isCommercialWitInitSum()))
                        model.russianPriority = false;

                    if(model.simplifiedProcedure) {
                        delete model.protocolPART1PublicationDateTime;
                        delete model.protocolStage2PART1PublicationDateTime;
                    }

                    if(noticeSrv.isNew223()){
                        // Обработать checkbox'ы
                        if(!model.notDishonest) model.notDishonest = false;
                        if(!model.emergency) model.emergency = false;
                        if(!$scope.isNoticeIntegrationEIS()) {
                            delete model.templateVersion;
                            delete model.extendFields;
                            angular.forEach(model.lots.lot, function (item) {
                                delete item.extendFields;
                            });
                        } else {
                            if(noticeSrv.isShowExtendedFields() && extendFieldSrv.isLoadTemplate() && noticeSrv.isNoticeExtFields() && $scope.isNoticeIntegrationEIS()) {
                                $scope.$parent.$parent.$broadcast('uetp.extendNoticeField', {
                                    type: 'refresh',
                                    ignoreOpenState: true
                                });
                            }
                        }
                        for (let i = 0; i < model.lots.lot.length; i++) {
                            if(!model.lots.lot[i].lotData.excludePurchaseFromPlan) model.lots.lot[i].lotData.excludePurchaseFromPlan = false;
                            if(!model.lots.lot[i].lotData.centralized) model.lots.lot[i].lotData.centralized = false;
                            if(!model.lots.lot[i].lotData.antimonopolyDecisionTaken) model.lots.lot[i].lotData.antimonopolyDecisionTaken = false;
                            if(!model.lots.lot[i].lotData.ignoredPurchase) model.lots.lot[i].lotData.ignoredPurchase = false;
                            if(!['simplecontest', 'prequalification', 'unlimitedprequalification', 'saleauction', 'openpublicoffer'].includes($stateParams.type)) {
                                if (!model.lots.lot[i].lotData.applicationSupplyNeeded) model.lots.lot[i].lotData.applicationSupplyNeeded = false;
                                if (!model.lots.lot[i].lotData.completingSupplyNeeded) model.lots.lot[i].lotData.completingSupplyNeeded = false;
                            }
                            if(!model.lots.lot[i].lotData.priceForItem) model.lots.lot[i].lotData.priceForItem = false;
                            if(model.lots.lot[i].cancellation && !model.lots.lot[i].cancellation.emergency) model.lots.lot[i].cancellation.emergency = false;
                            if(model.lots.lot[i].currency.digitalCode==='643') delete model.lots.lot[i].lotData.exchangeInfo;
                            if(!model.lots.lot[i].cancelled) delete model.lots.lot[i].cancellation;
                        }

                        // вкладка документация
                        if(model.documentationDelivery) {
                            model.documentationDelivery.place = $scope.notice.document.documentationDelivery.place;
                            model.documentationDelivery.procedure = $scope.notice.document.documentationDelivery.procedure;
                            model.documentationDelivery.deliveryEndDateTime = $scope.notice.document.documentationDelivery.deliveryEndDateTime;
                            model.documentationDelivery.deliveryStartDateTime = $scope.notice.document.documentationDelivery.deliveryStartDateTime;
                        }
                        if(!$scope.showCustomerBranches())
                            delete model.customerBranch;
                    }

                    // UETP-4642 -  Для однолотовых закупок, где закупка проводится за единичные расценки, в извещении на вкладке
                    // "Извещение о процедуре" поля "Начальная (максимальная) цена договора без НДС" и "Начальная (максимальная) цена
                    // договора c НДС" заполнять значением из полей вкладки "Лоты" - "Начальная (максимальная) цена договора по лоту
                    // без НДС*" и "Начальная (максимальная) цена договора по лоту с НДС*", соответственно.
                    if(model.lots.lot.length===1 && model.lotData && ($scope.isPercentPriceMode() || model.lotData.priceForItem===true)){
                        model.priceNoNDS = model.lots.lot[0].wholeLotPriceNoNDS;
                        model.price = model.lots.lot[0].wholeLotPriceWithNDS;
                    }
                    if($scope.isSimpleContest44())
                        $scope.model.priceNoNDS = $scope.model.price;

                    if(noticeSrv.isNewCommercial() || noticeSrv.isNew44()){
                        for (let i = 0; i < model.lots.lot.length; i++) {
                            if(model.lots.lot[i].lotData != null && !model.lots.lot[i].lotData.priceForItem) model.lots.lot[i].lotData.priceForItem = false;
                            if(!['simplecontest', 'prequalification', 'unlimitedprequalification', 'saleauction', 'openpublicoffer'].includes($stateParams.type)) {
                                if (noticeSrv.isNewCommercial() && !model.lots.lot[i].lotData.applicationSupplyNeeded) model.lots.lot[i].lotData.applicationSupplyNeeded = false;
                                if (noticeSrv.isNewCommercial() && !model.lots.lot[i].lotData.completingSupplyNeeded) model.lots.lot[i].lotData.completingSupplyNeeded = false;
                            }
                            if(model.lots.lot[i].lotData != null && model.lots.lot[i].currency.digitalCode==='643') delete model.lots.lot[i].lotData.exchangeInfo;
                        }
                    }

                    // UETP-9517
                    if(noticeSrv.showApplicationSupply())
                        model.lots.lot.forEach(lot=>{
                            if(!lot.lotData.applicationSupplyNeeded)
                                delete lot.lotData.depositPaymentDetails;
                        });

                   /*
                    * При открытой вкладке получаем актуальную модель в model.extendFields
                    * */
                    $scope.$parent.$parent.$broadcast('uetp.extendNoticeField', {type:'refresh'});
                    return model;
                }

                // заменяем customer на jointLotData
                function convertCustomersToJointLotData(model, i){
                    model.lots.lot[i].jointLotData={
                        jointLot:true,
                        lotCustomers:[]
                    };
                    model.lots.lot[i].jointLotData.lotCustomers={lotCustomer:[]};
                    angular.forEach(model.lots.lot[i].customer, function (customer) {
                        model.lots.lot[i].jointLotData.lotCustomers.lotCustomer.push({
                            lotCustomerEditEnabled:false,
                            customerInfo:{
                                fullName: customer.name,
                                shortName: customer.shortName,
                                iko: customer.iko,
                                inn: customer.inn,
                                kpp: customer.kpp,
                                ogrn: customer.ogrn,
                                legalAddress: customer.legalAddress && customer.legalAddress!=null ? customer.legalAddress.addressAsString : null,
                                postalAddress: customer.postalAddress && customer.postalAddress!=null ? customer.postalAddress.addressAsString : null,
                                phone: customer.phone,
                                email: customer.email,
                                okpo: customer.okpo
                            }
                        });
                    });
                }

                $scope.$on('getLotsRequiredProcedureFinished', function(event, result){
                    $scope.lotRequiredFields = result;
                });

                /**
                 * Авто заполнение полей
                 * @param model - модель
                 * @param object - объект в модели
                 * @param select - выбранное значение
                 */
                function autoFillByTypeDictFn(model, object, select) {
                    dictionarySrv.autoFillByTypeDict($scope, model, object, select);
                }

                /**
                 * Преобразуем модель для удобной работы
                 */
                function initModel() {
                    if ($scope.model){
                        if((noticeSrv.isNew223() || noticeSrv.isInitedNotice())&& !$scope.model.id){
                            $scope.model.notDishonest = true;
                            $scope.model.whoPublish = 'customers';
                        }

                        if(noticeSrv.isNew223() && $scope.notice.document){
                            if(!$scope.notice.document.documentationDelivery)
                                $scope.notice.document.documentationDelivery = {};
                            $scope.notice.document.documentationDelivery.deliveryStartDateTime = $scope.model.claimBegins;
                        }

                        initOrganizer();
                        
                        if(noticeSrv.isNew223()) {
                            $scope.model.deliveryPlaceIndication = $scope.model.deliveryPlaceIndication || $scope.deliveryPlaceIndicationVariants[1].id
                        }
                        if($scope.model && $scope.model.priceType!=='NO_PRICE' && !$scope.isReadOnly) {
                            if(!($scope.isNoticeChange() && $scope.model.priceMode===null))
                                $scope.model.priceMode = $scope.model.priceMode || $scope.priceModes[0].id;
                        }

                        if($scope.model.mailingListId){
                            $scope.model.mailingList = {
                                id: $scope.model.mailingListId,
                                title: $scope.model.etzpQualificationNumber
                            }
                        }
                    }

                    if(noticeSrv.isInitedNotice() && $stateParams.dataProc){
                        $scope.notice.document.purchaseMethodCode = $stateParams.dataProc.method_code;
                        $scope.notice.document.purchaseCodeName = $stateParams.dataProc.method_name;
                        $scope.notice.document.schemaVersion = 1;
                        $scope.model.deliveryPlaceIndication = $scope.model.deliveryPlaceIndication || $scope.deliveryPlaceIndicationVariants[1].id;

                        // вкладка документация
                        $scope.model.documentationDelivery = {};
                        $scope.model.documentationDelivery.deliveryStartDateTime = $scope.notice.document.claimBegins;

                        noticeSrv.getCustomerByInn($stateParams.dataProc.customers[0].inn).then(function (response) {
                            $rootScope.$broadcast('uetp.notice', {data: $scope.model, dataType: 'lot', type: 'create', eisnds:
                                response.data && response.data.result ? response.data.result.eisnds : true, planData: $stateParams.dataProc});
                        });
                    }
                    checkPlanLot();
                    // получаем список обязательных полей для лота и номенклатуры
                    if(noticeSrv.isNew223()) {
                        setTimeout(function () {
                            $rootScope.$broadcast('uetp.notice', {data: $scope.model, dataType: 'lot', type: 'getLotsRequired'});
                        }, 1000);
                    }
                }
                /*
                * Проверка наличия лотов из плана
                * */
                function checkPlanLot() {
                    $scope.havePlanLot = false;
                    if($scope.model.customer && $scope.model.lots){
                        angular.forEach($scope.model.lots.lot, function (lot) {
                            if(lot.lotPlanInfo && lot.lotPlanInfo.positionGuid){
                                $scope.havePlanLot = true;
                            }
                        })
                    }
                }
                /**
                 * Опубликовать процедуру
                 */
                function publishProcFn(model) {
                    delete $scope.winnerPaymentError;
                    if ($scope.auth.certId || $scope.auth.withoutCertEnabled) {
                        return noticeSrv.getDataForSign($scope.notice.type.id, $scope.notice.id).then(function (response) {
                            if (response.data.success) {
                                if($scope.auth.withoutCertEnabled){
                                    $scope.notice.document = model;
                                    return noticeSrv.sendSignData($scope.notice.type.id, $scope.notice.id, $scope.notice).then(function (response) {
                                        if (response.data.success) {
                                            if (['limitedcontest','limitedauction','limitedrequestquotations','limitedcontestqualification'].includes($scope.notice.type.id)) {
                                                return signAndSendParticipantsDocFn(model.participantsDoc, response.data.result);
                                            } else {
                                                return openProcedureFn(response.data.result);
                                            }
                                        } else {
                                            $scope.alertObjSaveProcedure = alertSrv.getAlertObj(response);
                                        }
                                    });
                                } else {
                                    return cryptography.generateSignature(response.data.result, $scope.auth.certId).then(function (value) {
                                        $scope.notice.signature = value;
                                        $scope.notice.document = model;
                                        return noticeSrv.sendSignData($scope.notice.type.id, $scope.notice.id, $scope.notice).then(function (response) {
                                            if (response.data.success) {
                                                //$scope.alertObjSaveProcedure = alertSrv.getSuccessMsgWithBtn('Извещение опубликовано. ', 'Перейти', openProcedureFn, response.data.result);
                                                if (['limitedcontest','limitedauction','limitedrequestquotations','limitedcontestqualification'].includes($scope.notice.type.id)) {
                                                    return signAndSendParticipantsDocFn(model.participantsDoc, response.data.result);
                                                } else {
                                                    return openProcedureFn(response.data.result);
                                                }
                                            } else {
                                                if(['711','712'].includes(response.data.errorCode))
                                                    $scope.winnerPaymentError = response.data.errorMessage;
                                                else
                                                    $scope.alertObjSaveProcedure = alertSrv.getAlertObj(response);
                                            }
                                        });
                                    }, function (reason) {
                                        if (typeof reason === 'string') {
                                            $scope.alertObjSaveProcedure = alertSrv.getErrorMsg("Ошибка подписи документа. " + reason);
                                        } else {
                                            $scope.alertObjSaveProcedure = alertSrv.getErrorMsg("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                                        }
                                    })
                                }
                            } else {
                                if(response.data.errorCode==='427') {
                                    $scope.errorMsg = response.data.errorMessage.replace('\n','<br/>');
                                    setTimeout(() => delete $scope.errorMsg, 7000);
                                } else
                                    $scope.alertObjSaveProcedure = alertSrv.getAlertObj(response);
                            }
                        })
                    } else {
                        $scope.alertObjSaveProcedure = alertSrv.getErrorMsg('У вас не установлен сертификат.');
                    }

                }

                $scope.closeAlert = function () {
                    delete $scope.winnerPaymentError;
                };

                function signAndSendParticipantsDocFn(model, openProcedureData) {
                	 if ($scope.auth.certId && !$scope.auth.withoutCertEnabled) {
                         return noticeSrv.getDataForSignParticipantsDoc($scope.notice.type.id, $scope.notice.id).then(function (response) {
                             if (response.data.success) {
                                 return cryptography.generateSignature(response.data.result, $scope.auth.certId).then(function (value) {
                                     $scope.participantsDoc = {};
                                     $scope.participantsDoc.signature = value;
                                     //$scope.participantsDoc.document = model;
                                     return noticeSrv.sendSignDataParticipantsDoc($scope.notice.type.id, $scope.notice.id, $scope.participantsDoc).then(function (response) {
                                         if (response.data.success) {
                                             return openProcedureFn(openProcedureData);
                                         } else {
                                             $scope.alertObjSaveProcedure = alertSrv.getAlertObj(response);
                                         }
                                     });
                                 }, function (reason) {
                                     if (typeof reason === 'string') {
                                         $scope.alertObjSaveProcedure = alertSrv.getErrorMsg("Ошибка подписи документа. " + reason);
                                     } else {
                                         $scope.alertObjSaveProcedure = alertSrv.getErrorMsg("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                                     }
                                 })

                             } else {
                                 $scope.alertObjSaveProcedure = alertSrv.getAlertObj(response);
                             }
                         })
                     } else if($scope.auth.withoutCertEnabled) {
                	     $scope.participantsDoc = {};
                	     return noticeSrv.sendSignDataParticipantsDoc($scope.notice.type.id, $scope.notice.id, $scope.participantsDoc).then(function (response) {
                              if (response.data.success) {
                                  return openProcedureFn(openProcedureData);
                              } else {
                                  $scope.alertObjSaveProcedure = alertSrv.getAlertObj(response);
                              }
                	     });
                     } else {
                         $scope.alertObjSaveProcedure = alertSrv.getErrorMsg('У вас не установлен сертификат.');
                     }
                }
                /**
                 * Удалить процедуру
                 */
                function deleteProcFn() {
                    noticeSrv.deleteProc($scope.notice).then(function (response) {
                        if (response.data.success) {
                            $scope.alertObjSaveProcedure = alertSrv.getAlertObj(response, 'Извещение удалено');
                            $state.go('notices',null,{location :'replace'});
                        } else {
                            $scope.alertObjSaveProcedure = alertSrv.getAlertObj(response);
                        }
                    });
                }

                function initButtonFn(action) {
                    if (action.id.indexOf('editNotice') >= 0) {
                        saveModelFn($scope.model);
                    }
                    if (action.id.indexOf('publishNotice') >= 0) {
                        $scope.submitted = true;
                        var model = modelForSave($scope.model);
                        if(!model)
                            return;
                        $scope.actionsInProcess[action.id]=true;
                        saveFn(model, 'publishNotice').then(function (response) {
                            if (response.data.success) {
                                noticeSrv.setAttachEdit(false);
                                if ($scope.notice.document.typeCode!=='simplecontest' && $scope.notice.document.attachment.length<1) {
                                    $scope.alertObjSaveProcedure = alertSrv.getErrorMsg('Документ должен содержать минимум одно вложение.');
                                    return;
                                }
                                return publishProcFn(model);
                            } else {
                                $scope.alertObjSaveProcedure = alertSrv.getAlertObj(response);
                            }
                        }).then(function () {
                            $scope.actionsInProcess[action.id]=false;
                        },function (reason) {
                            $scope.actionsInProcess[action.id]=false;
                            if (reason) {
                                console.log(reason)
                            }
                        });
                    }
                    if (action.id.indexOf('deleteNotice') >= 0) {
                        deleteProcFn();
                    }
                }

                /**
                 * Устанвоить активную вкладку
                 * @param panel
                 */
                function setActivePanelFn(panel) {
                    noticeSrv.setShowPanel(panel);
                }

                /**
                 * Открыть опубликованную процедуру
                 * @param id - id процедуры
                 */
                function openProcedureFn(id) {
                    return $state.go('openProcedure', {procType: $scope.notice.type.id.toLowerCase(), procId: id},{location :'replace'});
                }

                function saveModelFn(model) {
                    var claimEndsDate = new Date(model.claimEnds);
                    claimEndsDate.getHours() == 0 && claimEndsDate.getMinutes() == 0 ? $scope.checkField = true : $scope.checkField = false;

                    $scope.processing = true;
                    $scope.submitted = true;
                    noticeSrv.setSubmitted(true);
                    saveFn(modelForSave(model)).then(function (response) {
                        if (response.data.success) {
                            $scope.alertObjSaveProcedure = alertSrv.getAlertObj(response, 'Сохранение произведено успешно!');
                            noticeSrv.setAttachEdit(false);
                            if (response.data.result) {
                                //выставляем id после сохранения
                                $scope.notice.id = response.data.result;
                                $scope.notice.document.id = response.data.result;
                                $scope.model.id = response.data.result;

                                $state.go('notice', {
                                    type: $scope.notice.type.id,
                                    id: response.data.result,
                                    successSave: true
                                }, {location: 'replace'});
                            }
                        } else {
                            $scope.alertObjSaveProcedure = alertSrv.getAlertObj(response);
                        }
                        $scope.processing = false;
                        timeout($scope);
                    }, function (reason) {
                        if (reason) {
                            console.log(reason)
                        }
                        $scope.processing = false;
                        timeout($scope);
                    });
                }
                function goBackFn() {
                	noticeSrv.setAttachEdit(false);
                    history.back();
                }
                /**
                 * Закрыть форму
                 */
                function closeFormFn() {
                    //запрашиваем подтверждение если что-то меняли
                    if (!$scope.isReadOnly && ($scope.procedureForm.form.$dirty || $scope.model.attachment.length || $scope.model.lots.lot.length)) {
                        needConfirmSrv.call(goBackFn, [], 'Внесенные изменения сохранены не будут');
                    } else {
                        goBackFn();
                    }
                }
                function getFormFn() {
                    return $scope.procedureForm.form;
                }
                function changeDateFn() {
                    let stop = false;
                    if(['openpublicoffer','openauction','limitedauction','saleauction','auctionsmb'].includes($scope.model.typeCode)) stop  = checkDateFn();
                    if(stop) return;

                    const form = $scope.procedureForm.form;

                    if($scope.model.claimEnds && $scope.model.typeCode === "unlimitedprequalification"){
                        const submissionClosePKODateTime = new Date(angular.copy($scope.model.submissionClosePKODateTime)).setSeconds(0, 0),
                            claimEndsFull = new Date(angular.copy($scope.model.claimEnds)).setSeconds(0, 0);
                        const lastEnds = submissionClosePKODateTime instanceof Date ? Date.parse(submissionClosePKODateTime) : submissionClosePKODateTime,
                            endFull = claimEndsFull instanceof Date ? Date.parse(claimEndsFull) : claimEndsFull;
                        if($scope.model.claimEnds && $scope.model.submissionClosePKODateTime) {
                            let eVal=false, eTitle='';
                            if (lastEnds <= endFull) {
                                eVal = true;
                                eTitle = "Дата и время последнего окончания подачи заявок должна быть позже даты и времени первого окончания подачи заявок";
                            }
                            form['submissionClosePKODateTime'].$setValidity("endDate", !eVal);
                            if(eVal) form['submissionClosePKODateTime'].$setDirty();
                            form['submissionClosePKODateTime'].$$element[0].title = eVal?eTitle:"";
                        }
                    }
                    checkDates($scope.model);


                    if(noticeSrv.isNew223()){
                        if(!$scope.notice.document.documentationDelivery)
                            $scope.notice.document.documentationDelivery = {};
                        $scope.notice.document.documentationDelivery.deliveryStartDateTime = $scope.model.claimBegins;
                        $scope.notice.document.documentationDelivery.deliveryEndDateTime = $scope.model.typeCode ==='unlimitedprequalification' ? $scope.model.submissionClosePKODateTime : $scope.model.claimEnds;
                    }
                }

                $scope.checkDateTimeGEMsk = function(field1, field2, diffDates=false) {
                    if(field1 && field2){
                        const field1Date = new Date(angular.copy(field1)).setSeconds(0, 0), field2Date = new Date(angular.copy(field2)).setSeconds(0, 0);
                        const date1 = field1Date instanceof Date ? Date.parse(field1Date) : field1Date, date2 = field2Date instanceof Date ? Date.parse(field2Date) : field2Date;
                        const msk = getServerTimeMsk();
                        return !diffDates ? date1>=date2 && date1>=msk : new Date(angular.copy(field1)).setHours(0,0,0,0)!==new Date(angular.copy(field2)).setHours(0,0,0,0) && date1>=date2 && date1>=msk;
                    }
                    return true;
                }
                $scope.checkDateTimeGE = function(field1, field2) {
                    if(field1 && field2){
                        const field1Date = new Date(angular.copy(field1)).setSeconds(0, 0), field2Date = new Date(angular.copy(field2)).setSeconds(0, 0);
                        const date1 = field1Date instanceof Date ? Date.parse(field1Date) : field1Date, date2 = field2Date instanceof Date ? Date.parse(field2Date) : field2Date;
                        return date1>=date2;
                    }
                    return true;
                }
                function checkDateFn() {
                   return noticeSrv.checkDate($scope.procedureForm.form,angular.copy($scope.model.claimEnds),$scope.model.lots.lot,'claimEnds');
                }
                function englishSymbolFn(field) {
                    noticeSrv.englishSymbol($scope.procedureForm.form,field);
                }

                function needUpdateExtendFields() {
                    let currentCustomer = angular.copy(noticeSrv.getSelectedCustomer());
                    let newCustomer = angular.copy($scope.model.customer);
                    // if((angular.isUndefined(currentCustomer) || currentCustomer==null) && angular.isDefined(newCustomer)) return false;
                    if(angular.isUndefined(newCustomer) || angular.isUndefined(currentCustomer) || currentCustomer===null) return true;
                    return !( currentCustomer.id===newCustomer.id ||
                        (currentCustomer.customerType === 'division' &&
                        newCustomer.customerType === 'head' &&
                        currentCustomer.parent && currentCustomer.parent.id === newCustomer.id) ||
                        (newCustomer.customerType === 'division' &&
                        currentCustomer.customerType === 'head' &&
                        newCustomer.parent && newCustomer.parent.id === currentCustomer.id) ||
                        (newCustomer.customerType === 'division' &&
                        currentCustomer.customerType === 'division' &&
                        newCustomer.parent && currentCustomer.parent && newCustomer.parent.id === currentCustomer.parent.id));
                }

                $scope.resetCustomerBranch = function() {
                    if($scope.model.customerBranch)
                        delete $scope.model.customerBranch;
                }

                $scope.resetWinnerPayment = () => {
                    if($scope.model.tariffType!=='winnerPays') {
                        for (let i = 0; i < $scope.model.lots.lot.length; i++) {
                            delete $scope.model.lots.lot[i].winnerPayment;
                        }
                    }
                }

                function changeCustomerFn(ignoreExtFieldClear, initCall = false){
                    noticeSrv.setCustomerLoading(true);

                    reassignCustomer();

                    let needUpdateExtFields = ignoreExtFieldClear ? false : needUpdateExtendFields() || noticeSrv.isInitedNotice();
                    if(needUpdateExtFields) {
                        noticeSrv.setNoticeShowCustomerEISError(false);
                        delete $scope.notice.noticeIntegrationEIS;
                        clearExtendFields();
                    }

                    noticeSrv.setNoticeExtFields(false);
                    if(['requestproposals','limitedrequestproposals','requestquotations'].includes($scope.model.typeCode) && $scope.model.customer) {
                        if($scope.model.customer.rzdCustomer)
                            $scope.model.simplifiedProcedure = false;
                    }

                    if($scope.model.customer && $scope.model.customer.id) {
                        noticeSrv.setSelectedCustomer($scope.model.customer);

                        if ($scope.model.customer?.eisKis && isShowNonCompetitiveFn())
                            $scope.model.nonCompetitive = true;

                        if (!initCall || $scope.createFromPurchasePlan) {  // только смена заказчика из списка
                            if($scope.createFromPurchasePlan)
                                delete $scope.createFromPurchasePlan;
                            if ($scope.hasWinnerTariff() && $scope.isWinnerTariffAllowed()) {  // allowed_winner_tariff = true
                                if (!$scope.model.tariffType)
                                    $scope.model.tariffType = 'winnerPays';
                            } else {
                                delete $scope.model.tariffType;
                                $scope.resetWinnerPayment();
                            }
                        }

                        if($scope.model.typeCode !== 'simplecontest'){
                        // устанавливаем purchaseMethodCode и purchaseCodeName
                            extendFieldSrv.getPurchaseMethod($scope.model.typeCode, $scope.model.customer.id).then(function (response) {
                                if (response.data.success) {
                                    if(!$scope.isReadonlyDefined()) {
                                        $scope.notice.document.purchaseMethodCode = response.data.result.eisCode;
                                        $scope.notice.document.purchaseCodeName = response.data.result.eisName;
                                    }
                                } else {
                                    extendFieldSrv.setTemplateVersion(null);
                                }
                            });
                        }
                        if(noticeSrv.isShowExtendedFields()) {

                            let noticeIntegrationEIS = $scope.model.typeCode !== 'simplecontest' && $scope.notice && angular.isDefined($scope.notice.noticeIntegrationEIS) ? $scope.notice.noticeIntegrationEIS :
                                ($scope.model.customer && $scope.model.customer.customerType==='division' && $scope.model.customer.parent ?
                                $scope.model.customer.parent.noticeIntegrationEIS : $scope.model.customer.noticeIntegrationEIS);
                            
                            noticeSrv.setNoticeIntegrationEIS(noticeIntegrationEIS);
                        	if(!noticeIntegrationEIS){
                        		extendFieldSrv.clearNoticeExtFieldTemplate();
                                extendFieldSrv.setLoadTemplate(false);
                        		extendFieldSrv.setNoticeField(null);
                                extendFieldSrv.setTemplateVersion(null);
                                refreshExtFields();
                                return;
                        	}
                        	
 	                        if(needUpdateExtFields) {
                                refreshExtFields();
	                        } else {
                                noticeSrv.setNoticeExtFields(true);
                                noticeSrv.setCustomerLoading(false);
                            }
                        }
                        
                    }else{
                        noticeSrv.setSelectedCustomer(null);
                        $scope.notice.document.purchaseMethodCode = null;
                        extendFieldSrv.setTemplateVersion(null);
                        extendFieldSrv.setLoadTemplate(false);
                    }
                }
                function reassignCustomer() {
                    if($scope.model.customer && $scope.model.customer.id && !$scope.model.customer.customerType && $scope.customerDict) {
                        $scope.model.customer = $scope.customerDict.find(cd => cd.id===$scope.model.customer.id);
                    }
                }

                function refreshExtFields(){
                    //пытаемся получить новый шаблон
                    $scope.$parent.$parent.$broadcast('uetp.extendNoticeField', {customerId:$scope.model.customer.id,typeCode:$scope.model.typeCode, type: 'newLoad'});
                }
                function clearExtendFields(){
                    if($scope.model.extendFields) $scope.model.extendFields = {};
                    angular.forEach($scope.model.lots.lot, function (item) {
                        if(item.extendFields) {
                            item.extendFields = {};
                        }
                    });
                    extendFieldSrv.clearNoticeExtFieldTemplate();
                    $scope.notice.document.purchaseMethodCode = '';
                    $scope.notice.document.purchaseCodeName = '';
                }
                function changeQualificationSelectFn(select){
                    if(select.$select.selected) {
                        organizatorSrv.getMailingListById(select.$select.selected.id).then(function successCallback(response) {
                            if (response.data.success) {
                                let innList = response.data.result.participants.map(part => part.name);
                                $scope.$parent.$parent.setParticipantList(innList)
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        });
                    } else {
                        $scope.$parent.$parent.setParticipantList([]);
                    }
                }
                function getProtocolNameByTypeFn(procType, protocolType){
                    if(protocolType==='OPEN' && ['opencontest','requestquotations','limitedcontest','limitedrequestquotations','requestproposals','limitedrequestproposals','prequalification'].includes(procType))
                        return 'протокола вскрытия заявок';
                    else if(protocolType==='PARTS' && procType==='prequalification')
                        return "протокола рассмотрения заявок";
                    else if(protocolType==='PART1'){
                        if(['opencontest','requestquotations','limitedcontest','limitedrequestquotations'].includes(procType))
                            return 'протокола рассмотрения и оценки заявок';
                        else if(procType==='open2stagecontest')
                            return 'протокола рассмотрения первоначальных заявок';
                        else if(['requestproposals','limitedrequestproposals'].includes(procType))
                            return 'протокола запроса предложений';
                    }
                    else if(protocolType==='REZ'){
                        if(['opencontest','requestquotations','limitedcontest','limitedrequestquotations','requestproposals',
                            'limitedrequestproposals','open2stagecontest','prequalification','offerplacement','limitedcontestqualification','simplecontest'].includes(procType))
                            return 'протокола конкурсной комиссии';
                    }
                }
                function definedForProcedureFn(procType, protocolType){
                    if(['openauction','limitedauction','saleauction','openpublicoffer'].includes(procType)) return false;

                    else if(protocolType==='PART1')
                        return ['opencontest','requestquotations','limitedcontest','limitedrequestquotations','open2stagecontest','requestproposals','limitedrequestproposals'].includes(procType);
                    else if(protocolType==='REZ')
                        return ['opencontest','requestquotations','limitedcontest','limitedrequestquotations','requestproposals',
                            'limitedrequestproposals','open2stagecontest','prequalification','offerplacement','limitedcontestqualification','simplecontest'].includes(procType);
                    else if(protocolType==='PARTS')
                            return procType==='prequalification';
                    else return false;
                }

                function changeDeliveryPlaceIndicationFn(){
                    if($scope.model.deliveryPlaceIndication==='SINGLE_PLACE'){
                        if(lotDiffentPlaceInited()){
                            needConfirmSrv.callVariants(deleteLotDeliveryPlacesFn, function (){$scope.model.deliveryPlaceIndication='DIFFERENT_PLACE';},[], 'Все введенные ранее сведения о месте поставки лотов будут удалены. Продолжить?');
                        }

                    } else if($scope.model.deliveryPlaceIndication==='DIFFERENT_PLACE'){
                        if($scope.model.lots.lot && $scope.model.lots.lot.length > 0){
                            needConfirmSrv.callVariants(deleteAndInitLotDeliveryPlacesFn, function (){$scope.model.deliveryPlaceIndication='SINGLE_PLACE';}, [], 'Введенные ранее сведения о едином месте поставки лотов будут скопированы в сведения по каждому лоту. Продолжить?');
                        }
                    }
                    $scope.notice.document.deliveryPlaceIndication = $scope.model.deliveryPlaceIndication;
                }
                function lotDiffentPlaceInited(){
                    var result=false;
                    if ($scope.model.lots.lot && $scope.model.lots.lot.length > 0)
                        for (var i = 0; i < $scope.model.lots.lot.length; i++) {
                            if ($scope.model.lots.lot[i].deliveryPlaceIndication)
                                result = true;
                        }
                    return result;
                }
                function deleteLotDeliveryPlacesFn(){
                    if ($scope.model.lots.lot && $scope.model.lots.lot.length > 0)
                        for (var i = 0; i < $scope.model.lots.lot.length; i++) {
                            delete $scope.model.lots.lot[i].lotData.deliveryPlace;
                            if($scope.model.lots.lot[i].lotItems.lotItem && $scope.model.lots.lot[i].lotItems.lotItem.length > 0){
                                for (var j = 0; j < $scope.model.lots.lot[i].lotItems.lotItem.length; j++) {
                                    delete $scope.model.lots.lot[i].lotItems.lotItem[j].deliveryPlace;
                                }
                            }
                        }
                }
                function initLotDeliveryPlaceFromSinglePlace(){
                    if ($scope.model.lots.lot && $scope.model.lots.lot.length > 0) {
                        for (var i = 0; i < $scope.model.lots.lot.length; i++) {
                            if($scope.model.deliveryPlacePurchase) {
                                $scope.model.lots.lot[i].lotData.deliveryPlace = $scope.model.deliveryPlacePurchase;
                                $scope.model.lots.lot[i].deliveryPlaceIndication='GL';
                                $scope.notice.document.lots.lot[i].lotData.deliveryPlace = $scope.model.deliveryPlacePurchase;
                                $scope.notice.document.lots.lot[i].deliveryPlaceIndication='GL';
                            }
                            else $scope.model.lots.lot[i].lotData.deliveryPlace = {};
                            if ($scope.model.lots.lot[i].lotItems.lotItem && $scope.model.lots.lot[i].lotItems.lotItem.length > 0) {
                                for (var j = 0; j < $scope.model.lots.lot[i].lotItems.lotItem.length; j++) {
                                    $scope.model.lots.lot[i].lotItems.lotItem[j].deliveryPlace = $scope.model.lots.lot[i].lotData.deliveryPlace;
                                }
                            }
                        }
                    }
                }

                /*
                Выставляем недостающие поля в зависимости от Места поставки (уровня извещения)
                 */
                function initLotDeliveryPlaceFromSinglePlaceModel(models){
                    const isSinglePlace = models.deliveryPlaceIndication === 'SINGLE_PLACE';
                    if (models.lots.lot && models.lots.lot.length > 0) {
                        models.lots.lot.forEach(lot=>{
                            if (isSinglePlace) {
                                lot.deliveryPlaceIndication = 'GL';
                                lot.lotData.deliveryPlace = models.deliveryPlacePurchase;
                                if (lot.lotItems.lotItem)
                                    lot.lotItems.lotItem.forEach(lotItem=>lotItem.deliveryPlace = lot.lotData.deliveryPlace);
                            } else if (lot.deliveryPlaceIndication === 'GL' && lot.lotItems.lotItem)
                                lot.lotItems.lotItem.forEach(lotItem=>lotItem.deliveryPlace = lot.lotData.deliveryPlace);
                        });
                    }
                    if(!isSinglePlace)
                        delete models.deliveryPlace;
                }
                function initLotNomenclatureDeliveryPlace(models){
                    if (models.lots.lot)
                        models.lots.lot.forEach(lot=>{
                            if (lot.deliveryPlaceIndication === 'GL' && lot.lotItems.lotItem)
                                lot.lotItems.lotItem.forEach(lotItem=>lotItem.deliveryPlace = lot.lotData.deliveryPlace);
                        });
                }

                function deleteAndInitLotDeliveryPlacesFn(){
                    initLotDeliveryPlaceFromSinglePlace();
                    delete $scope.model.deliveryPlacePurchase;
                }

                function isNoticeChangeFn(){
                    return $scope.model && $scope.model.version>1;
                }
                
                function priceModeChangeFn(){
                    for (var i = 0; i < $scope.model.lots.lot.length; i++) {
                        delete $scope.model.lots.lot[i].wholeLotPriceNoNDS;
                        delete $scope.model.lots.lot[i].wholeLotPriceWithNDS;
                        delete $scope.model.lots.lot[i].lotData.startPercent;
                        delete $scope.model.lots.lot[i].lotData.percentMode;
                        delete $scope.model.lots.lot[i].lotData.priceForItem;
                        delete $scope.model.lots.lot[i].contractSum;
                        delete $scope.model.lots.lot[i].contractSumNoNDS;
                        delete $scope.model.lots.lot[i].winnerPayment;
                    }

                    updateBothSum();
                    noticeSrv.updatePriceMode($scope.model.priceMode);
                }

                function priceTypeChangeFn(oldValue) {
                	let futureCallPrices = false, futureCallDeposit = false, futureCallSupply = false;
                    const priceInited = lotPricesInited(), depositFields = checkDepositFields(), supplyFields = checkSupplyFields();

                    if($scope.model.priceType==='NO_PRICE') {
                        if(priceInited && !depositFields && !supplyFields) {
                        	futureCallPrices = true;
                        }
                    } else {
                        updateBothSum();

                        if($scope.model && $scope.model.priceType!=='NO_PRICE') {
                            if(!($scope.isNoticeChange() && $scope.model.priceMode===null))
                                $scope.model.priceMode = $scope.model.priceMode || $scope.priceModes[0].id;
                        }
                        if($scope.model?.priceType!=='NO_PRICE' && (!oldValue || 'NO_PRICE'===oldValue))
                            $scope.initShowPriceNds();
                    }

                    if (!$scope.model.lots.lot || $scope.model.lots.lot.length===0)
                        return;

                    if('NO_PRICE'===oldValue && 'MIN_PRICE'===$scope.model.priceType && supplyFields) {
                        futureCallSupply = true;
                    } else if('MAX_PRICE'===oldValue) {
                        if('NO_PRICE'===$scope.model.priceType && priceInited) {
                            futureCallPrices = true;
                        } else if('MIN_PRICE'===$scope.model.priceType && supplyFields) {
                            futureCallSupply = true;
                        }
                    } else if('MIN_PRICE'===oldValue) {
                        if('NO_PRICE'===$scope.model.priceType) {
                            if (priceInited && !depositFields)
                                futureCallPrices = true;
                            else if (depositFields) {
                                futureCallPrices = true;
                                futureCallDeposit = true;
                            }
                        } else if('MAX_PRICE'===$scope.model.priceType && depositFields)
                            futureCallDeposit = true;
                    }


                    if(['MIN_PRICE'].includes($scope.model.priceType)) {
                        if (!supplyFields && $scope.model.lots.lot && $scope.model.lots.lot.length > 0) {
                            $scope.model.lots.lot.forEach(l=>{
                                 if (l.lotData)
                                      l.lotData.applicationDepositNeeded = 'notRequired';
                            });
                        }
                    } else if($scope.model.lots.lot && $scope.model.lots.lot.length > 0) {
                        $scope.model.lots.lot.forEach(l=>{
                             if (l.lotData && l.lotData.applicationDepositNeeded==='notRequired')
                                  delete l.lotData.applicationDepositNeeded;
                        });
                    }

                    if(futureCallPrices && futureCallDeposit) {
                    	needConfirmSrv.callVariants(deleteDepositAndLotPricesFields, function() {
                            $scope.model.priceType = oldValue;
                        }, [], 'Все ранее заполненные поля с ценами и по задаткам будут очищены. Продолжить?');
                    }else if(futureCallPrices) {
                    	needConfirmSrv.callVariants(deleteLotPricesFn, function() {
                            $scope.model.priceType = oldValue;
                        }, [], 'Все ранее заполненные поля с ценами будут очищены. Продолжить?');
                    }else if(futureCallDeposit) {
                    	needConfirmSrv.callVariants(deleteDepositFields, function() {
                            $scope.model.priceType = oldValue;
                        }, [], 'Все ранее заполненные поля по задаткам будут очищены. Продолжить?');
                    }else if(futureCallSupply) {
                    	needConfirmSrv.callVariants(deleteSupplyFields, function() {
                            $scope.model.priceType = oldValue;
                        }, [], 'Все ранее заполненные поля по обеспечению будут очищены. Продолжить?');
                    }
                    
                    $scope.notice.document.priceType = $scope.model.priceType;
                }
                function checkDepositFields(){
                 let result=false;
               	 if ($scope.model.lots.lot && $scope.model.lots.lot.length > 0){
                     for (let i = 0; i < $scope.model.lots.lot.length; i++) {
                         if ($scope.model.lots.lot[i].lotData){
                        	 if($scope.model.lots.lot[i].lotData.depositPaymentDetails ||
                        	    $scope.model.lots.lot[i].lotData.applicationDepositSumm ||
                        	    // $scope.model.lots.lot[i].lotData.applicationSupplySumm ||
                   		        $scope.model.lots.lot[i].lotData.applicationDepositExtra ||
                                 ['operator', 'customer'].includes($scope.model.lots.lot[i].lotData.applicationDepositNeeded)){
                        		 result = true;
                        	 }
                         }
                     }
                 } 
               	 return result;
                }
                function deleteDepositAndLotPricesFields(){
                	deleteLotPricesFn();
                	deleteDepositFields();
                }

                function deleteDepositFields(operatorOnly){
                    if ($scope.model.lots.lot && $scope.model.lots.lot.length > 0){
                        for (let i = 0; i < $scope.model.lots.lot.length; i++) {
                            if ($scope.model.lots.lot[i].lotData && (!operatorOnly || 'operator' == $scope.model.lots.lot[i].lotData.applicationDepositNeeded)){
                                delete $scope.model.lots.lot[i].lotData.depositPaymentDetails;
                                delete $scope.model.lots.lot[i].lotData.applicationDepositSumm;
                                delete $scope.model.lots.lot[i].lotData.applicationDepositExtra;
                                if(!$scope.model.lots.lot[i].lotData.applicationSupplyNeeded)
                                    delete $scope.model.lots.lot[i].lotData.applicationDepositNeeded;
                            }
                        }
                    }
                }

                function deleteSupplyFields(operatorOnly){
                    if ($scope.model.lots.lot && $scope.model.lots.lot.length > 0){
                        for (let i = 0; i < $scope.model.lots.lot.length; i++) {
                            if ($scope.model.lots.lot[i].lotData && (!operatorOnly || 'operatorSupply' == $scope.model.lots.lot[i].lotData.applicationDepositNeeded)){
                                delete $scope.model.lots.lot[i].lotData.depositPaymentDetails;
                                delete $scope.model.lots.lot[i].lotData.applicationSupplySumm;
                                delete $scope.model.lots.lot[i].lotData.applicationSupplyCurrency;
                                delete $scope.model.lots.lot[i].lotData.applicationSupplyExtra;
                                delete $scope.model.lots.lot[i].lotData.applicationSupplyNeeded;
                                delete $scope.model.lots.lot[i].lotData.completingSupplySumm;
                                delete $scope.model.lots.lot[i].lotData.completingSupplyCurrency;
                                delete $scope.model.lots.lot[i].lotData.completingSupplyExtra;
                                delete $scope.model.lots.lot[i].lotData.completingSupplyNeeded;
                                if($scope.model.priceType === 'MIN_PRICE')
                                    $scope.model.lots.lot[i].lotData.applicationDepositNeeded = 'notRequired';
                                else
                                    delete $scope.model.lots.lot[i].lotData.applicationDepositNeeded;
                            }
                        }
                    }
                }

                function checkSupplyFields(){
                    return $scope.model.lots?.lot.some(lot => {
                        return ['depositPaymentDetails','applicationSupplySumm', 'applicationSupplyCurrency',
                            'applicationSupplyExtra', 'applicationSupplyNeeded','completingSupplySumm', 'completingSupplyCurrency',
                            'completingSupplyExtra', 'completingSupplyNeeded'].some(prop => lot?.lotData[prop])
                            || ['operatorSupply', 'customerSupply'].includes(lot.lotData?.applicationDepositNeeded);
                    });
                }
                function checkLotSupplyFields(lot){
                    if(!lot.applicationSupplyNeeded)
                        return true;
                    const customerSupply = lot.applicationSupplySumm && lot.applicationSupplyCurrency && lot.applicationSupplyExtra!==null && lot.applicationSupplyExtra ||
                        lot.completingSupplySumm && lot.completingSupplyCurrency && lot.completingSupplyExtra!==null && lot.completingSupplyExtra;
                    if(!customerSupply)
                        return customerSupply;
                    if('operatorSupply'===lot.applicationDepositNeeded)
                        return lot.depositPaymentDetails.INN && lot.depositPaymentDetails.KPP && lot.depositPaymentDetails.name && lot.depositPaymentDetails.account &&
                            lot.depositPaymentDetails.bankBIC;
                    return true;
                }

                function lotPricesInited(){
                    return $scope.model.lots.lot && $scope.model.lots.lot.length > 0 &&
                        $scope.model.lots.lot.find(lot=>lot.contractSumNoNDS || lot.contractSum || lot.wholeLotPriceNoNDS || lot.wholeLotPriceWithNDS);
                }
                function deleteLotPricesFn(){
                    if ($scope.model.lots.lot && $scope.model.lots.lot.length > 0) {
                        for (var i = 0; i < $scope.model.lots.lot.length; i++) {
                            delete $scope.model.lots.lot[i].contractSumNoNDS;
                            delete $scope.model.lots.lot[i].contractSum;
                            delete $scope.model.lots.lot[i].wholeLotPriceNoNDS;
                            delete $scope.model.lots.lot[i].wholeLotPriceWithNDS;
                            delete $scope.model.lots.lot[i].lotData.priceForItem;
                            delete $scope.model.lots.lot[i].lotData.startPercent;
                            for (var j = 0; j < $scope.model.lots.lot[i].lotItems.lotItem.length; j++) {
                                delete $scope.model.lots.lot[i].lotItems.lotItem[j].commodityItemPriceRubWithNDS;
                                delete $scope.model.lots.lot[i].lotItems.lotItem[j].commodityItemPriceRubNoNDS;
                                delete $scope.model.lots.lot[i].lotItems.lotItem[j].commodityItemPriceNoNDS;
                                delete $scope.model.lots.lot[i].lotItems.lotItem[j].commodityItemPriceWithNDS;
                            }
                        }
                        delete $scope.model.price;
                        delete $scope.model.priceNoNDS;
                    }
                }
                function jointPurchaseChangeFn(){
                    if($scope.model.jointPurchase){
                        if($scope.model.lots?.lot.some(lot =>  lot.lotData?.applicationDepositNeeded === 'operatorSupply')){
                            needConfirmSrv.callVariants(deleteSupplyFields, function(){
                                $scope.model.jointPurchase = false;
                            }, [true], 'Проведение совместных процедур с внесением обеспечения на лицевой счет участника на счете Оператора КОМИТА ЭТП не предусмотрено. Ранее заполненные поля по обеспечению будут очищены. Продолжить?');
                        } else if($scope.model.lots?.lot.some(lot =>  lot.lotData?.applicationDepositNeeded === 'operator')){
                            needConfirmSrv.callVariants(deleteDepositFields, function(){
                                $scope.model.jointPurchase = false;
                            }, [true], 'Проведение совместных процедур с внесением задатка на лицевой счет участника на счете Оператора КОМИТА ЭТП не предусмотрено. Ранее заполненные поля по задатку будут очищены. Продолжить?');
                        }
                    } else if(hasLotsAndCustomers()){
                        needConfirmSrv.callVariants(deleteLotCustomersFn, function (){$scope.model.jointPurchase=true;},[], 'Указанные в лотах заказчики будут удалены. Продолжить?');
                    }

                }
                function hasLotsAndCustomers(){
                    var result=false;
                    if ($scope.model.lots.lot && $scope.model.lots.lot.length > 0)
                        angular.forEach($scope.model.lots.lot, function (lot) {
                            if (lot.customer)
                                result = true;
                        });
                    return result;
                }
                function deleteLotCustomersFn(){
                    if ($scope.model.lots.lot && $scope.model.lots.lot.length > 0) {
                        angular.forEach($scope.model.lots.lot, function (lot) {
                            delete lot.jointLotData;
                            delete lot.customer;
                        });
                    }
                }
                function isSimpleContestFn() {
                    return $scope.model && $scope.model.typeCode ==='simplecontest';
                }

                function isRequestPricesFn() {
                    return $scope.model?.typeCode ==='requestprices';
                }

                $scope.hasProtocolDates = () => !['openpublicoffer','openauction','limitedauction','saleauction','unlimitedprequalification','requestprices'].includes($scope.model?.typeCode);

                $scope.isAuction = () => ['openauction', 'limitedauction', 'saleauction','openpublicoffer'].includes($scope.model?.typeCode);

                function isShowNonCompetitiveFn() {
                    return $scope.model && $scope.isNew223() &&
                        ['opencontest', 'openauction', 'requestquotations', 'requestproposals', 'open2stagecontest','limitedcontest','limitedauction','limitedrequestquotations','limitedrequestproposals','prequalification','unlimitedprequalification'].includes($scope.model.typeCode);
                }

                $scope.isSimpleContestConfines = function () {
                    return $scope.model && $scope.model.typeCode ==='simplecontest' && (noticeSrv.isNew44() || noticeSrv.isNewCommercial());
                };

                $scope.isSimpleContest44 = function () {
                    return $scope.model && $scope.model.typeCode ==='simplecontest' && noticeSrv.isNew44();
                };
                
                function checkInitContractSumLotsFn(data) {
                	var message = "";
                	var result = true;
                    var lotNumbers = [];
                	angular.forEach($scope.model.lots.lot, function (lot) {
                        if(data.initContractSumLots){
                        	var initContractSumLots = data.initContractSumLots[lot.ordinalNumber];
                        	if(initContractSumLots && Number(lot.contractSum) !== initContractSumLots){
                        		lotNumbers.push(lot.ordinalNumber);
                        		result = false;
                        	}
                        }
                        
                        if(data.initContractSumNoNDSLots){
                        	var initContractSumNoNDSLots = data.initContractSumNoNDSLots[lot.ordinalNumber];
                        	if(initContractSumNoNDSLots  && Number(lot.contractSumNoNDS) !== initContractSumNoNDSLots && !lotNumbers.includes(lot.ordinalNumber)){
                        		lotNumbers.push(lot.ordinalNumber);
                        		result = false;
                        	}
                        }
                        
                    });
               
                	if($scope.model.lots.lot.length > 1){
                		if(lotNumbers.length === 1){
                			message = " по лоту № " + lotNumbers[0];
                		}else if(lotNumbers.length > 1){
                			message = " по лотам №№ ";
                			angular.forEach(lotNumbers, function (item) {
                				message += item + ', ';
                            });
                			message = message.slice(0, -2);
                		}
                	}
                    return {
                    	result: result,
                    	message: message 
                    };
                }
                
                function checkInitDepositNeededLotsFn(data) {
                	let message = "";
                	let result = true;
                    let lotNumbers = [];
                	angular.forEach($scope.model.lots.lot, function (lot) {
                        if(data.initDepositNeededLots){
                        	let initDepositNeededLots = data.initDepositNeededLots[lot.ordinalNumber];
                        	if(initDepositNeededLots){
                                if(initDepositNeededLots == 'operator' && lot.lotData.applicationDepositNeeded != 'operator' ||
                                    lot.lotData.applicationDepositNeeded == 'operator' && initDepositNeededLots != 'operator'){
                                    lotNumbers.push(lot.ordinalNumber);
                                    result = false;
                                } else if(initDepositNeededLots == 'operatorSupply' && lot.lotData.applicationDepositNeeded != 'operatorSupply' ||
                                    lot.lotData.applicationDepositNeeded == 'operatorSupply' && initDepositNeededLots != 'operatorSupply'){
                                    lotNumbers.push(lot.ordinalNumber);
                                    result = false;
                                }
                            }
                        }
                    });
               
                	if($scope.model.lots.lot.length > 1){
                		if(lotNumbers.length === 1){
                			message = " по лоту № " + lotNumbers[0];
                		}else if(lotNumbers.length > 1){
                			message = " по лотам №№ ";
                			angular.forEach(lotNumbers, function (item) {
                				message += item + ', ';
                            });
                			message = message.slice(0, -2);
                		}
                	}
                    return {
                    	result: result,
                    	message: message 
                    };
                }
                
                function checkInitDepositSumLotsFn(data) {
                	let message = "";
                    let result = true;
                    let lotNumbers = [];
                	angular.forEach($scope.model.lots.lot, function (lot) {
                        if(data.initDepositSumLots){
                        	const initDepositNeededLots = data.initDepositNeededLots[lot.ordinalNumber];
                            const initDepositSumLots = data.initDepositSumLots[lot.ordinalNumber];
                        	if(initDepositNeededLots == 'operator' && lot.lotData.applicationDepositSumm !== initDepositSumLots){
                        		  lotNumbers.push(lot.ordinalNumber);
                        		  result = false;
                        	}
                        	else if(initDepositNeededLots == 'operatorSupply' && lot.lotData.applicationSupplySumm !== initDepositSumLots){
                        		  lotNumbers.push(lot.ordinalNumber);
                        		  result = false;
                        	}
                        }
                    });
               
                	if($scope.model.lots.lot.length > 1){
                		if(lotNumbers.length === 1){
                			message = " по лоту № " + lotNumbers[0];
                		}else if(lotNumbers.length > 1){
                			message = " по лотам №№ ";
                			angular.forEach(lotNumbers, function (item) {
                				message += item + ', ';
                            });
                			message = message.slice(0, -2);
                		}
                	}
                    return {
                    	result: result,
                    	message: message 
                    };
                }
                
                function checkInitDepositPriceTypeFn(data) {
                	var result = true;
                   
                    if(data.initDepositPriceType){
                    	var initDepositPriceType = data.initDepositPriceType;
                    	if($scope.model.priceType == 'MIN_PRICE' && initDepositPriceType !== 'MIN_PRICE' ||
                    	   initDepositPriceType == 'MIN_PRICE' && $scope.model.priceType !== 'MIN_PRICE'){
                     		  result = false;
                    	}
                    }
               
                    return {
                    	result: result 
                    };
                }

                $scope.isCheckDepositSumm = function () {
                	if(!$scope.checkDepositSaveAction){
                		return false;
                	}
                	let message = "";
                	let result = true;
                    let lotNumbers = [];
                	if(noticeSrv.isNewCommercial() && $scope.model.priceType=='MIN_PRICE' || noticeSrv.showApplicationSupply()){
                        if ($scope.model.lots.lot && $scope.model.lots.lot.length > 0) {
                            for (let i = 0; i < $scope.model.lots.lot.length; i++) {
                                const lots = $scope.model.lots.lot[i];
                                const summ= noticeSrv.showApplicationSupply() ? lots?.lotData?.applicationSupplySumm :
                                    lots?.lotData?.applicationDepositSumm;
                                if (lots?.lotData?.applicationDepositNeeded != 'notRequired' && summ) {
                                    if(($scope.isPercentPriceMode() || lots.lotData.priceForItem) && lots.wholeLotPriceNoNDS &&
                                        summ > lots.wholeLotPriceNoNDS ||
                                            !($scope.isPercentPriceMode() || lots.lotData.priceForItem) && lots.contractSumNoNDS &&
                                        summ > lots.contractSumNoNDS){
                                         lotNumbers.push(lots.ordinalNumber);
                                         result = false;
                                    }
                                }
                            }

                            if($scope.model.lots.lot.length > 1){
                                if(lotNumbers.length === 1){
                                    message = " по лоту № " + lotNumbers[0];
                                }else if(lotNumbers.length > 1){
                                    message = " по лотам №№ " + lotNumbers.join( ", " );
                                }
                            }
                        }
                    }
                	if(!result){
                	    const fieldName = noticeSrv.showApplicationSupply()?'Размер обеспечения заявки':'Задаток';
                        return `Проверьте заполнение поля "${fieldName}"${message}. ${fieldName} превышает начальную цену договора по лоту без НДС.`;
                	}else{
                		return false;
                	}
                }

                $scope.isSummingupDateTimeRequired = function() {
                    return noticeSrv.isNew223() && !$scope.isSimpleContest() && $scope.isNoticeIntegrationEIS() && $scope.model.customer && $scope.model && $scope.model.placingProcedure &&
                        ($scope.model.placingProcedure.examinationDateTime || $scope.model.placingProcedure.examinationPlace || $scope.model.placingProcedure.summingupPlace);
                }
                // Не отображаем для:
                // формы просмотра, если нет полей purchaseMethodCode, purchaseCodeName.
                // формы редактирования, если нет полей purchaseMethodCode, purchaseCodeName и нет ошибок под заказчиком - значит редактируется старое извещение.
                $scope.isExtFieldNotice = function(){
                    if($scope.isReadonlyDefined())
                        return !!noticeSrv.isExtFieldNotice();
                    else
                        return !(noticeSrv.isExtFieldNotice() && !$scope.isNoticeShowCustomerEISError() && noticeSrv.getSelectedCustomer());
                }
                $scope.isReadonlyDefined = function() {
                    return !!$scope.isReadOnly;
                }
                $scope.isEisPublished = function(){
                    return noticeSrv.isNew223() && !$scope.isSimpleContest() && $scope.isNoticeIntegrationEIS() && $scope.notice.eisNumber;
                }
                $scope.isPercentPriceMode = function(){
                    return $scope.model.priceMode==='percent';
                }
                $scope.showCustomerBranches = function()  {
                    return $scope.isNew223() && !$scope.isSimpleContest() && $scope.model.customer && $scope.model.whoPublish==='customers' && $scope.model.customer.branches && $scope.model.customer.branches.length>0;
                }
                $scope.customerBranchChange = function(selected) {
                    if(!selected)
                        delete $scope.model.customerBranch;
                }
                $scope.isSaleAucOrOpenPurlicOffer = function(){
                    return ['saleauction', 'openpublicoffer'].includes($scope.model.typeCode);
                }
                $scope.isCommercialWitInitSum = function(){
                    return $scope.isNewCommercial() && $scope.model.priceType === 'MIN_PRICE' &&
                        ['opencontest', 'limitedcontest', 'requestquotations', 'limitedrequestquotations', 'requestproposals', 'limitedrequestproposals', 'open2stagecontest', 'limitedcontestqualification'].includes($scope.model.typeCode);
                }
                $scope.hasStages = function() {
                    return ['requestproposals','limitedrequestproposals','open2stagecontest'].includes($scope.model.typeCode) && !$scope.model.simplifiedProcedure;
                }
                $scope.isTypedAuctionRequired = (field) => {
                    return noticeSrv.isNew223() && ['openauction', 'limitedauction'].includes($scope.model?.typeCode) && $scope.isNoticeIntegrationEIS() &&
                        $rootScope.properties && $scope.notice?.document?.purchaseMethodCode == $rootScope.properties['eis_exchange_rest.purchaseNoticeAEMethodCode'] && (!$scope.isReadonlyDefined() || field?.length>0);
                }
                $scope.isPrequalification = () => {
                    return ['prequalification', 'unlimitedprequalification'].includes($scope.model?.typeCode);
                }
                $scope.isShowPrices = () => {
                    return $scope.hasWinnerTariff() && $scope.model.priceType!=='NO_PRICE' || (noticeSrv.isNew223() || noticeSrv.isNewCommercial()) &&
                                            ['openauction', 'limitedauction','saleauction','openpublicoffer'].includes($scope.model?.typeCode);
                }
                $scope.hasWinnerTariff = () => {
                    return (noticeSrv.isNew223() || noticeSrv.isNewCommercial()) &&
                        !['prequalification', 'unlimitedprequalification','simplecontest','offerplacement','limitedcontestqualification','requestprices'].includes($scope.model?.typeCode);
                }
                $scope.isWinnerTariffAllowed = () => {

                    const allowedWinnerTariff = ($scope.model.customer && $scope.model.customer.customerType==='division' && $scope.model.customer.parent ?
                                                $scope.model.customer?.parent?.allowedWinnerTariff : $scope.model.customer?.allowedWinnerTariff);
                    const freeTariff = ($scope.model.customer && $scope.model.customer.customerType==='division' && $scope.model.customer.parent ?
                                                $scope.model.customer?.parent?.freeTariff : $scope.model.customer?.freeTariff);

                    return $scope.model.customer && allowedWinnerTariff && !freeTariff;

                }
                $scope.isRequestPriceNumber = () => noticeSrv.isNewCommercial() && 'requestprices'=== $scope.model?.typeCode && !$scope.model?.number && !$scope.model?.version;
            }]
    });
