angular.module('uetp').service('onlineCheckSrv', ['$q', 'httpSrv',
    function ($q, httpSrv) {
        'use strict';
        var timeout = false;
        return function (url, errorObject, value) {
            var deferred = $q.defer(),
                errorObj = errorObject;

            setTimeout(function () {
                timeout = false;
            }, 100);
            if (!timeout) {
                timeout = true;
                httpSrv.http({
                    method: 'POST',
                    url: url,
                    data: value
                })
                    .then(function successCallback(response) {

                        errorObj.title = response.data.message;
                        errorObj.value = !response.data.success;

                        deferred.resolve(errorObject);
                    }, function errorCallback(response) {
                        console.log(response);
                    });
            } else {
                deferred.reject();
            }
            return deferred.promise;
        };
//-----------------------
    }]);
