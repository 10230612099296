angular.module('uetp').constant('averageSaving', {
	title    : 'Средняя экономия на аукционах, %',
	access   : ['organizer'],
	url      : '/auth/org/downloadAverageSavingReport',
	template : 'app/components/reports/types/averageSavingReport.html',
	data     : {
		endDate   : null,
		startDate : null,
		procTypes:[
			{id:'openauction', message:'Открытый аукцион',selected:true},
			{id:'saleauction', message:'Доходный аукцион',selected:true},
			{id:'limitedauction', message:'Аукцион с ограниченным участием',selected:true}
		],
		customers:[],
		notHeld:false
	},
	settings:{
		divisionSelectSettings:{
			settings:{
				checkBoxes: true,
				idProperty:"id",
				displayProp:'name',
				scrollable:true
			},
			texts:{
				checkAll:"Выделить все",
				uncheckAll:"Снять выделение",
				dynamicButtonTextSuffix: "выделено",
				buttonDefaultText:"Выберите заказчиков"
			}
		},
		paramTitles:{
			period:"Период объявления процедуры"
		}
	},

	toMSK    : ['startDate', 'endDate'],
	fileName : function () {
		return "Средняя экономия на аукционах.xlsx";
	}
});