/**
 * Сервис по работе с заявкой на участие
 */
angular.module('uetp').service('participateSrv', ['$state', 'httpSrv', 'cryptography','dictionarySrv',
    function ($state, httpSrv, cryptography,dictionarySrv) {
        var service = this,
            participateData = {};

        /**
         * Получить Лот по его ID
         */
        this.getPreCreateClaim = function (procType, procId, lotId) {
            var url;
            var fz = dictionarySrv.getFzbyProcType(procType);
            if(fz==='44'){
                /**
                 * 44 ФЗ
                 */
            	url = '/' + procType + '/' + procId + '/lots/' + lotId + '/claims/preCreate'
            }else  if(fz==='223'){
                /**
                 * 223 ФЗ
                 */
                url = '/' + procType + '/' + procId + '/lots/' + lotId + '/claims/preCreate'
            }
            return httpSrv.http({
                method: 'GET',
                url:url
            });
        };

        /**
         * Сохранение заявки
         */
        this.createClaim = function (stateParams, param) {
            var url,method,fz = dictionarySrv.getFzbyProcType(stateParams.procType);
            if(fz==='44'){
                method =  param.id ? 'PUT' : 'POST';
                url= '/' + stateParams.procType + '/' + stateParams.procId + '/lots/' + stateParams.lotId + '/claims' + (param.id ? '/' + param.id : '')
            }else if(fz==='223'){
                method =  param.id ? 'PUT' : 'POST';
                url= '/' + stateParams.procType + '/' + stateParams.procId + '/lots/' + stateParams.lotId + '/claims' + (param.id ? '/' + param.id : '')
            }
            return httpSrv.http({
                method: method,
                url: url,
                data: param
            });
        };
        /**
         * получение данных для подписи нового заявления
         */
        this.getSignDataNewClaim = function (stateParams, param) {
            return httpSrv.http({
                method: 'POST',
                url: '/' + stateParams.procType + '/' + stateParams.procId + '/lots/' + stateParams.lotId + '/getDataForSignClaim',
                data: param
            });
        };
        /**
         * получение данных для подписи созданного документа
         */
        this.getSignDataUpdateClaim = function (stateParams, param) {
            var url,fz = dictionarySrv.getFzbyProcType(stateParams.procType);
            if(fz==='44'){
                /**
                 * 44 ФЗ
                 */
                url = '/' + stateParams.procType + '/' + stateParams.procId + '/lots/' + stateParams.lotId + '/claims/' + param.id + '/getDataForSign'
            }else  if(fz==='223'){
                /**
                 * 223 ФЗ
                 */
                url = '/' + stateParams.procType + '/' + stateParams.procId + '/lots/' + stateParams.lotId + '/claims/' + param.id + '/getDataForSign'
            }
            return httpSrv.http({
                method: 'POST',
                url: url
            });
        };
        /**
         * отправка подписанных данных для созданного документа
         */
        this.sendSignDataUpdateClaim = function (stateParams, param) {

            var url,fz = dictionarySrv.getFzbyProcType(stateParams.procType);
            //
            if(fz==='44'){
                /**
                 * 44 ФЗ
                 */
                url = '/' + stateParams.procType + '/' + stateParams.procId + '/lots/' + stateParams.lotId + '/claims/' + param.id + '/sendClaim'
            }else  if(fz==='223'){
                /**
                 * 223 ФЗ
                 */
                url = '/' + stateParams.procType + '/' + stateParams.procId + '/lots/' + stateParams.lotId + '/claims/' + param.id + '/sendClaim'
            }
            return httpSrv.http({
                method: 'POST',
                url: url,
                data: param
            });
        };
        /**
         * отправка подписанных данных для подписи нового заявления
         */
        this.sendSignDataNewClaim = function (stateParams, param) {
            return httpSrv.http({
                method: 'POST',
                url: '/' + stateParams.procType + '/' + stateParams.procId + '/lots/' + stateParams.lotId + '/createAndSendClaim',
                data: param
            });
        };
        /**
         * Получение заявки
         */
        this.getClaim = function (stateParams) {
            var url,fz = dictionarySrv.getFzbyProcType(stateParams.procType);
            //
            if(fz==='44'){
                /**
                 * 44 ФЗ
                 */
                url = '/' + stateParams.procType + '/' + stateParams.procId + '/lots/' + stateParams.lotId + '/claims/' + stateParams.claimId
            }else  if(fz==='223'){
                /**
                 * 223 ФЗ
                 */
                url = '/' + stateParams.procType + '/' + stateParams.procId + '/lots/' + stateParams.lotId + '/claims/' + stateParams.claimId
            }
            return httpSrv.http({
                method: 'GET',
                url: url
            });
        };
        /**
         * Просмотр заявки
         */
        this.getClaimView = function (stateParams) {
            return httpSrv.http({
                method: 'GET',
                url: `/${stateParams.procType}/${stateParams.procId}/claimView/${stateParams.claimId}`
            });
        };
        /**
         * Подпись нового документа
         */
        this.sendSignNew = function (stateParams, data, certId) {
            return service.getSignDataNewClaim(stateParams, data).then(function (response) {
                if (response.data.success) {
                    return cryptography.generateSignature(response.data.result[0], certId).then(function (value) {
                        data.part1.signature = value;
                        return cryptography.generateSignature(response.data.result[1], certId).then(function (value) {
                            data.part2.signature = value;
                            if (!response.data.result[2]) {
                                return service.sendSignDataNewClaim(stateParams, data)
                            }
                            return cryptography.generateSignature(response.data.result[2], certId).then(function (value) {
                                data.part3.signature = value;
                                return service.sendSignDataNewClaim(stateParams, data)
                            }, function (reason) {
                                if (typeof reason === 'string') {
                                    return Promise.reject("Ошибка подписи документа. " + reason);
                                } else {
                                    return Promise.reject("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                                }
                            })

                        }, function (reason) {
                            if (typeof reason === 'string') {
                                return Promise.reject("Ошибка подписи документа. " + reason);
                            } else {
                                return Promise.reject("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                            }
                        })
                    }, function (reason) {
                        if (typeof reason === 'string') {
                            return Promise.reject("Ошибка подписи документа. " + reason);
                        } else {
                            return Promise.reject("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                        }
                    })
                } else {
                    return Promise.resolve(response);
                }
            })
        };
        /**
         * Подпись уже созданного документа
         */
        this.sendSignUpdate = function (stateParams, data, certId) {
            return service.getSignDataUpdateClaim(stateParams, data).then(function (response) {
                if (response.data.success) {
                   if(!response.data.result[0])	{
                	   return service.sendSignUpdate23Parts(stateParams, response, data, certId);
                   } else {
                	   return cryptography.generateSignature(response.data.result[0], certId).then(function (value) {
                    	data.part1.signature = value;
                    	return service.sendSignUpdate23Parts(stateParams, response, data, certId);
                    }, function (reason) {
                        if (typeof reason === 'string') {
                            return Promise.reject("Ошибка подписи документа. " + reason);
                        } else {
                            return Promise.reject("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                        }
                    })
                  }//
                } else {
                    return Promise.resolve(response);
                }
            })
        };
        this.sendSignUpdate23Parts = function (stateParams, response, data, certId) {
	 	    return cryptography.generateSignature(response.data.result[1], certId).then(function (value) {
	           data.part2.signature = value;
	           if (!response.data.result[2]) {
	               return service.sendSignDataUpdateClaim(stateParams, data)
	           }
	           return cryptography.generateSignature(response.data.result[2], certId).then(function (value) {
	               data.part3.signature = value;
	               return service.sendSignDataUpdateClaim(stateParams, data)
	           }, function (reason) {
	               if (typeof reason === 'string') {
	                   return Promise.reject("Ошибка подписи документа. " + reason);
	               } else {
	                   return Promise.reject("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
	               }
	           })
	       }, function (reason) {
	           if (typeof reason === 'string') {
	               return Promise.reject("Ошибка подписи документа. " + reason);
	           } else {
	               return Promise.reject("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
	           }
	       })
        };
        /**
         * Подпись документа из таблицы
         */
        this.sendSignFromTable = function (stateParams, certId) {
            return service.getClaim(stateParams).then(function (response) {
                if (response.data.success) {
                    return service.sendSignUpdate(stateParams, response.data.result, certId)
                } else {
                    return response;
                }
            })
        };
        /**
         * Получение данные для подписи при отзыве заявки
         */
        this.getDataForRevoke = function (stateParams) {
            var url,fz = dictionarySrv.getFzbyProcType(stateParams.procType);
            if(fz==='44'){
                /**
                 * 44 ФЗ
                 */
            	 url =  '/' + stateParams.procType + '/claims/' + stateParams.claimId + '/getDataForRevoke'
            }else  if(fz==='223'){
                /**
                 * 223 ФЗ
                 */
                url =  '/' + stateParams.procType + '/claims/' + stateParams.claimId + '/getDataForRevoke'
            }
            return httpSrv.http({
                method: 'GET',
                url: url
            });
        };
        /**
         * Отправка отзыва заявки
         */
        this.sendRevoke = function (stateParams, certId) {
            return service.getDataForRevoke(stateParams).then(function (response) {
                if (response.data.success) {
                    var data = response.data.result;
                    return cryptography.generateSignature(data.hash, certId).then(function (value) {
                        data.signature = value;
                        data.sent = new Date().getTime();
                        var url,fz = dictionarySrv.getFzbyProcType(stateParams.procType);
                        if(fz==='44'){
                            /**
                             * 44 ФЗ
                             */
                        	 url =  '/' + stateParams.procType + '/claims/' + stateParams.claimId + '/revoke'
                        }else  if(fz==='223'){
                            /**
                             * 223 ФЗ
                             */
                            url =  '/' + stateParams.procType + '/claims/' + stateParams.claimId + '/revoke'
                        }
                        return httpSrv.http({
                            method: 'POST',
                            url: url,
                            data: data
                        });
                    }, function (reason) {
                        if (typeof reason === 'string') {
                            return Promise.reject("Ошибка подписи документа. " + reason);
                        } else {
                            return Promise.reject("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                        }
                    })
                } else {
                    return Promise.resolve(response);
                }
            }, function (reason) {
                return Promise.reject(reason);
            });
        };

        /**
         * Получить Участника по его ИНН (только если в базе только один с таким)
         */
        this.getParticipantByINN = function (procType,inn) {
            return httpSrv.http({
                method: 'GET',
                url: '/' + procType+'/getParticipantInfoByINN/' + inn
            });
        };

        /**
         * Установить данные по документу в сервис
         * @param data
         */
        this.setParticipateData = function (data) {
            participateData = data;
        };
        /**
         * Получить данные по документу
         * @returns {{}}
         */
        this.getParticipateData = function () {
            return participateData;
        };
        /**
         * Удаление документа
         * @param stateParams
         * @returns {*}
         */
        this.deleteClaim = function (stateParams) {
            var url,fz = dictionarySrv.getFzbyProcType(stateParams.procType);
            if(fz==='44'){
                /**
                 * 44 ФЗ
                 */
                url =  '/' + stateParams.procType + '/' + stateParams.procId + '/lots/' + stateParams.lotId + '/claims/' + stateParams.claimId
            }else  if(fz==='223'){
                /**
                 * 223 ФЗ
                 */
                url =  '/' + stateParams.procType + '/' + stateParams.procId + '/lots/' + stateParams.lotId + '/claims/' + stateParams.claimId
            }
            return httpSrv.http({
                method: 'DELETE',
                url: url
            });
        };
        /**
         * Получение настроек формы представления
         * @param scope - область видимости контроллера
         * @param type - тип закупки
         */
        this.getTabsVisible = function (scope, type) {
            scope.ctrl.templateUrl =  'app/components/participate/participate.html';
            if (['openpublicoffer', 'saleauction', 'openauction','unlimitedprequalification','prequalification','limitedauction','auctionsmb', 'requestprices'].includes(type)) {
                scope.model.showPart1 = true;
                scope.model.showPart2 = true;
                scope.model.specVision = true;
            } else if (type === 'openauctionsmb') {
                scope.model.showPart1 = true;
                scope.model.showPart2 = true;
            } else if (['limitedcontest','opencontest','limitedrequestquotations','requestquotations','requestproposals',
                'limitedrequestproposals','open2stagecontest','limitedcontestqualification','simplecontest'].includes(type)) {
                scope.model.showPart1 = true;
                scope.model.showPart2 = true;
                scope.model.showPart3 = true;
                scope.model.specVision = true;
            } else if (['opencontestsmb','offerplacementsmb'].includes(type)) {
                scope.model.showPart1 = true;
                scope.model.showPart2 = true;
                scope.model.showPart3 = true;
            } else if (type === 'requestquotationssmb') {
                scope.model.showPart2 = true;
                scope.model.showPart3 = true;
                scope.model.showPanel = 1;
                scope.tabs[1].title = 'Первая часть заявки';
                scope.tabs[2].title = 'Ценовое предложение';
                scope.ctrl.templateUrl =  'app/components/participate/msp223/participate.html';
            } else if (type === 'requestproposalssmb') {
                scope.model.showPart1 = true;
                scope.model.showPart2 = true;
                scope.model.showPart3 = true;
                scope.model.showPanel = 1;
                scope.tabs[1].title = 'Первая часть заявки';
                scope.tabs[2].title = 'Вторая часть заявки';
                scope.tabs[3].title = 'Ценовое предложение';
                scope.ctrl.templateUrl =  'app/components/participate/msp223/participate.html';
            }else if (['offerplacement', 'EAP44'].includes(type) ) {
                scope.model.showPart1 = true;
                scope.model.showPart2 = true;
                scope.model.showPart3 = false;
                scope.model.specVision = true;
            }
            
            if (scope.model.specVision) {
                scope.tabs[0].title = 'Заявка';
                scope.tabs[1].title = 'Реквизиты';
                scope.tabs[2].title = 'Предложенная цена';
            }

        };
        this.providedBankGuaranteeCheck = function(stateParams){
            return httpSrv.http({
                method: 'GET',
                url: `/${stateParams.procType}/${stateParams.procId}/lots/${stateParams.lotId}/claims/providedBankGuaranteeCheck`
            });
        }
    }])
;
