/**
 * @author dmitrys
 */
angular.module('uetp').controller("carouselPurchaseController", ['$scope', 'httpSrv', '$state', 'constants', function ($scope, httpSrv, $state, constants) {


    /*
    * Ширина блока
    * */
    var blockSize = 580;
    /*
    * Отступ блока с права
    * */
    var blockPadd = 37;
    /*
    * Текущая позиция карусели
    * */
    var carouselPurchasePos;
    /*
    * Минимальная позиция
    * TODO: Высчитвать позицию (если размер 1100 она 0)
    * */
    var minCarouselPurchasePos = 280;

    $scope.purchaseList = [];
    $scope.openProcedure = openProcedureFn;

    function openProcedureFn(procedure) {
        $state.go('openProcedure', {procType: procedure.type.id, procId: procedure.id, clearConfig: true})
    }

    function getPurchasesForCarousel() {
        var params = {itemsPerPage: 10, page: 0};
        if(constants.configProject.type === 'ppk')
            params.searchByField={portalType:'ppk'};
        httpSrv.http({
            method: 'POST',
            url: '/site/procedures/all', // listFree
            data: params
        }).then(function successCallback(response) {
            if (response.data.success) {
                $scope.purchaseList = response.data.items;
                carouselPurchasePos = minCarouselPurchasePos;
                //если закупок больше двух - выставляем вторую по центу
                if ($scope.purchaseList.length > 2) $scope.moveBlock(-1);
            }
        }, function errorCallback(response) {
            console.log(response);
        });
    }

    this.$onInit = function () {
        getPurchasesForCarousel();
    };


    $scope.moveBlock = function (dir) {

        //Проверка границы слева - переходим в конец
        if (dir === 1 && carouselPurchasePos >= minCarouselPurchasePos){
            carouselPurchasePos = minCarouselPurchasePos - (blockSize + blockPadd) * ($scope.purchaseList.length - 1)
            return;
        }
        //Проверка границы справа - переходим в начало
        if (dir === -1 && carouselPurchasePos <= minCarouselPurchasePos - (blockSize + blockPadd) * ($scope.purchaseList.length - 1)) {
            carouselPurchasePos = minCarouselPurchasePos;
            return;
        }
        /*
        * Расчитываем позицию и двигаем блок
        * */
        carouselPurchasePos += (blockSize + blockPadd) * dir;
    };
    /*
    * Двигаем блок стилями
    * */
    $scope.style = function () {
        return {"margin-left": carouselPurchasePos + 'px'};
    };
    /*
    * Выставляем ширину и отсутп блока
    * */
    $scope.defBlockSize = function () {
        return {"width": blockSize + 'px', "margin-right" :blockPadd+'px'};
    }
}])
    .directive("carouselPurchase", [function () {
        return {
            restrict: 'E',
            templateUrl: 'app/directives/carouselPurchase/carouselPurchase.html',
            controller: 'carouselPurchaseController'
        }
    }]);