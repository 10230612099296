//компонент контакты
angular.module('uetp')
    .component('about', {
        templateUrl: 'app/components/about/about.html',
        transclude: true,
        controller: ['$scope', function ($scope) {
            $scope.changeCity = changeCityFn;
            $scope.city = "spb";

            function changeCityFn(newCity) {
                $scope.city = $scope.city == newCity ? "" : newCity;
            }
        }]
    });