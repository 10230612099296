/**
 * @author dmitrys
 * Блокировка экрана при загрузке файлов
 */
angular.module('uetp').factory('fileBlockScreenFactory', ['$q', function ( $q) {
    /* Кол-во запросов */
    var count = 0;
    /*
    * Добавляем запрос
    * возвращаем true если это перавый запрос
    * */
    function add() {
        count++;
        return count === 1;
    }
    /*
    * Запрос завершился
    * возвращаем true если это последний запрос
    * */
    function load() {
        count--;
        return count === 0;
    }
    /*
    * Отображаем блокирующий блок
    * *скрываем блок из библиотеки loading-bar.js
    * */
    function enableViewPortBlock(){
        //скрываем блок из библиотеки
        var blockContainerLib = document.getElementById("blockContainer");
        if (blockContainerLib) blockContainerLib.style.display = "none";

        var blockContainer = document.getElementById("blockContainer2");
        if (!blockContainer){
            blockContainer = document.createElement("div");
            blockContainer.id = "blockContainer2";
            blockContainer.style.display = "none";
            document.body.appendChild(blockContainer);
        }
        blockContainer.style.display = "block";
    }
    /*
    * Скрываем блокирующий блок
    * */
    function disableViewPortBlock(){
        var blockContainer = document.getElementById("blockContainer2");
        if (!blockContainer) return;
        blockContainer.style.display = "none";
    }
    return {
        request: function (config) {
            if (config.isFile)
                if (add()) enableViewPortBlock();
            return config;
        },
        response: function (response) {
            if (response.config.isFile)
                if (load()) disableViewPortBlock();
            return response;
        },
        responseError: function (rejection) {
            if (rejection.config.isFile)
                if (load()) disableViewPortBlock();
            return $q.reject(rejection);
        }
    };
}]);