/* Прикрепление файлов v 4.0 */
import { using, component, Controller, asyncDigest } from '../../utils/angular-es.mjs';
import attachTypeCatalogEnum from '../../enums/attachTypeCatalog.enum.js';
import template from './template.html';

// Interface
//----------------------------------------------------------------------------------------------------------------------
const require = {
	ngModel    : '^ngModel', //Массив файлов
	parentForm : '^form',
};
const bindings = {
	selectTitle    : ['@', 'Выберите файл'], //Надпись на кнопке добавления (значение по умолчанию - 'Выберите файл'),
	fileType       : '@', //Тип файла на сервере
	accept         : '@', //Допустые типы файлов
	minDescription : '<', //Минимальный размер описания
	maxDescription : '<', //Максимальный размер описания
	maxFileSize    : '<', //Максимальный допустимый размер файла
	maxFiles       : '<', //Максимальное количество файлов (Если вместо модели используется объект, указать "single")
	api            : ['@', '/rest/fs/api/v1/orders/upload'], //Ссылка на основное api
	//Если указать api = "" то файлы не будут сохраняться на сервер а хранится в модели в поле content
	apiStorage     : ['@', '/fs/api/v1/orders/download/offer/'], //Ссылка на api для чтения
	noDuplicates   : '<', //Запретить дублирование
	validFormats   : '<', //Допустимые форматы файлов
	validSizes     : '<', //Допустимые размеры [WxH, WxH, ...] (если изображение)
	attachInfo     : '&', //Дополнительное описание для каждого файла
	imgFlag        : '<', //Наличие параметра isImg в запросе
	header         : '@', //Заголовок
	headerInfo     : '@', //Информация в заголовке
	info           : '@', //Дополнительное описание
	plugInfo       : '@', //Описание при пустом списке
	editable       : ['<', true], //Доступно ли редактирование описания и порядка файлов
	active         : ['<', true], //Доступно ли добавление
	addCreatedDate : '<', //Добавить дату создания файла
};

// Realization
//----------------------------------------------------------------------------------------------------------------------
const IMAGE_FORMATS       = ['jpg', 'jpeg', 'png', 'gif'];
const IMAGE_FORMATS_EXTRA = ['png', 'jpg', 'jpeg', 'svg', 'gif'];
const ERROR_TIMEOUT       = 7000;
const MB                  = 1048576;
const KB                  = 1024;
const FRACTION_DIGITS     = 1;
const FILETYPES_INFO = {
	default : {fileType: 0},
	Comment : {fileType: 100},
	Banner  : {fileType: 0,   type: attachTypeCatalogEnum.mainbanner     },
	Logo    : {fileType: 300, type: attachTypeCatalogEnum.mainbanner_logo},
	Pic     : {fileType: 301, type: attachTypeCatalogEnum.mainbanner_pic },
	Special : {fileType: 302, type: attachTypeCatalogEnum.special_offer  },
	Price   : {fileType: 303, type: attachTypeCatalogEnum.pricelist      },
	Docs    : {fileType: 304, type: attachTypeCatalogEnum.docs           },
	Product : {fileType: 305, type: attachTypeCatalogEnum.product        },
};
let instanceCount = 0;

component(__dirname, {
	@using('$timeout', 'systemMessages', 'httpSrv', '$rootScope')

	controller: class extends Controller {
		//Class
		static #getSize (size) {
			return size>MB
				?`${(size/MB).toFixed(FRACTION_DIGITS)} Мб`
				:`${(size/KB).toFixed(FRACTION_DIGITS)} Кб`;
		}

		//Instance
		#objectModel = false;
		#errorTimer = null;

		get invalidDescription () {
			return this.minDescription && (this.targetFile.description||{length:0}).length < this.minDescription;
		}

		get description () {
			let size = this.constructor.#getSize(this.maxFileSize);
			return (
				(this.maxFiles !== undefined?(this.maxFiles>1?`Допустимое количество файлов - ${this.maxFiles}, каждый`:'Допустим файл'):'Допустимы файлы, каждый')+
				(' объемом<br> не более '+ size) +
				(this.validFormatsText?`, в формате: ${this.validFormatsText}` : '')+ '.'
			);
		}

		$onInit () {
			const scope = this;
			if (this.maxFiles === 'single') {
				this.maxFiles = 1;
				this.#objectModel = true;
			}
			if (this.#objectModel) this.ngModel.$render = () => {
				this.attachments = this.ngModel.$viewValue.id ? [this.ngModel.$viewValue] : [];
			};
			else this.ngModel.$render = () => {this.attachments = this.ngModel.$viewValue || [];};

			this.INSTANCE = instanceCount++;
			this.status = null;
			this.initTarget();
			this.deletesArray = [];
			if (this.validSizes) this.validSizesText = this.validSizes.join(', ');
			if (this.validFormats) {
				this.validFormatsText = this.validFormats.join(', ').toUpperCase();
				this.validFormats = this.validFormats.map(format=>format.toLowerCase());
				if (!this.accept) this.accept = '.'+this.validFormats.join(',.');
			}
			this.$rootScope.$on('rejectFileEdit', () => {
				scope.rejectEdit();
			});
		}

		initTarget () {
			this.targetFile = {
				name: '',
				description: '',
			};
		}

		showAttachBlock () {
			if (this.status === 'add') {
				this.status = null;
				delete this.targetFile.description;
			}
			else if(this.status === 'edit') {
				this.targetFile.description = this.originalDescription;
				this.closeEdit();
				this.status = 'add';
			} else
				this.status = 'add';
		}

		selectFile () {
			this.$timeout(
				() => {
					const selectors = `div[name=${this.class}_upload_hidden_${this.INSTANCE}] > input`;
					document.querySelector(selectors).click();
				},
				0,
			);
		}

		@asyncDigest
		async uploadFile (files, objectUrl) {
			//Проверка на дубли
			if (this.noDuplicates) {
				if (
					Array.isArray(this.attachments) &&
					this.attachments.map(file => file.name).includes(files[0].name)
				) {
					return this.handleError('duplicates', files);
				}
			}

			//Проверка на формат файла
			const ext = files[0].name.split('.').pop();
			if (this.validFormats && !this.validFormats.includes(ext.toLowerCase())) {
				return this.handleError('type', files);
			}

			//Проверка на размеры для изображений
			if (this.validSizes && IMAGE_FORMATS.includes(ext.toLowerCase())) {
				const validDimensions = await this.#checkDimensions(objectUrl);
				if (!validDimensions) {
					return this.handleError('dimensions', files);
				}
			}
		}

		isImg (attach) {
			return attach && attach.name && IMAGE_FORMATS_EXTRA.find(
				el=> el === attach.name.toLowerCase().substring(attach.name.lastIndexOf('.')+1),
			);
		}

		successFile (response) {
			if (!response.data.success || !response.data.result || !response.data.result[0]) {
				this.handleError(response.data.message || 'Файл не добавлен');
				return;
			}

			const newFile = response.data.result[0];
			const attach = {
				...(this.fileType && FILETYPES_INFO[this.fileType] || FILETYPES_INFO['default']),
			};

			if (response.isVirtual) {
				attach.id      = `local-${this.attachments.length}`;
				attach.name    = newFile[0].files[0].name;
				attach.content = newFile;
				if (this.addCreatedDate) attach.creationDate = newFile[0].files[0].lastModified;
			} else {
				attach.id   = newFile.id;
				attach.name = newFile.name;
				if (this.addCreatedDate) attach.createdDate = newFile.createdDate;
			}

			if (this.maxDescription !== 0) attach.description = this.targetFile.description;

			this.attachments.push(attach);

			//При редактировании удаляем старую версию
			if (this.status === 'edit') this.removeAttach(this.targetFile);

			this.initTarget();

			this.status = null;

			if (this.#objectModel) this.ngModel.$setViewValue(this.attachments[0] || {});
			else this.ngModel.$setViewValue(this.attachments);
		}

		editAttach (file) {
			this.targetFile = file;
			this.status = 'edit';
			this.originalDescription = file.description;
		}

		prepareRemoving (file) {
			this.targetFile = file;
			this.status = 'removing';
		}

		rejectEdit () {
			this.targetFile.description = this.originalDescription;
			this.closeEdit();
		}

		closeRemoving () {
			this.initTarget();
			this.status = null;
		}

		closeEdit () {
			if (this.minDescription && this.targetFile.description?.length < this.minDescription) return;
			this.initTarget();
			this.status = null;
			//ie 11 баг снятие фокуса. Иначе не нажать кнопки
			globalThis.focus();
			if (document.activeElement) document.activeElement.blur();
		}

		removeAttach (file) {
			let index = this.attachments.indexOf(file);
			if (index > -1) {
				this.attachments.splice(index, 1);

				if (this.parentForm) this.parentForm.$setDirty();

				if (!this.deletesArray.find(el=>el.id === file.id)) this.deletesArray.push(file.id);

				this.initTarget();
				this.status = null;
			}

			if (this.#objectModel) this.ngModel.$setViewValue(this.attachments[0] || {});
			else this.ngModel.$setViewValue(this.attachments);
		}

		moveAttach(target, direction) {
			const itemIdx = this.attachments.indexOf(target);
			if (itemIdx === -1 || itemIdx + direction < 0 || itemIdx + direction >= this.attachments.length) return;
			const target2 = this.attachments[itemIdx + direction];
			this.attachments[itemIdx + direction] = target;
			this.attachments[itemIdx] = target2;
			/*const tmpEl = angular.copy(dataArray[itemIdx + delta]);
			dataArray[itemIdx + delta] = angular.copy(item);
			dataArray[itemIdx] = angular.copy(tmpEl);*/

			if (this.#objectModel) this.ngModel.$setViewValue(this.attachments[0] || {});
			else this.ngModel.$setViewValue(this.attachments);
			if (this.parentForm) this.parentForm.$setDirty();
		}

		handleError(type, files) {
			console.log('files', files);
			const errorMessage = this.#getErrMsg(type, files[0].name);

			if (this.#errorTimer) this.$timeout.cancel(this.#errorTimer);
			this.errorMessage = errorMessage;
			this.#errorTimer = this.$timeout(()=>this.errorMessage = null, ERROR_TIMEOUT);
			return 'repeat';
		}

		async #checkDimensions (URL) {
			const image = new Image();
			image.src = URL;

			return await new Promise(r=>{
				const validSizes = this.validSizes;
				image.onload = function () {
					let result = false;
					angular.forEach(validSizes, validSize=>{
						let [w, h] = validSize.replace(/\s+/g, '').split('x');
						w = Number.parseInt(w, 10);
						h = Number.parseInt(h, 10);
						if (this.width === w && this.height === h) result = true;
					});
					r(result);
				};
			});
		}

		#getErrMsg (type, name) {
			switch (type) {
			case 'type'        : return this.systemMessages.generateMsg_INCORRECT_FORMAT(name);
			case 'size'        : return this.systemMessages.generateMsg_MAX_FILE_SIZE(name, this.constructor.#getSize(this.maxFileSize));
			case 'dimensions'  : return this.systemMessages.generateMsg_DIMENSIONS(name, this.validSizes.join(', '));
			case 'description' : return this.systemMessages.generateMsg_DESCRIPTION_MIN(this.minDescription);
			case 'duplicates'  : return this.systemMessages.FILE_EXISTS;
			case 'empty'       : return this.systemMessages.EMPTY_FILE;
			}
			return type;
		}
	},

	template, require, bindings,
});
