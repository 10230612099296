/**
 * @author: sergeyu
 */
angular.module('uetp')
    .component('elVerTass', {
        bindings: {
            verType: '<',
            verRating: '<',
            verData: '<'
        },
        templateUrl: 'app/components/widgets/tassBusiness/companyValidate/view/verification/elementVerification/elementVerification.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'tassBusinessSrv', 'elVerSrv',
            function ($scope, $rootScope, $state, $stateParams, tassBusinessSrv, elVerSrv) {
                var ctrl = this;

                $scope.getIcon = getIconFn;


                const TYPES_TITLE = {
                    'bankruptcies': 'Сведения о банкротствах',
                    'arbitrage': 'Арбитражные дела',
                    'addressLinks': 'Связь по указанному адресу',
                    'taxArrears': 'Задолженность по уплате налогов',
                    'massHeads': 'Массовые руководители',
                    'massFounders': 'Массовые учредители',
                    'taxReporting': 'Предоставление в ФНС налоговой отчетности',
                    'unfairSuppliers': 'Реестр недобросовестных поставщиков',
                    'executiveProduction': 'Исполнительные производства',
                    'massAddress': 'Массовые адреса',
                    'disqualifiedPersons': 'Дисквалифицированные лица',
                    'prosecution': 'Ведомственные проверки'
                };

                const TYPES_TITLE_INFORMATION = {
                    'bankruptcies': 'Сообщения о банкротстве являются сигналом вероятного (хотя и не обязательного) скорого прекращения деятельности.',
                    'arbitrage': 'Арбитражные дела, в которых компания выступает истцом или ответчиком. Негативным можно считать только участие в качестве ответчика, однако серьёзность иска рекомендуется анализировать.',
                    'addressLinks': 'Данные ФНС о наличии или отсутствии связи по указанному адресу. Отсутствие связи с компанией по юридическому адресу указывает на её непрозрачность и является одним из признаков фирм-однодневок.',
                    'taxArrears': 'Присутствие в базе ФНС среди компаний, имеющих задолженность по уплате налогов более 1000р. Данный фактор имеет смысл рассматривать только в совокупности с иными негативными данными о компании.',
                    'massHeads': 'Руководитель отмечен ФНС как генеральный директор более 10 компаний. Зачастую некоторые из них не удаётся найти по доступным сейчас источникам.',
                    'massFounders': 'Данный учредитель отмечен ФНС как учредивший более 10 компаний. Зачастую некоторые из них не удаётся найти по доступным сейчас источникам.',
                    'taxReporting': 'Наличие сданной в допустимые сроки отчётности за прошлый отчётный период (год). Отсутствие отчётности - зачастую признак фактически прекратившей деятельность компании.',
                    'unfairSuppliers': 'Данный реестр формируется по итогам участия компании в государственных закупках в результате недобросовестного исполнения взятых обязательств.',
                    'executiveProduction': 'Наличие у Службы Судебных Приставов исполнительных производств в отношении компании. Серьёзность данного фактора зависит от объёма производств, их количества и доли от выручки компании.',
                    'massAddress': 'Данный адрес отмечен ФНС как "адрес массовой регистрации". Таковыми являются в том числе бизнес-центры и адреса компаний с большим количеством дочерних структур, поэтому данный признак стоит учитывать только в комплексе с иной негативной информацией.',
                    'disqualifiedPersons': 'Список дисквалифицированных лиц (лишённых права занимать определённую должность) в руководстве компаний. Их решения юридически не имеют силы.',
                    'prosecution': 'Результаты и планы официальных проверок по данным Генеральной Прокуратуры за последние 2 года и на ближайший календарный год.'
                };

                const TYPES_COLOR = {
                    0: {color: '#3EB659', classPanel: 'panel-success', icon: 'fas fa-check-circle', information: 'Положительный результат'},
                    1: {color: '#FFBE3E', classPanel: 'panel-warning', icon: 'fas fa-exclamation-circle', information: 'Удовлетворительный результат'},
                    2: {color: '#F45A5B', classPanel: 'panel-danger', icon: 'fas fa-exclamation-circle', information: 'Отрицательный результат'},
                    3: {color: '#8B0000', classPanel: 'panel-super-danger', icon: 'fas fa-exclamation-circle', information: 'Крайне отрицательный результат'}
                };
                $scope.ctrl = ctrl;
                $scope.TYPES_TITLE = TYPES_TITLE;
                $scope.TYPES_COLOR = TYPES_COLOR;
                $scope.hiddenPanel = false;
                $scope.modelView = {textBody: ''};
                this.$onInit = function () {
                    if (ctrl.verData) {
                        if (ctrl.verRating <= 3) {
                            $scope.classPanel = TYPES_COLOR[ctrl.verRating].classPanel;
                            $scope.colorIcon = TYPES_COLOR[ctrl.verRating].color;
                            $scope.informationIcon = TYPES_COLOR[ctrl.verRating].information;
                        }
                        if (ctrl.verType) {
                            $scope.title = TYPES_TITLE[ctrl.verType];
                            $scope.information = TYPES_TITLE_INFORMATION[ctrl.verType];
                            $scope.modelView.textBody = ctrl.verRating <= 3 ? fillElVerification(ctrl.verData) : 'Не удалось получить информацию.'
                        }
                    } else {
                        $scope.hiddenPanel = true;
                    }

                };

                function fillElVerification(data) {
                    return elVerSrv[ctrl.verType](data)
                }

                function getIconFn() {
                    if (ctrl.verRating <= 3) {
                        return '<i class="' + TYPES_COLOR[ctrl.verData.rating].icon + '"></i>';
                    }
                }
            }]
    })
    .service('elVerSrv', ['$filter', function ($filter) {
            var service = this;
            this.getDateFromString = function (dateStr) {
                var result = '';
                try {
                    var time = new Date(dateStr).getTime();
                    result = $filter('date')(time, 'dd.MM.yyyy', 'UTC+3');
                } catch {
                    result = dateStr;
                }
                return result;
            };
            this.bankruptcies = function (data) {
                var text = '';
                if (data.rating === 0) {
                    text = 'Сведения о банкротствах компании не найдены.'
                } else if (data.rating === 1) {
                    text = ''
                } else if (data.rating === 2 || data.rating === 3) {
                    text = 'Найдено сообщений о банкротстве: ' + data.count_val;
                }
                return text;
            };
            this.arbitrage = function (data) {
                var text = '';
                var getInfo = function (data) {
                    var result = '';
                    if (data.count_val) {
                        result = 'Арбитражных дел: ' + data.count_val;
                        if (data.sumVal) {
                            result += ' на общую сумму ' + data.sumVal + ' руб.'
                        }
                        if (data.percentage_of_revenue) {
                            result += ' (' + data.percentage_of_revenue + '% выручки)';
                        }
                        return result;
                    }
                };

                if (data.rating === 0) {
                    if (data.count_val === 0) {
                        text = 'Открытых арбитражных дел, в которых компания выступает ответчиком, не найдено.'
                    } else {
                        text += getInfo(data)
                    }
                } else {
                    text += getInfo(data)
                }
                return text;
            };
            this.addressLinks = function (data) {
                var text = '';
                if (data.rating === 0) {
                    text = 'Связь по указанному адресу - действующая.'
                } else if (data.rating === 1) {
                    text = '';
                } else if (data.rating === 2) {
                    text = 'Связь по адресу регистрации отсутствует: ' + data.invalidAddress;
                    if (data.date_add) {
                        text += '<br/>Дата внесения в реестр: ' + service.getDateFromString(data.date_add);
                    }
                } else if (data.id === 3) {
                    text = '';
                }
                return text;
            };
            this.taxArrears = function (data) {
                var text = '';
                if (data.rating === 0) {
                    text = 'Компания не является должником по уплате налогов.'
                } else if (data.rating === 1) {
                    text = 'Компания, по состоянию на  ' + service.getDateFromString(data.dateVal) + ', является должником по уплате налогов. Взыскиваемая судебными приставами задолженность по уплате налогов, превышающая 1000 руб.';
                } else if (data.rating === 2) {
                    text = '';
                } else if (data.rating === 3) {
                    text = '';
                }
                return text;
            };
            this.massHeads = function (data) {
                var text = '';
                if (data.rating === 0) {
                    text = 'Массовость руководителя по компании отсутствует.'
                } else if (data.rating === 1 && data.info) {
                    data.info.forEach(function (value) {
                        if (text) {
                            text += '<br>'
                        }
                        text += value.directorName + ', ИНН ' + value.inn;
                        if (value.countCompanies) {
                            text += ': ' + value.countCompanies + ' компаний.';
                        }
                    })
                } else if (data.rating === 2) {
                    text = '';
                } else if (data.rating === 3) {
                    text = '';
                }
                return text;
            };
            this.massFounders = function (data) {
                var text = '';
                if (data.rating === 0) {
                    text = 'Массовость учредителей по компании отсутствует.'
                } else if (data.rating === 1 && data.info) {
                    data.info.forEach(function (value) {
                        if (text) {
                            text += '<br>'
                        }
                        text += value.founderName + ', ИНН ' + value.inn;
                        if (value.countCompanies) {
                            text += ': ' + value.countCompanies + ' компаний.';
                        }
                    })
                } else if (data.rating === 2) {
                    text = '';
                } else if (data.rating === 3) {
                    text = '';
                }
                return text;
            };
            this.taxReporting = function (data) {
                var text = '';
                if (data.rating === 0) {
                    text = 'Компания сдала налоговую отчетность за отчетный год.'
                } else if (data.rating === 1) {
                    text = ''
                } else if (data.rating === 2) {
                    text = 'Компания, по состоянию на ' + service.getDateFromString(data.dateVal) + ', не сдала налоговую отчетность за отчетный год.';
                } else if (data.rating === 3) {
                    text = ''
                }
                return text;
            };
            this.unfairSuppliers = function (data) {
                var text = '';
                if (data.rating === 0) {
                    text = 'Компания отсутствует в реестре недобросовестных поставщиков.'
                } else if (data.rating === 1) {
                    text = ''
                } else if (data.rating === 2) {
                    text = 'Компания присутствует в реестре недобросовестных поставщиков c ' + service.getDateFromString(data.startDate) + ' до ' + service.getDateFromString(data.endDate) + '.';
                } else if (data.rating === 3) {
                    text = ''
                }
                return text;
            };
            this.executiveProduction = function (data) {
                var text = '';
                if (data.rating === 0) {
                    if (data.count_val === 0) {
                        text = 'Исполнительных производств не найдено.'
                    } else {
                        text = 'Количество исполнительных дел: ' + data.count_val;
                    }
                } else if (data.rating === 1) {
                    if (data.count_val) {
                        text = 'Количество исполнительных дел: ' + data.count_val;
                    }
                } else if (data.rating === 2) {
                    text = '';
                } else if (data.rating === 3) {
                    text = '';
                }
                return text;
            };
            this.massAddress = function (data) {
                var text = '';
                if (data.rating === 0) {
                    text = 'Массовость адреса по компании отсутствует.'
                } else if (data.rating === 1) {
                    text = (data.region ? data.region + ' ' : '') +
                        (data.city ? data.city + ' ' : '') +
                        (data.street ? data.street + ' ' : '') +
                        (data.house ? data.house + ' ' : '') +
                        (data.countJur ? ': ' + data.countJur + ' ' + service.getDeclensionCompany(data.countJur) : '') + '.';
                } else if (data.rating === 2) {
                    text = '';
                } else if (data.rating === 3) {
                    text = '';
                }
                return text;

            };
            /**
             * (data.info.person_position ? ' (' + data.info.person_position + ')' : '') todo
             * @param data
             * @returns {string}
             */
            this.disqualifiedPersons = function (data) {
                var text = '';
                if (data.rating === 0) {
                    if (!data.info) {
                        text = 'Дисквалифицированных лиц в компании не найдено.'
                    } else {
                        if (data.info) {
                            if (typeof data.info === 'object') {
                                data.info.forEach(function (value) {
                                    if (value.person_name) {
                                        if (text) {
                                            text += '<br>'
                                        }
                                        text += 'Руководитель ' + value.person_name + ' дисквалифицирован(а) с ' + service.getDateFromString(value.disqualified_from) + ' по ' + service.getDateFromString(value.disqualified_to) + '.';
                                    }
                                })
                            } else {
                                if (data.info.person_name)
                                    text = 'Руководитель ' + data.info.person_name + ' дисквалифицирован(а) с ' + service.getDateFromString(data.info.disqualified_from) + ' по ' + service.getDateFromString(data.info.disqualified_to) + '.';
                            }
                        }
                    }
                } else if (data.rating === 1) {
                    text = 'В компании обнаружены дисквалифицированные лица.'
                } else if (data.rating === 2) {
                    if (data.info) {
                        if (typeof data.info === 'object') {
                            data.info.forEach(function (value) {
                                if (value.person_name) {
                                    if (text) {
                                        text += '<br>'
                                    }
                                    text += 'Руководитель ' + value.person_name + ' дисквалифицирован(а) с ' + service.getDateFromString(value.disqualified_from) + ' по ' + service.getDateFromString(value.disqualified_to) + '.';
                                }
                            })
                        } else {
                            if (data.info.person_name)
                                text = 'Руководитель ' + data.info.person_name + ' дисквалифицирован(а) с ' + service.getDateFromString(data.info.disqualified_from) + ' по ' + service.getDateFromString(data.info.disqualified_to) + '.';
                        }
                    }
                } else if (data.rating === 3) {
                    text = '';
                }
                return text;
            };
            this.prosecution = function (data) {
                let text = '';
                let breakStr = () => {text && (text += '<br>')};
                if (data.rating === 0) {
                    text = 'За последние 2 года ведомственных проверок не проводилось.';
                    breakStr();
                    if (data.info && data.info.planned != null && data.info.planned > 0) {
                        text += 'Количество запланированных проверок: ' + data.info.planned;
                    } else {
                        text += 'В текущем году проверок не запланировано.';
                    }
                } else if (data.rating === 1) {
                    let count = 0;
                    if (data.info) {
                        if (data.info.passed_regular != null) {
                            count = Number(data.info.passed_regular);
                        }
                        if (data.info.passed_unregular != null) {
                            count += Number(data.info.passed_unregular)
                        }
                        text = 'Проведено проверок за последние 2 года: ' + count + ',';
                        if (data.info.violations != null) {
                            breakStr();
                            text += 'В т.ч. выявлено нарушений: ' + data.info.violations;
                        }
                    }
                    breakStr();
                    if (data.info && data.info.planned != null && data.info.planned > 0) {
                        text += 'Количество запланированных проверок: ' + data.info.planned;
                    } else {
                        text += 'В текущем году проверок не запланировано.';
                    }
                } else if (data.rating === 2) {
                    text = '';
                } else if (data.rating === 3) {
                    text = '';
                }
                return text;
            };
            this.getDeclensionCompany = function (count) {
          	    if (count % 10 == 1 && count % 100 != 11) {
             	   return 'компания';
                } else if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
             	   return 'компании';
                } else {
             	   return 'компаний';
                }
            };
        }]
    );

