angular.module('uetp').directive("subscribePanel", [function () {
    return {
        restrict: 'E',
        templateUrl: 'app/directives/subscribe/panel/subscribePanel.html',
        scope: {
            customer: '='
        },
        compile: function () {
            return {
                pre: function (scope) {
                    scope.init();
                }
            }
        },
        controller: ['$rootScope', '$scope', 'subscriptionSrv','alertSrv', 'authSrv', 'dictionarySrv', '$timeout', 'profileSrv',
            function ($rootScope, $scope, subscriptionSrv, alertSrv, authSrv, dictionarySrv, $timeout, profileSrv) {
                $scope.init = initFn;
                $scope.dictionary = dictionarySrv.getRemoteData();
                $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);

                let closePromise;
                $scope.wrongSubscriptionEmail = false;

                function initFn() {
                    $scope.auth = authSrv.getAuth();
                    if($scope.auth && $scope.auth.userId) 
                        profileSrv.loadOrganizationInfo().then((userData) => {
                            if (userData.data.success)
                                initSubscription(userData.data.result.email);
                        })
                    else
                        initSubscription();
                    $scope.filterSelected = filterSelectedFn;
                    $scope.subscribeToggle = subscribeToggleFn;
                    $scope.subscribe = subscribePurchaseFn;
                    dictionarySrv.resetPaginatedDict($scope);
                }

                $scope.refreshDict = (object, value, refreshScrolling,needToAdd) =>
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling,null,needToAdd);


                function filterSelectedFn() {
                   return $scope.subscription && ($scope.subscription.lots__items__okpd2Code && $scope.subscription.lots__items__okpd2Code.length > 0 ||
                          $scope.customer && $scope.customer.length > 0) ;
                }
                function subscribeToggleFn(value) {
                    initSubscription();
                    if(!value)
                        $rootScope.$emit('closeSubscribePanelEvent');
                }
                function subscribePurchaseFn() {
                    if($scope.process) return;
                    else $scope.process = true;
                   const model = angular.copy($scope.subscription);
                   model.docType = 'purchase';
                   delete model.agrement;
                    model.customer = [{ id: $scope.customer.id, inn: $scope.customer.inn, kpp: $scope.customer.kpp, ogrn: $scope.customer.ogrn, name: $scope.customer.name}];
                   if($scope.subscription.lots__items__okpd2Code?.length > 0){
                       model.okpd2 = angular.copy($scope.subscription.lots__items__okpd2Code);
                       angular.forEach(model.okpd2, function (item) {
	                        item.code = item.code + '%';
                       });
                   }
 	               subscriptionSrv.subscribePurchase(model).then(function (response) {
                       $scope.alertMessage = response.data.success ?  "Подписка на закупки оформлена.<br/>На указанный email направлено письмо для подтверждения." : "Ошибка при оформлении подписки на закупку.";
                       closePromise = $timeout(()=> { subscribeToggleFn(false);
                           $scope.process = false;
                       }, 5000);

	                }, function (reason) {
                       $scope.alertMessage = "Ошибка при оформлении подписки на закупку.";
                       console.log(reason);
                       $scope.process = false;
                    })
                }

                $scope.$on('$destroy', function () {
                    $timeout.cancel(closePromise);
                });

                $rootScope.$on("toggleSubscribePanelEvent", function () {
                    delete $scope.alertMessage;
                });
                function initSubscription(email) {
	               $scope.subscription = {};
	               if($scope.auth && $scope.auth.userId){
                      $scope.subscription.email = email ? email : $scope.auth.email;
                      $scope.subscription.userId = $scope.auth.userId;
                      $scope.subscription.userFio = $scope.auth.userName;
                      $scope.subscription.orgId = $scope.auth.orgId;
                   }
                }
                $rootScope.$on('subscribeCheckEmail', (event, value, error) => {
                    $scope.wrongSubscriptionEmail = (value.length>0 && error?.value);
                });
            }]
    }
}]);