/**
 * @author: sergeyu
 */
angular.module('uetp').service('dataSrv', ['$http', '$injector', '$state',
    function ($http, $injector, $state) {
        var service = this,
            modelData = null;

        this.setModelData = function (data) {
            modelData = data;
            localStorage.setItem('modelData', JSON.stringify(data))
        };

        this.getModelData = function () {

            if ($state.current.clearData) {
                modelData = null;
            }
            if (!modelData) {
                service.setModelData(JSON.parse(localStorage.getItem("modelData")))
            }
            return modelData;
        };
    }]);