angular.module('uetp').service('versionInfoCreateSrv', ['$http', '$rootScope', 'dialogSrv', 'httpSrv',
    function ($http, $rootScope, dialogSrv, httpSrv) {
        this.deleteItem = deleteItemFn;
        this.undoItem = undoItemFn;
        this.saveVersionInfo = saveVersionInfoFn;
        this.publicVersionInfo = publicVersionInfoFn;
        this.loadVersionInfo = loadVersionInfoFn;

        function deleteItemFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/news/versions/deleteVersionInfo/' + id
            })
        }

        function undoItemFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/news/versions/draftVersionInfo/' + id
            })
        }

        function saveVersionInfoFn(data) {
            return httpSrv.http({
                url: '/news/versions/createVersionInfo',
                data: data
            })
        }

        function publicVersionInfoFn(data) {
            return httpSrv.http({
                url: '/news/versions/publicVersionInfo',
                data: data
            })
        }

        function loadVersionInfoFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/news/versions/getVersionInfoById/' + id
            })
        }
    }]);