/**
 * Создание протокола "Уведомление о переносе сроков торгов"
 */
angular.module('uetp')
    .component('noticePostponementProcedure', {
        templateUrl: 'app/components/procedures/noticePostponement/noticePostponementProcedure.html',
        bindings: {
            procedureData: '<',
            protocolData: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'proceduresSrv', '$uibModal', 'authSrv',
            'httpSrv', 'storageSrv', 'vfSrv', 'needConfirmSrv', 'alertSrv','dictionarySrv', 'dialogSrv', 'protocolSrv','constants', 'permissionSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, proceduresSrv, $uibModal, authSrv,
                      httpSrv, storageSrv, vfSrv, needConfirmSrv, alertSrv, dictionarySrv, dialogSrv, protocolSrv, constants, permissionSrv) {
                var ctrl = this;
                $scope.ctrl = ctrl;
                $scope.constants = constants;
                $scope.getCurrencyPatternByCode = (code) => dictionarySrv.getCurrencyPatternByCode(code);
                $scope.actionBtns = {
                        isManualIncorrectProtocol: false,
                        isManualCancelProtocol: false,
                        isManualPublishProtocol: false
                };
                
                this.$onInit = function () {
                    $scope.isViewOnly = $stateParams.isViewOnly;
                    $scope.procedure = $scope.ctrl.procedureData.data.result;
                    $scope.protocol = $scope.ctrl.protocolData.data.result;
                    $scope.protocolForm = {form:{}};
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.changeState = changeStateFn;
                    $scope.ajaxActive = true;
                    $scope.isEisProcedure=proceduresSrv.isEisProcedure($scope.procedure);

                    if (!$scope.isViewOnly) {
                        $scope.maxCountAttachments = 1;
                        $scope.protocol.document.subjectExplanationRequest = "Перенос сроков";
                        $scope.protocol.document.titleExplanationRequest = "Перенос сроков";
                        $scope.auth = authSrv.getAuth();
                    }
                    
                    _getBtnVisible($scope.protocol.actions);

                };

                $scope.deleteAttach = deleteAttachFn;
                $scope.signSendData = signSendDataFn;
                $scope.uploadSuccess = uploadSuccessFn;
                $scope.downloadFile = downloadFileFn;
                $scope.cancel = cancelFn;
                $scope.hasPermission = permissionSrv.hasPermission;

                $scope.isPercentMode = function(){
                    return $scope.procedure && $scope.procedure.priceMode === 'percent';
                }

                function uploadSuccessFn(result) {
                    if (!$scope.protocol.document.attachment) {
                        $scope.protocol.document.attachment = []
                    }
                    angular.forEach(result, function (item) {
                        $scope.protocol.document.attachment.push({
                            fileName: item.fileName,
                            id: item.id
                        });
                    })
                }

                function deleteAttachFn(index) {
                    $scope.protocol.document.attachment.splice(index, 1);
                }

                function cancelFn() {
                    if (_getForm().$dirty) {
                        needConfirmSrv.call(_openProcedure, [], 'Внесенные изменения сохранены не будут');
                    } else {
                        _openProcedure();
                    }
                }

                function _focusFirstInvalid(form) {
                    $scope.submitted = true;
                    if (form.$error && form.$error.required && form.$error.required[0]
                        && form.$error.required[0].$$element && form.$error.required[0].$$element[0]) {
                        desktopSrv.scollTop();
                        form.$error.required[0].$$element[0].focus();
                    }
                    if (form.length > 0 && form[0].$$element && form[0].$$element[0]) {
                        desktopSrv.scollTop();
                        form[0].$$element[0].focus();
                    }
                }

                function _openProcedure() {
                    $state.go('openProcedure', {procType: $scope.procedure.type.id.toLowerCase(), procId: $scope.procedure.id},{location :'replace'});
                }

                /**
                 * Подпись и отправка
                 */
                function signSendDataFn() {
                    if ($scope.auth.certId || $scope.auth.withoutCertEnabled) {
                        if (validateForm()) {
                            $stateParams.protocolId = $scope.protocol.id;
                            var copy = angular.copy( $scope.protocol);
                            removeKeys(copy, 'deff__');
                            protocolSrv.saveProtocolFn($stateParams, copy, 'NOTICE_POSTPONEMENT').then(function (response) {
                                if (response.data.success) {
                                    $stateParams.noticeId = response.data.result[1]; // id notice
                                    protocolSrv.signAndSendProtocolAndNotice($stateParams, $scope.auth.certId).then(function (response) {
                                        if (response.data.success) {
                                            setTimeout(function () {
                                                _openProcedure();
                                            }, 1000);

                                        } else {
                                            $scope.alertObjRejectProcedure = alertSrv.getAlertObj(response);
                                        }
                                        $scope.ajaxActive = false;
                                    }, function (reason) {
                                        if (typeof reason === 'string') {
                                            $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn(reason);
                                        } else {
                                            $scope.alertObjRejectProcedure = alertSrv.getAlertObj(reason);
                                        }
                                        $scope.ajaxActive = false;
                                    });
                                } else {
                                    $scope.alertObjRejectProcedure = alertSrv.getAlertObj(response);
                                }
                            })
                        }
                    } else {
                        $scope.ajaxActive = false;
                        $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn('У вас не установлен сертификат.');
                    }
                }

                function changeStateFn(state) {
                    needConfirmSrv.call(doChangeStateFn,[state],'Выполнить смену редакции уведомления?');
                }
                function doChangeStateFn(state) {
                     $stateParams.protocolId = $scope.protocol.id;
                     protocolSrv.changeStateProtocolFn($stateParams, state).then(function (response) {
                            if (response.data.success) {
                            	$scope.alertObjRejectProcedure = alertSrv.getSuccessMsg('Статус уведомления изменён');
                            	setTimeout(function () {
                                    _openProcedure();
                                }, 3000);
                            	$scope.ajaxActive = false;
                            } else {
                                $scope.alertObjRejectProcedure = alertSrv.getAlertObj(response);
                            }
                        })
                }
 
                
                /**
                 * Активация кнопок в зависимости от разрешнных действий сервера
                 * @param actions - действия которые можно выпонить с документом
                 * @private
                 */
                function _getBtnVisible(actions) {
                   if (actions && actions.length > 0) {
                        angular.forEach(actions, function (action) {
                            if (action.id.indexOf('manualIncorrectProtocol') >= 0) {
                                $scope.actionBtns.isManualIncorrectProtocol = true;
                            } else if (action.id.indexOf('manualCancelProtocol') >= 0) {
                                $scope.actionBtns.isManualCancelProtocol = true;
                            } else if (action.id.indexOf('manualPublishProtocol') >= 0) {
                                $scope.actionBtns.isManualPublishProtocol = true;
                            }
                        })
                    } 
                }
                
                function downloadFileFn(file) {
                    var url = '/fs/file/' + $scope.procedure.type.id.toLowerCase() + '/' + $scope.procedure.id + '/files/' + file.id;
                    httpSrv.downloadFile(url, file.filename);
                }

                function _getForm() {
                    return $scope.protocolForm.form;
                }

                function validateForm() {
                    if (_getForm().$invalid && !checkModelValue(_getForm())) {
                        $scope.submitted = true;
                        if (_getForm().$error.required) {
                            $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn('Необходимо заполнить обязательные поля на форме. ', 'Перейти', _focusFirstInvalid, _getForm());
                        }
                        else {
                            var errorArr = Object.keys(_getForm().$error).length > 0 ? _getForm().$error[Object.keys($scope.protocolForm.form.$error)[0]] : [];
                            if (errorArr.length > 0 && errorArr[0].$$element && errorArr[0].$$element[0]) {
                                $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn('Неправильно заполнено поле. ', 'Перейти', _focusFirstInvalid, errorArr);
                            }
                        }
                        return false;
                    }
                    if ($scope.protocol.document.lots && $scope.protocol.document.lots.protocolLotApplication
                        && $scope.protocol.document.lots.protocolLotApplication.protocolLotApplications) {
                        var checkLot = false;
                        angular.forEach($scope.protocol.document.lots.protocolLotApplication.protocolLotApplications, function (lot) {
                            if (lot.deff__selectedLot) {
                                checkLot = true;
                            }
                        });
                        if (checkLot === false) {
                            $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn("Необходимо выбрать хотя бы один лот для переноса даты начала торгов!");
                            return false;
                        }
                    } else {
                        $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn("Отсутствует список лотов для переноса даты начала торгов!");
                        return false;
                    }
                    if (!$scope.protocol.document.attachment || $scope.protocol.document.attachment.length === 0) {
                        $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn("Обратите внимание! Документ должен содержать вложение.");
                        return false;
                    }

                    return true;
                }

                function checkModelValue(form) {
                    if(form.$error)
                    {
                        if(Array.isArray(form.$error?.maxlength)) {
                            for (const idx in form.$error.maxlength) {
                                if (!/^[0-9\s]{1,13}(?:\.[0-9]{0,2})?$|^0$/.test(form.$error.maxlength[idx].$viewValue.trim().replace(/\s/g, "").replace(/,/g, '.')))
                                    return false;
                            }
                        } else if(Array.isArray(form.$error.required) && form.$error?.required.some(el=>el.$invalid)) {
                            return false;
                        }
                    }
                    return true;
                }

                /**
                 * Удаление лишних атрибут объектов.
                 * @param obj
                 * @param keys
                 */
                //todo может надо использовать метод из desktop.service.js?
                function removeKeys(obj, keys){
                    var index;
                    for (var prop in obj) {
                        if(obj.hasOwnProperty(prop)){
                            switch(typeof(obj[prop])){
                                case 'string':
                                    index = prop.indexOf(keys);
                                    if(index > -1){
                                        delete obj[prop];
                                    }
                                    break;
                                case 'boolean':
                                    index = prop.indexOf(keys);
                                    if(index > -1){
                                        delete obj[prop];
                                    }
                                    break;
                                case 'object':
                                    index = prop.indexOf(keys);
                                    if(index > -1){
                                        delete obj[prop];
                                    }else{
                                        removeKeys(obj[prop], keys);
                                    }
                                    break;
                            }
                        }
                    }

                }
            }
        ]
    });

