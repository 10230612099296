/**
 * @author: sergeyu
 * Форма просмотра информации о компании
 */
angular.module('uetp')
    .component('companyViewTass', {
        bindings: {
            companyInfo: '<'
        },
        templateUrl: 'app/components/widgets/tassBusiness/companyValidate/view/baseView.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'alertSrv', 'authSrv', 'dialogSrv', 'desktopSrv','tassBusinessSrv','paginationSrv', 'tariffsSrv', 'filterService', 'propertiesService', '$q',
            function ($scope, $rootScope, $state, $stateParams, alertSrv, authSrv, dialogSrv, desktopSrv,tassBusinessSrv,paginationSrv, tariffsSrv, filterService, propertiesService, $q) {
                var ctrl = this;
                $scope.model = {
                    showPanel: '',
                    showCustomers: true,
                    showProviders: true,
                    showContracts: true,
                    showReporting: false,
                    showVerification: true,
                    showTaxes: true,
                    showFinReport: true,
                    showBlockedAccounts: false,
                    showComponent: false,//показывать компонет только для пользователей тарифного плана "Годовой"
                    searchInProcess: false
                };
                $scope.showInfo = true;
                var userInn, userTypeOrg;
                this.$onInit = function () {
                    var cabType = desktopSrv.getCabType();
                    $scope.model.searchInProcess = false;
                    /*
                    * Проверка прав
                    * */
                    authSrv.reloadOrgInfo().then(function (user) {
                        $scope.user = user;
                        $scope.usedTarifsArr = [];
                        angular.forEach($scope.user.userTariffs, function(value, key){
                            if(['D01', 'Z04', 'D11', 'Z14'].includes(key))
                                $scope.usedTarifsArr.push(key)
                        });
                        if(user.inn) userInn = user.inn;
                        if(user.typeOrg) userTypeOrg = user.typeOrg;
                        if(user.userRole==='operator_support_ro') $state.go('home');
                        checkCompanyValidationAccess().then(()=>{
                            init();
                        }, reason => {
                            dialogSrv.openModal('_dialogMessage.html', {message: reason.messageTxt}).then(function () {
                                $state.go(reason.state);
                            })
                        })

                    });

                    function init() {
                        if ($state.includes('companyValidationTass')) {
                            //отображаем только строку поиска
                            if ($stateParams.showError) {
                                $scope.alertCompanyValidateSearch = alertSrv.getErrorMsg($stateParams.showError);
                                $scope.showInfo = false;
                            }
                        } else {
                            if (ctrl.companyInfo) {
                                if (ctrl.companyInfo.data && ctrl.companyInfo.data.success && ctrl.companyInfo.data.result) {
                                    $scope.companyInfo = ctrl.companyInfo.data.result;
                                    $scope.model.showPanel = 'verification';
                                    $scope.registryPlaceholder = false;
                                    $scope.model.showComponent = true;
                                    tassBusinessSrv.clearRequestProcessed();
                                    $scope.modelView = ctrl.companyInfo.data.result;
                                    if ((!$scope.modelView.address || !$scope.modelView.address.length) &&
                                        $scope.modelView.legalAddress && $scope.modelView.legalAddress.length) {
                                        $scope.modelView.address = angular.copy($scope.modelView.legalAddress);
                                    }
                                    //устанавливаем id загруженной компании
                                    $stateParams.idCompany = $scope.modelView.id;
                                    //у ип - инн 12 сивмволов
                                    //у юр - инн 10 символов
                                    $scope.isIP = $scope.modelView.inn.length === 12;
                                    /*
                                    * Недействующее предприятие:
                                    * Есть дата закрытия
                                    * нет даты закрытия и нет даты постановки на учет
                                    * */
                                    $scope.inactive = $scope.modelView.closeDate || (!$scope.modelView.closeDate && !$scope.modelView.registerDate) ? true : false;
                                    if($scope.isIP){
                                    	 $scope.model.showReporting = false;
                                    	 tassBusinessSrv.setRequestProcessed('reporting', true);
                                    }
                                    if($scope.inactive){
                                    	 tassBusinessSrv.setRequestProcessed('reporting', true);
                                    	 tassBusinessSrv.setRequestProcessed('verification', true);
                                    }
                                    $scope.rColor = {'color': ratingColor($scope.modelView.rating)}

                                    var verifyResult = angular.copy($scope.modelView);
                                    verifyResult.loyality = null;
                                    tassBusinessSrv.setVerifyResult(verifyResult);

                               } else {
                                    // errorLoad(ctrl.companyInfo.data.errorMessage);
                                	var msg = ctrl.companyInfo.data && ctrl.companyInfo.data.errorCode === '501' ?
                                			'Достигнуто максимальное количество пробных проверок — 5. Для восстановления доступа к сервису необходимо подключить тарифный план "Безопасность закупок" или "Годовой".' : undefined ;
                                    errorLoad(msg);
                                }
                            } else {
                                errorLoad();
                            }
                        }
                    }

                    /**
                     * Ошибки при загрузке
                     * @param mess - сообщение об ошибке
                     */
                    function errorLoad(mess) {
                        $stateParams.idCompany = null;
                        var error = mess || "Компании, удовлетворяющие условиям поиска, не найдены.";
                        if ($state.includes('companyValidationTass')) {
                            $scope.alertCompanyValidateSearch = alertSrv.getErrorMsg(error);
                        } else {
                            $state.go('companyValidationTass', {showError: error});
                        }
                    }


                    function checkCompanyValidationAccess() {
                        const deferred = $q.defer();
                        if($state.includes('companyValidationTass') && $stateParams.showError) //просто вывод ошибки, проверки не нужны
                            deferred.resolve();
                        if (!authSrv.canActiveOtherPurchase(cabType, $scope.user)) {
                            let messageTxt = 'Доступ к Реестру закупок ЕИС закрыт. Необходима авторизация в ЛК!';
                            if (cabType === 'participant') {
                                messageTxt = 'Раздел доступен только для пользователей тарифного плана "Годовой"';
                            }
                            deferred.reject({
                                messageTxt,
                                state: cabType === 'participant' ? 'myProcedures' : 'home'
                            });
                        } else {
                            if ($state.includes('companyValidationTass') && authSrv.checkTariffsZ04_D01(cabType, $scope.user)) {
                                propertiesService.getProperty("api.url").then(function (host) {
                                    tassBusinessSrv.getParticipantLimit(host).then(response => {
                                        if (response.data.success && response.data.result > 0) {
                                            deferred.resolve();
                                        } else {
                                            tariffsSrv.getNeedTariffForServiceMsg().then(function(msg){
                                                deferred.reject({
                                                    messageTxt: msg,
                                                    state: 'tariffsMenu'
                                                });
                                            });
                                        }
                                    });
                                });
                            } else {
                                deferred.resolve();
                            }
                        }
                        return deferred.promise;
                    }
                };
                $scope.setActivePanel = setActivePanelFn;
                $scope.getRating = getRatingFn;
                $scope.searchCompany = searchCompanyFn;
                $scope.downloadVerifyResult = downloadVerifyResultFn;
                $scope.isDemo = isDemoFn;
                $scope.isRequestProcessed = isRequestProcessedFn;

                /**
                 * Выставление активной вкладки
                 * @param type - тип вкладки
                 */
                function setActivePanelFn(type) {
                    $scope.model.showPanel = type;
                }

                function ratingColor(rating) {
                    if (rating > 70) {
                        return 'green'
                    } else if (rating >= 50) {
                        return 'orange'
                    } else if (rating < 50) {
                        return 'red'
                    }
                }

                //todo вынести в директиву
                function getRatingFn() {
                    var title = 'Рейтинг ' + $scope.modelView.rating + '%';
                    var ok = '<i class="fas fa-star cursorPointer" title="' + title + '"></i>';
                    var no = '<i class="far fa-star cursorPointer" title="' + title + '"></i>';
                    var rating = $scope.modelView.rating;
                    if (rating > 80) {
                        return ok + ok + ok + ok + ok;
                    } else if (rating <= 80 && rating > 60) {
                        return ok + ok + ok + ok + no;
                    } else if (rating <= 60 && rating > 40) {
                        return ok + ok + ok + no + no;
                    } else if (rating <= 40 && rating > 20) {
                        return ok + ok + no + no + no;
                    } else if (rating <= 20 && rating > 0) {
                        return ok + no + no + no + no;
                    } else if (rating === 0) {
                        return no + no + no + no + no;
                    }
                }

                /*
                * Поиск компании по инн
                * */
                function searchCompanyFn() {
                    if(isDemoFn() && (userInn!==$scope.model.search || userTypeOrg==='nr')){
                        $scope.alertCompanyValidateSearch = alertSrv.getErrorMsg('На демонстрационной версии возможна проверка только своей организации.');
                        return;
                    }
                    $scope.model.searchInProcess = true;
                    filterService.resetCurrent('filterCompanyValidateContracts');

                    $scope.registryPlaceholder = true;
                    if ($scope.model.search) {
                        //при новом поиске сбрасываем память пагинации
                        paginationSrv.clearPaginationStateConfig('pagination', 'companyValidateProviders');
                        paginationSrv.clearPaginationStateConfig('pagination', 'companyValidateCustomers');
                        paginationSrv.clearPaginationStateConfig('pagination', 'companyValidateContracts');
                        $state.go('companyViewTass', {id: $scope.model.search});
                        if ($scope.model.search === $stateParams.id) {
                            $scope.registryPlaceholder = false;
                            $scope.model.search = '';
                        }
                    } else {
                        $scope.registryPlaceholder = false;
                    }
                }

                function downloadVerifyResultFn() {
                    propertiesService.getProperty("api.url").then(function (host){
                       tassBusinessSrv.downloadVerifyResult('/rest/tassValid/search/downloadOrganizationInfoPdf/' + $scope.modelView.inn, host);
                    });
                }
                function isDemoFn(){
                    return $rootScope.properties && $rootScope.properties['demo.mode']==="true";
                }
                function isRequestProcessedFn(){
                    return $scope.modelView && tassBusinessSrv.getRequestProcessed('reporting') && tassBusinessSrv.getRequestProcessed('verification');
                }

            }]
    });

