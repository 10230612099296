//компонент контакты
angular.module('uetp')
    .component('contacts', {
        templateUrl:['constants', function (constants) {
            return 'app/components/contacts/contacts_' + constants.configProject.type + '.html'
        }],
        transclude: true,
        controller: ['$scope', 'timeout', 'dialogSrv', 'httpSrv', function ($scope, timeout, dialogSrv, httpSrv) {

            $scope.reset = resetFn;
            $scope.sendFeedback = sendFeedbackFn;
            $scope.initCaptcha = initCaptchaFn;
            $scope.refreshCaptcha = refreshCaptchaFn;

            $scope.showSendMessage = false;
            init();

            $scope.closeAlert = function () {
                $scope.alertId = 'dismiss';
            };

            function resetFn() {
                $scope.active = {};
                $scope.cert = {};
                $scope.profile = {};
                $scope.user = {regAddress: {oksmCode: '643'}, realAddress: {}};
                $scope.certspin = {};
                $scope.captcha = {};
            }

            function initCaptchaFn() {
                resetFn();
                $scope.captcha.url = "/rest/auth/captcha.png?r=" + Math.random();
                $scope.active.cert = 'no';
            }

            function refreshCaptchaFn() {
                $scope.captcha.url = "/rest/auth/captcha.png?r=" + Math.random();
            }


            function sendFeedbackFn(data) {
                $scope.submitted = true;
                if (!$scope.form.feedback.$valid) {
                    $scope.alertId = 'validError';
                    timeout($scope);
                    return;
                }
                httpSrv.http({
                    method: 'POST',
                    url: '/auth/rekl/sendFeedback',
                    data: data,
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8'
                    }
                }).then(function successCallback(response) {
                    if (response.data.success) {
                        $scope.showSendMessage = true;
                    } else {
                        dialogSrv.showDialogMsg($scope, response.data.message ? response.data.message : "Не удалось отправить сообщение. Попробуйте позже");
                        $scope.refreshCaptcha();
                    }
                }, function errorCallback(response) {
                    console.log(response);
                });
            }

            function init() {
                $scope.form = {};
                $scope.submitted = false;
                $scope.captcha = {};
                $scope.alertId = 'dismiss';
                $scope.dialog = {};
                $scope.refreshCaptcha();
            }

        }]
    });