/**
 * @author: alexandrbu
 * БГ участники. список лимитов
 */
angular.module('uetp')
    .component('limitList', {
        templateUrl: 'app/components/bgParticipants/limit/limitList.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'bgParticipantsSrv', 'authSrv', 'desktopSrv', 'dialogSrv', '$filter','dictionarySrv', 'filterService', 'bankGuaranteeSrv', 'alertSrv', 'httpSrv', 'timeout', '$interval', 
            function ($scope, $rootScope, $state, $stateParams, bgParticipantsSrv, authSrv, desktopSrv, dialogSrv, $filter, dictionarySrv, filterService, bankGuaranteeSrv, alertSrv, httpSrv, timeout, $interval) {
        	var refreshIndex = null;   
        	this.$onInit = function () {
                	$scope.processTassParticipants = {};
                	$scope.showThrobber = false;
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.authData = authSrv;
                    $scope.user = authSrv.getUser();
                    $scope.totalPages = 0;
                    $scope.isRefreshAllLimitsRunning = false;
                    $scope.paginator = {
                        itemsPerPage: 20,
                        page: 0
                    };
                    _initModelView();
                    setRefreshAllLimitsRunningFn();
                    loadLimitListFn();
                    setProcessTassParticipants(false);
                    refreshIndex = $interval(function () {
                        $scope.setRefreshAllLimitsRunning();
                    }, 10000);
                };
                
                $scope.loadLimitList = loadLimitListFn;
                $scope.refreshLimits = refreshLimitsFn;
                $scope.refreshAllLimits = refreshAllLimitsFn;
                $scope.isSpin = isSpinFn;  
                $scope.exportExcel = exportExcelFn;
                $scope.setRefreshAllLimitsRunning = setRefreshAllLimitsRunningFn;
                
                this.$onDestroy = function () {
                    $interval.cancel(refreshIndex);
                };
                
                /**
                 * Инициализация модели в зависимости от ЛК
                 * @private
                 */
                function _initModelView() {
                    $scope.cabType();
                }

                /**
                 * Список лимитов
                 */
                function loadLimitListFn() {
                	prepareTableParams('filterBgLimitParticipant');
                    bgParticipantsSrv.loadLimitList($scope.paginator).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.limitList = response.data.items;
                            $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                        } else {
                            $scope.limitList = [];
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    })
               }

               function prepareTableParams(filterType) {
                    var filterParams = angular.copy(filterService.getFilterParams(filterType)),
                        searchParam = angular.copy(filterService.getSearchParam(filterType));
                    if (filterParams && Object.keys(filterParams).length > 0) {
                        $scope.paginator.searchByField = filterParams;
                    } else {
                        delete $scope.paginator.searchByField
                    }
                    $scope.paginator.search = searchParam;
               }
              
               function refreshLimitsFn(participant, orgId) {
            	  if($scope.processTassParticipants[orgId] || $scope.processTassParticipants['top']) {
               		 return;
               	  }
               	  $scope.processTassParticipants[orgId] = true;
                  bankGuaranteeSrv.refreshLimits(participant.inn, participant.ogrn, participant.fullName, orgId, participant.typeOrg).then(function successCallback(response) {
                     if (response.data.success) {
                    	 $scope.alertRefreshLimits = alertSrv.getSuccessMsgWithBtn("Обновление лимита для \"" + participant.fullName + "\" завершено");
                    	 loadLimitListFn();
                     } else {
                          $scope.alertRefreshLimits = alertSrv.getErrorMsgWithBtn(response.data.message ? response.data.message : "В результате выполнения произошла ошибка");
                     }
                     $scope.processTassParticipants[orgId] = false;
                 }, function errorCallback(response) {
                     console.log(response);
                     $scope.processTassParticipants[orgId] = false;
                     $scope.alertRefreshLimits = alertSrv.getErrorMsgWithBtn("В результате выполнения произошла ошибка");
                  });
               }
               
               function refreshAllLimitsFn() {
            	   if($scope.processTassParticipants['top']) {
               		  return;
               	   }
            	   setProcessTassParticipants(true);
                   bgParticipantsSrv.refreshAllLimits().then(function successCallback(response) {
                       if (response.data.success) {
                    	  $scope.alertRefreshLimits = alertSrv.getSuccessMsgWithBtn("Процесс обновления лимитов запущен");
                    	  //loadLimitListFn();
                    	  $scope.isRefreshAllLimitsRunning = true;
                       } else {
                          $scope.alertRefreshLimits = alertSrv.getErrorMsgWithBtn(response.data.message ? response.data.message : "В результате выполнения произошла ошибка");
                       }
                       setProcessTassParticipants(false);
                   }, function errorCallback(response) {
                       console.log(response);
                       setProcessTassParticipants(false);
                       $scope.alertRefreshLimits = alertSrv.getErrorMsgWithBtn("В результате выполнения произошла ошибка");
                   });
               }
               
               function setRefreshAllLimitsRunningFn() {
                   bgParticipantsSrv.isRefreshAllLimitsRunning().then(function successCallback(response) {
                       if (response.data.success) {
                    	  if($scope.isRefreshAllLimitsRunning && !response.data.result){
                    		  $scope.alertRefreshLimits = alertSrv.getSuccessMsgWithBtn("Обновление лимитов завершено");
                    		  loadLimitListFn();
                    	  }
                    	  $scope.isRefreshAllLimitsRunning = response.data.result;
                     	 
                      } else {
                          console.log(response.data.message);
                       }
                    }, function errorCallback(response) {
                       console.log(response);
                    });
               }
               
               function setProcessTassParticipants(value) {
            	   if(!value){
            		   $scope.processTassParticipants = {};
            	   }
               	   $scope.processTassParticipants['top'] = value;
               }
               
               function isSpinFn(orgId) {
                   return $scope.processTassParticipants['top'] || orgId && $scope.processTassParticipants[orgId] || $scope.isRefreshAllLimitsRunning;
               }
               
               function exportExcelFn() {
            	   var param = angular.copy($scope.paginator);
            	   bgParticipantsSrv.exportExcel(param);
               }
               
               $scope.$on('setShowThrobber', function (event, data) {
                   $scope.showThrobber = data;
                   timeout($scope);
               });
            }]
    });

