angular.module('uetp').factory('timeout', ['$exceptionHandler',
    function ($exceptionHandler) {
        return function (scope, execFunction) {
            var delay = 10;
            var periodRun = 1000;

            if (typeof execFunction == "function") {
                execFunction();
            }
            repeatDigest(0);

            function repeatDigest(currentPeriod) {
                try {
                    scope.$digest();
                } catch (e) {
                    if (e.message && e.message.indexOf(':inprog') != -1 && currentPeriod < periodRun) {
                        currentPeriod += delay;
                        setTimeout(function () {
                            repeatDigest(currentPeriod);
                        }, delay);
                    } else {
                        $exceptionHandler(e);
                    }
                }
            }
        }
    }]);
