/**
 * Обработка ошибок сервера
 */
angular.module('uetp').factory('requestErrorFactory', ['$injector', '$q', '$rootScope', function ($injector, $q, $rootScope) {
    var authSrv = null;
    return {
        request: function (config) {
            if (authSrv === null) {
                authSrv = $injector.get('authSrv');
            }
            if (authSrv.isValidCookie()) {
                return config;
            } else {
                $injector.get('authSrv').clearSession();
                $rootScope.$emit('serverErrorEvent', {
                    timeToClose: 3000000,
                    msg: 'Данный сеанс завершен по причине открытия Личного кабинета в другой вкладке браузера',
                    showAfterExit:true
                });
                return config;
            }
        },
        responseError: function (rejection) {
            var result = {};
            if (rejection.status === 401) {
                result.msg = 'Используемый токен устарел или кто-то удалил его путем выхода из ЛК. Повторите авторизацию!';
                result.loginOpen = true;
                result.timeToClose = 30000;
                $injector.get('authSrv').clearSession();
            } else if (rejection.status === 403) {
                result.msg = 'Доступ к методу закрыт. Видимо ваш пользователь не обладает достаточными правами доступа';
            } else if (rejection.status === 500) {
                result.msg = 'На сервере произошла ошибка!';
            } else if (rejection.status === 504) {
                result.msg = 'Внимание! Нет ответа от сервера!';
                result.focusMsg = true;
            } else if (rejection.status === -1) {
                result.msg = 'Проверьте подключение Вашего рабочего места к сети Интернет. Информация на странице не была обновлена.';
                result.focusMsg = true;
            }
            if (result.msg && !rejection.config.ignoreError) {
                $rootScope.$emit('serverErrorEvent', result)
            }
            return $q.reject(rejection);
        }

    };
}]);



