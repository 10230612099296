angular.module('uetp')
    .component('feedbackForm', {
        template: '<div ng-include="ctrl.templateUrl">',
        controller: ['$scope', '$rootScope', '$httpParamSerializerJQLike', '$state', '$stateParams', '$anchorScroll', '$log', 'httpSrv', 'timeout', 'dialogSrv', 'constants','authSrv',
            function ($scope, $rootScope, $httpParamSerializerJQLike, $state, $stateParams, $anchorScroll, $log, httpSrv, timeout, dialogSrv, constants, authSrv) {
                var ctrl = this;
                $scope.ctrl = ctrl;
                $scope.reset = resetFn;
                $scope.initCaptcha = initCaptchaFn;
                $scope.refreshCaptcha = refreshCaptchaFn;
                $scope.sendFeedback = sendFeedbackFn;
                $scope.constants = constants;
                $scope.banks = {absolut:"Абсолют", loko: "ЛокоБанка", vtb: "ВТБ", raiff:"Райффайзен Банка"};
                $scope.attachReset = function () {
                    $scope.attachItem = [];
                };
                $scope.attachReset();
                $scope.uploadFile = function (response) {
                    if (response) {
                        $scope.attachItem.push({
                            "id": response[0].id,
                            "fileType": 0,
                            "name": response[0].fileName,
                            "size": response[0].size
                        });

                        $scope.content.attachmentId = response[0].id;
                        //ie 11 баг снятие фокуса. Иначе не нажат кнопки
                        window.focus();
                        if (document.activeElement) {
                            document.activeElement.blur();
                        }
                    }
                };
                $scope.showFiles = function (files) {
                    $scope.attachItem.name = files[0].name;
                };
                $scope.onSizeError = function (files) {
                    $scope.fileSizeErrorMessage = "Файл " + files[0].name + " не добавлен. Превышен максимально разрешенный размер файла (5МБ)!";
                    $scope.alertId = 'fileSizeError';
                };
                $scope.deleteAttach = function (file, object, obj) {
                    delete obj.attachmentId;
                    var index = object.indexOf(file);
                    if (index > -1) {
                        object.splice(index, 1);
                    }
                    $scope.addAttach = 'show';
                    $scope.attachReset();
                };

                $scope.closeAlert = function () {
                    $scope.alertId = 'dismiss';
                };

                function resetFn() {
                    $scope.active = {};
                    $scope.cert = {};
                    $scope.profile = {};
                    $scope.user = {regAddress: {oksmCode: '643'}, realAddress: {}};
                    $scope.certspin = {};
                    $scope.captcha = {};
                }

                function initCaptchaFn() {
                    resetFn();
                    $scope.captcha.url = "/rest/auth/captcha.png?r=" + Math.random();
                    $scope.active.cert = 'no';
                }

                function refreshCaptchaFn() {
                    $scope.captcha.url = "/rest/auth/captcha.png?r=" + Math.random();
                }

                function sendFeedbackFn(url, data, afterSendType, theme) {
                    $scope.submitted = true;
                    if (theme) {
                        data.theme = theme;
                    }
                    if (!$scope.form.feedback.$valid) {
                        $scope.alertId = 'validError';
                        timeout($scope);
                        return;
                    }

                    httpSrv.http({
                        method: 'POST',
                        url: url,
                        data: data,
                        headers: {
                            'Content-Type': 'application/json; charset=utf-8'
                        }
                    }).then(function successCallback(response) {
                        if (response.data.success) {
                            if (afterSendType == 'go') {
                                $state.go('feedbackSent');
                            } else if (afterSendType == 'showMessage') {
                                $scope.showSendMessage = true;
                            }
                        } else {
                            dialogSrv.showDialogMsg($scope, response.data.message ? response.data.message : "Не удалось отправить сообщение. Попробуйте позже");
                            $scope.refreshCaptcha();
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }

                /**
                 * Инициализация шаблона
                 */
                function _initTemplate() {
                    var currentState = $state.current.name;

                    if (currentState === 'feedback') {
                        $scope.ctrl.templateUrl = 'app/components/feedbackForm/views/feedback.html';
                    } else if (currentState === 'contacts') {
                        $scope.ctrl.templateUrl = 'app/components/feedbackForm/views/contacts.html';
                    } else if (currentState === 'kinoandpravo') {
                        $scope.ctrl.templateUrl = 'app/components/feedbackForm/views/kinoandpravo.html';
                    } else if (currentState === 'becomeCustomer') {
                        $scope.ctrl.templateUrl = 'app/components/feedbackForm/views/becomeCustomer.html';
                    } else if (['guarantees', 'raiffeisen', 'lokobg'].includes(currentState)) {
                        $scope.ctrl.templateUrl = 'app/components/feedbackForm/views/guarantees.html';
                    } else if (['bankGuaranteeAppCreate', 'bankGuaranteeAppOpen', 'bankGuaranteeAppVTBCreate', 'bankGuaranteeAppVTBOpen'].includes(currentState)) {
                        $scope.ctrl.templateUrl = 'app/components/feedbackForm/views/createBankGuaranteeApp.html';
                    } else if (currentState === 'feedbackSent') {
                        $scope.ctrl.templateUrl = 'app/components/feedbackForm/views/feedbackSent.html';
                    }
                }

                this.$onInit = function () {
                    _initTemplate();
                    $scope.defaultTheme = $stateParams.theme;
                    $scope.content = {};
                    $scope.user = authSrv.getUser();
                    if ($scope.user.firstName) {
                        $scope.content.name = $scope.user.lastName + ' ' + $scope.user.firstName + ' ' + $scope.user.middleName;
                        $scope.content.email = $scope.user.email ? $scope.user.email : '';
                        $scope.content.phone = $scope.user.phone ? $scope.user.phone : '';
                    }
                    $scope.bankName = $stateParams.bank ? $scope.banks[$stateParams.bank] : $scope.banks.absolut;
                    $scope.form = {};
                    $scope.status = {};
                    $scope.isChecked = true;
                    $scope.captcha = {};
                    $scope.submitted = false;
                    $scope.alertId = 'dismiss';
                    $scope.dialog = {};
                    $scope.refreshCaptcha();
                    $scope.addAttach = 'show';
                }
            }]
    });
