/**
 * @author sergeyu
 */
angular.module('uetp')
    .component('counter', {
        templateUrl: 'app/components/systemMessage/counter.html',
        bindings: {
            counter: '@',
            refresh: '@'
        },
        controller: ['$scope', '$http', '$interval', 'httpSrv', '$rootScope', 'countSrv',
            function ($scope, $http, $interval, httpSrv, $rootScope, countSrv) {

                var ctrl = this;
                var intervalPromise;

                this.$onInit = function () {

                    $rootScope.$on('UPDATE:COUNTER', function () {
                        getCounter()
                    });

                    getCounter();

                    intervalPromise = $interval(function () {
                        getCounter();
                    }, ctrl.refresh);

                    $scope.$on('$destroy', function () {
                        $interval.cancel(intervalPromise);
                    });

                };

                function getCounter() {
                    httpSrv.http({
                        method: 'GET', ignoreLoadingBar: true,
                        url: '/message/api/v2/countUnread'
                    }).then(function successCallback(response) {
                        if (response.status === 401 || response.status === -1) {
                            $interval.cancel(intervalPromise);
                        } else {
                            if (response.data.success) {
                                $scope.counters = response.data.result;
                                countSrv.setCounters($scope.counters);
                            }
                        }
                    }, function errorCallback() {
                        $interval.cancel(intervalPromise);
                    });
                }
            }]
    })
    .service('countSrv', [function () {
            var service = this;
            service.counters = {
                'in':0,
                'system': 0,
                'pn':0
            };
            service.setCounters = function (counts) {
                service.counters.pn = counts.pn;
                service.counters['in'] = counts['in'];
                service.counters.system = counts.system;
            };
            service.getCounters = function () {
                return service.counters;
            };
    }]);
