/**
 * @author: sergeyu
 */
angular.module('uetp')
    .component('bgAgentEditor', {
        bindings: {
            agentData: '<'
        },
        templateUrl: 'app/components/bgAgent/bgAgent/agentOrg/agentEdit/agentEditor.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'bgAgentSrv', 'profileSrv', 'authSrv', 'desktopSrv', 'dictionarySrv','alertSrv','needConfirmSrv',
            function ($scope, $rootScope, $state, $stateParams, bgAgentSrv, profileSrv, authSrv, desktopSrv, dictionarySrv, alertSrv,needConfirmSrv) {
                $scope.saveNewAgent = saveNewAgentFn;
                $scope.setActivePanel = setActivePanelFn;
                $scope.goBack = goBackFn;
                $scope.cancel = cancelFn;
                $scope.resetLoginPassword = resetLoginPasswordFn;
                $scope.analyticAccessChange = analyticAccessChangeFn;
                $scope.onPersonalDataChanged = onPersonalDataChangedFn;
                var ctrl = this;
                $scope.ctrl = ctrl;
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.organizer = bgAgentSrv.getOrganizatorInfo();
                    $scope.modelViewAgent = {isEdit: false};
                    $scope.isReadOnly = isReadOnlyFn;
                    $scope.agent = {};
                    $scope.agentSaved = {};
                    $scope.model = {
                        showPanel: 'requisites',
                        dataPanelCustomer: {},
                        dataPanelOrganizer: {},
                        dataPanelAnalyticReports: {},
                        analyticAccess: false
                    };
                    $scope.showDragPanel = false;
                    $scope.form = {agentForm: {}};
                    $scope.agentSaveError = null;
                    $scope.roleList = dictionarySrv.getStaticDictByType('roles')['agent'];
                    $scope.agent.userRole = "bg_agent_user";
                    if (ctrl.agentData) {
                        $scope.modelViewAgent.isEdit = true;
                        if (ctrl.agentData.data.success) {
                            $scope.agent = ctrl.agentData.data.result;
                            $scope.agentSaved = angular.copy($scope.agent);
                            $scope.getDataDropPanels();
                        }
                    }
                    $scope.changedModel = false;
                };

                function _initModelView() {
                    $scope.modelViewAgent.isEdit = true;
                    profileSrv.getAgentById($stateParams.id).then(function (response) {
                        if (response.data.success) {
                            $scope.agent = response.data.result;
                            $scope.getDataDropPanels();
                            if ($stateParams.successSave) {
                                $scope.alertObjOrgAgentEditor = alertSrv.getAlertObj(response,'Сохранение произведено успешно!');
                            }
                        }
                    })
                }

                $scope.$watch("agent", function(){

                    setTimeout(function () {
                        if($scope.agent.email) {
                            $scope.changedModel = true;
                        }
                        $scope.message = document.querySelectorAll('#emailAgent')[0].title;
                        if ($scope.message && ($scope.form.agentForm.email.length || $scope.agent.email)) {
                            $scope.alertObjProfileAgentEditEmail = alertSrv.getErrorMsg($scope.message);
                        }
                    }, 500);
                }, true);
                /**
                 * Добавления нового представителя организации
                 */
                function saveNewAgentFn() {
                    if (!$scope.form.agentForm.$valid) {
                        $scope.alertObjOrgAgentEditor = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');
                        return;
                    }
                    if ($scope.agent.middleName === undefined || $scope.agent.middleName === "undefined") {
                        $scope.agent.middleName = '';
                    }
                    bgAgentSrv.saveNewAgent($scope.organizer.id, $scope.agent).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.form.agentForm.$setPristine();
                            if (!$scope.agent.id) {
                                $scope.agent.id = response.data.result;
                                $state.go('openBgAgentOrg', {id: $scope.agent.id, successSave: true},{location :'replace'});
                            } else{
                                $scope.agentSaved = $scope.agent;
                            }
                        }
                        $scope.alertObjOrgAgentEditor = alertSrv.getAlertObj(response,'Сохранение произведено успешно!');
                    }, function errorCallback(response) {
                        $scope.alertObjOrgAgentEditor = alertSrv.getAlertObj(response);
                        console.log(response);
                    });
                }

                function isReadOnlyFn() {
                    return authSrv.getAuth().userRole.some(r => ["bg_agent_user", 'operator_support_ro'].includes(r));
                }

                $scope.saveCustomer = function (data) {
                    return bgAgentSrv.saveUserCustomer($scope.agent.id, data);
                };
                $scope.saveOrganizer = function (data) {
                    return bgAgentSrv.saveUserOrganizer($scope.agent.id, data);
                };
                $scope.saveAnalyticReport = function (res) {
                    var data={
                        assignedAnalyticReports: res,
                        analyticAccess: true
                    };
                    return bgAgentSrv.saveUserAnalyticReport($scope.agent.id, data);
                };
                $scope.saveAnalyticReportNoAccess = function () {
                    var data={
                        assignedAnalyticReports: [],
                        analyticAccess: false
                    };
                    bgAgentSrv.saveUserAnalyticReport($scope.agent.id, data).then(function (response) {
                         $scope.alertObjAccessAgentEdit = alertSrv.getAlertObj(response,'Сохранение произведено успешно!');
                    }, function (response) {
                         $scope.alertObjAccessAgentEdit = alertSrv.getAlertObj(response);
                    });
                };
                $scope.getDataDropPanels = function () {
                    bgAgentSrv.getDataUserCustomer($scope.agent.id).then(function (response) {
                        if (response.data.success) {
                            $scope.model.dataPanelCustomer = {
                                dataLeft: response.data.result.allCustomer,
                                dataRight: response.data.result.assignedCustomer
                            };
                            bgAgentSrv.getDataOrganizer($scope.agent.id).then(function (response) {
                                if (response.data.success) {
                                    $scope.model.dataPanelOrganizer = {
                                        dataLeft: response.data.result.allOrganizer,
                                        dataRight: response.data.result.assignedOrganizer
                                    };
                                    bgAgentSrv.getDataAnalyticReports($scope.agent.id).then(function (response) {
                                        if (response.data.success) {
                                            $scope.model.dataPanelAnalyticReports = {
                                                dataLeft: response.data.result.allAnalyticReports,
                                                dataRight: response.data.result.assignedAnalyticReports
                                            };
                                            $scope.model.analyticAccess=response.data.result.analyticAccess;
                                            $scope.showDragPanel = true;
                                        }
                                    })
                                }
                            })
                        }
                    });
                };

                function analyticAccessChangeFn($event){
                    var isChecked=$event.target.checked;
                    $scope.model.analyticAccess = isChecked;
                }
                /**
                 * Выставление активной вкладки
                 * @param type - тип вкладки
                 */
                function setActivePanelFn(type) {
                    $scope.model.showPanel = type;
                }
                $scope.onSizeError = function (files) {
                    $scope.alertObjOrgAgentEditorSert = alertSrv.getErrorMsg("Сертификат " + files[0].name + " не добавлен. Превышен максимально разрешенный размер файла (30МБ)!");
                };
                $scope.onFileNameError = function (files) {
                    $scope.alertObjOrgAgentEditorSert = alertSrv.getErrorMsg("Документ " + files[0].name + " не добавлен. Добавление файлов с расширением *.exe запрещено!");
                };
                $scope.uploadFile = function (response) {
                    if (response.data.success) {
                        var result = response.data.result,
                            arr = result.name.split(' '),
                            prev = angular.copy($scope.agent);
                        $scope.agent = {
                            firstName: arr[1],
                            lastName: arr[0],
                            middleName: arr.length === 3 ? arr[2] : '',
                            certId: response.data.message,
                            userRole: "bg_agent_user"
                        };
                        if ($scope.modelViewAgent.isEdit === false) {
                            $scope.agent.email = result.email;
                        } else {
                            $scope.agent.email = prev.email;
                        }
                        $scope.alertObjOrgAgentEditorSert = alertSrv.getAlertObj(response,'Сертификат добавлен!');
                    } else {
                        $scope.alertObjOrgAgentEditorSert = alertSrv.getAlertObj(response);
                    }
                };
                function goBackFn() {
                    history.back();
                }
                function cancelFn() {
                    if($scope.form.agentForm.$dirty){
                        needConfirmSrv.call($scope.goBack,[],'Внесенные изменения сохранены не будут');
                    }else{
                        $scope.goBack();
                    }
                }

                function resetLoginPasswordFn(user) {
                    if(user == undefined){
                        return;
                    }
                    profileSrv.resetLoginPassword(user).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.alertObjOrgAgentEditor = alertSrv.getAlertObj(response, response.data.message);
                            _initModelView();
                        }
                    });
                }

                function onPersonalDataChangedFn(){
                    $scope.agent.consentProcessingDate = null;
                    $scope.agent.consentDisseminationDate = null;
                }

            }]
    });

