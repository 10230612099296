angular.module('uetp').service('newsletterCreateSrv', ['$http', '$rootScope', 'dialogSrv', 'httpSrv',
    function ($http, $rootScope, dialogSrv, httpSrv) {
        this.deleteItem = deleteItemFn;
        this.saveNewsLetter = saveNewsLetterFn;
        this.publicationNewsletter = publicationNewsletterFn;
        // this.publicationNews = publicationNewsFn;
        this.loadNewslettersItem = loadNewslettersItemFn;
        this.deleteAttach = deleteAttachFn;
        this.getOrganizersList = getOrganizersListFn;
        this.getParticipantsList = getParticipantsListFn;
        this.getParticipantsListRecursion = getParticipantsListRecursionFn;
        this.getEmailListUserForOrganizations = getEmailListUserForOrganizationsFn;
        this.getAllOrganizationsInfo = getAllOrganizationsInfoFn;
        this.paginator = {
            itemsPerPage: 20,
            page: 0,
            search: '',
            searchByField: {}
        };

        function getPagination() {
            return {
                itemsPerPage: 20,
                page: 0,
                search: '',
                searchByField: {}
            };
        }

        function deleteItemFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/message/api/v2/delivery/remove/' + id
            })
        }

        function saveNewsLetterFn(newsletter) {
            return httpSrv.http({
                url: '/message/api/v2/delivery/store',
                data: newsletter
            })
        }

        function publicationNewsletterFn(id, mails) {
            if (!mails) {
                mails = {};
            }
            return httpSrv.http({
                method: 'POST',
                url: '/message/api/v2/delivery/' + id + '/sent',
                data: mails
            })
        }

        function getOrganizersListFn() {
            this.paginator.itemsPerPage = 99999999;
            return httpSrv.http({
                method: 'POST',
                url: '/admin/obtainOrgList/organizer',
                data: this.paginator
            })
        }

        function getParticipantsListFn(param) {
            this.paginator.itemsPerPage = 99999999;
            this.paginator.searchByField = param;
            return httpSrv.http({
                method: 'POST',
                url: '/admin/obtainOrgList/simpleParticipant',
                data: this.paginator
            })
        }

        function getEmailListUserForOrganizationsFn(recipients) {
            return httpSrv.http({
                method: 'POST',
                url: '/admin/getEmailUsers',
                data: recipients
            })
        }

        function getAllOrganizationsInfoFn(groups) {
            return httpSrv.http({
                method: 'POST',
                url: '/admin/getAllOrganizationsInfo',
                data: groups
            })
        }

        function getParticipantsListPaginationFn(param, pagination) {
            var paginator = getPagination();
            paginator.itemsPerPage = pagination.itemsPerPage;
            paginator.page = pagination.page;
            paginator.searchByField = param;
            return httpSrv.http({
                method: 'POST',
                url: '/admin/obtainOrgList/simpleParticipant',
                data: paginator
            })
        }

        function getParticipantsListRecursionFn(param, pagination) {
            var responseReturn = null;
            return new Promise(function (resolve, reject) {
                var total = 0;
                var funRecursion = function (param, pagination) {
                    getParticipantsListPaginationFn(param, pagination).then(function (response) {
                        if (response.data.success) {
                            if (responseReturn === null) {

                                responseReturn = response;
                                total = response.data.total;
                                if (total > pagination.itemsPerPage) {
                                    pagination.page = pagination.page + 1;
                                    funRecursion(param, pagination)
                                } else {
                                    resolve(responseReturn)
                                }
                            } else {
                                if (response.data.items.length > 0) {
                                    Array.prototype.push.apply(responseReturn.data.items, response.data.items);
                                    pagination.page = pagination.page + 1;
                                    funRecursion(param, pagination)
                                } else {
                                    resolve(responseReturn)
                                }
                            }
                        } else {
                            resolve(response)
                        }
                    }, function (reason) {
                        reject(reason)
                    })
                };
                funRecursion(param, pagination)

            })
        }

        function loadNewslettersItemFn(id) {

            return httpSrv.http({
                method: 'GET',
                url: '/message/api/v2/delivery/' + id
            })
        }

        function deleteAttachFn(fileId) {
            return httpSrv.http({
                method: 'GET',
                url: '/message/api/v2/delivery/removeAttachment/' + fileId
            })
        }
    }]);