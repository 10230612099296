/**
 * Created by dmitrye on 07.12.2017.
 */
angular.module('uetp').controller('restorePasswordCtrl', ['$scope', '$rootScope', '$state', '$stateParams', 'httpSrv', 'dialogSrv','alertSrv',
    function ($scope, $rootScope, $state, $stateParams, httpSrv, dialogService,alertSrv) {

        $scope.restore = restoreFn;        // Отправить запрос на изменение пароля
        $scope.changePass = changePassFn;     // Изменить пароль

        $scope.reset = resetFn;          // Сброс капчи
        $scope.initCaptcha = initCaptchaFn;    // Инициализация капчи
        $scope.refreshCaptcha = refreshCaptchaFn; // Обновление капчи

        init();


        if ($stateParams.id) {
            restoredPwdChange();
        } else {
            initCaptchaFn();
        }
        function init(){
            $scope.form = {};
        }

        function resetFn() {
            $scope.captcha = {};
        }

        function initCaptchaFn() {
            $scope.captcha = {};
            $scope.captcha.url = "/rest/auth/captcha.png?r=" + Math.random();
        }

        function refreshCaptchaFn() {
            $scope.captcha.url = "/rest/auth/captcha.png?r=" + Math.random();
        }

        function restoreFn(user) {
            if (!user || !user.email || !user.captcha) {
                dialogService.showDialogMsg($scope, "Заполните, пожалуйста, все поля.");
                return;
            }
            httpSrv.http({
                url: '/auth/user/restorePassword',
                data: {param: user.email, message: user.captcha},
                block: true
            }).then(function successCallback(response) {
                if (!response.data.success) {
                    dialogService.showDialogMsgHtml($scope, response.data.message);
                } else {
                    $scope.alertObjRestorePassword = alertSrv.getSuccessMsg('На указанный адрес электронной почты направлено письмо с инструкцией по восстановлению пароля.');
                }
                refreshCaptchaFn();
            }, function errorCallback(response) {
                dialogService.showDialogMsg($scope, response);
                refreshCaptchaFn();
            });
        }

        // Изменение пароля
        function changePassFn(userPassword) {
            if (!checkPassword(userPassword.newPassword)) {
                $scope.alertObjChangePassword = alertSrv.getErrorMsgWithBtn('Введенный пароль не соответствует описанным выше требованиям!');
                return;
            }
            if (userPassword.newPassword != userPassword.newPassword1) {
                $scope.alertObjChangePassword = alertSrv.getErrorMsgWithBtn('Подтверждение пароля введено неверно');
                return;
            }
            httpSrv.http({
                url: '/auth/user/changePassword/',
                data: userPassword,
                block: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.alertObjChangePassword = alertSrv.getSuccessMsg('Ваш пароль изменен!');
                    $scope.blockChangePwd = true;
                    setTimeout(function () {
                      	if(!$scope.status){
                    		$scope.status = {};
                    	}
                        $scope.status.isOpenLogin = true;
                    }, 1000);
                } else {
                    $scope.alertObjChangePassword = alertSrv.getErrorMsg(response.data.message);
                }
            }, function errorCallback(response) {
                console.log(response)
            });

            function checkPassword(pwd) {
                return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9\/\!\?\.\,\~\'\^\*\_\+\=\;\:\?\|\@\#\$\&\%\_\\\-\{\}\(\)\[\]]{8,}$/.test(pwd);
            }
        }

        // Проверка ссылки на актуальность
        function restoredPwdChange() {
            var uid = $stateParams.id;
            if (uid) {
                $scope.menu = "changePassword";
                $scope.userPassword = {
                    id: uid
                };
                httpSrv.http({
                    method: 'GET',
                    url: '/auth/user/getUserLinkStatus/' + uid
                }).then(function successCallback(response) {
                    $scope.blockChangePwd = !response.data.success;
                    if ($scope.blockChangePwd) {
                        dialogService.showDialogMsg($scope, response.data.message);
                    }
                }, function errorCallback(response) {
                    console.log(response)
                });
            } else {
                $scope.blockChangePwd = true;
                dialogService.showDialogMsg($scope, "Пользователь не найден!");
            }
        }

    }]);