angular.module('uetp').constant('socAtcomReport', {
    title    : 'Списания по договорам в адрес оператора "АТКОМ"',
    access   : ['admincab', 'accountant'],
    url      : '/auth/tariff/downloadSocAtcomReport',
    template : 'app/components/reports/types/socAtcomReport.html',
    data     : {
    	endDate   : null,
		startDate : null
    },
    toMSK    : ['startDate', 'endDate'],
	fileName : function ($filter, data) {
		var date = "";
        if(data.startDate || data.endDate) {
            date = (data.startDate ? '_' + $filter('date')(data.startDate, 'dd.MM.yyyy') : '') + (data.endDate ? '_' + $filter('date')(data.endDate, 'dd.MM.yyyy') : '');
        }else{
            date = '_' + $filter('date')(getServerTimeMsk(), 'dd.MM.yyyy');
        }
		return "Списания по договорам в адрес оператора АТКОМ" + date + ".xlsx";
	},

});