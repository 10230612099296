/**
 * @author: sergeyu
 * Организатор
 */
angular.module('uetp')
    .component('bgAgent', {
        templateUrl: 'app/components/bgAgent/bgAgent/bgAgent.html',
        bindings: {
            orgData: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'bgAgentSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, bgAgentSrv) {
                var ctrl = this;
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.model = {
                        showPanel: 'reqBgAgent',
                        isEdit: false
                    };
                    _initShowPanel();
                    _refreshView();
                    if (ctrl.orgData) {
                        $scope.model.isEdit = true;
                        bgAgentSrv.setOrganizatorInfo(ctrl.orgData.data.result);
                        if(['admincab','support'].includes($scope.cabType()) && ctrl.orgData.data.result.shortName)
                            $scope.shortName = '(' +ctrl.orgData.data.result.shortName +')';
                    } else {
                        $scope.model.organizer = bgAgentSrv.setOrganizatorInfo({})
                    }
                };
                $scope.setActivePanel = setActivePanelFn;


                /**
                 * Инициализация панели по типу ЛК
                 * @private
                 */
                function _initShowPanel() {
                    var cabType = desktopSrv.getCabType();
                    if (cabType === 'admincab') {
                    }

                }

                /**
                 * Обновление состояния при перегрузке страницы по F5 или другим способом
                 * @private
                 */
                function _refreshView() {
                    var activePanel = $stateParams.setActivePanel;
                    if (activePanel && $scope.model.showPanel !== activePanel) {
                        setActivePanelFn(activePanel);
                    }
                }

                /**
                 * Выставление активной вкладки
                 * @param type - тип вкладки
                 */
                function setActivePanelFn(type) {
                    $scope.model.showPanel = type;
                }
                $rootScope.$on('setBgAgentViewName', function(event,type){
                    $scope.shortName = '(' + type + ')';
                });
            }]
    });

