/**
 * Страница "Обновить план закупок"
 */
angular.module('uetp')
    .component('purchasePlanUpdate', {
        templateUrl: 'app/components/purchasePlanUpdate/purchasePlanUpdate.html',
        bindings: {
        },
        controller: ['$scope', '$state', '$stateParams', 'propertiesService', 'dictionarySrv', 'dialogSrv', 'authSrv', 'purchasePlanUpdateSrv', 'alertSrv', 'loggerUiSrv',
            function ($scope, $state, $stateParams, propertiesService, dictionarySrv, dialogSrv, authSrv, purchasePlanUpdateSrv, alertSrv, loggerUiSrv) {
                $scope.model = {};
                
                $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                $scope.$stateParams = $stateParams;
                $scope.loadPlanNumbers = loadPlanNumbersFn;
                $scope.loadCustomers = loadCustomersFn;
                $scope.changeCustomer = changeCustomerFn;
                $scope.update = updateFn;
                $scope.goBack = goBackFn;
                $scope.refreshDict = refreshDictFn;

                this.$onInit = function () {
                    if(($state?.current?.startMth || $state?.current?.name)!=='purchasePlanUpdateAdmin')
                        $scope.loadCustomers(($state?.current?.startMth || $state?.current?.name)==='purchasePlanUpdateAdmin');
                }
                
                $scope.initSelect = (select) => {
                    select.$select.maskType='int10';
                    select.$select.keypresshandler = (e) =>{
                        const keyCode = e.keyCode || e.which;
                        if (!(keyCode >= 48 && keyCode <= 57)) {
                            e.preventDefault();
                        }
                    }
                }

                function refreshDictFn(object, value, refreshScrolling,needToAdd) {
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling,null,needToAdd);
                }

                function loadPlanNumbersFn(inn) {
                    propertiesService.getProperty("otherProcedures.url").then(function(host){
	                    purchasePlanUpdateSrv.loadPlanNumbers(inn, host, $scope).then(function (response) {
	                        if (response.data.success) {
	                            $scope.planNumbers = response.data.items;
	                        }else{
	                            $scope.alertObj = alertSrv.getErrorMsg("Ошибка получения данных с " + host);
                           }
	                    }, function (reason) {
	                        $scope.alertObj = alertSrv.getAlertObj(reason);
	                    })
                    })
                }
                
                function loadCustomersFn() {
                    if(($state?.current?.startMth || $state?.current?.name)==='purchasePlanUpdateAdmin')
                    {
                        $scope.refreshDict('headDzoCustomers', null, true);
                    } else {
                        purchasePlanUpdateSrv.loadCustomers().then(function (response) {
                            if (response.data.success) {
                                $scope.customers = response.data.result;
                                if ($scope.customers.length == 1) {
                                    $scope.model.customer = $scope.customers[0];
                                    $scope.loadPlanNumbers($scope.model.customer.inn);
                                }
                            } else {
                                $scope.alertObj = alertSrv.getErrorMsg("Ошибка получения данных");
                            }
                        }, function (reason) {
                            $scope.alertObj = alertSrv.getAlertObj(reason);
                        })
                    }
                }
                
                function changeCustomerFn(selectObj) {
	               delete $scope.model.planNumber;
                   $scope.planNumbers = [];
                   if(selectObj.$select.selected && selectObj.$select.selected.inn){
                       $scope.loadPlanNumbers(selectObj.$select.selected.inn);
                   }
                }

                function updateFn(){
	                if(!$scope.model.planNumber){
		              console.log('Отсутсвует номер плана закупок!');
                      return;
	                }
	                if(!$scope.model.customer || !$scope.model.customer.id){
		              console.log('Отсутсвует заказчик!');
                      return;
	                }
                    $scope.submitted = true;
                    $scope.alertWarn = true;
                    propertiesService.getProperty("otherProcedures.url").then(function(host){
	                    purchasePlanUpdateSrv.updatePlan($scope.model.planNumber, $scope.model.customer.id, $scope.model.customer.eisKis, host, $scope).then(function (response) {
	                        if (response.data.status == 200) {
		                        $scope.alertObj = alertSrv.getSuccessMsg(response.data.message||'План закупок из ЕИС выложен для обработки');
                                $scope.alertWarn = false;                         
                                setTimeout(function () {
                                   goBackFn();
                                }, 3000);
                                logging($scope.model.customer.id, $scope.model.planNumber, true);
	                        }else{
		                        $scope.submitted = false;
                                $scope.alertWarn = false;
	                            $scope.alertObj = alertSrv.getErrorMsg(response.data.message||'Ошибка обновления плана закупок');
                                logging($scope.model.customer.id, $scope.model.planNumber, false);
                                console.log(response.data);
                            }
	                    }, function (reason) {
		                    $scope.submitted = false;
                            $scope.alertWarn = false;
	                        $scope.alertObj = alertSrv.getAlertObj(reason);
                            logging($scope.model.customer.id, $scope.model.planNumber, false);
	                    })
                    })
                }

                function goBackFn(){
                   history.back();
                }

                function logging(targetId, info, success){
                    loggerUiSrv.sendLog('PURCHASE_PLAN_UPDATE',{
                        targetId: targetId,
                        info: info,
                        success: !success
                    });
                }
                $scope.setUnplannedNumber = (select) => {
                    if(select.$select.search.length===10 && !isNaN(select.$select.search)) {
                        $scope.model.planNumber = select.$select.search;
                    } else {
                        delete $scope.model.planNumber;
                    }
                }
            }]
    });
