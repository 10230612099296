angular.module('uetp').component('mailingLists', {
	templateUrl: 'app/components/mailingLists/mailingLists.html',
	controller: [
		'$scope', '$filter',
		'constants', 'desktopSrv', 'httpSrv','organizatorSrv','dictionarySrv','authSrv','timeout','alertSrv',
		function (
			$scope, $filter,
			constants, desktopSrv, httpSrv, organizatorSrv, dictionarySrv, authSrv, timeout,alertSrv //Списока адресатов
		) {
			$scope.model = {listsData: {},activeList:{participants:[]},editEntry:{}};
			$scope.showPanel = false;
			this.$onInit = function () {
				$scope.paginator = {
					itemsPerPage: 20,
					page: 0
				};
				$scope.participantsPaginator = {
					itemsPerPage: 20,
					page: 0
				};
				$scope.organizer = organizatorSrv.getOrganizatorInfo();
				$scope.getDataMailingLists();
			};

			$scope.getDataMailingLists = function () {
				organizatorSrv.getMailingLists(angular.copy($scope.paginator)).then(function successCallback(response) {
					if (response.data.success) {
						$scope.setActiveList({participants:[]});
						$scope.model.listsData=response.data;
						$scope.model.listsData.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
					}
				}, function errorCallback(response) {
					console.log(response);
				});
			};
			$scope.openList = function (listData) {
				organizatorSrv.getMailingListById(listData.id).then(function successCallback(response) {
					if (response.data.success) {
						$scope.setActiveList(response.data.result);
					}
				}, function errorCallback(response) {
					console.log(response);
				});
			};

            $scope.addNewList = function () {
				$scope.model.listsData.newEntry={};
				setTimeout(function () {
					window.document.getElementById('newMailingListInput').focus();
				}, 100);
            };

			$scope.deleteNewList = function () {
				delete $scope.model.listsData.newEntry;
			};

			$scope.saveNewList = function () {
				var title=$scope.model.listsData.newEntry.title;
				if(title&&title.length>0){
					organizatorSrv.saveMailingList(title).then(function successCallback(response) {
						if (response.data.success) {
							var list=response.data.result;
							$scope.deleteNewList();
							$scope.model.listsData.items.unshift(list);
							$scope.setActiveList(list);
							$scope.model.listsData.total=$scope.model.listsData.total+1;
							$scope.alertObjMailingLists = alertSrv.getAlertObj(response,'Сохранение произведено успешно!');
							$scope.model.listsData.totalPages = Math.ceil($scope.model.listsData.total / $scope.paginator.itemsPerPage);

						} else {
							$scope.alertObjMailingLists = alertSrv.getAlertObj(response);
						}
					}, function errorCallback(response) {
						console.log(response);
					});
				}
			};


			$scope.editTitle = function (index,data) {
				$scope.model.listsData.editEntry={
					id:data.id,
					title:data.title
				};
				setTimeout(function () {
					window.document.getElementById('editMailingListTitle_' + index).focus();
				}, 100);
			};

			$scope.cancelEditTitle = function () {
				$scope.model.listsData.editEntry={};
			};

			$scope.renameList = function (data,event) {
				//blur срабатывает раньше click, пришлось переделать на mousedown
				if(event&&event.which !== 1)
					return;
				var newTitle=$scope.model.listsData.editEntry.title;
				organizatorSrv.renameMailingList($scope.model.listsData.editEntry.id,$scope.model.listsData.editEntry.title).then(function successCallback(response) {
					if (response.data.success) {
						data.title=newTitle;
						if(data.id==$scope.model.activeList.id)
							$scope.model.activeList.title=newTitle;
						$scope.model.listsData.editEntry={};
					} else {
						$scope.alertObjMailingLists = alertSrv.getAlertObj(response);
					}
				}, function errorCallback(response) {
					console.log(response);
				});
			};

			$scope.deleteList = function (index,data) {
				organizatorSrv.deleteMailingList(data.id).then(function successCallback(response) {
					if (response.data.success) {
						if(data.id==$scope.model.activeList.id)
							$scope.setActiveList({participants:[]});
						$scope.model.listsData.items.splice(index, 1);
						$scope.model.listsData.total=$scope.model.listsData.total-1;
						$scope.alertObjMailingLists = alertSrv.getAlertObj(response,'Удаление произведено успешно!');
						$scope.model.listsData.totalPages = Math.ceil($scope.model.listsData.total / $scope.paginator.itemsPerPage);
						$scope.deleteNewList();
					} else {
						$scope.alertObjMailingLists = alertSrv.getAlertObj(response);
					}
				}, function errorCallback(response) {
					console.log(response);
				});
			};

			$scope.addNewParticipant = function () {
				$scope.model.activeList.newEntry={};
				setTimeout(function () {
					window.document.getElementById('newParticipantMailingListInput').focus();
				}, 100);
			};

			$scope.deleteNewParticipant = function () {
				delete $scope.model.activeList.newEntry;
			};

			$scope.saveParticipant = function () {
				var inn=$scope.model.activeList.newEntry.inn;
				if(inn&&inn.length>0){
					var innError=$scope.checkInn(inn);
					if(innError!=null){
						$scope.alertObjMailingLists = alertSrv.getErrorMsg(innError);
						return;
					}
					organizatorSrv.addParticipantToMailingList($scope.model.activeList.id,inn).then(function successCallback(response) {
						if (response.data.success) {
							$scope.model.activeList.participants.unshift({
								id:response.data.result,
								name:inn
							});
							$scope.alertObjMailingLists = alertSrv.getAlertObj(response,'Сохранение произведено успешно!');
							$scope.model.activeList.totalPages = Math.ceil($scope.model.activeList.participants.length / $scope.participantsPaginator.itemsPerPage);
							$scope.deleteNewParticipant()
						} else {
							$scope.alertObjMailingLists = alertSrv.getAlertObj(response);
							$scope.model.activeList.newEntry.inn="";
						}
					}, function errorCallback(response) {
						console.log(response);
					});
				}
			};

			$scope.checkInn = function (inn) {
				var error=null;
				if(/^0+$/.test(inn)){
					error="Некорректно указан ИНН/УИН";
				}
				else
					angular.forEach($scope.model.activeList.participants, function (item) {
						if(inn === item.name ){
							error = 'Участник с таким ИНН/УИН уже добавлен в список.';
							
						}
					});
				return error;
			};

			$scope.deleteParticipant = function (index,data) {
				organizatorSrv.deleteParticipantFromList($scope.model.activeList.id,data.id,data.name).then(function successCallback(response) {
					if (response.data.success) {
						$scope.model.activeList.participants.splice(index, 1);
						$scope.alertObjMailingLists = alertSrv.getAlertObj(response,'Удаление произведено успешно!');
						$scope.model.activeList.totalPages = Math.ceil($scope.model.activeList.participants.length / $scope.participantsPaginator.itemsPerPage);
						$scope.deleteNewParticipant();
					} else {
						$scope.alertObjMailingLists = alertSrv.getAlertObj(response);
					}
				}, function errorCallback(response) {
					console.log(response);
				});
			};

			$scope.setActiveList=function(activeListData){
				$scope.model.activeList=activeListData;
				$scope.model.activeList.participants=$scope.model.activeList.participants||[];
				$scope.model.activeList.totalPages = Math.ceil($scope.model.activeList.participants.length / $scope.participantsPaginator.itemsPerPage);
				$scope.participantsPaginator.page=0;
			};

			/**
			 * Поиск по спискам КО
			 */
			$scope.inputSearchList = function (reset) {
				$scope.getDataMailingLists();
				if (reset) {
					$scope.paginator.page = 0;
				}
			};
		}
	]
});