/**
 * @author: Belyaev Andrey
 * Компонент Ускоренная аккредитация
 */
angular.module('uetp')
    .component('accelAccred', {
        bindings: {
            tariffsData: '<'
        },
        templateUrl:['constants', function (constants) {
            return 'app/components/tariffsServices/accelAccred/accelAccred_' + constants.configProject.type + '.html'
        }],
        controller: ['$scope', 'proceduresSrv', 'authSrv', '$state',
            function ($scope, proceduresSrv, authSrv, $state) {
                const ctrl = this;
                $scope.ctrl = this;
                $scope.ndsText = '';
                this.$onInit = function () {
                    if (ctrl.tariffsData && ctrl.tariffsData.data.success) {
                          initNdsText(ctrl.tariffsData.data.items);
                    }
                }

                function initNdsText(tariffs){
                    if(tariffs.length == 1){
                        $scope.ndsText = tariffs[0].code == 'QS' ? 'НДС не облагается в связи с применением упрощенной системы налогообложения п.2 ст.346.11 НК РФ'
                            : 'кроме того НДС по ставке, установленной п. 3 ст. 164 НК РФ';
                    }
                }
                $scope.goAcceleratedAccreditation = () => {
                    authSrv.checkedAuth().then(function (auth) {
                        if (auth.key) {
                            if(auth.orgRole==='participant') {
                                $state.go('profile', {activeProfileTab: 'accreditation'});
                            } else {
                                $state.go('home')
                            }
                        } else {
                            const param = {
                                url: 'profile',
                                activeProfileTab: 'accreditation'
                            };
                            proceduresSrv.openLogin(param)
                        }
                    })
                }

            }
        ]
    });

