/**
 * @author sertakovs
 */
angular.module('uetp').service('statementSrv', ['$state', 'httpSrv',
    function ($state, httpSrv) {
        var service = this;
        service.getStatements = getStatementsFn;

        function getStatementsFn(pagination) {
            return httpSrv.http({
                method: 'POST',
                url: '/accounts/bankstatement/statements',
                data: pagination,
                showCounter: true
            })
        }
    }]);