/**
 * @author dmitrys
 * Вопрос-ответ
 */
angular.module('uetp')
    .component('questionAnswerCreate', {
        templateUrl: 'app/components/help/questionAnswer/questionAnswerCreate.html',
        bindings: {
            bindingsData: '<'
        },
        controller: ['$scope', '$rootScope', 'constants', '$state', '$stateParams', 'questionAnswerSrv', 'httpSrv', 'dialogSrv', 'alertSrv', 'desktopSrv','needConfirmSrv','dictionarySrv',
            function ($scope, $rootScope, constants, $state, $stateParams, questionAnswerSrv, httpSrv, dialogSrv, alertSrv, desktopSrv,needConfirmSrv,dictionarySrv) {
                $scope.save = saveFn;
                $scope.publish = publishFn;
                $scope.downloadFile = httpSrv.downloadFile;
                $scope.cabType = desktopSrv.getCabType;
                $scope.cancel = cancelFn;
                $scope.isReadOnly = isReadOnlyFn;

                $scope.preShow = false;
                function saveFn() {
                    $scope.submitted = true;
                    if (!$scope.form.mainForm.$valid) {
                        $scope.alertObjQuestionAnswer = alertSrv.getErrorMsg('Заполните все обязательные поля!');
                        return;
                    }
                    questionAnswerSrv.save($scope.model).then(function (response) {
                        if (response.data.success) {
                            $scope.submitted = false;
                            $scope.model.id = response.data.result;
                            $scope.model.number = response.data.message;
                            $scope.alertObjQuestionAnswer = alertSrv.getSuccessMsg('Вопрос-ответ сохранен!');
                            if ($scope.form.mainForm) {
                                $scope.form.mainForm.$setPristine();
                            }
                        }
                    });
                    if (document.activeElement) {
                        document.activeElement.blur();
                    }
                }

                function publishFn() {
                    $scope.submitted = true;
                    if (!$scope.form.mainForm.$valid) {
                        $scope.alertObjQuestionAnswer = alertSrv.getErrorMsg('Заполните все обязательные поля!');
                        return;
                    }
                    questionAnswerSrv.publish($scope.model).then(function (response) {
                        if (response.data.success) {
                            $scope.submitted = false;
                            $scope.model.state = 'published';
                            $scope.alertObjQuestionAnswer = alertSrv.getSuccessMsg('Вопрос-ответ опубликован!');
                        }
                    });
                }


                this.$onInit = function () {
                    var ctrl=this;
                    $scope.model = {};
                    $scope.form = {mainForm: ''};
                    if ($scope.form && $scope.form.mainForm) $scope.form.mainForm.$setPristine();
                    if ($scope.form && $scope.form.mainForm) $scope.form.mainForm.$setUntouched();

                    if(ctrl.bindingsData){
                        $scope.model = ctrl.bindingsData.data.result;
                    }
                    attachReset();
                    $scope.addAttach = '';
                    $scope.state = $state.current.name;
                    $scope.idDocTime = new Date().getTime();

                    $scope.maxCountAttachments = 1;
                    dictionarySrv.getDictionaryRemote('CATEGORY_question', false).then(function () {
                        $scope.dictionary = dictionarySrv.getRemoteData();
                    });
                }
                /**
                 * Вложение - возможно необходимо создать компонент?
                 */
                $scope.showFiles = function (files) {
                    $scope.attachItem.name = files[0].name;
                };
                $scope.onSizeError = function (files) {
                    $scope.alertObjAnswerCreateFile = alertSrv.getErrorMsg("Документ " + files[0].name + " не добавлен. Превышен максимально разрешенный размер файла (30МБ)!");
                };
                $scope.onFileNameError = function (files) {
                    $scope.alertObjAnswerCreateFile = alertSrv.getErrorMsg("Документ " + files[0].name + " не добавлен. Добавление файлов с расширением *.exe запрещено!");
                };
                $scope.uploadFile = function (response) {
                    if (!$scope.model.attachments) {
                        $scope.model.attachments = [];
                    }
                    $scope.model.attachments.push({
                        "id": response.data.result.id,
                        "fileType": 0,
                        "name": $scope.attachItem.name,
                        "description": $scope.attachItem.description
                    });
                    attachReset();
                    $scope.addAttach = '';
                    //ie 11 баг снятие фокуса. Иначе не нажат кнопки
                    window.focus();
                    if (document.activeElement) {
                        document.activeElement.blur();
                    }

                    if ($scope.form.mainForm) {
                        $scope.form.mainForm.$setDirty();
                    }
                };
                $scope.deleteAttach = function (file, object) {
                    questionAnswerSrv.deleteAttach(file.id).then(function (response) {
                        if (response.data.success) {
                            var index = object.attachments.indexOf(file);
                            if (index > -1) {
                                object.attachments.splice(index, 1);
                            }
                            if ($scope.form.mainForm) {
                                $scope.form.mainForm.$setDirty();
                            }
                        } else {
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    })
                };

                function attachReset() {
                    $scope.attachItem = {
                        name: '',
                        description: ''
                    };
                }

                $scope.addAttachShow = function () {
                    if ($scope.addAttach !== '') {
                        $scope.addAttach = '';
                    } else {
                        $scope.addAttach = 'show';
                    }
                };

                $scope.getMaxCountAttach = function() {
                    return new Array($scope.maxCountAttachments)
                }
                function goBackFn(){
                    history.back();
                }
                function cancelFn() {
                    if($scope.form.mainForm.$dirty && $scope.model.state != 'published'){
                        needConfirmSrv.call(goBackFn,[],'Внесенные изменения сохранены не будут');
                    }else{
                        goBackFn();
                    }
                }
                function isReadOnlyFn() {
                    return ["support"].indexOf(desktopSrv.getCabType())!=-1;
                }

            }]

    });

