(function(){
	function generateMsg_ORDER_ACTION (number, action){
		return "Вы уверены, что хотите "+action+" заказ №" + number;
	}

	function generateMsg_ORDER_ERROR (action) {
		return "Произошел сбой. Повторите "+action+" заказа";
	}

	angular.module('uetp').constant('systemMessages', {
		generateMsg_MAX_VOLUME : function(index, volume, measure) {
			return "Максимальный объем заказа по позиции "+index+" — "+volume+" "+(measure||'ед')+".<br>Введите значение объема, меньшее или равное товарному запасу";
		},
		generateMsg_MIN_VOLUME : function(index, volume, measure){
			return "Минимальный объем заказа по позиции "+index+" — "+(volume||1)+" "+(measure||'ед')+ ".<br>Введите значение объема, большее или равное минимальному объему заказа"
		},
		generateMsg_MAX_VOLUME_2 : function(count, volume) {
			return `Количество в позиции ${count} больше максимального объема заказа ${volume}.`;
		},
		ORDER_ACTIONS: {
			generateMsg_CONFIRM  : function(number) { return generateMsg_ORDER_ACTION(number, 'подтвердить'); },
			generateMsg_ACCEPT   : function(number) { return generateMsg_ORDER_ACTION(number, 'принять'    ); },
			generateMsg_REJECT   : function(number) { return generateMsg_ORDER_ACTION(number, 'отклонить'  ) + '?<br><br>Укажите причину <span class="red">*</span>'; },
			generateMsg_WITHDRAW : function(number) { return generateMsg_ORDER_ACTION(number, 'отменить'   ); }
		},
		ORDER_ERRORS: {
			generateMsg_STATUS   : function () {return generateMsg_ORDER_ERROR("изменение статуса");},
			generateMsg_ACCEPTED : function () {return generateMsg_ORDER_ERROR("прием");},
			generateMsg_REJECTED : function () {return generateMsg_ORDER_ERROR("отклонение");},
			generateMsg_CONFIRM  : function () {return generateMsg_ORDER_ERROR("подтверждение");}
		},

		DOCUMENT_SUM_MAX   : "Превышена максимально допустимая сумма документа! Сохранение позиции невозможно.",

		//Offer messages
		POS_CHANGED        : "Изменения в позиции не были сохранены. Необходимо сохранить позицию, чтобы избежать потери изменений.",
		INCORRECT_DATETIME : "Некорректно указана дата. Выберите актуальную дату",
		DATETIME_FORMAT    : "Некорректно указан формат даты и времени. Допустимый формат: ДД-ММ-ГГГГ ЧЧ:ММ",
		REQUIRED_FIELDS    : "Не все обязательные поля позиций заполнены",
		OFFER_SAVED        : "Оферта сохранена в статусе \"Черновик\" и доступна для дальнейшей работы с ней в Вашем личном кабинете в разделе Оферты по закупке.",
		OFFER_SAVED_2      : "Предложение сохранено в статусе \"Черновик\" и доступно для дальнейшей работы с ним в Вашем личном кабинете в разделе Мои Предложения.",
		OFFER_PUBLISHED    : "Оферта опубликована и доступна для просмотра в Вашем личном кабинете в разделе Оферты по закупке.",
		OFFER_PUBLISHED_2  : "Предложение опубликовано и доступно для просмотра в Вашем личном кабинете в разделе Мои Предложения.",
		DATA_WONTBE_SAVED  : "Изменения не были сохранены. Сохранить изменения?",
		POS_WONTBE_SAVED   : "Изменения в позиции не были сохранены. Сохранить изменения?",
		POS_WONTBE_SAVED_BTN   : "Изменения в позиции не были сохранены. Сохранить изменения",
		OFFER_NDS_ERROR    : "Для ценового запроса (позиции ценового запроса) с признаком 'НДС не облагается' ('Без НДС') в позиции оферты допустимо указывать только признак 'Без НДС'",
		OFFER_POS_ERROR    : "Цена за единицу (без НДС) не может быть больше цены из позиции ценового запроса",
		OFFER_POS_NDS_ERROR: "Цена за единицу (с НДС) не может быть больше цены из позиции ценового запроса, если в ценовом запросе не установлен признак 'НДС не облагается'",
		PERSONAL_DATA_PROCESSING : "Необходимо подтвердить согласие на обработку персональных данных представителя.",
		PERSONAL_DATA_DISSEMINATION : "Необходимо подтвердить согласие на распространение персональных данных представителя.",
		PERSONAL_DATA_PROCESSING_AND_DISSEMINATION : "Необходимо подтвердить согласия на обработку и на распространение персональных данных представителя.",

		//common
		EMPTY_FIELD: "Заполните все обязательные поля.",
		EMPTY_VOLUME: 'Документ содержит позиции с нулевым количеством. Сохранение невозможно.',
		FIELD_LENGTH_EXCEED: "Превышена допустимая длина значения",

		//Files
		generateMsg_MAX_FILE_SIZE: function (name, maxSize) {
			return "Файл " + name + " не добавлен. Превышен максимально разрешенный размер файла (" + maxSize + ")!";
		},
		generateMsg_INCORRECT_FORMAT: function (name) {
			return "Документ " + name + " не добавлен - неверный тип файла";
		},
		generateMsg_DESCRIPTION_MIN: function (name) {
			return "Описание не должно быть пустым";
		},
		EMPTY_FILE: "Добавление пустого файла невозможно. Выберите другой файл",
		MAX_FILES: "Добавлено максимально допустимое число файлов",
		CONSENT_PROCESSING: "Подтверждаю свое согласие на обработку персональных данных",
		CONSENT_DISSEMINATION: "Подтверждаю свое согласие на распространение своих персональных данных в предоставленном мной объеме"
	});
})();
