/**
 * @author: sergeyu
 *
 * Обучение
 */
angular.module('uetp')
    .component('securingZmo', {
        templateUrl: 'app/components/tariffsServices/securingZmo/securingZmo.html',
        controller: ['$scope', 'constants', 'propertiesService',
            function ($scope, constants, propertiesService) {
                this.$onInit = function () {
                    propertiesService.getProperty(`main.url.${constants.configProject.type==="etp" ? "uetp" : constants.configProject.type}`).then( function(mainUrl){
                        $scope.docsLink = `${mainUrl}/documents`;
                    });

                };
            }
        ]
    });

