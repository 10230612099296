/**
 * cabType и userRole - указываются кабинеты и типы пользователей, для которых доступен документ (all - доступно везде)
 */
angular.module('uetp').constant('videoList', {
    UETP: [
        {name: "Электронные торговые ресурсы АО «Комита»",     file: "https://www.youtube.com/embed/_UofOwwaWCc", cabType: "all", userRole: "all"},
        {name: "О регистрации и аккредитации Участника", file: "https://www.youtube.com/embed/RPKb7C8p6o0", cabType: "all", userRole: "all"},
        {name: "О сервисе «Проверка компании» в ЛК Участника",  file: "https://www.youtube.com/embed/PG-PM7XjVmg", cabType: "all", userRole: "participant_admin,user,unauthorized"},
        {name: "О сервисе «Проверка компании» в ЛК Заказчика",  file: "https://www.youtube.com/embed/JpbDxL1DMNU", cabType: "all", userRole: "organizer_cbz,organizer_user,organizer_admin,unauthorized"},
        {name: "О сервисе безопасности закупок «Анализ цен»",  file: "https://www.youtube.com/embed/Y7ICAYi2ztk", cabType: "all", userRole: "all"}
    ],
    PPK: [
        {name: "Электронные торговые ресурсы АО «Комита»",     file: "https://www.youtube.com/embed/_UofOwwaWCc", cabType: "all", userRole: "all"},
        {name: "О регистрации и аккредитации Участника",     file: "https://www.youtube.com/embed/gXWMLL-h3O0", cabType: "all", userRole: "all"},
        {name: "О сервисе «Проверка компании» в ЛК Участника",  file: "https://www.youtube.com/embed/PG-PM7XjVmg", cabType: "all", userRole: "participant_admin,user,unauthorized"},
        {name: "О сервисе «Проверка компании» в ЛК Заказчика",  file: "https://www.youtube.com/embed/JpbDxL1DMNU", cabType: "all", userRole: "organizer_cbz,organizer_user,organizer_admin,unauthorized"},
        {name: "О сервисе безопасности закупок «Анализ цен»",  file: "https://www.youtube.com/embed/Y7ICAYi2ztk", cabType: "all", userRole: "all"}
    ]
});