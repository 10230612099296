/**
 * Создание информации о версии
 */
angular.module('uetp')
    .component('versionInfoCreate', {
        templateUrl: 'app/components/versionInfos/versionCreate/versionCreate.html',
        controller: ['$scope', '$rootScope', 'constants', '$state', '$stateParams', 'versionInfoCreateSrv', 'httpSrv', 'dialogSrv', 'alertSrv', 'desktopSrv','needConfirmSrv', 'dictionarySrv',
            function ($scope, $rootScope, constants, $state, $stateParams, versionInfoCreateSrv, httpSrv, dialogSrv, alertSrv, desktopSrv,needConfirmSrv, dictionarySrv) {
                $scope.saveVersionInfo = saveVersionInfoFn;
                $scope.publicVersionInfo = publicVersionInfoFn;
                $scope.loadVersionInfoItem = loadVersionInfoItemFn;
                $scope.cancel = cancelFn;
                $scope.isReadOnly = isReadOnlyFn;
                init();

                function saveVersionInfoFn() {
                    $scope.submitted = true;
                    if (!$scope.form.mainForm.$valid) {
                        $scope.alertObjVersionInfo = alertSrv.getErrorMsg('Заполните все обязательные поля!');
                        return;
                    }

                    if ($scope.form.mainForm.content.$modelValue.length>10000) {
                        $scope.alertObjVersionInfo = alertSrv.getErrorMsg('Переполнение поля "Описание изменений": ' + $scope.form.mainForm.content.$modelValue.length + '/' + '10000 символов!');
                        return;
                    }

                    versionInfoCreateSrv.saveVersionInfo($scope.crtVersion).then(function (response) {
                        if (response.data.success) {
                            $scope.submitted = false;
                            $scope.crtVersion = response.data.result;
                            $scope.alertObjVersionInfo = alertSrv.getSuccessMsg('Информация о версии сохранена!');
                            if ($scope.form.mainForm) {
                                $scope.form.mainForm.$setPristine();
                            }
                        }
                    });
                    if (document.activeElement) {
                        document.activeElement.blur();
                    }
                }

                function publicVersionInfoFn() {
                    $scope.submitted = true;
                    if (!$scope.form.mainForm.$valid) {
                        $scope.alertObjVersionInfo = alertSrv.getErrorMsg('Заполните все обязательные поля!');
                        return;
                    }
                    $scope.crtVersion.state = 'published';

                    versionInfoCreateSrv.publicVersionInfo($scope.crtVersion).then(function (response) {
                        if (response.data.success) {
                            $scope.submitted = false;
                            $state.go('versionInfo');
                        }
                    });
                }

                function loadVersionInfoItemFn(id) {
                    versionInfoCreateSrv.loadVersionInfo(id).then(function (response) {
                        if (response.data.success) {
                            $scope.crtVersion = response.data.result;
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    })
                }

                
                function init() {
                    $scope.cabType = desktopSrv.getCabType();
                    $scope.crtVersion = {};
                    $scope.form = {mainForm: ''};
                    if ($scope.form && $scope.form.mainForm) $scope.form.mainForm.$setPristine();
                    if ($scope.form && $scope.form.mainForm) $scope.form.mainForm.$setUntouched();
                    $scope.types = dictionarySrv.getStaticDictByType('versionInfoTypes');
                    
                    $scope.state = $state.current.name;
                    if ($stateParams.id)
                        loadVersionInfoItemFn($stateParams.id);
                }

                function goBackFn(){
                    history.back();
                }
                function cancelFn() {
                    if($scope.form.mainForm.$dirty && $scope.crtVersion.state !== 'published'){
                        needConfirmSrv.call(goBackFn,[],'Внесенные изменения сохранены не будут');
                    }else{
                        goBackFn();
                    }
                }
                function isReadOnlyFn() {
                    return ["support"].indexOf(desktopSrv.getCabType())!=-1 || $scope.crtVersion.state === 'deleted';
                }
                $scope.isAdmin = () => ["admincab"].indexOf(desktopSrv.getCabType())!=-1;

                $scope.getTitleName = (crtVersion) => {
                    if(!crtVersion.id)
                        return "Добавление информации о версии";
                    return (crtVersion.id && (crtVersion.state==='draft' || ($scope.isAdmin() && crtVersion.state==='published'))) ?
                        "Редактирование информации о версии" : "Просмотр информации о версии";
                }

            }]

    });

