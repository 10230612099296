angular.module('uetp')
    .component('counterCart', {
        templateUrl: 'app/components/cart/views/cartCounter.html',
        controller: [
            '$scope', '$interval', 'cartSrv', 'constants', 'desktopSrv',
            function ($scope, $interval, cartSrv, constants, desktopSrv) {
                this.$onInit = function () {
                    $scope.cartCount = cartSrv.count;
                    $scope.cabType = desktopSrv.getCabType();
                    cartSrv.countPositions();
                    $scope.constants = constants;
                };

                $scope.checkCountsValue = function (counts) {
                    return counts > 99 ? '99+' : counts
                }
            }
        ]
    });
