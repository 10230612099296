/**
 * Создание протокола "Уведомление о переносе сроков"(protocolTransfer), "Уведомление о поступлении жалобы"(protocolComplaint) или "Уведомления"(protocolCustom)
 */
angular.module('uetp')
    .component('notificationProtocol', {
        templateUrl: 'app/components/procedures/protocol/notificationProtocol.html',
        bindings: {
            procedureData: '<',
            protocolData: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'proceduresSrv', '$uibModal',
            'authSrv', 'httpSrv', 'storageSrv', 'vfSrv', 'needConfirmSrv', 'alertSrv','dictionarySrv', 'dialogSrv', 'protocolSrv','constants', 'permissionSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, proceduresSrv, $uibModal,
                      authSrv, httpSrv, storageSrv, vfSrv, needConfirmSrv, alertSrv, dictionarySrv, dialogSrv, protocolSrv, constants, permissionSrv) {
                var ctrl = this;
                $scope.ctrl = ctrl;
                $scope.refreshResults = refreshResultsFn;
                $scope.deleteAttach = deleteAttachFn;
                $scope.signSendData = signSendDataFn;
                $scope.uploadSuccess = uploadSuccessFn;
                $scope.downloadFile = downloadFileFn;
                $scope.cancel = cancelFn;
                $scope.changeType = changeTypeFn;
                $scope.changeState = changeStateFn;
                $scope.hasPermission = permissionSrv.hasPermission;
                
                $scope.actionBtns = {
                   isManualIncorrectProtocol: false,
                   isManualCancelProtocol: false,
                   isManualPublishProtocol: false
                };
                
                this.$onInit = function () {
                    $scope.certCheck = authSrv.isCertAvailable;
                    $scope.procedure = $scope.ctrl.procedureData.data.result;
                    $scope.protocol = $scope.ctrl.protocolData.data.result;
                    $scope.isViewOnly = !($scope.protocol?.state?.id === 'created' || !$scope.protocol?.state);
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.constants = constants;
                    $scope.auth = authSrv.getAuth();
                    $scope.user = authSrv.getUser();
                    $scope.notifications = $scope.procedure.type.id==='requestprices' ? [{code:'CUSTOM', name:'Уведомление'}] :
                        [{code:'COMPLAINT', name:'Уведомление о поступлении жалобы'}, {code:'CUSTOM', name:'Уведомление'}];
                    $scope.ajaxActive = true;
                    $scope.isEisProcedure=proceduresSrv.isEisProcedure($scope.procedure);

                    if (!$scope.isViewOnly) {
                        $scope.rejectionDateOptions =  {
                            maxDate: getServerTimeMsk()
                        };
                        $scope.maxCountAttachments = 1;

                        $scope.refreshResults();
                        if(!$scope.protocol?.state) {
                            changeTypeFn();
                            $scope.protocol.document.attachment = [];
                        }
                    }
                    if($scope.protocol.workDays) {
                        $scope.workDaysForSelect = angular.copy($scope.protocol.workDays);
                    }
                    
                    _getBtnVisible($scope.protocol.actions);
                };


                function refreshResultsFn() {
                    var notifList = angular.copy($scope.notifications).map(function (item) {
                            return item['code']
                        }),
                        dateComp = getServerTimeMsk().getTime() > $scope.procedure.claimEnds,
                        listCont=false;

                    for(var i=0; i<notifList.length; i++){
                        if(notifList[i]==='TRANSFER')
                            listCont=true;
                    }

                    if (!listCont && dateComp && $scope.procedure.type.id!=='requestprices')
                        $scope.notifications.push({code: 'TRANSFER', name: 'Уведомление о переносе сроков'});
                }

                function uploadSuccessFn(result) {
                    angular.forEach(result, function (item) {
                        $scope.protocol.document.attachment.push({
                            fileName: item.fileName,
                            id: item.id
                        });
                    })
                }

                function deleteAttachFn(index) {
                    $scope.protocol.document.attachment.splice(index, 1);
                }

                function cancelFn() {
                    if (_getForm().$dirty) {
                        needConfirmSrv.call(_openProcedure, [], 'Внесенные изменения сохранены не будут');
                    } else {
                        _openProcedure();
                    }
                }

                function changeTypeFn() {
                    if($scope.protocol.document.type.code === 'TRANSFER') {
                        $scope.protocol.document.titleExplanationRequest = 'Перенос сроков';
                        $scope.protocol.document.subjectExplanationRequest = 'Перенос сроков';
                    } else if($scope.protocol.document.type.code === 'COMPLAINT') {
                        $scope.protocol.document.titleExplanationRequest = 'Уведомление о поступлении жалобы';
                        $scope.protocol.document.subjectExplanationRequest = 'Уведомление о поступлении жалобы';
                    } else if($scope.protocol.document.type.code === 'CUSTOM') {
                        $scope.protocol.document.titleExplanationRequest = '';
                        $scope.protocol.document.subjectExplanationRequest = '';
                    }
                    if($scope.protocol?.workDays?.length>2) {
                        if($scope.protocol.document.type.code === 'COMPLAINT') {
                            $scope.workDaysForSelect = $scope.protocol?.workDays.slice($scope.protocol?.workDays?.length - 2);
                        } else {
                            $scope.workDaysForSelect=angular.copy($scope.protocol.workDays);
                        }
                    } else if($scope.protocol.workDays) {
                        $scope.workDaysForSelect = angular.copy($scope.protocol.workDays);
                    }
                }

                function _focusFirstInvalid(form) {
                    $scope.submitted = true;
                    if (form.$error && form.$error.required && form.$error.required[0]
                        && form.$error.required[0].$$element && form.$error.required[0].$$element[0]) {
                        desktopSrv.scollTop();
                        form.$error.required[0].$$element[0].focus();
                    }
                    if (form.length > 0 && form[0].$$element && form[0].$$element[0]) {
                        desktopSrv.scollTop();
                        form[0].$$element[0].focus();
                    }
                }

                function _openProcedure() {
                    $state.go('openProcedure', {procType: $scope.procedure.type.id.toLowerCase(), procId: $scope.procedure.id});
                }

                /**
                 * Подпись и отправка
                 */
                function signSendDataFn() {
                    if ($scope.auth.certId || $scope.auth.withoutCertEnabled) {
                        if (validateForm()) {
                            $stateParams.protocolId = $scope.protocol.id;
                            $scope.forceSignDisable = true;
                            if(!$scope.protocol.state) {
                                protocolSrv.saveProtocolFn($stateParams, $scope.protocol, $scope.protocol.document.type.code).then((response) => {
                                    if (response.data.success) {
                                        $scope.signAndSend();
                                    } else {
                                        if($scope.auth.mchdCheckResult || $scope.user.mchdReq) {
                                            $scope.forceSignDisable = false;
                                        }
                                        openView(response);
                                    }
                                })
                            } else {
                                protocolSrv.updateProtocolFn($stateParams, $scope.protocol).then((response) => {
                                    if (response.data.success) {
                                        $scope.signAndSend();
                                    } else {
                                        if($scope.auth.mchdCheckResult || $scope.user.mchdReq) {
                                            $scope.forceSignDisable = false;
                                        }
                                        openView(response);
                                    }
                                })
                            }
                        }
                    } else {
                        $scope.ajaxActive = false;
                        $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn('У вас не установлен сертификат.');
                    }
                }

                $scope.signAndSend = () => {
                    protocolSrv.signAndSend($stateParams, $scope.protocol, $scope.auth.certId).then((response) => {
                        if (response.data.success) {
                            setTimeout(function () {
                                _openProcedure();
                            }, 1000);

                        } else {
                            if($scope.auth.mchdCheckResult || $scope.user.mchdReq) {
                                $scope.forceSignDisable = false;
                            }
                            openView(response);
                        }
                        $scope.ajaxActive = false;
                    }, (reason) => {
                        if($scope.auth.mchdCheckResult || $scope.user.mchdReq) {
                            $scope.forceSignDisable = false;
                        }
                        openView(reason);
                    });
                }

                function openView(response) {
                    if (typeof response === 'string') {
                        $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn(response);
                    } else {
                        $scope.alertObjRejectProcedure = alertSrv.getAlertObj(response);
                    }
                    $state.go('notificationProtocolView', {
                        procId: $scope.procedure.id,
                        procType: $scope.procedure.type.id.toLowerCase(),
                        protocolId: $scope.protocol.id
                    });
                    $scope.ajaxActive = false;
                }

                function changeStateFn(state) {
                    needConfirmSrv.call(doChangeStateFn,[state],'Выполнить смену редакции уведомления?');
                }
                function doChangeStateFn(state) {
                     $stateParams.protocolId = $scope.protocol.id;
                     protocolSrv.changeStateProtocolFn($stateParams, state).then(function (response) {
                            if (response.data.success) {
                            	$scope.alertObjRejectProcedure = alertSrv.getSuccessMsg('Статус уведомления изменён');
                            	setTimeout(function () {
                                    _openProcedure();
                                }, 3000);
                            	$scope.ajaxActive = false;
                            } else {
                                $scope.alertObjRejectProcedure = alertSrv.getAlertObj(response);
                            }
                        })
                }

                function downloadFileFn(file) {
                    var url = '/fs/file/' + $scope.procedure.type.id.toLowerCase() + '/' + $scope.procedure.id + '/files/' + file.id;
                    httpSrv.downloadFile(url, file.filename);
                }

                function _getForm() {
                    return $scope.form.rejectForm;
                }

                function validateForm() {
                    if (_getForm().$invalid) {
                        $scope.submitted = true;
                        if (_getForm().$error.required) {
                            $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn('Необходимо заполнить обязательные поля на форме. ', 'Перейти', _focusFirstInvalid, _getForm());
                        }
                        else {
                            var errorArr = Object.keys(_getForm().$error).length > 0 ? _getForm().$error[Object.keys(_getForm().$error)[0]] : [];
                            if (errorArr.length > 0 && errorArr[0].$$element && errorArr[0].$$element[0]) {
                                $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn('Неправильно заполнено поле. ', 'Перейти', _focusFirstInvalid, errorArr);
                            }
                        }
                        return false;
                    }
                    if (!$scope.protocol.document.attachment || $scope.protocol.document.attachment.length === 0) {
                        $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn("Обратите внимание! Документ должен содержать вложения.");
                        return false;
                    }

                    return true;
                }
                
                /**
                 * Активация кнопок в зависимости от разрешнных действий сервера
                 * @param actions - действия которые можно выпонить с документом
                 * @private
                 */
                function _getBtnVisible(actions) {
                    if (actions && actions.length > 0) {
                        angular.forEach(actions, function (action) {
                            if (action.id.indexOf('manualIncorrectProtocol') >= 0) {
                                $scope.actionBtns.isManualIncorrectProtocol = true;
                            } else if (action.id.indexOf('manualCancelProtocol') >= 0) {
                                $scope.actionBtns.isManualCancelProtocol = true;
                            } else if (action.id.indexOf('manualPublishProtocol') >= 0) {
                                $scope.actionBtns.isManualPublishProtocol = true;
                            }
                        })
                    }
                    $scope.isEdit = !$scope.isViewOnly;
                    $scope.actionBtns.isSign = 'created'===$scope.protocol?.state?.id || !$scope.protocol?.state;
                    $scope.actionBtns.isEdit = 'created'===$scope.protocol?.state?.id;
                    $scope.actionBtns.idDelete = 'created'===$scope.protocol?.state?.id;
                }
            }
        ]
    });

