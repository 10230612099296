/**
 * @author: sergeyu
 * Контракты компании
 */
angular.module('uetp')
    .component('contractsTass', {
        bindings: {
            tassInn: '=',
            initLoadData: '<' // загрузить данные для отображения когда станет true
        },
        templateUrl: 'app/components/widgets/tassBusiness/companyValidate/view/contracts/contracts.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'tassBusinessSrv', 'tablesSrv', 'paginationSrv', 'filterService', 'scService', 'propertiesService', '$filter',
            function ($scope, $rootScope, $state, $stateParams, tassBusinessSrv, tablesSrv, paginationSrv, filterService, scService, propertiesService, $filter) {
                var ctrl = this;
                $scope.loaded = false;

                $scope.getStatus = getStatusFn;
                $scope.getUrl = getUrlFn;
                $scope.getSubjectContract = getSubjectContractFn;
                $scope.loadContract = loadContractFn;
                $scope.sortBy = sortByFn;
                $scope.getRubPrice = getRubPriceFn;
                $scope.getCurrencyPrice = getCurrencyPriceFn;
                $scope.getCurrency = getCurrencyFn;

                this.$onInit = function () {
                    $scope.modelView = {
                        contracts: []
                    };
                    $scope.paginator = {
                        itemsPerPage: 20,
                        page: 0
                    };
                    $scope.sortByObject = tablesSrv.initSort($scope.sortByObject, $scope.paginator);
                    if(!$scope.loaded) $scope.loadContract();

                };
                this.$onChanges = function (changesObj) {
                    if (changesObj.initLoadData.currentValue) {
                        ($scope.paginator.search !== "" || $scope.paginator.searchByField) ? scService.setCounter($scope.paginator.count) : scService.setCounter($scope.paginator.count);
                        filterService.initModelView('ИНН заказчика/поставщика, регистрационный номер или предмет договора/контракта', 'Контракты');
                    }
                };
                
                /*
                * Статус по фз и state
                * */
                var STATUS = {
                    'contract_44': {
                        "EXECUTION": {
                            text: "Исполнение",
                            color: "#0066A1"
                        },
                        "EXECUTION_COMPLETED": {
                            text: "Исполнение завершено",
                            color: "#3DA124"
                        },
                        "EXPIRATION_44": {
                            text: "Исполнение прекращено",
                            color: "#E21A1A"
                        },
                        "Cancelled": {
                            text: "Аннулированные реестровые записи",
                            color: "#E21A1A"
                        }
                    },
                    'contract_223': {
                        "EXECUTION": {
                            text: "Исполнение",
                            color: "#0066A1"
                        },
                        "EXECUTION_COMPLETED": {
                            text: "Исполнение завершено",
                            color: "#3DA124"
                        },
                        "EXPIRATION": {
                            text: "Исполнение прекращено - расторжение",
                            color: "#E21A1A"
                        },
                        "EXECUTION_TERMINATION": {
                            text: "Исполнение прекращено - окончание срока действия договора",
                            color: "#E21A1A"
                        }
                    }
                };

                /*
                * Получить статус по фз и state
                * */
                function getStatusFn(row) {
                    return STATUS[row.type][row.state] || {};
                }

                function getRubPriceFn(row) {
                    var price =  row.rubPrice ? $filter('currency')(row.rubPrice, 'RUB') : (getCurrencyFn(row) === 'RUB' ? $filter('currency')(row.price, 'RUB') : '');
                    if(!price){
                    	var itemsPrice = 0;
                        angular.forEach(row.items, function (item) {
                        	itemsPrice += (item.qty ? item.qty * item.price : item.price);
                        });
                        if(itemsPrice > 0){
                        	price = $filter('currency')(itemsPrice, 'RUB');
                        }
                    }
                    return price;
                }

                function getCurrencyPriceFn(row) {
                	var currency = getCurrencyFn(row);
                    return currency != 'RUB' ? $filter('currency')(row.price, currency) : '';
                }
                
                function getCurrencyFn(row) {
                    if (row.currency) {
                        var idxCode = row.currency.indexOf('code=');
                        var idxSep = row.currency.indexOf(',');
                        if (idxCode != -1 && idxSep != -1 && idxCode < idxSep) {
                            return row.currency.slice(idxCode + 5, idxSep);
                        } else {
                            return row.currency;
                        }
                    } else {
                        return 'RUB';
                    }
                }

                /*
                * Подменяем ссылки на открытый раздел для 223 фз
                * */
                function getUrlFn(row) {
                    var url = row.urlEIS;
                    return row.type === 'contract_223' ? url.replace('https:', 'http:').replace('private', 'public') : row.urlEIS;
                }
                /*
                * Формируем поле "Предмет договора" и его тултип
                * -отображаем тултип если поле больше characters символов
                * */
                function getSubjectContractFn(row, isTitle,characters) {
                    var result = '';
                    if (row.subjectContract) {
                        result = row.subjectContract
                    } else if (row.items && row.items.length > 0) {
                        var subjectContract = '';
                        angular.forEach(row.items, function (item) {
                            subjectContract += item.name + ', ';
                        });
                        result = subjectContract.substring(0, subjectContract.length - 2);
                    } else {
                        result = '';
                    }
                    if (isTitle && result.length > Number(characters) || !isTitle) return result;
                    else return '';
                }



                function loadContractFn() {
                    $scope.loaded = false;
                    $scope.errorInfo = "";
                    if (!$scope.paginator.orderBy) {
                    	$scope.paginator.orderBy = "signDate";
                    	$scope.paginator.orderAsc = false;
                        var signDate = {
                            "activate": true,
                            "orderAsc": false,
                            "tag": "desc"
                        };
                        $scope.sortByObject.signDate = signDate;
                    }
                    filterService.initModelView('ИНН заказчика/поставщика, регистрационный номер или предмет договора/контракта', 'Контракты');
                    prepareTableParams('filterCompanyValidateContracts');
                    return propertiesService.getProperty("otherProcedures.url").then(function (host) {
                        return tassBusinessSrv.getOrganizationContracts($scope.paginator, ctrl.tassInn, host, $scope).then(function (response) {
                            if (response.data.success && response.data.items) {
                                $scope.modelView.contracts = response.data.items;
                                if ($scope.modelView.contracts.length === 0) {
                                    $scope.errorInfo = "Данных по заключенным контрактам компании не найдено."
                                }
                                if (response.data.message) {
                                    $scope.modelView.allSum = response.data.message;
                                    $scope.modelView.allCount = response.data.total;
                                }
                                $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                                if ($scope.paginator.search !== "" || $scope.paginator.searchByField) {
                                    $scope.paginator.count = response.data.total;
                                    scService.setCounter($scope.paginator.count);
                                } else {
                                    scService.clearCounter();
                                }
                                $scope.loaded = true;
                            } else {
                                $scope.modelView.contracts = {};
                                $scope.errorInfo = response.data.errorMessage ? response.data.errorMessage : "Получить информацию о компании в данный момент невозможно.";
                                $scope.loaded = false;
                            }

                        }, function () {
                            $scope.modelView.contracts = {};
                            $scope.errorInfo = "Получить информацию о компании в данный момент невозможно.";
                            $scope.loaded = false;
                        });
                    });
                }

                function sortByFn(by) {
                    tablesSrv.sortBy(by, $scope.sortByObject, $scope.paginator, $scope.loadContract);
                }

                function prepareTableParams(filterType) {
                    var filterParams = angular.copy(filterService.getFilterParams(filterType)),
                        searchParam = angular.copy(filterService.getSearchParam(filterType));
                    if (filterParams && Object.keys(filterParams).length > 0) {
                        $scope.paginator.searchByField = filterParams;
                    } else {
                        delete $scope.paginator.searchByField
                    }
                    $scope.paginator.search = searchParam;
                }
            }]
    });

