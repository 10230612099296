/**
 * @author dmitrys
 */
angular.module('uetp').service('coveringLetterSrv', ['$state', 'httpSrv',
    function ($state, httpSrv) {
        var service = this;

        service.sendAndSign = sendAndSignFn;
        service.getOrganizationInfo = getOrganizationInfoFn;
        service.checkPaymentOrder = checkPaymentOrderFn;
        service.getCoveringLetter = getCoveringLetterFn;
        service.answerCoveringLetter = answerCoveringLetterFn;
        service.addSignatureToCoveringLetter = addSignatureToCoveringLetterFn;
        /**
         * Получения информации об организации по счету
         * @param data - счет
         * @returns {*}
         */
        function getOrganizationInfoFn(data) {
            //поиск реализован через paginator
            var paginator = {
                itemsPerPage: 20,
                page: 0,
                search: data
            };
            return httpSrv.http({
                method: 'POST',
                data: paginator,
                url: '/auth/user/getOrganizationListInfo'
            });
        }
        /*
        * Отправить сопроводительное письмо
        * */
        function sendAndSignFn(data) {
            return httpSrv.http({
                method: 'POST',
                data: data,
                url: '/accounts/coveringLetters/createCoveringLetter'
            });
        }
        /*
        * Проверка наличия п/п требующих уточнения при запуске лк
        * */
        function checkPaymentOrderFn() {
            return httpSrv.http({
                method: 'GET',
                url: '/accounts/coveringLetters/getPaymentOrdersWithClaim'
            })
        }
        /*
        * Сопроводительное письмо по id
        * */
        function getCoveringLetterFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/accounts/coveringLetters/getCoveringLetter/' + id
            })
        }
        /*
        * Ответ участника на запрос подтверждения платежа
        * */
        function answerCoveringLetterFn(data) {
            return httpSrv.http({
                method: 'POST',
                data: data,
                url: '/accounts/coveringLetters/answerCoveringLetter'
            })
        }
        /*
        * Добавление подписи к документу сопроводительного письма
        * */
        function addSignatureToCoveringLetterFn(data) {
            return httpSrv.http({
                method: 'POST',
                data: data,
                url: '/accounts/coveringLetters/addSignatureToCoveringLetter'
            })
        }
    }]);