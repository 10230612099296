/**
 * @author: sergeysi
 * Компонент входа в лк через ЭМ
 */
angular.module('uetp')
    .component('webmarketLogin', {
        templateUrl: 'app/components/login/webmarketLogin.html',
        bindings: {
            callback: '=',
            data: '<'
        },
        controller: ['$scope', '$rootScope', '$state', 'authSrv', 'desktopSrv', 'propertiesService', 'profileSrv', 'httpSrv', 'dialogSrv',
            function ($scope, $rootScope, $state, authSrv, desktopSrv, propertiesService, profileSrv, httpSrv, dialogSrv) {

                $scope.token;
                $scope.decision = false;
                $scope.webmarketLogin = webmarketLoginFn;
                $scope.registration = registrationFn;
                $scope.blockLoginBtn = true;
                $scope.hasAcceptor = false;
                $scope.enterBtnText = 'Войти';
                $scope.showConfirm = true;
                $scope.confirmBtn = confirmBtnFn;
                $scope.isCheckboxConfirm = isCheckboxConfirmFn;
                $scope.showCrossLoginForm = false;
                $scope.modelView = {
                    checkboxConfirm: false
                };

                this.$onInit = function () {
                    init();
                };

                function init() {
                    propertiesService.loadProperties();

                    clearCookies();

                    if($scope.token) {
                        wmLogin(function (response){
                            if (response.data.success) {
                                $scope.blockLoginBtn = false;
                                $scope.errorMsg = '';
                                authSrv.crossLogin(response).then(function (auth) {
                                    _initCabAfterLogin(auth);
                                });
                            } else {
                                $scope.showCrossLoginForm = true;
                                showErrorMsg(response.data)
                            }
                        });
                    }
                }

                // чистим куки и storage
                function clearCookies() {
                    $scope.token = desktopSrv.getCookie('Redirect-Token');
                    desktopSrv.setCookie("Redirect-Token", '', {expires: -1});
                    let data = angular.copy(JSON.parse(sessionStorage.getItem("redirect_params")));
                    if(data) {
                        $scope.data = data;
                        sessionStorage.removeItem("redirect_params");
                    }
                }

                function showErrorMsg(data){
                    $scope.errorMsg = data.message;
                    $scope.hasAcceptor = false;
                    if (data.errorCode==='019' || data.errorCode==='020') {
                        $scope.enterBtnText = 'Зарегистрироваться';
                        $scope.modelView.checkboxConfirm = false;
                        $scope.hasAcceptor = true;
                    } else if(data.errorCode==='022' || data.errorCode==='023' || data.errorCode==='024') {
                        $scope.errorMsg = 'В процессе регистрации учетной записи возникла  ошибка". Обратитесь в службу технической поддержки.';
                        $scope.modelView.checkboxConfirm = false;
                    }
                    else
                        $scope.enterBtnText = 'Войти';
                }

                function confirmBtnFn() {
                    $scope.showConfirm = !$scope.showConfirm;
                    webmarketLoginFn();
                }

                function isCheckboxConfirmFn() {
                    return $scope.modelView.checkboxConsentProcessing && $scope.modelView.checkboxCconsentDissemination;
                }

                function _initCabAfterLogin(auth) {
                    desktopSrv.initCabinet(auth, false);
                    profileSrv.loadOrganizationInfo().then(function (userData) {
                        if (userData.data.success) {
                            var user = userData.data.result;
                            authSrv.setUser(user);
                            authSrv.checkProfileCert();
                            authSrv.setProfileSuccess(user.approvalOrg);
                            authSrv.setReglamentSuccess(user.approvalReglament);
                            authSrv.setApprovalStates(user.approvalStates);
                            authSrv.setUserValid(profileSrv.validateProfileModel(user));
                            authSrv.isCloseTariffPanel(user.id);
                            desktopSrv.initCabinet(auth, true);

                            checkMchdValidity();
                            
                            function checkMchdValidity() {
                                if(['organizer_admin', 'organizer_user', 'participant_admin', 'user'].includes(authSrv.getUser().userRole)) {
                                    profileSrv.mchdLoginCertCheck().then(function (checkRes) {
                                        if(!checkRes.data.success) {
                                            if(checkRes.data.errorCode==='430') {
                                                dialogSrv.openModal('_confirmInfoExt.html', {
                                                    title: "Внимание!",
                                                    message: checkRes.data.errorMessage,
                                                    buttons: [{
                                                        title: "Продолжить",
                                                        btnClass: "btn-success",
                                                        closeValue: false,
                                                        action: function () {
                                                            profileSrv.logUserMchd();
                                                            $rootScope.$emit('mchdError', false);
                                                        }
                                                    // },{
                                                    //     title: "Повторить проверку",
                                                    //     btnClass: "btn-success",
                                                    //     closeValue: false,
                                                    //     action: function () {
                                                    //         checkMchdValidity();
                                                    //     }
                                                    },{
                                                        title: "Отмена",
                                                        btnClass: "btn-light",
                                                        closeValue: true
                                                    }]
                                                });
                                            } else if(checkRes.data.errorCode==='427') {
                                                $rootScope.$emit('mchdError', true, checkRes.data.errorMessage);
                                            }
                                        }
                                    });
                                }
                            }

                            profileSrv.checkAccredSecured().then(function (checkRes) {
                                authSrv.updateOrgAccreditation(checkRes.data.result);
                            });
                            profileSrv.checkBGLimits(user);

                            if ($scope.data && $scope.data.state) {
                                $state.go($scope.data.state, $scope.data.param);
                            } else if ($scope.data && $scope.data.url) {
                                $state.go($scope.data.url, {
                                    procType: $scope.data.procType,
                                    procId: $scope.data.procId,
                                    url: $scope.data.url,
                                    lotId: $scope.data.lotId
                                });
                            } else {
                                desktopSrv.initCabinet(auth, true);
                            }

                            $rootScope.$emit("login");
                        } else {
                            authSrv.exit();
                        }
                    });
                }

                function webmarketLoginFn() {
                    $scope.registering = true;
                    $scope.decision = isCheckboxConfirmFn();
                    wmLogin(function (response){
                        if(!response.data.success) {
                            showErrorMsg(response.data);
                            $scope.registering = false;
                        } else {
                            authSrv.crossLogin(response).then(function (auth) {
                                _initCabAfterLogin(auth);
                            });
                        }
                    });
                }

                function wmLogin(callback) {
                    var data = {token: $scope.token, decision: $scope.decision};
                    login(data).then(function successCallback(response){
                        callback(response);
                    }, function errorCallback(response) {
                        showErrorMsg(response.data);
                        console.log(response);
                    });
                }

                function registrationFn(){
                    $state.go('registration');
                }

                function login(data) {
                    return httpSrv.http({
                        method: 'POST',
                        url: '/auth/user/wmLogin',
                        data: data
                    })
                }
            }]
    }
    );
