/**
 * @author: sergeyu
 * Просмотр участника
 */
angular.module('uetp')
    .component('participant', {
        templateUrl: 'app/components/participant/participant.html',
        bindings: {
            orgData: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv','participantSrv', 'authSrv','storageSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, participantSrv, authSrv,storageSrv) {
                var ctrl = this;
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    participantSrv.setOrgInfo(ctrl.orgData);
                    if(['admincab', 'support'].includes($scope.cabType()) && ctrl.orgData.data.success)
                        $scope.orgFullName = '(' +ctrl.orgData.data.result.fullName +')';
                    $scope.model = {
                        showPanel: 'requisites'
                    };
                    $scope.stateConfig = $stateParams.params && $stateParams.params.clearConfig ?
                        storageSrv.clearStateConfig($stateParams.activeProfileTab, 'showPanel') : storageSrv.getStateConfig($stateParams.activeProfileTab, 'showPanel');
                    if ($stateParams.activeProfileTab){
                        setActivePanelFn($stateParams.activeProfileTab);
                    }else{
                        if ($scope.stateConfig) {
                            setActivePanelFn($scope.stateConfig.typePanel)
                        }
                    }
                    $scope.fastAccreditation = ctrl.orgData.data.result.fastAccreditation;
                    $scope.isAccreditationDelayed = ctrl.orgData.data.result.isAccreditationDelayed;
                    $scope.isUserAgent = authSrv.isUserAgent;
                    $scope.isShowFiliation = "ul" == ctrl.orgData.data.result.typeOrg
                        || ("nr" == ctrl.orgData.data.result.typeOrg && "L" == ctrl.orgData.data.result.nrType);
                    _initShowPanel();
                };
                $scope.setActivePanel = setActivePanelFn;

                /**
                 * Выставление активной вкладки
                 * @param type - тип вкладки
                 */
                function setActivePanelFn(type) {
                    $scope.model.showPanel = type;
                    storageSrv.setStateConfig($stateParams.activeProfileTab, 'showPanel', {typePanel: type});
                }
                function _initShowPanel() {
                    $scope.model.showPersonalAccount = $scope.cabType() === 'accountant' ||  ($scope.cabType() === 'support' && authSrv.getUser().userRole === 'operator_support_ro');
                    $scope.model.showAgents = true;
                    $scope.model.showTariff = true;
                    $scope.model.showInvoices = true;
                    $scope.model.showPaymentOrders = true;
                    $scope.model.showCompletedWorks = $scope.cabType() === 'accountant';
                    $scope.model.showParticipationInPurchases = ["operator_admin"].includes(authSrv.getUser().userRole);
                    $scope.model.showFiliation = ["operator_admin", "operator_support_ro", "operator_support_rw"].includes(authSrv.getUser().userRole) && $scope.isShowFiliation;
                    $scope.model.bgAgents = ["operator_admin", "operator_accountant", "operator_inspector", "operator_support_ro", "operator_support_rw"].includes(authSrv.getUser().userRole);
                }

                $rootScope.$on('participantViewSetActivePanel', function(event,type){
                    $scope.model.showPanel = type;
                });


            }]
    });

