/**
 * @author: alexandrbu
 * Сервис для компонентов Банка для банковских гарантий
 */
angular.module('uetp').service('bgBankSrv', ['$state', 'httpSrv',
    function ($state, httpSrv) {
        var organizatorInfo = {};

        /**
         * Получение данных по банку по его ID
         */
        this.getOperatorById = function (id) {
            return httpSrv.http({
                method: 'GET',
                url: '/admin/getBgBankInfoById/' + id
            });
        };
        /**
         * Сохранение или обновления данных по банку
         */
        this.createOrUpdate = function (data) {
            return httpSrv.http({
                url: '/admin/registrationBgBank',
                data: data
            });
        };
        /**
         * Выставление полученных данныех по банку
         */
        this.setOrganizatorInfo = function (info) {
            organizatorInfo = info;
        };
        /**
         * Получение данных по банку
         */
        this.getOrganizatorInfo = function () {
            return organizatorInfo;
        };
        /**
         * Создание нового представителя банка
         * @param id - идентификатор банка
         * @param agent - регистрируемы пользователь организатора
         * @returns {*}
         */
        this.saveNewAgent = function (id, agent) {
            return httpSrv.http({
                url: '/admin/registrationUserBgBank/'+id,
                data: agent,
                block: true
            })
        };
      
    }]);