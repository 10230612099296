angular.module('uetp')
    .component('pagination', {
        templateUrl: 'app/components/pagination/pagination.html',
        bindings: {
            callback: '=',
            paginator: '=',
            totalPages: '=',
            hideViewPage: '@',
            limitPage: '@',
            limitResult: '@',
            showNumberPage: '@',//кол-во кнопок на странице
            showArrow: '@',//отобразить стрекли перехода на начало и конец
            showTopPager: '@',//признак верхнего пэйджинэйтора
            stateName:'@'//в случаем нескольких компонентов в одном состоянии указывать свое имя
        },
        controller: ['$state', '$stateParams', 'paginationSrv', '$location', '$anchorScroll',
            function ($state, $stateParams, paginationSrv, $location, $anchorScroll) {
                var ctrl = this;
                /**
                 * Выбраная страница в пагинаторе
                 */
                ctrl.Math = window.Math;
                this.$onInit = function () {
                    if (angular.isUndefined(ctrl.showNumberPage)) ctrl.showNumberPage = 5;
                    if (angular.isUndefined(ctrl.showArrow)) ctrl.showArrow = 'show';
                    if (angular.isUndefined(ctrl.stateName)) ctrl.stateName =  $state.current.name;
                    if (angular.isUndefined(ctrl.limitPage)) ctrl.limitPage = 500;
                    if (angular.isUndefined(ctrl.limitResult)) ctrl.limitResult = 10000;
                    if ($stateParams.clearConfig) {
                        paginationSrv.clearPaginationStateConfig('pagination', ctrl.stateName)
                    } else {
                        var config = paginationSrv.getPaginationStateConfig('pagination', ctrl.stateName);
                        if (config) {
                            ctrl.paginator.page = config.page;
                            ctrl.paginator.itemsPerPage = config.itemsPerPage;
                        }
                    }

                };
                ctrl.getNumber = getNumberFn;
                ctrl.changePage = changePageFn;
                ctrl.changeItemsPerPage = changeItemsPerPage;
                ctrl.changeSelectPages = changeSelectPagesFn;
                ctrl.getLast = getLastFn;
                ctrl.setLast = setLastFn;
                ctrl.setFirst = setFirstFn;
                ctrl.paginator = {
                    page: 0,
                    itemsPerPage: 20
                };
                function changePageFn(page) {
                    if (page < 0 || page > ctrl.totalPages - 1) return;
                    ctrl.paginator.page = page >= ctrl.limitPage - 1 ? ctrl.limitPage - 1 : ((page + 1) * ctrl.paginator.itemsPerPage > ctrl.limitResult ? page - 1 : page);
                    paginationSrv.setPaginationStateConfig('pagination', ctrl.stateName, ctrl.paginator);
                    if (ctrl.callback) {
                        ctrl.callback();
                    }
                    $location.hash('top');
                    $anchorScroll();
                }

                function getNumberFn(num) {
                    if (num) {
                        //при удаление последнего элемента переходить на пред страницу
                        if (num < ctrl.paginator.page + 1)
                            changePageFn(--ctrl.paginator.page);
                    } else {
                        //до загрузки данных ставим 1 страницу
                        num = 1;
                    }

                    var array = [];
                    for (var i = 0; i < num; i++) {
                        array.push(i);
                    }
                    return array;
                }

                function changeItemsPerPage(itemsPerPage) {
                    ctrl.paginator.page = 0;
                    ctrl.paginator.itemsPerPage = itemsPerPage;
                    paginationSrv.setPaginationStateConfig('pagination', ctrl.stateName, ctrl.paginator);
                    if (ctrl.callback) {
                        ctrl.callback();
                    }
                }

                /**
                 * Изменить выбраную страницу у кнопок пагинатора
                 */
                function changeSelectPagesFn(value) {
                    if (Math.floor(ctrl.paginator.page / ctrl.showNumberPage) + value > Math.floor((ctrl.totalPages - 1) / ctrl.showNumberPage) || Math.floor(ctrl.paginator.page / ctrl.showNumberPage) + value < 0 || ctrl.totalPages <= ctrl.showNumberPage) {
                        //если стоим на первой или последней странице пагинатора, то по '<' '>' переходим на след старницу
                        changePageFn(ctrl.paginator.page + value);
                    } else {
                        //иначе переходим на след блок из ctrl.showNumberPage страниц
                        //ctrl.selectPages+=value;
                        ctrl.paginator.page += value * ctrl.showNumberPage;
                        if (value === 1) {
                            //если двигаемся вперед, то ставим на первую страницу из блока
                            changePageFn(Math.floor(ctrl.paginator.page / ctrl.showNumberPage) * ctrl.showNumberPage);
                        } else {
                            //если двигаемся назад, то ставим на последнюю страницу из блока
                            changePageFn(Math.floor(ctrl.paginator.page / ctrl.showNumberPage) * ctrl.showNumberPage + ctrl.showNumberPage * 1 - 1);
                        }
                    }
                }

                /**
                 * Открыть последнюю страницу
                 */
                function setLastFn() {
                    if (ctrl.paginator.page >= ctrl.totalPages - 1) return;
                    // changePageFn(ctrl.selectPages*ctrl.showNumberPage); - на последний блок первую страницу
                    changePageFn(ctrl.totalPages > ctrl.limitPage ?
                        (ctrl.totalPages * ctrl.paginator.itemsPerPage > ctrl.limitResult ? Math.floor(ctrl.limitResult / ctrl.paginator.itemsPerPage) - 1 : ctrl.limitPage - 1) :
                        (ctrl.totalPages * ctrl.paginator.itemsPerPage > ctrl.limitResult ? Math.floor(ctrl.limitResult / ctrl.paginator.itemsPerPage) - 1 : ctrl.totalPages - 1));
                }

                /**
                 * Открыть первую страницу
                 */
                function setFirstFn() {
                    if (ctrl.paginator.page === 0) return;
                    changePageFn(0);
                }

                /**
                 * Получить последнюю страницу
                 */
                function getLastFn() {
                    return Math.floor(ctrl.totalPages / ctrl.showNumberPage)
                }
            }]
    });
