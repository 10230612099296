import '../attachments';

angular.module('uetp').component('offer', {
    templateUrl: 'app/components/offer/views/offerOpen.html',
    bindings: {
        offerData: '<'
    },
    controller: [
        '$scope', '$rootScope', '$state', '$stateParams', '$q', 'offerSrv', 'constants', 'desktopSrv', 'profileSrv', 'positionSrv', 'dictionarySrv', 'authSrv', 'systemMessages', 'cartSrv', 'httpSrv', 'dialogSrv',
        function ($scope, $rootScope, $state, $stateParams, $q, offerSrv, constants, desktopSrv, profileSrv, positionSrv, dictionarySrv, authSrv, systemMessages, cartSrv, httpSrv, dialogSrv) {
            var ctrl = this,
                editMode = $stateParams.params ? $stateParams.params.editMode : false,
                tabName = ($stateParams.params && $stateParams.params.tab) || false;
            this.$onInit = initFn;
            $scope.positionShow = positionShowFn;
            $scope.addAttachShow = addAttachShowFn;
            $scope.excelImport = excelImportFn;
            $scope.saveToDoc = saveToDocFn;
            $scope.positionDelete = positionDeleteFn;
            $scope.positionEdit = positionEditFn;
            $scope.positionClose = positionCloseFn;
            $scope.publicOffer = publicOfferFn;
            $scope.saveOffer = saveOfferFn;
            $scope.goBack = goBackFn;
            $scope.changeStatus = changeStatusFn;
            $scope.changeStatusToggle = changeStatusToggleFn;
            $scope.toggleDisabled = toggleDisabledFn;
            $scope.addToCartPosition = addToCartPositionFn;
            $scope.setActivePanel = setActivePanelFn;
            $scope.collapse = collapseFn;
            $scope.isInCart = cartSrv.isInCart;
            $scope.notInsertImg = desktopSrv.notInsertImg;
            $scope.uploadFile = uploadFileFn;
            $scope.showFiles = showFilesFn;
            $scope.attachReset = attachResetFn;
            $scope.deleteAttachment = deleteAttachmentFn;
            $scope.setAttachRemoving = setAttachRemovingFn;
            $scope.closeAlert = closeAlertFn;
            $scope.downloadFile = httpSrv.downloadFile;
            $scope.maxCountAttachments = 5;
            $scope.filialChangeHandler = filialChangeHandlerFn;
            $scope.hasVatRate = positionSrv.hasVatRate;
            $scope.addPositionsFromXls = function (response, object) {
                positionSrv.addPositionsFromXls($scope, response, object);
                $scope.offer.nonNds = positionSrv.checkDocNds($scope.offer.positions);
            };
            $scope.btnDeleteClick = btnDeleteClickFn;

            $scope.activePanel = 'info';
            $scope.attachmentDirty = false;

            function closeAlertFn(id) {
                id ? $scope[id] = 'dismiss' : $scope.alertId = 'dismiss';
            }

            function goBackFn(){
                checkOfferEditConfirm().then(function(isBack){
                	if(isBack){
                      history.back();
                	}
                });
            }

            function collapseFn(id) {
                const el = document.getElementById(id);
                const visible = el.style.display;
                el.style.display = visible === 'none' ? 'table-row' : 'none';
            }

            $scope.onSizeError = function (files) {
                $scope.fileSizeErrorMessage = "Документ " + files[0].name + " не добавлен. Превышен максимально разрешенный размер файла (30МБ)!";
                $scope.alertId = 'fileSizeError';
            };

            $scope.onSizeEmpty = function () {
                $scope.alertId = 'attachError';
                $scope.attachErrorText = "Добавление пустого файла невозможно. Выберите другой файл";
            };

            $scope.onFileNameError = function (files) {
                $scope.fileSizeErrorMessage = "Документ " + files[0].name + " не добавлен. Добавление файлов с расширением *.exe запрещено!";
                $scope.alertId = 'fileNameError';
            };

            function setActivePanelFn(type) {
                $scope.activePanel = type;
            }

            function btnDeleteClickFn(id) {
                $scope.btnDelete[id] = !$scope.btnDelete[id]
            }

            function filialChangeHandlerFn(selectObj) {
                var selected = selectObj.$select.selected;
                if (selected) {
                    $scope.offer.email = selected.email;
                    $scope.offer.filial =  selectObj.$select.selected;
                    $scope.offer.filial.name = selectObj.$select.selected.fullName;
                    $scope.offer.filial.organizationId =  $scope.user.id;
                } else {
                    $scope.offer.email = $scope.user.email;
                    $scope.offer.filial = {};
                }
            }

            function addToCartPositionFn(id) {
                cartSrv.addToCartPosition(id).then(function () {
                    cartSrv.countPositions();
                    cartSrv.loadCart();
                    // $scope.positionsLoad();
                })
            }

            function setAttachRemovingFn(attachId) {
                $scope.attachRemoving = attachId;
            }

            function deleteAttachmentFn (file, object) {
                var index = object.attachment.indexOf(file);
                if (index > -1) {
                    object.attachment.splice(index, 1);
                }
                $scope.attachmentDirty = true;
            }

            function attachResetFn() {
                $scope.attachItem = {
                    name: '',
                    description: ''
                };
            }

            function showFilesFn(files) {
                $scope.attachItem.name = files[0].name;
            }

            function uploadFileFn(object, response) {
                if (!$scope[object]) $scope[object] = {};
                if (!$scope[object].attachment) {
                    $scope[object].attachment = [];
                }
                if (!response.data.result || !response.data.result[0]) return;
                $scope[object].attachment.push({
                    "id": response.data.result[0].id,
                    "contentLenght": response.data.result[0].contentLenght,
                    "fileType": 0,
                    "name": $scope.attachItem.name,
                    "description": $scope.attachItem.description,
                    "createdDate":response.data.result[0].createdDate
                });
                $scope.attachReset();
                $scope.addAttach = 'hide';
                $scope.attachmentDirty = true;
                //ie 11 баг снятие фокуса. Иначе не нажат кнопки
                window.focus();
                if (document.activeElement) {
                    document.activeElement.blur();
                }
                if ($scope.form.mainForm) $scope.form.mainForm.$setDirty();
                if ($scope.form.profileForm) $scope.form.profileForm.$setDirty();
            }

            function changeStatusToggleFn(status){
                if ($scope.status == status) {
                    $scope.alertId = 'dismiss';
                    $scope.status = '';
                    offerSrv.changeStatus($scope.offer.id, status)
                        .then(function (responseStatus) {
                            if (responseStatus.data.success) {
                                $scope.alertId = 'purchasePublished';
                                setTimeout(function () {
                                    $state.go("offers.view", {
                                        id: $scope.offer.id,
                                        noScroll: false,
                                        params: {editMode: false}
                                    },
                                    {reload: true});
                                }, 4000);
                            } else {
                                dialogSrv.openModal(
                                    '_dialogMessage.html',
                                    {message: responseStatus.data.message}
                                );
                                $scope.disabledBtn = false;
                            }
                        });
                } else {
                    $scope.status = status;
                    $scope.alertId = status;
                }
            }

            $scope.setEndDateTitle = function (event) {
                event.target.title = $scope.form.mainForm.endDate.$invalid ? systemMessages.INCORRECT_DATETIME : '';
            };

            function changeStatusFn(id, type, status, alertId) {
                $scope.submitted = true;
                if (
                    type === 'offer' &&
                    alertId === 'offerSend' &&
                    ['eshop_sent', 'eshop_published'].indexOf(status) >= 0
                ) {
                    if ($scope.form.mainForm.endDate && $scope.form.mainForm.endDate.$invalid) {
                        $scope.alertId = "offerSaveError";
                        $scope.saveErrorTxt = $scope.rzd_market ? systemMessages.INCORRECT_DATETIME : systemMessages.DATETIME_FORMAT;
                        return;
                    }
                    if (!$scope.form.mainForm.$valid || !positionSrv.checkQuantity($scope, 'offer')) {
                        $scope.alertId = 'validError';
                        return;
                    }
                    if (!$scope.offer.positions || angular.equals($scope.offer.positions, [])) {
                        $scope.alertId2 = 'emptyPositions';
                        return;
                    }

                    if (!checkPersonalDataAgreement()) {
                       return;
                    }

                    //проверяем размер общей суммы перед сохранением
                    var mask = /^[0-9]{0,13}(?:\.[0-9]{0,2})?$/,
                        summaTest = $scope.offer.summa;
                    if (!mask.test(summaTest)) {
                        dialogSrv.showDialogMsg($scope, systemMessages.DOCUMENT_SUM_MAX);
                        return;
                    }
                    doChangeStatus();
                } else {
                    doChangeStatus();
                }

                function doChangeStatus() {
                    $scope.disabledBtn = true;
                    //выставляем дату публикации
                    $scope.offer.publicDate = desktopSrv.dateMsk();
                    $scope.offerPublishedText = systemMessages.OFFER_PUBLISHED_2;
                    var data = $scope.offer;
                    if ($scope.tmpIdDocLog) {
                        data.tmpIdDocLog = $scope.tmpIdDocLog;
                    }
                    data.deliveryRegionName = dictionarySrv.getRegionNames(data.deliveryRegion);
                    offerSrv.storeOffer(data).then(function (response) {
                        $scope.submitted = false;
                        if (response.data.success) {
                            offerSrv.loadOfferById($scope.offer.id).then(function (response) {
                                $scope.offer = response.data.result;
                                offerSrv.changeStatus($scope.offer.id, status).then(function (responseLoad) {
                                        if (responseLoad.data.success) {
                                            $scope.alertId = alertId;
                                            toggleDisabledFn(false);
                                            $scope.disabledBtn = false;
                                        } else {
                                            $scope.alertId = 'offerSaveError';
                                            $scope.saveErrorTxt = responseLoad.data.message;
                                        }
                                    },
                                    function (error) {
                                        console.error(error);
                                    });
                            }, function (error) {
                                console.error(error);
                            });
                        } else {
                            processErrorResponse(response)
                        }
                    }, function (error) {
                        console.error(error);
                    });
                }
                $scope.disabledBtn = false;
            }

            function publicOfferFn(offerId) {

                if ($scope.form.mainForm.endDate) {
                    var endDate = new Date(angular.copy($scope.offer.endDate)).setSeconds(0, 0),
                        nowDate = new Date().setSeconds(0, 0);
                    if (endDate && nowDate >= endDate) {
                        $scope.form.mainForm.endDate.$setValidity("endDate", false);
                        $scope.form.mainForm.endDate.$setDirty();
                        $scope.alertId = "offerSaveError";
                        $scope.saveErrorTxt = systemMessages.INCORRECT_DATETIME;
                        return;
                    }
                }

                // Отдельно проверяем radio-buttons
                if(positionSrv.isPosProcessing() && positionSrv.getPositionScope()?.form?.positionForm?.inlineRadioOptions?.$modelValue!==$scope.offerItem?.type) {
                    $scope.alertId = "offerSaveError";
                    $scope.saveErrorTxt = systemMessages.POS_CHANGED;
                    positionSrv.setOfferScope($scope);
                    return;
                }

                checkPositionEditConfirm().then(function(){
                    $scope.changeStatus(offerId, 'offer', 'eshop_published', 'offerSend');
                });
            }

            let saveProcess = false;
            function saveOfferFn(alertId, goOffersMy) {
                if(saveProcess) return;
                saveProcess = true;
                var i; //переменная для циклов

                $scope.submitted = true;
                if ($scope.form.mainForm.endDate && $scope.form.mainForm.endDate.$invalid) {
                    $scope.alertId = "offerSaveError";
                    $scope.saveErrorTxt = systemMessages.INCORRECT_DATETIME;
                    saveProcess = false;
                    return;
                }
                if (!$scope.form.mainForm.$valid || !positionSrv.checkQuantity($scope, 'offer')) {
                    if (!$scope.offer.positions || angular.equals($scope.offer.positions, [])) {
                        $scope.alertId2 = 'emptyPositions';
                    }
                    $scope.alertId = 'validError';
                    saveProcess = false;
                    return;
                }
                if (!$scope.offer.positions || angular.equals($scope.offer.positions, [])) {
                    $scope.alertId2 = 'emptyPositions';
                    saveProcess = false;
                    return;
                }
                if (positionSrv.isPosProcessing() && positionSrv.checkPositionChanged($scope.offer.positions, $scope.offerItem)) {
                    $scope.alertId = "offerSaveError";
                    $scope.saveErrorTxt = systemMessages.POS_CHANGED;
                    positionSrv.setOfferScope($scope);
                    saveProcess = false;
                    return;
                } 

                if (!checkPersonalDataAgreement()) {
                    saveProcess = false;
                    return;
                }

                for (i = 0; i < $scope.offer.positions.length; i++) {
                    var pos = $scope.offer.positions[i];
                    if (!pos.okpd2 || !pos.name || !pos.params || !pos.measure || !pos.price || (!pos.vatRate && pos.vatRate != 0) || !pos.volume) {
                        $scope.alertId = 'offerSaveError';
                        $scope.saveErrorTxt = systemMessages.REQUIRED_FIELDS;
                        saveProcess = false;
                        return;
                    }
                }

                //проверяем размер общей суммы перед сохранением
                var mask = /^[0-9]{0,13}(?:\.[0-9]{0,2})?$/,
                    summaTest = $scope.offer.summa;
                if (!mask.test(summaTest)) {
                    dialogSrv.showDialogMsg($scope, systemMessages.DOCUMENT_SUM_MAX);
                    saveProcess = false;
                    return;
                }
                $scope.disabledBtn = true;

                //пересчитываем позиции от 1 до n, для точной последовательности, чтоб не отслеживать импорт\удаление\добавление
                var data = angular.copy($scope.offer);
                $scope.offerSavedText =  systemMessages.OFFER_SAVED_2;
                $scope.positionCounter = 0;
                for (i = 0; i < data.positions.length; ++i) {
                    var p = data.positions[i];
                    p.seqNumber = ++$scope.positionCounter;
                }
                if ($scope.tmpIdDocLog) {
                    data.tmpIdDocLog = $scope.tmpIdDocLog;
                }

                //СОХРАНЯЕМ deliveryRegionName (возможно легаси)
                data.deliveryRegionName = dictionarySrv.getRegionNames(data.deliveryRegion);
                if (Array.isArray(data.deliveryRegion))
                    data.deliveryRegion = data.deliveryRegion.join(',');
                offerSrv.storeOffer(data).then(function (response) {
                        if (response.data.success) {
                            $scope.offer.id = response.data.result;
                            offerSrv.loadOfferById($scope.offer.id).then(function (responseLoad) {
                                    $scope.offer = responseLoad.data.result;
                                    positionSrv.checkPositionsVatRate($scope, 'offer');
                                    if (alertId == 'offerSave') $scope.offer.statusEng = 'eshop_notpublished';//?
                                    $scope.offerCreateMode = '';//?
                                    $scope.alertId = alertId;
                                    if ($scope.form.mainForm) $scope.form.mainForm.$setPristine();
                                    $scope.disabledBtn = false;
                                    $scope.attachmentDirty = false;
                                    $scope.blockBtnDifference = true;
                                    saveProcess = false;
                                    if (alertId === "offerPublished")
                                        toggleDisabledFn();
                                    if(goOffersMy){
                                     	$state.go("offersMy");
                                    }
                                },
                                function (error) {
                                    saveProcess = false;
                                    console.error(error);
                                }
                            );
                        } else {
                            processErrorResponse(response);
                        }
                    }, function (error) {
                        saveProcess = false;
                        console.error(error);
                    }
                )
            }

            function toggleDisabledFn(nextMode) {
                var currentMode = typeof nextMode == "undefined" ? !editMode : nextMode,
                    stateName = "offers.view";
                $state.go(stateName, {
                    id: $scope.offer.id,
                    params: {editMode: currentMode}
                }, {reload: true});
            }

            function processErrorResponse(response) {
                $scope.saveErrorTxt = response.data.message;
                $scope.alertId = 'offerSaveError';
                $scope.disabledBtn = false;
                $scope.form && $scope.form.mainForm.$setPristine();
                saveProcess = false;
            }

            function checkOfferEditConfirm(){
                var deferred = $q.defer();
                var isSave = $scope.state == 'offers.create' || $scope.offer.statusEng == 'eshop_notpublished';
                if (($scope.form.mainForm|| {}).$dirty) {
                    dialogSrv.openModal('_confirmInfoExt.html', {
                        message: systemMessages.DATA_WONTBE_SAVED,
                        buttons: [{
                            title: isSave ? "Сохранить" : "Сохранить и опубликовать",
                            btnClass: "btn-success",
                            closeValue: false,
                            action: function () {
                                if(isSave)
                                    $scope.saveOffer('offerSave', true);
                                else
                                    $scope.changeStatus($scope.offer.id, 'offer', 'eshop_published', 'offerSend');
                                deferred.resolve(false);
                            }
                        },{
                            title: "Не сохранять",
                            btnClass: "btn-danger",
                            closeValue: true,
                            action: function () {
                                positionSrv.positionClose($scope, 'offerItem');
                            }
                        },{
                            title: "Отмена",
                            btnClass: "btn-light",
                            closeValue: false
                        }
                    ]}).then(function (model) {
                        if(model && model.close)
                            deferred.resolve(true);
                    });
                } else {
                    deferred.resolve(true);
                }
                return deferred.promise;
            }

            function checkPositionEditConfirm(){
                var deferred = $q.defer();
                if ((($scope.form.mainForm || {})['form.positionForm'] || {}).$dirty ||
                    ((($scope.form.mainForm || {})['form.positionForm'] || {}).inlineRadioOptions && (($scope.form.mainForm || {})['form.positionForm'] || {}).inlineRadioOptions?.$modelValue!==$scope.offerItem?.type)) {
                    dialogSrv.openModal('_confirmInfoExt.html', {
                        message: systemMessages.POS_WONTBE_SAVED,
                        buttons: [{
                            title: "Сохранить",
                            btnClass: "btn-success",
                            closeValue: false,
                            action: function () {
                                $scope.$broadcast('position.save');
                                setTimeout(function(){
                                    if (!(($scope.form.mainForm || {})['form.positionForm'] || {}).$dirty){
                                        deferred.resolve(false);
                                    }
                                }, 0);
                            }
                        },{
                            title: "Не сохранять",
                            btnClass: "btn-danger",
                            closeValue: true
                        },{
                            title: "Отмена",
                            btnClass: "btn-light",
                            closeValue: false
                        }]
                    }).then(function (model) {
                        if(model && model.close)
                            deferred.resolve( true);
                    });
                } else {
                    deferred.resolve(false);
                }
                return deferred.promise;
            }

            function positionShowFn(docType) {
                checkPositionEditConfirm().then(function(isDirty){
                    if(isDirty){
                        $scope.form.mainForm['form.positionForm'].$setPristine();
                    }
                    scrollToNewPosition();
                    positionSrv.positionShow($scope, docType);
                });
            }

            function positionDeleteFn(docType, item) {
                positionSrv.positionDelete($scope, docType, item);
                $scope.offer.nonNds = positionSrv.checkDocNds($scope.offer.positions);
            }

            function positionEditFn(docType, item) {
                checkPositionEditConfirm().then(function () {
                    positionSrv.positionEdit($scope, docType, item);
                });
            }

            function positionCloseFn() {
                positionSrv.positionClose($scope, 'offerItem');
            }

            function saveToDocFn(item) {
                if ($scope.positionStatus != 'edit') {
                    if (!$scope.offer.positions) {
                        $scope.offer.positions = []
                    }
                    $scope.positionCounter = $scope.offer.positions.length;
                    $scope.offerItem = angular.copy(item);
                    $scope.offerItem.seqNumber = ++$scope.positionCounter;
                    $scope.offer.positions.push($scope.offerItem);
                } else {
                    var i;
                    var a = $scope.offer.positions,
                        offerItem = angular.copy(item);
                    for (i = 0; i < a.length; ++i) {
                        if (angular.equals(a[i].seqNumber, offerItem.seqNumber)) {
                            $scope.offer.positions[i] = offerItem;
                        }
                    }
                }
                $scope.positionStatus = 'hide';
                $scope.offer.summa = positionSrv.getTotal($scope.offer.positions, 'summa');
                $scope.offer.summaNds = positionSrv.getTotal($scope.offer.positions, 'summaNds', 'summa');
                $scope.offer.nonNds = positionSrv.checkDocNds($scope.offer.positions);
            }

            function excelImportFn() {
                $scope.btnDelete = {};
                $scope.addXLS = !$scope.addXLS;
            }

            function addAttachShowFn() {
                $scope.addAttach = 'show';
            }

            function scrollToNewPosition() {
                var scrollTop = window.pageYOffset;
                var posY = document.querySelector('#add_pos').getBoundingClientRect().top + scrollTop - 100;
                window.scrollTo(0, posY);
            }

            function loadOfferByIdFn(editMode) {
                if (ctrl.offerData.status == 401 || (ctrl.offerData.status == 200 && ctrl.offerData.data.errorCode == "402")) {
                    console.log("Пользователь не имеет прав для открытия данного документа");
                    $state.go("offers");
                    return;
                }
                if (!ctrl.offerData.data.success) {
                    console.log(ctrl.offerData.data.message);
                    $state.go("offers");
                    return;
                }
                if (ctrl.offerData.data.result.statusEng == 'eshop_notpublished' && (!ctrl.offerData.data.result.supplier || ctrl.offerData.data.result.supplier.id != $scope.user.id)) {
                    console.log("Пользователь не имеет прав для открытия черновика данного документа");
                    $state.go("offers");
                    return;
                }

                $scope.hiddenControl = {};
                $scope.navTabs = 'info';
                $scope.offer = ctrl.offerData.data.result;

                if ($scope.state==='offers.view' && editMode && $scope.offer.statusEng != "eshop_notpublished") {
                    $scope.offer.personalDataAgreement = false;
                }

                $scope.activePanel = 'info';
                if ($scope.state==='offers.view' && !editMode && $scope.offer.statusEng != "eshop_notpublished")
                    $scope.activePanel = 'position';

                $scope.btnDelete = {};
                $scope.positionSeqNumber = '';
                $scope.offer.nonNds = positionSrv.checkDocNds($scope.offer.positions);
                positionSrv.checkPositionsVatRate($scope, 'offer');
                $scope.navTabsShow = !editMode && $scope.offer.supplier.id == $scope.user.id ;
                $scope.positionCounter = $scope.offer.positions ? $scope.offer.positions.length : 0;
                $scope.disableForm = editMode ? !editMode : $scope.offer.statusEng != 'eshop_notpublished';
                window.scrollTo(0, 0);

                if (tabName) {
                    $scope.navTabs = tabName;
                }
            }

            function _initDict(dictList, reload) {
                angular.forEach(dictList, function (name) {
                    dictionarySrv.getDictionaryRemote(name, reload).then(function (dict) {
                        $scope.dictionary[name] = dict;
                    })
                })
            }

            function initFn() {
                $scope.state = $state.current.name;
                $scope.systemMessages = systemMessages;
                $scope.offer = {};
                $scope.attachReset();
                $scope.dateOptions = {
                    minDate: new Date(),
                    maxDate: new Date(new Date(new Date().setHours(0, 0, 0, 0)).getTime() + 1000 * 60 * 60 * 24 * 90 - 1),
                    term: 90
                };
                $scope.cabType = desktopSrv.getCabType();
                $scope.dictionary = dictionarySrv.getRemoteData();
                $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                _initDict(['fias_region', 'okei'], false);
                if (["customer", "participant"].includes($scope.cabType)){
                    cartSrv.loadCart();
                }
                $scope.auth = authSrv.getAuth();
                $scope.user = authSrv.getUser();
                $scope.positionCounter = 0;
                $scope.disableForm = false;
                $scope.supplierInfo = {showHide: false};
                $scope.positionSeqNumber = '';
                $scope.constants = constants;
                $scope.form = {};
                $scope.btnDelete = {};
                $scope.blockBtnDifference = $scope.state != 'offers.create';



                //positionSrv.setPosProcessing(false);
                $scope.paginator = {
                    itemsPerPage: 20,
                    page: 0
                };
                $scope.filialsList = [];
                if ($scope.cabType === 'participant') {
                    profileSrv.loadFiliationList($scope.paginator).then(function (response) {
                        if (response.data.success) {
                            $scope.filialsList = response.data.items.filter(function (filial) {
                                return !filial.locked;
                            });

                        }
                    });
                }
                if ($stateParams.id && ctrl.offerData) {
                    if ($rootScope.cabType == 'home')
                        $scope.user = {};
                    loadOfferByIdFn(editMode);

                } else {
                    setUserDetails();
                    offerSrv.createDraftOffer().then(function (id) {
                        $scope.offer.id = id;
                        $scope.offer.status = "eshop_draft";
                    });
                    $scope.disableForm = false;
                }
                $scope.box = {pictures: []};

                $scope.modelView = {
                    consentProcessing: $scope.offer.personalDataAgreement,
                    consentDissemination: $scope.offer.personalDataAgreement
                };
            }

            function setUserDetails() {
                $scope.offer.contactPerson = $scope.user.lastName + ' ' + $scope.user.firstName + (!$scope.user.middleName ? '' : ' ' + $scope.user.middleName);
                $scope.offer.email = $scope.user.email;
                $scope.offer.supplier = {
                    id: $scope.user.id,
                    kpp: $scope.user.kpp,
                    fullName: $scope.user.fullName,
                    site: $scope.constants.configProject.type,
                    typeOrg: $scope.user.typeOrg
                }
                $scope.offer.supplier[$scope.user.typeOrg == 'nr' ? 'uin' : 'inn'] = $scope.user.inn;
            }

            function checkPersonalDataAgreement() {
                if(!$scope.modelView.consentProcessing || !$scope.modelView.consentDissemination){
                    $scope.alertId = "offerSaveError";
                    $scope.saveErrorTxt = !$scope.modelView.consentProcessing && !$scope.modelView.consentDissemination ? systemMessages.PERSONAL_DATA_PROCESSING_AND_DISSEMINATION :
                        (!$scope.modelView.consentProcessing ? systemMessages.PERSONAL_DATA_PROCESSING : systemMessages.PERSONAL_DATA_DISSEMINATION);
                    return false;
                }

                $scope.offer.personalDataAgreement = true;
                return true;
            }

            $scope.getSupplierInfo = (offer) => {
                let res = offer.supplier.fullName && !offer.filial ? offer.supplier.fullName : '';
                if(offer.filial && offer.filial.id) res += offer.supplier.fullName;
                if(offer.supplier.inn && !offer.filial) res += (', ИНН ' + offer.supplier.inn + (offer.supplier.kpp ? ', КПП ' + offer.supplier.kpp : ''));
                if(offer.filial && offer.filial.id) res += (', ИНН ' + offer.supplier.inn + ', КПП ' + offer.filial.kpp);
                if(offer.supplierMspCategory && offer.supplierMspCategory != 'UNDEFINED'  && !offer.filial) res += ' (МСП) ';
                return res;
            }

        }]
});
