/**
 * @author: alexandrbu
 * Сервис для компоненты БГ участники
 */
angular.module('uetp').service('bgParticipantsSrv', ['$state', 'httpSrv',
    function ($state, httpSrv) {
        this.loadOrderList = loadOrderListFn;
        this.loadLimitList = loadLimitListFn;
        this.refreshAllLimits =  refreshAllLimitsFn;
        this.exportExcel = exportExcelFn;
        this.isRefreshAllLimitsRunning = isRefreshAllLimitsRunningFn;
        /**
         * Получение списка анкет БГ участников
         * @param id
         * @returns {*}
         */
        function loadOrderListFn(paginator) {
            let url = paginator.bank === 'ra' ? '/bankguarantee/rf/orders/getOrders'
                        : paginator.bank === 'lo' ? '/bankguarantee/lk/orders/getOrders'
                        : paginator.bank === 'vtb' ? '/bankguarantee/vtb/orders/ordersByParticipants'
                            : '/bankguarantee/orders/ordersByParticipants'
            return httpSrv.http({
                method: 'POST',
                url: url,
                data: paginator
            })

        }

        /**
         * Получение списка лимитов участников
         * @param paginator
         * @returns {*}
         */
        function loadLimitListFn(paginator) {
           return httpSrv.http({
                method: 'POST',
                url: '/bankguarantee/orders/limitsByParticipants',
                data: paginator
            })
        }
        
        function refreshAllLimitsFn() {
            return httpSrv.http({
                 method: 'POST',
                 url: '/bankguarantee/orders/limitInfoRefreshAll'
             })
        }
        
        function isRefreshAllLimitsRunningFn() {
            return httpSrv.http({
                 method: 'GET',
                 url: '/bankguarantee/orders/isLimitInfoRefreshAllRunning'
             })
        }
        
        function exportExcelFn(param) {
     	    httpSrv.downloadFile('/bankguarantee/orders/downloadLimitInfoXlsx', '', param);
        }


    }]);