/**
 * @author: artem.v
 * Активация ссылок в тексте. Заменяем " https://+" и " http://+" на "<a href=''>+<>"
 */
angular.module('uetp').filter('linksActivating', function () {
	return function (text, blank= false) {
		return text ? text.replace(
			/(https|http):\/\/[\S]{0,}/g,
			function (match, p1, offset, string) {
				if(string.contains('<a') && offset>6 && string.substr(offset-6, 5)==='href=')
					return match;
				const link = match.endsWith('.') ? match.substr(0, match.length-1) : match;
				return '<a href="'+link+'" ' + (blank ? 'target="_blank"' : '')+'>'+link+'</a>'+(match.endsWith('.') ? '.':'');
			}
		): '';
	};
});
