/**
 * Кнопка кабинета
 */
angular.module('uetp')
    .component('cabinet', {
          templateUrl:['constants', function (constants) {
            return 'app/components/header/cabinet/' + constants.configProject.type + '.html'
        }],
        controller: ['$scope', '$rootScope', '$state', 'authSrv', 'desktopSrv', 'countSrv', 'tariffsSrv', 'permissionSrv',
            function ($scope, $rootScope, $state, authSrv, desktopSrv, countSrv, tariffsSrv, permissionSrv) {
                this.$onInit = function () {
                    init();
                    $scope.counters = countSrv.getCounters();
                };
                $scope.$state = $state;
                $scope.auth = authSrv.getAuth();
                $scope.exit = authSrv.exit;
                $scope.isBGEnabled = desktopSrv.isBGEnabled;
                $scope.hasPermission = permissionSrv.hasPermission;
                $scope.hasPurchasePlanUpdatePermission = permissionSrv.hasPurchasePlanUpdatePermission;
                $scope.isPurchasePlanVisible = function () {
                    return authSrv.isPurchasePlanVisible($scope.cabType(), authSrv.getUser());
                };
                
                $scope.status = {
                    isOpen: false
                };
                $scope.showPanel = tariffsSrv.getShowPanel;

                var onStateChanged = $rootScope.$on("stateChanged", function () {
                    authSrv.checkedAuth().then(function (auth) {
                        $scope.auth = auth;
                    });
                });
                var onLogin =  $rootScope.$on("login", function () {
                    //обновляем модель для первого входа в лк после очистки кэша
                    $scope.user = authSrv.getUser();
                });
                function init() {
                    $scope.user = authSrv.getUser();
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.status = {
                        isOpen: false
                    };
                    $scope.$on('$destroy', function () {
                        onStateChanged();
                        onLogin();
                    })
                }
            }]
    });

