/**
 * @author: sergeysi
 * Сервис для компонентов Агента для банковских гарантий
 */
angular.module('uetp').service('bgAgentSrv', ['$state', 'httpSrv',
    function ($state, httpSrv) {
        var organizatorInfo = {};

        /**
         * Получить Организатора по его ID
         */
        this.getOperatorById = function (id) {
            return httpSrv.http({
                method: 'GET',
                url: '/admin/getBgAgentInfoById/' + id
            });
        };
        /**
         * Сохранение или обновления данных по организатору
         */
        this.createOrUpdate = function (data) {
            return httpSrv.http({
                url: '/admin/registrationBGAgent',
                data: data
            });
        };
        /**
         * Выставление полученных данныех по организатору
         */
        this.setOrganizatorInfo = function (info) {
            organizatorInfo = info;
        };
        /**
         * Получение данных по организатору
         */
        this.getOrganizatorInfo = function () {
            return organizatorInfo;
        };
        /**
         * Создание нового представителя организатора
         * @param id - идентификатор организатора
         * @param agent - регистрируемы пользователь организатора
         * @returns {*}
         */
        this.saveNewAgent = function (id, agent) {
            return httpSrv.http({
                url: '/admin/registrationUserBgAgent/'+id,
                data: agent,
                block: true
            })
        };
        /**
         * Сохранение назначенных представителю организатора заказчиков
         * @param id - идентификатор представителя организатора
         * @param data - список назначенных заказчиков представителю организатора
         * @returns {*}
         */
        this.saveUserCustomer = function (id, data) {
            return httpSrv.http({
                url: 'admin/customer/assignCustomersToUser/' + id,
                data: {assignedCustomer: data}
            })
        };
        /**
         * Сохранение назначенных организатору заказчиков
         * @param id - идентификатор организатора
         * @param data - список назначенных заказчиков организатору
         * @returns {*}
         */
        this.saveOrgCustomer = function (id, data) {
            return httpSrv.http({
                url: 'admin/customer/assignCustomersToOrganizer/' + id,
                data: {assignedCustomer: data}
            })
        }
        /**
         * Сохранение назначенных организатору орагнизаторов
         * @param id - идентификатор представителя организатора
         * @param data - список назначенных заказчиков организатору
         * @returns {*}
         */
        this.saveUserOrganizer = function (id, data) {
            return httpSrv.http({
                url: 'admin/customer/assignOrganizerToUser/' + id,
                data: {assignedOrganizer: data}
            })
        };
        /**
         * Сохранение назначенных представителю отчетов
         * @param id - идентификатор представителя организатора
         * @param data - список назначенных отчетов
         * @returns {*}
         */
        this.saveUserAnalyticReport = function (id, data) {
            return httpSrv.http({
                url: 'admin/assignAnalyticReportsToUser/' + id,
                data: data
            })
        };
        /**
         * Получение списка назначенных организатору заказчиков, плюс получения всего списка заказчиков
         * @param id - идентификатор организатора
         */
        this.getDataOrgCustomer = function (id) {
            return httpSrv.http({
                method: 'GET',
                url: 'admin/customer/customerListByOrganization/' + id
            })
        };

        /**
         * Получение списка назначенных организатору заказчиков, плюс получения всего списка заказчиков
         */
        this.getCustomers = function () {
            return httpSrv.http({
                method: 'GET',
                url: '/organizer/customers/getCustomers'
            })
        };

        /**
         * Получение списка рассылки с участниками по идентификатору
         */
        this.getMailingListById = function (id) {
            return httpSrv.http({
                method: 'GET',
                url: '/organizer/mailinglists/get/' + id
            })
        };

        /**
         * Добавление списка рассылки
         */
        this.saveMailingList = function (title) {
            return httpSrv.http({
                method: 'post',
                url: '/organizer/mailinglists/addList',
                data:{
                    title: title
                }
            })
        };

        /**
         * Переименование списка рассылки
         */
        this.renameMailingList = function (id,title) {
            return httpSrv.http({
                method: 'post',
                url: '/organizer/mailinglists/renameList/' + id,
                data: title
            })
        };

        /**
         * Удаление списка рассылки
         */
        this.deleteMailingList = function (id) {
            return httpSrv.http({
                method: 'GET',
                url: '/organizer/mailinglists/deleteList/' + id
            })
        };

        /**
         * Добавление участника к списку рассылки
         */
        this.addParticipantToMailingList = function (listId,inn) {
            return httpSrv.http({
                method: 'post',
                url: '/organizer/mailinglists/addParticipantToList/' + listId,
                data: inn
            })
        };

        /**
         * Добавление участника к списку рассылки
         */
        this.deleteParticipantFromList = function (listId,id,inn) {
            return httpSrv.http({
                method: 'post',
                url: '/organizer/mailinglists/deleteParticipantFromList/' + listId+'/' + id,
                data: inn
            })
        };


        /**
         * Получение списка назначенных представителю организатора заказчиков, плюс получения всего списка заказчиков
         * @param id - идентификатор представителя организатора
         */
        this.getDataUserCustomer = function (id) {
            return httpSrv.http({
                method: 'GET',
                url: 'admin/customer/customerListByUser/' + id
            })
        };
        /**
         * Получение списка назначенных представителю организаторов, плюс получения всего списка организаторов
         * @param id - идентификатор представителя организатора
         */
        this.getDataOrganizer = function (id) {
            return httpSrv.http({
                method: 'GET',
                url: 'admin/customer/organizerListByUser/' + id
            })
        };

        /**
         * Получение списка назначенных представителю отчетов из раздела "Аналитика", плюс получение всего списка отчетов
         * @param id - идентификатор представителя организатора
         */
        this.getDataAnalyticReports = function (id) {
            return httpSrv.http({
                method: 'GET',
                url: 'admin/analyticReportListByUser/' + id
            })
        };

        /**
         * Получение списка организации, разбитого на группы по коду
         */
        this.getOrganizationsCodeGroups = function () {
            return httpSrv.http({
                method: 'GET',
                url: '/auth/org/getOrganizationsCodeGroups'
            })
        };

        /**
         * Получение списка подразделений
         */
        this.getDivisionList = function () {
            return httpSrv.http({
                method: 'GET',
                url: '/organizer/customers/getDivisionList'
            })
        };

    }]);