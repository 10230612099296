/**
 * Часы и Пользователь ЛК
 */
angular.module('uetp')
    .component('lkinfo', {
        templateUrl: ['constants', function (constants) {
            return 'app/components/lk/lkinfo/' + constants.configProject.type + '.html'
        }],
        controller: ['$scope', '$rootScope', '$state', '$window', '$document', '$uibModal', 'authSrv', 'desktopSrv', 'httpSrv', 'timeout', 'dictionarySrv', 'permissionSrv', 'dialogSrv', 'profileSrv',
            function ($scope, $rootScope, $state, $window, $document, $uibModal, authSrv, desktopSrv, httpSrv, timeout, dictionarySrv, permissionSrv, dialogSrv, profileSrv) {
                this.$onInit = function () {
                    init();
                };
                $scope.$state=$state;
                $scope.exit = authSrv.exit;
                $scope.formatingFullName = formatingFullNameFn;
                $rootScope.$on('userDataChanged', function (event, data) {
                    $scope.user = data;
                    $scope.userType = dictionarySrv.getUserRoleDesc($scope.user.userRole);
                    checkUserType();
                    if(Object.keys($scope.user).length !== 0 && $scope.cabType() !== 'home' && 'profile' !== $state.$current.name){
                        checkUserConsent();
                    }
                });

                $scope.isMobile = () => {
                    return window.innerWidth <= 991;
                };

                $rootScope.$on('navbarCollapse', function(event, state){
                    $scope.hdrOpened = state;
                });

                function init() {
                    $scope.lkMenuOpen = false;
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.user = authSrv.getUser();
                    $scope.model = {};
                    $scope.status = {
                        menuOpen: false
                    };

                    $scope.userType = dictionarySrv.getUserRoleDesc($scope.user.userRole);
                    checkUserType();

                    document.addEventListener('click', function (event) {

                        if (event.target?.parentElement?.classList.contains('dropdown-item')) {
                            event.stopPropagation();
                        } else if (event.target?.id === 'lkMenuBTN' || event.target.closest('.menu-btn'))
                            $scope.lkMenuOpen = !$scope.lkMenuOpen;
                        else if ($scope.lkMenuOpen && !(event.target.closest('#lkMenuNEW') || event.target.closest('#lkMenuNAV') || event.target.closest('#lkMenuBTN')))
                            $scope.lkMenuOpen = false;
                        else if (['A', 'SPAN'].includes(event.target.tagName) && event.target.closest('#lkMenuNAV'))
                            $scope.lkMenuOpen = false;
                        timeout($scope);
                    });
                }

                $scope.lkMenuBtnName = function(){
                    return !$scope.lkMenuOpen ? 'lkMenuBTN':'';
                }

                $scope.hasPermission = permissionSrv.hasPermission;

                function getParticipantProfileInfo() {
                    httpSrv.http({
                        method: 'GET',
                        url: '/auth/user/getParticipantProfileInfo'
                    }).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.participantProfileInfo = response.data.result;
                        } else {
                            $scope.participantProfileInfo = 'EMPTY'
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }

                function checkUserType() {
                    if ($scope.userType === 'Участник')
                        getParticipantProfileInfo();
                }
                function checkUserConsent() {
                    if(!$scope.user.consentProcessingDate || !$scope.user.consentDisseminationDate){
                        $scope.consentModal = $scope.consentModal || $uibModal.open({
                            ariaLabelledBy: 'modal-title',
                            ariaDescribedBy: 'modal-body',
                            backdrop  : 'static',
                            keyboard  : false,
                            size: 'md',
                            templateUrl: '_consentModal.html',
                            scope: $scope,
                            controller:['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                                $scope.model = {};
                                profileSrv.loadOrganizationInfo().then((userData) => {
                                    if (userData.data.success)
                                        $scope.consentUserEmail = userData.data.result.email;
                                });
                                $scope.saveUserConsent = function (model) {
                                    if($scope.consentUserEmail)
                                        model.email = $scope.consentUserEmail;
                                    let data = angular.copy(model);
                                    data.consentProcessingDate = data.consentProcessingDate || new Date().getTime();
                                    data.consentDisseminationDate = data.consentDisseminationDate || new Date().getTime();
                                    const userData = authSrv.prepareUserDataForUpdate(data);
                                    return profileSrv.updateUserInfo(userData).then(function (response) {
                                        if (response.data.success) {
                                            authSrv.setUser(data);
                                            $uibModalInstance.close();
                                        } else {
                                            desktopSrv.showDialogMsg($scope, response.data.message);
                                        }
                                    }, function errorCallback(response) {
                                        console.log(response);
                                    });
                                };
                            }]
                        }).result.then(function(){
                            delete $scope.consentModal;
                        });
                    }
                }

                function formatingFullNameFn(text) {
                    var length = document.documentElement.clientWidth < 992 ? 15 : 40;
                    return text.length > length ? text.slice(0,length) + '...' : text
                }
                $scope.excludeZeroComita = (el) => !(el.availableBalance===0 && el.id.indexOf('comita')!==-1)
                $scope.isSingleOperator = () => $scope.participantProfileInfo.accounts.filter(el=>$scope.excludeZeroComita(el)).length===1
            }]
    });
