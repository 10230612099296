/**
 * @author dmitrys
 * Вопрос-ответ
 */
angular.module('uetp')
    .component('documentSamplesCreate', {
        templateUrl: 'app/components/help/documentSamples/documentSamplesCreate.html',
        controller: ['$scope', '$rootScope', 'constants', '$state', '$stateParams', 'documentSamplesSrv', 'httpSrv', 'dialogSrv', 'alertSrv', 'desktopSrv','needConfirmSrv','dictionarySrv',
            function ($scope, $rootScope, constants, $state, $stateParams, documentSamplesSrv, httpSrv, dialogSrv, alertSrv, desktopSrv,needConfirmSrv,dictionarySrv) {

                $scope.publish = publishFn;
                $scope.cabType = desktopSrv.getCabType;
                $scope.cancel = cancelFn;
                $scope.downloadFile = httpSrv.downloadFile;
                init();



                function publishFn() {
                    $scope.submitted = true;
                    if (!$scope.form.mainForm.$valid) {
                        $scope.alertObjDocumentSamples = alertSrv.getErrorMsg('Заполните все обязательные поля!');
                        return;
                    }
                    documentSamplesSrv.publish($scope.model).then(function (response) {
                        if (response.data.success) {
                            $scope.submitted = false;
                            $scope.model.status = 'published';
                            $scope.alertObjDocumentSamples = alertSrv.getSuccessMsg('Образец документа опубликован!');
                        }
                    });
                }
                /**
                 * Вложение - возможно необходимо создать компонент?
                 */
                $scope.showFiles = function (files) {
                    $scope.attachItem.name = files[0].name;
                };

                $scope.onSizeError = function (files) {
                    $scope.alertObjDocumentSamplesFile = alertSrv.getErrorMsg("Файл " + files[0].name + " не добавлен. Превышен максимально разрешенный размер файла (30МБ)!");
                };
                $scope.onFileNameError = function (files) {
                    $scope.alertObjDocumentSamplesFile = alertSrv.getErrorMsg("Файл " + files[0].name + " не добавлен. Добавление файлов с расширением *.exe запрещено!");
                };
                $scope.uploadFile = function (response) {
                    $scope.model.attachment={
                        "id": response.data.result.id,
                        "fileType": 0,
                        "name": $scope.attachItem.name,
                        "description": $scope.attachItem.description
                    };
                    attachReset();
                    $scope.addAttach = '';
                    //ie 11 баг снятие фокуса. Иначе не нажат кнопки
                    window.focus();
                    if (document.activeElement) {
                        document.activeElement.blur();
                    }

                    if ($scope.form.mainForm) {
                        $scope.form.mainForm.$setDirty();
                    }
                };
                $scope.deleteAttach = function (file, object) {
                    documentSamplesSrv.deleteAttach(file.id).then(function (response) {
                        if (response.data.success) {
                                object.attachment = {};
                            if ($scope.form.mainForm) {
                                $scope.form.mainForm.$setDirty();
                            }
                        } else {
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    })
                };
                function attachReset() {
                    $scope.attachItem = {
                        name: '',
                        description: ''
                    };
                }
                function init() {
                    $scope.model = {};
                    $scope.form = {mainForm: ''};
                    if ($scope.form && $scope.form.mainForm) $scope.form.mainForm.$setPristine();
                    if ($scope.form && $scope.form.mainForm) $scope.form.mainForm.$setUntouched();
                    attachReset();
                    $scope.addAttach = '';
                    $scope.state = $state.current.name;
                    $scope.idDocTime = new Date().getTime();
                    dictionarySrv.getDictionaryRemote('CATEGORY_doc', false).then(function () {
                        $scope.dictionary = dictionarySrv.getRemoteData();
                    });
                }

                function goBackFn(){
                    history.back();
                }
                function cancelFn() {
                    if($scope.form.mainForm.$dirty && $scope.model.status != 'published'){
                        needConfirmSrv.call(goBackFn,[],'Внесенные изменения сохранены не будут');
                    }else{
                        goBackFn();
                    }
                }

            }]

    });

