/* Выбор региона */

angular.module('uetp').component('regionSelector', {
	templateUrl: 'app/components/regionSelector/regionSelector.html',
	bindings: {
		modelPointer : '<',
		stringJoiner : '<',
		items        : '<',
		required     : '<',
		isDisabled   : '<',
		id           : '@',
		name         : '@',
		allRegionLbl : '<'
	},
	controller: [
		'$scope', 'listService',
		function ($scope, listService) {
			var it = this;

			this.$onChanges = function () {
				$scope.isDisabled = it.isDisabled;
				var newData = {};
				newData.selectedItems = listService._(it.modelPointer);
				//Если пришла строка
				if (newData.selectedItems && typeof newData.selectedItems === 'string') {
					if (it.stringJoiner === undefined) it.stringJoiner = ',';
					newData.selectedItems = newData.selectedItems.split(it.stringJoiner);
				}
				//Если пришел список со всеми элементами
				if (newData.selectedItems && it.items && newData.selectedItems.length === it.items.length) {
					newData.selectedItems = [null];
					newData.allRegions = true;
				} else {
					newData.allRegions = false;
				}
				$scope.data = $scope.data || {};
				if($scope.data.selectedItems && newData.selectedItems.toString() == $scope.data.selectedItems.toString()
					 && newData.allRegions == $scope.data.allRegions)
					return;
				$scope.data = newData;
			};

			this.$onInit = function () {
				//DEFAULT
				if (it.stringJoiner === undefined) it.stringJoiner = ',';

				$scope.required   = it.required;
				$scope.id         = it.id;
				$scope.name       = it.name;
				$scope.allRegionLbl = it.allRegionLbl;
				if (!$scope.allRegionLbl) {
					$scope.allRegionLbl = 'Все регионы';
				}

				function addAllToModel() {
					var allItems = [];
					for (var i=0; i < it.items.length; i++) {
						allItems[i] = it.items[i].code;
					}
					listService._(
						it.modelPointer,
						it.stringJoiner ? allItems.join(it.stringJoiner) : allItems
					);
				}

				$scope.changeSelected = function (item, isRemove) {
					//Если выбраны все доступные
					if (
						!isRemove && ($scope.data.selectedItems.length === it.items.length || item.code === null)
					) {
						$scope.data.allRegions = true;
						$scope.data.selectedItems = [null];
						addAllToModel();
					} else {
                        $scope.data.selectedItems = $scope.data.selectedItems.sort();
						var allElement = $scope.data.selectedItems.indexOf(null);
						if (allElement >= 0) {
							$scope.data.selectedItems.splice(allElement, 1);
						}
						listService._(
							it.modelPointer,
							it.stringJoiner ? $scope.data.selectedItems.join(it.stringJoiner) : $scope.data.selectedItems
						);
					}
				};

				$scope.changeAll = function () {
					if ($scope.data.allRegions) {
						$scope.data.selectedItems = [null];
						addAllToModel();
					} else {
						$scope.data.selectedItems = [];
						listService._(
							it.modelPointer,
							it.stringJoiner ? $scope.data.selectedItems.join(it.stringJoiner) : $scope.data.selectedItems
						);
					}
				};

				listService.addAllSelector($scope, {
					item        : {aoguid: 'all', formalname: $scope.allRegionLbl != 'Все регионы' ? $scope.allRegionLbl : 'Вся Россия', code: null, okato: '', shortname: ''},
					sourceItems : it.items,
					resultItems : 'regionsList'
				});
			};
		}
	]
});
