angular.module('uetp').directive('denySpaces', function () {
    return {
    	restrict: "A",
    	link: function (scope, element) {
			element.bind('input', function() {
				if (this.value)
					this.value = this.value.replace(/ /g, '')
			});
    	}
    };
});