/**
 * @author dmitrys
 */
angular.module('uetp').directive("extendField", [function () {
    return {
        restrict: 'E',
        templateUrl: 'app/directives/extendField/extendField.html',
        scope: {
            model: "=",
            isReadOnly: "=",
            form:"=" // Форма в которой лежит поле
        },
        replace: true,
        compile: function () {
            return {
                pre: function (scope) {
                    scope.initModel();
                }
            }
        },
        controller: ['$scope', '$filter', '$sce','constants','extendFieldSrv',
            function ($scope, $filter, $sce, constants, extendFieldSrv) {

                $scope.constants = constants;
                $scope.procedureForm = {};
                $scope.submitted = true;
                $scope.getDict = extendFieldSrv.getDict;
                $scope.autoFillCurrency = autoFillCurrencyFn;
                $scope.validField = validFieldFn;
                /*
                * Валидация поля
                * */
                function validFieldFn() {
                    return $scope.model.integrCode && $scope.form[$scope.model.integrCode] && $scope.form[$scope.model.integrCode].$invalid && ( $scope.form[$scope.model.integrCode].$dirty || $scope.submitted)
                }

                $scope.dateOptions = {
                };
                $scope.initModel = function () {
                };
                
                function autoFillCurrencyFn(select) {
                	if(select){
	                    var currency = $scope.model.value = {};
	                    currency.name = select ? select.name : "";
	                    currency.digitalCode = select ? select.code : "";
	                    currency.code = select ? select.pattern : "";
                	}else{
                		delete $scope.model.value;
                	}
                }
            }

        ]
    }
}]);