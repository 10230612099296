/**
 * Уведомление об отмене закупки
 */
angular.module('uetp')
    .component('informationAboutRejectProcedure', {
        templateUrl: 'app/components/procedures/reject/informationAboutRejectProcedure.html',
        bindings: {
            procedureData: '<',
            protocolData: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'proceduresSrv', '$uibModal', 'authSrv', 'httpSrv', 'storageSrv', 'vfSrv', 'needConfirmSrv', 'alertSrv','dictionarySrv', 'dialogSrv', 'protocolSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, proceduresSrv, $uibModal, authSrv, httpSrv, storageSrv, vfSrv, needConfirmSrv, alertSrv, dictionarySrv, dialogSrv, protocolSrv) {
                var ctrl = this;
                $scope.ctrl = ctrl;

                this.$onInit = function () {
                    $scope.isViewOnly = $stateParams.isViewOnly;
                    $scope.maxCountAttachments = 1;
                    $scope.auth = authSrv.getAuth();
                    $scope.supplyInfoMessage = false;
                    $scope.operatorSupplyMsg = true;
                    $scope.winnerPaysInfoMessage = false;
                    $scope.winnerPaysMsg = true;
                    $scope.cabType = desktopSrv.getCabType;

                    $scope.procedure = $scope.ctrl.procedureData.data.result;
                    if ($scope.ctrl.protocolData.data.success) {
                        $scope.protocol = $scope.ctrl.protocolData.data.result;

                        initSupplyInfoMessageFn();
                        initWinnerPaysMessageFn();

                        if (!$scope.isViewOnly) {
                            $scope.rejectionDateOptions =  {
                                maxDate: getServerTimeMsk()
                            };
                            $scope.protocol.document.attachment = [];
                        }
                    } else {
                        dialogSrv.showDialogMsgHtml($scope, $scope.ctrl.protocolData.data.errorMessage, function () {
                            _openProcedure()
                        });
                    }
                };


                $scope.deleteAttach = deleteAttachFn;
                $scope.signSendData = signSendDataFn;
                $scope.uploadSuccess = uploadSuccessFn;
                $scope.downloadFile = downloadFileFn;
                $scope.cancel = cancelFn;
                $scope.initSupplyInfoMessage = initSupplyInfoMessageFn;
                $scope.closeSupplyMsg = closeSupplyMsgFn;

                function uploadSuccessFn(result) {
                    angular.forEach(result, function (item) {
                        $scope.protocol.document.attachment.push({
                            fileName: item.fileName,
                            id: item.id
                        });
                    })
                }

                function deleteAttachFn(index) {
                    $scope.protocol.document.attachment.splice(index, 1);
                }

                function cancelFn() {
                    if (_getForm().$dirty) {
                        needConfirmSrv.call(_openProcedure, [], 'Внесенные изменения сохранены не будут');
                    } else {
                        _openProcedure();
                    }
                }

                function _focusFirstInvalid(form) {
                    $scope.submitted = true;
                    if (form.$error && form.$error.required && form.$error.required[0]
                        && form.$error.required[0].$$element && form.$error.required[0].$$element[0]) {
                        desktopSrv.scollTop();
                        form.$error.required[0].$$element[0].focus();
                    }
                }

                function _openProcedure() {
                    $state.go('openProcedure', {procType: $scope.procedure.type.id.toLowerCase(), procId: $scope.procedure.id},{location :'replace'});
                }

                /**
                 * Подпись и отправка
                 */
                function signSendDataFn() {
                    if ($scope.auth.certId || $scope.auth.withoutCertEnabled) {
                        if (validateForm()) {
                            $stateParams.protocolId = $scope.protocol.id;
                            $scope.protocol.document.purchaseRejection.rejectionDate = $scope.protocol.purchaseRejection.rejectionDate;
                            $scope.protocol.document.purchaseRejection.causeForRejection = $scope.protocol.purchaseRejection.causeForRejection;
                            protocolSrv.saveProtocolFn($stateParams, $scope.protocol, 'PURCHASE_REJECTION').then(function (response) {
                                if (response.data.success) {
                                    protocolSrv.signAndSend($stateParams, $scope.protocol, $scope.auth.certId).then(function (response) {
                                        if (response.data.success) {
                                            setTimeout(function () {
                                                _openProcedure();
                                            }, 1000);

                                        } else {
                                            $scope.alertObjRejectProcedure = alertSrv.getAlertObj(response);
                                        }
                                        $scope.ajaxActive = false;
                                    }, function (reason) {
                                        if (typeof reason === 'string') {
                                            $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn(reason);
                                        } else {
                                            $scope.alertObjRejectProcedure = alertSrv.getAlertObj(reason);
                                        }
                                        $scope.ajaxActive = false;
                                    });
                                } else {
                                    $scope.alertObjRejectProcedure = alertSrv.getAlertObj(response);
                                }
                            })
                        }
                    } else {
                        $scope.ajaxActive = false;
                        $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn('У вас не установлен сертификат.');
                    }
                }

                function downloadFileFn(file) {
                    var url = '/fs/file/' + $scope.procedure.type.id.toLowerCase() + '/' + $scope.procedure.id + '/files/' + file.id;
                    httpSrv.downloadFile(url, file.filename);
                }

                function _getForm() {
                    return $scope.form.rejectForm;
                }

                function validateForm() {
                    if (_getForm().$invalid) {
                        $scope.submitted = true;
                        if (_getForm().$error.required) {
                            $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn('Необходимо заполнить обязательные поля на форме. ', 'Перейти', _focusFirstInvalid, _getForm());
                        }
                        else {
                            var errorArr = Object.keys(_getForm().$error).length > 0 ? _getForm().$error[Object.keys($scope.protocolForm.form.$error)[0]] : [];
                            console.log(errorArr);
                            if (errorArr.length > 0 && errorArr[0].$$element && errorArr[0].$$element[0]) {
                                $scope.alertObjRejectProcedure = alertSrv.getErrorMsg(errorArr[0].$$element[0].title);
                            }
                        }
                        return false;
                    }
                    if (!$scope.protocol.document.attachment || $scope.protocol.document.attachment.length === 0) {
                        $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn("Обратите внимание! Документ должен содержать вложения.");
                        return false;
                    }

                    return true;
                }

                function closeSupplyMsgFn() {
                    $scope.operatorSupplyMsg = false;
                }

                $scope.closeWinnerPaysMsg = function () {
                    $scope.winnerPaysMsg = false;
                }

                function initSupplyInfoMessageFn() {
                    if(!$scope.isViewOnly && $scope.cabType() === 'organizer' && !['prequalification', 'unlimitedprequalification'].includes($scope.procedure.type.id) && $scope.operatorSupplyMsg && $scope.procedure.lots.some(lot =>lot.applicationDepositNeeded === 'operatorSupply' && $scope.protocol.claimLots && $scope.protocol.claimLots[lot.ordinalNumber]>0))
                        $scope.supplyInfoMessage = 'При публикации уведомления об отказе от проведения процедуры будет прекращено блокирование денежных средств, заблокированных на счете Оператора КОМИТА ЭТП для обеспечения заявок. Блокирование будет прекращено для всех участников, подавших заявки.';
                }

                function initWinnerPaysMessageFn() {
                    if(!$scope.isViewOnly && $scope.cabType() === 'organizer' && $scope.winnerPaysMsg && $scope.procedure.lots.some(lot => lot?.winnerPayment>0 && lot?.state?.id !== 'canceled' && $scope.protocol.claimLots && $scope.protocol.claimLots[lot.ordinalNumber]>0))
                        $scope.winnerPaysInfoMessage = 'При публикации уведомления об отказе от проведения процедуры будет прекращено блокирование денежных средств, заблокированных на счете Оператора КОМИТА ЭТП для обеспечения вознаграждения оператора, взимаемого с победителя. Блокирование будет прекращено для всех участников, подавших заявки.';
                    else $scope.winnerPaysInfoMessage = false;
                }
            }
        ]
    });

