/**
 * @author dmitrys
 * Директива для скрытия необязательных полей
 * для отображения кнопки необходимо в атрибуты <legend> добавить only-required-title
 * будут проверяться все вложеные <div> в <fieldset> и восприниматься как поле с заголовком
 * Если <div> это объеденяющая группа полей неоходимо вставить в атрибуты <div> only-required-block
 *
 * Если в <fieldset> не осталось <div> после скрытия, то <fieldset> тоже скорется
 */
angular.module('uetp').directive("onlyRequired", ['$compile','onlyRequiredSrv',function ($compile,onlyRequiredSrv) {
    return {
        restrict: 'A',
        scope: {},//изолируем scope
        link: function(scope, element) {
            /*
            * Проставляем иконку скрытия на <legend>
            * необходим атрибут only-required-title
            * */
            function findLegend(element) {
                if (element.localName === 'legend' && checkAttrTitle(element)) {
                    scope.text = 'Показать необязательные поля';
                    var icon = angular.element($compile(
                        '<span class="onlyRequired-text" style="float: right">' +
                        '<i class="onlyRequired-eye fas fa-eye-slash" title="Только обязательные поля"></i>' +
                        '<span>{{text}}</span>' +
                        '</span>')(scope));
                    icon.on('click', function () {
                        scope.$apply(function () {
                            var glaz = element.getElementsByClassName('fa-eye');
                            var hideField = false;
                            if(glaz.length>0){
                                var glazE = angular.element(glaz[0]);
                                glazE.removeClass('fa-eye');
                                glazE.addClass('fa-eye-slash');
                                glazE.attr('title', 'Все поля');
                                hideField = true;
                                scope.text = 'Показать необязательные поля'
                            }else{
                                glaz = element.getElementsByClassName('fa-eye-slash');
                                var glazE = angular.element(glaz[0]);
                                glazE.removeClass('fa-eye-slash');
                                glazE.addClass('fa-eye');
                                glazE.attr('title', 'Только обязательные поля');
                                hideField = false;
                                scope.text = 'Скрыть необязательные поля'
                            }
                            onlyRequiredSrv.showHide(element.parentNode, hideField);
                        });
                    });
                    angular.element(element).append(icon)
                }else{
                    angular.forEach(element.children, function (item) {
                        findLegend(item);
                    })
                }
                /*
                * Ищем only-required-title в атрибутах <legend>
                * */
                function checkAttrTitle(element) {
                    var value = false;
                    if(element.localName !== 'legend') return false;
                    angular.forEach(element.attributes, function (att) {
                        if(att.name === 'only-required-title')
                            value = true
                    });
                    return value;
                }
            }
            findLegend(element[0]);
        }
    }
}]).service('onlyRequiredSrv', [function () {
    var service = this;
    service.showHide = showHideFn;
    /*
    * Скрываем/показываем поля без тэга required
    * */
    function showHideFn(element,hide) {
        var check = false;
        angular.forEach(element.children, function (item) {
            check = checkAttrBlock(item);
            if(item.localName==="div" && !check) {
                if (!findRequired(item)) {
                    //todo добавить проверку на валидность поля, невалидные поля скрывать не надо (сломают валидацию)
                    if (item.style) {
                        item.style.display = hide?'none':''
                    }
                }
            }else if(item.localName==="div" && check){
                service.showHide(item,hide);
            }else if(item.localName==="fieldset"){
                service.showHide(item,hide);
                checkFieldset(item,hide);
            }
        });
        /*
        * Проверка этот div элемент или объедененная группа
        * */
        function checkAttrBlock(elem) {
            var value = false;
            angular.forEach(elem.attributes, function (item) {
                if(item.name === 'only-required-block')
                    value = true
            });
            return value;
        }
        /*
        * Проверяем <fieldset> на наличие не скрытх <div>
        *     если все <div> скрыты, то скрываем <fieldset>
        * */
        function checkFieldset(fieldset,hide) {
            if(!hide) fieldset.style.display = '';
            if(fieldset.children && fieldset.children.length>0){
                var haveDiv = false;
                //ищем есть ли не скрытые div
                if(checkBlock(fieldset)) haveDiv = true;
                //все div скрыты - скрываем fieldset
                if(!haveDiv) fieldset.style.display = 'none';
            }
            /*
            * Проверям <div> как элемент или как группу
            * */
            function checkBlock(block) {
                var value = false;
                angular.forEach(block.children, function (item) {
                    var check = checkAttrBlock(item);
                    if (item.localName === "div" && !check) {
                        if(item.style.display!=='none') value = true;
                    }else{
                        if(checkBlock(item)) value = true;
                    }
                });
                return value;
            }
        }
        /*
        * ищем поля с тэгом required
        * есть - true, нет - false
        * */
        function findRequired(item) {
            var value = false;
            angular.forEach(item.attributes, function (att) {
                if(att.name === 'required')
                    value = true
            });
            if(!value){
                if(item.children && item.children.length>0){
                    angular.forEach(item.children, function (child) {
                        if(!value){
                            value = findRequired(child);
                        }
                    })
                }
            }
            return value;
        }
    }
}]);