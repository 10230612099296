/**
 * Исправление проблем с атрибутом autocomplete в Chrome
 */
angular.module('uetp').directive('chromeautocomplete', ['desktopSrv','$timeout', function (desktopSrv,$timeout) {

    return {
        restrict: 'A',
        replace: true,
        scope: {
            disabled: '@'
        },
        compile: function compile() {
            return {
                pre: function preLink( scope, element, attributes ) {
                    attributes.$set('readonly','readonly');
                },
                post: function postLink( scope, element) {
                    $timeout(function () {
                        element.removeAttr('readonly');
                    }, 1000);
                }
            };
         }
    }
}])