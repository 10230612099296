
angular.module('uetp')
    .component('bankVTBLanding', {
        templateUrl: 'app/components/bankVTBLanding/bankVTBLanding.html',
        controller: [
            function () {

                this.$onInit = function () {
                    setTimeout(()=>{
                        try{
                            const node = document.createElement('script');
                            node.src = 'assest/vtbLanding/calculator.5abd655e.js';
                            node.type = 'text/javascript';
                            document.getElementsByTagName('head')[0].appendChild(node);
                        } catch {
                            // ignore
                        }

                    }, 1000)

                }
            }]
    });
