/**
 * @author: sergeyu
 * Запрос участнику
 */
angular.module('uetp')
    .component('reqExplanationOrg', {
        bindings: {
            createData: '<',
            openData: '<',
            procedureInfo: '<'
        },
        templateUrl: 'app/components/explanationOrg/request/reqExplanationOrg.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'explanationOrgSrv', 'cryptography', 'authSrv', 'desktopSrv', 'httpSrv', 'alertSrv', 'dialogSrv','needConfirmSrv',
            function ($scope, $rootScope, $state, $stateParams, explanationOrgSrv, cryptography, authSrv, desktopSrv, httpSrv, alertSrv, dialogSrv,needConfirmSrv) {
                var ctrl = this;
                $scope.ctrl = ctrl;
                $scope.cancel = cancelFn;
                $scope.toAnswerCreate = toAnswerCreateFn;
                
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.certError = authSrv.isCertificateError;
                    $scope.submitted = false;
                    $scope.procedure = {};
                    $scope.procedure.type = {};
                    $scope.procedure.type.id = $stateParams.procType;
                    $scope.procedure.id = $stateParams.procId;
                    $scope.auth = authSrv.getAuth();
                    $scope.form = {explanation: {}};
                    $scope.participants = [];
                   
                    if(ctrl.procedureInfo && !ctrl.procedureInfo.error && ctrl.procedureInfo.data.success){
                    	 $scope.procedureName = ctrl.procedureInfo.data.result.name;
                         $scope.procedureNumber = ctrl.procedureInfo.data.result.number;
                    }
                    
                    if (ctrl.createData && !ctrl.createData.error) {
                        if (ctrl.createData.data.success) {
                            $scope.model = ctrl.createData.data.result.purchase;
                            $scope.model.created = ctrl.createData.data.result.created;
                            $scope.viewModel = ctrl.createData.data.result.purchase;
                            $scope.viewModel.isEdit = !ctrl.createData.data.result.id;
                            
                            $scope.participants = ctrl.createData.data.result.participants;
                        }  else {
                            dialogSrv.showDialogMsgHtml($scope, ctrl.createData.data.errorMessage, function () {
                                history.back();
                            });
                        }
                    } else if(ctrl.createData && ctrl.createData.error) {
                        dialogSrv.showDialogMsgHtml($scope, ctrl.createData.error, function () {
                            history.back();
                        });
                    } else if (ctrl.openData) {
                        $scope.model = ctrl.openData.data.result;
                        $scope.viewModel = {};
                        $scope.viewModel.subject = $scope.model.subject;
                        $scope.viewModel.desc = $scope.model.message;
                        $scope.viewModel.reciver = $scope.model.reciverDescription;
                        $scope.model.created = $scope.model.createDate;
                        if($scope.model.attachments && $scope.model.attachments.length>0){
                          $scope.model.file = [];
                          $scope.model.file.id = $scope.model.attachments[0].id;
                          $scope.model.file.fileName = $scope.model.attachments[0].name;
                        } 	
                        
                        $scope.viewModel.isEdit = !ctrl.openData.data.result.id;

                        if(!$scope.model.read){
                            $rootScope.$emit('UPDATE:COUNTER'); // $rootScope.$on
                        }
                    }
                };

                /**
                 * Подпись и отправка
                 */
                $scope.signSendData = function () {
                    if ($scope.auth.certId || $scope.auth.withoutCertEnabled) {
                        var model = angular.copy($scope.model),
                            requestModel = {};
                        requestModel.subject = model.subject;
                        requestModel.desc = model.desc;
                        requestModel.applId = model.applId;
                        requestModel.created = model.created;
                        requestModel.participantId = model.participant.id;
                        $scope.submitted = true;
                        explanationOrgSrv.getDataForSign($stateParams.procType, $stateParams.procId, requestModel, 'explanationOrgRequests').then(function (response) {
                            if (response.data.success) {
                                if($scope.auth.withoutCertEnabled){
                                    explanationOrgSrv.sendSignData($stateParams.procType, $stateParams.procId, requestModel, 'explanationOrgRequests').then(function (response) {
                                        if (response.data.success) {
                                            $scope.viewModel.isEdit = false;
                                            $scope.ctrl.alertObjReqExplanation = alertSrv.getSuccessMsgWithBtn("Запрос отправлен!");
                                            $scope.viewModel.reciver = $scope.model.participant ? $scope.model.participant.inn + ', ' + $scope.model.participant.name : '';
                                        } else {
                                            $scope.submitted = false;
                                            $scope.ctrl.alertObjReqExplanation = alertSrv.getAlertObj(response);
                                        }

                                    })
                                } else {
                                    cryptography.generateSignature(response.data.result, $scope.auth.certId).then(function (value) {
                                        requestModel.signature = value;
                                        explanationOrgSrv.sendSignData($stateParams.procType, $stateParams.procId, requestModel, 'explanationOrgRequests').then(function (response) {
                                            if (response.data.success) {
                                                $scope.viewModel.isEdit = false;
                                                $scope.ctrl.alertObjReqExplanation = alertSrv.getSuccessMsgWithBtn("Запрос отправлен!");
                                                $scope.viewModel.reciver = $scope.model.participant ? $scope.model.participant.inn + ', ' + $scope.model.participant.name : '';
                                            } else {
                                                $scope.submitted = false;
                                                $scope.ctrl.alertObjReqExplanation = alertSrv.getAlertObj(response);
                                            }

                                        })
                                    }, function (reason) {
                                        $scope.submitted = false;
                                        if (typeof reason === 'string') {
                                            $scope.ctrl.alertObjReqExplanation = alertSrv.getErrorMsgWithBtn("Ошибка подписи документа. " + reason);
                                        } else {
                                            $scope.ctrl.alertObjReqExplanation = alertSrv.getErrorMsgWithBtn("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                                        }
                                    })
                                }
                            } else {
                                $scope.submitted = false;
                                $scope.ctrl.alertObjReqExplanation = alertSrv.getAlertObj(response);
                            }

                        })
                    } else {
                        $scope.submitted = false;
                        $scope.ctrl.alertObjReqExplanation = alertSrv.getErrorMsgWithBtn('У вас не установлен сертификат.');
                    }
                };

                function toAnswerCreateFn(idMsg) {
	               	 $state.go('createAnswerExplanationOrgFromReq', {
	                        procType: $stateParams.procType,
	                        procId: $stateParams.procId,
	                        explReqId:idMsg
	                    });
               } 
                $scope.uploadSuccess = function (result) {
                    $scope.model.file = {
                        fileName: result[0].fileName,
                        id: result[0].id
                    };
                    $scope.model.applId = result[0].id;

                };
                $scope.deleteAttach = function () {
                    $scope.model.file = null;
                    $scope.model.applId = null;
                };
                $scope.reset = function () {
                    history.back();
                };
                $scope.downloadFile = function (file) {
                	if (ctrl.openData){
                		var url = '/message/api/v2/download/' + file.id;
                	}else{
                		var url = '/' + $stateParams.procType + '/downloadFile/' + file.id;
    	            }
                    httpSrv.downloadFile(url, file.fileName);
                };

                function cancelFn() {
                    if($scope.form.explanation.$dirty && $scope.viewModel.isEdit){
                        needConfirmSrv.call($scope.reset,[],'Внесенные изменения сохранены не будут');
                    }else{
                        $scope.reset();
                    }
                }

            }]
    });

