/**
 * @author dmitrys
 * Форматированный вывод дат и времени
 */
angular.module('uetp').directive("formatDate", [function () {
    return {
        restrict: 'E',
        templateUrl: 'app/directives/formatDate/formatDate.html',
        scope: {
            model: "=",
            format: "@",//формат вывода даты "dd.MM.yyyy" или "dd.MM.yyyy HH:mm" и тп
            timeZone: "@",//временая зона по умолчанию UTC+3
            timeZoneCaption: "@"//при переопределении timeZone указавыть свое описание
        },
        replace: true,
        compile: function () {
            return {
                pre: function (scope) {
                    scope.initModel();
                }
            }
        },
        controller: ['$scope', '$filter',
            function ($scope, $filter) {

                /**
                 * Отобразить время в местом часовом поясе
                 */
                $scope.localDate = localDateFn;

                $scope.type = {
                    format:'dd.MM.yyyy',
                    timeZone:'UTC+3',
                    timeZoneCaption:'МСК'
                };
                $scope.initModel = function () {
                    if (angular.isDefined($scope.format)) $scope.type.format = $scope.format;
                    if (angular.isDefined($scope.timeZone)) $scope.type.timeZone = $scope.timeZone;
                    if (angular.isDefined($scope.timeZoneCaption)) $scope.type.timeZoneCaption = $scope.timeZoneCaption;
                };

                function localDateFn() {
                    return $filter('date')($scope.model, $scope.type.format) + ' в вашем часовом поясе';
                }

            }

        ]
    }
}]);