/**
 * Создание протокола "Уведомление о переносе сроков торгов"
 */
angular.module('uetp')
    .component('informationAboutCancelLots', {
        templateUrl: 'app/components/procedures/cancelLots/informationAboutCancelLots.html',
        bindings: {
            procedureData: '<',
            protocolData: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'proceduresSrv', '$uibModal', 'authSrv',
            'httpSrv', 'storageSrv', 'vfSrv', 'needConfirmSrv', 'alertSrv','dictionarySrv', 'dialogSrv', 'protocolSrv','constants','$timeout',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, proceduresSrv, $uibModal, authSrv,
                      httpSrv, storageSrv, vfSrv, needConfirmSrv, alertSrv, dictionarySrv, dialogSrv, protocolSrv, constants,$timeout) {
                var ctrl = this;
                $scope.ctrl = ctrl;
                $scope.constants = constants;
                this.$onInit = function () {
                    $scope.isViewOnly = $stateParams.isViewOnly;
                    $scope.procedure = $scope.ctrl.procedureData.data.result;
                    $scope.protocol = $scope.ctrl.protocolData.data.result;
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.supplyInfoMessage = false;
                    $scope.operatorSupplyMsg = true;
                    $scope.winnerPaysInfoMessage = false;
                    $scope.winnerPaysMsg = true;

                    if (!$scope.isViewOnly) {
                        $scope.maxCountAttachments = 1;
                        $scope.protocol.document.subjectExplanationRequest = "Перенос сроков";
                        $scope.protocol.document.titleExplanationRequest = "Перенос сроков";
                        $scope.auth = authSrv.getAuth();
                        $scope.cancelLotsDateOptions =  {
                            minDate: getServerTimeMsk()
                        };
                        angular.forEach($scope.protocol.document.cancelledLots.cancelledLot, function(lot) {
                            if (isCancelLotFn(lot)) {
                                lot.selectedLot = true;
                            }
                        });
                    } else {
                        angular.forEach($scope.protocol.document.cancelledLots.cancelledLot, function(lot) {
                            lot.selectedLot = true;
                        });

                    }
                    initSupplyInfoMessageFn();
                    initWinnerPaysMessageFn();
                };

                $scope.deleteAttach = deleteAttachFn;
                $scope.signSendData = signSendDataFn;
                $scope.uploadSuccess = uploadSuccessFn;
                $scope.downloadFile = downloadFileFn;
                $scope.cancel = cancelFn;
                $scope.selectLot = selectLotFn;
                $scope.isCancelLot = isCancelLotFn;
                $scope.initSupplyInfoMessage = initSupplyInfoMessageFn;
                $scope.closeSupplyMsg = closeSupplyMsgFn;

                function uploadSuccessFn(result) {
                    if (!$scope.protocol.document.attachment) {
                        $scope.protocol.document.attachment = []
                    }
                    angular.forEach(result, function (item) {
                        $scope.protocol.document.attachment.push({
                            fileName: item.fileName,
                            id: item.id
                        });
                    })
                }

                function deleteAttachFn(index) {
                    $scope.protocol.document.attachment.splice(index, 1);
                }

                function cancelFn() {
                    if (_getForm().$dirty) {
                        needConfirmSrv.call(_openProcedure, [], 'Внесенные изменения сохранены не будут');
                    } else {
                        _openProcedure();
                    }
                }

                function selectLotFn(row) {
                    if (!row.selectedLot && (!isCancelLotFn(row))) {
                        row.cancelDate = undefined;
                        row.cancelInfo = undefined;
                    }
                    $timeout(function () {
                        initSupplyInfoMessageFn();
                        initWinnerPaysMessageFn();
                    });
                }

                function _focusFirstInvalid(form) {
                    $scope.submitted = true;
                    if (form.$error && form.$error.required && form.$error.required[0]
                        && form.$error.required[0].$$element && form.$error.required[0].$$element[0]) {
                        desktopSrv.scollTop();
                        form.$error.required[0].$$element[0].focus();
                    }
                    if (form.length > 0 && form[0].$$element && form[0].$$element[0]) {
                        desktopSrv.scollTop();
                        form[0].$$element[0].focus();
                    }
                }

                function _openProcedure() {
                    $state.go('openProcedure', {procType: $scope.procedure.type.id.toLowerCase(), procId: $scope.procedure.id},{location :'replace'});
                }

                /**
                 * Подпись и отправка
                 */
                function signSendDataFn() {
                    if ($scope.auth.certId || $scope.auth.withoutCertEnabled) {
                        if (validateForm()) {
                            $stateParams.protocolId = $scope.protocol.id;
                            var copy = angular.copy( $scope.protocol);
                            _removeNotSelectLots(copy);
                            protocolSrv.saveProtocolFn($stateParams, copy, 'PURCHASE_LOT_CANCELLATION').then(function (response) {
                                if (response.data.success) {
                                    $stateParams.noticeId = response.data.result[1]; // id notice
                                    protocolSrv.signAndSend($stateParams, copy, $scope.auth.certId).then(function (response) {
                                        if (response.data.success) {
                                            setTimeout(function () {
                                                _openProcedure();
                                            }, 1000);

                                        } else {
                                            $scope.alertObjCancelLots = alertSrv.getAlertObj(response);
                                        }
                                        $scope.ajaxActive = false;
                                    }, function (reason) {
                                        if (typeof reason === 'string') {
                                            $scope.alertObjCancelLots = alertSrv.getErrorMsgWithBtn(reason);
                                        } else {
                                            $scope.alertObjCancelLots = alertSrv.getAlertObj(reason);
                                        }
                                        $scope.ajaxActive = false;
                                    });
                                } else {
                                    $scope.alertObjCancelLots = alertSrv.getAlertObj(response);
                                }
                            })
                        }
                    } else {
                        $scope.ajaxActive = false;
                        $scope.alertObjCancelLots = alertSrv.getErrorMsgWithBtn('У вас не установлен сертификат.');
                    }
                }

                function downloadFileFn(file) {
                    var url = '/fs/file/' + $scope.procedure.type.id.toLowerCase() + '/' + $scope.procedure.id + '/files/' + file.id;
                    httpSrv.downloadFile(url, file.filename);
                }

                function _getForm() {
                    return $scope.form.cancelLotsForm;
                }

                function validateForm() {
                    if (_getForm().$invalid) {
                        $scope.submitted = true;
                        if (_getForm().$error.required) {
                            $scope.alertObjCancelLots = alertSrv.getErrorMsgWithBtn('Необходимо заполнить обязательные поля на форме. ', 'Перейти', _focusFirstInvalid, _getForm());
                        }
                        else {
                            var errorArr = Object.keys(_getForm().$error).length > 0 ? _getForm().$error[Object.keys(_getForm().$error)[0]] : [];
                            if (errorArr.length > 0 && errorArr[0].$$element && errorArr[0].$$element[0]) {
                                $scope.alertObjCancelLots = alertSrv.getErrorMsgWithBtn('Неправильно заполнено поле. ', 'Перейти', _focusFirstInvalid, errorArr);
                            }
                        }
                        return false;
                    }

                    if (!_isExistCanceledLots() && !_isExistReCreatedLots()) {
                        $scope.alertObjCancelLots = alertSrv.getErrorMsgWithBtn("Нет выбранных лотов для отмены или лотов для отмены отказа.");
                        return false;
                    }

                    if (!$scope.protocol.document.attachment || $scope.protocol.document.attachment.length === 0) {
                        $scope.alertObjCancelLots = alertSrv.getErrorMsgWithBtn("Обратите внимание! Документ должен содержать вложение.");
                        return false;
                    }

                    return true;
                }

                function _isExistCanceledLots() {
                    if ($scope.protocol.document.cancelledLots.cancelledLot) {
                        var checkLot = false;
                        angular.forEach($scope.protocol.document.cancelledLots.cancelledLot, function (lot) {
                            if (lot.selectedLot && !isCancelLotFn(lot)) {
                                checkLot = true;
                            }
                        });
                        return checkLot !== false;
                    } else {
                        return false;
                    }
                }

                function _isExistReCreatedLots() {
                    if ($scope.protocol.document.cancelledLots.cancelledLot) {
                        var checkLot = false;
                        angular.forEach($scope.protocol.document.cancelledLots.cancelledLot, function (lot) {
                            if (!lot.selectedLot && isCancelLotFn(lot)) {
                                checkLot = true;
                            }
                        });
                        return checkLot !== false;
                    } else {
                        return false;
                    }
                }

                function isCancelLotFn(lot) {
                    return $scope.protocol.stateLots[lot.ordinalNumber] === 'canceled';
                }

                function _removeNotSelectLots(protocol){
                    var cancelLots = [];
                    angular.forEach(protocol.document.cancelledLots.cancelledLot, function (item) {
                        if (item.selectedLot) {
                            delete item['selectedLot'];
                            cancelLots.push(item);
                        }
                    })
                    protocol.document.cancelledLots.cancelledLot = cancelLots;
                }

                function closeSupplyMsgFn() {
                    $scope.operatorSupplyMsg = false;
                }

                $scope.closeWinnerPaysMsg = function () {
                    $scope.winnerPaysMsg = false;
                }

                function initSupplyInfoMessageFn() {
                    if($scope.cabType() === 'organizer' && !$scope.isViewOnly && !['prequalification', 'unlimitedprequalification'].includes($scope.procedure.type.id) && $scope.operatorSupplyMsg &&
                        $scope.protocol.document.cancelledLots.cancelledLot.some(clot=>clot.selectedLot && $scope.procedure.lots.some(lot =>clot.ordinalNumber===lot.ordinalNumber && lot.applicationDepositNeeded === 'operatorSupply' && $scope.protocol.claimLots && $scope.protocol.claimLots[lot.ordinalNumber]>0)))
                        $scope.supplyInfoMessage = 'При публикации уведомления об отказе от проведения лота будет прекращено блокирование денежных средств, заблокированных на счете Оператора КОМИТА ЭТП для обеспечения заявок. Блокирование будет прекращено для всех участников, подавших заявки.';
                    else $scope.supplyInfoMessage = false;
                }

                function initWinnerPaysMessageFn() {
                    if($scope.cabType() === 'organizer' && !$scope.isViewOnly && $scope.winnerPaysMsg &&
                        $scope.protocol.document.cancelledLots.cancelledLot.some(clot=>clot.selectedLot && $scope.procedure.lots.some(lot =>clot.ordinalNumber===lot.ordinalNumber && lot?.winnerPayment>0 && lot?.state?.id !== 'canceled' && $scope.protocol.claimLots && $scope.protocol.claimLots[lot.ordinalNumber]>0)))
                        $scope.winnerPaysInfoMessage = 'При публикации уведомления об отказе от проведения лота будет прекращено блокирование денежных средств, заблокированных на счете Оператора КОМИТА ЭТП для обеспечения вознаграждения оператора, взимаемого с победителя. Блокирование будет прекращено для всех участников, подавших заявки.';
                    else $scope.winnerPaysInfoMessage = false;
                }
            }
        ]
    });

