/**
 * @author dmitrys
 */
angular.module('uetp').service('invoiceSrv', ['$state', 'httpSrv',
    function ($state, httpSrv) {
        var service = this,
            stateTitle = {
                noPayment: 'Не оплачен',
                partial:'Оплачен частично',
                acceptedPayment:'Оплачен',
                finished:'Закрыт',
                canceled:'Аннулирован',
                revoked:'Отозван'
            };




        service.getInvoice = getInvoiceFn;
        service.getInvoices = getInvoicesFn;
        service.getInvoicesByOrg = getInvoicesByOrgFn;
        service.setInvoiceState = setInvoiceStateFn;
        service.revokeInvoice = revokeInvoiceFn;
        service.checkRevokeInvoice = checkRevokeInvoiceFn;
        service.getStateTitle = getStateTitleFn;


        function getInvoiceFn(id) {
            return httpSrv.http({
                method: 'GET',
                url:'/auth/tariff/invoice/'+id
            });
        }

        function getInvoicesFn(pagination) {
            return httpSrv.http({
                method: 'POST',
                url: '/auth/tariff/invoices',
                data: pagination,
                showCounter: true
            })
        }
        function getInvoicesByOrgFn(pagination, participantId) {
            return httpSrv.http({
                method: 'POST',
                url: '/auth/tariff/invoicesByOrg/' + participantId,
                data: pagination
            })
        }

        function setInvoiceStateFn(invoice) {
            return httpSrv.http({
                method: 'POST',
                url: '/auth/tariff/invoiceState',
                data: invoice
            })
        }
        function revokeInvoiceFn(id) {
            return httpSrv.http({
                method: 'GET',
                url:'/auth/tariff/revokeinvoice/'+id
            });
        }
        function checkRevokeInvoiceFn(id) {
            return httpSrv.http({
                method: 'GET',
                url:'/auth/tariff/checkrevokeinvoice/'+id
            });
        }
        function getStateTitleFn(type) {
            return stateTitle[type];
        }

    }]);