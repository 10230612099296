/**
 * Компонент регистрация Заказчика - обработка оператором
 */
angular.module('uetp')
    .component('customerRegistrationOpen', {
        bindings: {
            customerRegData: '<'
        },
        templateUrl: 'app/components/customer/registration/customerRegistrationOpen.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'constants', 'authSrv', 'desktopSrv', 'dialogSrv', 'dictionarySrv', 'alertSrv', 'httpSrv', 'customerRegSrv',
            function ($scope, $rootScope, $state, $stateParams, constants, authSrv, desktopSrv, dialogSrv, dictionarySrv, alertSrv, httpSrv, customerRegSrv) {
                const ctrl = this;

                $scope.constants = constants;
                $scope.downloadFile = downloadFileFn;
                $scope.dictionary = dictionarySrv.getRemoteData();
                $scope.makeDecision = makeDecisionFn;
                $scope.processCustomerRegistration = processCustomerRegistrationFn;
                $scope.downloadFileArchive = customerRegSrv.downloadFileArchive;
                $scope.cancel = cancelFn;


                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType();
                    $scope.dictSrv = dictionarySrv;
                    $scope.authUser = authSrv.getAuth();
                    $scope.reason = {};
                    _initDict(['okcm', 'fias_region'], false);

                    $scope.form = {registerForm: {}};
                    $scope.status = {};
                    $scope.disableForm = true;
                    $scope.docs={
                        accreditation_founder:{},
                        accreditation_proxy:{},
                        accreditation_other:{}
                    };
                    if (ctrl.customerRegData) {
                        if (ctrl.customerRegData.data.success) {
                            $scope.customerReg = ctrl.customerRegData.data.result;
                            $scope.consentProcessing = !!$scope.customerReg.document.consentProcessing;
                            $scope.consentDissemination = !!$scope.customerReg.document.consentDissemination;
                            $scope.isChecked = angular.equals($scope.customerReg.document.orgInfo.regAddress, $scope.customerReg.document.orgInfo.realAddress)
                                || !$scope.customerReg.document.orgInfo.realAddress || !$scope.customerReg.document.orgInfo.realAddress.country;

                            if ($scope.customerReg.document.documents) {
                                fillDocsByType($scope.customerReg.document.documents.document);
                            }
                        }
                    }
                    $scope.rejectObjects = {
                        setStatus: function (id, state) {
                            $scope.reason.show = true;
                            $scope.reason.id = id;
                            $scope.reason.state = state;
                        },
                        hideSetStatus: function () {
                            reasonReset();
                        },
                        sendSetStatus: function () {
                            processCustomerRegistrationFn($scope.reason.id, $scope.reason.state, $scope.reason.set.message || '');
                        }
                    };
                    if ($stateParams.params) {
                        $scope.notApproved = $stateParams.params.notApproved;
                    }
                };

                function _initDict(dictList, reload) {
                    angular.forEach(dictList, function (name) {
                        dictionarySrv.getDictionaryRemote(name, reload)
                    });
                }

                function downloadFileFn(accredId, attachId, attachName) {
                    httpSrv.downloadFile('/fs/file/downloadAttachment/' + accredId + '/' + attachId, attachName);
                }


                function makeDecisionFn(id, state) {
                    if (state === 'registered') {
                        processCustomerRegistrationFn(id, state);
                    } else if (state === 'notregistered') {
                        $scope.rejectObjects.setStatus(id, state);
                    }
                }

                function processCustomerRegistrationFn(id, state, reasonTxt) {
                    let data = {
                        id: id,
                        state: state
                    };
                    if (state == 'notregistered' || (state == 'registered' && reasonTxt)){
                        data.rejectReason = reasonTxt;
                    }
                    reasonReset();
                    customerRegSrv.processCustomerRegistration(data).then(function successCallback(response) {
                        if (response.data.success) {
                            if (state != 'processing'){
                                localStorage.setItem("customerRegistrationState", state);
                                $state.go("customerRegistrationTable",{notApproved: $scope.notApproved});
                            }
                            else {
                                $scope.customerReg.state = "processing";
                                $scope.customerReg.inspector = {
                                    id:$scope.authUser.userId,
                                    personName:authSrv.getAuth().userName
                                };
                            }
                        } else {
                            dialogSrv.showDialogMsg($scope, response.data.message, function () {
                                $state.reload();
                            });
                        }
                    })
                }

                function setDocByTypeFn(type, doc){
                    return $scope.docs[type] = doc;
                }

                function fillDocsByType(docArray){
                    if (!Array.isArray(docArray)) return;
                    docArray.forEach(function(el){
                        setDocByTypeFn(el.documentType, el);
                    })
                }

                function reasonReset() {
                    $scope.reason = {
                        'show': "",
                        'id': "",
                        'set': {},
                        'state':""
                    };
                }
                function cancelFn() {
                    if (['admincab','support'].includes($scope.cabType)) {
                        $state.go("customerRegistrationTable",{notApproved: $scope.notApproved});
                    } else{
                        history.back();
                    }
                }
            }]
    });

