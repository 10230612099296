/**
 * @author sergeyu
 *
 */
angular.module('uetp').service('explanationSrv', ['$state', 'httpSrv', 'dictionarySrv',
    function ($state, httpSrv, dictionarySrv) {
        /**
         * Создание документа
         * @param procType - тип процедуры
         * @param procId - идентификатор процедуры
         * @param typeExplanation - тип разъяснения
         * @returns {*}
         */
        this.createExplanation = function (procType, procId, typeExplanation) {
            var url,fz = dictionarySrv.getFzbyProcType(procType);
            if(fz==='44'){
                /**
                 * 44 ФЗ
                 */
            	url =  '/' + procType + '/' + procId + '/' + typeExplanation + '/create'
            }else  if(fz==='223'){
                /**
                 * 223 ФЗ
                 */
                url =  '/' + procType + '/' + procId + '/' + typeExplanation + '/create'
            }
            return httpSrv.http({
                url:url
            })
        };
        /**
         * Получение данных для подписи
         * @param procType - тип процедуры
         * @param procId - идентификатор процедуры
         * @param data - данные для получения хеша
         * @param typeExplanation - тип разъяснения
         * @returns {*}
         */
        this.getDataForSign = function (procType, procId, data, typeExplanation) {
            /*if(auth.withoutCertEnabled && auth.withoutCertEnabled==='true'){
                return {data:{success:true, result:data}};
            }*/
            var url,fz = dictionarySrv.getFzbyProcType(procType);
            if(fz==='44'){
                /**
                 * 44 ФЗ
                 */
            	 url = '/' + procType + '/' + procId + '/' + typeExplanation + '/getDataForSign';
            }else  if(fz==='223'){
                /**
                 * 223 ФЗ
                 */
                url = '/' + procType + '/' + procId + '/' + typeExplanation + '/getDataForSign';
            }
            return httpSrv.http({
                url: url,
                data: data
            })
        };
        /**
         * Отправка документа
         * @param procType - тип процедуры
         * @param procId - идентификатор процедуры
         * @param data - данные для отправка с подписью
         * @param typeExplanation - тип разъяснения
         * @returns {*}
         */
        this.sendSignData = function (procType, procId, data, typeExplanation) {
            var url,fz = dictionarySrv.getFzbyProcType(procType);
            if(fz==='44'){
                /**
                 * 44 ФЗ
                 */
            	url = '/' + procType + '/' + procId + '/' + typeExplanation + '/send';
            }else  if(fz==='223'){
                /**
                 * 223 ФЗ
                 */
                url = '/' + procType + '/' + procId + '/' + typeExplanation + '/send';
            }
            return httpSrv.http({
                url:url,
                data: data
            })
        };
        /**
         * Получение Запроса на разъяснение
         * @param procType - тип процедуры
         * @param procId - идентификатор процедуры
         * @param reqId - идентификатор "Запроса на разъяснение"
         * @returns {*}
         */
        this.getReqExplanation = function (procType, procId, reqId) {
            var url,fz = dictionarySrv.getFzbyProcType(procType);
            if(fz==='44'){
                /**
                 * 44 ФЗ
                 */
            	url = '/' + procType + '/' + procId + '/explanationRequests/' + reqId;
            }else  if(fz==='223'){
                /**
                 * 223 ФЗ
                 */
                url = '/' + procType + '/' + procId + '/explanationRequests/' + reqId;
            }
            return httpSrv.http({
                method: 'GET',
                url: url
            })
        };
        /**
         * Форма редактирования email Запроса на разъяснение
         * @param procType - тип процедуры
         * @param procId - идентификатор процедуры
         * @param reqId - идентификатор "Запроса на разъяснение"
         * @returns {*}
         */
        this.getReqExplanationEmail = function (procType, procId, reqId) {
            var url,fz = dictionarySrv.getFzbyProcType(procType);
            if(fz==='44'){
                /**
                 * 44 ФЗ
                 */
            	url = '/' + procType + '/' + procId + '/explanationEmailRequests/' + reqId;
            }else  if(fz==='223'){
                /**
                 * 223 ФЗ
                 */
                url = '/' + procType + '/' + procId + '/explanationEmailRequests/' + reqId;
            }
            return httpSrv.http({
                method: 'GET',
                url: url
            })
        };

        /**
         * Отправка email Запроса на разъяснение
         * @param procType - тип процедуры
         * @param procId - идентификатор процедуры
         * @param data - данные для отправка с подписью
         * @param typeExplanation - тип разъяснения
         * @returns {*}
         */
        this.sendEmailData = function (procType, procId, data, typeExplanation) {
            var url,fz = dictionarySrv.getFzbyProcType(procType);
            if(fz==='44'){
                /**
                 * 44 ФЗ
                 */
            	url = '/' + procType + '/' + procId + '/' + typeExplanation + '/emailSend';
            }else  if(fz==='223'){
                /**
                 * 223 ФЗ
                 */
                url = '/' + procType + '/' + procId + '/' + typeExplanation + '/emailSend';
            }
            return httpSrv.http({
                url:url,
                data: data
            })
        };
        /**
         * Получение Разъяснение
         * @param procType - тип процедуры
         * @param procId - идентификатор процедуры
         * @param expId - идентификатор "Разъяснения"
         * @returns {*}
         */
        this.getExplanation = function (procType, procId, expId) {
            var url,fz = dictionarySrv.getFzbyProcType(procType);
            if(fz==='44'){
                /**
                 * 44 ФЗ
                 */
                url = '/' + procType + '/' + procId + '/explanations/' + expId;
            }else  if(fz==='223'){
                /**
                 * 223 ФЗ
                 */
                url = '/' + procType + '/' + procId + '/explanations/' + expId;
            }
            return httpSrv.http({
                method: 'GET',
                url: url
            })
        };
        /**
         * Отправка документа (игнорирование запроса)
         * @param procType - тип процедуры
         * @param procId - идентификатор процедуры
         * @param reqId - идентификатор "Запроса на разъяснение"
         * @returns {*}
         */
        this.ignoreExplanation = function (procType, procId, reqId) {
            var url,fz = dictionarySrv.getFzbyProcType(procType);
            if(fz==='44'){
                /**
                 * 44 ФЗ
                 */
            	url = '/' + procType + '/' + procId + '/explanationRequests/' + reqId + '/ignore';
            }else  if(fz==='223'){
                /**
                 * 223 ФЗ
                 */
                url = '/' + procType + '/' + procId + '/explanationRequests/' + reqId + '/ignore';
            }
            return httpSrv.http({
                url: url
            })
        };
        /**
         * Прикрепить "Разъяснение" к "Запросу на разъяснение"
         * @param procType - тип процедуры
         * @param procId - идентификатор процедуры
         * @param explId - идентификатор "Разъяснение"
         * @returns {*}
         */
        this.bindExplanation = function (procType, procId, explId, data) {
            var url,fz = dictionarySrv.getFzbyProcType(procType);
            if(fz==='44'){
                /**
                 * 44 ФЗ
                 */
            	 url = '/' + procType + '/' + procId + '/explanations/' + explId + '/bind';
            }else  if(fz==='223'){
                /**
                 * 223 ФЗ
                 */
                url = '/' + procType + '/' + procId + '/explanations/' + explId + '/bind';
            }
            return httpSrv.http({
                url: url,
                data: data
            })
        };
        /**
         * Получить список Разъяснений
         * @param procType - тип процедуры
         * @param procId - идентификатор процедуры
         * @returns {*}
         */
        this.getExplanations = function (procType, procId) {
            var url,fz = dictionarySrv.getFzbyProcType(procType);
            if(fz==='44'){
                /**
                 * 44 ФЗ
                 */
            	url = '/' + procType + '/' + procId + '/explanations/';
            }else  if(fz==='223'){
                /**
                 * 223 ФЗ
                 */
                url = '/' + procType + '/' + procId + '/explanations/';
            }
            return httpSrv.http({
                method: 'GET',
                url: url
            })
        };
        /**
         * Получение форматированного имени участника
         * @param data - данные по учатнику
         * @returns {string}
         */
        this.getParticipantName = function (data) {
            var requisites = '',
                base = data.name + ',<br>',
                addressLegal = data.legalAddress ? '<br>Юридический адрес: ' + data.legalAddress : '',
                addressReal = data.postalAddress ? '<br>Фактический адрес: ' + data.postalAddress : '',
                msp = '';
            var getField = function (name, data) {
                if (data) {
                    return ', ' + name + ':' + data
                }
                return ''
            };


            if (data.ul || data.orgUL) {
                requisites = data.ul || data.orgUL;
                base += 'ИНН:' + requisites.inn + getField('КПП', requisites.kpp) + getField('ОГРН', requisites.ogrn) + ',';
            }
            else if (data.fl || data.orgFL) {
                requisites = data.fl || data.orgFL;
                base += 'ИНН:' + requisites.inn + ',';
            }
            else if (data.ip || data.orgIP) {
                requisites = data.ip || data.orgIP;
                base += 'ИНН:' + requisites.inn + ',';
            }
            else if (data.nr || data.orgNonResident) {
                requisites = data.nr || data.orgNonResident;
                base += 'Идентификационный номер:' + requisites.code + getField('Страна', requisites.countryName) + ',';
            }
            if (requisites.msp) {
                var nameMSP;
                dictionarySrv.getStaticDictByType('mspCategory').forEach(function (el) {
                    if (el.id === requisites.msp) {
                        nameMSP = el.name
                    }
                });
                msp = 'Субъект малого и среднего предпринимательства, ' + nameMSP;
                msp = '<br>' + msp
            }
            return base + msp + ' ' + addressLegal + (addressReal ? ', ' + addressReal : '')
                + getField('<br>Телефон', data.phone)
                + getField('<br>Адрес электронной почты', data.email);
        };

        /**
         * Повторно отправить в ЕИС
         */
        this.retryToEIS = function (id) {
            return httpSrv.http({
                url: `/organizer/procedures/retryExplToEIS/${id}`,
                method: 'GET'
            })
        };

        /**
         * Удаление разъяснения
         * @param explanationId - id
         * @returns {*}
         */
        this.deleteExplanation = function (explanationId) {
            return httpSrv.http({
                method: 'DELETE',
                url: `/organizer/api/explanations/${explanationId}`
            })
        };

    }]);