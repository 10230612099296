angular.module('uetp')
    .component('bankGuarantees', {
        templateUrl: 'app/components/bankGuarantees/bankGuarantees.html',
        controller: ['$scope', 'constants',
            function ($scope, constants) {
                $scope.feedbackFormVisibility = function () {
                    $scope.feedbackForm = $scope.feedbackForm ? false : true;
                };

                this.$onInit = function () {
                    $scope.feedbackForm = false;
                    $scope.constants = constants;
                }
            }]
    });
