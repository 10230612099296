/**
 * @author dmitrys
 */
angular.module('uetp')
    .component('finReportTass', {
        bindings: {
            tassId: '='
        },
        templateUrl: 'app/components/widgets/tassBusiness/companyValidate/view/finReport/finReport.html',
        controller: ['$scope','$stateParams', 'propertiesService', 'tassBusinessSrv',
            function ($scope,$stateParams,  propertiesService, tassBusinessSrv) {
                var ctrl = this;
                $scope.loaded = false; // контроль загруки. Один раз при первом отображении

                $scope.modelView = {
                    showEmpty:true//признка отображения пустых записей
                };

                $scope.loadReport = loadReportFn;
                $scope.selectYear = selectYearFn;
                $scope.showHideRow = showHideRowFn;
                $scope.showsYears = showsYearsFn;

                this.$onInit = function () {
                    if (!$scope.loaded) $scope.loadReport();
                };

                function loadReportFn() {
                    propertiesService.getProperty("api.url").then(function (host) {
                        tassBusinessSrv.getOrganizationFinance($scope, ctrl.tassId, $stateParams.id, host).then(function (response) {
                            $scope.errorInfo = null;
                            if (response.data.success) {
                                if (response.data.result.error) {
                                    if (response.data.result.error.indexOf('не активна') !== -1) {
                                        $scope.errorInfo = '<span class="red">Недействующее предприятие</span>';
                                    } else {
                                        $scope.errorInfo = response.data.result.error;
                                    }
                                } else {
                                    $scope.modelView.report = response.data.result;
                                    var arr = [];
                                    angular.forEach($scope.modelView.report, function (value, key) {
                                        arr.push(key);
                                        //заменяем пустые значения на "-"
                                        angular.forEach(value.data, function (item, itemKey) {
                                            if(value.data[itemKey] === "") value.data[itemKey] = "-";
                                        })
                                    });
                                    $scope.modelView.years = arr.sort(function (a, b) {
                                        return a>b ? 1 : -1;
                                    });
                                    //по умолчанию отображаем последний год
                                    if($scope.modelView.report){
                                        $scope.modelView.selectYear = $scope.modelView.years[$scope.modelView.years.length-1];
                                    }
                                    $scope.selectYear($scope.modelView.selectYear);
                                }
                                $scope.loaded = true;
                            } else {
                                $scope.errorInfo = response.data.errorMessage ? response.data.errorMessage : "Получить информацию о компании в данный момент невозможно.";
                                $scope.loaded = false;
                            }
                            tassBusinessSrv.setRequestProcessed('reporting', true);
                        }, function () {
                            $scope.errorInfo = "Получить информацию о компании в данный момент невозможно.";
                            $scope.loaded = false;
                            tassBusinessSrv.setRequestProcessed('reporting', true);
                        })
                    });
                }
                /*
                * Выбрать год, за который отображать данные
                * */
                function selectYearFn(year) {
                    $scope.model = $scope.modelView.report[year];
                    $scope.modelView.selectYear = year;
                }
                /*
                * Скрыть пустые записи
                * */
                function showHideRowFn(index3, index4) {
                    var result = true;
                    //признак скрытия пустых строк
                    if(!$scope.modelView.showEmpty){
                        //Скрываем только если обе строки = '-', пустые или нули
                        result = !((index3 ==="-" && index4 ==="-") || (!index3 && !index4) || (index3 ===0 && index4 ===0) || (index3 ==="0" && index4 ==="0"))  ;
                    }
                    return result;
                }
                /*
                 * Показать 'Выберите период'
                 **/
                function showsYearsFn() {
                     return $scope.modelView.years && Object.keys($scope.modelView.years).length > 0;
                }
            }]
    });

