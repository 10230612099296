/**
 * @author: alexandrbu
 * БГ участники. список анкет
 */
angular.module('uetp')
    .component('orderList', {
        template: '<div ng-include="ctrl.templateUrl">',
        bindings: {
            bank: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'bgParticipantsSrv', 'authSrv', 'desktopSrv', 'dialogSrv', 'paginationSrv',
            function ($scope, $rootScope, $state, $stateParams, bgParticipantsSrv, authSrv, desktopSrv, dialogSrv, paginationSrv) {
                let ctrl = this;
                $scope.ctrl = ctrl;
                $scope.ctrl.templateUrl = '';

        	  this.$onInit = function () {
                 $scope.cabType = desktopSrv.getCabType;
                 $scope.authData = authSrv;
                 $scope.user = authSrv.getUser();
                 $scope.totalPages = 0;
                 $scope.paginator = {
                     itemsPerPage: 20,
                     page: 0,
                     orderAsc: false,
                     bank: ''
                 };
                  $scope.orderListTpl = {
                      'ra':'app/components/bgParticipants/order/orderListRaif.html',
                      'lo':'app/components/bgParticipants/order/orderListLoko.html',
                      'vtb':'app/components/bgParticipants/order/orderListVtb.html',
                      'ab':'app/components/bgParticipants/order/orderList.html'
                  };
                  let configPag = paginationSrv.getPaginationStateConfig('pagination', $state.current.name); // память пагинации
                  if (configPag) {
                      $scope.paginator.page = configPag.page;
                      $scope.paginator.itemsPerPage = configPag.itemsPerPage;
                  }
                  let configSort = paginationSrv.getPaginationStateConfig('sort', $state.current.name); // память сортировки
                  if (configSort) {
                      if (configSort.orderBy) {
                          setTimeout(function() {
                              let elements = window.document.querySelectorAll('[st-sort="' + configSort.orderBy + '"]');
                              if (elements && elements.length > 0) elements[0].classList.add(configSort.orderAsc ? "sortAsc" : "sortDesc");
                          }, 500)
                      }
                      $scope.paginator.orderAsc = configSort.orderAsc;
                      $scope.paginator.orderBy = configSort.orderBy;
                  }
                 _initModelView();
              };
              $scope.loadOrderList = loadOrderListFn;
              $scope.dbClickRow = dbClickRowFn;
              $scope.changeSort = changeSortFn;
              
                /**
                 * Инициализация модели в зависимости от ЛК
                 * @private
                 */
                function _initModelView() {
                    $scope.paginator.bank = ctrl.bank;
                    $scope.ctrl.templateUrl = $scope.orderListTpl[$scope.paginator.bank || 'ab'];
                    loadOrderListFn();
                }

                /**
                 * Список анкет участников
                 */
                function loadOrderListFn() {
                 	bgParticipantsSrv.loadOrderList($scope.paginator).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.orderList = response.data.items;
                            $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                        } else {
                            $scope.orderList = [];
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    })
                }

                /**
                 * Двойной клик по ячейке таблицы
                 * @param data - данные строки
                 * @param param - параметры клика
                 */
                function dbClickRowFn(data, param) {
                    if (param) {
                        $state.go(param, {id: data.id, clearConfig: true})
                    } else if (data.participant && data.participant.id) {
                        $state.go($scope.paginator.bank === 'vtb' ? 'bankGuaranteesVTBAdmin' : 'bankGuaranteesAdmin', {
                            participantId: data.participant.id,
                            clearConfig: true
                        });
                    }
                }
                
                function changeSortFn($event) {
                    let th = $event.currentTarget,
                        orderBy = th.getAttribute('st-sort'),
                        orderAsc = th.classList.contains("sortAsc");

                    [].forEach.call(document.querySelectorAll(".sortColumn"), function (column) {
                        column.classList.remove("sortDesc");
                        column.classList.remove("sortAsc");
                    });
                    orderAsc = !orderAsc;
                    th.classList.add(orderAsc ? "sortAsc" : "sortDesc");
                    if (orderBy) {
                        // $scope.paginator.page = 0;
                        $scope.paginator.orderBy = orderBy;
                        $scope.paginator.orderAsc = orderAsc;
                        paginationSrv.setPaginationStateConfig('sort', $state.current.name, {
                            orderBy: orderBy,
                            orderAsc: orderAsc
                        });
                        loadOrderListFn();
                    }
                }
            }]
    });

