
angular.module('uetp')
    .component('factoring', {
        templateUrl: 'app/components/factoring/factoring.html',
        controller: [
            function () {

                this.$onInit = function () {
                    
                }
            }]
    });
