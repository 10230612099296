/**
 * @author: sergeyu
 * [-]
 */
angular.module('uetp')
    .component('versionInfoTab', {
        templateUrl: 'app/components/profile/versionInfo/versionInfo.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', '$filter', 'httpSrv', 'desktopSrv', 'timeout', 'dictionarySrv', 'paginationSrv',
            function ($scope, $rootScope, $state, $stateParams, $filter, httpSrv, desktopSrv, timeout, dictionarySrv, paginationSrv) {

                $scope.cabType = desktopSrv.getCabType();
                $scope.versionInfoLoad = versionInfoLoadFn;
                $scope.downloadFile = httpSrv.downloadFile;
                $scope.getVersionInfoFileName = getVersionInfoFileNameFn;

                $scope.types = dictionarySrv.getStaticDictByType('versionInfoTypes').filter(item=>item.id!=='all');

                this.$onInit = function () {
                    $scope.totalPages = 0;
                    $scope.paginator = {
                        itemsPerPage: 20,
                        page: 0
                    };
                    const config = paginationSrv.getPaginationStateConfig('pagination', $state.current.name);
                    if (config) {
                        $scope.paginator.page = config.page;
                        $scope.paginator.itemsPerPage = config.itemsPerPage;
                    }
                    const configSort = paginationSrv.getPaginationStateConfig('sort', $state.current.name);
                    if (configSort) {
                          $scope.paginator.orderAsc = configSort.orderAsc;
                          $scope.paginator.orderBy = configSort.orderBy;
                    }
                    if(['admincab', 'support'].includes($scope.cabType)) {
                        $scope.type = $scope.types[0].id;
                        versionInfoLoadFn($scope.type);
                    } else if(['organizer', 'participant'].includes($scope.cabType))
                        versionInfoLoadFn($scope.cabType==='organizer' ? 'org' : 'uch');
                };

                function getVersionInfoFileNameFn(version) {
                    return `Информация об изменениях в версии ${version.number.replaceAll('.', '_')} от ${$filter('date')(version.releaseDate, 'dd.MM.yyyy','UTC+3').replaceAll('.', '_')}.pdf`;
                }

                $scope.changeType = (type) => {
                    $scope.type = type;
                    $scope.paginator.page = 0;
                    versionInfoLoadFn(type);
                }

                function versionInfoLoadFn(type) {
                    const param = angular.copy($scope.paginator);

                    param.searchByField = {state: 'published'};
                    param.searchByField.type = type ? type : (['organizer', 'participant'].includes($scope.cabType) ?
                        ($scope.cabType==='organizer' ? 'org' : 'uch') : $scope.type);

                    if (!param.orderBy) {
                        param.orderBy = "releaseDate";
                        param.orderAsc = false;
                    }

                    httpSrv.http({
                        url: '/news/versions/getVersionInfos',
                        data: param,
                        showCounter: true
                    }).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.versionInfos = response.data.items;
                            $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                        }
                        timeout($scope);
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }
                $scope.switchVersionInfo = (versionInfo) =>{
                    $scope.versionInfos.forEach(vi=>{
                        if(vi.id!==versionInfo.id)
                            vi.opened = false;
                    });
                    if(!versionInfo.opened)
                        versionInfo.opened = !versionInfo.opened;
                }

            }]
    });

