angular.module('uetp').directive('ngDoubleTap', function () {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            let lastTap = 0;
            element.bind('touchend', function (event) {
                let currentTime = new Date().getTime();
                let tapLength = currentTime - lastTap;
                if (tapLength < 500 && tapLength > 0) {
                    scope.$apply(function () {
                        scope.$eval(attrs.ngDoubleTap);
                    });
                    event.preventDefault();
                }
                lastTap = currentTime;
            });
        }
    };
});