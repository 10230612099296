/**
 * Страница "Сервисы безопасности закупок"
 */
angular.module('uetp')
    .component('servicesPage', {
        template: '<div ng-include="templateUrl">',
        bindings: {
            searchCompanyLimit: '<',
            priceAnalysisLimit: '<',
            subscriptionLimit: '<'
        },
        controller: ['$scope', '$state', 'filterService', 'tariffsSrv', 'dialogSrv', 'authSrv', 'desktopSrv',
            function ($scope, $state, filterService, tariffsSrv, dialogSrv, authSrv, desktopSrv) {
                $scope.modelView = {
                    companyValidateInfoView: false,
                    companyValidateSearch: '',
                    priceAnalysisPaginator: {},
                    otherProceduresPaginator: {},
                    btnTariffDisabled: true,
                    dTariffName: '',
                    btnTariffName: '',
                    isHaveTariffs: false,
                    countLeft: {
                        searchCompany: 0,
                        priceAnalysis: 0,
                        subscription: 0
                    }
                }
                $scope.submitted = false;
                $scope.companyValidateForm = {};

                $scope.searchCompany = searchCompanyFn;
                $scope.priceAnalysisTableLoad = priceAnalysisTableLoadFn;
                $scope.otherProceduresLoad = otherProceduresLoadFn;
                $scope.createSubscription = createSubscriptionFn;
                $scope.goSubscriptionsTable = goSubscriptionsTableFn;
                $scope.activateTariff = activateTariffFn;
                $scope.filterSearch = filterSearchFn;
                this.$onInit = function () {
                    const ctrl = this;
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.templateUrl = $scope.cabType() === 'agent' ? 'app/components/servicesPage/servicesPageAgent.html'
                        : 'app/components/servicesPage/servicesPage.html';
                    // Проставляем имя фильтра
                    // TODO: в случае добавления еще одного простого поиска на страницу
                    //  в filterService необходимо подержать установку placeholder через filterType
                    filterService.initModelView('Наименование закупки, код/наименование номенклатуры по ОКПД2, ОКВЭД2, КТРУ', 'Иные закупки');
                    $scope.modelView.isHaveTariffs = authSrv.haveTariffsFormService();
                    $scope.modelView.countLeft = {
                        searchCompany: ctrl.searchCompanyLimit.data.result,
                        priceAnalysis: ctrl.priceAnalysisLimit.data.result,
                        subscription: ctrl.subscriptionLimit.data.result
                    }
                    tariffsSrv.getTariffsProfile().then(function successCallback(response){
                        if (response.data.success) {
                            const dTariff = response.data.items.find(item =>['D01','D11'].includes(item.id) && item.active);
                            if(dTariff){
                                $scope.modelView.btnTariffName = 'Подключить тариф '+dTariff.id;
                                $scope.modelView.dTariffName = dTariff.id;
                                $scope.modelView.btnTariffDisabled = false;
                            } else {
                                $scope.modelView.btnTariffName = 'Подключить тариф D11';
                                $scope.modelView.dTariffName = 'D11';
                            }
                        }
                    }, function errorCallback() {
                        $scope.modelView.btnTariffName = 'Подключить тариф D11';
                        $scope.modelView.dTariffName = 'D11';
                    });

                }

                /**
                 * Переход в раздел "Проверка компании"
                 * разрешен переход в режиме промодоступа или тем, у кого есть тарифы
                 */
                function searchCompanyFn() {
                    if ($scope.modelView.countLeft.searchCompany > 0) {
                        if($scope.modelView.companyValidateSearch)
                            $state.go('companyViewTass', {id: $scope.modelView.companyValidateSearch});
                        else
                            $state.go('companyValidationTass');
                    }
                }

                /**
                 * Переход в раздел "Анализ цен"
                 * разрешен переход в режиме промодоступа или тем, у кого есть тарифы
                 */
                function priceAnalysisTableLoadFn() {
                    if ($scope.modelView.countLeft.priceAnalysis > 0) {
                        $state.go('priceAnalysisTable', getParamToGo('filterPriceAnalysis'));
                    }
                }

                /**
                 * Переход в раздел "Реестр закупок ЕИС"
                 */
                function otherProceduresLoadFn() {
                    $state.go('otherProcedures', getParamToGo('filterProceduresOther'));
                }

                /**
                 * Переход в раздел "Создать подписку на закупки ЕИС"
                 * разрешен переход в режиме промодоступа или тем, у кого есть тарифы
                 */
                function createSubscriptionFn() {
                    if ($scope.modelView.countLeft.subscription > 0) {
                        $state.go('createSubscription', {clearConfig: true});
                    }
                }

                /**
                 * Переход в раздел "Подписки на закупки ЕИС"
                 * разрешен переход в режиме промодоступа или тем, у кого есть тарифы
                 */
                function goSubscriptionsTableFn() {
                    $state.go('subscriptionTable', {clearConfig: true});
                }

                /**
                 * Переход на состояние через запуск фильтра
                 * @param filterType - тип фильтра
                 */
                function filterSearchFn(filterType) {
                    $scope.$broadcast('filterSearchOutside', {filterType});
                }

                /**
                 * Получени данных фильтра в немодифицированном виде
                 * необходимо передать данные фильтра, так как они есть
                 * @param filterType - тип фильтра
                 * @return {
                 *     filterType,
                 *     searchParams || filterParams
                 * }
                 */
                function getParamToGo(filterType) {
                    const result = {
                        filterType: filterType
                    };
                    const filterParams = angular.copy(filterService.getFilterNoModifyParams(filterType)),
                        searchParam = angular.copy(filterService.getSearchParam(filterType));
                    if (filterParams && Object.keys(filterParams).length > 0) {
                        result.filterParams = filterParams;
                    } else {
                        result.searchParams = searchParam
                    }
                    return result;
                }

                function activateTariffFn() {
                    tariffsSrv.activateTariff({id: $scope.modelView.dTariffName}).then(function (response) {
                        if (response.data.success) {
                            dialogSrv.openModal('_dialogMessage.html',
                                {message: 'На электронную почту отправлены документы для оплаты подключения тарифного плана.'});
                        } else {
                            dialogSrv.openModal('_dialogMessage.html', {message: response.data.message || 'Произошла ошибка. Обратитесь в тех.поддержку.'})
                        }
                    })
                }
            }]
    });
