/**
 * @author: dmitrys
 * Сервис для получаения сообщения у разных сервисов при переходе назад в браузере
 */
angular.module('uetp').service('needConfirmStateSrv', ['bankGuaranteeSrv',
    function (bankGuaranteeSrv) {
        var service = this;

        service.getNeedConfirmMsg = getNeedConfirmMsgFn;

        /**
         * Получить сообщение в зависимости от state
         * @param state - состояние
         */
        function getNeedConfirmMsgFn(state){
            switch (state) {
                case 'bankGuaranteeApp':
                    return bankGuaranteeSrv.getNeedConfirmMsg();
                default:
                    throw new Error("Необходимо указать state");
            }
        }
    }]);