/**
 * @author: sergeyu
 * Отчетность компании
 */
angular.module('uetp')
    .component('reportingTass', {
        bindings: {
            tassId: '='
        },
        templateUrl: 'app/components/widgets/tassBusiness/companyValidate/view/reporting/reporting.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'tassBusinessSrv','propertiesService',
            function ($scope, $rootScope, $state, $stateParams, tassBusinessSrv,propertiesService) {
                var ctrl = this;
                $scope.loaded = false; // контроль загруки. Один раз при первом отображении
                $scope.modelView = {};

                $scope.loadReport = loadReportFn;

                this.$onInit = function () {
                    if(!$scope.loaded) $scope.loadReport();
                };
                function loadReportFn() {
                    propertiesService.getProperty("api.url").then(function(host){
                        tassBusinessSrv.getOrganizationFinance($scope,ctrl.tassId,$stateParams.id,host).then(function (response) {
                            $scope.errorInfo = null;
                            if (response.data.success) {
                                if (response.data.result.error) {
                                    if (response.data.result.error.indexOf('не активна') !== -1) {
                                        $scope.errorInfo = '<span class="red">Недействующее предприятие</span>';
                                    } else {
                                        $scope.errorInfo = response.data.result.error;
                                    }
                                } else {
                                    var arr = [];
                                    angular.forEach(response.data.result, function (value, key) {
                                        arr.push({year: key, data: value})
                                    });
                                    //сортируем по убыванию
                                    $scope.modelView = arr.sort(function (a, b) {
                                        return a.year < b.year ? 1 : -1;
                                    });
                                }
                                var verifyResult = tassBusinessSrv.getVerifyResult();
                                if(verifyResult){
                                   verifyResult.financeValue = angular.copy(response.data.result);
                                   tassBusinessSrv.setVerifyResult(verifyResult);
                                }
                                $scope.loaded = true;
                            } else {
                                $scope.errorInfo = response.data.errorMessage ? response.data.errorMessage : "Получить информацию о компании в данный момент невозможно.";
                                $scope.loaded = false;
                            }
                            tassBusinessSrv.setRequestProcessed('reporting', true);
                        }, function () {
                            $scope.errorInfo = "Получить информацию о компании в данный момент невозможно.";
                            $scope.loaded = false;
                            tassBusinessSrv.setRequestProcessed('reporting', true);
                        })
                    });
                }
            }]
    });

