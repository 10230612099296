angular.module('uetp').directive("ngFormChange",  function () {
    return {
        require: "form",
        restrict: 'A',
        scope: {
            callback:"=ngFormChange",
            watchList: "@watchList"
        },
        link: function (scope, element) {
            element.on("input", function ($event) {
                const elemName = $event.target.name;
                const isIgnored = $event.target.type === 'checkbox';
                const hasChangedFields = !scope.watchList || scope.watchList.split(",").includes(elemName);
                if (typeof scope.callback === "function" && hasChangedFields && !isIgnored) scope.callback(scope);
            });
        }
    }
});