
angular.module('uetp').service('completedWorksSrv', ['$state', 'httpSrv', 'authSrv', 'cryptography',
    function ($state, httpSrv, authSrv, cryptography) {
        const service = this;
        service.getCompletedWork = getCompletedWorkFn;
        service.loadCompletedWorks = loadCompletedWorksFn;
        service.signAndSend = signAndSendFn;
        service.getDataForSign = getDataForSignFn;
        service.sendSignData = sendSignDataFn;

        function getCompletedWorkFn(id) {
            return httpSrv.http({
                method: 'GET',
                url:'/site/act/'+id
            });
        }

        function loadCompletedWorksFn(param,  participantId) {
            const url = participantId ? `/site/act/getCompletedWorksListByOrg/${participantId}`
                :'/site/act/getCompletedWorksList';
            return httpSrv.http({
                method: 'POST',
                url: url,
                data: param,
                showCounter: true
            })
        }

        function signAndSendFn(id, data, certId) {
            const model = angular.copy(data);
            return service.getDataForSign(id, model).then(function (response) {
                if (response.data.success) {
                    return cryptography.generateSignature(response.data.result, certId).then(function (value) {
                        model.signature = value;
                        return service.sendSignData(id, model);
                    }, function (reason) {
                        if (typeof reason === 'string') {
                            return Promise.reject("Ошибка подписи документа. " + reason);
                        } else {
                            return Promise.reject("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                        }
                    });
                } else {
                    return Promise.resolve(response);
                }
            }, function (reason) {
                return Promise.resolve(reason);
            })
        }

        /**
         * Получение данных для подписи
         */
        function getDataForSignFn(id, model) {
            return httpSrv.http({
                method: 'POST',
                url: '/site/act/getDataForSign/' + id,
                data: model
            });
        }

        function sendSignDataFn(id, model){
            return httpSrv.http({
                method: 'POST',
                url: '/site/act/createCustomerDoc/' + id,
                data: model
            })
        }

    }]);