/**
 * @author: sergeyu
 * Поиск и фильтр
 */
angular.module('uetp')
    .component('filter', {
        templateUrl: 'app/components/filter/searchFilter.html',
        bindings: {
            callback: '=',
            filterShow: '@', // show or hide
            topPaginatorShow: '@', // show or hide
            topPaginatorTotalPages: '=', // total pages for top paginator
            scShow: '@', // show or hide <sc></sc> (по-умолчанию show)
            isTableFilter: '@', // table - фильтр работает с таблицей или нет(not)  (по-умолчанию table)
            cleanBtnShow: '@', // show or hide
            filterType: '@', // указывается имя файла html, но без расширения html
            paginator: '=',
            smallField: '@',
            open:'@' //открытый фильтр (незакрывающийся)
        },
        controller: ['$scope', '$rootScope', '$filter', 'constants', '$state', '$stateParams', '$timeout', '$q', 'filterService', 'desktopSrv', 'dictionarySrv', 'alertSrv', 'timeout', 'profileSrv',
            function ($scope, $rootScope, $filter, constants, $state, $stateParams, $timeout, $q, filterService, desktopSrv, dictionarySrv, alertSrv, timeout, profileSrv) {
                var ctrl = this;
                $scope.modelView = filterService.modelView;
                $scope.selectArrAll = [
                    {code:'sent', name:'Ожидает обработки'},
                    {code:'processing', name:'В работе'},
                    {code:'accred', name:'Аккредитовано'},
                    {code:'notaccred', name:'Не аккредитовано'},
                    {code:'deferred', name:'Отложено'},
                    {code:'revoked', name:'Отозвано'}
                ];
                $scope.selectArrWaiting = [
                    {code:'sent', name:'Ожидает обработки'},
                    {code:'processing', name:'В работе'},
                    {code:'deferred', name:'Отложено'}
                ];
                $scope.selectArr = [];
                const year = (getServerTimeMsk()||new Date()).getFullYear();
                $scope.years = [{year : year.toString()}, {year: (year + 1).toString()}];

                this.$onInit = function () {
                    $scope.$state = $state;
                    $scope.$stateParams = $stateParams;
                    $scope.constants = constants;
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.filterAction = false;
                    $scope.searchInProcess = false;
                    $scope.$stateParams.notApproved ? $scope.selectArr = $scope.selectArrWaiting : $scope.selectArr = $scope.selectArrAll;
                    $rootScope.$on("searchInProcess", function(event, value){
                        $scope.searchInProcess = value;
                    });
                    /**
                     * Запуск фильтра вне кмопонента фильтра
                     */
                    $scope.$on('filterSearchOutside', (event, data, resetParams) =>{
                        // Необходимо передавать тип фильтра(у компонентов может быть несколько компонентов фильтра)
                        if(data.filterType === ctrl.filterType){
                            if(resetParams){
                                $scope.filter = filterService.getFilterNoModifyParams(ctrl.filterType);
                                // Значения фильтра по-умолчанию
                                initDefaultFilter(ctrl.filterType);
                            }
                            // Запуск простого или расширеного фильтра по filterAction
                            $scope.filterAction ? ctrl.filterSearchStart(): ctrl.searchStart();
                        }
                    });
                    init();
                };
                ctrl.searchStart = searchFn;
                ctrl.filterSearchStart = filterSearchFn;
                ctrl.filterOpen = filterOpenFn;
                ctrl.cleanField = cleanFieldFn;
                ctrl.filterClear = filterClearFn;
                ctrl.filterAndSearchClear = filterAndSearchClearFn;
                ctrl.arrayFiltered = arrayFilteredFn;
                $scope.fillMinMaxOpt = fillMinMaxOptFn;
                $scope.fillBoundMinMaxOpt = fillBoundMinMaxOptFn;
                $scope.refreshDict = refreshDictFn;
                $scope.resetFilterField = resetFilterFieldFn;
                $scope.getForm = getFormFn;
                $scope.setMaxDate = setMaxDateFn;

                /**
                 * Выставление параметров в сервис
                 * @param param - параметры для запроса на сервер фильтр
                 * @param filterType - тип фильтра
                 */
                function setFilterParams(param, filterType) {
                    filterService.setFilterParams(param, filterType);
                }

                /**
                 * Выставление заполненную модель в сервис
                 * @param model - модель данных фильтра
                 * @param filterType - тип фильтра
                 */
                function setFilterNoModifyParams(model, filterType) {
                    filterService.setFilterNoModifyParams(model, filterType);
                }

                /**
                 * Поиск по строке поиска
                 */
                function searchFn() {
                    $scope.searchInProcess = true;
                    ctrl.paginator.page = 0;
                    $scope.filter = {};

                    if(['filterPurchasePlan','filterPurchasePlanAdmin'].includes(ctrl.filterType) && !ctrl.open)
                        $scope.filter.hiddenyear = 'empty';

                    setFilterParams($scope.filter, ctrl.filterType);
                    filterService.setSearchParam(ctrl.paginator.search, ctrl.filterType);
                    $q.when(ctrl.callback()).then(searchEnded, searchEnded);
                }

                function searchEnded(){
                    $scope.searchInProcess = false;

                    if(['filterPurchasePlan','filterPurchasePlanAdmin'].includes(ctrl.filterType) && $scope.filter.hiddenyear)
                        delete $scope.filter.hiddenyear;

                    timeout($scope);
                }

                /**
                 * Поиск по фильтру
                 * @param isControl - контроль над нумерацией
                 */
                function filterSearchFn(isControl, notClearSearch) {
                    $scope.searchInProcess = true;
                    if($scope.filter.s_claimBeginsDate && $scope.filter.e_claimBeginsDate)
                        if($scope.filter.s_claimBeginsDate > $scope.filter.e_claimBeginsDate){
                            $scope.filter.e_claimBeginsDate = $scope.filter.s_claimBeginsDate;
                        }
                    if($scope.filter.s_auctionDateStart && $scope.filter.e_auctionDateStart)
                        if($scope.filter.s_auctionDateStart > $scope.filter.e_auctionDateStart){
                            $scope.filter.e_auctionDateStart = $scope.filter.s_auctionDateStart;
                        }
                    if($scope.filter.s_claimEndsDate && $scope.filter.e_claimEndsDate)
                        if($scope.filter.s_claimEndsDate > $scope.filter.e_claimEndsDate){
                            $scope.filter.e_claimEndsDate = $scope.filter.s_claimEndsDate;
                        }
                    if($scope.filter.s_claimEnds && $scope.filter.e_claimEnds)
                        if($scope.filter.s_claimEnds > $scope.filter.e_claimEnds){
                            $scope.filter.e_claimEnds = $scope.filter.s_claimEnds;
                        }
                    if($scope.filter.s_created && $scope.filter.e_created)
                        if($scope.filter.s_created > $scope.filter.e_created){
                            $scope.filter.e_created = $scope.filter.s_created;
                        }
                    if($scope.filter.s_sent && $scope.filter.e_sent)
                        if($scope.filter.s_sent > $scope.filter.e_sent){
                            $scope.filter.e_sent = $scope.filter.s_sent;
                        }
                    if($scope.filter.s_lotsPrice && $scope.filter.e_lotsPrice)
                        if (parseFloat($scope.filter.s_lotsPrice) > parseFloat($scope.filter.e_lotsPrice)) {
                            var e = $scope.filter.e_lotsPrice;
                            $scope.filter.e_lotsPrice = $scope.filter.s_lotsPrice;
                            $scope.filter.s_lotsPrice = e;
                        }
                    if (!$scope.filter.s_signDate && ctrl.filterType === 'filterPriceAnalysis') {
                        $scope.filter.s_signDate = new Date(2018, 0, 1);
                    }

                    const filter = ['filterPurchasePlan', 'filterPurchasePlanAdmin'].includes(ctrl.filterType) ? {hiddenyear: 'empty'} : {};

                    setTimeout(function () {
                        if ($scope.form && $scope.form.filterForm && !$scope.form.filterForm.$valid) {
                            $scope.alertObjFilter = alertSrv.getErrorMsg('Форма содержит ошибки.');
                            searchEnded();
                            return;
                        }
                        if (countObjectFields($scope.filter) > 0 || $scope.filterAction) {
                            if(!notClearSearch){
                               ctrl.paginator.search = '';
                            }
                            filterService.setSearchParam(ctrl.paginator.search, ctrl.filterType);
                        }

                        angular.copy($scope.filter, filter);
                        if(['filterPurchasePlan','filterPurchasePlanAdmin'].includes(ctrl.filterType) && !filter.year)
                            filter.hiddenyear = 'empty';
                        // Заполняем filter данными для отправки на сервер
                        filterService.prepareFilterFields(filter, $scope.$stateParams.type);
                        if (!isControl) {
                            ctrl.paginator.page = 0;
                        }
                        $scope.submitted = true;
                        setFilterParams(filter, ctrl.filterType);
                        setFilterNoModifyParams($scope.filter, ctrl.filterType);
                        $q.when(ctrl.callback()).then(searchEnded, searchEnded);
                    },100);

                    $rootScope.$emit("filterSearch");
                }

                /**
                 * Открытие фильтра
                 */
                function filterOpenFn() {
                    if ($scope.filterAction) {
                        setFilterParams({}, ctrl.filterType);
                        setFilterNoModifyParams({}, ctrl.filterType);
                    } else {
                        $scope.blockFilterFields = $rootScope.blockFilterFields;
                        dictionarySrv.resetPaginatedDict($scope);
                        loadDictByFilterType();
                    }

                    $scope.filterAction = !$scope.filterAction;

                    if(ctrl.filterType === 'filterPurchasePlan') {
                        ctrl.open = $scope.filterAction;
                        if (!$scope.filter.year)
                            $scope.filter.year = year.toString();
                    }

                    $scope.dict = angular.copy($rootScope.dict); // чтоб не вызывать digest. Справочники могли сразу не успеть загрузиться.
                    timeout($scope);
                }

                /**
                 * Сброс поля ввода
                 */
                function cleanFieldFn() {
                    ctrl.paginator.search = '';
                }

                function filterAndSearchClearFn() {
	                ctrl.filterClear();
                    ctrl.paginator.search = '';
                    filterService.setSearchParam(ctrl.paginator.search, ctrl.filterType);
                }

                /**
                 * Предзагрузка справочника по типу фильтра
                 */
                function loadDictByFilterType() {
                    switch (ctrl.filterType){
                        case "filterNotices":
                            $scope.refreshDict('customers', null, true);
                            break;
                        case "filterOffers":
                            $scope.refreshDict('rOKPD2', null, true);
                            $scope.refreshDict('rSKMTR', null, true);
                            break;
                        case "filterOrganizator":
                            $scope.refreshDict('customers', null, true);
                            break;
                        case "filterPosition":
                            $scope.refreshDict('rOKPD2', null, true);
                            $scope.refreshDict('rSKMTR', null, true);
                            break;
                        case "filterMyOffers":
                            $scope.refreshDict('rOKPD2', null, true);
                            $scope.refreshDict('rSKMTR', null, true);
                            break;
                        case "filterProcedures":
                            $scope.refreshDict('organizers', null, true);
                            $scope.refreshDict('customers', null, true);
                            // $scope.refreshDict('rOKVED2', null, true);
                            // $scope.refreshDict('rOKPD2', null, true);
                            break;
                        case "filterProcedures44":
                            $scope.refreshDict('organizers', null, true);
                            $scope.refreshDict('customers', null, true);
                            // $scope.refreshDict('rOKVED2', null, true);
                            // $scope.refreshDict('rOKPD2', null, true);
                            break;
                        case "filterProceduresSOC":
                            $scope.refreshDict('organizers', null, true);
                            $scope.refreshDict('customers', null, true);
                            // $scope.refreshDict('rOKVED2', null, true);
                            // $scope.refreshDict('rOKPD2', null, true);
                            break;
                       case "filterProceduresAll":
                            $scope.refreshDict('organizers', null, true);
                            $scope.refreshDict('customers', null, true);
                            // $scope.refreshDict('rOKVED2', null, true);
                            // $scope.refreshDict('rOKPD2', null, true);
                            break;
                       case "filterCabProcedures":
                            $scope.refreshDict('organizers', null, true);
                            $scope.refreshDict('customers', null, true);
                            // $scope.refreshDict('rOKVED2', null, true);
                            // $scope.refreshDict('rOKPD2', null, true);
                            break;
                       case "filterUC":
                            $scope.refreshDict('rSKMTR', null, true);
                            // $scope.refreshDict('rOKPD2', null, true);
                            break;
                       case "filterPurchasePlan":
                            //    выключена пред загрузка, т.к. спровчоники работают по поиску от 3 символов
                            // $scope.refreshDict('rOKPD2EIS', null, true);
                            // $scope.refreshDict('rOKVED2EIS', null, true);
                            // $scope.refreshDict('rKTRU', null, true);
                            $scope.refreshDict('customers', null, true);
                            break;
                       case "filterPurchasePlanAdmin":
                            //    выключена пред загрузка, т.к. спровчоники работают по поиску от 3 символов
                            // $scope.refreshDict('rOKPD2EIS', null, true);
                            // $scope.refreshDict('rOKVED2EIS', null, true);
                            // $scope.refreshDict('rKTRU', null, true);
                            $scope.refreshDict('headDzoCustomers223', null, true);
                            break;
                    }
                }
                /**
                 * Очистка данных фильтра
                 * @param notClearParams - не сбрасывать параметры фильтра
                 */
                function filterClearFn(notClearParams, initDefault) {
                    $scope.filter = ['filterPurchasePlan','filterPurchasePlanAdmin'].includes(ctrl.filterType) ? {hiddenyear: 'empty', year: year.toString()} : {};
                    setMinMaxOpt();
                    if(initDefault) initDefaultFilter(ctrl.filterType);
                    if(notClearParams) return;
                    $scope.searchInProcess = true;
                    // dictionarySrv.resetPaginatedDicts($scope,['rKTRU','rOKVED2EIS','rOKPD2EIS']);
                    setFilterParams($scope.filter, ctrl.filterType);
                    setFilterNoModifyParams($scope.filter, ctrl.filterType);
                    $q.when(ctrl.callback()).then(searchEnded, searchEnded);
                    $rootScope.$emit("filterClear");
                }

                /**
                 * Подсчет заполненных полей в фильтре
                 * @param obj
                 * @returns {number}
                 */
                function countObjectFields(obj) {
                    var type = typeof(obj);
                    if (type !== "object" || obj == null) return 0;
                    return Object.keys(obj).length;
                }


                function init() {
                    $scope.form = {};
                    $scope.filterUrl = 'app/components/filter/viewsFilter/';
                    $scope.submitted = false;
                    $scope.filterElement = {};
                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                    $scope.typeOrg = dictionarySrv.getStaticDictByType('typeOrg');
                    $scope.typeCustomer = dictionarySrv.getStaticDictByType('typeCustomer');
                    $scope.typePortal = dictionarySrv.getStaticDictByType('typePortal');
                    $scope.purchaseMethod = dictionarySrv.getStaticDictByType('purchaseMethod');
                    $scope.purchaseMethod44 = dictionarySrv.getStaticDictByType('purchaseMethod44');
                    $scope.purchaseStatus = dictionarySrv.getStaticDictByType('purchaseStatus');
                    $scope.purchaseStatusNoClaiming = dictionarySrv.getStaticDictByType('purchaseStatusNoClaiming');
                    $scope.purchaseStatus44 = dictionarySrv.getStaticDictByType('purchaseStatus44');
                    $scope.purchaseStatusSOC = dictionarySrv.getStaticDictByType('purchaseStatusSOC');
                    $scope.typeProcedure = dictionarySrv.getStaticDictByType('typeProcedure');
                    $scope.typeProcedureSCO = dictionarySrv.getStaticDictByType('typeProcedureSCO');
                    $scope.typeProcedureEIS = dictionarySrv.getStaticDictByType('typeProcedureEIS');
                    $scope.purchaseStatusEIS = dictionarySrv.getStaticDictByType('purchaseStatusEIS');
                    $scope.placementTypes = dictionarySrv.getStaticDictByType('placementTypes');
                    $scope.utensilsMSP = dictionarySrv.getStaticDictByType('utensilsMSP');
                    $scope.claimStates = dictionarySrv.getStaticDictByType('claimStates');
                    $scope.sellerDocStatus = dictionarySrv.getStaticDictByType('sellerDocStatus');
                    $scope.customerRegistrationStates = dictionarySrv.getStaticDictByType('customerRegistrationStates');
                    $scope.procedureList = [];
                    $scope.procedureListCommercial = [];
                    $scope.procedureListAll = [];
                    $scope.procedureListPublic = [];
                    $scope.filterAction = filterService.getActiveFilter(ctrl.filterType,ctrl.open==="true");
                    $scope.fieldsMaxLength = filterService.getMaxLengthFields(ctrl.filterType);
                    $scope.formName = $rootScope.formName;
                    $scope.somePlaceholder = $rootScope.somePlaceholder;
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.state = $state.current;
                    $scope.getProcedureList = getProcedureListFn;
                    $scope.sectionChange = sectionChangeFn;
                    $scope.disabledCustomerType = disabledCustomerTypeFn;

                    $scope.filialsList = [];
                    if ($scope.cabType() === 'participant') {
                        profileSrv.loadFiliationList().then(function (response) {
                            if (response.data.success) {
                                $scope.filialsList = response.data.items.filter(function (filial) {
                                    return !filial.locked;
                                });

                            }
                        });
                    }

                    if (ctrl.filterShow === 'show') {
                        var name = '';
                        if (ctrl.filterType) {
                            name += ctrl.filterType;
                        }
                        $scope.filterUrl += name + '.html';
                    }
                    if(!ctrl.scShow){
                        $scope.scShow = true;
                    }else {
                        $scope.scShow = ctrl.scShow === 'show';
                    }
                    if(!ctrl.isTableFilter){
                        $scope.isTableFilter = true;
                    }else {
                        $scope.isTableFilter = ctrl.isTableFilter === 'table';
                    }
                    //? сохраняем поиск
                    $timeout(function () {
                        ctrl.paginator.search = filterService.getSearchParam(ctrl.filterType);
                    });
                    // Сохраненные или переданные значения фильтра
                    $scope.filter = filterService.getFilterNoModifyParams(ctrl.filterType);
                    // Значения фильтра по-умолчанию
                    initDefaultFilter(ctrl.filterType);
                    setMinMaxOpt();
                    dictionarySrv.getProcedureList().then(function (result) {
                        if (result.data.success) {
                        	$scope.procedureListAll = $scope.state.name==='jointProcedures' ?
                                angular.copy(result.data.result.filter(p => !['prequalification', 'unlimitedprequalification','requestprices'].includes(p.id))) :
                                angular.copy(result.data.result);
                            $scope.procedureList = result.data.result.filter(function (item) {
                                return item.id !== 'simplecontest'
                            })
                            $scope.procedureListCommercial = angular.copy($scope.procedureList);
                            $scope.procedureListPublic = angular.copy($scope.procedureList);
                            
                            const saleauction = {id: "saleauction", message: "Доходный аукцион"};
                            $scope.procedureListAll.push(saleauction);
                            $scope.procedureListCommercial.push(saleauction);
                            $scope.procedureListPublic.push(saleauction);
                            const openpublicoffer = {id: "openpublicoffer", message: "Открытое публичное предложение"};
                            $scope.procedureListAll.push(openpublicoffer);
                            $scope.procedureListCommercial.push(openpublicoffer);
                            $scope.procedureListPublic.push(openpublicoffer);
                            const requestprices = {id: "requestprices", message: "Запрос цен"};
                            $scope.procedureListCommercial.push(requestprices);
                            if($scope.state.name!=='jointProcedures')
                                $scope.procedureListAll.push(requestprices);
                            $scope.procedureListPublic.push(requestprices);

                        }
                    });

                    $scope.dictionary = dictionarySrv.getRemoteData();
                    _initDict(['CATEGORY_question','fias_region','categoryDict'], false);

                    if(ctrl.filterType === 'filterPurchasePlan' && ($state?.current?.startMth || $state?.current?.name)!=='purchasePlanAdmin') {
                        _initDict([['admincab', 'support'].includes($scope.cabType()) ? 'headDzoCustomers223' : 'customersOfOrganizer'], false);
                    }
                }
                function _initDict(dictList, reload) {
                    angular.forEach(dictList, function (name) {
                        dictionarySrv.getDictionaryRemote(name, reload);
                    })
                }
                /**
                 * Значения фильтра по умолчанию
                 * @param filterType - тип фильтра
                 */
                function initDefaultFilter(filterType) {
                    if (filterType === 'filterPurchasePlan') {
                        if (!$scope.filter.year) {
                            $scope.filter.year = year.toString();
                        }
                        ctrl.open = true;
                        $scope.filterAction = true;
                        if (!$scope.filter.year)
                            $scope.filter.year = year.toString();
                    }
                    if (filterType === 'filterPriceAnalysis') {
                        if (!$scope.filter.section) {
                            $scope.filter.section = {
                                44: true,
                                223: true
                            }
                        }
                        if (!$scope.filter.s_signDate)
                            setTimeout(() => {
                                const date = getServerTimeMsk()||new Date();
                                const yearAgo = date.setFullYear(date.getFullYear() - 1);
                                $scope.filter.s_signDate = new Date(yearAgo);
                            }, 500);
                    }
                    
                    if(filterType === 'filterEisMonitoring') {
                        setTimeout(function () {
                            const mskDate = getServerTimeMsk();
                            if (mskDate && !$scope.filter.s_publicDate && !$scope.filter.e_publicDate) {
                                $scope.filter.s_publicDate = mskDate;
                                $scope.filter.e_publicDate = mskDate;
                                var filterModify = {
                                    s_publicDate: $filter('date')(mskDate, 'dd.MM.yyyy') + ' 00:00:00',
                                    e_publicDate: $filter('date')(mskDate, 'dd.MM.yyyy') + ' 23:59:59'
                                }
                                setFilterParams(filterModify, filterType);
                            }
                        }, 500);
                    }

                    if('filterEisIntegrationProc' === filterType){
                        setTimeout(function () {
                             const mskDate = getServerTimeMsk(), mskDate2 = angular.copy(mskDate);
                             if (mskDate && !$scope.filter.s_claimBeginsDate && !$scope.filter.e_claimBeginsDate) {
                                 mskDate2.setMonth(mskDate2.getMonth() - 1);
                                 $scope.filter.s_claimBeginsDate = new Date(mskDate2);
                                 $scope.filter.e_claimBeginsDate = mskDate;
                                 
                                 const filterModify = {
                                     s_claimBeginsDate: $filter('date')($scope.filter.s_claimBeginsDate, 'dd.MM.yyyy') + ' 00:00:00',
                                     e_claimBeginsDate: $filter('date')($scope.filter.e_claimBeginsDate, 'dd.MM.yyyy') + ' 23:59:59'
                                 }
                                 setFilterParams(filterModify, filterType);
                             }
                       }, 500);
                    }
                }
                function setMinMaxOpt() {
                    // даты которые должны быть связаны вместе , для выставление minDate maxDate
                    $scope.minMaxOpt = {
                        orderDate: {
                            max: {maxDate: $scope.filter.orderDateEnd},
                            min: {minDate: $scope.filter.orderDateStart}
                        },
                        createDate: {
                            max: {maxDate: $scope.filter.e_createDate},
                            min: {minDate: $scope.filter.s_createDate}
                        },
                        created: {
                            max: {maxDate: $scope.filter.e_created},
                            min: {minDate: $scope.filter.s_created}
                        },
                        claimEndsDate: {
                            max: {maxDate: $scope.filter.e_claimEndsDate},
                            min: {minDate: $scope.filter.s_claimEndsDate}
                        },
                        claimEnds: {
                            max: {maxDate: $scope.filter.e_claimEnds},
                            min: {minDate: $scope.filter.s_claimEnds}
                        },
                        sent: {
                            max: {maxDate: $scope.filter.e_sent},
                            min: {minDate: $scope.filter.s_sent}
                        },
                        auctionDateStart: {
                            max: {maxDate: $scope.filter.e_auctionDateStart},
                            min: {minDate: $scope.filter.s_auctionDateStart}
                        },
                        publicDate: {
                            max: {maxDate: $scope.filter.e_publicDate},
                            min: {minDate: $scope.filter.s_publicDate}
                        },
                        deliveryDate: {
                            max: {maxDate: $scope.filter.e_deliveryDate},
                            min: {minDate: $scope.filter.s_deliveryDate}
                        },
                        endDate: {
                            max: {maxDate: $scope.filter.e_endDate},
                            min: {minDate: $scope.filter.s_endDate}
                        },
                        registrationdate: {
                            max: {maxDate: $scope.filter.e_registrationdate},
                            min: {minDate: $scope.filter.s_registrationdate}
                        },
                        accreditationDate: {
                            max: {maxDate: $scope.filter.e_accreditationDate},
                            min: {minDate: $scope.filter.s_accreditationDate}
                        },
                        paymentOrderDate: {
                            max: {maxDate: $scope.filter.e_paymentOrderDate},
                            min: {minDate: $scope.filter.s_paymentOrderDate}
                        },
                        sellerDocDate: {
                            max: {maxDate: $scope.filter.e_sellerDocDate},
                            min: {minDate: $scope.filter.s_sellerDocDate}
                        },
                        claimBegins: {
                            max: {maxDate: $scope.filter.e_claimBeginsDate},
                            min: {minDate: $scope.filter.s_claimBeginsDate}
                        },
                        publish_date: {
                            max: {maxDate: $scope.filter.e_publish_date},
                            min: {minDate: $scope.filter.s_publish_date}
                        },
                        publish_date_first: {
                            max: {maxDate: $scope.filter.e_publish_date_first},
                            min: {minDate: $scope.filter.s_publish_date_first}
                        },
                        publishDate: {
                            max: {maxDate: $scope.filter.e_publishDate},
                            min: {minDate: $scope.filter.s_publishDate}
                        },
                        signDate: {
                            max: {maxDate: $scope.filter.e_signDate},
                            min: {minDate: $scope.filter.s_signDate}
                        },
                        signDatePriceAnalysis: {
                            max: {maxDate: $scope.filter.e_signDate},
                            min: {minDate: new Date(2018, 0, 1)}
                        },
                        lotsPrice: {
                            max: {maxOpt: $scope.filter.e_lotsPrice},
                            min: {minOpt: $scope.filter.s_lotsPrice}
                        },
                        decisionDate: {
                            max: {maxDate: $scope.filter.e_decisionDate},
                            min: {minDate: $scope.filter.s_decisionDate}
                        }
                    };

                }

                /**
                 * Выставление минмальной или максимальной даты в $scope.minMaxOpt
                 * @param type - передаем название даты
                 * @param subType - название подтипа это какую мы хотип заполнить дату
                 * (пример: Если мы исправили дату "с", то надо для даты "по" выставить минимально допустимое значение, в этом случае ставится subType = 'min'.
                 *  В другом случае наоборот. )
                 * @param value - значение которое надо указать противоположной дате. Если выбрали значение у "c" , то и высталяем дату "с"
                 * example code =>  <input type="text" ng-model="filter.s_publicDate"
                 *                         ng-change="fillMinMaxOpt('publicDate','min',filter.s_publicDate)"
                 datepicker-options="minMaxOpt.publicDate.max"
                 placeholder="с"/>
                 */
                function fillMinMaxOptFn(type, subType, value) {
                    fillBoundMinMaxOptFn(type, subType, value, "Date");
                }

                function fillBoundMinMaxOptFn(type, subType, value, postfix) {
                    $scope.minMaxOpt[type][subType][subType + postfix] = value;
                }

                function refreshDictFn(object, value, refreshScrolling,needToAdd) {
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling,null,needToAdd);
                }
                function resetFilterFieldFn(type, isStartSearch) {
                    if ($scope.filter[type]) {
                        delete $scope.filter[type];
                        if(isStartSearch){
                            $scope.filterAction ? ctrl.filterSearchStart(): ctrl.searchStart();
                        }
                    }
                }
                function getFormFn() {
                    return $scope.form.filterForm;
                }
                function setMaxDateFn(type, value) {
                	$scope.filter[type] = $filter('date')(value, 'dd.MM.yyyy') + ' 23:59:59';
                }
                /**
                 *
                 * @param criteriaArr - массив занчений, которые попадут в фильтр
                 * @param nameField - имя поля по которому будет фильтроваться список
                 * @returns {Function} - вернет отфилтрованный список
                 */
                function arrayFilteredFn(criteriaArr, nameField) {
                    return function (item) {
                        if (!criteriaArr || criteriaArr.length === 0) return true;
                        return criteriaArr.includes(item[nameField])
                    }
                }
                function getProcedureListFn() {
                    return  ['procedures', 'myProcedures', 'jointProcedures'].includes($scope.state.name)  ? 
                    		$scope.procedureListAll : ($scope.state.name === 'commercialProcedures' ? $scope.procedureListCommercial : $scope.procedureList);
                }
                function sectionChangeFn() {
                	if($scope.filter.typeProcedure == 'simplecontest'){
                       delete $scope.filter.customerType;
                	}
                }
                function disabledCustomerTypeFn() {
                   return $scope.filter.typeProcedure == 'simplecontest';
                }
                $scope.excludePurchase = function(el) {
                    return !($scope.state.name === 'commercialProcedures' && el.id==='offerplacement');
                }
          }]
    });
