/**
 * Заявки
 */
angular.module('uetp')
    .component('claimsList', {
        templateUrl: 'app/components/procedures/claims/claims.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'proceduresSrv', 'authSrv', 'alertSrv', '$interval','needConfirmSrv','dialogSrv', 'tablesSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, proceduresSrv, authSrv, alertSrv, $interval, needConfirmSrv, dialogSrv, tablesSrv) {
                var ctrl = this;
                this.$onInit = function () {
                    ctrl.alerts = {};
                    $scope.ctrl = ctrl;
                    $scope.auth = authSrv.getAuth();
                    $scope.user = authSrv.getUser();
                    $scope.getCabType = desktopSrv.getCabType;
                    $scope.paginator = {
                        itemsPerPage: 20,
                        page: 0
                    };
                    $scope.isOrganizer = ["organizer", "organizer_cbz"].includes($scope.getCabType());
                    claimsByProcedureLoadFn();
                };
                $scope.changeSort = changeSortFn;
                $scope.claimsByProcedureLoad = claimsByProcedureLoadFn;
                $scope.downloadClaimDocs = downloadClaimDocsFn;
                $scope.downloadClaimCert = downloadClaimCertFn;

                function claimsByProcedureLoadFn() {
                    proceduresSrv.getClaimsByProcedure($scope.paginator).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.claims = response.data.items;
                            $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                        } else {
                            $scope.claims = [];
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response)
                    });
                }

                function downloadClaimDocsFn(claimId){
                    proceduresSrv.downloadClaimArchive($stateParams, claimId);
                }

                function downloadClaimCertFn(claimId){
                    proceduresSrv.downloadClaimCert($stateParams, claimId);
                }

                function changeSortFn($event) {
                    var th = $event.currentTarget;
                    [].forEach.call(document.querySelectorAll(".sortButton"), function (button) {
                        button.classList.remove("boldSort");
                    });
                    th.classList.add("boldSort");

                    if (tablesSrv.changeSort($scope.paginator, th)) claimsByProcedureLoadFn();
                }
            }]
    });

