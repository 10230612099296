angular.module('uetp').component('analyticReports', {
	templateUrl: 'app/components/reports/analyticReports.html',
	controller: [
		'$scope', '$filter',
		'constants', 'desktopSrv', 'httpSrv','organizatorSrv','dictionarySrv','authSrv','timeout','alertSrv',
		'averageSaving', 'declaredLots', 'postedInfo',
		function (
			$scope, $filter,
			constants, desktopSrv, httpSrv, organizatorSrv, dictionarySrv, authSrv, timeout,alertSrv,
			averageSaving, declaredLots , postedInfo, //Список отчетов
		) {
			var reportTypes = {
				AverageSaving: averageSaving,
				DeclareLots: declaredLots,
				PostInfo : postedInfo,
			};
			$scope.title = "Отчеты";
			$scope.form ={reportForm: {}};
			organizatorSrv.getDataAnalyticReports(authSrv.getAuth().userId).then(function (response) {
				if (response.data.success) {
					var assignedReportsArray=response.data.result.assignedAnalyticReports;
					var assignedReports=[] ;
					assignedReportsArray.forEach(function (el) {
						assignedReports.push(el.id);
					});
					Object.keys(reportTypes).forEach(function(key) {
						if (reportTypes[key].access.indexOf(desktopSrv.getCabType()) < 0||assignedReports.indexOf(key)==-1)
							delete reportTypes[key];
					});
					var DEFAULT_REPORT = Object.keys(reportTypes)[0];
					$scope.reportTypes = reportTypes;


					$scope.filterElement  = {};
					$scope.constants      = constants;

					$scope.initReport     = initReportFn;
					$scope.downloadReport = downloadReportFn;
					$scope.fillMinMaxOpt  = fillMinMaxOptFn;
					$scope.getTitle  = getTitleFn;
					$scope.procTypeAllChange  = procTypeAllChangeFn;
					$scope.getOrgsData=getOrgsDataFn;
					$scope.getDivisionList=getDivisionListFn;
					$scope.isProcTypeSelected=isProcTypeSelectedFn;
					$scope.isAllProcTypeSelected=isAllProcTypeSelectedFn;
					$scope.isOrgsSelected=isOrgsSelectedFn;
					$scope.extraOptionsAllChange = extraOptionsAllChangeFn;
					$scope.isExtraOptionSelected = isExtraOptionSelectedFn;
					initReportFn(DEFAULT_REPORT);
				}
			});


			//Methods
			function initReportFn(DEFAULT_REPORT) {
				var type = $scope.currentReport || DEFAULT_REPORT;
				$scope.currentReport  = type;
				$scope.reportTemplate = reportTypes[type].template;
				$scope.reportSettings = reportTypes[type].settings;
				$scope.report         = angular.copy(reportTypes[type].data);
				if($scope.report.orgs){
					if(!$scope.orgList){
						getOrgsDataFn(function(){
							$scope.report.orgs=angular.copy($scope.orgList);
							initOrgs();
						});
					}
					else {
						$scope.report.orgs=angular.copy($scope.orgList);
						initOrgs();
					}
				}
				if($scope.report.customers){
					if(!$scope.customersList){
						getCustomersFn(function(){
							if($scope.customersList.length==1)
								$scope.report.customers=angular.copy($scope.customersList);
						});
					}
					else if($scope.customersList.length==1) {
						$scope.report.customers=angular.copy($scope.customersList);
					}
				}
				else if($scope.report.divisions){
					if(!$scope.customersList){
						getDivisionListFn(function(){
							$scope.report.divisions=angular.copy($scope.customersList);
						});
					}
					else {
						$scope.report.divisions=angular.copy($scope.customersList);
					}
				}
				if($scope.report.procTypes!=null){
					if($scope.currentReport === 'PostInfo')
						$scope.isAllExtraOptionsSelected = false;
					$scope.isAllProcTypesSelected = $scope.currentReport !== 'PostInfo';
					angular.forEach($scope.procTypes, itm => itm.selected = $scope.currentReport !== 'PostInfo');
					if($scope.report.procTypes.length!=null&&$scope.report.procTypes.length==0){
						if(!$scope.procTypes){
							dictionarySrv.getProcedureList().then(function(result){
								if(result.data.success){
									$scope.procTypes=result.data.result;
									angular.forEach($scope.procTypes, itm => itm.selected = $scope.currentReport !== 'PostInfo');
									$scope.report.procTypes =angular.copy($scope.procTypes);
								}
							});
						}
						else{
							$scope.report.procTypes =angular.copy($scope.procTypes);
						}
					}
				}

				//For period element
				$scope.minMaxOpt = {
					date: {
						max: {maxDate: null},
						min: {minDate: null}
					}
				}
			}
			function initOrgs(){
				$scope.report.orgs.ckz.ckecked=true;
				$scope.report.orgs.rzds.ckecked=true;
				$scope.report.orgsSelected.rkz=angular.copy($scope.report.orgs.rkz);
				$scope.report.orgsSelected.dmto=angular.copy($scope.report.orgs.dmto);
				$scope.report.orgsSelected.others=angular.copy($scope.report.orgs.others);

			}

			function getOrgsDataFn(callback) {
				organizatorSrv.getOrganizationsCodeGroups().then(function (response) {
					if (response.data.success) {
						$scope.orgList=response.data.result;
						if(callback)  callback();
					}
				});
			}

			function getDivisionListFn(callback) {
				organizatorSrv.getDivisionList().then(function (response) {
					if (response.data.success) {
						$scope.report.divisions=angular.copy(response.data.result);
						$scope.customersList=response.data.result;
						if(callback)  callback();
					}
				});
			}

			function getCustomersFn (callback) {
				organizatorSrv.getCustomers().then(function (response) {
					if (response.data.success) {
						$scope.customersList=response.data.result;
						if(callback)  callback();
					}
				});
			}

			function isProcTypeSelectedFn() {
				if($scope.report.procTypes){
					for(var i=0;i<$scope.report.procTypes.length;i++){
						if($scope.report.procTypes[i].selected==true){
							return true;
						}
					}
				}
				return false;
			}

			function isAllProcTypeSelectedFn() {
				if($scope.report.procTypes){
					for(var i=0;i<$scope.report.procTypes.length;i++){
						if($scope.report.procTypes[i].selected==false){
							return false;
						}
					}
				}
				return true;
			}

			function isOrgsSelectedFn() {
				if($scope.report.orgs&&$scope.report.orgs.ckz){
					if($scope.report.orgs.ckz.ckecked||$scope.report.orgs.rzds.ckecked
						||$scope.report.orgsSelected.rkz.length>0||$scope.report.orgsSelected.dmto.length>0||$scope.report.orgsSelected.others.length>0)
						return true;
				}
				return false;
			}

			function getSelectedOrgs() {
				var orgs=[];
				if($scope.report.orgs.ckz.ckecked!==false)
					orgs.push($scope.report.orgs.ckz.id);
				if($scope.report.orgs.rzds.ckecked!==false)
					orgs.push($scope.report.orgs.rzds.id);
				for(var i=0;i<$scope.report.orgsSelected.rkz.length;i++){
					orgs.push($scope.report.orgsSelected.rkz[i].id);
				}
				for(var i=0;i<$scope.report.orgsSelected.dmto.length;i++){
					orgs.push($scope.report.orgsSelected.dmto[i].id);
				}
				for(var i=0;i<$scope.report.orgsSelected.others.length;i++){
					orgs.push($scope.report.orgsSelected.others[i].id);
				}
				return orgs;
			}

			function getSelectedDivisions() {
				var divisions=[];
				for(var i=0;i<$scope.report.divisions.length;i++){
					divisions.push($scope.report.divisions[i].id);
				}
				return divisions;
			}
			
			function getSelectedCustomers() {
				var customers=[];
				for(var i=0;i<$scope.report.customers.length;i++){
					customers.push($scope.report.customers[i].id);
				}
				return customers;
			}

			function makeParams(report) {
				if($scope.report.orgs){
					report.orgs=getSelectedOrgs();
					delete report.orgsSelected;
				}
				else if($scope.currentReport === 'AverageSaving' || $scope.currentReport === 'PostInfo') {
					report.orgs=[authSrv.getAuth().orgId];
				}
				report.user = authSrv.getAuth().userId;
				if($scope.report.customers)
					report.customers=getSelectedCustomers();
				else if($scope.report.divisions)
					report.customers=getSelectedDivisions();
				if(report.procTypes){
					var procTypeArr=[];
					for(var i=0;i<$scope.report.procTypes.length;i++){
						if($scope.report.procTypes[i].selected==true){
							procTypeArr.push($scope.report.procTypes[i].id);
						}
					}
					report.procTypes=procTypeArr;
				}
				if(report.extraOptions) {
					report.extraOptions = $scope.report.extraOptions.filter(option=> option.selected === true).map(option => option.id);
				}
			}

			function toMSK(date) {
				var timeZoneMSK = new Date().getTimezoneOffset()/60+3;
				date.setHours(date.getHours()+timeZoneMSK);
			}

			function downloadReportFn() {
				$scope.submitted = true;
				if ($scope.form && !$scope.form.reportForm.$valid) {
					$scope.alertObjReports = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');
					$scope.submitted = false;
					return;
				}
				var cr = reportTypes[$scope.currentReport];

				var report = angular.copy($scope.report);
				makeParams(report) ;
				var mskData = cr.toMSK;

				if (mskData) mskData.forEach(function (name) {
					if (report[name]) toMSK(report[name]);
				});

				httpSrv.downloadFile(
					cr.url,
					cr.fileName ? cr.fileName($filter, report) : null,
					report
				);
			}

			//For period element
			function fillMinMaxOptFn(type, subType) {
				var dateType = subType==='min'?'startDate':'endDate';
				$scope.minMaxOpt[type][subType][subType + "Date"] = $scope.report[dateType];
			}
			function getTitleFn(compName){
				var settings = $scope.reportSettings;
				if(settings.paramTitles&&settings.paramTitles.hasOwnProperty(compName))
					return settings.paramTitles[compName];
				return null;
			}

			function procTypeAllChangeFn(){
				var cr = $scope.report;
				$scope.isAllProcTypesSelected = !$scope.isAllProcTypesSelected;
				angular.forEach(cr.procTypes, function(itm){ itm.selected = $scope.isAllProcTypesSelected; });
				return true;
			}

			$scope.procTypeOptionToggled = function(){
				$scope.isAllProcTypesSelected = $scope.report.procTypes.every(function(itm){ return itm.selected; })
			}

			function extraOptionsAllChangeFn(){
				$scope.isAllExtraOptionsSelected = !$scope.isAllExtraOptionsSelected;
				angular.forEach($scope.report.extraOptions, itm => itm.selected = $scope.isAllExtraOptionsSelected);
				return true;
			}

			function isExtraOptionSelectedFn() {
				if($scope.report.extraOptions) {
					return $scope.report.extraOptions.forEach(itm => itm.selected);
				}
				return false;
			}

			$scope.extraOptionToggled = function(){
				$scope.isAllExtraOptionsSelected = $scope.report.extraOptions.every(itm => itm.selected);
			}
		}
	]
});