//angular-es.mjs 2.1R
const module = angular.module(__moduleName);

const className = dirname => dirname.split(dirname.includes('\\') ? '\\' : '/').pop();

export const service = list => {
	for (let srv of Object.entries(list)) module.service(...srv);
};

export class Injector {
	constructor (...inject) {
		for (let name of this.constructor.$inject) this[name] = inject.shift();
	}
}

export class Controller extends Injector {
	#initialized = false;

	constructor (...inject) {
		super(...inject);
		this.class =
			this.constructor.selfname &&
			this.constructor.selfname.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
	}

	setDefaults (changes = null) {
		for (let [key, [, value]] of Object.entries(this.constructor.defaults)) {
			if (!changes || changes[key] && changes[key].isFirstChange()) {
				if (this[key] === undefined) this[key] = value;
			}
		}
	}

	$onChanges (changes) {
		if (!this.#initialized) {
			this.#initialized = true;
			this.setDefaults(changes);
		}
	}

	updateScope (obj) {
		for (let [k, v] of Object.entries(obj)) this.$scope[k] = v;
	}
}

export const using = (...dependencies) => (container, key) => {
	if (key) container = container[key];
	if (!container.$inject) container.$inject = dependencies;
	else container.$inject = [...container.$inject, ...dependencies];
};

export const asyncDigest = (target, key) => {
	const originMethod = target[key];
	target[key] = function (...args) {
		return new Promise(async resolve=>resolve(
			await this.$q(async qResolve=>qResolve(
				await originMethod.call(this, ...args),
			)),
		));
	};
	if (!target.constructor.$inject) target.constructor.$inject = ['$q'];
	else if (!target.constructor.$inject.includes('$q')) {
		target.constructor.$inject.push('$q');
	}
	return target;
};

export const component = (dirname, obj, ctrlName = null) => {
	obj.controllerAs = ctrlName || 'it';
	const name = obj.controller.selfname = className(dirname);

	let defaults = {};
	if (obj.bindings) {
		let binds = obj.bindings;
		for (let [key, type] of Object.entries(binds)) {
			if (Array.isArray(type)) [binds[key]] = defaults[key] = type;
		}
	}
	obj.controller.defaults = defaults;

	//Install component to Angular main module
	module.component(name, obj);
};

export const loadTemplates = templates => {
	module.run(['$templateCache', $templateCache=>{
		for (let [path, data] of Object.entries(templates)) $templateCache.put(path, data);
	}]);
};