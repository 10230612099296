/**
 * @author sergeyu
 * Директива по выводу найденных записей. При фильтрации или поиске
 */
angular.module('uetp').controller('scController', ['$scope', 'scService', function ($scope, scService) {
    $scope.model = scService.getCounter();
}]).directive('sc', [function () {
    return {
        restrict: 'E',
        controller: 'scController',
        scope: {
            hasTopPager: "@", // признак наличия верхнего пэйджинэйтора
        },
        template: '<div style="height: 15px" ng-class="{\'top-pgntr-cntr-tmpl\': this.hasTopPager===\'true\'}"><div class="sc-style" ng-if="model.countData !== \'\'"><span>Найдено записей: </span><span >{{model.countData}}</span></div></div>'
    };
}]).service('scService', [function () {

    var model = {
        countData: ''
    };
    /**
     * Установка количества
     * @param count
     */
    this.setCounter = function (count) {
        if (count >= 0) {
            model.countData = count;
        }

    };
    /**
     * Получение количества.
     * Связывать по ссылке на объект.
     * Необходимо для получения связи котнроллер сервис.
     */
    this.getCounter = function () {
        return model
    };
    /**
     * Очистка количества
     */
    this.clearCounter = function () {
        model.countData = '';
    }
}]);