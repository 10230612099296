angular.module('uetp')
    .component('blockedAccounts', {
        templateUrl: 'app/components/widgets/tassBusiness/companyValidate/view/blockedAccounts/blockedAccounts.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'tassBusinessSrv','propertiesService',
            function ($scope, $rootScope, $state, $stateParams, tassBusinessSrv, propertiesService) {
                $scope.loaded = false;
                $scope.modelView = {
                    captchaTitle: "Проверка сведений о наличии решений о приостановлении операций по счетам налогоплательщика не проводилась.<br>Для выполнения поверки введите код"
                };
                $scope.errorCaptcha = false;

                $scope.loadAccountsInfo = loadAccountsInfoFn;
                $scope.refreshCaptcha = refreshCaptchaFn;

                this.$onInit = function () {
                    if(!$scope.loaded) $scope.loadAccountsInfo();
                };
                function loadAccountsInfoFn(captcha, update){
                    $scope.loaded = false;
                    $scope.errorCaptcha = false;
                    if(update){
                        $scope.modelView.captcha = "";
                        $scope.modelView.captchaTitle='Для выполнения повторной проверки введите код:';
                    }
                    propertiesService.getProperty("api.url").then(
                        host => {
                            tassBusinessSrv.getOrganizationVerification($scope, $stateParams.idCompany, host, captcha, update).then(
                                response => {
                                    $scope.errorInfo = null;
                                    if(!response.data.success) {
                                        $scope.errorInfo = "Информация о заблокированных счетах отсутствует.";
                                        return;
                                    }
                                    const resultData = response.data.result.tassData;
                                    if(resultData.status === 'error') {
                                        $scope.errorCaptcha = true;
                                        $scope.errorMsg = resultData.message;
                                        delete $scope.modelView.data;
                                        delete $scope.modelView.queryDate;
                                    }
                                    if(resultData.captcha){
                                        $scope.captchaSrc = resultData.captcha.startsWith('http') ?
                                            resultData.captcha : `https://${resultData.captcha}`;
                                        delete $scope.modelView.data;
                                        delete $scope.modelView.queryDate;
                                    } else if(resultData.data){
                                        delete $scope.captchaSrc;
                                        $scope.modelView.data = resultData.data;
                                        $scope.modelView.queryDate = response.data.result.lastUpdated;
                                    }

                                    $scope.loaded = true;
                                },
                                error => {
                                    console.log(error);
                                    $scope.errorInfo = "Информация о заблокированных счетах отсутствует.";
                                    $scope.loaded = true;
                                }
                            );
                        }
                    );
                }
                function refreshCaptchaFn() {
                    $scope.loaded = false;
                    $scope.errorCaptcha = false;
                    propertiesService.getProperty("api.url").then(
                        host => {
                            tassBusinessSrv.getOrganizationVerification($scope, $stateParams.idCompany, host, null, true).then(
                                response => {
                                    $scope.errorInfo = null;
                                    if(!response.data.success) {
                                        $scope.errorInfo = "Информация о заблокированных счетах отсутствует.";
                                        return;
                                    }
                                    const resultData = response.data.result.tassData;
                                    if(resultData.status === 'error') {
                                        $scope.errorCaptcha = true;
                                        $scope.errorMsg = resultData.message;
                                    }
                                    if(resultData.captcha) {
                                        $scope.captchaSrc = resultData.captcha.startsWith('http') ?
                                            resultData.captcha : `https://${resultData.captcha}`;
                                    }

                                    $scope.loaded = true;
                                },
                                error => {
                                    console.log(error);
                                    $scope.errorInfo = "Информация о заблокированных счетах отсутствует.";
                                    $scope.loaded = true;
                                }
                            );
                        }
                    );
                }
            }]
    });