/**
 * Форма "Заполнить номер ЕИС"
 */
angular.module('uetp')
    .component('eisIntegrationProcEdit', {
        templateUrl: 'app/components/proceduresEIS/eisIntegrationProcEdit/eisIntegrationProcEdit.html',
        bindings: {
            procedureData: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'proceduresSrv','httpSrv', 'alertSrv', 'dialogSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, proceduresSrv, httpSrv, alertSrv, dialogSrv) {
                var ctrl = this;
                $scope.ctrl = ctrl;
                $scope.findInEis = findInEisFn;
                $scope.fillEisNumber = fillEisNumberFn;
                $scope.eisNumberChanged = eisNumberChangedFn;
                $scope.cancel = cancelFn;
                this.$onInit = function () {
                    $scope.procedure = $scope.ctrl.procedureData;
                    $scope.eisNumber = '';
                    $scope.eisProcedure = null;
                };

                function findInEisFn(){
                    var param = {
                        searchByField: {
                            purchase_number: $scope.eisNumber,
                            indexes: 'purchase_notice_223'
                        }
                    };
                    proceduresSrv.searchPurchases($scope, param)
                        .then(function (response) {
                            if(response.data.success && response.data.items && response.data.items.length > 0){
                                $scope.eisProcedure = response.data.items[0];
                            }
                            else{
                                dialogSrv.showDialogMsg($scope, "Закупка с номером ЕИС " + $scope.eisNumber + " не найдена.");
                            }
                        })
                }

                function fillEisNumberFn(){
                    var data={
                        purchaseNumber: $scope.eisProcedure.purchase_number,
                        urlEis: $scope.eisProcedure.url_eis,
                        guidNumber: $scope.eisProcedure.guidNumber,
                        lotsIdEis: {}
                    };
                    angular.forEach($scope.eisProcedure.lots, function(lot){
                        data.lotsIdEis[lot.lot_number] = lot.id_eis;
                    });
                    updateProcEisFields($scope.procedure.id, data);
                }
                
                function eisNumberChangedFn(){
                    $scope.eisProcedure = null;
                }

                function cancelFn(){
                    goBackFn();
                }

                function goBackFn() {
                    history.back();
                }

                function updateProcEisFields(id, data) {
                    return httpSrv.http({
                        method: 'POST',
                        url: '/admin/eis/updateInfo/' + id,
                        data: data
                    }).then(function (response) {
                        if (response.data.success) {
                            goBackFn();
                        } else {
                            $scope.alertObjEisEdit = alertSrv.getAlertObj(response);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }
            }]
    });

