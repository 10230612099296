/**
 * Директива для поля дата. Если в поле не задано время, устанавливать его в 23:59
 */
angular.module('uetp').directive('maxTime', [function () {
    return {
        require: 'ngModel',
        priority: 1,
        link: function (scope, element, attrs, ctrl) {
            if(attrs['maxTime'])
                ctrl.$parsers.push(function (value) {
                    const inputVal = element.val();
                    if(value && inputVal &&  inputVal.trim().length == 10) {
                        return new Date(value.setHours(23, 59));
                    }
                    return value;
                });
        }
    };
}]);