/**
 * @author: dmitrys
 *
 * Реестр платежей
 * В ЛК Бухгалтера выводим как таблицу (с фильтрами и поиском) - isTable = true
 * В Лк Участника как вкладку - isTable = false
 */
angular.module('uetp')
    .component('paymentOrdersTable', {
        templateUrl: 'app/components/paymentOrder/table/paymentOrdersTable.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'authSrv', 'httpSrv', 'desktopSrv','paginationSrv','filterService', 'dialogSrv','paymentOrderSrv','participantSrv', 'timeout',
            function ($scope, $rootScope, $state, $stateParams, authSrv, httpSrv, desktopSrv,  paginationSrv, filterService, dialogSrv,paymentOrderSrv,participantSrv, timeout) {
                var state = $state.current,
                    ctrl = this;
                /**
                 * В ЛК Бухгалтера выводим как таблицу (с фильтрами и поиском)
                 * В Лк Участника как вкладку
                 */
                $scope.isTable = $stateParams.isTable;
                $scope.ctrl = ctrl;

                $scope.$stateParams = $stateParams;

                $scope.downloadFile = httpSrv.downloadFile;

                $scope.paymentService = paymentOrderSrv;
                $scope.bulkCheckbox = bulkCheckboxFn;
                $scope.toggleCheckedItem = toggleCheckedItemFn;

                $scope.paginator = {
                    itemsPerPage: 20,
                    page: 0,
                    search: ""
                };

                $scope.paymentOrdersLoad = paymentOrdersLoadFn;

                $scope.clickRow = clickRowFn;
                $scope.changeSort = changeSortFn;
                $scope.goBack = goBackFn;

                function goBackFn(){
                    history.back();
                }


                this.$onInit = function () {
                    $scope.auth = authSrv.getAuth();
                    $scope.cabType = desktopSrv.getCabType;
                    if($stateParams.isInvoiceParticipantView){
                        var orgInfo = participantSrv.getOrgInfo();
                        $scope.user = orgInfo.data ? orgInfo.data.result : null;
                        if(!$scope.user) {
                            dialogSrv.openModal('_dialogMessage.html',
                                {message: "Не удалось получить информацию об участнике."}).then(function () {
                                    $state.go('home');
                            });
                        }
                    }
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.paymentOrdersLoad();
                    var config = paginationSrv.getPaginationStateConfig('pagination', $scope.$stateParams.type); // память пагинации
                    if (config) {
                        $scope.paginator.page = config.page;
                        $scope.paginator.itemsPerPage = config.itemsPerPage;
                    }
                    if($scope.$stateParams.resetPaginationPage){
                        $scope.paginator.page = 0;
                        $scope.paginator.itemsPerPage = 20;
                        paginationSrv.clearPaginationStateConfig('pagination', $scope.$stateParams.type)
                    }
                    $scope.isInvoiceParticipantView = $stateParams.isInvoiceParticipantView;

                };

                /**
                 * Загрузка реестра платежей
                 */
                function paymentOrdersLoadFn() {
                    filterService.initModelView('Поиск по плательщику, получателю, номеру', 'Организаторы');
                    prepareTableParams('filterPaymentOrder');
                    if (!$scope.paginator.searchByField) {
                       $scope.paginator.searchByField = {};
                    }
                    var url;
                    if($scope.cabType() === 'participant') {   //Реестр платежей в ЛК участника, раздел "Тарифы и счета"
                        url ='/accounts/coveringLetters/paymentOrdersList';
                    }
                    else if($stateParams.isInvoiceParticipantView){ //Реестр платежей в карточке участника
                        if(!$scope.user.account)
                            return;
                        $scope.paginator.searchByField = {participantId: $scope.user.id};
                        url ='/accounts/coveringLetters/paymentOrdersList/' + $scope.user.account.id;
                    }
                    else{ //Реестр платежей - раздел в ЛК бухгалтера
                        url ='/accounts/paymentOrder/paymentOrdersList/' + $stateParams.type;
                    }

                    httpSrv.http({
                        url: url,
                        data: $scope.paginator,
                        showCounter: true
                    }).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.checkedItems = [];
                            $scope.dataTable = response.data.items;
                            $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                            $scope.poIsErrorDisplay = response.data.errors;
                            $scope.poNotProcessedDisplay = response.data.notProcessed;
                            $scope.poTotalDisplay = response.data.all;
                            if($scope.isTable)
                                _initAccreditionDelayed();
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }
                function clickRowFn(row,stateName) {
                    if (stateName) {
                        $state.go(stateName, {id: row.id, params: prepareFormParams()});
                    } else {
                        $state.go(state.name.replace("My", "") + ".view", {id: row.id, params: prepareFormParams()})
                    }

                }
                /**
                 * Сортировка
                 */
                function changeSortFn($event) {
                    var th = $event.currentTarget;

                    [].forEach.call(document.querySelectorAll(".sortButton"), function (button) {
                        button.classList.remove("boldSort");
                    });
                    th.classList.add("boldSort");

                    if (changeSortTh(th)) {
                        $scope.paymentOrdersLoad();
                    }
                }
                function changeSortTh(target) {
                    var th = target,
                        orderBy = th.getAttribute('st-sort'),
                        orderAsc = th.classList.contains("sortAsc");

                    [].forEach.call(document.querySelectorAll(".sortColumn"), function (column) {
                        column.classList.remove("sortDesc");
                        column.classList.remove("sortAsc");
                    });
                    orderAsc = !orderAsc;
                    th.classList.add(orderAsc ? "sortAsc" : "sortDesc");

                    if (orderBy) {
                        //$scope.paginator.page = 0;   // если надо, то вернуть
                        $scope.paginator.orderBy = orderBy;
                        $scope.paginator.orderAsc = orderAsc;
                        // tablesSrv.setTableSort(state.name, {orderBy: orderBy, orderAsc: orderAsc});
                        paginationSrv.setPaginationStateConfig('sortTh', $state.current.name, {
                            targetObject: {
                                name: orderBy,
                                orderAsc: orderAsc
                            }
                        });
                        return true
                    }
                    return false
                }
                function prepareFormParams() {
                    var dataObj = {clearConfig: true};
                    dataObj.participantId = $stateParams.id;
                    return dataObj;
                }
                function prepareTableParams(filterType) {
                    var filterParams = angular.copy(filterService.getFilterParams(filterType)),
                        searchParam = angular.copy(filterService.getSearchParam(filterType));
                    if (filterParams && Object.keys(filterParams).length > 0) {
                        $scope.paginator.searchByField = filterParams;
                    } else {
                        delete $scope.paginator.searchByField
                    }
                    $scope.paginator.search = searchParam;
                }
                function bulkCheckboxFn($event) {
                    var checked = $event.target.checked;
                    [].forEach.call(document.querySelectorAll(".itemCheck"), function (el) {
                        el.checked = checked;
                        checkItems(checked, el.id);
                    })

                }
                function toggleCheckedItemFn($event, item, items) {
                    checkItems($event.target.checked, item.id);
                    $scope.bulkChBox = items.length == $scope.checkedItems.length;
                    timeout($scope);
                }
                function checkItems(checked, id) {
                    if (!Array.isArray($scope.checkedItems)) $scope.checkedItems = [];
                    var pos = $scope.checkedItems.indexOf(id);
                    if (pos === -1 && checked) {
                        $scope.checkedItems.push(id);
                    }
                    if (pos !== -1 && !checked) {
                        $scope.checkedItems.splice(pos, 1);
                    }
                }
                function _initAccreditionDelayed() {
                    if($scope.cabType()==='accountant') {
                        var param = {
                            itemsPerPage: 1,
                            page: 0,
                            searchByField: {
                                isAccreditationDelayed: true
                            }
                        };
                        httpSrv.http({
                            url: '/admin/obtainOrgList/participant',
                            data: param
                        }).then(function successCallback(response) {
                            if (response.data.success) {
                                $scope.AccreditionDelayed = response.data.items;
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        });
                    }
                }
            }
        ]
    });

