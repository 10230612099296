//компонент страницы Реклама
angular.module('uetp')
  .component('rekl', {
    templateUrl: 'app/components/rekl/rekl.html',
    transclude: true,
    controller: ['$scope', 'constants', function ($scope, constants) {
        $scope.changeAdProgram = adProgramFn;
        $scope.program = "none";
        $scope.constants = constants;

        //начальная инициализация
        init();

        function adProgramFn(program) {
            $scope.program = $scope.program == program ? "" : program;
        }

      function init() {
        window.scrollTo(0, 0);
      }

    }]
  });