/*global Ya*/

((d, w, c) => {
	(w[c] = w[c] || []).push(() => {
		try {
			w[`yaCounter${__yandexMetrika}`] = new Ya.Metrika({
				id: Number(__yandexMetrika),
				clickmap: true,
				trackLinks: true,
				accurateTrackBounce: true,
				webvisor: true,
			});
		} catch {
			console.warn('Ya.Metrika is not initialized');
		}
	});

	let n = d.getElementsByTagName('script')[0],
		s = d.createElement('script'),
		f = () => n.parentNode.insertBefore(s, n);

	s.type = 'text/javascript';
	s.async = true;
	s.src = 'https://mc.yandex.ru/metrika/watch.js';
	s.onerror = () => console.warn('Ошибка подключения метрики');

	if (w.opera === '[object Opera]') d.addEventListener('DOMContentLoaded', f, false);
	else f();
})(document, globalThis, 'yandex_metrika_callbacks');