/**
 * @author: sergeyu
 * [-]
 */
angular.module('uetp')
    .component('pwdChange', {
        templateUrl: 'app/components/profile/pwdChange/pwdChange.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'httpSrv','alertSrv',
            function ($scope, $rootScope, $state, $stateParams, httpSrv,alertSrv) {

                this.$onInit = function () {
                    $scope.form = {};
                };
                $scope.changePass = changePassFn;
                $scope.resetPwdState=resetPwdStateFn;

                function resetPwdStateFn(){
                    if ($scope.form.pwdForm && $scope.form.pwdForm.oldPwd)$scope.form.pwdForm.oldPwd.$setValidity("required", true);
                    document.getElementById("password").title = '';

                }
                function changePassFn(userPassword) {
                    if (!userPassword.password) {
                        $scope.alertObjPwdChange = alertSrv.getErrorMsg("Введенный старый пароль не совпадает с текущим паролем пользователя");
                        if ($scope.form.pwdForm && $scope.form.pwdForm.oldPwd)$scope.form.pwdForm.oldPwd.$setValidity("required", false);
                        document.getElementById("password").title = 'Введенный старый пароль не совпадает с текущим паролем пользователя';
                        return;
                    }

                    httpSrv.http({
                            url: '/auth/user/userChangePassword',
                            data: userPassword
                        }
                    ).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.alertObjPwdChange = alertSrv.getAlertObj(response,'Ваш пароль изменен!');
                            $scope.blockChangePwd = true;
                            $scope.form.pwdForm.$setPristine();
                        } else {
                            if (response.data.errorCode=="002"){
                                if ($scope.form.pwdForm && $scope.form.pwdForm.oldPwd)$scope.form.pwdForm.oldPwd.$setValidity("required", false);
                                document.getElementById("password").title = 'Введенный старый пароль не совпадает с текущим паролем пользователя';
                            }
                            $scope.alertObjPwdChange = alertSrv.getAlertObj(response);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }

            }]
    });

