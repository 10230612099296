/**
 * @author sergeyu
 */
/**
 * example alertpanel
 * Варианты использования:

 *****с директивой <inputfile>:
    Указать в inputfiles и alertpanel одинаковый alert-obj
     <inputfiles url = "" alert-obj="alertObjTest" callback="" succses-msg="Успешное сообщение">
     ...
     </inputfiles>
     <alertpanel alert-obj="alertObjTest" dissmis-time="7000"></alertpanel>
    В случаи успешной загрузки файла по умолчанию отображается "Файл импортирован"
    для вывода другого успешного сообщения в inputfiles можно указать succses-msg
 *****С кнопками и тп
     <button ng-click="test()"> Button </button>
     <alertpanel alert-obj="alertObjTest"></alertpanel>

     в контроллере - функция test():

     если надо вернуть именно ошибку и успех (пример: валидация или оповещение), то:
         $scope.alertObjTest = alertSrv.getErrorMsg('Сообщение с ошибкой');
         $scope.alertObjTest = alertSrv.getSuccessMsg('Сообщение с успехом (зеленое)');

     если надо обработать ответ с сервера:
         для success = true
            $scope.alertObjTest = alertSrv.getAlertObj(response,'Успешное сообщение');

         для success = false:
             $scope.alertObjTest = alertSrv.getAlertObj(response);
             или
             $scope.alertObjTest = alertSrv.getAlertObj(response,msg);

             Сообщение будет определенно автоматически, если с сервера не пришло никаких сообщений, будет выведено
             сообщение msg или 'Ошибка!', если msg пустой

    *****В виде статического блока
     <alertpanel type="warning" show-icon="false" >
        Текст блока
     </alertpanel>
        type - тип блока: info, warning или danger
        show-icon - показывать иконку, необязательный параметр, по умолчанию 'true'
        no-wrap - убрать margin

 */
import './alertPanel.scss';
import template from './alertPanel.html';

angular.module('uetp').directive('alertpanel', function () {
    return {
        restrict: 'E',
        template,
        scope: {
            alertObj    : '=',
            dissmisTime : '@',
            type        : '@',
            showIcon    : '<',
            noWrap      : '<',
        },
        transclude: true,
        compile: function () {
            return {
                pre: function (scope) {
                    if (scope.initModel) scope.initModel();
                }
            }
        },
        controller: ['$scope',
            function ($scope) {
                if ($scope.type) {
                    if(!angular.isDefined($scope.showIcon)) $scope.showIcon = true;
                    return;
                }

                $scope.closeAlert = closeAlertFn;
                $scope.click = clickFn;
                $scope.canClose = true;
                $scope.initModel = function () {
                    if(!angular.isDefined($scope.dissmisTime)) $scope.dissmisTime = 5000;
                    if($scope.dissmisTime === 'none') $scope.canClose = false;
                };
                /**
                 * Скрыть сообщение
                 */
                function closeAlertFn() {
                    $scope.alertObj.dismiss = true;
                }
                /**
                 * вызвать callback по клику
                 */
                function clickFn(alertObj) {
                    if(angular.isDefined(alertObj.params) && alertObj.params != null) {
                        alertObj.callback(alertObj.params);
                    }else{
                        alertObj.callback();
                    }
                }
            }
        ]
    }

}).service('alertSrv', ['$sce', function ($sce) {
    const service = this;

    /**
     * Получить объект при зарузке файла
     * Анализируем ответ с сервера:
     * если все ок - выводим наше сообщение (msg)
     * если нет - анализируем ошибку и выводим сообщение,
     * если message и errorMessage пустые(ошибка неизветсная) - используем пользовательский msg или Ошибка!
     * @param response - ответ сервера
     * @param msg - переопределить сообщение
     * @returns объект с сообщением по умолчанию или msg
     */
    service.getAlertObj = function (response, msg) {
        var mess = null,
        success = false;
        //проверка ошибок со статусом 200
        if(response.status == 200 && response.data.success!=false){
            //успешный запрос
            success = true;
            mess = msg;
        }else if(response.status == 200 && response.data.success==false){
            //неуспешный запрос
            success = false;
            //если message и errorMessage пустые - используем пользовательский msg или Ошибка!
            if(response.data.errorMessage){
                mess = response.data.errorMessage;
                if (mess.indexOf('javax.ws.rs.BadRequestException:') >=0) {
                    mess = mess.replace('javax.ws.rs.BadRequestException:','')
                }
            } else{
                if(response.data.message){
                    mess =  response.data.message;
                }else{
                    mess = msg||'Ошибка!';
                }
            }
        }
        if(response.status == 400){
            success = false;
            if (response.data.parameterViolations && response.data.parameterViolations.length > 0) {
                mess = response.data.parameterViolations[0].message;
            } else if (response.data.errors && response.data.errors.length > 0) {
                mess = '';
                angular.forEach(response.data.errors, function (error) {
                    mess+=error.text+'<br>';
                })
            } else {
                mess = msg || 'Ошибка!';
            }
        }
        if(response.status == 401){
            success = false;
            mess = 'Ошибка прав доступа!';
        }
        if(response.status == 405){
            success = false;
            mess = msg || 'Ошибка!';
        }
        if (response.status == 500) {
            success = false;
            if(response.data) {
                mess = msg || response.data;
            }
            if (response.data.includes('UT000020')) {
                mess = 'Файл не добавлен. Превышен максимально разрешенный размер файла!';
            }
        }
        if (response.status === -1) {
            mess = msg||'Проверьте подключение Вашего рабочего места к сети Интернет. Информация на странице не была обновлена.';
            success = false;
        }
        if(response.status == 504){
            success = false;
            mess = msg||'Внимание! Нет ответа от сервера!';
        }
        if(response.status === 802 || response.status === 800) {
            success = false;
            mess = response.data.message;
        }
        //todo Проверки response - другие статусы
        if(!success){
            if(mess==null) mess = msg;
        }
        return {
            success:success,
            mess:$sce.trustAsHtml(mess),
            dismiss:false,
            btn:false
        }
    };
    /**
     * Получить ошибочное сообщение
     * @param msg - сообщение
     */
    service.getErrorMsg = function (msg) {
        return {
            success:false,
            mess:$sce.trustAsHtml(msg),
            dismiss:false,
            btn:false
        }
    };
    /**
     * Получить успешное сообщение
     * @param msg - сообщение
     */
    service.getSuccessMsg = function (msg) {
        return {
            success:true,
            mess:$sce.trustAsHtml(msg),
            dismiss:false,
            btn:false
        }
    };
    /**
     * Получить ошибочное сообщение c кнопкой
     * @param msg - сообщение
     * @param btn - текст кнопки
     * @param callback - функция
     * @param params - параметры
     */
    service.getErrorMsgWithBtn = function (msg,btn,callback,params) {
        return {
            success:false,
            mess:$sce.trustAsHtml(msg),
            btn:btn,
            callback:callback,
            params:params,
            dismiss:false
        }
    };
    /**
     * Получить успешное сообщение c кнопкой
     * @param msg - сообщение
     * @param btn - текст кнопки
     * @param callback - функция
     * @param params - параметры
     */
    service.getSuccessMsgWithBtn = function (msg,btn,callback,params) {
        return {
            success:true,
            mess:$sce.trustAsHtml(msg),
            btn:btn,
            callback:callback,
            params:params,
            dismiss:false
        }
    };
}]);