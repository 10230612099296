/**
 * Компонент для форм создания различных объектов Анкеты банковской гарантии
 *
 * @author dmitrys
 */
angular.module('uetp')
    .component('bankGuaranteeFormCreator', {
        templateUrl: 'app/components/bankGuaranteeApp/formCreator/bankGuaranteeFormCreator.html',
        controller: ['$scope', 'constants', 'bankGuaranteeSrv', 'needConfirmSrv', 'alertSrv', 'desktopSrv', 'onlyRequiredSrv', '$stateParams', 'bankGuaranteeVTBSrv',
            function ($scope, constants, bankGuaranteeSrv, needConfirmSrv, alertSrv, desktopSrv, onlyRequiredSrv, $stateParams, bankGuaranteeVTBSrv) {
                $scope.closeForm = closeFormFn;
                $scope.selectType = selectTypeFn;
                $scope.selectRegion = selectRegionFn;
                $scope.save = saveFn;
                $scope.countryChange = countryChangeFn;
                $scope.modelView = {
                    isReadOnly: false//форма редактирования
                };
                $scope.constants = constants;
                $scope.setPDL = bankGuaranteeSrv.setPDL;
                $scope.getDict = bankGuaranteeSrv.getDict;
                $scope.clearDopInfoByProxy = bankGuaranteeSrv.clearDopInfoByProxy;
                $scope.getPassportDocName = bankGuaranteeVTBSrv.getPassportDocName;
                $scope.addPassportDoc = bankGuaranteeVTBSrv.addPassportDoc;
                $scope.delPassportDoc = delPassportDocFn;
                $scope.downloadFile = bankGuaranteeVTBSrv.downloadFile;
                $scope.calculationBuhPeriods = calculationBuhPeriodsFn;
                $scope.dateOptions = {};
                $scope.submitted = false;
                $scope.bankGuarantee = {};
                $scope.founderList = [
                    {
                        url: 'app/components/bankGuaranteeApp/formCreator/foundersCompanies.html',
                        name: 'Юридическое лицо',
                        type: 'foundersCompanies'
                    },
                    {
                        url: 'app/components/bankGuaranteeApp/formCreator/foundersPersons.html',
                        name: 'Физическое лицо',
                        type: 'foundersPersons'
                    }
                ];
                $scope.founderLokoList = [
                    {
                        url: 'app/components/bankGuaranteeApp/formCreator/foundersCompaniesLoko.html',
                        name: 'Юридическое лицо',
                        type: 'foundersCompanies'
                    },
                    {
                        url: 'app/components/bankGuaranteeApp/formCreator/foundersPersonsLoko.html',
                        name: 'Физическое лицо',
                        type: 'foundersPersons'
                    }
                ];
                $scope.founderVTBList = [
                    {
                        url: 'app/components/bankGuaranteeApp/formCreator/foundersCompaniesVTB.html',
                        name: 'Юридическое лицо',
                        type: 'foundersCompanies'
                    },
                    {
                        url: 'app/components/bankGuaranteeApp/formCreator/foundersPersonsVTB.html',
                        name: 'Физическое лицо',
                        type: 'foundersPersons'
                    }
                ];
                $scope.bailsList = [
                    {
                        url: 'app/components/bankGuaranteeApp/formCreator/bailsCompany.html',
                        name: 'Юридическое лицо',
                        type: 'company'
                    },
                    {
                        url: 'app/components/bankGuaranteeApp/formCreator/bailsPerson.html',
                        name: 'Физическое лицо',
                        type: 'person'
                    }
                ];
                $scope.tooltipInfoPhone = "Телефон необходимо указывать вместе с кодом города. Пример формата ввода номера: +7 (123) 456-78-90";
                $scope.tooltipInfoAddress = "Формат указания адреса: 195112, г. Санкт-Петербург, Малоохтинский пр., д. 68";

                $scope.feedbackFormVisibility = function () {
                    $scope.feedbackForm = $scope.feedbackForm ? false : true;
                };

                this.$onInit = function () {
                    $scope.dictionary = bankGuaranteeSrv.getDictRemote();
                    $scope.modelView.tableInfo = bankGuaranteeSrv.getTableForm();
                    $scope.modelView.isReadOnly = $scope.modelView.tableInfo.isReadOnly;
                    $scope.model = angular.copy($scope.modelView.tableInfo.data);
                    //если новая запись создаем пустую модель
                    if ($scope.modelView.tableInfo.isNew) $scope.model = {};
                    /*
                    * Модель анкеты
                    * */
                    $scope.modelApp = bankGuaranteeSrv.getData();
                    if ($scope.modelView.tableInfo.type) {
                        $scope.templates = 'app/components/bankGuaranteeApp/formCreator/' + $scope.modelView.tableInfo.type + '.html'
                        if ($scope.modelView.tableInfo.type === 'founders') {
                            if ($scope.model.foundersTemp && $scope.model.foundersTemp.type) {
                                angular.forEach($scope.founderList, function (item) {
                                    if ($scope.model.foundersTemp.type === item.type) {
                                        $scope.modelView.foundersTemp = item;
                                    }
                                })
                            }
                        } else if ($scope.modelView.tableInfo.type === 'foundersLoko') {
                            if ($scope.model.foundersTemp && $scope.model.foundersTemp.type) {
                                angular.forEach($scope.founderLokoList, function (item) {
                                    if ($scope.model.foundersTemp.type === item.type) {
                                        $scope.modelView.foundersTemp = item;
                                    }
                                })
                            }
                        }else if ($scope.modelView.tableInfo.type === 'foundersVTB') {
                            if ($scope.model.foundersTemp && $scope.model.foundersTemp.type) {
                                angular.forEach($scope.founderVTBList, function (item) {
                                    if ($scope.model.foundersTemp.type === item.type) {
                                        $scope.modelView.foundersTemp = item;
                                    }
                                })
                            }
                        } else if ($scope.modelView.tableInfo.type === 'bails') {
                            if ($scope.model.bailType) {
                                angular.forEach($scope.bailsList, function (item) {
                                    if (item.type === $scope.model.bailType) {
                                        $scope.modelView.bailsTemp = item;
                                    }
                                })
                            }
                        } else if ($scope.modelView.tableInfo.type === 'principalBuhPeriods') {
                            $scope.modelView.autoCalculation = true;
                            if (!$scope.model.list) $scope.model.list = {};
                            /**
                             * Считаем все поля
                             */
                            $scope.calculationBuhPeriods(null);
                        }
                    } else {
                        goBackFn();
                    }
                    //если новая строка - проставляем значения по умолчанию
                    if ($scope.modelView.tableInfo.isNew) {
                        setDefaultValues($scope.model, $scope.modelView.tableInfo.type)
                    }
                };
                /*
                * Скрываем необязательные поля при открытии формы
                * необходима задержка для отработки ng-include
                * */
                $scope.$on("$includeContentLoaded", function () {
                    if (!['beneficiariesLoko', 'foundersLoko'].includes($scope.modelView.tableInfo.type)) {
                        setTimeout(function () {
                            onlyRequiredSrv.showHide($scope.bankGuarantee.form.$$element[0], true);
                        }, 100);
                    }
                });

                /*
                * Установить модель по умолчанию
                * model - модель
                * type - тип формы
                * */
                function setDefaultValues(model, type) {
                    if (['collegialExecutiveBodyMember', 'companyBeneficiaries',
                        'supervisoryBoardMember', 'companyBeneficiariesVTB'].includes(type)) {
                        $scope.model.personAttributes = {
                            citizenship: 'RUS',
                            countryOfResidence: 'RUS'
                        };
                        $scope.model.identityDocument = {
                            identityDocumentType: 'passportRf'
                        }
                    }else if (type === 'companyBeneficiariesLoko'){
                        $scope.model.personAttributes = {
                            citizenship: 'RUS',
                            citizenshipName: 'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
                            countryOfResidence: 'RUS',
                            countryOfResidenceName: 'РОССИЙСКАЯ ФЕДЕРАЦИЯ'
                        };
                        $scope.model.identityDocument = {
                            identityDocumentType: 'passportRf'
                        }
                    }
                }

                /**
                 * Установть переменную наименования
                 * @param row - выбраная запись
                 * @param model - модель
                 * @param key - ключ для установки
                 */
                function countryChangeFn(row, model, key) {
                    var selected = row.$select.selected;
                    model[key+"Name"] = selected? selected.ncm: null;
                }
                /**
                 * Сохранить данные
                 * @param buhPeriodsCheckSkip - true если сохранить без проверки бух отчетности
                 */
                function saveFn(buhPeriodsCheckSkip) {
                    if (['founders', 'foundersLoko', 'foundersVTB'].includes($scope.modelView.tableInfo.type) && !$scope.modelView.foundersTemp) {
                        $scope.alertObjBankGuaranteeCreator = alertSrv.getErrorMsg('Необходимо выбрать тип учредителя');
                        return;
                    }
                    if ($scope.modelView.tableInfo.type === 'bails' && !$scope.modelView.bailsTemp) {
                        $scope.alertObjBankGuaranteeCreator = alertSrv.getErrorMsg('Необходимо выбрать тип поручительства');
                        return;
                    }
                    if ($scope.bankGuarantee.form.$invalid) {
                        $scope.alertObjBankGuaranteeCreator = alertSrv.getErrorMsgWithBtn('Необходимо заполнить обязательные поля на форме. ', 'Перейти', $scope.focusFirstInvalid, $scope.bankGuarantee.form);
                        return;
                    }
                    // Проверка разделов с копиями паспортов
                    if('companyBeneficiariesVTB'===$scope.modelView.tableInfo.type || ('foundersVTB'===$scope.modelView.tableInfo.type && 'foundersPersons'===$scope.modelView.foundersTemp.type) ){
                        // Проверка наличия файла копии паспорта
                        if(!$scope.model.id){
                            $scope.alertObjBankGuaranteeCreator = alertSrv.getErrorMsg('Необходимо добавить копию паспорта');
                            return;
                        }
                    }
                    if ($scope.modelView.tableInfo.type === 'principalBuhPeriods') {
                        /**
                         * Если галка не стоит и не нужно сбрасывать проверку
                         * проверяем все введенные значения с рассчитаными и выводим ошибку в случае расхождения
                         */
                        if (!$scope.modelView.autoCalculation && !buhPeriodsCheckSkip) {
                            var errorMessage = '';
                            angular.forEach($scope.modelView.calculationList, function (value, key) {
                                if (Number($scope.model.list[key]) !== Number(value)) {
                                    errorMessage += getErrorMsgBuhPeriod(key, $scope.model.list[key], value) + '<br>';
                                }
                            });
                            if (errorMessage.length > 0) {
                                /**
                                 * Сообщение с возможностью сохранения без проверки
                                 */
                                $scope.alertObjBankGuaranteeBuhPeriods = alertSrv.getErrorMsgWithBtn(errorMessage + '<br>', 'Данные корректны - сохранить', saveFn, true);
                                return;
                            }
                        }
                    }
                    if('foundersVTB' === $scope.modelView.tableInfo.type && $scope.model.personAttributes){
                        $scope.model.personAttributes.isIpdl = $scope.model.personAttributes.isIpdl || false;
                    }
                    
                    if ($scope.modelView.tableInfo.isNew) {
                        if (!$scope.modelApp.principal[$scope.modelView.tableInfo.type]) $scope.modelApp.principal[$scope.modelView.tableInfo.type] = [];
                        $scope.modelApp.principal[$scope.modelView.tableInfo.type].push($scope.model);
                    } else {
                        //обновление данных по индексу в массиве
                        $scope.modelApp.principal[$scope.modelView.tableInfo.type][$scope.modelView.tableInfo.index] = $scope.model;
                    }
                    bankGuaranteeSrv.setActiveLevel('app');

                    /**
                     * Получить сообщение об ошибки для полей не совподающих с авторасчетом
                     * @param typeB - наименование поля
                     * @param valueB - значение поля
                     * @param valueBCalc - автоматически расчитаное поле
                     * @returns {string}
                     */
                    function getErrorMsgBuhPeriod(typeB, valueB, valueBCalc) {
                        var type = typeB.slice(1);
                        return "Введённое значение \"" + valueB + "\" в строке \"" + type + "\" не соответствует " +
                            "рассчитанному \"" + valueBCalc + "\". Пожалуйста, проверьте введённое значение " +
                            "по строке \"" + type + "\" перед отправкой анкеты в банк."
                    }
                }

                /*
                * Выбор типа из списка (Поручители, Учредители)
                * type - тип списка
                * */
                function selectTypeFn(type, row) {
                    var selected = row.$select.selected;

                    //удаляем файл копии паспорта при смене типа учредителя с Физического лица на Юридическое лицо
                    if (type === 'founders' && selected.type == 'foundersCompanies' && $scope.model && $scope.model.id) {
                        delPassportDocFn($scope.model);
                    }
                    $scope.model = {};
                    if (type === 'bails') {
                        //проставляем тип
                        $scope.model.bailType = selected.type;
                        //устанавливаем значения по умолчанию
                        if ($scope.model.bailType === 'company') {
                            $scope.model.signer = {};
                            $scope.model.signer.byProxy = false;

                            $scope.model.signer.personAttributes = {
                                citizenship: 'RUS',
                                countryOfResidence: 'RUS'
                            };
                            $scope.model.signer.identityDocument = {
                                identityDocumentType: 'passportRf'
                            }
                        } else {
                            $scope.model.identityDocument = {
                                identityDocumentType: 'passportRf'
                            };
                        }
                    } else if (type === 'founders') {
                        //проставляем тип
                        if (!$scope.model.foundersTemp) $scope.model.foundersTemp = {};
                        $scope.model.foundersTemp.type = selected.type;
                        //устанавливаем значения по умолчанию
                        if (selected.type === 'foundersPersons') {
                            $scope.model.personAttributes = {
                                citizenship: 'RUS',
                                countryOfResidence: 'RUS'
                            };
                            $scope.model.identityDocument = {
                                identityDocumentType: 'passportRf'
                            };
                        }
                        if ($scope.modelView.tableInfo.type === 'foundersLoko') {
                            $scope.model.personAttributes = {
                                citizenship: 'RUS',
                                citizenshipName: 'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
                                countryOfResidence: 'RUS',
                                countryOfResidenceName: 'РОССИЙСКАЯ ФЕДЕРАЦИЯ'
                            };
                            $scope.model.identityDocument = {
                                identityDocumentType: 'passportRf'
                            };
                        }
                    }
                    if (!['beneficiariesLoko', 'foundersLoko'].includes($scope.modelView.tableInfo.type)) {
                        setTimeout(function () {
                            onlyRequiredSrv.showHide($scope.bankGuarantee.form.$$element[0], true);

                        }, 100);
                    }
                }

                /**
                 * Выбор региона
                 * @param row - выбранное значение из справочника
                 * @param model - модель куда ставим
                 */
                function selectRegionFn(row, model) {
                    const selected = row.$select.selected;
                    model.region = selected ? {
                        id: selected.code,
                        name: selected.formalname + ' ' + selected.shortname
                    } : null
                }

                $scope.focusFirstInvalid = function (form) {
                    setTimeout(function () {//отрисовка
                        $scope.submitted = true;
                        if (form && form.$error && form.$error.required && form.$error.required[0]
                            && form.$error.required[0].$$element && form.$error.required[0].$$element[0]) {
                            desktopSrv.scollTop();
                            form.$error.required[0].$$element[0].focus();
                        }
                    }, 400)
                };

                function closeFormFn() {
                    if ($scope.modelView.isReadOnly) {
                        goBackFn();
                    } else {
                        needConfirmSrv.call(!$scope.model.id ? cleanModelView : goBackFn, [], 'Внесенные изменения сохранены не будут');
                    }
                }

                function cleanModelView(){
                    if($scope.modelView.tableInfo.data)
                        delete $scope.modelView.tableInfo.data.deff__fileName;
                    goBackFn();
                }

                /*
                * Вернуться назад без сохранения
                * */
                function goBackFn() {
                    bankGuaranteeSrv.setActiveLevel('app');
                }

                $scope.importBuhReportSuccess = function (result) {
                    if (result) {
                        $scope.model.list = result
                    }
                };
                $scope.getOrderId = function () {
                    return $stateParams.id;
                };

                /**
                 * Автоподсчет полей
                 * @param type - поле которое считаем (null для автоподсчета всех полей)
                 */
                function calculationBuhPeriodsFn(type) {
                    var model = $scope.model.list;
                    if (type) {
                        if (!$scope.modelView.calculationList) {
                            $scope.modelView.calculationList = {};
                        }
                        var list = $scope.modelView.calculationList;
                        if (type === "b1100") {
                            list.b1100 = getValue(model.b1110) + getValue(model.b1120) + getValue(model.b1130) + getValue(model.b1140) + getValue(model.b1150) + getValue(model.b1160) + getValue(model.b1170) + getValue(model.b1180) + getValue(model.b1190);
                            // проставляем в модель до след расчета
                            setInModel();
                            // пересчитываем часть которая зависит от вновь рассчитанной
                            $scope.calculationBuhPeriods("b1600");
                        } else if (type === "b1200") {
                            list.b1200 = getValue(model.b1210) + getValue(model.b1220) + getValue(model.b1230) + getValue(model.b1240) + getValue(model.b1250) + getValue(model.b1260);
                            setInModel();
                            // пересчитываем часть которая зависит от вновь рассчитанной
                            $scope.calculationBuhPeriods("b1600");
                        } else if (type === "b1600") {
                            list.b1600 = getValue(model.b1100) + getValue(model.b1200);
                            setInModel();
                        } else if (type === "b1300") {
                            list.b1300 = getValue(model.b1310) - getValue(model.b1320) + getValue(model.b1340) + getValue(model.b1350) + getValue(model.b1360) + getValue(model.b1370);
                            setInModel();
                            // пересчитываем часть которая зависит от вновь рассчитанной
                            $scope.calculationBuhPeriods("b1700");
                        } else if (type === "b1400") {
                            list.b1400 = getValue(model.b1410) + getValue(model.b1420) + getValue(model.b1430) + getValue(model.b1450);
                            setInModel();
                            // пересчитываем часть которая зависит от вновь рассчитанной
                            $scope.calculationBuhPeriods("b1700");
                        } else if (type === "b1500") {
                            list.b1500 = getValue(model.b1510) + getValue(model.b1520) + getValue(model.b1530) + getValue(model.b1540) + getValue(model.b1550);
                            setInModel();
                            // пересчитываем часть которая зависит от вновь рассчитанной
                            $scope.calculationBuhPeriods("b1700");
                        } else if (type === "b1700") {
                            list.b1700 = getValue(model.b1300) + getValue(model.b1400) + getValue(model.b1500);
                            setInModel();
                        } else if (type === "b2100") {
                            list.b2100 = getValue(model.b2110) - getValue(model.b2120);
                            setInModel();
                            // пересчитываем часть которая зависит от вновь рассчитанной
                            $scope.calculationBuhPeriods("b2200");
                        } else if (type === "b2200") {
                            list.b2200 = getValue(model.b2100) - getValue(model.b2210) - getValue(model.b2220);
                            setInModel();
                            // пересчитываем часть которая зависит от вновь рассчитанной
                            $scope.calculationBuhPeriods("b2300");
                        } else if (type === "b2300") {
                            list.b2300 = getValue(model.b2200) + getValue(model.b2310) + getValue(model.b2320) - getValue(model.b2330) + getValue(model.b2340) - getValue(model.b2350);
                            setInModel();
                            // пересчитываем часть которая зависит от вновь рассчитанной
                            $scope.calculationBuhPeriods("b2400");
                        } else if (type === "b2400") {
                            list.b2400 = getValue(model.b2300) - getValue(model.b2410) + getValue(model.b2430) + getValue(model.b2450) + getValue(model.b2460);
                            setInModel();
                        }


                    } else {
                        /**
                         * Если не указано поле - считаем все
                         * поля b1600, b1700, b2200, b2300 и b2400 запускать не надо, тк рассчитаются за счет вызова в группе зависимых полей
                         */
                        $scope.calculationBuhPeriods("b1100");
                        $scope.calculationBuhPeriods("b1200");
                        // $scope.calculationBuhPeriods("b1600");
                        $scope.calculationBuhPeriods("b1300");
                        $scope.calculationBuhPeriods("b1400");
                        $scope.calculationBuhPeriods("b1500");
                        // $scope.calculationBuhPeriods("b1700");
                        $scope.calculationBuhPeriods("b2100");
                        // $scope.calculationBuhPeriods("b2200");
                        // $scope.calculationBuhPeriods("b2300");
                        // $scope.calculationBuhPeriods("b2400");
                    }

                    /**
                     * Если стоит галка автоподсчета - проставляем в поле рассчитанную величину
                     */
                    function setInModel() {
                        if ($scope.modelView.autoCalculation) {
                            model[type] = list[type];
                        }
                    }

                    /**
                     * Получаем поле или 0
                     */
                    function getValue(value) {
                        return value ? Number(value) : 0;
                    }
                }

                /**
                 * Удаление вложения
                 * @param model
                 */
                function delPassportDocFn(model){
                    bankGuaranteeVTBSrv.delPassportDoc(model, $scope.modelApp.principal);
                }

                $scope.onPassFileNameError = () =>
                    $scope.modelView.alertObjDocPassport = alertSrv.getErrorMsg("Файл не добавлен. Можно добавлять только файлы с расширением pdf.");
            }]
    });
