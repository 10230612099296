angular.module('uetp').constant('balance', {
	title    : 'Оборотно-сальдовая ведомость',
	access   : ['admincab', 'accountant'],
	url      : '/auth/tariff/downloadOsvReport',
	template : 'app/components/reports/types/balanceReport.html',
	data     : {
		statementDate: null,
		type: 'xlsx'
	},
	toMSK    : ['statementDate'],
	fileName : function ($filter, data) {
		var ext = {
			xlsx : 'xlsx',
			csv  : 'csv'
		};
		return "Оборотно_сальдовая_ведомость_" + $filter('date')(data.statementDate, 'dd.MM.yyyy') + '.' + ext[data.type];
	}
});