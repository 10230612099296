angular.module('uetp').service('titleSrv', ['constants',
    function (constants) {
        this.setTitle = setTitleFn;

        function setTitleFn(title) {
            let addTitle = '';
            let description = '';

            if (title !== 'default' && title) {
                addTitle = title + ' — ';
                description = addTitle + constants.configProject.title;
            } else {
                description = constants.configProject.metaDesc;
            }
            document.title = addTitle + constants.configProject.title;
            document.querySelector('meta[name="description"]').setAttribute("content", description);
        }
    }]
);

