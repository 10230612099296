/**
 * Заявление на банковскую гарантию
 *
 * @author dmitrys
 */
angular.module('uetp')
    .component('bankGuaranteeApp', {
        templateUrl: 'app/components/bankGuaranteeApp/bankGuaranteeApp.html',
        bindings: {
            bankGuaranteeAppData: '<',
            participantInfoData: '<'
        },
        controller: ['$scope', '$state', '$stateParams', 'constants', 'bankGuaranteeSrv', '$filter', 'dictionarySrv', 'httpSrv', 'alertSrv', 'authSrv','desktopSrv', 'needConfirmSrv', 'propertiesService','onlyRequiredSrv','proceduresSrv',
            function ($scope, $state, $stateParams, constants, bankGuaranteeSrv, $filter, dictionarySrv, httpSrv, alertSrv, authSrv, desktopSrv, needConfirmSrv, propertiesService,onlyRequiredSrv,proceduresSrv) {

                var ctrl = this;

                $scope.goBack = goBackFn;
                $scope.clickRow = clickRowFn;
                $scope.delRow = delRowFn;
                $scope.save = saveFn;
                $scope.signSend = signSendFn;
                $scope.bikChangeHandler = bikChangeHandlerFn;
                $scope.refreshDict = refreshDictFn;
                $scope.addFile = addFileFn;
                $scope.selectDopDocument = selectDopDocumentFn;
                $scope.clearAdress = clearAdressFn;
                $scope.deleteOtherField = deleteOtherFieldFn;
                $scope.downloadFile = downloadFileFn;
                $scope.getProductType = getProductTypeFn;
                $scope.getActiveLevel = bankGuaranteeSrv.getActiveLevel;
                $scope.setActiveLevel = bankGuaranteeSrv.setActiveLevel;
                $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                $scope.getActiveTab = bankGuaranteeSrv.getActiveTab;
                $scope.setActiveTab = setActiveTabFn;
                $scope.setPDL = bankGuaranteeSrv.setPDL;
                $scope.getDict = bankGuaranteeSrv.getDict;
                $scope.clearDopInfoByProxy = bankGuaranteeSrv.clearDopInfoByProxy;
                $scope.checkAgreement = checkAgreementFn;
                $scope.showDecision = showDecisionFn;
                $scope.submitted = false;
                $scope.fillCheckbox = fillCheckboxFn;
                $scope.hasRelationsExtraFields = hasRelationsExtraFieldsFn;
                $scope.guaranteeReceivingWayExtraFields = guaranteeReceivingWayExtraFieldsFn;
                $scope.selectPurchaseEIS = selectPurchaseEISFn;
                $scope.fillPrincipalSignerFromPrincipal = fillPrincipalSignerFromPrincipalFn;
                $scope.getLinkPurchaseEIS = getLinkPurchaseEISFn;
                $scope.changeState = changeStateFn;
                $scope.assignAgent = assignAgentFn;
                $scope.isShowIkzList = isShowIkzListFn;
                $scope.modelView = {
                    agreementCheck: false,//согласие на обработку данных
                    agreementCheckBik: false,//согласие на получение АКБ
                    dict:null,//справочники
                    isReadOnly: false,//форма редактирования
                    doingSaveOrSend:false,//процесс сохранения или отправки
                    doingSaveAndSend:false,//процесс сохранения и отправки
                    formCreate:false,// форма создания
                    isRefresh: false,// обновление или создание анкеты
                    isPrimary: false, // маленькая форма (с лимитом) или большая
                    isAgent:false,//Форма создания агентом
                    participantInfo:null//Информация по пользователю, за которого заполняет данные агент
                };
                $scope.tooltipInfoPhone = "Телефон необходимо указывать вместе с кодом города. Пример формата ввода номера: +7 (123) 456-78-90";
                $scope.tooltipInfoAddress = "Формат указания адреса: 195112, г. Санкт-Петербург, Малоохтинский пр., д. 68";
                /*
                * Формы для валидации
                * Каждая вкладка в отдельной форме
                * */
                $scope.companyInfo = {};
                $scope.companyManagement = {};
                $scope.principalBuh = {};
                $scope.principalSigner = {};
                $scope.bankGuarantee = {};

                $scope.constants = constants;

                $scope.dateOptions = {};
                $scope.tomorrow = getServerTimeMsk();
                if($scope.tomorrow){
                    $scope.tomorrow.setDate($scope.tomorrow.getDate() + 1);
                    $scope.tomorrow.setHours(0, 0, 0, 0);
                    $scope.dateOptionsGuaranteeStart = {
                        minDate: $scope.tomorrow
                    };
                }

                $scope.paginator = {
                    itemsPerPage: 20,
                    page: 0
                };
                $scope.dateGuaranteeStart = {
                    opened: false
                };
                /*
                * Типы документов - основной блок
                * */
                $scope.modelView.documentTypeList = ['doc_principalCharter', 'doc_principalDocumentEIO', 'doc_otherClientDocs', 'doc_guarantorDocumentEIO',
                    'doc_Nonresident', 'doc_finReportQ', 'doc_taxForm', 'doc_principalFinReport', 'doc_principalDocumentConfirming', 'doc_bigDealApproveLot'
                ];

                $scope.modelView.primaryDocumentTypeList = ['doc_otherClientDocs'];

                $scope.feedbackFormVisibility = function () {
                    $scope.feedbackForm = $scope.feedbackForm ? false : true;
                };

                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.auth = authSrv.getAuth();
                    $scope.user = authSrv.getUser();
                    $scope.feedbackForm = false;
                    // Временно убираем проверку по комментарию
                    // https://jira.comita.lan/browse/UETP-5202?focusedCommentId=586469&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-586469
                    //propertiesService.getProperty("bankguarantee.enabled").then(function() {
                    //    if(!desktopSrv.isBGEnabled()) {
                    //        $state.go('home');
                    //    } else {
                            initComponent();
                    //    }
                    //});
                };

                function initComponent(){
                    //primary анкета
                    var isPrimary = getPrimaryByState();
                    //форма создания
                    var isAppCreate = getAppCreate();
                    //форма редактирования
                    var isAppOpen = getAppOpen();
                    //форма создания копии
                    var isAppCopy = getAppCopy();
                    //форма агента
                    var isAppAgent = getAppAgent();
                    //Информация по пользователю, за которого заполняет данные агент
                    if(ctrl.participantInfoData && ctrl.participantInfoData.data && ctrl.participantInfoData.data.success){
                        $scope.modelView.participantInfo = ctrl.participantInfoData.data.result;
                        $scope.modelView.participantSelected = $stateParams.participantSelected;
                    }
                    //если попали под участником на состояние агента - редирект на правильное состояние
                    if(!authSrv.isUserAgent()){
                        if(isAppAgent){
                            //для формы создания
                            if(isAppCreate){
                                $state.go('bankGuaranteeAppCreate')
                            }else if (isAppOpen){
                                //для форм редактирования (Primary и Order)
                                if(isPrimary){
                                    $state.go('bankGuaranteePrimaryAppOpen',{id: $stateParams.id, participantId: $stateParams.participantId, clearConfig: true})
                                }else{

                                    $state.go('bankGuaranteeAppOpen',{id: $stateParams.id.id, clearConfig: true})
                                }
                            }else if (isAppCopy){
                                //для формы копирования
                                $state.go('bankGuaranteeAppCopy',{id: $stateParams.id, clearConfig: true})
                            }
                        }
                    }
                    if (!(authSrv.canActiveBankGuarantee($scope.cabType(), $scope.user) || authSrv.isUserAgent() || ['admincab','support'].includes($scope.cabType()))) {
                        $state.go('bankGuarantees');
                    }
                    $scope.certError = authSrv.isCertificateError;
                    dictionarySrv.resetPaginatedDict($scope);
                    bankGuaranteeSrv.setDictRemote(dictionarySrv.getRemoteData());
                    $scope.dictionary = bankGuaranteeSrv.getDictRemote();
                    _initDict(['okcm'], false);

                    $scope.refreshDict('rOKPD2EIS', null, true);
                    initDocument(isPrimary);
                    $scope.modelView.isPrimary = isPrimary;
                    $scope.modelView.isAgent = isAppAgent;
                    if (isAppOpen) {
                        $scope.modelView.isRefresh = true;
                        $scope.modelView.formCreate = false;
                        if (ctrl.bankGuaranteeAppData && ctrl.bankGuaranteeAppData.data && ctrl.bankGuaranteeAppData.data.success) {
                            //статус заявки
                            $scope.modelView.state = ctrl.bankGuaranteeAppData.data.state;
                            //время создания
                            $scope.modelView.created = ctrl.bankGuaranteeAppData.data.created;
                            //агент
                            $scope.modelView.agent = ctrl.bankGuaranteeAppData.data.agent;
                            //последний ответ от банка
                            if(ctrl.bankGuaranteeAppData.data.responseContent){
                               $scope.modelView.decisionOptions = ctrl.bankGuaranteeAppData.data.responseContent.decisionOptions;
                               $scope.modelView.taskDefinitionKey = ctrl.bankGuaranteeAppData.data.responseContent.taskDefinitionKey;
                            }
                            //заявка
                            var data = JSON.parse(ctrl.bankGuaranteeAppData.data.result);
                            bankGuaranteeSrv.setData(getModelFromView(data));
                            //главки согласия
                            $scope.modelView.agreementCheck= data.isAgreement;
                            $scope.modelView.agreementCheckBik = data.isAgreementBki;
                            if ($stateParams.successSave) {
                                $scope.alertObjbankGuaranteeApp = alertSrv.getSuccessMsg('Заявка сохранена успешно.');
                                setTimeout(function () {
                                    desktopSrv.scrollBottom();
                                }, 1500);
                            }
                            //waitClient - Ожидает действий пользователя
                            //анкета после отправки пришла с ошибками спустя время
                            if($scope.modelView.state === 'waitClient'){
                                if(ctrl.bankGuaranteeAppData.data.currentMessage){
                                    initErrorMess(ctrl.bankGuaranteeAppData.data.currentMessage);
                                    setTimeout(function () {
                                        desktopSrv.scrollBottom();
                                    }, 1500);
                                }
                            }
                        }
                    }
                    /*
                    * Копируем анкету
                    * */
                    if (isAppCopy) {
                        $scope.modelView.formCreate = true;
                        $scope.modelView.isRefresh = false;
                        if (ctrl.bankGuaranteeAppData && ctrl.bankGuaranteeAppData.data && ctrl.bankGuaranteeAppData.data.success) {
                            //статус заявки
                            $scope.modelView.state = 'draft';
                            //заявка
                            var data = JSON.parse(ctrl.bankGuaranteeAppData.data.result);
                            //UETP-6047 data.documents = [];
                            delete data.agreement;
                            delete data.decision;
                            bankGuaranteeSrv.setData(getModelFromView(data));
                        }
                    }
                    if ($stateParams.clearConfig) {
                        if (isAppCreate) bankGuaranteeSrv.setData({principal: {}});
                        $scope.setActiveTab('bankGuarantee');
                        $scope.setActiveLevel('app');
                    }
                    if (isAppCreate) {
                        $scope.modelView.state = 'draft';
                        $scope.modelView.formCreate = true;//форма создания
                        $scope.modelView.isRefresh = false;
                        /*
                        * Заполняем значениями по умолчанию
                        * isPrimary - PRIMARY или ORDER анкета
                        * participantInfo - информация по участнику (есть если заполняет агент)
                        * */
                        var data = bankGuaranteeSrv.getDefaultModel($scope.modelView.isPrimary,$scope.modelView.participantInfo);
                        bankGuaranteeSrv.setData(data);
                    }
                    //если статус не черновик или ожидаются действия от пользователя, то можно редактировать
                    $scope.modelView.isReadOnly = true;
                    if(($scope.modelView.state === 'draft' || $scope.modelView.state === 'waitClient') && !['admincab','support'].includes($scope.cabType())){
                        $scope.modelView.isReadOnly = false;
                    }

                    $scope.model = bankGuaranteeSrv.getData();

                    if($stateParams.purchasData){
                        bankGuaranteeSrv.setDataFormPurchase($scope.model, $stateParams.purchasData, $stateParams.purchasDataType,$scope.modelView.isPrimary);
                    }
                    getDocumentFromView(angular.copy($scope.model));
                    $scope.modelView.isEdit = isAppOpen;

                    if($scope.showDecision() && !$scope.model.decision) {
                       $scope.model.decision = {};
                    }
                    //заблокировать вкладку документа для primry анкеты в статусе черновик (draft)
                    $scope.modelView.disabledDocuments = $scope.modelView.state === 'draft' && $scope.modelView.isPrimary
                    // Сохраняем признаки формы в сервис
                    bankGuaranteeSrv.setStateApp(isPrimary, isAppCreate, isAppOpen, isAppCopy, isAppAgent, $scope.modelView.isReadOnly);
                    if($scope.cabType() === 'admincab' && $stateParams.participantId) {
                        bankGuaranteeSrv.loadParticipantAgentsList($stateParams.participantId).then(function successCallback(response) {
                            if (response.data.success) {
                                $scope.agentsList = response.data.result;
                            } else {
                                $scope.agentsList = [];
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        })
                    }
                }

                /**
                * Заполнить анкету по закупке из ЕИС
                 * поиск по номеру закупки
                * */
                function selectPurchaseEISFn() {
                    if($scope.model.bankGuarantee && $scope.model.bankGuarantee.purchaseNumber) {
                        var param = {
                            searchByField: {purchase_number:$scope.model.bankGuarantee.purchaseNumber}
                        };
                        proceduresSrv.searchPurchases($scope, param).then(function (response) {
                            if(response && response.data && response.data.success) {
                                var purchaseData = response.data.items;
                                if (purchaseData && purchaseData.length>0)
                                    bankGuaranteeSrv.setDataFormPurchase($scope.model, purchaseData[0], "EIS", $scope.modelView.isPrimary);
                            }
                        })


                    }
                }
                /**
                 * Заполнить Данные о подписанте на вкладке Подписант
                 * данными о руководителе с вкладки Органы управления
                 * */
                function fillPrincipalSignerFromPrincipalFn() {
                    if ($scope.model.principal.companyHead) {
                        $scope.model.principal.principalSigner = angular.copy($scope.model.principal.companyHead);
                        if ($scope.model.principal.principalSigner.eioProtocolDate) {
                            delete $scope.model.principal.principalSigner.eioProtocolDate
                        }
                        if ($scope.model.principal.principalSigner.eioProtocolNumber) {
                            delete $scope.model.principal.principalSigner.eioProtocolNumber
                        }
                        $scope.model.principal.principalSigner.byProxy = false;
                    }
                }
                /*
                * Ссылка на закупку ЕИС по ее номеру для всех ФЗ
                * */
                function getLinkPurchaseEISFn (purchaseNumber){
                    return 'https://zakupki.gov.ru/epz/order/extendedsearch/results.html?searchString='+purchaseNumber+'&morphology=on';
                }
                /*
                * Скрываем необязательные поля при открытии формы
                * необходима задержка для отработки ng-include
                * */
                $scope.$on("$includeContentLoaded", function(event, templateName){
                    setTimeout(function () {
                    var name = templateName.slice(templateName.lastIndexOf('/')+1,templateName.lastIndexOf('.'));
                        if(name==='bankGuarantee'){
                            onlyRequiredSrv.showHide($scope.bankGuarantee.form.$$element[0], true);
                        }else  if(name==='companyInfo'){
                            onlyRequiredSrv.showHide($scope.companyInfo.form.$$element[0], true);
                        }else  if(name==='companyManagement'){
                            onlyRequiredSrv.showHide($scope.companyManagement.form.$$element[0], true);
                        }else  if(name==='principalBuh'){
                            onlyRequiredSrv.showHide($scope.principalBuh.form.$$element[0], true);
                        }else  if(name==='principalSigner'){
                            onlyRequiredSrv.showHide($scope.principalSigner.form.$$element[0], true);
                        }
                    }, 100);
                });
                function _initDict(dictList, reload) {
                    angular.forEach(dictList, function (name) {
                        dictionarySrv.getDictionaryRemote(name, reload);
                    })
                }
                /*
               * Получаем полный список всех возможных типов документов и проставляем тип блока (основной/дополнительный)
               * Заполняем основной блок документов (documents)
               * и список дополнительных (dopDocumentList)
               * */
                function initDocument(isPrimary) {
                    $scope.modelView.documentsFull = angular.copy(bankGuaranteeSrv.getDocTypeList());
                    angular.forEach($scope.modelView.documentsFull, function (item) {
                        angular.forEach(isPrimary ? $scope.modelView.primaryDocumentTypeList : $scope.modelView.documentTypeList, function (type) {
                            if(item.type === type){
                                item.isMainBlock = true;
                            }
                        })
                    })
                    $scope.modelView.dopDocumentList = [];
                    $scope.modelView.documents = [];

                    angular.forEach($scope.modelView.documentsFull, function (item) {
                       if(item.isMainBlock) {
                           add($scope.modelView.documents, item)
                       }else{
                           add($scope.modelView.dopDocumentList, item)
                       }
                    });
                    /*
                    * храним тип и список файлов для этого типа
                    * isMultiple - возможно несколько файлов
                    * isRequired - обязательные файлы
                    * */
                    function add(list, elem) {
                        list.push({
                            type: elem.type,
                            label: elem.label,
                            isMultiple: elem.isMultiple,
                            isRequired: elem.isRequired,
                            canEdit: elem.canEdit,
                            info: elem.info,
                            isShow:true,//отображение в списке
                            files: []
                        })
                    }
                }
                /*
                * Установить активную вкладку
                * */
                function setActiveTabFn(tab) {
                    if(tab === 'documents' && $scope.modelView.disabledDocuments) return;
                    bankGuaranteeSrv.setActiveTab(tab)
                }
                /*
                * Проверка анкеты на тип
                *  true - PRIMARY
                *  false - ORDER
                * */
                function getPrimaryByState(){
                    if($state.includes('bankGuaranteeAppCreate') || $state.includes('bankGuaranteeAppOpen')
                        || $state.includes('bankGuaranteeAppAgentCreate') || $state.includes('bankGuaranteeAppAgentOpen')){
                        return false;
                    } else if($state.includes('bankGuaranteePrimaryAppCreate') || $state.includes('bankGuaranteePrimaryAppOpen')
                        || $state.includes('bankGuaranteePrimaryAppAgentCreate') || $state.includes('bankGuaranteePrimaryAppAgentOpen')){
                        return true;
                    } else if($state.includes('bankGuaranteeAppCopy') || $state.includes('bankGuaranteeAppAgentCopy'))
                        return ctrl.bankGuaranteeAppData && ctrl.bankGuaranteeAppData.data.type==="primary";
                    else return $scope.modelView.isPrimary;
                }

                function isShowIkzListFn(){
                    return !$scope.modelView.isPrimary && $scope.model.bankGuarantee.federalLaw == '44';
                }

                /*
                * Форма создания
                * */
                function getAppCreate() {
                    return $state.includes('bankGuaranteeAppCreate') || $state.includes('bankGuaranteePrimaryAppCreate')
                        || $state.includes('bankGuaranteeAppAgentCreate') || $state.includes('bankGuaranteePrimaryAppAgentCreate');
                }
                /*
                * Форма редактирования
                * */
                function getAppOpen() {
                    return $state.includes('bankGuaranteeAppOpen') || $state.includes('bankGuaranteePrimaryAppOpen')
                        || $state.includes('bankGuaranteeAppAgentOpen') || $state.includes('bankGuaranteePrimaryAppAgentOpen')
                }
                /*
                * Форма создания копии
                * */
                function getAppCopy() {
                    return $state.includes('bankGuaranteeAppCopy') || $state.includes('bankGuaranteeAppAgentCopy')
                }
                /*
                * Форма агента
                * */
                function getAppAgent() {
                    return $state.includes('bankGuaranteeAppAgentCreate') || $state.includes('bankGuaranteePrimaryAppAgentCreate')
                        || $state.includes('bankGuaranteeAppAgentOpen') || $state.includes('bankGuaranteePrimaryAppAgentOpen')
                        || $state.includes('bankGuaranteeAppAgentCopy')
                }
                /*
                * Подготовляем документы для отображения
                * */
                function getDocumentFromView(model) {
                    /*
                    * Предзаполняем список пустыми значениями
                    * */
                    $scope.model.documents = angular.copy($scope.modelView.documents);

                    if (!$scope.model.dopDocuments) $scope.model.dopDocuments = [];
                    /*
                    * Проставляем файлы
                    * */
                    if (model.documents) {
                        angular.forEach(model.documents, function (file) {
                            if (($scope.modelView.isPrimary ? $scope.modelView.primaryDocumentTypeList : $scope.modelView.documentTypeList).indexOf(file.type)>=0) {
                                /*
                                * запоняем основные документы ($scope.model.documents)
                                * */
                                angular.forEach($scope.model.documents, function (item) {
                                    if (file.type === item.type) {
                                        addFile(item, file);
                                    }
                                });
                            }else if (($scope.modelView.isPrimary ? $scope.modelView.primaryDocumentTypeList : $scope.modelView.documentTypeList).indexOf(file.type) === -1) {
                                /*
                                * если файл не из основные блока, то добавляем в доп документы
                                * */
                                //флаг поиска места
                                var foundPlace = false;
                                //ищем место для файла в доп документах
                                angular.forEach($scope.model.dopDocuments, function (item) {
                                    if (file.type === item.type) {
                                        //нашли -> записываем файла
                                        foundPlace = true;
                                        addFile(item, file);
                                    }
                                });
                                if(!foundPlace){
                                    var obj = {type:file.type, files:[]};
                                    addFile(obj, file);
                                    $scope.model.dopDocuments.push(obj);
                                }
                                //скрываем тип файла из списка доп докумнтов для выбора
                                angular.forEach($scope.modelView.dopDocumentList, function (doc) {
                                    if(doc.type === file.type){
                                        doc.isShow = false;
                                    }
                                })
                            }
                        })
                    }
                    /*
                    * добавляем файлв с список
                    * item.files - список файлов
                    * file - файла для добавления
                    * */
                    function addFile(item, file) {
                        if(!item.files) item.files = [];
                        /*
                        * Если у файла не заполнены свойства - вытаскиваем их из справочника
                        * */
                        if(!item.label || item.label===''){
                            angular.forEach($scope.modelView.dopDocumentList, function (doc) {
                                if(doc.type === file.type){
                                    item.label = doc.label;
                                    item.isMultiple = doc.isMultiple;
                                }
                            })
                        }
                        item.files.push(file)
                    }
                }
                /**
                 * Заполнить Номер корреспондентского счета банка из выбранного банка
                 */
                function bikChangeHandlerFn(selectObj, dataObj) {
                    var selected = selectObj.$select.selected;
                    dataObj.bic = selected ? selected.bic : "";
                    dataObj.accounts = selected ? selected.accounts : "";
                    dataObj.participant = selected ? selected.participant : "";
                }

                /**
                 * Обновление справочника
                 * @param object - тип справочника
                 * @param value - введенные значения
                 * @param refreshScrolling - обновление данных при прокрутке
                 */
                function refreshDictFn(object, value, refreshScrolling, needToAdd, searchValue) {
                    if (!value) {
                        if (object === 'rBIK') {
                            value = searchValue;
                        }
                    }
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling, null, needToAdd)
                }
                /*
                 * Добавить файлы по типу вложения
                 * @param files - файлы
                 * @param type - тип вложения
                 * */
                function addFileFn(files, type) {
                    /*
                    * если файл из основного блока добавить в modelView.documents, если из доп - modelView.dopDocuments
                    * */
                    if (($scope.modelView.isPrimary ? $scope.modelView.primaryDocumentTypeList : $scope.modelView.documentTypeList).indexOf(type) === -1) {
                        if (!$scope.model.dopDocuments) $scope.model.dopDocuments = [];
                        doAddFile($scope.model.dopDocuments, files)
                    } else {
                        if (!$scope.model.documents) $scope.model.documents = [];
                        doAddFile($scope.model.documents, files)
                    }

                    /*
                    * Добавить файлы в модель (основные или дополнительные)
                    * model - модель куда добавить
                    * */
                    function doAddFile(model, files) {
                        angular.forEach(model, function (item) {
                            if (item.type === type) {
                                angular.forEach(files, function (file) {
                                    item.files.push(
                                        {
                                            created: file.created,
                                            fileName:file.fileName,
                                            id: file.id,
                                            mimeType: file.mimeType,
                                            sended: file.sended,
                                            size: file.size,
                                            state: file.state,
                                            type: file.type
                                        }
                                    )
                                })
                            }
                        })
                    }

                }
                /*
                * Подготовляем документы для сохранения
                * */
                function getDocumentFromSave(model) {
                    var documents = [];
                    /*
                    * Информация по основным документам
                    * */
                    doGetDocument(documents, model.documents);
                    /*
                    * Информация по дополнительным документам
                    * */
                    doGetDocument(documents, model.dopDocuments);
                    /*
                    * Преобразовать документы для сохранения
                    * */
                    function doGetDocument(list, from) {
                        angular.forEach(from, function (item) {
                            angular.forEach(item.files, function (file) {
                                if(!file.type) file.type = item.type;
                                list.push(file)
                            })
                        })
                    }
                    return documents;
                }
                /*
                * Проверка документов
                * Можно добавить только 10 документов в один блок
                *
                * return {
                *   result:true, - результат проверки
                *   list:[] - список блоков в которых ошибка
                * }
                * */
                function doCheckCountDocument(data) {
                    var resultCheck = {result:true, list:[]};
                    /*
                    * Проверка основных документов
                    * */
                    checkDocument(resultCheck,data.documents);
                    /*
                    * Проверка дополнительных документов
                    * */
                    checkDocument(resultCheck,data.dopDocuments);

                    function checkDocument(resultCheck, from) {
                        angular.forEach(from, function (item) {
                            if(item.files && item.files.length>10){
                                resultCheck.result = false;
                                resultCheck.list.push(item.label);
                            }
                        });
                    }
                    return resultCheck;
                }
                /*
                * Проверяем кол-во вложений и формируем сообщение с ошибкой
                * */
                function checkCountDocument(data) {
                    var checkSizeDoc = doCheckCountDocument(data);
                    if(!checkSizeDoc.result) {
                        var msg = '';
                        if (checkSizeDoc.list.length === 1) {
                            msg = 'На вкладке "Документы" в разделе "' + checkSizeDoc.list[0] + '" добавлено больше 10 вложений ';
                        } else {
                            msg = 'На вкладке "Документы" в разделах: ';
                            angular.forEach(checkSizeDoc.list, function (item) {
                                msg += '<br>"'+item + '"';
                            });
                            msg += '<br>добавлено больше 10 вложений ';
                        }
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsgWithBtn(msg, 'Перейти', $scope.setActiveTab,
                            'documents');
                        $scope.modelView.doingSaveOrSend = false;
                    }
                    return checkSizeDoc.result;
                }
                /*
                * Выбрали дополнительный документ из списка
                * добавляем документ в список доп документов
                * */
                function selectDopDocumentFn(selectObj) {
                    var selected = angular.copy(selectObj.$select.selected);
                    if (!$scope.model.dopDocuments) $scope.model.dopDocuments = [];
                    $scope.model.dopDocuments.push({
                        type: selected.type,
                        label: selected.label,
                        isMultiple: selected.isMultiple,
                        isRequired: selected.isRequired,
                        canEdit: selected.canEdit,
                        files: []
                    });
                    //скрываем тип файла из списка доп докумнтов для выбора
                    angular.forEach($scope.modelView.dopDocumentList, function (doc) {
                        if(doc.type === selected.type){
                            doc.isShow = false;
                        }
                    });
                    delete $scope.modelView.selectDopDoc;
                }
                /*
                * Очитсить модель адрес при смене типа
                * */
                function clearAdressFn(data) {
                    data.rent = {};
                    data.property = {};
                }

                /**
                 * Удаление доп поля если не выбрано "ИНОЕ"
                 * @param selectNameModel - имя поля в модели(key in model)
                 * @param deleteName - имя поля для удаления из модели(key in model)
                 * @param model - модель данных где будем удалять и искать
                 *
                 */
                function deleteOtherFieldFn(selectNameModel, deleteName, model) {
                    if (model[selectNameModel] !== 'other') {
                        delete model[deleteName];
                    }
                }

                function downloadFileFn(url, fileName) {
                    if (url === 'agreement') {
                        if ($scope.model.principal && $scope.model.principal.principalSigner && $scope.model.principal.principalSigner.lastName &&
                            $scope.model.principal.principalSigner.firstName && $scope.model.principal.principalSigner.secondName &&
                            $scope.model.principal.principalSigner.registrationAddress && $scope.model.principal.principalSigner.identityDocument &&
                            $scope.model.principal.principalSigner.identityDocument.number &&
                            $scope.model.principal.principalSigner.identityDocument.issuingAuthority &&
                            $scope.model.principal.principalSigner.identityDocument.issuingAuthorityCode &&
                            $scope.model.principal.principalSigner.identityDocument.series &&
                            $scope.model.principal.principalSigner.identityDocument.issuedDate) {
                            url = '/bankguarantee/orders/downloadOrderFile/doc_persDataAgree';
                            var dataForAgreement = getModelFromSave(angular.copy($scope.model));
                            httpSrv.downloadFile(url, 'согласие на обработку персональных данных.docx', dataForAgreement);
                        } else {
                            $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Заполненые не все поля для согласия');
                        }
                    } else if (url === 'agreementBik') {
                        url = '/bankguarantee/orders/downloadOrderFile/doc_bkiPermission';
                        var dataForAgreement = getModelFromSave(angular.copy($scope.model));
                        httpSrv.downloadFile(url, 'согласие на получение АКБ «Абсолют Банк» (ПАО) основной части кредитной истории.docx', dataForAgreement);
                    } else {
                        httpSrv.downloadFile(url, fileName)
                    }
                }

                function getProductTypeFn(type) {
                    var productType = $scope.getDict('productType');

                    return (productType.find(function (name) {
                        return name.code === type
                    }) || {}).name
                }

                function fillCheckboxFn(data) {
                    if (!data) {
                        return false;
                    } else {
                        return true;
                    }
                }

                function hasRelationsExtraFieldsFn(data) {
                    if (!data) {
                        delete $scope.model.principal.companyInfo.registrationPlace;
                        delete $scope.model.principal.companyInfo.relationsName;
                        delete $scope.model.principal.companyInfo.relationsShare;
                        delete $scope.model.principal.companyInfo.relationsType;
                    }
                }

                function guaranteeReceivingWayExtraFieldsFn(data) {
                    if (data != 'dhl') {
                        delete $scope.model.bankGuarantee.gettingAddress;
                        delete $scope.model.bankGuarantee.gettingPhone;
                        delete $scope.model.bankGuarantee.gettingFio;
                        delete $scope.model.bankGuarantee.gettingInfo;
                    }
                }

                function goBackFn() {
                    if ($scope.modelView.isAgent) {
                        $state.go('bankGuarantees', {participantInfo:  $scope.modelView.participantInfo, participantSelected: $scope.modelView.participantSelected});
                    } else
                        history.back();
                }

                /*
                * Открытие или создание новой строки
                * type - тип состояния
                * data - данные (если null - создание)
                * index - индекс в массиве
                * */
                function clickRowFn(type, data, index) {
                    //data пустая когда создание
                    var isNew = data === null;
                    bankGuaranteeSrv.setTableForm(data, type, isNew, $scope.modelView.isReadOnly,index);
                    $scope.setActiveLevel('table');
                }

                /*
                * Сохранение анкеты
                * */
                function saveFn(models) {
                    if (!$scope.modelView.doingSaveOrSend) {
                        $scope.modelView.doingSaveOrSend = true;
                    } else {
                        return;
                    }
                    //оишбки кол-ва вложений
                    if(!checkCountDocument(models)) {
                        $scope.modelView.doingSaveOrSend = false;
                        return;
                    }

                    //форма создания
                    var isAppCreate = getAppCreate();
                    //форма создания копии
                    var isAppCopy = getAppCopy();
                    //форма создания агентом
                    var isAppAgent = getAppAgent();
                    var model = getModelFromSave(angular.copy(models));
                    var agreement = getAgreement(model);
                    if (agreement) {
                        model.agreement = agreement;
                    }
                    model.isAgreement = $scope.modelView.agreementCheck;
                    model.isAgreementBki = $scope.modelView.agreementCheckBik;
                    //форма создания агентом - проставляем id пользователя, за которого агент заполняем бг
                    if(isAppAgent){
                        if($stateParams.participantId) {
                            model.participantId = $stateParams.participantId;
                            model.participantName = $scope.modelView.participantInfo.fullName; //наименование участника - запрашиваем при открытии
                            model.participantInn = $scope.modelView.participantInfo.inn;
                            model.participantOgrn = $scope.modelView.participantInfo.ogrn;
                            model.participantType = $scope.modelView.participantInfo.typeOrg;
                            model.agentName = $scope.user.fullName;//наименование агента - берем из профиля
                        }
                        if($stateParams.agent)
                            model.agent = $stateParams.agent;
                    }else{
                    	model.participantName = $scope.user.fullName;
                    	model.participantInn = $scope.user.inn;
                    	model.participantOgrn = $scope.user.ogrn;
                    	model.participantType = $scope.user.typeOrg;
                    }

                    bankGuaranteeSrv.save(model, $stateParams.id, $scope.modelView.isRefresh, false, $scope.modelView.isPrimary, $stateParams.participantId).then(function (response) {
                        if (response.data && response.data.success) {
                            /*
                            * Была форма создания, после сохранения открываем по id
                            * */
                            if (isAppCreate || isAppCopy) {
                                var  state = '';
                                if(isAppAgent){
                                    if($scope.modelView.isPrimary){
                                        state = 'bankGuaranteePrimaryAppAgentOpen'
                                    }else{
                                        state = 'bankGuaranteeAppAgentOpen'
                                    }
                                    $state.go(state, {
                                        id: response.data.result.id,
                                        participantId:$stateParams.participantId,
                                        participantSelected: $scope.modelView.participantSelected,
                                        successSave: true
                                    }, {location: 'replace'});
                                }else{
                                    if($scope.modelView.isPrimary){
                                        state = 'bankGuaranteePrimaryAppOpen'
                                    }else{
                                        state = 'bankGuaranteeAppOpen'
                                    }
                                    $state.go(state, {
                                        id: response.data.result.id,
                                        participantId: $stateParams.participantId ? $stateParams.participantId : '',
                                        successSave: true
                                    }, {location: 'replace'});
                                }

                            } else {
                                $scope.alertObjbankGuaranteeApp = alertSrv.getSuccessMsg(response.data.result);
                            }
                            $scope.modelView.doingSaveOrSend = false;
                        } else {
                            $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Ошибка!');
                            $scope.modelView.doingSaveOrSend = false;
                            console.log(response)
                        }
                    }, function (reason) {
                        console.log(reason);
                        $scope.modelView.doingSaveOrSend = false;
                    })

                }
                /*
                * Перейи на первое не валидное поле
                * data.form - форма
                * data.tab - вкладка
                * */
                $scope.focusFirstInvalid = function (data) {
                    var form = data.form;
                    var tab = data.tab;
                    if($scope.getActiveTab()!=tab)
                        $scope.setActiveTab(tab);
                    setTimeout(function(){//отрисовка
                        $scope.submitted = true;
                        if (form && form.$error && form.$error.required && form.$error.required[0]
                            && form.$error.required[0].$$element && form.$error.required[0].$$element[0]) {
                            desktopSrv.scollTop();
                            form.$error.required[0].$$element[0].focus();
                        }
                    }, 400)
                };
                /*
                * Подписать и отправить
                *
                **/

                function signSendFn(models) {
                    if(!$scope.modelView.doingSaveAndSend) {
                        $scope.modelView.doingSaveAndSend = true;
                    }else{
                        return;
                    }
                    if ($scope.bankGuarantee.form && $scope.bankGuarantee.form.$invalid) {
                        showRequireFieldErrorMessage($scope.bankGuarantee.form, 'bankGuarantee');
                        return;
                    }

                    /*
                    * На вкладке "Отчетность" необходимы два заполненых периуда
                    * */
                    var principalBuhCheck = true;
                    angular.forEach(models.principal.principalBuhPeriods, function (item) {
                        if(!item.date) principalBuhCheck = false;
                    });
                    if (!principalBuhCheck) {
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsgWithBtn('На вкладке "Отчетность" заполнены не все периоды. ', 'Перейти',$scope.setActiveTab,
                            'principalBuh');
                        $scope.modelView.doingSaveAndSend = false;
                        return;
                    }
                    /*
                    * Проверяем формы и показываем ошибки
                    * */
                    if ($scope.companyInfo.form && $scope.companyInfo.form.$invalid) {
                        showRequireFieldErrorMessage($scope.companyInfo.form, 'companyInfo');
                        return;
                    }
                    if ($scope.companyManagement.form && $scope.companyManagement.form.$invalid) {
                        showRequireFieldErrorMessage($scope.companyManagement.form, 'companyManagement');
                        return;
                    }
                    if ($scope.principalBuh.form && $scope.principalBuh.form.$invalid) {
                        showRequireFieldErrorMessage($scope.principalBuh.form, 'principalBuh');
                        return;
                    }
                    if ($scope.principalSigner.form && $scope.principalSigner.form.$invalid) {
                        showRequireFieldErrorMessage($scope.principalSigner.form, 'principalSigner');
                        return;
                    }
                    if (!$scope.modelView.isPrimary && (!models.principal.companyBeneficiaries || models.principal.companyBeneficiaries.length===0)) {
                        showRequireFieldErrorMessage($scope.bankGuarantee.form, 'companyBeneficiaries');
                        return;
                    }
                    if (!$scope.modelView.isPrimary && (!models.principal.beneficiaries || models.principal.beneficiaries.length===0)) {
                        showRequireFieldErrorMessage($scope.bankGuarantee.form, 'bankGuarantee');
                        return;
                    }
                    if (!$scope.modelView.isPrimary && (!models.principal.founders || models.principal.founders.length===0)) {
                        showRequireFieldErrorMessage($scope.bankGuarantee.form, 'founders');
                        return;
                    }
                    //Для анкеты Order проверяем наличие обязательных вложений
                    //и заполненость всех обязательных полей в таблице 'Заказчики' на вкалдке 'Гарантия'
                    if(!$scope.modelView.isPrimary) {
                        var checkDoc = true;
                        angular.forEach(models.documents, function (item) {
                            if (item.isRequired) {
                                if (!item.files || (item.files && item.files.length === 0)) checkDoc = false;
                            }
                        });
                        if (!checkDoc) {
                            $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsgWithBtn('Добавьте все обязательные вложения. ', 'Перейти', $scope.setActiveTab,
                                'documents');
                            $scope.modelView.doingSaveAndSend = false;
                            return;
                        }
                        //Проверяем все обязательные поля таблицы 'Заказчики'
                        var checkBeneficiaries = true;
                        angular.forEach(models.principal.beneficiaries, function (item) {
                            if(!item.actualAddress || !item.inn || !item.kpp || !item.legalAddress
                                || !item.ogrn || !item.fullName || !item.amount || !item.purchaseAmount){
                                checkBeneficiaries = false;
                            }
                        })
                        if(!checkBeneficiaries){
                            $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsgWithBtn('Необходимо заполнить обязательные поля в таблице "Заказчики". ', 'Перейти',$scope.setActiveTab,'bankGuarantee');
                            $scope.modelView.doingSaveAndSend = false;
                            return;
                        }
                    }
                    //оишбки кол-ва вложений
                    if(!checkCountDocument(models)) {
                        $scope.modelView.doingSaveAndSend = false;
                        return;
                    }
                    if($scope.showDecision()) {
                    	if(!$scope.model.decision.resultCode){
                            $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Необходимо выбрать решение.');
                            $scope.modelView.doingSaveAndSend = false;
                            return;
                    	}

                    	var isCommentRequired = false;
                        angular.forEach($scope.modelView.decisionOptions, function (item) {
                            if (item.code === $scope.model.decision.resultCode) {
                            	isCommentRequired = item.isCommentRequired;
                            }
                        });

                        if(isCommentRequired && !$scope.model.decision.comment){
                        	 $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Для данного решения необходим комментарий.');
                             $scope.modelView.doingSaveAndSend = false;
                             return;
                        }

                        if($scope.model.decision.comment === ''){
                        	 delete $scope.model.decision.comment;
                        }
                    }
                    var certId = $scope.auth.certId;
                    /*
                    * Сохраняем форму, а затем подписываем
                    * */
                    var model = getModelFromSave(angular.copy(models));
                    if(!$scope.modelView.agreementCheck){
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Необходимо согласие на обработку персональных данных');
                        $scope.modelView.doingSaveAndSend = false;
                        return;
                    }
                    if(!$scope.modelView.agreementCheckBik){
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Необходимо согласие на получение АКБ «Абсолют Банк» (ПАО) основной части кредитной истории');
                        $scope.modelView.doingSaveAndSend = false;
                        return;
                    }
                    var agreement = getAgreement(model);
                    if (agreement) {
                        model.isAgreement = $scope.modelView.agreementCheck;
                        model.isAgreementBki = $scope.modelView.agreementCheckBik;
                        model.agreement = agreement;
                        bankGuaranteeSrv.save(model, $stateParams.id, $scope.modelView.isRefresh, true, $scope.modelView.isPrimary).then(function (response) {
                            if (response.data && response.data.success) {
                                /*
                                * Подписываем
                                * */
                                bankGuaranteeSrv.getDocumentsDataForSign(certId, $stateParams.id).then(
                                    function (response) {
                                        if (response.data && response.data.success) {
                                            sendAndSign(response.data.result);
                                        }
                                    },
                                    function (reason) {
                                        console.log(reason);
                                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Ошибка! '+reason);
                                        $scope.modelView.doingSaveAndSend = false;
                                    }
                                );
                            } else {
                                $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Ошибка!');
                                console.log(response);
                                $scope.modelView.doingSaveAndSend = false;
                            }
                        }, function (reason) {
                            console.log(reason);
                            $scope.modelView.doingSaveAndSend = false;
                        })
                    } else {
                        $scope.modelView.doingSaveAndSend = false;
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Заполненые не все поля для согласия');
                    }
                    function sendAndSign(data) {
                        bankGuaranteeSrv.signDocument(data.dataList, $scope.auth.certId).then(
                            function (signData) {
                                bankGuaranteeSrv.sendSignedDocuments(signData, $stateParams.id).then(function (result) {
                                    if (result.data && result.data.success) {
                                        bankGuaranteeSrv.send($stateParams.id).then(function (response) {
                                            $scope.modelView.doingSaveAndSend = false;
                                            if (response.data && response.data.success) {
                                                var result = response.data.result;
                                                if(result.state ==='draft'){
                                                    //отправка с ошибками
                                                    if(result.currentMessage){
                                                        initErrorMess(result.currentMessage);
                                                    }
                                                }else{
                                                    //успешная отправка
                                                    let param = {
                                                        saveParam:{
                                                            number:model.bankGuarantee.purchaseNumber,
                                                            date:$scope.modelView.created
                                                        }
                                                    }
                                                    // Под агентом сохраняем признак выбранной анкеты участником
                                                    if ($scope.modelView.isAgent) {
                                                        param.participantSelected = $scope.modelView.participantSelected;
                                                        param.participantInfo = $scope.modelView.participantInfo;
                                                    }
                                                    $state.go('bankGuarantees', param);
                                                }
                                            } else {
                                                $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Ошибка!');
                                                console.log(response)
                                            }
                                        }, function (reason) {
                                            $scope.modelView.doingSaveAndSend = false;
                                            console.log(reason);
                                        })
                                    }else {
                                        $scope.modelView.doingSaveAndSend = false;
                                    }
                                })
                            },
                            function (reason) {
                                if (typeof reason === 'string') {
                                    $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg("Ошибка подписи документа. " + reason);

                                } else {
                                    $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                                }
                                $scope.modelView.doingSaveAndSend = false;
                                console.log(reason)
                            }
                        );
                    }

                    /*
                    *  Сообщение о незаполенном поле
                    */
                    function showRequireFieldErrorMessage(form,tab){
                        $scope.submitted = true;
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsgWithBtn('Необходимо заполнить обязательные поля на форме. ', 'Перейти',$scope.focusFirstInvalid,
                            {form:form, tab:tab});
                        $scope.modelView.doingSaveAndSend = false;
                    }
                }
                /*
                * Отобразить сообщение об ошибках
                * */
                function initErrorMess(data) {
                    var currentMessage, mess = '<br>';
                    try {
                        currentMessage = JSON.parse(data);
                        angular.forEach(currentMessage, function (value, key) {
                            mess += (key + ": " + value + '<br>');
                        });
                    } catch {
                        currentMessage = data.split(';');
                        if (typeof currentMessage === 'object') {
                            angular.forEach(currentMessage, function (value) {
                                mess += value + '<br>';
                            });
                        } else {
                            mess = data;
                        }
                    }
                    if (mess.length > 0) {
                        var errorMsg = 'В анкете обнаружены ошибки. Ознакомьтесь с ними, исправьте и повторно отправьте анкету на получение банковской гарантии.<br>';
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg(errorMsg + mess);
                    }
                }

                /*
                * Получить согласие
                * null если не все поля
                * */
                function getAgreement(model) {
                    //проверка заполненых обязательных полей для согласия и галка согласия (modelView.agreementCheck)
                    if (model.principal && model.principal.principalSigner && model.principal.principalSigner.lastName &&
                        model.principal.principalSigner.firstName && model.principal.principalSigner.secondName &&
                        model.principal.principalSigner.registrationAddress && model.principal.principalSigner.identityDocument &&
                        model.principal.principalSigner.identityDocument.issuingAuthority &&
                        model.principal.principalSigner.identityDocument.issuingAuthorityCode &&
                        model.principal.principalSigner.identityDocument.number &&
                        model.principal.principalSigner.identityDocument.series &&
                        model.principal.principalSigner.identityDocument.issuedDate) {
                        return {
                            signerName: model.principal.principalSigner.lastName + ' ' + model.principal.principalSigner.firstName + ' ' + model.principal.principalSigner.secondName,
                            signerAddress: model.principal.principalSigner.registrationAddress,
                            signerPassport: model.principal.principalSigner.identityDocument.number
                        }
                    } else {
                        $scope.modelView.agreementCheck = false;
                        return null;
                    }
                }

                function checkAgreementFn() {
                    var model = bankGuaranteeSrv.getData();
                    //проверка заполненых обязательных полей для согласия
                    if (model.principal && model.principal.principalSigner && model.principal.principalSigner.lastName &&
                        model.principal.principalSigner.firstName && model.principal.principalSigner.secondName &&
                        model.principal.principalSigner.registrationAddress && model.principal.principalSigner.identityDocument &&
                        model.principal.principalSigner.identityDocument.issuingAuthority &&
                        model.principal.principalSigner.identityDocument.issuingAuthorityCode &&
                        model.principal.principalSigner.identityDocument.number &&
                        model.principal.principalSigner.identityDocument.series &&
                        model.principal.principalSigner.identityDocument.issuedDate) {
                        return true
                    } else {
                        $scope.modelView.agreementCheck = false;
                        return false;
                    }
                }


                function showDecisionFn() {
                    return $scope.modelView.taskDefinitionKey &&
                    ($scope.modelView.taskDefinitionKey.indexOf('UserTaskInitiatorRework')>=0 || $scope.modelView.taskDefinitionKey == 'UserTaskInitiatorInput')&&
                    $scope.modelView.decisionOptions && $scope.modelView.decisionOptions.length>0;
                }
                /**
                 * Удалить элемент из таблицы
                 * Удалить вложение
                 * Если есть type необходимо восстановить отображение элемента в списке
                 * @param model - модель
                 * @param index - номер строки для удаления
                 * @param type - тип документа
                 */
                function delRowFn(model, index, type) {
                    model.splice(index, 1);
                    if(type){
                        angular.forEach($scope.modelView.dopDocumentList, function (doc) {
                            if(doc.type === type){
                                doc.isShow = true;
                            }
                        })
                    }
                }

                /*
                * Получить модель для сохранения
                * */
                function getModelFromSave(model) {
                    if (model.principal) {
                        /*
                        * * Преобразуем дату в формат yyyy-MM-dd и удаляем пустые поля из модели
                        * */
                        convertDateAndEmptyFields(model.principal);
                        convertDateAndEmptyFields(model.bankGuarantee);
                        if(model.principal.inn && model.principal.inn.length!==10){
                            //оставляем кпп только у ЮЛ
                            delete model.principal.kpp;
                        }
                        /*
                        * Банк
                        * */
                        if (model.principal.bankAccounts && model.principal.bankAccounts.bank) {
                            var bankObj = {
                                bik: model.principal.bankAccounts.bank.bic,
                                corrNumber: model.principal.bankAccounts.bank.accounts,
                                name: model.principal.bankAccounts.bank.participant
                            };
                            delete model.principal.bankAccounts.bank;
                            model.principal.bankAccounts.bank = bankObj;
                        }
                        if (model.principal.addressesUr && model.principal.addressesUr.rent && model.principal.addressesUr.rent.landlordBankReq) {
                            var bankObj = {
                                bik: model.principal.addressesUr.rent.landlordBankReq.bic,
                                corrNumber: model.principal.addressesUr.rent.landlordBankReq.accounts,
                                name: model.principal.addressesUr.rent.landlordBankReq.participant
                            };
                            delete model.principal.addressesUr.rent.landlordBankReq;
                            model.principal.addressesUr.rent.landlordBankReq = bankObj;
                        }
                        if (model.principal.addressesFac && model.principal.addressesFac.rent && model.principal.addressesFac.rent.landlordBankReq) {
                            var bankObj = {
                                bik: model.principal.addressesFac.rent.landlordBankReq.bic,
                                corrNumber: model.principal.addressesFac.rent.landlordBankReq.accounts,
                                name: model.principal.addressesFac.rent.landlordBankReq.participant
                            };
                            delete model.principal.addressesFac.rent.landlordBankReq;
                            model.principal.addressesFac.rent.landlordBankReq = bankObj;
                        }
                        if (model.principal.addressesPost && model.principal.addressesPost.rent && model.principal.addressesPost.rent.landlordBankReq) {
                            var bankObj = {
                                bik: model.principal.addressesPost.rent.landlordBankReq.bic,
                                corrNumber: model.principal.addressesPost.rent.landlordBankReq.accounts,
                                name: model.principal.addressesPost.rent.landlordBankReq.participant
                            };
                            delete model.principal.addressesPost.rent.landlordBankReq;
                            model.principal.addressesPost.rent.landlordBankReq = bankObj;
                        }
                        /*
                        Преобразуем:
                        model.principal.founders c типом foundersCompanies -> model.principal.founders.foundersCompanies
                        model.principal.founders с типом foundersPersons -> model.principal.founders.foundersPersons
                        * */
                        if (model.principal.founders) {
                            var founders = {};
                            angular.forEach(model.principal.founders, function (item) {
                                var data = angular.copy(item);
                                delete data.foundersTemp;
                                if (!founders[item.foundersTemp.type]) founders[item.foundersTemp.type] = [];
                                founders[item.foundersTemp.type].push(data);
                            });
                            delete model.principal.founders;
                            model.principal.founders = founders;
                        }
                        /*
                        Преобразуем:
                        model.principal.collegialExecutiveBodyMember -> model.principal.companyManagement.collegialExecutiveBodyMember
                        model.principal.supervisoryBoardMember -> model.principal.companyManagement.supervisoryBoardMember
                        model.principal.principalBuhPeriods -> model.principal.principalBuh.periods;
                        * */
                        if (model.principal.collegialExecutiveBodyMember) {
                            if (!model.principal.companyManagement) model.principal.companyManagement = {};
                            model.principal.companyManagement.collegialExecutiveBodyMember = model.principal.collegialExecutiveBodyMember
                            delete model.principal.collegialExecutiveBodyMember;
                        }
                        if (model.principal.supervisoryBoardMember) {
                            if (!model.principal.companyManagement) model.principal.companyManagement = {};
                            model.principal.companyManagement.supervisoryBoardMember = model.principal.supervisoryBoardMember
                            delete model.principal.supervisoryBoardMember;
                        }
                        if (model.principal.principalBuhPeriods) {
                            if (!model.principal.principalBuh) model.principal.principalBuh = {};
                            model.principal.principalBuh.periods = {};
                            angular.forEach(model.principal.principalBuhPeriods, function (item) {
                                //заполняем период если есть дата периода
                                if (item.date) {
                                    model.principal.principalBuh.periods[item.date] = {};
                                    angular.forEach(item.list, function (value, key) {
                                        model.principal.principalBuh.periods[item.date][key] = value;
                                    })
                                }
                            });
                            delete model.principal.principalBuhPeriods;
                        }
                        /*
                       Преобразуем:
                       model.principal.beneficiaries -> model.bankGuarantee.beneficiaries
                       model.principal.bails -> model.bankGuarantee.bails
                       */
                        if(!model.bankGuarantee) model.bankGuarantee = {};
                        if (model.principal.beneficiaries) {
                            model.bankGuarantee.beneficiaries = model.principal.beneficiaries;
                            delete model.principal.beneficiaries;
                        }
                        if (model.principal.bails) {
                            model.bankGuarantee.bails = model.principal.bails;
                            delete model.principal.bails;
                        }
                        /*
                        * Оставляем в списке okpd только name и code
                        * */
                        if(model.bankGuarantee.okpd){
                            angular.forEach(model.bankGuarantee.okpd, function (item) {
                                angular.forEach(item, function (value, key) {
                                    if(!(key==='code' || key==='name')) delete item[key]
                                })
                            })
                        }
                        if(model.bankGuarantee.guaranteeReceivingWay==='dhl' && model.bankGuarantee.gettingPhone) {
                            if(model.bankGuarantee.gettingPhone && !model.bankGuarantee.gettingPhone.contains("(")) {
                                const phone = model.bankGuarantee.gettingPhone.replaceAll(' ', '');
                                model.bankGuarantee.gettingPhone = '('+phone.substring(0, 3)+')'+ phone.substring(3, 6)+'-'+ phone.substring(6,8)+'-'+ phone.substring(8);
                            }
                            model.bankGuarantee.gettingPhone = ('+7' + model.bankGuarantee.gettingPhone.replaceAll(' ', ''));
                        }

                        /*
                        * Приводим контакты
                        * */
                        if (model.principal.companyBeneficiaries && model.principal.companyBeneficiaries.length > 0) {
                            angular.forEach(model.principal.companyBeneficiaries, function (item) {
                                contractToArray(item);
                            })
                        }
                        if (model.principal.companyHead)
                            contractToArray(model.principal.companyHead);
                        if (model.principal.accountant)
                            contractToArray(model.principal.accountant);
                        if (model.principal.companyManagement && model.principal.companyManagement.collegialExecutiveBodyMember && model.principal.companyManagement.collegialExecutiveBodyMember.length > 0) {
                            angular.forEach(model.principal.companyManagement.collegialExecutiveBodyMember, function (item) {
                                contractToArray(item);
                            })
                        }
                        if (model.principal.companyManagement && model.principal.companyManagement.supervisoryBoardMember && model.principal.companyManagement.supervisoryBoardMember.length > 0) {
                            angular.forEach(model.principal.companyManagement.supervisoryBoardMember, function (item) {
                                contractToArray(item);
                            })
                        }
                        if (model.principal.founders && model.principal.founders.foundersPersons && model.principal.founders.foundersPersons.length > 0) {
                            angular.forEach(model.principal.founders.foundersPersons, function (item) {
                                contractToArray(item);
                            })
                        }
                        if (model.bankGuarantee.bails && model.bankGuarantee.bails.length > 0) {
                            angular.forEach(model.bankGuarantee.bails, function (item) {
                                if (item.bailType === 'company')
                                    contractToArray(item.signer);
                            })
                        }
                        if (model.principal.principalSigner)
                            contractToArray(model.principal.principalSigner);
                        /*
                        * Преобразуем адрес два объекта в один массив
                        * */
                        if(model.principal.addressesUr || model.principal.addressesFac || model.principal.addressesPost)
                            model.principal.addresses = [];
                        if (model.principal.addressesUr) {
                            model.principal.addressesUr.addressType = 'legal';
                            model.principal.addresses.push(model.principal.addressesUr);
                            delete model.principal.addressesUr;
                        }
                        if (model.principal.addressesFac) {
                            model.principal.addressesFac.addressType = 'actual';
                            model.principal.addresses.push(model.principal.addressesFac);
                            delete model.principal.addressesFac;
                        }
                        if (model.principal.addressesPost) {
                            model.principal.addressesPost.addressType = 'postal';
                            model.principal.addresses.push(model.principal.addressesPost);
                            delete model.principal.addressesPost;
                        }
                        /*
                        * Преобразуем bankAccounts obj to array
                        * */
                        if (model.principal.bankAccounts) {
                            var obj = angular.copy(model.principal.bankAccounts);
                            delete model.principal.bankAccounts;
                            model.principal.bankAccounts = [obj];
                        }

                        if (model.bankGuarantee.ikzList) {
                            const obj = angular.copy(model.bankGuarantee.ikzList);
                            delete model.bankGuarantee.ikzList;
                            model.bankGuarantee.ikzList = [obj];
                        }

                        if (!$scope.modelView.isPrimary) {
                            /*
                            * Убираем лишние поля из companyInfo.
                            * */
                            if (!model.principal.companyInfo.hasBeneficiariesInfo) {
                                delete model.principal.companyInfo.beneficiariesInfo;
                            }
                            if (!model.principal.companyInfo.isHolding) {
                                delete model.principal.companyInfo.holdingName;
                            }
                            if (!model.principal.companyInfo.hasRating) {
                                delete model.principal.companyInfo.ratingAgency;
                                delete model.principal.companyInfo.rating;
                            }
                            if (!model.principal.companyInfo.overdueDebt) {
                                delete model.principal.companyInfo.overdueDebtAmount;
                            }
                            if (!model.principal.companyInfo.debtToBudget) {
                                delete model.principal.companyInfo.debtToBudgetAmount;
                            }
                            if (!model.principal.companyInfo.regBefore01072002) {
                                delete model.principal.companyInfo.regNumberBefore01072002;
                            }
                            if (!model.principal.companyInfo.hasSalariesDebt && model.principal.staffInfo) {
                                delete model.principal.staffInfo.staffDebts;
                            }
                            /*
                            * Убираем лишние поля из companyManagement при отсутствии соответствующих галочек
                            * */
                            if (model.principal.companyManagement) {
                                hasCollegialExecutiveBodyExtraFields(model.principal.companyManagement.hasCollegialExecutiveBody);
                                hasSupervisoryBoardExtraFields(model.principal.companyManagement.hasSupervisoryBoard);
                                managementCompanyExtraFields(model.principal.companyManagement.soleExecutiveBody);
                            }
                            if (!model.bankGuarantee.prepaymentExists) {
                                delete model.bankGuarantee.prepaymentAmount;
                            }
                            if (!model.bankGuarantee.isContractConcluded) {
                                delete model.bankGuarantee.tenderContractSubject;
                                delete model.bankGuarantee.contractType;
                                delete model.bankGuarantee.tenderContractRegNumber;
                                delete model.bankGuarantee.tenderContractNumber;
                                delete model.bankGuarantee.tenderContractAmount;
                                delete model.bankGuarantee.tenderContractSignedDate;
                                delete model.bankGuarantee.assessmentProtocolDate;
                                delete model.bankGuarantee.assessmentProtocolName;
                                delete model.bankGuarantee.assessmentProtocolNumber;
                                delete model.bankGuarantee.isBigDeal;
                                delete model.bankGuarantee.similarContractsNumber;
                            }
                            if(!isShowIkzListFn())
                                delete model.bankGuarantee.ikzList;

                        }
                    }
                    /*
                    * Проставляем в заявку обновленный список документов
                    * */
                    var documents = getDocumentFromSave(model);
                    delete model.documents;
                    delete model.dopDocuments;
                    model.documents = documents;
                    return model;

                    /*
                    * Преобразуем дату в формат yyyy-MM-dd и удаляем пустые поля из модели
                    * */
                    function convertDateAndEmptyFields(data) {
                        angular.forEach(data, function (value, key) {
                            if (value instanceof Date) {
                                data[key] = $filter('date')(value, 'yyyy-MM-dd');
                            } else if (value === '' || value === null || angular.isUndefined(value)) {
                                delete data[key];
                            } else if (value instanceof Object) {
                                convertDateAndEmptyFields(value);
                            }
                        })
                    }

                    /*
                    * Преобразуем объект в массив с одним элементом
                    * */
                    function contractToArray(data) {
                        if (data.contacts) {
                            var obj = angular.copy(data.contacts);
                            delete data.contacts;
                            data.contacts = [obj];
                        }
                    }

                    function managementCompanyExtraFields(data) {
                        if (data != 'managementCompany') {
                            delete model.principal.companyManagement.managementCompany;
                            delete $scope.model.principal.companyManagement.managementCompany;
                        }
                    }

                    function hasCollegialExecutiveBodyExtraFields(data) {
                        if (!data) {
                            delete model.principal.companyManagement.collegialExecutiveBodyName;
                            delete model.principal.companyManagement.supervisoryBoardName;
                            delete model.principal.collegialExecutiveBodyMember;
                            delete $scope.model.principal.companyManagement.collegialExecutiveBodyName;
                            delete $scope.model.principal.companyManagement.supervisoryBoardName;
                            delete $scope.model.principal.collegialExecutiveBodyMember;
                            delete model.principal.companyManagement.collegialExecutiveBodyMember;
                        }
                    }

                    function hasSupervisoryBoardExtraFields(data) {
                        if (!data) {
                            delete model.principal.supervisoryBoardMember;
                            delete $scope.model.principal.supervisoryBoardMember;
                            delete model.principal.companyManagement.supervisoryBoardMember;
                        }
                    }
                }

                /*
                * Получить модель для отображения
                * */
                function getModelFromView(model) {
                    if (model.principal) {
                        /*
                        Преобразуем:
                        model.principal.founders.foundersCompanies -> model.principal.founders c типом foundersCompanies
                        model.principal.founders.foundersPersons -> model.principal.founders с типом foundersPersons
                        * */
                        if (model.principal.founders) {
                            var foundersCompanies = angular.copy(model.principal.founders.foundersCompanies);
                            var foundersPersons = angular.copy(model.principal.founders.foundersPersons);
                            delete model.principal.founders;
                            model.principal.founders = [];
                            angular.forEach(foundersCompanies, function (item) {
                                item.foundersTemp = {type: 'foundersCompanies'};
                                model.principal.founders.push(item)
                            });
                            angular.forEach(foundersPersons, function (item) {
                                item.foundersTemp = {type: 'foundersPersons'};
                                model.principal.founders.push(item)
                            });
                        }
                        if (model.principal.founders && model.principal.founders.foundersCompanies) {
                            model.principal.foundersCompanies = model.principal.founders.foundersCompanies;
                            delete model.principal.founders.foundersCompanies;
                        }
                        if (model.principal.founders && model.principal.founders.foundersPersons) {
                            model.principal.foundersPersons = model.principal.founders.foundersPersons;
                            delete model.principal.founders.foundersPersons;
                        }
                        /*
                        * Приводим контакты
                        * */
                        if (model.principal.companyBeneficiaries && model.principal.companyBeneficiaries.length > 0) {
                            angular.forEach(model.principal.companyBeneficiaries, function (item) {
                                contractsToObj(item);
                            })
                        }
                        if (model.principal.companyHead)
                            contractsToObj(model.principal.companyHead);
                        if (model.principal.accountant)
                            contractsToObj(model.principal.accountant);
                        if (model.principal.companyManagement && model.principal.companyManagement.collegialExecutiveBodyMember && model.principal.companyManagement.collegialExecutiveBodyMember.length > 0) {
                            angular.forEach(model.principal.companyManagement.collegialExecutiveBodyMember, function (item) {
                                contractsToObj(item);
                            })
                        }
                        if (model.principal.companyManagement && model.principal.companyManagement.supervisoryBoardMember && model.principal.companyManagement.supervisoryBoardMember.length > 0) {
                            angular.forEach(model.principal.companyManagement.supervisoryBoardMember, function (item) {
                                contractsToObj(item);
                            })
                        }
                        if (model.principal.founders && model.principal.founders.length > 0) {
                            angular.forEach(model.principal.founders, function (item) {
                                if (item.foundersTemp.type === 'foundersPersons')
                                    contractsToObj(item);
                            })
                        }
                        if (model.principal.principalSigner)
                            contractsToObj(model.principal.principalSigner);
                        /*
                        * Преобразуем адрес массив в два объекта
                        * */
                        if (model.principal.addresses && model.principal.addresses.length > 0) {
                            var obj = angular.copy(model.principal.addresses);
                            delete model.principal.addresses;
                            angular.forEach(obj, function (item) {
                                if(item.addressType ==='legal'){
                                    model.principal.addressesUr = item;
                                }else if(item.addressType ==='actual'){
                                    model.principal.addressesFac = item;
                                }else if(item.addressType ==='postal'){
                                    model.principal.addressesPost = item;
                                }
                            })
                        }
                        /*
                        * Преобразуем bankAccounts array to obj
                        * */
                        if (model.principal.bankAccounts && model.principal.bankAccounts.length > 0) {
                            var obj = angular.copy(model.principal.bankAccounts[0]);
                            delete model.principal.bankAccounts;
                            model.principal.bankAccounts = obj;
                            /*
                            * Банк
                            * */
                            if (model.principal.bankAccounts && model.principal.bankAccounts.bank) {
                                var bankObj = {
                                    bic: model.principal.bankAccounts.bank.bik,
                                    accounts: model.principal.bankAccounts.bank.corrNumber,
                                    participant: model.principal.bankAccounts.bank.name
                                };
                                delete model.principal.bankAccounts.bank;
                                model.principal.bankAccounts.bank = bankObj;
                            }
                            if (model.principal.addressesUr && model.principal.addressesUr.rent && model.principal.addressesUr.rent.landlordBankReq) {
                                var bankObj = {
                                    bic: model.principal.addressesUr.rent.landlordBankReq.bik,
                                    accounts: model.principal.addressesUr.rent.landlordBankReq.corrNumber,
                                    participant: model.principal.addressesUr.rent.landlordBankReq.name
                                };
                                delete model.principal.addressesUr.rent.landlordBankReq;
                                model.principal.addressesUr.rent.landlordBankReq = bankObj;
                            }
                            if (model.principal.addressesFac && model.principal.addressesFac.rent && model.principal.addressesFac.rent.landlordBankReq) {
                                var bankObj = {
                                    bic: model.principal.addressesFac.rent.landlordBankReq.bik,
                                    accounts: model.principal.addressesFac.rent.landlordBankReq.corrNumber,
                                    participant: model.principal.addressesFac.rent.landlordBankReq.name
                                };
                                delete model.principal.addressesFac.rent.landlordBankReq;
                                model.principal.addressesFac.rent.landlordBankReq = bankObj;
                            }
                            if (model.principal.addressesPost && model.principal.addressesPost.rent && model.principal.addressesPost.rent.landlordBankReq) {
                                var bankObj = {
                                    bic: model.principal.addressesPost.rent.landlordBankReq.bik,
                                    accounts: model.principal.addressesPost.rent.landlordBankReq.corrNumber,
                                    participant: model.principal.addressesPost.rent.landlordBankReq.name
                                };
                                delete model.principal.addressesPost.rent.landlordBankReq;
                                model.principal.addressesPost.rent.landlordBankReq = bankObj;
                            }
                        }

                        /*
                        Преобразуем:
                        model.principal.companyManagement.collegialExecutiveBodyMember -> model.principal.collegialExecutiveBodyMember
                        model.principal.companyManagement.supervisoryBoardMember -> model.principal.supervisoryBoardMember
                        model.principal.principalBuh.periods -> model.principal.principalBuhPeriods -> model.principal.principalBuh.periods;
                        * */
                        if (model.principal.companyManagement && model.principal.companyManagement.collegialExecutiveBodyMember) {
                            model.principal.collegialExecutiveBodyMember = model.principal.companyManagement.collegialExecutiveBodyMember;
                            delete model.principal.companyManagement.collegialExecutiveBodyMember;
                        }
                        if (model.principal.companyManagement && model.principal.companyManagement.supervisoryBoardMember) {
                            model.principal.supervisoryBoardMember = model.principal.companyManagement.supervisoryBoardMember;
                            delete model.principal.companyManagement.supervisoryBoardMember;
                        }
                        if (model.principal.principalBuh) {
                            model.principal.principalBuhPeriods = [];
                            angular.forEach(model.principal.principalBuh.periods, function (value, key) {
                                var list = {};
                                angular.forEach(value, function (item, itemKey) {
                                    list[itemKey] = item;
                                });
                                model.principal.principalBuhPeriods.push({date: key, list: list})
                            });
                            /*
                            * Если не заполнен 1 или 2 периуда - проставляем пустые значения
                            * Периодов должно быть всегда 2
                            * */
                            if(model.principal.principalBuhPeriods.length===0){
                                model.principal.principalBuhPeriods.push({});
                                model.principal.principalBuhPeriods.push({});
                            }else if(model.principal.principalBuhPeriods.length===1){
                                model.principal.principalBuhPeriods.push({});
                            }
                            delete model.principal.principalBuh.periods;
                        }
                    }
                    if(model.bankGuarantee){

                        // доп.форматирование телефона
                        if(model.bankGuarantee.guaranteeReceivingWay==='dhl' && model.bankGuarantee.gettingPhone && model.bankGuarantee.gettingPhone.startsWith('+7')) {
                            const phone = model.bankGuarantee.gettingPhone.replaceAll('+7', '');
                            model.bankGuarantee.gettingPhone = phone.substring(0, 5) + ' ' + phone.substring(5);
                        }
                        /*
                       Преобразуем:
                       model.bankGuarantee.beneficiaries -> model.principal.beneficiaries
                       model.bankGuarantee.bails -> model.principal.bails
                       */


                       if (model.bankGuarantee.beneficiaries ) {
                           model.principal.beneficiaries = model.bankGuarantee.beneficiaries;
                           delete model.bankGuarantee.beneficiaries;
                           }
                           if (model.bankGuarantee.bails ) {
                               model.principal.bails = model.bankGuarantee.bails;
                               delete model.bankGuarantee.bails;
                           }
                        if (model.principal.bails && model.principal.bails.length > 0) {
                            angular.forEach(model.principal.bails, function (item) {
                                if (item.bailType === 'company')
                                    contractsToObj(item.signer);
                            })
                        }
                        if (model.bankGuarantee.ikzList && model.bankGuarantee.ikzList.length > 0) {
                            const obj = angular.copy(model.bankGuarantee.ikzList[0]);
                            delete model.bankGuarantee.ikzList;
                            model.bankGuarantee.ikzList = obj;
                        }
                   }
                   return model;
                   /*
                   * Преобразуем массив в объект (в массиве всегда один контракт)
                   * */
                    function contractsToObj(data) {
                        if (data.contacts && data.contacts.length > 0) {
                            var obj = angular.copy(data.contacts[0]);
                            delete data.contacts;
                            data.contacts = obj;
                        }
                    }
                }
                
                function changeStateFn(){
                	bankGuaranteeSrv.changeState($stateParams.id).then(
                	 function (response) {
                        if(response.data && response.data.success) {
                        	$scope.alertObjbankGuaranteeApp = alertSrv.getSuccessMsg('Статус изменен.');
                        }else {
                        	$scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Ошибка при изменении статуса!');
                        	console.log(response);
                        }
                    }, function (reason) {
                    	$scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Ошибка при изменении статуса!');
                        console.log(reason);
                    })
                }

                function assignAgentFn(){
                    if($scope.modelView.agent){
                        const agent = $scope.agentsList.find(a => a.id == $scope.modelView.agent);
                        if(agent){
                            bankGuaranteeSrv.assignAgent($stateParams.id, agent).then(
                                function (response) {
                                    if(response.data && response.data.success) {
                                        $scope.alertObjbankGuaranteeApp = alertSrv.getSuccessMsg('Агент назначен.');
                                    }else {
                                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Ошибка при назначении агента!');
                                        console.log(response);
                                    }
                                }, function (reason) {
                                    $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Ошибка при назначении агента!');
                                    console.log(reason);
                                })
                        }
                    }

                }
            }]
    });
