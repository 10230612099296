/**
 * @author dmitrys
 */
angular.module('uetp').service('bankGuaranteeSrv', ['$state', 'httpSrv', 'cryptography', 'authSrv','$filter',
    function ($state, httpSrv, cryptography, authSrv,$filter) {
        var service = this;
        // Сообщения для подтверждения перехода назад
        const CONFIRM_TEXT_BANKGUARANTEE_CREATE = 'Вы уверены, что хотите покинуть эту страницу?\nВведённые данные могут не сохраниться.';
        const CONFIRM_TEXT_BANKGUARANTEE_VIEW = 'Вы уверены, что хотите покинуть эту страницу?';
        /*
        * Данные анкеты
        * */
        service.data = {
            principal:{}
        };
        /**
         * Состояние анкеты
         */
        service.stateApp = {
            isPrimary:false, // Primary анкета
            isAppCreate:false, // Форма создания
            isAppOpen:false, // Форма редактирования
            isAppCopy:false, // Форма создания копии
            isAppAgent:false, // Форма агента
            isReadOnly:false, // Форма просмотра
        }
        /*
        * Активная вкладка
        * */
        service.activeTab = 'bankGuarantee';
        /*
        * Активный уровень
        * table - форма таблицы
        * app - форма заявки
        * documents - форма с документами
        * */
        service.activeLevel = 'app';
        /*
        * Поля для связывания формы анкеты и формы таблицы
        * data - данные для таблицы (пустое когда новая запись)
        * type - тип формы таблицы
        * isNew - новая запись таблицы
        * */
        service.tableForm = {
            data:null,
            type:null,
            isNew:false
        };
        service.dictRemote = null;
        service.dict = {
            /*
               * Основания наличия статуса
               * */
            beneficiaryReason: [
                {code:'controlOfActions', name:'Возможность контролировать действия Клиента'},
                {code:'directCapitalParticipation', name:'Прямое участие в капитале Клиента'},
                {code:'indirectCapitalParticipation', name:'Косвенное участие в капитале Клиента'},
                {code:'companyHead', name:'Единоличный исполнительный орган юридического лица'}
            ],
            /*
            * Основание полномочий
            * */
            authorizingDoc:[
                {code: 'order', name: 'Приказ о назначении'},
                {code: 'decision', name: 'Решение о назначении'},
                {code: 'protocol', name: 'Протокол'},
                {code: 'other', name: 'Другое'}
            ],
            /*
               * Цели установления и предполагаемый характер деловых отношений с Банком
               * */
            bankBusinessRelationsType: [
                {code: 'obtainingCredit', name: 'Получение кредита'},
                {code: 'bankClientService', name: 'Обслуживание с использованием системы "Банк-клиент"'},
                {code: 'obtainingBankGuarantee', name: 'Получение банковской гарантии'},
                {code: 'cashServices', name: 'Расчетно-кассовое обслуживание'},
                {code: 'other', name: 'Иное'}
            ],
            /*
            * Кредитная история
            * */
            creditHistory: [
                {code: 'noHistory', name: 'Кредитная история отсутствует'},
                {code: 'noDelays', name: 'Задолженность погашалась без просрочек'},
                {code: 'wereDelays', name: 'Допускалась задержка платежа не более чем на 5 дней'}
            ],
            /*
            * Продукт
            * */
            productType:[
                {code:'overdraft', name:'Овердрафт'},
                {code:'nonRevolvingCreditLine', name:'Невозобновляемая кредитная линия'},
                {code:'revolvingCreditLine', name:'Возобновляемая кредитная линия'},
                {code:'leasing', name:'Лизинг'},
                {code:'guarantee', name:'Гарантия'},
                {code:'loan', name:'Кредит'},
                {code:'oneTimeCredit', name:'Коммерческий кредит'},
                {code:'factoring', name:'Факторинг'}
            ],
            /*
            * Валюта
            * */
            currency:[
                {code:'840', name:'Доллар США'},
                {code:'810', name:'Рубль'},
                {code:'978', name:'Евро'}
            ],
            /*
            * Цели финансово-хозяйственной деятельности организации
            * */
            financialAndEconomicActivityType: [
                {code: 'publicProjects', name: 'Реализация общественных проектов'},
                {code: 'financing', name: 'Финансирование'},
                {code: 'salesIncrease', name: 'Увеличение объемов продаж'},
                {
                    code: 'profitFromMainActivity',
                    name: 'Извлечение/получение прибыли от основной хозяйственной деятельности'
                },
                {code: 'productProviding', name: 'Обеспечение потребителей продукцией'},
                {code: 'other', name: 'Иное'}
            ],
            /*
            * Оценка финансового положения клиента
            * */
            financialPosition: [
                {
                    code: 'Precarious',
                    name: 'Нестабильное финансовое положение, наличие положительного и отрицательного финансового результата в течение последнего налогового периода'
                },
                {
                    code: 'Stable',
                    name: 'Стабильное финансовое положение, постоянный положительный финансовый результат в течение последнего налогового периода'
                },
                {code: 'Poor', name: 'Плохое финансовое положение, отрицательный финансовый результат'},
                {
                    code: 'Missing',
                    name: 'Данные о финансовом положении отсутствуют (с момента государственной регистрации прошло менее 3 месяцев)'
                }
            ],
            /*
            * Наличие отзывов контрагентов
            * Наличие отзывов кредитных организаций, ранее обслуживавших Клиента, с информацией об оценке деловой репутации
            * */
            financialReviews: [
                {code: 'attached', name: 'Имеются и приложены (необходимо прикрепить файл)'},
                {code: 'missing', name: 'Не предоставлены в связи с невозможностью их получения'}
            ],
            /*
            * Сведения об источниках происхождения денежных средств
            * */
            fundsSources: [
                {code: 'borrow', name: 'Заёмные средства'},
                {code: 'own', name: 'Собственные средства'},
                {code: 'other', name: 'Иное'}
            ],
            /*
            * Тип собственности
            * */
            ownershipFormGrouping: [
                {code: 'FederalOwnership', name: 'Организация, находящаяся в федеральной собственности'},
                {
                    code: 'GovernmentOwnership',
                    name: 'Организация, находящаяся в государственной (кроме федеральной) собственности'
                },
                {code: 'NonGovernmentOwnership', name: 'Негосударственная организация'}
            ],
            /*
            * Тип организации (согласно типа собственности)
            * */
            ownershipForm: [
                {code: 'FinancialOrganization', name: 'Финансовая организация'},
                {code: 'CommercialOrganization', name: 'Коммерческая организация'},
                {code: 'NonCommercialOrganization', name: 'Некоммерческая организация'}
            ],
            /*
            * Квартал
            * */
            latestPeriod: [
                {code: '2018-12', name: 'IV кв 2018',   edit: false},
                {code: '2019-03', name: 'I кв 2019',    edit: false},
                {code: '2019-06', name: 'II кв 2019',   edit: false},
                {code: '2019-09', name: 'III кв 2019',  edit: false},
                {code: '2019-12', name: 'IV кв 2019',   edit: false},
                {code: '2020-03', name: 'I кв 2020',    edit: false},
                {code: '2020-06', name: 'II кв 2020',   edit: false},
                {code: '2020-09', name: 'III кв 2020',  edit: false},
                {code: '2020-12', name: 'IV кв 2020',   edit: false},
                {code: '2021-03', name: 'I кв 2021',    edit: false},
                {code: '2021-06', name: 'II кв 2021',   edit: false},
                {code: '2021-09', name: 'III кв 2021',  edit: false},
                {code: '2021-12', name: 'IV кв 2021',   edit: true},
                {code: '2022-03', name: 'I кв 2022',    edit: true},
                {code: '2022-06', name: 'II кв 2022',   edit: true},
                {code: '2022-09', name: 'III кв 2022',  edit: true},
                {code: '2022-12', name: 'IV кв 2022',   edit: true},
                {code: '2023-03', name: 'I кв 2023',    edit: true},
                {code: '2023-06', name: 'II кв 2023',   edit: true},
                {code: '2023-09', name: 'III кв 2023',  edit: true},
                {code: '2023-12', name: 'IV кв 2023',   edit: true}
            ],
            /*
            * Единоличный исполнительный орган
            * */
            soleExecutiveBody: [
                {code: 'companyHead', name: 'Руководитель'},
                {code: 'managementCompany', name: 'Управляющая компания'}
            ],
            /*
            * Решения (форма диалога с банком)
            * */
            decisionOptions : [
                {
                    code: 'SEND_TO_BANK',
                    name: 'Отправить в Банк'
                },
                {
                    code: 'ACCEPT',
                    name: 'Согласовано'
                },
                {
                    code: 'RESEND',
                    name: 'Повторно отправить на подпись'
                },
                {
                    code: 'REWORK',
                    name: 'Отправить на доработку'
                },
                {
                    code: 'REJECT',
                    name: 'Отказ от гарантии'
                },
                {
                    code: 'CANCEL',
                    name: 'Отмена заявки'
                },
                {
                    code: 'NOCHANGE',
                    name: 'Согласовано (без изменений)'
                },
                {
                    code: 'RECHECK',
                    name: 'Предложить правки в форме гарантии'
                }
            ],
            /*
            * Гражданство и Страна проживания
            * */
            countrys:[
                "AFG", "ALB", "ATA", "DZA", "ASM", "AND", "AGO", "ATG", "AZE", "ARG", "AUS", "AUT", "BHS", "BHR", "BGD", "ARM", "BRB", "BEL", "BMU", "BTN", "BOL", "BIH", "BWA", "BVT", "BRA", "BLZ", "IOT", "SLB", "VGB", "BRN", "BGR", "MMR", "BDI", "BLR", "KHM", "CMR", "CAN", "CPV", "CYM", "CAF", "LKA", "TCD", "CHL", "CHN", "TWN", "CXR", "CCK", "COL", "COM", "MYT", "COG", "COD", "COK", "CRI", "HRV", "CUB", "CYP", "CZE", "BEN", "DNK", "DMA", "DOM", "ECU", "SLV", "GNQ", "ETH", "ERI", "EST", "FRO", "FLK", "SGS", "FJI", "FIN", "ALA", "FRA", "GUF", "PYF", "ATF", "DJI", "GAB", "GEO", "GMB", "PSE", "DEU", "GHA", "GIB", "KIR", "GRC", "GRL", "GRD", "GLP", "GUM", "GTM", "GIN", "GUY", "HTI", "HMD", "VAT", "HND", "HKG", "HUN", "ISL", "IND", "IDN", "IRN", "IRQ", "IRL", "ISR", "ITA", "CIV", "JAM", "JPN", "KAZ", "JOR", "KEN", "PRK", "KOR", "KWT", "KGZ", "LAO", "LBN", "LSO", "LVA", "LBR", "LBY", "LIE", "LTU", "LUX", "MAC", "MDG", "MWI", "MYS", "MDV", "MLI", "MLT", "MTQ", "MRT", "MUS", "MEX", "MCO", "MNG", "MDA", "MNE", "MSR", "MAR", "MOZ", "OMN", "NAM", "NRU", "NPL", "NLD", "CUW", "ABW", "SXM", "BES", "NCL", "VUT", "NZL", "NIC", "NER", "NGA", "NIU", "NFK", "NOR", "MNP", "UMI", "FSM", "MHL", "PLW", "PAK", "PAN", "PNG", "PRY", "PER", "PHL", "PCN", "POL", "PRT", "GNB", "TLS", "PRI", "QAT", "REU", "ROU", "RUS", "RWA", "BLM", "SHN", "KNA", "AIA", "LCA", "MAF", "SPM", "VCT", "SMR", "STP", "SAU", "SEN", "SRB", "SYC", "SLE", "SGP", "SVK", "VNM", "SVN", "SOM", "ZAF", "ZWE", "ESP", "SSD", "SDN", "ESH", "SUR", "SJM", "SWZ", "SWE", "CHE", "SYR", "TJK", "THA", "TGO", "TKL", "TON", "TTO", "ARE", "TUN", "TUR", "TKM", "TCA", "TUV", "UGA", "UKR", "MKD", "EGY", "GBR", "GGY", "JEY", "IMN", "TZA", "USA", "VIR", "BFA", "URY", "UZB", "VEN", "WLF", "WSM", "YEM", "ZMB"
            ],
            /*
            * Тип документа
            * */
            identityDocument :[
                {
                    code:'passportRf',
                    name:'Паспорт РФ'
                }
            ],
            position:[
                {
                    code:'director',
                    name:'Директор'
                },
                {
                    code:'ceo',
                    name:'Генеральный директор'
                },
                {
                    code:'president',
                    name:'Президент'
                },
                {
                    code:'actingCeo',
                    name:'Действующий генеральный директор'
                },
                {
                    code:'other',
                    name:'Другая'
                }
            ],
            federalLaw:[
                {code:'44', name:'44'},
                {code:'223', name:'223'},
                {code:'185-615', name:'185-615'},
                {code:'commercial', name:'Коммерческие торги'}
            ],
            federalLawPrimary:[
                {code:'44', name:'44'},
                {code:'223', name:'223'},
                {code:'commercial', name:'Коммерческие торги'}
            ],
            currencyRef:[
                {code:'RUB', name:'Российский рубль'},
                {code:'USD', name:'Доллар США'},
                {code:'EUR', name:'Евро'},
                {code:'KZT', name:'Казахстанский тенге'},
                {code:'CNY', name:'Китайский юань'}
            ]

        };
        /*
        * Значени по умолчанию для списков
        * codeName - значени списка
        * modelName - имя в модели
        * */
        var defaultSelectedItems = [
            //Тип собственности
            {
                codeName: 'NonGovernmentOwnership',//Негосударственная организация
                modelName: 'ownershipFormGrouping'
            },
            //Кредитная история
            {
                codeName: 'noHistory',//Кредитная история отсутствует
                modelName: 'creditHistory'
            },
            //Тип организации (согласно типа собственности)
            {
                codeName: 'CommercialOrganization',//Коммерческая организация
                modelName: 'ownershipForm'
            },
            //Цели финансово-хозяйственной деятельности организации
            {
                codeName: 'profitFromMainActivity',//Извлечение/получение прибыли от основной хозяйственной деятельности
                modelName: 'financialAndEconomicActivityType'
            },
            //Наличие отзывов кредитных организаций, ранее обслуживавших Клиента, с информацией об оценке деловой репутации
            {
                codeName: 'missing',//Не предоставлены в связи с невозможностью их получения
                modelName: 'financialReviewsCredit'
            },
            //Оценка финансового положения клиента
            {
                codeName: 'Stable',//Стабильное финансовое положение, постоянный положительный финансовый результат в течение последнего налогового периода
                modelName: 'financialPosition'
            },
            //Наличие отзывов контрагентов
            {
                codeName: 'missing',//Не предоставлены в связи с невозможностью их получения
                modelName: 'financialReviewsContragents'
            }
        ];
        service.getActiveTab = getActiveTabFn;
        service.setActiveTab = setActiveTabFn;
        service.getActiveLevel = getActiveLevelFn;
        service.setActiveLevel = setActiveLevelFn;
        service.getTableForm = getTableFormFn;
        service.setTableForm = setTableFormFn;
        service.clearDopInfoByProxy = clearDopInfoByProxyFn;
        service.getData = getDataFn;
        service.setData = setDataFn;
        service.setDataFormPurchase = setDataFormPurchaseFn;
        service.save = saveFn;
        service.deleteApp = deleteAppFn;
        service.setPDL = setPDLFn;
        service.getBankGuarantee = getBankGuaranteeFn;
        service.send = sendFn;
        service.getDocumentsDataForSign = getDocumentsDataForSignFn;
        service.signDocument = signDocumentFn;
        service.sendSignedDocuments = sendSignedDocumentsFn;
        service.sendDocuments = sendDocumentsFn;
        service.getDict = getDictFn;
        service.getDocTypeList = getDocTypeListFn;
        service.getDefaultModel = getDefaultModelFn;
        service.setDictRemote = setDictRemoteFn;
        service.getDictRemote = getDictRemoteFn;
        service.downloadFileByOrderIdAndType = downloadFileByOrderIdAndTypeFn;
        service.getOrganizationInfoForAgent = getOrganizationInfoForAgentFn;
        service.loadParticipantAgentsList = loadParticipantAgentsListFn;
        service.getNeedConfirmMsg = getNeedConfirmMsgFn;
        service.setStateApp = setStateAppFn;
        service.getStateApp = getStateAppFn;
        /*
        * Методы для работы диалога с банком
        * */
        service.getRequests = getRequestsFn;
        service.answer = answerFn;
        service.sendToBank = sendToBankFn;
        /* Методы Запросов на лимита */
        service.getLimits = getLimitsFn;
        service.getLimitsCheckTypeOrg = getLimitsCheckTypeOrgFn;
        service.refreshLimits = refreshLimitsFn;
        service.getStateInfo = getStateInfoFn;
        service.changeState = changeStateFn;
        service.assignAgent = assignAgentFn;

        function getActiveTabFn() {
            return service.activeTab;
        }
        function setActiveTabFn(value) {
            service.activeTab = value;
        }
        function getActiveLevelFn() {
            return service.activeLevel;
        }
        function setActiveLevelFn(value) {
            service.activeLevel = value;
        }
        function getTableFormFn() {
            return service.tableForm;
        }
        /*
        * Установить данные для передачи между таблицой и формой
        * data - данные (null если создание)
        * type - тип формы
        * isNew - если новая запись
        * isReadOnly - true если форма просмотра
        * index - индекс в массиве для обновления данных
        * */
        function setTableFormFn(data, type, isNew, isReadOnly,index) {
            service.tableForm.data = data;
            service.tableForm.type = type;
            service.tableForm.isNew = isNew;
            service.tableForm.isReadOnly = isReadOnly;
            service.tableForm.index = index;
        }
        /*
        * Очистить информацию когда убирают "Представитель действует по доверенности"
        * model - модель для очистки
        * */
        function clearDopInfoByProxyFn(model) {
            delete model['proxyNumber'];
            delete model['proxyDateStart'];
            delete model['proxyName'];
            delete model['proxyDateEnd'];
            delete model['proxyAgreementAmount'];
            delete model['proxyGuaranteeAmount'];
        }
        function getDataFn() {
            return service.data;
        }
        function setDataFn(value) {
            service.data = value;
        }
        /*
        * Проставляем данны из закупки в формате модели для отображения
        * model - куда ставим (анкета)
        * purchasData - что ставим (закупка)
        *
        * type - тип откуда пришли данные (
        * UETP - закупки КОМИТА ЭТП (из БД КОМИТА ЭТП)
        * EIS - из Реестра закупок ЕИС (из ElasticSearch purchase_notice*, purchase_notification*)
        * )
        * isPrimary - анкета с лимитом или нет
        * */
        function setDataFormPurchaseFn(model, purchasData, type, isPrimary) {
            var currentDate = getServerTimeMsk().getTime();
            //однолотовая процедура
            var isOneLot = purchasData.lots && purchasData.lots.length === 1;
            if(!model.bankGuarantee)
                model.bankGuarantee = {};
            
            if(type==='UETP'){
                //общие поля для анкеты Primary и Order
                model.bankGuarantee.bankGuaranteeType = purchasData.claimEnds>currentDate?"participation":"execution";
                if(purchasData.commercial){
                    model.bankGuarantee.federalLaw = purchasData.section;
                    model.bankGuarantee.purchaseNumber = purchasData.number;
                }else{
                    model.bankGuarantee.federalLaw = "223";
                    model.bankGuarantee.purchaseNumber = purchasData.eisNumber;
                }
                //однолотовая процедура
                if(isOneLot){
                    if(purchasData.claimEnds>currentDate){
                        model.bankGuarantee.bankGuaranteeSum = purchasData.lots[0].applicationSupplySumm;
                    }
                }
                //поля для анкеты Order
                if(!isPrimary){
                    if(purchasData.commercial){
                        if(purchasData.type && purchasData.type.id && purchasData.id)
                            model.bankGuarantee.url = "https://etp.comita.ru/openProcedure/"+purchasData.type.id+"/"+purchasData.id;
                    }else{
                        model.bankGuarantee.url = purchasData.eisUrl;
                    }
                    model.bankGuarantee.isCommercial = purchasData.commercial;
                    model.bankGuarantee.purchasePublishedDate = $filter('date')(purchasData.claimBegins, 'yyyy-MM-dd');
                    model.bankGuarantee.tenderDate =  $filter('date')(purchasData.claimEnds, 'yyyy-MM-dd');
                    if(purchasData.type && purchasData.type.id) {
                        model.bankGuarantee.auctionType =
                            ["limitedauction",
                                "limitedcontest",
                                "limitedcontestqualification",
                                "limitedrequestproposals",
                                "limitedrequestquotations"].indexOf(purchasData.type.id) !== -1 ? "private" : "public";
                        model.bankGuarantee.tenderType = purchasData.type.name;
                    }
                    model.bankGuarantee.marketPlace = "Электронная торговая площадка КОМИТА";
                    //однолотовая процедура
                    if(isOneLot) {
                        model.bankGuarantee.contractMaxPrice = purchasData.priceNoNDS;
                        model.bankGuarantee.lotNumber = "1";
                        model.bankGuarantee.subject = purchasData.lots[0].name;
                        model.bankGuarantee.okpd = [];
                        angular.forEach(purchasData.lots[0].items, function (item) {
                            model.bankGuarantee.okpd.push({
                                name: item.okpd2Name,
                                code: item.okpd2Code
                            })
                        });
                        if (model.bankGuarantee.okpd.length === 0) delete model.bankGuarantee.okpd;
                    }
                    if (purchasData.customer) {
                        var fillAddress = function (address) {
                            var str = '';
                            if (address.addressNonResident) {
                                str += address.addressNonResident;
                            }
                            else {
                                str += address.zip + getField(address.regionOKATOName) + getField(address.area) + getField(address.settlement) + getField(address.oksmName) + getField(address.street) + getField(address.building) + getField(address.subBuilding) + getField(address.office);
                            }
                            return str;
                        };

                        var getField = function (data) {
                            if (data) {
                                return ', ' + data
                            }
                            return ''
                        };

                        if (!model.principal) model.principal = {};
                        if (!model.principal.beneficiaries) model.principal.beneficiaries = [];
                        var beneficiaries = {};
                        if (purchasData.customer.realAddress) {
                            beneficiaries.actualAddress = fillAddress(purchasData.customer.realAddress);
                        } else {
                            if (purchasData.customer.regAddress) {
                                beneficiaries.actualAddress = fillAddress(purchasData.customer.regAddress);
                            }
                        }
                        if (purchasData.customer.regAddress) {
                            beneficiaries.legalAddress = fillAddress(purchasData.customer.regAddress);
                        }
                        beneficiaries.inn = purchasData.customer.inn;
                        beneficiaries.kpp = purchasData.customer.kpp;
                        beneficiaries.ogrn = purchasData.customer.ogrn;
                        beneficiaries.fullName = purchasData.customer.name;
                        model.principal.beneficiaries.push(beneficiaries);
                    }
                }
            }else if(type==='EIS'){
                //заполняем данными из закупки ЕИС

                //общие поля для анкеты Primary и Order
                model.bankGuarantee.purchaseNumber = purchasData.purchase_number;
                model.bankGuarantee.federalLaw = purchasData.section;
                if(purchasData.submission_close>currentDate){
                    model.bankGuarantee.bankGuaranteeType = "participation";
                }else{
                    model.bankGuarantee.bankGuaranteeType = "execution";
                }
                //поля из однолотовой процедуры
                if(isOneLot) {
                    if (purchasData.submission_close > currentDate) {
                        model.bankGuarantee.bankGuaranteeSum = purchasData.lots[0].applicationSupplySumm;
                    } else {
                        model.bankGuarantee.bankGuaranteeSum = purchasData.lots[0].contractSupplySumm;
                    }
                }
                //поля для анкеты Order
                if(!isPrimary){
                    model.bankGuarantee.isCommercial = false;
                    if(purchasData.publish_date_first){
                        model.bankGuarantee.purchasePublishedDate = $filter('date')(purchasData.publish_date_first, 'yyyy-MM-dd');
                    }
                    if(purchasData.publish_date){
                        model.bankGuarantee.purchasePublishedDate = $filter('date')(purchasData.publish_date, 'yyyy-MM-dd');
                    }
                    model.bankGuarantee.tenderDate = $filter('date')(purchasData.submission_close, 'yyyy-MM-dd');
                    model.bankGuarantee.tenderType = purchasData.method_name;
                    model.bankGuarantee.url = purchasData.url_eis;
                    if(purchasData.method_name) {
                        model.bankGuarantee.auctionType = purchasData.method_name.indexOf('с ограниченным участием') >= 0 ? 'private' : 'public';
                    }
                    if(!model.principal) model.principal = {};
                    model.principal.beneficiaries = [];
                    angular.forEach(purchasData.customers, function (item) {
                        var beneficiar = {};
                        beneficiar.inn = item.inn;
                        beneficiar.kpp = item.kpp;
                        beneficiar.ogrn = item.ogrn;
                        beneficiar.fullName = item.name;
                        if (purchasData.section === "44" || purchasData.section === "615")
                            beneficiar.customerRegNum = item.regnum;
                        model.principal.beneficiaries.push(beneficiar);
                    });
                    //поля из однолотовой процедуры
                    if(isOneLot) {
                        model.bankGuarantee.lotNumber = "1";
                        model.bankGuarantee.subject = purchasData.lots[0].lot_name;
                        model.bankGuarantee.marketPlace = purchasData.ep_name;
                        //Необходимо брать поле "startprice_cur" если "startprice"=null
                        model.bankGuarantee.contractMaxPrice = purchasData.lots[0].startprice ? purchasData.lots[0].startprice:purchasData.lots[0].startprice_cur;
                        model.bankGuarantee.okpd = [];
                        angular.forEach(purchasData.lots[0].lotItems, function (item) {
                            if (item.okpd2_code && item.okpd2_name) {
                                model.bankGuarantee.okpd.push({
                                    name: item.okpd2_name,
                                    code: item.okpd2_code
                                })
                            }
                        });
                        if (model.bankGuarantee.okpd.length === 0) delete model.bankGuarantee.okpd;
                    }
                }
            }
        }
        /*
        * Сохранение анкеты
        * refresh = true - обновление
        * */
        function saveFn(data, id, refresh, isCreateDocs, isPrimary) {
            var method = 'POST',
                url = 'bankguarantee/orders';
            //анкета с лимитом
            if(isPrimary) url = 'bankguarantee/orders/primary';
            if(refresh){
                method = 'PUT';
                url = 'bankguarantee/orders/' + id + (isCreateDocs ? '?isCreateDocs=true' : '') 
            }
            return httpSrv.http({
                method: method,
                data: data,
                url: url
            });
        }
        /*
        * Удалить анкету
        * доступно только для черновика
        * */
        function deleteAppFn(orderId) {
            return httpSrv.http({
                method: 'DELETE',
                url: '/bankguarantee/orders/'+orderId
            });
        }
        /*
        * Получить анкету по id
        * */
        function getBankGuaranteeFn(id, isCopy) {
            return httpSrv.http({
                method: 'GET',
                url: 'bankguarantee/orders/'+id + (isCopy ? '/copy' : '')
            });
        }
        /*
        * Отправка анкеты
        * orderId - внутрений id заявки
        * */
        function sendFn(orderId) {
            return httpSrv.http({
                method: 'POST',
                url: '/bankguarantee/orders/'+orderId+'/send'
            });
        }
        /*
        * Отправляем вложения и получем данные для подписи
        * orderId - внутрений id заявки
        * data - анкета
        * */
        function getDocumentsDataForSignFn(certHash,orderId) {
            return cryptography.getAlgoInfo(certHash).then(function (data) {
                return httpSrv.http({
                    method: 'POST',
                    url: '/bankguarantee/orders/'+orderId+'/getDocumentsDataForSign',
                    data:data.code
                });
            })
        }
        function _iteratorObject(array) {
            var nextIndex = 0;
            return {
                next: function(){
                    return nextIndex < array.length ?
                        {object: array[nextIndex++], done: false} :
                        {done: true};
                }
            }
        }

        /**
         * Подпись документа
         * @param model - данные для подписи
         * @param certHash - иентификатор сертификата
         * @returns {Promise}
         */
        function signDocumentFn(model, certHash) {
            var iterator = _iteratorObject(model);
            return new Promise(function (resolve, reject) {
                var recursiveSign = function (iterator, object) {
                    cryptography.signHash(object.data, certHash, object.hashAlg).then(function (data) {
                        object.signature =  data;
                        var iterate = iterator.next();
                        if (!iterate.done) {
                            recursiveSign(iterator, iterate.object);
                        } else {
                            resolve(model);
                        }
                    }, function (reason) {
                        reject(reason)
                    })
                };
                recursiveSign(iterator, iterator.next().object)
            });
        }

        /**
         * Отправить подписанные данные
         */
        function sendSignedDocumentsFn(data, orderId) {
            return httpSrv.http({
                method: 'POST',
                url: '/bankguarantee/orders/' + orderId + '/sendSignedDocuments',
                data: {dataList:data}
            });
        }
        /*
        @deprecate
        * Отправляем подписанные вложения
        * orderId - внутрений id заявки
        * data - подписанные вложения
        * */
        function sendDocumentsFn(data,orderId) {
            return httpSrv.http({
                method: 'PUT',
                url: '/bankguarantee/orders/'+orderId+'/sendDocuments',
                data:data
            });
        }
        /*
        * Получить справочник по типу
        * */
        function getDictFn(type) {
            return service.dict[type];
        }
        /*
        * Установить значение ПДЛ (ИПДЛ, МПДЛ, РПДЛ)
        * model - модель
        * value - что выбрали (isIpdl, isMpdl, isRpdl)
        * */

        function setPDLFn(model, value) {
            //сбрасываем значения
            model.mpdlEmployerAddress = "";
            model.mpdlEmployerName = "";
            model.mpdlPosition = "";
            model.mpdlRelation = "";
            model.ipdlEmployerAddress = "";
            model.ipdlEmployerName = "";
            model.ipdlPosition = "";
            model.ipdlRelation = "";
            model.rpdlEmployerAddress = "";
            model.rpdlEmployerName = "";
            model.rpdlPosition = "";
            model.rpdlRelation = "";
            if (value === 'isIpdl') {
                delete model.isMpdl;
                delete model.isRpdl;

                delete model.mpdlEmployerAddress;
                delete model.mpdlEmployerName;
                delete model.mpdlPosition;
                delete model.mpdlRelation;

                delete model.rpdlEmployerAddress;
                delete model.rpdlEmployerName;
                delete model.rpdlPosition;
                delete model.rpdlRelation;


            } else if (value === 'isMpdl') {
                delete model.isIpdl;
                delete model.isRpdl;

                delete model.ipdlEmployerAddress;
                delete model.ipdlEmployerName;
                delete model.ipdlPosition;
                delete model.ipdlRelation;

                delete model.rpdlEmployerAddress;
                delete model.rpdlEmployerName;
                delete model.rpdlPosition;
                delete model.rpdlRelation;
            } else if (value === 'isRpdl') {
                delete model.isMpdl;
                delete model.isIpdl;

                delete model.mpdlEmployerAddress;
                delete model.mpdlEmployerName;
                delete model.mpdlPosition;
                delete model.mpdlRelation;

                delete model.ipdlEmployerAddress;
                delete model.ipdlEmployerName;
                delete model.ipdlPosition;
                delete model.ipdlRelation;
            }
        }
        
        /*
        * Получение всего диалога с банком по id анкеты
        * */
        function getRequestsFn(orderId) {
            return httpSrv.http({
                method: 'GET',
                url: '/bankguarantee/orders/'+orderId+'/requests'
            });
            //TODO: временнные данные
            /*return [
                {
                    id:'1',
                    created:'1567873497',
                    requestContent:{
                        "orderId": "TCSfHafF3hQVxxa-deecb",
                        "taskDefinitionKey":"UserTaskInitiatorSignDocs",
                        "processDefinitionKey": "bg-pa-limit-check:46:677d64c6-c4e0-11e9-9321-0242ac120039",
                        "decisionOptions": [
                            {
                                "code": "SEND_TO_BANK",
                                "text": "Отправить в Банк",
                                "isCommentRequired": false
                            },
                            {
                                "code": "REJECT",
                                "text": "Отказаться от заявки на лимит",
                                "isCommentRequired": true
                            }],
                        "bankComment": "decision: Укажите решение для продолжения. Возможные значения: SEND_TO_BANK, REJECT",
                        "taskName":"Подписать документы",
                        "taskDescription":null,
                        "url":null,
                        "orderStatus":"PendingAgent",
                        "statusDescription":"Заявка ожидает обновления данных",
                        "documents": [
                            {
                                "type": "doc_taxForm",
                                "fileName": "doc_taxForm.docx",
                                "id": "85ab7079-c981-11e9-9af6-0242ac120035"
                            }, {
                                "type": "doc_declarationBenOwner",
                                "fileName": "bg_declarationBenOwner.docx",
                                "id": "85acd00d-c981-11e9-9af6-0242ac120035"
                            }, {

                                "type": "doc_persDataAgree",
                                "fileName": "bg_dataProcessingAgreement.docx",
                                "id": "85ae2fa1-c981-11e9-9af6-0242ac120035"
                            }, {

                                "type": "doc_principalFinReport",
                                "fileName": "doc_principalFinReport.docx",
                                "id": "85a9c2c5-c981-11e9-9af6-0242ac120035"
                            }, {

                                "type": "doc_finReportQ",
                                "fileName": "doc_finReportQ.docx",
                                "id": "85ac33cb-c981-11e9-9af6-0242ac120035"
                            }, {

                                "type": "doc_principalForm",
                                "fileName": "bg_questionnaire_legalentity.docx",
                                "id": "85ad935f-c981-11e9-9af6-0242ac120035"
                            }, {

                                "type": "doc_limitRequest",
                                "fileName": "bg_limitRequest.docx",
                                "id": "85a38133-c981-11e9-9af6-0242ac120035"
                            }, {

                                "type": "doc_principalDocumentConfirming",
                                "fileName": "doc_principalDocumentConfirming.docx",
                                "id": "85aad437-c981-11e9-9af6-0242ac120035"
                            }]
                    },
                    clientResponse:null
                },

                {
                    id:'1',
                    created:'1567873497',
                    requestContent:{
                        "decisionOptions": [
                            {
                                "code": "SEND_TO_BANK",
                                "text": "Отправить в Банк",
                                "isCommentRequired": false
                            },
                            {
                                "code": "REJECT",
                                "text": "Отказаться от заявки на лимит",
                                "isCommentRequired": true
                            }
                        ],
                        "bankComment": "Коммент банка",
                        "taskName": "Подписать документы",
                        "documents": [
                            {
                                "type": "doc_principalForm",
                                "fileName": "bg_questionnaire_legalentity.docx",
                                id:'3'
                            },
                            {
                                "type": "doc_limitRequest",
                                "fileName": "bg_limitRequest.docx",
                                id:'4'
                            }
                        ]
                    },
                    //ответ
                    clientResponse:{
                        id:'2',
                        sendDate:'1567873497',
                        clientResponseContent:{
                            "decision": {
                                "comment": "Обратите внимание на сроки Обратите внимание на сроки Обратите внимание на сроки",
                                "isCommentRequired": false,
                                "resultCode": "ACCEPT"
                            },
                            "documents": [
                                {
                                    "type": "doc_principalForm",
                                    "fileName": "bg_questionnaire_legalentity.docx",
                                    id:'3'
                                },
                                {
                                    "type": "doc_limitRequest",
                                    "fileName": "bg_limitRequest.docx",
                                    id:'4'
                                }
                            ]
                        }
                    }
                },
                {
                    id:'1',
                    created:'1567873497',
                    requestContent:{
                        "decisionOptions": [
                            {
                                "code": "SEND_TO_BANK",
                                "text": "Отправить в Банк",
                                "isCommentRequired": false
                            },
                            {
                                "code": "REJECT",
                                "text": "Отказаться от заявки на лимит",
                                "isCommentRequired": true
                            }
                        ],
                        "bankComment": "Коммент банка",
                        "taskName": "Подписать документы",
                        "documents": [
                            {
                                "type": "doc_principalForm",
                                "fileName": "bg_questionnaire_legalentity.docx",
                                id:'3'
                            },
                            {
                                "type": "doc_limitRequest",
                                "fileName": "bg_limitRequest.docx",
                                id:'4'
                            }
                        ]
                    },
                    //ответ
                    clientResponse:{
                        id:'2',
                        sendDate:'1567873497',
                        clientResponseContent:{
                            "decision": {
                                "comment": "Обратите внимание на сроки Обратите внимание на сроки Обратите внимание на сроки",
                                "isCommentRequired": false,
                                "resultCode": "ACCEPT"
                            },
                            "documents": [
                                {
                                    "type": "doc_principalForm",
                                    "fileName": "bg_questionnaire_legalentity.docx",
                                    id:'3'
                                },
                                {
                                    "type": "doc_limitRequest",
                                    "fileName": "bg_limitRequest.docx",
                                    id:'4'
                                }
                            ]
                        }
                    }
                }
            ];*/


        }
        /*
        * Сохранить ответ
        * orderId - id анкеты
        * requestId - id запроса
        * data - данные ответа
        * */
        function answerFn(orderId, requestId, data) {
            return httpSrv.http({
                method: 'POST',
                url: '/bankguarantee/orders/'+orderId+'/requests/answer/'+requestId,
                data:data
            });
        }
        /*
        * Отправить ответ
        * orderId - id анкеты
        * requestId - id запроса
        * */
        function sendToBankFn(orderId, requestId) {
            return httpSrv.http({
                method: 'POST',
                url: '/bankguarantee/orders/'+orderId+'/requests/sendToBank/'+requestId
            });
        }
        /*
        * Модель по умолчанию в формате модели для отображения
        * userInfo - данные по участнику - если заполняет агент
        * */
        function getDefaultModelFn(isPrimary,userInfo) {
            var model = {};
            model.principal = {};
            var user = authSrv.getUser();
            if(userInfo){
                user = userInfo;
            }
            model.principal.fullName = user.fullName;
            model.principal.inn = user.inn;
            model.principal.ogrn = user.ogrn;
            model.principal.principalSigner = {};
            model.principal.principalSigner.personAttributes = {
                citizenship: 'RUS',
                countryOfResidence: 'RUS'
            };
            model.principal.principalSigner.identityDocument = {
                identityDocumentType: 'passportRf'
            };
            model.bankGuarantee = {};
            model.bankGuarantee.federalLaw = '223';
            //поля поумолчанию для большой анкеты
            if(!isPrimary) {
                model.principal.companyHead = {};
                model.principal.companyHead.personAttributes = {
                    citizenship: 'RUS',
                    countryOfResidence: 'RUS'
                };
                model.principal.companyHead.identityDocument = {
                    identityDocumentType: 'passportRf'
                };

                model.principal.kpp = user.kpp;
                model.principal.addressesFac = {};
                model.principal.addressesUr = {};
                //нет фактического адреса, но есть юр - ставим везде юр
                if (!user.realAddress) {
                    if (user.regAddress) {
                        model.principal.addressesFac.address = user.regAddress.addressAsString;
                        model.principal.addressesUr.address = user.regAddress.addressAsString;
                    }
                } else {
                    //есть оба
                    if (user.regAddress) {
                        model.principal.addressesUr.address = user.regAddress.addressAsString;
                    }
                    model.principal.addressesFac.address = user.realAddress.addressAsString;
                }

                model.principal.bankAccounts = {};
                model.principal.bankAccounts.useInDocuments = false;

                if (user.requisites) {
                    model.principal.bankAccounts.number = user.requisites.acc;
                    model.principal.bankAccounts.bank = {};
                    model.principal.bankAccounts.bank = {
                        bic: user.requisites.bicBank,
                        accounts: user.requisites.bankAcc,
                        participant: user.requisites.bank
                    };
                }
                model.principal.principalBuhPeriods = [
                    {}, {}
                ];
                //заполняем выподающие списки вкладки "Доп. информация о компании"
                if(!model.principal.companyInfo){
                    model.principal.companyInfo = {}
                }
                angular.forEach(defaultSelectedItems, function (item) {
                    model.principal.companyInfo[item.modelName] = item.codeName;
                });

                model.bankGuarantee.isCommercial = false;
                model.bankGuarantee.isBigDeal = false;
                model.bankGuarantee.isContractConcluded = false;
                model.bankGuarantee.isGuaranteeInBeneficiaryForm = false;
                model.bankGuarantee.isRequiredSecurityForGuaranteePeriod = false;
                model.bankGuarantee.isRequiredIndisputableDebiting = false;
                model.bankGuarantee.isIncludedForfeit = false;
                model.bankGuarantee.isSplittedByBeneficiaries = false;
            }
            model.bankGuarantee.currencyRefId = 'RUB';
            model.bankGuarantee.prepaymentExists = false;
            return model;
        }

        /**
         * Утановить ссылку на загруженные справочники с сервера
         * @param dictRemote - справочники
         */
        function setDictRemoteFn(dictRemote) {
            service.dictRemote = dictRemote;
        }

        /**
         * Получить справочники
         */
        function getDictRemoteFn() {
            return service.dictRemote;
        }

        /**
         * Лимиты БГ
         */
        function getLimitsFn(inn, ogrn, fullName, orgId, typeOrg) {
            return httpSrv.http({
                method: 'POST',
                url: '/bankguarantee/orders/limitInfo/' + inn + '/' + ogrn + '/' + orgId,
                data: {
                   fullName: fullName,
                   typeOrg: typeOrg
                }
            });
        }

        function getLimitsCheckTypeOrgFn(typeOrg, inn, ogrn, fullName, orgId) {
            if (typeOrg === 'ul' || typeOrg === 'ip') {
                return this.getLimits(inn, ogrn, fullName, orgId, typeOrg)
            } else {
                return Promise.resolve({success: false})
            }
        }
        
        function refreshLimitsFn(inn, ogrn, fullName, orgId, typeOrg) {
            return httpSrv.http({
                method: 'POST',
                url: '/bankguarantee/orders/limitInfoRefresh/' + inn + '/' + ogrn + '/' + orgId,
                data: {
                   fullName: fullName,
               	   typeOrg: typeOrg
               }
            });
        }

        /*
        * Получить данные об участнике для заполнения анкеты агентом
        * */
        function getOrganizationInfoForAgentFn(participantId) {
            if(authSrv.isUserAgent()){
                return httpSrv.http({
                    method: 'GET',
                    url: '/auth/user/getOrganizationInfoForAgent/'+participantId
                })
            }else{
                return Promise.resolve(false)
            }

        }
        /**
         * Получение списка агентов, назначенных организации
         * @param id
         * @returns {*}
         */
        function loadParticipantAgentsListFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/admin/getParticipantAgentsList/' + id
            })
        }


        function downloadFileByOrderIdAndTypeFn(data) {
            if(data.bgScanLotFileId && data.bgScanLotFileName)
                httpSrv.downloadFile('/bankguarantee/orders/files/'+data.bgScanLotFileId+'/download', data.bgScanLotFileName)
        }

        function getStateInfoFn(state){
            if(state==='waitClient')
                return "<p>Двойным кликом на анкете откройте её на редактирование. Ознакомьтесь с ошибками или <br>требованиями банка, внесите изменения и повторно направьте анкету в банк.</p>";
            else if(state==='waitResponseFromClient')
                return "<p>Нажмите на кнопку \"Запросы от банка\", ознакомьтесь с запросом. Для подготовки ответа нажмите на <br>кнопку \"Ответить\" рядом с запросом. Для отправки <br>ответа необходимо нажать на кнопку \"Подписать и отправить\"</p>";
            else if(state==='finished')
                return "<p>Скачать скан гарантии</p>";
            else return "";
        }

        function changeStateFn(orderId) {
            return httpSrv.http({
                method: 'GET',
                url: '/bankguarantee/orders/changeState/' + orderId
            });
        }

        function assignAgentFn(orderId, agent) {
            let data = {
                id: agent.id,
                param: agent.fullName
            }
            return httpSrv.http({
                method: 'POST',
                url: '/bankguarantee/orders/assignAgent/' + orderId,
                data: data
            });
        }
        /**
         * Уставновить состояние анкеты
         * @param isPrimary - Primary анкета
         * @param isAppCreate - форма создания
         * @param isAppOpen - Форма редактирования
         * @param isAppCopy - Форма создания копии
         * @param isAppAgent - Форма агента
         */
        function setStateAppFn(isPrimary, isAppCreate, isAppOpen, isAppCopy, isAppAgent, isReadOnly){
            service.stateApp = {
                isPrimary:isPrimary,
                isAppCreate:isAppCreate,
                isAppOpen:isAppOpen,
                isAppCopy:isAppCopy,
                isAppAgent:isAppAgent,
                isReadOnly:isReadOnly,
            }
        }

        /**
         * Вернуть сосотояние анкеты
         * @return {{isAppCreate: boolean, isPrimary: boolean, isAppAgent: boolean, isAppCopy: boolean, isAppOpen: boolean, isReadOnly: boolean}}
         */
        function getStateAppFn(){
            return service.stateApp;
        }

        /**
         * Получаем сообщение при переходе назад по кнопку браузера
         * анализируем состояние анкеты
         */
        function getNeedConfirmMsgFn() {
            if(service.stateApp.isReadOnly){
                return CONFIRM_TEXT_BANKGUARANTEE_VIEW
            }else{
                if(service.stateApp.isAppCreate || service.stateApp.isAppOpen){
                    return CONFIRM_TEXT_BANKGUARANTEE_CREATE;
                }
            }
            return CONFIRM_TEXT_BANKGUARANTEE_VIEW;
        }
        /*
        * Список типов документов
        * canEdit - можем ли мы приклыдвать файл (если true появится в списках для выбора)
        *       в анкете в блоке дополнительных документов
        *       в чате в списке
        * isMultiple - доступен ли множественный выбор
        * isRequired - обязательный ли тип
        * TODO: для ускореной проверки -  пока нет спровочника имитируем его на клиенте
        * */
        function getDocTypeListFn() {
            return [
                {
                    type: "bg_dataProcessingAgreement",
                    label: "Согласие",
                    isRequired: false,
                    isMultiple: true,
                    canEdit: false
                },
                {
                    type: "doc_agentRelationAgreement",
                    label: "Согласие на работу с агентом",
                    isRequired: false,
                    isMultiple: true,
                    canEdit: false
                },
                {
                    type: "doc_bigDealApproveLot",
                    label: "Протокол одобрения сделки",
                    isMultiple: false,
                    isRequired: false,
                    canEdit: true
                },
                {
                    type: "doc_finReportQ",
                    label: "Бух. отчётность с отметкой ИФНС за последний отчётный год",
                    isMultiple: true,
                    isRequired: true,
                    canEdit: true,
                    info: "Бухгалтерская отчетность (Формы 1 и 2) с отметкой ИФНС за прошедший год <br>(если сдаётся электронно либо по почте, то необходимы также сканы <br>соответствующих квитанций об отправке и приёме отчётности)"
                },
                {
                    type: "doc_principalFinReport",
                    label: "Бух. отчётность с отметкой ИФНС за последний отчётный квартал",
                    isMultiple: true,
                    isRequired: true,
                    canEdit: true,
                    info: "Бухгалтерская отчетность (Формы 1 и 2) за последний отчётный квартал <br>(достаточно выгрузки в формате xls из 1С)"
                },
                {
                    type: "doc_principalDocumentConfirming",
                    label: "Документ, подтверждающий право собственности/аренды помещения"	,
                    isMultiple: true,
                    isRequired: true,
                    canEdit: true
                },
                {
                    type: "doc_principalDocumentEIO",
                    label: "Решение о назначении на должность ЕИО",
                    isMultiple: false,
                    isRequired: true,
                    canEdit: true,
                    info: "Решение, протокол или выписка из протокола общего собрания участников<br> о назначении на должность единоличного исполнительного органа юридического лица"
                },
                {
                    type: "doc_principalCharter",
                    label: "Устав",
                    isMultiple: true,
                    isRequired: true,
                    canEdit: true
                },
                {
                    type: "doc_taxForm",
                    label: "Налоговая декларация/ ПП об уплате налогов",
                    isMultiple: true,
                    isRequired: true,
                    canEdit: true,
                    info: "Декларация по налогу на прибыль или НДС (печатная форма с отметкой о принятии <br>от ИФНС или xml файл отчетности с квитанцией о приеме от ИНФС) и <br>платежное поручение об оплате данного налога"
                },
                {
                    type: "doc_Nonresident",
                    label: "Иные документы на проживание в РФ (для нерезидента)",
                    isMultiple: true,
                    isRequired: false,
                    canEdit: true
                },
                {
                    type: "doc_principalPassport",
                    label:  "Копия всех страниц паспорта ЕИО (включая пустые), Паспорт РФ бенефициарного владельца",
                    isMultiple: true,
                    isRequired: true,//TODO: true/false
                    canEdit: true
                },
                {
                    type: "doc_declarationBenOwner",
                    label: "Декларация бенефициарного владельца",
                    isMultiple: false,
                    isRequired: false,
                    canEdit: true
                },
                {
                    type: "doc_debtStatement",
                    label: "Справка ИФНС о наличии/отсутствии просроченной задолженности по налогам",
                    isMultiple: false,
                    isRequired: true,
                    canEdit: true
                },
                {
                    type: "doc_companyBuhScanPdfWithoutBarcode",
                    label: "Электронная форма 'Бухгалтерская отчётность'",
                    isMultiple: false,
                    isRequired: true,
                    canEdit: false
                },
                {
                    type: "doc_principalForm",
                    label: "Анкета",
                    isMultiple: false,
                    isRequired: true,
                    canEdit: false
                },
                {
                    type: "doc_bgRequestLot",
                    label:  "Заявка на выпуск БГ А.Финанс",
                    isMultiple: false,
                    isRequired: true,
                    canEdit: false
                },
                {
                    type: "doc_limitRequest",
                    label: "Заявка на Лимит для БГ А.Финанс",
                    isMultiple: false,
                    isRequired: true,
                    canEdit: false
                },
                {
                    type: "doc_woApp",
                    label: "Заявление о некрупности сделки",
                    isMultiple: false,
                    isRequired: true,
                    canEdit: true
                },
                {
                    type: "doc_otherClientDocs",
                    label: "Прочие документы",
                    isMultiple: true,
                    isRequired: false,
                    canEdit: true
                },
                {
                    type: "doc_egrulExtract",
                    label: "Выписка из ЕГРЮЛ",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_reissueReason",
                    label: "Обоснование изменения формы гарантии",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_managingCompany",
                    label: "Договор с Управляющей компанией",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_bkiPermission",
                    label: "Согласие на получение кредитной истории в БКИ",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_bill",
                    label: "Предложение условий выдачи банковской гарантии и Счет на оплату вознаграждения за выдачу банковской гарантии",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_persDataAgree",
                    label: "Согласие на обработку персональных данных",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_bgScanLot",
                    label: "Скан гарантии",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_finReportConfirm",
                    label: "Подтверждение отправки годовой отчетности",
                    isMultiple: true,
                    canEdit: true
                },
                {
                    type: "doc_guarantorDocumentEIO",
                    label: "Копия всех страниц паспорта РФ (включая пустые) учредителей, ЕИО и бенефициарных владельцев",
                    isMultiple: true,
                    isRequired: true,
                    canEdit: true
                },
                {
                    type: "doc_comDecreaseReason",
                    label: "Обоснование понижения комиссии",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_credDecision",
                    label: "Кредитное решение",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_principalConfirmingSigner",
                    label: "Документ, подтверждающий право подписи",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_bgScan",
                    label: "Сканированная копия банковской гарантии",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_woAppLot",
                    label: "Справка об отсутствии необходимости одобрения сделки",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_bigDealApprove",
                    label: "Решение уполномоченного органа об одобрении крупной сделки",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_contragentsReviews",
                    label: "Отзывы контрагентов",
                    isMultiple: true,
                    canEdit: true
                },
                {
                    type: "doc_bgReissued",
                    label: "Гарантия (изменённая)",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_otherOrder",
                    label: "Прочие документы по сделке",
                    isMultiple: true,
                    canEdit: true
                },
                {
                    type: "doc_visa",
                    label: "Сведения о въездной визе",
                    isMultiple: true,
                    canEdit: true
                },
                {
                    type: "doc_agentBillingAct",
                    label: "Акт биллинга агента",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_requestGuarantee",
                    label: "Подписанный скан заявления на выдачу банковской гарантии",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_edoAgree",
                    label: "Согласие с присоединением к Регламенту ЭДО",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_accessionRequest",
                    label: "Заявление о присоединении к Договору",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_ledgerYear",
                    label: "Для Клиентов находящихся на УСН/ЕНВД - Книга доходов и расходов за последний завершенный год. Документ должен содержать отметку ФНС о приеме отчетности, либо, если отчетность сдается электронно или по почте, то дополнительно необходимы сканы соответствующих квитанций об отправке и приеме отчетности или протоколы входного контроля",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_creditReviews",
                    label: "Отзывы кредитных организаций",
                    isMultiple: true,
                    canEdit: true
                },
                {
                    type: "doc_principalLicense",
                    label: "Лицензии. Обязателен при формировании заявки.",
                    isMultiple: true,
                    canEdit: true
                },
                {
                    type: "doc_otherFins",
                    label: "Прочие финансовые документы",
                    isMultiple: true,
                    canEdit: true
                },
                {
                    type: "doc_companyBuhScanPdfXml",
                    label: "Контейнер ФНС (бух отчетность)",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_executedContracts",
                    label: "Исполненный(ые) контракт(ы) и подписанный(ые) акт(ы) приема-передачи товаров (выполнения работ, оказания услуг)",
                    isMultiple: true,
                    canEdit: true
                },
                {
                    type: "doc_principalExtractRegistry",
                    label: "Выписка из реестра акционеров. Обязателен при формировании заявки.",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_principalCharterChange",
                    label: "Изменение устава принципала",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_guarantee",
                    label: "Гарантия",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_formManagingComp",
                    label: "Анкета управляющей компании",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_companyBuhScanPdfWithBarcode",
                    label: "Скан бух отчетности (pdf417)",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_purchase",
                    label: "Документация о закупке",
                    isMultiple: true,
                    canEdit: true
                },
                {
                    type: "doc_guaranteeLot",
                    label: "Макет БГ",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_guaranteeContract",
                    label: "Гарантийнный договор",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_orderComments",
                    label: "Переписка по сделке",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_accountArrests",
                    label: "Отчет о результате запроса о действующих решениях ФНС о приостановлении операций по счетам Клиента в банках (копия экрана)",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_boardElect",
                    label: "Протокол об избрании совета директоров (наблюдательного совета)",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_profVerdict",
                    label: "Профессиональное суждение",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type: "doc_bgReissuedScan",
                    label: "Скан оригинала гарантии (изменённой)",
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type:'doc_orderParamsGeneratedLot',
                    label:'Параметры заявки',
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type:'doc_declarationBenOwner2',
                    label:'Декларация бенефициарного владельца 2',
                    isMultiple: false,
                    canEdit: true
                },
                {
                    type:'doc_finReportGenerated',
                    label:'Бухгалтерская отчетность',
                    isMultiple: false,
                    canEdit: true
                }
            ]
        }
    }]);
