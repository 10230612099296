angular.module('uetp')
    .component('taxesTass', {
        bindings: {
            companyInfo: '=',
            initLoadData: '<' // загрузить данные для отображения когда станет true
        },
        templateUrl: 'app/components/widgets/tassBusiness/companyValidate/view/taxes/taxes.html',
        controller: ['$scope',
            function ($scope) {
                var ctrl = this;

                const TYPES_TITLE = {
                    'eshn': 'Применение единого сельскохозяйственного налога',
                    'usn': 'Применение упрощенной системы налогообложения',
                    'envd': 'Применение единого налога на вмененный доход',
                    'srp': 'Применение налогообложения при соглашении о разделе продукции',
                    'taxSum': 'Общая сумма недоимки по налогу, пени и штрафу',
                    'taxArrears': 'Сумма недоимки по налогу',
                    'taxFine': 'Сумма пени',
                    'taxPenalty': 'Сумма штрафа',
                };

                $scope.modelView = {};
                $scope.paytax = {};
                $scope.snr = {};
                $scope.debtam = {};
                $scope.taxesView = false;

                this.$onInit = function () {
                    if(ctrl.companyInfo){
                        $scope.modelView = ctrl.companyInfo;

                        if(($scope.modelView.paytax && $scope.modelView.paytax.length) || ($scope.modelView.debtam && $scope.modelView.debtam.length) || $scope.modelView.snr || $scope.modelView.taxoffence) $scope.taxesView = true;

                        if($scope.modelView.paytax && $scope.modelView.paytax.length){
                            $scope.paytax.dateActualization = $scope.modelView.paytax[0].dateActualization;
                            $scope.paytax.infoBlock = $scope.modelView.paytax;
                        }

                        if($scope.modelView.debtam && $scope.modelView.debtam.length){
                            $scope.debtam.dateActualization = $scope.modelView.debtam[0].dateActualization;
                            $scope.debtam.infoBlock = $scope.modelView.debtam.map(function(item) {
                                item.info = [];

                                if(item.taxArrears !== undefined) item.info.push(getDebtamInfo(item.taxArrears, TYPES_TITLE['taxArrears']));
                                if(item.taxFine !== undefined) item.info.push(getDebtamInfo(item.taxFine, TYPES_TITLE['taxFine']));
                                if(item.taxPenalty !== undefined) item.info.push(getDebtamInfo(item.taxPenalty, TYPES_TITLE['taxPenalty']));
                                if(item.taxSum !== undefined) item.info.push(getDebtamInfo(item.taxSum, TYPES_TITLE['taxSum']));
                                return item
                            });
                        }

                        if($scope.modelView.snr){
                            $scope.snr.dateActualization = $scope.modelView.snr.dateActualization;
                            $scope.snr.arrBlock = [];
                            if($scope.modelView.snr.eshn !== undefined) $scope.snr.arrBlock.push(getSnrArrBlock($scope.modelView.snr.eshn, TYPES_TITLE['eshn']));
                            if($scope.modelView.snr.usn !== undefined) $scope.snr.arrBlock.push(getSnrArrBlock($scope.modelView.snr.usn, TYPES_TITLE['usn']));
                            if($scope.modelView.snr.envd !== undefined) $scope.snr.arrBlock.push(getSnrArrBlock($scope.modelView.snr.envd, TYPES_TITLE['envd']));
                            if($scope.modelView.snr.srp !== undefined) $scope.snr.arrBlock.push(getSnrArrBlock($scope.modelView.snr.srp, TYPES_TITLE['srp']));
                        }
                    }
                };

                function getSnrArrBlock(value, title) {
                    return {
                        value : value ? 'Да' : 'Нет',
                        title: title
                    }
                }

                function getDebtamInfo(value, title) {
                    return {
                        value : value,
                        title: title
                    }
                }
            }]
    });

