/**
 * для разделения файлов по секциям используется section
 *
 * cabType и userRole - указываются кабинеты и типы пользователей, для которых доступен документ (all - доступно везде)
 */

angular.module('uetp').constant('presentationList', {
    UETP: [
        {name: "Возможности электронных торговых ресурсов ГК «Комита» для Заказчиков", file: "customers_etp.pdf", section: "common", cabType: "all", userRole: "all"},
        {name: "Возможности электронных торговых ресурсов ГК «Комита» для Поставщиков", file: "participants_presentation_etp.pdf", section: "common", cabType: "all", userRole: "all"}
    ],
    PPK: [
        {name: "Возможности Портала Поставщиков Кузбасса для Заказчиков", file: "customers_ppk.pdf", section: "common", cabType: "all", userRole: "all"},
        {name: "Возможности Портала Поставщиков Кузбасса для Поставщиков", file: "participants_presentation_ppk.pdf", section: "common", cabType: "all", userRole: "all"}
    ]
});