/**
 * @author sergeyu
 */
angular.module('uetp')
    .component('explreqs', {
        templateUrl: 'app/components/systemMessage/explreqs.html',
        bindings: {
            refresh: '@'
        },
        controller: ['$scope', '$http', '$interval', 'httpSrv', '$rootScope', '$state',
            function ($scope, $http, $interval, httpSrv, $rootScope, $state) {

                var ctrl = this;
                var intervalPromise;

                this.$onInit = function () {

                    $rootScope.$on('UPDATE:EXPLREQS', function () {
                        getExplreqs();
                        getExplreqsNotRead();
                    });

                    getExplreqs();
                    getExplreqsNotRead();

                    intervalPromise = $interval(function () {
                        var oldExplreqs = $scope.explreqs;
                        getExplreqs();
                        getExplreqsNotRead();
                        if (oldExplreqs != $scope.explreqs && $state.current.name == 'explanationReqList') {
                            $state.reload();
                        }

                    }, ctrl.refresh);

                    $scope.$on('$destroy', function () {
                        $interval.cancel(intervalPromise);
                    });

                };

                function getExplreqs() {
                    httpSrv.http({
                        method: 'GET', ignoreLoadingBar: true,
                        url: '/organizer/explreqs/count'
                    }).then(function successCallback(response) {
                        if (response.status === 401 || response.status === -1) {
                            $interval.cancel(intervalPromise);
                        } else {
                            if (response.data.success) {
                                $scope.explreqs = response.data.result;
                            }
                        }
                    }, function errorCallback() {
                        $interval.cancel(intervalPromise);
                    });
                }

                function getExplreqsNotRead() {
                    httpSrv.http({
                        method: 'GET', ignoreLoadingBar: true,
                        url: '/organizer/explreqs/countNotRead'
                    }).then(function successCallback(response) {
                        if (response.status === 401 || response.status === -1) {
                            $interval.cancel(intervalPromise);
                        } else {
                            if (response.data.success) {
                                $scope.explreqsNotRead = response.data.result;
                            }
                        }
                    }, function errorCallback() {
                        $interval.cancel(intervalPromise);
                    });
                }
            }]
    });