angular.module('uetp').directive("imageScreen", ["$window", function ($window) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            angular.element($window).bind('resize', function () {
                changeImage();
                scope.$apply();
            });

            changeImage();

            function changeImage() {
                var screenWidth = $window.innerWidth;

                if (screenWidth <= 992) {
                    elem.attr('src', attrs.res992);
                } else if (screenWidth <= 1440) {
                    elem.attr('src', attrs.res1440);
                } else if (screenWidth <= 1920) {
                    elem.attr('src', attrs.res1920);
                } else if (screenWidth <= 8000) {
                    elem.attr('src', attrs.res8000);
                } else {
                    elem.attr('src', attrs.res8000);
                }
            }
        }
    };
}])