angular.module('uetp').constant('tariffs', {
    title    : 'Тарифы участников',
    access   : ['admincab', 'accountant'],
    url      : '/accounts/tariff/downloadTariffsXls',
    template : 'app/components/reports/types/tariffsReport.html',
    data     : {
        endDate   : null,
        startDate : null,
        state     : ''
    },
    toMSK    : ['startDate', 'endDate'],
    fileName : function ($filter, data) {
        var date = "";
        if(data.startDate && data.endDate) {
            date = $filter('date')(data.startDate, 'dd.MM.yyyy_HH-mm') + '_' + $filter('date')(data.endDate, 'dd.MM.yyyy_HH-mm');
        }else{
            date = $filter('date')(getServerTimeMsk(), 'dd.MM.yyyy_HH-mm');
        }
        return "Тарифы_участников_УЭТП_" + date+'.xlsx';
    }
});