var filters = angular.module('filters', []);

/**
 * Фильтрация роутинга. Проверка вызываемых состояний на наличие поментки авторизации (authRequired).
 * Если поментка есть и значение true, то необходимо проверить был ли выдан токен
 */
filters.run(['$rootScope', '$state', '$transitions', 'authSrv', '$http', 'desktopSrv', 'loggerUiSrv', 'titleSrv', 'needConfirmStateSrv', 'permissionSrv',
    function ($rootScope, $state, $transitions, authSrv, $http, desktopSrv, loggerUiSrv, titleSrv, needConfirmStateSrv, permissionSrv) {
        /**
         * Проверка ответа сервера
         * @param $transitions - объект отлеживания "перехода"
         * @private
         */
        function _checkResponseState($transitions) {
            var error = $transitions.error();
            if (error.type === 6) { //error type
                var detail = error.detail;
                if (detail.status === 200) {
                    var response = detail.data;
                    $rootScope.$emit('serverErrorEvent', {msg: response.errorMessage || response.message});
                } else if (detail.status === 404) {
                    $rootScope.$emit('serverErrorEvent', {msg: "404 - Запрашиваемый ресурс не найден"});
                } else if (detail.status === 403) {
                    $rootScope.$emit('serverErrorEvent', {msg: "403 - У вас не хватает прав доступа"});
                }
            }
        }
        var whoFirst = '';
        /**
         *  при переходе проверяем авторизацию и если состояние требует авторизованного пользователя,
         *  а активной сессии нет, то перекидываем на home
         * @param $transitions - объект отлеживания "перехода"
         * @private
         */
        function _checkBeforeTransition($transitions) {
            var to = $transitions.$to(),
                from = $transitions.$from(),
                auth = localStorage.getItem('key');
            // запоминаем предыдущее положение скрола
            if (from.name) {
                desktopSrv.setScrollStateConfig('scroll', from.name, desktopSrv.getScrollConfig())
            }
            if (auth) {
                if ($http.defaults.headers.common['Auth-Token'] !== auth) {
                    authSrv.checkedAuth()
                }
            } else if ($http.defaults.headers.common['Auth-Token']) {
                // если нет авторизации, а в http остался токен,
                // то надо выйти полностью, т.к на одном из окон был произведен выход
                authSrv.checkedAuth(false);
                $rootScope.$emit("exitEvent", {});
                return $transitions.router.stateService.target("home");
            }
            if (to.authRequired) {
                if (!auth) {
                    if (to.directTransitionAuth) {
                        var isNotFrom = !from.name; //признак прямого перехода по ссылки
                        var params = $transitions.params();
                        setTimeout(function () {
                            $rootScope.$emit('openLoginEvent', {
                                state:  to.name,
                                param: params
                            });
                        },(isNotFrom ? 1000 : 50));
                    }
                    if (isNotFrom) {
                        return $transitions.router.stateService.target("home");
                    } else {
                        return false;
                    }
                }
                if (!authSrv.securityCheck(to)) {
                    return $transitions.router.stateService.target("home");
                }
            }
            if(whoFirst === ''){
                //Выставляем пизнак кто первый вызвался
                whoFirst = 'onBefore';
            }else{
                //если ты не первый - ничего не делаешь и сбрасываешь признак
                whoFirst = '';
            }

            if (!to.authRequired && typeof Ya == 'object') {
                try {
                    setTimeout(function () {
                        //метрика
                        if (!window.yaCounter) {
                            window.yaCounter = new Ya.Metrika({id: Number(__yandexMetrika)});
                        }
                        window.yaCounter.hit(to, {
                            title: 'ngMetrics',
                            referer: from
                        });
                    }, 0);
                } catch (err) {
                    console.log(err);
                }
            }
            
            if(to.permissionScope){
                if(to.permissionScope==='workflow')
                    return permissionSrv.hasPermission();
                if(to.permissionScope==='contract')
                    return permissionSrv.hasContractPermission();
                else
                    return permissionSrv.hasAnyPermission();
            }

            return true;
        }

        /**
         * Выподняем переход при удачном переходе после проверок в onBefore
         * @param $transitions - объект отлеживания "перехода"
         * @private
         */
        function _initSuccessTransition($transitions) {
            //т.к. есть общие состояния, сохраняем предыдущее состояние при переходе
            $rootScope.prevState = $transitions.$from();
            // логирование переходов
            if ($transitions.$to().stateSendLog) {
                loggerUiSrv.sendLog($transitions.$to().stateSendLog)
            }
            $rootScope.$emit("stateChanged", $transitions.$to().authRequired);
        }

        $transitions.onError({}, function ($transitions) {
            _checkResponseState($transitions);
        });
        $transitions.onBefore({}, function ($transitions) {
            //сбрасываем title
            titleSrv.setTitle('default');
            return _checkBeforeTransition($transitions)
        });
        $transitions.onSuccess({}, function ($transitions) {
            _initSuccessTransition($transitions)

        });
        var to = null;
        $transitions.onCreate({}, function ($transitions) {
            // Если переход состояниея в самого себя - пропускаем и сбрасываем признак whoFirst
            if($transitions.$to().name !== $transitions.from().name){
                to = $transitions.$to();
            }else{
                whoFirst = '';
            }
        });
        /**
         * Следим за переходом и по клику назад в браузере выводим сообщение для state с needConfirm:true
         * Сообщение из needConfirmText или по-умолчанию
         * Для особых случаев можно подгрузить сообщение через сервис, указав параметр needConfirmState
         */
        $rootScope.$on('$locationChangeStart', function (e) {
            if(whoFirst === ''){
                // Выставляем пизнак кто первый вызвался
                whoFirst = 'locationChangeStart';
                if(to){
                    if (to.needConfirm) {
                        var text = to.needConfirmText ? to.needConfirmText : 'Вы уверены, что хотите покинуть эту страницу?\nВведённые данные могут не сохраниться.';
                        // Грузим сообщение из сервисов
                        if(to.needConfirmState){
                            text = needConfirmStateSrv.getNeedConfirmMsg(to.needConfirmState);
                        }
                        var result = window.confirm(text);
                        if (!result) {
                            whoFirst = '';
                            e.preventDefault();
                        }
                    }
                }
            }else{
                //если ты не первый - ничего не делаешь и сбрасываешь признак
                whoFirst = '';
            }
        });
    }]);

