/**
 * Сервис для регистрации заказчика
 */
angular.module('uetp').service('customerRegSrv', ['$state', 'httpSrv',
    function ($state, httpSrv) {

        this.validateCustomerInn = function(inn){
            return httpSrv.http({
                url: '/auth/registration/validateCustomerInn',
                data: {param: inn},
                block: true
            });
        }

        this.generateCustomerXml = function(data){
            return httpSrv.http({
                url: '/auth/registration/generateCustomerXmlForSign',
                data: data,
                block: true
            });
        }

        this.registrationCustomer = function(data){
            return httpSrv.http({
                url: '/auth/registration/registrationCustomer',
                data: data,
                block: true
            });
        }

        this.getRegistrationCustomerById = function(id) {
            return httpSrv.http({
                method:'GET',
                url: `/admin/getCustomerRegistrationById/${id}`
            })
        }

        this.processCustomerRegistration = function(data) {
            return httpSrv.http({
                url: '/auth/registration/processCustomerRegistration/',
                data: data,
                block: true
            })
        }

        this.downloadFileArchive = function(customerRegId) {
            httpSrv.downloadFile(`/fs/api/v1/site/download/customerRegArchive/${customerRegId}`);
        }

    }]);