/**
 * @author: sergeyu
 */
angular.module('uetp').service('sysMessageSrv', ['$state', 'httpSrv',
    function ($state, httpSrv) {
        /**
         * Получить Уведомление по его ID
         */
        this.getMessage = function (id) {
            return httpSrv.http({
                method: 'GET',
                url: '/message/api/v2/readMessage/' + id
            });
        };
        
        this.getIndividualMessage = function() {
            return httpSrv.http({
                method: 'GET',
                url: '/message/api/v2/getIndividualMessage'
            });
        };

        this.getIndividualMessages = function(counterOnly) {
            var param = {
                itemsPerPage: 10,
                page: 0
            };
            if(counterOnly)
                param.addParams = {counterOnly: 'true'};
            return httpSrv.http({
                method: 'POST',
                data: param,
                url: '/message/api/v2/getIndividualMessages'
            });
        };

        this.readIndividualMessage = function(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/message/api/v2/readIndividualMessage/' + id
            });
        };

    }]);