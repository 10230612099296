/**
 * @author: alexandrbu
 * райффайзен
 */
angular.module('uetp')
    .component('rfGuaranteeApp', {
        templateUrl: 'app/components/rfGuaranteeApp/rfGuaranteeApp.html',
        bindings: {
            rfGuaranteeAppData: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv','authSrv', 'profileSrv', 'rfGuaranteeAppSrv', 'alertSrv', 'needConfirmSrv','httpSrv',
        	'constants',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, authSrv, profileSrv, rfGuaranteeAppSrv, alertSrv, needConfirmSrv, httpSrv, 
             constants) {
        	   var ctrl = this;
        	
        	   $scope.constants = constants;
        	   $scope.send = sendFn;
               $scope.addFile = addFileFn;
               $scope.getAttachments = getAttachmentsFn;
               $scope.delRow = delRowFn;
               $scope.closeForm = closeFormFn;
               $scope.goTable = goTableFn;
               $scope.downloadFile = downloadFileFn;
               $scope.userAccess = rfGuaranteeAppSrv.userAccess;
               $scope.changeInGroup = changeInGroupFn;
               
        	   this.$onInit = function () {
        		   
                	$scope.cabType = desktopSrv.getCabType();
                    $scope.auth = authSrv.getAuth();
                    $scope.user = authSrv.getUser();
                    
                    if (!$scope.userAccess($scope.cabType, $scope.user) && !["admincab"].includes($scope.cabType)){
                    	console.log("Отсутствуют права для доступа к разделу");
                    	$state.go('home');
                    	return false;
                    }
                    
                    var isCreate = $state.includes('rfGuaranteeAppCreate');
                    var isOpen = $state.includes('rfGuaranteeAppOpen');
                    var isCopy = $state.includes('rfGuaranteeAppCopy');
                    
                    $scope.guaranteeTypes = rfGuaranteeAppSrv.getGuaranteeTypeList();
                    $scope.documents = angular.copy(rfGuaranteeAppSrv.getDocTypeList());
                    $scope.modelView ={
                       alertObjRfGuaranteeDocumentsApp:{},
                       isReadOnly: false
                    }
                    var participantInfo =  $stateParams.participant ? $stateParams.participant : $scope.user;
                	
                    if(isCreate){
                    	$scope.model = {};
                    	setParticipantFn($scope.model, participantInfo);
                    	setAgentNameFn($scope.model, $scope.user);
                        $scope.model.order = {};
                        $scope.model.order.contactPerson = {
                           fio: $scope.auth.userName,
                           email: $scope.user.email,
                           phone: $scope.user.phone
                        };
                        $scope.model.order.principal = {
                        	 name: participantInfo.fullName,
                             inn: participantInfo.inn,
                             ogrn: participantInfo.ogrn
                        };
                        $scope.model.order.attachments = [];
                    }
             
                    if(isOpen || isCopy){
                    	 if (ctrl.rfGuaranteeAppData && ctrl.rfGuaranteeAppData.data && ctrl.rfGuaranteeAppData.data.success) {
                    		 $scope.model = ctrl.rfGuaranteeAppData.data.result;
                    	 }
                    	 if(isOpen){
                             $scope.modelView.isReadOnly = true;
                    	 }
                    	 if(isCopy){
                    	     setParticipantFn($scope.model, participantInfo);
                    	     setAgentNameFn($scope.model, $scope.user);
                    	     if($scope.model.order.validityFrom){
                    	    	 $scope.model.order.validityFrom = new Date($scope.model.order.validityFrom);
                    	     }
                             if($scope.model.order.validityTo){
                            	 $scope.model.order.validityTo = new Date($scope.model.order.validityTo);
                    	     }
                             if($scope.model.order.estimatedIssue){
                            	 $scope.model.order.estimatedIssue = new Date($scope.model.order.estimatedIssue);
                             }
                    	 }
                    }
                    $scope.submitted = false;
                    $scope.sendDisabled = false;
                };
                
                function setParticipantFn(model, participantInfo) {
                     model.participant = {
                  	    id: participantInfo.id,
                    	inn: participantInfo.inn,
                        ogrn: participantInfo.ogrn,
                        fullName: participantInfo.fullName,
                        typeOrg: participantInfo.typeOrg
                      };
                }
                
                function setAgentNameFn(model, user) {
                  if(authSrv.isUserAgent()){	
                     model.agentName = user.fullName;
                  }
                }
                
                function sendFn() {
                    if ($scope.rfg.form.$invalid) {
                        $scope.submitted = true;
                        var errorMessage; 
                        if ($scope.rfg.form.$error && $scope.rfg.form.$error.required){
                        	errorMessage = 'Необходимо заполнить обязательные поля на форме. ';
                        } else {
                        	errorMessage = 'Введены некорректные значения на форме. ';
                        }
                        $scope.alertObjRfGuaranteeApp = alertSrv.getErrorMsgWithBtn(errorMessage);
                        return false;
                	}
                    
                    var verifyAttachments = true;
                    angular.forEach($scope.documents, function (document) {
                    	if(document.isRequired && !getAttachmentsFn(document.type).length){
                            verifyAttachments = false;
                    	}
                    })
                    if(!verifyAttachments){
                  		$scope.submitted = true;
                        $scope.alertObjRfGuaranteeApp = alertSrv.getErrorMsgWithBtn('Необходимо загрузить обязательные вложения. ');
                        return false;
                    }
                	$scope.sendDisabled = true;
                	rfGuaranteeAppSrv.send($scope.model).then(
                            function (response) {
                                if (response.data && response.data.success) {
                                	//$scope.alertObjRfGuaranteeApp = alertSrv.getSuccessMsg(response.data.message);
                                	$scope.modelView.isReadOnly = true;
                                	goTableFn(response.data.message);
                                }else{
                                	$scope.alertObjRfGuaranteeApp = alertSrv.getErrorMsgWithBtn(
                                			response.data && response.data.message ? response.data.message : 'Ошибка отправки анкеты в Банк');
                                	$scope.sendDisabled = false;
                                }
                                
                            },
                            function (reason) {
                                console.log(reason);
                                $scope.alertObjRfGuaranteeApp = alertSrv.getErrorMsgWithBtn('Ошибка отправки анкеты в Банк');
                                $scope.sendDisabled = false;
                            });
                }
                
                function getAttachmentsFn(type){
                	var attachments = [];
                	angular.forEach($scope.model.order.attachments, function (attach) {
                		if(attach.type == type){
                			attachments.push(attach);
                		}
                	});
                	return attachments;
                }
                
                function addFileFn(files, type) {
                    angular.forEach(files, function (file) {
                      		$scope.model.order.attachments.push(
                                 {
                                     created: file.created,
                                     fileName:file.fileName,
                                     id: file.id,
                                     mimeType: file.mimeType,
                                     sended: file.sended,
                                     size: file.size,
                                     type: type
                                 }
                             )
                         })
                }

                function delRowFn(file) {
                	var index = $scope.model.order.attachments.indexOf(file);
                	$scope.model.order.attachments.splice(index, 1);
                }
                
                function closeFormFn(){
                	$scope.modelView.isReadOnly /*|| !$scope.rfg.form.$dirty*/ ? goTableFn() : needConfirmSrv.call(goTableFn, [], 'Внесенные изменения сохранены не будут');
                }
                
                function goTableFn(saveParam) {
                    if ($scope.user.userRole === 'operator_admin') history.back();
                    else $state.go('rfGuarantees', {saveParam:saveParam, clearConfig:true, participant: $stateParams.participant}, {reload: true});
                }
                
                function downloadFileFn(url, fileName) {
                      httpSrv.downloadFile(url, fileName);
                }
                
                function changeInGroupFn() {
                    if(!$scope.model.order.principal.inGroup){
                    	delete $scope.model.order.principal.inGroupCompanies;
                    }
                }
                

            }]
    });

