/**
 * @author dmitrys
 * Счета
 */
angular.module('uetp')
    .component('invoice', {
        templateUrl: 'app/components/invoice/invoice.html',
        bindings: {
            invoiceData: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv','invoiceSrv','paymentOrderSrv', 'httpSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv,invoiceSrv, paymentOrderSrv, httpSrv) {
                //$scope.invoice = this.invoiceData;
                $scope.invoice = {};
                $scope.paymentService = paymentOrderSrv;
                $scope.process = false; // обработка запроса
                $scope.initInc = initIncFn;
                $scope.successPay = successPayFn;
                $scope.processRefillorRefund = processRefillorRefundFn;
                $scope.oplat = oplatFn;
                $scope.goBack = goBackFn;
                $scope.closeAlert = closeAlertFn;
                $scope.downloadFile = httpSrv.downloadFile;
                function goBackFn() {
                    history.back();
                }
                this.$onInit = function () {
                    var ctrl = this;
                    $scope.invoice = ctrl.invoiceData.invoice;
                    $scope.orgData = ctrl.invoiceData.orgData;
                    $scope.status = invoiceSrv.getStateTitle($scope.invoice.invoiceState);
                    $scope.isAtcomTariff = isAtcomTariff($scope.invoice.tariff.id);
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.modelView = {
                        typeProcess: '', // manualTransfer, fillRefund, other
                        choiceSelect: false, // индикатор отображения выбора обработки
                        btnSuccess: false, // индикатор для отображения кнопок подтвердить и отменить
                        comment:''
                    };
                    $scope.alerts = {
                        alertPanel: '',
                        alertPanelText: ''
                    };
                    $scope.form = {mainForm: {}};
                };

                $scope.viewOrder = function (data) {
                    $state.go('paymentOrdersView', {id: data.id});
                };

                $scope.check2Summ = function () {
                    if($scope.invoice.changedOrderSum && $scope.invoice.changedOrderSum !== $scope.invoice.paymentOrderSum){
                        return true
                    } else {
                        return false
                    }
                };

                function isAtcomTariff(code) {
                    return ['Z11','Z12','Z13','Z14','D11','QS'].includes(code);
                }
                /**
                 * Начать выбор типа обработки
                 */
                function initIncFn() {
                    $scope.modelView.choiceSelect = true;
                    $scope.typeProcess = [
                            {type: 'changeStatusPaid', name: 'Зафиксировать оплату счета по скану п/п'}
                        ];
                }
                /**
                 * Открыть кнопки для подтверждения двейстия пользователя
                 * @param result
                 */
                function successPayFn(result) {
                    $scope.modelView.btnSuccess = result;
                    if (!result) {
                        _clearModelView();
                    }
                }
                function _clearModelView() {
                    $scope.modelView = {
                        comment: '',
                        typeProcess: '',
                        choiceSelect: false, // индикатор отображения выбора обработки
                        btnSuccess: false // индикатор для отображения кнопок подтвердить и отменить
                    };
                }
                /**
                 * Ручное зачисление на ЛС или Иное
                 */
                function processRefillorRefundFn() {
                    var partUrl = '';
                    var data = angular.copy($scope.invoice);
                    if ($scope.modelView.typeProcess === 'changeStatusPaid') {
                        partUrl = '';
                    } else {
                        console.log('Не указан тип обработки!')
                    }
                    httpSrv.http({
                        method: 'POST',
                        url: '/auth/...' + partUrl,
                        data: data
                    }).then(function successCallback(response) {
                        if (response.data.success) {
                            _reloadForm();
                        } else {
                            if (response.data.errorCode === '415') {
                                $scope.form.mainForm.numacc.$setValidity('numacc', false);
                                initAlertPanel(response.data.message, 'errorRequest');
                            }
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    });

                }

                function _reloadForm() {
                    _clearModelView();
                    invoiceSrv.getInvoice($scope.invoice.id).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.invoice = response.data.result
                        }
                    });
                }
                /**
                 * Выставляем на оплату
                 */
                function oplatFn() {
                    $scope.process = true;
                    var invoice = angular.copy($scope.invoice);
                    if ($scope.modelView.typeProcess === 'changeStatusPaid') {
                        invoice.comments = $scope.modelView.comment;
                        invoice.invoiceState = 'acceptedPayment'
                    }
                    invoiceSrv.setInvoiceState(invoice).then(function (response) {
                        successPayFn(false);
                        if(response.data.success){
                            $scope.invoice.invoiceState='acceptedPayment';
                            $state.go('invoiceView', {id: $scope.invoice.id, params: {clearConfig: true}},{reload :true});
                        } else {
                            if (response.data.errorCode === '417') {
                                $scope.modelView.choiceSelect = false;
                                $scope.modelView.typeProcess = 'manualHandleError';
                                initAlertPanel(response.data.message, 'errorRequest');
                            }
                        }

                    }, function (reason) {
                        console.log(reason)
                    })
                }

                function closeAlertFn() {
                    $scope.alerts.alertPanel = '';
                    $scope.alerts.alertPanelText = '';
                }

                function initAlertPanel(text, type) {
                    $scope.alerts.alertPanel = type;
                    $scope.alerts.alertPanelText = text;
                }

            }]
    });
