/**
 * Заявки
 */
angular.module('uetp')
    .component('procedureStatistics', {
        template: '<div ng-include="ctrl.templateUrl">',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'proceduresSrv', 'authSrv', 'alertSrv', '$interval','dialogSrv', 'tablesSrv', 'paginationSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, proceduresSrv, authSrv, alertSrv, $interval, dialogSrv, tablesSrv, paginationSrv) {
                var ctrl = this;
                $scope.procedure = proceduresSrv.getProcedureData().data.result;
                $scope.changeSort = changeSortFn;
                $scope.statisticsByProcedureLoad = statisticsByProcedureLoadFn;
                $scope.claimsStatisticsLoad = claimsStatisticsLoadFn;
                this.$onInit = function () {
                    ctrl.alerts = {};
                    $scope.ctrl = ctrl;
                    $scope.auth = authSrv.getAuth();
                    $scope.getCabType = desktopSrv.getCabType;
                    $scope.ctrl.templateUrl = '';
                    _initTemplate();
                    $scope.totalPages = 0;
                    $scope.paginator = {
                        itemsPerPage: 20,
                        page: 0
                    };
                    var config = paginationSrv.getPaginationStateConfig('pagination', "procedureStatistics");
                    if (config) {
                        $scope.paginator.page = config.page;
                        $scope.paginator.itemsPerPage = config.itemsPerPage;
                    }
                    if (['organizer','cbz'].includes($scope.getCabType())){
                       claimsStatisticsLoadFn();
                    }
                    statisticsByProcedureLoadFn();
                };
                function _initTemplate() {
                    if (['organizer','cbz'].includes($scope.getCabType())) {
                        $scope.ctrl.templateUrl = 'app/components/procedures/statistics/statistics.html';
                    } else {
                        $scope.ctrl.templateUrl = 'app/components/procedures/statistics/statisticsViews.html';
                    }
                }
                function statisticsByProcedureLoadFn() {
                    proceduresSrv.getStatisticsByProcedure($scope.paginator).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.viewStatistics = response.data;
                            $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                        } else {
                            $scope.viewStatistics = false;
                        }
                    }, function errorCallback(response) {
                        console.log(response)
                    });
                }
                function claimsStatisticsLoadFn() {
                    proceduresSrv.getClaimsStatistics().then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.claims = response.data.result;
                        } else {
                            $scope.claims = [];
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response)
                    });
                }

                function changeSortFn($event) {
                    var th = $event.currentTarget;

                    [].forEach.call(document.querySelectorAll(".sortButton"), function (button) {
                        button.classList.remove("boldSort");
                    });
                    th.classList.add("boldSort");

                    if (tablesSrv.changeSort($scope.paginator, th)) statisticsByProcedureLoadFn();
                }
            }]
    });

