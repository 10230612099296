angular.module('uetp').component('cart', {
    templateUrl: 'app/components/cart/views/cart.html',
    controller: ['$scope', '$rootScope', 'cartSrv', 'desktopSrv', 'constants', '$stateParams', '$state', 'authSrv', 'positionSrv', 'orderSrv', '$q', 'dialogSrv',
        function ($scope, $rootScope, cartSrv, desktopSrv, constants, $stateParams, $state, authSrv, positionSrv, orderSrv, $q, dialogSrv) {

                    $scope.state = $state.current.name;

                    this.$onInit = initFn;

                    $scope.goToCart = goToCartFn;
                    $scope.browseCart = browseCartFn;
                    $scope.intervalCountPositions = intervalCountPositionsFn;
                    $scope.getCountPositions = getCountPositionsFn;
                    $scope.deleteCartPosition = deleteCartPositionFn;
                    $scope.deleteCartPositionExtended = deleteCartPositionExtendedFn;
                    $scope.cleanCartPositions = cleanCartPositionsFn;
                    $scope.bulkCheckbox = bulkCheckboxFn;
                    $scope.makeOrder = makeOrderFn;
                    $scope.checkUnChecked = checkUnCheckedFn;
                    $scope.checkMaxVolume = checkMaxVolumeFn;
                    $scope.cartPositionCounter = cartPositionCounterFn;
                    $scope.deleteUnChecked = deleteUnCheckedFn;
                    $scope.getCartSubTotal = getCartSubTotalFn;
                    $scope.getCartTotal = getCartTotalFn;
                    $scope.getDeliveryRegion = getDeliveryRegionFn;
                    $scope.openOrder = openOrderFn;
                    $scope.watchUnChecked = watchUnCheckedFn;
                    $scope.orderCartReset = orderCartResetFn;
                    $scope.deleteCartPositionsAddedToOrder = deleteCartPositionsAddedToOrderFn;
                    $scope.showCart = showCartFn;
                    $scope.btnDeleteEmpty = btnDeleteEmptyFn;
                    $scope.getPositionsDetails = getPositionsDetailsFn;
                    $scope.getPositionsDetailsClose = getPositionsDetailsCloseFn;
                    $scope.btnDeleteClick = btnDeleteClickFn;
                    $scope.addToCartPositionCount = addToCartPositionCountFn;


                    function btnDeleteClickFn(id) {
                        $scope.btnDelete[id] = !$scope.btnDelete[id]
                    }

                    function goToCartFn() {
                        $scope.browseCart();
                        $scope.showCart();
                        $scope.btnDeleteEmpty();
                        window.scrollTo(0, 0);
                    }

                    function btnDeleteEmptyFn() {
                        $scope.btnDelete = {};
                    }

                    function browseCartFn() {
                        cartSrv.loadCart().then(function successCallback(response) {
                            if (response && response.data) {
                                $scope.cart = response.data;
                                cartSrv.cart = $scope.cart;
                                $scope.cartArray = [];
                                cartSrv.cartArray = $scope.cartArray;
                                for (var i = 0; i < $scope.cart.length; i++) {
                                    if ($scope.cart[i].cartsOrganization) {
                                        for (var j = 0; j < $scope.cart[i].cartsOrganization.length; j++) {
                                            $scope.cart[i].cartsOrganization[j].checkbox = true;
                                            $scope.cartArray.push($scope.cart[i].cartsOrganization[j].id);
                                            if ($scope.cart[i].cartsOrganization[j].price) {
                                                $scope.cart[i].cartsOrganization[j].price = Number($scope.cart[i].cartsOrganization[j].price.toFixed(2));
                                            }
                                            if ($scope.cart[i].cartsOrganization[j].summa) {
                                                $scope.cart[i].cartsOrganization[j].summa = Number($scope.cart[i].cartsOrganization[j].summa.toFixed(2));
                                            }
                                            if ($scope.cart[i].cartsOrganization[j].filialId) {
                                                $scope.cart[i].canGroup = true;
                                            }
                                            checkMaxVolumeFn($scope.cart[i]);
                                            checkUnCheckedFn($scope.cart[i]);
                                        }
                                    }
                                }
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        });
                    }

                    function intervalCountPositionsFn() {
                        getCountPositionsFn();
                        if ($scope.countPositions != $scope.countPositionsPrev) {
                            browseCartFn();
                            $scope.countPositionsPrev = angular.copy($scope.countPositions);
                        }
                    }

                    function getCountPositionsFn() {
                        cartSrv.countPositions().then(function successCallback(response) {
                            if (response) {
                                $scope.countPositions = response.data;
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        });
                    }

                    function deleteCartPositionFn(id) {
                        cartSrv.deleteCartPosition(id).then(function successCallback(response) {
                            if (response) {
                                cartSrv.countPositions();
                                browseCartFn();
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        });
                    }

                    function deleteCartPositionExtendedFn(orderItem) {
                        var index = $scope.order.positionCart.indexOf(orderItem);
                        if (index > -1) {
                            $scope.order.positionCart.splice(index, 1);
                        }
                        $scope.btnDelete = {};
                        if ($scope.form.mainForm) {
                            $scope.form.mainForm.$setDirty();
                        }
                        deleteCartPositionFn(orderItem.id);
                    }

                    function cleanCartPositionsFn(cleanListIds) {
                        var ids = cleanListIds.map(function (el) {
                            return el.id;
                        });

                        cartSrv.cleanCartPositions(ids).then(function successCallback(response) {
                            if (response) {
                                cartSrv.countPositions();
                                browseCartFn();
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        });
                    }

                    function bulkCheckboxFn(container, value) {
                        angular.forEach(container.cartsOrganization, function (item) {
                            item.checkbox = value;
                        });
                        checkUnCheckedFn(container);
                    }

                    function makeOrderFn(cart, orgId) {

                        checkMaxVolumeFn(cart);
                        if (!cart.volumeCorrect) {
                            $scope.alertId = 'orderCreateError';
                            $scope.orderCreateErrorText = $scope.volumePositionText;
                            $scope.posErrorOrg = orgId;
                            return;
                        }

                        var ids = cart.cartsOrganization.filter(function (el) {
                            return el.checkbox;
                        }).map(function (el) {
                            return el.id;
                        });

                        orderSrv.checkOfferPosition(ids, false).then(function (result) {
                            checkOfferPosition(result, cart, orgId);
                        });

                        function createOrder(cart){
                            window.scrollTo(0, 0);
                            orderSrv.setPositions(cart);
                            $state.go('orders.create');
                        }

                        function checkOfferPosition(result, cart, orgId){
                            var actualList=[], nonActualList=[], deletedList=[];
                            angular.forEach(cart.cartsOrganization, function (item) {
                                if(item.checkbox){
                                    if((result.deleted && result.deleted.indexOf(item.id) != -1) || ['eshop_purchase_finished','eshop_withdrawn'].includes(item.statusOffer) )
                                        deletedList.push({name: item.name, url: item.parentUrl, okpd2: item.okpd2, id: item.id});
                                    else if(result.changed && result.changed.indexOf(item.id) != -1)
                                        nonActualList.push({name: item.name, url: item.parentUrl, id: item.id});
                                    else
                                        actualList.push({id:item.id})
                                }
                            });

                            if(deletedList.length>0 || nonActualList.length>0) {
                                var text = '';
                                if(deletedList.length>0) {
                                    text += "<span class=\"fa fa-exclamation-triangle cart-dialog-icon\"></span><span class=\"cart-dialog-text\"> Следующие позиции были сняты с публикации или срок действия предложения истек:</span><br/><span class='cart-dialog-text'>"
                                    angular.forEach(deletedList, function (item) {
                                        text += '- <a class="cart-dialog-text" href="' + item.url + '" target="_blank">' + item.okpd2 + ', ' + item.name + '</a><br/>';
                                    });
                                    text += "</span><br/>";
                                }
                                if(nonActualList.length>0) {
                                    text += "<span class=\"fa fa-exclamation-triangle cart-dialog-icon\"></span><span class=\"cart-dialog-text\"> В заказе есть измененные поставщиком позиции:</span><br/><span>"
                                    angular.forEach(nonActualList, function (item) {
                                        text += '- <a class="cart-dialog-text" href="' + item.url + '" target="_blank">' + item.name + '</a><br/>';
                                    });
                                    text += "</span><br/>";
                                }
                                text+='<span class=\'cart-dialog-text\'>Нажимая на кнопку \"Продолжить\" вы соглашаетесь на ';
                                if(deletedList.length>0 && nonActualList.length>0)
                                    text+="формирование заказа с изменениями и удаление из корзины несуществующих позиций.</span>";
                                else if(deletedList.length>0){
                                    text+="удаление из корзины несуществующих позиций.</span>";
                                } else {
                                    text+="формирование заказа с изменениями.</span>";
                                }

                                dialogSrv.openModal('_confirmInfoExt.html', {
                                    message: text,
                                    buttons: [{
                                        title: "Продолжить",
                                        btnClass: "btn-success",
                                        closeValue: false,
                                        action: function () {
                                            var changedIds = nonActualList.map(function (el) {
                                                return el.id;
                                            });
                                            var deletedIds = deletedList.map(function (el) {
                                                return el.id;
                                            });
                                            cleanUpdateCartPositions(cart, changedIds, deletedIds).then(function successCallback(cart) {
                                            	cartSrv.countPositions();
                                            	if(actualList.length === 0){
                                                    browseCartFn();
                                                    window.scrollTo(0, 0);
                                                }else{
                                                    createOrder(cart);
                                                }
                                            }, function errorCallback(response) {
                                                console.log(response);
                                            });
                                        }
                                    },{
                                        title: "Отмена",
                                        btnClass: "btn-light",
                                        closeValue: true
                                    }]
                                });
                            } else if((actualList.length + nonActualList.length)===0){
                                $scope.alertId = 'orderCreateError';
                                $scope.orderCreateErrorText = 'Не выбраны актуальные позиции для формирования заказа.';
                                $scope.posErrorOrg = orgId;
                                return;
                            }else{
                                createOrder(cart);
                            }
                        }
                    }

                    $scope.closeAlert = function () {
                        $scope.alertId = 'dismiss';
                    };

                    function openOrderFn(cart) {
                        $scope.order = {};
                        $scope.disableForm = false;
                        $scope.submitted = false;
                        $scope.orderEditRejection = false;
                        $scope.orderCreateMode = 'create';
                        $scope.orderAction = 'viewOrder';
                        $scope.addOrder = 'hide';
                        $scope.addAttach = 'hide';
                        $scope.navTabs = 'info';
                        $scope.navTabsShow = 'hide';
                        $scope.orderCartReset();
                        $scope.attachReset();

                        //выставляем значения по умолчанию
                        $scope.order = cart;
                        $scope.order.period = 0;
                        $scope.order.supplier.id = cart.supplier.id;
                        $scope.showSupplier = cart.supplier.fullName + ', ИНН ' + cart.supplier.inn + (cart.org.kpp ? ', КПП' + cart.org.kpp : '');
                    }


                    function checkUnCheckedFn(container) {
                        container.checkboxCorrect = false;
                        var item = container.cartsOrganization;
                        for (var i = item.length - 1; i >= 0; i--) {
                            if (item[i].checkbox) {
                                container.checkboxCorrect = true;
                            }
                        }
                    }

                    function checkMaxVolumeFn(container) {
                        container.volumeCorrect = true;
                        var item = container.cartsOrganization;
                        for (var i = 0; i < item.length; i++) {
                            var incorrect = item[i].count > item[i].offerPositionVolume;
                            if (incorrect)
                                $scope.volumePositionText = 'Количество(' + item[i].count + ') в позиции(' + (i + 1) + ') больше максимального объема заказа(' + item[i].offerPositionVolume + ').';
                            container.volumeCorrect = container.volumeCorrect && !incorrect;
                        }
                    }

                    function watchUnCheckedFn(item) {
                        var checked = 0,
                            unchecked = 0;
                        for (var i = item.length - 1; i >= 0; i--) {
                            if (item[i].checkbox) {
                                checked++;
                            }
                            if (!item[i].checkbox) {
                                unchecked++;
                            }
                        }
                        if (checked != 0 && unchecked == 0) {
                            return true
                        }
                        if (checked != 0 && unchecked != 0) {
                            return false
                        }
                        if (checked == 0 && unchecked != 0) {
                            return false
                        }
                    }

                    function deleteUnCheckedFn(item) {
                        var i;
                        for (i = item.length - 1; i >= 0; i--) {
                            if (!item[i].checkbox) {
                                //не удаляем из корзины позиции, только из заказа
                                //$scope.deleteCartPosition(item[i].id);
                                $scope.order.positionCart.splice(i, 1)
                            }
                        }
                        var max = item.length;
                        for (i = 0; i < max; i++) {
                            delete $scope.order.positionCart[i].checkbox
                        }
                    }

                    function getCartSubTotalFn(item, prop) {
                        for (var i = 0, max = item.length; i < max; i++) {
                            var result = 0;
                            result += item[i].position[prop] * item[i].count * 100;
                            result = result / 100;
                            item[i].position.volume = item[i].count;
                            item[i].position.summa = Number(result.toFixed(2));
                        }
                    }

                    function cartPositionCounterFn() {
                        var i;
                        var data = $scope.order;
                        $scope.positionCounter = 0;
                        for (i = 0; i < data.positionCart.length; ++i) {
                            data.positionCart[i].seqNumber = ++$scope.positionCounter;
                        }
                    }

                    function orderCartResetFn() {
                        $scope.order = {
                            supplier: {
                                id: ''
                            },
                            positionCart: [],
                            attachment: []
                        };
                    }

                    function deleteCartPositionsAddedToOrderFn(cart) {
                        var i;
                        var data = cart;
                        for (i = 0; i < data.positionCart.length; ++i) {
                            deleteCartPositionFn(data.positionCart[i].id);
                        }
                    }

                    function cleanUpdateCartPositions(cart,changedIds, deletedIds){
                        if(changedIds.length>0 || deletedIds.length>0){
                            return cartSrv.cleanUpdateCartPositions(deletedIds, changedIds).then(
                                function successCallback(response) {
                                    if (response.data.success) {
                                        var newPositions=response.data.result;
                                        var newCartOrganizations=[];
                                        angular.forEach(cart.cartsOrganization, function (item) {
                                            if(deletedIds.indexOf(item.id)==-1){
                                                if(changedIds.indexOf(item.id)!=-1){
                                                    angular.forEach(newPositions,function (pos) {
                                                        if(pos.id==item.id){
                                                            pos.checkbox= item.checkbox;
                                                            newCartOrganizations.push(pos);
                                                        }
                                                    });
                                                }
                                                else
                                                    newCartOrganizations.push(item);
                                            }
                                        });
                                        cart.cartsOrganization=newCartOrganizations;
                                        return cart;
                                    }
                                });
                        } else {
                            return $q.resolve(cart);
                        }
                    }

                    function showCartFn() {
                        $scope.orderAction = 'showCart';
                        browseCartFn();
                    }

                    function getDeliveryRegionFn(items) {
                        var deliveryRegion = function () {
                            var result = '';
                            var deliveryRegionPrev = '';
                            for (var i = 0, max = items.length; i < max; i++) {
                                if (items[i].deliveryRegion) {
                                    if (deliveryRegionPrev != '') {
                                        if (deliveryRegionPrev != items[i].deliveryRegion) {
                                            return '';
                                        }
                                    } else {
                                        deliveryRegionPrev = items[i].deliveryRegion;
                                        result = deliveryRegionPrev;
                                    }
                                }
                            }
                            return result;
                        };
                        return deliveryRegion()
                    }

                    function getCartTotalFn(items, prop) {
                        var sum = function () {
                            var result = 0;
                            for (var i = 0, max = items.length; i < max; i++) {
                                result += items[i].position[prop] * 100;
                            }
                            result = result / 100;
                            return Number(result.toFixed(2));
                        };
                        return sum()
                    }

                    function getPositionsDetailsFn(id, site) {
                        if ($scope.positionsDetailsId == id) {
                            getPositionsDetailsCloseFn();
                        } else {
                            $scope.positionsDetailsId = id;
                            getPositionsDetailsUpdateFn(id, site)
                        }
                    }

                    function getPositionsDetailsUpdateFn(id, site) {
                        positionSrv.getPositionDetails(id, site).then(function successCallback(response) {
                            if (response.data.success) {
                                $scope.positionsDetails = response.data.result;
                            } else {
                                dialogSrv.showDialogMsg($scope, response.data.message);
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        });
                    }

                    function getPositionsDetailsCloseFn() {
                        $scope.positionsDetailsId = '';
                    }

                    function addToCartPositionCountFn(item, container) {
                        checkMaxVolumeFn(container);
                        if (container.volumeCorrect) {
                            cartSrv.addToCartPositionCount(item.parent.id, item.volume).then(function () {
                                if($scope.positionsDetailsId == item.id) {
                                    getPositionsDetailsUpdateFn(item.id, item.parent.site);
                                }
                            });
                        }
                    }

                    function initFn() {
                        $scope.cabType = desktopSrv.getCabType();
                        $scope.cart = {};
                        $scope.cartArray = [];
                        $scope.btnDelete = {};
                        $scope.countPositions = 0;
                        $scope.countPositionsPrev = 0;
                        $scope.orgRole = localStorage.getItem("orgRole");
                        $scope.shipping = {};

                        if (['customer', 'organizer', 'participant'].includes($scope.cabType)) {
                            cartSrv.countPositions();
                            $scope.browseCart();
                        }
                    }
                }]

    });
