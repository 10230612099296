/**
 * @author dmitrys
 * Вопрос-ответ. Просмотр на открытой части
 */
angular.module('uetp')
    .component('questionAnswerHomeView', {
        templateUrl: 'app/components/help/questionAnswer/questionAnswerHomeView.html',
        bindings: {
            bindingsData: '<'
        },
        controller: ['$scope', '$rootScope', 'constants', '$state', '$stateParams', 'questionAnswerSrv', 'httpSrv', 'dialogSrv', 'alertSrv', 'desktopSrv',
            function ($scope, $rootScope, constants, $state, $stateParams, questionAnswerSrv, httpSrv, dialogSrv, alertSrv, desktopSrv) {

                $scope.cabType = desktopSrv.getCabType();
                $scope.goBack = goBackFn;
                $scope.downloadFile = httpSrv.downloadFile;
                this.$onInit = function () {
                    var ctrl = this;
                    $scope.model = {};
                    if(ctrl.bindingsData){
                        $scope.model = ctrl.bindingsData.data.result;
                    }else{
                       goBackFn();
                    }
                };

                function goBackFn() {
                    $state.go('questionAnswerTable');
                }

            }]

    });

