angular.module('uetp').service('offerSrv', ['httpSrv', '$rootScope', 'dialogSrv', 'propertiesService', 'constants',
    function (httpSrv, $rootScope, dialogSrv, propertiesService, constants) {
        this.deleteOffer = deleteOfferFn;
        this.loadOfferById = loadOfferByIdFn;
        this.createDraftOffer = createDraftOfferFn;
        this.storeOffer = storeOfferFn;
        this.changeStatus = changeStatusFn;
        this.siteTitle = siteTitleFn;
        
        function changeStatusFn(id, status) {
            return httpSrv.http({
                method: 'GET',
                url: '/orders/api/v1/offer/changeStatus/' + id + '/' + status,
                block: true
            })
        }

        function storeOfferFn(data) {
            return httpSrv.http({
                method: 'POST',
                url: '/orders/api/v1/offer/store',
                data: data,
                block: true
            })
        }

        function loadOfferByIdFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/orders/api/v1/offer/' + id
            })
        }

        function deleteOfferFn($scope, id) {
            return httpSrv.http({
                 method: 'DELETE',
                 url: '/orders/api/v1/offer/' + id
            }).then(function successCallback(response) {
                if (!response.data.success) {
                    dialogSrv.showDialogMsg($scope, response.data.message);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function createDraftOfferFn() {
            return httpSrv.http({
                method: 'GET',
                url: '/orders/api/v1/offer/createDraft',
            }).then(function successCallback(response) {
                if (response.data.success) {
                    return response.data.result;
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }
        
        function siteTitleFn(site) {
        	return constants.configProject.type === 'ppk' ? '' :
        	       (site === 'ppk' ? 'Предложение Портала Поставщиков Кузбасса' : 
        	       (site !== 'etp' ? 'Предложение Электронного магазина' : ''));
        }
    }]);



