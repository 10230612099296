angular.module('uetp').constant('ppkTariff', {
    title    : 'Учет подключения тарифных планов ППК',
    access   : ['admincab', 'accountant'],
    url      : '/auth/tariff/downloadPPKTariffsXls',
    template : 'app/components/reports/types/ppkTariffReport.html',
    data     : {
        endDate   : null,
        startDate : new Date(2020, 4, 26, 16)
    },
    toMSK    : ['startDate', 'endDate'],
    fileName : function ($filter, data) {
        const date = $filter('date')(data.startDate, 'dd.MM.yyyy_HH-mm')
            + '_' + $filter('date')(data.endDate ? data.endDate : getServerTimeMsk(), 'dd.MM.yyyy_HH-mm');
        return "Учет_подключений_ТП_ППК_" + date+'.xlsx';
    },
    minMaxOpt: {
        date: {
            minRequired: true,
            maxFillTime: true
        }
    }
});