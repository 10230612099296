//кнопка назад
angular.module('uetp').directive("backBtn", [function () {
    return {
        restrict: 'E',
        templateUrl: 'app/directives/backBtn/backBtn.html',
        scope: {
            callback: '=' // если нужно выполнить доп. действия кроме возврата назад (или перенаправление)
        },
        compile: function () {
            return {
                pre: function (scope) {
                    scope.init();
                }
            }
        },
        controller: ['$scope', 'dialogSrv',
            function ($scope, dialogSrv) {
                var btnObj;
                $scope.init = initFn;
                $scope.goBack = goBackFn;

                function initFn() {
                    btnObj = {text: "OK"};

                    if (typeof $scope.callback == "function") {
                        btnObj.action = $scope.callback;
                    } else {
                        btnObj.action = function () {
                            history.back();
                        }
                    }
                }

                function goBackFn(){
                    dialogSrv.showDialogPrompt($scope,"Внесенные изменения сохранены не будут",btnObj);
                }
            }]
    }
}]);