/**
 * @author: sergeyu
 * Ответ на запрос о разъяснении (Разъяснение)
 */
angular.module('uetp')
    .component('answerExplanation', {
        bindings: {
            openData: '<',
            createData: '<',
            procedureData: '<'
        },
        templateUrl: 'app/components/explanation/answer/answerExplanation.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'explanationSrv', 'cryptography', 'authSrv', 'constants', 'httpSrv', 'alertSrv','needConfirmSrv', 'desktopSrv','proceduresSrv', 'titleSrv',
            function ($scope, $rootScope, $state, $stateParams, explanationSrv, cryptography, authSrv, constants, httpSrv, alertSrv, needConfirmSrv, desktopSrv, proceduresSrv, titleSrv) {
                var ctrl = this;
                $scope.ctrl = ctrl;
                this.$onInit = function () {
                    $scope.cabType =  desktopSrv.getCabType();
                    $scope.auth = authSrv.getAuth();
                    $scope.user = authSrv.getUser();
                    $scope.certError = authSrv.isCertificateError;
                    $scope.constants = constants;
                    $scope.submitted = false;
                    $scope.stateParams = {
                        procType: $stateParams.procType, // тип закупки
                        procId: $stateParams.procId, // идентификатор закупки
                        explReqId: $stateParams.explReqId,  // идентификатор запроса на разъяснение (если создаем разъяснение из запроса)
                        explId: $stateParams.explId,  // идентификатор разъяснения (если открыли на просмотр)
                        isPersonal: $stateParams.isPersonal
                    };

                    $scope.alertId = '';
                    $scope.form = {explanation: {}};
                    if (ctrl.openData) {
                        $scope.model = ctrl.openData.data.result;
                        $scope.model.procedure = ctrl.procedureData;
                        $scope.viewModel = ctrl.openData.data.result;
                        $scope.viewModel.isEdit = false;
                        if ($stateParams.successSend) {
                            $scope.ctrl.alertObjAnswerExplanation = alertSrv.getSuccessMsgWithBtn('Документ отправлен!');
                        }
                    } else if (ctrl.createData) {
                        $scope.model = ctrl.createData.data.result;
                        _initFields();
                        delete  $scope.model.file;
                        $scope.viewModel = {
                            isEdit: true
                        };
                    } else {
                        $scope.model = {};
                        _initFields();
                        $scope.viewModel = {
                            isEdit: true
                        };
                    }

                    if($scope.stateParams.explId && !($scope.user.userProperties && (!$scope.user.userProperties.procedureWorkflow || $scope.user.userProperties.procedureWorkflow === "false")) && 'organizer'===$scope.cabType) {
                        $scope.viewModel.isEdit = false;
                        $scope.viewModel.signAndSendOnly = $scope.model.state.id==='created';
                    }

                    $scope.viewModel.isEisProcedure=proceduresSrv.isEisProcedure($scope.model.procedure);
                    $scope.attachStyle = $scope.viewModel.isEdit ? {
                        'margin-left': '290px'
                    } : {}

                    if ($scope.viewModel.purchaseNumber||$scope.viewModel.procedure?.number||$scope.model.procedure?.number) {
                        titleSrv.setTitle(($scope.viewModel.purchaseNumber||$scope.viewModel.procedure?.number||$scope.model.procedure?.number) + ' | Разъяснение ');
                    }

                    if($scope.viewModel.signAndSendOnly && (!$scope.model.files || $scope.model.files.length===0))
                        $scope.errorMsg = 'Разъяснение должно содержать минимум одно вложение. Для разъяснений, загруженных из внешней системы, добавление вложения производится в этой системе.';
                    else delete $scope.errorMsg;
                };

                function _initFields () {
                    var message;
                    if ($scope.stateParams.procType.toLowerCase() == 'requestquotations') { // Запрос котировок
                        message = 'Разъяснения положений приложений к извещению о закупке';
                    } else {
                        message = 'Разъяснения положений документации о закупке';
                    }
                    $scope.model.desc = message;
                    $scope.model.subject = message;
                    $scope.model.decided = "";
                    $scope.decidedDateOptions = {
                        minDate: $scope.model.procedure?.claimBegins ? new Date($scope.model.procedure.claimBegins) : ($scope.model.decided ?  new Date($scope.model.decided):new Date()),
                        maxDate: getServerTimeMsk()
                    }
                }

                /**
                 * Подпись и отправка
                 */
                $scope.signSendData = function () {
                    if ($scope.auth.certId || $scope.auth.withoutCertEnabled) {
                        var model = angular.copy($scope.model),
                            requestModel = {};
                        requestModel.subject = model.subject;
                        requestModel.desc = model.desc;
                        requestModel.applIds = model.applIds;
                        if (model.decided && typeof model.decided === 'number') {
                            requestModel.decided = model.decided;
                        } else {
                            requestModel.decided = model.decided.getTime();
                        }

                        if ($scope.stateParams.explReqId) {
                            requestModel.requestId = $scope.stateParams.explReqId
                        }
                        if($scope.stateParams.explId) {
                            requestModel.explId = $scope.stateParams.explId
                        }
                        $scope.submitted = true;
                        explanationSrv.getDataForSign($stateParams.procType, $stateParams.procId, requestModel, 'explanations').then(function (response) {
                            if (response.data.success) {
                                if($scope.auth.withoutCertEnabled){
                                    var explanationType = $stateParams.isPersonal ? 'explanationsPersonal' : 'explanations';
                                    explanationSrv.sendSignData($stateParams.procType, $stateParams.procId, requestModel, explanationType).then(function (response) {
                                        if (response.data.success) {
                                            $state.go('openAnswerExplanation', {
                                                procType: $stateParams.procType,
                                                procId: $stateParams.procId,
                                                explId: response.data.result,
                                                successSend: true
                                            }, {location: 'replace'});
                                        } else {
                                            $scope.submitted = false;
                                            $scope.ctrl.alertObjAnswerExplanation = alertSrv.getAlertObj(response);
                                        }
                                    });
                                } else {
                                    cryptography.generateSignature(response.data.result, $scope.auth.certId).then(function (value) {
                                        requestModel.signature = value;
                                        var explanationType = $stateParams.isPersonal ? 'explanationsPersonal' : 'explanations';
                                        explanationSrv.sendSignData($stateParams.procType, $stateParams.procId, requestModel, explanationType).then(function (response) {
                                            if (response.data.success) {
                                                $state.go('openAnswerExplanation', {
                                                    procType: $stateParams.procType,
                                                    procId: $stateParams.procId,
                                                    explId: response.data.result,
                                                    successSend: true
                                                }, {location: 'replace'});
                                            } else {
                                                $scope.submitted = false;
                                                $scope.ctrl.alertObjAnswerExplanation = alertSrv.getAlertObj(response);
                                            }
                                        })
                                    }, function (reason) {
                                        $scope.submitted = false;
                                        if (typeof reason === 'string') {
                                            $scope.ctrl.alertObjAnswerExplanation = alertSrv.getErrorMsgWithBtn("Ошибка подписи документа. " + reason);
                                        } else {
                                            $scope.ctrl.alertObjAnswerExplanation = alertSrv.getErrorMsgWithBtn("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                                        }
                                    });
                            }
                            } else {
                                $scope.submitted = false;
                                $scope.ctrl.alertObjAnswerExplanation = alertSrv.getAlertObj(response);
                            }
                        })
                    } else {
                        $scope.submitted = false;
                        $scope.ctrl.alertObjAnswerExplanation = alertSrv.getErrorMsgWithBtn('У вас не установлен сертификат.');
                    }
                };
                $scope.isModifyAttached = false;
                $scope.uploadSuccess = function (result) {
                    if (!$scope.model.applIds) {
                        $scope.model.applIds = []
                    }
                    if (!$scope.model.files) {
                        $scope.model.files = []
                    }
                    angular.forEach(result, function (item) {
                        $scope.model.files.push({
                            fileName: item.fileName,
                            id: item.id
                        });

                        $scope.model.applIds.push(item.id);
                        $scope.isModifyAttached = true;
                    })


                };
                $scope.deleteAttach = function (index) {
                    $scope.model.files.splice(index, 1);
                    $scope.model.applIds.splice(index, 1);
                    if (!angular.isDefined($scope.model.applIds[0])) {
                        $scope.model.applIds = null;
                        $scope.model.files = null;
                    }
                    $scope.isModifyAttached = true;
                };
                $scope.back = function () {
                    history.back();
                };
                $scope.reset = function () {
                    if($scope.form.explanation.$dirty || $scope.isModifyAttached){
                        needConfirmSrv.call($scope.back,null,'Внесенные изменения сохранены не будут');
                    }else{
                        $scope.back();
                    }
                };
                $scope.downloadFile = function (file) {
                    const url = $scope.stateParams.explId ?
                        '/fs/file/' + $scope.stateParams.procType + '/' + $scope.stateParams.procId + '/explanations/' + $scope.stateParams.explId + '/files/' + file.id
                        :'/fs/file/' + $scope.stateParams.procType + '/' + $scope.stateParams.procId + '/files/' + file.id;
                    httpSrv.downloadFile(url, file.filename);
                };
            }]
    });

