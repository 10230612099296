/**
 * @author dmitrys
 * Форма запроса счета
 */
angular.module('uetp')
    .component('invoiceRequestForm', {
        templateUrl: 'app/components/invoiceRequestForm/invoiceRequestForm.html',
        bindings: {
            callback: '='
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams','dictionarySrv','authSrv','invoiceReqFormSrv','alertSrv',
            function ($scope, $rootScope, $state, $stateParams,dictionarySrv,authSrv,invoiceReqFormSrv,alertSrv) {

                var ctrl = this;
                this.$onInit = function () {
                    $scope.submitted = true;
                    $scope.user = authSrv.getUser();
                    resetForm();
                    $scope.form = {mainForm: ''};
                    $scope.currencyDict = dictionarySrv.getStaticDictByType('currency');
                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                    dictionarySrv.resetPaginatedDict($scope);
                    $scope.refreshDict('rBANKDIVISION', null, true);
                };
                $scope.refreshDict = refreshDictFn;
                $scope.autoFillByTypeDict = autoFillByTypeDictFn;
                $scope.send = sendFn;
                $scope.selectCurrency = selectCurrencyFn;
                $scope.refreshTable = refreshTableFn;

                /*
                * Отправить запрос
                * */
                function sendFn(data) {
                    var model = angular.copy(data);
                    if (model.currency) {
                        model.accountCurrency = model.currency.pattern;
                        delete model.currency;
                    }
                    //обрезаеи ( ) - в номере
                    if (model.phone)
                        model.phone = model.phone.toString().trim().replace(new RegExp('\\(|\\)|-', 'g'), '');
                    invoiceReqFormSrv.makeRequestSpecAccount(model).then(function (response) {
                        if (response.data.success) {
                            if (ctrl.callback) {
                                ctrl.callback();
                            }
                            resetForm();
                        }else{
                            $scope.alertObjInvoiceRequestForm = alertSrv.getAlertObj(response);
                        }
                    }, function (reason) {
                        $scope.alertObjInvoiceRequestForm = alertSrv.getAlertObj(reason, 'Произошла ошибка. Попробуйте выполнить запрос позднее или обратитесь в техническую поддержку.');
                    })
                }
                function resetForm() {
                    delete $scope.model;
                    $scope.model = {
                        fullName:$scope.user.lastName + " " + $scope.user.firstName + " " + $scope.user.middleName, //ФИО Контактного лица
                        // phone:'', //мобильный телефон Контактного лица
                        email:$scope.user.email, //email Контактного лица
                        fz_44:false,//признак спец.счета, применим только для валюты RUB
                        bankAgentId: "comita",//константа
                        inn: $scope.user.inn//инн пользователя
                    };
                }
                /*
                * Выбрали валюту
                * */
                function selectCurrencyFn(select) {
                    if(select.pattern !=='RUB') $scope.model.fz_44 = false;
                }
                /*
                * Обновить таблицу по callback
                * */
                function refreshTableFn(){
                    if(ctrl.callback){
                        ctrl.callback();
                    }
                }
                /**
                 * Автозаполнение полей
                 * @param model - модель
                 * @param object - объект в модели
                 * @param select - выбранное значение
                 */
                function autoFillByTypeDictFn(model, object, select) {
                    dictionarySrv.autoFillByTypeDict($scope, model, object, select);
                }
                function refreshDictFn(object, value, refreshScrolling,needToAdd) {
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling,null,needToAdd);
                }
            }]

    });

