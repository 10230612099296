/**
 * @author sergeyu
 * Две панели с перетягиванием элементов
 */
angular.module('uetp').controller('dragdroppanelCtrl', ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv',
    '$filter', 'alertSrv', 'newsletterCreateSrv', 'needConfirmSrv', 'dictionarySrv', 'drapdropPanelSrv',
    function ($scope, $rootScope, $state, $stateParams, desktopSrv, $filter, alertSrv, newsletterCreateSrv, needConfirmSrv,
              dictionarySrv, drapdropPanelSrv) {

        $scope.dictionary = dictionarySrv.getRemoteData();
        $scope.refreshDict = refreshDictFn;
        this.$onInit = function () {
            /**
             * Получение справочников
             */
            _initDictionaryBase();
        };

        function _initDictionaryBase() {
            dictionarySrv.resetPaginatedDict($scope);
            $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
            $scope.refreshDict('rOKVED2', null, false);
        }

        /**
         * Обновить словарь
         */
        function refreshDictFn(object, value, refreshScrolling, needToAdd) {
            dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling, null, needToAdd);
        }

        $scope.initModel = initModelFn;
        $scope.filterTypeChange = filterTypeChangeFn;
        $scope.filterOKVEDChange = filterOKVEDChangeFn;
        $scope.save = saveFn;
        $scope.goBack = goBackFn;
        $scope.cancel = cancelFn;
        var leftData = null,
            rightData = null,
            filteredParams = function (search) {
                if ($scope.typePanel === 'organizer') {
                    return {
                        'fullName': search,
                        'code': search
                    };
                } else if ($scope.typePanel === 'report') {
                    return {
                        'description': search
                    };
                } else {
                    return {
                        'name': search,
                        'inn': search,
                        'uin': search
                    };
                }
            };


        $scope.model = {
            searchLeft: '',
            searchRight: '',
            dataLeftPanel: [],
            dataRightPanel: []
        };
        $scope.modelView = {
            somePlaceholder: 'Поиск',
            isEdit: false,
            counterL: 0,
            counterR: 0
        };
        $scope.totalPagesLeft = 0;
        $scope.leftPagination = {
            itemsPerPage: 20,
            page: 0
        };
        $scope.totalPagesRight = 0;
        $scope.rightPagination = {
            itemsPerPage: 20,
            page: 0
        };
        $scope.hideBut = false;
        $scope.showFilter = false;

        $scope.filterData = {
            isMsp: null,
            regionOKATOCode: null,
            org_type: null,
            okvedList: null,
            okvedNotExact: null
        };


        /**
         * Загрузка данных
         * @private
         */
        function initModelFn(attrs) {
            //были ли изменения
            drapdropPanelSrv.setChanges(false);

            $scope.cabType = desktopSrv.getCabType;
            if ($scope.sph) {
                $scope.modelView.somePlaceholder = $scope.sph;
            }
            $scope.model = {
                dataLeftPanel: [],
                dataRightPanel: []
            };
            if (angular.isDefined($scope.datapanel)) {
                $scope.model.dataLeftPanel = $scope.datapanel && $scope.datapanel.dataLeft || [];
                $scope.model.dataRightPanel = $scope.datapanel && $scope.datapanel.dataRight || [];
                _checkedData();
                leftData = $scope.model.dataLeftPanel;
                rightData = $scope.model.dataRightPanel;
                _fillCounterLeft();
                _fillCounterRight();
                $scope.totalPagesLeft = Math.ceil($scope.modelView.counterL / $scope.leftPagination.itemsPerPage);
                $scope.totalPagesRight = Math.ceil($scope.modelView.counterR / $scope.rightPagination.itemsPerPage);
            }
            if (angular.isDefined($scope.disabledPanel)) {
                if($scope.disabledPanel === true) {
                    $scope.disabledPanel = true;
                }
            } else
                $scope.disabledPanel = false;
            $scope.getdatapanelDefined = typeof $scope.getdatapanel === "function";
            $scope.showSave = attrs.showSave ? 'true'===attrs.showSave : true;

            if (angular.isDefined($scope.datafilter)) {
                if($scope.datafilter.length) {
                    $scope.showFilter = true;
                    $scope.datafilter.selected = $scope.datafilter[0].name;
                    angular.forEach($scope.datafilter, function (item) {
                        item.items.selected = item.items[0]
                    });

                }
            }
        }

        /**
         * Проверяем какие элементы уже добавлены из левой части в правую
         * @private
         */
        function _checkedData() {
            angular.forEach($scope.model.dataLeftPanel, function (item) {
                item.isActive = $scope.model.dataRightPanel.map(function (el) {
                    return el.id;
                }).indexOf(item.id) >= 0;
            })
        }

        function getParticipantsListRecursionFn() {
            newsletterCreateSrv.getParticipantsListRecursion($scope.filterData, {itemsPerPage: 500, page: 0}).then(function (response) {
                if (response.data.success) {
                    $scope.model.dataLeftPanel = response.data.items;
                    leftData = response.data.items;
                    $scope.modelView.counterL = leftData.length;
                    $scope.totalPagesLeft = Math.ceil($scope.modelView.counterL / $scope.leftPagination.itemsPerPage);
                    $scope.leftPagination.page = 0;
                    _checkedData();
                } else {
                }
            }, function errorCallback(response) {
                console.log(response);
            })
        }

        function filterTypeChangeFn(filter, code) {
            filter.selected === 'all' ? $scope.filterData[code] = null : $scope.filterData[code] = filter.selected;
            getParticipantsListRecursionFn();
        }

        function filterOKVEDChangeFn(filter, code) {
            if (Array.isArray(filter)) {
                $scope.filterData[code] = [];
                for (var i = 0; i < filter.length; i++) {
                    $scope.filterData[code].push(filter[i].id);
                }
                $scope.filterData[code] = $scope.filterData[code].join(',');
            } else {
                $scope.filterData[code] = null;
            }
            getParticipantsListRecursionFn();
        }

        /**
         * Переместить в одну или другую сторону в зависимости от текущего нахождения
         * @param data - списко элементов где происходят действия
         * @param isLeft - признак что мы производим действия в левой панели
         */
        $scope.checkedActive = function (data, isLeft) {
            if (isLeft) {
                if (data.isActive === true) {
                    return;
                }
                data.isActive = !data.isActive;
                rightData.push(angular.copy(data));
                if ($scope.getdatapanelDefined) {
                    $scope.getdatapanel(_selectFilter('', rightData));
                }

            } else {
                angular.forEach(leftData, function (item) {
                    if (item.id === data.id) {
                        item.isActive = false;
                    }
                });
                rightData.splice(rightData.indexOf(data), 1);
            }

            drapdropPanelSrv.setChanges(true);
            $scope.inputSearchLeft(false);
            $scope.inputSearchRight(false);
            _fillPaginationPage()
        };

        function _fillPaginationPage() {
            $scope.totalPagesRight = Math.ceil($scope.modelView.counterR / $scope.rightPagination.itemsPerPage);
        }

        /**
         * Заполнить счетчик записей левой панели
         * list = отфильтрованный список
         * @private
         */
        function _fillCounterLeft() {
            $scope.modelView.counterL = $scope.model.dataLeftPanel.length;
        }

        /**
         * Заполнить счетчик записей правой панели
         * list = отфильтрованный список
         * @private
         */
        function _fillCounterRight() {
            $scope.modelView.counterR = $scope.model.dataRightPanel.length;
        }

        /**
         * Переместить все из левой панели в парвую
         */
        $scope.addAll = function () {
            angular.forEach($scope.model.dataLeftPanel, function (data) {
                if (!data.isActive) {
                    leftData.map(function (el) {
                        if (el.id === data.id) {
                            el.isActive = data.isActive = true;
                        }
                    });
                    rightData.push(data);
                    if ($scope.getdatapanelDefined) {
                        $scope.getdatapanel(_selectFilter('', rightData));
                    }
                }
            });
            drapdropPanelSrv.setChanges(true);
            $scope.inputSearchLeft(false);
            $scope.inputSearchRight(false);
        };
        /**
         * Удалить все из правой и сделать видимыми элементы в левой
         */
        $scope.removeAll = function (all) {
            angular.forEach($scope.model.dataRightPanel, function (data) {
                angular.forEach(leftData, function (item) {
                    if (item.id === data.id) {
                        item.isActive = false;

                    }
                });
            });
            if(all)
                rightData = [];
            else {
                const allAssigned = _selectFilter('', rightData);
                const filtered = _selectFilter($scope.model.searchRight, rightData);

                rightData = allAssigned.filter(value => !filtered.includes(value))
            }
            if ($scope.getdatapanelDefined)
                $scope.getdatapanel(rightData);

            drapdropPanelSrv.setChanges(true);
            $scope.inputSearchLeft(false);
            $scope.inputSearchRight(false);
        };

        /**
         * Поиск по левой панели
         */
        $scope.inputSearchLeft = function (reset) {
            $scope.model.dataLeftPanel = _selectFilter($scope.model.searchLeft, leftData);
            $scope.totalPagesLeft = Math.ceil($scope.model.dataLeftPanel.length / $scope.leftPagination.itemsPerPage);
            if (reset) {
                $scope.leftPagination.page = 0;
            }
            _fillCounterLeft();
        };
        /**
         * Поиск по правой панели
         */
        $scope.inputSearchRight = function (reset) {
            if ($scope.getdatapanelDefined) {
                $scope.getdatapanel(_selectFilter('', rightData));
            }
            $scope.model.dataRightPanel = _selectFilter($scope.model.searchRight, rightData);
            $scope.totalPagesRight = Math.ceil($scope.model.dataRightPanel.length / $scope.rightPagination.itemsPerPage);
            if (reset) {
                $scope.rightPagination.page = 0;
            }
            _fillCounterRight();
        };

        /**
         * фильтр для таблицы
         * @param search
         * @param model
         * @returns {*}
         * @private
         */
        function _selectFilter(search, model) {
            if (search && search.length > 0) {
                return $filter('selectFilter')(model, filteredParams(search));
            } else {
                return model
            }

        }

        function saveFn() {
            $scope.callback(_selectFilter('', rightData)).then(function (response) {
                $scope.alertObjDragdropPanel = alertSrv.getAlertObj(response,'Сохранение произведено успешно!');
            }, function (response) {
                $scope.alertObjDragdropPanel = alertSrv.getAlertObj(response);
            })
        }
        function goBackFn() {
            history.back();
        }
        function cancelFn() {
            if(drapdropPanelSrv.hasChanges()) { 
                needConfirmSrv.call(
                    function(){
                        drapdropPanelSrv.setChanges(false);
                        $scope.goBack();
                    }, [], 'Внесенные изменения сохранены не будут');
            }else{
                drapdropPanelSrv.setChanges(false);
                $scope.goBack();
            }
        }

    }]).directive('dragdroppanel', [function () {
    return {
        restrict: 'AE',
        controller: 'dragdroppanelCtrl',
        scope: {
            datapanel: '=',
            sph: '@',
            typePanel: '@',
            callback: '=',
            getdatapanel: '=',
            datafilter: '=',
            disabledPanel: '<',
            showSave:'=?showSave',
            showClose:'='
        },
        templateUrl: 'app/directives/dragdropPanel/dragdropPanel.html',
        compile: function () {
            return {
                pre: function (scope, el, attrs) {
                    scope.initModel(attrs);
                }
            }
        }
    };
}]);