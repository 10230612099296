//кнопка назад
angular.module('uetp').directive("subscribe", [function () {
    return {
        restrict: 'E',
        templateUrl: 'app/directives/subscribe/subscribe.html',
        scope: {
	        filter: '='
        },
        compile: function () {
            return {
                pre: function (scope) {
                    scope.init();
                }
            }
        },
        controller: ['$scope', 'subscriptionSrv','alertSrv', 'authSrv', '$rootScope', 'profileSrv',
            function ($scope, subscriptionSrv, alertSrv, authSrv, $rootScope, profileSrv) {
                $scope.init = initFn;
                $scope.auth = authSrv.getAuth();
                $scope.wrongSubscriptionEmail = false;

                
                function initFn() {
                    if($scope.auth && $scope.auth.userId)
                        profileSrv.loadOrganizationInfo().then((userData) => {
                            if (userData.data.success)
                                $scope.userEmail = userData.data.result.email;
                            initSubscription();
                        })
                    else
                        initSubscription();

                    $scope.filterSelected = filterSelectedFn;
                    $scope.subscribeToggle = subscribeToggleFn;
                    $scope.subscribe = subscribePurchaseFn;
                }

                $scope.$watch('filter', function(newValue) {
                  if (!newValue || 
                      (!newValue.customer || newValue.customer.length == 0) && 
                      (!newValue.lots__items__okpd2Code || newValue.lots__items__okpd2Code.length == 0)){
                        $scope.subscribeOpened = false;
                        initSubscription();
                  }
                  if (!newValue || 
                      (!newValue.lots__items__okpd2Code || newValue.lots__items__okpd2Code.length == 0)){
                        newValue.okpd2Group = false;
                  }
                }, true);

                $scope.subscribeCloseAlert = function () {
	                 $scope.alertId = 'dismiss';
                };
                function filterSelectedFn() {
                   var selected = $scope.filter && ($scope.filter.lots__items__okpd2Code && $scope.filter.lots__items__okpd2Code.length > 0 || 
                          $scope.filter.customer && $scope.filter.customer.length > 0) ;
                   return selected;
                }
                function subscribeToggleFn(value) {
                  $scope.subscribeOpened = value;
                  initSubscription();
                }
                function subscribePurchaseFn() {
                    if($scope.process) return;
                    else $scope.process = true;
                   let model = {};
                   model = angular.copy($scope.subscription);
                   model.docType = 'purchase';
                   delete model.agrement;
                   if($scope.filter.customer && $scope.filter.customer.length > 0){
                       model.customer = angular.copy($scope.filter.customer);
                   }
                   if($scope.filter.lots__items__okpd2Code && $scope.filter.lots__items__okpd2Code.length > 0){
                       model.okpd2 = angular.copy($scope.filter.lots__items__okpd2Code);
                       if($scope.filter.okpd2Group){
                        angular.forEach(model.okpd2, function (item) {
	                         item.code = item.code + '%';
                         });
                       }
                   }
 	               subscriptionSrv.subscribePurchase(model).then(function (response) {
                        if (response.data.success) {
                           subscribeToggleFn(false);
                           $scope.process = false;
                           $scope.alertId = 'saveSubscriptionSuccess';
                        }else{
                           $scope.alertId = 'saveSubscriptionFailed';
                        }
                       $scope.process = false;
	                }, function (reason) {
                        $scope.alertId = 'saveSubscriptionFailed';
                        console.log(reason);
                        $scope.process = false;
                    })
                }
                function initSubscription() {
	               $scope.subscription = {};
	               if($scope.auth && $scope.auth.userId){
                      $scope.subscription.email = $scope.userEmail ? $scope.userEmail : $scope.auth.email;
                      $scope.subscription.userId = $scope.auth.userId;
                      $scope.subscription.userFio = $scope.auth.userName;
                      $scope.subscription.orgId = $scope.auth.orgId;
                   }
                }
                $rootScope.$on('subscribeCheckEmail', (event, value, error) => {
                    $scope.wrongSubscriptionEmail = (value.length>0 && error?.value);
                });
            }]
    }
}]);