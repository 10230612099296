/**
 * @author: sergeyu
 * Сервис по запуску Личных кабинетов
 */
angular.module('uetp').service('desktopSrv', ['$window', '$rootScope', '$http', '$injector', '$state', 'storageSrv', 'authSrv', 'permissionSrv',
    function ($window, $rootScope, $http, $injector, $state, storageSrv, authSrv, permissionSrv) {
        var services = this,
            cabType = '',
            cabTypeRolesMatch={
                "operator_admin":"admincab",
                "operator_accountant":"accountant",
                "operator_inspector":"inspector",
                "operator_support_rw":"support",
                "operator_support_ro":"support",
                "operator_manager":"manager"
            };

        services.browserType = {};
        services.dateMsk = dateMskFn;
        services.dateMskWithoutTime = dateMskWithoutTimeFn;
        services.removeKeys = removeKeysFn;
        services.setCookie = setCookieFn;
        services.getCookie = getCookieFn;
        services.notInsertImg = notInsertImgFn;

        /**
         * Запуск кабинета по данным авторизации
         * @param auth - данные по аунтентификации
         * @param stateChange - сменить состояние при выставлении типа кабинета
         */
        this.initCabinet = function (auth, stateChange) {
            var state = auth ? _getStateFn(auth.orgRole, auth.userRole ? auth.userRole[0] : "") : _getStateFn();
            if (stateChange) {
                $state.go(state);
            }
        };
        /**
         * Проверка на bankGuaranteesParams (открытие новой вкладки)
         */
        this.checkBankGuaranteesParams = function () {
            var guarantees = JSON.parse(localStorage.getItem("bankGuaranteesParams"));
            if (guarantees) {
                localStorage.removeItem("bankGuaranteesParams");
                $state.go('bankGuarantees', guarantees);
            }
        };
        /**
         * Получить тип кабинета
         * @param cb {function}
         * @returns {string}
         */
        this.getCabType = function (cb) {
            if (cabType === '') {
                if (cb) authSrv.getAuth(auth=>{
                    services.initCabinet(auth);
                    cb(cabType);
                }); else services.initCabinet(authSrv.getAuth());
            } else if (cb) cb(cabType);
            return cabType;
        };


        /**
         * Получить нужный тип ЛК
         * @param orgRole - тип организации
         * @param userRole - роль пользователя
         * @returns {string} - состояние
         * @private
         */
        function _getStateFn(orgRole, userRole) {
            var state = "home";
            if (userRole === "organizer_cbz") {
                _changeCabType("cbz");
                state = 'procedures';
            } else if (userRole === "operator_manager") {
                _changeCabType(cabTypeRolesMatch[userRole]||"manager");
                state = 'allProcedures';
            } else if (orgRole === "participant") {
                _changeCabType("participant");
                state = 'myProcedures';
            } else if (orgRole === "admin") {
                _changeCabType(cabTypeRolesMatch[userRole]||"admincab");
                state = userRole == 'operator_accountant' ? 'paymentOrders' : 'participant';
            } else if (orgRole === "organizer") {
                _changeCabType("organizer");
                state = permissionSrv.hasPermission() ? 'notices' : 'procedures';
            } else if (orgRole === "agent") {
                _changeCabType("agent");
                state = 'bg';
            } else if (orgRole === "customer") {
                _changeCabType("customer");
                state = 'profile';
            } else if (['bank_admin', 'bank_user'].includes(userRole)) {
                _changeCabType("bank");
                state = authSrv.getUser().code == 'VTB' ? 'bankvtb' : 'lokobank';
            } else {
                _changeCabType("home");
            }
            return state;
        }

        function _changeCabType(type) {
            if (cabType !== type) {
                cabType = type;
                $rootScope.$emit('cabTypeChange', type);
            }
        }

        this.getUrlParamByName = function (name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        };
        /**
         * Прокрутить страницу вверх
         */
        this.scollTop = function () {
            window.document.body.scrollTop = 0;
            window.document.documentElement.scrollTop = 0;
            services.setScrollStateConfig('scroll', $state.current.name, {bS: 0, dS: 0});
        };
        this.scrollBottom = function () {
            window.scrollTo(0, document.body.scrollHeight);
        };
        /**
         * Прокрутить по сохраненным параметрам
         */
        this.scroll = function () {
            var config = services.getScrollStateConfig('scroll', $state.current.name);
            setTimeout(function () {
                window.document.body.scrollTop = 0;
                window.document.documentElement.scrollTop = 0;
                if (config) {
                    window.document.body.scrollTop += config.bS;
                    window.document.documentElement.scrollTop += config.dS;
                }

            }, config ? 500 : 0)

        };
        /**
         * Работа со скролом запоминание положения
         * параметры смотреть в storageSrv
         */
        this.setScrollStateConfig = function (guid, type, data) {
            storageSrv.setStateConfig(guid, type, data);
        };
        this.getScrollStateConfig = function (guid, type) {
            return storageSrv.getStateConfig(guid, type);
        };
        this.getScrollConfig = function () {
            return {bS: window.document.body.scrollTop, dS: window.document.documentElement.scrollTop}
        };

        /**
         * Плавное прокручивание
         * @param element - элемент на который будет совершаться прокрутка
         * @param params - параметры прокрутки. Гуглить scrollIntoViewOptions
         */
        this.scrollIntoViewFn = function (element, params) {
            if (element) {
                if (services.browserType.isChrome || services.browserType.isFF || services.browserType.isOpera) {
                    setTimeout(function () {
                        element.scrollIntoView(params);
                    }, 100)
                } else {
                    element.scrollIntoView && element.scrollIntoView(params);
                }
            }
        };

        function _browserDetected() {
            var ua = navigator.userAgent;
            services.browserType = {
                isChrome: ua.search(/Chrome/) > 0,
                isFF: ua.search(/Firefox/) > 0,
                isOpera: ua.search(/Opera/) > 0,
                isSafari: ua.search(/Safari/) > 0,
                isMSIE: (ua.search(/MSIE/) > 0 || typeof window.document.documentMode === "number")
            };
        }

        /**
         * Вычитаем таймзону и отправляем как по UTC. Серврер прибавит +3
         * @returns {number}
         */
        function dateMskFn() {
            var dateNow = new Date();
            return new Date((dateNow.getTime()) + (60000 * dateNow.getTimezoneOffset())).getTime();
        }

        /**
         * Получить дату без времени по мск. Серврер прибавит +3
         */
        function dateMskWithoutTimeFn() {
            var date = new Date().setHours(0, 0, 0, 0);
            return new Date(date).setUTCHours(new Date(date).getUTCHours()+(new Date().getTimezoneOffset()/(-60)-3), 0);
        }
        _browserDetected();

        /**
         * Удаление лишних атрибут объектов.
         * @param obj
         * @param keys
         */
        function removeKeysFn(obj, keys){
            var index;
            for (var prop in obj) {
                if(obj.hasOwnProperty(prop)){
                    switch(typeof(obj[prop])){
                        case 'string':
                            index = prop.indexOf(keys);
                            if(index > -1){
                                delete obj[prop];
                            }
                            break;
                        case 'number':
                            index = prop.indexOf(keys);
                            if(index > -1){
                                delete obj[prop];
                            }
                            break;
                        case 'boolean':
                            index = prop.indexOf(keys);
                            if(index > -1){
                                delete obj[prop];
                            }
                            break;
                        case 'object':
                            index = prop.indexOf(keys);
                            if(index > -1){
                                delete obj[prop];
                            }else{
                                services.removeKeys(obj[prop], keys);
                            }
                            break;
                    }
                }
            }

        }

        this.isBGEnabled = function () {
            return $rootScope.properties && $rootScope.properties['bankguarantee.enabled']==="true";
        };
        /**
         * Падеж слов
         */
        this.casesOfwords = function () {
            var cases = [2, 0, 1, 1, 1, 2];
            var endingsSubFunction = function (titles, number) {
                number = Math.abs(number);
                return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
            };
            return function (_titles) {
                if (arguments.length === 1) {
                    return function (_number) {
                        return endingsSubFunction(_titles, _number)
                    }
                } else {
                    return endingsSubFunction.apply(null, arguments)
                }
            }
        };

        function setCookieFn(name, value, options) {
            options = options || {};

            var expires = options.expires;
            if (typeof expires === "number" && expires) {
                var d = new Date();
                d.setTime(d.getTime() + expires * 1000);
                expires = options.expires = d;
            }
            if (expires && expires.toUTCString) {
                options.expires = expires.toUTCString();
            }

            value = encodeURIComponent(value);

            var updatedCookie = name + "=" + value + ";path=/";

            for (var propName in options) {
                updatedCookie += "; " + propName;
                var propValue = options[propName];
                if (propValue !== true) {
                    updatedCookie += "=" + propValue;
                }
            }
            updatedCookie += "; SameSite=Lax; Secure=true"
            document.cookie = updatedCookie;
        }

        function getCookieFn(name) {
            var value = "; " + document.cookie;
            var parts = value.split("; " + name + "=");
            if (parts.length === 2) return parts.pop().split(";").shift();
        }

        /**
         * Отмена втсавки изображения в textAngular
         * @param e - событие
         */
        function notInsertImgFn(e) {
            e.stopPropagation();
            e.preventDefault();
        }
    }]);
