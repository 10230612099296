/**
 * для разделения файлов по секциям используется section
 *
 * cabType и userRole - указываются кабинеты и типы пользователей, для которых доступен документ (all - доступно везде)
 */

angular.module('uetp').constant('documentsList', {
    UETP: [
        {name: "Регламент работы", file: "reglament_etp.pdf", section: "common", cabType: "all", userRole: "all"},
        {name: "Условия использования тарифных планов", file: "tariffs_etp.pdf", section: "common", cabType: "all", userRole: "all"},
        {name: "Регламент работы с 26.04.2023", file: "reglament_etp_26042023.pdf", section: "archive", cabType: "all", userRole: "all"},
        {name: "Регламент работы с 19.09.2022", file: "reglament_uetp.pdf", section: "archive", cabType: "all", userRole: "all"},
        {name: "Регламент работы с 01.01.2021", file: "reglament_uetp_01012021.pdf", section: "archive", cabType: "all", userRole: "all"},
        {name: "Регламент работы до 31.12.2020", file: "reglament_uetp_comita.pdf", section: "archive", cabType: "all", userRole: "all"},
        {name: "Условия использования тарифных планов с 01.01.2021", file: "tariffs_uetp.pdf", section: "archive", cabType: "all", userRole: "all"},
        {name: "Условия использования тарифных планов до 31.12.2020", file: "tariffs_uetp_comita.pdf", section: "archive", cabType: "all", userRole: "all"},
        {name: "Временный регламент работы для нужд бюджетных и автономных учреждений Вологодской области до 31.12.2020", file: "reglament_temp_VO.pdf", section: "archive", cabType: "all", userRole: "all"},
        {name: "Настройка рабочего места", file: "rp_settings_etp.pdf", section: "common", cabType: "all", userRole: "all"},
        {name: "Руководство по регистрации и аккредитации Участника", file: "rp_registration_etp.pdf", section: "common", cabType: "all", userRole: "all"},
        {name: "Руководство по регистрации Заказчика", file: "rpz_registration_etp.pdf", section: "common", cabType: "all", userRole: "all"},
        {name: "Руководство Организатора", file: "rp_organizer_etp.pdf", section: "common", cabType: "organizer,support,cbz,admincab", userRole: "operator_support_ro,organizer_cbz,organizer_admin,organizer_user"},
        {name: "Руководство Участника", file: "rp_participant_etp.pdf", section: "common", cabType: "all", userRole: "all"},
        {name: "Пример платежного поручения для ЗМО, задатков и обеспечения заявки", file: "atcom_payment_order.pdf", section: "common", cabType: "all", userRole: "all"},

        {name: "Положение о закупке товаров, работ, услуг для нужд ОАО «РЖД»", file: "Polozhenie_OAO_RZD.pdf", section: "rzd", cabType: "all", userRole: "all"},
        {name: "Примерные формы документации о закупке", file: "sample_docs.zip", section: "rzd", cabType: "all", userRole: "all"},

        {name: "Постановление Правительства Вологодской области № 972 от 21.10.2019", file: "statement_vo_972_21_10_2019.pdf", section: "vologda", cabType: "all", userRole: "all"},
        {name: "Типовое положение о закупке товаров, работ, услуг, утвержденное приказом управления образования мэрии города Череповца", file: "polozhenie_Cherepovec.pdf", section: "vologda", cabType: "all", userRole: "all"},
        {name: "Положение о закупке товаров, работ, услуг МАУ «Центр социального питания» от 29.04.2019", file: "polozhenie_VO_29_04_2019.pdf", section: "vologda", cabType: "all", userRole: "all"},
        {name: "Внесение изменений в положение о закупке товаров, работ, услуг МАУ «Центр социального питания» от 14.11.2019", file: "izmeneniya_polozhnie_VO_14_11_2019.pdf", section: "vologda", cabType: "all", userRole: "all"},
        {name: "Положение о закупке товаров, работ, услуг МАУ «Центр питания Тотемского муниципального района» от 11.11.2019", file: "polozhenie_MAU_11_11_2019.pdf", section: "vologda", cabType: "all", userRole: "all"},
        {name: "Пример платежного поручения для задатков до 31.12.2020", file: "comita_payment_order.pdf", section: "archive", cabType: "all", userRole: "all"}
    ],
    PPK: [
        {name: "Регламент работы", file: "reglament_etp.pdf", section: "common", cabType: "all", userRole: "all"},
        {name: "Условия использования тарифных планов", file: "tariffs_etp.pdf", section: "common", cabType: "all", userRole: "all"},
        {name: "Регламент работы с 26.04.2023", file: "reglament_etp_26042023.pdf", section: "archive", cabType: "all", userRole: "all"},
        {name: "Регламент работы с 19.09.2022", file: "reglament_uetp.pdf", section: "archive", cabType: "all", userRole: "all"},
        {name: "Регламент работы с 01.01.2021", file: "reglament_uetp_01012021.pdf", section: "archive", cabType: "all", userRole: "all"},
        {name: "Регламент работы до 31.12.2020", file: "reglament_uetp_comita.pdf", section: "archive", cabType: "all", userRole: "all"},
        {name: "Условия использования тарифных планов с 01.01.2021", file: "tariffs_uetp.pdf", section: "archive", cabType: "all", userRole: "all"},
        {name: "Условия использования тарифных планов до 31.12.2020", file: "tariffs_uetp_comita.pdf", section: "archive", cabType: "all", userRole: "all"},
        {name: "Настройка рабочего места", file: "rp_settings_ppk.pdf", section: "common", cabType: "all", userRole: "all"},
        {name: "Руководство по регистрации и аккредитации Участника", file: "rp_registration_ppk.pdf", section: "common", cabType: "all", userRole: "all"},
        {name: "Руководство по регистрации Заказчика", file: "rpz_registration_etp.pdf", section: "common", cabType: "all", userRole: "all"},
        {name: "Руководство Организатора", file: "rp_organizer_ppk.pdf", section: "common", cabType: "organizer,support,cbz,admincab", userRole: "operator_support_ro,operator_admin,organizer_admin,organizer_user"},
        {name: "Руководство Участника", file: "rp_participant_ppk.pdf", section: "common", cabType: "all", userRole: "all"},
        {name: "Пример платежного поручения для ЗМО, задатков и обеспечения заявки", file: "atcom_payment_order.pdf", section: "common", cabType: "all", userRole: "all"},
        {name: "Пример платежного поручения для задатков до 31.12.2020", file: "comita_payment_order.pdf", section: "archive", cabType: "all", userRole: "all"}
    ]
});