/**
 * Компонент формы регистрации Заказчика
 */
var OKSM_RUS = '643';

angular.module('uetp')
    .component('customerRegistration', {
        templateUrl: 'app/components/customer/registration/customerRegistration.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'constants', 'authSrv', 'desktopSrv', 'dialogSrv', 'profileSrv', 'cryptography', 'dictionarySrv', 'httpSrv', 'alertSrv', 'customerRegSrv', 'timeout', '$filter',
            function ($scope, $rootScope, $state, $stateParams, constants, authSrv, desktopSrv, dialogSrv, profileSrv,  cryptography, dictionarySrv, httpSrv, alertSrv, customerRegSrv, timeout, $filter) {

                $scope.reset = resetFn;
                $scope.fillSelect = fillSelectFn;
                $scope.checkedAddress = checkedAddressFn;
                $scope.register = registerFn;
                $scope.constants = constants;
                $scope.changeOksm = changeOksmFn;
                $scope.changeOkato = changeOkatoFn;
                $scope.uploadCert = uploadCertFn;
                $scope.refreshDict = refreshDictFn;
                $scope.isRequiredAllowedCheckbox = isRequiredAllowedCheckboxFn;
                $scope.isErrorAllowedCheckbox = isErrorAllowedCheckboxFn;
                $scope.isFormValid = isFormValidFn;
                $scope.attachDoc = attachDocFn;
                $scope.deleteAttach = deleteAttachFn;
                $scope.downloadFile = downloadFileFn;

                this.$onInit = function () {
                    $scope.dictSrv = dictionarySrv;
                    $scope.processing = false;
                    $scope.middleNameCert = "";
                    _initDict(['okcm', 'fias_region'], false);
                    $scope.form = {registerForm: {}};
                    $scope.status = {};
                    $scope.isChecked = true;
                    $scope.active = {};
                    $scope.cert = {};
                    $scope.prevRealAddress =  {country: OKSM_RUS, countryName: 'РОССИЙСКАЯ ФЕДЕРАЦИЯ'};
                    $scope.cadesPlugin = {};
                    $scope.submitted = false;
                    $scope.processing = false;
                    $scope.dictionary = dictionarySrv.getRemoteData();
                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                    $scope.docs={
                        accreditation_founder:{},
                        accreditation_proxy:{},
                        accreditation_other:{}
                    };
                    initCustomerReg();
                    resetFn();

                    document.getElementById("container").addEventListener("keydown", function (e) {
                        const keyCode = e.which || e.keyCode;
                        if (keyCode == 9 && e.target.tagName == "INPUT") {
                            window.scrollTo(0, e.target.getBoundingClientRect().top + window.pageYOffset - 200);
                        }
                    })
                };

                function _initDict(dictList, reload) {
                    angular.forEach(dictList, function (name) {
                        dictionarySrv.getDictionaryRemote(name, reload)
                    });
                }

                function changeOksmFn(object, select) {
                    $scope.customerReg.document.orgInfo[object].region = '';
                    $scope.customerReg.document.orgInfo[object].regionName = '';
                    $scope.customerReg.document.orgInfo[object].countryName = select.$select.selected ? select.$select.selected.ncm : "";
                    if (select.$select.selected.kokcm3 === OKSM_RUS) $scope.customerReg.document.orgInfo.regAddress.postIndex = '';
                }

                function changeOkatoFn(object, select) {
                    $scope.customerReg.document.orgInfo[object].regionName = select.$select.selected ? (select.$select.selected.formalname + " " + select.$select.selected.shortname) : "";
                }

                /**
                 * Сброс регистрационных данных
                 */
                function resetFn() {
                    $scope.active = {};
                    $scope.cert = {};
                    $scope.customerReg = {};
                }

                /**
                 * Инициализация селекта сертификатов которые находятся в личном хранилище
                 */
                function fillSelectFn() {
                    if(!authSrv.getAuth().withoutCertEnabled) {
                        resetFn();
                        cryptography.ready.then(function () {
                            const selectCert = document.getElementById("cert");
                            if (selectCert) {
                                cryptography.initPluginWorking().then(function (plugin) {
                                    plugin.initHtmlChecker();
                                    fillAllCertByStore(plugin, 'cert');
                                });

                                selectCert.addEventListener('change', certChangeHandler);
                                bindFormEnterPress();
                            }
                        }, function(error){
                            return cryptography.errorHandler(error).catch(function () {});
                        });
                    }
                }

                fillSelectFn();

                /**
                 * Совпадает ли адрес с юридическим
                 * @param checked
                 */
                function checkedAddressFn(checked) {
                    if (checked) {
                        $scope.prevRealAddress = angular.copy($scope.customerReg.document.orgInfo.realAddress);
                        delete $scope.customerReg.document.orgInfo.realAddress;
                    } else {
                        $scope.customerReg.document.orgInfo.realAddress = angular.copy($scope.prevRealAddress);
                    }
                    $scope.isChecked = !$scope.isChecked;
                    $scope.form.registerForm.$setDirty();
                }

                /**
                 * Регистрация организации на серверер
                 */
                function registerFn() {
                    //ie 11 баг снятие фокуса
                    window.focus();
                    if ($scope.processing) return;
                    $scope.processing = true;
                    setTimeout(function () {
                        sendReg();
                    }, 1000);

                    function sendReg() {
                        $scope.submitted = true;
                        if (!$scope.form.registerForm.$valid) {
                            $scope.alertObjRegistrationSave = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');

                            $scope.processing = false;
                            timeout($scope);
                            return;
                        }
                        if ($scope.customerReg.document.allowed223 !=='true' && $scope.customerReg.document.allowed44 !=='true' && $scope.customerReg.document.allowedCommercial !=='true'){
                            $scope.alertObjRegistrationSave = alertSrv.getErrorMsg('Должно быть заполнено хотя бы одно из полей: Участие в закупках по 223-ФЗ, Участие в закупках по 44-ФЗ, Участие в коммерческих торгах.');
                            return;
                        }

                        if ($scope.isChecked) {
                            delete $scope.customerReg.document.orgInfo.realAddress;
                        }

                        const consentProcessingEl = document.getElementById("consentProcessing");
                        const consentDisseminationEl = document.getElementById("consentDissemination");
                        $scope.customerReg.document.consentProcessing = $filter('stripHTML')(consentProcessingEl.innerText);
                        $scope.customerReg.document.consentDissemination = $filter('stripHTML')(consentDisseminationEl.innerText);
                        $scope.customerReg.document.createDate = $filter('date')(new Date(), 'dd.MM.yyyy HH:mm');
                        customerRegSrv.generateCustomerXml($scope.customerReg.document).then(function successCallback(response) {
                            if (response.data.success) {
                                return cryptography.generateSignature(response.data.message, $scope.cert.thumbPrint).then(function (value) {
                                    $scope.signature = document.getElementById("signature").value = value;
                                    prepareRegDataFn($scope.signature);
                                    customerRegSrv.registrationCustomer($scope.customerReg).then(function successCallback(response) {
                                        if (response.data.success) {
                                            dialogSrv.showDialogMsgHtml($scope, `Сформирован запрос на регистрацию.\n`+
                                            `На электронный адрес ${response.data.result} отправлено письмо. Для подтверждения адреса электронной почты перейдите по указанной в письме ссылке в течение следующих 24 часов.`,
                                                function () {
                                                    $state.go('home');
                                                });
                                            $scope.reset();
                                        } else {
                                            dialogSrv.showDialogMsg($scope, response.data.message);
                                            $scope.alertObjRegistrationSave = alertSrv.getErrorMsg(response.data.message || response.data.errorMessage);
                                        }
                                        $scope.processing = false;
                                    });

                                }, function errorCallback(response) {
                                    if (response.indexOf('0x800B010A') >= 0) {
                                        $scope.alertObjRegistrationSave = alertSrv.getErrorMsg('Нет доверия к этому корневому сертификату центра сертификации, поскольку он не найден в хранилище доверенных корневых сертификатов центров сертификации.');
                                    } else {
                                        $scope.alertObjRegistrationSave = alertSrv.getErrorMsg('Ошибка подписи данных для регистрации. Проверьте правильность установки сертифика на вашем устройстве.');
                                    }
                                    $scope.processing = false;
                                    console.log(response)
                                });
                            } else{
                                $scope.processing = false;
                                $scope.alertObjRegistrationSave = alertSrv.getErrorMsg(response.data.message);
                            }
                        }).then(null, function errorCallback(response) {
                            $scope.alertObjRegistrationSave = alertSrv.getAlertObj(response);
                            $scope.processing = false;
                            console.log(response);
                        });
                    }
                }

                function prepareRegDataFn(signature){
                    $scope.customerReg.signature = signature;
                    $scope.customerReg.name = $scope.customerReg.document.orgInfo.legalEntity.fullName;
                    $scope.customerReg.inn = $scope.customerReg.document.orgInfo.legalEntity.INN;
                    $scope.customerReg.kpp = $scope.customerReg .document.orgInfo.legalEntity.KPP;
                }

                /**
                 * Выбор сертификата (весит на event)
                 */
                function certChangeHandler() {
                    $scope.active.show = false;
                    if ($scope.alertObjRegistration) $scope.alertObjRegistration.dismiss = true;

                    $scope.selectedCert = null;

                    if (!this.value) return $scope.$digest();
                    const certObj = this[this.value],
                        subjectName = certObj.subjectName;
                    $scope.selectedCert = certObj;
                    if (!subjectName) return;

                    let certValidate = {"certInBase64": certObj.base64Value};

                    profileSrv.uploadCustomerCert(certValidate).then((response) => {
                        if (response.data.success) {
                            $scope.cert = certObj;
                            fillCustomerFields(response.data.result, response.data.message);
                        } else {
                            $scope.alertObjRegistration = alertSrv.getErrorMsg(response.data.message);
                        }
                    }, (response) => {
                        console.log(response);
                    });
                }



                /**
                 * Разбор данных сертификата
                 * @param regJson
                 */
                function fillCustomerFields(regJson, certId) {

                    const innVal = regJson.inn,
                        ogrn = regJson.ogrn;
                    $scope.customerReg ={
                        document:{
                            orgInfo:{
                                contactInfo:{},
                                legalEntity:{
                                    INN:regJson.inn,
                                    OGRN:regJson.ogrn,
                                    fullName: regJson.fullName,
                                    shortName: regJson.fullName
                                },
                                regAddress: {country: OKSM_RUS, countryName: 'РОССИЙСКАЯ ФЕДЕРАЦИЯ'},
                                realAddress: {}
                            },
                            signaturePerson:{
                                fio:{
                                    lastName: regJson.lastName,
                                    firstName: regJson.firstName,
                                    middleName: regJson.middleName
                                },
                                cert:certId
                            },
                            documents:{
                                document:[]
                            },
                            allowed223: 'true',
                            allowedCommercial: 'true'
                        }
                    };

                    if (innVal.length === 10 && (ogrn && ogrn.length !== 13 )) {
                        dialogSrv.showDialogMsg($scope, "ОГРН в сертификате не соответствует типу организации (ЮЛ, ИП или ФЛ). Возможно, сертификат выпущен неверно.");
                        return;
                    }
                    $scope.middleNameCert = regJson.middleName;

                    if (!/^[0]+$/g.test(innVal)) {
                       customerRegSrv.validateCustomerInn(innVal).then(function successCallback(response) {
                            if (!response.data.success) {
                                $scope.alertObjRegistration = alertSrv.getErrorMsg(response.data.message);
                            } else {
                                $scope.active.show = true;
                            }
                        })
                    } else {
                        $scope.active.show = true;
                    }
                }

                function bindFormEnterPress() {
                    const form = document.querySelector("form[name='form.registerForm']");
                    if (!form) return;
                    form.removeEventListener("keypress", formClickHandler, true);
                    form.addEventListener("keypress", formClickHandler, true);

                    function formClickHandler(e) {
                        if (e.keyCode == 13) {
                            if (e.defaultPrevented) return;
                            if (e.target.tagName == "INPUT") e.preventDefault();
                            registerFn();
                            return false;
                        }
                    }
                }
                function uploadCertFn(cert) {
                    let a = document.createElement("a");
                    let fName = cert.getInfo;
                    fName = fName ? (fName +".cer") : "сертификат.cer";
                    var blob = new Blob([cert.base64Value], {type: "application/octet-stream;base64"});
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, fName);
                        setTimeout(function () {
                            window.URL.revokeObjectURL(blob)
                        }, 500);
                    } else {
                        a.href  = 'data:application/octet-stream;base64,' + cert.base64Value;
                        a.download = fName;
                        document.body.appendChild(a);
                        a.click();
                        setTimeout(function () {
                            document.body.removeChild(a);
                        }, 500);
                    }
                }

                /**
                 * Обновление справочника
                 * @param object - тип справочника
                 * @param value - введенные значения
                 * @param refreshScrolling - обновление данных при прокрутке
                 */
                function refreshDictFn(object, value, refreshScrolling) {
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling)
                }

                function initCustomerReg() {
                    $scope.customerReg = {
                        document:{}
                    };
                }


                function isRequiredAllowedCheckboxFn() {
                    return $scope.customerReg.document.allowed223 !=='true' && $scope.customerReg.document.allowed44 !=='true' && $scope.customerReg.document.allowedCommercial !== 'true';
                }

                function isErrorAllowedCheckboxFn() {
                    return isRequiredAllowedCheckboxFn() && ($scope.form.registerForm.allowed223.$dirty || $scope.form.registerForm.allowed44.$dirty
                        || $scope.form.registerForm.allowedCommercial.$dirty || $scope.submitted);
                }

                function isFormValidFn() {
                    return $scope.form.registerForm.$valid && $scope.docs.accreditation_founder && $scope.docs.accreditation_founder.id
                        && $scope.docs.accreditation_proxy && $scope.docs.accreditation_proxy.id;
                }

                function deleteAttachFn(type, object) {
                    const index = object.indexOf($scope.docs[type]);
                    if (index > -1) {
                        object.splice(index, 1);
                    }
                    $scope.docs[type] = {};
                }

                function attachDocFn(data, type) {
                    const fileItem = data[0],
                        document = {
                            id: fileItem.id,
                            fileName: fileItem.fileName,
                            fileHash: fileItem.hash,
                            documentType: type,
                            fileSize: fileItem.size
                        };
                    if (!$scope.customerReg.document.documents.document) $scope.customerReg.document.documents.document = [];
                    const i = $scope.customerReg.document.documents.document.findIndex(d=>d.documentType == type);
                    if (i == -1) {
                        $scope.customerReg.document.documents.document.push(document);
                    } else {
                        $scope.customerReg.document.documents.document[i] = document;
                    }
                    setDocByTypeFn(type, document);
                    if ($scope.form.registerForm) $scope.form.registerForm.$setDirty();
                }

                function setDocByTypeFn(type, doc){
                    return $scope.docs[type] = doc;
                }

                function downloadFileFn(accredId, attachId, attachName) {
                    httpSrv.downloadFile('/fs/file/downloadAttachment/' + accredId + '/' + attachId, attachName);
                }
            }]
    });

