/**
 * @author: sergeyu
 *
 * Таблица счета
 */
angular.module('uetp')
    .component('invoiceTable', {
        templateUrl: 'app/components/invoice/table/invoiceTable.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'authSrv', 'httpSrv', 'desktopSrv', 'invoiceSrv','paginationSrv','filterService', 'dialogSrv',
            function ($scope, $rootScope, $state, $stateParams, authSrv, httpSrv, desktopSrv, invoiceSrv, paginationSrv, filterService, dialogSrv) {
                var ctrl = this;
                /**
                 * В ЛК Бухгалтера выводим как таблицу (с фильтрами и поиском)
                 * В Лк Участника как вкладку
                 */
                $scope.isTable = $stateParams.isTable;
                $scope.invoiceSrv = invoiceSrv;
                $scope.ctrl = ctrl;

                $scope.downloadFile = httpSrv.downloadFile;

                $scope.paginator = {
                    itemsPerPage: 20,
                    page: 0,
                    search: ""
                };

                $scope.invoiceLoad = invoiceLoadFn;
                $scope.revokeInvoice = revokeInvoiceFn;
                $scope.checkrevokeInvoice = checkrevokeInvoiceFn;
                $scope.clickRow = clickRowFn;
                $scope.changeSort = changeSortFn;
                $scope.getPaymentRemain = getPaymentRemainFn;
                $scope.goBack = goBackFn;

                function goBackFn(){
                    history.back();
                }


                this.$onInit = function () {
                    $scope.auth = authSrv.getAuth();
                    $scope.cabType = desktopSrv.getCabType();
                    $scope.invoiceLoad();
                    var config = paginationSrv.getPaginationStateConfig('pagination', $state.current.name); // память пагинации
                    if (config) {
                        $scope.paginator.page = config.page;
                        $scope.paginator.itemsPerPage = config.itemsPerPage;
                    }
                };

                function invoiceLoadFn() {
                    filterService.initModelView('Поиск по плательщику, номеру', 'Реестр счетов');
                    prepareTableParams('filterInvoice');
                    // просмот счетов через ЛК админа, буха, ТП
                    if ($stateParams.isInvoiceParticipantView) {
                        invoiceSrv.getInvoicesByOrg($scope.paginator , $stateParams.id).then(function successCallback(response) {
                            if(response.data.success){
                                $scope.invoices = response.data.items;
                                $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                            }
                        });
                    } else {
                        invoiceSrv.getInvoices($scope.paginator).then(function successCallback(response) {
                            if(response.data.success){
                                $scope.invoices = response.data.items;
                                $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                            }
                        });
                    }

                }
                function clickRowFn(row) {
                    //проверка на тип пользователя: оператор/бухгалтер
                    var isOperator = $scope.auth.userRole[0].indexOf('operator_') > -1;
                    $state.go('invoiceView', {id: row.id, isOperator:isOperator, params: {clearConfig: true}});
                }
                /**
                 * Выставляем на оплату
                 * @param row
                 */
                $scope.oplat = function (row) {
                    invoiceSrv.setInvoiceState('acceptedPayment',row.id).then(function (response) {
                        if(response.data.success){
                            invoiceLoadFn()
                        }

                    }, function (reason) { console.log(reason) })
                };
                /**
                 * Сортировка
                 */
                function changeSortFn($event) {
                    var th = $event.currentTarget;

                    [].forEach.call(document.querySelectorAll(".sortButton"), function (button) {
                        button.classList.remove("boldSort");
                    });
                    th.classList.add("boldSort");

                    if (changeSortTh(th)) {
                        $scope.invoiceLoad();
                    }
                }
                function changeSortTh(target) {
                    var th = target,
                        orderBy = th.getAttribute('st-sort'),
                        orderAsc = th.classList.contains("sortAsc");

                    [].forEach.call(document.querySelectorAll(".sortColumn"), function (column) {
                        column.classList.remove("sortDesc");
                        column.classList.remove("sortAsc");
                    });
                    orderAsc = !orderAsc;
                    th.classList.add(orderAsc ? "sortAsc" : "sortDesc");

                    if (orderBy) {
                        //$scope.paginator.page = 0;   // если надо, то вернуть
                        $scope.paginator.orderBy = orderBy;
                        $scope.paginator.orderAsc = orderAsc;
                        // tablesSrv.setTableSort(state.name, {orderBy: orderBy, orderAsc: orderAsc});
                        paginationSrv.setPaginationStateConfig('sortTh', $state.current.name, {
                            targetObject: {
                                name: orderBy,
                                orderAsc: orderAsc
                            }
                        });
                        return true
                    }
                    return false
                }
                function prepareTableParams(filterType) {
                    var filterParams = angular.copy(filterService.getFilterParams(filterType)),
                        searchParam = angular.copy(filterService.getSearchParam(filterType));
                    if (filterParams && Object.keys(filterParams).length > 0) {
                        $scope.paginator.searchByField = filterParams;
                    } else {
                        delete $scope.paginator.searchByField
                    }
                    $scope.paginator.search = searchParam;
                }

                function revokeInvoiceFn(id) {
                    invoiceSrv.revokeInvoice(id).then(function (response) {
                        if(response.data.success){
                            invoiceLoadFn()
                        } else {
                            dialogSrv.openModal('_dialogMessage.html',
                                {message: response.data.message});
                        }

                    }, function (reason) { console.log(reason) })
                }
                function checkrevokeInvoiceFn(invoice) {
                    invoiceSrv.checkRevokeInvoice(invoice.id).then(function (response) {
                        if(response.data.success){
                            var text = response.data.result && response.data.result.message
                                ? response.data.result.message
                                : ('Отозвать ' + (invoice.tariff.group === 'tariff' ? 'Договор-оферту?' : 'Счет?'));

                            dialogSrv.openModal('_confirmInfo.html', {message: text}).then(function (model) {
                                if (model && model.close) {
                                    revokeInvoiceFn(invoice.id);
                                }
                            });
                            invoiceLoadFn()
                        } else {
                            dialogSrv.openModal('_dialogMessage.html', {message: response.data.message});
                        }

                    }, function (reason) { console.log(reason) })
                }
                function getPaymentRemainFn(all,paid) {
                    return all - paid;

                }
            }
        ]
    });

