/**
 * @author: sergeyu
 * Организатор. Реквизиты организации
 */
angular.module('uetp')
    .component('reqOrganizator', {
        templateUrl: 'app/components/organizator/reqOrganizator/reqOrganizator.html',
        bindings: {
            callback: '='
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'organizatorSrv', 'authSrv', 'desktopSrv','alertSrv', 'dictionarySrv','needConfirmSrv', '$timeout',
            function ($scope, $rootScope, $state, $stateParams, organizatorSrv, authSrv, desktopSrv,alertSrv, dictionarySrv,needConfirmSrv, $timeout) {
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.timeZone = dictionarySrv.getStaticDictByType('timeZone');
                    $scope.form = {profileForm: {}};
                    $scope.model = {regAddress:{oksmCode:'643', oksmName:'РОССИЙСКАЯ ФЕДЕРАЦИЯ'}, realAddress:{oksmCode:'643', oksmName:'РОССИЙСКАЯ ФЕДЕРАЦИЯ'},
                        allowedCommercial:true, defaultCommercial:false};
                    $scope.prevRealAddress = {oksmCode:'643', oksmName:'РОССИЙСКАЯ ФЕДЕРАЦИЯ'};
                    setInitTimepickerVal();
                    $scope.modelView = {isEdit: false};
                    $scope.authData = authSrv;
                    $scope.timeIntWork = true;
                    $scope.timeIntTrade = true;
                    $scope.isReadOnly = isReadOnlyFn;
                    $scope.isChecked = false;
                    $scope.typePortal = dictionarySrv.getStaticDictByType('typePortal');
                    $scope.bikChangeHandler = bikChangeHandlerFn;
                    $scope.loginreadonly = true;
                    $scope.passreadonly = true;
                    if ($stateParams.id) {
                        if ($stateParams.successSave) {
                            $scope.alertObjreqOrganizator = alertSrv.getSuccessMsg('Сохранение произведено успешно!');
                        }
                        $scope.modelView.isEdit = true;
                        $scope.model = organizatorSrv.getOrganizatorInfo();
                        $scope.isChecked = organizatorSrv.equalsWithExceptions($scope.model.regAddress, $scope.model.realAddress, 'id') || !$scope.model.realAddress || !$scope.model.realAddress.oksmCode;
                    }
                    _initDictionaryBase();

                    $timeout(function () {
                        $scope.loginreadonly = false;
                        $scope.passreadonly = false;
                    }, 500);
                };
                function setInitTimepickerVal() {
                    var date = new Date(1970, 0, 1, 0, 0, 0, 0);
                    $scope.model.startWork = new Date(date.setHours( 9, 0, 0, 0 ));
                    $scope.model.endWork = new Date(date.setHours( 18, 0, 0, 0 ));
                    $scope.model.startTrading = new Date(date.setHours( 9, 0, 0, 0 ));
                    $scope.model.endTrading = new Date(date.setHours( 15, 0, 0, 0 ));
                }
                $scope.createOrgUpdate = createOrgUpdateFn;
                $scope.checkDateInterval = checkDateIntervalFn;
                $scope.cancel = cancelFn;
                $scope.refreshDict = refreshDictFn;
                /**
                 * Обновление реквизитов
                 */
                function createOrgUpdateFn() {
                    if (!$scope.model.startTrading || !$scope.model.endTrading || !$scope.model.startWork || !$scope.model.endWork || !$scope.model.city){
                        $scope.alertObjreqOrganizator = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');
                        return;
                    }
                    if (!checkTimeInterval()){
                        $scope.alertObjreqOrganizator = alertSrv.getErrorMsg('Неверно указаны временные интервалы.');
                        return;
                    }

                    const dataObj = {
                    	id: $scope.model.id,
                    	code: $scope.model.code,
                    	fullName: $scope.model.fullName,
                        startTrading: $scope.model.startTrading, //parseDate($scope.model.startTrading),
                        endTrading: $scope.model.endTrading, //parseDate($scope.model.endTrading),
                        startWork: $scope.model.startWork, //parseDate($scope.model.startWork),
                        endWork: $scope.model.endWork, //parseDate($scope.model.endWork)
                        timeZone: $scope.model.timeZone,
                        city: $scope.model.city,
                        shortName : $scope.model.shortName,
                        iko : $scope.model.iko,
                        loginEis : $scope.model.loginEis,
                        passwordEis : $scope.model.passwordEis,
                        inn : $scope.model.inn,
                        kpp : $scope.model.kpp,
                        ogrn : $scope.model.ogrn,
                        okpo : $scope.model.okpo,
                        phone : $scope.model.phone,
                        orgMail : $scope.model.orgMail,
                        okved : $scope.model.okved,
                        okvedOther : $scope.model.okvedOther,
                        realAddress : $scope.isChecked?angular.copy($scope.model.regAddress):$scope.model.realAddress,
                        regAddress : $scope.model.regAddress,
                        portalType : $scope.model.portalType,
                        requisites: $scope.model.requisites,
                        notificationControl: $scope.model.notificationControl,
                        limitDaysContract44: $scope.model.limitDaysContract44,
                        limitDaysContract223: $scope.model.limitDaysContract223,
                        contractEnabled: $scope.model.contractEnabled,
                        otherClaimsView: $scope.model.otherClaimsView
                    };

                    if(!organizatorSrv.checkRequisites(dataObj.requisites))
                        delete dataObj.requisites;

                    organizatorSrv.createOrUpdate(dataObj).then(function (response) {
                        if (response.data.success) {
                            $scope.form.profileForm.$setPristine();
                            if(['admincab','support'].includes($scope.cabType()))
                                $rootScope.$emit('setOrganizerViewName', dataObj.shortName ? dataObj.shortName : dataObj.name);
                            if (!dataObj.id) {
                            	dataObj.id = response.data.result;
                                $state.go('openOrganizator', {id: dataObj.id, successSave: true},{location :'replace'});
                            }
                            organizatorSrv.setOrganizatorInfo(dataObj);
                            $scope.alertObjreqOrganizator = alertSrv.getAlertObj(response,'Сохранение произведено успешно!');
                        } else {
                            $scope.alertObjreqOrganizator = alertSrv.getAlertObj(response,'Организатор с указанным кодом уже зарегистрирован!');
                        }
                    })
                }
                function checkTimeInterval(){
                    return new Date($scope.model.startTrading) < new Date($scope.model.endTrading) &&  new Date($scope.model.startWork) < new Date($scope.model.endWork);
                }
                function checkDateIntervalFn(startTime, endTime, labe) {
                    if(labe === 'work'){
                        $scope.timeIntWork =  new Date(startTime) < new Date(endTime);
                    } else if (labe === 'trade'){
                        $scope.timeIntTrade = new Date(startTime) < new Date(endTime);
                    }
                }
                function cancelFn() {
                    if($scope.form.profileForm.$dirty){
                        needConfirmSrv.call($state.go,['organizators'],'Внесенные изменения сохранены не будут');
                    }else{
                        $state.go('organizators');
                    }
                }

                function isReadOnlyFn() {
                    return ["accountant", "support"].indexOf(desktopSrv.getCabType())!=-1;
                }
                /**
                 * Совпадает ли адрес с Юр.адресом
                 * @param checked
                 */
                $scope.checkedAddress = function (checked) {
                    if (!checked) {
                        $scope.prevRealAddress = angular.copy($scope.model.realAddress);
                        $scope.model.realAddress = angular.copy($scope.model.regAddress);
                    } else {
                        $scope.model.realAddress = angular.copy($scope.prevRealAddress);
                    }
                    $scope.form.profileForm.$setDirty();
                };
                function _initDictionaryBase() {
                    $scope.dictionary = dictionarySrv.getRemoteData();
                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                    dictionarySrv.resetPaginatedDict($scope);
                    _initDict(['okcm', 'fias_region'], false);

                    function _initDict(dictList, reload) {
                        angular.forEach(dictList, function (name) {
                            dictionarySrv.getDictionaryRemote(name, reload);
                        })
                    }
                }

                /**
                 * Обновление справочника
                 * @param object - тип справочника
                 * @param value - введенные значения
                 * @param refreshScrolling - обновление данных при прокрутке
                 */
                 function refreshDictFn(object, value, refreshScrolling, needToAdd, searchValue) {
                    if (!value) {
                        if (object === 'rBIK') {
                            value = searchValue;
                        }
                    }
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling, null, needToAdd)
                }
                
                /**
                 * Заполнить Номер корреспондентского счета банка из выбранного банка
                 */
                function bikChangeHandlerFn(selectObj, dataObj) {
                    var selected = selectObj.$select.selected,
                        bankNameProperty = dataObj.bankName == undefined ? "bank" : "bankName";//т.к. отличаются названия полей в json

                    dataObj[bankNameProperty] = selected ? selected.participant : "";
                    dataObj.bankAcc = selected ? selected.accounts : "";
                }
            }]
    });

