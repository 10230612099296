/**
 * Обрезание длинныз строк при отображение и выставление точек после обрезания
 */
angular.module('uetp').filter('characters', function () {
        return function (input, chars, breakOnWord) {
            if (isNaN(chars)) return input;
            if (chars <= 0) return '';
            if (input && input.length > chars) {
                //на случай использования в ng-bind-html
                var idx = -1,
                    lookForward = true;
                while ((idx = input.indexOf('<span class="ui-select-highlight">', idx+1))!=-1 && lookForward){
                    if (idx <= chars) {
                        chars += 34;
                        if (input.indexOf('</span>') != -1&&input.indexOf('</span>')<chars) chars += 7;//length==7
                    } else lookForward = false;
                }//length==34

                if (input.length < chars) return input;
                input = input.substring(0, chars);

                if (!breakOnWord) {
                    var lastspace = input.lastIndexOf(' ');
                    //get last space
                    if (lastspace !== -1) {
                        input = input.substr(0, lastspace);
                    }
                }
                /**
                 * Думаю это не нужно. Обрубает не так как требует ТЗ
                 */
                //else{
                //    while(input.charAt(input.length-1) === ' '){
                //        input = input.substr(0, input.length -1);
                //    }
                //}
                return input + '…';
            }
            return input;
        };
    })
/**
 * Обрежет текст в середине 123..890
 */
    .filter('splitcharacters', function() {
        return function (input, chars) {
            if (isNaN(chars)) return input;
            if (chars <= 0) return '';
            if (input && input.length > chars) {
                var prefix = input.substring(0, chars/2);
                var postfix = input.substring(input.length-chars/2, input.length);
                return prefix + '...' + postfix;
            }
            return input;
        };
    })
    .filter('words', function () {
        return function (input, words) {
            if (isNaN(words)) return input;
            if (words <= 0) return '';
            if (input) {
                var inputWords = input.split(/\s+/);
                if (inputWords.length > words) {
                    input = inputWords.slice(0, words).join(' ') + '…';
                }
            }
            return input;
        };
    });