angular.module('uetp')
    .component('bank', {
        templateUrl: 'app/components/bank/bank.html',
        controller: ['$scope', 'constants', '$state', 'authSrv',
            function ($scope, constants, $state) {
                $scope.constants = constants;
                $scope.choiceBank = choiceBankFn;

                function choiceBankFn (bankName) {
                    if (bankName === "absolut") {
                        $state.current.name === 'bg' ? $state.go('bankGuarantees') : $state.go('guarantees');
                    } else if (bankName === "raif") {
                        $state.current.name === 'bg' ? $state.go('rfGuarantees') : $state.go('raiffeisen');
                    } else if (bankName === "loko") {
                        $state.current.name === 'bg' ? $state.go('lokobank') : $state.go('lokobg');
                    }else if (bankName === "vtb") {
                        $state.current.name === 'bg' ? $state.go('bankGuaranteesVTB') : $state.go('bankVTBLanding');
                    }
                }
            }]
    });
