//компонент контакты
angular.module('uetp')
    .component('becomeSupplier', {
        templateUrl:['constants', function (constants) {
            return 'app/components/becomeSupplier/becomeSupplier_' + constants.configProject.type + '.html'
        }],
        transclude: true,
        controller: ['$scope', function ($scope) {

            $scope.content = {};
            $scope.form = {};
            $scope.showSendMessage = false;

            init();

            $scope.closeAlert = function () {
                $scope.alertId = 'dismiss';
            };

            function init() {
                $scope.alertId = 'dismiss';
            }
        }]
    });