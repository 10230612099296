/**
 * @author: sergeyu
 * Сервис аутентификации пользователя
 */
angular.module('uetp').service('authSrv', ['$http', '$injector', '$state', '$rootScope', 'cryptography', 'constants',
    function ($http, $injector, $state, $rootScope, cryptography, constants) {
        var service = this,
            authObj = {auth: {}},
            userObj = {user: {}},
            profileSuccess = false,
            reglamentSuccess = false,
            userFieldValid = false,
            certificateError = false,
            certInvalidData = null,
            mchdCheckResult = null,
            certValid = true,
            certEmpty = false,
            closeTariffPanelInfo = true,
            disableBeforeUnload = false,
            approvalStates = false,
            authListeners = [];
        /**
         * Установка данныз о пользователе
         * @param userData
         * @param eventStop - остановить event он смене данный пользователя
         */
        this.setUser = function (userData, eventStop) {
            localStorage.setItem("userData", JSON.stringify(userData));
            userObj.user = userData;
            if (!eventStop) {
                $rootScope.$emit("userDataChanged", userData);
            }
        };
        /**
         * Получения данных о пользователе
         * @returns {{}}
         */
        this.getUser = function () {
            return userObj.user;
        };
        this.setDisableBeforeUnload = function(value){
            disableBeforeUnload = value;
        };
        /**
         * Установка подтверждения разрешения работы в ЛК пользователя
         * @param success
         */
        this.setProfileSuccess = function (success) {
            profileSuccess = success;
        };
        /**
         * Получения подверждения разрешения работы в ЛК пользователя
         * @returns {boolean}
         */
        this.isProfileSuccess = function () {
            return profileSuccess;
        };
        /**
         * Установка подтверждения регламента площадки
         * @param success
         */
        this.setReglamentSuccess = function (success) {
            reglamentSuccess = success;
        };
        /**
         * Получение подтверждления регламента площадки
         * @returns {boolean}
         */
        this.isReglamentSuccess = function () {
            return reglamentSuccess;
        };
        /**
         * Установка валидации данных пользователя
         * @param valid
         */
        this.setUserValid = function (valid) {
            userFieldValid = valid;
        };
        /**
         * Получение валидации данных пользователя
         * @returns {boolean}
         */
        this.isUserValid = function () {
            return userFieldValid;
        };
        /**
         * Установка типов состояний аккредитации
         * @param valid
         */
        this.setApprovalStates = function (valid) {
            approvalStates = valid;
        };
        /**
         * Получение типов состояний аккредитации
         * @returns {boolean}
         */
        this.getApprovalStates = function () {
            return approvalStates;
        };
        /**
         * Проверям на вилидность токен пользователя.
         * Берем куки с токеном и берем токен вкладки браузера.
         * Если они не равны, то пользователь зашел в новой вкладке в ЛК и ему выдали новый токен.
         * @returns {boolean} - true токены валидны, false токены не валидны
         */
        this.isValidCookie = function () {
            var name = getCookie("Auth-Token");
            var key = $http.defaults.headers.common['Auth-Token'];
            if (!name || !key) {
                return true;
            } else {
                return name === key;
            }
        };
        /**
         * Обновление сертификата пользователя в объекте auth
         * @param certId
         */
        this.updateCertId = function (certId) {
            const auth = service.getAuth();
            auth.certId = certId;
            localStorage.setItem("auth", JSON.stringify(auth));
        };
        /**
         * Обновление сообщений по МЧД в объекте auth
         * @param certId
         */
        this.updateMchdCheckResult = function (val) {
            const auth = service.getAuth();
            auth.mchdCheckResult = val;
            localStorage.setItem("auth", JSON.stringify(auth));
        };
        /**
         * Обновление ФИО пользователя в объекте auth
         * @param userName
         */
        this.updateUserName = function (userName) {
            var auth = service.getAuth();
            auth.userName = userName;
            localStorage.setItem("auth", JSON.stringify(auth));
        };
        /**
         * Обновление аккредитации пользователя в объекте auth
         * @param orgAccreditation
         */
        this.updateOrgAccreditation = function (val) {
            const auth = service.getAuth();
            auth.orgAccreditation = val;
            localStorage.setItem("auth", JSON.stringify(auth));
            $rootScope.$emit('checkAccred');
        };

        /**
         * Получить данные по авторизованному пользователю
         */
        this.getAuth = function (cb) {
            if (cb) {
                if (authObj.auth && Object.keys(authObj.auth).length) cb(authObj.auth);
                else authListeners.push(cb);
            }
            return authObj.auth;
        };
        this.isSuccessUseCookie  = function () {
            return getCookie('Agree-Msg');
        };
        this.setIsSuccessUseCookie  = function (param) {
            setCookie('Agree-Msg', param, {expires:63072000000});
        };
        this.isEISInfo  = function () {
            return getCookie('EIS-Info-Warning');
        };
        this.setEISInfo  = function (param) {
            setCookie('EIS-Info-Warning', param, {expires:63072000000});
        };
        /**
         * Проверить закрывал ли он окно с информацией что все закупки теперь платные.
         * @param userId - идентификатор пользователя
         * @returns {boolean}
         */
        this.isCloseTariffPanel  = function (userId) {
            if (userId) {
                return closeTariffPanelInfo = !!getCookie('Close-Tariff-Panel-ID_' + userId);
            }
            return closeTariffPanelInfo;
        };
        this.setCloseTariffPanel  = function (param, userId) {
            setCookie('Close-Tariff-Panel-ID_' + userId, param, {expires:93072000000});
            closeTariffPanelInfo = true;
        };
        /**
         * Выставить информацию по авторизованному пользователю
         * @param authParam - login request
         * return promise auth
         * @private
         */
        function _setAuth(authParam) {
            authObj.auth = angular.copy(authParam);
            $http.defaults.headers.common['Auth-Token'] = authParam.key;
            if (authParam.key) {
                setCookie('Auth-Token', authParam.key, {Path: '/'});
                setCookie('Type-Shop', constants.configProject.reqType, {Path: '/'});
            }
            localStorage.setItem("key", authParam.key);
            localStorage.setItem('timeToLogoff', authParam.timeToLogoff);
            localStorage.setItem("auth", JSON.stringify(authParam));
            authListeners.forEach(l => l(authObj.auth));
            authListeners.length = 0;
            return Promise.resolve(authObj.auth)
        }

        /**
         * Выставить отпечаток браузера
         * @param fp - отпечаток
         * @private
         */
        function _setFPrints(fp) {
            $http.defaults.headers.common['Finger-Prints'] = fp;
        }

        /**
         * Очистка данных пользователя
         * isLogoff - очистка сессионных данные только при выходе из ЛК или когда сервер вернет 401
         * @private
         */
        function _clearAuthSrv(isLogoff) {
        	clearLocalStorage();
            if (isLogoff) {
                localStorage.clear();
                sessionStorage.clear();
            }

            _setFPrints(null);
            service.setUser({});
            service.setCertValid(true);
            service.setCertEmpty(false);
            service.setCertOutdated(null);
            delete $http.defaults.headers.common['Auth-Token'];
            setCookie("Auth-Token", '', {expires: -1});
            setCookie("Type-Shop", '');
            return _setAuth({key: '', timeToLogoff: 0});
        }

        /**
         * Выход в главное меню и очистка данных по выходу
         * @private
         */
        function _exitGoHome(isLogoff) {
            _clearAuthSrv(isLogoff).then(function (auth) {
                service.updateOrgAccreditation(true);
                $rootScope.$emit('exitEvent', auth);
                setTimeout(function () {
                    window.location.href = '/'
                }, 100)
            });

        }

        /**
         * Вход в систему под пользователем
         * @param login
         * @param password
         */
        this.login = function (login, password, captcha) {
            _clearAuthSrv();
            var httpSrv = $injector.get('httpSrv');
            return httpSrv.http({
                method: 'POST',
                url: '/auth/user/login',
                data: {login: login, password: password, captcha: captcha}
            }).then(function (response) {
                if (response.data.success) {
                    var result = response.data.result;
                    var authParam = getAuthParam(result);
                    _setFPrints(result.fingerprints);
                    return _setAuth(authParam);
                } else {
                    return Promise.reject(response);
                }
            }, function (reason) {
                throw new Error(reason);
            })
        };

        this.crossLogin = function (response) {
            var result = response.data.result;
            var authParam = getAuthParam(result);
            _setFPrints(result.fingerprints);
            return _setAuth(authParam);
        }

        this.loginByCertGetSign = function () {
            var httpSrv = $injector.get('httpSrv');
            return httpSrv.http({
                method: 'GET',
                url: '/auth/user/preLoginCert'
            })
        };

        this.loginByCert = function (data) {
            _clearAuthSrv();
            var httpSrv = $injector.get('httpSrv');
            return httpSrv.http({
                method: 'POST',
                url: '/auth/user/loginCert',
                data:data
            }).then(function (response) {
                if (response.data.success) {
                    var result = response.data.result;
                    var authParam = getAuthParam(result);
                    _setFPrints(result.fingerprints);
                    return _setAuth(authParam);
                } else {
                    return Promise.reject(response);
                }
            }, function (reason) {
                throw new Error(reason);
            })
        };

        /**
         * Выводим окно предупреждения закрытия вкладки браузера
         * @private
         */
        function _closeTabBrowser() {
            function closeFn(e) {
                if (!disableBeforeUnload && authObj && authObj.auth && authObj.auth.key) {
                    var confirmationMessage = "Внимание! \n" +
                        "Выход из личного кабинете осуществляется не корректно. Для корректного выхода нажмите \"Отмена\" и нажмите на кнопку \"Выход\" в личном кабинете.";
                    (e || window.event).returnValue = confirmationMessage;
                    return confirmationMessage;
                }
            }

            window.addEventListener("beforeunload", closeFn);

        }
        _closeTabBrowser();


      this.reloadOrgInfo = function () {
        var profile = $injector.get('profileSrv');
        return profile.loadOrganizationInfo().then(function (userData) {
          if (userData.data.success) {
            var user = userData.data.result;
            service.setUser(user, true);
            return Promise.resolve(service.getUser());
          }
        }, function (reason) {
          return Promise.reject("Ошибка получения данных по организации. " + reason);
        })
      };

      /**
         * Проверка всех параметров аутентификатии
         * reloadOrgInfo - перегрузить данные по организации
         */
        this.checkedAuth = function (reloadOrgInfo) {
            var token = localStorage.getItem("key");
            if (token && angular.isDefined(token) && token.length > 10) {
                if (reloadOrgInfo) {
                    var profile = $injector.get('profileSrv');
                    return profile.loadOrganizationInfo().then(function (userData) {
                        if (userData.data.success) {
                            var user = userData.data.result;
                            service.setUser(user, true);
                            return _setAuth(JSON.parse(localStorage.getItem("auth")))
                        }
                    }, function (reason) {
                        console.log(reason);
                        return Promise.reject("Ошибка получения данных по организации. " + reason);
                    })
                } else {
                    service.setUser(JSON.parse(localStorage.getItem("userData")), false); //ОТПРАВИТЬ СОБЫТИЯ
                    return _setAuth(JSON.parse(localStorage.getItem("auth")))
                }

            }
            return _clearAuthSrv()
        };
        this.checkedAuthEventStop = function (eventStop) {
            const token = localStorage.getItem("key");
            if (token && token.length > 10) {
                service.setUser(JSON.parse(localStorage.getItem("userData")), eventStop ? eventStop : false);
                return _setAuth(JSON.parse(localStorage.getItem("auth")))
            }
            return _clearAuthSrv()
        };
        this.exit = function () {
            var httpSrv = $injector.get('httpSrv');
            return httpSrv.http({
                method: 'GET',
                url: '/auth/user/logoff'
            }).then(function () {
                return _exitGoHome(true)
            }, function () {
                return _exitGoHome(true)
            })
        };
        this.clearSession = function (isLogoff) {
            _exitGoHome(isLogoff)
        };
        
        this.refreshSession = function () {
            var httpSrv = $injector.get('httpSrv');
            return httpSrv.http({
                method: 'GET',
                url: '/auth/user/validateSession'
            })
        };

        //проверка сертификата текущего авторизованного пользователя
        this.isCertValid = function () {
            return certValid;
        };
        this.isCertEmpty = function () {
            return certEmpty;
        };
        this.isCertAvailable = function () {
            return certValid && !certEmpty;
        };
        this.setCertValid = function (value) {
            certValid = value;
        };
        this.setCertEmpty = function (value) {
            certEmpty = value;
        };
        this.isCertificateError = function () {
            return certificateError;
        };
        this.setCertificateError = function (value) {
            certificateError = value;
        };
        this.getCertOutdated = function () {
            return certInvalidData;
        };
        this.setCertOutdated = function (value) {
            certInvalidData = value;
        };
        this.getMchdCheckResult = function () {
            return service.getAuth()?.mchdCheckResult;
        };
        this.isEmailCheck = function () {
            if (userObj.user && userObj.user.id) {
                return userObj.user.email && !userObj.user.emailConfirm
            }
            return true;
        };
        this.isWithoutCertEnabled = function () {
            return service.getUser().withoutCertEnabled;
        }
        this.checkProfileCert = function () {
            if (service.getUser().orgRole != "participant" && service.getUser().orgRole != "organizer" || service.getUser().userRole=="organizer_cbz") {
                service.setCertValid(true);
                service.setCertEmpty(false);
                service.setCertificateError(false);
                service.setCertOutdated(null);
                return;
            }
            var user = service.getUser(), certId = user.certId;
            if (!user.certId && !user.withoutCertEnabled) {
                service.setCertValid(true);
                service.setCertEmpty(true);
                service.setCertificateError(true);
                service.setCertOutdated(null);
                return;
            }
            service.setCertEmpty(false);
            if(!user.withoutCertEnabled)
                setTimeout(checkCert, 1000);

            function checkCert() {
                var checkDate = function (certDate) {
                    var privateKeyDate = service.getAuth().privateKeyNotAfter,
                        serverDate = getServerTime();
                    privateKeyDate = privateKeyDate || certDate;
                    return certDate > serverDate && privateKeyDate > serverDate;
                };
                cryptography.initPluginWorking().then(function (plugin) {
                    service.setCertOutdated(null);
                    plugin.findCertificate(certId).then(function (data) {
                        cryptography.getCertInfoByCert(data).then(function (certInfo) {
                            service.setCertValid(true);
                            service.setCertificateError(false);
                            $rootScope.$emit("cryptoChecked", plugin.isPluginLatest());
                            const auth = service.getAuth();
                            if(auth.mchdCheckResult) {
                                service.updateMchdCheckResult(auth.mchdCheckResult);
                                $rootScope.$emit("mchdError", true, auth.mchdCheckResult);
                            }

                            if (certId && angular.isDefined(certInfo.validToDate) && !checkDate(new Date(certInfo.validToDate).getTime())) { // validToDate время формата по gmt
                                service.setCertValid(false);
                                service.setCertificateError(true);
                                service.setCertOutdated(certInfo);
                                $rootScope.$emit("certChecked", true);
                            } else {
                                $rootScope.$emit("certChecked", false);
                            }
                        })
                    }, function (err) {
                        service.setCertValid(false);
                        service.setCertificateError(true);
                        $rootScope.$emit("certCheckFailed");
                        console.log(err);
                    });
                }, cryptography.errorHandler);
            }
        };
        //проверка прав доступа пользователя к состоянию
        this.securityCheck = function(state){
            if (state.secured!=null){
                var userRole = service.getUser().userRole;
                if(state.secured.indexOf(userRole) == -1)
                    return false;
            }
            if(state.isSecuredFn!=null) {
                if(!state.isSecuredFn(service))
                    return false;
            }
            return true;
        };

        //установка куки
        function setCookie(name, value, options) {
            options = options || {};

            var expires = options.expires;
            if (typeof expires === "number" && expires) {
                var d = new Date();
                d.setTime(d.getTime() + expires * 1000);
                expires = options.expires = d;
            }
            if (expires && expires.toUTCString) {
                options.expires = expires.toUTCString();
            }

            value = encodeURIComponent(value);

            var updatedCookie = name + "=" + value;

            for (var propName in options) {
                updatedCookie += "; " + propName;
                var propValue = options[propName];
                if (propValue !== true) {
                    updatedCookie += "=" + propValue;
                }
            }
            updatedCookie += "; SameSite=Lax; Secure=true"
            document.cookie = updatedCookie;
        }
        function getCookie(name) {
            var value = "; " + document.cookie;
            var parts = value.split("; " + name + "=");
            if (parts.length === 2) return parts.pop().split(";").shift();
        }


        this.changeOrganizer = function (id) {
            var httpSrv = $injector.get('httpSrv');
            return httpSrv.http({
                method: 'GET',
                url: '/auth/user/changeOrganization/'+id
            }).then(function (response) {
                if (response.data.success) {
                    _clearAuthSrv();
                    setTimeout(() => {
                        $rootScope.$emit('UPDATE:EXPLREQS');
                        $rootScope.$emit('UPDATE:COUNTER');
                    },1000);

                    var result = response.data.result;
                    var authParam = getAuthParam(result);
                    _setFPrints(result.fingerprints);
                    return _setAuth(authParam);
                } else {
                    return Promise.reject(response);
                }
            }, function (reason) {
                throw new Error(reason);
            })
        };

        function getAuthParam(result){
            return {
                key: result.tokenId,
                userName: result.name,
                certId: !result.certificateId && result.withoutCertEnabled ? "withoutCertEnabled" : result.certificateId,
                withoutCertEnabled: result.withoutCertEnabled,
                orgRole: result.orgRole,
                userRole: result.userRole,
                timeToLogoff: result.timeToLogoff,
                privateKeyNotAfter: result.privateKeyNotAfter,
                privateKeyNotBefore: result.privateKeyNotBefore,
                mchdCheckResult: result.mchdCheckResult,
                maxCountAttachmentsContract: result.attachmentsParams.maxCountAttachmentsContract,
                maxSizeAttachmentsContract: result.attachmentsParams.maxSizeAttachmentsContract,
                maxCountAttachments: result.attachmentsParams.maxCountAttachments,
                maxSizeAttachments: result.attachmentsParams.maxSizeAttachments,
                maxCountAttachmentsOrg: result.attachmentsParams.maxCountAttachmentsOrg,
                maxSizeAttachmentsOrg: result.attachmentsParams.maxSizeAttachmentsOrg,
                maxSizeAttachmentsForExplanationRequest: result.attachmentsParams.maxSizeAttachmentsForExplanationRequest,
                maxSizeAttachmentsBguarantee: result.attachmentsParams.maxSizeAttachmentsBguarantee,
                maxSizeAttachmentsBguaranteeLoko: result.attachmentsParams.maxSizeAttachmentsBguaranteeLoko,
                maxSizeAttachmentsRfGuarantee: result.attachmentsParams.maxSizeAttachmentsRfGuarantee,
                maxSizeEisXml: result.attachmentsParams.maxSizeEisXml,
                maxmultiupload: result.attachmentsParams.maxMultiUpload,
                userId: result.userId,
                orgId: result.orgId,
                accountEnabled: result.accountEnabled,
                email: result.email,
                orgAccreditation: result.orgAccreditation,
                hasMarketOrgApproval: result.hasMarketOrgApproval,
                approvalStates: result.approvalStates
            };
        }

        /**
         * Открытие модального окна регистрации
         * */
        this.loginModal = function (state, param) {
            if (service.getAuth().key) {
                $state.go(state, param);
            } else {
                var params = {};
                params.state = state;
                params.param = param;
                $rootScope.$emit('openLoginEvent', params);
            }
        };

        /**
         * Проверка доступа к реестру закупок ЕИС
         * */
        this.canActiveOtherPurchase = function (cabType, user) {
            return cabType === 'organizer' || user.otherPurchasesVisible || user.userRole === 'operator_support_ro' || user.userRole === 'operator_admin' || ['agent','bank'].includes(cabType)
        };
        /**
         * Проверка доступа к плану закупок ЕИС
         * */
        this.isPurchasePlanVisible = function (cabType, user) {
            return cabType === 'organizer' && user.purchasePlanVisible;
        };
        /**
         * Проверка доступа к банковским гарантиям
         * */
        this.canActiveBankGuarantee = function (cabType, user) {
            return (cabType === 'participant' && (user.typeOrg === 'ul' || user.typeOrg === 'ip')) || cabType === 'agent';
        };
        /**
         * Проверка наличия тарифа Z04 и D01
         */
        this.checkTariffsZ04_D01 = function (cabType, user) {
            return cabType === 'participant' && !(user.userTariffs && (user.userTariffs.Z04 || user.userTariffs.D01 || user.userTariffs.Z14 || user.userTariffs.D11));
        }
        /**
         * Проверка доступа к банковским гарантиям
         * */
        this.isUserAgent = function () {
            var user = service.getUser();
            var type = user.userRole;
            return ['bg_agent_admin', 'bg_agent_user'].includes(type);
        };

        this.isUserBank = function () {
            var user = service.getUser();
            var type = user.userRole;
            return ['bank_admin', 'bank_user'].includes(type);
        };

        this.isAdminBGChatView = function (cabType, user) {
            return cabType === 'admincab' && user.userRole && ['operator_support_ro', 'operator_admin'].includes(user.userRole);
        };

        function clearLocalStorage() {
            //в ie нет localStorage.key(i) делаем через angular
            angular.forEach(localStorage, function (value, key) {
                if (!key.startsWith("claimAttachOrderBy_")&&
                    !key.startsWith("claimAttachOrderAsc_")&&
                    key !=="bankGuaranteesParams") {
                    localStorage.removeItem(key);
                }
            })
        }

        this.addTypeShop = function (){
            setCookie('Type-Shop', constants.configProject.reqType, {Path:'/'});
        };

        this.removeTypeShop = function (){
            if(getCookie('Type-Shop'))
                setCookie("Type-Shop", '');
        }
        /**
         * Проверка достпа к разделу "Сервисы"
         */
        this.haveTariffsFormService = function () {
            const user = service.getUser();
            return ["bank", "organizer", "agent", "admin"].includes(user.orgRole)  || (user.userTariffs ? user.userTariffs.Z04 || user.userTariffs.D01 || user.userTariffs.Z14 || user.userTariffs.D11 : false);
        }

        this.prepareUserDataForUpdate = function(model){
            return {
                lastName: model.lastName,
                firstName: model.firstName,
                middleName: model.middleName,
                email: model.email,
                emailForNotice: model.emailForNotice,
                id: service.getAuth().userId,
                powerOfAttorney: model.powerOfAttorney,
                userProperties: model.userProperties,
                consentProcessingDate: model.consentProcessingDate,
                consentDisseminationDate: model.consentDisseminationDate
            };
        }

    }]);
