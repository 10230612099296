/**
 * @author: alexandrbu
 * БГ участники
 */
angular.module('uetp')
    .component('bgParticipants', {
        templateUrl: 'app/components/bgParticipants/bgParticipants.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv','authSrv', 'storageSrv', 'paginationSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, authSrv, storageSrv, paginationSrv) {
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.model = {
                        showPanel: 'order'
                    };
                    $scope.modelView = {
                        bank: ''
                    };
                    $scope.dictBank = [
                        {code:'ab', name:'Абсолют банк'},
                        {code:'vtb', name:'ВТБ'},
                        {code:'lo', name:'Локо-Банк'},
                        {code:'ra', name:'Райффайзен банк'}
                    ];
                    $scope.stateConfig = $stateParams.clearConfig ?
                        storageSrv.clearStateConfig('bankGUID', 'showBank') : storageSrv.getStateConfig('bankGUID', 'showBank');

                    _initShowPanel();
                    _refreshView();
                };
                $scope.setActivePanel = setActivePanelFn;
                $scope.setActiveBank = setActiveBankFn;
                $scope.changeBank = function (selectObj) {
                    $scope.model.showPanel = 'order'
                    if (selectObj.$select.selected) setActiveBankFn(selectObj.$select.selected.code, true)
                }

                /**
                 * Инициализация панели БГ участники по типу ЛК
                 * @private
                 */
                function _initShowPanel() {
                    if ($stateParams.activeBgParticipantsTab){
                        $scope.model.showPanel = $stateParams.activeBgParticipantsTab;
                    }
                    if ($scope.stateConfig) {
                        setActiveBankFn($scope.stateConfig.bank)
                    }
                }

                /**
                 * Обновление состояния при перегрузке страницы по F5 или другим способом
                 * @private
                 */
                function _refreshView() {
                    var activePanel = $stateParams.setActivePanel;
                    if (activePanel && $scope.model.showPanel !== activePanel) {
                        setActivePanelFn(activePanel);
                    }
                }

                /**
                 * Выставление активной вкладки
                 * @param type - тип вкладки
                 */
                function setActivePanelFn(type) {
                    $scope.model.showPanel = type;
                }

                /**
                 * Выставление активного банка
                 * @param bank - тип вкладки
                 * @param change - при смене банка, а не закрытии анкеты, например
                 */
                function setActiveBankFn(bank, change) {
                    storageSrv.setStateConfig('bankGUID', 'showBank', {bank: bank});
                    $scope.modelView.bank = bank;
                    if (change) {
                        paginationSrv.clearPaginationStateConfig('pagination', $state.current.name);
                        paginationSrv.clearPaginationStateConfig('sort', $state.current.name);
                    }
                }

            }]
    });

