/**
 * @author: sergeyu
 * Сервис по работе с криптографией
 */

angular.module('uetp').service('cryptography', ['$rootScope','$injector',
    function ($rootScope, $injector) {
        this._startService = false;

        //обработка ошибок/исключений работы плагина
        this.errorHandler = errorHandlerFn;

        let ready_resolve;
        let ready_reject;

        this.ready = new Promise(function(resolve, reject) {
            ready_resolve = resolve;
            ready_reject = reject;
        });

        /**
         * Запуск сервиса для работы с криптографией
         * @private
         */
        this._initService = function () {
            var me = this;

            if (!me._startService) {
                me._startService = true;
                cadesplugin.then(function () {
                        return me.getPlugin().then(function (plugin) {
                            return plugin.initImplementation().then(function () {
                                $injector.get('authSrv').checkProfileCert()
                            },errorHandlerFn);
                        });
                    }, errorHandlerFn
                ).then(ready_resolve, ready_reject);
            } else
                ready_resolve();

        };
        /**
         * Получение ссылочного объекта на криптографию
         * @returns {*|Promise}
         */
        this.getPlugin = function () {
            if (!this.crypt) {
                this.crypt = window.crypt;
                if (!this.crypt)
                    return Promise.reject("Плагин не подключен.")
            }
            return this.crypt;
        };
        /**
         * Запуск криптографии
         */
        this.initPluginWorking = function (callback) {
            return this.getPlugin().then(function (plugin) {
                return plugin.initPluginWorking(callback);
            }, errorHandlerFn);
        };

        
        /**
         * Получение версии криптографического плагина
         * @returns {PromiseLike<T> | Promise<T> | *}
         */
        this.getPluginVersion = function () {
            return this.getPlugin().then(function (plugin) {
                return plugin.getPluginVersion();
            });
        };
        /**
         * Признак является ли версия плагина актуальной
         */
        this.isPluginLatest = function () {
            return this.getPlugin().then(function (plugin) {
                return plugin.isPluginLatest();
            });
        };

        /**
         * Получение минимально допустимой версии плагина для работы с криптографией
         */
        this.getLatestPluginVersion = function () {
            return this.getPlugin().then(function (plugin) {
                return plugin.getLatestPluginVersion();
            });
        };
        /**
         * Получение сертификата по отпечатку
         */
        this.getCertInfo = function (hash) {
            return this.getPlugin().then(function (plugin) {
                return plugin.getCertInfo(hash);
            });
        };
        this.getCertInfoByCert = function (cert) {
            return this.getPlugin().then(function (plugin) {
                return plugin.getCertInfoByCert(cert);
            });
        };
        /**
         * Создание подписи
         */
        this.generateSignature = function (data, certHash, attached) {
            const authSrv = $injector.get('authSrv');
            if (authSrv.isWithoutCertEnabled())
                return new Promise(function (resolve) {
                    resolve("");
                });
            else
                return this.getPlugin().then(function (plugin) {
                    return plugin.generateSignature(data, certHash, attached);
                });
        };
        this.signHash = function (data,certHash,vHash) {
            const authSrv = $injector.get('authSrv');
            if (authSrv.isWithoutCertEnabled())
                return new Promise(function (resolve) {
                    resolve("");
                });
            else
                return this.getPlugin().then(function (plugin) {
                    return plugin.signHash(data, certHash, vHash);
                });
        };
        this.getAlgoInfo = function (certHash) {
            const authSrv = $injector.get('authSrv');
            if (authSrv.isWithoutCertEnabled())
                return new Promise(function (resolve) {
                    resolve({name: "algo.FriendlyName", code: "algo.Value"});
                });
            else
                return this.getPlugin().then(function (plugin) {
                    return plugin.getAlgoInfo(certHash);
                });
        };
        /**
         * Расшифровка подписи
         */
        this.decrypt = function (data, certHash) {
            return this.getPlugin().then(function (plugin) {
                return plugin.decrypt(data, certHash);
            });
        };
        /**
         * Проверка сертификата
         */
        this.validateCertificate = function (hash) {
            return this.getPlugin().then(function (plugin) {
                return plugin.validateCertificate(hash);
            });

        };
        /**
         * Поиск сертификата по его отпечатку
         */
        this.findCertificate = function (hash) {
            var self = this;
            var crypt = self.getPlugin();
            return crypt.then(function (plugin) {
                return plugin.findCertificate(hash);
            });
        };

        this.parseSubjectName = function (subjectName) {
            return new Promise(function (resolve, reject) {
                var result = {active: {}};
                var firstEl = subjectName.substring(0, subjectName.indexOf("="));
                subjectName = subjectName
                    .replace(firstEl, " " + firstEl);
                subjectName = subjectName
                    .replace(/ T=/g, " @@T=")
                    .replace(/ S=/g, " @@S=")
                    .replace(/ SN=/g, " @@SN=")
                    .replace(/ G=/g, " @@G=")
                    .replace(/ C=/g, " @@C=")
                    .replace(/ CN=/g, " @@CN=")
                    .replace(/ OU=/g, " @@OU=")
                    .replace(/ O=/g, " @@O=")
                    .replace(/ L=/g, " @@L=")
                    .replace(/ E=/g, " @@E=")
                    .replace(/ STREET=/g, " @@STREET=")
                    .replace(/ OID.1/g, " @@OID.1");
                const innVal = convertCertField(subjectName.match(/1.2.643.100.4=[0-9\s]{1,}/g) || subjectName.match(/ИНН=[0-9\s]{1,}/g) || subjectName.match(/1.2.643.3.131.1.1=[0-9\s]{1,}/g));
                if (/^[0]+$/g.test(innVal)) {
                    result.typeOrg = 'nr';
                    result.active.type = 4;
                } else if (innVal.length === 10) {
                    result.typeOrg = 'ul';
                    result.active.type = 1;
                } else if (innVal.length === 12) {
                    const ogrn = convertCertField(subjectName.match(/ОГРН=[0-9\s]{1,}/g) || subjectName.match(/1.2.643.100.1=[0-9\s]{1,}/g) || subjectName.match(/ОГРНИП=[0-9\s]{1,}/g) || subjectName.match(/1.2.643.100.5=[0-9\s]{1,}/g));
                    result.typeOrg = ogrn ? 'ip' : 'fl';
                }

                if (result.active.type === 1) {
                    result.fullName = convertCertField(subjectName.match(/O=[[0-9А-Яа-яёЁ\s\,\"»«“”]{1,}/g));
                }

                const fio = findValue("CN", subjectName, false),
                    io = findValue("G", subjectName, false),
                    email = convertCertField(subjectName.match(/E=[@\w\.\-]{1,}/g)),
                    lastName = findValue("SN", subjectName, false),
                    post = findValue("T", subjectName);
                let name,
                    patronymic,
                    ioArr = io ? parseIO(io) : (fio ? parseIO(fio.substring(fio.indexOf(" ") + 1)) : ["", ""]);

                if (!io && !fio)
                    reject("Неверный формат имени");

                name = ioArr[0] ? ioArr[0] : "";
                patronymic = ioArr[1] ? ioArr[1] : "";

                if ((innVal.length === 10 && (subjectName.match(/ОГРН=[0-9\s]{1,}/g) == null && subjectName.match(/1.2.643.100.1=[0-9\s]{1,}/g) == null || subjectName.match(/ОГРНИП=[0-9\s]{1,}/g) != null || subjectName.match(/1.2.643.100.5=[0-9\s]{1,}/g) != null)) ||
                    (innVal.length === 12 && (subjectName.match(/ОГРН=[0-9\s]{1,}/g) != null || subjectName.match(/1.2.643.100.1=[0-9\s]{1,}/g) != null))) {
                    reject("ОГРН (ОГРНИП) в сертификате не соответствует типу организации (ЮЛ, ИП или ФЛ). Возможно, сертификат выпущен неверно.");
                }

                result.lastName = lastName;
                result.firstName = name;
                result.middleName = patronymic;
                result.inn = innVal;
                result.ogrn = getOrn(subjectName);
                result.email = email;
                result.post = post;

                if ([result.lastName, result.firstName, result.inn, result.fullName].some((e)=>e==="")) {
                    reject("Выбранный сертификат не валиден. Отсутствуют обязательные поля!");
                }

                resolve(result);


                function findValue(prefix, str, standartTagEnd = true) {
                    if (!str.includes("@@" + prefix + "=")) return "";
                    const strToEnd = str.substring(str.indexOf("@@" + prefix + "=") + prefix.length + 3);
                    const finishIdx = strToEnd.indexOf(standartTagEnd ? ", @@" : ", ");
                    return finishIdx>0 ? strToEnd.substring(0, finishIdx) : strToEnd;
                }

                function getOrn(subjectName) {
                    return convertCertField(subjectName.match(/ОГРН=[0-9\s]{1,}/g) || subjectName.match(/ОГРНИП=[0-9\s]{1,}/g) || subjectName.match(/1.2.643.100.1=[0-9\s]{1,}/g) || subjectName.match(/1.2.643.100.5=[0-9\s]{1,}/g));
                }

                function parseIO(str) {
                    return str.includes(" ") ? str.split(" ") : [str, ""];
                }
            })
        };
        this.getFlInn = function (subjectName) {
            const firstEl = subjectName.substring(0, subjectName.indexOf("="));
            subjectName = subjectName
                .replace(firstEl, " " + firstEl);
            subjectName = subjectName
                .replace(/ T=/g, " @@T=")
                .replace(/ S=/g, " @@S=")
                .replace(/ SN=/g, " @@SN=")
                .replace(/ G=/g, " @@G=")
                .replace(/ C=/g, " @@C=")
                .replace(/ CN=/g, " @@CN=")
                .replace(/ OU=/g, " @@OU=")
                .replace(/ O=/g, " @@O=")
                .replace(/ L=/g, " @@L=")
                .replace(/ E=/g, " @@E=")
                .replace(/ STREET=/g, " @@STREET=")
                .replace(/ OID.1/g, " @@OID.1");
            const innVal = convertCertField(subjectName.match(/1.2.643.100.4=[0-9\s]{1,}/g) || subjectName.match(/ИНН=[0-9\s]{1,}/g) || subjectName.match(/1.2.643.3.131.1.1=[0-9\s]{1,}/g));
            return innVal.length === 12 && !convertCertField(subjectName.match(/ОГРН=[0-9\s]{1,}/g) || subjectName.match(/1.2.643.100.1=[0-9\s]{1,}/g)) ? innVal : undefined;
        }

        this._initService()


        function errorHandlerFn(error) {
            setTimeout(function () {
                var pluginStatus = document.getElementById('pluginStatus');
                if (pluginStatus) {
                    pluginStatus.classList.add("alert-danger");
                    pluginStatus.innerHTML = "<p><span class='glyphicon glyphicon-alert'></span> На рабочем месте не установлен компонент КриптоПро ЭЦП Browser plug-in версии 2.0. Скачайте и установите его по <a href='/downloads/install_plug_in.pdf' target='_blank' class='alert-link'>инструкции</a>.</p>"
                } else {
                    $rootScope.$emit("pluginNotInstalled");
                }
            }, 2000)
            return Promise.reject(error);
        }
    }]);

