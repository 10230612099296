/**
 * @author: sergeyu
 * Тарифы
 */
angular.module('uetp').service('tariffsSrv', ['$state', '$filter', 'httpSrv', '$rootScope','$q','propertiesService',
    function ($state, $filter, httpSrv, $rootScope, $q, propertiesService) {
        var service = this;
        service.showPanel = '';
        this.activateTariffWithInvoice = activateTariffWithInvoiceFn;

        /**
         * Получить список тарифов
         * @returns {*}
         */
        this.getTariffs = function (pagination) {
            var paginationObj = pagination || {
                itemsPerPage: 1000,
                page: 0
            };
            return httpSrv.http({
                method: 'POST',
                url: '/site/tariff/browse',
                data: paginationObj
            });
        };
        /**
         * Получить список тарифов для аккредитации
         * @returns {*}
         */
        this.getAccredTariffs = function () {
            const paginationObj = {
                itemsPerPage: 1000,
                page: 0,
                searchByField:{
                    tariffGroup:'accreditation'
                }
            };
            return service.getTariffs(paginationObj);
        };
        /**
         * Получить список тарифов для профиля пользователя
         * @returns {*}
         */
        this.getTariffsProfile = function (pagination) {
            var paginationObj = pagination || {
                itemsPerPage: 1000,
                page: 0
            };
            return httpSrv.http({
                method: 'POST',
                url: '/accounts/tariff/browse',
                data: paginationObj
            });
        };
        /**
         * Подключить тариф
         * @param tariff
         * @returns {*}
         */
        this.activateTariff = function (tariff) {
            return httpSrv.http({
                method: 'GET',
                url: 'auth/tariff/activate/' + tariff.id
            });
        };
        /**
         * Подключить тариф
         * @param tariff
         * @returns {*}
         */
        async function activateTariffWithInvoiceFn(tariff) {
           return await httpSrv.http({
                        method: 'GET',
                        url: `auth/tariff/activateAndDownload/${tariff.id}`
            });
        }

        /**
         * Подключить тариф
         * @param id
         * @param email
         * @returns {*}
         */
        this.activateTariffByEmail = function (id, email) {
            return httpSrv.http({
                method: 'GET',
                url: 'auth/tariff/activate/' + id,
                param: email
            });
        };
        /**
         * История подключенных тарифов
         * @param pagination
         * @returns {*}
         */
        this.getMyTariffHistory = function (pagination) {
            var paginationObj = pagination || {
                itemsPerPage: 1000,
                page: 0,
                orderAsc: false,
                orderBy: 'dateStart'
            };
            if(!paginationObj.addParams){
                paginationObj.addParams = {trnc: true};
            }
            return httpSrv.http({
                method: 'POST',
                url: '/accounts/tariff/history',
                data: paginationObj
            });
        };
        /**
         * История подключенных тарифов по id пользователя
         * @returns {*}
         */
        this.getTariffHistory = function (participantId, pagination) {
            var paginationObj = pagination || {
                itemsPerPage: 1000,
                page: 0
            };
            if(!paginationObj.addParams){
                paginationObj.addParams = {trnc: true};
            }
            return httpSrv.http({
                method: 'POST',
                url: '/accounts/tariff/historyByOrg/' + participantId,
                data: paginationObj
            });
        };

        /**
         * Получение списка счетов для тарифа
         * @returns {*}
         */
        this.invoicesByTarif = function (participantTariffId) {

            return httpSrv.http({
                method: 'POST',
                url: '/accounts/invoice/invoicesByTarif/' + participantTariffId
            });
        };
        /**
         * Отключить тариф
         * @param tariff - тариф
         * @param message - причина отключения
         * @returns {*}
         */
        this.deactivateTariff = function (tariff, message) {
            return httpSrv.http({
                method: 'POST',
                url: '/accounts/tariff/disableTariff/'+ tariff.id,
                data: {message:message}
            });
        };

        this.validateDeactivateTariff = function (tariffInfo) {
            var resultObject = {
                text:'',
                type:0
            };
            var serverTime = getServerTime();
            var getSummInvoice = function (invoices) {
                var summ = 0;
                angular.forEach(invoices, function (invoice) {
                    if (invoice.tariff.group === 'tariff') {
                        summ = invoice.sumOverpayments != null ? invoice.sumOrderPayments - invoice.sumOverpayments : invoice.sumOrderPayments
                    }
                });
                return summ
            };
            var getWorkingDays = function (date) {
                return new Promise(function (resolve, reject) {
                    propertiesService.getProperty("api.url").then(function (host){
                        resolve(httpSrv.requestXHR(undefined,{
                            method: 'GET',
                            url: host + '/rest/dict-calendar/rest/calendar/getDateAddWorkDay/' + date + '/5'
                        }, function (reason) {
                            reject(reason);
                        }));
                    });
                })
            };
            var dateCheckTariff = function (data) {
                return new Promise(function (resolve) {
                    service.invoicesByTarif(data.id).then(function successCallback(response) {
                        if (response.data.success) {
                            const tariffInvoices = response.data.result;
                            getWorkingDays (data.dateStart).then(function successCallback(response) {
                                if (response.data.success) {
                                    const calcDate = new Date(response.data.result).getTime();
                                    if (serverTime <= calcDate) {
                                        resultObject =  {
                                            text: 'В случае отключения тарифного плана "' + data.tariff.name +
                                                '", денежные средства по оплате лицензионного договора-оферты в размере ' + getSummInvoice(tariffInvoices) +
                                                ' руб. будут переведены на лицевой счет участника.' +
                                                ' Денежные средства в размере платы за подключение тарифного плана, при их наличии, возврату не подлежат.' +
                                                ' <br><br>Отключить тарифный план?',
                                            type:1
                                        };
                                    } else if (serverTime > calcDate) {
                                        resultObject =  {
                                            text :'<span class="red-text"><i class="far fa-bell"></i> С момента подключения тарифного плана прошло больше 5 дней.</span><br>' +
                                                ' В случае отключения тарифного плана "' + data.tariff.name +
                                                '", денежные средства по оплате лицензионного договора-оферты в размере ' + getSummInvoice(tariffInvoices) +
                                                ' руб. будут переведены на лицевой счет участника. Денежные средства в размере платы за подключение тарифного плана, при их наличии,' +
                                                ' возврату не подлежат. <br><br>Отключить тарифный план?',
                                            type:2
                                        };
                                    } else {
                                        resultObject =  {
                                            text:'Произошла ошибка вычисления даты.',
                                            type:0
                                        };
                                    }

                                } else {
                                    resultObject = {
                                        text:'Произошла ошибка вычисления даты.',
                                        type:0
                                    };
                                }
                                resolve (resultObject);
                            }, function errorCallback(response) {
                                console.log(response);
                                resolve ({
                                    text:'Произошла ошибка вычисления даты.',
                                    type:0
                                });
                            });
                        } else {
                            resultObject = {
                                text:'Произошла ошибка загрузки счетов.',
                                type:0
                            };
                            resolve (resultObject);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                        resolve ({
                            text:'Произошла ошибка загрузки счетов.',
                            type:0
                        });
                    });
                })

            };
            //закомментировано для использования серверной проверки
            //if (tariffInfo.tariff.accesibleLots > 0) {
            //    resultObject.text = !singleTariff(tariffInfo)
            //        ? 'Отключить тарифный план невозможно. Зафиксировано использование тарифного плана участником для участия в закупках.'
            //        : '';
            //} else {
            //    resultObject.text = !periodTariff(tariffInfo)
            //        ? 'Отключить тарифный план невозможно. Зафиксировано использование тарифного плана участником для участия в закупках.'
            //        : '';
            //}
            if (!resultObject.text) {
                resultObject = dateCheckTariff(tariffInfo)
            }
            return resultObject;
        };

        this.checkTariffsInvoiceWait=function(tariffCodes) {
            return httpSrv.http({
                method: 'POST',
                url:'/auth/tariff/checktariffsinvoicewait',
                data:tariffCodes
            });
        };

        this.setShowPanel = function(type){
            service.showPanel = type;
        };
        this.getShowPanel = function () {
            return service.showPanel;
        };
        this.isDemo = function (){
            return $rootScope.properties && $rootScope.properties['demo.mode']==="true";
        };
        this.closeTariffInfoPanel = function() {
            return httpSrv.http({
                method:'GET',
                url: '/auth/user/closeTariffInfoPanel'
            })
        }

        this.getNeedTariffForServiceMsg = function(){
            const deferred = $q.defer();
            let defaultMsg = 'Раздел доступен только для пользователей тарифных планов «Годовой» (Z14) или «Безопасность закупок» (D11).';
            this.getTariffsProfile().then(function successCallback(response){
                if (response.data.success) {
                    const z4Tariff = response.data.items.filter(item =>['Z04','Z14'].includes(item.id) && item.active).map(item => item.id).join(', ');
                    const d1Tariff = response.data.items.filter(item =>['D01','D11'].includes(item.id) && item.active).map(item => item.id).join(', ');
                    let msg = 'Раздел доступен только для пользователей тарифных планов «Годовой»' + (z4Tariff ? ' (' + z4Tariff + ')' : '')
                        + ' или «Безопасность закупок» ' + (d1Tariff ? ' (' + d1Tariff + ')' : '') + '.';
                    deferred.resolve(msg);
                }
                else deferred.resolve(defaultMsg);

            }, function errorCallback() {
                deferred.resolve(defaultMsg);
            });
            return deferred.promise;
        }
    }]);