/**
 * @author: sergeyu
 * Просмотр уведомлений
 */
angular.module('uetp')
    .component('systemMessage', {
        bindings: {
            messageData:'<'
        },
        templateUrl: 'app/components/systemMessage/systemMessage.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'authSrv', 'constants','needConfirmSrv','dictionarySrv','noticeSrv', 'httpSrv',
            function ($scope, $rootScope, $state, $stateParams, authSrv, constants,needConfirmSrv,dictionarySrv,noticeSrv, httpSrv) {
                var ctrl = this;
                $scope.cancel = cancelFn;
                $scope.openProcedure = openProcedureFn;
                $scope.initProcNameByType = noticeSrv.initProcNameByType;
                $scope.getNotificationProtocolType = getNotificationProtocolTypeFn;
                $scope.showAllMessage = showAllMessageFn;
                this.$onInit = function () {
                    $scope.auth = authSrv.getAuth();
                    $scope.constants = constants;
                    if (ctrl.messageData) {
                        $scope.model = ctrl.messageData.data.result;
                        if($scope.model.message)
                            initMessFn();
                        if(!$scope.model.read){
                            $rootScope.$emit('UPDATE:COUNTER'); // $rootScope.$on
                            $rootScope.$emit('msgReaded',$scope.model.id); // $rootScope.$on
                        }
                    }
                    $scope.stateParams = {
                    };
                };
                $scope.reset = function () {
                    history.back();
                };
                $scope.downloadFile = function (file) {
                    var url = '/message/api/v2/download/' +  file.id;
                    httpSrv.downloadFile(url, file.name);
                };
                function openProcedureFn() {
                    $state.go('openProcedure',{procType:$scope.model.purchaseType, procId: $scope.model.purchaseId, clearConfig: true})
                }
                function initMessFn() {
                    var mess = $scope.model.message;
                    if(mess.indexOf('не допущена')>0){
                        $scope.model.message = mess.replace(new RegExp('не допущена', 'g'), '<b class="red">'+'notAdmitted'+'</b>');
                   
                        if($scope.model.message.indexOf('допущена')>0){
                        	$scope.model.message = $scope.model.message.replace(new RegExp('допущена', 'g'), '<b class="green">'+'допущена'+'</b>');
                        }
                        $scope.model.message = $scope.model.message.replace(new RegExp('notAdmitted', 'g'), '<b class="red">'+'не допущена'+'</b>');
                     
                    }else if(mess.indexOf('не допущена')==-1 && mess.indexOf('допущена')>0){
                        $scope.model.message = mess.replace(new RegExp('допущена', 'g'), '<b class="green">'+'допущена'+'</b>');
                    }
                }
                function cancelFn() {
                    $scope.reset();
                }
                function getNotificationProtocolTypeFn(procedureType, protocolType, stage) {
                    if(['openauction','saleauction','limitedauction'].includes(procedureType) && protocolType=='REZ')
                        return 'Протокол проведения аукциона';
                    else if('openpublicoffer'===procedureType && protocolType==='REZ')
                        return 'Протокол проведения открытого публичного предложения';
                    else if(procedureType=='requestproposals' || procedureType=='limitedrequestproposals') {
                        if(protocolType == 'OPEN' && stage=='stage2')
                            return 'Протокол вскрытия окончательных предложений';
                        else if(protocolType=='PART1' && stage=='stage2')
                            return 'Протокол рассмотрения и оценки окончательных предложений';
                        else if (protocolType == 'PART1')
                            return 'Протокол запроса предложений';
                        else if(protocolType=='OPEN')
                            return 'Протокол вскрытия заявок';
                    }
                    else if(procedureType=='open2stagecontest') {
                        if(protocolType == 'OPEN' && stage=='stage2')
                            return 'Протокол вскрытия окончательных заявок';
                        else if(protocolType=='PART1' && stage=='stage2')
                            return 'Протокол рассмотрения и оценки окончательных заявок';
                        else if (protocolType == 'PART1')
                            return 'Протокол рассмотрения первоначальных заявок';
                        else if(protocolType=='OPEN')
                            return 'Протокол вскрытия первоначальных заявок';
                    }

                    if(protocolType=='OPEN')
                        return 'Протокол вскрытия';
                    else if(protocolType=='PARTS')
                        return 'Протокол рассмотрения заявок';
                    else if(protocolType=='PART1')
                        return 'Протокол рассмотрения и оценки заявок';
                    else if(protocolType=='REZ')
                        return 'Протокол конкурсной комиссии';
                    else return protocolType;
                }

                function showAllMessageFn() {
                    $scope.isShowAllMessage = true;
                }
            }

        ]
    });

