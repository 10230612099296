/**
 * @author dmitrys
 * Подтверждение платежа
 *
 * Создается в ответ на запрос уточнения буха
 */
angular.module('uetp').component('confirmPayment', {
    templateUrl: 'app/components/paymentOrder/confirmPayment/confirmPayment.html',
    bindings: {
        coveringLetterData: '<'
    },
    controller: ['$scope', '$state','coveringLetterSrv','profileSrv','authSrv','cryptography','alertSrv','desktopSrv','invoiceSrv','httpSrv','$filter','$rootScope',
        function ($scope,  $state,coveringLetterSrv,profileSrv,authSrv,cryptography,alertSrv,desktopSrv,invoiceSrv,httpSrv,$filter,$rootScope) {


            $scope.goBack = goBackFn;
            $scope.sendAndSign = sendAndSignFn;
            $scope.previewCoveringLetter = previewCoveringLetterFn;
            $scope.selectAllInvoice = selectAllInvoiceFn;
            $scope.selectRefund = selectRefundFn;
            $scope.checkInvoice = checkInvoiceFn;

            $scope.invoiceSrv = invoiceSrv;
            $scope.downloadFile = httpSrv.downloadFile;

            this.$onInit = function () {
                /*
                * model - Запрос (сопроводительное письмо)
                * model.order - платежка
                * */
                $scope.model = this.coveringLetterData.data.result;
                /*
                * Измененный запрос (модель для отправки)
                * */
                $scope.modelView = angular.copy($scope.model);

                //нет счетов -> доступно только refund
                if($scope.model.type === 'specify' && $scope.modelView.order.invoices.length === 0){
                    $scope.modelView.type = 'refund';
                }

                $scope.submitted = true;
                $scope.isReadOnly = false;
                $scope.confirmPaymentForm = {};

                $scope.auth = authSrv.getAuth();
                $scope.user = authSrv.getUser();
                //объект пустышка для хранения связи
                $scope.obj = {};
                $scope.obj.allInvoice = false;
                //выбраны ли счета
                $scope.obj.checkInvoice = false;
                _initAccount();


            };

            function _initAccount() {
                if($scope.user.account.id){
                    profileSrv.getPersonalAccount($scope.user.account.id).then(function (response) {
                        if (response.data.success) {
                            $scope.user.account.accountId = response.data.result.accountId;
                        }
                    }, function errorCallback(response) {
                        $scope.alertObjOrgAgentEditor = alertSrv.getAlertObj(response);
                        console.log(response);
                    });
                }
            }
            /*
            * Предпросмотр подтверждения пдатежа
            * */
            function previewCoveringLetterFn(obj) {
                var data = angular.copy(obj);
                delete data.clickConfirm;
                if (data.type === 'specify') {
                    deleteUnselect(data.order.invoices);
                    desktopSrv.removeKeys(data, 'deff__');
                }
                data.orgInn = $scope.user.inn;
                data.orgName = $scope.user.fullName;
                if ($scope.user.typeOrg === 'nr') data.notResident = true;
                var type = (data.type === 'specify') ? "Уточнение назначения платежа"
                    : (data.type === 'third') ? "Подтверждение платежа" : "Зачисление на ЛС платежа";
                var fileName =type+'_'+data.order.numb+'_от_'+$filter('date')(data.order.orderDate, 'dd.MM.yyyy')+'.pdf';
                httpSrv.downloadFile('/accounts/coveringLetters/previewCoveringLetter', fileName, data)
            }
            /*
            * Выбрать все счета
            * */
            function selectAllInvoiceFn(value) {
                angular.forEach($scope.modelView.order.invoices, function (data) {
                    data.deff__selectedInvoice = value;
                });
                $scope.checkInvoice();
            }
            /*
            * При выборе refund сбросить выбранные счета
            * */
            function selectRefundFn() {
                $scope.obj.allInvoice = false;
                $scope.selectAllInvoice(false);
            }
            /*
            * Подписать и отправить
            * */
            function sendAndSignFn(data) {
                $scope.modelView.clickConfirm = true;
                var dataForSave = angular.copy(data);
                delete dataForSave.clickConfirm;

                if (dataForSave.type === 'specify') {
                    deleteUnselect(dataForSave.order.invoices);
                    desktopSrv.removeKeys(dataForSave, 'deff__');
                }
                dataForSave.orgInn = $scope.user.inn;
                dataForSave.orgName = $scope.user.fullName;

                if ($scope.user.typeOrg === 'nr') dataForSave.notResident = true;
                var certId = $scope.auth.certId;
                if (certId) {
                    cryptography.findCertificate(certId).then(function (data) {
                        cryptography.getCertInfoByCert(data).then(function (value) {
                            dataForSave.certificate = value.base64Value;
                            client.get('dict/serverTime', function (serverDateInt) {
                                var serverDate = new Date(parseInt(serverDateInt));
                                if (typeof data.ValidFromDate === "date") {
                                    if (_checkFromDate(serverDate, data.ValidFromDate) && _checkToDate(serverDate, data.ValidToDate)) {
                                        save(dataForSave);
                                    }
                                } else {
                                    data.ValidFromDate.then(function (fromDate) {
                                        _checkFromDate(serverDate, fromDate);
                                        data.ValidToDate.then(function (toDate) {
                                            if (_checkToDate(serverDate, toDate)) {
                                                save(dataForSave);
                                            }
                                        });
                                    });
                                }
                            });
                        });

                    }, function errorCallback() {
                        $scope.alertObjConfirmPayment = alertSrv.getErrorMsg('Крипто-Про плагин не установлен!');
                        $scope.modelView.clickConfirm = false;
                    });
                } else {
                    $scope.alertObjConfirmPayment = alertSrv.getErrorMsg('У вас не установлен сертификат.');
                    $scope.modelView.clickConfirm = false;
                }
                function _checkFromDate(serverDate, fromDate) {
                    if (serverDate < new Date(fromDate)) {
                        $scope.alertObjConfirmPayment = alertSrv.getErrorMsg('Создание документа не доступно. Причина: cрок действия сертификата истек');
                        $scope.modelView.clickConfirm = false;
                        return false;
                    }
                    return true;
                }
                function _checkToDate(serverDate, toDate) {
                    if (new Date(toDate) < serverDate) {
                        $scope.alertObjConfirmPayment = alertSrv.getErrorMsg('Создание документа не доступно. Причина: cрок действия сертификата еще не наступил');
                        $scope.modelView.clickConfirm = false;
                        return false;
                    }
                    return true;
                }

                function save(data) {
                    coveringLetterSrv.answerCoveringLetter(data).then(function (response) {
                        if (response.data.success) {
                            cryptography.generateSignature(response.data.result, $scope.auth.certId).then(function (value) {
                                data.signature = value;
                                data.signDate = response.data.message;
                                coveringLetterSrv.addSignatureToCoveringLetter(data).then(function (response) {
                                    if (response.data.success) {
                                        $rootScope.$emit("checkPaymentOrder");
                                        goBackFn();
                                    } else {
                                        $scope.alertObjConfirmPayment = alertSrv.getAlertObj(response);
                                        $scope.modelView.clickConfirm = false;
                                    }
                                });
                            }, function (reason) {
                                if (typeof reason === 'string') {
                                    //в случаии отмены пользователем отправляем без сигнатуры
                                    if(reason.indexOf("0x8010006E")!==-1){
                                        coveringLetterSrv.addSignatureToCoveringLetter(data);
                                        $scope.alertObjConfirmPayment = alertSrv.getErrorMsg("Ошибка подписи. Действие было отменено пользователем.");
                                    }else{
                                        $scope.alertObjConfirmPayment = alertSrv.getErrorMsg("Ошибка подписи. " + reason);
                                    }
                                } else {
                                    $scope.alertObjConfirmPayment = alertSrv.getErrorMsg("Ошибка подписи. " + reason.name + ': ' + reason.message);
                                }
                                $scope.modelView.clickConfirm = false;
                            })
                        } else {
                            $scope.alertObjConfirmPayment = alertSrv.getAlertObj(response);
                            $scope.modelView.clickConfirm = false;
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                        $scope.alertObjConfirmPayment = alertSrv.getAlertObj(response);
                        $scope.modelView.clickConfirm = false;
                    })
                }
            }
            /*
            * Удалить не выбранные счета
            * */
            function deleteUnselect(data) {
                for (var i=data.length-1;i>=0;i--) {
                    var row = data[i];
                    //Счет не выбран -> удаляем
                    if(!row.deff__selectedInvoice){
                        data.splice(i,1);
                    }
                }
            }
            /*
            * Есть ли выбраные счета
            *   Запускаем проверку при клике по checkbox счета/ов
            * */
            function checkInvoiceFn() {
                const arr = $scope.modelView.order.invoices.filter(item => item.deff__selectedInvoice);
                $scope.obj.checkInvoice = !!arr.length;
                $scope.obj.allInvoice = arr.length === $scope.modelView.order.invoices.length;
            }
            function goBackFn(){
                history.back();
            }
        }
    ]
});
