/**
 * @author: sergeyu
 * Запрос на разъяснение
 */
angular.module('uetp')
    .component('reqExplanation', {
        bindings: {
            createData: '<',
            openData: '<'
        },
        template: '<div ng-include="ctrl.templateUrl">',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'explanationSrv', 'cryptography', 'authSrv', 'desktopSrv', 'httpSrv',
            'alertSrv', 'dialogSrv', 'needConfirmSrv', 'profileSrv', 'permissionSrv', 'participantSrv', 'titleSrv', 'proceduresSrv',
            function ($scope, $rootScope, $state, $stateParams, explanationSrv, cryptography, authSrv, desktopSrv, httpSrv,
                      alertSrv, dialogSrv, needConfirmSrv, profileSrv, permissionSrv, participantSrv, titleSrv, proceduresSrv) {
                var ctrl = this;
                $scope.ctrl = ctrl;
                $scope.ctrl = ctrl;
                $scope.ctrl.templateUrl = '';
                $scope.cancel = cancelFn;
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    _initTemplate();
                    $scope.certError = authSrv.isCertificateError;
                    $scope.submitted = false;
                    $scope.procedure = {};
                    $scope.procedure.type = {};
                    $scope.procedure.type.id = $stateParams.procType;
                    $scope.procedure.id = $stateParams.procId;
                    $scope.auth = authSrv.getAuth();
                    $scope.form = {explanation: {}};
                    $scope.show3DaysWarning = false;
                    if (ctrl.createData && !ctrl.createData.error) {
                        if (ctrl.createData.data.success) {
                            $scope.model = ctrl.createData.data.result.purchase;
                            $scope.model.created = ctrl.createData.data.result.created;
                            $scope.viewModel = ctrl.createData.data.result.purchase;
                            $scope.viewModel.participant = explanationSrv.getParticipantName(ctrl.createData.data.result.participant);
                            $scope.viewModel.isEdit = !ctrl.createData.data.result.id;
                            proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId).then((response) => {
                                if (response.data.success) {
                                    $scope.show3DaysWarning = ctrl.createData.data.result.show3DaysWarning && response.data.result.section==='223' && $scope.cabType() === 'participant';
                                }
                            });

                        }  else {
                            dialogSrv.showDialogMsgHtml($scope, ctrl.createData.data.errorMessage, function () {
                                history.back();
                            });
                        }
                    } else if(ctrl.createData && ctrl.createData.error) {
                        dialogSrv.showDialogMsgHtml($scope, ctrl.createData.error, function () {
                            history.back();
                        });
                    } else if (ctrl.openData) {
                        $scope.model = ctrl.openData.data.result;
                        $scope.viewModel = ctrl.openData.data.result.document.purchase;
                        $scope.viewModel.participant = explanationSrv.getParticipantName(ctrl.openData.data.result.document.participant);
                        $scope.viewModel.desc = explanationSrv.getParticipantName(ctrl.openData.data.result.document.participant);
                        $scope.viewModel.subject = ctrl.openData.data.result.subject;
                        $scope.viewModel.desc = ctrl.openData.data.result.description;
                        $scope.viewModel.isEdit = !ctrl.openData.data.result.id;
                        proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId).then((response) => {
                            if (response.data.success) {
                                $scope.show3DaysWarning = ctrl.openData.data.result.show3DaysWarning && response.data.result.section==='223' && $scope.cabType() === 'participant';
                            }
                        });

                        if ($stateParams.successSend) {
                            $scope.ctrl.alertObjReqExplanation = alertSrv.getSuccessMsgWithBtn('Документ отправлен организатору!');
                        }
                    }
                    if ($scope.viewModel.number) {
                        titleSrv.setTitle($scope.viewModel.number + ' | Запрос на разъяснение');
                    }
                };

                $scope.hasPermission = permissionSrv.hasPermission;

                function _initTemplate() {
                    if ('cbz' === $scope.cabType()) {
                        $scope.ctrl.templateUrl = 'app/components/explanation/request/reqExplanationForCbz.html';
                    } else {
                        $scope.ctrl.templateUrl = 'app/components/explanation/request/reqExplanation.html';
                    }
                }



                /**
                 * Подпись и отправка
                 */
                $scope.signSendData = function () {
                    profileSrv.checkAccredSecured($stateParams.procType, 'true').then(function (checkRes) {
                        if(participantSrv.needUpdateAuth())
                            authSrv.updateOrgAccreditation(checkRes.data.result);
                        if(checkRes.data.result){

                            if ($scope.auth.certId) {
                                var model = angular.copy($scope.model),
                                    requestModel = {};
                                requestModel.subject = model.subject;
                                requestModel.desc = model.desc;
                                requestModel.applId = model.applId;
                                requestModel.created = model.created;
                                $scope.submitted = true;
                                explanationSrv.getDataForSign($stateParams.procType, $stateParams.procId, requestModel, 'explanationRequests').then(function (response) {
                                    if (response.data.success) {
                                        cryptography.generateSignature(response.data.result, $scope.auth.certId).then(function (value) {
                                            requestModel.signature = value;
                                            explanationSrv.sendSignData($stateParams.procType, $stateParams.procId, requestModel, 'explanationRequests').then(function (response) {
                                                if (response.data.success) {
                                                    participantSrv.addFavorite($stateParams.procId);
                                                    $state.go('openReqExplanation', {
                                                        procType: $stateParams.procType,
                                                        procId: $stateParams.procId,
                                                        explReqId: response.data.result,
                                                        successSend: true
                                                    }, {location: 'replace'});
                                                } else {
                                                    $scope.submitted = false;
                                                    $scope.ctrl.alertObjReqExplanation = alertSrv.getAlertObj(response);
                                                }

                                            })
                                        }, function (reason) {
                                            $scope.submitted = false;
                                            if (typeof reason === 'string') {
                                                $scope.ctrl.alertObjReqExplanation = alertSrv.getErrorMsgWithBtn("Ошибка подписи документа. " + reason);
                                            } else {
                                                $scope.ctrl.alertObjReqExplanation = alertSrv.getErrorMsgWithBtn("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                                            }
                                        })
                                    } else {
                                        $scope.submitted = false;
                                        $scope.ctrl.alertObjReqExplanation = alertSrv.getAlertObj(response);
                                    }

                                })
                            } else {
                                $scope.submitted = false;
                                $scope.ctrl.alertObjReqExplanation = alertSrv.getErrorMsgWithBtn('У вас не установлен сертификат.');
                            }
                        } else {
                            $scope.ctrl.alertObjReqExplanation = alertSrv.getErrorMsgWithBtn('Отправка заявки невозможна! Отсутствует или закончилась аккредитация у организации. ', 'Перейти', goToAccreditation, 'profile');
                        }
                    });
                };
                /**
                 * Переход в профиль, вкладка "аккредитация"
                 */

                function goToAccreditation () {
                    $state.go("profile",({activeProfileTab:'accreditation'}))
                }

                $scope.uploadSuccess = function (result) {
                    $scope.model.file = {
                        fileName: result[0].fileName,
                        id: result[0].id
                    };
                    $scope.model.applId = result[0].id;

                };
                $scope.deleteAttach = function () {
                    $scope.model.file = null;
                    $scope.model.applId = null;
                };
                $scope.reset = function () {
                    history.back();
                };
                $scope.downloadFile = function (file) {
                    const url = $scope.ctrl.createData ?
                        '/fs/file/' + $scope.procedure.type.id + '/' + $scope.procedure.id + '/files/' + file.id
                        :'/fs/file/' + $scope.procedure.type.id + '/' + $scope.procedure.id + '/explanationRequests/' + $scope.model.id + '/files/' + file.id;
                    httpSrv.downloadFile(url, file.fileName);
                };

                function cancelFn() {
                    if($scope.form.explanation.$dirty){
                        needConfirmSrv.call($scope.reset,[],'Внесенные изменения сохранены не будут');
                    }else{
                        $scope.reset();
                    }
                }

            }]
    });

