/**
 * Заявление на банковскую гарантию Loko банк
 *
 * @author dmitrys
 */
angular.module('uetp')
    .component('bankGuaranteeAppLoko', {
        templateUrl: 'app/components/bankGuaranteeAppLoko/bankGuaranteeAppLoko.html',
        bindings: {
            bankGuaranteeAppData: '<',
            participantInfoData: '<'
        },
        controller: ['$scope', '$state', '$stateParams', 'constants', 'bankGuaranteeSrv', '$filter', 'dictionarySrv', 'httpSrv', 'alertSrv', 'authSrv', 'desktopSrv', 'needConfirmSrv', 'propertiesService', 'proceduresSrv', 'bankGuaranteeLokoSrv',
            function ($scope, $state, $stateParams, constants, bankGuaranteeSrv, $filter, dictionarySrv, httpSrv, alertSrv, authSrv, desktopSrv, needConfirmSrv, propertiesService, proceduresSrv, bankGuaranteeLokoSrv) {

                var ctrl = this;

                $scope.goBack = goBackFn;
                $scope.clickRow = clickRowFn;
                $scope.delRow = delRowFn;
                $scope.save = saveFn;
                $scope.send = sendFn;
                $scope.bikChangeHandler = bikChangeHandlerFn;
                $scope.refreshDict = refreshDictFn;
                $scope.addFile = addFileFn;
                $scope.downloadFile = downloadFileFn;
                $scope.getActiveLevel = bankGuaranteeSrv.getActiveLevel;
                $scope.setActiveLevel = bankGuaranteeSrv.setActiveLevel;
                $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                $scope.getActiveTab = bankGuaranteeSrv.getActiveTab;
                $scope.setActiveTab = setActiveTabFn;
                $scope.getDict = bankGuaranteeSrv.getDict;
                $scope.getStaticDictByType = dictionarySrv.getStaticDictByType;
                $scope.clearDopInfoByProxy = bankGuaranteeSrv.clearDopInfoByProxy;
                $scope.getInvoker = bankGuaranteeLokoSrv.getInvoker;
                $scope.submitted = false;
                $scope.decisionFormSubmitted = false;
                $scope.selectPurchaseEIS = selectPurchaseEISFn;
                $scope.getLinkPurchaseEIS = getLinkPurchaseEISFn;
                $scope.countryChange = countryChangeFn;
                $scope.changeCurrency = changeCurrencyFn;
                $scope.scrollBottom = scrollBottomFn;
                $scope.showAgreement = showAgreementFn;
                $scope.startWork = startWorkFn;
                $scope.downloadOrder = downloadOrderFn;
                $scope.downloadPDFOrder = downloadPDFOrderFn;
                $scope.isRequestProcessed = isRequestProcessedFn;
                $scope.refreshRequestProcessed = refreshRequestProcessedFn;
                $scope.setDecisionType = setDecisionTypeFn;
                $scope.getDecisionType = getDecisionTypeFn;
                $scope.deleteOtherField = deleteOtherFieldFn;
                $scope.sendDecision = sendDecisionFn;
                $scope.isReqComment = isReqCommentFn;
                $scope.addDecisionFile = addDecisionFileFn;
                $scope.modelView = {
                    agreementCheck: false,//согласие на обработку данных
                    agreementCheckBik: false,//согласие на получение АКБ
                    agreementTypeShow: null,// Тип отображаемого согласия BIK или ISP
                    dict: null,//справочники
                    isReadOnly: false,//форма редактирования
                    doingSaveOrSend: false,//процесс сохранения или отправки
                    doingSaveAndSend: false,//процесс сохранения и отправки
                    formCreate: false,// форма создания
                    isRefresh: false,// обновление или создание анкеты
                    isAgent: false,//Форма создания агентом
                    participantInfo: null,//Информация по пользователю, за которого заполняет данные агент
                    workStarter: null, // ФИО сотрудника банка взявшего анкету в работу
                    requestTassId: null, // id сформированого вложения
                    decisionType: null, // Тип решения по выдаче гарантии (reject, requestToClient, agreementClient)
                    decision: null, // Решение по выдаче гарантии
                    decisionFileName: null // Имя файла для решения
                };
                $scope.decisionTypes = {
                    reject: 'Отказать в выдаче',
                    requestToClient: 'Запросить уточнение',
                    agreementClient: 'Гарантия одобрена'
                }
                $scope.tooltipInfoPhone = "Телефон необходимо указывать вместе с кодом города. Пример формата ввода номера: +7 (123) 456-78-90";
                $scope.tooltipInfoAddress = "Формат указания адреса: 195112, г. Санкт-Петербург, Малоохтинский пр., д. 68";
                /*
                * Формы для валидации
                * Каждая вкладка в отдельной форме
                * */
                $scope.companyInfo = {};
                $scope.companyManagement = {};
                $scope.bankGuarantee = {};
                $scope.decisionForm = {};

                $scope.constants = constants;

                $scope.dateOptions = {};
                $scope.tomorrow = getServerTimeMsk();
                if ($scope.tomorrow) {
                    $scope.tomorrow.setDate($scope.tomorrow.getDate() + 1);
                    $scope.tomorrow.setHours(0, 0, 0, 0);
                    $scope.dateOptionsGuaranteeStart = {
                        minDate: $scope.tomorrow
                    };
                }

                $scope.paginator = {
                    itemsPerPage: 20,
                    page: 0
                };
                $scope.dateGuaranteeStart = {
                    opened: false
                };
                /*
                * Типы документов - основной блок
                * */
                $scope.modelView.documentTypeList = ['doc_principalCharter', 'doc_principalDocumentEIO', 'doc_otherClientDocs', 'doc_guarantorDocumentEIO',
                    'doc_Nonresident', 'doc_finReportQ', 'doc_taxForm', 'doc_principalFinReport', 'doc_principalDocumentConfirming', 'doc_bigDealApproveLot'
                ];

                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.auth = authSrv.getAuth();
                    $scope.user = authSrv.getUser();
                    $stateParams.bank = 'loko';
                    initComponent();
                };

                function initComponent() {
                    //форма создания
                    var isAppCreate = getAppCreate();
                    //форма редактирования
                    var isAppOpen = getAppOpen();
                    //форма создания копии
                    var isAppCopy = getAppCopy();
                    //форма агента
                    var isAppAgent = getAppAgent();
                    var participant = null;
                    //Информация по пользователю, за которого заполняет данные агент
                    if (ctrl.participantInfoData && ctrl.participantInfoData.data && ctrl.participantInfoData.data.success) {
                        $scope.modelView.participantInfo = ctrl.participantInfoData.data.result;
                        $scope.modelView.participantSelected = $stateParams.participantSelected;
                    }
                    //если попали под участником на состояние агента - редирект на правильное состояние
                    if (!authSrv.isUserAgent()) {
                        if (isAppAgent) {
                            //для формы создания
                            if (isAppCreate) {
                                $state.go('bankGuaranteeAppLokoCreate')
                            } else if (isAppOpen) {
                                $state.go('bankGuaranteeAppLokoOpen', {id: $stateParams.id.id, clearConfig: true})
                            } else if (isAppCopy) {
                                //для формы копирования
                                $state.go('bankGuaranteeAppLokoCopy', {id: $stateParams.id, clearConfig: true})
                            }
                        }
                    }
                    if (!(authSrv.canActiveBankGuarantee($scope.cabType(), $scope.user) || authSrv.isUserAgent() || ['admincab', 'support', 'bank'].includes($scope.cabType()))) {
                        $state.go('lokobank');
                    }
                    dictionarySrv.resetPaginatedDict($scope);
                    bankGuaranteeSrv.setDictRemote(dictionarySrv.getRemoteData());
                    $scope.dictionary = bankGuaranteeSrv.getDictRemote();
                    _initDict(['okcm'], false);

                    $scope.refreshDict('rOKPD2EIS', null, true);

                    $scope.modelView.isAgent = isAppAgent;
                    if (isAppOpen) {
                        $scope.modelView.isRefresh = true;
                        $scope.modelView.formCreate = false;
                        if (ctrl.bankGuaranteeAppData && ctrl.bankGuaranteeAppData.data && ctrl.bankGuaranteeAppData.data.success) {
                            const result = ctrl.bankGuaranteeAppData.data.result
                            //статус заявки
                            $scope.modelView.state = result.state.id;
                            //время создания
                            $scope.modelView.created = result.created;
                            //заявка
                            var data = result.order;
                            bankGuaranteeSrv.setData(getModelFromView(data));
                            //главки согласия
                            $scope.modelView.agreementCheck = data.agreement;
                            $scope.modelView.agreementCheckBik = data.agreementBki;
                            $scope.modelView.workStarter = result.workStarter;
                            participant = result.participant;
                            if ($stateParams.successSave) {
                                $scope.alertObjbankGuaranteeApp = alertSrv.getSuccessMsg('Заявка сохранена успешно.');
                                setTimeout(function () {
                                    desktopSrv.scrollBottom();
                                }, 1500);
                            }
                            if ($scope.cabType() === 'bank') {
                                bankGuaranteeLokoSrv.autoCreateTassParticipant($stateParams.id, result.order.principal.inn).then(function (response) {
                                    if (response && response.data && response.data.success) {
                                        $scope.modelView.requestTassId = response.data.result;
                                    }
                                })
                            }
                        }

                    }
                    /*
                    * Копируем анкету
                    * */
                    if (isAppCopy) {
                        $scope.modelView.formCreate = true;
                        $scope.modelView.isRefresh = false;
                        if (ctrl.bankGuaranteeAppData && ctrl.bankGuaranteeAppData.data && ctrl.bankGuaranteeAppData.data.success) {
                            //статус заявки
                            $scope.modelView.state = 'draft';
                            //заявка
                            var data = ctrl.bankGuaranteeAppData.data.result.order;
                            //UETP-6047 data.documents = [];
                            delete data.agreement;
                            bankGuaranteeSrv.setData(getModelFromView(data));
                        }
                    }
                    if ($stateParams.clearConfig) {
                        if (isAppCreate) bankGuaranteeSrv.setData({principal: {}});
                        $scope.setActiveTab('bankGuarantee');
                        $scope.setActiveLevel('app');
                    }
                    if (isAppCreate) {
                        $scope.modelView.state = 'draft';
                        $scope.modelView.formCreate = true;//форма создания
                        $scope.modelView.isRefresh = false;
                        /*
                        * Заполняем значениями по умолчанию
                        * participantInfo - информация по участнику (есть если заполняет агент)
                        * */
                        var data = bankGuaranteeLokoSrv.getDefaultModel($scope.modelView.participantInfo);
                        bankGuaranteeSrv.setData(data);
                        participant = $scope.user;
                    }
                    //если статус не черновик или ожидаются действия от пользователя, то можно редактировать
                    $scope.modelView.isReadOnly = true;
                    if (($scope.modelView.state === 'draft') && !['admincab', 'support'].includes($scope.cabType())) {
                        $scope.modelView.isReadOnly = false;
                    }

                    $scope.model = bankGuaranteeSrv.getData();

                    if ($stateParams.purchasData) {
                        bankGuaranteeLokoSrv.setDataFormPurchase($scope.model, $stateParams.purchasData, $stateParams.purchasDataType);
                    }
                    if (participant) {
                        let typeOrg = participant.typeOrg;
                        if ($stateParams.participantId) {
                            typeOrg = $scope.modelView.participantInfo.typeOrg;
                        }
                        initDocument(typeOrg);
                        getDocumentFromView(angular.copy($scope.model));
                    }
                    $scope.modelView.isEdit = isAppOpen;

                    //заблокировать вкладку документа для анкеты в статусе черновик (draft)
                    $scope.modelView.disabledDocuments = $scope.modelView.state === 'draft' && isAppCreate || isAppCopy;
                    // Сохраняем признаки формы в сервис
                    bankGuaranteeSrv.setStateApp(false, isAppCreate, isAppOpen, isAppCopy, isAppAgent, $scope.modelView.isReadOnly);
                }

                /**
                 * Заполнить анкету по закупке из ЕИС
                 * поиск по номеру закупки
                 * */
                function selectPurchaseEISFn() {
                    if ($scope.model.bankGuarantee && $scope.model.bankGuarantee.purchaseNumber) {
                        var param = {
                            searchByField: {purchase_number: $scope.model.bankGuarantee.purchaseNumber}
                        };
                        proceduresSrv.searchPurchases($scope, param).then(function (response) {
                            if (response && response.data && response.data.success) {
                                var purchaseData = response.data.items;
                                if (purchaseData && purchaseData.length > 0)
                                    bankGuaranteeLokoSrv.setDataFormPurchase($scope.model, purchaseData[0], "EIS");
                            }
                        })


                    }
                }

                /**
                 * Установть переменную наименования
                 * @param row - выбраная запись
                 * @param model - модель
                 * @param key - ключ для установки
                 */
                function countryChangeFn(row, model, key) {
                    var selected = row.$select.selected;
                    model[key+"Name"] = selected? selected.ncm: null;
                }

                /*
                * Ссылка на закупку ЕИС по ее номеру для всех ФЗ
                * */
                function getLinkPurchaseEISFn(purchaseNumber) {
                    return 'https://zakupki.gov.ru/epz/order/extendedsearch/results.html?searchString=' + purchaseNumber + '&morphology=on';
                }


                function _initDict(dictList, reload) {
                    angular.forEach(dictList, function (name) {
                        dictionarySrv.getDictionaryRemote(name, reload);
                    })
                }

                /*
               * Получаем полный список всех возможных типов документов и проставляем тип блока (основной/дополнительный)
               * Заполняем основной блок документов (documents)
               * */
                function initDocument(typeOrg) {
                    const documents = angular.copy(bankGuaranteeLokoSrv.getDocTypeList(typeOrg));
                    $scope.modelView.documents = [];

                    angular.forEach(documents, function (item) {
                        $scope.modelView.documents.push({
                            type: item.type,
                            label: item.label,
                            isMultiple: item.isMultiple,
                            isRequired: item.isRequired,
                            canEdit: item.canEdit,
                            info: item.info,
                            isShow: true,//отображение в списке
                            files: []
                        })

                    });

                }

                /*
                * Установить активную вкладку
                * */
                function setActiveTabFn(tab) {
                    if (tab === 'documents' && $scope.modelView.disabledDocuments) return;
                    bankGuaranteeSrv.setActiveTab(tab)
                }

                /*
                * Форма создания
                * */
                function getAppCreate() {
                    return $state.includes('bankGuaranteeAppLokoCreate') || $state.includes('bankGuaranteeAppLokoAgentCreate');
                }

                /*
                * Форма редактирования
                * */
                function getAppOpen() {
                    return $state.includes('bankGuaranteeAppLokoOpen') || $state.includes('bankGuaranteeAppLokoAgentOpen');
                }

                /*
                * Форма создания копии
                * */
                function getAppCopy() {
                    return $state.includes('bankGuaranteeAppLokoCopy') || $state.includes('bankGuaranteeAppLokoAgentCopy')
                }

                /*
                * Форма агента
                * */
                function getAppAgent() {
                    return $state.includes('bankGuaranteeAppLokoAgentCreate') || $state.includes('bankGuaranteeAppLokoAgentOpen')
                        || $state.includes('bankGuaranteeAppLokoAgentCopy')
                }

                /*
                * Подготовляем документы для отображения
                * */
                function getDocumentFromView(model) {
                    /*
                    * Предзаполняем список пустыми значениями
                    * */
                    $scope.model.documents = angular.copy($scope.modelView.documents);

                    /*
                    * Проставляем файлы
                    * */
                    if (model.documents) {
                        angular.forEach(model.documents, function (file) {
                            angular.forEach($scope.model.documents, function (item) {
                                if (file.type === item.type) {
                                    if (!item.files) item.files = [];
                                    item.files.push(file)
                                }
                            });
                        })
                    }
                }

                /**
                 * Заполнить Номер корреспондентского счета банка из выбранного банка
                 */
                function bikChangeHandlerFn(selectObj, dataObj) {
                    var selected = selectObj.$select.selected;
                    dataObj.bic = selected ? selected.bic : "";
                    dataObj.accounts = selected ? selected.accounts : "";
                    dataObj.participant = selected ? selected.participant : "";
                }

                /**
                 * Обновление справочника
                 * @param object - тип справочника
                 * @param value - введенные значения
                 * @param refreshScrolling - обновление данных при прокрутке
                 */
                function refreshDictFn(object, value, refreshScrolling, needToAdd, searchValue) {
                    if (!value) {
                        if (object === 'rBIK') {
                            value = searchValue;
                        }
                    }
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling, null, needToAdd)
                }

                /*
                 * Добавить файлы по типу вложения
                 * @param files - файлы
                 * @param type - тип вложения
                 * */
                function addFileFn(files, type) {
                    if (!$scope.model.documents) $scope.model.documents = [];
                    angular.forEach($scope.model.documents, function (item) {
                        if (item.type === type) {
                            angular.forEach(files, function (file) {
                                item.files.push(
                                    {
                                        created: file.created,
                                        fileName: file.fileName,
                                        id: file.id,
                                        mimeType: file.mimeType,
                                        sended: file.sended,
                                        size: file.size,
                                        state: file.state,
                                        type: file.type
                                    }
                                )
                            })
                        }
                    })
                }

                /*
                * Подготовляем документы для сохранения
                * */
                function getDocumentFromSave(model) {
                    const documents = [];
                    angular.forEach(model.documents, function (item) {
                        angular.forEach(item.files, function (file) {
                            if (!file.type) file.type = item.type;
                            documents.push(file)
                        })
                    })
                    return documents;
                }

                /*
                * Проверка документов
                * Можно добавить только 10 документов в один блок
                *
                * return {
                *   result:true, - результат проверки
                *   list:[] - список блоков в которых ошибка
                * }
                * */
                function doCheckCountDocument(data) {
                    const resultCheck = {result: true, list: []};
                    angular.forEach(data.documents, function (item) {
                        if (item.files && item.files.length > 10) {
                            resultCheck.result = false;
                            resultCheck.list.push(item.label);
                        }
                    });
                    return resultCheck;
                }

                /*
                * Проверяем кол-во вложений и формируем сообщение с ошибкой
                * */
                function checkCountDocument(data) {
                    var checkSizeDoc = doCheckCountDocument(data);
                    if (!checkSizeDoc.result) {
                        var msg = '';
                        if (checkSizeDoc.list.length === 1) {
                            msg = 'На вкладке "Документы" в разделе "' + checkSizeDoc.list[0] + '" добавлено больше 10 вложений ';
                        } else {
                            msg = 'На вкладке "Документы" в разделах: ';
                            angular.forEach(checkSizeDoc.list, function (item) {
                                msg += '<br>"' + item + '"';
                            });
                            msg += '<br>добавлено больше 10 вложений ';
                        }
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsgWithBtn(msg, 'Перейти', $scope.setActiveTab,
                            'documents');
                        $scope.modelView.doingSaveOrSend = false;
                    }
                    return checkSizeDoc.result;
                }

                function downloadFileFn(url, fileName) {
                    httpSrv.downloadFile(url, fileName)
                }

                function goBackFn() {
                    if ($scope.modelView.isAgent) {
                        $state.go('lokobank', {participantInfo:  $scope.modelView.participantInfo, participantSelected: $scope.modelView.participantSelected});
                    } else {
                        history.back();
                    }
                }

                /*
                * Открытие или создание новой строки
                * type - тип состояния
                * data - данные (если null - создание)
                * index - индекс в массиве
                * */
                function clickRowFn(type, data, index) {
                    //data пустая когда создание
                    var isNew = data === null;
                    bankGuaranteeSrv.setTableForm(data, type, isNew, $scope.modelView.isReadOnly, index);
                    $scope.setActiveLevel('table');
                }

                /*
                * Сохранение анкеты
                * */
                function saveFn(models) {
                    if (!$scope.modelView.doingSaveOrSend) {
                        $scope.modelView.doingSaveOrSend = true;
                    } else {
                        return;
                    }
                    //оишбки кол-ва вложений
                    if (!checkCountDocument(models)) {
                        $scope.modelView.doingSaveOrSend = false;
                        return;
                    }

                    //форма создания
                    var isAppCreate = getAppCreate();
                    //форма создания копии
                    var isAppCopy = getAppCopy();
                    //форма создания агентом
                    var isAppAgent = getAppAgent();
                    var model = getModelFromSave(angular.copy(models));

                    bankGuaranteeLokoSrv.save(model, $stateParams.id, $scope.modelView.isRefresh, false).then(function (response) {
                        if (response.data && response.data.success) {
                            /*
                            * Была форма создания, после сохранения открываем по id
                            * */
                            if (isAppCreate || isAppCopy) {
                                if (isAppAgent) {
                                    $state.go('bankGuaranteeAppLokoAgentOpen', {
                                        id: response.data.result,
                                        participantId: $stateParams.participantId,
                                        participantSelected: $scope.modelView.participantSelected,
                                        successSave: true
                                    }, {location: 'replace'});
                                } else {
                                    $state.go('bankGuaranteeAppLokoOpen', {
                                        id: response.data.result,
                                        successSave: true
                                    }, {location: 'replace'});
                                }

                            } else {
                                $scope.alertObjbankGuaranteeApp = alertSrv.getSuccessMsg(response.data.result);
                            }
                            $scope.modelView.doingSaveOrSend = false;
                        } else {
                            $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Ошибка!');
                            $scope.modelView.doingSaveOrSend = false;
                            console.log(response)
                        }
                    }, function (reason) {
                        console.log(reason);
                        $scope.modelView.doingSaveOrSend = false;
                    })

                }

                /*
                * Перейи на первое не валидное поле
                * data.form - форма
                * data.tab - вкладка
                * */
                $scope.focusFirstInvalid = function (data) {
                    var form = data.form;
                    var tab = data.tab;
                    if ($scope.getActiveTab() != tab)
                        $scope.setActiveTab(tab);
                    setTimeout(function () {//отрисовка
                        $scope.submitted = true;
                        if (form && form.$error && form.$error.required && form.$error.required[0]
                            && form.$error.required[0].$$element && form.$error.required[0].$$element[0]) {
                            desktopSrv.scollTop();
                            form.$error.required[0].$$element[0].focus();
                        }
                    }, 400)
                };

                /*
                * Отправить
                **/
                function sendFn(models) {
                    if (!$scope.modelView.doingSaveAndSend) {
                        $scope.modelView.doingSaveAndSend = true;
                    } else {
                        return;
                    }
                    if ($scope.bankGuarantee.form && $scope.bankGuarantee.form.$invalid) {
                        showRequireFieldErrorMessage($scope.bankGuarantee.form, 'bankGuarantee');
                        return;
                    }

                    /*
                    * Проверяем формы и показываем ошибки
                    * */
                    if ($scope.companyInfo.form && $scope.companyInfo.form.$invalid) {
                        showRequireFieldErrorMessage($scope.companyInfo.form, 'companyInfo');
                        return;
                    }
                    if ($scope.companyManagement.form && $scope.companyManagement.form.$invalid) {
                        showRequireFieldErrorMessage($scope.companyManagement.form, 'companyManagement');
                        return;
                    }
                    if (!models.principal.beneficiariesLoko || models.principal.beneficiariesLoko.length === 0) {
                        showRequireFieldErrorMessage($scope.bankGuarantee.form, 'bankGuarantee');
                        return;
                    }
                    if (!models.principal.foundersLoko || models.principal.foundersLoko.length === 0) {
                        showRequireFieldErrorMessage($scope.bankGuarantee.form, 'founders');
                        return;
                    }
                    // Проверяем наличие обязательных вложений
                    //и заполненость всех обязательных полей в таблице 'Заказчики' на вкалдке 'Гарантия'
                    var checkDoc = true;
                    angular.forEach(models.documents, function (item) {
                        if (item.isRequired) {
                            if (!item.files || (item.files && item.files.length === 0)) checkDoc = false;
                        }
                    });
                    if (!checkDoc) {
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsgWithBtn('Добавьте все обязательные вложения. ', 'Перейти', $scope.setActiveTab,
                            'documents');
                        $scope.modelView.doingSaveAndSend = false;
                        return;
                    }
                    //Проверяем все обязательные поля таблицы 'Заказчики'
                    var checkBeneficiaries = true;
                    angular.forEach(models.principal.beneficiariesLoko, function (item) {
                        if (!item.actualAddress || !item.inn || !item.kpp || !item.legalAddress
                            || !item.ogrn || !item.fullName) {
                            checkBeneficiaries = false;
                        }
                    })
                    if (!checkBeneficiaries) {
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsgWithBtn('Необходимо заполнить обязательные поля в таблице "Заказчики". ', 'Перейти', $scope.setActiveTab, 'bankGuarantee');
                        $scope.modelView.doingSaveAndSend = false;
                        return;
                    }

                    //оишбки кол-ва вложений
                    if (!checkCountDocument(models)) {
                        $scope.modelView.doingSaveAndSend = false;
                        return;
                    }

                    /*
                    * Сохраняем форму, а затем подписываем
                    * */
                    var model = getModelFromSave(angular.copy(models));
                    if (!$scope.modelView.agreementCheck) {
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Необходимо согласие на обработку персональных данных');
                        $scope.modelView.doingSaveAndSend = false;
                        return;
                    }
                    if (!$scope.modelView.agreementCheckBik) {
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Необходимо согласие на получение АКБ «Абсолют Банк» (ПАО) основной части кредитной истории');
                        $scope.modelView.doingSaveAndSend = false;
                        return;
                    }
                    bankGuaranteeLokoSrv.save(model, $stateParams.id, $scope.modelView.isRefresh, true).then(function (response) {
                        if (response.data && response.data.success) {
                            doSend(model.invoker);
                        } else {
                            $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Ошибка!');
                            console.log(response);
                            $scope.modelView.doingSaveAndSend = false;
                        }
                    }, function (reason) {
                        console.log(reason);
                        $scope.modelView.doingSaveAndSend = false;
                    })

                    function doSend(invoker) {
                        bankGuaranteeLokoSrv.send($stateParams.id, invoker).then(function (response) {
                            $scope.modelView.doingSaveAndSend = false;
                            if (response.data && response.data.success) {
                                //успешная отправка
                                let param = {
                                    saveParam: {
                                        number: model.order.bankGuarantee.purchaseNumber,
                                        date: $scope.modelView.created
                                    }
                                }
                                // Под агентом сохраняем признак выбранной анкеты участником
                                if ($scope.modelView.isAgent) {
                                    param.participantSelected = $scope.modelView.participantSelected;
                                    param.participantInfo = $scope.modelView.participantInfo;
                                }
                                $state.go('lokobank', param);

                            } else {
                                $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Ошибка!');
                                console.log(response)
                            }
                        }, function (reason) {
                            $scope.modelView.doingSaveAndSend = false;
                            console.log(reason);
                        })
                    }

                    /*
                    *  Сообщение о незаполенном поле
                    */
                    function showRequireFieldErrorMessage(form, tab) {
                        $scope.submitted = true;
                        $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsgWithBtn('Необходимо заполнить обязательные поля на форме. ', 'Перейти', $scope.focusFirstInvalid,
                            {form: form, tab: tab});
                        $scope.modelView.doingSaveAndSend = false;
                    }
                }

                /**
                 * Удалить элемент из таблицы
                 * Удалить вложение
                 * @param model - модель
                 * @param index - номер строки для удаления
                 */
                function delRowFn(model, index) {
                    model.splice(index, 1);
                }

                /*
                * Получить модель для сохранения
                * */
                function getModelFromSave(model) {
                    //форма создания агентом
                    var isAppAgent = getAppAgent();
                    if (model.principal) {
                        /*
                        * * Преобразуем дату в формат yyyy-MM-dd и удаляем пустые поля из модели
                        * */
                        convertDateAndEmptyFields(model.principal);
                        convertDateAndEmptyFields(model.bankGuarantee);
                        if (model.principal.inn && model.principal.inn.length !== 10) {
                            //оставляем кпп только у ЮЛ
                            delete model.principal.kpp;
                        }
                        /*
                        * Банк
                        * */
                        if (model.principal.bankAccounts && model.principal.bankAccounts.bank) {
                            var bankObj = {
                                bik: model.principal.bankAccounts.bank.bic,
                                corrNumber: model.principal.bankAccounts.bank.accounts,
                                name: model.principal.bankAccounts.bank.participant
                            };
                            delete model.principal.bankAccounts.bank;
                            model.principal.bankAccounts.bank = bankObj;
                        }
                        if (model.principal.addressesUr && model.principal.addressesUr.rent && model.principal.addressesUr.rent.landlordBankReq) {
                            var bankObj = {
                                bik: model.principal.addressesUr.rent.landlordBankReq.bic,
                                corrNumber: model.principal.addressesUr.rent.landlordBankReq.accounts,
                                name: model.principal.addressesUr.rent.landlordBankReq.participant
                            };
                            delete model.principal.addressesUr.rent.landlordBankReq;
                            model.principal.addressesUr.rent.landlordBankReq = bankObj;
                        }
                        if (model.principal.addressesFac && model.principal.addressesFac.rent && model.principal.addressesFac.rent.landlordBankReq) {
                            var bankObj = {
                                bik: model.principal.addressesFac.rent.landlordBankReq.bic,
                                corrNumber: model.principal.addressesFac.rent.landlordBankReq.accounts,
                                name: model.principal.addressesFac.rent.landlordBankReq.participant
                            };
                            delete model.principal.addressesFac.rent.landlordBankReq;
                            model.principal.addressesFac.rent.landlordBankReq = bankObj;
                        }
                        /*
                        Преобразуем:
                        model.principal.foundersLoko c типом foundersCompanies -> model.principal.founders.foundersCompanies
                        model.principal.foundersLoko с типом foundersPersons -> model.principal.founders.foundersPersons
                        * */
                        if (model.principal.foundersLoko) {
                            var founders = {};
                            angular.forEach(model.principal.foundersLoko, function (item) {
                                var data = angular.copy(item);
                                delete data.foundersTemp;
                                if (!founders[item.foundersTemp.type]) founders[item.foundersTemp.type] = [];
                                founders[item.foundersTemp.type].push(data);
                            });
                            delete model.principal.foundersLoko;
                            model.principal.founders = founders;
                        }
                        /*
                       Преобразуем:
                       model.principal.beneficiariesLoko -> model.bankGuarantee.beneficiaries
                       model.principal.companyBeneficiariesLoko -> model.principal.companyBeneficiaries
                       */
                        if (!model.bankGuarantee) model.bankGuarantee = {};
                        if (model.principal.beneficiariesLoko) {
                            model.bankGuarantee.beneficiaries = model.principal.beneficiariesLoko;
                            delete model.principal.beneficiariesLoko;
                        }
                        if (model.principal.companyBeneficiariesLoko) {
                            model.principal.companyBeneficiaries = model.principal.companyBeneficiariesLoko;
                            delete model.principal.companyBeneficiariesLoko;
                        }
                        /*
                        * Приводим контакты
                        * */
                        if (model.principal.companyBeneficiaries && model.principal.companyBeneficiaries.length > 0) {
                            angular.forEach(model.principal.companyBeneficiaries, function (item) {
                                contractToArray(item);
                            })
                        }
                        if (model.principal.companyHead)
                            contractToArray(model.principal.companyHead);
                        if (model.principal.founders && model.principal.founders.foundersPersons && model.principal.founders.foundersPersons.length > 0) {
                            angular.forEach(model.principal.founders.foundersPersons, function (item) {
                                contractToArray(item);
                            })
                        }
                        /*
                        * Преобразуем адрес два объекта в один массив
                        * */
                        if (model.principal.addressesUr || model.principal.addressesFac)
                            model.principal.addresses = [];
                        if (model.principal.addressesUr) {
                            model.principal.addressesUr.addressType = 'legal';
                            model.principal.addresses.push(model.principal.addressesUr);
                            delete model.principal.addressesUr;
                        }
                        if (model.principal.addressesFac) {
                            model.principal.addressesFac.addressType = 'actual';
                            model.principal.addresses.push(model.principal.addressesFac);
                            delete model.principal.addressesFac;
                        }
                        /*
                        * Преобразуем bankAccounts obj to array
                        * */
                        if (model.principal.bankAccounts) {
                            var obj = angular.copy(model.principal.bankAccounts);
                            delete model.principal.bankAccounts;
                            model.principal.bankAccounts = [obj];
                        }

                        /*
                        * Убираем лишние поля из companyManagement при отсутствии соответствующих галочек
                        * */
                        if (model.principal.companyManagement) {
                            managementCompanyExtraFields(model.principal.companyManagement.soleExecutiveBody);
                        }

                    }
                    /*
                    * Проставляем в заявку обновленный список документов
                    * */
                    var documents = getDocumentFromSave(model);
                    delete model.documents;
                    model.documents = documents;


                    // Согласия
                    model.agreement = $scope.modelView.agreementCheck;
                    model.agreementBki = $scope.modelView.agreementCheckBik;
                    var participant = {};
                    var agentName = null;
                    //форма создания агентом - проставляем id пользователя, за которого агент заполняем бг
                    if (isAppAgent) {
                        if ($stateParams.participantId) {
                            participant.id = $stateParams.participantId;
                            participant.fullName = $scope.modelView.participantInfo.fullName; //наименование участника - запрашиваем при открытии
                            participant.inn = $scope.modelView.participantInfo.inn;
                            participant.uin = $scope.modelView.participantInfo.uin;
                            participant.kpp = $scope.modelView.participantInfo.kpp;
                            participant.ogrn = $scope.modelView.participantInfo.ogrn;
                            participant.typeOrg = $scope.modelView.participantInfo.typeOrg;
                            agentName = $scope.user.fullName;//наименование агента - берем из профиля
                        }
                    } else {
                        participant.id = $scope.user.id;
                        participant.fullName = $scope.user.fullName;
                        participant.inn = $scope.user.inn;
                        participant.uin = $scope.user.uin;
                        participant.kpp = $scope.user.kpp;
                        participant.ogrn = $scope.user.ogrn;
                        participant.typeOrg = $scope.user.typeOrg;
                    }

                    var invoker = $scope.getInvoker($scope.user);

                    // Подготовяливаем объект для сохранения
                    return {
                        order: model,
                        participant: participant,
                        agentName: agentName,
                        invoker: invoker
                    }

                    /*
                    * Преобразуем дату в формат yyyy-MM-dd и удаляем пустые поля из модели
                    * */
                    function convertDateAndEmptyFields(data) {
                        angular.forEach(data, function (value, key) {
                            if (value instanceof Date) {
                                data[key] = $filter('date')(value, 'yyyy-MM-dd');
                            } else if (value === '' || value === null || angular.isUndefined(value)) {
                                delete data[key];
                            } else if (value instanceof Object) {
                                convertDateAndEmptyFields(value);
                            }
                        })
                    }

                    /*
                    * Преобразуем объект в массив с одним элементом
                    * */
                    function contractToArray(data) {
                        if (data.contacts) {
                            var obj = angular.copy(data.contacts);
                            delete data.contacts;
                            data.contacts = [obj];
                        }
                    }

                    function managementCompanyExtraFields(data) {
                        if (data != 'managementCompany') {
                            delete model.principal.companyManagement.managementCompany;
                            delete $scope.model.principal.companyManagement.managementCompany;
                        }
                    }

                }

                /*
                * Получить модель для отображения
                * */
                function getModelFromView(model) {
                    if (model.principal) {
                        /*
                        Преобразуем:
                        model.principal.founders.foundersCompanies -> model.principal.founders c типом foundersCompanies
                        model.principal.founders.foundersPersons -> model.principal.founders с типом foundersPersons
                        * */
                        if (model.principal.founders) {
                            var foundersCompanies = angular.copy(model.principal.founders.foundersCompanies);
                            var foundersPersons = angular.copy(model.principal.founders.foundersPersons);
                            delete model.principal.founders;
                            model.principal.foundersLoko = [];
                            angular.forEach(foundersCompanies, function (item) {
                                item.foundersTemp = {type: 'foundersCompanies'};
                                model.principal.foundersLoko.push(item)
                            });
                            angular.forEach(foundersPersons, function (item) {
                                item.foundersTemp = {type: 'foundersPersons'};
                                model.principal.foundersLoko.push(item)
                            });
                        }
                        // if (model.principal.founders && model.principal.founders.foundersCompanies) {
                        //     model.principal.foundersCompanies = model.principal.founders.foundersCompanies;
                        //     delete model.principal.founders.foundersCompanies;
                        // }
                        // if (model.principal.founders && model.principal.founders.foundersPersons) {
                        //     model.principal.foundersPersons = model.principal.founders.foundersPersons;
                        //     delete model.principal.founders.foundersPersons;
                        // }
                        /*
                        * Приводим контакты
                        * */
                        if (model.principal.companyBeneficiaries && model.principal.companyBeneficiaries.length > 0) {
                            angular.forEach(model.principal.companyBeneficiaries, function (item) {
                                contractsToObj(item);
                            })
                        }
                        if (model.principal.companyHead)
                            contractsToObj(model.principal.companyHead);
                        if (model.principal.foundersLoko && model.principal.foundersLoko.length > 0) {
                            angular.forEach(model.principal.foundersLoko, function (item) {
                                if (item.foundersTemp.type === 'foundersPersons')
                                    contractsToObj(item);
                            })
                        }
                        /*
                        * Преобразуем адрес массив в два объекта
                        * */
                        if (model.principal.addresses && model.principal.addresses.length > 0) {
                            var obj = angular.copy(model.principal.addresses);
                            delete model.principal.addresses;
                            angular.forEach(obj, function (item) {
                                if (item.addressType === 'legal') {
                                    model.principal.addressesUr = item;
                                } else if (item.addressType === 'actual') {
                                    model.principal.addressesFac = item;
                                }
                            })
                        }
                        /*
                        * Преобразуем bankAccounts array to obj
                        * */
                        if (model.principal.bankAccounts && model.principal.bankAccounts.length > 0) {
                            var obj = angular.copy(model.principal.bankAccounts[0]);
                            delete model.principal.bankAccounts;
                            model.principal.bankAccounts = obj;
                            /*
                            * Банк
                            * */
                            if (model.principal.bankAccounts && model.principal.bankAccounts.bank) {
                                var bankObj = {
                                    bic: model.principal.bankAccounts.bank.bik,
                                    accounts: model.principal.bankAccounts.bank.corrNumber,
                                    participant: model.principal.bankAccounts.bank.name
                                };
                                delete model.principal.bankAccounts.bank;
                                model.principal.bankAccounts.bank = bankObj;
                            }
                            if (model.principal.addressesUr && model.principal.addressesUr.rent && model.principal.addressesUr.rent.landlordBankReq) {
                                var bankObj = {
                                    bic: model.principal.addressesUr.rent.landlordBankReq.bik,
                                    accounts: model.principal.addressesUr.rent.landlordBankReq.corrNumber,
                                    participant: model.principal.addressesUr.rent.landlordBankReq.name
                                };
                                delete model.principal.addressesUr.rent.landlordBankReq;
                                model.principal.addressesUr.rent.landlordBankReq = bankObj;
                            }
                            if (model.principal.addressesFac && model.principal.addressesFac.rent && model.principal.addressesFac.rent.landlordBankReq) {
                                var bankObj = {
                                    bic: model.principal.addressesFac.rent.landlordBankReq.bik,
                                    accounts: model.principal.addressesFac.rent.landlordBankReq.corrNumber,
                                    participant: model.principal.addressesFac.rent.landlordBankReq.name
                                };
                                delete model.principal.addressesFac.rent.landlordBankReq;
                                model.principal.addressesFac.rent.landlordBankReq = bankObj;
                            }
                        }

                        /*
                        Преобразуем:
                        model.principal.companyBeneficiaries -> model.principal.companyBeneficiariesLoko
                        * */
                        if (model.principal.companyBeneficiaries) {
                            model.principal.companyBeneficiariesLoko = model.principal.companyBeneficiaries;
                            delete model.principal.companyBeneficiaries;
                        }
                    }
                    if (model.bankGuarantee) {
                        /*
                       Преобразуем:
                       model.bankGuarantee.beneficiaries -> model.principal.beneficiariesLoko
                       */
                        if (model.bankGuarantee.beneficiaries) {
                            model.principal.beneficiariesLoko = model.bankGuarantee.beneficiaries;
                            delete model.bankGuarantee.beneficiaries;
                        }
                    }
                    return model;

                    /*
                    * Преобразуем массив в объект (в массиве всегда один контракт)
                    * */
                    function contractsToObj(data) {
                        if (data.contacts && data.contacts.length > 0) {
                            var obj = angular.copy(data.contacts[0]);
                            delete data.contacts;
                            data.contacts = obj;
                        }
                    }
                }

                /**
                 * Проставляем currencyName и currencyCode
                 */
                function changeCurrencyFn(selectObj) {
                    var selected = selectObj.$select.selected;
                    $scope.model.bankGuarantee.currencyName = selected ? selected.name : null;
                    $scope.model.bankGuarantee.currencyCode = selected ? selected.code : null;
                }

                function scrollBottomFn() {
                    setTimeout(() => {
                        desktopSrv.scrollBottom();
                    })
                }

                /**
                 * Показать окно с согласиями
                 * @param type - тип согласия (BIK или ISP)
                 */
                function showAgreementFn(type) {
                    $scope.modelView.agreementTypeShow = type;
                    this.setActiveLevel('agreement');
                    desktopSrv.scollTop();
                }

                /**
                 * Взять в работу анкету сотрудником банка
                 */
                function startWorkFn() {
                    var invoker = $scope.getInvoker($scope.user);
                    bankGuaranteeLokoSrv.startWork($stateParams.id, invoker).then(function (response) {
                        if (response.data && response.data.success) {
                            $scope.modelView.state = 'inProcess';
                            $scope.modelView.workStarter = invoker.userName;

                        } else {
                            $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Ошибка!');
                            console.log(response)
                        }
                    }, function (reason) {
                        console.log(reason);
                    })
                }

                function downloadOrderFn() {
                    httpSrv.downloadFile('/bankguarantee/lk/orders/downloadOrder/' + $stateParams.id);
                }

                function downloadPDFOrderFn() {
                    if ($scope.isRequestProcessed()) {
                        $scope.downloadFile('/bankguarantee/orders/files/' + $scope.modelView.requestTassId + '/download', 'Выгрузить')
                    }
                }

                function isRequestProcessedFn() {
                    return $scope.modelView && $scope.modelView.requestTassId;
                }

                function refreshRequestProcessedFn() {
                    $scope.modelView.requestTassId = null;
                    bankGuaranteeLokoSrv.createTassParticipant($stateParams.id, $scope.model.principal.inn).then(function (response) {
                        if (response && response.data && response.data.success) {
                            $scope.modelView.requestTassId = response.data.result;
                        }
                    })
                }

                /**
                 * Выбрать тип решения по выдаче гарантии
                 * @param type - тип решения
                 * reject - Отказать в выдаче
                 * requestToClient - Запросить уточнение
                 * agreementClient - Гарантия одобрена
                 */
                function setDecisionTypeFn(type) {
                    $scope.alertObjbankGuaranteeApp = null;
                    if(type === null){
                        $scope.modelView.decision = null;
                        $scope.decisionFormSubmitted = false;
                    }
                    $scope.modelView.decisionType = type;
                }

                function getDecisionTypeFn() {
                    return $scope.modelView.decisionType;
                }
                /**
                 * Удаление доп поля если не выбрано "ИНОЕ"
                 * @param selectNameModel - имя поля в модели(key in model)
                 * @param deleteName - имя поля для удаления из модели(key in model)
                 * @param model - модель данных где будем удалять и искать
                 *
                 */
                function deleteOtherFieldFn(selectNameModel, deleteName, model) {
                    if (model[selectNameModel] !== 'other') {
                        delete model[deleteName];
                    }
                }

                /**
                 * Выбрать решение
                 * reject - Отказать в выдаче
                 * requestToClient - Запросить уточнение
                 * agreementClient - Гарантия одобрена
                 */
                function sendDecisionFn(){
                    if($scope.getDecisionType()) {
                        if ($scope.decisionForm.form.$invalid) {
                            $scope.decisionFormSubmitted = true;
                            $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');
                            return;
                        }

                        const invoker = $scope.getInvoker($scope.user);
                        let files = [];
                        let comment = null;
                        if($scope.modelView.decision) {
                            files = $scope.modelView.decision.files || [];
                            comment = $scope.modelView.decision.comment || null;
                        }
                        const interaction = {
                            invoker: invoker,
                            fileIds: files.map(item => item.id),
                            comment: comment
                        };
                        // Проверка обязательных вложений по типу ответа
                        if(['agreementClient'].includes($scope.getDecisionType())){
                            if(files.length === 0){
                                $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Необходимо добавить вложение!');
                                return;
                            }
                        }
                        if(files.length>10){
                            $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg('Добавлено больше 10 вложений');
                            return;
                        }
                        bankGuaranteeLokoSrv.sendDecision($stateParams.id, $scope.modelView.decisionType, interaction).then(function (response) {
                            if (response.data && response.data.success) {
                                $state.go('lokobank', {
                                    saveParam: {
                                        message: $scope.decisionTypes[$scope.getDecisionType()] + ' по анкете по закупке №'+  $scope.model.bankGuarantee.purchaseNumber,
                                        date: $scope.modelView.created
                                    }
                                });

                            } else {
                                $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg(response.data.message || 'Ошибка!');
                                console.log(response)
                            }
                        }, function (reason) {
                            console.log(reason);
                        })
                    }
                }
                /**
                 * Условия обязательности коммента
                 * @returns {boolean}
                 */
                function isReqCommentFn() {
                    return ['requestToClient', 'agreementClient'].includes($scope.getDecisionType())
                }
                /*
                 * Добавить файл решения
                 * @param files - файлы
                 * */
                function addDecisionFileFn(files) {
                    if(files){
                        if(!$scope.modelView.decision) {
                            $scope.modelView.decision = {}
                        }
                        const decision = $scope.modelView.decision;
                        if(!decision.files){
                            decision.files = [];
                        }
                        angular.forEach(files, function (file) {
                            decision.files.push(
                                {
                                    fileName: file.fileName,
                                    id: file.id,
                                }
                            )
                        })
                    }
                }

            }]
    });
