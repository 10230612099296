/**
 * @author dmitrys
 * Таймер До 300-летия Кузбасса
 */
angular.module('uetp').controller("counter300YearsController", ['$scope','$timeout', function ($scope,$timeout) {
    this.$onInit = async function () {
        /*
        * Счетчик дней, часов, минут и секунд
        * */
        initCounter();
        await getCounterFromServerTime();
        updateCounter();
        updateTime();
    };

    function initCounter(){
        $scope.counter = {
            day:0,
            hours:0,
            minutes:0,
            seconds:0,
            show: false,
            beforeEvent: true
        };
    }

    /*
    * Расчитываем дату до 300-летия Кузбасса
    * */
    async function getCounterFromServerTime() {
        const serverTime = await new Promise((r)=>getServerTime(r));
        if (serverTime) {
            //убираем милисекундны и не учитываем их + Серверное время Московское, добавим еще 4 часа для Кузбасса
            const time = Math.floor((serverTime + 14400000) / 1000);
            // 6 июля 2021 00:00:00 - серверное время
            if(time>=1625518800){
                initCounter();
                $scope.counter.show = true;
                $scope.counter.beforeEvent = false;
                return;
            }
            const allTime = 1625518800 - time;
            //коф-ты для преобразования
            const kDay = 24 * 60 * 60, kHours = 60 * 60, kMinut = 60;
            //дней
            $scope.counter.day = Math.floor(allTime / kDay);
            //находим остаток от деления дней и вычисляем оставшиеся часы
            const hours = allTime % kDay;
            $scope.counter.hours = Math.floor(hours / kHours);
            const minutes = hours % kHours;
            $scope.counter.minutes = Math.floor(minutes / kMinut);
            $scope.counter.seconds = minutes % kMinut;
            $scope.counter.show = true;
            if($scope.counter.day<=0 && $scope.counter.hours<=0 && $scope.counter.minutes<=0 && $scope.counter.seconds<=0)
                $scope.counter.beforeEvent = false;
        }
    }
    function updateCounter () {
        //Выходим если время вышло
        if(
            $scope.counter.seconds <= 0 &&
            $scope.counter.minutes <= 0 &&
            $scope.counter.hours <= 0 &&
            $scope.counter.day <= 0
        ) {
            $scope.counter.beforeEvent = false;
            return;
        }
        $scope.counter.seconds--;
        /*
        * Если секунда == 0 пытаемся запросить у старших разрядов
        * */
        if($scope.counter.seconds<=0){
            if(getMinutes()) $scope.counter.seconds = 59;
        }
        $timeout(updateCounter, 1000);
        /*
        * Запрашиваем из минуты, если минута == 0, пытаемся запросить из часа
        * */
        function getMinutes() {
            if($scope.counter.minutes>0){
                $scope.counter.minutes--;
                return true
            }else {
                var result = getHours();
                if(result) $scope.counter.minutes = 59;
                return result;
            }
        }
        /*
        * Запрашиваем из часа, если час == 0, пытаемся запросить из дня
        * */
        function getHours() {
            if($scope.counter.hours>0){
                $scope.counter.hours--;
                return true
            }else {
                var result = getDay();
                if(result) $scope.counter.hours = 23;
                return result;
            }
        }
        /*
        * Запращиваем из дня
        * */
        function getDay() {
            if($scope.counter.day>0){
                $scope.counter.day--;
                return true
            }else {
                return false;
            }
        }
    }
    /*
    * Синхронизуриуем время раз в минуту
    * */
    function updateTime() {
        $timeout(function () {
            getCounterFromServerTime();
            updateTime();
        }, 60000);
    }
    
    /**
     * Обновляем время, когда вкладка активна
     */
    document.addEventListener("visibilitychange", () => {
	  if (document.visibilityState === 'visible') {
		  getCounterFromServerTime();
	  }
    });
}])
    .directive("counter300Years", [function () {
        return {
            restrict: 'E',
            templateUrl: 'app/directives/counter300Years/counter300Years.html',
            controller: 'counter300YearsController'
        }
    }]).filter('zeroAdd',[function () {
    return function(value) {
        if(value!=null && (value.length===1 || value<10)) return "0"+value;
        return value;
    }
}]);