angular.module('uetp')
    .component('becomeBgAgent', {
        templateUrl: 'app/components/bgAgent/becomeBgAgent/becomeBgAgent.html',
        controller: ['$scope', '$state', 'dialogSrv', 'httpSrv', 'timeout',
            function ($scope, $state, dialogSrv, httpSrv, timeout) {
                this.$onInit = function () {
                    /**
                     * Капча
                     */
                    $scope.model={};
                    $scope.reset = resetFn;
                    $scope.initCaptcha = initCaptchaFn;
                    $scope.refreshCaptcha = refreshCaptchaFn;

                    initCaptchaFn();

                    function resetFn() {
                        $scope.captcha = {};
                    }

                    function initCaptchaFn() {
                        resetFn();
                        $scope.captcha.url = "/rest/auth/captcha.png?r=" + Math.random();
                    }

                    function refreshCaptchaFn() {
                        $scope.captcha.url = "/rest/auth/captcha.png?r=" + Math.random();
                    }

                    $scope.sendBgAgentCollaboration = function(url, data) {
                        $scope.submitted = true;
                        
                        if (!$scope.form.becomeBgAgentFeedback.$valid) {
                            $scope.alertId = 'validError';
                            timeout($scope);
                            return;
                        }

                        httpSrv.http({
                            method: 'POST',
                            url: url,
                            data: data,
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8'
                            }
                        }).then(function successCallback(response) {
                            dialogSrv.showDialogMsg($scope, response.data.success ? "Спасибо, с Вами свяжутся в ближайшее время." : (response.data.message ? response.data.message : "Не удалось отправить сообщение. Попробуйте позже"));
                            $scope.refreshCaptcha();
                        }, function errorCallback(response) {
                            console.log(response);
                        });
                    }

                }
            }
        ]});
