/**
 * @author: sergeyu
 * Просмотр участника
 */
angular.module('uetp')
    .component('participantRequisites', {
        templateUrl: 'app/components/participant/participantRequisites/participantRequisites.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'dictionarySrv', 'dialogSrv', 'profileSrv','participantSrv', 'httpSrv', 'alertSrv', 'organizatorSrv', 'authSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, dictionarySrv, dialogSrv, profileSrv, participantSrv, httpSrv, alertSrv, organizatorSrv, authSrv) {
                this.$onInit = function () {
                    $scope.active = {};
                    $scope.form = {profileForm: {}};
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.typeCustomer = dictionarySrv.getStaticDictByType('typeCustomer');
                    $scope.mspCategory = dictionarySrv.getStaticDictByType('mspCategory');
                    $scope.orgTypes = dictionarySrv.getStaticDictByType('typeOrgDocument');
                    $scope.dictionary = dictionarySrv.getRemoteData();
                    $scope.auth = authSrv.getAuth();
                    $scope.updateOrg = updateOrgFn;
                    _initDict(['okcm', 'fias_region'], false);
                    init();
                };
                $scope.setActivePanel = setActivePanelFn;
                $scope.downloadApplication = downloadApplicationFn;
                $scope.downloadDocsArchive = downloadDocsArchiveFn;
                $scope.getRealAddressTitle = getRealAddressTitleFn;
                $scope.getRegAddressTitle = getRegAddressTitleFn;
                $scope.goBack = goBackFn;
                function _initDict(dictList, reload) {
                    angular.forEach(dictList, function (name) {
                        dictionarySrv.getDictionaryRemote(name, reload);
                    })
                }

                function getRealAddressTitleFn() {
                    if ($scope.active.type == 1) { // Юридическое лицо
                        return 'Фактический адрес';
                    } else if ($scope.active.type == 4) { // Нерезидент
                        return 'Фактическое местонахождение (адрес фактического местонахождения организации, её главного офиса)';
                    } else if ($scope.active.type == 2) { // ИП
                        return 'Фактическое местонахождение';
                    } else if ($scope.active.type == 3) { // Физ. лицо
                        return 'Место фактического проживания';
                    }

                    return '';
                }

                function getRegAddressTitleFn() {
                    if ($scope.active.type == 1) { // Юридическое лицо
                        return 'Юридический адрес';
                    } else if ($scope.active.type == 4) { // Нерезидент
                        return 'Юридический адрес (адрес организации в стране регистрации)';
                    } else if ($scope.active.type == 2) { // ИП
                        return 'Адрес регистрации';
                    } else if ($scope.active.type == 3) { // Физ. лицо
                        return 'Место регистрации';
                    }

                    return '';
                }

                function _initTypeOrg() {
                    var typeOrg = $scope.user.typeOrg;
                    $scope.active.type = typeOrg === 'ul' ? 1 : typeOrg === 'nr' ? 4 : typeOrg === 'fl' ? 3 : 2;
                }

                function updateOrgFn(user) {
                    $scope.submitted = true;
                    if ($scope.form && !$scope.form.profileForm.$valid) {
                        $scope.alertObjParticipantRequisities = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');
                        return;
                    }

                    if(!organizatorSrv.checkRequisites(user.requisites))
                        delete user.requisites;

                    profileSrv.updateParticipantOrg(user).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.alertObjParticipantRequisities = alertSrv.getAlertObj(response,'Изменение реквизитов организации произведено успешно!');
                        } else {
                            $scope.alertObjParticipantRequisities = alertSrv.getAlertObj(response, response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }

                /**
                 * Выставление активной вкладки
                 * @param type - тип вкладки
                 */
                function setActivePanelFn(type) {
                    $scope.model.showPanel = type;
                }

                function downloadApplicationFn(accredId, attachId, attachName) {
                    httpSrv.downloadFile('/fs/file/downloadAttachment/' + accredId + '/' + attachId, attachName);
                }

                function downloadDocsArchiveFn(accredId){
                    return httpSrv.downloadFile('/fs/file/downloadAttachmentArchive/' + accredId);
                }

                function fillDocsByType(docArray){
                    if (!Array.isArray(docArray)) return;
                    docArray.forEach(function(el){
                        $scope.docs[el.type] =  el;
                    })
                }

                function init() {
                    if (!participantSrv.getOrgInfo().data.success) {
                        dialogSrv.showDialogMsg($scope, participantSrv.getOrgInfo().data.message);
                        return;
                    }
                    $scope.user = participantSrv.getOrgInfo().data.result;
                    $scope.userProfile = angular.copy($scope.user);
                    $scope.openForm = true;
                    $scope.subjectMsp = !!$scope.user.mspType;
                    $scope.isChecked = angular.equals($scope.user.regAddress, $scope.user.realAddress) || !$scope.user.realAddress || !$scope.user.realAddress.oksmCode;
                    if ($scope.user.requisites && $scope.user.requisites.bicBank) {
                        var bik = $scope.user.requisites.bicBank,
                            param = {
                                search: bik,
                                itemsPerPage: 1000,
                                page: 0
                            };
                        dictionarySrv.loadPaginatedDict($scope, 'BIK', param, 'rBIK')
                    }
                    $scope.docs={
                        accreditation_egrul:{},
                        accreditation_founder:{},
                        accreditation_proxy:{},
                        accreditation_chief:{},
                        accreditation_maxSum:{},
                        accreditation_passport:{},
                        accreditation_egrip:{},
                        accreditation_other:{}
                    }
                    fillDocsByType($scope.userProfile.applications);
                    _initTypeOrg();
                    if ($scope.user.accreditationId) {
                        _loadAccreditationById($scope.user.accreditationId);
                    }

                    // Для ИП подмена ФИО
                    if($scope.active.type===2 && $scope.userProfile.fullName &&
                        ($scope.auth?.userRole||[]).some(r=>['operator_accountant', 'operator_admin', 'operator_inspector', 'operator_manager', 'operator_support_ro'].includes(r))) {
                        const arr = angular.copy($scope.userProfile.fullName).split(' ');
                        $scope.userProfile.lastName = arr.shift();
                        $scope.userProfile.firstName = arr.shift();
                        $scope.userProfile.middleName = arr.length===0 ? '' : (arr.length>1 ? arr.join(' ') : arr[0]);
                    }
                }

                function goBackFn(){
                    history.back();
                }

                function _loadAccreditationById(id) {
                    profileSrv.getAccreditationById(id).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.accred = response.data.result;
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    })
                }
            }]
    });

