/**
 * @author dmitrys
 */
angular.module('uetp').service('bankGuaranteeVTBSrv', ['$state', 'httpSrv', 'cryptography', 'authSrv', '$filter',
    function ($state, httpSrv, cryptography, authSrv, $filter) {
        const service = this;
        /*
        * Значени по умолчанию для списков
        * codeName - значени списка
        * modelName - имя в модели
        * */
        const defaultSelectedItems = [
            //Тип собственности
            {
                codeName: 'NonGovernmentOwnership',//Негосударственная организация
                modelName: 'ownershipFormGrouping'
            },
            //Кредитная история
            {
                codeName: 'noHistory',//Кредитная история отсутствует
                modelName: 'creditHistory'
            },
            // Тип организации (согласно типа собственности)
            {
                codeName: 'CommercialOrganization',//Коммерческая организация
                modelName: 'ownershipForm'
            },
            //Цели финансово-хозяйственной деятельности организации
            {
                codeName: 'profitFromMainActivity',//Извлечение/получение прибыли от основной хозяйственной деятельности
                modelName: 'financialAndEconomicActivityType'
            },
            //Наличие отзывов кредитных организаций, ранее обслуживавших Клиента, с информацией об оценке деловой репутации
            {
                codeName: 'missing',//Не предоставлены в связи с невозможностью их получения
                modelName: 'financialReviewsCredit'
            },
            //Оценка финансового положения клиента
            {
                codeName: 'Stable',//Стабильное финансовое положение, постоянный положительный финансовый результат в течение последнего налогового периода
                modelName: 'financialPosition'
            },
            //Наличие отзывов контрагентов
            {
                codeName: 'missing',//Не предоставлены в связи с невозможностью их получения
                modelName: 'financialReviewsContragents'
            }
        ];
        /**
         * Докумнты - копии паспортов физ лиц
         */
        let principalPassportDocument = [];
        service.clearDopInfoByProxy = clearDopInfoByProxyFn;
        service.setDataFormPurchase = setDataFormPurchaseFn;
        service.save = saveFn;
        service.deleteApp = deleteAppFn;
        service.setPDL = setPDLFn;
        service.getBankGuarantee = getBankGuaranteeFn;
        service.send = sendFn;
        service.getDocumentsDataForSign = getDocumentsDataForSignFn;
        service.signDocument = signDocumentFn;
        service.sendSignedDocuments = sendSignedDocumentsFn;
        service.getDocTypeList = getDocTypeListFn;
        service.getDefaultModel = getDefaultModelFn;
        service.downloadFileByOrderIdAndType = downloadFileByOrderIdAndTypeFn;
        service.copyFileById = copyFileByIdFn;
        service.getLastJsonFile = getLastJsonFileFn;
        service.hasJsonFile = hasJsonFileFn;
        service.downloadFile = httpSrv.downloadFile;
        service.loadParticipantAgentsList = loadParticipantAgentsListFn;
        /*
        * Методы для работы диалога с банком
        * */
        service.getRequests = getRequestsFn;
        service.answer = answerFn;
        service.sendToBank = sendToBankFn;
        service.getStateInfo = getStateInfoFn;
        service.changeState = changeStateFn;
        service.assignAgent = assignAgentFn;
        service.addPrincipalPassportDoc = addPrincipalPassportDocFn;
        service.addPassportDoc = addPassportDocFn;
        service.delPassportDoc = delPassportDocFn;
        service.findPassportDocById = findPassportDocByIdFn;
        service.findPassportDocByFSId = findPassportDocByFSIdFn;
        service.deletePrincipalPassportDoc = deletePrincipalPassportDocFn;
        service.getPrincipalPassportDocument = getPrincipalPassportDocumentFn;
        service.setPrincipalPassportDocument = setPrincipalPassportDocumentFn;
        service.getPassportDocName = getPassportDocNameFn;
        service.sendDecision = sendDecisionFn;
        service.requestToClientLocal = requestToClientLocalFn;
        service.responseToBankLocal = responseToBankLocalFn;
        service.getDict = getDictFn;

        service.dict = {
            /*
            * Изменить статус анкеты: cписок возможных статусов
            * */
            statesForChange: [
                {code:'draft', name:'Черновик'},
                {code:'failedByBank', name:'Ошибка обработки банком'},
                {code:'rejected', name:'Отказано банком'},
                {code:'rejectedByAgent', name:'Заявка отклонена клиентом'}
            ]
        }

        /*
        * Получить справочник по типу
        * */
        function getDictFn(type) {
            return service.dict[type];
        }

        /*
        * Очистить информацию когда убирают "Представитель действует по доверенности"
        * model - модель для очистки
        * */
        function clearDopInfoByProxyFn(model) {
            delete model['proxyNumber'];
            delete model['proxyDateStart'];
            delete model['proxyName'];
            delete model['proxyDateEnd'];
            delete model['proxyAgreementAmount'];
            delete model['proxyGuaranteeAmount'];
        }

        /*
        * Проставляем данны из закупки в формате модели для отображения
        * model - куда ставим (анкета)
        * purchasData - что ставим (закупка)
        *
        * type - тип откуда пришли данные (
        * UETP - закупки КОМИТА ЭТП (из БД КОМИТА ЭТП)
        * EIS - из Реестра закупок ЕИС (из ElasticSearch purchase_notice*, purchase_notification*)
        * )
        * */
        function setDataFormPurchaseFn(model, purchasData, type) {
            const currentDate = getServerTimeMsk().getTime();
            //однолотовая процедура
            const isOneLot = purchasData.lots && purchasData.lots.length === 1;
            if (!model.bankGuarantee)
                model.bankGuarantee = {};
            if (type === 'UETP') {
                model.bankGuarantee.bankGuaranteeType = purchasData.claimEnds > currentDate ? "participation" : "execution";
                if (purchasData.commercial) {
                    model.bankGuarantee.purchaseNumber = purchasData.number;
                } else {
                    model.bankGuarantee.federalLaw = "223";
                    model.bankGuarantee.purchaseNumber = purchasData.eisNumber;
                }
                //однолотовая процедура
                if (isOneLot) {
                    if (purchasData.claimEnds > currentDate) {
                        model.bankGuarantee.bankGuaranteeSum = purchasData.lots[0].applicationSupplySumm;
                    }
                }
                if (purchasData.commercial) {
                    if (purchasData.type && purchasData.type.id && purchasData.id)
                        model.bankGuarantee.url = "https://etp.comita.ru/openProcedure/" + purchasData.type.id + "/" + purchasData.id;
                } else {
                    model.bankGuarantee.url = purchasData.eisUrl;
                }
                model.bankGuarantee.purchasePublishedDate = $filter('date')(purchasData.claimBegins, 'yyyy-MM-dd');
                model.bankGuarantee.tenderDate = $filter('date')(purchasData.claimEnds, 'yyyy-MM-dd');
                if (purchasData.type && purchasData.type.id) {
                    model.bankGuarantee.auctionType =
                        ["limitedauction",
                            "limitedcontest",
                            "limitedcontestqualification",
                            "limitedrequestproposals",
                            "limitedrequestquotations"].indexOf(purchasData.type.id) !== -1 ? "private" : "public";
                    model.bankGuarantee.tenderType = purchasData.type.name;
                }
                model.bankGuarantee.marketPlace = "Электронная торговая площадка КОМИТА";
                //однолотовая процедура
                if (isOneLot) {
                    model.bankGuarantee.contractMaxPrice = purchasData.priceNoNDS;
                    model.bankGuarantee.lotNumber = "1";
                    model.bankGuarantee.subject = purchasData.lots[0].name;
                    model.bankGuarantee.okpd = [];
                    angular.forEach(purchasData.lots[0].items, function (item) {
                        model.bankGuarantee.okpd.push({
                            name: item.okpd2Name,
                            code: item.okpd2Code
                        })
                    });
                    if (model.bankGuarantee.okpd.length === 0) delete model.bankGuarantee.okpd;
                }
                if (purchasData.customer) {
                    const getField = function (data) {
                        if (data) {
                            return ', ' + data
                        }
                        return ''
                    };
                    const fillAddress = function (address) {
                        let str = '';
                        if (address.addressNonResident) {
                            str += address.addressNonResident;
                        } else {
                            str += address.zip + getField(address.regionOKATOName) + getField(address.area) + getField(address.settlement) + getField(address.oksmName) + getField(address.street) + getField(address.building) + getField(address.subBuilding) + getField(address.office);
                        }
                        return str;
                    };


                    if (!model.principal) model.principal = {};
                    if (!model.principal.beneficiaries) model.principal.beneficiaries = [];
                    const beneficiaries = {};
                    if (purchasData.customer.realAddress) {
                        beneficiaries.actualAddress = fillAddress(purchasData.customer.realAddress);
                    } else {
                        if (purchasData.customer.regAddress) {
                            beneficiaries.actualAddress = fillAddress(purchasData.customer.regAddress);
                        }
                    }
                    if (purchasData.customer.regAddress) {
                        beneficiaries.legalAddress = fillAddress(purchasData.customer.regAddress);
                    }
                    beneficiaries.inn = purchasData.customer.inn;
                    beneficiaries.kpp = purchasData.customer.kpp;
                    beneficiaries.ogrn = purchasData.customer.ogrn;
                    beneficiaries.fullName = purchasData.customer.name;
                    model.principal.beneficiaries.push(beneficiaries);
                }

            } else if (type === 'EIS') {
                //заполняем данными из закупки ЕИС
                model.bankGuarantee.purchaseNumber = purchasData.purchase_number;
                model.bankGuarantee.federalLaw = purchasData.section;
                if (purchasData.submission_close > currentDate) {
                    model.bankGuarantee.bankGuaranteeType = "participation";
                } else {
                    model.bankGuarantee.bankGuaranteeType = "execution";
                }
                //поля из однолотовой процедуры
                if (isOneLot) {
                    if (purchasData.submission_close > currentDate) {
                        model.bankGuarantee.bankGuaranteeSum = purchasData.lots[0].applicationSupplySumm;
                    } else {
                        model.bankGuarantee.bankGuaranteeSum = purchasData.lots[0].contractSupplySumm;
                    }
                }
                if (purchasData.publish_date_first) {
                    model.bankGuarantee.purchasePublishedDate = $filter('date')(purchasData.publish_date_first, 'yyyy-MM-dd');
                }
                if (purchasData.publish_date) {
                    model.bankGuarantee.purchasePublishedDate = $filter('date')(purchasData.publish_date, 'yyyy-MM-dd');
                }
                model.bankGuarantee.tenderDate = $filter('date')(purchasData.submission_close, 'yyyy-MM-dd');
                model.bankGuarantee.tenderType = purchasData.method_name;
                model.bankGuarantee.url = purchasData.url_eis;
                if (purchasData.method_name) {
                    model.bankGuarantee.auctionType = purchasData.method_name.indexOf('с ограниченным участием') >= 0 ? 'private' : 'public';
                }
                if (!model.principal) model.principal = {};
                model.principal.beneficiaries = [];
                angular.forEach(purchasData.customers, function (item) {
                    const beneficiar = {};
                    beneficiar.inn = item.inn;
                    beneficiar.kpp = item.kpp;
                    beneficiar.ogrn = item.ogrn;
                    beneficiar.fullName = item.name;
                    if (purchasData.section === "44" || purchasData.section === "615")
                        beneficiar.customerRegNum = item.regnum;
                    model.principal.beneficiaries = beneficiar;
                });
                //поля из однолотовой процедуры
                if (isOneLot) {
                    model.bankGuarantee.lotNumber = "1";
                    model.bankGuarantee.subject = purchasData.lots[0].lot_name;
                    model.bankGuarantee.marketPlace = purchasData.ep_name;
                    //Необходимо брать поле "startprice_cur" если "startprice"=null
                    model.bankGuarantee.contractMaxPrice = purchasData.lots[0].startprice ? purchasData.lots[0].startprice : purchasData.lots[0].startprice_cur;
                    model.bankGuarantee.okpd = [];
                    angular.forEach(purchasData.lots[0].lotItems, function (item) {
                        if (item.okpd2_code && item.okpd2_name) {
                            model.bankGuarantee.okpd.push({
                                name: item.okpd2_name,
                                code: item.okpd2_code
                            })
                        }
                    });
                    if (model.bankGuarantee.okpd.length === 0) delete model.bankGuarantee.okpd;
                }

            }
        }

        /*
        * Сохранение анкеты
        * refresh = true - обновление
        * */
        function saveFn(data, id, refresh, isCreateDocs) {
            let method = 'POST';
            let url = 'bankguarantee/vtb/orders';
            //анкета с лимитом
            if (refresh) {
                method = 'PUT';
                url = `bankguarantee/vtb/orders/${id}${isCreateDocs ? '?isCreateDocs=true' : ''}`
            }
            return httpSrv.http({
                method: method,
                data: data,
                url: url
            });
        }

        /*
        * Удалить анкету
        * доступно только для черновика
        * */
        function deleteAppFn(orderId) {
            return httpSrv.http({
                method: 'DELETE',
                url: `/bankguarantee/vtb/orders/${orderId}`
            });
        }

        /*
        * Получить анкету по id
        * */
        function getBankGuaranteeFn(id, isCopy) {
            return httpSrv.http({
                method: 'GET',
                url: `bankguarantee/vtb/orders/${id}${isCopy ? '/copy' : ''}`
            });
        }

        /*
        * Отправка анкеты
        * orderId - внутрений id заявки
        * */
        function sendFn(orderId) {
            return httpSrv.http({
                method: 'POST',
                url: `/bankguarantee/vtb/orders/${orderId}/send`
            });
        }

        /*
        * Отправляем вложения и получем данные для подписи
        * orderId - внутрений id заявки
        * data - анкета
        * */
        function getDocumentsDataForSignFn(certHash, orderId) {
            return cryptography.getAlgoInfo(certHash).then(function (data) {
                return httpSrv.http({
                    method: 'POST',
                    url: `/bankguarantee/vtb/orders/${orderId}/getDocumentsDataForSign`,
                    data: data.code
                });
            })
        }

        function _iteratorObject(array) {
            let nextIndex = 0;
            return {
                next: function () {
                    return nextIndex < array.length ?
                        {object: array[nextIndex++], done: false} :
                        {done: true};
                }
            }
        }

        /**
         * Подпись документа
         * @param model - данные для подписи
         * @param certHash - иентификатор сертификата
         * @returns {Promise}
         */
        function signDocumentFn(model, certHash) {
            const iterator = _iteratorObject(model);
            return new Promise(function (resolve, reject) {
                const recursiveSign = function (iterator, object) {
                    cryptography.signHash(object.data, certHash, object.hashAlg).then(function (data) {
                        object.signature = data;
                        const iterate = iterator.next();
                        if (!iterate.done) {
                            recursiveSign(iterator, iterate.object);
                        } else {
                            resolve(model);
                        }
                    }, function (reason) {
                        reject(reason)
                    })
                };
                recursiveSign(iterator, iterator.next().object)
            });
        }

        /**
         * Отправить подписанные данные
         */
        function sendSignedDocumentsFn(data, orderId) {
            return httpSrv.http({
                method: 'POST',
                url: `/bankguarantee/vtb/orders/${orderId}/sendSignedDocuments`,
                data: {dataList: data}
            });
        }

        /*
        * Установить значение ПДЛ (ИПДЛ, МПДЛ, РПДЛ)
        * model - модель
        * value - что выбрали (isIpdl, isMpdl, isRpdl)
        * */

        function setPDLFn(model, value) {
            //сбрасываем значения
            model.mpdlEmployerAddress = "";
            model.mpdlEmployerName = "";
            model.mpdlPosition = "";
            model.mpdlRelation = "";
            model.ipdlEmployerAddress = "";
            model.ipdlEmployerName = "";
            model.ipdlPosition = "";
            model.ipdlRelation = "";
            model.rpdlEmployerAddress = "";
            model.rpdlEmployerName = "";
            model.rpdlPosition = "";
            model.rpdlRelation = "";
            if (value === 'isIpdl') {
                delete model.isMpdl;
                delete model.isRpdl;

                delete model.mpdlEmployerAddress;
                delete model.mpdlEmployerName;
                delete model.mpdlPosition;
                delete model.mpdlRelation;

                delete model.rpdlEmployerAddress;
                delete model.rpdlEmployerName;
                delete model.rpdlPosition;
                delete model.rpdlRelation;


            } else if (value === 'isMpdl') {
                delete model.isIpdl;
                delete model.isRpdl;

                delete model.ipdlEmployerAddress;
                delete model.ipdlEmployerName;
                delete model.ipdlPosition;
                delete model.ipdlRelation;

                delete model.rpdlEmployerAddress;
                delete model.rpdlEmployerName;
                delete model.rpdlPosition;
                delete model.rpdlRelation;
            } else if (value === 'isRpdl') {
                delete model.isMpdl;
                delete model.isIpdl;

                delete model.mpdlEmployerAddress;
                delete model.mpdlEmployerName;
                delete model.mpdlPosition;
                delete model.mpdlRelation;

                delete model.ipdlEmployerAddress;
                delete model.ipdlEmployerName;
                delete model.ipdlPosition;
                delete model.ipdlRelation;
            }
        }

        /*
        * Получение всего диалога с банком по id анкеты
        * */
        function getRequestsFn(orderId) {
            return httpSrv.http({
                method: 'GET',
                url: `/bankguarantee/vtb/orders/${orderId}/requests`
            });
        }

        /*
        * Сохранить ответ
        * orderId - id анкеты
        * requestId - id запроса
        * data - данные ответа
        * */
        function answerFn(orderId, requestId, data) {
            return httpSrv.http({
                method: 'POST',
                url: `/bankguarantee/vtb/orders/${orderId}/requests/answer/${requestId}`,
                data: data
            });
        }

        /*
        * Отправить ответ
        * orderId - id анкеты
        * requestId - id запроса
        * */
        function sendToBankFn(orderId, requestId) {
            return httpSrv.http({
                method: 'POST',
                url: `/bankguarantee/vtb/orders/${orderId}/requests/sendToBank/${requestId}`
            });
        }

        /*
        * Модель по умолчанию в формате модели для отображения
        * userInfo - данные по участнику - если заполняет агент
        * */
        function getDefaultModelFn(userInfo) {
            const model = {};
            model.principal = {};
            let user = authSrv.getUser();
            if (userInfo) {
                user = userInfo;
            }
            model.principal.fullName = user.fullName;
            model.principal.inn = user.inn;
            model.principal.ogrn = user.ogrn;
            model.principal.okpo = user.okpo;
            model.principal.principalSigner = {};
            model.principal.principalSigner.personAttributes = {
                citizenship: 'RUS',
                countryOfResidence: 'RUS'
            };
            model.principal.principalSigner.identityDocument = {
                identityDocumentType: 'passportRf'
            };
            model.bankGuarantee = {};
            model.bankGuarantee.federalLaw = '223';
            model.bankGuarantee.guaranteeReceivingWay = 'online';
            model.principal.companyHead = {};
            model.principal.companyHead.personAttributes = {
                citizenship: 'RUS',
                countryOfResidence: 'RUS'
            };
            model.principal.companyHead.identityDocument = {
                identityDocumentType: 'passportRf'
            };

            model.principal.kpp = user.kpp;
            model.principal.addressesFac = {};
            model.principal.addressesUr = {};
            //нет фактического адреса, но есть юр - ставим везде юр
            if (!user.realAddress) {
                if (user.regAddress) {
                    model.principal.addressesFac.address = user.regAddress.addressAsString;
                    model.principal.addressesUr.address = user.regAddress.addressAsString;
                }
            } else {
                //есть оба
                if (user.regAddress) {
                    model.principal.addressesUr.address = user.regAddress.addressAsString;
                }
                model.principal.addressesFac.address = user.realAddress.addressAsString;
            }

            model.principal.bankAccounts = {};
            model.principal.bankAccounts.useInDocuments = false;

            if (user.requisites) {
                model.principal.bankAccounts.number = user.requisites.acc;
                model.principal.bankAccounts.bank = {};
                model.principal.bankAccounts.bank = {
                    bic: user.requisites.bicBank,
                    accounts: user.requisites.bankAcc,
                    participant: user.requisites.bank
                };
            }
            model.principal.principalBuhPeriods = [
                {}, {}
            ];
            //заполняем выподающие списки вкладки "Доп. информация о компании"
            if (!model.principal.companyInfo) {
                model.principal.companyInfo = {}
            }
            angular.forEach(defaultSelectedItems, function (item) {
                model.principal.companyInfo[item.modelName] = item.codeName;
            });

            model.bankGuarantee.isBigDeal = false;
            model.bankGuarantee.isContractConcluded = false;
            model.bankGuarantee.isGuaranteeInBeneficiaryForm = false;
            model.bankGuarantee.isRequiredSecurityForGuaranteePeriod = false;
            model.bankGuarantee.isIncludedForfeit = false;
            model.bankGuarantee.isSplittedByBeneficiaries = false;
            model.bankGuarantee.prepaymentExists = false;

            return model;
        }

        /**
         * Получение списка агентов, назначенных организации
         * @param id
         * @returns {*}
         */
        function loadParticipantAgentsListFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: `/admin/getParticipantAgentsList/${id}`
            })
        }


        function downloadFileByOrderIdAndTypeFn(data) {
            if (data.bgScanLotFileId && data.bgScanLotFileName)
                httpSrv.downloadFile(`/bankguarantee/vtb/orders/files/${data.bgScanLotFileId}/download`, data.bgScanLotFileName);
        }

        function copyFileByIdFn(fileId) {
            return httpSrv.http({
                method: 'GET',
                url: `/bankguarantee/vtb/orders/files/copy/${fileId}`
            });
        }

        function hasJsonFileFn(data) {
            return data.attachments.find( f =>f.type==='fs_json' && f.state === 'created');
        }

        function getLastJsonFileFn(data) {
            const jsonAtt = data.attachments.filter( f =>f.type==='fs_json' && f.state === 'created');
            return {bgScanLotFileId: jsonAtt[0].id, bgScanLotFileName: 'for_bank.json'};
        }

        function getStateInfoFn(state) {
            if (state === 'waitClient')
                return "<p>Двойным кликом на анкете откройте её на редактирование. Ознакомьтесь с ошибками или <br>требованиями банка, внесите изменения и повторно направьте анкету в банк.</p>";
            else if (state === 'waitResponseFromClient')
                return "<p>Нажмите на кнопку \"Запросы от банка\", ознакомьтесь с запросом. Для подготовки ответа нажмите на <br>кнопку \"Ответить\" рядом с запросом. Для отправки <br>ответа необходимо нажать на кнопку \"Подписать и отправить\"</p>";
            else if (state === 'finished')
                return "<p>Скачать скан гарантии</p>";
            else return "";
        }

        function changeStateFn(orderId, newState) {
            return httpSrv.http({
                method: 'GET',
                url: `/bankguarantee/vtb/orders/changeState/${orderId}/${newState}`
            });
        }

        function assignAgentFn(orderId, agent) {
            let data = {
                id: agent.id,
                param: agent.fullName
            }
            return httpSrv.http({
                method: 'POST',
                url: `/bankguarantee/vtb/orders/assignAgent/${orderId}`,
                data: data
            });
        }
        /**
         * Добавление копии паспорта
         * @param files - файл копии паспорта (всегда 1)
         * @param type - тип вложения
         * @param data -  data.model - модель участника для установки id файла
         */
        function addPassportDocFn(files, type, data) {
            if(data.model && files && files.length === 1){
                const file = files[0];
                file.type = type;
                if(data.model.id)
                    service.deletePrincipalPassportDoc(data.model.id);
                data.model.id = file.id;
                data.model.deff__fileName = file.fileName;
                service.addPrincipalPassportDoc(file)
            }
        }

        /**
         * Удаление файла копии паспорта
         */
        function delPassportDocFn(model, principal) {
            if(!isDocMultiusage(model.id, principal))
                principalPassportDocument = principalPassportDocument.filter(function(value){
                    return value.id !== model.id;
                });
            delete model.id;
            delete model.deff__fileName;
        }

        function isDocMultiusage(id, principal){
            return id===principal.principalSigner.id && id===principal.companyHead.id;
        }

        /**
         * Добавление копии паспорта физ лица
         * @param file - документ
         */
        function addPrincipalPassportDocFn(file) {
            principalPassportDocument.push({
                created: file.created, //
                fileName: file.fileName, //
                id: file.id, //
                mimeType: file.mimeType,
                sended: file.sended,
                size: file.size, //
                state: file.state, //
                type: file.type
            });
        }
        function findPassportDocByIdFn(id) {
            return principalPassportDocument.find(item => item.id === id);
        }

        function findPassportDocByFSIdFn(id) {
            return principalPassportDocument.find(item => item.fileServiceId === id);
        }
        /**
         * Получить копии паспортов физ лиц
         * Возвращаем копию массива
         */
        function getPrincipalPassportDocumentFn() {
            return principalPassportDocument.slice();
        }
        /**
         * Установить копии паспортов физ лиц (при редактирование анкеты)
         */
        function setPrincipalPassportDocumentFn(listPassport) {
            principalPassportDocument = listPassport;
        }
        // TODO: добавить удаление документов при удалении информации по лицу
        function deletePrincipalPassportDocFn(id) {
            angular.forEach(principalPassportDocument, (item, index) => {
                if (item.id === id) {
                    principalPassportDocument.splice(index, 1);
                }
            });
        }
        /**
         * Вывод имени файла из модели
         * Берем имя файла из deff__fileName или ищем в списке файлов
         */
        function getPassportDocNameFn(model) {
            let result = '';
            if (model && model.id) {
                if (model.deff__fileName) {
                    result = model.deff__fileName;
                } else {
                    // Не нашли в модели - ищем в списке и проставляем в модель
                    let file = service.findPassportDocById(model.id);
                    if(!file)
                        file = service.findPassportDocByFSId(model.id);
                    if (file) {
                        result = file.fileName;
                        model.deff__fileName = file.fileName;
                    }
                }
            }
            return result;
        }
        /**
         * Отправить решение
         * @param orderId - внутрений id заявки
         * @param type - тип решения
         * finish - Выдача гарантии по анкете
         * reject - Отказ в выдаче по анкете
         * requestToClient - Запрос уточнения по анкете
         * agreement - Одобрение гарантии по анкете
         * @param interaction - параметры решения
         * */
        function sendDecisionFn(orderId, type, interaction) {
            return httpSrv.http({
                method: 'POST',
                url: `/bankguarantee/vtb/orders/${type}/${orderId}`,
                data: interaction
            });
        }

        /*
        * Отправить запрос на уточнение по анкете ВТБ
        * orderId - id анкеты
        * requestId - id запроса
        * data - данные запроса
        * */
        function requestToClientLocalFn(orderId, data) {
            return httpSrv.http({
                method: 'POST',
                url: `/bankguarantee/vtb/orders/${orderId}/requests/requestToClientLocal`,
                data: data
            });
        }

        /*
        * Отправить ответ назапрос на уточнение по анкете ВТБ
        * orderId - id анкеты
        * requestId - id запроса
        * data - данные запроса
        * */
        function responseToBankLocalFn(orderId, responseId, data) {
            return httpSrv.http({
                method: 'POST',
                url: `/bankguarantee/vtb/orders/${orderId}/requests/responseToBankLocal/${responseId}`,
                data: data
            });
        }


        /*
        * Список типов документов
        * canEdit - можем ли мы приклыдвать файл (если true появится в списках для выбора)
        *       в анкете в блоке дополнительных документов
        *       в чате в списке
        * isMultiple - доступен ли множественный выбор
        * isRequired - обязательный ли тип
        * TODO: для ускореной проверки -  пока нет спровочника имитируем его на клиенте
        * */
        function getDocTypeListFn(typeUser) {
            return [
                {
                    type: "doc_principalExtractRegistry",
                    label: "Копия выписки из реестра акционеров на текущую дату (для АО, срок действия не более 1 (одного) месяца)",
                    isRequired: typeUser === 'ul', // Обязательно для ЮЛ
                    isMultiple: false,
                    canEdit: false
                },
                {
                    type: "doc_principalDocumentEIO",
                    label: "Копия решения об избрании/ назначении на должность единоличного исполнительного органа (директора, генерального директора, президента или исполнительного директора согласно Уставу)",
                    isRequired: typeUser === 'ul', // Обязательно для ЮЛ
                    isMultiple: false,
                    canEdit: false
                },
                {
                    type: "doc_principalCharter",
                    label: "Копии Устава и изменений к нему (при наличии) с отметкой регистрации в УФНС/ИФНC",
                    isRequired: typeUser === 'ul', // Обязательно для ЮЛ
                    isMultiple: false,
                    canEdit: false
                },
                // Тип документа скрыт, прикладываем в разделах
                // {
                //     type: "doc_principalPassport",
                //     label: "Копии паспорта учредителей, ЕИС и бенефициарных владельцев",
                //     isRequired: true,
                //     isMultiple: true,
                //     canEdit: false
                // },
                {
                    type: "ANNUAL_FINANCIAL_REPORT",
                    label: "Бух. отчётность с отметкой ИФНС за последний отчётный год",
                    isRequired: true,
                    isMultiple: false,
                    canEdit: false
                },
                {
                    type: "QUARTERLY_FINANCIAL_REPORT",
                    label: "Бух. отчётность с отметкой ИФНС за последний отчётный квартал",
                    isRequired: true,
                    isMultiple: false,
                    canEdit: false
                },
                {
                    type: "doc_otherClientDocs",
                    label: "Иные документы",
                    isRequired: false,
                    isMultiple: false,
                    canEdit: true
                }
            ]
        }
    }]);
