/**
 * @author: sergeyu
 * Просмотр заказчика
 */
angular.module('uetp')
    .component('customer', {
        templateUrl: 'app/components/customer/customer.html',
        bindings: {
            customerData: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'storageSrv', 'customerSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, storageSrv, customerSrv) {
                var ctrl = this;
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    if (ctrl.customerData.data.success) {
                        customerSrv.setCustomerType(ctrl.customerData.data.result.customerType);
                        customerSrv.setCustomerInfo(ctrl.customerData.data.result);
                        customerSrv.setUetpCodes([]);
                        
                        if(['admincab','support'].includes($scope.cabType()) && (ctrl.customerData.data.result.shortName || ctrl.customerData.data.result.name)) {
                            $scope.shortName = '(' + (ctrl.customerData.data.result.shortName ? ctrl.customerData.data.result.shortName : ctrl.customerData.data.result.name) + ')';
                        }
                    }

                    $scope.model = {
                        showPanel: 'requisites',
                        isEdit: !!$stateParams.id
                    };
                    $scope.stateConfig = $stateParams.params && $stateParams.params.clearConfig ?
                        storageSrv.clearStateConfig($stateParams.activeProfileTab, 'showPanel') : storageSrv.getStateConfig($stateParams.activeProfileTab, 'showPanel');
                    if ($stateParams.activeProfileTab){
                        setActivePanelFn($stateParams.activeProfileTab);
                    }else{
                        if ($scope.stateConfig) {
                            setActivePanelFn($scope.stateConfig.typePanel)
                        }
                    }
                };
                $scope.setActivePanel = setActivePanelFn;
                $scope.hasEisIntegration = customerSrv.hasEisIntegration;
                /**
                 * Выставление активной вкладки
                 * @param type - тип вкладки
                 */
                function setActivePanelFn(type) {
                    $scope.model.showPanel = type;
                    storageSrv.setStateConfig($stateParams.activeProfileTab, 'showPanel', {typePanel: type});
                }

                $rootScope.$on('customerViewSetActivePanel', function(event,type){
                    $scope.model.showPanel = type;
                });
                $rootScope.$on('setCustomerViewName', function(event,type){
                    $scope.shortName = '(' + type + ')';
                });
            }]
    });

