/**
 * Форма создания сопроводительного письма
 *
 * Создает бух внутр платежки
 */
angular.module('uetp')
    .component('coveringLetter', {
        templateUrl: 'app/components/coveringLetter/coveringLetter.html',
        bindings: {
            orgData: '<',
            paymentOrderData: '<'
        },
        controller: ['$scope', '$stateParams', 'coveringLetterSrv', 'alertSrv', 'authSrv', 'httpSrv', 'dictionarySrv',
            function ($scope, $stateParams, coveringLetterSrv, alertSrv, authSrv, httpSrv, dictionarySrv) {

                var ctrl = this;

                $scope.goBack = goBackFn;
                $scope.sendAndSign = sendAndSignFn;
                $scope.checkShowType = checkShowTypeFn;
                $scope.refreshDict = refreshDictFn;

                this.$onInit = function () {


                    $scope.submitted = true;
                    $scope.isReadOnly = false;
                    $scope.model = {};
                    $scope.model.order = {};
                    $scope.model.order.id = $stateParams.id;
                    $scope.model.accountId = $stateParams.acc;
                    $scope.modelView = {};
                    $scope.modelView.acc = {};
                    $scope.coveringLetterForm = {};
                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                    //Есть ЛС
                    if (ctrl.orgData) {
                        if (ctrl.orgData.data.success) {
                            $scope.model.orgMail = (ctrl.orgData.data.items && ctrl.orgData.data.items.length>0)?ctrl.orgData.data.items[0].orgMail:null;
                        }
                    } else {
                        //нет ЛС - открываем выбор
                        $scope.showAcc = true;
                    }
                    $scope.paymentOrder = ctrl.paymentOrderData;
                    $scope.model.accountantEmail = authSrv.getAuth().email;
                    checkShowTypeFn()
                };

                /*
                * отправить запрос
                * */
                function sendAndSignFn(data) {

                    if ($scope.showAcc) {
                        $scope.model.accountId = $scope.modelView.acc.id;
                        //получаем мыло по счету
                        coveringLetterSrv.getOrganizationInfo($scope.modelView.acc.id).then(function successCallback(response) {
                            if (response.data.success) {
                                data.orgMail = response.data.items[0].orgMail;
                                doSendAndSign(data);
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        });
                    } else {
                        doSendAndSign(data);
                    }

                    function doSendAndSign(data) {
                        coveringLetterSrv.sendAndSign(data).then(function successCallback(response) {
                            if (response.data.success) {
                                $scope.alertObjCoveringLetter = alertSrv.getSuccessMsg('Запрос отправлен');
                                setTimeout(300, $scope.goBack());
                            }else{
                                $scope.alertObjCoveringLetter = alertSrv.getAlertObj(response);
                            }
                        }, function (reason) {
                            $scope.alertObjCoveringLetter = alertSrv.getAlertObj(reason);
                        });
                    }
                }
                /*
                * Условия отображения типа обработки
                * */
                function checkShowTypeFn() {
                    if($scope.paymentOrder.paymentOrder.processResult === '03' || $scope.paymentOrder.paymentOrder.processResult === '12'){
                        $scope.modelView.isThridPayment = $scope.paymentOrder.paymentOrder.invoices && $scope.paymentOrder.paymentOrder.invoices.length>0;
                    }else{
                        $scope.modelView.isThridPayment = true;
                    }
                }

                function goBackFn() {
                    history.back();
                }

                function refreshDictFn(object, value, refreshScrolling,needToAdd) {
                    return dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling,null,needToAdd);
                }
            }]
    });