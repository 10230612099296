/**
 * @author: sergeyu
 * Ускоренная аккредитация
 */
angular.module('uetp')
    .component('accelerationAccreditation', {
        bindings: {
            tariffsData: '<'
        },
        templateUrl: 'app/components/profile/accreditation/accelerationAccreditation/accelerationAccreditation.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'profileSrv', 'authSrv', 'desktopSrv', '$log',
            'dictionarySrv', 'alertSrv', 'cryptography', 'httpSrv', 'dialogSrv', 'needConfirmSrv',
            function ($scope, $rootScope, $state, $stateParams, profileSrv, authSrv, desktopSrv, $log,
                      dictionarySrv, alertSrv, cryptography, httpSrv, dialogSrv, needConfirmSrv) {

                var ctrl = this;
                $scope.ctrl = ctrl;
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType();
                    $scope.user = angular.copy(authSrv.getUser());
                    $scope.active = {};
                    $scope.form = {accredForm: {}};
                    $scope.submitted = true;
                    $scope.processing = false;
                    $scope.isAccountSuccess = !!($scope.user.account && $scope.user.account.id);
                    $scope.ndsText = '';
                    $scope.dictionary = dictionarySrv.getRemoteData();
                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                    if (ctrl.tariffsData && ctrl.tariffsData.data.success) {
                        initNdsText(ctrl.tariffsData.data.items);
                    }
                    dictionarySrv.resetPaginatedDict($scope);
                    _initTypeOrg();
                    _initDict(['okcm', 'fias_region'], false);
                };
                $scope.goBack = goBackFn;
                $scope.signSendData = signSendDataFn;

                function _initTypeOrg() {
                    var typeOrg = $scope.user.typeOrg;
                    $scope.active.type = typeOrg === 'ul' ? 1 : typeOrg === 'nr' ? 4 : typeOrg === 'fl' ? 3 : 2;
                }

                function _initDict(dictList, reload) {
                    angular.forEach(dictList, function (name) {
                        dictionarySrv.getDictionaryRemote(name, reload);
                    })

                }

                function signSendDataFn(user) {
                    $scope.processing = true;
                    var reqData = {
                        email: user.email,
                        phone: user.phone,
                        lastName: user.lastName,
                        firstName: user.firstName,
                        middleName: user.middleName,
                        address: user.regAddress
                    };
                    httpSrv.http({
                        method: 'POST',
                        url: '/auth/tariff/storeAccreditationPayment',
                        data:reqData
                    }).then(function (response) {
                        if (response.data.success) {
                            $scope.processing = false;
                            var userData = authSrv.getUser();
                            userData.fastAccreditation = false;
                            authSrv.setUser(userData);
                            $scope.form.accredForm.$dirty = false;
                            dialogSrv.openModal('_dialogMessage.html',
                                {message: 'На электронную почту отправлен счет на оплату услуги "Ускоренное рассмотрение документов".'});
                            setTimeout(function () {
                                httpSrv.downloadFile("/auth/tariff/downloadInvoice/"+response.data.result);
                                $scope.reset();
                            },200)
                        } else {
                            $scope.ctrl.alertObj =  alertSrv.getErrorMsgWithBtn(response.data.errorMessage);
                        }
                    }, function (reason) {
                        $scope.processing = false;
                        console.log(reason)
                    })
                }
                $scope.reset = function () {
                    $state.go('profile', {activeProfileTab: 'accreditation'});
                };
                function goBackFn() {
                    if ($scope.form.accredForm.$dirty) {
                        needConfirmSrv.call($scope.reset, [], 'Внесенные изменения сохранены не будут');
                    } else {
                        $scope.reset();
                    }
                }

                function initNdsText(tariffs){
                    if(tariffs.length == 1){
                        $scope.ndsText = tariffs[0].code == 'QS' ? 'НДС не облагается в связи с применением упрощенной системы налогообложения п.2 ст.346.11 НК РФ'
                            : 'кроме того НДС по ставке, установленной п. 3 ст. 164 НК РФ';
                    }
                }

            }]
    });

