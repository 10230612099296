/*global checkForPlugIn getCertInfo generateSignature signHash getAlgoInfo decryptData findCertificate validateCertificate GetErrorMessage*/

import crypt_async from './crypt_async.raw.js';
import crypt_npapi from './crypt_npapi.raw.js';

(function () {
    if (window.crypt) return;

    var crypt = {};

    crypt.errors = {
        //CryptoPro Errors
        '0x20000064': 'Мало памяти',
        '0x20000065': 'Не удалось открыть файл',
        '0x20000066': 'Операция отменена пользователем',
        '0x20000067': 'Некорректное преобразование BASE64',
        '0x20000068': 'Если указан параметр \'-help\', то других быть не должно',
        '0x200000C8': 'Указан лишний файл',
        '0x200000C9': 'Указан неизвестный ключ',
        '0x200000CA': 'Указана лишняя команда',
        '0x200000CB': 'Для ключа не указан параметр',
        '0x200000CC': 'Не указана команда',
        '0x200000CD': 'Не указан необходимый ключ',
        '0x200000CE': 'Указан неверный ключ',
        '0x200000CF': 'Параметром ключа \'-q\' должно быть натуральное число',
        '0x200000D0': 'Не указан входной файл',
        '0x200000D1': 'Не указан выходной файл',
        '0x200000D2': 'Команда не использует параметр с именем файла',
        '0x200000D3': 'Не указан файл сообщения',
        '0x2000012C': 'Не удалось открыть хранилище сертификатов',
        '0x2000012D': 'Сертификаты не найдены',
        '0x2000012E': 'Найдено более одного сертификата (ключ \'-1\')',
        '0x2000012F': 'Команда подразумевает использование только одного сертификата',
        '0x20000130': 'Неверно указан номер',
        '0x20000131': 'Нет используемых сертификатов',
        '0x20000132': 'Данный сертификат не может применяться для этой операции',
        '0x20000133': 'Цепочка сертификатов не проверена',
        '0x20000134': 'Криптопровайдер, поддерживающий необходимый алгоритм не найден',
        '0x20000135': 'Неудачный ввод пароля ключевого контейнера',
        '0x20000190': 'Не указана маска файлов',
        '0x20000191': 'Указаны несколько масок файлов',
        '0x20000192': 'Файлы не найдены',
        '0x20000193': 'Задана неверная маска',
        '0x20000194': 'Неверный хеш',
        '0x200001F4': 'Ключ \'-start\' указан, а выходной файл нет',
        '0x200001F5': 'Содержимое файла - не подписанное сообщение',
        '0x200001F6': 'Неизвестный алгоритм подписи',
        '0x200001F7': 'Сертификат автора подписи не найден',
        '0x200001F8': 'Подпись не найдена',
        '0x200001F9': 'Подпись не верна',
        '0x20000200': 'Штамп времени не верен',
        '0x20000258': 'Содержимое файла - не зашифрованное сообщение',
        '0x20000259': 'Неизвестный алгоритм шифрования',
        '0x2000025A': 'Не найден сертификат с соответствующим секретным ключом',
        '0x200002BC': 'Не удалось инициализировать COM',
        '0x200002BD': 'Контейнеры не найдены',
        '0x200002BE': 'Не удалось получить ответ от сервера',
        '0x200002BF': 'Сертификат не найден в ответе сервера',
        '0x200002C0': 'Файл не содержит идентификатор запроса:',
        '0x200002C1': 'Некорректный адрес ЦС',
        '0x200002C2': 'Получен неверный Cookie',
        '0x20000320': 'Серийный номер содержит недопустимое количество символов',
        '0x20000321': 'Неверный код продукта',
        '0x20000322': 'Не удалось проверить серийный номер',
        '0x20000323': 'Не удалось сохранить серийный номер',
        '0x20000324': 'Не удалось загрузить серийный номер',
        '0x20000325': 'Лицензия просрочена',

        //OS errors
        '0x8009200C': 'Невозможно найти сертификат и закрытый ключ, используемый для расшифровки'
    };
    /**
     * Ссообщения об ошибках
     */
    crypt.msgPluginNotDeclared = 'Плагин не подключен.';
    crypt.msgPluginNotWorked = 'Плагин не загружен. <br><a href="/downloads/cadesplugin.exe">скачайте</a> и установите плагин';
    crypt.msgErrorOpenStore = 'Ошибка при открытии хранилища. Пожалуйста, проверьте наличие сертификатов и выберите действующий в настройках';
    crypt.msgErrorListStore = 'Ошибка при перечислении сертификатов';
    crypt.msgErrorGetName = 'Ошибка при получении свойства SubjectName';
    crypt.msgErrorGetThumb = 'Ошибка при получении свойства Thumbprint';
    crypt.msgNotCreateObjectCPSigner = 'Не удается создать объект CPSigner';
    crypt.msgNotCreateSign = 'Не удалось создать подпись из-за ошибки';
    crypt.msgNotFound = 'Сертификат не найден';
    crypt.msgNotEncrypt = 'Не удалось расшифровать контент';

    crypt.cadesplugin = null;
    crypt.async = false;

    crypt.pluginVersion = null;
    crypt.getPluginVersion = function () {
        return this.pluginVersion;
    }

    crypt.latestPluginVersion = {
        MajorVersion: 2,
        MinorVersion: 0,
        BuildVersion: 12711
    };
    crypt.getLatestPluginVersion = function () {
        return this.latestPluginVersion;
    };

    crypt.basePluginVersion = {
        MajorVersion: 1,
        MinorVersion: 5,
        BuildVersion: 1633
    };
    crypt.pluginWorking = false;
    crypt.pluginLatest = false;
    crypt.initImplementation = function () {
        var that = this;
        return new Promise(function(resolve, reject) {
            window.cryptoLoadApi = new CustomEvent("cryptoLoaded", {
                bubbles: true,
                cancelable: false
            });
            cadesplugin.set_log_level(cadesplugin.LOG_LEVEL_ERROR);
            var canAsync = !!cadesplugin.CreateObjectAsync;
            if (canAsync) {
                that._include_async_code().then(function () {
                    resolve(true)
                },reject)
            } else {
                that._include_npapi_code().then(function () {
                    resolve(true)
                },reject);
            }
        })
    };

    crypt.initPluginWorking = function (callback) {
        if (typeof checkForPlugIn == "function")
            return checkForPlugIn(crypt, callback);
        else
            return Promise.reject("<p><span class='signInTextWait'>На рабочем месте не установлен компонент КриптоПро ЭЦП <br>Browser plug-in версии 2.0. <br>Скачайте и установите его по <a href='/downloads/install_plug_in.pdf' target='_blank' class='alert-link'>инструкции</a>.</span></p>");
    };

    crypt.isPluginLatest = function () {
        return this.pluginLatest;
    };
    crypt.isPluginWorking = function () {
        return this.pluginWorking;
    };

    crypt.getCertInfo = function (hash) {
        var self = this;
        return self.findCertificate(hash).then(function (cert) {
            if (!cert)
                return Promise.reject("Сертификат не найден.");
            return getCertInfo(cert);
        }, function (reason) {
            return Promise.reject(reason);
        });
    };
    crypt.getCertInfoByCert = function (cert) {
        return getCertInfo(cert);
    };

    crypt.generateSignature = function (data, certHash, attached) {
        var self = this;
        return self.findCertificate(certHash).then(function (cert) {
            if (!cert)
                return Promise.reject("Сертификат не найден.");
            return generateSignature(data, cert, attached, self);
        }, function (reason) {
            return Promise.reject(reason);
        });
    };

    crypt.signHash = function (data, certHash, vHash) {
        var self = this;
        return self.findCertificate(certHash).then(function (cert) {
            if (!cert)
                return Promise.reject("Сертификат не найден.");
            return signHash(data, cert, vHash, self);
        }, function (reason) {
            return Promise.reject(reason);
        });
    };
    crypt.getAlgoInfo = function (certHash) {
        var self = this;
        return self.findCertificate(certHash).then(function (cert) {
            if (!cert)
                return Promise.reject("Сертификат не найден.");
            return getAlgoInfo(cert);
        }, function (reason) {
            return Promise.reject(reason);
        });
    };

    crypt.decrypt = function (data, certHash) {
        return decryptData(data, certHash, self);
    };

    crypt.findCertificate = function (certHash, crypt) {
        return findCertificate(certHash, crypt);
    };

    crypt.validateCertificate = function (certHash) {
        return validateCertificate(certHash, crypt);
    };

    crypt._compareVersions = function (v1, v2) {
        if (v1.MajorVersion < v2.MajorVersion)
            return -1;

        if (v1.MajorVersion > v2.MajorVersion)
            return 1;

        if (v1.MinorVersion < v2.MinorVersion)
            return -1;

        if (v1.MinorVersion > v2.MinorVersion)
            return 1;

        if (v1.BuildVersion < v2.BuildVersion)
            return -1;

        if (v1.BuildVersion > v2.BuildVersion)
            return 1;

        return 0;
    };

    crypt._translateError = function (msg) {
        if (msg.indexOf('Error calling method on NPObject!') > -1) {
            return 'Ошибка вызова метода NPObject!';
        } else if (msg.indexOf('The action was cancelled by the user') > -1) {
            return 'Действие отменено пользователем';
        } else if (msg.indexOf('Cannot find the certificate and private key to use for decryption') > -1) {
            return 'Невозможно найти сертификат и закрытый ключ, используемый для расшифровки';
        } else {
            return 'Непредвиденная ошибка: <br>' + msg;
        }
    };

    crypt._async_code_promise = null;
    crypt._include_async_code = function () {
        var self = this;

        if (self._async_code_promise)
            return self._async_code_promise;

        var fileref = document.createElement('script');
        fileref.setAttribute("type", "text/javascript");
        fileref.text = crypt_async;
        document.getElementsByTagName("head")[0].appendChild(fileref);

        self._async_code_promise = new Promise(function (resolve) {
            resolve();
        });

        return self._async_code_promise;
    };

    crypt._npapi_code_promise = null;
    crypt._include_npapi_code = function () {
        var self = this;

        if (self._async_code_promise)
            return self._async_code_promise;

        var fileref = document.createElement('script');
        fileref.setAttribute("type", "text/javascript");
        fileref.text = crypt_npapi;
        document.getElementsByTagName("head")[0].appendChild(fileref);
        self._npapi_code_promise = new Promise(function (resolve) {
            resolve();
        });

        return self._npapi_code_promise;
    };

    crypt._getCertInfoString = function (certSubjectName, certFromDate) {
        return crypt._extractCertInfo(certSubjectName, 'CN=') + "; Выдан: " + crypt._getCertDate(certFromDate);
    };

    crypt._extractCertInfo = function (from, what) {
        var certName = "";

        var begin = from.indexOf(what);

        if (begin >= 0) {
            var end = from.indexOf(', ', begin);
            certName = (end < 0) ? from.substr(begin) : from.substr(begin, end - begin);
        }

        return certName;
    };

    crypt._getCertDate = function (paramDate) {
        var certDate = new Date(paramDate);
        return crypt._print2Digit(certDate.getUTCDate()) + "." + crypt._print2Digit(certDate.getMonth() + 1) + "." + certDate.getFullYear() + " " +
            crypt._print2Digit(certDate.getUTCHours()) + ":" + crypt._print2Digit(certDate.getUTCMinutes()) + ":" + crypt._print2Digit(certDate.getUTCSeconds());
    };

    crypt._print2Digit = function (digit) {
        return (digit < 10) ? "0" + digit : digit;
    };
    crypt.initHtmlChecker = function () {
        var that = this;
        var pluginStatus = document.getElementById('pluginStatus');
        return new Promise(function (resolve) {
            var text = '';
            if (that.pluginWorking) { // плагин работает, объекты создаются
                if (!that.pluginLatest) {
                    text = "<p><span class='glyphicon glyphicon-alert'></span> " +
                        "На рабочем месте не установлен компонент КриптоПро ЭЦП Browser plug-in версии 2.0. Скачайте и установите его по" +
                        " <a href='/downloads/install_plug_in.pdf' target='_blank' class='alert-link'>инструкции</a>.</p>";
                    if (pluginStatus) {
                        pluginStatus.classList.add("alert-warning");
                        pluginStatus.innerHTML = text
                    }

                    resolve({status:false, text : text})
                } else {
                    text = "<span class='fa fa-check-circle'></span> Плагин загружен.";
                    if (pluginStatus) {
                        pluginStatus.classList.remove("alert-danger");
                        pluginStatus.classList.add("alert-success");
                        pluginStatus.innerHTML = text;
                        setTimeout(function () {
                            pluginStatus.classList.remove("alert-success");
                            pluginStatus.innerHTML = "";
                        }, 3000);
                    }
                    resolve({status:true, text : text})
                }
            }
            else {
                text = "<span class='fa fa-exclamation-triangle'></span> Не удалось запустить плагин для работы с сертификатами. Попробуйте <a href='cripto/cadesplugin.exe' target='_blank' class='alert-link'>скачать</a> и установить плагин самостоятельно.";
                if (pluginStatus) {
                    pluginStatus.classList.add("alert-danger");
                    pluginStatus.innerHTML = text;
                }
                resolve({status:false, text : text})
            }
        })
    };
    crypt.createCryptObject = function (id, certList) {
        var lst = document.getElementById(id);
        if (!lst)
            return;
        while (lst.options.length > 0) {
            lst.remove(0);
        }
        var oOpt = document.createElement("OPTION");
        oOpt.text = "Выберите сертификат";
        oOpt.value = "";
        lst.options.add(oOpt);
        certList.forEach(function (cert) {
            addToCertList(cert);
        });

        function addToCertList(cert) {
            var oOpt = document.createElement("OPTION");
            try {
                var subjectName = cert.subjectName,
                    innOun = getInn(cert, subjectName);
                oOpt.text = cert.getInfo + '; ' + innOun + " (c " + getDateStr(cert.ValidFromDate) + " по " + getDateStr(cert.ValidToDate) + ")";

            }
            catch (e) {
                console.log("Ошибка при получении свойства SubjectName: " + GetErrorMessage(e));
            }
            try {
                oOpt.value = cert.thumbPrint;
            }
            catch (e) {
                console.log("Ошибка при получении свойства Thumbprint: " + GetErrorMessage(e));
            }

            lst.options.add(oOpt);
            lst[cert.thumbPrint] = cert;

            function getInn(cert, subjectName) {
                let innVal = subjectName.contains('1.2.643.100.4') ? subjectName.match(/1.2.643.100.4=[0-9\s]{1,}/g).toString().replace('1.2.643.100.4', 'ИНН') :
                    subjectName.match(/ИНН=[0-9\s]{1,}/g) || subjectName.match(/1.2.643.3.131.1.1=[0-9\s]{1,}/g);
                if (!innVal) {
                    if(!cert.inn) return "";
                    innVal = cert.inn;
                }
                if (innVal.length > 0 && innVal[0].indexOf("00") == 4) {
                    innVal = innVal[0].substr(0, 4) + innVal[0].substr(6);
                }
                return innVal;
            }
        }

        function getDateStr(date) {
            var d = new Date(date);
            return ("0" + d.getDate()).slice(-2) + "." + ("0" + (d.getMonth() + 1)).slice(-2) + "." +
                d.getFullYear();
        }
    };
    crypt.compareCerts = function (a, b) {
        var strA = a.getInfo,
            strB = b.getInfo;
        if (strA < strB)
            return -1;
        if (strA > strB)
            return 1;
        return 0;
    };
    crypt.isOldCert = function (code) {
        if (code === '1.2.643.2.2.19') {
            var year = null;
            try {
                var serverTime = getServerTimeMsk();
                year = serverTime.getFullYear();
            } catch {
                year = null;
            }
            if (year === null) {
                year = new Date().getFullYear();
            }
            return year >= 2020;
        } else {
            return false;
        }
    };

    window.crypt = new Promise(function (resolve, reject) {
        if (!window.cadesplugin)
            reject(self.msgPluginNotDeclared);
        else {
            var result = crypt;
            result.cadesplugin = window.cadesplugin;
            resolve(result);

            // result.cadesplugin.then(function(){
            //   result.cadesplugin.async = !!result.cadesplugin.CreateObjectAsync;
            //   if (result.cadesplugin.async) {
            //     return result._include_async_code().then(function(){
            //       return PluginInitialize_Async(result.cadesplugin);
            //     });
            //   } else {
            //       var oAbout = result.cadesplugin.CreateObject("CAdESCOM.About");
            //       if (!oAbout)
            //         throw new Error(result.msgPluginNotWorked);
            //
            //       var pluginVersion = oAbout.PluginVersion;
            //       if(typeof(pluginVersion) !== "undefined") {
            //         result.pluginVersion = {
            //           MajorVersion: pluginVersion.MajorVersion,
            //           MinorVersion: pluginVersion.MinorVersion,
            //           BuildVersion: pluginVersion.BuildVersion
            //         };
            //       } else {
            //         var oldVersion = oAbout.Version.split(".");
            //         result.pluginVersion = {
            //           MajorVersion: parseInt(oldVersion[0]),
            //           MinorVersion: parseInt(oldVersion[1]),
            //           BuildVersion: parseInt(oldVersion[2])
            //         };
            //       }
            //
            //       result.pluginWorking = result._compareVersions(result.pluginVersion, result.basePluginVersion) >= 0;
            //       result.pluginLatest = result._compareVersions(result.pluginVersion, result.latestPluginVersion) >= 0;
            //       resolve(result);
            //   }
            // });
        }
    });
}());
