/*Добавляет в список элемент "Выбрать всё"*/

angular.module('uetp').service('listService', function() {
	this.addAllSelector = function (scope, opts) {
		if (!opts.sourceItems || !Array.isArray(opts.sourceItems)) return false;
		var out = opts.sourceItems.slice();
		var item = angular.copy(opts.sourceItems[0]);
		var keys = Object.keys(opts.item);
		for (var i = 0; i < keys.length; i++) {
			var prop = keys[i];
			item[prop] = opts.item[keys[i]];
		}
		if (opts.toBegin) out.unshift(item);
		else out.push(item);
		scope[opts.resultItems] = out;

		return true;
	};

	//Pointer selector
	function _(pointer, value) {
		if (value !== undefined) pointer[0][pointer[1]] = value;
		else return pointer[0][pointer[1]];
	}

	this._ = _;
});
