/* Блок одной новости */

angular.module('uetp').component('newsBlock', {
    templateUrl: 'app/components/news/newsBlock/newsBlock.html',
    bindings: {
        modify      : '<',
        model       : '<',
        isCollapsed : '<',
    },
    controller: [
        '$scope', 'httpSrv', '$sce',
        function ($scope, httpSrv, $sce) {
            var KILO = 1024;
            var ctrl = this;
            this.$onChanges = function () {
                $scope.modify = ctrl.modify;
                if (!ctrl.model) return;
                $scope.modifys = [];
                $scope.trustAsHtml = trustAsHtmlFn;
                if (ctrl.modify) ctrl.modify.forEach(function (mod) {
                   if (mod) $scope.modifys.push('news-block--'+mod);
                });
                $scope.modifys      = $scope.modifys.join(' ');
                $scope.isCollapsed  = ctrl.isCollapsed;
                $scope.model        = ctrl.model;
                $scope.downloadFile = httpSrv.downloadFile;
                if ($scope.model.attachment) $scope.model.attachment.forEach(function (file) {
                    if (file.contentLenght < KILO) {
                        file.size = file.contentLenght + ' б';
                    } else if (file.contentLenght < KILO * KILO) {
                        file.size = Math.round(file.contentLenght / KILO) + ' Кб';
                    } else {
                        file.size = Math.round(file.contentLenght / KILO / KILO) + ' Мб';
                    }
                });

            };
            function trustAsHtmlFn(html) {
                return $sce.trustAsHtml(html);
            }
        }
    ]
});