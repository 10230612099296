angular.module('uetp').service('paymentOrderSrv', ['$state', 'httpSrv',
    function ($stateParams, httpSrv) {
        var _infoStatus = {
                '00': 'платежное поручение загружено в реестр, но не отправлено',
                '01': 'платежное поручение обработано',
                '02': 'платежное поручение обработано, оплата от третьего лица',
                '03': 'платежное поручение обработано, не удалось найти лицевой счет или номер выставленного счета',
                '04': 'платежное поручение отправлено в банк',
                '05': 'платежное поручение проведено банком',
                '06': 'платежное поручение не отправлялось в банк из текущей системы',
                '07': 'платежное поручение исполнено',
                '08': 'превышено время обработки платежного поручения',
                '09': 'платежное поручение отклонено',
                '11': 'платежное поручение отправлено из другой системы',
                '12': 'обработка платежного поручения отложена',
                '13': 'платежное поручение отклонено пользователем',
                '99': 'неизвестная ошибка'
            };

        this.sentPaymentOrders = sentPaymentOrdersFn;
        this.sentAllPaymentOrders = sentAllPaymentOrdersFn;
        this.getParticipantByInn = getParticipantByInnFn;
        this.getInvoicesParticipantByOrder = getInvoicesParticipantByOrderFn;
        this.getOrganizationsInfoByList = getOrganizationsInfoByListFn;

        function sentPaymentOrdersFn(paymentOrders) {
            return httpSrv.http({
                method: 'POST',
                url: '/auth/paymentOrder/processSent',
                data: paymentOrders
            })
        }

        function getParticipantByInnFn(search) {
            var pagination = {
                itemsPerPage: 20,
                page: 0,
                search:search
            };
            return httpSrv.http({
                url: 'admin/obtainOrgList/participant',
                data: pagination
            })
        }

        function getOrganizationsInfoByListFn(orgIds) {
            return httpSrv.http({
                method: 'POST',
                url: '/admin/getOrganizationsInfoByList',
                data: orgIds
            })
        }
        
        function getInvoicesParticipantByOrderFn(order, participant, type) {
            if (type === 'linkedInvoice') {
                return httpSrv.http({
                    method: 'GET',
                    url: 'auth/tariff/' + order.id + '/invoices/' + participant.id
                })
            } else if(type === 'linkedInvoiceByEmail'){
                return httpSrv.http({
                    method: 'GET',
                    url: 'auth/tariff/' + order.id + '/invoiceHandWorks/' + participant.id
                })
            }
        }
        function sentAllPaymentOrdersFn() {
                return httpSrv.http({
                    method: 'POST',
                    url: '/auth/paymentOrder/processAllSent'
                })
        }

        this.setData = function (data) {
            this.data = data;
        };
        this.getData = function () {
            return this.data;
        };
        this.getPersonName = function (data) {
            var result = '';
            if (data) {
                if (data.inn) {
                    result = data.inn
                }
                if (data.name) {
                    result = result ? result + ', ' + data.name : data.name;
                }
            }
            return result;
        };
        this.getPaymentOrder = function (id) {
            var url = '/accounts/coveringLetters/getPaymentOrder/';

            return httpSrv.http({
                method: 'GET',
                url: url + id,
                headers: {"Auth-Token": localStorage.getItem('key')}
            });
        };
        this.getProcessColor = function (typeProcess, typeColor,cabType) {
            var result = '';
            if (['99', '09', '02', '03', '13'].indexOf(typeProcess) > -1
                || (cabType === 'participant' && ['12'].indexOf(typeProcess) > -1)
                || (cabType === 'accountant' && ['08'].indexOf(typeProcess) > -1)) {
                result = typeColor === 'table' ? 'text-danger' : 'bs-callout-danger';
            } else if (['00', '04', '08'].indexOf(typeProcess) > -1) {
                result = typeColor === 'table' ? 'text-warning' : 'bs-callout-warning';
            }else if (['12'].indexOf(typeProcess) > -1) {
                result = typeColor === 'table' ? 'text-info' : 'bs-callout-info';
            } else if (['05', '07', '01','11'].indexOf(typeProcess) > -1) {
                result = typeColor === 'table' ? 'text-success' : 'bs-callout-success';
            } else if (['06'].indexOf(typeProcess) > -1) {
                result = typeColor === 'table' ? 'text-muted' : 'bs-callout-muted';
            }
            return result;
        };

        this.getProcessStatus = function (data, inTable,cabType) {
            var textParam = {
                color: '',
                text: ''
            };
            if (data) {
                if (data.processResult === '99') {
                    textParam.text = 'Неизвестная ошибка';
                } else if (data.type === 'outgoing') {
                    if (data.processResult === '00') {
                        textParam.text = 'Подготовлено для отправки';
                    } else if (data.processResult === '04' || (cabType === 'participant' && data.processResult === '08')) {
                        textParam.text = 'Отправлено';
                    } else if (data.processResult === '05') {
                        textParam.text = 'Проведено';
                    } else if (data.processResult === '06') {
                        textParam.text = 'Не отправлялось из КОМИТА ЭТП';
                    } else if (data.processResult === '07') {
                        textParam.text = 'Исполнено';
                    } else if (data.processResult === '08') {
                        textParam.text = 'Превышено время обработки';
                    } else if (data.processResult === '09') {
                        textParam.text = 'Отклонено';
                    } else if (data.processResult === '11') {
                        textParam.text = 'Отправлено из другой системы';
                    } else if (data.processResult === '13') {
                        textParam.text = 'Отклонено вручную';
                    }
                } else if (data.type === 'incoming') {
                    if (data.processResult === '00') {
                        textParam.text = 'Подготовлено для отправки';
                    } else if (data.processResult === '01') {
                        textParam.text = 'Обработано';
                    } else if ( data.processResult === '03' || (cabType === 'participant' && data.processResult === '12')) {
                        textParam.text = 'Обработано с ошибкой';
                    }else if (data.processResult === '12') {
                        textParam.text = 'Отложено';
                    } else if (data.processResult === '02') {
                        textParam.text = 'Оплата от третьего лица';
                    }
                }
                textParam.color = this.getProcessColor(data.processResult, 'table',cabType);

                if (inTable) {
                    const processTitle = this.getProcessStatusDesc(data.processResult, cabType);
                    return '<span class="' + textParam.color + '"><span title="' + processTitle + '" class="glyphicon defaultCursor">&#xe086; </span> ' + textParam.text + '</span>';
                }
                return '<span class="' + textParam.color + '">' + textParam.text + '</span>';
            }
            return '';
        };

        this.getProcessStatusDesc = function (code, cabType) {
            if(cabType === 'participant' && code === '12') {
               return 'платежное поручение обработано, ожидается уточнение платежа';
            } else if(cabType === 'participant' && code === '08') {
                return  'платежное поручение отправлено в банк';
            } else return _infoStatus[code];
        };

        this.getCoveringStatus = function (row) {
            var textParam = {
                color: '',
                text: '',
                title:''
            };
            if(row.state === 'sent') {
                textParam.color = 'text-muted';
                textParam.title = 'Подтверждение платежа отправлено';
                textParam.text = 'Дан ответ';
            }else if(row.state === 'claimed'){
                textParam.color = 'text-danger';
                textParam.title = 'Необходимо отправить подтверждение платежа';
                textParam.text = 'Требуется ответ';
            }
            //
            return '<span class="' + textParam.color + '"><span title="' + textParam.title + '" class="glyphicon defaultCursor">&#xe086; </span> ' + textParam.text + '</span>';

        };
        /*
        * иконка "Запрос на уточнение платежа" в заголовок раздела
        * Анализируем сопроводительные письма
        *
        * */
        this.getRequestStatus = function (data,cabType) {

            if(!data) return '';
            if(data && data.length === 0) return '';

            var param = {
                color:'',
                title:''
            };

            //все запросы отправлены(уч) /  ответ на запрос поступил(бух)
            if (cabType() === 'participant') {
                param.color = 'text-muted';
                param.title = 'Подтверждение платежа отправлено';
            } else {
                param.color = 'text-primary';
                param.title = 'Подтверждение платежа получено';
            }

            //  есть запрос на подтвреждение(уч) / ответов на запросы не поступило(бух)
            angular.forEach(data, function (item) {
               if(item.state === 'claimed') {
                   if (cabType() === 'participant') {
                       param.color = 'text-danger';
                       param.title = 'Необходимо отправить подтверждение платежа';
                   } else {
                       param.color = 'text-danger';
                       param.title = 'Ожидает подтверждение платежа';
                   }
               }
            });


            return '<i class="fas fa-file-invoice ' + param.color + '" title="' + param.title + '"></i>'
        };
    }]);