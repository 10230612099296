/**
 * Директива для поля дата.
 */
angular.module('uetp').directive('normalDate', function () {
    return {
        require: 'ngModel',
        priority: 1,
        link: function (scope, element, attrs, ctrl) {
            ctrl.$formatters.push(function (value) {
                if(value) {
                    return new Date(value).setUTCHours(new Date(value).getUTCHours()-(new Date().getTimezoneOffset()/(-60)), 0)
                }
                return null;
            });
        }
    };
});