/**
 * @author: alexandrbu
 * Сервис райффайзен
 */
angular.module('uetp').service('rfGuaranteeAppSrv', ['$state', 'httpSrv', 'desktopSrv', '$httpParamSerializerJQLike', 'authSrv',
    function ($state, httpSrv, desktopSrv, $httpParamSerializerJQLike, authSrv) {
        this.getDocTypeList = getDocTypeListFn;
        this.getGuaranteeTypeList = getGuaranteeTypeListFn;
        
        this.send = sendFn;
        this.getRfGuarantee = getRfGuaranteeFn;
        this.userAccess = userAccessFn;
        
        function sendFn(orderInfo) {
            return httpSrv.http({
                method: 'POST',
                url: '/bankguarantee/rf/orders/send',
                data: orderInfo
            })

        }

        function getDocTypeListFn() {
            return [
                {
                    type: "yearFinReport",
                    label: "Годовая финансовая отчетность за последний завершенный финансовый год, с отметкой о принятии ИФНС либо с приложением документов, подтверждающих сдачу отчетности в налоговые органы",
                    isRequired: true,
                    isMultiple: false,
                    canEdit: false,
                    files:[]
                },
                {
                    type: "quarterFinReport",
                    label: "Промежуточная бухгалтерская (финансовая) отчетность Принципала за квартал, предшествующий обращению Принципала ",
                    isRequired: true,
                    isMultiple: false,
                    canEdit: false,
                    files:[]
                },
                {
                    type: "creditInfo",
                    label: "Информация по всем действующим кредитным договорам",
                    isRequired: false,
                    isMultiple: false,
                    canEdit: false,
                    files:[]
                }
           ]
        }
        
        function getGuaranteeTypeListFn() {
            return [
                {
                    code: "gTender",
                    name: "Тендерная гарантия",
                },
                {
                	code: "gContractExecution",
                	name: "Гарантия исполнения контракта",
                },
                {
                	code: "gRefundAdvance",
                	name: "Гарантия возврата аванса",
                },
                {
                    code: "gWarrantyPeriod",
                    name: "Гарантия исполнения обязательств в гарантийный период",
                },
                {
                	code: "gFns",
                	name: "Гарантии в пользу ФНС, ФТС, РАР",
                }
           ]
        }
        
        function getRfGuaranteeFn(id, isCopy) {
            return httpSrv.http({
                method: 'GET',
                url: 'bankguarantee/rf/orders/'+id + (isCopy ? '/copy' : '')
            });
        }
        
        function userAccessFn(cabType, user){
        	return cabType === 'participant' && (user.typeOrg === 'ul' || user.typeOrg === 'ip') || authSrv.isUserAgent();
        }
    }]);