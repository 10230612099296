/**
 * @author: sertakovs
 *
 * Реестр выписок
 */
angular.module('uetp')
    .component('statementTable', {
        templateUrl: 'app/components/statement/table/statementTable.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'authSrv', 'httpSrv', 'desktopSrv', 'statementSrv','paginationSrv','filterService', 'dialogSrv', 'dictionarySrv', 'tablesSrv',
            function ($scope, $rootScope, $state, $stateParams, authSrv, httpSrv, desktopSrv, statementSrv, paginationSrv, filterService, dialogSrv, dictionarySrv, tablesSrv) {
                var ctrl = this;
                /**
                 * В ЛК Бухгалтера выводим как таблицу (с фильтрами и поиском)
                 */
                $scope.isTable = $stateParams.isTable;
                $scope.statementSrv = statementSrv;
                $scope.ctrl = ctrl;

                $scope.downloadFile = httpSrv.downloadFile;

                $scope.paginator = {
                    itemsPerPage: 20,
                    page: 0,
                    search: "",
                    orderAsc: false,
                    orderBy: "processDate"
                };

                $scope.statementLoad = statementLoadFn;
                $scope.clickRow = clickRowFn;
                $scope.changeSort = changeSortFn;


                this.$onInit = function () {
                    $scope.auth = authSrv.getAuth();
                    $scope.cabType = desktopSrv.getCabType();
                    $scope.statementLoad();
                    $scope.sortByObject = tablesSrv.initSort($scope.sortByObject, $scope.paginator);
                    _initDictionaryBase();
                };

                function statementLoadFn() {
                    filterService.initModelView('Поиск по наименованию файла выписки', 'Реестр выписок');
                    prepareTableParams('filterStatement');

                    statementSrv.getStatements($scope.paginator).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.statements = response.data.items;
                            $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                            if(response.data.message == 'BANK_STATEMENTS_DELAY'){
                            	$scope.alertMessageStatementsDelay = 'Задержка загрузки выписок в систему';
                            }
                        }
                    });


                }

                /**
                 * Сортировка
                 */
                function changeSortFn($event) {
                    var th = $event.currentTarget;

                    [].forEach.call(document.querySelectorAll(".sortButton"), function (button) {
                        button.classList.remove("boldSort");
                    });
                    th.classList.add("boldSort");

                    if (tablesSrv.changeSort($scope.paginator, th)) loadSortedData();
                }

                function loadSortedData() {
                    var pagination = angular.copy($scope.paginator);
                    statementSrv.getStatements(pagination).then(function (response) {
                        if (response.data.success) {
                            $scope.statements = response.data.items;
                        }
                    })
                }

                function prepareTableParams(filterType) {
                    var filterParams = angular.copy(filterService.getFilterParams(filterType)),
                        searchParam = angular.copy(filterService.getSearchParam(filterType));
                    if (filterParams && Object.keys(filterParams).length > 0) {
                        $scope.paginator.searchByField = filterParams;
                    } else {
                        delete $scope.paginator.searchByField
                    }
                    $scope.paginator.search = searchParam;
                }

                function clickRowFn() {}

                function _initDictionaryBase() {
                    $scope.dictionary = dictionarySrv.getRemoteData();
                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                    dictionarySrv.resetPaginatedDict($scope);
                    _initDict(['operatorReq'], false);

                    function _initDict(dictList, reload) {
                        angular.forEach(dictList, function (name) {
                            dictionarySrv.getDictionaryRemote(name, reload)
                        })
                    }
                }

            }
        ]
    });

