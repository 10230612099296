//поиск на главной странице
angular.module('uetp')
    .component('startpageSearch', {
        templateUrl:['constants', function (constants) {
            return 'app/components/startPageSearch/' + constants.configProject.type + '.html'
        }],
        controller: ['$scope', '$state', 'searchFilterService', 'filterService',
            function ($scope, $state, searchFilterService, filterService) {

                $scope.doSearch = doSearchFn;
                $scope.setSearchType = setSearchTypeFn;
                $scope.button = {};
                $scope.buttons = [
                    {
                        name:'Все закупки',
                        state:"allProcedures",
                        placeholder:'Поиск по заказчику, наименованию и номеру закупки',
                        typeFilter : 'filterProceduresAll'
                    },
                    {
                        name:'Закупки по 223-ФЗ',
                        state:"publicProcedures",
                        placeholder:'Поиск по заказчику, наименованию и номеру закупки',
                        typeFilter : 'filterProcedures'
                    },
                    {
                        name:'Коммерческие торги',
                        state:"commercialProcedures",
                        placeholder:'Поиск по заказчику, наименованию и номеру закупки',
                        typeFilter : 'filterProcedures'
                    },
                    {
                        name:'Закупки малого объема',
                        state:"publicProceduresSOC",
                        placeholder:'Поиск по заказчику, наименованию и номеру закупки',
                        typeFilter : 'filterProceduresSOC'
                    }
                ];
                $scope.somePlaceholder = "";

                function setSearchTypeFn(item) {
                    $scope.button = item;
                }

                function doSearchFn() {
                    filterService.setStateSearchParam($scope.search, $scope.button.typeFilter, $scope.button.state);
                    $state.go($scope.button.state,{clearConfig: true});
                    init();
                }

                init();

                function init() {
                    $scope.button = $scope.buttons[0];
                    $scope.search = '';
                }
            }]
    });

