/**
 * @author: sergeyu
 *
 * Тарифы
 */
angular.module('uetp')
    .component('tariffs', {
        bindings: {
            tariffsData: '<'
        },
        template: '<div ng-include="ctrl.templateUrl">',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'authSrv', 'dialogSrv', 'desktopSrv',
            'tariffsSrv', 'invoiceSrv', 'httpSrv', 'tablesSrv', 'paginationSrv', 'profileSrv', '$filter', 'constants',
            function ($scope, $rootScope, $state, $stateParams, authSrv, dialogSrv, desktopSrv,
                      tariffsSrv, invoiceSrv, httpSrv, tablesSrv, paginationSrv, profileSrv, $filter, constants) {
                var state = $state.current,
                    ctrl = this;
                $scope.ctrl = ctrl;
                $scope.changeSort = changeSortFn;
                $scope.invoiceSrv = invoiceSrv;
                $scope.deactivateTariff = deactivateTariffFn;
                $scope.getTariffTitle = getTariffTitleFn;
                $scope.isDemo = isDemoFn;
                $scope.isSecurityTariff = isSecurityTariffFn;
                $scope.isAnnualTariff = isAnnualTariffFn;
                $scope.downloadFile = httpSrv.downloadFile;
                $scope.detailInfoView = false;
                $scope.paginator = {
                    itemsPerPage: 1000,
                    page: 0
                };
                $scope.isAccredOrg = false;
                $scope.viewModel = {
                    showTariffMyHistory: false,
                    showTariffProfile: true,
                    hideFieldParticipant: true
                };
                $scope.model = {
                    selectedTariff: {}
                };
                $scope.stateTitle = {
                    wait: 'Ожидается подключение',
                    finished: 'Завершен',
                    active: 'Подключен',
                    waitAccred: 'Ожидает аккредитации',
                    canceled: 'Отключен'
                };
                $scope.annualText = "<ul>" +
                    "<li><p>Сервис «Проверка компании» позволяет проявить должную осмотрительность, оценить финансовое состояние и судебно-исковую нагрузку на контрагента.</p></li>" +
                    "<li><p>Аналитическая система осуществляет поиск по закупкам Единой информационной системы с удобным сервисом.</p></li>" +
                    "</ul>";

                $scope.goBack = goBackFn;
                $scope.btnDisabled = false;

                function goBackFn(){
                    history.back();
                }

                this.$onInit = function () {
                    $scope.auth = authSrv.getAuth();
                    $scope.user = authSrv.getUser();

                    $scope.cabType = desktopSrv.getCabType;
                    var successResponse = true;
                    if ($scope.cabType() === 'participant' && state.name !== 'tariffsHome') {
                        tariffsSrv.getTariffsProfile().then(function successCallback(response) {
                            ctrl.templateUrl = 'app/components/tariffsServices/tariffs/tariffsProfile_' + constants.configProject.type + '.html';
                            if (response.data.success) {
                                profileSrv.checkAccred().then(function (checkRes) {
                                    if (checkRes.data.success) {
                                        $scope.isAccredOrg = !!checkRes.data.result;
                                        response.data.items = response.data.items.filter(function (item) {
                                            return item.group === 'tariff' || item.group === 'service';
                                        });
                                        _fillLogicTariff(response.data.items);
                                        if (!$stateParams.isHiddenTariffHistory && !$stateParams.isInvoiceParticipantView) {
                                            _updateTariffHistory().then(function (res) {
                                                if (res.data.success) {
                                                    $scope.myTariffsHistory = res.data.items;
                                                }
                                            });
                                        }

                                        _initTariffMenu();
                                    } else {
                                        successResponse = false;
                                    }
                                }, function (reason) {
                                    console.log(reason);
                                    successResponse = false;
                                });


                            } else {
                                successResponse = false;
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                            successResponse = false;

                        })

                    } else if (state.name === 'tariffsHome') {
                        if (ctrl.tariffsData && ctrl.tariffsData.data.success) {
                            ctrl.templateUrl = 'app/components/tariffsServices/tariffs/tariffsHomePage_' + constants.configProject.type + '.html';
                            _fillLogicTariff(ctrl.tariffsData.data.items);
                        } else {
                            successResponse = false;
                        }
                    } else {  // лк админа, тп, бух
                        _initTariffMenu();
                        ctrl.templateUrl = 'app/components/tariffsServices/tariffs/tariffsProfile_' + constants.configProject.type + '.html';
                    }
                    if (!successResponse) {
                        dialogSrv.openModal('_dialogMessage.html',
                            {message: 'Ошибка получения информации по тарифным планам. Попробуйте позже.'});
                    }

                    if (!$stateParams.isHiddenTariffHistory && !$stateParams.isInvoiceParticipantView) {
                        _updateTariffHistory().then(function (response) {
                            if (response.data.success) {
                                $scope.myTariffsHistory = response.data.items;
                            }
                        });
                    }
                };

                function _initTariffMenu() {
                    if ($scope.cabType() === 'participant') {
                        $scope.viewModel.showTariffProfile = true;
                    } else { // лк админа, тп, бух
                        $scope.viewModel.showTariffProfile = false;
                        $scope.viewModel.hideFieldParticipant = $scope.cabType() == 'support';
                        $scope.getParticipantTariffHistory($stateParams.id)
                    }
                }

                $scope.updateTariff = function () {
                    tariffsSrv.getTariffsProfile().then(function (response) {
                        if (response.data.success) {
                            ctrl.tariffsData = response;
                            var tariffs = response.data.items.filter(function (item) {
                                return item.group === 'tariff' || item.group === 'service';
                            });
                            _fillLogicTariff(tariffs)
                        }
                    })
                };
                $scope.activateTariff = function (tariff) {
                    $scope.btnDisabled = true;
                    tariffsSrv.activateTariffWithInvoice(tariff).then(function (response) {
                        if (response.data.success) {
                            $scope.updateTariff();
                            dialogSrv.openModal('_dialogMessage.html',
                                {message: 'На электронную почту отправлены документы для оплаты подключения тарифного плана.'});
                            if(response.data.result.name)
                                setTimeout(function () {
                                    httpSrv.downloadFile("/auth/tariff/downloadInvoice/"+response.data.result.name);
                                },200)
                            _updateTariffHistory().then(function (response) {
                                if (response.data.success) {
                                    $scope.myTariffsHistory = response.data.items;

                                }
                            })
                        } else {
                            dialogSrv.openModal('_dialogMessage.html', {message: response.data.message || 'Произошла ошибка. Обратитесь в тех.поддержку.'})
                        }
                        $scope.btnDisabled = false;
                    })
                };

                $scope.selectedTariffInvoice = function (data) {
                    if ($scope.model.selectedTariff.id === data.id) {
                        $scope.model.selectedTariff = {};
                    } else {
                        $scope.model.selectedTariff = data;
                        setTimeout(function () {
                            desktopSrv.scrollIntoViewFn(document.querySelectorAll('#partViewInvoicesTariff')[0], {
                                behavior: "smooth",
                                block: "end"
                            });
                        }, 500)

                    }
                };

                function _updateTariffHistory() {
                    return tariffsSrv.getMyTariffHistory();
                }

                function _getParticipantTariffHistory(id) {
                    return tariffsSrv.getTariffHistory(id);
                }

                $scope.getMyTariffHistory = function () {
                    _updateTariffHistory().then(function (response) {
                        if (response.data.success) {
                            $scope.myTariffsHistory = response.data.items;
                            $scope.viewModel.showTariffMyHistory = !$scope.viewModel.showTariffMyHistory
                        }
                    })

                };
                $scope.getParticipantTariffHistory = function (id) {
                    _getParticipantTariffHistory(id).then(function (response) {
                        if (response.data.success) {
                            $scope.myTariffsHistory = response.data.items;
                            $scope.viewModel.showTariffMyHistory = true;
                        }
                    })

                };
                $scope.showInvoices = function () {
                    if ($scope.cabType !== 'participant') {
                        $rootScope.$emit('participantViewSetActivePanel', 'invoicesView')
                    }
                };

                function _fillLogicTariff(tariffs) {
                    /**
                     * Получение даты последнего активного тарифа
                     * @param lastParticipantTariffs - списко тарифов подключенных пользователем
                     */
                    var maxDateTariffs = function (lastParticipantTariffs) {
                        var sorted_ms = lastParticipantTariffs.map(function (item) {
                            return new Date(item.dateEnd).getTime()
                        }).sort();
                        return sorted_ms[sorted_ms.length - 1];
                    };
                    /**
                     * Заполнение данный под тарифы с разовыми участниями
                     * @param tariff - данные по тарифу
                     */
                    var singleTariff = function (tariff) {
                        var getCountsActiveLots = function (lastParticipantTariffs) {
                            var count = 0;
                            angular.forEach(lastParticipantTariffs, function (item) {
                                count += item.lotCounts;
                            });
                            return count;
                        };
                        if (tariff.lastParticipantTarif && tariff.lastParticipantTarif.length > 0) {
                            tariff.viewModel = {
                                lotsCountActive: getCountsActiveLots(tariff.lastParticipantTarif),
                                dateEnd: maxDateTariffs(tariff.lastParticipantTarif),
                                updateTariff: false
                            }

                        }
                    };
                    /**
                     * Заполнение данный под тарифы за период
                     * @param tariff - данные по тарифу
                     */
                    var periodTariff = function (tariff) {
                        if (tariff.lastParticipantTarif && tariff.lastParticipantTarif.length > 0) {
                            tariff.viewModel = {
                                dateEnd: maxDateTariffs(tariff.lastParticipantTarif),
                                updateTariff: true
                            }

                        }
                    };
                    /**
                     * Заполнение данный под тариф Безопасность закупок
                     */
                    var secureTariff = function (tariff) {
                        if (tariff.lastParticipantTarif && tariff.lastParticipantTarif.length > 0) {
                            tariff.viewModel = {
                                dateEnd: maxDateTariffs(tariff.lastParticipantTarif),
                                updateTariff: true
                            }

                        }
                    };

                    angular.forEach(tariffs, function (tariff) {
                        if (tariff.accesibleLots > 0) {
                            singleTariff(tariff)
                        } else if (tariff.accesibleLots < 0) {
                            periodTariff(tariff)
                        } else if (tariff.accesibleLots === 0) {
                            secureTariff(tariff);
                        }
                        tariff.tariffTitle = $scope.getTariffTitle(tariff);
                    });
                    $scope.tariffs = tariffs;
                }

                //Sort
                function changeSortFn($event) {
                    var th = $event.currentTarget;

                    [].forEach.call(document.querySelectorAll(".sortButton"), function (button) {
                        button.classList.remove("boldSort");
                    });
                    th.classList.add("boldSort");

                    if (tablesSrv.changeSort($scope.paginator, th)) loadSortedData();
                }

                function loadSortedData() {
                    var pagination = angular.copy($scope.paginator);
                    if ($scope.cabType() === 'participant') {
                        tariffsSrv.getMyTariffHistory(pagination).then(function (response) {
                            if (response.data.success) {
                                $scope.myTariffsHistory = response.data.items;
                            }
                        })
                    } else { // лк админа, тп, бух
                        tariffsSrv.getTariffHistory($stateParams.id,pagination).then(function (response) {
                            if (response.data.success) {
                                $scope.myTariffsHistory = response.data.items;
                            }
                        })
                    }

                }

                function deactivateTariffFn(tariff) {
                    tariffsSrv.validateDeactivateTariff(tariff).then (function (result){
                        if (result.text) {
                            if (result.type === 0) {
                                dialogSrv.openModal('_dialogMessage.html', {message: result.text})
                            } else if (result.type === 1 || result.type === 2) {
                                dialogSrv.openModal('_dialogConfMessage.html', {message: result.text}).then(function (model) {
                                    if (model && model.close) {
                                        tariffsSrv.deactivateTariff(tariff, model.textConf).then(function (response) {
                                            if (response.data.success) {
                                                $scope.getParticipantTariffHistory($stateParams.id)
                                            } else {
                                                dialogSrv.openModal('_dialogMessage.html', {message: response.data.message || response.data.errorMessage})
                                            }
                                        })
                                    }
                                })
                            }
                        }
                    })
                }

                /**
                 * Подсказки для кнопок подключения тарифа
                 * @param tariff - данные по тарифу
                 */
                function getTariffTitleFn(tariff) {
                    var value = '';
                    if (tariff.state == 'wait') {
                        if (['Z03', 'Z04', 'Z13', 'Z14'].includes(tariff.code)) {
                            value = 'Ожидает оплаты или отзыва следующий счет:';
                        } else {
                            value = 'Ожидают оплаты или отзыва следующие счета:';
                        }
                        if ($scope.myTariffsHistory) {
                            var invoices = new Array();
                            angular.forEach($scope.myTariffsHistory, function (item) {
                                if (item.tariff.id === tariff.id) {
                                    angular.forEach(item.invoice, function (inv) {
                                        if (inv.invoiceState === 'noPayment' || inv.invoiceState === 'partial') {
                                            invoices.push(inv);
                                        }
                                    });
                                }
                            });
                            var sortedInvoice = $filter('orderBy')(invoices, 'paymentOrderNumber');
                            angular.forEach(sortedInvoice, function (inv) {
                                value += '<br>' + inv.paymentOrderNumber + ' от ' + $filter('date')(inv.paymentOrderDate, 'dd.MM.yyyy');
                            });

                        }
                    }
                    return value;
                }
                function isDemoFn(){
                    return $rootScope.properties && $rootScope.properties['demo.mode']==="true";
                }

                function isSecurityTariffFn(code){
                    return code === 'D01' || code === 'D11';
                }

                function isAnnualTariffFn(code){
                    return code === 'Z04' || code === 'Z14';
                }
                $scope.isAuthorized = () => Object.keys($scope.user).length !== 0

            }
        ]
    });

