angular.module('uetp').service('ucSrv', ['httpSrv',
    function (httpSrv) {
        this.getUCById = getUcByIdFn;
        this.getLastDescription = getLastDescriptionFn;

        function getUcByIdFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/cert/rest/reestruc/uc/' + id
            })
        }

        function getLastDescriptionFn(arr) {
            var description = "";
            if (arr && arr.length > 0) { //ie
                for (var idx in arr) {
                    if (arr[idx].type == "authorization") {
                        description = arr[idx].description;
                        return description;
                    }
                }
            }
            return description;
        }
    }]);