/**
 * @author: sergeyu
 * Профиль пользователя. Список представителей организации
 */
angular.module('uetp')
    .component('agentList', {
        templateUrl: 'app/components/profile/agents/agentList.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'profileSrv', 'authSrv', 'desktopSrv', 'dialogSrv', '$filter','dictionarySrv',
            function ($scope, $rootScope, $state, $stateParams, profileSrv, authSrv, desktopSrv, dialogSrv, $filter, dictionarySrv) {
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.modelViewAgent = {};
                    $scope.authData = authSrv;
                    $scope.auth = authSrv.getAuth();
                    $scope.user = authSrv.getUser();
                    $scope.reason = profileSrv.reasonReset();
                    $scope.btnDelete = {};
                    $scope.roleList = dictionarySrv.getStaticDictByType('roles')[$scope.cabType()];
                    $scope.userRoles = [
                        {id: "admin", name: "Администратор"}
                        // ,{id: "accountant", name: "Бухгалтер"}
                    ];
                    if (authSrv.getAuth().userRole.includes('user')) {
                        $state.go('home')
                    }
                    $scope.participantAdminCount = 0;
                    _initModelView();
                    $scope.getAgentsList();
                };
                $scope.getAgentsList = getAgentsListFn;
                $scope.updateAgents = updateAgentsFn;
                $scope.openAgent = openAgentFn;
                $scope.deleteAgent = deleteAgentFn;
                $scope.checkAdmin = checkAdminFn;

                /**
                 * Инициализация модели в зависимости от ЛК
                 * @private
                 */
                function _initModelView() {
                    var cabType = $scope.cabType();
                    if (cabType === 'admincab') {

                    }

                }

                function _checkParticipantAdmin() {
                    var arrList = [];
                    angular.forEach($scope.agentsList, function (item) {
                        if(item.userRole === 'participant_admin') arrList.push(item);
                    });
                    $scope.participantAdminCount = arrList.length;
                }

                $scope.btnDeleteClick = function (id) {
                    $scope.btnDelete[id] = !$scope.btnDelete[id]
                };

                function checkAdminFn(agent){
                    if(($scope.participantAdminCount === 1 && agent.userRole === 'participant_admin') || agent.id===$scope.auth.userId){
                        return true;
                    }
                }


                function deleteAgentFn(id){
                    $scope.reason = profileSrv.reasonReset();
                    profileSrv.deleteAgent(id).then(function(response){
                        if (response.data.success){
                            getAgentsListFn();
                        } else{
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }

                    })
                }

                function openAgentFn(data) {
                    if(data.userRole==='organizer_cbz') return;
                    var arrList = [];
                    angular.forEach($scope.agentsList, function (item) {
                        if(['participant_admin','organizer_admin'].includes(item.userRole)) arrList.push(item);
                    });

                    $state.go('profile.openAgent', {id: data.id, list_count: arrList.length});
                }

                /**
                 * Обнвовление представителей организации из таблицы
                 */
                function updateAgentsFn() {
                    setTimeout(function () {
                        if (!$scope.form.agentsForm.$valid) {
                            $scope.alertId = 'agentsValidError';
                            return;
                        }
                        profileSrv.updateAgents($scope.agentsList).then(function successCallback(response) {
                            if (response.data.success) {
                                $scope.alertId = 'agentsSaved';
                                if ($scope.form.agentsForm) $scope.form.agentsForm.$setPristine();
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        });
                    }, 700)
                }

                /**
                 * Список представителей организации
                 */
                function getAgentsListFn() {
                    var id = $scope.user.id;
                    profileSrv.loadAgentsList(id).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.agentsList = response.data.result;
                            _checkParticipantAdmin();
                        } else {
                            $scope.agentsList = [];
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    })
                }

                /**
                 * Блокировка пользователя. Набор методов
                 */
                $scope.blockStatusObjects = {
                    setBlockStatus: (agent) => {
                        $scope.reason = profileSrv.setBlockStatus(agent);

                        if ($scope.form.agentsForm) {
                            $scope.form.agentsForm.$setDirty();
                        }
                    },
                    hideSetBlockStatus: (agentId) => {
                        $scope.reason = profileSrv.hideSetBlockStatus(agentId);
                    },
                    sendSetBlockStatus: async () => {
                        try {
                            const response = await profileSrv.blockUser($scope.reason);
                            if (response.data.success) {
                                $scope.blockStatusObjects.hideSetBlockStatus($scope.reason.id);
                                getAgentsListFn();
                            } else {
                                desktopSrv.showDialogMsg($scope, response.data.message);
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    }
                };

            }]
    });

