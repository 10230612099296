angular.module('uetp').constant('participantsReport', {
    title    : 'Зарегистрированные поставщики за период',
    access   : ['admincab', 'accountant'],
    url      : '/auth/tariff/downloadParticipantsXls',
    template : 'app/components/reports/types/participantsReport.html',
    data     : {
        endDate   : null,
        startDate : null
    },
    toMSK    : ['startDate', 'endDate'],
    fileName : function ($filter, data) {
        var date = "";
        if(data.startDate && data.endDate) {
            date = $filter('date')(data.startDate, 'dd.MM.yyyy_HH-mm') + '_' + $filter('date')(data.endDate, 'dd.MM.yyyy_HH-mm');
        }else{
            date = $filter('date')(getServerTimeMsk(), 'dd.MM.yyyy_HH-mm');
        }
        return "Зарегистрированные_поставщики_УЭТП_" + date+'.xlsx';
    }
});