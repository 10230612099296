//Плашка позиции
angular.module('uetp').component('positionElement', {
	templateUrl: 'app/components/positionElement/positionElement.html',
	bindings: {
		position       : '<', //Данные позиции
		highlightParam : '<', //Подсветка (при поиске)
		buttons        : '=', //Список кнопок с параметрами
		extraTemplate  : '<', //Внутренний суб-шаблон для дополнительных элементов
		extraParams    : '=', //Параметры для шаблона
		loadDetails    : '<', //Загружать детали отдельным запросом
		openByDefault  : '<', //Отображать плашку развернутой по умолчанию
		showTotalPrice : '<', //Показывать "Итого"
		countField     : '<', //Параметры поля "количество", если пусто/false - не отображается, если true - текстовая надпись, если объект - редактируемое поле
		//Свойства объекта countField для редактируемого поля: copyButton - отображать кнопку копирования, disabled - заблокировать

		onVolumeChange : '<', //Обработчик вызываемый при изменении количества
		onVolumeCopy   : '<' //Обработчик вызываемый при копировании количества


	},
	controller: [
		'$scope',  '$rootScope', '$state', 'constants', 'positionSrv', 'offerSrv', 'propertiesService', 'orderSrv',
		function (
			$scope,  $rootScope, $state,  constants, positionSrv, offerSrv, propertiesService, orderService
		) {
			var it = this;
			this.$onInit = initFn;
			$scope.extraTemplate  = it.extraTemplate;
			$scope.highlightParam = it.highlightParam;
			$scope.constants = constants;
			$scope.hasVatRate = hasVatRateFn;
			$scope.offerSiteTitle = offerSrv.siteTitle;

			function round100(v) {return Math.round(v*100)/100;}

			function hasVatRateFn(element){
				return element.vatRate > 0 || element.vatRate===0;
			}

			$scope.srefTo = function (sref) {
				// var pos = it.position;
				// orderService.checkOfferPosition(pos.id).then(function(response){
				// 	if (response.data.success)
						$state.go.apply($state, sref);
				// });
			};
			$scope.buttonClick = function(clickData) {
				if (clickData[2]) //без проверок позиции
					processButtonClick();
				else {
					// orderService.checkOfferPosition(pos.id).then(function (response) {
					// 	if (response.data.success) {
							processButtonClick();
						// }
					// });
				}
				function processButtonClick(){
					var props = null;
					if (clickData.length > 1) props = clickData.slice(1);
					if (props) clickData[0].apply(this, props);
					else clickData[0]();
				}
			};


			$scope.showDiscounts = function(position){
				orderService.showDiscounts($scope, position.discounts, position.vatRate);
			};

			var paramsOpened = {};
			$scope.isOpen = function (id) {
				return paramsOpened[id];
			};
			$scope.toggle = function (id) {
				paramsOpened[id] = !paramsOpened[id];
			};

			$scope.getFullName = function(item) {
				if (item) {
					if (angular.isUndefined(item.fullName)) {
						return item.lastName + ' ' + item.firstName + ' ' + item.middleName;
					} else {
						return item.fullName;
					}
				}
			};

			$scope.getWagTypeInfoById = function(id) {
				return $scope.wagTypeInfos[id].code + ' - ' +$scope.wagTypeInfos[id].name;
			};

			$scope.box = {pictures: []};

			$scope.wagTypeInfos = [];

			//ES6: Переписать на async/await
			$scope.toggleDetails = function () {
				if ($scope.detailsStatus === 'loading') return;

				if (!$scope.details) {
					if (!it.loadDetails) {
						$scope.details = it.position;
						$scope.detailsStatus = 'hidden';
					} else {
						$scope.detailsStatus = 'loading';
						var id = it.position.offerPositionId || it.position.id;
						if (!["ppk", "etp"].includes(it.position.site)) {
							propertiesService.getProperty("uem.url").then(function (host) {
								$scope.portalHost=host;
								getPositionDetailsHost(id);
							})
						} else
							getPositionDetailsHost(id);

						return;
					}
				} else toggleVisibility();

				function getPositionDetailsHost(id) {
					positionSrv.getPositionDetailsHost(id, $scope.portalHost).then(function successCallback(response) {
						if (response.data.success) {
							$scope.details = response.data.result;
							$scope.detailsStatus = 'hidden';
							toggleVisibility();
							$scope.$apply();
						} else {
							$scope.detailsStatus = 'failed';
							$scope.detailsErrorMessage = response.data.message;
							console.error(response.data.message);
						}
					});
				}

				function toggleVisibility(){
					if      ($scope.detailsStatus === 'visible') $scope.detailsStatus = 'hidden';
					else if ($scope.detailsStatus === 'hidden' ) $scope.detailsStatus = 'visible';
				}
			};

			$scope.getMinPrice = function (discountsArr, volume, price, vatRate, total){
				var prices = discountsArr.filter(function (item) {
					return volume >= item.volume;
				}).map(function(item){
					var discountPrice = item.price;
					if (vatRate) discountPrice = discountPrice * (100 + vatRate) / 100;
					return discountPrice;
				});
				prices.push(price);
				var minPrice = Math.min.apply(Math, prices);

				if (total) {
					return minPrice * volume;
				}

				var output = '<span class="position-element__price">'+ round100(minPrice) + ' ₽</span>';

				if (prices.length > 1) {
					output += '<span ng-if="position.discounts" class="position-element__discount-tip">(с учетом скидки)</span>';
				}

				return output;
			};

			//<--
			$scope.changeVolume = function () {
				if (it.onVolumeChange) it.onVolumeChange(it.position.id, it.position.volume);
			};
			$scope.copyVolume = function () {
				if (it.onVolumeCopy) it.onVolumeCopy(it.position.volume);
			};
			$scope.showCommonDiscounts = function(discountsArr){
				if (!Array.isArray(discountsArr) || !discountsArr.length) return false;
				return discountsArr.filter(function(d){return d.type=='common'}).length>0;
			};

            $scope.openOffer = function (position) {
				if (["etp", "ppk"].includes((position||{}).site)){
					window.open('/offers/' + position.offerId, "_blank");
                } else {
                    propertiesService.getProperty("uem.url").then(function (host) {
						window.open(host + '/offers/' + position.offerId, "_blank");
					});
				}
            };

			function initFn(){
				if ($scope.openByDefault) $scope.toggleDetails();
			}
		}
	]
});
