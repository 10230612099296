/**
 * @author: ivank
 * Профиль пользователя. Список заявлений на аккредитацию
 */
angular.module('uetp')
    .component('accreditationList', {
        templateUrl: 'app/components/profile/accreditation/accreditationList.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'profileSrv', 'authSrv', 'desktopSrv', 'dialogSrv', '$filter','dictionarySrv','alertSrv',
            function ($scope, $rootScope, $state, $stateParams, profileSrv, authSrv, desktopSrv, dialogSrv, $filter,dictionarySrv,alertSrv) {
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.modelViewAgent = {};
                    $scope.authData = authSrv;
                    $scope.user = authSrv.getUser();
                    $scope.viewModel = {
                        fastAccreditation: $scope.user.fastAccreditation,
                        isFastAccredAccess: false

                    };
                    $scope.totalPages = 0;
                    $scope.paginator = {
                        orderBy: 'created',
                        orderAsc: false,
                        itemsPerPage: 20,
                        page: 0
                    };
                    $scope.accredBtnActive = false;
                    $scope.emailProfile = authSrv.isEmailCheck;
                    _initModelView();
                    loadAccredidationListFn();
                };

                $scope.openAccred = openAccredFn;
                $scope.loadAccredidationList = loadAccredidationListFn;
                $scope.checkAccount = checkAccountFn;
                $scope.isDemo = isDemoFn;
                $scope.checkType = checkTypeFn;
                $scope.sort = sortFn;

                /**
                 * Инициализация модели в зависимости от ЛК
                 * @private
                 */
                function _initModelView() {
                    $scope.cabType();
                }
                function openAccredFn(data) {
                    $state.go('openAccreditation', {id: data.id});
                }
                function sortFn(val) {
                    if($scope.paginator.orderBy === val){
                        $scope.paginator.orderAsc = !$scope.paginator.orderAsc
                    } else {
                        $scope.paginator.orderBy = val;
                        $scope.paginator.orderAsc = false
                    }
                    loadAccredidationListFn();
                }
                function checkAccountFn() {
                    if(isDemoFn()){
                        $scope.alertNoAccount = alertSrv.getErrorMsgWithBtn('Данный функционал не доступен в демонстрационной версии.');
                        return;
                    }
                    if($scope.user.emailConfirm && $scope.user.email===$scope.user.emailConfirm) {
                        $scope.alertNoAccount = alertSrv.getErrorMsgWithBtn('Для подачи заявления на аккредитацию необходимо подтвердить email.');
                        return;
                    }
                    if($scope.user.account){
                        $state.go('accelAccEdit');
                    } else{
                        $scope.alertNoAccount = alertSrv.getErrorMsgWithBtn('Отсутствует лицевой счет у организации. Для получения счета необходимо отправить заявление на аккредитацию.');
                    }
                }
                function loadAccredidationListFn() {
                    profileSrv.accelerationAccredAvailable( $scope.user.id).then(function (response) {
                        if (response.data.success) {
                            $scope.viewModel.isFastAccredAccess = response.data.result === 1;
                        }
                    });
                    profileSrv.loadAccreditationList($scope.paginator, $scope.user.id ).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.accredList = response.data.items;
                            $scope.accredBtnActive = !!response.data.notRead;
                            $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                        } else {
                            $scope.agentsList = [];
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    })
                }
                function isDemoFn(){
                    return $rootScope.properties && $rootScope.properties['demo.mode']==="true";
                }
                function checkTypeFn(accred) {
                    if(accred.type === 'requisites_renew'){
                        return accred.created;
                    }else if(accred.type === 'accreditation' || 'accreditation_renew'){
                        return accred.accreditationDate;
                    }
                }

            }]
    });

