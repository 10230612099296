angular.module('uetp').service('docsHistorySrv', ['httpSrv', 'propertiesService',
    function (httpSrv) {
        var docType = {
            order:'Заказ',
            offer:'Предложение'
        };

        this.paginator = {
            itemsPerPage: 20,
            page: 0
        };
        this.setPaginator = function (paginator) {
            this.paginator = paginator;
        };

        this.getDocTypeByTypeHistory = function (typeHistory) {
            return docType[typeHistory] || "";
        };
        this.loadMetaDataById = function (typeHistory, id) {
            var url = '/orders/api/v1/history/'+typeHistory+'/'+id+'/metadata';
            return httpSrv.http({
                method: 'GET',
                url: url
            })
        };
        this.loadLogListByIdDoc = function (typeHistory, id, paginator) {
            var pg = paginator || this.paginator;
            if (!id) {
                return;
            }
            if (typeHistory) {
                pg.addParams = {};
                pg.addParams.docType = typeHistory;
            }
            var url = '/orders/api/v1/history/' + typeHistory + '/'+id;

            return httpSrv.http({
                method: 'POST',
                url: url,
                data: pg
            })

        }

    }]);
