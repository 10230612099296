/**
 * @author: sergeyu
 * Компонент Главной страницы
 */
angular.module('uetp')
    .component('home', {
        templateUrl:['constants', function (constants) {
            return 'app/components/home/' + constants.configProject.type + '.html'
        }],
        controller: ['$scope', '$rootScope', '$state', 'authSrv',  'httpSrv', 'dialogSrv', '$injector',
            function ($scope, $rootScope, $state, authSrv,  httpSrv, dialogSrv, $injector) {

                this.$onInit = function () {
                    $scope.cabType = $injector.get('desktopSrv').getCabType;
                };
            }]
    });

