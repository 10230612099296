angular.module('uetp').service('newsCreateSrv', ['$http', '$rootScope', 'dialogSrv', 'httpSrv',
    function ($http, $rootScope, dialogSrv, httpSrv) {
        this.deleteItem = deleteItemFn;
        this.saveNews = saveNewsFn;
        this.publicationNews = publicationNewsFn;
        this.loadNewsItem = loadNewsItemFn;
        this.deleteAttach = deleteAttachFn;

        function deleteItemFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/news/deleteNews/' + id
            })
        }

        function saveNewsFn(crtNews) {
            return httpSrv.http({
                url: '/news/saveNews',
                data: crtNews
            })
        }

        function publicationNewsFn(crtNews) {
            return httpSrv.http({
                url: '/news/publicationNews',
                data: crtNews
            })
        }

        function loadNewsItemFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/news/newsItem/' + id
            })
        }

        function deleteAttachFn(fileId) {
            return httpSrv.http({
                method: 'GET',
                url: '/news/deleteNewsAttachment/' + fileId
            })
        }
    }]);