/**
 * @author dmitrys
 */
angular.module('uetp').service('documentSamplesSrv', ['$http', '$rootScope', 'dialogSrv', 'httpSrv',
    function ($http, $rootScope, dialogSrv, httpSrv) {
        var service = this;
        service.deleteItem = deleteItemFn;
        service.publish = publishFn;
        service.deleteAttach = deleteAttachFn;

        /**
         * Удалить образец документа
         */
        function deleteItemFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/admin/deleteDocSample/' + id
            })
        }

        /**
         * Опубликовать Образец документа
         */
        function publishFn(crtNews) {
            return httpSrv.http({
                url: '/admin/publicateDocSample',
                data: crtNews
            })
        }

        /**
         * Удалить вложение
         */
        function deleteAttachFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/admin/deleteQuestionAttachment/' + id
            })
        }

    }]);