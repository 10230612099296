/**
 * Проверка вводимых значений в input
 */
angular.module('uetp').directive('inputformat', [function () {

    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, elem, attr, ctrl) {
            if (attr['masktype'] || elem[0].tagName == "TEXTAREA") {
                var maskType = attr['masktype'],
                    maskObj = {
                        'money': /^[0-9\s]{1,13}(?:\.[0-9]{0,2})?$|^0$/,
                        'percent': /^[0-9\s]{1,12}(?:\.[0-9]{0,4})?$/,
                        'exchangerate': /^[0-9\s]{1,13}(?:\.[0-9]{0,6})?$|^0$/,
                        'quantity': /^[0-9]{0,13}(?:\.[0-9]{0,5})?$/,
                        'quantityNot0'    : /^[1-9][0-9]{0,13}(?:\.[0-9]{0,5})?$/,
                        'quantity13.2': /^[0-9][0-9]{0,12}(?:\.[0-9]{0,2})?$/,
                        'supplySum': /^[0-9]{0,17}(?:\.[0-9]{0,2})?$/,
                        'symbol': /^[a-zA-Z0-9]*$/,
                        'int': /^[0-9]*$/,
                        'int3-int3': /^[0-9][0-9][0-9]-[0-9][0-9][0-9]$/,
                        'int2_int2': /^[0-9][0-9]\s[0-9][0-9]$/,
                        'snils': /^[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9]\s[0-9][0-9]$/,
                        'intPositive' : /^[1-9]\d*$/,
                        'int-': /^(\-)?[0-9]*$/,
                        'int.int': /^[0-9]+(\.[0-9]*)?$/,
                        'int2': /^((?!0)[0-9]{0,2})$/,
                        'int3': /^(\d{0,3})$|^0$/,
                        'int11': /^(\d{0,11})$|^0$/,
                        'int10': /^(\d{0,10})$|^0$/,
                        'int19.1': /^[0-9]{0,19}(?:\.[0-9]{0,1})?$|^0$/,
                        'int19.5': /^[0-9]{0,19}(?:\.[0-9]{0,5})?$|^0$/,
                        'withoutlat':/^[^A-z]*$/,
                        'withoutcyr':/^[^А-яёЁ]*$/,
                        'nds': /^[0-9]{0,2}(?:\.[0-9]{0,2})?$/,
                        'guid': /^[a-fA-F0-9\-]{0,36}$/,
                        'time': /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/
                    },
                    maxlength = elem[0].tagName === "TEXTAREA" || elem[0].tagName === "INPUT" ? attr['maxlength'] : null;//ie9
                // Установка null для полей в числовыми форматами вместо 0
                const digitsNull = attr['digitsnull'] ? attr['digitsnull'] ==='true' : false;
                if (!ctrl) return;
                ctrl.$parsers.unshift(function (viewValue) {
                    var currValue = maskType ? viewValue.toString().replace(/,/g, '.') : viewValue,
                        onlyDigits = maskType ? ['quantity','int19.1','int19.5', 'int-', 'int.int'].includes(maskType) : false,
                        modelValue = !onlyDigits || ctrl.$modelValue ? ctrl.$modelValue : 0;
                    if (modelValue == null) modelValue = ""; //ie fix
                    //если поле НЕ обязательное и не заполнено, то оставляем его пустым
                    if (!ctrl.$$attr.required && digitsNull) onlyDigits = false;
                    if (maskType) {
                        if (['guid'].indexOf(maskType) !== -1) {
                            if (!maskObj[maskType].test(currValue)) {
                                currValue = modelValue;
                            }
                        } else if ('time'===maskType) {
                            currValue = normalizeTime(currValue);
                            if (!maskObj[maskType].test(getFullTime(currValue)))
                                currValue = modelValue;
                        } else if(['int3-int3'].includes(maskType)) {
                            currValue = currValue.toString().replace(/[^\d-]+/, "");
                        } else if(['int2_int2'].includes(maskType)) {
                            currValue = currValue.toString().replace(/[^\d\s]+/, "");
                        } else if(['snils'].includes(maskType)) {
                            currValue = currValue.toString().replace(/[^\d\s-]+/, "");
                        } else if (currValue && maskObj[maskType] && !maskObj[maskType].test(currValue)) {
                            currValue = modelValue;
                        }
                        //возможно и для кол-ва
                        if (['money','int19.1','int19.5', 'quantity13.2','int.int', 'percent'].includes(maskType)) {
                            currValue = currValue.toString().replace(/\s/g, "").replace(/^0+([0-9])/, '$1');
                        }
                    } else if (maxlength && currValue && currValue.length > maxlength) {//ie9
                        currValue = modelValue
                    }
                    try {
                        ctrl.$setViewValue(currValue);
                        ctrl.$render();
                    }catch {
                    }
                    return onlyDigits ? Number(currValue) : currValue;
                });

                function normalizeTime(current) {
                    return current.length>2 && /^(\d{3})$/.test(current.substring(current.length-3)) ?
                        current.substring(0, current.length-1) + ':' + current.substring(current.length-1) : current;
                }

                function getFullTime(current) {
                    return current.length < 8 ? current + '00:00:00'.substring(current.length) : current;
                }
                
                elem.bind('blur', function () {
                    if(elem.val()!=='' && maskType==='quantity'){
                        elem.val(replaceStr(elem.val()));
                    }
                });
                var replaceStr = function (value) {
                    var s = value.toString().trim().replace(/\s/g, "").replace('.', ',');
                    return s.endsWith(',') ? s.substr(0, s.length-1) : s;
                };
            }
        }
    };
}]);
