/**
 * @author: sergeysi
 * Заказчики. Способ закупки ЕИС (Филиалы)
 */
angular.module('uetp')
    .component('customerBranches', {
        bindings: {
            customerData: '<'
        },
        templateUrl: 'app/components/customer/customerEisIntegration/customerBranches.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'profileSrv', 'authSrv', 'desktopSrv', 'dialogSrv', 'customerSrv',
            function ($scope, $rootScope, $state, $stateParams, profileSrv, authSrv, desktopSrv, dialogSrv, customerSrv) {
                var ctrl = this;
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.authData = authSrv;
                    $scope.user = authSrv.getUser();
                    $scope.customerId = $stateParams.id;
                    if(ctrl.customerData && ctrl.customerData.data.success) {
                        $stateParams.customer = ctrl.customerData.data.result;
                    }
                    if(['admincab', 'support'].includes($scope.cabType()))
                        getCustomerBranchesFn();
                };

                $scope.openCustomerBranch = openCustomerBranchFn;
                $scope.deleteCustomerBranch = deleteCustomerBranchFn;

                function deleteCustomerBranchFn(id){
                    customerSrv.deleteCustomerBranch(id).then(function(response){
                        if (response.data.success){
                            getCustomerBranchesFn();
                        } else{
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }

                    })
                }

                function openCustomerBranchFn(data) {
                    $state.go('openCustomerBranch', {id: data.id, customerId: $stateParams.id, branchData: $scope.branches, customer: ctrl.customerData.data.result});
                }

                /**
                 * Список способов филиалов
                 */
                function getCustomerBranchesFn() {
                    customerSrv.getCustomerBranches($stateParams.id).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.branches = response.data.result;
                        } else {
                            $scope.branches = [];
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    })
                }
                $scope.cancel = function () {
                    $state.go('customer');
                };
        }]
});