import template from './reports.html';
import './types/averageSavingReport';
import './types/balanceReport';
import './types/blockingReport';
import './types/customersReport';
import './types/declaredLotsReport';
import './types/openInvoiceReport';
import './types/participantsReport';
import './types/paymentOrdersReport';
import './types/ppkTariffReport';
import './types/socAtcomReport';
import './types/tariffsReport';
import './types/tariffUsageReport';
import './types/postedInfoReport';

const REPORTS = [
	'tariffs', 'balance', 'openInvoice', 'customersReport', 'participantsReport', 'socAtcomReport', 'blockingReport', 'tariffUsage', 'ppkTariff', 'paymentOrdersReport'
];

angular.module('uetp').component('reports', {
	template,
	controller: [
		'$scope', '$rootScope', '$filter',
		'constants', 'desktopSrv', 'httpSrv', '$timeout',
		...REPORTS,
		function (
			$scope, $rootScope, $filter,
			constants, desktopSrv, httpSrv, $timeout,
			...reportTypesValue
		) {
			const globalInit = ()=>{
				$scope.reportTypes = reportTypesValue.reduce((obj, rep, i)=>{
					obj[REPORTS[i]] = rep;
					return obj;
				}, {});
				const cabType = desktopSrv.getCabType();
				REPORTS.forEach(key=>{
					if (!$scope.reportTypes[key].access.includes(cabType)) delete $scope.reportTypes[key];
				});
				if (Object.keys($scope.reportTypes).length === 0) return;

				$timeout(() => {
					const yesterday = new Date(getServerTime());
					$scope.yesterday = new Date(yesterday.setDate(yesterday.getDate() - 1));
					$scope.currentDate = getServerTimeMsk();
				}, 0)

				$scope.form           = {reportForm: {}};
				$scope.filterElement  = {};
				$scope.constants      = constants;

				$scope.initReport     = initReportFn;
				$scope.downloadReport = downloadReportFn;
				$scope.fillMinMaxOpt  = fillMinMaxOptFn;
				initReportFn();
			};

			globalInit();
			$rootScope.$on('cabTypeChange', globalInit);

			//Methods
			function initReportFn() {
				var type = $scope.currentReport || Object.keys($scope.reportTypes)[0];
				$scope.currentReport  = type;
				$scope.reportTemplate = $scope.reportTypes[type].template;
				$scope.report         = angular.copy($scope.reportTypes[type].data);
				if($scope.report.accountType){
					if(!$scope.accountTypes){
						getAccountTypes().then(function () {
							if($scope.accountTypes)
								$scope.report.accountType = $scope.accountTypes.find(a => a.id === $scope.report.accountType.id);
						});
					}
				}
				//For period element
				$scope.minMaxOpt = {
					date: {
						max: {maxDate: null},
						min: {minDate: null},
						minCurrent: {minDate: getServerTimeMsk()}
					}
				}
				if($scope.reportTypes[type].minMaxOpt)
					angular.extend($scope.minMaxOpt.date, angular.copy($scope.reportTypes[type].minMaxOpt.date));
			}

			function getAccountTypes() {
				return httpSrv.http({
					method: 'GET',
					url: '/admin/getAccountTypeOperators'
				}).then(function successCallback(response) {
					if (response.data.success) {
						$scope.accountTypes=response.data.result;
					}
				}, function errorCallback(response) {
					console.error(response);
				});
			}


			function toMSK(date) {
				var timeZoneMSK = new Date().getTimezoneOffset()/60+3;
				date.setHours(date.getHours()+timeZoneMSK);
			}

			function downloadReportFn() {
				var cr = $scope.reportTypes[$scope.currentReport];

				var report = angular.copy($scope.report);
				var mskData = cr.toMSK;

				if (mskData) mskData.forEach(function (name) {
					if (report[name]) toMSK(report[name]);
				});

				httpSrv.downloadFile(
					cr.url,
					cr.fileName ? cr.fileName($filter, report) : null,
					report
				);
			}

			//For period element
			function fillMinMaxOptFn(type, subType) {
				if ($scope.report.startDate || $scope.report.endDate) {
					var dateDype = subType==='min'?'startDate':'endDate';
					$scope.minMaxOpt[type][subType][subType + "Date"] = $scope.report[dateDype];
				}
			}
		}
	]
});