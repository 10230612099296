/**
 * @author: sergeysi
 * Сервис для панелей с перетягиванием элементов
 */
angular.module('uetp').service('drapdropPanelSrv', [
    function () {
        const service = this;
        let hasChanges = false;

        service.setChanges = (val) => hasChanges = val;
        service.hasChanges = () => hasChanges;
    }
]);