//компонент страницы Реклама
angular.module('uetp')
  .component('privacy', {
    templateUrl: 'app/components/privacy/privacy.html',
    transclude: true,
    controller: [function() {
      //начальная инициализация
      init();
      function init() {
        window.scrollTo(0, 0);
      }
    }]
  });