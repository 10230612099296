/**
 * Создание новостей
 */
angular.module('uetp')
    .component('newsCreate', {
        templateUrl: 'app/components/news/newsCreate/newsCreate.html',
        controller: ['$scope', '$rootScope', 'constants', '$state', '$stateParams', 'newsCreateSrv', 'httpSrv', 'dialogSrv', 'alertSrv', 'desktopSrv','needConfirmSrv',
            function ($scope, $rootScope, constants, $state, $stateParams, newsCreateSrv, httpSrv, dialogSrv, alertSrv, desktopSrv,needConfirmSrv) {
                $scope.saveNews = saveNewsFn;
                $scope.publicationNews = publicationNewsFn;
                $scope.loadNewsItem = loadNewsItemFn;
                $scope.downloadFile = httpSrv.downloadFile;
                $scope.cancel = cancelFn;
                $scope.isReadOnly = isReadOnlyFn;
                $scope.onTopChange = onTopChangeFn;
                $scope.isViewOnly = $stateParams.isViewOnly;
                init();
                $scope.preShow = false;

                function saveNewsFn() {
                    $scope.submitted = true;
                    if (!$scope.form.mainForm.$valid) {
                        $scope.alertObjNews = alertSrv.getErrorMsg('Заполните все обязательные поля!');
                        return;
                    }
                    if($scope.crtNews.top && !($scope.crtNews.images && $scope.crtNews.images.length > 0)){
                        $scope.alertObjNews = alertSrv.getErrorMsg('Необходимо добавить изображение.');
                        return;
                    }
                    $scope.crtNews.type = $scope.newsTypeItem.selected;
                    if ($scope.crtNews.type === 'news') {
                        $scope.crtNews.sendGroups = $scope.newsSentTypeItem.selected;
                    } else if ($scope.crtNews.type === 'massive') {
                        $scope.crtNews.sendGroups = $scope.newsSpamTypeItem.selected;
                    }
                    newsCreateSrv.saveNews($scope.crtNews).then(function (response) {
                        if (response.data.success) {
                            $scope.submitted = false;
                            $scope.crtNews.id = response.data.result;
                            $scope.crtNews.number = response.data.message;
                            $scope.alertObjNews = alertSrv.getSuccessMsg('Новость сохранена!');
                            if ($scope.form.mainForm) {
                                $scope.form.mainForm.$setPristine();
                            }
                        }
                    });
                    if (document.activeElement) {
                        document.activeElement.blur();
                    }
                }

                function publicationNewsFn() {
                    $scope.submitted = true;
                    if (!$scope.form.mainForm.$valid) {
                        $scope.alertObjNews = alertSrv.getErrorMsg('Заполните все обязательные поля!');
                        return;
                    }
                    if($scope.crtNews.top && !($scope.crtNews.images && $scope.crtNews.images.length > 0)){
                        $scope.alertObjNews = alertSrv.getErrorMsg('Необходимо добавить изображение.');
                        return;
                    }
                    $scope.crtNews.type = $scope.newsTypeItem.selected;
                    if ($scope.crtNews.type === 'news') {
                        $scope.crtNews.sendGroups = $scope.newsSentTypeItem.selected;
                    } else if ($scope.crtNews.type === 'massive') {
                        $scope.crtNews.sendGroups = $scope.newsSpamTypeItem.selected;
                    }
                    newsCreateSrv.publicationNews($scope.crtNews).then(function (response) {
                        if (response.data.success) {
                            $scope.submitted = false;
                            $scope.crtNews.status = 'published';
                            $scope.alertObjNews = alertSrv.getSuccessMsg('Новость опубликована!');
                        }
                    });
                }

                function loadNewsItemFn(id) {
                    newsCreateSrv.loadNewsItem(id).then(function (response) {
                        if (response.data.success) {
                            $scope.crtNews = response.data.result;
                            if(!$scope.crtNews.site) $scope.crtNews.site="";
                            $scope.newsTypeItem.selected = $scope.crtNews.type;
                            if ($scope.crtNews.type === 'news') {
                                $scope.newsSentTypeItem.selected = $scope.crtNews.sendGroups;
                            } else if ($scope.crtNews.type === 'massive') {
                                $scope.newsSpamTypeItem.selected = $scope.crtNews.sendGroups;
                            }
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    })
                }

                $scope.newsTypeChange = function (select) {
                    $scope.newsTypeItem = select.$select.selected;
                };

                $scope.showFilesNews = function (files) {
                    $scope.attachItem.name = files[0].name;
                };
                $scope.onSizeError = function (files) {
                    $scope.alertObjNewsFile = alertSrv.getErrorMsg("Документ " + files[0].name + " не добавлен. Превышен максимально разрешенный размер файла (30МБ)!");
                };
                $scope.onFileNameError = function (files) {
                    $scope.alertObjNewsFile = alertSrv.getErrorMsg("Документ " + files[0].name + " не добавлен. Добавление файлов с расширением *.exe запрещено!");
                };
                $scope.onSizeImageError = function () {
                    $scope.alertObjNewsImage = alertSrv.getErrorMsg("Файл превышает допустимый размер 1 Мб.");
                };
                $scope.onFileNameImageError = function () {
                    $scope.alertObjNewsImage = alertSrv.getErrorMsg("Файл не соответствует одному из форматов - JPG, JPEG, PNG.");
                };
                $scope.uploadFileNews = function (response) {
                    var attachField = getAttachField(response.data.result.fileType);
                    if (!$scope.crtNews[attachField]) {
                        $scope.crtNews[attachField] = [];
                    }
                    $scope.crtNews[attachField].push({
                        "id": response.data.result.id,
                        "fileType": response.data.result.fileType,
                        "name": $scope.attachItem.name,
                        "description": $scope.attachItem.description
                    });
                    attachReset();
                    if(response.data.result.fileType == 0)
                        $scope.addAttach = '';
                    else
                        $scope.addImage = '';
                    //ie 11 баг снятие фокуса. Иначе не нажат кнопки
                    window.focus();
                    if (document.activeElement) {
                        document.activeElement.blur();
                    }

                    if ($scope.form.mainForm) {
                        $scope.form.mainForm.$setDirty();
                    }
                };
                $scope.deleteAttach = function (file, object) {
                    var attachField = getAttachField(file.fileType);
                    newsCreateSrv.deleteAttach(file.id).then(function (response) {
                        if (response.data.success) {
                            var index = object[attachField].indexOf(file);
                            if (index > -1) {
                                object[attachField].splice(index, 1);
                            }
                            if ($scope.form.mainForm) {
                                $scope.form.mainForm.$setDirty();
                            }
                        } else {
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    })
                };

                function attachReset() {
                    $scope.attachItem = {
                        name: '',
                        description: ''
                    };
                }

                function getAttachField(attachType){
                    return attachType == 1 ? 'images' : 'attachment';
                }

                $scope.addAttachShow = function () {
                    if ($scope.addAttach !== '') {
                        $scope.addAttach = '';
                    } else {
                        $scope.addAttach = 'show';
                    }
                };

                $scope.addImageShow = function () {
                    if ($scope.addImage !== '') {
                        $scope.addImage = '';
                    } else {
                        $scope.addImage = 'show';
                    }
                };

                function init() {
                    $scope.cabType = desktopSrv.getCabType();
                    $scope.crtNews = {top: false};
                    $scope.form = {mainForm: ''};
                    if ($scope.form && $scope.form.mainForm) $scope.form.mainForm.$setPristine();
                    if ($scope.form && $scope.form.mainForm) $scope.form.mainForm.$setUntouched();
                    $scope.newsSiteTypes = {};
                    $scope.newsSiteTypes.items = [
                        {name: 'Везде', code: ''},
                        {name: 'Только ППК', code: 'ppk'},
                        {name: 'Только КОМИТА ЭТП', code: 'etp'}
                    ];
                    $scope.newsTypeItem = {};
                    $scope.newsTypes = {};
                    $scope.newsTypes.items = [
                        {name: 'Новость', code: 'news'},
                        {name: 'Массовая рассылка', code: 'massive'}
                    ];
                    $scope.newsTypeItem = {};

                    $scope.newsSentTypes = {};
                    $scope.newsSentTypes.items = [
                        {name: 'Сайт', code: 'site'},
                        {name: 'Сайт и поставщики', code: 'site_provider'},
                        {name: 'Сайт и заказчики', code: 'site_customer'},
                        {name: 'Сайт и все пользователи', code: 'site_all'}
                    ];
                    $scope.newsSentTypeItem = {};

                    $scope.newsSpamTypes = {};
                    $scope.newsSpamTypes.items = [
                        {name: 'Всем пользователям', code: 'all'},
                        {name: 'Только поставщикам', code: 'provider'},
                        {name: 'Только заказчикам', code: 'customer'}
                    ];
                    $scope.newsSpamTypeItem = {};
                    attachReset();
                    $scope.addAttach = '';
                    $scope.addImage = '';
                    $scope.state = $state.current.name;
                    $scope.idDocTime = new Date().getTime();

                    if ($stateParams.id) loadNewsItemFn($stateParams.id);
                    $scope.maxCountAttachments = 2;
                }

                function goBackFn(){
                    history.back();
                }
                function cancelFn() {
                    if($scope.form.mainForm.$dirty && $scope.crtNews.status != 'published'){
                        needConfirmSrv.call(goBackFn,[],'Внесенные изменения сохранены не будут');
                    }else{
                        goBackFn();
                    }
                }
                function isReadOnlyFn() {
                    return ["support"].indexOf(desktopSrv.getCabType())!=-1;
                }

                function onTopChangeFn() {
                    if(!$scope.crtNews.top && $scope.crtNews.images && $scope.crtNews.images.length>0)
                        $scope.deleteAttach($scope.crtNews.images[0], $scope.crtNews);
                }

                $scope.getMaxCountAttach = function() {
                    return new Array($scope.maxCountAttachments)
                }

            }]

    });

