/**
 * Сервис для пагинатора
 */
angular.module('uetp').service('paginationSrv', ['$state', '$stateParams', 'storageSrv',
    function ($state, $stateParams, storageSrv) {
        var service = this;
        /**
         * Работа с пагинатором запоминание страниц и количества отображаемых страниц
         * параметры смотреть в storageSrv
         */
        service.getPaginationStateConfig = function (guid, type) {
            return storageSrv.getStateConfig(guid, type);
        };
        service.setPaginationStateConfig = function (guid, type, data) {
            storageSrv.setStateConfig(guid, type, data);
        };
        service.clearPaginationStateConfig = function (guid, type) {
            storageSrv.clearStateConfig(guid, type);
            return {page: 0, itemsPerPage: 20}
        }
    }]);
