/**
 * Акт по оплаченному счету
 */
angular.module('uetp')
    .component('completedWork', {
        templateUrl: 'app/components/completedWorks/completedWork.html',
        bindings: {
            completedWorkData: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', '$q', 'desktopSrv','completedWorksSrv','tablesSrv', 'invoiceSrv', 'httpSrv', 'authSrv', 'cryptography', 'alertSrv', 'dialogSrv', 'timeout',
            function ($scope, $rootScope, $state, $stateParams, $q, desktopSrv, completedWorksSrv, tablesSrv, invoiceSrv, httpSrv, authSrv, cryptography, alertSrv, dialogSrv, timeout) {
                $scope.completedWork = {};
                $scope.goBack = goBackFn;
                $scope.openAdditional = openAdditionalFn;
                $scope.signDoc = signDocFn;
                $scope.downloadFile = httpSrv.downloadFile;
                $scope.invoiceSrv = invoiceSrv;
                $scope.auth = authSrv.getAuth();
                function goBackFn() {
                    history.back();
                }
                this.$onInit = function () {
                    const ctrl = this;
                    $scope.paginator = {
                        itemsPerPage: 20,
                        page: 0
                    };
                    $scope.completedWork = ctrl.completedWorkData;
                    $scope.operations = $scope.completedWork.operations;
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.showAdditional = false;
                    $scope.processing = false;
                    $scope.showSignForm = $scope.cabType() == 'participant' && $scope.completedWork.sellerDocStatus.id === 'sent';
                    $scope.model = {}
                };

                function openAdditionalFn(){
                    $scope.showAdditional = !$scope.showAdditional;
                    if($scope.showAdditional && $scope.showSignForm && !$scope.model.position && authSrv.isCertAvailable())
                        cryptography.findCertificate($scope.auth.certId).then(function (data) {
                            if(data!=null){
                                $q.when(data.SubjectName).then(function (value) {
                                    $scope.model.position = convertCertField(value.match(/(\s|^)T=[А-Яа-яёЁ\s]{1,}/g));
                                    timeout($scope);
                                })
                            }
                        });
                }

                function signDocFn(){
                    $scope.processing = true;
                    if(!authSrv.isCertAvailable()){
                        $scope.alertSaveCompletedWork = alertSrv.getErrorMsgWithBtn('В профиле пользователя отсутствует действительный сертификат для подписи!');
                        $scope.processing = false;
                        return;
                    }
                    $scope.model.signDate = new Date();
                    return completedWorksSrv.signAndSend($scope.completedWork.id, $scope.model, $scope.auth.certId).then(function successCallback(response) {
                        if (response.data.success) {
                            return $state.reload();
                        } else {
                            dialogSrv.showDialogMsg($scope, response.data.message || response.data.errorMessage);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    }).finally(function () {
                        $scope.processing = false;
                    });
                }
            }]
    });
