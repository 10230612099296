angular.module('uetp').controller('factoringCarouselController', ['$scope', 
    function ($scope) {
        this.$onInit = function () {
            const facSlides = $scope.facSlides = [];
            let currIndex = 0;
            $scope.facInterval = 5000;
            $scope.facNoWrapSlides = false;
            $scope.facActive = 0;

            $scope.addSlide = function () {
                facSlides.push({
                    image: {
                        src: 'img/banner-warranty.png',
                    },
                    metrika: 'guaranteeAim',
                    ref: 'bank',
                    clazz: 'slide-uetp',
                    id: currIndex++
                });

                facSlides.push({
                    image: {
                        src: 'img/banner-factor.png',
                    },
                    metrika: 'factoringAim',
                    ref: 'factoring',
                    small: '',
                    clazz:'slide-uetp',
                    id: currIndex++
                });

            };

            $scope.addSlide();
        };
    }]).directive('factoringcarousel', [function () {
    return {
        restrict: 'E',
        controller: 'factoringCarouselController',
        templateUrl: 'views/factoringcarousel.html'
    };
}]);
