/**
 * @author: sergeyu
 * Просмотр закупки ЕИС
 */
angular.module('uetp')
    .component('proceduresEISView', {
        templateUrl: 'app/components/proceduresEIS/procedures.html',
        bindings: {
            procedureData: '<',
            personalAccountData: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'proceduresSrv', '$uibModal', 'authSrv', 'httpSrv',
                     'storageSrv', 'vfSrv', 'needConfirmSrv', 'alertSrv', 'dictionarySrv', '$filter', 'dialogSrv', 'noticeSrv', 'loggerUiSrv', 'permissionSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, proceduresSrv, $uibModal, authSrv, httpSrv,
                      storageSrv, vfSrv, needConfirmSrv, alertSrv, dictionarySrv, $filter, dialogSrv, noticeSrv, loggerUiSrv, permissionSrv) {
                var ctrl = this;
                $scope.ctrl = ctrl;
                $scope.totalPagesCheck = totalPagesCheckFn;
                $scope.openNomenclature = openNomenclatureFn;
                $scope.setActivePanel = setActivePanelFn;
                $scope.downloadFile = downloadFileFn;
                $scope.onEisOpen = onEisOpen;
                $scope.proceduresSrv = proceduresSrv;
                $scope.createNoticeFromPlan = createNoticeFromPlanFn;
                $scope.showPage = true;
                $scope.isOrganizer = isOrganizerFn;
                this.$onInit = function () {
                	$scope.cabType = desktopSrv.getCabType();
                    $scope.auth = authSrv.getAuth();
                    $scope.user = ctrl.personalAccountData;
                    if (authSrv.canActiveOtherPurchase($scope.cabType,$scope.user)) {
                        $scope.dateMonth = dictionarySrv.getStaticDictByType('dateMonth');
                        $scope.paginator = {
                            itemsPerPage: 50,
                            page: 0
                        };
                        $scope.procedure = ctrl.procedureData.data.items[0];

                        //признак отображения вкладок
                        if (!$scope.procedure) {
                            var messageTxt = 'Закупка не найдена.';
                            dialogSrv.openModal('_dialogMessage.html',
                                {message: messageTxt}).then(function () {
                                $state.go('home');
                            }) ;
                        } else {
                            _initShowPanel();
                            $scope.isShowPanel = $scope.procedure.state === 'PLANNED';

                            proceduresSrv.fillTimeZone($scope.procedure, $scope.procedure.customers[0].timeZone);
                            proceduresSrv.needSupplySum($scope.procedure);
                            analyzeStartDate($scope.procedure);
                            initCurrencyProc($scope.procedure);
                        }
                    }else{
                    	$scope.showPage = false;
                        var messageTxt = 'Доступ к Реестру закупок ЕИС закрыт.';
                        if ($scope.cabType === 'participant') {
                            messageTxt = 'Раздел доступен только для пользователей тарифного плана "Годовой"';
                        }
                        dialogSrv.openModal('_dialogMessage.html',
                            {message: messageTxt}).then(function () {
                            if ($scope.cabType === 'participant') {
                                $state.go('myProcedures')
                            } else {
                                $state.go('home')
                            }
                        })
                    }
                };

                $scope.hasPermission = permissionSrv.hasPermission;

                $scope.showHideBtnBankGuarantees = function () {
                    return ($scope.auth.userRole.includes('user') || $scope.auth.userRole.includes('participant_admin')) && $scope.procedure.state !== 'CANCELLED' && $scope.procedure.state !== 'PLANNED' && desktopSrv.isBGEnabled()
                };
                $scope.showCreateNotice = function () {
                    return $stateParams.canCreateNotice==='true' && $scope.isOrganizer() && $scope.hasPermission();
                };

                function _initShowPanel() {
                    $scope.model = {
                        showPanel: 'lots',
                        showLots:true,//Показывать вкладку "Информация по лотам"
                        showDocumentation: true //Показать вкладку "Документация по закупке"
                    };
                    if($scope.procedure.doc_name === 'pprf615NotificationPO') {
                        $scope.model.showLots = false;
                    }
                }

                function isOrganizerFn(){
                    return $scope.user && ($scope.user.userRole==='organizer_admin' || $scope.user.userRole==='organizer_user');
                }

                function totalPagesCheckFn(data) {
                    return Math.ceil(data.length / $scope.paginator.itemsPerPage);
                }
                function initCurrencyProc(data) {
                    data.viewCurrency = false;
                    if (data.lots && data.lots.length > 0) {
                        var viewCurrency = true,
                            currencyType = data.lots[0].currency_code;
                        angular.forEach(data.lots, function (lot) {
                            if (lot.currency_code !== currencyType) {
                                viewCurrency = false;
                            }
                        });
                        data.viewCurrency = viewCurrency;
                    }
                }

                function openNomenclatureFn(data) {
                    if (!data.showInfo) {
                        if (!data.analyzSuccess) {
                            angular.forEach(data.lotItems, function (item) {
                                item.analyzSuccess = true; // чтоб не делать анализ повторно
                                if (item.okved2_name) {
                                    data.showOkved2 = true;
                                }
                                if (item.okpd2_name) {
                                    data.showOkpd2 = true;
                                }
                                if (item.ktru_name) {
                                    data.showKtru = true;
                                }
                                if (item.mnn_name) {
                                    data.showMnn = true;
                                }
                            });
                        }
                    }
                    data.showInfo = !data.showInfo

                }
                function analyzeStartDate(procedure) {
                    if (procedure.start_date) {
                        var stringDate = $filter('date')(procedure.start_date, 'dd.MM.yyyy HH:mm', 'UTC+3');
                        procedure.sDateTime = !!(stringDate && stringDate.indexOf('00:00') === -1);
                    }
                }
                /**
                 * Выставление активной вкладки
                 * @param type - тип вкладки
                 */
                function setActivePanelFn(type) {
                    $scope.model.showPanel = type;
                }
                function downloadFileFn(data) {
                    httpSrv.downloadFile(data.url, data.fileName);
                }

                /**
                 * Создание извещения из позиции плана закупки
                 */
                function createNoticeFromPlanFn(){
                    getEisPurchaseMethod($scope.procedure.method_code, $scope.procedure.purchase_number, $scope.procedure.customers[0].inn).then(function (response) {
                        if (response.data.success) {
                            noticeSrv.setState('procedure');
                            noticeSrv.setShowPanel('procedure');
                            $state.go('createInitedNotice', {
                                type: response.data.result.uetpCode,
                                dataProc: $scope.procedure
                            });
                        } else {
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                    });
                }
                function getEisPurchaseMethod(code, purchaseNumber, customerInn) {
                    return httpSrv.http({
                        method: 'POST',
                        url: '/organizer/eis/eisPurchaseMethod/' + code + '/' + purchaseNumber + '/' + customerInn
                    })
                }

                function onEisOpen(purchase_number){
                    loggerUiSrv.sendLog('PROCEDURE_EIS_OPEN',{
                        targetId: purchase_number
                    });
                }


            }]
    });

