/**
 * @author: sergeysi
 * Компонент уведомлений участника
 */
angular.module('uetp')
    .component('lkNotification', {
        templateUrl: 'app/components/lk/notification/lknotification.html',
        bindings: {
            onlyCookies: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$window', '$document', 'authSrv', 'desktopSrv', 'dictionarySrv',
            '$interval', '$uibModal', '$timeout', 'lkNotificationSrv','sysMessageSrv','coveringLetterSrv', '$filter', 'participantSrv',
            function ($scope, $rootScope, $state, $window, $document, authSrv, desktopSrv, dictionarySrv,
                      $interval, $uibModal, $timeout, lkNotificationSrv, sysMessageSrv, coveringLetterSrv, $filter, participantSrv) {
                $rootScope.$on("msgReaded", function (event, msgId) {
                    if ($scope.context.individualMessage && $scope.context.individualMessage.id === msgId) {
                        $scope.readIndividualMessage();
                        _refreshCounter();
                    } else
                        _initIndividualMessage(true);
                });
                $rootScope.$on("checkAccred", function () {
                    $scope.context.accredited = $scope.auth().orgAccreditation;
                    if(!$scope.context.accredExpires)
                        $scope.context.accredExpires = !$scope.context.accredited && $scope.user().accreditationEnd && $scope.user().accreditationEnd > new Date().getTime();
                    _refreshCounter();
                });
                $rootScope.$on("login", function () {
                    _init();
                });
                $rootScope.$on("checkPaymentOrder", function () {
                    _initCheckPaymentOrder();
                });
                $rootScope.$on("openLkNotificationEvent", function () {
                    _refreshCounter();
                    openLkNotificationFn($scope.context, $scope.notIMCount);
                });
                let intervalPromise;

                this.$onInit = function () {
                    // контекст уведомлений
                    $scope.context = {
                        accredExpires: false,
                        notificationCount: {},
                        showNotifications: false,
                        accredited: true,
                        paymentCheck: {},
                        agreeMsg: !authSrv.isSuccessUseCookie()
                    }
                    $timeout(function () {
                        _init();
                        intervalPromise = $interval(function () {
                            _initIndividualMessage(true);
                        }, 30000);
                    }, 500);

                    $scope.$on('$destroy', function () {
                        $interval.cancel(intervalPromise);
                    });
                };

                function _init() {
                    _initIndividualMessage(false);
                    _initCheckPaymentOrder();
                    _initApprovalTypeshop();
                }

                $scope.showNotifications = false;
                $scope.notificationCount = 0;
                $scope.alertObjServerError = {};
                $scope.$state = $state;
                $scope.auth = authSrv.getAuth;
                $scope.user = authSrv.getUser;
                $scope.cabType = desktopSrv.getCabType;
                $scope.windowWidth = window.innerWidth;
                $scope.getNotificationCount = lkNotificationSrv.getNotificationCount;

                function _initCheckPaymentOrder() {
                    if($scope.cabType()==='participant' && $scope.auth().userRole !== 'user') {
                        coveringLetterSrv.checkPaymentOrder().then(function (response) {
                            if (response.data.success) {
                                $scope.context.paymentCheck = response.data.result;
                            }
                            _refreshCounter();
                        }, function (reason) {
                            console.log(reason);
                        });
                    }else{
                        $scope.context.paymentCheck = {};
                    }
                }

                function _initIndividualMessage(counterOnly) {
                    const prevImCounter = $scope.context.imCounter;
                    lkNotificationSrv.initIndividualMessage($scope.context, counterOnly, function(){
                        _refreshCounter();
                        // обновляем сообщения
                        if(counterOnly && prevImCounter!==$scope.context.imCounter)
                            _initIndividualMessage(false);
                        }, function(){ $interval.cancel(intervalPromise);});
                }

                angular.element($window).bind('resize', function () {
                    $scope.windowWidth = window.innerWidth;
                });



                function _refreshCounter() {
                    $scope.context.notificationCount = {};

                    if(!$scope.context.approvalStatesMarket && $scope.context.accredExpires && participantSrv.isShowMsg('accredExpires') )
                        $scope.context.notificationCount.accredExpires3Month='accredExpires3Month';
                    if(!$scope.context.accredited &&
                        !$scope.context.approvalStatesMarket && !$scope.context.accredExpires && participantSrv.isShowMsg('accredExpires'))
                        $scope.context.notificationCount.accredExpires='accredExpires';
                    if(!$scope.context.accredited && participantSrv.isShowMsg('zmo') && $scope.context.approvalStatesMarket)
                        $scope.context.notificationCount.notAccred='notAccred';
                    if($scope.context.imCounter){
                        $scope.context.notificationCount.individualMessages=$scope.context.imCounter;
                    }
                    if($scope.context.paymentCheck?.length>0 && participantSrv.isShowMsg('paymentCheck')){
                        $scope.context.notificationCount.paymentCheck="paymentCheck";
                    }
                    $scope.notificationCount = lkNotificationSrv.getNotificationCount($scope.context.notificationCount);
                    $scope.notIMCount = $scope.context.imCounter ? Object.keys($scope.context.notificationCount).length - 1 : Object.keys($scope.context.notificationCount).length;
                }

                function _initApprovalTypeshop() {
                    const approvalStates = $scope.user().approvalStates;
                    $scope.context.approvalStatesMarket = approvalStates && approvalStates.includes("market");
                }

                $rootScope.$on("resetNotificationIconEvent", function ($event, param1, param2, close) {
                    if(close)
                        $scope.showNotifications = !$scope.showNotifications;
                    if(param1)
                        $scope.context.individualMessages = param1;
                    if(param2)
                        $scope.context.imCounter = param2;
                    _refreshCounter();
                });

                $scope.toggleNotifications = function () {
                    $scope.showNotifications = !$scope.showNotifications;
                    $rootScope.$emit($scope.showNotifications ? "openLkNotificationEvent" : "closeLkNotificationEvent");
                }

                $rootScope.$on("refreshCounter", function () {
                    _refreshCounter();
                });

                // Скрываем уведомления при клике вне окна
                $timeout(function () {
                    angular.element($document).on('mousedown', function (event) {
                        if ($scope.showNotifications && event.target && !["lkNotificationContent", "lkNotification", "lkNotificationDiv", "lkNotificationArrow", "lkNotificationBell"].includes(event.target.id) &&
                            !event.target.closest('#lkNotificationContent')) {
                            $scope.toggleNotifications();
                        }
                    });
                }, 100);

                function openLkNotificationFn(context, notIMCount) {
                    $uibModal.open({
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'lk-notif-modal-body',
                        backdrop  : false,
                        keyboard  : true,
                        size: 'lk-notification-left',
                        templateUrl: 'app/components/lk/notification/lknotificationmodal.html',
                        windowClass: 'my-modal',
                        controller:['$rootScope', '$scope', '$uibModalInstance', 'authSrv', 'desktopSrv', '$state', 'lkNotificationSrv', 'participantSrv', 'sysMessageSrv', 'httpSrv',
                            function ($rootScope, $scope, $uibModalInstance, authSrv, desktopSrv, $state, lkNotificationSrv, participantSrv, sysMessageSrv, httpSrv) {
                            $scope.user = authSrv.getUser;
                            $scope.auth = authSrv.getAuth;
                            $scope.cabType = desktopSrv.getCabType;
                            $scope.$state = $state;
                            $scope.isShowMsg = participantSrv.isShowMsg;
                            $scope.setShowMsg = setShowMsgFn;

                            $scope.downloadFile = function (file) {
                                httpSrv.downloadFile(`/message/api/v2/download/${file.id}`, file.name);
                            };

                            function setShowMsgFn(param, value) {
                                if($scope.notificationCount) {
                                    if (value) {
                                        $scope.notificationCount++;
                                        $scope.notIMCount++;
                                    } else {
                                        $scope.notificationCount--;
                                        $scope.notIMCount--;
                                    }
                                }

                                participantSrv.setShowMsg(param, value);
                            }

                            $scope.notificationClose = function(doClose) {
                                if(!doClose)
                                    $rootScope.$emit("resetNotificationIconEvent", $scope.individualMessages, $scope.notificationCount - $scope.notIMCount, true);
                                else {
                                    $rootScope.$emit("resetNotificationIconEvent", $scope.individualMessages, $scope.notificationCount - $scope.notIMCount);
                                    $uibModalInstance.close();
                                }
                            }

                            $scope.$on('modal.closing', function(event, reason){
                                if (!['cancel','escape key press'].includes(reason)) {
                                    return
                                }
                                $scope.notificationClose(false);
                            });

                            const deregister = $rootScope.$on("closeLkNotificationEvent", function () {
                                $scope.notificationClose(true);
                            });

                            $scope.$on('$destroy', deregister);

                            if (context) {
                                $scope.notificationCount = lkNotificationSrv.getNotificationCount(context.notificationCount);
                                $scope.accredited = context.accredited;
                                $scope.approvalStatesMarket = context.approvalStatesMarket;
                                $scope.accredExpires = context.accredExpires;
                                $scope.paymentCheck = context.paymentCheck;
                                $scope.individualMessages = context.individualMessages;
                                $scope.notIMCount = notIMCount;
                            }
                            $scope.setAllIndividualMessage = function(id, val) {
                                $scope.individualMessages.filter(im=>im.id===id).forEach(im=>im.isShowAllIndividualMessage = val);
                            }
                            $scope.isShowAllIndividualMessage = function(id){
                                const im = $scope.individualMessages.find(im=>im.id===id);
                                return im ? im.isShowAllIndividualMessage : false;
                            }
                            $scope.readIndividualMessage = function(id) {
                                sysMessageSrv.readIndividualMessage(id).then(function successCallback(response) {
                                    if (response.data.success) {
                                        lkNotificationSrv.initIndividualMessage($scope, false, function(){
                                            $scope.notificationCount = $scope.imCounter + $scope.notIMCount;
                                        });
                                    } else {
                                        console.log(response);
                                    }
                                }, function errorCallback(response) {
                                    console.log(response);
                                });
                            }
                        }]
                    }).result['catch'](function (res) {
                        if (!['cancel','escape key press'].includes(res))
                            throw res;
                    });
                }
            }]
    });