/**
 * @author sergeyu
 * Разрешенные действия для кнопок в зависимости от пришедшего actions  в запросе
 */
angular.module('uetp').directive("actionsbtn", [function () {
    return {
        restrict: 'E',
        templateUrl: 'app/directives/actionsBtn/actionsBtn.html',
        scope: {
            actionsList: '=', // списко кнопок с указанием действия
            targetData: '=', // данные которые потребуются для выполнения действий
            permissionScope: '=', // настройки прав доступа
            textBtn: "@", // текст кнопки если это группа кнопок
            color: '@', //  цвет кнопки, указываем стили bootstrap
            viewsBtns:'=' // отобразить только указанный кнопки в этом атрибуте
        },
        compile: function () {
            return {
                pre: function (scope) {
                    scope.initModel();
                }
            }
        },
        controller: ['$rootScope', '$scope', 'actionSrv', 'authSrv', 'noticeSrv', 'constants', 'permissionSrv',
            function ($rootScope, $scope, actionSrv, authSrv, noticeSrv, constants, permissionSrv) {
                $scope.actionsFn = actionSrv.getActionFn;
                $scope.auth = authSrv.getAuth();
                $scope.actionDisabledCheck = actionDisabledCheckFn;
                const fullDateAccredCheck = $scope.targetData && $scope.targetData.fullDateAccredCheck ? $scope.targetData.fullDateAccredCheck : $scope.auth.orgAccreditation;
                $scope.initModel = function () {
                    $scope.model = {
                        text: $scope.textBtn,
                        color: constants.configProject.type ==='ppk' && $scope.color.indexOf('btn-primary')!==-1 ? $scope.color + '  btn-danger btn-danger-custom' : $scope.color,
                        dataTarget: $scope.targetData,
                        permissionScope: $scope.permissionScope,
                        actions: _modifyActionsByViewsBtns($scope.actionsList)
                    }
                };
                $scope.hasPermission = getPermission();

                function getPermission() {
                    if($scope.permissionScope){
                        if(!$scope.permissionScope.includes('workflow') &&
                           !$scope.permissionScope.includes('contract') && $scope.permissionScope.includes('sign'))
                            return permissionSrv.hasSignContractPermission;
                        else if($scope.permissionScope.includes('workflow') &&
                           $scope.permissionScope.includes('contract') && !$scope.permissionScope.includes('sign'))
                            return permissionSrv.hasWorkflowOrContractPermission;
                        else if($scope.permissionScope.includes('workflow') && $scope.permissionScope.includes('contract') && $scope.permissionScope.includes('sign'))
                            return permissionSrv.hasAnyPermission;
                        else
                            return permissionSrv.hasPermission;
                    } else
                        return function () { return true; }
                }

                function _modifyActionsByViewsBtns(actions) {
                    var list = null;
                    if ($scope.viewsBtns && actions && actions.length > 0 ) {
                        list = [];
                        angular.forEach(actions, function(action) {
                            angular.forEach($scope.viewsBtns, function (viewBtn) {
                                if (action.id.indexOf(viewBtn) >= 0) {
                                    list.push(action);
                                }
                            })
                        })
                    } else {
                        list = actions;
                    }
                    return list;

                }

                function actionDisabledCheckFn(id) {
                    if (id) {
                        const disabledCertActions = '.sendClaim';
                        if((''||id).startsWith('simplecontest.') && id.indexOf(disabledCertActions) !== -1)
                            return authSrv.isCertificateError() || ($scope.auth.hasMarketOrgApproval === false && fullDateAccredCheck === false);
                        return id.indexOf(disabledCertActions) !== -1
                            && (authSrv.isCertificateError() || fullDateAccredCheck === false);
                    } else {
                        return false;
                    }
                }

                $rootScope.$on("enableActions", function(){
                    if($scope.model?.actions)
                        angular.forEach($scope.model.actions, action => action.disabled = false);
                });
            }]
    }
}]).directive('compile', ['$compile', function ($compile) {
    return function(scope, element, attrs) {
        scope.$watch(
            function(scope) {
                return scope.$eval(attrs.compile);
            },
            function(value) {
                element.html(value);
                $compile(element.contents())(scope);
            }
        );
    };
}]).service('actionSrv', ['$state', '$stateParams', '$injector', '$uibModal', 'authSrv', 'proceduresSrv', 'participateSrv', 'protocolSrv', 'needConfirmSrv','$filter', 'profileSrv', '$sce', 'participantSrv', 'timeout', 'explanationSrv',
    function ($state, $stateParams, $injector, $uibModal, authSrv, proceduresSrv, participateSrv, protocolSrv, needConfirmSrv, $filter, profileSrv, $sce, participantSrv, timeout, explanationSrv) {
        var service = this,
            /**
             * Поиск заявкок для редактирования которые не были отозваны
             * @param claims
             * @returns {*}
             */
            getActualClaimId = function (claims) {
                var id = null;
                var sortClaims = $filter('orderBy')(claims, 'created');
                angular.forEach(sortClaims, function (claim) {
                    if (claim.state.id !== 'revoked') {
                        id = claim.id;
                    }
                });
                return id;
            },
            deleteProtocol = function (data) {
                protocolSrv.deleteProtocolById(data).then(function (response) {
                    if (response.data.success) {
                        openModal('_dialogInfo.html', {message: 'Протокол был удален!'}).then(function () {
                            $state.go('openProcedure', {
                                procType: data.procType.toLowerCase(),
                                procId: data.procId
                            }, {reload: true})
                        })
                    } else {
                        console.log(response);
                        openModal('_dialogInfo.html', {message: 'Протокол не был удален. Обратитесь в тех.поддержку.'})
                    }
                }, function (reason) {
                    console.log(reason);
                    openModal('_dialogInfo.html', {message: 'Протокол не был удален. Обратитесь в тех.поддержку.'})
                })
            },
            doRetryToEIS = function (data) {
                protocolSrv.retryToEIS(data).then(function (response) {
                    if (response.data.success) {
                        openModal('_dialogInfo.html', {message: 'Протокол отправлен в ЕИС!'}).then(function () {
                            $state.go('openProcedure', {
                                procType: data.procType.toLowerCase(),
                                procId: data.procId
                            }, {reload: true})
                        })
                    } else {
                        console.log(response);
                        openModal('_dialogInfo.html', {message: 'Протокол не был отправлен в ЕИС. Обратитесь в тех.поддержку.'})
                    }
                }, function (reason) {
                    console.log(reason);
                    openModal('_dialogInfo.html', {message: 'Протокол не был удален. Обратитесь в тех.поддержку.'})
                })
            },
            doRetryExplToEIS = function (data) {
                explanationSrv.retryToEIS(data.id).then(function (response) {
                    if (response.data.success) {
                        openModal('_dialogInfo.html', {message: 'Разъяснение отправлено в ЕИС!'}).then(function () {
                            $state.go('openProcedure', {
                                procType: data.procType.toLowerCase(),
                                procId: data.procId,
                                setActivePanel: 'expls'
                            }, {reload: true})
                        })
                    } else {
                        console.log(response);
                        openModal('_dialogInfo.html', {message: 'Разъяснение не было отправлено в ЕИС. Обратитесь в тех.поддержку.'})
                    }
                }, function (reason) {
                    console.log(reason);
                    openModal('_dialogInfo.html', {message: 'Разъяснение не было отправлено в ЕИС. Обратитесь в тех.поддержку.'})
                })
            },
            baseMth = {
                /**
                 * Внести изменение в закупку
                 * @param data
                 */
                createNoticeNewVersion: function (data) {
                    if(data.claimEnds<getServerTimeMsk()){
                        openModal('_dialogInfo.html', {message: 'Внести изменения в закупку возможно только на этапе "Подача заявок"'})
                        return;
                    }
                    needConfirmSrv.call(baseMth.doCreateNoticeNewVersion,[data],'Выполнить действие: Внести изменение в закупку?');
                },
                doCreateNoticeNewVersion:function (data) {
                    if(data.claimEnds<getServerTimeMsk()){
                        openModal('_dialogInfo.html', {message: 'Внести изменения в закупку возможно только на этапе "Подача заявок"'})
                        return;
                    }
                    var procType = data.type.id.toLowerCase(),
                        procId = data.id;
                    proceduresSrv.createNoticeNewVersion(procType, procId).then(function (response) {
                        if (response.data.success) {
                            $state.go('notice', {
                                type: procType,
                                id: response.data.result
                            });
                        }
                    }, function (reason) {
                        console.log(reason);
                        openModal('_dialogInfo.html', {message: 'Внести изменение в закупку не удалось. Обратитесь в тех.поддержку.'})
                    });
                },
                /**
                 * Удалить извещение из вкладки закупки Извещения
                 */
                deleteNotice: function (data) {
                    needConfirmSrv.call(baseMth.doDeleteNotice,[data],'Выполнить действие: Удалить извещение?');
                },
                /**
                 * Удалить извещение из вкладки закупки Извещения
                 */
                deleteExplanation: function (data) {
                    needConfirmSrv.call(baseMth.doDeleteExplanation,[data],'Выполнить действие: Удалить разъяснение?');
                },
                /**
                 * Удалить извещение из вкладки закупки Извещения
                 */
                retryExplToEIS: function (data) {
                    needConfirmSrv.call(baseMth.doRetryExplToEIS,[data],'Вы действительно хотите повторно отправить разъяснение в ЕИС?');
                },
                doDeleteNotice: function (notice) {
                    var nSrv = $injector.get('noticeSrv');
                    nSrv.deleteProc(notice).then(function (response) {
                        if (response.data.success) {
                            openModal('_dialogInfo.html', {message: 'Документ успешно удален!'}).then(function () {
                                $state.go('openProcedure', {
                                    procId: $stateParams.procId,
                                    procType: $stateParams.procType.toLowerCase()
                                }, {reload: true})
                            })
                        } else {
                            console.log(response);
                            openModal('_dialogInfo.html', {message: 'Документ не был удален. Обратитесь в тех.поддержку.'})
                        }
                    });
                },
                doDeleteExplanation: function (explanation) {
                    var eSrv = $injector.get('explanationSrv');
                    eSrv.deleteExplanation(explanation.exp.id).then(function (response) {
                        if (response.data.success) {
                            openModal('_dialogInfo.html', {message: 'Документ успешно удален!'}).then(function () {
                                $state.go('openProcedure', {
                                    procId: $stateParams.procId,
                                    procType: $stateParams.procType.toLowerCase()
                                }, {reload: true})
                            })
                        } else {
                            console.log(response);
                            openModal('_dialogInfo.html', {message: 'Документ не был удален. Обратитесь в тех.поддержку.'})
                        }
                    });
                },
                /**
                 * Создание "разъяснения" на "запрос разъяснения"
                 * @param data - данные для выполения действий
                 */
                createExplanation: function (data) {
                    var procType = data.procedure.procType || data.procedure.type.id,
                        procId = data.procedure.procId || data.procedure.id;
                    $state.go('createAnswerExplanationFromReq', {
                        procType: procType.toLowerCase(),
                        procId: procId,
                        explReqId: data.reqExp.id
                    });
                },
                /**
                 * Создание "разъяснения" на "запрос разъяснения"
                 * @param data - данные для выполения действий
                 */
                createExplanationPersonal: function (data) {
                    var procType = data.procedure.procType || data.procedure.type.id,
                        procId = data.procedure.procId || data.procedure.id;
                    $state.go('createAnswerExplanationFromReq', {
                        procType: procType.toLowerCase(),
                        procId: procId,
                        explReqId: data.reqExp.id,
                        isPersonal:true
                    });
                },
                /**
                 * Создание email "разъяснения" на "запрос разъяснения"
                 * @param data - данные для выполения действий
                 */
                createExplanationEmail: function (data) {
                    var procType = data.procedure.procType || data.procedure.type.id,
                        procId = data.procedure.procId || data.procedure.id;
                    $state.go('createAnswerExplanationEmailFromReq', {
                        procType: procType.toLowerCase(),
                        procId: procId,
                        explReqId: data.reqExp.id
                    });
                },
                /**
                 * Выбрать уже готовое разъяснение
                 * @param data - данные для выполения действий
                 */
                chooseExplanation: function (data) {
                    var procType = data.procedure.procType || data.procedure.type.id,
                        procId = data.procedure.procId || data.procedure.id;
                    $state.go('answerReadyExplanation', {
                        procType: procType.toLowerCase(),
                        procId: procId,
                        explReqId: data.reqExp.id
                    }, {reload: true})
                },
                /**
                 * Проигнорировать "запрос на разъяснение"
                 * @param data - данные для выполения действий
                 */
                ignoreExplanationRequest: function (data) {
                    var expSrv = $injector.get('explanationSrv');
                    expSrv.ignoreExplanation(data.procedure.type.id.toLowerCase(), data.procedure.id, data.reqExp.id).then(function (response) {
                        if (response.data.success) {
                            data.reqExp.state.name = 'Отмечен как обработанный';
                            data.reqExp.state.id = 'ignored';
                            delete data.reqExp.actions;
                            $state.reload();
                        }
                    });

                },
                /**
                 * Создать "запрос на разъяснение"
                 * @param data - данные для выполения действий
                 */
                requestExplanations: function (data) {
                    profileSrv.checkAccredSecured($stateParams.procType, 'true').then(function (checkRes) {
                        if(participantSrv.needUpdateAuth())
                            authSrv.updateOrgAccreditation(checkRes.data.result);
                        if(checkRes.data.result){
                            authSrv.checkedAuth().then(function (auth) {
                                var param = {
                                    procType: data.type.id.toLowerCase(),
                                    procId: data.id
                                };
                                if (auth.key) {
                                    $state.go('createReqExplanation', param);
                                } else {
                                    param.url = 'createReqExplanation';
                                    proceduresSrv.openLogin(param)
                                }
                            })
                        } else {
                            openModal('_dialogInfo.html', {message: 'Отправка запроса невозможна! Отсутствует или закончилась аккредитация у организации. <a ui-sref="profile({activeProfileTab:\'accreditation\'})" ng-click="closeModal()">Перейти</a>'})
                        }
                    });
                },
                /**
                 * Создать "запрос участнику"
                 * @param data - данные для выполения действий
                 */
                requestExplanationsOrg: function (data) {
                    authSrv.checkedAuth().then(function (auth) {
                        var param = {
                            procType: data.type.id.toLowerCase(),
                            procId: data.id
                        };
                        if (auth.key) {
                            $state.go('createReqExplanationOrg', param);
                        } else {
                            param.url = 'createReqExplanationOrg';
                            proceduresSrv.openLogin(param)
                        }
                    })
                },
                createExplanationOrg: function (data) {
                    var procType = data.procedure.procType || data.procedure.type.id,
                        procId = data.procedure.procId || data.procedure.id;
                    $state.go('createAnswerExplanationOrgFromReq', {
                        procType: procType.toLowerCase(),
                        procId: procId,
                        explReqId: data.reqExp.id
                    });
                },
                /**
                 * Подать заявку
                 * @param data - данные для выполения действий
                 */
                createClaimOnLot: function (data) {
                    authSrv.checkedAuth().then(function (auth) {
                        var param = {
                            procType: data.procedure.type.id.toLowerCase(),
                            procId: data.procedure.id,
                            lotId: data.lot.id
                        };
                        if (auth.key) {
                            $state.go('participate', param)
                        } else {
                            param.url = 'participate';
                            proceduresSrv.openLogin(param)
                        }

                    })
                },
                /**
                 * Редактировать заявку
                 * @param data - данные для выполения действий
                 */
                editClaimOnLot: function (data) {
                    authSrv.checkedAuth().then(function (auth) {
                        if (auth.key) {
                            $state.go('openParticipate', {
                                procType: data.procedure.type.id.toLowerCase(),
                                procId: data.procedure.id,
                                lotId: data.lot.id,
                                claimId: getActualClaimId(data.lot.claims)
                            })
                        } else {
                            proceduresSrv.openLogin(data)
                        }

                    })
                },
                sendClaimOnLot:function (data) {
                    needConfirmSrv.call(baseMth.doSendClaimOnLot,[data],'Выполнить действие: Отправить заявку на участие?');
                },
                doSendClaimOnLot: async function (data) {
                    let accredCheckResult;
                    if(data.fullDateAccredCheck)
                        accredCheckResult = data.fullDateAccredCheck;
                    else {
                        const checkResult = await profileSrv.checkAccredSecured($stateParams.procType);
                        authSrv.updateOrgAccreditation(checkResult.data.result);
                        accredCheckResult = checkResult.data.result;
                    }
                    if(!accredCheckResult) {
                        openModal('_dialogInfo.html', {message: 'Отправка заявки невозможна! Отсутствует или закончилась аккредитация у организации. <a ui-sref="profile({activeProfileTab:\'accreditation\'})" ng-click="closeModal()">Перейти</a>'})
                        return;
                    }

                    const auth = await authSrv.checkedAuth();
                    if(!auth.key) return;
                    try{
                        const response = await participateSrv.sendSignFromTable({
                                    procType: data.procedure.type.id.toLowerCase(),
                                    procId: data.procedure.id,
                                    lotId: data.lot.id,
                                    claimId: getActualClaimId(data.lot.claims)
                                }, auth.certId);
                        if (response.data.success) {
                            openModal('_dialogInfo.html', {message: 'Документ отправлен'}).then(function () {
                                $state.go('openProcedure', {
                                    procType: data.procedure.type.id.toLowerCase(),
                                    procId: data.procedure.id
                                }, {reload: true})
                            })
                        } else {
                            if (response.data.errorMessage) {
                                openModal('_dialogInfo.html', {message: response.data.errorMessage})
                            } else {
                                openModal('_dialogInfo.html', {message: response.data.message ?
                                        (['109', '136','137'].includes(response.data.errorCode) ? response.data.message : 'Документ не был опубликован. Обратитесь в тех.поддержку.') :
                                        'Документ не был отправлен. Обратитесь в тех.поддержку.'});
                            }
                        }
                    } catch (reason){
                        openModal('_dialogInfo.html', {message: reason})
                    }
                },
                revokeClaimOnLot:function (data) {
                    needConfirmSrv.call(baseMth.doRevokeClaimOnLot,[data],'Выполнить действие: Отозвать заявку на участие?');
                },
                doRevokeClaimOnLot: function (data) {
                    authSrv.checkedAuth().then(function (auth) {
                        if (auth.key) {
                            participateSrv.sendRevoke({
                                procType: data.procedure.type.id.toLowerCase(),
                                procId: data.procedure.id,
                                claimId: getActualClaimId(data.lot.claims)
                            }, auth.certId).then(function (response) {
                                if (response.data.success) {
                                    openModal('_dialogInfo.html', {message: 'Документ отозван!'}).then(function () {
                                        $state.go('openProcedure', {
                                            procType: data.procedure.type.id.toLowerCase(),
                                            procId: data.procedure.id
                                        }, {reload: true})
                                    })
                                } else {
                                    console.log(response);
                                    var txt = ['109', '136', '137', '138', '429'].includes(response.data.errorCode) ? response.data.message :
                                        (response.data.errorMessage && response.data.errorMessage.length>0  ? response.data.errorMessage : 'Документ не был отозван. Обратитесь в тех.поддержку.');
                                    openModal('_dialogInfo.html', {message: txt})
                                }
                            })
                        } else {

                        }

                    })
                },
                deleteClaimOnLot:function (data) {
                    needConfirmSrv.call(baseMth.doDeleteClaimOnLot,[data],'Выполнить действие: Удалить заявку на участие?');
                },
                doDeleteClaimOnLot: function (data) {
                    authSrv.checkedAuth().then(function (auth) {
                        if (auth.key) {
                            var stateParams = {
                                procType: data.procedure.type.id.toLowerCase(),
                                procId: data.procedure.id,
                                lotId: data.lot.id,
                                claimId: getActualClaimId(data.lot.claims)
                            };
                            participateSrv.deleteClaim(stateParams).then(function (response) {
                                if (response.data.success) {
                                    openModal('_dialogInfo.html', {message: 'Документ успешно удален!'}).then(function () {
                                        $state.go('openProcedure', {
                                            procType: data.procedure.type.id.toLowerCase(),
                                            procId: data.procedure.id
                                        }, {reload: true})
                                    })
                                } else {
                                    console.log(response);
                                    openModal('_dialogInfo.html', {message: response.data.message || response.data.errorMessage})
                                }
                            }, function (reason) {
                                console.log(reason);
                                openModal('_dialogInfo.html', {message: 'Документ не был удален. Обратитесь в тех.поддержку.'})
                            })
                        } else {

                        }

                    })
                },
                /**
                 * Создать протокол вскрытия
                 */
                createOpeningProtocol: function (data) {
                    var params = {
                        procId: data.id,
                        procType: data.type.id.toLowerCase()
                    };
                    protocolSrv.getDataForCreateOpeningProtocol(params).then(function (createData) {
                        if (createData.data.success) {
                            var result = createData.data.result;
                            protocolSrv.saveProtocolFn($stateParams, result, result.document.type.code).then(function (response) {
                                if (response.data.success) {
                                    params.protocolId = result.id;
                                    $state.go('openProtocol', params)
                                }
                            }, function (reason) {
                                console.log(reason);
                                openModal('_dialogInfo.html', {message: "Ошибка создания протокола"})
                            });
                        } else {
                            openModal('_dialogInfo.html', {message: createData.data.errorMessage})
                        }
                    });
                },
                createRebiddingOpeningProtocolOnLot: function (data) {
                    var params = {
                            procType: data.procedure.type.id.toLowerCase(),
                            procId: data.procedure.id,
                            lotId: data.lot.id
                        };
                    protocolSrv.getDataForCreateRebiddingOpeningProtocol(params).then(function (createData) {
                        if (createData.data.success) {
                            var result = createData.data.result;
                            protocolSrv.saveProtocolFn(params/*$stateParams*/, result, result.document.type.code).then(function (response) {
                                if (response.data.success) {
                                    params.protocolId = result.id;
                                    $state.go('openProtocol', params)
                                }
                            }, function (reason) {
                                console.log(reason);
                                openModal('_dialogInfo.html', {message: "Ошибка создания протокола"})
                            });
                        } else {
                            openModal('_dialogInfo.html', {message: createData.data.errorMessage})
                        }
                    });
                },
                /**
                 * Создать протокол первых частей
                 */
                createRatingFirstClaimPartsProtocol: function (data) {
                    var params = {
                        procId: data.id,
                        procType: data.type.id.toLowerCase()
                    };
                    protocolSrv.getDataForCreateRatingFirstClaimPartsProtocol({
                        procId: data.id,
                        procType: data.type.id.toLowerCase()
                    }).then(function (createData) {
                        if (createData.data.success) {
                            var result = createData.data.result;
                            protocolSrv.saveProtocolFn($stateParams, result, result.document.type.code).then(function (response) {
                                if (response.data.success) {
                                    params.protocolId = result.id;
                                    params.fromCreate = true;
                                    $state.go('openProtocol', params)
                                }
                            }, function (reason) {
                                console.log(reason);
                                openModal('_dialogInfo.html', {message: "Ошибка создания протокола"})
                            });
                        } else {
                            openModal('_dialogInfo.html', {message: createData.data.errorMessage})
                        }
                    })
                },
                createRebiddingRatingFirstClaimPartsProtocol: function (data) {
                    var params = {
                        procId: data.id,
                        procType: data.type.id.toLowerCase()
                    };
                    protocolSrv.getDataForCreateRebiddingRatingFirstClaimPartsProtocol({
                        procId: data.id,
                        procType: data.type.id.toLowerCase()
                    }).then(function (createData) {
                        if (createData.data.success) {
                            var result = createData.data.result;
                            protocolSrv.saveProtocolFn($stateParams, result, result.document.type.code).then(function (response) {
                                if (response.data.success) {
                                    params.protocolId = result.id;
                                    $state.go('openProtocol', params)
                                }
                            }, function (reason) {
                                console.log(reason);
                                openModal('_dialogInfo.html', {message: "Ошибка создания протокола"})
                            });
                        } else {
                            openModal('_dialogInfo.html', {message: createData.data.errorMessage})
                        }
                    })
                },
                createRebiddingRatingSecondClaimPartsProtocol: function (data) {
                    var params = {
                        procId: data.id,
                        procType: data.type.id.toLowerCase()
                    };
                    protocolSrv.getDataForCreateRebiddingRatingSecondClaimPartsProtocol({
                        procId: data.id,
                        procType: data.type.id.toLowerCase()
                    }).then(function (createData) {
                        if (createData.data.success) {
                            var result = createData.data.result;
                            protocolSrv.saveProtocolFn($stateParams, result, result.document.type.code).then(function (response) {
                                if (response.data.success) {
                                    params.protocolId = result.id;
                                    $state.go('openProtocol', params)
                                }
                            }, function (reason) {
                                console.log(reason);
                                openModal('_dialogInfo.html', {message: "Ошибка создания протокола"})
                            });
                        } else {
                            openModal('_dialogInfo.html', {message: createData.data.errorMessage})
                        }
                    })
                },
                /**
                 * Создать протокол вторых частей
                 */
                createRatingSecondClaimPartsProtocol: function (data) {
                    var params = {
                        procId: data.id,
                        procType: data.type.id.toLowerCase()
                    };
                    protocolSrv.getDataForCreateRatingSecondClaimPartsProtocol(params).then(function (createData) {
                        if (createData.data.success) {
                            var result = createData.data.result;
                            protocolSrv.saveProtocolFn($stateParams, result, result.document.type.code).then(function (response) {
                                if (response.data.success) {
                                    params.protocolId = result.id;
                                    $state.go('openProtocol', params)
                                }
                            }, function (reason) {
                                console.log(reason);
                                openModal('_dialogInfo.html', {message: "Ошибка создания протокола"})
                            });
                        } else {
                            openModal('_dialogInfo.html', {message: createData.data.errorMessage})
                        }
                    });
                },
                /**
                 * Создать протокол подведения итогов
                 */
                createSummarizingProtocol: function (data) {
                    var params = {
                        procId: data.id,
                        procType: data.type.id.toLowerCase()
                    };
                    protocolSrv.getDataForCreateSummarizingProtocol(params).then(function (createData) {
                        if (createData.data.success) {
                            var result = createData.data.result;
                            protocolSrv.saveProtocolFn($stateParams, result, result.document.type.code).then(function (response) {
                                if (response.data.success) {
                                    params.protocolId = result.id;
                                    $state.go('openProtocol', params)
                                }
                            }, function (reason) {
                                console.log(reason);
                                openModal('_dialogInfo.html', {message: "Ошибка создания протокола"})
                            });
                        } else {
                            openModal('_dialogInfo.html', {message: createData.data.errorMessage})
                        }
                    });
                },
                /**
                 * Создать протокол изменения условий договора
                 */
                createChangeContractAdditionalProtocol: function (data) {
                    var params = {
                        procId: data.id,
                        procType: data.type.id.toLowerCase()
                    };
                    protocolSrv.getDataForCreateChangeContractAdditionalProtocol(params).then(function (createData) {
                        if (createData.data.success) {
                            var result = createData.data.result;
                            protocolSrv.saveProtocolFn($stateParams, result, result.document.type.code).then(function (response) {
                                if (response.data.success) {
                                    params.protocolId = result.id;
                                    $state.go('openProtocol', params)
                                }
                            }, function (reason) {
                                console.log(reason);
                                openModal('_dialogInfo.html', {message: "Ошибка создания протокола"})
                            });
                        } else {
                            openModal('_dialogInfo.html', {message: createData.data.errorMessage})
                        }
                    });
                },
                /**
                 * Редактирование протокола или открытие
                 * @param data
                 */
                editProtocolOnProcedure: function (data) {
                    var id = undefined;
                    angular.forEach(data.protocols, function (protocol) {
                        if (protocol.state.id === 'created') {
                            id = protocol.id
                        }
                    });
                    $state.go('openProtocol', {
                        procId: data.id,
                        procType: data.type.id.toLowerCase(),
                        protocolId: id
                    })
                },
                /**
                 * Редактирование протокола вскрытия или открытие
                 * @param data
                 */
                editOpeningProtocolOnProcedure: function (data) {
                    var id = undefined;
                    angular.forEach(data.protocols, function (protocol) {
                        if (protocol.state.id === 'created' && (protocol.type.id === 'PARTS' || protocol.type.id === 'OPEN')) {
                            id = protocol.id
                        }
                    });
                    $state.go('openProtocol', {
                        procId: data.id,
                        procType: data.type.id.toLowerCase(),
                        protocolId: id
                    })
                },
               /**
                 * Редактирование протокола запроса предложений или открытие
                 * @param data
                 */
                editRatingFirstClaimProtocolOnProcedure: function (data) {
                    var id = undefined;
                    angular.forEach(data.protocols, function (protocol) {
                        if (protocol.state.id === 'created' && (protocol.type.id === 'PART1' || protocol.type.id === 'PARTS') ) {
                            id = protocol.id
                        }
                    });
                    $state.go('openProtocol', {
                        procId: data.id,
                        procType: data.type.id.toLowerCase(),
                        protocolId: id
                    })
                },
                editRebiddingRatingFirstClaimProtocolOnProcedure: function (data) {
                    var id = undefined;
                    angular.forEach(data.protocols, function (protocol) {
                        if (protocol.state.id === 'created' && protocol.type.id === 'REBIDDING_PART1' ) {
                            id = protocol.id
                        }
                    });
                    $state.go('openProtocol', {
                        procId: data.id,
                        procType: data.type.id.toLowerCase(),
                        protocolId: id
                    })
                },
                editRebiddingRatingSecondClaimProtocolOnProcedure: function (data) {
                    var id = undefined;
                    angular.forEach(data.protocols, function (protocol) {
                        if (protocol.state.id === 'created' && protocol.type.id === 'REBIDDING_PART1' ) {
                            id = protocol.id
                        }
                    });
                    $state.go('openProtocol', {
                        procId: data.id,
                        procType: data.type.id.toLowerCase(),
                        protocolId: id
                    })
                },
                /**
                 * Редактирование протокола подведения итогов или открытие
                 * @param data
                 */
                editSummarizingProtocolOnProcedure: function (data) {
                    var id = undefined;
                    angular.forEach(data.protocols, function (protocol) {
                        if (protocol.state.id === 'created' && protocol.type.id === 'REZ' ) {
                            id = protocol.id
                        }
                    });
                    $state.go('openProtocol', {
                        procId: data.id,
                        procType: data.type.id.toLowerCase(),
                        protocolId: id
                    })
                },
                /**
                 * Редактирование протокола подведения итогов или открытие
                 * @param data
                 */
                editChangeContractAdditionalProtocol: function (data) {
                    var id = undefined;
                    angular.forEach(data.protocols, function (protocol) {
                        if (protocol.state.id === 'created' && protocol.type.id === 'CHANGE_CONTRACT_ADDITIONAL' ) {
                            id = protocol.id
                        }
                    });
                    $state.go('openProtocol', {
                        procId: data.id,
                        procType: data.type.id.toLowerCase(),
                        protocolId: id
                    })
                },
                editRebiddingOpeningProtocolOnLot: function (data) {
                    var id = undefined;
                    angular.forEach(data.procedure.protocols, function (protocol) {
                        if (protocol.state.id === 'created' && protocol.type.id === 'REBIDDING_OPEN' && data.lot.ordinalNumber==protocol.lotNumber) {
                            id = protocol.id
                        }
                    });
                    $state.go('openProtocol', {
                        procId: data.procedure.id,
                        procType: data.procedure.type.id.toLowerCase(),
                        protocolId: id
                    })
                },
                /**
                 * Создать Уведомление о переторжке
                 */
                planRebidding: function (data) {
                    var params = {
                        procType: data.procedure.type.id.toLowerCase(),
                        procId: data.procedure.id,
                        lotId: data.lot.id
                    };
                    $state.go('createRebiddingNotice', params);

                },
                /**
                 * Редактирование протокола или открытие
                 * @param data
                 */
                editProtocol: function (data) {
                    $state.go(['TRANSFER','COMPLAINT','CUSTOM'].includes(data.type.id) && data.state.id==='created' ? 'notificationProtocolView' :
                        (['ADDITIONAL'].includes(data.type.id) && data.state.id==='created' ? 'additionalProtocolView' : 'openProtocol'), {
                        procId: $stateParams.procId,
                        procType: $stateParams.procType.toLowerCase(),
                        protocolId: data.id
                    })
                },
                /**
                 * Удаление протокола
                 * @param data
                 */
                deleteProtocol: function (data) {
                    needConfirmSrv.call(deleteProtocol, [{
                        procId: $stateParams.procId,
                        procType: $stateParams.procType.toLowerCase(),
                        protocolId: data.id
                    }], 'Вы действительно хотите удалить протокол?');
                },
                /**
                 * опубликовать протокол
                 */
                publishProtocolOnProcedure: function (data) {
                    authSrv.checkedAuth().then(function (auth) {
                        var id = undefined;
                        angular.forEach(data.protocols, function (protocol) {
                            if (protocol.state.id === 'created') {
                                id = protocol.id
                            }
                        });
                        protocolSrv.signAndSendFromTable({
                            procId: $stateParams.procId,
                            procType: $stateParams.procType.toLowerCase(),
                            protocolId: id
                        }, auth.certId).then(function (response) {
                            if (response.data.success) {
                                openModal('_dialogInfo.html', {message: 'Документ опубликован!'}).then(function () {
                                    $state.go('openProcedure', {
                                        procType: $stateParams.procType.toLowerCase(),
                                        procId: $stateParams.procId
                                    }, {reload: true})
                                })
                            } else {
                                console.log(response);
                                if (response.data && response.data.errorMessage) {
                                    var txt = 'Документ не был опубликован. Обратитесь в тех.поддержку.';
                                    if (response.data.errorMessage.indexOf('Протокол без вложений') !== -1) {
                                      txt = "Протокол не опубликован. Для публикации добавьте минимум одно вложение.";
                                    }
                                    openModal('_dialogInfo.html', {message: txt})
                                } else if (response.data && response.data.message ){
                                    txt = (response.data.errorCode === '109' || response.data.errorCode === '136' || response.data.errorCode === '137') ? response.data.message : 'Документ не был опубликован. Обратитесь в тех.поддержку.';
                                    openModal('_dialogInfo.html', {message: txt});

                                } else {
                                    openModal('_dialogInfo.html', {message: 'Документ не был опубликован. Обратитесь в тех.поддержку.'})
                                }

                            }
                        }, function (reason) {
                            console.log(reason);
                            openModal('_dialogInfo.html', {message: 'Документ не был опубликован. Обратитесь в тех.поддержку.'})
                        })
                    })
                },
                /**
                 * опубликовать протокол Opening
                 */
                publishOpeningProtocolOnProcedure: function (data) {
                    authSrv.checkedAuth().then(function (auth) {
                        var id = undefined;
                        angular.forEach(data.protocols, function (protocol) {
                            if (protocol.state.id === 'created' && protocol.type.id === 'PARTS') {
                                id = protocol.id
                            }
                        });
                        protocolSrv.signAndSendFromTable({
                            procId: $stateParams.procId,
                            procType: $stateParams.procType.toLowerCase(),
                            protocolId: id
                        }, auth.certId).then(function (response) {
                            if (response.data.success) {
                                openModal('_dialogInfo.html', {message: 'Документ опубликован!'}).then(function () {
                                    $state.go('openProcedure', {
                                        procType: $stateParams.procType.toLowerCase(),
                                        procId: $stateParams.procId
                                    }, {reload: true})
                                })
                            } else {
                                console.log(response);
                                if (response.data && response.data.errorMessage) {
                                    var txt = 'Документ не был опубликован. Обратитесь в тех.поддержку.';
                                    if (response.data.errorMessage.indexOf('Протокол без вложений') !== -1) {
                                      txt = "Протокол не опубликован. Для публикации добавьте минимум одно вложение.";
                                    }
                                    openModal('_dialogInfo.html', {message: txt})
                                } else if (response.data && response.data.message ){
                                    txt = (response.data.errorCode === '109' || response.data.errorCode === '136' || response.data.errorCode === '137') ? response.data.message : 'Документ не был опубликован. Обратитесь в тех.поддержку.';
                                    openModal('_dialogInfo.html', {message: txt});

                                } else {
                                    openModal('_dialogInfo.html', {message: 'Документ не был опубликован. Обратитесь в тех.поддержку.'})
                                }

                            }
                        }, function (reason) {
                            console.log(reason);
                            openModal('_dialogInfo.html', {message: 'Документ не был опубликован. Обратитесь в тех.поддержку.'})
                        })
                    })
                },
                /**
                 * опубликовать протокол RatingFirstClaim
                 */
                publishRatingFirstClaimProtocolOnProcedure: function (data) {
                    authSrv.checkedAuth().then(function (auth) {
                        var id = undefined;
                        angular.forEach(data.protocols, function (protocol) {
                            if (protocol.state.id === 'created' && (protocol.type.id === 'PART1' || protocol.type.id === 'PARTS')) {
                                id = protocol.id
                            }
                        });
                        protocolSrv.signAndSendFromTable({
                            procId: $stateParams.procId,
                            procType: $stateParams.procType.toLowerCase(),
                            protocolId: id
                        }, auth.certId).then(function (response) {
                            if (response.data.success) {
                                openModal('_dialogInfo.html', {message: 'Документ опубликован!'}).then(function () {
                                    $state.go('openProcedure', {
                                        procType: $stateParams.procType.toLowerCase(),
                                        procId: $stateParams.procId
                                    }, {reload: true})
                                })
                            } else {
                                console.log(response);
                                if (response.data && response.data.errorMessage) {
                                    var txt = 'Документ не был опубликован. Обратитесь в тех.поддержку.';
                                    if (response.data.errorMessage.indexOf('Протокол без вложений') !== -1) {
                                      txt = "Протокол не опубликован. Для публикации добавьте минимум одно вложение.";
                                    }
                                    openModal('_dialogInfo.html', {message: txt})
                                } else if (response.data && response.data.message ){
                                    txt = (response.data.errorCode === '109' || response.data.errorCode === '136' || response.data.errorCode === '137') ? response.data.message : 'Документ не был опубликован. Обратитесь в тех.поддержку.';
                                    openModal('_dialogInfo.html', {message: txt});

                                } else {
                                    openModal('_dialogInfo.html', {message: 'Документ не был опубликован. Обратитесь в тех.поддержку.'})
                                }

                            }
                        }, function (reason) {
                            console.log(reason);
                            openModal('_dialogInfo.html', {message: 'Документ не был опубликован. Обратитесь в тех.поддержку.'})
                        })
                    })
                },
                publishRebiddingRatingFirstClaimProtocolOnProcedure: function (data) {
                    authSrv.checkedAuth().then(function (auth) {
                        var id = undefined;
                        angular.forEach(data.protocols, function (protocol) {
                            if (protocol.state.id === 'created' && protocol.type.id === 'REBIDDING_PART1') {
                                id = protocol.id
                            }
                        });
                        protocolSrv.signAndSendFromTable({
                            procId: $stateParams.procId,
                            procType: $stateParams.procType.toLowerCase(),
                            protocolId: id
                        }, auth.certId).then(function (response) {
                            if (response.data.success) {
                                openModal('_dialogInfo.html', {message: 'Документ опубликован!'}).then(function () {
                                    $state.go('openProcedure', {
                                        procType: $stateParams.procType.toLowerCase(),
                                        procId: $stateParams.procId
                                    }, {reload: true})
                                })
                            } else {
                                console.log(response);
                                if (response.data && response.data.errorMessage) {
                                    var txt = 'Документ не был опубликован. Обратитесь в тех.поддержку.';
                                    if (response.data.errorMessage.indexOf('Протокол без вложений') !== -1) {
                                      txt = "Протокол не опубликован. Для публикации добавьте минимум одно вложение.";
                                    }
                                    openModal('_dialogInfo.html', {message: txt})
                                } else if (response.data && response.data.message ){
                                    txt = (response.data.errorCode === '109' || response.data.errorCode === '136' || response.data.errorCode === '137') ? response.data.message : 'Документ не был опубликован. Обратитесь в тех.поддержку.';
                                    openModal('_dialogInfo.html', {message: txt});

                                }  else {
                                    openModal('_dialogInfo.html', {message: 'Документ не был опубликован. Обратитесь в тех.поддержку.'})
                                }

                            }
                        }, function (reason) {
                            console.log(reason);
                            openModal('_dialogInfo.html', {message: 'Документ не был опубликован. Обратитесь в тех.поддержку.'})
                        })
                    })
                },
                publishRebiddingRatingSecondClaimProtocolOnProcedure: function (data) {
                    authSrv.checkedAuth().then(function (auth) {
                        var id = undefined;
                        angular.forEach(data.protocols, function (protocol) {
                            if (protocol.state.id === 'created' && protocol.type.id === 'REBIDDING_PART1') {
                                id = protocol.id
                            }
                        });
                        protocolSrv.signAndSendFromTable({
                            procId: $stateParams.procId,
                            procType: $stateParams.procType.toLowerCase(),
                            protocolId: id
                        }, auth.certId).then(function (response) {
                            if (response.data.success) {
                                openModal('_dialogInfo.html', {message: 'Документ опубликован!'}).then(function () {
                                    $state.go('openProcedure', {
                                        procType: $stateParams.procType.toLowerCase(),
                                        procId: $stateParams.procId
                                    }, {reload: true})
                                })
                            } else {
                                console.log(response);
                                if (response.data && response.data.errorMessage) {
                                    var txt = 'Документ не был опубликован. Обратитесь в тех.поддержку.';
                                    if (response.data.errorMessage.indexOf('Протокол без вложений') !== -1) {
                                      txt = "Протокол не опубликован. Для публикации добавьте минимум одно вложение.";
                                    }
                                    openModal('_dialogInfo.html', {message: txt})
                                } else if (response.data && response.data.message ){
                                    txt = (response.data.errorCode === '109' || response.data.errorCode === '136' || response.data.errorCode === '137') ? response.data.message : 'Документ не был опубликован. Обратитесь в тех.поддержку.';
                                    openModal('_dialogInfo.html', {message: txt});

                                }  else {
                                    openModal('_dialogInfo.html', {message: 'Документ не был опубликован. Обратитесь в тех.поддержку.'})
                                }

                            }
                        }, function (reason) {
                            console.log(reason);
                            openModal('_dialogInfo.html', {message: 'Документ не был опубликован. Обратитесь в тех.поддержку.'})
                        })
                    })
                },
                /**
                 * опубликовать протокол Summarizing
                 */
                publishSummarizingProtocolOnProcedure: function (data) {
                    authSrv.checkedAuth().then(function (auth) {
                        var id = undefined;
                        angular.forEach(data.protocols, function (protocol) {
                            if (protocol.state.id === 'created' && protocol.type.id=== 'REZ') {
                                id = protocol.id
                            }
                        });
                        protocolSrv.signAndSendFromTable({
                            procId: $stateParams.procId,
                            procType: $stateParams.procType.toLowerCase(),
                            protocolId: id
                        }, auth.certId).then(function (response) {
                            if (response.data.success) {
                                openModal('_dialogInfo.html', {message: 'Документ опубликован!'}).then(function () {
                                    $state.go('openProcedure', {
                                        procType: $stateParams.procType.toLowerCase(),
                                        procId: $stateParams.procId
                                    }, {reload: true})
                                })
                            } else {
                                console.log(response);
                                if (response.data && response.data.errorMessage) {
                                    var txt = 'Документ не был опубликован. Обратитесь в тех.поддержку.';
                                    if (response.data.errorMessage.indexOf('Протокол без вложений') !== -1) {
                                      txt = "Протокол не опубликован. Для публикации добавьте минимум одно вложение.";
                                    }
                                    openModal('_dialogInfo.html', {message: txt})
                                }else if (response.data && response.data.message ){
                                    txt = (response.data.errorCode === '109' || response.data.errorCode === '136' || response.data.errorCode === '137') ? response.data.message : 'Документ не был опубликован. Обратитесь в тех.поддержку.';
                                    openModal('_dialogInfo.html', {message: txt});

                                }  else {
                                    openModal('_dialogInfo.html', {message: 'Документ не был опубликован. Обратитесь в тех.поддержку.'})
                                }

                            }
                        }, function (reason) {
                            console.log(reason);
                            openModal('_dialogInfo.html', {message: 'Документ не был опубликован. Обратитесь в тех.поддержку.'})
                        })
                    })
                },
                publishRebiddingOpeningProtocolOnLot: function (data) {
                    authSrv.checkedAuth().then(function (auth) {
                        var id = undefined;
                        angular.forEach(data.procedure.protocols, function (protocol) {
                            if (protocol.state.id === 'created' && protocol.type.id=== 'REBIDDING_OPEN' && data.lot.ordinalNumber==protocol.lotNumber) {
                                id = protocol.id
                            }
                        });
                        protocolSrv.signAndSendFromTable({
                            procId: $stateParams.procId,
                            procType: $stateParams.procType.toLowerCase(),
                            protocolId: id
                        }, auth.certId).then(function (response) {
                            if (response.data.success) {
                                openModal('_dialogInfo.html', {message: 'Документ опубликован!'}).then(function () {
                                    $state.go('openProcedure', {
                                        procType: $stateParams.procType.toLowerCase(),
                                        procId: $stateParams.procId
                                    }, {reload: true})
                                })
                            } else {
                                console.log(response);
                                if (response.data && response.data.errorMessage) {
                                    var txt = 'Документ не был опубликован. Обратитесь в тех.поддержку.';
                                    if (response.data.errorMessage.indexOf('Протокол без вложений') !== -1) {
                                      txt = "Протокол не опубликован. Для публикации добавьте минимум одно вложение.";
                                    }
                                    openModal('_dialogInfo.html', {message: txt})
                                } else if(response.data && response.data.message) {
                                    txt = (response.data.errorCode === '109' || response.data.errorCode === '136' || response.data.errorCode === '137') ? response.data.message : 'Документ не был опубликован. Обратитесь в тех.поддержку.';
                                    openModal('_dialogInfo.html', {message: txt})
                                }else {
                                    openModal('_dialogInfo.html', {message: 'Документ не был опубликован. Обратитесь в тех.поддержку.'})
                                }

                            }
                        }, function (reason) {
                            console.log(reason);
                            openModal('_dialogInfo.html', {message: 'Документ не был опубликован. Обратитесь в тех.поддержку.'})
                        })
                    })
                },
                /**
                 * опубликовать протокол
                 */
                publishProtocol: function (data) {
                    authSrv.checkedAuth().then(function (auth) {
                        protocolSrv.signAndSendFromTable({
                            procId: $stateParams.procId,
                            procType: $stateParams.procType.toLowerCase(),
                            protocolId: data.id
                        }, auth.certId).then(function (response) {
                            if (response.data.success) {
                                openModal('_dialogInfo.html', {message: 'Документ опубликован!'}).then(function () {
                                    $state.go('openProcedure', {
                                        procType: $stateParams.procType.toLowerCase(),
                                        procId: $stateParams.procId
                                    }, {reload: true})
                                })
                            } else {
                                console.log(response);
                                if (response.data && response.data.errorMessage) {
                                    var txt = response.data.errorMessage.length>0 ? response.data.errorMessage : 'Документ не был опубликован. Обратитесь в тех.поддержку.';
                                    if (response.data.errorMessage.indexOf('Протокол без вложений') !== -1) {
                                        txt = "Протокол не опубликован. Для публикации добавьте минимум одно вложение.";
                                    }
                                    openModal('_dialogInfo.html', {message: txt})
                                }else if (response.data && response.data.message ){
                                    txt = (response.data.errorCode === '109' || response.data.errorCode === '136' || response.data.errorCode === '137') ? response.data.message : 'Документ не был опубликован. Обратитесь в тех.поддержку.';
                                    openModal('_dialogInfo.html', {message: txt});

                                }  else {
                                    openModal('_dialogInfo.html', {message: 'Документ не был опубликован. Обратитесь в тех.поддержку.'})
                                }
                            }
                        }, function (reason) {
                            console.log(reason);
                            openModal('_dialogInfo.html', {message: 'Документ не был опубликован. Обратитесь в тех.поддержку.'})
                        })
                    })
                },
                /**
                 * изменение опубликованного протокола
                 */
                editPublishedProtocol: function (data) {
                    var params = {
                        procId: $stateParams.procId,
                        procType: $stateParams.procType.toLowerCase(),
                        protocolId: data.id
                    };
                    protocolSrv.getDataForEditPublishedProtocol(params).then(function (createData) {
                        if (createData.data.success) {
                            var result = createData.data.result;
                            protocolSrv.saveProtocolPublicFn(params, result).then(function (response) {
                                if (response.data.success) {
                                    params.protocolId = result.id;
                                    $state.go('openProtocol', params)
                                } else {
                                    openModal('_dialogInfo.html', {message: response.data.errorMessage});
                                }
                            }, function (reason) {
                                console.log(reason);
                                openModal('_dialogInfo.html', {message: "Ошибка создания протокола"})
                            });
                        } else {
                            openModal('_dialogInfo.html', {message: createData.data.errorMessage})
                        }
                    });
                },
                /**
                 * открытие формы "Уведомление об отказе"
                 */
                procedureReject: function(procedure) {
                    var params = {
                        procId: procedure.id,
                        procType: procedure.type.id.toLowerCase()
                    };
                    $state.go('informationAboutRejectProcedure', params, {location: 'replace'})
                },
                /**
                 * открытие формы "Сведения об отмене лотов закупки"
                 */
                informationAboutCancelLots: function(procedure) {
                    var params = {
                        procId: procedure.id,
                        procType: procedure.type.id.toLowerCase()
                    };
                    $state.go('informationAboutCancelLots', params, {location: 'replace'})
                },
                /**
                 * открытие формы "Перейти к заключению договора в электронной форме"
                 */
                initSendToParticipant: function(data) {
                    if(data.contract.contractId){
                        $state.go('contract', {id: data.contract.contractId})
                    } else {
                        $state.go('contract', {id: data.contract.lotId, contract: data.contract, procedure: data.procedure})
                    }
                },
                /**
                 * открытие формы "Зафиксировать заключение договора в бумажной форме"
                 */
                finishedPaper: function(data) {
                    if(data.contract.contractId){
                        $state.go('contractPaper', {id: data.contract.contractId})
                    } else {
                        $state.go('contractPaper', {id: data.contract.lotId, contract: data.contract, procedure: data.procedure})
                    }
                },
                /**
                 * открытие формы "Зафиксировать отказ от заключения договора"
                 */
                finishedReject: function(data) {
                    if(data.contract.contractId){
                        $state.go('contractReject', {id: data.contract.contractId})
                    } else {
                        $state.go('contractReject', {id: data.contract.lotId, contract: data.contract, procedure: data.procedure})
                    }
                },
                /**
                 * открытие формы договора
                 */
                contractOpen: function(data) {
                    if(data.contract.contractId){
                    	var state = 'contract';
                    	if(data.contract.contractState && data.contract.contractState.id){
                    		if(data.contract.contractState.id === 'finishedPaper'){
                    			state = 'contractPaper';
                    		}else if(data.contract.contractState.id.indexOf('finishedReject') >= 0){
                    			state = 'contractReject';
                    		}
                    	}
                        $state.go(state, {id: data.contract.contractId})
                    } else {
                        $state.go('contract', {id: data.contract.lotId, contract: data.contract, procedure: data.procedure})
                    }
                },
                /**
                 * открытие формы "Публикация дополнительного протокола"
                 */
                additionalProtocol: function(procedure) {
                    var params = {
                        procId: procedure.id,
                        procType: procedure.type.id.toLowerCase()
                    };
                    $state.go('additionalProtocol', params, {location: 'replace'})
                },
                /**
                 * открытие формы "Уведомление о переносе сроков начала торгов"
                 */
                noticePostponementProcedure: function(procedure) {
                    var params = {
                        procId: procedure.id,
                        procType: procedure.type.id.toLowerCase()
                    };
                    $state.go('noticePostponementProcedure', params, {location: 'replace'})
                    ['catch'](function (err) {
                  	   openModal('_dialogInfo.html', {message: err.detail.data.errorMessage});
                       $state.go('openProcedure', {
                           procType: $stateParams.procType.toLowerCase(),
                           procId: $stateParams.procId
                       }, {reload: true})
                    });
                },


                /**
                 * открытие формы "Публикация уведомления"
                 */
                notificationProtocol: function(procedure) {
                    var params = {
                        procId: procedure.id,
                        procType: procedure.type.id.toLowerCase()
                    };
                    $state.go('notificationProtocol', params, {location: 'replace'})
                },
                /**
                 * открытие формы "Возврат заявок"
                 */
                returnClaimsProtocol: function(procedure) {
                    var params = {
                        procId: procedure.id,
                        procType: procedure.type.id.toLowerCase()
                    };
                    $state.go('returnClaimsProtocol', params, {location: 'replace'})
                },
                /**
                 * Повторно отправить в ЕИС
                 */
                retryToEIS: function(data) {
                	 needConfirmSrv.call(doRetryToEIS, [{
                         procId: $stateParams.procId,
                         procType: $stateParams.procType.toLowerCase(),
                         protocolId: data.id
                     }], 'Вы действительно хотите повторно отправить в ЕИС?');
                },
                /**
                 * Повторно отправить разъяснение в ЕИС
                 */
                retryExplToEIS: function(data) {
                     needConfirmSrv.call(doRetryExplToEIS, [{
                         procId: $stateParams.procId,
                         procType: $stateParams.procType.toLowerCase(),
                         id: data.exp.id
                     }], 'Вы действительно хотите повторно отправить разъяснение в ЕИС?');
                },


                /**
                 * ручная смена статуса уведомления. Отменить уведомление
                 */
                manualIncorrectProtocol: function (data) {
                    needConfirmSrv.call(baseMth.doManualIncorrectProtocol,[data],'Выполнить смену редакции ' +
                        (data.type.id==='ADDITIONAL' ? 'дополнительного протокола?' : (data.type.id==='CHANGE_CONTRACT_ADDITIONAL' ? 'протокола изменений условий договора?' : 'уведомления?')));
                },
                doManualIncorrectProtocol: function(protocol) {
                  	 $stateParams.protocolId = protocol.id;
                     protocolSrv.changeStateProtocolFn($stateParams, 'incorrect').then(function (response) {
                            if (response.data.success) {
                            	openModal('_dialogInfo.html', {message: 'Статус ' + (['ADDITIONAL','CHANGE_CONTRACT_ADDITIONAL'].includes(protocol.type.id) ? 'протокола' : 'уведомления') + ' изменён'}).then(function () {
                            	    $state.go('openProcedure', {
                                       procType: $stateParams.procType.toLowerCase(),
                                       procId: $stateParams.procId,
                                       clearConfig: false
                                     }, {reload: true})
                            	})
                             } else {
                            	 openModal('_dialogInfo.html', {message: 'Статус ' + (['ADDITIONAL','CHANGE_CONTRACT_ADDITIONAL'].includes(protocol.type.id) ? 'протокола' : 'уведомления') + ' не был изменён. Обратитесь в тех.поддержку.'})
                            }
                   })
                },
                /**
                 * ручная смена статуса уведомления. Признать недействующим
                 */
                manualCancelProtocol: function (data) {
                    needConfirmSrv.call(baseMth.doManualCancelProtocol,[data],'Выполнить смену редакции ' + (data.type.id==='ADDITIONAL' ? 'дополнительного протокола?' : (data.type.id==='CHANGE_CONTRACT_ADDITIONAL' ? 'протокола изменений условий договора?' : 'уведомления?')));
                },
                doManualCancelProtocol: function(protocol) {
                     $stateParams.protocolId = protocol.id;
                     protocolSrv.changeStateProtocolFn($stateParams, 'canceled').then(function (response) {
                            if (response.data.success) {
                            	 openModal('_dialogInfo.html', {message: 'Статус ' + (['ADDITIONAL','CHANGE_CONTRACT_ADDITIONAL'].includes(protocol.type.id) ? 'протокола' : 'уведомления') + ' изменён'}).then(function () {
                             	    $state.go('openProcedure', {
                                        procType: $stateParams.procType.toLowerCase(),
                                        procId: $stateParams.procId,
                                        clearConfig: false
                                      }, {reload: true})
                             	})
                             } else {
                            	 openModal('_dialogInfo.html', {message: 'Статус ' + (['ADDITIONAL','CHANGE_CONTRACT_ADDITIONAL'].includes(protocol.type.id) ? 'протокола' : 'уведомления') + ' не был изменён. Обратитесь в тех.поддержку.'})
                            }
                    })
                },
                /**
                 * ручная смена статуса уведомления. Признать действующим
                 */
                manualPublishProtocol: function (data) {
                    needConfirmSrv.call(baseMth.doManualPublishProtocol,[data],'Выполнить смену редакции ' + (data.type.id==='ADDITIONAL' ? 'дополнительного протокола?' : (data.type.id==='CHANGE_CONTRACT_ADDITIONAL' ? 'протокола изменений условий договора?' : 'уведомления?')));
                },
                doManualPublishProtocol: function(protocol) {
                  	 $stateParams.protocolId = protocol.id;
                     protocolSrv.changeStateProtocolFn($stateParams, 'published').then(function (response) {
                            if (response.data.success) {
                            	 openModal('_dialogInfo.html', {message: 'Статус ' + (['ADDITIONAL','CHANGE_CONTRACT_ADDITIONAL'].includes(protocol.type.id) ? 'протокола' : 'уведомления') + ' изменён'}).then(function () {
                             	    $state.go('openProcedure', {
                                        procType: $stateParams.procType.toLowerCase(),
                                        procId: $stateParams.procId,
                                        clearConfig: false
                                      }, {reload: true})
                             	})
                             } else {
                            	 openModal('_dialogInfo.html', {message: 'Статус ' + (['ADDITIONAL','CHANGE_CONTRACT_ADDITIONAL'].includes(protocol.type.id) ? 'протокола' : 'уведомления') + ' не был изменён. Обратитесь в тех.поддержку.'})
                            }
                    })
                }
            },
            /**
             * Открытый конкурс
             */
            opencontest = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                deleteExplanation: baseMth.deleteExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRebiddinOpeningProtocolOnLot: baseMth.createRebiddingOpeningProtocolOnLot,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                createChangeContractAdditionalProtocol: baseMth.createChangeContractAdditionalProtocol,
                planRebidding: baseMth.planRebidding,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editRatingFirstClaimProtocolOnProcedure: baseMth.editRatingFirstClaimProtocolOnProcedure,
                editSummarizingProtocolOnProcedure: baseMth.editSummarizingProtocolOnProcedure,
                editChangeContractAdditionalProtocol: baseMth.editChangeContractAdditionalProtocol,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishRatingFirstClaimProtocolOnProcedure: baseMth.publishRatingFirstClaimProtocolOnProcedure,
                publishSummarizingProtocolOnProcedure : baseMth.publishSummarizingProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol,
                editRebiddingOpeningProtocolOnLot: baseMth.editRebiddingOpeningProtocolOnLot,
                publishRebiddingOpeningProtocolOnLot: baseMth.publishRebiddingOpeningProtocolOnLot,
                createRebiddingRatingFirstClaimPartsProtocol: baseMth.createRebiddingRatingFirstClaimPartsProtocol,
                editRebiddingRatingFirstClaimProtocolOnProcedure: baseMth.editRebiddingRatingFirstClaimProtocolOnProcedure,
                publishRebiddingRatingFirstClaimProtocolOnProcedure: baseMth.publishRebiddingRatingFirstClaimProtocolOnProcedure                                
            },
            /**
             * Открытый двухэтапный конкурс
             */
            open2stagecontest = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                deleteExplanation: baseMth.deleteExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                createChangeContractAdditionalProtocol: baseMth.createChangeContractAdditionalProtocol,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editRatingFirstClaimProtocolOnProcedure: baseMth.editRatingFirstClaimProtocolOnProcedure,
                editSummarizingProtocolOnProcedure: baseMth.editSummarizingProtocolOnProcedure,
                editChangeContractAdditionalProtocol: baseMth.editChangeContractAdditionalProtocol,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishRatingFirstClaimProtocolOnProcedure: baseMth.publishRatingFirstClaimProtocolOnProcedure,
                publishSummarizingProtocolOnProcedure : baseMth.publishSummarizingProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol
            },
            /**
             * ПКО с неограниченным сроком
             */
            unlimitedprequalification = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                deleteExplanation: baseMth.deleteExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editRatingFirstClaimProtocolOnProcedure: baseMth.editRatingFirstClaimProtocolOnProcedure,
                editSummarizingProtocolOnProcedure: baseMth.editSummarizingProtocolOnProcedure,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishRatingFirstClaimProtocolOnProcedure: baseMth.publishRatingFirstClaimProtocolOnProcedure,
                publishSummarizingProtocolOnProcedure : baseMth.publishSummarizingProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol
            },
            /**
             * Запрос предложений
             */
            requestproposals = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                deleteExplanation: baseMth.deleteExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRebiddinOpeningProtocolOnLot: baseMth.createRebiddingOpeningProtocolOnLot,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                createChangeContractAdditionalProtocol: baseMth.createChangeContractAdditionalProtocol,
                planRebidding: baseMth.planRebidding,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editRatingFirstClaimProtocolOnProcedure: baseMth.editRatingFirstClaimProtocolOnProcedure,
                editSummarizingProtocolOnProcedure: baseMth.editSummarizingProtocolOnProcedure,
                editChangeContractAdditionalProtocol: baseMth.editChangeContractAdditionalProtocol,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishRatingFirstClaimProtocolOnProcedure: baseMth.publishRatingFirstClaimProtocolOnProcedure,
                publishSummarizingProtocolOnProcedure : baseMth.publishSummarizingProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol,
                editRebiddingOpeningProtocolOnLot: baseMth.editRebiddingOpeningProtocolOnLot,
                publishRebiddingOpeningProtocolOnLot: baseMth.publishRebiddingOpeningProtocolOnLot,
                createRebiddingRatingFirstClaimPartsProtocol: baseMth.createRebiddingRatingFirstClaimPartsProtocol,
                editRebiddingRatingFirstClaimProtocolOnProcedure: baseMth.editRebiddingRatingFirstClaimProtocolOnProcedure,
                publishRebiddingRatingFirstClaimProtocolOnProcedure: baseMth.publishRebiddingRatingFirstClaimProtocolOnProcedure,
                createRebiddingRatingSecondClaimPartsProtocol: baseMth.createRebiddingRatingSecondClaimPartsProtocol,
                editRebiddingRatingSecondClaimProtocolOnProcedure: baseMth.editRebiddingRatingSecondClaimProtocolOnProcedure,
                publishRebiddingRatingSecondClaimProtocolOnProcedure: baseMth.publishRebiddingRatingSecondClaimProtocolOnProcedure

            },
            /**
             * Запрос предложений с ограниченным участием
             */
            limitedrequestproposals = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                deleteExplanation: baseMth.deleteExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                createChangeContractAdditionalProtocol: baseMth.createChangeContractAdditionalProtocol,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editRatingFirstClaimProtocolOnProcedure: baseMth.editRatingFirstClaimProtocolOnProcedure,
                editSummarizingProtocolOnProcedure: baseMth.editSummarizingProtocolOnProcedure,
                editChangeContractAdditionalProtocol: baseMth.editChangeContractAdditionalProtocol,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishRatingFirstClaimProtocolOnProcedure: baseMth.publishRatingFirstClaimProtocolOnProcedure,
                publishSummarizingProtocolOnProcedure : baseMth.publishSummarizingProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol
            },
            /**
             * Запрос предложений (МСП)
             */
            requestproposalssmb = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol
            },
            /**
             * Запрос цен
             */
            requestprices = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                deleteExplanation: baseMth.deleteExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol
            },
            /**
             * Открытый конкурс МСП
             */
            opencontestsmb = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol
            },
            /**
             * Открытый аукцион
             */
            openauction = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                deleteExplanation: baseMth.deleteExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                createChangeContractAdditionalProtocol: baseMth.createChangeContractAdditionalProtocol,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editChangeContractAdditionalProtocol: baseMth.editChangeContractAdditionalProtocol,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol,
                sendBid: function (data) {
                    $state.go('openProcedure', {
                        procType: data.procedure.type.id.toLowerCase(),
                        procId: data.procedure.id,
                        clearConfig: true,
                        toAuction: data.lot.ordinalNumber
                    }, {reload: true})
                }
            },
            /**
             * Доходный аукцион
             */
            saleauction = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                deleteExplanation: baseMth.deleteExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                createChangeContractAdditionalProtocol: baseMth.createChangeContractAdditionalProtocol,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editChangeContractAdditionalProtocol: baseMth.editChangeContractAdditionalProtocol,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol,
                sendBid: function (data) {
                    $state.go('openProcedure', {
                        procType: data.procedure.type.id.toLowerCase(),
                        procId: data.procedure.id,
                        clearConfig: true,
                        toAuction: data.lot.ordinalNumber
                    }, {reload: true})
                }
            },
            /**
             * Открытое публичное предложение
             */
            openpublicoffer = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                deleteExplanation: baseMth.deleteExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                createChangeContractAdditionalProtocol: baseMth.createChangeContractAdditionalProtocol,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editChangeContractAdditionalProtocol: baseMth.editChangeContractAdditionalProtocol,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol,
                sendBid: function (data) {
                    $state.go('openProcedure', {
                        procType: data.procedure.type.id.toLowerCase(),
                        procId: data.procedure.id,
                        clearConfig: true,
                        toAuction: data.lot.ordinalNumber
                    }, {reload: true})
                }
            },
            /**
             * Аукцион с ограниченным участием
             */
            limitedauction = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                deleteExplanation: baseMth.deleteExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                createChangeContractAdditionalProtocol: baseMth.createChangeContractAdditionalProtocol,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editChangeContractAdditionalProtocol: baseMth.editChangeContractAdditionalProtocol,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol,
                sendBid: function (data) {
                    $state.go('openProcedure', {
                        procType: data.procedure.type.id.toLowerCase(),
                        procId: data.procedure.id,
                        clearConfig: true,
                        toAuction: data.lot.ordinalNumber
                    }, {reload: true})
                }
            },
            /**
             * Открытый аукцион МСП
             */
            openauctionsmb = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol,
                sendBid: function (data) {
                    $state.go('openProcedure', {
                        procType: data.procedure.type.id.toLowerCase(),
                        procId: data.procedure.id,
                        clearConfig: true,
                        toAuction: data.lot.ordinalNumber
                    }, {reload: true})
                }
            },
            /**
             * Аукцион (МСП)
             */
            auctionsmb = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol,
                sendBid: function (data) {
                    $state.go('openProcedure', {
                        procType: data.procedure.type.id.toLowerCase(),
                        procId: data.procedure.id,
                        clearConfig: true,
                        toAuction: data.lot.ordinalNumber
                    }, {reload: true})
                }
            },
            /**
             * Запрос котировок (МСП)
             */
            requestquotationssmb = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol
            },
            /**
             * Запрос котировок
             */
            requestquotations = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                deleteExplanation: baseMth.deleteExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                createChangeContractAdditionalProtocol: baseMth.createChangeContractAdditionalProtocol,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editRatingFirstClaimProtocolOnProcedure: baseMth.editRatingFirstClaimProtocolOnProcedure,
                editSummarizingProtocolOnProcedure: baseMth.editSummarizingProtocolOnProcedure,
                editChangeContractAdditionalProtocol: baseMth.editChangeContractAdditionalProtocol,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishRatingFirstClaimProtocolOnProcedure: baseMth.publishRatingFirstClaimProtocolOnProcedure,
                publishSummarizingProtocolOnProcedure : baseMth.publishSummarizingProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol,
                planRebidding: baseMth.planRebidding,
                createRebiddinOpeningProtocolOnLot: baseMth.createRebiddingOpeningProtocolOnLot,
                editRebiddingOpeningProtocolOnLot: baseMth.editRebiddingOpeningProtocolOnLot,
                publishRebiddingOpeningProtocolOnLot: baseMth.publishRebiddingOpeningProtocolOnLot,
                createRebiddingRatingFirstClaimPartsProtocol: baseMth.createRebiddingRatingFirstClaimPartsProtocol,
                editRebiddingRatingFirstClaimProtocolOnProcedure: baseMth.editRebiddingRatingFirstClaimProtocolOnProcedure,
                publishRebiddingRatingFirstClaimProtocolOnProcedure: baseMth.publishRebiddingRatingFirstClaimProtocolOnProcedure                                
 
            },
            /**
             * Закупка малого объема
             */
            simplecontest = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                deleteExplanation: baseMth.deleteExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                createChangeContractAdditionalProtocol: baseMth.createChangeContractAdditionalProtocol,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editSummarizingProtocolOnProcedure: baseMth.editSummarizingProtocolOnProcedure,
                editChangeContractAdditionalProtocol: baseMth.editChangeContractAdditionalProtocol,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishSummarizingProtocolOnProcedure : baseMth.publishSummarizingProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol
            },
            /**
             * Предварительный квалификационный отбор
             */
            prequalification = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                deleteExplanation: baseMth.deleteExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editRatingFirstClaimProtocolOnProcedure: baseMth.editRatingFirstClaimProtocolOnProcedure,
                editSummarizingProtocolOnProcedure: baseMth.editSummarizingProtocolOnProcedure,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishRatingFirstClaimProtocolOnProcedure: baseMth.publishRatingFirstClaimProtocolOnProcedure,
                publishSummarizingProtocolOnProcedure : baseMth.publishSummarizingProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol
            },
            /**
             * Размещение оферты
             */
            offerplacement = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                deleteExplanation: baseMth.deleteExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                createChangeContractAdditionalProtocol: baseMth.createChangeContractAdditionalProtocol,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editOpeningProtocolOnProcedure: baseMth.editOpeningProtocolOnProcedure,
                editSummarizingProtocolOnProcedure: baseMth.editSummarizingProtocolOnProcedure,
                editChangeContractAdditionalProtocol: baseMth.editChangeContractAdditionalProtocol,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishOpeningProtocolOnProcedure : baseMth.publishOpeningProtocolOnProcedure,
                publishSummarizingProtocolOnProcedure : baseMth.publishSummarizingProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol
            },
            /**
             * Размещение оферты МСП
             */
            offerplacementsmb = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol
            },
            /**
             * Конкурс с ограниченным участием
             */
            limitedcontest = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                deleteExplanation: baseMth.deleteExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRebiddinOpeningProtocolOnLot: baseMth.createRebiddingOpeningProtocolOnLot,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                createChangeContractAdditionalProtocol: baseMth.createChangeContractAdditionalProtocol,
                planRebidding: baseMth.planRebidding,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editRatingFirstClaimProtocolOnProcedure: baseMth.editRatingFirstClaimProtocolOnProcedure,
                editSummarizingProtocolOnProcedure: baseMth.editSummarizingProtocolOnProcedure,
                editChangeContractAdditionalProtocol: baseMth.editChangeContractAdditionalProtocol,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishRatingFirstClaimProtocolOnProcedure: baseMth.publishRatingFirstClaimProtocolOnProcedure,
                publishSummarizingProtocolOnProcedure : baseMth.publishSummarizingProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol,
                editRebiddingOpeningProtocolOnLot: baseMth.editRebiddingOpeningProtocolOnLot,
                publishRebiddingOpeningProtocolOnLot: baseMth.publishRebiddingOpeningProtocolOnLot,
                createRebiddingRatingFirstClaimPartsProtocol: baseMth.createRebiddingRatingFirstClaimPartsProtocol,
                editRebiddingRatingFirstClaimProtocolOnProcedure: baseMth.editRebiddingRatingFirstClaimProtocolOnProcedure,
                publishRebiddingRatingFirstClaimProtocolOnProcedure: baseMth.publishRebiddingRatingFirstClaimProtocolOnProcedure
            },
            /**
             * Конкурс среди организаций, прошедших предварительный квалификационный отбор
             */
            limitedcontestqualification = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                deleteExplanation: baseMth.deleteExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                createChangeContractAdditionalProtocol: baseMth.createChangeContractAdditionalProtocol,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editOpeningProtocolOnProcedure: baseMth.editOpeningProtocolOnProcedure,
                editSummarizingProtocolOnProcedure: baseMth.editSummarizingProtocolOnProcedure,
                editChangeContractAdditionalProtocol: baseMth.editChangeContractAdditionalProtocol,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishOpeningProtocolOnProcedure : baseMth.publishOpeningProtocolOnProcedure,
                publishSummarizingProtocolOnProcedure : baseMth.publishSummarizingProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol
            },
            /**
             * Запрос котировок с ограниченным участием
             */
            limitedrequestquotations = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                deleteExplanation: baseMth.deleteExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                createChangeContractAdditionalProtocol: baseMth.createChangeContractAdditionalProtocol,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editRatingFirstClaimProtocolOnProcedure: baseMth.editRatingFirstClaimProtocolOnProcedure,
                editSummarizingProtocolOnProcedure: baseMth.editSummarizingProtocolOnProcedure,
                editChangeContractAdditionalProtocol: baseMth.editChangeContractAdditionalProtocol,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishRatingFirstClaimProtocolOnProcedure: baseMth.publishRatingFirstClaimProtocolOnProcedure,
                publishSummarizingProtocolOnProcedure : baseMth.publishSummarizingProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol,
                planRebidding: baseMth.planRebidding,
                createRebiddinOpeningProtocolOnLot: baseMth.createRebiddingOpeningProtocolOnLot,
                editRebiddingOpeningProtocolOnLot: baseMth.editRebiddingOpeningProtocolOnLot,
                publishRebiddingOpeningProtocolOnLot: baseMth.publishRebiddingOpeningProtocolOnLot,
                createRebiddingRatingFirstClaimPartsProtocol: baseMth.createRebiddingRatingFirstClaimPartsProtocol,
                editRebiddingRatingFirstClaimProtocolOnProcedure: baseMth.editRebiddingRatingFirstClaimProtocolOnProcedure,
                publishRebiddingRatingFirstClaimProtocolOnProcedure: baseMth.publishRebiddingRatingFirstClaimProtocolOnProcedure
            },
            /**
             *  Электронный аукцион
             */
            EAP44 = {
                createNoticeNewVersion: baseMth.createNoticeNewVersion,
                deleteNotice: baseMth.deleteNotice,
                createExplanation: baseMth.createExplanation,
                createExplanationPersonal: baseMth.createExplanationPersonal,
                createExplanationEmail: baseMth.createExplanationEmail,
                chooseExplanation: baseMth.chooseExplanation,
                ignoreExplanationRequest: baseMth.ignoreExplanationRequest,
                requestExplanations: baseMth.requestExplanations,
                createClaimOnLot: baseMth.createClaimOnLot,
                editClaimOnLot: baseMth.editClaimOnLot,
                sendClaimOnLot: baseMth.sendClaimOnLot,
                revokeClaimOnLot: baseMth.revokeClaimOnLot,
                deleteClaimOnLot: baseMth.deleteClaimOnLot,
                editClaim: baseMth.editClaimOnLot,
                sendClaim: baseMth.sendClaimOnLot,
                revokeClaim: baseMth.revokeClaimOnLot,
                deleteClaim: baseMth.deleteClaimOnLot,
                createOpeningProtocol: baseMth.createOpeningProtocol,
                createRatingFirstClaimPartsProtocol: baseMth.createRatingFirstClaimPartsProtocol,
                createRatingSecondClaimPartsProtocol: baseMth.createRatingSecondClaimPartsProtocol,
                createSummarizingProtocol: baseMth.createSummarizingProtocol,
                editProtocolOnProcedure: baseMth.editProtocolOnProcedure,
                editProtocol: baseMth.editProtocol,
                deleteProtocol: baseMth.deleteProtocol,
                publishProtocolOnProcedure: baseMth.publishProtocolOnProcedure,
                publishProtocol: baseMth.publishProtocol,
                editPublishedProtocol: baseMth.editPublishedProtocol,
                sendBid: function (data) {
                    $state.go('openProcedure', {
                        procType: data.procedure.type.id.toLowerCase(),
                        procId: data.procedure.id,
                        clearConfig: true,
                        toAuction: data.lot.ordinalNumber
                    }, {reload: true})
                }
            },

            /* Для всех видов*/
            base = {
                procedureReject: baseMth.procedureReject,
                noticePostponementProcedure: baseMth.noticePostponementProcedure,
                notificationProtocol: baseMth.notificationProtocol,
                informationAboutCancelLots: baseMth.informationAboutCancelLots,
                additionalProtocol: baseMth.additionalProtocol,
                requestExplanationsOrg: baseMth.requestExplanationsOrg,
                createExplanation: baseMth.createExplanationOrg,
                returnClaimsProtocol: baseMth.returnClaimsProtocol,
                manualIncorrectProtocol: baseMth.manualIncorrectProtocol,
                manualCancelProtocol: baseMth.manualCancelProtocol,
                manualPublishProtocol: baseMth.manualPublishProtocol,
                initSendToParticipant: baseMth.initSendToParticipant,
                finishedPaper: baseMth.finishedPaper,
                finishedReject: baseMth.finishedReject,
                contractOpen: baseMth.contractOpen,
                retryToEIS: baseMth.retryToEIS,
                retryExplToEIS: baseMth.retryExplToEIS
            };

        /**
         * Получение методов для кнопки по ее id
         * @param id - тип и название действия (пример: opencontest.createExplanation)
         * @param data - данные для выполнения действий над кнопкой
         * @param actionBlock - группа действий
         * @param scope - scope
         */
        service.getActionFn = function (id, data, actionBlock, scope) {
            if (id) {
                var path = id.split('.'),
                    actionByType = actions[path[0]];
                if (actionByType && actionByType[path[1]]) {
                    //disable action group
                    if(actionBlock && scope) {
                        angular.forEach(actionBlock, action => action.disabled = true);
                        timeout(scope);
                    }
                    actionByType[path[1]](data)
                } else {
                    console.log(path[1] + ' Нет обработчика!')
                }
            }
        };
        var actions = {
            opencontest: opencontest,
            opencontestsmb: opencontestsmb,
            open2stagecontest: open2stagecontest,
            openauction: openauction,
            openauctionsmb: openauctionsmb,
            auctionsmb: auctionsmb,
            requestquotationssmb: requestquotationssmb,
            requestquotations: requestquotations,
            prequalification: prequalification,
            offerplacement: offerplacement,
            offerplacementsmb: offerplacementsmb,
            limitedcontest: limitedcontest,
            limitedrequestquotations: limitedrequestquotations,
            limitedauction: limitedauction,
            requestproposals: requestproposals,
            limitedrequestproposals: limitedrequestproposals,
            requestproposalssmb: requestproposalssmb,
            limitedcontestqualification: limitedcontestqualification,
            unlimitedprequalification: unlimitedprequalification,
            simplecontest: simplecontest,
            saleauction: saleauction,
            openpublicoffer: openpublicoffer,
            EAP44: EAP44,
            requestprices: requestprices,
            base: base
        };
        // todo в 1,7 перейти на dialog.service.js
        function openModal(modal, data) {
            return $uibModal.open({
                backdrop  : 'static',
                keyboard  : false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                size: 'md',
                templateUrl: modal,
                controller:['$scope', '$uibModalInstance', '$rootScope', function ($scope, $uibModalInstance, $rootScope) {
                    $scope.model = {
                        message: data.message
                    };

                    $scope.closeModal = function (type) {
                        if(!type)
                            $rootScope.$emit('enableActions');
                        $uibModalInstance.close();
                    };
                }]
            }).result.then(function (value) {
                return Promise.resolve(value)
            })
        }


    }]);