/**
 * @author dmitrys
 */
angular.module('uetp').service('subscriptionSrv', ['$state','httpSrv',
    function ($state,httpSrv) {
        var service = this;
        service.subscribe = subscribeFn;
        service.deleteItem = deleteItemFn;
        service.getSubscribe = getSubscribeFn;
        service.getModelFromView = getModelFromViewFn;
        service.getModelFromSave = getModelFromSaveFn;
        service.subscribePurchase = subscribePurchaseFn;
        
        /*
        * Подписаться на закупку КОМИТА ЭТП
        * */                
        function subscribePurchaseFn(data) {
          return httpSrv.http({
                method: 'POST',
                url: '/subscriber/api/v1/subscribe',
                data: data
          })
        }
        /*
        * Подписаться
        * */
        function subscribeFn(data, host, scope) {
           /* return httpSrv.http({
                method: 'POST',
                url: '/other-purchases/proc/subscribe',
                data: data
            })*/
            
          return httpSrv.requestXHR(scope,{
                method: 'POST',
                url: host + '/rest/other-purchases/proc/subscribe',
                data: data
            })
            
        }
        /*
        * Удалить подписку
        * */
        function deleteItemFn(id, host, scope) {
           /* return httpSrv.http({
                method: 'GET',
                url: '/other-purchases/proc/unsubscribe/'+id
            })*/
           return httpSrv.requestXHR(scope,{
                method: 'GET',
                url: host + '/rest/other-purchases/proc/unsubscribe/'+id
            })
        }
        /*
        * Получить подписку
        * */
        function getSubscribeFn(id, host, scope) {
           /* return httpSrv.http({
                method: 'GET',
                url: '/other-purchases/proc/subscribe/'+id
            })*/
            return httpSrv.requestXHR(scope,{
                method: 'GET',
                url: host + '/rest/other-purchases/proc/subscribe/'+id
            })
        }
        /*
        * Модель для отображения
        * *преобразуем объекты для коректной работы списков
        * */
        function getModelFromViewFn(data) {
            var model = {};
            angular.forEach(data, function(value,key) {
                if (key === 'okpd2' || key === 'okved2' || key === 'ktru') {
                    var nameKey = 'deff__'+key;
                    model[nameKey] = [];
                    angular.forEach(value, function(row) {
                        var obj = {};
                        obj.name = row.param;
                        obj.code = row.id;
                        model[nameKey].push(obj);
                    });
                }else {
                    model[key] = value;
                }
            });
            return model;
        }
        /*
         * Модель для сохранения
         * *списки записываем через ','
         * */
        function getModelFromSaveFn(model) {
            var data = {};
            angular.forEach(model, function (value, key) {
                if (key === 'deff__okpd2' || key === 'deff__okved2' || key === 'deff__ktru') {
                     // key =  key === 'deff__okpd2'? 'deff__okpd':key === 'deff__okved2'?'deff__okved':key;
                    var newKey = key.replace(/deff__/g, '');
                    data[newKey] = [];
                    angular.forEach(value, function (row) {
                        var obj = {};
                        obj.id = row.code;
                        obj.param = row.name;
                        data[newKey].push(obj);
                    })
                } else if (key === 'customer') {
                    data[key] = [];
                    angular.forEach(value, function (row) {
                        var obj = {};
                        obj.name = row.name;
                        obj.inn = row.inn;
                        obj.kpp = row.kpp;
                        obj.ogrn = row.ogrn;
                        obj.id = row.id;
                        data[key].push(obj);
                    })
                }
                else {
                    data[key] = value;
                }
            });
            return data;
        }


    }]);