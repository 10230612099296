/**
 * @author: sergeyu
 * Доступ пользователя к организациям-агентам для банковских гарантий
 */
angular.module('uetp')
    .component('bgAgents', {
        templateUrl: 'app/components/bgAgent/consent/bgAgents.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', '$filter', 'desktopSrv', 'dictionarySrv', 'dialogSrv', 'profileSrv', 'participantSrv', 'alertSrv',
            function ($scope, $rootScope, $state, $stateParams, $filter, desktopSrv, dictionarySrv, dialogSrv, profileSrv, participantSrv, alertSrv) {
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.roleList = dictionarySrv.getStaticDictByType('roles')['participant'];
                    init();
                };

                $scope.updateBgAgentsById = updateBgAgentsByIdFn;
                $scope.toggleCheckedItem = toggleCheckedItemFn;
                $scope.bulkCheckbox = bulkCheckboxFn;
                $scope.goBack = goBackFn;
                $scope.editDisabled = editDisabledFn;
                
                function goBackFn(){
                    history.back();
                }
                
                function getAgentsList() {
                    return profileSrv.loadBgAgentsList($scope.user.id).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.bgAgentsList = response.data.result;
                            if (!Array.isArray($scope.checkedItems)) $scope.checkedItems = [];
                            angular.forEach($scope.bgAgentsList, function(item) {
                                if(item.successed)
                                    $scope.checkedItems.push(item.id);
                            });
                        } else {
                            $scope.bgAgentsList = [];
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response)
                    })
                }

                function init() {
                    $scope.form = {bgAgentsForm: {}};
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.user = participantSrv.getOrgInfo().data.result;
                    $scope.openForm = true;
                    getAgentsList();
                }

                function bulkCheckboxFn($event) {
                    var checked = $event.target.checked;
                    [].forEach.call(document.querySelectorAll(".itemCheck"), function (el) {
                        el.checked = checked;
                        checkItems(checked, el.id);
                    })
                }

                function checkItems(checked, id) {
                    if (!Array.isArray($scope.checkedItems)) $scope.checkedItems = [];
                    var pos = $scope.checkedItems.indexOf(id);
                    if (pos === -1 && checked) {
                        $scope.checkedItems.push(id);
                    }
                    if (pos !== -1 && !checked) {
                        $scope.checkedItems.splice(pos, 1);
                    }
                }

                function toggleCheckedItemFn($event, item, items) {
                    checkItems($event.target.checked, item.id);
                    $scope.bulkChBox = items.length == $scope.checkedItems.length;
                    //timeout($scope);
                }

                /**
                * Обнвовление представителей организации из таблицы
                */
                function updateBgAgentsByIdFn(id) {
                    setTimeout(function () {
                        var lst = angular.copy($scope.bgAgentsList);
                        if($scope.checkedItems.length>0){
                            angular.forEach(lst, function(item){
                                item.successed = $scope.checkedItems.includes(item.id);
                            });
                        }
                        profileSrv.updateBgAgentsById(lst, id).then(function successCallback(response) {
                            if (response.data.success) {
                               $scope.alertObjBgAgents = alertSrv.getAlertObj(response,'Изменения сохранены!');
                               if ($scope.form.bgAgentsForm) $scope.form.bgAgentsForm.$setPristine();
                            }
                        }, function errorCallback(response) {
                            $scope.alertObjBgAgents = alertSrv.getAlertObj(response);
                            console.log(response);
                        });
                    }, 700)
                }
                
                function editDisabledFn() {
                	return $scope.cabType() == 'inspector' || $scope.cabType() == 'accountant' || $scope.cabType() == 'support';
                }
            }]
    });

