/**
 * @author: sergeyu
 * Ответ на запрос о разъяснении (Разъяснение)
 */
angular.module('uetp')
    .component('answerExplanationOrg', {
        bindings: {
            openData: '<',
            createData: '<',
            procedureInfo: '<'
        },
        templateUrl: 'app/components/explanationOrg/answer/answerExplanationOrg.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'explanationOrgSrv', 'cryptography', 'authSrv', 'constants', 'httpSrv', 'alertSrv', 'needConfirmSrv', 'desktopSrv', '$filter', 'titleSrv',
            function ($scope, $rootScope, $state, $stateParams, explanationOrgSrv, cryptography, authSrv, constants, httpSrv, alertSrv, needConfirmSrv, desktopSrv, $filter, titleSrv) {
                var ctrl = this;
                $scope.ctrl = ctrl;
                this.$onInit = function () {
                    $scope.cabType =  desktopSrv.getCabType();
                    $scope.auth = authSrv.getAuth();
                    $scope.certError = authSrv.isCertificateError;
                    $scope.constants = constants;
                    $scope.submitted = false;
                    $scope.stateParams = {
                        procType: $stateParams.procType, // тип закупки
                        procId: $stateParams.procId, // идентификатор закупки
                        explReqId: $stateParams.explReqId,  // идентификатор запроса на разъяснение (если создаем разъяснение из запроса)
                        explId: $stateParams.explId,  // идентификатор разъяснения (если открыли на просмотр)
                        isPersonal: $stateParams.isPersonal
                    };
                    $scope.alertId = '';
                    $scope.form = {explanation: {}};
                    
                    if(ctrl.procedureInfo && !ctrl.procedureInfo.error && ctrl.procedureInfo.data.success){
                    	$scope.procedureName = ctrl.procedureInfo.data.result.name;
                        $scope.procedureNumber = ctrl.procedureInfo.data.result.number;
                    }
                    
                    if (ctrl.openData) {
                        $scope.model = ctrl.openData.data.result;
                        $scope.viewModel = ctrl.openData.data.result;
                        $scope.viewModel.isEdit = false;
                        if ($stateParams.successSend) {
                           // $scope.ctrl.alertObjAnswerExplanation = alertSrv.getSuccessMsgWithBtn('Документ отправлен!');
                        }
                        $scope.model.answer = $scope.model.message;
                        $scope.model.reciver = $scope.model.reciverDescription;
                        
                        if($scope.model.linkMsg){
                        	 $scope.viewModel.desc = $scope.model.linkMsg.message;
                             $scope.viewModel.subject = $scope.model.linkMsg.subject;
                             $scope.viewModel.created = $scope.model.linkMsg.createDate;
                        }
                        
                        if($scope.model.attachments && $scope.model.attachments.length>0){
                        	$scope.model.files = [];
                        	angular.forEach($scope.model.attachments, function (attach) {
                                $scope.model.files.push({
                                    fileName: attach.name,
                                    id: attach.id
                                });
                            })
                        } 	
                        
                        if(!$scope.model.read){
                            $rootScope.$emit('UPDATE:COUNTER'); // $rootScope.$on
                        }
                        
                    } else if (ctrl.createData) {
                        $scope.model = ctrl.createData.data.result;
                        
                        if($scope.model.linkMsg){
                        	$state.go('openProcedure', {
                                procType: $stateParams.procType,
                                procId: $stateParams.procId
                            }, {reload: true});
                        }
                        
                        
                        $scope.viewModel = {
                                isEdit: true
                        };
                        _initFields();
                        delete  $scope.model.file;
                        
                    } else {
                        $scope.model = {};
                        $scope.viewModel = {
                                isEdit: true
                        };
                        _initFields();
                    }
                    $scope.attachStyle = $scope.viewModel.isEdit ? {
                        'margin-left': '290px'
                    } : {}

                    if ($scope.viewModel.purchaseNumber) {
                        titleSrv.setTitle($scope.viewModel.purchaseNumber + ' | Ответ на запрос');
                    }
                };

                function _initFields () {
                    $scope.viewModel.reciver = $scope.model.senderDescription;
                    $scope.viewModel.subject = $scope.model.subject;
                    $scope.viewModel.desc = $scope.model.message;
                    $scope.viewModel.created = $scope.model.createDate;
                    
                  }

                /**
                 * Подпись и отправка
                 */
                $scope.signSendData = function () {
                    if ($scope.auth.certId) {
                        var model = angular.copy($scope.model),
                            requestModel = {};
                        requestModel.subject = 'Ответ участника на запрос от ' + $filter('date')($scope.viewModel.created, 'dd.MM.yyyy HH:mm:ss');
                        requestModel.desc = model.answer;
                        requestModel.applIds = model.applIds;
                        requestModel.decided = getServerTimeMsk();
                        
                        if ($scope.stateParams.explReqId) {
                            requestModel.requestId = $scope.stateParams.explReqId
                        }
                        $scope.submitted = true;
                        explanationOrgSrv.getDataForSign($stateParams.procType, $stateParams.procId, requestModel, 'explanationsOrg').then(function (response) {
                            if (response.data.success) {
                                cryptography.generateSignature(response.data.result, $scope.auth.certId).then(function (value) {
                                    requestModel.signature = value;
                                    var explanationType = 'explanationsOrg';
                                    explanationOrgSrv.sendSignData($stateParams.procType, $stateParams.procId, requestModel, explanationType).then(function (response) {
                                        if (response.data.success) {
                                        	 $scope.viewModel.isEdit = false;
                                        	 $scope.ctrl.alertObjAnswerExplanation = alertSrv.getSuccessMsgWithBtn("Ответ отправлен!");
                                        	 $scope.viewModel.answer = $scope.model.answer;
                                        	 $scope.attachStyle={};
                                        } else {
                                            $scope.submitted = false;
                                            $scope.ctrl.alertObjAnswerExplanation = alertSrv.getAlertObj(response);
                                        }
                                    })
                                }, function (reason) {
                                    $scope.submitted = false;
                                    if (typeof reason === 'string') {
                                        $scope.ctrl.alertObjAnswerExplanation = alertSrv.getErrorMsgWithBtn("Ошибка подписи документа. " + reason);
                                    } else {
                                        $scope.ctrl.alertObjAnswerExplanation = alertSrv.getErrorMsgWithBtn("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                                    }
                                })
                            } else {
                                $scope.submitted = false;
                                $scope.ctrl.alertObjAnswerExplanation = alertSrv.getAlertObj(response);
                            }
                        })
                    } else {
                        $scope.submitted = false;
                        $scope.ctrl.alertObjAnswerExplanation = alertSrv.getErrorMsgWithBtn('У вас не установлен сертификат.');
                    }
                };
                $scope.isModifyAttached = false;
                $scope.uploadSuccess = function (result) {
                    if (!$scope.model.applIds) {
                        $scope.model.applIds = []
                    }
                    if (!$scope.model.files) {
                        $scope.model.files = []
                    }
                    angular.forEach(result, function (item) {
                        $scope.model.files.push({
                            fileName: item.fileName,
                            id: item.id
                        });

                        $scope.model.applIds.push(item.id);
                        $scope.isModifyAttached = true;
                    })


                };
                $scope.deleteAttach = function (index) {
                    $scope.model.files.splice(index, 1);
                    $scope.model.applIds.splice(index, 1);
                    if (!angular.isDefined($scope.model.applIds[0])) {
                        $scope.model.applIds = null;
                        $scope.model.files = null;
                    }
                    $scope.isModifyAttached = true;
                };
                $scope.back = function () {
                    history.back();
                };
                $scope.reset = function () {
                    if(($scope.form.explanation.$dirty || $scope.isModifyAttached) && $scope.viewModel.isEdit){
                        needConfirmSrv.call($scope.back,null,'Внесенные изменения сохранены не будут');
                    }else{
                        $scope.back();
                    }
                };
                $scope.downloadFile = function (file) {
                 	if (ctrl.openData){
                		var url = '/message/api/v2/download/' + file.id;
                	}else{
                		var url = '/' + $stateParams.procType + '/downloadFile/' + file.id;
    	            }
                    httpSrv.downloadFile(url, file.filename);
                };
            }]
    });

