/**
 * @author: sergeyu
 */
angular.module('uetp').service('dialogSrv', ['$http', '$rootScope', '$log','$sce','ngDialog','$uibModal',
    function ($http, $rootScope, $log, $sce, ngDialog, $uibModal) {

        this.showDialogMsg = showDialogMsgFn;
        this.showDialogPrompt = showDialogPromptFn;
        this.showDialogMsgHtml = showDialogMsgHtmlFn;
        this.showDialogConfirm = showDialogConfirmFn;
        this.showDialogWithTpl = showDialogWithTplFn;
        this.openModal = openModalFn;
        function openModalFn(modal, data) {
            return $uibModal.open({
                backdrop  : 'static',
                keyboard  : false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                size: 'md',
                templateUrl: modal,
                controller:['$scope', '$uibModalInstance',  function ($scope, $uibModalInstance) {
                    $scope.model = {
                        title: data.title,
                        message: data.message,
                        buttons: data.buttons,
                        btnOkText: data.btnOkText ? data.btnOkText : 'ОК'
                    };

                    $scope.closeModal = function (typeClose) {
                        var result = angular.copy($scope.model);
                        result.close = typeClose;
                        $uibModalInstance.close(result);
                    };
                }]
            }).result.then(function (model) {
                return Promise.resolve(model)
            })
        }
        function showDialogPromptFn($scope, msg, btnObj, callback) {
            $scope.dialog = {msg: msg};
            $scope.dialog.submitBtnText = btnObj.text;
            $scope.dialog.submitBtnAction = btnObj.action;
            $scope.dialog.submitBtnActionParam = btnObj.param;
            ngDialog.open({
                template: 'views/dialog/dialogPrompt.html',
                className: 'ngdialog-theme-default',
                plain: false,
                overlay: true,
                showClose: true,
                disableAnimation: true,
                scope: $scope,
                preCloseCallback: function () {
                    $scope.dialog = {};
                    if (typeof callback === "function") callback();
                }
            });
        }
        function showDialogConfirmFn($scope, callback) {
            ngDialog.open({
                template: 'views/dialog/dialogConfirm.html',
                className: 'ngdialog-theme-default',
                plain: false,
                overlay: true,
                showClose: true,
                disableAnimation: true,
                scope: $scope,
                preCloseCallback: function (isConf) {
                    if (typeof callback === "function") callback(isConf);
                }
            });
        }
        function showDialogMsgFn($scope, msg, callback) {
            $scope.dialog = {msg: msg};
            ngDialog.open({
                template: 'views/dialog/dialogInfo.html',
                className: 'ngdialog-theme-default',
                plain: false,
                overlay: true,
                showClose: true,
                disableAnimation: true,
                scope: $scope,
                preCloseCallback: function () {
                    if (typeof callback == "function") callback();
                }
            });
        }

        function showDialogMsgHtmlFn($scope, msgHtml, callback) {
            $scope.dialog = {msgHtml: $sce.trustAsHtml(msgHtml)};
            ngDialog.open({
                template: 'views/dialog/dialogInfoHtml.html',
                className: 'ngdialog-theme-default',
                plain: false,
                overlay: true,
                showClose: true,
                disableAnimation: true,
                scope: $scope,
                preCloseCallback: function () {
                    if (typeof callback == "function") callback();
                }
            });
        }

        function showDialogWithTplFn($scope, tpl) {
            $scope.dialog = {};
            ngDialog.open({
                template: tpl,
                className: 'ngdialog-theme-default cmsb-class',
                plain: false,
                overlay: true,
                showClose: true,
                width: 950,
                height: 'auto',
                disableAnimation: true,
                scope: $scope,
                preCloseCallback: function () {
                    $scope.dialog = {};
                    $scope.appRefView = false;
                }
            });
        }
    }]);

