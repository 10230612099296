angular.module('uetp').controller('ucController', ['getUCData', '$scope', '$rootScope', '$http', 'constants', '$state', '$stateParams', 'dialogSrv', '$filter', 'ucSrv', 'httpSrv', 'authSrv','needConfirmSrv','desktopSrv',
    function (getUCData, $scope, $rootScope, $http, constants, $state, $stateParams, dialogService, $filter, ucService, httpSrv, authSrv,needConfirmSrv, desktopSrv) {
        //$scope.ucLoadById = ucLoadByIdFn;
        $scope.loadUCById = loadUCByIdFn;
        $scope.showInfoAUC = showInfoAUCFn;
        $scope.showPAK = showPAKFn;
        $scope.updateAUC = updateAUCFn;
        $scope.changeAuthState = changeAuthStateFn;
        $scope.loadCrl = loadCrlFn;
        $scope.showHistoryIcon = showHistoryIconFn;
        $scope.toggleWithdraw = toggleWithdrawFn;
        $scope.deleteAuth = deleteAuthFn;
        $scope.changeSort = changeSortFn;
        $scope.goBack = goBackFn;
        $scope.cancel = cancelFn;
        $scope.isReadOnly = isReadOnlyFn;
        $scope.goBack = goBackFn;

        function goBackFn(){
            history.back();
        }

        //начальная инициализация
        init();

        function isReadOnlyFn() {
            return ["accountant", "support"].indexOf(desktopSrv.getCabType())!=-1;
        }

        function toggleWithdrawFn() {
            $scope.alertId = $scope.alertId == 'withdraw' ? '' : 'withdraw';
        }

        function showHistoryIconFn(arr) {
            var authArr = arr.filter(function (el) {
                return el.type == "authorization";
            });
            return authArr.length > 0;
        }

        function showInfoAUCFn() {
            $scope.tabType = 'infoAUC';
        }

        function showPAKFn() {
            $scope.tabType = 'PAK';
        }

        function deleteAuthFn(data) {
            var dataObj = {
                centerId: $stateParams.id,
                state: data.authorizationState,
                startFrom: data.authorizationStartFrom
            };
            httpSrv.http({
                url: '/cert/rest/reestruc/delete_authorization',
                data: dataObj
            }).then(function successCallback(response) {
                toggleWithdrawFn();
                if (response.data.success) {
                    saveDescription(data.description, dataObj.centerId).then(function () {
                        $scope.alertId = 'ucSaved';
                        setTimeout(function () {
                            $state.reload();
                        }, 500);
                    }, function (reason) {
                        dialogService.showDialogMsg($scope, reason.data.message);
                    })
                } else {
                    dialogService.showDialogMsg($scope, response.data.message);
                }
                if ($scope.form.ucForm) $scope.form.ucForm.$setPristine();
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function loadUCByIdFn() {
            if (getUCData.status != 200) {
                console.log("Ошибка при загрузке УЦ");
                $state.go("uc");
                return;
            }
            $scope.data = getUCData.data;
            if ($scope.data.authorizationState) {
                $scope.data.authorizationStartFromDate = $filter('date')($scope.data.authorizationStartFrom, 'dd.MM.yyyy HH:mm')
                // $scope.data.description = ucService.getLastDescription($scope.data.accreditations);
                $scope.authorizationStartFromOld = $scope.data.authorizationStartFrom;
                $scope.authorizationState = $scope.authorizationStateOld = $scope.data.authorizationState;
            }
            if (Array.isArray($scope.data.keys) && $scope.data.keys.length > 0) {
                $scope.data.keys.forEach(function (keyItem) {
                    if (Array.isArray(keyItem.trustCertificates) && keyItem.trustCertificates.length > 0) {
                        keyItem.crlValid = keyItem.trustCertificates[0].crlValid;
                        keyItem.crlValidThrough = keyItem.trustCertificates[0].crlValidThrough;
                    } else {
                        keyItem.crlValidThrough = "";
                        keyItem.crlValid = false;
                    }
                })
            }
            $scope.tabType = 'infoAUC';
        }

        function loadCrlFn(id) {
            httpSrv.http({
                method: 'POST',
                url: '/cert/rest/reestruc/load_crl',
                data: {userId: authSrv.getAuth().userId, centerId: id}
            }).then(function successCallback() {
                //$state.reload($state.current.name);
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function changeSortFn($event) {
            var th = $event.currentTarget,
                orderBy = th.getAttribute('st-sort'),
                orderAsc = th.classList.contains("sortAsc");

            [].forEach.call(document.querySelectorAll(".sortColumn"), function (column) {
                column.classList.remove("sortDesc");
                column.classList.remove("sortAsc");
            });
            orderAsc = !orderAsc;
            th.classList.add(orderAsc ? "sortAsc" : "sortDesc");
            if (orderBy) {
                //$scope.paginator.page = 0;   // если надо, то вернуть
                $scope.property = orderBy;
                $scope.reverse = !orderAsc;
            }
        }

        /***
         * Сохранить дополнительные сведения
         * @returns promise response
         */
        function saveDescription(description, centerId) {
            var data = {
                "centerId": centerId,
                "description": description
            };
            return httpSrv.http({
                url: '/cert/rest/reestruc/store_info',
                data: data
            })
        }

        function updateAUCFn(data) {
            if ($scope.authorizationStateOld == $scope.authorizationState)
                data.authorizationStartFrom = $scope.authorizationStartFromOld;
            var dataObj = {
                centerId: $stateParams.id,
                state: $scope.authorizationState,
                startFrom: data.authorizationStartFrom
            };

            httpSrv.http({
                url: '/cert/rest/reestruc/store_authorization',
                data: dataObj
            }).then(function successCallback(response) {
                if (response.data.success) {
                    saveDescription(data.description, dataObj.centerId).then(function () {
                        $scope.alertId = 'ucSaved';
                        setTimeout(function () {
                            $state.reload();
                        }, 500);
                    }, function (reason) {
                        dialogService.showDialogMsg($scope, reason.data.message);
                    })
                } else {
                    dialogService.showDialogMsg($scope, response.data.message);
                }
                if ($scope.form.ucForm) $scope.form.ucForm.$setPristine();
            }, function errorCallback(response) {
                console.log(response);
            });

        }

        function changeAuthStateFn(selectObj) {
            $scope.data.authorizationStartFrom = new Date();
            $scope.data.authorizationStartFromDate = $filter('date')($scope.data.authorizationStartFrom, 'dd.MM.yyyy HH:mm');
            if (selectObj.$select.selected)
                $scope.authorizationState = selectObj.$select.selected.name;
        }

        $scope.closeAlert = function () {
            $scope.alertId = 'dismiss';
        };

        function init() {
            $scope.form = {};
            $scope.alertId = '';
            $scope.authorizationStateList = [
                {id: "Действует", name: "Действует"},
                {id: "Прекращена", name: "Прекращена"},
                {id: "Приостановлена", name: "Приостановлена"},
            ];
            if ($stateParams.id) loadUCByIdFn();
            $scope.property = 'nikname';
            $scope.reverse = false;
        }

        function goBackFn() {
            history.back();
        }
        function cancelFn() {
            if($scope.form.ucForm.$dirty){
                needConfirmSrv.call($scope.goBack,[],'Внесенные изменения сохранены не будут');
            }else{
                $scope.goBack();
            }
        }
    }]);
