angular.module('uetp')
    .component('docHistory', {
        templateUrl: 'app/components/docHistory/docHistoryView.html',
        bindings: {
            metaData: '<',
            historyData: '<'
        },
        controller: ['$scope', '$rootScope',  'constants', '$state', '$stateParams', 'docsHistorySrv',
            function ($scope, $rootScope,  constants, $state, $stateParams, docsHistorySrv) {
                var ctrl = this;
                /**
                 * order - История Заказа
                 * offer - История Предложения
                 */
                this.$onInit = initFn;
                $scope.openDocHistory = openDocHistoryFn;
                /**
                 * Сыылка на вызов метода для пагинатора
                 */
                $scope.loadHistory = loadHistoryFn;
                $scope.paginator = {
                    itemsPerPage: 20,
                    page: 0
                };

                function loadHistoryFn() {
                    loadLogListByIdDocFn($scope.idDoc)
                }

                function loadLogListByIdDocFn(id) {
                    if (!id) {
                        return;
                    }
                    docsHistorySrv.loadLogListByIdDoc($scope.typeHistory, id, $scope.paginator).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.historyList = response.data.items;
                            $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    })
                }

                function openDocHistoryFn() {
                    $scope.docType = docsHistorySrv.getDocTypeByTypeHistory($scope.typeHistory);
                    $scope.metaData = ctrl.metaData.data.result;
                    $scope.historyList = ctrl.historyData.data.items;
                    $scope.totalPages = Math.ceil(ctrl.historyData.data.total / $scope.paginator.itemsPerPage);
                }

                function initFn() {
                    $scope.typeHistory = $stateParams.typeHistory;
                    $scope.idDoc = $stateParams.id;
                    if ($stateParams.id) $scope.openDocHistory();
                }

            }]

    });
