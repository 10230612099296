/**
 * Вкладка лоты в процедуре
 * @author dmitrys
 */
angular.module('uetp')
    .component('lots', {
        templateUrl: 'app/components/notice/lots/lots.html',
        controller: ['$rootScope', '$scope', '$stateParams', 'noticeSrv', 'vfSrv', 'desktopSrv', 'dictionarySrv', 'extendFieldSrv',
            function ($rootScope, $scope, $stateParams, noticeSrv, vfSrv, desktopSrv, dictionarySrv, extendFieldSrv) {
                $scope.state = getStateFn;

                $scope.customerDict = [];

                /**
                 * Пагинатор
                 */
                $scope.totalPages = 0;
                $scope.paginator = {
                    itemsPerPage: 50,
                    page: 0
                };
                $scope.delRow = delRowFn;
                $scope.clickRow = clickRowFn;
                $scope.setState = setStateFn;
                $scope.getState = getStateFn;
                $scope.importLot = importLotFn;
                $scope.isCancelLot = isCancelLotFn;
                $scope.isShowCustomerEISError = noticeSrv.isNoticeShowCustomerEISError;
                $scope.isCustomerLoading = noticeSrv.isCustomerLoading;
                $scope.selectedCustomer = noticeSrv.getSelectedCustomer;
                $scope.isLoadTemplate = extendFieldSrv.isLoadTemplate;
                $scope.isNew223 = noticeSrv.isNew223;
                $scope.isInitedNotice = noticeSrv.isInitedNotice;
                $scope.isNoticeIntegrationEIS = noticeSrv.isNoticeIntegrationEIS;
                $scope.addLotBtnDisableReason = '';
                $scope.isOpenPublicOffer = noticeSrv.isOpenPublicOffer;
                $scope.isSimpleContest = noticeSrv.isSimpleContest;
                $scope.isSimpleContest44 = noticeSrv.isSimpleContest44;
                $scope.isPercentPriceMode = noticeSrv.isPercentPriceMode;

                this.$onInit = function () {
                    /**
                     * Конфигурация формы
                     */
                    $scope.isEdit = noticeSrv.getEditForm();
                    $scope.isReadOnly = noticeSrv.getReadOnlyForm();
                    $scope.notice = noticeSrv.getNoticeInfo();
                    $scope.model = $scope.notice.document;
                    $scope.lf = vfSrv.getVisibleLotFields($stateParams.type);
                    $scope.currencyDict = dictionarySrv.getStaticDictByType('currency');

                    updatePaginatorFn();
                    window.scrollTo(0, 0);

                };
                $scope.$on('uetp.notice', function (event, data) {
                    if (data.dataType === 'lot' && data.type === 'create') {
                        for(var i = 0; i<data.planData.lots.length; i++) {
                            clickRowFn(null, data.planData.lots[i], data.eisnds, data.planData);
                        }
                    } else
                    if (data.dataType === 'lot' && data.type === 'update') {
                        if (data.data != null)
                            updateDateFn(data.data);
                    }
                    if (data.dataType === 'lot' && data.type === 'setCustomerDict') {
                        $scope.customerDict = data.data;
                    }
                    if (data.dataType === 'lot' && data.type === 'getLotsRequired') {
                        var model = angular.copy(data.data);
                        $scope.$parent.$broadcast('uetp.notice', {data: model,dataType: 'lot',type: 'getLotRequired'});
                    }
                });
                $scope.$on('getLotsRequiredFinished', function(event, result){
                    $rootScope.$broadcast('getLotsRequiredProcedureFinished', result);
                });

                /**
                 * Удалить элемент из таблицы lots
                 * @param index - номер строки для удаления
                 */
                function delRowFn(index) {
                    var indexByPage = ($scope.paginator.page)*$scope.paginator.itemsPerPage + index;
                    $scope.model.lots.lot.splice(indexByPage, 1);
                    for (var i = indexByPage; i < $scope.model.lots.lot.length; i++) {
                        $scope.model.lots.lot[i].ordinalNumber = i + 1;
                    }
                    updatePaginatorFn();
                    $scope.$parent.$parent.$broadcast('uetp.notice', {dataType: 'lot', type: 'delete'});
                }

                /**
                 *
                 */
                function getOriginalNumberLot() {
                    var numberRow = 0;
                    //ищем последний номер
                    angular.forEach($scope.model.lots.lot, function (lot) {
                        if (lot.ordinalNumber > numberRow) {
                            numberRow = lot.ordinalNumber;
                        }
                    });
                    numberRow++;
                    return numberRow;
                }

                /**
                 * Открыть Таблицу "Лот"
                 * @param row - строка таблицы, если null - создаем новую строку c номым номером
                 * @param initLotParam param for callbackfn
                 * @param eisnds param eisnds
                 * @param planData param planData
                 */
                function clickRowFn(row, initLotParam, eisnds, planData) {
                    if (row == null) {
                        row = noticeSrv.defaultLot();
                        row.ordinalNumber = getOriginalNumberLot()
                        
                        if ((noticeSrv.isNewCommercial() && $scope.model.priceType=='MIN_PRICE' || noticeSrv.showApplicationSupply())
                            && $scope.model.lots.lot && $scope.model.lots.lot.length > 0){
                          let lastDepositLot;
                          
                          let numberDepositRow = 0;
                          angular.forEach($scope.model.lots.lot, function (lot) {
                              if (lot.ordinalNumber > numberDepositRow && ['operator', 'operatorSupply'].includes(lot.lotData?.applicationDepositNeeded)) {
                                  numberDepositRow = lot.ordinalNumber;
                                  lastDepositLot = lot;
                              }
                          });
                          if(!row.lotData){
                              row.lotData = {};
                          }
                          if(lastDepositLot && lastDepositLot.lotData && lastDepositLot.lotData.depositPaymentDetails){
                              row.lotData.deff__depositPaymentDetails = angular.copy(lastDepositLot.lotData.depositPaymentDetails);
                          }
                       }
                    }
                    row.deff__priceType = $scope.model.priceType;
                    row.deff__typeCode = $scope.model.typeCode;

                    if(initLotParam){
                        row = initEmptyLot(initLotParam, row, eisnds, planData);
                    }
                    if(!row.lotData){
                    	row.lotData = {};
                    }
                    if(noticeSrv.isNewCommercial() && $scope.model.priceType=='MIN_PRICE' && !row.lotData.applicationDepositNeeded) {
                    	row.lotData.applicationDepositNeeded = 'notRequired'
                    }
                    
                    var modelCopy = angular.copy(row);
                    
                    $scope.$parent.$broadcast('uetp.notice', {
                        data: modelCopy,
                        dataType: 'lot',
                        dict:{
                            customer: $scope.customerDict
                        },
                        params:{
                            jointPurchaseEnabled: $scope.model.jointPurchase,
                            nonCompetitive: $scope.model.nonCompetitive,
                            tariffType: $scope.model.tariffType
                        },
                        type: 'createOrEdit'
                    });
                    if(!initLotParam)
                        setStateFn('lot');
                    else{
                        // сохраняем лот
                        desktopSrv.removeKeys(modelCopy, 'deff__');
                        updateDateFn(modelCopy);
                    }
                }

                /**
                 * Заполнение пустого лота из позиции плана закупки
                 * @param lot лот плана закупки
                 * @param row извещение
                 * @param eisnds признак eisnds
                 * @param planData план закупки
                 */
                function initEmptyLot(lot, row, eisnds, planData){
                    row.subject = lot.lot_name;
                    row.currency.digitalCode = $scope.getDigitalCurrencyCode(lot.currency_code);
                    row.currency.code = lot.currency_code;
                    row.currency.name = $scope.getNameCurrencyCode(lot.currency_code);
                    if(eisnds)
                        row.contractSum = lot.startprice;
                    else
                        row.contractSumNoNDS = lot.startprice;

                    row.lotPlanInfo = {};
                    row.lotPlanInfo.planRegistrationNumber = planData.numberPlan;
                    row.lotPlanInfo.planGuid = planData.positionPlanGuid;
                    row.lotPlanInfo.positionNumber = lot.positionNumber;
                    row.lotPlanInfo.lotPlanPosition = 'COMMODITY';
                    row.lotPlanInfo.positionGuid = lot.id;
                    row.lotData = {};
                    if(lot.startprice_cur){
                        row.lotData.startingContractPriceRub = lot.startprice_cur;
                    }
                    row.deliveryPlaceIndication = 'GL';
                    row.lotData.deliveryPlace = {};
                    
                    angular.forEach(dictionarySrv.getRemoteData().fias_region, function(item){
                       if(item.okato===lot.deliveryPlace_OKATO){
                           row.lotData.deliveryPlace.region = item.code;
                           row.lotData.deliveryPlace.state = item.federalokrug;
                       }

                    });
                    row.lotData.deliveryPlace.regionOkato = lot.deliveryPlace_OKATO;
                    
                    if(lot.lotItems){
                        row.lotItems.lotItem = [];
                    }

                    angular.forEach(lot.lotItems, function (lotItem) {
                        var rowLotItem = {};
                        rowLotItem.ordinalNumber = getItemOriginalNumber(row);
                        rowLotItem.okpd2 = {};
                        rowLotItem.okpd2.code = lotItem.okpd2_code;
                        rowLotItem.okpd2.name = lotItem.okpd2_name;
                        rowLotItem.name = lotItem.okpd2_name;
                        rowLotItem.okved2 = {};
                        rowLotItem.okved2.code = lotItem.okved2_code;
                        rowLotItem.okved2.name = lotItem.okved2_name;
                        rowLotItem.okei = {};
                        rowLotItem.okei.code = lotItem.okeiCode;
                        rowLotItem.okei.name = lotItem.okeiName;
                        rowLotItem.qty = lotItem.qty;
                        row.lotItems.lotItem.push(rowLotItem);
                    });
                    return row;
                }

                /**
                 *  Получаем номер новой строки номенклатуры
                 */
                function getItemOriginalNumber(row) {
                    var numberRow = 0;
                    //ищем последний номер
                    angular.forEach(row.lotItems.lotItem, function (item) {
                        if (item.ordinalNumber > numberRow) {
                            numberRow = item.ordinalNumber;
                        }
                    });
                    numberRow++;
                    return numberRow;
                }

                $scope.getDigitalCurrencyCode = (currency) => ($scope.currencyDict.find(c=>c.pattern===currency)||{}).code
                $scope.getNameCurrencyCode = (currency) => ($scope.currencyDict.find(c=>c.pattern===currency)||{}).name
                $scope.getCurrencyPatternByCode = (code) => ($scope.currencyDict.find(c => c.code === code) || {}).pattern

                /**
                 * Установить активное состояние вкладки
                 * @param value
                 */
                function setStateFn(value) {
                    noticeSrv.setState(value);
                }

                /**
                 * Вернуть активное состояние вкладки
                 */
                function getStateFn() {
                    return noticeSrv.getState();
                }

                /**
                 * Обновляем данные в модели
                 * @param data - данные для обновления
                 */
                function updateDateFn(data) {
                    var lots, update = false;
                    if ($scope.model.lots.lot && $scope.model.lots.lot.length > 0)
                        for (var i = 0; i < $scope.model.lots.lot.length; i++) {
                            lots = $scope.model.lots.lot[i];
                            if (lots.ordinalNumber == data.ordinalNumber) {
                                $scope.model.lots.lot[i] = data;
                                update = true;
                            }
                        }
                    //Добавить, если не нашел данные в модели
                    if (!update) {
                        if (!$scope.model.lots.lot) {
                            $scope.model.lots.lot = [];
                        }
                        $scope.model.lots.lot.push(data);
                    }
                    updatePaginatorFn();
                }

                /**
                 * Callbaсk для импорта лотов
                 * @param data - то что импортировали
                 */
                function importLotFn(data) {
                    $scope.model.lots.lot = [];
                    for (var i = 0; i < data.length; i++) {
                        //data[i].ordinalNumber = getOriginalNumberLot();
                        $scope.model.lots.lot.push(data[i]);
                    }
                }


                /**
                 * Обновление пагинатора
                 */
                function updatePaginatorFn() {
                    $scope.totalPages = ($scope.model && $scope.model.lots && $scope.model.lots.lot.length) ? Math.ceil($scope.model.lots.lot.length / $scope.paginator.itemsPerPage) : null;
                }

                function isCancelLotFn(lot) {
                    return $scope.notice.stateLots?$scope.notice.stateLots[lot.ordinalNumber] === 'canceled':false;
                }
                $scope.lotBtnDisableReason = function () {
                    if(!$scope.isReadOnly && !$scope.selectedCustomer()) return 'Перед добавлением лота необходимо указать Заказчика на вкладке "Извещение о процедуре".';
                    else if($scope.isShowCustomerEISError()) return 'Необходимо обратиться в Службу поддержки Оператора для настройки интеграции для способа закупки ' + $scope.notice.document.typeName + '.';
                    else if(($scope.isCustomerLoading() || !$scope.isLoadTemplate()) && $scope.isNoticeIntegrationEIS()) return 'Загружается информация о полях извещения: просмотр, добавление и редактирование лотов пока невозможно.';
                }
                $scope.hasCustomerError = function() {
                    return ($scope.isNew223() || $scope.isInitedNotice()) && !$scope.isSimpleContest() &&
                        (!$scope.selectedCustomer() || $scope.isShowCustomerEISError() ||
                            (($scope.isCustomerLoading() || !$scope.isLoadTemplate()) && $scope.isNoticeIntegrationEIS()));
                }
            }]
    });