/**
 * Блоки сообщений в шапке
 */
angular.module('uetp')
    .component('headerMsg', {
        templateUrl: 'app/components/header/headerMsg/headerMsg.html',
        bindings: {
            onlyCookies: '<'
        },
        controller: ['$scope', '$rootScope', '$state', 'authSrv', 'desktopSrv', '$timeout', '$filter', 'tariffsSrv', 'profileSrv', 'sysMessageSrv', '$sce', 'coveringLetterSrv', 'participantSrv',
            function ($scope, $rootScope, $state, authSrv, desktopSrv, $timeout, $filter, tariffsSrv, profileSrv, sysMessageSrv, $sce, coveringLetterSrv, participantSrv) {
                $rootScope.$on("exitEvent", function () {
                    _restartPanel($scope.viewModel.showAfterExit)
                });
                this.$onInit = function () {
                    $scope.$state = $state;
                    $scope.auth = authSrv.getAuth;
                    $scope.user = authSrv.getUser;
                    $scope.certValid = authSrv.isCertValid;
                    $scope.certEmpty = authSrv.isCertEmpty;
                    $scope.emailCheck = authSrv.isEmailCheck;
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.errorType = "";
                    $scope.isLatestPlugin = true;
                    $scope.isCertOutdated = false;
                    $scope.hasMchdCheckResult = false;
                    $scope.viewModel = {
                        serverError: false,
                        timeToClose: 30000,
                        showAfterExit:false
                    };
                    _initApprovalTypeshop();
                    $scope.showPanel = tariffsSrv.getShowPanel;
                    $scope.agreeMsg = !authSrv.isSuccessUseCookie();
                    $scope.isCloseTariffPanel = authSrv.isCloseTariffPanel;
                    $scope.accredited = true;
                    $scope.accredExpires = false;
                    $scope.isShowMsg = participantSrv.isShowMsg;
                    $scope.setShowMsg = participantSrv.setShowMsg;

                    $scope.isDemo = isDemoFn;
                }

                profileSrv.checkAccredSecured().then(function (checkRes) {
                    authSrv.updateOrgAccreditation(checkRes.data.result);
                });

                $rootScope.$on("cryptoChecked", function (event, isLatestPlugin) {
                    $scope.isLatestPlugin = isLatestPlugin;
                    if (!$scope.isLatestPlugin) {
                        authSrv.setCertificateError(true);
                    }
                    $timeout($scope);
                });
                $rootScope.$on("login", function () {
                    _restartPanel(false);
                    _initApprovalTypeshop();
                });
                $rootScope.$on("certChecked", function (event, isCertOutdated) {
                    $scope.isCertOutdated = isCertOutdated;
                    if (isCertOutdated) {
                        var certInfo = authSrv.getCertOutdated();
                        var timeCertDate = new Date(certInfo.validToDate).getTime();
                        var timePKDate = new Date($scope.auth().privateKeyNotAfter || certInfo.validToDate).getTime();
                        var date = '';
                        if (timePKDate < timeCertDate) {
                            date = $filter('date')(timePKDate, 'dd.MM.yyyy HH:mm:ss','UTC+3');
                        } else {
                            date = $filter('date')(timeCertDate, 'dd.MM.yyyy HH:mm:ss','UTC+3');
                        }
                        $scope.errorMsg = 'Срок действия сертификата ' + $scope.user().certSubjectName + ' истек ' + date + ' (МСК). Функционал подписи недоступен'
                    }
                    $timeout($scope);
                });
                $rootScope.$on("mchdError", function (event, show, errMsg) {
                    $scope.hasMchdCheckResult = show;
                    if(!show) {
                        authSrv.updateMchdCheckResult(null);
                        delete $scope.mchdErrorMsg;
                    } else {
                        $scope.mchdErrorMsg = errMsg ? errMsg.replace('\n', '<br/>') : authSrv.getMchdCheckResult();
                    }
                });
                $rootScope.$on("certCheckFailed", function () {
                    $timeout($scope);
                });


                $rootScope.$on("checkAccred", function () {
                    $scope.accredited = $scope.auth().orgAccreditation;
                    if(!$scope.accredExpires)
                        $scope.accredExpires = !$scope.accredited && $scope.user().accreditationEnd && $scope.user().accreditationEnd > new Date().getTime();
                });

                $rootScope.$on("pluginNotInstalled", function () {
                    if (authSrv.getAuth().withoutCertEnabled)
                        $scope.errorType = '';
                    else {
                        $scope.errorType = "pluginNotInstalled";
                        authSrv.setCertificateError(true);
                        $timeout($scope);
                    }
                });
                $rootScope.$on("serverErrorEvent", function ($event, result) {
                    if (result.loginOpen) {
                        $rootScope.$emit('openLoginPanelEvent');
                    }
                    if (result.focusMsg) {
                        desktopSrv.scollTop()
                    }
                    $scope.viewModel.serverError = true;
                    if (result.timeToClose) {
                        $scope.viewModel.timeToClose = result.timeToClose;
                    } else {
                        $scope.viewModel.timeToClose = 30000;
                    }
                    $scope.errorMsg = result.msg;
                    $scope.viewModel.showAfterExit = result.showAfterExit?result.showAfterExit:false;
                    $timeout($scope);
                });
                $scope.closeAlertByType = function (type) {
                    $scope.viewModel[type] = false;
                };

                $scope.closeAgreeMsg = function () {
                    authSrv.setIsSuccessUseCookie(true);
                    $scope.agreeMsg = false;
                };
                $scope.closeTariffInfoPanel = function () {
                    tariffsSrv.closeTariffInfoPanel().then(function (response) {
                        if (response.data.success) {
                            authSrv.setCloseTariffPanel(true, $scope.user().id);
                        }
                    }, function (reason) {
                        console.log(reason);
                    });
                };

                function _restartPanel(showAfterExit) {
                    $scope.errorType = "";
                    $scope.isLatestPlugin = true;
                    $scope.isCertOutdated = false;
                    $scope.hasMchdCheckResult = false;
                    //не надо показывать -> сообщение очищаем модель
                    if(!showAfterExit) {
                        $scope.viewModel = {
                            serverError: false,
                            timeToClose: 30000,
                            showAfterExit:false
                        };
                    }
                }

                function _initApprovalTypeshop() {
                    var approvalStates = $scope.user().approvalStates;
                    $scope.approvalStatesMarket = approvalStates && approvalStates.includes("market");
                }

                function isDemoFn(){
                    return $rootScope.properties && $rootScope.properties['demo.mode']==="true";
                }
            }]
    });
