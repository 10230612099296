angular.module('uetp').filter('bytes', function() {
    return function(bytes, precision) {
        if (bytes === 0) { return '0 bytes' }
        if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
        if (typeof precision === 'undefined') precision = 1;

        var units = ['байт', 'Кб', 'Мб', 'Гб', 'Тб', 'Пб'],
            number = Math.floor(Math.log(bytes) / Math.log(1024)),
            val = (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision);

        return  (val.match(/\.0*$/) ? val.substr(0, val.indexOf('.')) : val) +  ' ' + units[number];
    }
}).filter('numberDivide',['$filter', function ($filter) {
    return function(value) {
        if(!value) return "-";
        if(isNaN(Number(value))) return value;
        return $filter('currency')(value, '', 0);
    }
}]).filter('delimiter', function () {
    return function(value) {
        return (''+value).replace('.',',');
    }
}).filter('percent', ['$filter', function ($filter) {
    return function(value) {
        let fractionSize = 2;
        if(value && value.toString().indexOf('.') > 0) {
            const str = value.toString().substring(value.toString().indexOf('.') + 1);
            if(str.length > 2) fractionSize = str.length;
        }
        return $filter('number')(value, fractionSize);
    }
}]);