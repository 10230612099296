//профиль. список филиалов
angular.module('uetp')
    .component('filiationList', {
        templateUrl: 'app/components/profile/filiation/filiationList.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'profileSrv', 'authSrv', 'desktopSrv', 'dialogSrv', '$filter','participantSrv',
            function ($scope, $rootScope, $state, $stateParams, profileSrv, authSrv, desktopSrv, dialogSrv, $filter, participantSrv) {
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.modelViewFiliation = {};
                    $scope.authData = authSrv;
                    $scope.user = authSrv.getUser();
                    $scope.totalPages = 0;
                    $scope.paginator = {
                        itemsPerPage: 20,
                        page: 0
                    };
                    $scope.btnDelete = {};
                    $scope.filiationReadOnly = false;
                    _initModelView();
                    if ($stateParams.id) {
                        $scope.filiationReadOnly = true;
                        loadFiliationListByOrgIdFn($stateParams.id);
                    } else {
                        $scope.filiationReadOnly = false;
                        loadFiliationListFn();
                    }
                    $scope.canCreateFilial = authSrv.getUser().userRole.includes('operator_support_rw') || authSrv.getUser().userRole.includes('participant_admin');
                };
                $scope.loadFiliationList = loadFiliationListFn;
                $scope.loadFiliationListByOrgId = loadFiliationListByOrgIdFn;
                $scope.openFiliation = openFiliationFn;
                $scope.deleteItem = deleteItemFn;

                $scope.btnDeleteClick = function (id) {
                    $scope.btnDelete[id] = !$scope.btnDelete[id]
                };

                /**
                 * Инициализация модели в зависимости от ЛК
                 * @private
                 */
                function _initModelView() {
                    $scope.cabType();
                }

                function openFiliationFn(data) {
                    $state.go('profile.openFiliation', {id: data.id});
                }

                /**
                 * Список филиалов организации
                 */

                function loadFiliationListFn() {
                    profileSrv.loadFiliationList($scope.paginator).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.filiationList = response.data.items;
                            $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                        } else {
                            $scope.filiationList = [];
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    })
                }

                function loadFiliationListByOrgIdFn(id) {
                        participantSrv.getOrgById(id).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.filiationList = response.data.result.filiations;
                            $scope.totalPages = Math.ceil(response.data.result.filiations.length / $scope.paginator.itemsPerPage);
                        } else {
                            $scope.filiationList = [];
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    })
                }

                function deleteItemFn(id) {
                    profileSrv.deleteFiliation(id).then(function successCallback(response) {
                        if (response.data.success) {
                            loadFiliationListFn();
                        } else {
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    })

                }

            }]
    });

