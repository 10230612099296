/**
 * @author dmitrys
 */
angular.module('uetp').directive("inputfiles", [function () {
    return {
        restrict: 'E',
        templateUrl: 'app/directives/inputfiles/inputfiles.html',
        scope: {
            myfile: "@",
            url: '@',
            callback: "=",
            type: "@",
            bankGuaranteeType: "@",//передаем тип вложения через отдельный параметр, чтобы не смешивать наши типы с банковскими
            extraParam: "=", // Дополнительные данные для callback
            additionalFields: "=",
            succsesMsg: "@",
            fileMultiple: "@",
            maxFileSize: "@",//todo будет удалено в 1.21
            alertObj: '=',
            disabled: '@',
            fnDisabled: '&',
            attachmentsLength: '@',//todo будет удалено в 1.21
            streaming:'@',//загрузка отдельными запросами
            onFilenameError: '&',
            maxsize: '@',
            fileNameReg: '@',
            onPreUpload: '=',
            onError: '&',
            onSizeError: '&',
            onSizeEmpty: '&?'
        },
        transclude: true,
        link: function (scope, element, attributes) {

            scope.msie = typeof window.document.documentMode === "number"; //is IE
            var file = element.querySelectorAll('#file');

            file[0].disabled = (scope.disabled === "true");

            if (scope.fileMultiple == 'true' && !scope.msie) {
                file[0].setAttribute("multiple", true);
            }
            if(!scope.streaming)
                scope.streaming = false;
            element.bind("click", function (event) {
                if(scope.fnDisabled) {
                    file[0].disabled = scope.fnDisabled();
                    if(file[0].disabled) {
                        event.stopPropagation();
                        event.preventDefault();
                    }
                }
            })
            element.bind("change", function (changeEvent) {

                const DEFAULT_MAX_ATTACHMENT_SIZE = 5242880;

                let files = changeEvent.target.files,
                    errorMsg = '',
                    maxSizeAttachments = DEFAULT_MAX_ATTACHMENT_SIZE,
                    maxCountAttachments,
                    auth = scope.authSrv.getAuth(),
                    maxFileSize = scope.maxFileSize,
                    maxMultiUpload = auth.maxmultiupload,
                    allFiles = scope.attachmentsLength * 1 + files.length;

                if (files.length === 0) {
                    return;
                }
                // добавлена обработка ошибок, старое поведение пока оставляем
                if(file[0].files[0].size===0 && scope.onSizeEmpty){
                      scope.$apply(function () {
                          scope.onSizeEmpty({ files: file[0].files });
                      });
                      this.value='';
                      return;
                }

                if(scope.maxsize && scope.maxsize < file[0].files[0].size){
                      scope.$apply(function () {
                          scope.onSizeError({ files: file[0].files, maxsize: scope.maxsize });
                      });
                      this.value='';
                      return;
                }
                let fullFiles = file[0].files;
                if(scope.type ==='noticeDocumentationImport' && scope.fileNameReg && fullFiles){
                    const allFilesArrays = [...file[0].files];
                    if(allFilesArrays.some(f => !f.name.match(new RegExp(scope.fileNameReg, "i")))){
                        scope.$apply(function () {
                          scope.onFilenameError({ files: allFilesArrays });
                        });
                    }  
                } else if(scope.fileNameReg && !fullFiles[0].name.match(new RegExp(scope.fileNameReg, "i"))) {
                    scope.$apply(function () {
                        scope.onFilenameError({files: file[0].files});
                    });
                    this.value = '';
                    return;
                }

                if(scope.onPreUpload) {
                    scope.$apply(function () {
                        scope.onPreUpload(function (files, hasPreUploadErrors) {
                            scope.myfile = files ?? file[0].files;
                            scope.hasPreUploadErrors = hasPreUploadErrors;

                            const fcheck = fileChecks([...scope.myfile]);
                            if(!scope.hasPreUploadErrors) {
                                if(fcheck) {
                                    scope.submit(scope.myfile);
                                }
                                else {
                                    fileSubmit(scope.myfile, scope.hasPreUploadErrors);
                                }
                            } else {
                                fileSubmit(scope.myfile, scope.hasPreUploadErrors);
                            }
                            changeEvent.target.value = '';
                        }, changeEvent);
                    });

                    return;
                }

                function fileChecks(myfiles) {
                    let result = true;

                    const localfiles = myfiles ? myfiles : files;

                    // Значения maxSize и maxCount в зависимости от роли
                    const roleSettings = {
                        'participant': {
                            maxSize: auth.maxSizeAttachments,
                            maxCount: auth.maxCountAttachments
                        },
                        'organizer': {
                            maxSize: auth.maxSizeAttachmentsOrg,
                            maxCount: auth.maxCountAttachmentsOrg
                        }
                    };

                    // Значения на основе orgRole
                    const currentRole = roleSettings[auth.orgRole] || {};
                    maxSizeAttachments = currentRole.maxSize;
                    maxCountAttachments = currentRole.maxCount;

                    // Настройки для различных типов
                    const typeSettings = {
                        'regExplanation': { maxSize: auth.maxSizeAttachmentsForExplanationRequest },
                        'bankGuarantee': { maxSize: auth.maxSizeAttachmentsBguarantee },
                        'bankGuaranteeLoko': { maxSize: auth.maxSizeAttachmentsBguaranteeLoko },
                        'bankGuaranteeLokoChat': { maxSize: auth.maxSizeAttachmentsBguaranteeLoko },
                        'bankGuaranteeDecisionLoko': { maxSize: auth.maxSizeAttachmentsBguaranteeLoko },
                        'rfGuarantee': { maxSize: auth.maxSizeAttachmentsRfGuarantee },
                        'eisXml': { maxSize: auth.maxSizeEisXml },
                        'regExplanationOrg': { maxSize: auth.maxSizeAttachments, maxCount: auth.maxCountAttachments },
                        'answerExplanationOrg': { maxSize: auth.maxSizeAttachments, maxCount: auth.maxCountAttachments },
                        'purchaseContract': { maxSize: auth.maxSizeAttachmentsContract, maxCount: auth.maxCountAttachmentsContract },
                        'ContractRelated': { maxSize: auth.maxSizeAttachmentsContract, maxCount: auth.maxCountAttachmentsContract }
                    };

                    const currentTypeSettings = typeSettings[scope.type] || {};
                    if (currentTypeSettings.maxSize) {
                        maxSizeAttachments = currentTypeSettings.maxSize;
                    }
                    if (currentTypeSettings.maxCount) {
                        maxCountAttachments = currentTypeSettings.maxCount;
                    }

                    if (localfiles.length > maxMultiUpload) {
                        errorMsg = 'За один раз можно добавить не больше ' + maxMultiUpload + ' вложений. Вы выбрали ' + localfiles.length;
                        result = false;
                    } else if ((scope.type === 'procedures' || scope.type === 'purchaseContract' || scope.type === 'ContractRelated') && allFiles > maxCountAttachments) {
                        errorMsg = 'Невозможно добавить выбранные файлы, так как общее количество вложений превысит максимально разрешенное - ' + maxCountAttachments + '.'
                        result = false;
                    } else {
                        angular.forEach(localfiles, function (file) {
                            if (file.size === 0) {
                                errorMsg = 'Файл ' + file.name + ' не добавлен.<br/>Невозможно добавить пустой файл.<br/>';
                                file.err = errorMsg;
                                result = false;
                            }
                            if (maxSizeAttachments < file.size && scope.type !== 'noticeImport') {
                                if (['procedures', 'answerExplanation', 'accreditation_other', 'accreditation_maxSum',
                                    'accreditation_chief', 'accreditation_proxy', 'accreditation_founder', 'accreditation_egrul',
                                    'rejectProcedure', 'regExplanation', 'cancelLots', 'additionalProtocol', 'regExplanationOrg',
                                    'answerExplanationOrg', 'purchaseContract', 'ContractRelated', 'eisXml', 'noticeDocumentationImport'].includes(scope.type)) {
                                    errorMsg = 'Файл ' + file.name + ' не добавлен.<br/>' +
                                        'Превышен максимально разрешенный размер файла ('
                                        + (maxSizeAttachments / 1048576).toFixed(2) + ' Мб)!<br/>' +
                                        'Размер файла ' + file.name + ': ' + (file.size / 1048576).toFixed(2) + ' Мб.';
                                    file.err = errorMsg;
                                    result = false;
                                } else {
                                    errorMsg = 'Файл ' + file.name + ' не добавлен.<br/>' +
                                        'Максимальный размер файла ('
                                        + (maxSizeAttachments / 1048576).toFixed(2) + ' Мб)!<br/>';
                                    result = false;
                                }

                            }
                            if (maxFileSize < file.size) {
                                errorMsg = 'Файл ' + file.name + ' не добавлен.<br/>' +
                                    'Превышен максимально разрешенный размер файла ('
                                    + (maxFileSize / 1048576).toFixed(2) + ' Мб)!<br/>' +
                                    'Размер файла ' + file.name + ': ' + (file.size / 1048576).toFixed(2) + ' Мб.';
                                file.err = errorMsg;
                                result = false;
                            }
                            if (file.name && file.name.toLowerCase().endsWith('.exe')) {
                                errorMsg = 'Файл ' + file.name + ' не добавлен.<br/>' +
                                    'Добавление файлов с расширением *.exe запрещено!';
                                file.err = errorMsg;
                                result = false;
                            }
                        });
                    }

                    return result && !(localfiles || {})?.some(f=>f.err);
                }

                function fileSubmit(myfiles, hasPreuploadErrors) {
                    let allError = true;
                    let allErrorMsg = '';

                    const localfiles = myfiles ? myfiles : files;

                    angular.forEach(localfiles, function (file) {
                        if(!file.err) allError = false;
                        else allErrorMsg +=(file.err.endsWith('<br/><br/>') ? file.err : `${allErrorMsg ? '<br/>' + file.err : file.err}`);
                    });

                    if ((errorMsg && localfiles.length === 1) || (errorMsg && allFiles > maxCountAttachments) || localfiles.length > maxMultiUpload) {
                        if(hasPreuploadErrors) {
                            allErrorMsg += `${allErrorMsg ? (allErrorMsg.endsWith('<br/>') ? '<br/>' : '<br/><br/>'):''}${hasPreuploadErrors}`;
                        }
                        scope.cancelSubmit(errorMsg);
                    } else if (allError) {
                        if(hasPreuploadErrors) {
                            allErrorMsg += `${allErrorMsg ? (allErrorMsg.endsWith('<br/>') ? '<br/>' : '<br/><br/>'):''}${hasPreuploadErrors}`;
                        }
                        scope.cancelSubmit(allErrorMsg);
                    } else {
                        scope.myfile = localfiles;
                        scope.submit(scope.myfile, hasPreuploadErrors);
                        
                        if(allErrorMsg) {
                            scope.cancelSubmit(allErrorMsg);
                        }
                    }
                }
                
                const allFilesArrays = [...files];
                fileChecks(allFilesArrays);
                fileSubmit(allFilesArrays);

                //сбрасываем файл
                changeEvent.target.value = '';
            });

            if ('accept' in attributes) {
                attributes.$observe('accept', function uploadButtonAcceptObserve(value) {
                file.attr('accept', value);
              });
            }

        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'upload', 'alertSrv', 'authSrv', '$timeout',
            function ($scope, $rootScope, $state, $stateParams, upload, alertSrv, authSrv, $timeout) {
                $scope.authSrv = authSrv;
                var errorArr;
                function alertObjEvent(data) {
                    if (typeof $scope.alertObj === 'function') {
                        $scope.alertObj(data)
                    } else {
                        $scope.alertObj = data;
                    }
                }
                $scope.submit = function (files, error) {
                    errorArr = [];
                    var successArr = [];
                    angular.forEach(files, function (file) {
                        if (file.err) {
                            errorArr.push(file);
                        } else {
                            successArr.push(file);
                        }
                    });

                    var data = null;
                    if ($scope.msie) {
                        //загрузка одного файла
                        data = {aFile: files[0]};
                        if($scope.additionalFields) {
                            const val = $scope.additionalFields();
                            Object.keys(val).forEach(k=>data.append(k, val[k]));
                        }
                        loadFile(data).then(
                            function successCallback(response) {
                                if (response.data.success) {
                                    if ($scope.callback) {
                                        if ($scope.type === 'certificate') {
                                            $scope.callback(response, $scope.type);
                                        } else if ($scope.type === 'bankGuarantee' || $scope.type === 'rfGuarantee' || $scope.type === 'bankGuaranteeLoko'){
                                            //передаем тип банковского вложения
                                            $scope.callback(response.data.result, $scope.bankGuaranteeType, $scope.extraParam);
                                        } else if ($scope.type === 'nomenclatureImport'){
                                            $scope.callback(response.data);
                                        } else {
                                            $scope.callback(response.data.result, $scope.type);
                                        }
                                    }
                                }
                                initMess(response, error);
                            },
                            function errorCallback(response) {
                                alertObjEvent(alertSrv.getAlertObj(response));
                            }
                        );
                    } else {
                        //Поочередная загрузка отдельными запросами
                        if($scope.streaming && $scope.streaming !== "false") {
                            new Promise(function (resolve) {
                                var responseArray = [];
                                var fileCount = successArr.length;
                                let commonResult = false; // итоговый результат
                                angular.forEach(successArr, function (file) {
                                    data = {aFile: file};
                                    loadFile(data).then(
                                        function successCallback(response) {
                                            if (response.data.success) {
                                                if ($scope.callback) {
                                                    if ($scope.type === 'certificate') {
                                                        $scope.callback(response, $scope.type);
                                                    } else if ($scope.type === 'bankGuarantee' || $scope.type === 'rfGuarantee' || $scope.type === 'bankGuaranteeLoko'){
                                                        //передаем тип банковского вложения
                                                        $scope.callback(response.data.result, $scope.bankGuaranteeType, $scope.extraParam);
                                                    } else if ($scope.type === 'nomenclatureImport'){
                                                        $scope.callback(response.data);
                                                    } else if ($scope.type === 'noticeDocumentationImport' && $scope.callback(response.data.result, $scope.type) && !commonResult){
                                                        commonResult = true;
                                                    } else {
                                                        $scope.callback(response.data.result, $scope.type);
                                                    }
                                                }
                                            } else if(response.data.errorCode==="001"){
                                                $state.go('home');
                                            }
                                            responseArray.push(response);
                                            checkCollectResponses();
                                        },
                                        function errorCallback() {
                                            //responseArray.push(response);
                                            checkCollectResponses();
                                        }
                                    );
                                });
                                /*
                                * Отправили все файлы -> выходим
                                * */
                                function checkCollectResponses() {
                                    fileCount--;
                                    if(fileCount===0){
                                        resolve(responseArray);
                                    }
                                }
                            }).then(function successCallback(response) {
                                let resp = response[0];
                                if (response.length > 1)
                                    for (let i = 1; i < response.length; i++) {
                                        resp.data.result.push(response[i].data.result);
                                    }
                                initMess(resp, error);
                            });
                        }else{
                            //мульти загрузка одним запросом
                            var fd = new FormData();
                            angular.forEach(successArr, function (file) {
                                fd.append('file[]', file);
                            });
                            if($scope.additionalFields) {
                                const val = $scope.additionalFields();
                                Object.keys(val).forEach(k=>fd.append(k, val[k]));
                            }
                            data = fd;
                            loadFile(data).then(
                                function successCallback(response) {
                                    if (response.data.success) {
                                        if ($scope.callback) {
                                            if ($scope.type === 'certificate') {
                                                $scope.callback(response, $scope.type);
                                            } else if ($scope.type === 'bankGuarantee' || $scope.type === 'rfGuarantee' || $scope.type === 'bankGuaranteeLoko') {
                                                //передаем тип банковского вложения
                                                $scope.callback(response.data.result, $scope.bankGuaranteeType, $scope.extraParam);
                                            } else if (['nomenclatureImport','mchdImport'].includes($scope.type)) {
                                                $scope.callback(response.data);
                                            } else {
                                                $scope.callback(response.data.result, $scope.type);
                                            }
                                        }
                                    } else if ($scope.callback && $scope.type === 'mchdImport') {
                                        $scope.callback(response.data);
                                    }
                                    initMess(response);
                                },
                                function errorCallback(response) {
                                    alertObjEvent(alertSrv.getAlertObj(response));
                                }
                            );
                        }
                    }
                    function loadFile(data) {
                        var url = $scope.url.startsWith('/') ? ('rest'
                            + $scope.url) : ('rest/' + $scope.url);
                        return upload({
                            url: url,
                            method: 'POST',
                            headers: {
                                'Content-Type': 'multipart/form-data; charset=utf-8'
                            },
                            isFile:true,
                            data: data
                        });
                    }
                    function initMess(response, error) {
                        var message = null, haveError = false;
                        if (response.data && response.data.success) {
                            if ($scope.type === 'certificate') {
                                if(response.data.errorCode==='406_3' || response.data.errorMessage)
                                    message = response.data.errorMessage;
                                else
                                    message = 'Сертификат добавлен!<br/>Для завершения нажмите кнопку Сохранить изменения.';
                            } else if ($scope.type === 'noticeImport') {
                                message = '';
                                if (response.data.result.length) {
                                    angular.forEach(response.data.result, function (res) {
                                        message += (res + '<br/>');
                                    });
                                }
                            } else if ($scope.type === 'importBuhReport' && response.data.message) {
                                message = response.data.message;
                            } else if ($scope.type === 'nomenclatureImport') {
                                if(response.data.errorMessage) {
                                    message = response.data.errorMessage;
                                    haveError = true;
                                } else {
                                    return;
                                }
                            } else if ($scope.type === 'mchdImport') {
                                return;
                            } else if (response.data.result && response.data.result.length === 1) {
                                message = 'Файл добавлен.';
                                if (errorArr.length) {
                                    angular.forEach(errorArr, function (file) {
                                        message += (message.endsWith('<br/>') ? '<br/>' : '<br/><br/>') + file.err;
                                        haveError = true;
                                    });
                                }
                                if($scope.hasPreUploadErrors) {
                                    if(error) {
                                        message += `${message ? (message.endsWith('<br/>') ? '<br/>' : '<br/><br/>'):''}${error}`;
                                    }
                                    delete $scope.hasPreUploadErrors;
                                }

                            } else if (response.data.result && response.data.result.length !== 1){
                                message = 'Добавлено файлов: ' + response.data.result.length + '.';
                                if (errorArr.length) {
                                    angular.forEach(errorArr, function (file) {
                                        message += (message.endsWith('<br/>') ? '<br/>' : '<br/><br/>') + file.err;
                                        haveError = true;
                                    });
                                }
                                if($scope.hasPreUploadErrors) {
                                    if(error) {
                                        message += `${message ? (message.endsWith('<br/>') ? '<br/>' : '<br/><br/>'):''}${error}`;
                                    }
                                    delete $scope.hasPreUploadErrors;
                                }
                            }
                        } else if (response.data && !response.data.success && $scope.type === 'mchdImport') {
                            return;
                        }
                        if (haveError || error) {
                            alertObjEvent(alertSrv.getErrorMsg($scope.succsesMsg || message))
                        } else {
                            alertObjEvent(alertSrv.getAlertObj(response, $scope.succsesMsg || message || response.data.message));
                        }
                    }
                };
                $scope.cancelSubmit = function (msg) {
                    alertObjEvent(alertSrv.getErrorMsg(msg));
                    $timeout(function () {
                        if ($scope) $scope.$apply();
                    });
                }
            }]
    }
}]);
