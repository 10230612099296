angular.module('uetp').service('homeNewsSrv', [
    '$http', '$rootScope', 'dialogSrv', 'httpSrv',
    function ($http, $rootScope, dialogSrv, httpSrv) {
        this.getNewsById = function (id, cb) {
            httpSrv.http({
                method: 'GET',
                url: '/news/newsItem/' + id
            }).then(
                function successCallback(response) {
                    if (response.data.success) cb(response.data.result);
                },
                function errorCallback(response) {
                    console.log(response);
                }
            );
        };

        this.getPublishedNews = function (paginator, cb) {
            httpSrv.http({
                url: '/news/newsPublishedList',
                data: paginator,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    var newsCount = response.data.total > 3 ? 3 : response.data.total;
                    cb(
                        response.data.items,
                        Math.ceil(
                            response.data.total / paginator.itemsPerPage,
                            response.data.items.slice(0, newsCount)
                        )
                    );
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }
    }
]);