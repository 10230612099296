/**
 * @author dmitrys
 */
angular.module('uetp').directive("ngClickConfirm", ['$parse','needConfirmSrv',function ($parse,needConfirmSrv) {
    return {
        restrict: 'A',
        link: function(scope, element, attr) {
            var fn = $parse(attr['ngClickConfirm']),
                conditionFn   = angular.isDefined(attr['confirmCondition']) ? $parse(attr['confirmCondition']) /*scope.confirmCondition*/ : null,
                confirmTextFn = angular.isDefined(attr['confirmTextFn']) ? $parse(attr['confirmTextFn']) : null;
            scope.element = element;
            element.on('click', function (event) {
                event.target.blur && event.target.blur();
                scope.$apply(function () {
                    if (conditionFn && !conditionFn(scope)) {
                        fn(scope, {$event: event, $model: {close: true}});
                        return;
                    }

                    var text = confirmTextFn ? confirmTextFn(scope, {$event: event}) : element.attr('confirmText');
                    var isWithoutPrefix = element.attr('withoutPrefix') ? element.attr('withoutPrefix') : false;
                    var btnOkText = element.attr('btnOkText') ? element.attr('btnOkText') : 'ОК';
                    var prefix = isWithoutPrefix ? '' : 'Выполнить действие: ';
                    needConfirmSrv.openModal('_confirmInfo.html', {message: prefix+text+'?', btnOkText: btnOkText}).then(function (value) {
                        if(value) {
                            fn(scope, {$event: event});
                        }
                    });
                });
            });
        }
    }
}]).service('needConfirmSrv', ['$sce','$uibModal',
    function ($sce,$uibModal) {
        var service = this;
        service.call = callFn;
        service.callVariants = callVariantsFn;
        service.openModal = function(modal, data) {
            return $uibModal.open({
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                size: 'md',
                templateUrl: modal,
                backdrop: 'static',
                keyboard: false,
                controller:['$scope', '$uibModalInstance', '$rootScope', function ($scope, $uibModalInstance, $rootScope) {
                    $scope.model = {
                        message: data.message,
                        btnOkText: data.btnOkText ? data.btnOkText : 'ОК'
                    };

                    $scope.closeModal = function (type) {
                        if(!type)
                            $rootScope.$emit('enableActions');
                        $uibModalInstance.close(type);
                    };
                }]
            }).result.then(function (value) {
                return Promise.resolve(value)
            })
        };

        /**
         * Вызов всплывающего окна подтверждения
         * @param fn - функция
         * @param params - параметры, оборачивать в []
         * @param msg - сообщение в окне
         * --  needConfirmSrv.call(saveModelFn,[$scope.model,param2, param3],'Сохранить?') ---
         */
        function callFn(fn,params,msg) {
            service.openModal('_confirmInfo.html', {message: msg}).then(function (value) {
                if(value) {
                    if (params) {
                        fn.apply(this,params);
                    } else {
                        fn();
                    }

                }
            });
        }
        /**
         * Вызов всплывающего окна подтверждения (с обработчиком на отмену)
         * @param fn - OK-функция
         * @param fn2 - CANCEL-функция
         * @param params - параметры, оборачивать в []
         * @param msg - сообщение в окне
         * --  needConfirmSrv.call(saveModelFn,[$scope.model,param2, param3],'Сохранить?') ---
         */
        function callVariantsFn(fn,fn2,params,msg) {
            service.openModal('_confirmInfo.html', {message: msg}).then(function (value) {
                if(value) {
                    if (params) {
                        fn.apply(this,params);
                    } else {
                        fn();
                    }
                } else {
                    if (params) {
                        fn2.apply(this,params);
                    } else {
                        fn2();
                    }
                }
            });
        }
    }]);
