/**
 * @author: sergeyu
 * Компонент подвала
 */
angular.module('uetp')
    .component('footertoolbar', {
        templateUrl:['constants', function (constants) {
            return 'app/components/footer/' + constants.configProject.type + '.html'
        }],
        controller: ['$scope', '$state',
            function ($scope, $state) {
                this.$onInit = function () {
                    $scope.year = new Date().getFullYear();
                    $scope.$state=$state;
                };
            }]
    });

