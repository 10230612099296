/**
 * @author: sergeyu
 * Заказчики компании
 */
angular.module('uetp')
    .component('customersTass', {
        bindings: {
            tassInn: '=',
            initLoadData: '<' // загрузить данные для отображения когда станет true
        },
        templateUrl: 'app/components/widgets/tassBusiness/companyValidate/view/customers/customers.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'tassBusinessSrv', 'filterService', 'tablesSrv', 'paginationSrv', 'scService', 'propertiesService',
            function ($scope, $rootScope, $state, $stateParams, tassBusinessSrv, filterService, tablesSrv, paginationSrv, scService, propertiesService) {
                var ctrl = this;
                $scope.loaded = false; // контроль загруки. Один раз при первом отображении

                $scope.loadData = loadDataFn;
                $scope.sortBy = sortByFn;

                $scope.modelView = {};


                this.$onInit = function () {
                    $scope.paginator = {
                        itemsPerPage: 20,
                        page: 0
                    };
                    $scope.sortByObject = tablesSrv.initSort($scope.sortByObject, $scope.paginator);
                    if(!$scope.loaded) $scope.loadData();

                };
                this.$onChanges = function (changesObj) {
                    if (changesObj.initLoadData.currentValue) {
                        $scope.paginator.search === "" ? scService.clearCounter() : scService.setCounter($scope.paginator.count);
                        filterService.initModelView('ИНН, наименование заказчика', 'Заказчики');
                    }
                };
                function loadDataFn() {
                    $scope.loaded = false;
                    $scope.errorInfo = "";
                    if (!$scope.paginator.orderBy) {
                        $scope.paginator.orderBy = "total_sum";
                        $scope.paginator.orderAsc = false;
                        $scope.sortByObject.total_sum = {
                            "activate": true,
                            "orderAsc": false,
                            "tag": "desc"
                        };
                    }
                    filterService.initModelView('ИНН, наименование заказчика', 'Заказчики');
                    prepareTableParams('filterCompanyValidateCustomers');
                    return propertiesService.getProperty("otherProcedures.url").then(function (host) {
                        return tassBusinessSrv.getCustomers($scope.paginator, ctrl.tassInn, host, $scope).then(function (response) {
                            $scope.errorInfo = null;
                            if (response.data.success) {
                                $scope.modelView = response.data;
                                if (response.data.message) {
                                    $scope.modelView.allSum = response.data.message;
                                    $scope.modelView.allCount = response.data.total;
                                    $scope.modelView.allContractCount = response.data.notRead;
                                }
                                $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                                if ($scope.paginator.search !== "") {
                                    $scope.paginator.count = response.data.total;
                                    scService.setCounter($scope.paginator.count);
                                } else {
                                    scService.clearCounter();
                                }
                                if ($scope.modelView.items.length === 0) {
                                    $scope.errorInfo = "Данных по заказчикам компании не найдено."
                                }
                                $scope.loaded = true;
                            } else {
                                $scope.errorInfo = response.data.errorMessage ? response.data.errorMessage : "Получить информацию о компании в данный момент невозможно.";
                                $scope.loaded = false;
                            }
                        }, function () {
                            $scope.errorInfo = "Получить информацию о компании в данный момент невозможно.";
                            $scope.loaded = false;
                        })
                    });
                }

                function sortByFn(by) {
                    tablesSrv.sortBy(by, $scope.sortByObject, $scope.paginator, $scope.loadData);
                }

                function prepareTableParams(filterType) {
                    var filterParams = angular.copy(filterService.getFilterParams(filterType)),
                        searchParam = angular.copy(filterService.getSearchParam(filterType));
                    if (filterParams && Object.keys(filterParams).length > 0) {
                        $scope.paginator.searchByField = filterParams;
                    } else {
                        delete $scope.paginator.searchByField
                    }
                    $scope.paginator.search = searchParam;

                }
            }]
    });

