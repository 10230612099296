/**
 * @author: sergeyu
 * Профиль пользователя. Реквизиты организации
 */
angular.module('uetp')
    .component('requisitesProfile', {
        templateUrl: 'app/components/profile/requisites/requisites.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'profileSrv', 'authSrv', 'desktopSrv', 'dictionarySrv','alertSrv', 'httpSrv', 'dialogSrv', 'organizatorSrv',
            function ($scope, $rootScope, $state, $stateParams, profileSrv, authSrv, desktopSrv, dictionarySrv, alertSrv, httpSrv, dialogSrv, organizatorSrv) {
                $rootScope.$on('cabTypeChange', _initModelView);

                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.modelViewRequisite = {subjectMsp: false};
                    $scope.options = {};
                    $scope.form = {profileForm: {}};
                    $scope.userCertificates = {};
                    $scope.authData = authSrv;
                    $scope.typeRequisition = '';
                    $scope.active = {};
                    $scope.newOrganizer = "";
                    $scope.isReadOnly = isReadOnlyFn;
                    $scope.user = angular.copy(authSrv.getUser());
                    $scope.dictionary = dictionarySrv.getRemoteData();
                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                    angular.forEach(dictionarySrv.getStaticDictByType('typeOrg'), function (type) {
                        if($scope.user.typeOrg === type.id){
                            $scope.typeOrg = type.name
                        }
                    });
                    dictionarySrv.resetPaginatedDict($scope);
                    $scope.docs={
                        accreditation_egrul:{},
                        accreditation_egrip:{},
                        accreditation_founder:{},
                        accreditation_proxy:{},
                        accreditation_chief:{},
                        accreditation_passport:{},
                        accreditation_maxSum:{},
                        accreditation_other:{}
                    };
                    $scope.accredited = authSrv.getAuth().orgAccreditation;
                    $rootScope.$on("checkAccred", function () {
                        $scope.accredited = authSrv.getAuth().orgAccreditation;
                    });
                    fillDocsByType($scope.user.applications);
                    fillRequisitesFn();
                    _initTypeOrg();
                    _initModelView();
                    // Для ИП подмена ФИО
                    if($scope.active.type===2 && $scope.userProfile.fullName) {
                        const arr = angular.copy($scope.userProfile.fullName).split(' ');
                        $scope.userProfile.lastName = arr.shift();
                        $scope.userProfile.firstName = arr.shift();
                        $scope.userProfile.middleName = arr.length===0 ? '' : (arr.length>1 ? arr.join(' ') : arr[0]);
                    }
                };
                $scope.bikChangeHandler = bikChangeHandlerFn;
                $scope.refreshDict = refreshDictFn;
                $scope.updateOrg = updateOrgFn;
                $scope.checkSubjectMSP = checkSubjectMSPFn;
                $scope.downloadApplication = downloadApplicationFn;
                $scope.changeOrganizer = changeOrganizerFn;
                $scope.sendConfirmationOrgMail = sendConfirmationOrgMailFn;
                $scope.rejectConfirmationOrgMail = rejectConfirmationOrgMailFn;
                setTimeout(function(){//-states?
                    if ($scope.form.profileForm && typeof $scope.form.profileForm.$setPristine == "function")$scope.form.profileForm.$setPristine();
                },500);

                function _initDict(dictList, reload) {
                    angular.forEach(dictList, function (name) {
                        dictionarySrv.getDictionaryRemote(name, reload);
                    })

                }

                function _initTypeOrg() {
                    var typeOrg = $scope.user.typeOrg;
                    $scope.active.type = typeOrg === 'ul' ? 1 : typeOrg === 'nr' ? 4 : typeOrg === 'fl' ? 3 : 2;
                }

                function _initModelView() {
                    var cabType = $scope.cabType();
                    if (cabType === 'admincab') {
                        _initDict(['okcm', 'fias_region'], false);
                        $scope.typeRequisition = 'app/components/profile/requisites/requisitionCabType/requisitesAdminCab.html'
                    } else if (cabType === 'participant') {
                        _initDict(['okcm', 'fias_region'], false);
                        $scope.typeRequisition = 'app/components/profile/requisites/requisitionCabType/requisitesParticipant.html';
                        $scope.showAccreditation = authSrv.getAuth().userRole.includes('participant_admin');
                    } else if (cabType === 'organizer') {
                        $scope.typeRequisition = 'app/components/profile/requisites/requisitionCabType/requisitesOrganizerCab.html';
                        getAvailableOrganizers();
                    } else if (cabType === 'accountant' ) {
                        _initDict(['okcm', 'fias_region'], false);
                        $scope.typeRequisition = 'app/components/profile/requisites/requisitionCabType/requisitesAccountant.html'
                    } else if ( ['inspector','support'].includes(cabType)) {
                        _initDict(['okcm', 'fias_region'], false);
                        $scope.typeRequisition = 'app/components/profile/requisites/requisitionCabType/requisitesInspector.html'
                    } else if (cabType === 'agent') {
                        _initDict(['okcm', 'fias_region'], false);
                        $scope.typeRequisition = 'app/components/profile/requisites/requisitionCabType/requisitesAgent.html';
                    }

                }

                function getAvailableOrganizers(){
                    $scope.organizersAvailable = false;
                    profileSrv.getAvailableOrganizers().then(function(response){
                        if (response.data.success){
                            $scope.availableOrganizers = response.data.result;
                            $scope.organizersAvailable = $scope.availableOrganizers && $scope.availableOrganizers.length>1;
                        }
                    })
                }

                function changeOrganizerFn(id) {
                    authSrv.changeOrganizer(id).then(function () {

                            //после получения данных авторизации, в т.ч. нового токена, получаем данные организации и обновляем профиль
                            profileSrv.loadOrganizationInfo().then(function (userData) {
                                if (userData.data.success) {
                                    var user = userData.data.result;
                                    authSrv.setUser(user);
                                    authSrv.checkProfileCert();
                                    authSrv.setProfileSuccess(user.approvalOrg);
                                    authSrv.setReglamentSuccess(user.approvalReglament);
                                    authSrv.setUserValid(profileSrv.validateProfileModel(user));
                                    $state.reload();
                                } else {
                                    authSrv.exit();
                                }
                            });

                    });
                }

                $scope.changeOrg = function(select){
                    //+ мб доп действия
                    $scope.newOrganizer = select.$select.selected?select.$select.selected.id:null;
                }

                function downloadApplicationFn(accredId, attachId, attachName) {
                    httpSrv.downloadFile('/fs/file/downloadAttachment/' + accredId + '/' + attachId, attachName);
                }

                /**
                 * Раскрытие селекта для выбора типа МСП
                 */
                function checkSubjectMSPFn() {
                    $scope.modelViewRequisite.subjectMsp = !$scope.modelViewRequisite.subjectMsp
                }

                /**
                 * Заполнить реквизиты организации
                 */
                function fillRequisitesFn() {
                    $scope.mspCategory = dictionarySrv.getStaticDictByType('mspCategory');
                    $scope.modelViewRequisite.subjectMsp = !!$scope.user.mspType;
                    $scope.options.kppDisabled = !!$scope.user.kpp;
                    $scope.options.accountEnabled = $scope.authData.getAuth().accountEnabled === 'true';
                    $scope.isChecked = organizatorSrv.equalsWithExceptions($scope.user.regAddress, $scope.user.realAddress, 'id') || !$scope.user.realAddress || !$scope.user.realAddress.oksmCode;
                    showProfileDlg();

                    function showProfileDlg() {
                        $scope.userProfile = angular.copy($scope.user);
                        if ($scope.newRegEtzp && !$scope.userProfile.regAddress) {
                            $scope.userProfile.regAddress = {oksmCode: '643'};
                        }
                        $scope.oldOrgMail = $scope.userProfile.orgMail;
                        if ($scope.userProfile.orgMailConfirm) {
                            showOrgMailChangeMsg();
                        }
                    }
                }

                /**
                 * Обновление реквизитов организации
                 */
                function updateOrgFn(user) {
                    $scope.submitted = true;
                    if ($scope.form && !$scope.form.profileForm.$valid) {
                        $scope.alertObjRequisitionCab = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');
                        return;
                    }
                    if (user.middleName === undefined || user.middleName === "undefined") {
                        user.middleName = '';
                    }

                    profileSrv.updateOrg(user).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.form.profileForm.$setPristine();
                            $scope.submitted = false;
                            profileSrv.loadOrganizationInfo().then(function successCallback(response) {
                                if (response.data.success) {
                                    var userData = response.data.result;
                                    if (userData.lastName) userData.lastName = userData.lastName.replace("null", "");
                                    if (userData.firstName) userData.firstName = userData.firstName.replace("null", "");
                                    if (userData.middleName) userData.middleName = userData.middleName.replace("null", "");
                                    authSrv.setProfileSuccess(userData.approvalDate !== undefined);
                                    authSrv.setReglamentSuccess(userData.approvalReglamentDate !== undefined);
                                    authSrv.setUserValid(profileSrv.validateProfileModel(userData));
                                    profileSrv.setMaxCountAttachments(userData);
                                    if ($scope.cabType()=='participant'&&$scope.oldOrgMail != $scope.userProfile.orgMail) {
                                        $scope.userProfile.orgMailConfirm = $scope.userProfile.orgMail;
                                        showOrgMailChangeMsg();
                                        if (!$scope.oldOrgMailEmail) $scope.oldOrgMailEmail = $scope.userProfile.orgMailConfirm;
                                    }
                                    userData.orgMail = $scope.oldOrgMail;
                                    authSrv.setUser(userData);
                                    $scope.user = userData;
                                    $scope.userProfile = angular.copy($scope.user);
                                    $scope.alertObjRequisitionCab = alertSrv.getAlertObj(response,'Изменение реквизитов организации произведено успешно!');
                                }else{

                                    $scope.alertObjRequisitionCab = alertSrv.getAlertObj(response);
                                }
                            });
                        } else {
                            $scope.alertObjRequisitionCab = alertSrv.getAlertObj(response, response.data.message);
                        }
                        delete $scope.prevRealAddress;
                        localStorage.setItem("userName", user.lastName + " " + user.firstName + " " + user.middleName);
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }

                /**
                 * Совпадает ли адрес с Юр.адресом
                 * @param checked
                 */
                $scope.checkedAddress = function (checked) {
                    if (!checked) {
                        $scope.prevRealAddress = angular.copy($scope.userProfile.realAddress);
                        delete $scope.userProfile.realAddress;
                    } else {
                        $scope.userProfile.realAddress = angular.copy($scope.prevRealAddress);
                    }
                    $scope.form.profileForm.$setDirty();
                };

                /**
                 * Заполнить Номер корреспондентского счета банка из выбранного банка
                 */
                function bikChangeHandlerFn(selectObj, dataObj) {
                    var selected = selectObj.$select.selected,
                        bankNameProperty = dataObj.bankName == undefined ? "bank" : "bankName";//т.к. отличаются названия полей в json

                    dataObj[bankNameProperty] = selected ? selected.participant : "";
                    dataObj.bankAcc = selected ? selected.accounts : "";
                }

                /**
                 * Обновление справочника
                 * @param object - тип справочника
                 * @param value - введенные значения
                 * @param refreshScrolling - обновление данных при прокрутке
                 */
                function refreshDictFn(object, value, refreshScrolling,needToAdd, searchValue) {
                    if (!value) {
                        if (object === 'rBIK') {
                            value = searchValue;
                        }
                    }
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling, null, needToAdd)
                }
                function fillDocsByType(docArray){
                    if (!Array.isArray(docArray)) return;
                    docArray.forEach(function(el){
                        $scope.docs[el.type] =  el;
                    })
                }

                function showOrgMailChangeMsg() {
                    var msg = (!$scope.oldOrgMail || $scope.oldOrgMail == $scope.userProfile.orgMailConfirm) ?
                              'Для подтверждения ' + $scope.userProfile.orgMailConfirm + ' перейдите по ссылке в письме.' :
                              'Для подтверждения изменения на ' + $scope.userProfile.orgMailConfirm + ' перейдите по ссылке в письме.';
                    $scope.alertObjUserOrgMail = alertSrv.getSuccessMsgWithBtn(msg);
                }

                function sendConfirmationOrgMailFn(model) {
                    var dataObj = {
                        param: model.orgMail
                    };
                    profileSrv.sendConfirmationOrgMail(dataObj).then(function (response) {
                        if (response.data.success) {
                            dialogSrv.showDialogMsg($scope, "На указанный адрес электронной почты отправлено письмо с ссылкой для подтверждения адреса");
                        } else {
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }

                    })
                }

                function rejectConfirmationOrgMailFn() {
                    profileSrv.rejectConfirmationOrgMail().then(function (response) {
                        if (response.data.success) {
                            $scope.userProfile.orgMailConfirm = "";
                            profileSrv.loadOrganizationInfo().then(function (response) {
                                if (response.data.success) {
                                    var userData = response.data.result;
                                    authSrv.setUser(userData);
                                    $scope.userProfile = userData;
                                    $scope.oldOrgMail = $scope.userProfile.orgMail;
                                    $scope.alertObjRejectOrgMail = alertSrv.getAlertObj(response, 'Операция отменена!');
                                } else {
                                    $scope.alertObjUserOrgMail = alertSrv.getAlertObj(response);
                                }
                            });
                        } else {
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }

                    })
                }

                function isReadOnlyFn() {
                    return authSrv.getAuth().userRole.includes("bg_agent_user");
                }
            }]
    });

