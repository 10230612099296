angular.module('uetp')
    .directive('currencyDirective', function() {
        return {
            link:function($scope, element, attrs) {
                $scope.$watch(attrs.currencyDirective,function(value){
                        var val = value.toString(),
                            text;
                        if(val === '643') {
                            text = 'RUB'
                        } else if(val === '156'){
                            text = 'CNY'
                        } else if(val === '840'){
                            text = 'USD'
                        } else if(val === '978'){
                            text = 'EUR'
                        }
                        element.text(text);
                    }
                );
            }
        }
    });