/**
 * Директива для поля дата. Где надо выводить время по МСК вне таймзоны пользователя
 */
angular.module('uetp').directive('mskDate', function () {
    return {
        require: 'ngModel',
        priority: 1,
        link: function (scope, element, attrs, ctrl) {
            ctrl.$formatters.push(function (value) {
                if(value) {
                    var date = new Date(value);
                    return new Date((date.getTime() + (180 * 60000))+ (60000 * date.getTimezoneOffset()));
                }
            });

            ctrl.$parsers.push(function (value) {
                if(value) {
                    return new Date((value.getTime() - (180 * 60000)) - (60000 * value.getTimezoneOffset()));
                }
                return null;
            });
        }
    };
});