/**
 * Директива для поля дата. Выставляет время в зависимости от часового пояса. При отправке долен будет вычесть пояс и отпарвить как дату со временем 00-00
 */
angular.module('uetp').directive('onlyDate', function () {
    return {
        require: 'ngModel',
        priority: 1,
        link: function (scope, element, attrs, ctrl) {
            ctrl.$formatters.push(function (value) {
                if(value) {
                    var date = new Date(value);
                    return new Date(date.getTime() + date.getTimezoneOffset()* 60000)
                }
            });

            ctrl.$parsers.push(function (value) {
                if(value) {
                    return  new Date(value.getTime() - value.getTimezoneOffset()*60000)
                }
                return null;
            });
        }
    };
});