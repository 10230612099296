angular.module('uetp')
    .component('order', {
        templateUrl: 'app/components/order/views/orderOpen.html',
        bindings: {
            orderData: '<'
        },
        controller: [
            '$interval', '$scope', '$rootScope', '$q', 'dialogSrv', 'cartSrv',
            'orderSrv', 'desktopSrv', 'dictionarySrv', 'constants', '$stateParams', '$state', '$timeout',
            'positionSrv', 'authSrv', '$filter', 'systemMessages', 'httpSrv', 'alertSrv',

            function (
                $interval, $scope, $rootScope, $q, dialogSrv, cartSrv,
                orderSrv, desktopSrv, dictionarySrv, constants, $stateParams, $state, $timeout,
                positionSrv, authSrv, $filter, systemMessages, httpSrv, alertSrv
            ) {
                var editMode = ($stateParams.params && $stateParams.params.editMode) || false,
                    ctrl = this;
                this.$onInit = initFn;

                $scope.state = $state.current.name;
                $scope.systemMessages = systemMessages;
                $scope.constants = constants;

                $scope.itemSave = itemSaveFn;
                $scope.loadOrderById = loadOrderByIdFn;
                $scope.docReset = docResetFn;
                $scope.saveCartOrder = saveCartOrderFn;
                $scope.changeStatus = changeStatusFn;
                $scope.changeStatusToggle = changeStatusToggleFn;
                $scope.toggleWithdraw = toggleWithdrawFn;
                $scope.doWithdraw = doWithdrawFn;
                $scope.toggleDisabled = toggleDisabledFn;
                $scope.getCartTotal = getCartTotalFn;
                $scope.getDeliveryRegion = getDeliveryRegionFn;
                $scope.activeTab = activeTabFn;
                $scope.getActiveTab = getActiveTabFn;
                $scope.addToCartPositionCount = cartSrv.addToCartPositionCount;
                $scope.toggleVisibility = toggleVisibilityFn;
                $scope.goBack = goBackFn;
                $scope.getAddressString = getAddressStringFn;
                $scope.paramsChanged = false;
                $scope.needRecalc = false;
                $scope.supplierContactPerson = '';
                $scope.purchaseContactPerson = '';
                $scope.activePanel = $scope.state==='orders.create' ? 'info' : 'position';
                $scope.setActivePanel = setActivePanelFn;
                $scope.collapse = collapseFn;

                $scope.getOfferPositionVolumeField = getOfferPositionVolumeFieldFn;
                $scope.hasVatRate = positionSrv.hasVatRate;
                $scope.notInsertImg = desktopSrv.notInsertImg;
                $scope.declOfNum = declOfNumFn;
                $scope.toggleVisibilityPositionDetail = toggleVisibilityPositionDetailFn;

                $scope.downloadAll = downloadAllFn;
                $scope.downloadFile = httpSrv.downloadFile;
                $scope.deleteAll = deleteAllFn;
                $scope.deleteFile = deleteFileFn;
                $scope.addFile = addFileFn;

                $scope.cancel = cancelFn;
                $scope.maxCountAttachments = authSrv.getUser().maxCountAttachments;

                $scope.joinContact = function (src, tokens) {
                    return tokens.reduce(function (result, token) {
                        if (typeof token === 'string') token = src[token];
                        else if (Array.isArray(token)) token = src[token[0]] && (token[1] + (src[token[0]] || token[2]));
                        if (token) result.push(token);
                        return result;
                    }, []).join(', ');
                };

                function collapseFn(id) {
                    const el = document.getElementById(id);
                    const visible = el.style.display;
                    el.style.display = visible === 'none' ? 'block' : 'none';
                }


                $scope.editAttach = function (file) {
                    $scope.attachReset();
                    $scope.addAttach = 'edit';
                    $scope.attachItem = file;
                    $scope.prevVersion = angular.copy(file);
                };


                $scope.getTotalSum = function (cacheIt) {
                    var order = $scope.order;
                    var total = (order.nonNds || !order.summaNds) ? order.summa : order.summaNds;

                    if (cacheIt) $scope.order.sumGeneral = total;
                    return total;
                };

                //сумма всех НДС
                function getTotalNDS() {
                    var order = $scope.order;

                    $scope.noNDS = true;
                    //проверяем наличие строк с НДС (даже если НДС = 0)
                    for (var i = 0; i < order.positions.length; i++) {
                        var pos = order.positions[i].position ? order.positions[i].position : order.positions[i];
                        if (positionSrv.hasVatRate(pos)) $scope.noNDS = false;
                    }
                    if (!$scope.noNDS) {
                        //если есть строки "без НДС" и строки с НДС > 0
                        if (order.summaNds < order.summa) {
                            var sum = 0;
                            for (var j = 0; j < order.positions.length; j++) {
                                var summaNds = order.positions[j].summaNds || order.positions[j].summa,
                                    summa = order.positions[j].summa;
                                sum += summaNds - summa;
                            }
                        }
                        $scope.totalNDS = sum > 0 ? sum : order.summaNds ? (order.summaNds - order.summa) : 0;
                    }
                }

                /**
                 * Показ детальной информации позиции в просмотре заказа
                 * @param idx
                 */
                function toggleVisibilityPositionDetailFn(idx) {
                    $scope.showPositionDetail[idx] = !$scope.showPositionDetail[idx];
                }

                //либо из контроллера-? ------------------------->
                $scope.saveToDoc = saveToDocFn;
                $scope.positionShow = function (docType) {
                    positionSrv.positionShow($scope, docType);
                };
                $scope.positionDelete = function (docType, item) {
                    positionSrv.positionDelete($scope, docType, item);
                };
                $scope.positionEdit = function (docType, item) {
                    if ((($scope.form.mainForm || {})['form.orderItem'] || {}).$dirty || ($scope.form.positionForm || {}).$dirty) {
                        dialogSrv.openModal('_confirmInfo.html', {
                            message: systemMessages.POS_WONTBE_SAVED,
                            buttonSuccess: 'Продолжить'
                        }).then(function (model) {
                            if (model && model.close) {
                                positionSrv.positionEdit($scope, docType, item);
                            }
                        });
                    } else positionSrv.positionEdit($scope, docType, item);

                    var p = $scope.orderItem.position;
                    if (p) {
                        dictionarySrv.loadData('rFreight', p.freightId).then(function (data) {
                            p.freight = data;
                        });
                        dictionarySrv.loadData('rPack', p.packageId).then(function (data) {
                            p.packageData = data;
                        });
                    }
                };
                $scope.positionClose = function () {
                    if ((($scope.form.mainForm || {})['form.orderItem'] || {}).$dirty) {
                        dialogSrv.openModal('_confirmInfo.html', {
                            message: systemMessages.POS_WONTBE_SAVED,
                            buttonSuccess: 'Продолжить'
                        }).then(function (model) {
                            if (model && model.close) {
                                positionSrv.positionClose($scope, 'orderItem');
                            }
                        });
                    } else positionSrv.positionClose($scope, 'orderItem');
                };

                $scope.capitalizeFirst = function (input) {
                    if (angular.isDefined(input) && input)
                        $scope.order.name = input.substring(0, 1).toUpperCase() + input.substring(1);
                };

                //Вычисляет полную сумму заказа с несколькими позициями (без учета доставки)
                function calcSumValues() {
                    //Обновляем сумму заказа
                    if ($scope.currentItem && $scope.order) {
                        //Если заказ с одной позицией
                        $scope.order.summa = $scope.currentItem.summa;
                        $scope.order.summaNds = $scope.currentItem.summaNds;
                    } else {
                        $scope.order.summa = 0;
                        $scope.order.summaNds = 0;
                        var posWraps = null;
                        if ($scope.order.positions) posWraps = $scope.order.positions;

                        if (posWraps) posWraps.forEach(function (posWrap) {
                            if (posWrap.summa) {
                                $scope.order.summa = Number(($scope.order.summa + posWrap.summa).toFixed(2));
                            }
                            var summaNds = posWrap.summaNds || posWrap.summa;
                            $scope.order.summaNds = Number(($scope.order.summaNds + summaNds).toFixed(2));
                        });
                    }
                }

                function calcSumValuesPos(positionContainer) {
                    //Обновляем стоимость позиции
                    if (!positionContainer.volume) return;
                    var price = positionContainer.price,
                        priceNds = positionContainer.priceNds,
                        vatRate = positionContainer.vatRate,
                        volume = Number(positionContainer.volume);

                    if (volume !== null || volume !== undefined) {
                        price = ((price * 100) * volume) / 100;
                        priceNds = price * Number((100 + vatRate) / 100).toFixed(2);
                    }
                    positionContainer.summa = Number(price.toFixed(2));
                    positionContainer.summaNds = Number(priceNds.toFixed(2));

                    //Обновляем сумму заказа
                    calcSumValues();
                }

                $scope.getSum = function (currentItem, recalc) {
                    var currentItemExist = currentItem !== undefined;

                    if (!recalc) $scope.paramsChanged = true;
                    if (currentItemExist) $scope.orderItem = currentItem;

                    calcSumValuesPos($scope.orderItem);
                };

                function getOfferPositionVolumeFieldFn() {
                    if ($scope.currentItem.offerPositionVolume) {
                        return "currentItem.offerPositionVolume";
                    }

                    return "currentItem.offerPosition.volume";
                }

                function saveToDocFn(item) {
                    if ($scope.positionStatus != 'edit') {
                        if (!$scope.order.positions) {
                            $scope.order.positions = []
                        }
                        ++$scope.positionCounter;
                        $scope.offetItem = item;
                        $scope.orderItem.seqNumber = $scope.positionCounter;
                        $scope.order.positions.push($scope.orderItem);
                    } else {
                        var i;
                        var a = $scope.order.positions;
                        for (i = 0; i < a.length; ++i) {
                            if (angular.equals(a[i].seqNumber, $scope.orderItem.seqNumber)) {
                                $scope.order.positions[i] = $scope.orderItem;
                            }
                        }
                    }

                    positionCloseFn();
                }


                function loadOrderByIdFn(id, editMode) {
                    delete $scope.disableForm; //чтобы не мелькало от старых состояний
                    // choiceTypePurchase();
                    $scope.contract = {};

                    if ((ctrl.orderData.status == 401 || (ctrl.orderData.status == 200 && ctrl.orderData.data.errorCode == "402")) && !$stateParams.orderDoc) {
                        console.log("Пользователь не имеет прав для открытия данного документа");
                        $state.go('orders');
                        return;
                    }

                    $scope.hiddenControl = {};
                    $scope.contract = {};
                    $scope.navTabs = 'info';
                    $scope.btnDelete = {};
                    $scope.positionSeqNumber = '';

                    $scope.order = ctrl.orderData.data.result;
                    getTotalNDS();
                    
                    $scope.activePanel = ($scope.state==='orders.view' && $scope.order.statusEng !== "eshop_notpublished") ? 'position' : 'info';

                    if ($scope.order.positions.length == 1) {
                        copyItemValue($scope.order.positions[0]);
                    }

                    $scope.showSupplier = $scope.order.supplier.fullName + ', ИНН ' + $scope.order.supplier.inn + ($scope.order.filialSupplier && $scope.order.filialSupplier.kpp ? ', КПП ' + $scope.order.filialSupplier.kpp : ($scope.order.supplier.kpp ? ', КПП ' + $scope.order.supplier.kpp : ''));
                    if($scope.order.filialSupplier)
                        $scope.showSupplierFilial = $scope.order.filialSupplier.fullName && $scope.order.filialSupplier.kpp;
                    $scope.showCustomer = $scope.order.customer.fullName + ', ИНН ' + $scope.order.customer.inn + ($scope.order.customer.kpp ? ', КПП ' + $scope.order.customer.kpp : '');
                    $scope.navTabsShow = !editMode && ($scope.user.id == $scope.order.customer.id || $rootScope.cabType == "inspector");
                    // скрываем шапку вкладок у реестра закупок когда мы в ЛК Инспектора
                    if ($rootScope.cabType == "inspector" && $rootScope.prevState && $rootScope.prevState.name == 'purchases') {
                        $scope.navTabsShow = false;
                    }
                    $scope.positionCounter = $scope.order.positions ? $scope.order.positions.length : 0;
                    $scope.disableForm = editMode ? !editMode : $scope.order.statusEng != 'eshop_notpublished';
                    $scope.hiddenControl.hideSendOrdersMessage = $scope.order.statusEng == 'eshop_finished'
                        || $scope.order.statusEng == 'eshop_withdrawn';
                    $scope.status = $scope.order.statusEng;
                    $scope.supplierContactPerson = getFullNameFn([
                        $scope.order.supplier.lastName,
                        $scope.order.supplier.firstName,
                        $scope.order.supplier.middleName
                    ], true) + $scope.joinContact($scope.order.supplier, [
                        ['email', 'email: '],
                        ['phone', 'телефон: ', ($scope.order.filialSupplier || {}).phone],
                        ['urlShop', ' сайт: ']
                    ]);
                    $scope.purchaseContactPerson = $scope.joinContact($scope.order, [
                        'contactPerson',
                        ['email', 'email: '],
                        ['phone', 'телефон: ']
                    ]);
                    if (!$stateParams.noScroll) window.scrollTo(0, 0);
                }

                $scope.addAttachShowFn = function () {
                    $scope.addAttach = 'show';
                };

                $scope.showOrder = function (docItem) {
                    if ($scope.addOrderSeqNumber == docItem.seqNumber) {
                        $scope.orderPositionClose();
                    } else {
                        dictionarySrv.resetPaginatedDict($scope);
                        $scope.showOrderPanel = 'show';
                        $scope.addOrderSeqNumber = docItem.seqNumber;
                        $scope.docItem = {};
                        $scope.docItem = angular.copy(docItem);
                        var param = {
                            searchByField: {},
                            itemsPerPage: 20,
                            page: 0
                        };
                        if (docItem.skmtr) {
                            param.searchByField.skmtr = docItem.skmtr;
                            dictionarySrv.loadPaginatedDict($scope, 'SKMTR', param, 'rSKMTR');
                        } else if (docItem.okpd2) {
                            param.searchByField.okpd2 = docItem.okpd2;
                            dictionarySrv.loadPaginatedDict($scope, 'SKMTR', param, 'rSKMTR')
                        }

                        if (docItem.okpd2) {
                            var paramOkpd = {
                                searchByField: {
                                    code: docItem.okpd2
                                },
                                itemsPerPage: 20,
                                page: 0
                            };
                            dictionarySrv.loadPaginatedDict($scope, 'OKPD2', paramOkpd, 'rOKPD2');
                        }
                    }
                };

                function positionCloseFn() {
                    $scope.docReset();
                    $scope.positionStatus = 'hide';
                }

                function docResetFn() {
                    $scope.orderItem = {
                        type: 'ESHOP_PRODUCT'
                    };
                }

                function toggleWithdrawFn() {
                    if ($scope.withdrawStatus == 'doWithdraw') {
                        $scope.alertId = 'dismiss';
                        $scope.withdrawStatus = '';
                        $scope.showReasonTop = false;
                    } else {
                        $scope.showReasonTop = true;
                        $scope.withdrawStatus = 'doWithdraw';
                        $scope.alertId = 'withdraw';
                    }
                }

                function doWithdrawFn() {
                    orderSrv.changeStatus($scope.order.id,  'eshop_withdrawn', $scope.user.fullName).then(function (data) {
                        $scope.alertId = 'dismiss';
                        $scope.withdrawStatus = '';
                        if (data.success) {
                            setTimeout(function () {
                                toggleDisabledFn(false);
                            }, 1000)
                        } else {
                            dialogSrv.openModal(
                                '_dialogMessage.html',
                                {message: data.message}
                            );
                        }
                    });
                }

                function toggleDisabledFn(nextMode) {
                    var currentMode = typeof nextMode == "undefined" ? !editMode : nextMode;
                    $state.go("orders.view", {id: $scope.order.id, params: {editMode: currentMode}}, {reload: true});
                }

                $scope.btnDeleteClick = function (id) {
                    $scope.btnDelete[id] = !$scope.btnDelete[id]
                };

                $scope.closeAlert = function (id) {
                    if(id){
                        $scope[id] = 'dismiss';
                    } else {
                        $scope.alertId = 'dismiss';
                        $scope.status = '';
                        $scope.showReasonTop = false;
                        $scope.showReasonBottom = false;
                        if ($scope.order) $scope.order.reason = "";
                    }
                };
                $scope.closeAttachAlert = function () {
                    $scope.alertId = 'dismiss';
                }
                $scope.closeAlertRating = function () {
                    $scope.showSendRating = false
                };
                $scope.currentDate = function () {
                    return new Date().setHours(0, 0, 0, 0);
                };

                $scope.currentDateDayOffset = function (dayOffset) {
                    if (!dayOffset) {
                        return;
                    }
                    return new Date().setDate(new Date().getDate() + dayOffset);
                };

                $scope.currentDateMsk = function () {
                    return getServerTimeMsk();
                };

                //возможно необходимо выделить компонент-------------------------->
                $scope.attachReset = function () {
                    $scope.attachItem = {
                        name: '',
                        description: ''
                    };
                };
                $scope.attachReset();
                $scope.addAttach = '';
                $scope.addAttachShow = function () {
                    $scope.addAttach = 'show';
                };
                $scope.closeRejectAttach = function (object) {
                    for (var i = 0; i < $scope[object].attachment.length; i++) {
                        if ($scope[object].attachment[i].id == $scope.prevVersion.id)
                            $scope[object].attachment[i] = $scope.prevVersion;
                    }
                    delete $scope.prevVersion;
                    $scope.closeAttach();
                };
                $scope.closeAttach = function () {
                    $scope.attachReset();
                    $scope.addAttach = 'hide';
                    //ie 11 баг снятие фокуса. Иначе не нажат кнопки
                    window.focus();
                    if (document.activeElement) {
                        document.activeElement.blur();
                    }
                    if ($scope.form.mainForm) $scope.form.mainForm.$setDirty();
                    if ($scope.form.profileForm) $scope.form.profileForm.$setDirty();
                };
                $scope.uploadFile = function (object, response) {
                    if (!$scope[object]) $scope[object] = {};
                    if (!$scope[object].attachment) {
                        $scope[object].attachment = [];
                    }
                    if (!response.data.result || !response.data.result[0]) return;
                    $scope[object].attachment.push({
                        "id": response.data.result[0].id,
                        "contentLenght": response.data.result[0].contentLenght,
                        "fileType": 0,
                        "name": $scope.attachItem.name,
                        "description": $scope.attachItem.description,
                        "createdDate":response.data.result[0].createdDate
                    });
                    $scope.attachReset();
                    $scope.addAttach = 'hide';
                    $scope.attachmentDirty = true;
                    //ie 11 баг снятие фокуса. Иначе не нажат кнопки
                    window.focus();
                    if (document.activeElement) {
                        document.activeElement.blur();
                    }
                    if ($scope.form.mainForm) $scope.form.mainForm.$setDirty();
                    if ($scope.form.profileForm) $scope.form.profileForm.$setDirty();
                };

                $scope.showFiles = function showFiles(files) {
                    $scope.attachItem.name = files[0].name;
                }
                
                $scope.onSizeError = function (files) {
                    $scope.fileSizeErrorMessage = "Документ " + files[0].name + " не добавлен. Превышен максимально разрешенный размер файла (30МБ)!";
                    $scope.alertId = 'fileSizeError';
                };

                $scope.onSizeEmpty = function () {
                    $scope.alertId = 'attachError';
                    $scope.attachErrorText = "Добавление пустого файла невозможно. Выберите другой файл";
                };

                $scope.onFileNameError = function (files) {
                    $scope.fileSizeErrorMessage = "Документ " + files[0].name + " не добавлен. Добавление файлов с расширением *.exe запрещено!";
                    $scope.alertId = 'fileNameError';
                };
                
                $scope.deleteAttach = function (file, object) {
                    var index = object.attachment.indexOf(file);
                    if (index > -1) {
                        object.attachment.splice(index, 1);
                    }
                    if ($scope.form.mainForm) $scope.form.mainForm.$setDirty();
                    if ($scope.form.profileForm) $scope.form.profileForm.$setDirty();

                };

                // from documentation
                function downloadAllFn() {
                    // пока не реализовано. сделать по аналогии с извещением
                    httpSrv.downloadFile('fs/file/orders/'+$scope.order.id+'/downloadDocArchive');
                }

                function deleteAllFn() {
                    $scope.order.attachment = [];
                }
                function addFileFn(data) {
                    if(!$scope.order.attachment) $scope.order.attachment = [];
                    if(data) {
                        angular.forEach(data, function (file) {
                            if (getIdxByFileName( $scope.order.attachment, file.name)===-1) {
                                $scope.order.attachment.push({
                                    name: file.name,
                                    id: file.id,
                                    contentLenght: file.contentLenght,
                                    createdDate: file.createdDate,
                                    description: $scope.attachItem.description
                                });
                            }
                            else
                                dialogSrv.showDialogMsg($scope, "Файл уже присутствует в списке");
                        });
                        $scope.addAttach = 'hide';
                        $scope.attachReset();
                    }
                    setNumberFn($scope.order.attachment);
                    updatePaginatorFn();
                }
                $scope.preUpload = function (callback, changeEvent) {
                    if($scope.order.attachment) {
                        changeEvent.target.files[0].description = $scope.order.attachment.description;
                        callback();
                    }
                };


                function getIdxByFileName(dataArray, fileName){
                    var idx = -1;
                    if (!dataArray || !dataArray.length) return idx;
                    for (var i=0; i<dataArray.length;i++){
                        if (dataArray[i].name == fileName){
                            return i;
                        }
                    }
                    return idx;

                }
                function deleteFileFn(item) {
                    for(var i=0;i<$scope.order.attachment.length;i++){
                        if($scope.order.attachment[i].id == item.id){
                            $scope.order.attachment.splice(i,1);
                            break;
                        }
                    }
                    setNumberFn($scope.order.attachment);
                    if($scope.order.attachment.length<=$scope.paginator.itemsPerPage)
                        $scope.paginator.page = 0;

                    updatePaginatorFn();
                }
                function setNumberFn(attachment) {
                    for(var i=0;i<attachment.length;i++){
                        attachment[i].deff__number = i+1;
                    }
                }
                /**
                 * Обновление пагинатора
                 */
                function updatePaginatorFn() {
                    $scope.totalPages = ($scope.order.attachment.length) ? Math.ceil($scope.order.attachment.length / $scope.paginator.itemsPerPage) : null;
                }

                $scope.addPurchaseEdit = function (orderItem) {
                    if ($scope.addPurchaseSeqNumber == orderItem.seqNumber) {
                        $scope.purchasePositionClose();
                    } else {
                        dictionarySrv.resetPaginatedDict($scope);
                        $scope.addPurchase = 'show';
                        $scope.addPurchaseSeqNumber = orderItem.seqNumber;
                        $scope.orderItem = {};
                        $scope.orderItem = angular.copy(orderItem);
                        if (orderItem.skmtr) {
                            var param = {
                                searchByField: {},
                                itemsPerPage: 20,
                                page: 0
                            };
                            param.searchByField.skmtr = orderItem.skmtr;
                            dictionarySrv.loadPaginatedDict($scope, 'SKMTR', param, 'rSKMTR');
                        }
                        if (orderItem.okpd2) {
                            var paramOkpd = {
                                searchByField: {
                                    code: orderItem.okpd2
                                },
                                itemsPerPage: 20,
                                page: 0
                            };
                            dictionarySrv.loadPaginatedDict($scope, 'OKPD2', paramOkpd, 'rOKPD2');
                        }
                    }
                };

                $scope.cartItemSave = function () {
                    $scope.positionSaveValidate = true;
                    if ($scope.orderItem.volume > $scope.orderItem.parent.volume) {
                        $scope.alertId = 'volumePositionError';
                        $scope.volumePositionText = 'Количество в позиции(' + $scope.orderItem.volume + ') больше максимального объема заказа(' + $scope.orderItem.parent.volume + ').';
                    } else if ($scope.orderItem.volume != undefined && $scope.orderItem.volume != "") {
                        $scope.itemSave($scope.order.positions);
                        $scope.positionSeqNumber = '';
                    } else {
                        $scope.alertId = 'validPurchaseError';
                    }
                };

                $scope.orderItemSave = function () {
                    $scope.positionSaveValidate = true;
                    if ($scope.orderItem.volume != undefined && $scope.orderItem.volume != "") {
                        $scope.itemSave($scope.order.positions);
                        $scope.addPurchaseSeqNumber = '';
                    } else {
                        $scope.alertId = 'validPurchaseError';
                    }
                };

                function itemSaveFn(positions) {
                    for (var i = 0; i < positions.length; ++i) {
                        if (angular.equals(positions[i].seqNumber, $scope.orderItem.seqNumber)) {
                            positions[i] = angular.copy($scope.orderItem);
                        }
                    }
                    $scope.order.summa = $scope.getCartTotal(positions, 'summa');
                    $scope.order.summaNds = $scope.getCartTotal(positions, 'summaNds', 'summa');
                    $scope.addToCartPositionCount($scope.orderItem.parent.id, $scope.orderItem.volume);

                    $scope.addPurchase = 'hide';
                    $scope.positionSaveValidate = false;
                    $scope.alertId = 'dismiss';
                    $scope.alertId2 = 'dismiss';
                    positionSrv.positionClose($scope, 'orderItem')
                }

                $scope.orderPositionClose = function () {
                    $scope.purchaseReset();
                    $scope.addPurchaseSeqNumber = '';
                    $scope.addPurchase = 'hide';
                };

                $scope.purchaseReset = function () {
                    $scope.orderItem = {
                        type: 'ESHOP_PRODUCT'
                    };
                };

                function saveCartOrderFn(alertId) {
                    // Сохранение нового заказа
                    $scope.submitted = true;

                    if(!validateForm()) return;

                    var currentItemVolume;
                    //Поиск ошибки в объеме нескольких позиций
                    for (var errorElement in $scope.form.mainForm.$error) {
                        if ($scope.form.mainForm.$error.hasOwnProperty(errorElement)) {
                            if (errorElement.indexOf('volume_') !== 0) {
                                $scope.alertId = 'validError';
                                return;
                            } else {
                                var seqNum = errorElement.split('_')[1];
                                currentItemVolume = document.getElementsByName(errorElement)[0];
                                if (currentItemVolume) {
                                    if (Number(currentItemVolume.value) < Number(currentItemVolume.dataset.min_volume)) $scope.alertId2 = 'volumeIncorrect_' + seqNum;
                                    if (Number(currentItemVolume.value) > Number(currentItemVolume.dataset.max_volume)) $scope.alertId2 = 'volumeIncorrectMax_' + seqNum;
                                    return;
                                }
                            }
                        }
                    }

                    if ($scope.orderItem && $scope.orderItem.parent && $scope.orderItem.volume > $scope.orderItem.parent.volume) {
                        $scope.alertId = 'volumePositionError';
                        $scope.volumePositionText = 'Количество в позиции(' + $scope.orderItem.volume + ') больше максимального объема заказа(' + $scope.orderItem.parent.volume + ').';
                        $scope.alertOrder = alertSrv.getErrorMsg($scope.volumePositionText);
                        return;
                    }

                    if ($scope.form.mainForm && !$scope.form.mainForm.$valid) {
                        $scope.alertId = 'saveError';
                        $scope.saveErrorText = systemMessages.EMPTY_FIELD;
                        $scope.alertOrder = alertSrv.getErrorMsg($scope.saveErrorText);
                        return;
                    }
                    if (!positionSrv.checkQuantity($scope, 'order', true)) {
                        $scope.alertId = 'saveError';
                        $scope.saveErrorText = systemMessages.EMPTY_VOLUME;
                        $scope.alertOrder = alertSrv.getErrorMsg($scope.saveErrorText);
                        return;
                    }
                    if (!checkPersonalDataAgreement()) {
                        $scope.alertOrder = alertSrv.getErrorMsg($scope.saveErrorText);
                        return;
                    }

                    //проверяем размер общей суммы перед сохранением
                    var mask = /^[0-9]{0,13}(?:\.[0-9]{0,2})?$/,
                        summaTest = $scope.order.summa;

                    if (!mask.test(summaTest)) {
                        dialogSrv.openModal(
                            '_dialogMessage.html',
                            {message: systemMessages.DOCUMENT_SUM_MAX}
                        );
                        return;
                    }

                    if ($scope.state === "orders.view") {
                        if (!$scope.order.positions || angular.equals($scope.order.positions, [])) {
                            $scope.alertId2 = 'emptyPositions';
                            $scope.alertOrder = alertSrv.getErrorMsg('Предложение должно содержать хотя бы одну позицию.');
                            return;
                        }
                    }


                    if ($scope.state === "orders.create") {
                        if (!positionSrv.checkQuantity($scope, 'order', true)) {
                            $scope.alertId = 'saveError';
                            $scope.saveErrorText = systemMessages.EMPTY_VOLUME;
                            $scope.alertOrder = alertSrv.getErrorMsg($scope.saveErrorText);
                            $scope.order.positions = [];
                            return;
                        }
                        if (!$scope.order.positions || angular.equals($scope.order.positions, [])) {
                            $scope.alertId2 = 'emptyPositions';
                            $scope.alertOrder = alertSrv.getErrorMsg('Предложение должно содержать хотя бы одну позицию.');
                            return;
                        }
                        $scope.deleteUnChecked($scope.order.positions);
                        $scope.order.publicDate = desktopSrv.dateMsk();
                    }
                    //пересчитываем позиции от 1 до n, для точной последовательности, чтоб не отслеживать импорт\удаление\добавление
                    var i;
                    var data = $scope.order;
                    $scope.positionCounter = 0;
                    for (i = 0; i < data.positions.length; ++i) {
                        data.positions[i].seqNumber = ++$scope.positionCounter;
                    }
                    data.customer = {
                        id: $scope.user.id,
                        inn:$scope.user.inn,
                        kpp:$scope.user.kpp,
                        fullName: $scope.user.fullName
                    };
                    $scope.disabledBtn = true;

                    // чистим лишнее
                    desktopSrv.removeKeys(data, 'deff__');

                    data.deliveryRegionName = dictionarySrv.getRegionNames(data.deliveryRegion);
                    return orderSrv.storeOrder(data)
                        .then(function successCallback(response) {
                            $scope.disabledBtn = false;
                            if (response.data.success) {
                                $scope.variants = null;
                                $scope.showCurrentVariant = true;
                                $scope.order.id = response.data.result;
                                $scope.alertId = alertId;
                                $scope.alertId2 = 'dismiss';
                                if ($scope.order.attachment)
                                    setNumberFn($scope.order.attachment);
                                if ($scope.form.mainForm) $scope.form.mainForm.$setPristine();

                                setTimeout(function () {
                                    cartSrv.loadCart();
                                }, 1000);
                                cartSrv.countPositions();
                                if (alertId === "orderPublished") {
                                    toggleDisabledFn();
                                } else if ($scope.state === "orders.create") {
                                    return $timeout(function () {
                                        $state.go("orders.view", {
                                            id: response.data.result,
                                            successSave: true,
                                            noScroll: true
                                        });
                                    }, 1500);
                                }
                            } else {
                                $scope.alertId = 'saveError';
                                $scope.saveErrorText = response.data.message;
                                $scope.alertOrder = alertSrv.getErrorMsg($scope.saveErrorText);
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        });

                }

                $scope.deleteUnChecked = function (item) {
                    for (var i = 0; i < item.length; i++) {
                        delete $scope.order.positions[i].checkbox
                    }
                };

                $scope.deleteCartPositionsAddedToOrder = function (cart) {
                    var i;
                    var data = cart;
                    for (i = 0; i < data.positions.length; ++i) {
                        $scope.deleteCartPosition(data.positions[i].id);
                    }
                };

                function changeStatusToggleFn(status){
                    $scope.alertId = 'dismiss';
                    orderSrv.changeStatus($scope.order.id, status, $scope.user.fullName)
                        .then(function (responseStatus) {
                            if (responseStatus.data.success) {
                                $scope.status = status;
                                $scope.alertId = 'purchasePublished';
                                alertSrv.getSuccessMsg('Заказ опубликован и доступен для просмотра в Вашем личном кабинете в разделе Мои Заказы.');
                                setTimeout(function () {
                                    $state.go("orders.view", {
                                        id: $scope.order.id,
                                        noScroll: false,
                                        params: {editMode: false}
                                    },{reload: true});
                                }, 4000);
                            } else {
                                dialogSrv.openModal(
                                    '_dialogMessage.html',
                                    {message: responseStatus.data.message}
                                ).then(function () {
                                    if(responseStatus.data.errorCode === '440' || responseStatus.data.errorCode === '441')
                                        $state.go($scope.order.supplier.id == $scope.user.id ? 'orders' : 'ordersMy');
                                });
                                $scope.disabledBtn = false;

                            }
                        });
                }

                function changeStatusFn (id, status) {

                    if(!validateForm()) return;

                    if (($scope.orderItem || {}).parent && $scope.orderItem.volume > $scope.orderItem.parent.volume) {
                        $scope.alertId = 'volumePositionError';
                        $scope.volumePositionText = systemMessages.generateMsg_MAX_VOLUME_2($scope.orderItem.volume, $scope.orderItem.parent.volume);
                        $scope.alertOrder = alertSrv.getErrorMsg($scope.volumePositionText);
                        return;
                    }

                    $scope.submitted = true;
                    if ($scope.form.mainForm && $scope.form.mainForm.volume && !$scope.form.mainForm.volume.$valid) {
                        $scope.alertId = 'validError';
                        return;
                    }
                    if ($scope.form.mainForm && !$scope.form.mainForm.$valid || !positionSrv.checkQuantity($scope, 'order')) {
                        $scope.alertId = 'validError';
                        return;
                    }

                    if (!$scope.order.positions || angular.equals($scope.order.positions, [])) {
                        $scope.alertId2 = 'emptyPositions';
                        $scope.alertOrder = alertSrv.getErrorMsg('Предложение должно содержать хотя бы одну позицию.');
                        return;
                    }

                    if (!checkPersonalDataAgreement()) {
                        $scope.alertOrder = alertSrv.getErrorMsg($scope.saveErrorText);
                        return;
                    }

                    //проверяем размер общей суммы перед сохранением
                    var mask = /^[0-9]{0,13}(?:\.[0-9]{0,2})?$/,
                        summaTest = $scope.order.summa;

                    if (!mask.test(summaTest)) {
                        dialogSrv.openModal(
                            '_dialogMessage.html',
                            {message: systemMessages.DOCUMENT_SUM_MAX}
                        );
                        return;
                    }
                    $scope.disabledBtn = true;

                    //пересчитываем позиции от 1 до n, для точной последовательности, чтоб не отслеживать импорт\удаление\добавление
                    var i;
                    var data = angular.copy($scope.order);
                    $scope.positionCounter = 0;
                    for (i = 0; i < data.positions.length; ++i) {
                        data.positions[i].seqNumber = ++$scope.positionCounter;
                    }

                    $scope.order.publicDate = desktopSrv.dateMsk();

                    data.deliveryRegionName = dictionarySrv.getRegionNames(data.deliveryRegion);
                    orderSrv.storeOrder(data).then(function (response) {
                        if (response.data.success) {
                            $scope.purchaseCreateMode = 'edit';
                            $scope.alertId2 = 'dismiss';
                            // $scope.loadMyPurchases();
                            $scope.order.id = response.data.result;
                            orderSrv.loadOrderById($scope.order.id).then(
                                function (response) {
                                    $scope.disabledBtn = true;
                                    if (response.data.success) {
                                        $scope.order.statusEng = response.data.result.statusEng;
                                        orderSrv.changeStatus($scope.order.id, status, $scope.user.fullName)
                                        .then(function (responseStatus) {
                                            if (responseStatus.data.success) {
                                                $scope.alertId = 'purchasePublished';
                                                $scope.alertOrder = alertSrv.getSuccessMsg(status === 'eshop_sent' ? 'Заказ отправлен поставщику.' : 'Заказ сохранен.');
                                                setTimeout(function () {
                                                    $state.go("orders.view", {
                                                        id: id,
                                                        noScroll: false,
                                                        params: {editMode: false}
                                                    });
                                                }, 4000);
                                            } else {
                                                dialogSrv.openModal(
                                                    '_dialogMessage.html',
                                                    {message: responseStatus.data.message}
                                                );
                                                $scope.disabledBtn = false;
                                            }
                                        });
                                    }
                                },
                                function (error) {
                                    $scope.disabledBtn = false;
                                    console.error(error);
                                });
                            if ($scope.form.mainForm) $scope.form.mainForm.$setPristine();
                            // $scope.deleteCartPositionsAddedToOrder(data);
                        } else {
                            $scope.disabledBtn = false;
                            $scope.alertId = 'saveError';
                            $scope.saveErrorText = response.data.message;
                            $scope.alertOrder = alertSrv.getErrorMsg($scope.saveErrorText);
                        }
                    }, function (error) {
                        console.error(error);
                    });
                }


                /**
                 * Вычисление итоговой суммы по позициям
                 * @param items позиции
                 * @param property поле для расчета суммы
                 * @param altProperty поле для расчета суммы, если нет поля prop
                 * @returns {number}
                 */
                function getCartTotalFn(items, property, altProperty) {
                    var result = items.reduce(function (sum, item) {
                        return sum + (item[property] || (altProperty ? item[altProperty] : 0));
                    }, 0);
                    return Number(result.toFixed(2));
                }

                function getDeliveryRegionFn(items) {
                    var deliveryRegion = function () {
                        var result = '';
                        var deliveryRegionPrev = '';
                        for (var i = 0, max = items.length; i < max; i++) {
                            if (items[i].deliveryRegion) {
                                if (deliveryRegionPrev != '') {
                                    if (deliveryRegionPrev != items[i].deliveryRegion) {
                                        return '';
                                    }
                                } else {
                                    deliveryRegionPrev = items[i].deliveryRegion;
                                    result = deliveryRegionPrev;
                                }
                            }
                        }
                        return result;
                    };
                    return deliveryRegion()
                }

                function activeTabFn(type) {
                    orderSrv.setShowPanel(type);
                }

                function getActiveTabFn() {
                    return orderSrv.getShowPanel();
                }


                //TODO: Дубликат функции, перенести в один сервис
                function declOfNumFn(titles, number) {
                    number = Math.abs(number);
                    var cases = [2, 0, 1, 1, 1, 2];
                    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
                }


                $scope.refreshDict = function (object, value, refreshScrolling, searchByField, forcedAll) {
                    if (!$scope.dictPaginated) $scope.dictPaginated = {};
                    if (!$scope.dictPaginated[object]) $scope.dictPaginated[object] = [];
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling, searchByField, forcedAll);
                };

                $scope.getMinPrice = function (discountsArr) {
                    return Math.min.apply(Math, discountsArr.map(function (item) {
                        return item.price;
                    }));
                };

                function toggleVisibilityFn(idx, object) {
                    $scope[object][idx] = !$scope[object][idx];
                }

                function checkOrderEditConfirm() {
                    var deferred = $q.defer();
                    var isSave = $scope.state == 'orders.create' || $scope.order.statusEng == 'eshop_notpublished';
                    if ((($scope.form || {}).mainForm || {}).$dirty) {
                        dialogSrv.openModal('_confirmInfoExt.html', {
                            message: systemMessages.DATA_WONTBE_SAVED,
                            buttons: [{
                                title: isSave ? "Сохранить" : "Сохранить и опубликовать",
                                btnClass: "btn-success",
                                closeValue: false,
                                action: function () {
                                    $q.when($scope.saveCartOrder(isSave ? 'orderSave' : 'orderPublished')).then(function(){
                                        var unsavedChanges=(($scope.form || {}).mainForm || {}).$dirty;
                                        deferred.resolve(!unsavedChanges);
                                    });
                                }
                            }, {
                                title: "Не сохранять",
                                btnClass: "btn-danger",
                                closeValue: true
                            }, {
                                title: "Отмена",
                                btnClass: "btn-light",
                                closeValue: false
                            }
                            ]
                        }).then(function (model) {
                            if (model && model.close)
                                deferred.resolve(true);
                        });
                    } else {
                        deferred.resolve(true);
                    }
                    return deferred.promise;
                }

                function goBackFn() {
                    if (['customer', 'organizer', 'participant'].indexOf($scope.cabType) !== -1) {
                        checkOrderEditConfirm().then(function (isBack) {
                            if (isBack) {
                                $state.go("ordersMy");
                            }
                        });
                    } else {
                        history.back();
                    }
                }

                function copyItemValue(objValue, recalc) {
                    $scope.currentItem = objValue;
                    if (!$scope.currentItem.type) {
                        $scope.currentItem.type = 'ESHOP_PRODUCT';
                    }
                    var p = $scope.currentItem.position;
                    if (p) {
                        dictionarySrv.loadData('rFreight', p.freightId).then(function (data) {
                            p.freight = data;
                        });
                        dictionarySrv.loadData('rPack', p.packageId).then(function (data) {
                            p.packageData = data;
                        });
                    }
                    if (recalc) $scope.getSum($scope.currentItem, recalc);

                }

                $scope.showActionFiles = function (files) {
                    if (!$scope.modelAction.attachItem) {
                        $scope.modelAction.attachItem = {}
                    }
                    $scope.modelAction.attachItem.name = files[0].name;
                };

                $scope.onSizeActionError = function (files) {
                    $scope.fileSizeErrorMessage = "Документ " + files[0].name + " не добавлен. Превышен максимально разрешенный размер файла (30МБ)!";
                    $scope.alertId = 'fileSizeActionError';
                    $scope.alertOrder = alertSrv.getErrorMsg($scope.fileSizeErrorMessage);
                };

                $scope.attachActionReset = function () {
                    $scope.attachItem = {
                        name: '',
                        description: ''
                    };
                };
                $scope.deleteActionAttach = function (file) {
                    var index = $scope.modelAction.attachment.indexOf(file);
                    if (index > -1) {
                        $scope.modelAction.attachment.splice(index, 1);
                    }
                };


                function getAddressStringFn(data) {
                    return orderSrv.getAddressString(data);
                }


                /**
                 * Собирает имя во одну строку
                 * @param {array} arrayName массив из фамилии, имени, отчества
                 * @param {boolean} addSeparator признак добавления запятой в конце
                 * @returns {string}
                 */
                function getFullNameFn(arrayName, addSeparator) {
                    var fullName = '';
                    arrayName.forEach(function (name) {
                        if (name) {
                            if (fullName) {
                                fullName = fullName + ' ' + name;
                            } else {
                                fullName = name;
                            }
                        }
                    });
                    if (!fullName) addSeparator = false;
                    return addSeparator ? fullName + ', ' : fullName;
                }

                $scope.setAttachRemoving = function (attachId) {
                    $scope.attachRemoving = attachId;
                };

                $scope.deleteAttachment = function (file, object) {
                    var index = object.attachment.indexOf(file);
                    if (index > -1) {
                        object.attachment.splice(index, 1);
                    }
                    $scope.attachmentDirty = true;
                }
                
                function _initDict(dictList, reload) {
                    angular.forEach(dictList, function (name) {
                        dictionarySrv.getDictionaryRemote(name, reload).then(function (dict) {
                            $scope.dictionary[name] = dict;
                        })
                    })
                }

                function initFn() {
                    var i;
                    $scope.cabType = desktopSrv.getCabType();
                    $scope.dictionary = dictionarySrv.getRemoteData();
                    _initDict(['fias_region', 'okei'], false);
                    $scope.availableRegions = [];
                    $rootScope.constants = constants;
                    $scope.properties = $rootScope.properties;
                    $scope.paginator = {
                        itemsPerPage: 20,
                        page: 0
                    };
                    $scope.order = {};
                    $scope.processAction = false;
                    $scope.modelAction = {};
                    $scope.currentItem = null;
                    $scope.viewTab = orderSrv.getShowPanel();
                    $scope.disableForm = false;
                    $scope.positionSeqNumber = '';
                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                    //dictionarySrv.loadDictionaries($rootScope.cabType);
                    $scope.form = {};
                    $scope.btnDelete = {};
                    $scope.idDocTime = new Date().getTime();
                    $scope.disableCalc = false;
                    $scope.hidePosition = {};
                    $scope.hideResult = {};
                    $scope.orderDebug = {};
                    $scope.dateOpen = {};
                    $scope.auth = authSrv.getAuth();
                    $scope.user = authSrv.getUser();
                    $scope.order.phone = $scope.order.phone || $scope.user.phone;
                    $scope.dateOptions = {
                        minDate: new Date()
                    };

                    if ($stateParams.successSave && $stateParams.noScroll) {
                        //прокрутка вниз
                        setTimeout(function () {
                            window.document.body.scrollTop += 999;
                            window.document.documentElement.scrollTop += 999;
                        }, 500);
                    }

                    if (!$stateParams.id && $scope.state === "orders.create") {
                        window.scrollTo(0, 0);

                        if (orderSrv.getPositions()) {
                            $scope.positionsForOrder = {};
                            $scope.positionsForOrder = orderSrv.getPositions();
                            var kpps = [];
                            for (i = 0; i < $scope.positionsForOrder.cartsOrganization.length; i++) {
                                var kpp = $scope.positionsForOrder.cartsOrganization[i].filial ? $scope.positionsForOrder.cartsOrganization[i].filial.kpp : ($scope.positionsForOrder.org.kpp ? $scope.positionsForOrder.org.kpp : null);
                                if (kpp && kpps.indexOf(kpp) == -1) {
                                    kpps.push(kpp)
                                }
                            }
                            $scope.showSupplier = $scope.positionsForOrder.org.fullName + ', ИНН ' + $scope.positionsForOrder.org.inn
                                + (kpps.length > 1 ? ($scope.positionsForOrder.org.kpp ? ', КПП ' + $scope.positionsForOrder.org.kpp : '') : kpps[0] ? (', КПП ' + kpps[0]) : '');
                            $scope.positionCounter = 0;
                            var orderWithNDS = false;

                            //подготовка позиций, удаление из заказа позиций без галочек
                            for (i = 0; i < $scope.positionsForOrder.cartsOrganization.length; ++i) {
                                $scope.positionsForOrder.cartsOrganization[i].seqNumber = ++$scope.positionCounter;
                                $scope.positionsForOrder.cartsOrganization[i].volume = $scope.positionsForOrder.cartsOrganization[i].minimumPackageVolume ? Math.max($scope.positionsForOrder.cartsOrganization[i].minimumPackageVolume, $scope.positionsForOrder.cartsOrganization[i].volume) : $scope.positionsForOrder.cartsOrganization[i].volume;
                                var price = $scope.positionsForOrder.cartsOrganization[i].price,
                                    volume = $scope.positionsForOrder.cartsOrganization[i].volume;

                                var posWithNDS = $scope.positionsForOrder.cartsOrganization[i].vatRate !== undefined;
                                if (posWithNDS) orderWithNDS = true;

                                if (volume != null || volume !== undefined) {
                                    $scope.positionsForOrder.cartsOrganization[i].summa = Number((price * volume).toFixed(2));
                                    if (posWithNDS) {
                                        var vatRate = Number((100 + $scope.positionsForOrder.cartsOrganization[i].vatRate) / 100).toFixed(2);
                                        $scope.positionsForOrder.cartsOrganization[i].summaNds = Number(($scope.positionsForOrder.cartsOrganization[i].summa * vatRate).toFixed(2));
                                    } else delete $scope.positionsForOrder.cartsOrganization[i].summaNds;
                                }
                            }

                            for (i = $scope.positionsForOrder.cartsOrganization.length - 1; i >= 0; i--) {
                                if (!$scope.positionsForOrder.cartsOrganization[i].checkbox) {
                                    $scope.positionsForOrder.cartsOrganization.splice(i, 1);
                                }
                            }

                            $scope.order.nonNds = !orderWithNDS;
                            $scope.order.summa = $scope.getCartTotal($scope.positionsForOrder.cartsOrganization, 'summa');
                            if (orderWithNDS) $scope.order.summaNds = $scope.getCartTotal($scope.positionsForOrder.cartsOrganization, 'summaNds', 'summa');
                            $scope.order.positions = angular.copy($scope.positionsForOrder.cartsOrganization);
                            $scope.order.supplier = angular.copy($scope.positionsForOrder.org);
                            if(!$scope.order.supplier.site) {
                                $scope.order.supplier.site = $scope.constants.configProject.type;
                            }
                            if(!$scope.order.supplier.typeOrg) {
                                $scope.order.supplier.typeOrg = $scope.user.typeOrg;
                            }
                            $scope.order.contactPerson = $scope.user.lastName + ' ' + $scope.user.firstName + (!$scope.user.middleName ? '' : ' ' + $scope.user.middleName);
                            $scope.order.email = $scope.user.email;
                            $scope.order.deliveryRegion = $scope.getDeliveryRegion($scope.positionsForOrder.cartsOrganization);

                            orderSrv.createDraftOrder().then(function (id) {
                                $scope.order.id = id;
                            })
                        } else {
                            $state.go("cart");
                        }
                    } else {
                        loadOrderByIdFn($stateParams.id, editMode);
                    }

                    $scope.showPositionDetail = [];

                    if($scope.order.attachment && $scope.order.attachment.length>0)
                        setNumberFn($scope.order.attachment);

                    $scope.modelView = {
                        consentProcessing: $scope.order.personalDataAgreement,
                        consentDissemination: $scope.order.personalDataAgreement
                    };
                }
                function setActivePanelFn(type) {
                    $scope.activePanel = type;
                }
                function _getForm() {
                    return $scope.form.mainForm;
                }

                function validateForm() {
                    if (_getForm().$invalid) {
                        $scope.submitted = true;
                        if (_getForm().$error.required) {
                            $scope.alertOrder = alertSrv.getErrorMsg('Заполните все обязательные поля!');
                        }
                        else {
                            var errorArr = Object.keys(_getForm().$error).length > 0 ? _getForm().$error[Object.keys(_getForm().$error)[0]] : [];
                            console.log(errorArr);
                            if (errorArr.length > 0 && errorArr[0].$$element && errorArr[0].$$element[0]) {
                                $scope.alertOrder = alertSrv.getErrorMsg(errorArr[0].$$element[0].title);
                            }
                        }
                        return false;
                    }

                    return true;
                }
                function cancelFn(){
                    if($scope.state === "orders.create")
                        $state.go('orders');
                    else
                        $state.go('ordersMy');
                }

                function checkPersonalDataAgreement() {
                    if(!$scope.modelView.consentProcessing || !$scope.modelView.consentDissemination){
                        $scope.alertId = "saveError";
                        $scope.saveErrorText = !$scope.modelView.consentProcessing && !$scope.modelView.consentDissemination ? systemMessages.PERSONAL_DATA_PROCESSING_AND_DISSEMINATION :
                            (!$scope.modelView.consentProcessing ? systemMessages.PERSONAL_DATA_PROCESSING : systemMessages.PERSONAL_DATA_DISSEMINATION);
                        return false;
                    }

                    $scope.order.personalDataAgreement = true;
                    return true;
                }
            }]
    });
