/**
 * @author: sergeyu
 * Просмотр участника
 */
angular.module('uetp')
    .component('participantAgents', {
        templateUrl: 'app/components/participant/participantAgents/participantAgents.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', '$filter', 'desktopSrv', 'dictionarySrv', 'dialogSrv', 'profileSrv', 'participantSrv', 'alertSrv',
            function ($scope, $rootScope, $state, $stateParams, $filter, desktopSrv, dictionarySrv, dialogSrv, profileSrv, participantSrv, alertSrv) {
                var userRoles = [
                    {id: "admin", name: "Администратор"},
                    {id: "user", name: "Пользователь"}
                ];
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.roleList = dictionarySrv.getStaticDictByType('roles')['participant'];
                    init();
                };
                $scope.updateAgents = updateAgentsFn;
                $scope.isReadOnly = isReadOnlyFn;
                $scope.viewAgent = viewAgentFn;
                $scope.goBack = goBackFn;

                function goBackFn(){
                    history.back();
                }

                function getAgentsList() {
                    var id = $scope.user.id;
                    return profileSrv.loadAgentsList(id).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.agentsList = response.data.result;
                        } else {
                            $scope.agentsList = [];
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response)
                    })
                }

                function init() {
                    $scope.form = {userDetailsForm: {}};
                    $scope.reason = profileSrv.reasonReset();
                    $scope.cabType = desktopSrv.getCabType;
                    if (!participantSrv.getOrgInfo().data.success) {
                        dialogSrv.showDialogMsg($scope, participantSrv.getOrgInfo().data.message);
                        return;
                    }
                    $scope.user = participantSrv.getOrgInfo().data.result;
                    $scope.openForm = true;
                    $scope.userAdminRoles = userRoles;
                    // if ($scope.form.userDetailsForm) {
                    //     $scope.form.userDetailsForm.$setPristine();
                    //     $scope.form.userDetailsForm.$setUntouched();
                    // }
                    getAgentsList();
                }

                function isReadOnlyFn() {
                    return ["accountant", "support"].indexOf(desktopSrv.getCabType()) != -1;
                }

                /**
                 * Блокировка пользователя. Набор методов
                 */
                $scope.blockStatusObjects = {
                    setBlockStatus: (agent) => {
                        $scope.reason = profileSrv.setBlockStatus(agent);

                        if ($scope.form.userDetailsForm) {
                            $scope.form.userDetailsForm.$setDirty();
                        }
                    },
                    hideSetBlockStatus: (agentId) => {
                        $scope.reason = profileSrv.hideSetBlockStatus(agentId);
                    },
                    sendSetBlockStatus: async () => {
                        try {
                            const response = await profileSrv.blockUser($scope.reason);
                            if (response.data.success) {
                                $scope.blockStatusObjects.hideSetBlockStatus($scope.reason.id);
                                getAgentsList();
                            } else {
                                desktopSrv.showDialogMsg($scope, response.data.message);
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    }
                };

                /**
                 * Обнвовление представителей организации из таблицы
                 */
                function updateAgentsFn() {
                    setTimeout(function () {
                        if (!$scope.form.userDetailsForm.$valid) {
                            $scope.alertObjParticipantAgents = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');
                            return;
                        }
                        profileSrv.updateAgents($scope.agentsList).then(function successCallback(response) {
                            if (response.data.success) {
                                $scope.alertObjParticipantAgents = alertSrv.getAlertObj(response,'Изменения сохранены!');
                                if ($scope.form.userDetailsForm) $scope.form.userDetailsForm.$setPristine();
                            }
                        }, function errorCallback(response) {
                            $scope.alertObjParticipantAgents = alertSrv.getAlertObj(response);
                            console.log(response);
                        });
                    }, 700)
                }
                function viewAgentFn(id) {
                    $state.go('participantAgentView', {id:id})
                }

            }]
    });

