/**
 * @author: sergeyu
 * Профиль пользователя
 */
angular.module('uetp')
    .component('tariffsMenu', {
        templateUrl: 'app/components/tariffsServices/tariffs/tariffsMenu.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv','authSrv','tariffsSrv','storageSrv','paginationSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, authSrv,tariffsSrv,storageSrv,paginationSrv) {
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.model = {
                        showPanel: 'personalAccount',
                        showTariffsProfile: false, // тарифы
                        showInvoicesProfile: false, // счета
                        showPersonalAccount: true, // лицевой счет
                        showPaymentOrders: false, // реестр платежей
                        showCompletedWorks: false, // реестр актов
                        bgAgents: false // Агенты для банковских гарантий
                    };

                    $scope.stateConfig = $stateParams.clearConfig ?
                        storageSrv.clearStateConfig($state.current.name, 'showPanel') : storageSrv.getStateConfig($state.current.name, 'showPanel');

                    _initShowPanel();
                    _refreshView();
                    tariffsSrv.setShowPanel($scope.model.showPanel);
                };
                $scope.setActivePanel = setActivePanelFn;

                /**
                 * Инициализация панели профиля по типу ЛК
                 * @private
                 */
                function _initShowPanel() {
                    var cabType = desktopSrv.getCabType();

                    if (cabType === 'admincab') {
                        $scope.model.showPersonalAccount = false;
                    }
                    if (cabType === 'support') {
                        $scope.model.showPersonalAccount = false;
                        $scope.model.showAgents = false;
                    }
                    if (cabType === 'inspector') {
                        $scope.model.showPersonalAccount = false;
                        $scope.model.showAgents = false;
                    }
                    if (cabType === 'organizer') {
                        $scope.model.showAgents = false;
                        $scope.model.showPersonalAccount = false;
                    }
                    if (cabType === 'participant') {
                        var user = authSrv.getUser();
                        $scope.model.showFiliation = (authSrv.getAuth().userRole.includes('participant_admin') && ['ul', 'nr'].indexOf(user.typeOrg)!=-1);
                        $scope.model.showAccreditation = authSrv.getAuth().userRole.includes('participant_admin');
                        $scope.model.showTariffsProfile = true;
                        $scope.model.showInvoicesProfile = true;
                        $scope.model.showPaymentOrders = true;
                        //$scope.model.showCompletedWorks = true;
                        if(authSrv.getAuth().userRole.includes('operator_admin'))
                            $scope.model.bgAgents = true;
                        if (authSrv.getAuth().userRole.includes('user')) {
                            $scope.model.showAgents = false;
                        }

                        if ($stateParams.activeProfileTab){
                            $scope.model.showPanel = $stateParams.activeProfileTab;
                            tariffsSrv.setShowPanel($stateParams.activeProfileTab);
                            storageSrv.setStateConfig($state.current.name, 'showPanel', {typePanel: $stateParams.activeProfileTab});
                        }else{
                            if ($scope.stateConfig) {
                                setActivePanelFn($scope.stateConfig.typePanel)
                            }
                        }
                    }
                    if (cabType === 'accountant') {
                        $scope.model.showAgents = false;
                    }
                }

                /**
                 * Обновление состояния при перегрузке страницы по F5 или другим способом
                 * @private
                 */
                function _refreshView() {
                    var activePanel = $stateParams.setActivePanel;
                    if (activePanel && $scope.model.showPanel !== activePanel) {
                        setActivePanelFn(activePanel);
                    }
                }

                /**
                 * Выставление активной вкладки
                 * @param type - тип вкладки
                 */
                function setActivePanelFn(type) {
                    // $scope.model.showPanel = type;
                    $state.go('tariffsMenu', {activeProfileTab: type});
                    tariffsSrv.setShowPanel(type);
                    storageSrv.setStateConfig($state.current.name, 'showPanel', {typePanel: type});
                    paginationSrv.clearPaginationStateConfig('pagination', "tariffsMenu");
                    // $state.go('tariffsMenu');
                }

            }]
    });

