/*
* Список всех новостей с фильтром и пагинацией
* */

angular.module('uetp').component('news', {
    templateUrl: 'app/components/news/news.html',
    controller: [
        '$scope', '$state', '$stateParams', 'homeNewsSrv', 'paginationSrv', 'filterService', '$location', '$anchorScroll', '$timeout',
        function ($scope, $state, $stateParams, homeNewsSrv, paginationSrv, filterService, $location, $anchorScroll, $timeout) {
            $scope.updateNewsList = updateNewsListFn;
            $scope.currentId = $stateParams.id;
            $scope.selectNews = function (id) {
                $state.go('homeNewsBlock', {id:id, listModel:$scope.listModel});
            };

            //Параметры фильтра
            filterService.initModelView('Поиск по теме и тексту новости', 'Новости');

            //Сброс параметра пагинации везде, кроме страниц начиная со второй
            if($location.hash() !== 'top') {
                paginationSrv.clearPaginationStateConfig('pagination', $state.current.name);
            }

            //Когда необходима прокрутка
            if ($stateParams.id && $stateParams.listModel === true || !$stateParams.id) $scope.waitScroll = true;

            function scroll() {
                if ($scope.waitScroll) {

                    $timeout(function () {
                        $anchorScroll.yOffset = 400;
                        $anchorScroll($stateParams.id?'news_'+$stateParams.id:'top');
                        $scope.waitScroll = false;
                    }, 750);
                }
            }

            if ($stateParams.id) {
                var id = $stateParams.id;
                if (!$stateParams.forceLoadAll || !$stateParams.listModel) homeNewsSrv.getNewsById(id, function (news) {
                    $scope.news = news;
                    if ($scope.listModel && typeof $scope.listModel === 'object') $scope.listModel.loadedNews[id] = news;
                    scroll();
                });
            } else {
                $stateParams.listModel = true;
            }

            if ($stateParams.listModel) {
                if (typeof $stateParams.listModel === 'object') {
                    $scope.listModel = $stateParams.listModel;
                } else {
                    updateNewsListFn(scroll);
                }
            }

            function prepareTableParams(filterType) {
                var filterParams = angular.copy(filterService.getFilterParams(filterType)),
                    searchParam = angular.copy(filterService.getSearchParam(filterType));
                if (filterParams && Object.keys(filterParams).length > 0) {
                    $scope.listModel.paginator.searchByField = filterParams;
                } else {
                    delete $scope.listModel.paginator.searchByField
                }
                $scope.listModel.paginator.search = searchParam;
            }

            // Functions
            function updateNewsListFn(cb) {
                $scope.listModel = {};

                $scope.listModel.loadedNews = {};
                $scope.listModel.paginator = {
                    itemsPerPage: 20,
                    page: 0,
                    search: '',
                    searchByField: {}
                };
                var config = paginationSrv.getPaginationStateConfig('pagination', $state.current.name); // память пагинации
                if (config) {
                    $scope.listModel.paginator.page         = config.page;
                    $scope.listModel.paginator.itemsPerPage = config.itemsPerPage;
                }
                $scope.listModel.paginator.addParams = {
                    titleSize: 200,
                    contentSize: 800
                };
                prepareTableParams('filter');

                homeNewsSrv.getPublishedNews($scope.listModel.paginator, function (newsList, totalPages, newsMain) {
                    $scope.listModel.newsList   = newsList;
                    $scope.listModel.totalPages = totalPages;
                    $scope.listModel.newsMain   = newsMain;

                    if ($stateParams.forceLoadAll) {
                        $scope.listModel.newsList.forEach(function (news) {
                            homeNewsSrv.getNewsById(news.id, function (news) {$scope.listModel.loadedNews[news.id] = news;});
                        });
                    }

                    if (cb) cb()
                });
            }
        }
    ]
});