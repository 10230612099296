/**
 * Диалог с локо банком
 *
 * @author dmitrys
 */
angular.module('uetp')
    .component('bankGuaranteeLokoChat', {
        templateUrl: 'app/components/bankGuaranteeLokoChat/bankGuaranteeLokoChat.html',
        bindings: {
            bankGuaranteeChatData: '<'
        },
        controller: ['$scope', '$state', '$stateParams', 'bankGuaranteeLokoSrv', 'httpSrv', 'alertSrv', 'authSrv', 'desktopSrv',
            function ($scope, $state, $stateParams, bankGuaranteeLokoSrv, httpSrv, alertSrv, authSrv, desktopSrv) {

                var ctrl = this;
                $scope.downloadFile = httpSrv.downloadFile;
                $scope.getInvoker = bankGuaranteeLokoSrv.getInvoker;
                $scope.addFile = addFileFn;
                $scope.delRow = delRowFn;
                $scope.send = sendFn;
                $scope.getLabelbyAction = getLabelbyActionFn;
                $scope.getColorbyAction = getColorbyActionFn;
                $scope.showGuarantee = showGuaranteeFn;
                $scope.refreshChat = refreshChatFn;
                $scope.setDecisionType = setDecisionTypeFn;
                $scope.getDecisionType = getDecisionTypeFn;
                $scope.getStateByType = getStateByTypeFn;
                $scope.goBack = goBackFn;
                $scope.isReqComment = isReqCommentFn;
                $scope.model = {
                    list: [], // список сообщений
                    answer: null // модель ответа
                };
                $scope.modelView = {
                    showReplyPanel: false, //показать панель ответа
                    doingSend: false, //процесс отправки
                    isBank: true, // true - чат банка, false - чат клиента
                    actions: [], // Доступные действия
                    decisionType: null, // Выбранное решение
                    appState: null, // Состояние анкеты
                    orderId: null // id анкеты
                };
                $scope.submitted = false;
                $scope.bankGuaranteeChat = {};
                const actions = {
                    responseToBank: {
                        text: 'Ответить в банк',
                        color: 'blue'
                    },
                    agreementBank: {
                        text: 'Направить в банк правки',
                        color: 'blue'
                    },
                    payComission: {
                        text: 'Макет согласован',
                        color: 'green'
                    },
                    requestToClient: {
                        text: 'Запросить уточнение',
                        color: 'red'
                    },
                    reject: {
                        text: 'Отказать',
                        color: 'red'
                    },
                    agreementClient: {
                        text: 'Гарантия одобрена',
                        color: 'green'
                    },
                    finish: {
                        text: 'Гарантия выдана',
                        color: 'green'
                    },
                }
                $scope.isOutbox = function (fromBank) {
                    return $scope.modelView.isBank && fromBank || !$scope.modelView.isBank && !fromBank;
                }

                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.auth = authSrv.getAuth();
                    $scope.user = authSrv.getUser();
                    $scope.modelView.isBank = $scope.cabType() === 'bank';
                    $scope.modelView.orderId = $stateParams.id;
                    if (!(authSrv.canActiveBankGuarantee($scope.cabType(), $scope.user) || authSrv.isAdminBGChatView($scope.cabType(), $scope.user) || $scope.cabType() === 'bank')) {
                        $state.go('lokobank');
                    }
                    if($stateParams.message !== null){
                        $scope.alertObjBankGuaranteeChat = $stateParams.message;
                    }
                    /**
                     * Список сообщений
                     * "process": [{
                            "fromBank": true,
                            "created": "2020-08-31T07:13:47.813Z",
                            "state": {"id": "waitClient", "name": "Требует уточнения клиентом"},
                            "files": [{
                                "id": "ididid",
                                "fileName": "file.xml",
                                "size": 1143416
                            }],
                            "comment": "32423"
                        }]
                     * Доступные действия
                     * actions: ["requestToClient", "reject"]
                     */

                    const bankGuaranteeChatData = ctrl.bankGuaranteeChatData.data;
                    if (bankGuaranteeChatData && bankGuaranteeChatData.success) {
                        const result = bankGuaranteeChatData.result;
                        $scope.model.list = result.process;
                        $scope.modelView.created = result.created;
                        $scope.modelView.purchaseNumber = result.purchaseNumber;
                        $scope.modelView.appState = result.state ? result.state.id: null;
                        $scope.modelView.actions = result.actions;
                        // для агента не доступно действия в статусе анкеты agreementClient
                        if(authSrv.isUserAgent() && $scope.modelView.appState === 'agreementClient'){
                            $scope.modelView.actions = [];
                        }
                        $scope.modelView.showReplyPanel = $scope.modelView.actions? $scope.modelView.actions.length > 0: false;
                    }

                };

                /**
                 * Описание кнопки по типу action
                 */
                function getLabelbyActionFn(action) {
                    return actions[action].text || '';
                }
                /**
                 * Цвет по типу action
                 */
                function getColorbyActionFn(action) {
                    return actions[action].color || 'blue';
                }

                /**
                 * Условия обязательности коммента
                 * @returns {boolean}
                 */
                function isReqCommentFn() {
                    return ['requestToClient', 'agreementClient', 'responseToBank', 'agreementBank'].includes($scope.getDecisionType())
                }
                /**
                * Добавить файлы
                * @param files - файлы
                * */
                function addFileFn(files) {
                    if (!$scope.model.answer) $scope.model.answer = {};
                    if (!$scope.model.answer.files) $scope.model.answer.files = [];
                    angular.forEach(files, function (file) {
                        $scope.model.answer.files.push({
                            fileName: file.fileName,
                            id: file.id,
                            size: file.size
                        })
                    })
                }

                /**
                 * Удалить вложение
                 * Если есть type необходимо восстановить отображение элемента в списке
                 * @param model - модель
                 * @param index - номер строки для удаления
                 */
                function delRowFn(model, index) {
                    model.splice(index, 1);
                }

                /**
                * Отправить ответ
                * model - данные ответа
                * */
                function sendFn(model) {
                    const type = $scope.getDecisionType();
                    // Валидация формы
                    if($scope.bankGuaranteeChat.form.$invalid){
                        $scope.submitted = true;
                        return;
                    }
                    // Проверка обязательных вложений по типу ответа
                    if(['finish', 'agreementClient', 'agreementBank'].includes(type)){
                        if(!(model || {}).files || model.files && model.files.length === 0){
                            $scope.alertObjBankGuaranteeChat = alertSrv.getErrorMsg('Необходимо добавить вложение!');
                            return;
                        }
                    }
                    if (!$scope.modelView.doingSend) {
                        $scope.modelView.doingSend = true;
                    } else {
                        return;
                    }
                    const invoker = $scope.getInvoker($scope.user);
                    const interaction = {
                        invoker: invoker,
                        fileIds: model && model.files ? model.files.map(item => item.id): null,
                        comment: model && model.comment ? model.comment : null
                    };
                    bankGuaranteeLokoSrv.sendDecision($stateParams.id, type, interaction).then(function (response) {
                        if (response.data && response.data.success) {
                            $scope.alertObjBankGuaranteeChat = alertSrv.getSuccessMsg('Ответ отправлен');
                            $scope.modelView.doingSend = false;
                            $scope.refreshChat($scope.alertObjBankGuaranteeChat);
                        } else {
                            $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg(response.data.message || 'Ошибка!');
                            console.log(response)
                        }
                    }, function (reason) {
                        console.log(reason);
                    })
                }


                /**
                 * Макет согласован
                 * Подписываем и отправляем
                 */
                function payComission() {
                    $scope.modelView.doingSend = true;
                    var certId = $scope.auth.certId;
                    bankGuaranteeLokoSrv.dateForSign($stateParams.id, certId).then(
                        function (responseSign) {
                            if (responseSign.data && responseSign.data.success) {
                                sendAndSign(responseSign.data.result);
                            } else {
                                console.log(responseSign);
                                $scope.alertObjBankGuaranteeChat = alertSrv.getErrorMsg('Ошибка!');
                                $scope.modelView.doingSend = false;
                            }
                        },
                        function (reason) {
                            console.log(reason);
                            $scope.alertObjBankGuaranteeChat = alertSrv.getErrorMsg('Ошибка!');
                            $scope.modelView.doingSend = false;
                        }
                    );

                    function sendAndSign(data) {
                        // Получаем алгоритм для подписи полученые в dateForSign
                        const hashAlg = bankGuaranteeLokoSrv.hashAlg;
                        if (data) {
                            bankGuaranteeLokoSrv.signDocument(data, $scope.auth.certId, hashAlg).then(
                                function (signData) {
                                    const invoker = $scope.getInvoker($scope.user);
                                    const interaction = {
                                        invoker: invoker,
                                        sign: signData.map(item =>{
                                            return {
                                                hashValue: item.signature,
                                                idDoc: item.idDoc
                                            }
                                        }),
                                    };
                                    bankGuaranteeLokoSrv.payComission($stateParams.id, interaction).then(function (result) {
                                        if (result.data && result.data.success) {
                                            $scope.alertObjBankGuaranteeChat = alertSrv.getSuccessMsg('Ответ отправлен');
                                            $scope.modelView.doingSend = false;
                                            $scope.refreshChat($scope.alertObjBankGuaranteeChat);
                                        } else {
                                            $scope.modelView.doingSend = false;
                                            $scope.refreshChat();
                                        }
                                    });
                                },
                                function (reason) {
                                    if (typeof reason === 'string') {
                                        $scope.alertObjBankGuaranteeChat = alertSrv.getErrorMsg("Ошибка подписи документа. " + reason);

                                    } else {
                                        $scope.alertObjBankGuaranteeChat = alertSrv.getErrorMsg("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                                    }
                                    $scope.modelView.doingSend = false;
                                    console.log(reason)
                                }
                            );
                        }
                    }
                }

                /**
                * Открыть анкету
                * */
                function showGuaranteeFn() {
                    $state.go('bankGuaranteeAppLokoOpen', {id: $stateParams.id, clearConfig: true})
                }

                function refreshChatFn(message) {
                    $state.go($state.current, {id: $stateParams.id, message: message}, {reload: true});
                }
                /**
                 * Выбрать тип решения
                 * @param type - тип решения
                 */
                function setDecisionTypeFn(type) {
                    $scope.alertObjBankGuaranteeChat = null;
                    $scope.alertObjDocumentationBank = null;
                    if(type === null){
                        $scope.model.answer = null;
                    }
                    // Если макет согласован - сразу переходим к отправке
                    if (type === 'payComission') {
                        $scope.modelView.decisionType = null;
                        payComission();
                        return;
                    }

                    $scope.modelView.decisionType = type;
                }

                function getDecisionTypeFn() {
                    return $scope.modelView.decisionType;
                }

                /**
                 * Имя состояния анкеты для сообщения по типу кабинета
                 * @param state - состояние анкеты для сообщение
                 */
                function getStateByTypeFn(state) {
                    if($scope.cabType() === 'bank') {
                        if (state.id === 'payComission') {
                            return 'Макет согласован'
                        }
                    }
                    return state.name;
                }

                function goBackFn() {
                    history.back();
                }

            }]
    });
