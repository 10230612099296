angular.module('uetp').controller('subscriptionController', ['$scope', '$rootScope', 'httSrv', 'scService', function ($scope, $rootScope, httSrv, scService) {

    $scope.subscribe = subscribeFn;
    $scope.doSubscribe = doSubscribeFn;
    $scope.checkButton = checkButtonFn;
    $scope.bindSubscribe = bindSubscribeFn;

    $scope.searchButtonPressed = scService.getCounter();
    $rootScope.$on("filterClear", function(){
        if ($scope.showSubscribe) subscribeFn();
    })
    $rootScope.$on("filterSearch", function(){
        $scope.visibleSubscription = checkButtonFn();
        if ($scope.showSubscribe) subscribeFn();
        $scope.searchButtonPressed = scService.getCounter();

    });

    function subscribeFn() {
        if ($scope.showSubscribe) {
            $scope.showSubscribe = false;
            $scope.alertSubscribe = false;
        } else {
            $scope.showSubscribe = true;
            $scope.message = "";
            $scope.mailError = false;
            if (localStorage.getItem("email") != "" && localStorage.getItem("email") != "null") {
                $scope.subscription.email = localStorage.getItem("email");
            }
        }
    }

    function doSubscribeFn(email) {
        if(isDemo()) {
            $scope.alertSubscribe = true;
            $scope.message = "Данный функционал не доступен в демонстрационной версии.";
            return;
        }
        if (email) {
            var errFlg = !(/^[a-zA-Z0-9\+-_]+([\.-]?[a-zA-Z0-9\+-_]+)*@[a-zA-Z0-9\+-_]+([\.-]?[a-zA-Z0-9\+-_]+)*(\.[a-zA-Z0-9]{2,6})+$/.test(email));
            if (errFlg) {
                $scope.alertSubscribe = true;
                $scope.message = "Неверно указан email";
                $scope.mailError = true;
                return;
            }
            $scope.alertSubscribe = false;

            function prepareFilterFields() {
                angular.forEach(subscribeData, function (value, key) {
                    if (value == '' ||
                        value == '0' ||
                        value == null ||
                        key == 'name' ||
                        key == 'number' ||
                        key == 'type' ||
                        key == 's_deliveryDate' ||
                        key == 'e_deliveryDate' ||
                        key == 'IsUrgent' ||
                        key == 'status') {
                        delete subscribeData[key];
                    }
                });
            }

            var subscribeData = angular.copy($scope.filter);
            subscribeData.email = $scope.subscription.email;
            prepareFilterFields();

            httSrv.http({
                method: 'POST',
                url: '/news/subscribe',
                data: subscribeData
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.alertSubscribe = true;
                    $scope.message = "Подписка оформлена. На указанный email направлено письмо для подтверждения.";
                    $scope.showSubscribe = false;
                    $scope.mailError = false;
                    setTimeout(function () {
                        $scope.alertSubscribe = false;
                    }, 4000);
                } else {
                    $scope.alertSubscribe = true;
                    $scope.mailError = true;
                    $scope.message = "Подписка уже существует";
                }
            }, function errorCallback(response) {
                console.log(response);
            });

        } else {
            $scope.alertSubscribe = true;
            $scope.mailError = true;
            $scope.message = "Заполните email";
        }
    }

    function checkButtonFn() {
       var result = !isNullOrEmpty($scope.filter.deliveryRegion) ||
           !isNullOrEmpty($scope.filter.okpd2) ||
           !isNullOrEmpty($scope.filter.skmtr) ||
           !isNullOrEmpty($scope.filter.s_publicDate) ||
           !isNullOrEmpty($scope.filter.s_publicDate) ||
           (!isNullOrEmpty($scope.filter.s_summa) && $scope.filter.s_summa != 0)||
           (!isNullOrEmpty($scope.filter.e_summa) && $scope.filter.e_summa != 0);
        if (!result) $scope.visibleSubscription = false;
        return result;
    }

    init();

    function init() {
        $scope.showSubscribe = false;
        $scope.subscription = {};
        if (localStorage.getItem("email") != "" && localStorage.getItem("email") != "null") {
            $scope.subscription.email = localStorage.getItem("email");
        }
        $scope.alertSubscribe = false;
        $scope.message = "";
    }
    function isNullOrEmpty(el){
        return el == undefined || el == null || el == "";
    }
    function bindSubscribeFn(e){
        var keyCode = e.keyCode || e.which;
        if (keyCode == '13') {
            doSubscribeFn(e.target.value);
        }
    }
    function isDemo(){
        return $rootScope.properties && $rootScope.properties['demo.mode']==="true";
    }

}]).directive('subscriptionButtons', [function () {
    return {
        restrict: 'E',
        controller: 'subscriptionController',
        scope: {
            filter: '='
        },
        templateUrl: 'views/subscription/subscriptionButtons.html'
    };
}]);