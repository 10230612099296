/**
 * @author: alexandrbu
 */
angular.module('uetp')
    .component('bgBankAgents', {
        templateUrl: 'app/components/bgBank/bgBank/bgBankAgents/bgBankAgents.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'bgBankSrv', 'profileSrv', 'desktopSrv', 'dialogSrv', '$filter', 'dictionarySrv', 'needConfirmSrv', 'alertSrv', 'httpSrv', 'authSrv',
            function ($scope, $rootScope, $state, $stateParams, bgBankSrv, profileSrv, desktopSrv, dialogSrv, $filter, dictionarySrv, needConfirmSrv, alertSrv, httpSrv, authSrv) {
                this.$onInit = function () {
                    $scope.organizer = bgBankSrv.getOrganizatorInfo();
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.user = authSrv.getUser();
                    $scope.form = {agentsForm: {}};
                    $scope.reason = profileSrv.reasonReset();
                    $scope.roleList = dictionarySrv.getStaticDictByType('roles')['bank'];
                    $scope.getAgentsList();
                    $scope.isReadOnly = isReadOnlyFn;
                };
                $scope.getAgentsList = getAgentsListFn;
                $scope.openAgent = openAgentFn;
                $scope.goBack = goBackFn;
                $scope.cancel = cancelFn;
                $scope.deleteRow = deleteRowFn;

                function openAgentFn(data) {
                    $state.go('openBgBankAgent', {id: data.id});
                }

                /**
                 * Список представителей банка
                 */
                function getAgentsListFn() {
                    var id = $scope.organizer.id;
                    if (id) {
                        profileSrv.loadAgentsList(id).then(function successCallback(response) {
                            if (response.data.success) {
                                $scope.agentsList = response.data.result;
                            } else {
                                $scope.agentsList = [];
                                dialogSrv.showDialogMsg($scope, response.data.message);
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        })
                    }
                }

                function deleteRowFn(id) {
                    $scope.reason = profileSrv.reasonReset();
                    httpSrv.http({
                        method: 'DELETE',
                        url: '/admin/deleteOrganizerUsers/' + id
                    }).then(function successCallback(response) {
                        $scope.alertObjAgentList = alertSrv.getAlertObj(response,"Представитель удален");
                        getAgentsListFn();
                    }, function errorCallback(response) {
                        $scope.alertObjAgentList = alertSrv.getAlertObj(response);
                    });
                }

                function isReadOnlyFn() {
                    return authSrv.getAuth().userRole.some(r => ["bank_user", 'operator_support_ro'].includes(r));
                }

                /**
                 * Блокировка пользователя. Набор методов
                 */
                $scope.blockStatusObjects = {
                    setBlockStatus: (agent) => {
                        $scope.reason = profileSrv.setBlockStatus(agent);

                        if ($scope.form.agentsForm) {
                            $scope.form.agentsForm.$setDirty();
                        }
                    },
                    hideSetBlockStatus: (agentId) => {
                        $scope.reason = profileSrv.hideSetBlockStatus(agentId);
                    },
                    sendSetBlockStatus: async () => {
                        try {
                            const response = await profileSrv.blockUser($scope.reason);
                            if (response.data.success) {
                                $scope.blockStatusObjects.hideSetBlockStatus($scope.reason.id);
                                getAgentsListFn();
                            } else {
                                desktopSrv.showDialogMsg($scope, response.data.message);
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    }
                };

                function goBackFn() {
                    history.back();
                }
                function cancelFn() {
                    if($scope.form.agentsForm.$dirty){
                        needConfirmSrv.call($scope.goBack,[],'Внесенные изменения сохранены не будут');
                    }else{
                        $scope.goBack();
                    }
                }
            }]
    });

