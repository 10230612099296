angular.module('uetp').constant('blockingReport', {
    title    : 'Блокировки по процедурам',
    access   : ['admincab', 'accountant'],
    url      : '/auth/tariff/downloadBlockingReport',
    template : 'app/components/reports/types/blockingReport.html',
    data     : {
    	statementDate: null
    },
    toMSK    : ['statementDate'],
	fileName : function ($filter) {
		return "Блокировки по процедурам_" + $filter('date')(getServerTimeMsk(), 'ddMMyyyy.HHmm') + ".xlsx"
	},
});