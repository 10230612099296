/**
 * @author: sergeyu
 */
angular.module('uetp')
    .component('agentEditorOrganizer', {
        bindings: {
            agentData: '<'
        },
        templateUrl: 'app/components/organizator/agentOrg/agentEdit/agentEditor.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'organizatorSrv', 'profileSrv', 'authSrv', 'desktopSrv',
            'dictionarySrv','alertSrv','needConfirmSrv', 'httpSrv', 'propertiesService', 'dialogSrv',
            function ($scope, $rootScope, $state, $stateParams, organizatorSrv, profileSrv, authSrv, desktopSrv, dictionarySrv,
                      alertSrv,needConfirmSrv, httpSrv, propertiesService, dialogSrv) {
                $scope.saveNewAgent = saveNewAgentFn;
                $scope.setActivePanel = setActivePanelFn;
                $scope.goBack = goBackFn;
                $scope.cancel = cancelFn;
                $scope.resetLoginPassword = resetLoginPasswordFn;
                $scope.analyticAccessChange = analyticAccessChangeFn;
                $scope.onPersonalDataChanged = onPersonalDataChangedFn;
                $scope.downloadFile = httpSrv.downloadFile;
                var ctrl = this;
                $scope.ctrl = ctrl;
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.organizer = organizatorSrv.getOrganizatorInfo();
                    $scope.modelViewAgent = {isEdit: false};
                    $scope.isReadOnly = isReadOnlyFn;
                    $scope.auth = authSrv.getAuth();
                    $scope.userMchds = {};
                    $scope.agent = {};
                    $scope.agentSaved = {};
                    $scope.model = {
                        showPanel: 'requisites',
                        dataPanelCustomer: {},
                        dataPanelOrganizer: {},
                        dataPanelAnalyticReports: {},
                        analyticAccess: false
                    };
                    $scope.showDragPanel = false;
                    $scope.form = {agentForm: {}};
                    $scope.agentSaveError = null;
                    $scope.roleList = dictionarySrv.getStaticDictByType('roles')['organizer'];
                    $scope.agent.userRole = "organizer_user";
                    $scope.userCertificates = {};
                    if (ctrl.agentData) {
                        $scope.modelViewAgent.isEdit = true;
                        if (ctrl.agentData.data.success) {
                            $scope.agent = ctrl.agentData.data.result;
                            $scope.agentSaved = angular.copy($scope.agent);
                            $scope.getDataDropPanels();
                            $scope.userRoleChange();
                        } else {
                            if(!isReadOnlyFn() && !$scope.agent.userProperties)
                                $scope.agent.userProperties =
                                    { procedureWorkflow:'true', contractWorkflow: 'true', signContractEnabled: 'true', sendContractEmail: 'false', allowed_223: 'true', allowed_44: 'true', allowed_commercial: 'true'};
                        }
                    } else {
                        if(!isReadOnlyFn() && !$scope.agent.userProperties)
                                $scope.agent.userProperties = 
                                    { procedureWorkflow:'true', contractWorkflow: 'true', signContractEnabled: 'true', sendContractEmail: 'false', allowed_223: 'true', allowed_44: 'true', allowed_commercial: 'true'};
                    }

                    if($stateParams.id) {
                        profileSrv.getAgentById($stateParams.id).then(function (response) {
                            if (response.data.success) {
                                $scope.agent = response.data.result;
                                profileSrv.appendAllows($scope.agent);
                                if ($stateParams.successSave) {
                                    $scope.alertObjOrgAgentEditor = alertSrv.getAlertObj(response,'Сохранение произведено успешно!');
                                }
                                refreshCerts();
                            }
                        })
                        refreshMchds();
                    }
                    $scope.changedModel = false;
                };

                function refreshCerts() {
                    if (['admincab','support','accountant','inspector'].includes($scope.cabType())) {
                        profileSrv.loadCertificatesByUserId($scope.userCertificates, $stateParams.id);
                    }
                }
                function refreshMchds() {
                    if(['organizer_admin', 'organizer_user', 'participant_admin','user'].includes($scope.agent.userRole)) {
                        propertiesService.getProperty("mchd.url").then((host) => {
                            if (!host.endsWith('/')) host += '/';
                            $scope.mchdUrl = host;
                            profileSrv.loadAgentMchds($scope.userMchds, $scope.agent.id);
                        });
                    }
                }

                function _initModelView() {
                    $scope.modelViewAgent.isEdit = true;
                    profileSrv.getAgentById($stateParams.id).then(function (response) {
                        if (response.data.success) {
                            $scope.agent = response.data.result;
                            profileSrv.appendAllows($scope.agent);
                            $scope.getDataDropPanels();
                            if ($stateParams.successSave) {
                                $scope.alertObjOrgAgentEditor = alertSrv.getAlertObj(response,'Сохранение произведено успешно!');
                            }

                            refreshCerts();
                        }
                    })
                }

                $scope.userRoleChange = function(){
                    if(['organizer_user', 'organizer_admin', 'operator_admin'].includes($scope.agent.userRole)){
                        $scope.agent.userProperties.allowed_223 = 'true';
                        $scope.agent.userProperties.allowed_44 = 'true';
                        $scope.agent.userProperties.allowed_commercial = 'true';
                    } else {
                        delete $scope.agent.userProperties.allowed_223;
                        delete $scope.agent.userProperties.allowed_44;
                        delete $scope.agent.userProperties.allowed_commercial;
                    }
                }

                $scope.$watch("agent", function(){

                    setTimeout(function () {
                        if($scope.agent.email) {
                            $scope.changedModel = true;
                        }
                        $scope.message = document.querySelectorAll('#emailAgent')[0].title;
                        if ($scope.message && ($scope.form.agentForm.email.length || $scope.agent.email)) {
                            $scope.alertObjProfileAgentEditEmail = alertSrv.getErrorMsg($scope.message);
                        }
                    }, 500);
                }, true);
                /**
                 * Добавления нового представителя организации
                 */
                function saveNewAgentFn() {
                    if (!$scope.form.agentForm.$valid) {
                        $scope.alertObjOrgAgentEditor = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');
                        return;
                    }
                    if ($scope.agent.middleName === undefined || $scope.agent.middleName === "undefined") {
                        $scope.agent.middleName = '';
                    }

                    const confirmText = ($scope.agentSaved?.firstName!==$scope.agent?.firstName || $scope.agentSaved?.lastName!==$scope.agent?.lastName || $scope.agentSaved?.middleName!==$scope.agent?.middleName) &&
                                            ($scope.agentSaved?.mchdId || $scope.agentSaved?.certId) ?
                        "Были внесены изменения в ФИО пользователя. После сохранения изменений нужно будет зарегистрировать сертификат этого пользователя и, при необходимости, пользователю добавить МЧД. Сохранить изменения?":
                        'Выполнить действие: ' + ($scope.modelViewAgent.isEdit?'Сохранить изменения':'Зарегистрировать') + '?';

                    dialogSrv.openModal('_confirmInfoExt.html', {
                            title: "Сообщение",
                            message: confirmText,
                            buttons: [{
                                title: $scope.modelViewAgent.isEdit?'Сохранить изменения':'Зарегистрировать',
                                btnClass: "btn-success",
                                closeValue: false,
                                action: function () {
                                    if($scope.organizer.id)
                                        processSaveNewAgentFn();
                                    else
                                        organizatorSrv.getOperatorById($scope.agent.orgId).then(function (response) {
                                            if(response.data.success) {
                                                $scope.organizer = response.data.result;
                                            }
                                            processSaveNewAgentFn();
                                        });
                                }
                            },{
                                title: "Отмена",
                                btnClass: "btn-light",
                                closeValue: true
                            }]
                        });
                }

                function processSaveNewAgentFn(){
                    organizatorSrv.saveNewAgent($scope.organizer.id, $scope.agent).then((response)=>{
                        if (response.data.success) {
                            $scope.form.agentForm.$setPristine();
                            $stateParams.successSave = true;
                            $state.reload();
                        }
                    }, (responseError) => {
                        $scope.alertObjOrgAgentEditor = alertSrv.getAlertObj(responseError);
                        console.log(responseError);
                    });
                }

                function isReadOnlyFn() {
                    return ["accountant", "support"].includes(desktopSrv.getCabType());
                }

                $scope.saveCustomer = function (data) {
                    return organizatorSrv.saveUserCustomer($scope.agent.id, data);
                };
                $scope.saveOrganizer = function (data) {
                    return organizatorSrv.saveUserOrganizer($scope.agent.id, data);
                };
                $scope.saveAnalyticReport = function (res) {
                    var data={
                        assignedAnalyticReports: res,
                        analyticAccess: true
                    };
                    return organizatorSrv.saveUserAnalyticReport($scope.agent.id, data);
                };
                $scope.saveAnalyticReportNoAccess = function () {
                    var data={
                        assignedAnalyticReports: [],
                        analyticAccess: false
                    };
                     organizatorSrv.saveUserAnalyticReport($scope.agent.id, data).then(function (response) {
                         $scope.alertObjAccessAgentEdit = alertSrv.getAlertObj(response,'Сохранение произведено успешно!');
                    }, function (response) {
                         $scope.alertObjAccessAgentEdit = alertSrv.getAlertObj(response);
                    });
                };
                $scope.getDataDropPanels = function () {
                    organizatorSrv.getDataUserCustomer($scope.agent.id).then(function (response) {
                        if (response.data.success) {
                            $scope.model.dataPanelCustomer = {
                                dataLeft: response.data.result.allCustomer,
                                dataRight: response.data.result.assignedCustomer
                            };
                            organizatorSrv.getDataOrganizer($scope.agent.id).then(function (response) {
                                if (response.data.success) {
                                    $scope.model.dataPanelOrganizer = {
                                        dataLeft: response.data.result.allOrganizer,
                                        dataRight: response.data.result.assignedOrganizer
                                    };
                                    organizatorSrv.getDataAnalyticReports($scope.agent.id).then(function (response) {
                                        if (response.data.success) {
                                            $scope.model.dataPanelAnalyticReports = {
                                                dataLeft: response.data.result.allAnalyticReports,
                                                dataRight: response.data.result.assignedAnalyticReports
                                            };
                                            $scope.model.analyticAccess=response.data.result.analyticAccess;
                                            $scope.showDragPanel = true;
                                        }
                                    })
                                }
                            })
                        }
                    });
                };

                function analyticAccessChangeFn($event){
                    var isChecked=$event.target.checked;
                    $scope.model.analyticAccess = isChecked;
                }
                /**
                 * Выставление активной вкладки
                 * @param type - тип вкладки
                 */
                function setActivePanelFn(type) {
                    $scope.model.showPanel = type;
                }
                $scope.onSizeError = function (files) {
                    $scope.alertObjOrgAgentEditorSert = alertSrv.getErrorMsg("Сертификат " + files[0].name + " не добавлен. Превышен максимально разрешенный размер файла (30МБ)!");
                };
                $scope.onFileNameError = function (files) {
                    $scope.alertObjOrgAgentEditorSert = alertSrv.getErrorMsg("Документ " + files[0].name + " не добавлен. Добавление файлов с расширением *.exe запрещено!");
                };
                $scope.uploadFile = function (response) {
                    if (response.data.success) {
                        var result = response.data.result,
                            arr = result.name.split(' '),
                            prev = angular.copy($scope.agent);

                        $scope.agent.mchdReq = ['406_2_2', '406_3'].includes(response.data.errorCode);
                        
                        $scope.alertObjOrgAgentEditorSert = ['406_2_2', '406_3'].includes(response.data.errorCode) && (!$scope.modelViewAgent.isEdit || !isReadOnlyFn()) && response.data?.errorMessage ?
                            alertSrv.getSuccessMsg(response.data?.errorMessage.replace('Сохранить изменения', 'Зарегистрировать')):
                            alertSrv.getAlertObj(response,'Сертификат загружен. Необходимо сохранить изменения!');


                        $scope.agent.firstName = arr[1];
                        $scope.agent.lastName = arr[0];
                        $scope.agent.middleName = arr.length === 3 ? arr[2] : '';
                        $scope.agent.certId = response.data.message;
                        $scope.agent.email = $scope.modelViewAgent.isEdit === false ? result.email : prev.email;

                        // Сделать активной кнопку Сохранить
                        $scope.form.agentForm.$dirty = true;
                    } else {
                        $scope.alertObjOrgAgentEditorSert = alertSrv.getAlertObj(response);
                    }
                };
                function goBackFn() {
                    history.back();
                }
                function cancelFn() {
                    if($scope.form.agentForm.$dirty){
                        needConfirmSrv.call($scope.goBack,[],'Внесенные изменения сохранены не будут');
                    }else{
                        $scope.goBack();
                    }
                }

                function resetLoginPasswordFn(user) {
                    if(user == undefined){
                        return;
                    }
                    profileSrv.resetLoginPassword(user).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.alertObjOrgAgentEditor = alertSrv.getAlertObj(response, response.data.message);
                            _initModelView();
                        }
                    });
                }

                function onPersonalDataChangedFn(){
                    $scope.agent.consentProcessingDate = null;
                    $scope.agent.consentDisseminationDate = null;
                }


                $scope.getAgentInfo = () => {
                    return {"inn": $scope.organizer.inn}
                }

                $scope.hasActiveCert = () => {
                    return $scope.agent.certId ? $scope.userCertificates?.storedCerts?.find(cert=>cert.id===$scope.agent.certId) : false;
                }

                $scope.isMchdDisabled = () => !$scope.agent.mchdReq || !$scope.hasActiveCert()

                $scope.mchdCheckbox = () => {
                    if($scope.cabType() === 'admincab') {
                        $scope.agent.mchdReq = !$scope.agent.mchdReq;
                        $scope.form.agentForm.$dirty = true;
                    }
                }
                $scope.getMchdLink = (mchd) =>
                    $scope.mchdUrl + 'full?poanum=' + mchd.mchdId + '&principal=' + $scope.agent.inn + '&agent=' + mchd.agentInn;
            }]
    });

