angular.module('uetp').directive('escapeChar', ['$timeout', function ($timeout) {
    return {
    	restrict: "A",
		require: 'ngModel',
    	link: function ($scope, $elem, attrs, $ctrl) {
			$elem.on('input keyup change', function() {
				$ctrl.$setViewValue($elem.val().toString().replace(/\r/g, '').replace(/\n/g, ' '));
				$timeout(function () {
					$ctrl.$render();
				});
			});
    	}
    };
}]);