/**
 * @author: alexandrbu
 * Переторжки 
 */
angular.module('uetp')
    .component('reqExplanationsOrg', {
        templateUrl: 'app/components/procedures/reqExplanationsOrg/reqExplanationsOrg.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'proceduresSrv', 'authSrv', 'alertSrv',
            '$interval','needConfirmSrv','dialogSrv', '$filter', 'httpSrv', 'permissionSrv', 'tablesSrv', 'paginationSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, proceduresSrv, authSrv, alertSrv,
                      $interval, needConfirmSrv, dialogSrv, $filter, httpSrv, permissionSrv, tablesSrv, paginationSrv) {
                var ctrl = this;
                this.$onInit = function () {
                    ctrl.alerts = {};
                    $scope.ctrl = ctrl;
                    $scope.auth = authSrv.getAuth();
                    $scope.getCabType = desktopSrv.getCabType;
                    $scope.procedure = proceduresSrv.getProcedureData().data.result;
                    $scope.hasPermission = permissionSrv.hasPermission;
                    $scope.changeSort = changeSortFn;
                    $scope.totalPages = 0;
                    $scope.paginator = {
                            itemsPerPage: 20,
                            page: 0,
                            search: '',
                            searchByField: {}
                        };
                    $scope.actions = [];
                    if ($scope.getCabType() === 'organizer' && $scope.procedure.state.id != 'claiming' && $scope.procedure.state.id != 'published') {
                       $scope.actions.push({id:'base.requestExplanationsOrg', name:'Создать запрос участнику'});
                    }
                    const config = paginationSrv.getPaginationStateConfig('pagination', $state.current.name);
                    if (config) {
                        $scope.paginator.page = config.page;
                        $scope.paginator.itemsPerPage = config.itemsPerPage;
                    }
                    messagesLoadFn(); 
                    
                };
                $scope.totalPagesCheck = totalPagesCheckFn;
    
                $scope.messagesLoad = messagesLoadFn; 
                $scope.toAnswerOpen = toAnswerOpenFn;
                $scope.toReqOpen = toReqOpenFn;
                $scope.toAnswerCreate = toAnswerCreateFn;
                $scope.showDataTable = showDataTableFn;

                function messagesLoadFn() {
                    if (!$scope.paginator.orderBy) {
                        $scope.paginator.orderBy = "createDate";
                    }
                    var param = angular.copy($scope.paginator);
                    param.searchByField={
                    	purchaseId: $scope.procedure.id
                    };
                    if(checkAvailableCbz()) {
                        param.searchByField.senderRecipientOrgId = $scope.procedure.organizer.id;
                    }
                    httpSrv.http({
                        url: '/message/api/v2/showMessage/7',
                        data: param,
                        showCounter: true
                    }).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.dataTable = response.data.items;
                            $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }

                function checkAvailableCbz(){
                    return $scope.getCabType() === 'cbz' && proceduresSrv.isRZDOrChildRZD($scope.procedure);
                }
                
                function toAnswerOpenFn(idMsg) {
                	 $state.go('openAnswerExplanationOrg', {
                         procType: $stateParams.procType,
                         procId: $stateParams.procId,
                         explId:idMsg
                     });
                }
                
                function toReqOpenFn(idMsg) {
	               	 $state.go('openReqExplanationOrg', {
	                        procType: $stateParams.procType,
	                        procId: $stateParams.procId,
	                        explReqId:idMsg
	                    });
                }     
                function toAnswerCreateFn(idMsg) {
	               	 $state.go('createAnswerExplanationOrgFromReq', {
	                        procType: $stateParams.procType,
	                        procId: $stateParams.procId,
	                        explReqId:idMsg
	                    });
                }

                function showDataTableFn() {
	               	 return $scope.dataTable && $scope.dataTable.length > 0;
                }

                function totalPagesCheckFn(data) {
                    return Math.ceil(data.length / $scope.paginator.itemsPerPage);
                }

                function changeSortFn($event) {
                    let th = $event.currentTarget;
                    [].forEach.call(document.querySelectorAll(".sortButton"), function (button) {
                        button.classList.remove("boldSort");
                    });
                    th.classList.add("boldSort");

                    if (tablesSrv.changeSort($scope.paginator, th)) messagesLoadFn();
                }

            }]
    });

