/**
 * @author: sergeyu
 * Ответ на запрос о разъяснении (Разъяснение)
 */
angular.module('uetp')
    .component('answerReady', {
        bindings: {
            explanations: '<'
        },
        templateUrl: 'app/components/explanation/answer/answerReady/answerReady.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'explanationSrv', 'authSrv', 'constants', 'alertSrv', 'httpSrv','needConfirmSrv',
            function ($scope, $rootScope, $state, $stateParams, explanationSrv, authSrv, constants, alertSrv, httpSrv,needConfirmSrv) {
                $scope.cancel = cancelFn;
                this.$onInit = function () {
                    $scope.auth = authSrv.getAuth();
                    $scope.constants = constants;
                    $scope.form = {explanation: {}};
                    $scope.readyToBind = false;
                    $scope.submitted = false;
                    $scope.model = {
                        answerId: '',
                        explanations: []
                    };
                    $scope.stateParams = {
                        procType: $stateParams.procType, // тип закупки
                        procId: $stateParams.procId, // идентификатор закупки
                        explReqId: $stateParams.explReqId  // идентификатор запроса на разъяснение (если создаем разъяснение из запроса)
                    };
                    $scope.alertId = '';
                    $scope.viewModel = {};
                    $scope.viewModel.isEdit = false;
                    $scope.isSend = false;
                    explanationSrv.getExplanations($stateParams.procType, $stateParams.procId).then(function (response) {
                        if (response.data.success) {
                            $scope.model.explanations = response.data.result.filter(expl=>expl?.state?.id!=='created');
                        }
                    })

                };
                $scope.selectedAnswerFn = function () {
                    $scope.viewModel = {};
                    explanationSrv.getExplanation($scope.stateParams.procType, $scope.stateParams.procId, $scope.model.answerId?.id).then(function (response) {
                        if (response.data.success) {
                            $scope.viewModel = response.data.result;
                            $scope.readyToBind = true;
                        } else {
                            $scope.readyToBind = false;
                        }

                    }, function () {
                        $scope.readyToBind = false;
                    })
                };
                $scope.bindAnswer = function () {
                    if ($scope.readyToBind) {
                        $scope.submitted = true;
                        explanationSrv.bindExplanation($scope.stateParams.procType, $scope.stateParams.procId, $scope.model.answerId?.id,
                            {requestId: $scope.stateParams.explReqId}).then(function (response) {
                            if (response.data.success) {
                                $scope.isSend = true;
                                $scope.alertObjAnswerReady = alertSrv.getAlertObj(response, 'Документ привязан к запросу на разъяснение!');
                            } else {

                                if (response.data.errorMessage && response.data.errorMessage.indexOf('chooseExplanation') >= 0) {
                                    $scope.submitted = true;
                                    $scope.alertObjAnswerReady = alertSrv.getErrorMsgWithBtn('Действие уже было выполнено');
                                } else {
                                    $scope.submitted = false;
                                    $scope.alertObjAnswerReady = alertSrv.getAlertObj(response);
                                }
                            }
                        })
                    }
                };
                $scope.downloadFile = function (file) {
                    var url = '/fs/file/' + $scope.stateParams.procType + '/' + $scope.stateParams.procId + '/explanations/' + $scope.viewModel.id + '/files/' + file.id;
                    httpSrv.downloadFile(url, file.fileName);
                };
                $scope.reset = function () {
                    history.back();
                };

                function cancelFn() {
                    if($scope.form.explanation.$dirty && !$scope.isSend){
                        needConfirmSrv.call($scope.reset,[],'Внесенные изменения сохранены не будут');
                    }else{
                        $scope.reset();
                    }
                }
            }
        ]
    });

