/**
 * Диалог с банком ВТБ
 *
 * @author dmitrys
 */
angular.module('uetp')
    .component('bankGuaranteeVTBChat', {
        templateUrl: 'app/components/bankGuaranteeVTBChat/bankGuaranteeVTBChat.html',
        bindings: {
            bankGuaranteeChatData: '<',
            bankGuaranteeAppData: '<'
        },
        controller: ['$scope', '$state', '$stateParams', 'bankGuaranteeSrv', 'httpSrv', 'alertSrv', 'authSrv', 'desktopSrv', '$filter', 'bankGuaranteeVTBSrv', 'dialogSrv',
            function ($scope, $state, $stateParams, bankGuaranteeSrv, httpSrv, alertSrv, authSrv, desktopSrv, $filter, bankGuaranteeVTBSrv, dialogSrv) {

                const ctrl = this;
                $scope.downloadFile = httpSrv.downloadFile;
                $scope.reply = replyFn;
                $scope.addFile = addFileFn;
                $scope.delRow = delRowFn;
                $scope.send = sendFn;
                $scope.showGuarantee = showGuaranteeFn;
                $scope.refreshChat = refreshChatFn;
                $scope.goBack = goBackFn;
                $scope.isLocalRequest = isLocalRequestFn;
                $scope.model = {};
                $scope.bankGuaranteeParams = {};
                $scope.bankGuaranteeChat = {};
                $scope.modelView = {
                    showReplyPanel: false,//показать панель ответа
                    isBank: true, // true - чат банка, false - чат клиента
                    doingSend: false,//процесс отправки
                    isLocalReply: false // ответ в ЛК
                };
                $scope.dateOptions = {};
                $scope.tomorrow = getServerTimeMsk();
                if ($scope.tomorrow) {
                    $scope.tomorrow.setDate($scope.tomorrow.getDate() + 1);
                    $scope.tomorrow.setHours(0, 0, 0, 0);
                    $scope.dateOptionsGuaranteeStart = {
                        minDate: $scope.tomorrow,
                        maxDate: $scope.getDateOptionsGuaranteeStartMaxDate
                    };
                    $scope.dateOptionsGuaranteeEnd = {
                        minDate: $scope.getDateOptionsGuaranteeEndMinDate
                    };

                }

                $scope.dateGuaranteeStart = {
                    opened: false
                };
                $scope.dateGuaranteeEnd = {
                    opened: false
                };

                $scope.setDateOptionsGuaranteeStartMaxDate = setDateOptionsGuaranteeStartMaxDateFn;

                $scope.setDateOptionsGuaranteeEndMinDate = setDateOptionsGuaranteeEndMinDateFn;
                $scope.setDecisionType = setDecisionTypeFn;
                $scope.getDecisionType = getDecisionTypeFn;
                $scope.isMaxFileNum = isMaxFileNumFn;
                $scope.getInvoker = bankGuaranteeVTBSrv.getInvoker;

                function setDateOptionsGuaranteeStartMaxDateFn(value) {
                    $scope.dateOptionsGuaranteeStart['maxDate'] = value;
                }

                function setDateOptionsGuaranteeEndMinDateFn(value) {
                    $scope.dateOptionsGuaranteeEnd['minDate'] = value;
                }

                $scope.isOutbox = function (fromBank) {
                    return $scope.modelView.isBank && fromBank || !$scope.modelView.isBank && !fromBank;
                }

                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.auth = authSrv.getAuth();
                    $scope.user = authSrv.getUser();
                    if (!(authSrv.canActiveBankGuarantee($scope.cabType(), authSrv.getUser()) || authSrv.isAdminBGChatView($scope.cabType(), authSrv.getUser()) || $scope.cabType() === 'bank')) {
                        $state.go('bankGuaranteesVTB');
                    }
                    if ($stateParams.message !== null) {
                        $scope.alertObjBankGuaranteeChat = $stateParams.message;
                    }
                    $scope.modelView.isBank = $scope.cabType() === 'bank';
                    $scope.bankGuaranteeParams = ctrl.bankGuaranteeAppData;
                    $scope.bankGuaranteeParamsParse = JSON.parse($scope.bankGuaranteeParams.data.result);
                    $scope.modelView.created = $scope.bankGuaranteeParams.data.created;
                    $scope.modelView.number = $scope.bankGuaranteeParamsParse.bankGuarantee.purchaseNumber;
                    /*
                    * Список доступных докментов для отправки (изменяется при выборе)
                    * */
                    $scope.modelView.dopDocumentList = angular.copy(bankGuaranteeVTBSrv.getDocTypeList());
                    angular.forEach($scope.modelView.dopDocumentList, item => item.isShow = true);
                    /*
                    * Список решений с расшифровкой
                    * */
                    $scope.modelView.decisionOptionsList = angular.copy(bankGuaranteeSrv.getDict('decisionOptions'));
                    $scope.modelView.orderId = $stateParams.id;
                    /*
                    * Полный список типов файлов
                    * */
                    $scope.modelView.fullDocList = angular.copy(bankGuaranteeVTBSrv.getDocTypeList());

                    const bankGuaranteeChatData = ctrl.bankGuaranteeChatData.data;
                    if (bankGuaranteeChatData && bankGuaranteeChatData.success) {
                        const result = bankGuaranteeChatData.result;
                        $scope.model.list = getModelFromView(result);
                    }

                    $scope.selectDecision = selectDecisionFn;
                    $scope.isCustomDecision = isCustomDecisionFn;
                    $scope.sendDisabled = sendDisabledFn;
                    $scope.isReworkChangeFields = isReworkChangeFieldsFn;
                };

                /*
                * Кнопка ответить - открытие панели ответа
                */
                function replyFn(data) {
                    $scope.modelView.isLocalReply = $scope.isLocalRequest(data);
                    const inbox = data.inbox;
                    const outbox = data.outbox;
                    $scope.modelView.answerId = inbox.id;
                    $scope.model.answer = {
                        files: []
                    };
                    $scope.modelView.showReplyPanel = true;
                    if(!$scope.modelView.isLocalReply){
                        if(outbox && outbox.state === 'draft')
                            $scope.addFile(outbox.files);
                    }
                }

                /*
                * Локальный запрос или через API банка
                * Если есть id пользователя банка, создавшего запрос, то запрос локальный
                */
                function isLocalRequestFn(requestItem){
                    return requestItem && requestItem.inbox && !!requestItem.inbox.requestInvoker;
                }

                /**
                 * Выбрать тип решения
                 * @param type - тип решения                 *
                 * responseToBank - Ответить банку
                 */
                function setDecisionTypeFn(type) {
                    $scope.alertObjBankGuaranteeChat = null;
                    $scope.alertObjDocumentationBank = null;
                    if(type === null){
                        $scope.model.answer = null;
                    }
                    $scope.modelView.decisionType = type;
                }

                function getDecisionTypeFn() {
                    return $scope.modelView.decisionType;
                }

                /*
                * Получить имя типа документа по его типу
                * */
                function getLabelForType(type) {
                    let value = '';
                    angular.forEach($scope.modelView.fullDocList, item => {
                        if (item.type === type) value = item.label;
                    });
                    return value;
                }

                /*
                * Получить список для отображения
                        inbox: { - блок входящего сообщения
                            id: '' - id запроса
                            date: '',
                            comment: '',
                            decisionOptions:[] - доступные дейстивя
                            files: []
                        },
                        outbox: { - блок ответа
                            date: '',
                            decisionCode:'',
                            decisionName:'',
                            comment: '',
                            files: []
                        }
                * */
                function getModelFromView(models) {
                    const list = [];
                    angular.forEach(models, item => {
                        const newItem = {};
                        /*
                        * Входящее сообщение
                        * */
                        if (item.requestContent) {
                            newItem.inbox = getInbox(item);
                        }
                        /*
                        * Ответ
                        * */
                        if (item.clientResponse) {
                            newItem.outbox = getOutbox(item.clientResponse);
                        }
                        list.push(newItem)
                    });

                    /*
                    * Разбор входящего сообщения
                    * */
                    function getInbox(data) {
                        const inbox = {};
                        const content = JSON.parse(data.requestContent);
                        inbox.taskName = content.taskName;
                        inbox.comment = content.statusDescription;
                        inbox.date = data.created;
                        inbox.id = data.id;
                        inbox.state = data.requestState;
                        getDocument(inbox, content.documents, true);
                        inbox.decisionOptions = content.decisionOptions;
                        inbox.taskDefinitionKey = content.taskDefinitionKey;
                        inbox.requestInvoker = data.requestInvoker;
                        return inbox;
                    }

                    /*
                    * Разбор исходящего сообщения
                    * */
                    function getOutbox(data) {
                        const outbox = {};
                        const content = JSON.parse(data.clientResponseContent);
                        outbox.comment = content.decision ? content.decision.comment : content.comment;
                        getDocument(outbox, content.documents ? content.documents : content.files, false);
                        outbox.date = data.sendDate;
                        outbox.state = data.responseState;

                        return outbox;
                    }

                    /*
                    * Преобразуем блок документов
                    * */
                    function getDocument(model, documents, isInbox) {
                        if (!isInbox) {
                            model.files = documents;
                            angular.forEach(model.files, file => file.label = getLabelForType(file.type));
                        }
                        if (isInbox) {
                            model.files = [];
                            angular.forEach(documents, item => {
                                const label = getLabelForType(item.type);
                                angular.forEach(item.files, file => {
                                    const newFile = file;
                                    newFile.type = item.type;
                                    newFile.label = label;
                                    model.files.push(newFile);
                                })
                            });
                        }
                    }
                    return list;
                }

                /*
                * Добавить файлы по типу вложения
                * @param files - файлы
                * */
                function addFileFn(files) {
                    if (!$scope.model.answer.files) $scope.model.answer.files = [];
                    angular.forEach(files, file => {
                        $scope.model.answer.files.push({
                            created: file.created,
                            fileName: file.fileName,
                            id: file.id,
                            size: file.size,
                            type: file.type
                        })
                    });
                }

                /**
                 * Удалить вложение
                 * Если есть type необходимо восстановить отображение элемента в списке
                 * @param model - модель
                 * @param index - номер строки для удаления
                 * @param type - тип документа
                 */
                function delRowFn(model, index) {
                    model.splice(index, 1);
                }

                function isMaxFileNumFn(files) {
                    return files && files.length >= 10;
                }

                /*
                * Отправить ответ
                * model - данные ответа
                * */
                function sendFn(model) {
                    if ($scope.modelView.doingSend) {
                        return;
                    }
                    $scope.modelView.doingSend = true;
                    
                    if (!$scope.modelView.answerId) {
                        $scope.alertObjBankGuaranteeChat = alertSrv.getErrorMsg('Ошибка! Не выбран запрос для ответа');
                        $scope.modelView.doingSend = false;
                        return;
                    }
                    
                    
                    if($scope.bankGuaranteeChat.form.$invalid){
                        $scope.submitted = true;
                        $scope.modelView.doingSend = false;
                        return;
                    }

                    if(model.files && model.files.length > 10){
                        $scope.alertObjBankGuaranteeChat = alertSrv.getErrorMsg('Добавлено больше 10 вложений');
                        $scope.modelView.doingSend = false;
                        return;
                    }
                    if (!model.comment) {
                        $scope.alertObjBankGuaranteeChat = alertSrv.getErrorMsg('Для отправки ответа в банк необходимо указать комментарий');
                        $scope.modelView.doingSend = false;
                        return;
                    }
                    const data = {};
                    if (model.comment || model.decision) {
                        data.decision = {};
                        if (model.decision) data.decision.resultCode = model.decision;
                        if (model.comment) data.decision.comment = model.comment;
                    }
                    if (model.files.length>0) {
                        data.documents = [];
                        angular.forEach(model.files, file => {
                            data.documents.push({
                                fileName: file.fileName,
                                type: !file.type ? "GUARANTEE_OFFER_PDF" : file.type,
                                id: file.id
                            })
                        })
                    }
                    if($scope.modelView.isLocalReply){
                        bankGuaranteeVTBSrv.responseToBankLocal($stateParams.id, $scope.modelView.answerId, model).then(function (response) {
                            if (response.data && response.data.success) {
                                $scope.alertObjBankGuaranteeChat = alertSrv.getSuccessMsg('Ответ отправлен');
                                $scope.modelView.doingSend = false;
                                $scope.refreshChat($scope.alertObjBankGuaranteeChat);
                            } else {
                                $scope.alertObjbankGuaranteeApp = alertSrv.getErrorMsg(response.data.message || 'Ошибка!');
                                $scope.modelView.doingSend = false;
                                console.log(response)
                            }
                        }, function (reason) {
                            console.log(reason);
                        })
                    }
                    else{
                        const certId = $scope.auth.certId;
                        bankGuaranteeVTBSrv.answer($stateParams.id, $scope.modelView.answerId, data).then(response => {
                            if (response.data && response.data.success) {
                                if ($scope.cabType() === 'agent') {
                                    sendToBank(response.data.result.id);
                                } else {
                                    bankGuaranteeVTBSrv.getDocumentsDataForSign(certId, response.data.result.id)
                                        .then(responseSign => {
                                                if (responseSign.data && responseSign.data.success) {
                                                    sendAndSign(responseSign.data.result, response.data.result.id);
                                                } else {
                                                    error(`Ошибка!`, responseSign);
                                                }
                                            },
                                            reason => {
                                                error(`Ошибка!`, reason);
                                            }
                                        );
                                }
                            } else {
                                error(`Ошибка!`, response);
                            }
                            //скрыть панель ответа
                        }, reason => {
                            error(`Ошибка!`, reason);
                        });
                    }

                    function sendAndSign(data, reqId) {
                        if (data && data.dataList) {
                            bankGuaranteeVTBSrv.signDocument(data.dataList, $scope.auth.certId).then(signData => {
                                    bankGuaranteeVTBSrv.sendSignedDocuments(signData, reqId).then(response => {
                                        if (response.data && response.data.success) {
                                            sendToBank(reqId);
                                        } else {
                                            error(`Ошибка!`, response);
                                        }
                                    }, reason => {
                                        error(`Ошибка!`, reason);
                                    })

                                },
                                reason => {
                                    error(typeof reason === 'string' ? `Ошибка подписи документа. ${reason}` : `Ошибка подписи документа. ${reason.name}: ${reason.message}`, reason);
                                }
                            );
                        } else {
                            sendToBank(reqId);
                        }
                    }

                    function sendToBank(reqId){
                        bankGuaranteeVTBSrv.sendToBank($stateParams.id, reqId).then(result => {
                            if (result.data && result.data.success) {
                                delete $scope.modelView.answerId;
                                success(`Ответ отправлен`);
                                refreshChatFn($scope.alertObjBankGuaranteeChat);
                            } else {
                                dialogSrv.showDialogMsg($scope, 'Ошибка связи с банком! Обратитесь в Службу технической поддержки.');
                                refreshChatFn();
                            }
                        })
                    }

                    function success(msg){
                        $scope.alertObjBankGuaranteeChat = alertSrv.getSuccessMsg(msg);
                        $scope.modelView.doingSend = false;
                        $scope.modelView.showReplyPanel = false;
                    }

                    function error(msg, data){
                        $scope.alertObjBankGuaranteeChat = alertSrv.getErrorMsg(msg);
                        $scope.modelView.doingSend = false;
                        if(data)
                            console.log(data);
                    }
                }

                /*
                * Открыть анкету
                * */
                function showGuaranteeFn() {
                    $state.go('bankGuaranteeAppVTBOpen', {id: $stateParams.id, clearConfig: true})
                }

                function refreshChatFn(message) {
                    $state.go($state.current, {id: $stateParams.id, message: message}, {reload: true});
                }

                function goBackFn() {
                    if ($stateParams.participantSelected) {
                        $state.go('bankGuaranteesVTB', {participantInfo:  $stateParams.participantInfo, participantSelected: $stateParams.participantSelected});
                    } else{
                        history.back();
                    }
                }

                function selectDecisionFn() {
                    if ($scope.modelView.taskDefinitionKey == 'UserTaskInitiatorApproveDocs2') {
                        if ($scope.model.answer.decision == 'REWORK') {
                            $scope.model.answer.documents = [];
                            $scope.model.answer.documents.push({
                                type: "doc_guaranteeLot",
                                label: "Макет БГ",
                                isMultiple: false,
                                isRequired: true,
                                canEdit: true,
                                isShow: true,
                                files: []
                            });
                        } else {
                            $scope.model.answer.documents = [];
                        }
                    } else if ($scope.modelView.taskDefinitionKey == 'UserTaskInitiatorApproveDocs') {
                        if ($scope.model.answer.decision == 'REWORK') {
                            $scope.model.answer.bankGuarantee = {};
                        } else {
                            delete $scope.model.answer.bankGuarantee;
                        }
                    }
                }

                function isCustomDecisionFn() {
                    return $scope.modelView.taskDefinitionKey == 'UserTaskInitiatorApproveDocs2' &&
                        $scope.model.answer && $scope.model.answer.decision == 'REWORK';
                }

                function sendDisabledFn() {
                    let needGuaranteeLotFile = false;
                    if ($scope.isCustomDecision()) {
                        needGuaranteeLotFile = true;
                        angular.forEach($scope.model.answer.documents, item => {
                            if (item.type == 'doc_guaranteeLot' && item.files && item.files.length > 0) {
                                needGuaranteeLotFile = false;
                            }
                        })
                    }

                    return $scope.modelView.doingSend || needGuaranteeLotFile ||
                        $scope.modelView.taskDefinitionKey == 'UserTaskInitiatorApproveDocs2' && (!$scope.model.answer || !$scope.model.answer.decision) ||
                        $scope.modelView.taskDefinitionKey == 'UserTaskInitiatorApproveDocs' && (!$scope.model.answer || !$scope.model.answer.decision ||
                            $scope.model.answer.decision == 'REWORK' && (!$scope.model.answer.bankGuarantee || !$scope.model.answer.bankGuarantee.bankGuaranteeSum || !$scope.model.answer.bankGuarantee.startDate || !$scope.model.answer.bankGuarantee.endDate));
                }

                function isReworkChangeFieldsFn() {
                    return $scope.modelView.taskDefinitionKey == 'UserTaskInitiatorApproveDocs' &&
                        $scope.model.answer && $scope.model.answer.decision == 'REWORK';
                }

                $scope.onFileNameError = () =>
                    $scope.modelView.alertObjDocumentationBank = alertSrv.getErrorMsg("Файл не добавлен. Можно добавлять только файлы с расширением pdf.");
            }]
    });
