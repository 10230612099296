/**
 * @author: sergeyu
 * Создание заказчика
 */
angular.module('uetp')
    .component('customerRequisites', {
        templateUrl: 'app/components/customer/customerRequisites/customerRequisites.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'dictionarySrv', 'httpSrv', 'alertSrv','needConfirmSrv', 'customerSrv', 'organizatorSrv', '$timeout',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, dictionarySrv, httpSrv, alertSrv, needConfirmSrv, customerSrv, organizatorSrv, $timeout) {
                this.$onInit = function () {
                    $scope.form = {};
                    $scope.user = {regAddress:{oksmCode:'643', oksmName:'РОССИЙСКАЯ ФЕДЕРАЦИЯ'}, realAddress:{oksmCode:'643', oksmName:'РОССИЙСКАЯ ФЕДЕРАЦИЯ'},
                        allowed223:true, allowed44:false, allowedCommercial:true, defaultCommercial:false,eisnds:false,noticeIntegrationEIS:false,
                        auctionOpenProtocolEnabled:false, freeTariff: false, freeSVP: false};
                    $scope.prevRealAddress = {oksmCode:'643', oksmName:'РОССИЙСКАЯ ФЕДЕРАЦИЯ'};
                    $scope.modelView = {isEdit: false};
                    $scope.isReadOnly = isReadOnlyFn;
                    $scope.typeCustomer = dictionarySrv.getStaticDictByType('typeCustomer');
                    $scope.typePortal = dictionarySrv.getStaticDictByType('typePortal');
                    $scope.bikChangeHandler = bikChangeHandlerFn;
                    $scope.loginreadonlyrequisites = true;
                    $scope.passreadonlyrequisites = true;
                    _initDictionaryBase();
                    if ($stateParams.id) {
                        $scope.modelView.isEdit = true;
                        $scope.user = customerSrv.getCustomerInfo();
                        $scope.isChecked = organizatorSrv.equalsWithExceptions($scope.user.regAddress, $scope.user.realAddress, 'id') || !$scope.user.realAddress || !$scope.user.realAddress.oksmCode;
                        if($scope.user.requisites && $scope.user.requisites.bicBank){
                            $scope.refreshDict('rBIK', null, false, null, $scope.user.requisites.bicBank)
                        }
                    }

                    $timeout(function () {
                        $scope.loginreadonlyrequisites = false;
                        $scope.passreadonlyrequisites = false;
                    }, 500);

                    $scope.submitted = true;
                };
                $scope.refreshDict = refreshDictFn;
                $scope.createOrUpdate = createOrUpdateFn;
                $scope.createWithOrganizer = createWithOrganizerFn;
                $scope.orgFilter = orgFilterFn;
                $scope.allowCommercialChange = allowCommercialChangeFn;
                $scope.parentOrgChange = parentOrgChangeFn;
                $scope.portalTypeDisabled = portalTypeDisabledFn;
                $scope.cabType = desktopSrv.getCabType();
                $scope.cancel = function () {
                    //$state.go('customer')


                    if($scope.form.registerForm.$dirty){
                        needConfirmSrv.call($state.go,['customer'],'Внесенные изменения сохранены не будут');
                    }else{
                        $state.go('customer');
                    }

                };
                $scope.changeCustomerType = function () {
                    $scope.loginreadonlyrequisites = true;
                    $scope.passreadonlyrequisites = true;
                    
                    if($scope.user.customerType === 'head'){
                    	if($scope.user.parent){
                    	   delete $scope.user.portalType;
                    	}
                        delete $scope.user.parent;
                       
                    }else if ($scope.user.customerType === 'division'){
                    	delete $scope.user.requisites;
                        $scope.user.freeTariff = false;
                        $scope.user.freeSVP = false;
                    }
                    if(!$scope.user.customerType !== 'division') {
                        $timeout(function () {
                            $scope.loginreadonlyrequisites = false;
                            $scope.passreadonlyrequisites = false;
                        }, 500);
                    }
                    customerSrv.setCustomerType($scope.user.customerType);
                };
                function allowCommercialChangeFn($event){
                    if (!$event.target.checked){
                        $scope.user.defaultCommercial = false;
                    }
                }


                function isReadOnlyFn() {
                    return ["accountant", "support"].indexOf(desktopSrv.getCabType())!=-1;
                }

                function orgFilterFn(el){
                    return !$scope.user.id || $scope.user.id!= el.id;
                }
                /**
                 * Создание, обновление заказчика
                 */
                function createOrUpdateFn(data) {
                    $scope.submitted = true;
                    if ($scope.form && !$scope.form.registerForm.$valid) {
                        $scope.alertObjCustomerEdit = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');
                        $scope.submitted = false;
                        return;
                    }
                    if ($scope.form &&!data.allowed223&&!data.allowed44&&!data.allowedCommercial){
                        $scope.alertObjCustomerEdit = alertSrv.getErrorMsg('Должно быть заполнено хотя бы одно из полей: Участие в закупках по 223-ФЗ, Участие в закупках по 44-ФЗ, Участие в коммерческих торгах.');
                        $scope.submitted = false;
                        return;
                    }

                    if(!organizatorSrv.checkRequisites(data.requisites))
                        delete data.requisites;

                    customerSrv.createUpdateCustomer(data).then(function (response) {
                        $scope.submitted = false;
                        $scope.form.registerForm.$setPristine();
                        if (response.data.success) {
                            const msg = $scope.modelView.isEdit?'Изменение реквизитов организации произведено успешно!':'Организация успешно зарегистрирована';
                            $scope.alertObjCustomerEdit = alertSrv.getAlertObj(response, msg);
                            $scope.modelView.isEdit = true;
                            if(['admincab','support'].includes($scope.cabType))
                                $rootScope.$emit('setCustomerViewName', data.shortName ? data.shortName : data.name);
                            $state.go('customerView', {id: response.data.result, successSave: true},{location :'replace'});
                        } else {
                            $scope.alertObjCustomerEdit = alertSrv.getAlertObj(response);
                        }
                    })
                }
                /**
                 * Создание заказчика с созданием организатора
                 */
                function createWithOrganizerFn(data) {
                    $scope.submitted = true;
                    if ($scope.form && !$scope.form.registerForm.$valid) {
                        $scope.alertObjCustomerEdit = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');
                        $scope.submitted = false;
                        return;
                    }
                    if ($scope.form &&!data.allowed223&&!data.allowed44&&!data.allowedCommercial){
                        $scope.alertObjCustomerEdit = alertSrv.getErrorMsg('Должно быть заполнено хотя бы одно из полей: Участие в закупках по 223-ФЗ, Участие в закупках по 44-ФЗ, Участие в коммерческих торгах.');
                        $scope.submitted = false;
                        return;
                    }
                    customerSrv.createCustomerAndOrganizer(data).then(function (response) {
                        $scope.submitted = false;
                        $scope.form.registerForm.$setPristine();
                        if (response.data.success) {
                            var msg ='Организация успешно зарегистрирована';
                            $scope.alertObjCustomerEdit = alertSrv.getAlertObj(response, msg);
                            $scope.modelView.isEdit = true;
                            if (!$scope.user.id) {
                                $state.go('customerRequisites', {id: response.data.result, successSave: true},{location :'replace'});
                            }
                            $scope.user.id = response.data.result;
                        } else {
                            $scope.alertObjCustomerEdit = alertSrv.getAlertObj(response);
                        }
                    })
                }


                function _initDictionaryBase() {
                    $scope.dictionary = dictionarySrv.getRemoteData();
                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                    dictionarySrv.resetPaginatedDict($scope);
                    _initDict(['okcm', 'fias_region', 'customers'], false);

                    function _initDict(dictList, reload) {
                        angular.forEach(dictList, function (name) {
                            dictionarySrv.getDictionaryRemote(name, reload);
                        })

                    }
                }

                /**
                 * Совпадает ли адрес с Юр.адресом
                 * @param checked
                 */
                $scope.checkedAddress = function (checked) {
                    if (!checked) {
                        $scope.prevRealAddress = angular.copy($scope.user.realAddress);
                        delete $scope.user.realAddress;
                    } else {
                        $scope.user.realAddress = angular.copy($scope.prevRealAddress);
                    }
                    $scope.form.registerForm.$setDirty();
                };

                /**
                 * Обновление справочника
                 * @param object - тип справочника
                 * @param value - введенные значения
                 * @param refreshScrolling - обновление данных при прокрутке
                 */
                function refreshDictFn(object, value, refreshScrolling, needToAdd, searchValue) {
                    if (!value) {
                        if (object === 'rBIK') {
                            value = searchValue;
                        }
                    }
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling, null, needToAdd)
                }
                
                function parentOrgChangeFn() {
                	if($scope.user.parent){
                		$scope.user.portalType = $scope.user.parent.portalType;
                 	}
                }
               
                function portalTypeDisabledFn() {
                	return ['division','dzo'].includes($scope.user.customerType) && $scope.user.parent;
                }
                /**
                 * Заполнить Номер корреспондентского счета банка из выбранного банка
                 */
                function bikChangeHandlerFn(selectObj, dataObj) {
                    var selected = selectObj.$select.selected,
                        bankNameProperty = angular.isUndefined(dataObj.bankName) ? "bank" : "bankName";//т.к. отличаются названия полей в json

                    dataObj[bankNameProperty] = selected ? selected.participant : "";
                    dataObj.bankAcc = selected ? selected.accounts : "";
                }
            }]

    });

