angular.module('uetp')
    .component('fasArchive', {
        templateUrl: 'app/components/fas/fasArchive.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'constants', 'authSrv', 'desktopSrv', 'dialogSrv', 'profileSrv', 'cryptography', 'dictionarySrv', 'httpSrv', '$filter', 'timeout',
            function ($scope, $rootScope, $state, $stateParams, constants, authSrv, desktopSrv, dialogSrv, profileSrv, cryptography, dictionarySrv, httpSrv, $filter, timeout) {
                $scope.constants = constants;
                $scope.downloadFasArchive = downloadFasArchiveFn;
                $scope.refreshDict = refreshDictFn;
                $scope.downloadFile = downloadFileFn;
                $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                $scope.dictionary = dictionarySrv.getRemoteData();

                this.$onInit = function () {
                    $scope.model = {};
                    $scope.submitted = false;
                    $scope.filterElement = {};
                    $scope.goBack = goBackFn;
                };

                /**
                 * Обновление справочника
                 * @param object - тип справочника
                 * @param value - введенные значения
                 * @param refreshScrolling - обновление данных при прокрутке
                 */
                function refreshDictFn(object, value, refreshScrolling) {
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling)
                }
                
                function downloadFileFn(archive) {
                    if(archive.extralarge){
                        httpSrv.downloadFile(`/fs/file/procedureDocArchiveById/${archive.procList[0]}/${!!archive.withClaims}/${encodeURIComponent(archive.fileName)}`);
                    } else {
                        $scope.throbbers.push(archive.fileName);
                        $rootScope.$broadcast('setShowThrobber', true);

                        const params = {
                            certificateId: archive.withClaims,
                            tokenId: archive.fileName,
                            userRole: archive.procList
                        };
                        httpSrv.downloadFile('/fs/file/procedureDocList', archive.fileName, params);
                    }
                }

                function downloadFasArchiveFn() {
                    if($scope.showThrobber)
                        return;
                    $scope.searchProcess = true;
                    delete $scope.archives;
                    let customers = '';
                    if ($scope.model.customer) {
                        $scope.model.customer.forEach(item=>
                            customers += customers === '' ? item.id : (',' + item.id)
                        );
                    }

                    const pager = {
                        searchByField: {
                            customer: customers,
                            withClaims: $scope.model.withClaims,
                            isOrganizer: $scope.model.isOrganizer
                        }
                    }

                    if($scope.model.startDate && $scope.model.endDate && $scope.model.startDate > $scope.model.endDate)
                        $scope.model.endDate = $scope.model.startDate;

                    if ($scope.model.startDate instanceof Date)
                        pager.searchByField.startDate = $filter('date')($scope.model.startDate, 'dd.MM.yyyy', 'UTC+3') + ' 00:00:00';
                    if ($scope.model.endDate instanceof Date)
                        pager.searchByField.endDate = $filter('date')($scope.model.endDate, 'dd.MM.yyyy', 'UTC+3') + ' 23:59:59';

                    httpSrv.http({
                        method: 'POST',
                        url: `/admin/getFASDownloadList`,
                        data: pager
                    }).then((response)=>{
                        if(response.data.success) {
                            $scope.archives = response.data.result;
                            $scope.throbbers = [];
                        }
                        $scope.searchProcess = false;
                    });
                }

                function goBackFn() {
                    history.back();
                }

                $scope.$on('setShowThrobber', function (event, data) {
                   $scope.showThrobber = data;
                   if(!$scope.showThrobber)
                       $scope.throbbers = [];
                   timeout($scope);
                });


                $scope.checkDateTimeGE = function(field1, field2) {
                    if(!(field1 && field2)) return true;

                    const field1Date = new Date(angular.copy(field1)).setSeconds(0, 0), field2Date = new Date(angular.copy(field2)).setSeconds(0, 0);
                    const date1 = field1Date instanceof Date ? Date.parse(field1Date) : field1Date, date2 = field2Date instanceof Date ? Date.parse(field2Date) : field2Date;
                    return date1>=date2;
				}

            }]

    });

