/**
 * @author: sergeyu
 * Сервис для компонентов Участников
 */
angular.module('uetp').service('participantSrv', ['$state', 'httpSrv', 'authSrv',
    function ($state, httpSrv, authSrv) {
        var service = this,
            showMsg = {zmo: true, accredExpires: true, paymentCheck: true},
            orgInfo = {},
            favoritesLoaded = false,
            favorites = [];

        this.setShowMsg = function(name, value) {
            showMsg[name] = value;
        }

        this.isShowMsg = function(name) {
            return showMsg[name];
        }

        this.needUpdateAuth = function(){
            return !(authSrv.getAuth().approvalStates && authSrv.getAuth().approvalStates.includes("market") && this.isShowMsg('zmo'));
        }

        /**
         * Получить Участника по его ID
         */
        this.getOrgById = function (id) {
            return httpSrv.http({
                method: 'GET',
                url: '/admin/getOrganizationInfoById/' + id
            });
        };

        this.setOrgInfo = function (org) {
            orgInfo = org;
        };

        this.getOrgInfo = function () {
            return orgInfo;
        };
        
        /**
         * Получить список ид избранных закупок
         */
        this.loadFavorites = function () {
            return httpSrv.http({
                method: 'GET',
                url: '/participant/procedures/favorites'
            });
        };

        this.getFavorites = function () {
            return favorites;
        };
        
        this.setFavorites = function (favoritesData) {
            favorites = favoritesData;
        };
        
        /**
         * Проставить признак избранной закупки для списка закупок
         */
        this.markFavorites = function (procedures) {
            if(!favoritesLoaded){
               this.loadFavorites().then(function successCallback(response) {
                  if (response.data.success) {
                      service.setFavorites(response.data.result);
                      service.doMarkFavorites(procedures);
                  }
               });
               favoritesLoaded = true;
            }else{
	           service.doMarkFavorites(procedures);
            }
        };

        this.doMarkFavorites = function (procedures) {
	     if(service.getFavorites().length > 0){
		    angular.forEach(procedures, function (item) {
	           if(service.getFavorites().includes(item.id)){
		           item.favorite = true;
	           }
           });
          }
        };

        /**
         * Добавить в список избранных закупок
         */
        this.addFavorite = function (id) {
	        if(favoritesLoaded && service.getFavorites().indexOf(id) == -1){
               service.getFavorites().push(id);
            }
        };

        /**
         * Удалить из списка избранных закупок
         */
        this.removeFavorite = function (id) {
	        var pos = service.getFavorites().indexOf(id);
            if(pos !== -1){
               service.getFavorites().splice(pos, 1);
            }
        };

        /**
         * Обёртка для добавления/удаленияь из списка избранных закупок
         */
        this.updateFavorite = function (procedure) {
	        if(favoritesLoaded){
	          if(procedure.favorite){
		        service.addFavorite(procedure.id);
	          }else{
		        service.removeFavorite(procedure.id);
	          }
            }
        };
    }]);