/**
 * @author: sergeyu
 * Компонент входа в лк
 */
angular.module('uetp')
    .component('loginpanel', {
        bindings: {
            callback: '=',
            data: '<',
            templateType: '@'
        },
        templateUrl: ['$element', '$attrs', function($element, $attrs) {
            if ($attrs.templateType === 'mobile') {
                return 'app/components/login/loginPanelMobile.html';
            } else {
                return 'app/components/login/loginPanel.html';
            }
        }],
        controller: ['$scope', '$rootScope', '$state', 'authSrv', 'desktopSrv', 'profileSrv', 'cryptography', 'jwtHelper', '$sce', 'propertiesService', 'constants', 'timeout', 'alertSrv', 'dialogSrv',
            function ($scope, $rootScope, $state, authSrv, desktopSrv, profileSrv, cryptography, jwtHelper, $sce, propertiesService, constants, timeout, alertSrv, dialogSrv) {

                $scope.login = loginFn;
                $scope.loginCert = loginCertFn;
                $scope.loginWebmarket = loginWebmarketFn;
                $scope.focusLogin = focusLoginFn;
                $scope.signIn = signInFn;
                /**
                 * Сброс капчи
                 */
                $scope.reset = resetFn;
                /**
                 * Инициализация капчи
                 */
                $scope.initCaptcha = initCaptchaFn;
                /**
                 * Обновление капчи
                 */
                $scope.refreshCaptcha = refreshCaptchaFn;
                /**
                 * Заполняем сертификаты в селект
                 */
                $scope.fillSelect = fillSelectFn;

                /**
                 * Возможность кроссавторизации через ЭМ
                 */
                $scope.webmarketLoginEnabled  = false;
                
                propertiesService.getProperty('crossauth.market').then(function (val) {
                    $scope.webmarketLoginEnabled = val==="true";
                    timeout($scope);
                });

                var ctrl = this;
                $scope.passShow = false;
                this.$onInit = function () {
                    $scope.$sce = $sce;
                    $scope.blockLoginBtn = false;
                    $scope.showCertForm = false;
                    $scope.showLoginForm = true;
                    $scope.successLoadPlugin = false;
                    $scope.errorMsgLoadPlugin = null;
                    $scope.certSelected = null;
                    $scope.model = {};
                    if (ctrl.data) {
                        $scope.data = ctrl.data;
                    }
                    $scope.closeLoginModal = closeLoginModalFn;
                };
                
                /**
                 * Отправка логина и пароля для дальнейшей аутентификации
                 * @param model
                 */
                function closeLoginModalFn() {
                    ctrl.callback();
                }

                function loginFn(model) {
                    if (!model.login || !model.password) {
                        return;
                    }
                    delete $scope.model.error;
                    delete $scope.model.errorMsg;
                    delete $scope.model.isBlocked;
                    delete $scope.model.portalUrl;
                    $scope.blockLoginBtn = true;

                    authSrv.login(model.login, model.password, model.captcha).then(function (auth) {
                        _initCabAfterLogin(auth);
                    }, function (reason) {
                        $scope.blockLoginBtn = false;
                        if (reason.data.errorCode === "003") {
                            $scope.model.isBlocked = true;
                        } else if (reason.data.errorCode === "015" ) {
                            $scope.model.error = true;
                            $scope.model.portalUrl = reason.data.message;
                        } else {
                            if (reason.data.errorCode === "001" || reason.data.errorCode === "006") {
                                //todo пока так, нужен параметр для отображения капчи
                                initCaptchaFn();
                            }
                            $scope.model.error = true;
                            $scope.model.errorMsg = reason.data.message;
                        }
                    });
                }

                function loginCertFn() {
                    if (!$scope.selectedCert) {
                        return;
                    }
                    delete $scope.model.error;
                    delete $scope.model.errorMsg;
                    delete $scope.model.isBlocked;
                    delete $scope.model.portalUrl;
                    $scope.blockLoginBtn = true;
                    authSrv.loginByCertGetSign().then(function (response) {
                        if (response.data.success) {
                            var a = response.data.result;
                            var data = jwtHelper.decodeToken(a);
                            cryptography.generateSignature(window.Base64.encode(data.jti), $scope.selectedCert.thumbPrint).then(function (signature) {
                                authSrv.loginByCert({
                                    id: a,
                                    message: signature
                                }).then(function (auth) {
                                    _initCabAfterLogin(auth);
                                }, function (reason) {
                                    $scope.blockLoginBtn = false;
                                    $scope.model.error = true;
                                    if (reason.data.errorCode === "015" ) {
                                        $scope.model.portalUrl = reason.data.message;
                                    }
                                    else{
                                        $scope.model.errorMsg = reason.data.message;
                                    }

                                })
                            }, function (reason) {
                                $scope.blockLoginBtn = false;
                                if (typeof reason === 'string') {
                                    $scope.model.error = true;
                                    $scope.model.errorMsg = "Ошибка подписи документа. " + reason;
                                } else {
                                    $scope.model.error = true;
                                    $scope.model.errorMsg = "Ошибка подписи документа. " + reason.name + ': ' + reason.message
                                }
                            })
                        }
                    }, function (reason) {
                        $scope.blockLoginBtn = false;
                        if (reason.data.errorCode === "003") {
                            $scope.model.isBlocked = true;
                        } else {
                            $scope.model.error = true;
                            $scope.model.errorMsg = reason.data.message;
                        }
                    })
                }

                function loginWebmarketFn() {
                	propertiesService.getProperty("market.url").then(function (host) {
                		propertiesService.getProperty("main.url."+(constants.configProject.type==="etp" ? "uetp" : ""+constants.configProject.type)).then(function (redirectHost) {
                			var url = host +'/auth?redirect_uri='+redirectHost+'/rest/auth/user/crossauthentry';
                            if ($scope.data) {
                                sessionStorage.setItem("redirect_params", JSON.stringify($scope.data));
                            }
                			window.location.href = url;
                		});
                	});
                }

                function resetFn() {
                    $scope.captcha = {};
                }

                function initCaptchaFn() {
                    $scope.model.captcha="";
                    $scope.captcha = {};
                    $scope.captcha.url = "/rest/auth/captcha.png?r=" + Math.random();
                }

                function refreshCaptchaFn() {
                    $scope.captcha.url = "/rest/auth/captcha.png?r=" + Math.random();
                }

                function _initCabAfterLogin(auth) {
                    $scope.blockLoginBtn = false;
                    $scope.auth = authSrv.getAuth();
                    desktopSrv.initCabinet(auth, false);
                    profileSrv.loadOrganizationInfo().then(function (userData) {
                        if (userData.data.success) {
                            const user = userData.data.result;
                            authSrv.setUser(user);
                            authSrv.checkProfileCert();
                            authSrv.setProfileSuccess(user.approvalOrg);
                            authSrv.setReglamentSuccess(user.approvalReglament);
                            authSrv.setUserValid(profileSrv.validateProfileModel(user));
                            authSrv.isCloseTariffPanel(user.id);
                            if ($scope.data && $scope.data.state) {
                                ctrl.callback();
                                $state.go($scope.data.state, $scope.data.param);
                            } else if ($scope.data && $scope.data.url) {
                                ctrl.callback();
                                $state.go(getUrl($scope.data.url, user, $scope.data.activeProfileTab), {
                                    procType: $scope.data.procType,
                                    procId: $scope.data.procId,
                                    id: $scope.data.id,
                                    lotId: $scope.data.lotId,
                                    openFactoring: $scope.data.openFactoring,
                                    activeProfileTab: (user.orgRole==='participant' ? $scope.data.activeProfileTab : null)
                                });
                            } else {
                                desktopSrv.initCabinet(auth, true);
                            }

                            checkMchdValidity();

                            function checkMchdValidity() {
                                if(['organizer_admin', 'organizer_user', 'participant_admin', 'user'].includes(authSrv.getUser().userRole)) {
                                    profileSrv.mchdLoginCertCheck().then(function (checkRes) {
                                        if(!checkRes.data.success) {
                                            if(checkRes.data.errorCode==='430') {
                                                dialogSrv.openModal('_confirmInfoExt.html', {
                                                    title: "Внимание!",
                                                    message: checkRes.data.errorMessage,
                                                    buttons: [{
                                                        title: "Продолжить",
                                                        btnClass: "btn-success",
                                                        closeValue: false,
                                                        action: function () {
                                                            profileSrv.logUserMchd();
                                                            $rootScope.$emit('mchdError', false);
                                                        }
                                                    // },{
                                                    //     title: "Повторить проверку",
                                                    //     btnClass: "btn-success",
                                                    //     closeValue: false,
                                                    //     action: function () {
                                                    //         checkMchdValidity();
                                                    //     }
                                                    },{
                                                        title: "Отмена",
                                                        btnClass: "btn-light",
                                                        closeValue: true
                                                    }]
                                                });
                                            } else if(checkRes.data.errorCode==='427') {
                                                authSrv.updateMchdCheckResult(checkRes.data.errorMessage);
                                                $rootScope.$emit('mchdError', true, checkRes.data.errorMessage);
                                            }
                                        }
                                    });
                                }
                            }


                            profileSrv.checkAccredSecured().then(function (checkRes) {
                                authSrv.updateOrgAccreditation(checkRes.data.result);
                            });
                            profileSrv.checkBGLimits(user); // просто вызываем проверку лимита для обновления данных в базе при логине
                            $rootScope.$emit("login");
                        } else {
                            authSrv.exit();
                        }
                    });
                    function getUrl(url, user, activeProfileTab) {
                        return (url==='profile' && user.orgRole!=='participant' && activeProfileTab==='accreditation') ? 'home' : url;
                    }
                }



                /**
                 * Инициализация селекта сертификатов которые находятся в личном хранилище
                 */
                var selectCert = null;


                function fillSelectFn() {
                    if (!authSrv.getAuth().withoutCertEnabled) {
                        setTimeout(function () {
                            selectCert = document.getElementById("cert");
                            if (selectCert) {
                                cryptography.initPluginWorking().then(function (plugin) {
                                    plugin.initHtmlChecker().then(function (answer) {
                                        if (answer.status) {
                                            fillAllCertByStore(plugin, 'cert').then(function () {
                                                    $scope.successLoadPlugin = answer.status;
                                                    $scope.$apply()
                                                },
                                                function (reason) {
                                                    if (reason.success === false && reason.error && reason.error.message.indexOf("Не найдено сертификатов") !== -1) {
                                                        $scope.errorMsgLoadPlugin = "Не найдено ни одного действующего сертификата";
                                                        $scope.$apply()
                                                    }
                                                });

                                        } else {
                                            $scope.errorMsgLoadPlugin = answer.text;
                                            $scope.$apply()
                                        }
                                    }, function (reason) {
                                        $scope.errorMsgLoadPlugin = reason;
                                        $scope.$apply()
                                    });

                                }, function (reason) {
                                    $scope.errorMsgLoadPlugin = reason;
                                    $scope.$apply()
                                });
                                selectCert.addEventListener('change', certChangeHandler);
                            }
                        }, 500);
                    }
                    signInFn('cert');
                }

                function certChangeHandler() {
                    $scope.selectedCert = null;
                    if (!this.value) return $scope.$digest();
                    $scope.selectedCert = this[this.value];
                }

                function focusLoginFn() {
                    window.document.getElementById("login").focus()
                }

                function signInFn(attr) {
                    $scope.model = {};
                    $scope.captcha = null;
                    $scope.selectedCert = null;
                    if (attr === 'cert') {
                        $scope.showLoginForm = false;
                        $scope.showCertForm = true;
                    } else if (attr === 'login') {
                        $scope.showCertForm = false;
                        $scope.showLoginForm = true;
                    }
                }
            }]
    });

