/**
 * Вывод кнопки получения банковской гарантии с логикой проверки лимита
 */
angular.module('uetp').directive("bgbutton", [function () {
    return {
        restrict: 'E',
        templateUrl: 'app/directives/bunkGuarantees/bunkGuarantees.html',
        scope: {
            disabled: '=',
            checkLimit: '=',
            purchaseData: '=',
            purchaseDataType: '@'
        },
        compile: function () {
            return {
                pre: function (scope) {
                    scope.init();
                }
            }
        },
        controller: ['$scope', 'bankGuaranteeSrv', 'authSrv', 'desktopSrv',
            function ($scope, bankGuaranteeSrv, authSrv, desktopSrv) {
                $scope.cabType = desktopSrv.getCabType;
                $scope.getBankGuarantees = getBankGuaranteesFn;
                $scope.model = {
                    needNewBG: null,
                    disabled: null,
                    checkLimit: null,
                    purchaseData: null,
                    purchaseDataType: null
                };

                $scope.init = function () {
                    if (angular.isDefined($scope.disabled)) $scope.model.disabled = $scope.disabled;
                    if (angular.isDefined($scope.checkLimit)) $scope.model.checkLimit = $scope.checkLimit;
                    if (angular.isDefined($scope.purchaseData)) $scope.model.purchaseData = $scope.purchaseData;
                    if (angular.isDefined($scope.purchaseDataType)) $scope.model.purchaseDataType = $scope.purchaseDataType;
                    _startLogicBtn();
                };

                function _startLogicBtn() {
                    try {
                        if (desktopSrv.getCabType() === 'home' || $scope.model.disabled) {
                            $scope.model.needNewBG = true; // выводить кнопку получить БГ
                        } else {
                            $scope.user = authSrv.getUser();
                            if ($scope.user.typeOrg === 'ul' || $scope.user.typeOrg === 'ip') {
                                setTimeout(function () {
                                    bankGuaranteeSrv.getLimits($scope.user.inn, $scope.user.ogrn, $scope.user.fullName, $scope.user.id, $scope.user.typeOrg).then(function (limit) {
                                        $scope.model.needNewBG =
                                            !(limit.data.success && limit.data.result); // если есть лимит инфорируем пользователя
                                    }, function (reason) {
                                        console.log(reason);
                                        $scope.model.needNewBG = true;
                                    })
                                }, 1000)
                            } else {
                                $scope.model.needNewBG = true;
                            }
                        }
                    } catch (e) {
                        console.warn('directive bg warn: ' + e);
                        $scope.model.needNewBG = true; // отображаем кнопку в любом случае

                    }
                }

                function getBankGuaranteesFn() {
                    localStorage.bankGuaranteesParams = JSON.stringify({
                        checkLimit: $scope.model.checkLimit,
                        purchasData: $scope.model.purchaseData,
                        purchasDataType: $scope.model.purchaseDataType
                    });
                    window.open(document.location.origin);
                }

            }]
    }
}]);