/**
 * @author dmitrys
 * Вкладка расширеные поля
 */
angular.module('uetp')
    .component('extendNoticeField', {
        bindings: {
            initLoadData: '<' // обновляем данные когда станет true
        },
        templateUrl: 'app/components/notice/extendNoticeField/extendNoticeField.html',
        controller: ['$scope', '$rootScope', '$state','noticeSrv','extendFieldSrv',
            function ($scope, $rootScope, $state,noticeSrv,extendFieldSrv) {
                $scope.model = {};
                //список полей
                $scope.model.list = [];
                //$scope.model.tabs = [];
                $scope.formObj = {};
                $scope.isReadOnly = noticeSrv.getReadOnlyForm();
                $scope.isNoticeExtFields = noticeSrv.isNoticeExtFields;
                $scope.isNoticeIntegrationEIS = noticeSrv.isNoticeIntegrationEIS;
                /*
                * Признак открытой вкладки
                * */
                var isOpen = false;
                this.$onInit = function () {
                    var notice = noticeSrv.getNoticeInfo();
                    if (notice.document) {
                        var customerId = null;
                        var noticeIntegrationEIS = false;
                        if (notice.document.customer && $scope.model.customer.id) {
                            customerId = $scope.model.customer.id;
                        } else if (notice.document.customerCode) {
                            customerId = notice.document.customerCode;
                            noticeIntegrationEIS = notice.noticeIntegrationEIS;
                        }
                        if (customerId != null && noticeSrv.isShowExtendedFields() && noticeIntegrationEIS &&
                        	notice.type.id != 'simplecontest') {
                            var data = {};
                            data.typeCode = notice.document.typeCode;
                            data.customerId = customerId;
                            loadNewTemplate(data);
                        }
                    }
                };
                this.$postLink = function () {
                    //проставляем форму для валидации
                    noticeSrv.setForm('extendField',$scope.formObj);
                };
                this.$onChanges = function (changesObj) {
                    if (changesObj.initLoadData.currentValue && !isOpen) {
                        /*
                        * При каждом открытии вкладки надо проверять загруженный шаблон, т.к при смене заказчика шаблон меняется
                        * */
                        isOpen = true;
                    }else{
                        //если isOpen = true -> выполняем переход со вкладки
                        if(isOpen){
                            isOpen = false;
                            var data = extendFieldSrv.getExtendFieldForSave($scope.model.list);
                            extendFieldSrv.setNoticeField(data);
                        }
                    }
                };
                /*
                * Строим форму по полученым полям
                * */
                function initModel() {
                    //получаем загруженный шаблон
                    $scope.model.list = angular.copy(extendFieldSrv.getNoticeExtFieldTemplate('NOTICE'));
                    var model = [];//, groups = [];
                    var notice = noticeSrv.getNoticeInfo();
                    if(notice.document){
                        if(!notice.document.extendFields)notice.document.extendFields = {};
                        /*
                        * Получаем данные
                        * Поля: integrCode, description, type, value
                        * */
                        model = extendFieldSrv.getExtendFieldForModel(notice.document.extendFields);
                        //проставляем полученые данные в поля шаблона
                        extendFieldSrv.setModelToTemplate($scope.model.list, model);
                    }
                }
                /*
                * Загружаем шаблон
                * data.typeCode - тип процедуры
                * data.customerId - id заказчика
                * */
                function loadNewTemplate(data) {
                    //сбрасываем шаблон и признак зашрузки шаблона
                    extendFieldSrv.clearNoticeExtFieldTemplate();
                    extendFieldSrv.setLoadTemplate(false);
                    //пытаемся получить новый шаблон
                    extendFieldSrv.loadNoticeExtFieldTemplate(data.typeCode, data.customerId, $scope).then(
                        function (response) {
                            if (response.data.success) {
                                //все данные получены - инициализируем форму
                                initModel();
                                // оповещаем об изменении списка обязательных полей
                                $rootScope.$broadcast('uetp.notice', {data: $scope.model, dataType: 'lot', type: 'getLotRequired'});
                                noticeSrv.setNoticeShowCustomerEISError(false);
                            } else {
                                noticeSrv.setNoticeShowCustomerEISError(true);
                            }
                            noticeSrv.setCustomerLoading(false);
                        });
                }
                /*
                * refresh - Получем актульаные данные при открытой вкладе
                * newLoad - Загружаем новый шаблон и строим форму
                * */
                $scope.$on('uetp.extendNoticeField', function (event, data) {
                    if(noticeSrv.isShowExtendedFields()){
                        if(data.type ==='newLoad'){
                            //сбрасываем Расширеные поля
                            extendFieldSrv.setNoticeField(null);
                            extendFieldSrv.setTemplateVersion(null);
                            $scope.model.list = [];
                            loadNewTemplate(data);
                        }else if(data.type ==='refresh'){
                            if(isOpen || data.ignoreOpenState){
                                var noticeExtendField = extendFieldSrv.getExtendFieldForSave($scope.model.list);
                                extendFieldSrv.setNoticeField(noticeExtendField);
                            }
                        }
                    }
                });
            }]
    });
