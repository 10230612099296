/**
 * Новости
 */
angular.module('uetp')
    .component('homeNewsPpk', {
        templateUrl: 'app/components/news/homeNews/homeNewsPpk.html',
        controller: ['$scope', '$rootScope', 'httpSrv', '$state',
            function ($scope, $rootScope, httpSrv, $state) {
                $scope.newsList = [];
                $scope.newsMain = [];
                $scope.paginator = {
                    itemsPerPage: 20,
                    page: 0,
                    addParams: {
                        titleSize: 100,
                        contentSize: 400
                    }
                };

                this.$onInit = function () {
                    httpSrv.http({
                        method: 'POST',
                        url: '/news/lastNewsHomePageList',
                        data: $scope.paginator
                    }).then(function successCallback(response) {
                        if (response.data.success) $scope.newsMain = response.data.items;
                    }, function errorCallback(response) {
                        console.error(response);
                    });
                };

                $scope.selectNews = function (id) {
                    $state.go('homeNewsBlock', {id: id, listModel: true});
                };
            }]
    });