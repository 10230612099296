angular.module('uetp').constant('tariffUsage', {
    title    : 'Использование тарифов',
    access   : ['admincab', 'accountant'],
    url      : '/auth/tariff/downloadTariffUsageXls',
    template : 'app/components/reports/types/tariffUsageReport.html',
    data     : {
        endDate   : null,
        startDate : null
    },
    toMSK    : ['startDate', 'endDate'],
    minMaxOpt : {
        date: {
            minRequired: true,
            maxFillTime: true
        }
    }
});