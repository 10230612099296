/* Превью картинки с просмотром в модальном окне
	Атрибуты:
	picture-url - ссылка на картинку
	picture-title - заголовок в окне просмотра
	picture-tooltip - всплывающая подсказка, если "true", отображает текст из picture-title
* */

angular.module('uetp').component('previewModal', {
	templateUrl: 'app/components/preview/previewModal.html',
	require: {
		ngModel: "^ngModel"
	},
	bindings: {
		pictures: '<'
	},
	controller: [
		'$scope',
		function ($scope) {
			var it = this;
			this.$onInit = function () {
				$scope.indx = it.ngModel.$viewValue;

				$scope.closePreview = function() {
					document.removeEventListener('keydown', $scope.escHandler);
					it.ngModel.$setViewValue(null);
					$scope.picture = null;
					$scope.indx = null;
				};
				$scope.changePic = function(next) {
					if (next && $scope.nextPic) {
						it.ngModel.$setViewValue(it.ngModel.$viewValue+1);
						$scope.indx += 1;
					}
					else if ($scope.prevPic) {
						it.ngModel.$setViewValue(it.ngModel.$viewValue-1);
						$scope.indx -= 1;
					}
					render();
				};
				$scope.changePicIdx = (indx) => {
					it.ngModel.$setViewValue(indx);
					$scope.indx = indx;
					render();
				};
				$scope.changeNextPreview = (next) => {
					let nextIndx = next ? $scope.indx + 1 : $scope.indx - 1;
					if(next && $scope.indx>=(getUnique(it.pictures).length -1))
						nextIndx = 0;
					else if(!next && $scope.indx===0)
						nextIndx = getUnique(it.pictures).length -1;
					$scope.changePicIdx(nextIndx);
				}
				function render() {
					const pics = getUnique(it.pictures);
					const current = it.ngModel.$viewValue;
					if(!$scope.indx)
						$scope.indx = current;
					if (!pics) return;
					$scope.picture = pics[current];
					$scope.prevPic = current > 0 ? pics[current-1] : null;
					$scope.nextPic = pics.length > current+1 ? pics[current+1] : null;
				}

				$scope.getPictures = () => {
					return getUnique($scope.pics);
				}
				function getUnique(pics) {
					if(pics.length>1 && pics[0].url===pics[1].url)
						return Array.from(pics).slice(1);
					else return pics;
				}
				$scope.pics = getUnique(it.pictures);
				it.ngModel.$render = render;
			};
		}
	]
});

angular.module('uetp').component('preview', {
	templateUrl: 'app/components/preview/preview.html',
	require: {
		ngModel: "^ngModel"
	},
	bindings: {
		pictureId      : '<',
		pictureUrl     : '<',
		pictureTitle   : '<',
		pictureTooltip : '<',
		pictures       : '<',
		positionId	   : '<',
		indicator      : '<',
		picIds		   : '<'
	},
	controller: [
		'$scope', '$element',
		function ($scope, $element) {
			var it = this;

			this.$onInit = function () {
				$scope.indicator = it.indicator;
				$scope.pictureUrl = it.pictureUrl;
				$scope.positionId = it.positionId;
				if($scope.indicator && it.picIds) {
					$scope.sPicIds = it.picIds;
				}
				if (it.pictureTooltip) {
					$scope.pictureTooltip = typeof it.pictureTooltip === 'string'
						? it.pictureTooltip
						: it.pictureTitle
				}
				var pics = it.pictures;
				pics.push({
					url   : it.pictureUrl,
					title : it.pictureTitle,
				});

				$scope.openPreview = function () {
					const idx = it.pictures.findIndex(pic=>pic.url===it.pictureUrl);
					it.ngModel.$setViewValue(idx===0 ? idx : (idx-1));
					document.addEventListener('keydown', $scope.escHandler);
				};

			};
			$scope.escHandler = (event) => {
				if('Escape'===event.code) {
					document.getElementById('preview-modal-btn').click();
				}
			}

			$element.addClass('preview');
		}
	]
});
