angular.module('uetp')
    .component('positionsImg', {
        templateUrl: 'app/components/positionsImg/positionsImg.html',
        require: {
            ngModel: "^ngModel"
        },
        bindings: {
            attachments: '<',
            positionId: '<',
            positionSite: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', '$timeout',
            function ($scope, $rootScope, $state, $stateParams, $timeout) {
                $scope.box = {pictures: [], ngCurrentPic: ''};
                this.$onInit = function () {
                    $scope.attachments = this.attachments;
                    $scope.positionId = this.positionId;
                    $scope.positionSite = this.positionSite;
                    $scope.positionModel = this.ngModel;
                    $scope.model = {};
                    $scope.model.id = $stateParams.id
                    $scope.picIds = $scope.attachments ? $scope.attachments.map(a=>a.id) : [];
                    $timeout(function(){
                        if($scope.picIds.length>0)
                            document.getElementById('imgIndicator_'+$scope.positionId+'_'+$scope.picIds[0]).classList.add('preview__indicator-item-active')
                    })
                };
                $scope.getCurrent = () => {
                    if(!$scope.current) return $scope.attachments[0];
                    else return $scope.current;
                }
                $scope.setPreview = (current, url, desc) => {
                    $scope.current = current;
                    document.getElementById('img_'+$scope.positionId).src=url;
                    document.getElementById('img_'+$scope.positionId).parentElement.title=desc;
                    document.getElementById('img_'+$scope.positionId).alt=desc;
                    const indicator = document.getElementById('imgIndicatorBlock_'+$scope.positionId);
                    const curActiveIndicator = indicator.getElementsByClassName('preview__indicator-item-active');
                    if(curActiveIndicator && curActiveIndicator.length>0)
                        curActiveIndicator[0].classList.remove('preview__indicator-item-active');
                    document.getElementById('imgIndicator_'+$scope.positionId+'_'+$scope.current.id).classList.add('preview__indicator-item-active')
                }

            }]
    });

