import { sentryMsg } from '../utils/sentry.es6';

/**
 * @author: sergeyu
 * Сервис для выполнения запросов на сервер
 */
angular.module('uetp').service('httpSrv', ['$http', '$filter', '$rootScope', '$timeout', '$state', 'constants', 'authSrv', 'dialogSrv',
    function ($http, $filter, $rootScope, $timeout, $state, constants, authSrv, dialogSrv) {
        /**
         * Параметры запроса по умолчанию
         */
        var baseParams = {
            method: 'POST',
            headers: {'Content-Type': 'application/json; charset=utf-8'}
        };
        var service = this;
        service.sendMsgSentry = function (response) {
            if (response && response.data && !response.data.success) {
                sentryMsg(response.data);
            }
        };
        /**
         *
         * @param params = {
     *                  methods - тип запроса POST, GET (option, default POST),
     *                  url - адрес запроса (require),
     *                  header - дополнительные параметры в заголоке запроса (option, default AUTH-TOKEN , FP, Content-Type - application/json; charset=utf-8),
     *                  data - данные для передачи на сервер  (option)
     *                 }
         * return promise request или error только then(success, error)
         */
        this.http = function (params) {
            var validate = validateParams(params);
            if (validate === true) {
                params.method = angular.isDefined(params.method) ? params.method : baseParams.method;
                params.headers = angular.isDefined(params.headers) ? params.headers : baseParams.headers;
                if (params.headers) {
                    params.headers['Type-Shop'] = constants.configProject.reqType;
                }
                return new Promise(function (resolve, reject) {
                    $http(params).then(function (response) {
                        service.sendMsgSentry(response);
                        if (response.status === 200 || response.data.success || !angular.isDefined(response.data.success)) {
                            resolve(response)
                        } else if (response.data.success === false){
                            if (response.data.errorCode) {
                                resolve(response)
                            } else {
                                reject(response)
                            }
                        } else {
                            reject(response)
                        }
                    }, function (reason) {
                        reject(reason)
                    })
                })
            } else {
                return validate
            }
        };
        this.downloadFile = downloadFileFn;
        this.downloadEmulatedLink = downloadEmulatedLinkFn; // эмулируем клик. Иначе Хром подумаем что мы злоупотребляем доверием пользователя. В момент не явной выгрузки файла
        this.downloader = downloaderFn;
        this.requestXHR = requestXHRFn;
        
        /**
         * Проверка параметров запроса
         * @param params
         * @returns boolean true || promise error
         */
        function validateParams(params) {
            var msg = null;
            if (!angular.isDefined(params)) {
                msg = "Не заданы параметры запроса.";
            } else if (!params.url) {
                msg = "Не заданы адрес запроса. param: URL";
            } else if (params.url) {
                if (!params.denyChangeUrl) {
                    params.url = params.url.startsWith('/') ? ('rest'
                        + params.url) : 'rest/' + params.url;
                }
            }

            if (msg) {
                return Promise.reject(new Error(msg));
            }
            return true;
        }

        /**
         * Получение имени файла по стандарту RFC5987
         * @param contentDisposition - headers
         * @returns {*}
         */
        function getFileNameByContentDisposition(contentDisposition) {
            if (contentDisposition) {
                var splitContent = contentDisposition.split("; "),
                    content = null;
                var file;
                angular.forEach(splitContent, function (name) {
                    if (name.indexOf('filename*=') >= 0) {
                        content = name;
                    } else {
                        file = name.slice(name.lastIndexOf("=") + 2, -1);
                    }
                });
                if (content) {
                    var regex = /filename[^;=\n]*=(UTF-8(['"]*))?(.*)/;
                    var matches = regex.exec(content);
                    var filename;

                    if (matches != null && matches[3]) {
                        filename = matches[3].replace(/['"]/g, '');
                        filename = filename.replace(/\+/g, ' ');
                    }

                    return decodeURIComponent(filename);
                } else {
                    return decodeURIComponent(file);
                }
            }
            console.log(contentDisposition);
            return null;
        }
        function downloadEmulatedLinkFn(url) {
            var a = document.createElement("a");
                a.href  = url;
                a.target = '_blank';
                document.body.appendChild(a);
                a.click();
            setTimeout(function () {
                document.body.removeChild(a);
            }, 500);

        }
        function downloadFileFn(url, fileName, data, useXhr, scope, notFoundMsg) {
            if (!data && !useXhr){
                authSrv.addTypeShop();
                var urlPath = "";
                //для запросов к внешнии ресурсам
                if(url.startsWith('http://') || url.startsWith('https://')){
                    urlPath = url;
                }else{
                    urlPath = url.startsWith('/') ? ('rest' + url) : ('rest/' + url);
                }
                var a = document.createElement("a");
                a.href  = urlPath;
                a.target = '_blank';
                a.setAttribute('download','download');
                document.body.appendChild(a);
                a.click();
                setTimeout(function () {
                    document.body.removeChild(a);
                    authSrv.removeTypeShop();
                }, 1000);

                //window.open(urlPath, "_blank");
            } else {
                sendXhr(url, fileName, data, scope, notFoundMsg);
            }

            function sendXhr(url, fileName, data, scope, notFoundMsg) {
                var xhr = new XMLHttpRequest(),
                    ticket = localStorage.getItem("key");
                var urlPath = "";
                if(url.startsWith('http://') || url.startsWith('https://')){
                    urlPath = url;
                }else{
                    urlPath = url.startsWith('/') ? ('rest' + url) : ('rest/' + url);
                }
                xhr.open(data ? 'post' : 'get', urlPath);
                if (ticket) {
                    xhr.setRequestHeader('Auth-Token', ticket);
                }
                xhr.setRequestHeader('Type-Shop', constants.configProject.reqType);
                xhr.responseType = "arraybuffer";
                xhr.onload = function () {
                	$rootScope.$broadcast('setShowThrobber', false);
                    var blob = new Blob([xhr.response], {type: "application/octet-stream"}),
                        url = window.URL.createObjectURL(blob),
                        a = document.createElement("a"),
                        respHeader = getFileNameByContentDisposition(this.getResponseHeader("Content-Disposition"));
                    if (!respHeader && !fileName) {
                        console.log("Ошибка выполнения запроса: " + status + ": " + xhr.statusText + " (" + xhr.responseURL + ")");
                        return;
                    }
                    if(xhr.status !== 200 && scope){
                     	dialogSrv.showDialogMsg(scope, notFoundMsg||'Файл не найден');
                        return;
                    }
                    if(xhr.status == 401){
                        $state.go('home');
                        return;
                    }
                    var fName = fileName ? fileName : respHeader;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0)
                        window.navigator.msSaveBlob(blob, fName);
                    else {
                        a.href = url;
                        a.download = fName;
                        document.body.appendChild(a);
                        a.click();
                        setTimeout(function () {
                            document.body.removeChild(a);
                            window.URL.revokeObjectURL(blob)
                        }, 500);
                    }
                };
                if (data) {
                    function prepareFilterFields() {
                        angular.forEach(sendData, function (value, key) {
                            if (value === '') {
                                delete sendData[key];
                            } else {
                                if (key === 'org') {
                                    // проверяем org т.к при выставление в селект организации и удаление по крестику в модели появляется org и пытается отправиться с параметром null
                                    if (sendData[key]) {
                                        sendData['orgId'] = sendData[key].id;
                                    }
                                    delete sendData[key];
                                }
                                if (value instanceof Date) {
                                    sendData[key] = $filter('date')(sendData[key], 'dd.MM.yyyy HH:mm:ss');
                                }
                            }
                        });
                    }

                    var sendData = angular.copy(data);
                    prepareFilterFields();
                    xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
                    sendData = angular.copy(JSON.stringify(sendData));
                }
                $rootScope.$broadcast('setShowThrobber', true);
                xhr.send(data ? sendData : null);
            }
        }

        /**
         * Когда перенесем в куки токен можно использовать эту выгрузку
         * @param url
         * @param mth
         */
        function downloaderFn(url, mth) {
            var iframe = window.document.getElementById('download-file-iFrame');
            var urlPath = url.startsWith('/') ? ('rest'
                + url) : ('rest/' + url);
            iframe.src = urlPath;
            mth = mth || 'GET';
            var container = document.createElement("div");
            container.setAttribute("id", "download-file-container-download");
            document.body.appendChild(container);
            var form = document.createElement("form");
            form.setAttribute("name", "download-file-form");
            form.setAttribute("action", urlPath);
            form.setAttribute("method", mth);
            form.setAttribute("target", "download-file-iFrame");
            container.appendChild(form);
            form.submit();
        }
        
        /**
         * Запрос через XHR
         * scope - требуется выполнить apply
         * @param params = {
         *                  methods - тип запроса POST, GET (option, default POST),
         *                  url - адрес запроса (require),
         *                  header - дополнительные параметры в заголоке запроса (option, default AUTH-TOKEN , FP, Content-Type - application/json; charset=utf-8),
         *                  data - данные для передачи на сервер  (option)
         *                 }
         * return promise request или error только then(success, error)
         */
        function requestXHRFn(scope,params) {
            var XHR = ("onload" in new XMLHttpRequest()) ? XMLHttpRequest : XDomainRequest;
            var xhr = new XHR();
            var ticket = localStorage.getItem("key");
            params.method = angular.isDefined(params.method) ? params.method : 'POST';

            xhr.open(params.method, params.url, true);

            xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
            xhr.setRequestHeader('Type-Shop', constants.configProject.reqType);
            if (ticket) {
                xhr.setRequestHeader('Auth-Token', ticket);
            }
            var param = angular.copy(params.data);
            xhr.send(params.data?JSON.stringify(param):null);

            return new Promise(function (resolve, reject) {
                xhr.onload = function () {
                    if(xhr.status !== 200){
                        return reject(this);
                    }
                	const response = {data: JSON.parse(xhr.response)};
                    resolve(response);
                    $timeout(function () {
                        if (scope) scope.$apply();
                    });
                };
                xhr.onError = function () {
                    console.log(this);
                    reject(this);
                };
            });
        }

    }]);
