angular.module('uetp').controller('subscriptionNewsController', ['$scope', '$rootScope', 'httpSrv','authSrv', function ($scope, $rootScope, httpSrv, authSrv) {

    $scope.subscribe = subscribeFn;
    $scope.doSubscribe = doSubscribeFn;
    $scope.checkButton = checkButtonFn;
    $scope.bindSubscribe = bindSubscribeFn;

    function subscribeFn() {
        if ($scope.showSubscribe) {
            $scope.showSubscribe = false;
            $scope.alertSubscribe = false;
            $scope.subscription.email = "";
        } else {
            $scope.showSubscribe = true;
            $scope.message = "";
            $scope.mailError = false;
            if ($scope.auth.email !== "" && $scope.auth.email !== "null") {
                $scope.subscription.email = $scope.auth.email;
            }
        }
    }

    function doSubscribeFn(email) {
        if(isDemo()) {
            $scope.alertSubscribe = true;
            $scope.message = "Данный функционал не доступен в демонстрационной версии.";
            return;
        }

        if (email) {
            var errFlg = !(/^[a-zA-Z0-9\+-_]+([\.-]?[a-zA-Z0-9\+-_]+)*@[a-zA-Z0-9\+-_]+([\.-]?[a-zA-Z0-9\+-_]+)*(\.[a-zA-Z0-9]{2,6})+$/.test(email));
            if (errFlg) {
                $scope.alertSubscribe = true;
                $scope.message = "Неверно указан email";
                $scope.mailError = true;
                return;
            }
            $scope.alertSubscribe = false;

            var subscribeData = {};
            subscribeData.email = $scope.subscription.email;
            subscribeData.active = true;
            subscribeData.subscribeDate = Date.now();
            httpSrv.http({
                method: 'POST',
                url: '/news/subscribe',
                data: subscribeData
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.alertSubscribe = true;
                    $scope.message = "Подписка оформлена. На указанный email направлено письмо для подтверждения.";
                    $scope.showSubscribe = false;
                    $scope.mailError = false;
                    delete $scope.subscription.email;
                } else {
                    $scope.alertSubscribe = true;
                    $scope.mailError = true;
                    $scope.message = "Подписка уже существует";
                }
            }, function errorCallback(response) {
                console.log(response);
            });

        } else {
            $scope.alertSubscribe = true;
            $scope.mailError = true;
            $scope.message = "Заполните email";
        }
    }

    function isDemo(){
        return $rootScope.properties && $rootScope.properties['demo.mode']==="true";
    }

    function checkButtonFn() {
        if ($scope.filter.deliveryRegion != undefined ||
            $scope.filter.okpd2 != undefined ||
            $scope.filter.skmtr != undefined ||
            $scope.filter.s_publicDate != undefined || 
            $scope.filter.e_publicDate != undefined ||
            $scope.filter.s_summa != undefined ||
            $scope.filter.e_summa != undefined
        ) {
            return true;
        } else {
            return false;
        }
    }

    init();

    function init() {
        $scope.auth = authSrv.getAuth();
        $scope.showSubscribe = false;
        $scope.subscription = {};
        if ($scope.auth.email !== "" && $scope.auth.email !== "null") {
            $scope.subscription.email = $scope.auth.email;
        }
        $scope.alertSubscribe = false;
        $scope.message = "";

    }
    function bindSubscribeFn(e){
        var keyCode = e.keyCode || e.which;
        if (keyCode == '13') {
            doSubscribeFn(e.target.value);
        }
    }

}]).directive('subscriptionNews', [function () {
    return {
        restrict: 'E',
        controller: 'subscriptionNewsController',
        templateUrl: 'views/subscription/subscriptionNews.html'
    };
}]);