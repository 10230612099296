angular.module('uetp').constant('declaredLots', {
	title    : 'Отчет по объявленным лотам',
	access   : ['organizer'],
	url      : '/auth/org/downloadDeclareLotsReport',
	template : 'app/components/reports/types/declaredLotsReport.html',
	data     : {
		endDate   : null,
		startDate : null,
		reportKind:'summary',
		procTypes:[],
		customers:[]
	},
	settings:{
		divisionSelectSettings:{
			settings:{
				checkBoxes: true,
				idProperty:"id",
				displayProp:'name',
				scrollable:true
			},
			texts:{
				checkAll:"Выделить все",
				uncheckAll:"Снять выделение",
				dynamicButtonTextSuffix: "выделено",
				buttonDefaultText:"Выберите заказчиков"
			}
		},
		paramTitles:{
			period:"Период объявления закупки"
		}
	},

	toMSK    : ['startDate', 'endDate']
});