/**
 * @author: sergeysi
 * Добавление/редактирование нового филиала
 */
angular.module('uetp')
    .component('branchEditor', {
        bindings: {
            branchData: '<',
            customerData: '<'
        },
        templateUrl: 'app/components/customer/customerEisIntegration/branches/branchEditor.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'profileSrv', 'authSrv', 'desktopSrv', 'dictionarySrv', 'alertSrv', 'onlineCheckSrv', 'dialogSrv', 'needConfirmSrv','customerSrv', '$timeout',
            function ($scope, $rootScope, $state, $stateParams, profileSrv, authSrv, desktopSrv, dictionarySrv, alertSrv, onlineCheck, dialogSrv, needConfirmSrv, customerSrv, $timeout) {
                var ctrl = this;
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.form = {branchForm: {}};
                    // TODO Добавить проверки пользователя и аутентификации!!!
                    $scope.authData = authSrv;
                    $scope.user = authSrv.getUser();
                    $scope.branch = {};
                    $scope.defaultUserRole = null;
                    $scope.loginreadonly = true;
                    $scope.passreadonly = true;

                    _initForm();

                    if ($stateParams.successSaveMsg) {
                        $scope.alertObjBranchEdit = alertSrv.getSuccessMsg($stateParams.successSaveMsg);
                    }

                    function _initForm(){
                        if ($stateParams.id) {
                            $scope.branch = ctrl.branchData.data.result;
                        } else {
                            $scope.branch.inn = ctrl.customerData.inn;
                            $scope.branch.ogrn = ctrl.customerData.ogrn;
                            $scope.branch.iko = ctrl.customerData.iko;
                        }

                        $scope.customer = customerSrv.getCustomerInfo();
                        $timeout(function () {
                            if ($scope.form.branchForm && typeof $scope.form.branchForm.$setPristine == "function") $scope.form.branchForm.$setPristine();
                        });
                        $timeout(function () {
                            $scope.loginreadonly = false;
                            $scope.passreadonly = false;
                        }, 500);
                    }
                };
                
                $scope.saveOrUpdateCustomerBranch = saveOrUpdateCustomerBranchFn;
                $scope.goBack = goBackFn;
                $scope.cancel = cancelFn;

                /**
                 * Добавления нового или обновление филиала заказчика
                 * @param method
                 */
                function saveOrUpdateCustomerBranchFn() {
                    if (!$scope.form.branchForm.$valid) {
                        $scope.alertObjBranchEdit = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');
                        return;
                    }

                    const dataObj = angular.copy($scope.branch);
                    dataObj.customer = $scope.customer;

                    customerSrv.createOrUpdateCustomerBranch(dataObj).then(function successCallback(response) {
                        if(!response.data.success) {
                            $scope.alertObjBranchEdit = alertSrv.getErrorMsg("При сохранении филиала произошла ошибка. Попробуйте выполнить запрос позднее или обратитесь в техническую поддержку.");
                        } else {
                            const successSaveMsg = dataObj.id ? 'Изменения успешно сохранены' : 'Филиал добавлен';
                            $scope.form.branchForm.$setPristine();
                            $scope.branch.id = response.data.result;
                            $state.go('openCustomerBranch', {id: $scope.branch.id, customerId: $stateParams.customerId, successSaveMsg: successSaveMsg}).then(function(){
                                $scope.alertObjBranchEdit = alertSrv.getSuccessMsg(successSaveMsg);
                            });
                        }
                    }, function errorCallback(response) {
                        $scope.alertObjBranchEdit = alertSrv.getAlertObj(response);
                        console.log(response);
                    });
                }

                function cancelFn() {
                    if ($scope.form.branchForm.$dirty) {
                        needConfirmSrv.call(goBackFn, [], 'Внесенные изменения сохранены не будут');
                    } else {
                        if($stateParams.customerId)
                            $state.go('customerView', {id: $stateParams.customerId});
                        else
                            $scope.goBack();
                    }
                }

                function goBackFn() {
                    $stateParams.customerId ? $state.go('customerView', {id: $stateParams.customerId}) : $scope.goBack();
                }

            }]

    });

