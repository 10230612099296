/**
 * Сервис для сохранения выбранных параметров по фильтру
 */
angular.module('uetp').service('searchFilterService', ['$state',
    function ($state) {
        var service = this;
        /**
         * Ключ - значения.
         * Ключ формируется о типа фильтра и состояние (state). Чтоб не перехватить заполненный фильтр другой страницы
         */
        service.filterNoModifyParams = {}; // данные для модели фильтра, без переименования названия переменных под сервер. Пример ng-model="doc__id" НЕ меняется на doc.id.
        service.filterModifyParams = {}; // подготовленные данные для сервера. Пример ng-model="doc__id" меняется на doc.id

        service.activeFilter = {};
        service.search = {};

        /**
         * Параметры простого поиска
         * @param searchValue - значение
         * @param typeFilter - тип фильтра
         */
        this.setSearchParam = function (searchValue, typeFilter) {
            var type = typeFilter + $state.$current.name;
            service.search[type] = searchValue;
        };

        this.setStateSearchParam = function (searchValue, typeFilter, state) {
            var type = typeFilter + state;
            service.search[type] = searchValue;
        };
        this.getSearchParam = function (typeFilter) {
            var type = typeFilter + $state.$current.name;
            return angular.isDefined(service.search[type]) ? service.search[type] : "";
        };
        /**
         * Установка выбранных параметров в фильтре
         * (не подготовленных для сервера. Имя полей фильтра равно имени его модели) doc__id = ng-model="doc__id"
         * @param params - фильтр
         * @param typeFilter - тип фильтра
         */
        this.setFilterNoModifyParams = function (params, typeFilter) {
            var type = typeFilter + $state.$current.name;
            service.filterNoModifyParams[type] = params;
        };
        /**
         * Получение заполненного фильтра по его типу (подготовленных для отправки на сервер)
         * @param typeFilter - тип фильтра
         */
        this.getFilterNoModifyParams = function (typeFilter) {
            var type = typeFilter + $state.$current.name;
            return angular.isDefined(service.filterNoModifyParams[type]) ? service.filterNoModifyParams[type] : {}
        };
        /**
         * Установка выбранных параметров в фильтре (подготовленных для отправки на сервер)
         * @param params - фильтр
         * @param typeFilter - тип фильтра
         */
        this.setFilterParams = function (params, typeFilter) {
            var type = typeFilter + $state.$current.name;
            service.filterModifyParams[type] = params;
            service.activeFilter[type] = params && Object.keys(params).length > 0;
        };
        /**
         * Получение заполненного фильтра по его типу (подготовленных для отправки на сервер)
         * @param typeFilter - тип фильтра
         */
        this.getFilterParams = function (typeFilter) {
            var type = typeFilter + $state.$current.name;
            return angular.isDefined(service.filterModifyParams[type]) ? service.filterModifyParams[type] : {}
        };
        /**
         * Получение данных активноести фильтра по его фильтра
         * @param typeFilter - тип фильтра
         */
        this.getActiveFilter = function (typeFilter) {
            var type = typeFilter + $state.$current.name;
            return angular.isDefined(service.activeFilter[type]) ? service.activeFilter[type] : false;
        };
        /**
         * Сброс данных всех фильтров
         */
        this.resetService = function () {
            service.filterModifyParams = {};
            service.filterNoModifyParams = {};
            service.activeFilter = {};
            service.search = {};
        }
        /**
         * Сброс данных указанного фильтра
         */
        this.resetCurrent = function (typeFilter, stateName) {
            var state = stateName ? stateName : $state.$current.name;
            var type = typeFilter + state;
            service.filterModifyParams[type] = {};
            service.filterNoModifyParams[type] = {};
            service.activeFilter[type] = false;
            service.search[type] = ''
        }
    }]);