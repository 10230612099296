/**
 * @author: ivank
 * Профиль пользователя. Добавление нового филиала организации
 */
angular.module('uetp')
    .component('filiationEditor', {
        templateUrl: 'app/components/profile/filiation/filiationEdit/filiationEditor.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'profileSrv', 'authSrv', 'desktopSrv', 'dictionarySrv', 'alertSrv','needConfirmSrv',
            function ($scope, $rootScope, $state, $stateParams, profileSrv, authSrv, desktopSrv, dictionarySrv, alertSrv,needConfirmSrv) {

                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;

                    $scope.modelViewFiliation = {isEdit: false};
                    $scope.form = {filiationForm: {}};
                    $scope.filiationSaveError = null;
                    $scope.authData = authSrv;
                    $scope.user = authSrv.getUser();
                    $scope.filiation = {};
                    $scope.dictionary = dictionarySrv.getRemoteData();
                    _initDict(['okcm', 'fias_region'], false);
                    _initModelView();
                    $scope.readOnly = authSrv.getAuth().userRole.includes('operator_support_ro') || authSrv.getAuth().userRole.includes('operator_support_rw');
                };
                $scope.storeFiliation = storeFiliationFn;
                $scope.cancel = cancelFn;
                function _initDict(dictList, reload) {
                    angular.forEach(dictList, function (name) {
                        dictionarySrv.getDictionaryRemote(name, reload);
                    })
                }

                function _initModelView() {
                    if ($stateParams.id) {
                        $scope.modelViewFiliation.isEdit = true;
                        getFiliationById($stateParams.id);
                    } else {
                        $scope.filiation.realAddress = {oksmCode: '643', oksmName: 'РОССИЯ'};
                    }
                }

                function getFiliationById(id){
                    profileSrv.getFiliationById(id).then(function(response){
                        if (response.data.success){
                            $scope.filiation = response.data.result;
                        }
                    })

                }
                function storeFiliationFn() {

                    if (!$scope.form.filiationForm.$valid) {
                        $scope.alertObjFiliationEdit = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');
                        return;
                    }

                    profileSrv.storeFiliation($scope.filiation).then(function successCallback(response) {
                        $scope.alertObjFiliationEdit = alertSrv.getAlertObj(response,'Сохранение произведено успешно!');
                        $scope.form.filiationForm.$setPristine();
                        setTimeout(function(){
                            $state.go("profile", {activeProfileTab:'filiation'});
                        }, 500);
                    }, function errorCallback(response) {
                        $scope.alertObjFiliationEdit = alertSrv.getAlertObj(response);
                        console.log(response);
                    });
                }
                function goBackFn(){
                    history.back();
                }
                function cancelFn() {
                    if($scope.form.filiationForm.$dirty){
                        needConfirmSrv.call(goBackFn,[],'Внесенные изменения сохранены не будут');
                    }else{
                        goBackFn();
                    }
                }
                $scope.changeOksm = (oksm) => {
                    if(oksm) {
                        $scope.filiation.realAddress.oksmName = oksm.ncm_short;
                    }
                    $scope.filiation.realAddress.regionOKATOCode = '';
                    $scope.filiation.realAddress.regionOKATOName = '';
                }
                $scope.changeOkato = (okato) => {
                    if(okato && okato?.formalname && okato?.shortname) {
                        $scope.filiation.realAddress.regionOKATOName = okato?.formalname + ' ' + okato?.shortname;
                    }
                }
            }]
    });

