/**
 * @author: sergeysi
 * Отправка на email запроса о разъяснении (Разъяснение)
 */
angular.module('uetp')
    .component('answerExplanationEmail', {
        bindings: {
            openData: '<',
            createData: '<'
        },
        templateUrl: 'app/components/explanation/email/answerExplanationEmail.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'explanationSrv', 'authSrv', 'constants', 'httpSrv', 'alertSrv','needConfirmSrv', 'desktopSrv', 'titleSrv',
            function ($scope, $rootScope, $state, $stateParams, explanationSrv, authSrv, constants, httpSrv, alertSrv, needConfirmSrv, desktopSrv, titleSrv) {
                var ctrl = this;
                $scope.ctrl = ctrl;
                this.$onInit = function () {
                    $scope.auth = authSrv.getAuth();
                    $scope.constants = constants;
                    $scope.submitted = false;
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.stateParams = {
                        procType: $stateParams.procType, // тип закупки
                        procId: $stateParams.procId, // идентификатор закупки
                        explReqId: $stateParams.explReqId,  // идентификатор запроса на разъяснение (если создаем разъяснение из запроса)
                        explId: $stateParams.explId  // идентификатор разъяснения (если открыли на просмотр)
                    };

                    $scope.alertId = '';
                    $scope.form = {explanationEmail: {}};
                    if (ctrl.openData) {
                        $scope.model = ctrl.openData.data.result;
                        $scope.viewModel = ctrl.openData.data.result;
                        $scope.viewModel.isEdit = false;
                        if ($stateParams.successSend) {
                            $scope.ctrl.alertObjAnswerExplanation = alertSrv.getSuccessMsgWithBtn('Документ отправлен!');
                        }
                    } else if (ctrl.createData) {
                        $scope.model = ctrl.createData.data.result;
                        delete  $scope.model.file;
                        $scope.viewModel = {
                            isEdit: true
                        };
                    } else {
                        $scope.model = {};
                        $scope.viewModel = {
                            isEdit: true
                        };
                    }
                    $scope.attachStyle = $scope.viewModel.isEdit ? {
                        'margin-left': '290px'
                    } : {}
                    if ($scope.viewModel.purchaseNumber) {
                        titleSrv.setTitle($scope.viewModel.purchaseNumber + ' | Ответ на запрос');
                    }
                };

                /**
                 * Отправка email
                 */
                $scope.sendEmailData = function () {
                    var model = angular.copy($scope.model),
                        requestModel = {};
                    
                    requestModel.mailTo = model.emailListInfo;
                    if ($scope.stateParams.explReqId)
                        requestModel.requestId = $scope.stateParams.explReqId;
                    
                    $scope.submitted = true;
                    explanationSrv.sendEmailData($stateParams.procType, $stateParams.procId, requestModel, 'explanations').then(function (response) {
                        if (response.data.success)
                            $scope.back();
                        else
                            $scope.ctrl.alertObjAnswerExplanation = alertSrv.getAlertObj(response);
                        $scope.submitted = false;
                    });
                };
                $scope.back = function () {
                    history.back();
                };
                $scope.reset = function () {
                    if($scope.form.explanationEmail.$dirty){
                        needConfirmSrv.call($scope.back,null,'Внесенные изменения сохранены не будут');
                    }else{
                        $scope.back();
                    }
                };
            }]
    });

