/**
 * @author: Belyaev Andrey
 * Сервис для компонента Договоры
 */

angular.module('uetp').service('contractSrv', ['$state', '$stateParams', 'httpSrv', 'cryptography',
    function ($state, $stateParams, httpSrv, cryptography) {
        var service = this;
        service.getDocumentsDataForSign = getDocumentsDataForSignFn;
        service.signDocument = signDocumentFn;

        /**
         * видимость вкладки договоры
         */
        this.viewContractsTab = function (id) {
            return httpSrv.http({
                method: 'GET',
                url: '/site/contract/isContractsVisible/' + id
            })
        };

        /**
         * таблица договоров в процедуре
         */
        this.getContractsList = function (procedureId, paginator) {
            return httpSrv.http({
                method: 'POST',
                url: '/site/contract/lotsInfo/' + procedureId,
                data: paginator
            })
        };

        /**
         * начальный запрос на заключение контракта
         */
        this.initSend = function (lotId, customerId, participantId, packetId, files) {
            return httpSrv.http({
                method: 'POST',
                url: `/site/contract/${lotId}/${customerId}/${participantId}/${packetId}/request/initSend`,
                data: files
            })
        };
        this.finishedPaperInitSend = function (lotId, customerId, participantId, contractData) {
            return httpSrv.http({
                method: 'POST',
                url: '/site/contract/' + lotId + '/' + customerId + '/' + participantId + '/finishedPaper/initSend',
                data: contractData
            })
        };
        this.finishedPaperSend = function (contractId, contractData) {
             return httpSrv.http({
                method: 'POST',
                url: '/site/contract/' + contractId + '/finishedPaper/send',
                data: contractData
            })
        };
        this.finishedRejectInitSend = function (lotId, customerId, participantId, contractData) {
        	var finishedUrl = contractData.rejectType === 'rejectByOrganizer' ? 'finishedRejectByOrganizer' : 'finishedRejectByCustomer';
            return httpSrv.http({
                method: 'POST',
                url: '/site/contract/' + lotId + '/' + customerId + '/' + participantId + '/' + finishedUrl + '/initSend',
                data: contractData
            })
        };
        this.finishedRejectSend = function (contractId, contractData) {
        	var finishedUrl = contractData.rejectType === 'rejectByOrganizer' ? 'finishedRejectByOrganizer' : 'finishedRejectByCustomer';
            return httpSrv.http({
                method: 'POST',
                url: '/site/contract/' + contractId + '/' + finishedUrl + '/send',
                data: contractData
            })
        };
        this.getContractSumByClaim = function (lotId, participantId) {
            return httpSrv.http({
               method: 'GET',
               url: '/site/contract/' + lotId + '/' + participantId + '/contractSumByClaim'
           })
       };
       
        /**
         * договор, где все документы по процессу заключения
         */
        this.getContractProcess = function (contractId) {
            return httpSrv.http({
                method: 'GET',
                url: '/site/contract/' + contractId + '/contractProcess'
            })
        };

        /**
         * последующие запросы организатора
         */
        this.subsequentSend = function (contractId, packetId, files) {
            return httpSrv.http({
                method: 'POST',
                url: `/site/contract/${contractId}/${packetId}/request/send`,
                data: files
            })
        };

        /**
         * ответ-отказ учатника
         */
        this.rejectParticipant = function (contractId, packetId, files) {
            return httpSrv.http({
                method: 'POST',
                url: `/site/contract/${contractId}/${packetId}/response/reject`,
                data: files
            })
        };

        /**
         * Прекратить работу с договором
         */
        this.stopContractProcess = function (contractId) {
             return httpSrv.http({
                method: 'GET',
                url: '/site/contract/' + contractId + '/request/stopContractProcess'
            })
        };

        /**
         * Удалить проект договора
         */
        this.deletePacket = function (packetId) {
            return httpSrv.http({
                method: 'GET',
                url: '/site/contract/' + packetId + '/request/deletePacket'
            })
        };


        /**
         * Прекратить работу с дополнительным соглашением
         */
        this.stopAdditionalProcess = function (contractId, packetId) {
            return httpSrv.http({
                method: 'GET',
                url: `/site/contract/${contractId}/${packetId}/request/stopAdditionalProcess`
            })
        };
        
        /**
         * подписание документов участником
         */
        this.signingDocumentsParticipant = function (contractId, packetId, hashes) {
            return httpSrv.http({
                method: 'POST',
                url: `/site/contract/${contractId}/${packetId}/response/sign`,
                data: hashes
            })
        };

        /**
         * подписание документов организатором
         */
        this.signingDocumentsOrganizer = function (contractId, packetId, contractData) {
            return httpSrv.http({
                method: 'POST',
                url: `/site/contract/${contractId}/${packetId}/request/sign`,
                data: contractData
            })
        };

        /**
         * Подпись документа
         * @param model - данные для подписи
         * @param certHash - иентификатор сертификата
         * @returns {Promise}
         */
        function signDocumentFn(model, certHash) {
            var iterator = _iteratorObject(model);
            return new Promise(function (resolve, reject) {
                var recursiveSign = function (iterator, object) {
                    cryptography.signHash(object.hashValue, certHash, service.hashAlg).then(function (data) {
                        object.hashValue = data;
                        var iterate = iterator.next();
                        if (!iterate.done) {
                            recursiveSign(iterator, iterate.object);
                        } else {
                            resolve(model);
                        }
                    }, function (reason) {
                        reject(reason)
                    })
                };
                recursiveSign(iterator, iterator.next().object)
            });
        }

        function _iteratorObject(array) {
            var nextIndex = 0;
            return {
                next: function () {
                    return nextIndex < array.length ?
                        {object: array[nextIndex++], done: false} :
                        {done: true};
                }
            }
        }

        /*
        * Отправляем вложения и получем данные для подписи. получаем хеши
        * contractId - внутрений id договора
        * data - анкета
        * */

        function getDocumentsDataForSignFn(certHash, contractId, packetId, data) {
            service.hashAlg = null;
            return cryptography.getAlgoInfo(certHash).then(function (info) {
                service.hashAlg = info.code;
                data = data || {};
                data.certOid = info.code;
                data.packetId = packetId;
                return httpSrv.http({
                    method: 'POST',
                    url: `/site/contract/${contractId}/response/dateForSign`,
                    data: data
                });
            })
        }


    }
]);