/**
 * @author dmitrys
 */
angular.module('uetp').service('invoiceReqFormSrv', ['$state', 'httpSrv',
    function ($state, httpSrv) {
        var service = this;

        service.makeRequestSpecAccount = makeRequestSpecAccountFn;

        /*
        * Запросить резервирование
        * */
        function makeRequestSpecAccountFn(data) {
            return httpSrv.http({
                method: 'POST',
                url: '/specialaccount/specacc/makeRequestSpecAccount',
                ignoreError:true,
                data: data
            })
        }


    }]);