/**
 * @author: sergeyu
 * Таблица заявлений на разъяснения по процедуре
 */
angular.module('uetp')
    .component('reqExplanations', {
        template: '<div ng-include="ctrl.templateUrl">',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'proceduresSrv', 'permissionSrv', 'dictionarySrv', 'needConfirmSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, proceduresSrv, permissionSrv, dictionarySrv, needConfirmSrv) {
                var ctrl = this;
                $scope.ctrl = ctrl;
                $scope.ctrl.templateUrl = '';
                $scope.hasPermission = permissionSrv.hasPermission;
                $scope.changeSort = changeSortFn;
                $scope.explanationStates = dictionarySrv.getStaticDictByType('explanationStates');

                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    _initTemplate();
                    $scope.procedure = proceduresSrv.getProcedureData().data.result;
                    $scope.dataTable = $scope.procedure.explanationRequests;
                    if(($scope.dataTable || []).length>0)
                        $scope.dataTable.forEach(e=>{
                            if(e.explanations)
                            {
                                const newExpls = e.explanations.filter(expl=>expl?.state?.id!=='created');
                                newExpls.forEach(expl=>{
                                    if(expl?.state?.id==='sent_participant')
                                        expl.state.name='Направлено в ЛК';
                                })
                                e.explanations = newExpls;
                            }

                        })
                    $scope.orderProperty = 'created';
                    $scope.reverse = false;
                };
                $scope.createAnswerExp = function () {
                    $state.go('createAnswerExplanationForReq', {
                        procType: $scope.procedure.type.id,
                        procId: $scope.procedure.id
                    })
                }

                function _initTemplate() {
                    if ('cbz' === $scope.cabType()) {
                        $scope.ctrl.templateUrl = 'app/components/procedures/reqExplanations/reqExplanationsForCbz.html';
                    } else {
                        $scope.ctrl.templateUrl = 'app/components/procedures/reqExplanations/reqExplanations.html';
                    }

                }

                function changeSortFn($event) {
                    let th = $event.currentTarget,
                        orderBy = th.getAttribute('st-sort'),
                        orderAsc = th.classList.contains("sortAsc");

                    [].forEach.call(document.querySelectorAll(".sortColumn"), function (column) {
                        column.classList.remove("sortDesc");
                        column.classList.remove("sortAsc");
                    });
                    orderAsc = !orderAsc;
                    th.classList.add(orderAsc ? "sortAsc" : "sortDesc");
                    if (orderBy) {
                        $scope.orderProperty = orderBy;
                        $scope.reverse = !orderAsc;
                    }
                }

                $scope.changeExplanationState = function (el, prevState, selector) {
                    if(prevState!=='' && prevState!==selector.$select.selected.id)
                        needConfirmSrv.callVariants(changeStateProcess, function (){
                            selector.$select.selected = getState(selector.$select.items, prevState);
                            el.state = selector.$select.selected;
                            },['admin/changeExplanationState/'+el.id+'/'+selector.$select.selected.id], 'Статус разъяснения будет изменён на "'+el.state.name+'". Продолжить?');
                }


                function changeStateProcess(url){
                    return proceduresSrv.changeState(url);
                }

                function getState(items, id){
                    return items.filter(function (item) {return item.id===id; })[0];
                }
            }]
    });

