/**
 * @author: ivank
 Список заявлений на аккредитацию
 */
angular.module('uetp')
    .component('participantAccreditation', {
        templateUrl: 'app/components/participant/participantAccreditation/accreditationList.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'profileSrv', 'authSrv', 'desktopSrv', 'dialogSrv', '$filter','dictionarySrv','alertSrv','participantSrv', 'httpSrv',
            function ($scope, $rootScope, $state, $stateParams, profileSrv, authSrv, desktopSrv, dialogSrv, $filter, dictionarySrv, alertSrv, participantSrv, httpSrv) {

                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.totalPages = 0;
                    $scope.paginator = {
                        orderBy: 'created',
                        orderAsc: false,
                        itemsPerPage: 100,
                        page: 0
                    };
                    $scope.user = participantSrv.getOrgInfo().data.result;
                    _initModelView();
                    loadAccredidationListFn();
                };

                $scope.openAccred = openAccredFn;
                $scope.loadAccredidationList = loadAccredidationListFn;
                $scope.checkType = checkTypeFn;
                $scope.sort = sortFn;
                $scope.goBack = goBackFn;
                $scope.checkAccreditationDocs = checkAccreditationDocsFn;

                function goBackFn(){
                    history.back();
                }

                /**
                 * Инициализация модели в зависимости от ЛК
                 * @private
                 */
                function _initModelView() {
                    $scope.cabType();
                }
                function sortFn(val) {
                    if($scope.paginator.orderBy === val){
                        $scope.paginator.orderAsc = !$scope.paginator.orderAsc
                    } else {
                        $scope.paginator.orderBy = val;
                        $scope.paginator.orderAsc = false
                    }
                    loadAccredidationListFn();
                }
                function openAccredFn(data) {
                    $state.go('openAccreditation', {id: data.id});
                }
                function loadAccredidationListFn() {
                    profileSrv.loadAccreditationList($scope.paginator, $scope.user.id).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.accredList = response.data.items;
                            $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                        } else {
                            $scope.accredList = [];
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    })
                }
                function checkTypeFn(accred) {
                    if(accred.type === 'requisites_renew'){
                        return accred.created;
                    }else if(accred.type === 'accreditation' || 'accreditation_renew'){
                        return accred.accreditationDate;
                    }
                }

                function checkAccreditationDocsFn(accredList) {
                    return accredList && accredList.length>0 && accredList.some(acc => acc?.document?.documents?.document?.length>0 &&
                        (acc.type === "accreditation" && acc.state === "accred" ||
                            ["accreditation_renew","requisites_renew"].includes(acc.type)));
                }

                $scope.downloadAttachmentArchive = function () {
                    httpSrv.downloadFile('/fs/api/v1/site/download/accreditationArchive/'+ $scope.user.id);
                }
            }]
    });

