/**
 * @author: sergeyu
 * Доступ пользователя к организациям-агентам для банковских гарантий
 */
angular.module('uetp')
    .component('participationInPurchases', {
        templateUrl: 'app/components/participationInPurchases/participationInPurchases.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', '$filter', 'desktopSrv', 'dialogSrv', 'participantSrv', 'paginationSrv', 'httpSrv', 'filterService','tablesSrv',
            function ($scope, $rootScope, $state, $stateParams, $filter, desktopSrv, dialogSrv, participantSrv, paginationSrv, httpSrv, filterService, tablesSrv) {
                this.$onInit = function () {
                    init();
                };
                $scope.sort = sortFn;
                $scope.changeSort = changeSortFn;
                $scope.goBack = goBackFn;
                $scope.resetPaginator = resetPaginatorFn;
                $scope.participationInPurchaseLoad = participationInPurchaseLoadFn;
                $scope.paginator = {
                    orderBy: 'created',
                    orderAsc: false,
                    itemsPerPage: 20,
                    page: 0
                };

                function resetPaginatorFn() {
                    $scope.paginator = {
                        orderBy: 'created',
                        orderAsc: false,
                        itemsPerPage: 20,
                        page: 0
                    };
                    $scope.totalPages = 1;
                    delete $scope.paginator.search;
                    delete $scope.paginator.searchByField;
                }

                function goBackFn(){
                    $state.go("participant");
                }

                function sortFn(val) {
                    if($scope.paginator.orderBy === val){
                        $scope.paginator.orderAsc = !$scope.paginator.orderAsc
                    } else {
                        $scope.paginator.orderBy = val;
                        $scope.paginator.orderAsc = false
                    }
                    participationInPurchaseLoadFn();
                }

                /**
                 * Сортировка
                 */
                function changeSortFn($event) {
                    var th = $event.currentTarget;

                    [].forEach.call(document.querySelectorAll(".sortButton"), function (button) {
                        button.classList.remove("boldSort");
                    });
                    th.classList.add("boldSort");

                    if (tablesSrv.changeSort($scope.paginator, th)) participationInPurchaseLoadFn();
                }
                
                $scope.exportSearchResult = function exportSearchResult() {
                    var paginator = angular.copy($scope.paginator);
                    httpSrv.downloadFile('/auth/org/downloadParticipationInPurchaseSearchResult/' + $scope.user.id, '', paginator);
                };

                function participationInPurchaseLoadFn() {
                    filterService.initModelView('Поиск по номеру закупки', 'Участники');
                    prepareTableParams('filterParticipateInPurchases');
                    return httpSrv.http({
                        method: 'POST',
                        url: '/auth/org/getParticipationInPurchaseList/' + $scope.user.id,
                        data: $scope.paginator,
                        showCounter: true
                    }).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.participationList = response.data.items;
                            $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                        } else {
                            $scope.participationList = [];
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response)
                    })
                }

                function prepareTableParams(filterType) {
                    var filterParams = angular.copy(filterService.getFilterParams(filterType)),
                        searchParam = angular.copy(filterService.getSearchParam(filterType));
                    if (filterParams && Object.keys(filterParams).length > 0) {
                        $scope.paginator.searchByField = filterParams;
                    } else {
                        delete $scope.paginator.searchByField
                    }
                    $scope.paginator.search = searchParam;
                }

                function init() {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.totalPages = 0;
                    var config = paginationSrv.getPaginationStateConfig('pagination', $state.current.name); // память пагинации
                    if (config) {
                        $scope.paginator.page = config.page;
                        $scope.paginator.itemsPerPage = config.itemsPerPage;
                    }
                    $scope.user = participantSrv.getOrgInfo().data.result;
                    participationInPurchaseLoadFn();
                }
            }]
    });

