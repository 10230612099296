/**
 * @author: sergeyu
 * Торги (проведение аукциона)
 */
angular.module('uetp')
    .component('auctionList', {
        templateUrl: 'app/components/procedures/auction/auction.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'proceduresSrv', 'authSrv', 'alertSrv', '$interval', '$filter', 'profileSrv', 'dialogSrv', 'dictionarySrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, proceduresSrv, authSrv, alertSrv, $interval, $filter, profileSrv, dialogSrv, dictionarySrv) {
                var ctrl = this,
                    refreshIndex = null;
                this.$onInit = function () {
                    ctrl.alerts = {};
                    $scope.initTimeList = new Date(); // время первого обновления хода аукциона при открытии вкладки
                    $scope.ctrl = ctrl;
                    $scope.auth = authSrv.getAuth();
                    $scope.getCabType = desktopSrv.getCabType;
                    $scope.procedure = proceduresSrv.getProcedureData().data.result;
                    $scope.model = {
                        isOpen: {},
                        counter: {},
                        counterText: {},
                        lastUpdate: {},
                        indexes: {},
                        currencyCode: [],
                        cancelBiddingsArr: [], // лоты по котором торги отменены
                        cancelBiddingsText: '' // лоты по котором торги отменены
                    };
                    const mchdErrors = ['427','429','430_2'];
                    _getCancelBiddngs($scope.procedure.lots);
                    $scope.cabType = desktopSrv.getCabType;

                    $scope.onEndFn = function (id, value) {
                        this.auction.money = value;
                        $scope.getMinMax(id, this.auction);
                    };

                    proceduresSrv.getAuctionsProgressByProcedure().then(function (response) {
                        if (response.data.success) {
                            $scope.auctions = response.data.result;

                            if (!$scope.auctions.bids) {
                                $scope.auctions.bids = []
                            }

                            setTimeout(function () {
                                if ($stateParams.toAuction) {
                                    var data = $stateParams.toAuction;
                                    var id = data + "_current";
                                    var elements = window.document.querySelectorAll('[lot_current="' + id + '"]');
                                    if (elements && elements[0])
                                        elements[0].focus();
                                }
                                if ($scope.model.indexes) {
                                    angular.forEach($scope.model.indexes, function (auction, index) {
                                            $scope.model.currencyCode[index] = _getCurrencyType(auction);
                                    });
                                }
                                openCurrentBids();
                            }, 500);

                            refreshIndex = $interval(function () {
                                $scope.refreshAllAuction();
                            }, 10000);
                        }
                    });
                    this.$onDestroy = function () {
                        $interval.cancel(refreshIndex);
                    };

                    /*
                     Если идут торги и пользователь допущен - открываем ход торгов
                     */
                    function openCurrentBids() {
                        if($scope.model.isOpen && $scope.auctions){
                            for(let index = 0; index<$scope.auctions.length; index++){
                                const auction = $scope.auctions[index];
                                if(auction.statusCode === 'current' && //auction.bids && auction.bids.length > 0 &&
                                    auction.participantNumber && //(auction.minMyBid || auction.minBid) &&
                                    (!$scope.model.isOpen[index] || $scope.model.isOpen[index]=='false'))
                                {
                                    $scope.model.isOpen[index] = 'true';
                                }
                            }
                        }
                    }
                    /**
                     * Обновление аукционов
                     */
                    $scope.refreshAllAuction = function () {
                        if ($scope.model.indexes) {
                            angular.forEach($scope.model.indexes, function (auction, index) {
                                if (auction.statusCode !== 'complete') {
                                    $scope.updateAuction(index, auction, false);
                                }
                            })
                        }
                    };
                    // true/false - открыто/закрыто
                    // manual_open - открыто вручную
                    // manual_close - закрыто вручную
                    $scope.open = function (index) {
                        if($scope.model.isOpen[index]==='false')
                            $scope.model.isOpen[index] = 'manual_open';
                        else
                            $scope.model.isOpen[index] = ($scope.model.isOpen[index] === 'manual_close' ? $scope.model.isOpen[index] = 'manual_open' : 'manual_close');
                    };


                    $scope.viewFullTbl = function (element, index, auctions) {
                        if ($scope.model.counter[index] === 10) {
                            $scope.model.counter[index] = auctions.length
                        } else {
                            $scope.model.counter[index] = 10;
                        }
                        desktopSrv.scrollIntoViewFn(element.target, {
                            behavior: "smooth",
                            block: "end"
                        });
                    };
                    /**
                     * Обновление хода аукциона
                     * @param index
                     * @param auction
                     * @param srollMoney - прокрутить к цене
                     */
                    $scope.updateAuction = function (index, auction, srollMoney) {

                        return proceduresSrv.getAuctionAndTradeProgress(auction.id).then(function (response) {
                            if (response.data.success) {
                                var minBid = auction.minBid,
                                    minMyBid = auction.minMyBid,
                                    maxBid = auction.maxBid,
                                    maxMyBid = auction.maxMyBid;
                                for (var prop in auction) {
                                    if (auction.hasOwnProperty(prop)) {
                                        if (prop === 'money' || prop === 'moneyError' || prop === 'perfectBid' || prop === 'myBid') {
                                            continue;
                                        }
                                        delete auction[prop];
                                    }
                                }

                                if (response.data.result.participantNumber) {
                                    if(response.data.result.minBid >= minBid && response.data.result.minBid > auction.perfectBid){
                                        auction.perfectBid = response.data.result.minBid
                                    } else if(response.data.result.maxBid <= maxBid && response.data.result.maxBid < auction.perfectBid){
                                        auction.perfectBid = response.data.result.maxBid
                                    }

                                    if(response.data.result.minMyBid >= minMyBid && response.data.result.minMyBid > auction.myBid){
                                        auction.myBid = response.data.result.minMyBid
                                    } else if(response.data.result.maxMyBid <= maxMyBid && response.data.result.maxMyBid < auction.myBid){
                                        auction.myBid = response.data.result.maxMyBid
                                    }

                                }
                                angular.extend(auction, response.data.result);
                                if ($scope.model.counter[index] > 10) {
                                    $scope.model.counter[index] = auction.bids ? auction.bids.length : 0;
                                } else {
                                    $scope.model.counter[index] = 10;
                                }
                                $scope.model.lastUpdate[index] = new Date();
                                if (srollMoney) {
                                    setTimeout(function () {
                                        var element = window.document.getElementById('auctionMoney_' + index);
                                        desktopSrv.scrollIntoViewFn(element, {
                                            behavior: "smooth"
                                        }, 100);
                                    });
                                }
                                openCurrentBids();
                                return Promise.resolve(response)
                            }

                        })

                    };
                    /**
                     * Отправить предложение
                     * @param index - индекс строки
                     * @param auction - аукцион
                     */
                    $scope.sendBid = function (index, auction) {
                        if($scope.errorMsg)
                             delete $scope.errorMsg;
                        profileSrv.checkAccredSecured($stateParams.procType, 'true').then(function (checkRes) {
                            if (checkRes.data.result) {

                                $scope.onLoadSend = true;
                                proceduresSrv.signAndSendBid(auction.id, auction.money, $scope.auth.certId, "auction").then(function (response) {
                                    if (response.data.success) {
                                        // если ставка отменена, то менять запрос на "неуспешный"
                                        if (!response.data.result.accepted) {
                                            response.data.success = false;
                                        }
                                        $scope.ctrl.alerts[index] = alertSrv.getAlertObj(response, response.data.result.bidStatusDesc);
                                        $scope.updateAuction(index, auction, false);
                                        auction.money = '';
                                    } else {
                                        if(!response.data.success && mchdErrors.includes(response.data.errorCode)) {
                                            $scope.errorMsg = response.data.errorMessage.replace('\n', '<br/>');
                                        } else {
                                            $scope.ctrl.alerts[index] = alertSrv.getAlertObj(response);
                                        }
                                    }
                                    $scope.onLoadSend = false;
                                }, function (reason) {
                                    if(!reason.data.success && mchdErrors.includes(reason.data.errorCode)) {
                                        $scope.errorMsg = reason.data.errorMessage.replace('\n', '<br/>');
                                    } else if (typeof reason === 'string') {
                                        $scope.ctrl.alerts[index] = alertSrv.getErrorMsgWithBtn(reason);
                                    } else {
                                        if (reason.status === -1)
                                            $scope.ctrl.alerts[index] = alertSrv.getAlertObj(reason, 'Не удалось подать ценовое предложение. Проверьте подключение Вашего рабочего места к сети Интернет.');
                                        else $scope.ctrl.alerts[index] = alertSrv.getAlertObj(reason);
                                    }
                                    $scope.onLoadSend = false;
                                })
                            } else {
                                dialogSrv.openModal('_dialogInfo.html', {message: 'Отправка ценового предложения невозможна! Отсутствует или закончилась аккредитация у организации. <a ui-sref="profile({activeProfileTab:\'accreditation\'})" ng-click="closeModal()">Перейти</a>'})
                            }
                        });
                    };
                    /**
                     * Временный метод для теста todo
                     * @param id
                     */
                    $scope.start = function () {};
                    /**
                     * Проверка цены вхождения в допустимый диапазон шага
                     * Пример: НМЦ составляет 100 рублей, шаг от 0,5р до 5 р.
                     * a.  Текущие предложения участников:
                     * 1.  7,30 р.
                     * 2.  7,15 р.
                     * 3.  6,00 р.
                     * Первый участник - [7,29; 6,00] U [5,50; 1,00]
                     * Второй участник - [7,14; 6,00] U [5,50; 1,00]
                     * Третий участник не может подавать предложения.
                     * b.  Первый участник подаёт предложение по цене 6,30руб (лучшая цена не изменилась, время торгов не продлевается). После этого
                     * 1.  6,30 р.
                     * 2.  7,15 р.
                     * 3.  6,00 р.
                     * Первый участник - [6,29; 6,00] U [5,50; 1,00]
                     * Второй участник - [7,14; 6,00] U [5,50; 1,00]
                     * Третий участник не может подавать предложения.
                     * @param index
                     * @param auction
                     */
                    $scope.getMinMax = function (index, auction) {
                        if (auction.money) {
                            var input = parseFloat(auction.money);
                            if (auction.maxBid && auction.maxMyBid) {
                                if (!((auction.maxBid >= input && input >= auction.minBid) || (auction.maxMyBid >= input && input >= auction.minMyBid))) {
                                    auction.moneyError = 'Ваше предложение указано неправильно. Диапазон цен должен быть с '
                                        + $filter('percent')(auction.minBid) + ' по ' + $filter('percent')(auction.maxBid) + '. Или вы можете улучшить свое предложение. Диапазон цен должен быть с ' + $filter('percent')(auction.minMyBid) + ' по ' + $filter('percent')(auction.maxMyBid);
                                } else {
                                    auction.moneyError = ''
                                }
                            } else if (auction.minBid) {
                                if (!((auction.maxBid >= input && input >= auction.minBid))) {
                                    auction.moneyError = 'Ваше предложение указано неправильно. Диапазон цен должен быть с '
                                        + $filter('percent')(auction.minBid) + ' по ' + $filter('percent')(auction.maxBid);
                                } else {
                                    auction.moneyError = ''
                                }
                            } else if (auction.maxMyBid) {
                                if (!((auction.maxMyBid >= input && input >= auction.minMyBid))) {
                                    auction.moneyError = 'Ваше предложение указано неправильно. Диапазон цен должен быть с '
                                        + $filter('percent')(auction.minMyBid) + ' по ' + $filter('percent')(auction.maxMyBid);
                                } else {
                                    auction.moneyError = ''
                                }
                            }
                        }
                    };
                    $scope.focusMoney = function (index, auction, money) {
                        auction.money = money;
                        var element = window.document.getElementById('auctionMoney_' + index);
                        element.focus();
                        $scope.getMinMax(index, auction)
                    }
                };

                function _getCurrencyType(auction) {
                    return auction?.currency ? dictionarySrv.getCurrencyPatternByCode(auction.currency) : ''
                }

                /**
                 * Выводим лоты по которым закупки не состоялись
                 * @param lots - список лотов по процедуре
                 * @private
                 */
                function _getCancelBiddngs(lots) {
                    angular.forEach(lots, function (lot) {
                        if (lot.state.id === 'cancelBiddings' ||  $scope.procedure.missedContest) {
                            if ($scope.model.cancelBiddingsText) {
                                $scope.model.cancelBiddingsText += ', '
                            }
                            $scope.model.cancelBiddingsArr.push(lot.ordinalNumber);
                            $scope.model.cancelBiddingsText += lot.ordinalNumber
                        }
                    })
                }

                $scope.isPercentMode = function(){
                    return $scope.procedure && $scope.procedure.priceMode==='percent';
                }
                $scope.fixPrecision =  function(value) {return value.toFixed($scope.getFractionSize(value)<2 ? 2 : $scope.getFractionSize(value))}

                $scope.getFractionSize = function(val) {
                    if(!val)
                        return 0;
                    let str = '';
                    if(val.toString().indexOf('.')>0) {
                        str = val.toString().substring(val.toString().indexOf('.')+1);
                        while(str.endsWith("0")) str=str.slice(0, -1);
                    }
                    return str.length;
                }
            }]
    });

