angular.module('uetp').component('position', {
    templateUrl: 'app/components/position/positionOffer.html',
    bindings: {
        type: '<',
        saveFn: '=',
        data: '=',
        close: '='
    },
    controller: [
        '$scope', '$rootScope', '$state', '$stateParams', '$q', 'offerSrv', 'constants', 'desktopSrv', 'profileSrv', 'positionSrv', 'httpSrv', 'dictionarySrv', 'authSrv', 'dialogSrv', 'systemMessages',
        function ($scope, $rootScope, $state, $stateParams, $q, offerSrv, constants, desktopSrv, profileSrv, positionSrv, httpSrv, dictionarySrv, authSrv, dialogSrv, systemMessages) {
            var ctrl = this;
            this.$onInit = initFn;
            $scope.getTemplate = getTemplateFn;
            $scope.emptySKMTR = emptySKMTRFn;
            $scope.emptyOKPD2 = emptyOKPD2Fn;
            $scope.initFormSelect = initSelectFn;
            $scope.autoFillSkmtr = autoFillSkmtrFn;
            $scope.autoFillOkpd2 = autoFillOkpd2Fn;
            $scope.autoFillMeasure = autoFillMeasureFn;
            $scope.refreshDict = refreshDictFn;
            $scope.SetESHOP_PRODUCT = SetESHOP_PRODUCTFn;           //тип позиции товар
            $scope.SetESHOP_SERVICE = SetESHOP_SERVICEFn;           //тип позиции услуга
            $scope.onOpenClose = onOpenCloseFn;
            $scope.changePriceNds = changePriceNdsFn;
            $scope.changeVatRate = changeVatRateFn;
            $scope.groupMeasure = groupMeasureFn;
            $scope.changePriceNegotiable = changePriceNegotiableFn;
            $scope.positionSrv = positionSrv;
            $scope.checkOfferPositionVolume = checkOfferPositionVolumeFn;
            $scope.checkMinimalPackageVolume = checkMinimalPackageVolumeFn;
            $scope.confirmClose = confirmCloseFn;
            $scope.deleteAttachment = deleteAttachmentFn;
            $scope.uploadFile = uploadFileFn;
            $scope.hasVatRate = positionSrv.hasVatRate;
            $scope.downloadFile = httpSrv.downloadFile;
            $scope.positionClose = positionCloseFn;
            $scope.positionConfirmClose = positionConfirmCloseFn;
            $scope.notInsertImg = desktopSrv.notInsertImg;
            $scope.maxCountAttachments = 5;

            $scope.$on('position.save', function () {
                $scope.positionSave('offer');
            });

            function positionCloseFn() {
                ctrl.close()
            }

            function positionConfirmCloseFn() {
                checkPositionEditConfirm().then(function(){
                     ctrl.close();
                });
            }

            function checkPositionEditConfirm(){
                var deferred = $q.defer();
                if (($scope.form.positionForm||{}).$dirty) {
                    dialogSrv.openModal('_confirmInfoExt.html', {
                        message: systemMessages.POS_WONTBE_SAVED,
                        buttons: [{
                            title: "Сохранить",
                            btnClass: "btn-success",
                            closeValue: false,
                            action: function () {
                                $scope.positionSave('offer');
                            }
                        },{
                            title: "Не сохранять",
                            btnClass: "btn-danger",
                            closeValue: true
                        },{
                            title: "Отмена",
                            btnClass: "btn-light",
                            closeValue: false
                        }]
                    }).then(function (model) {
                        if(model && model.close)
                            deferred.resolve();
                    });
                } else {
                    deferred.resolve();
                }
                return deferred.promise;
            }


            $scope.positionSave = function (docType) {
                positionSrv.positionSave($scope, docType, ctrl.saveFn);
            };
            $scope.positionShow = function (docType) {
                positionSrv.positionShow($scope, docType);
            };
            $scope.positionDelete = function (docType, item) {
                positionSrv.positionDelete($scope, docType, item);
            };
            $scope.positionEdit = function (docType, item) {
                positionSrv.positionEdit($scope, docType, item);
            };
            $scope.getSum = function (docType) {
                positionSrv.getSum($scope, docType);
            };
            $scope.closeAlert = function () {
                $scope.alertId = 'dismiss';
            };
            $scope.attachReset = function () {
                return $scope['attachItem'] = {
                    name: '',
                    description: ''
                };
            };
            $scope.addAttachShow = function () {
                $scope.attachReset();
                $scope['addAttach'] = 'show';
            };

            $scope.onSizeError = function (files) {
                $scope.fileSizeErrorMessage = "Документ " + files[0].name + " не добавлен. Превышен максимально разрешенный размер файла!";
                $scope.alertId = 'fileSizeError';
            };

            $scope.setAttachRemoving = function (attachId) {
                $scope.attachRemoving = attachId;
            };

            $scope.closeAttachAlert = function () {
                $scope.alertId = 'dismiss';
            };

            function confirmCloseFn() {
                $scope.positionClose();
                /*if ($scope.confirmClose) {
                    $scope.confirmClose = false;
                } else {
                    if (($scope.form.positionForm||{}).$dirty) $scope.confirmClose = true;
                    else $scope.positionClose();
                }*/
            }

            function deleteAttachmentFn(file, object) {
                var index = object['attachment'].indexOf(file);
                if (index > -1) {
                    object['attachment'].splice(index, 1);
                }
            }

            function uploadFileFn(result, object) {
                if (!result || !result[0]) return;

                if (!$scope[object]) $scope[object] = {};
                if (!$scope[object]['attachment']) $scope[object]['attachment'] = [];
                $scope[object]['attachment'].push({
                    "id": result[0].id,
                    "fileType": 0,
                    "name": result[0].name,
                    "description": $scope['attachItem'].description,
                    "description": $scope['attachItem'].description ? $scope['attachItem'].description : $scope.offerItem.imageDescription,
                    "createdDate":result[0].createdDate
                });
                $scope.attachReset();
                $scope['addAttach'] = 'hide';
                //ie 11 баг снятие фокуса. Иначе не нажат кнопки
                window.focus();
                if (document.activeElement) {
                    document.activeElement.blur();
                }
            }

            function autoFillSkmtrFn(object, select) {
                dictionarySrv.autoFillSkmtr($scope, object, select);
            }

            function autoFillOkpd2Fn(object, select) {
                dictionarySrv.autoFillOkpd2($scope, object, select);
            }

            function autoFillMeasureFn(object, select) {
                dictionarySrv.autoFillMeasure($scope, object, select);
                if ($scope[object].measure == constants.measurePC) { // Если выбрали ШТ
                    if ($scope[object].volume) {
                        $scope[object].volume = parseInt($scope[object].volume);
                    }
                    if ($scope[object].minimumPackageVolume) {
                        $scope[object].minimumPackageVolume = parseInt($scope[object].minimumPackageVolume);
                    }
                }
            }

            function initSelectFn(object) {
                if (!$scope.formSelect) $scope.formSelect = {};
                var elemId = object.$select.$element[0].id;
                $scope.formSelect[elemId] = object.$select;
            }

            function emptySKMTRFn(object, $event) {
                dictionarySrv.emptySKMTR($scope, object, $event);
            }

            function emptyOKPD2Fn(object, $event) {
                dictionarySrv.emptyOKPD2($scope, object, $event);
            }

            function refreshDictFn(object, value, refreshScrolling, offer, okpd2, forcedAll) {
                var searchByField = {};
                if (offer && offer.purchaseId && (!offer.id || offer.statusEng == 'eshop_notpublished')) {
                    searchByField.okpd2 = okpd2;
                } else if ($scope.planData && $scope.planData.okpd2) {
                    if (object == 'rOKPD2') {
                        searchByField.code = $scope.planData.okpd2;
                    } else if (object == 'rSKMTR') {
                        searchByField.okpd2 = $scope.planData.okpd2;
                    }
                }
                dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling, searchByField, forcedAll);
            }

            function changePriceNdsFn(percent, itemType, nonNds, isNdsFiled) {
                positionSrv.changePriceNds($scope, percent, itemType, nonNds,isNdsFiled);
            }
            function changeVatRateFn(docType) {
                var itemType = docType + "Item";
                positionSrv.changePriceNds($scope, $scope[itemType].vatRate, docType);
            }

            function groupMeasureFn(measure) {
                // Килограмм, Тонна, Литр, Штука, Метр, Метр кубический
                if (measure.code === '006' ||
                    measure.code === '166' ||
                    measure.code === '168' ||
                    measure.code === '112' ||
                    measure.code === constants.measurePC ||
                    measure.code === '113'
                ) {
                    return "Рекомендуемые";
                } else {
                    return "Прочие";
                }
            }

            function SetESHOP_PRODUCTFn(object) {
                $scope[object].type = 'ESHOP_PRODUCT';
                if (object == 'offerItem') {
                    $scope[object].volume = undefined;
                    $scope.getSum('offer');
                }
            }

            function SetESHOP_SERVICEFn(object) {
                $scope[object].type = 'ESHOP_SERVICE';
                if (object == 'companyItem') return;
                $scope[object].skmtr = '';
                $scope[object].mark = '';
                $scope[object].gost = '';
                if (object == 'offerItem') {
                    $scope.getSum('offer');
                }
            }

            function onOpenCloseFn(isOpen) {
                dictionarySrv.onOpenClose($scope, isOpen);
            }

            function changePriceNegotiableFn(object, checked) {
                if ($scope[object]) {
                    $scope[object].priceNegotiable = checked;
                    $scope[object].measure = '';
                    $scope[object].price = '';
                }
            }

            function checkOfferPositionVolumeFn(offerItem) {
                if (offerItem.minimumPackageVolume && parseFloat(offerItem.volume) < parseFloat(offerItem.minimumPackageVolume)) {
                    $scope.form.positionForm['offerItemVolume'].$setValidity('offerItemVolume', false);
                    setInputTitle('offerItemVolume', 'Товарные запасы не должны быть меньше ' + parseFloat(offerItem.minimumPackageVolume) + ' ' + (offerItem.measureName ? offerItem.measureName : 'ед.'));
                } else {
                    $scope.form.positionForm['offerItemVolume'].$setValidity('offerItemVolume', true);
                    setInputTitle('offerItemVolume', 'OK');
                }
            }

            function checkMinimalPackageVolumeFn(offerItem) {
                if (offerItem.volume && parseFloat(offerItem.volume) < parseFloat(offerItem.minimumPackageVolume)) {
                    $scope.form.positionForm['minimumPackageVolume'].$setValidity('minimumPackageVolume', false);
                    setInputTitle('minimumPackageVolume', 'Минимальный объем заказа не должен превышать ' + parseFloat(offerItem.volume) + ' ' + (offerItem.measureName ? offerItem.measureName : 'ед.'));
                } else {
                    $scope.form.positionForm['minimumPackageVolume'].$setValidity('minimumPackageVolume', true);
                    setInputTitle('minimumPackageVolume', 'OK');
                }
            }

            function setInputTitle(input, message) {
                var input = document.querySelector("input[name='" + input + "']");
                if (input) {
                    input.title = message;
                }
            }

            function initFn() {
                $scope.auth = authSrv.getAuth();
                $scope.systemMessages = systemMessages;
                $scope.offerItem = ctrl.data? angular.copy(ctrl.data):{
                    type: 'ESHOP_PRODUCT'
                };
                $scope.offerItem.useVatPrice = $scope.offerItem.useVatPrice || false;
                $rootScope.constants = constants;
                $scope.showServiceJob = true;
                $scope.showSumma = true;
                $scope.maxCntPositionAttachments = 1;
                $scope.attachReset();
                $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                positionSrv.downloadVatRateList($scope);
                if ($scope.offerItem) {
                    if ($scope.offerItem.isCalcTransportEnabled == undefined) {
                        $scope.offerItem.isCalcTransportEnabled = true;
                    }
                    $scope.offerItem.useVatPrice = $scope.offerItem.useVatPrice || false;
                }
                $scope.confirmClose = false;
                $scope.editAttach = {};
                positionSrv.setPositionScope($scope);
            }

            function getTemplateFn(){
                return  'app/components/position/positionOffer.html';
            }

            $scope.checkClear = (object) => {
                dictionarySrv.dictClearSearchByField(object);
            }
        }]
});
