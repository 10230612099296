/**
 * @author: sergey
 * Перенос строки. Заменяем \n на </br>
 */
angular.module('uetp').filter('lineBreaks', ['$sce', function ($sce) {
    return function (text) {
        if(text.$$unwrapTrustedValue){
            text = text.$$unwrapTrustedValue();
        }
        return text ? $sce.trustAsHtml(text.replace(/\n/g, '</br>')) : '';
    };
}]);