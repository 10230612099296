/**
 * @author Yuriy Mayatnikov
 * реализация Цели для Я.Метрики
 */

angular.module('uetp').directive("metrikaAim", ['metrikaAimSrv',
    function (metrikaAimSrv) {
        return {
            restrict: 'A',
            link: function (scope, element, attr) {
                var aimType = attr.metrikaAim;
                if (aimType) {
                    scope.element = element;
                    element.on('click', function () {
                        scope.$apply(function () {
                            metrikaAimSrv.sendAim(aimType);
                        });
                    });
                }
            }
        }
    }]).service('metrikaAimSrv', [function () {
    var service = this;

    service.sendAim = function (aimType) {
        try {
            setTimeout(function () {
                if (!window.yaCounter && typeof Ya == 'object') {
                    window.yaCounter = new Ya.Metrika({id: Number(__yandexMetrika)});
                }
                if (window.yaCounter)
                    window.yaCounter.reachGoal(aimType);
            }, 0);
        } catch (err) {
            console.log(err);
        }
    };
}]);
