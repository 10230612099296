/**
 * Форматирование input под денежный формат
 */
angular.module('uetp').directive('currencyBlur', ['$filter', 'desktopSrv', function ($filter, desktopSrv) {
    return {
        require: 'ngModel',
        restrict: 'A',
        scope: {
            modelValue: '=ngModel'
        },
        link: function (scope, el, attrs, ngModelCtrl) {
            if (desktopSrv.browserType.isMSIE) {
                return
            }
            var replaceStr = function (value) {
                return value.toString().trim().replace(/\s/g, "").replace(/,/g, '.');
            };
            
            var value = replaceStr(el.val());
            if(attrs['masktype'] && attrs['masktype']==='percent') {
                if (value !== '')
                    el.val($filter('percent')(value));
            } else
                el.val($filter('currency')(scope.modelValue, ''));

            el.bind('focus', function () {
                el.val(scope.modelValue);
            });

            el.bind('input', function () {
                scope.modelValue = el.val();
                scope.$apply();
            });

            el.bind('blur', function () {
                var value = replaceStr(el.val());
                if(attrs['masktype'] && attrs['masktype']==='percent') {
                    if (value !== '')
                        el.val($filter('percent')(value));
                } else
                    el.val($filter('currency')(scope.modelValue, ''));
            });

            ngModelCtrl.$formatters.push(function (modelValue) {
                if (modelValue === '' || modelValue === null)
                    return '';
                else if(attrs['masktype'] && modelValue && attrs['masktype']==='percent')
                    return $filter('percent')(modelValue);
                else
                    return $filter('currency')(modelValue, '')
            })
        }
    }
}])  // директива для полей с денежными велечинами
    .directive('price', ['$filter', function ($filter) {
        return {
            require: 'ngModel',
            restrict: 'A',
            scope: {
                ngModel: '='//,
                //eisFormat: '@'
            },
            link: function (scope, el, attrs, ngModelCtrl) {
                /**
                 * Обрезаем пробелы и запятые
                 */
                var replaceStr = function (value) {
                    return value.toString().trim().replace(/\s/g, "").replace(/,/g, '.');
                };

                /**
                 * Заполняем модель числовыми значениями, вмето тестовых
                 * @param value - значение в дом модели
                 */
                function formatter(value) {
                    value = replaceStr(value);
                    //if(eisFormat && eisFormat=='true')
                    var pattern = /^[0-9\s]{1,13}(?:\.[0-9]{0,2})?$|^0$/;
                    if(attrs['masktype'] && attrs['masktype']==='supplySum')
                        pattern = /^[0-9\s]{1,17}(?:\.[0-9]{0,2})?$|^0$/
                    else if(attrs['masktype'] && attrs['masktype']==='percent')
                        pattern = /^[0-9\s]{1,12}(?:\.[0-9]{0,4})?$/
                    if (pattern.test(value)) {
                        ngModelCtrl.$modelValue = parseFloat(value);
                        scope.ngModel = parseFloat(value);
                        return parseFloat(value);
                    }
                }

                /**
                 * Возвращаем число в исходный формат 1000.00
                 */
                el.bind('focus', function () {
                    el.val(replaceStr(el.val()));
                });
                /**
                 * Преобразуем по выходу из поля в формат "денег" 1 000,00
                 */
                el.bind('blur', function () {
                    var value = replaceStr(el.val());
                    if(attrs['masktype'] && attrs['masktype']==='percent'){
                        if(value!=='')
                            el.val($filter('percent')(value));
                    } else if(!(attrs['masktype'] && value==='' && (['supplySum','money'].includes(attrs['masktype'])))){
                        el.val(($filter('currency')(value, '')));
                    }
                });
                /**
                 * Преобразуем имещееся значение в формат "денег"
                 */
                ngModelCtrl.$formatters.push(function (modelValue) {
                    if(attrs['masktype'] && modelValue && attrs['masktype']==='percent'){
                        return $filter('percent')(modelValue);
                    }
                    return $filter('currency')(modelValue, '')
                });

                ngModelCtrl.$parsers.unshift(formatter);

            }
        }
    }]);