/**
 * @author dmitrys
 */
angular.module('uetp').service('questionAnswerSrv', ['$http', '$rootScope', 'dialogSrv', 'httpSrv',
    function ($http, $rootScope, dialogSrv, httpSrv) {
        var service = this;
        service.deleteItem = deleteItemFn;
        service.save = saveFn;
        service.publish = publishFn;
        service.load = loadFn;
        service.loadPublished = loadPublishedFn;
        service.deleteAttach = deleteAttachFn;
        service.setSelectedCategory = setSelectedCategoryFn;
        service.getSelectedCategory = getSelectedCategoryFn;
        service.selectedCategory = '';
        /**
         * Удалить вопрос-ответ
         */
        function deleteItemFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/admin/deleteQuestion/' + id
            })
        }
        /**
         * Сохранить Вопрос-ответ
         */
        function saveFn(crtNews) {
            return httpSrv.http({
                url: '/admin/saveQuestion',
                data: crtNews
            })
        }
        /**
         * Опубликовать вопрос-ответ
         */
        function publishFn(crtNews) {
            return httpSrv.http({
                url: '/admin/publicateQuestion',
                data: crtNews
            })
        }
        /**
         * Открыть на редактирование/просмотр. Оператор
         */
        function loadFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/admin/openQuestion/' + id
            })
        }
        /**
         *Открыть на просмотр. Оч
         */
        function loadPublishedFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/site/openPublishedQuestion/' + id
            })
        }
        /**
         * Удалить вложение
         */
        function deleteAttachFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/admin/deleteQuestionAttachment/' + id
            })
        }
        function setSelectedCategoryFn(value) {
            service.selectedCategory = value;
        }
        function getSelectedCategoryFn() {
            return service.selectedCategory;
        }
    }]);