angular.module('uetp').constant('attachTypeCatalogEnum', {
    mainbanner_logo: "mainbanner_logo",
    mainbanner_pic: "mainbanner_pic",
    mainbanner: "mainbanner",
    special_offer: "special_offer",
    pricelist: "pricelist",
    docs: "docs",
    product: "product",
});

