angular.module('uetp').constant('openInvoice', {
    title    : 'Открытие счетов',
    access   : ['admincab'],
    url      : '/auth/tariff/downloadOpeningAccountsReport',
    template : 'app/components/reports/types/openInvoiceReport.html',
    data     : {
        endDate   : null,
        startDate : null
    },
    toMSK    : ['startDate', 'endDate'],
    fileName : function ($filter, data) {
        var date = "";
        if(data.startDate && data.endDate) {
            date = $filter('date')(data.startDate, 'dd.MM.yyyy_HH-mm') + '_' + $filter('date')(data.endDate, 'dd.MM.yyyy_HH-mm');
        }else{
            date = $filter('date')(getServerTimeMsk(), 'dd.MM.yyyy_HH-mm');
        }
        return "specaccounts_" + date+'.xlsx';
    }
});