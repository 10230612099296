/**
 * @author: sergeyu
 * Калькулятор комиссии
 */
angular.module('uetp')
    .component('bankGuaranteeCalc', {
        templateUrl: 'app/components/bankGuaranteeCalc/bankGuaranteeCalc.html',
        controller: ['$scope', '$http', 'loggerUiSrv',
            function ($scope, $http, loggerUiSrv) {
                var commissionData = [];
                $scope.model = {};
                $scope.modelView = {
                    form: {},
                    params: {},
                    visible: true
                };
                $scope.initCommissionData = function () {
                    return $http({
                        method: 'GET',
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        url: '/json/commission_v2.json?r=' + Math.random()
                    })
                };
                /**
                 * Получить месяц по количеству дней (округляется в большую сторону)
                 * @param days - дни
                 */
                $scope.getMonthFromDays = function (days) {
                    return Math.ceil(days / 30);
                };
                /**
                 * Получить список ставок (ставки считаются в меньшую сторону)
                 * @param amount - сумма по которой надо получить список ставок
                 */
                $scope.getCommissionPercentArrayByAmount = function (amount) {
                    var nAmount = Number(amount);
                    return commissionData.filter(function (item) {
                        return item.amountTo >= nAmount && item.amount <= nAmount
                    })
                };
                /**
                 * Округление суммы до сотен
                 * @param commission - высчитанная комиссия
                 * @returns {number}
                 */
                $scope.roundAmountCommission = function (commission) {
                    var nCommission = Number(commission.toFixed(0));
                    return nCommission + (100 - nCommission % 100);
                };
                $scope.clearResult = function () {
                    $scope.model.commission = 0;
                    $scope.model.percent = 0;
                };
                /**
                 * Расчет комиссии
                 * @param data - модель формы
                 */
                $scope.calculate = function (data) {
                    try {
                        var month = $scope.getMonthFromDays(data.days);
                        var commissionAmount = $scope.getCommissionPercentArrayByAmount(data.amount);
                        if (commissionAmount && commissionAmount.length === 1) {
                            var percent = commissionAmount[0].percent[month - 1];
                            var commission = (percent * (data.amount / 100)) / 365 * data.days;
                            var roundCommission = $scope.roundAmountCommission(commission);
                            $scope.model.commission = roundCommission < 1000 ? 1000 : roundCommission;
                            $scope.model.percent = percent;
                            var loggerInfo = "Размер банковской гарантии: " + $scope.model.amount + "; Срок банковской гарантии: " + $scope.model.days;
                            loggerUiSrv.sendLog('BANK_GUARANTEE_CALC', {info:loggerInfo});
                        } else {
                            $scope.clearResult()
                        }
                    } catch (e) {
                        $scope.clearResult();
                        console.error(e);
                    }
                };
                /**
                 * Проврка ограничений на поле
                 */
                $scope.checkFields = function (name, data, max) {
                    var prepareData = parseInt(data),
                        prepareMax = parseInt(max);
                    if (prepareData > prepareMax) {
                        $scope.modelView.form[name].$setValidity(name, false);
                    } else {
                        $scope.modelView.form[name].$setValidity(name, true);
                    }
                };

                this.$onInit = function () {
                    $scope.initCommissionData().then(function (response) {
                        if (response.status === 200) {
                            $scope.modelView.params = response.data.params;
                            commissionData = response.data.commissions;
                        }
                    }, function () {
                        $scope.alertObjBankGuaranteeCalc = "Калькулятор комиссий недоступен!";
                    });
                }

            }]
    });
