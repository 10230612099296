/**
 * Личный кабинет
 */
angular.module('uetp')
    .component('lk', {
        templateUrl: 'app/components/lk/lk.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv','authSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, authSrv) {
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.model = {
                        showPanel: 'requisites',
                        showRequisite: true, // реквизиты
                        showUserInfo: true, // пользователь
                        showAgents: true, // прдеставители организации
                        showPersonalAccount: true // лицевой счет
                    };
                    _initShowPanel();
                    _refreshView();
                };
                $scope.setActivePanel = setActivePanelFn;

                /**
                 * Инициализация панели профиля по типу ЛК
                 * @private
                 */
                function _initShowPanel() {
                    var cabType = desktopSrv.getCabType();
                    if (cabType === 'admincab') {
                        $scope.model.showPersonalAccount = false;
                    }
                    if (cabType === 'organizer') {
                        $scope.model.showAgents = false;
                        $scope.model.showPersonalAccount = false;
                    }
                    if (cabType === 'participant') {
                        if (authSrv.getAuth().userRole.includes('user')) {
                            $scope.model.showAgents = false;
                        }
                    }
                }

                /**
                 * Обновление состояния при перегрузке страницы по F5 или другим способом
                 * @private
                 */
                function _refreshView() {
                    var activePanel = $stateParams.setActivePanel;
                    if (activePanel && $scope.model.showPanel !== activePanel) {
                        setActivePanelFn(activePanel);
                    }
                }

                /**
                 * Выставление активной вкладки
                 * @param type - тип вкладки
                 */
                function setActivePanelFn(type) {
                    $scope.model.showPanel = type;
                }

            }]
    });

