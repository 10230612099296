/**
 * @author: sergeyu
 * Проверки компании
 */
angular.module('uetp')
    .component('verificationTass', {
    	templateUrl: 'app/components/widgets/tassBusiness/companyValidate/view/verification/verification.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'tassBusinessSrv', 'propertiesService',
            function ($scope, $rootScope, $state, $stateParams, tassBusinessSrv, propertiesService) {
                $scope.modelView = {};
                $scope.loaded = false; // контроль загруки. Один раз при первом отображении
                $scope.loadLoyality = loadLoyalityFn;
                
                this.$onInit = function () {
                	if(!$scope.loaded) $scope.loadLoyality();
                };
                function loadLoyalityFn() {
                    propertiesService.getProperty("api.url").then(function(host){
                        tassBusinessSrv.getOrganizationLoyality($scope, $stateParams.idCompany, $stateParams.id, host).then(function (response) {
                        	$scope.errorInfo = null;
                            if (response.data.success) {
                                if (response.data.result.error) {
                                    if (response.data.result.error.indexOf('не активна') !== -1) {
                                        $scope.errorInfo = '<span class="red">Недействующее предприятие</span>';
                                    } else {
                                        $scope.errorInfo = response.data.result.error;
                                    }
                                } else {
                                	 $scope.modelView = response.data.result.data;
                                }
                                var verifyResult = tassBusinessSrv.getVerifyResult();
                                if(verifyResult){
                                   verifyResult.loyalityValue = angular.copy(response.data.result);
                                   tassBusinessSrv.setVerifyResult(verifyResult);
                                }
                                $scope.loaded = true;
                            } else {
                                $scope.errorInfo = response.data.errorMessage ? response.data.errorMessage : "Получить информацию о компании в данный момент невозможно.";
                                $scope.loaded = false;
                            }
                            tassBusinessSrv.setRequestProcessed('verification', true);
                        }, function () {
                            $scope.errorInfo = "Получить информацию о компании в данный момент невозможно.";
                            $scope.loaded = false;
                            tassBusinessSrv.setRequestProcessed('verification', true);
                        }
                        )
                    });
                }
            }]
    });