/**
 * @author: sergeyu
 * Перехватчик запросов для отображения количества записей при поиске или фильтрации
 */
angular.module('uetp').factory('scInterceptor', ['$injector', function ($injector) {
    /**
     * Проверка ответа от сервера на его успешность
     */
    function validateResponse(response) {
        return response.status === 200 && response.data && response.data.success && response.config && response.config.url;
    }


    /**
     * Сервис по работе с директивой scDirective. Для динамического отображения
     */
    var _scService = null;
    var scService = function() {
        if(!_scService) {
            _scService = $injector.get('scService');
        }
        return _scService;
    };

    return {
        response: function (response) {
            if (validateResponse(response) && response.config.showCounter) {
                var requestData = response.config.data || response.config.params;
                if (!!requestData.search || (!!requestData.searchByField && Object.keys(requestData.searchByField).length > 0)
                    || (!!requestData.filter && Object.keys(requestData.filter).length > 0)) {
                    scService().setCounter(response.data.total);
                } else {
                    scService().clearCounter();
                }
            }
            return response;
        }
    };

}]);


