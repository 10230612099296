/**
 * @author dmitrys
 * Просмотр/создание подписки
 */

angular.module('uetp')
    .component('subscription', {
        templateUrl: 'app/components/subscription/subscription.html',
        bindings: {
            subscriptionData: '<',
            procedureData: '<',
            personalAccountData: '<',
			subscriptionLimit: '<'
        },
        controller: ['$scope','$rootScope','$state','$stateParams', 'alertSrv','dictionarySrv','desktopSrv','subscriptionSrv','authSrv', 'dialogSrv', 'propertiesService',
            function ($scope, $rootScope, $state, $stateParams, alertSrv, dictionarySrv,desktopSrv,subscriptionSrv,authSrv,dialogSrv, propertiesService) {
                var ctrl = this;
                $scope.subscribe = subscribeFn;
                $scope.getTitle = getTitleFn;
                $scope.refreshDict = refreshDictFn;
                $scope.goBack = goBackFn;
                $scope.showPage = true;
                $scope.disabledBtn = false;
                $scope.isDemo = isDemoFn;
				$scope.submitted = false;
				$scope.isView = false;

                this.$onInit = function () {
                	$scope.cabType = desktopSrv.getCabType();
                	$scope.user = ctrl.personalAccountData;

                	if (authSrv.canActiveOtherPurchase($scope.cabType,$scope.user)) {
                        $scope.subscriptionForm = {};
	                    $scope.subscriptionForm.form = {};
	                    $scope.successSubscription = $stateParams.successSave;

	                    $scope.utensilsMSP = dictionarySrv.getStaticDictByType('utensilsMSP');
	                    $scope.dictionary = dictionarySrv.getRemoteData();
	                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
	                    dictionarySrv.resetPaginatedDict($scope);

	                    $scope.refreshDict('customersEIS', null, true);
	                    $scope.refreshDict('rOKPD2EIS', null, true);
	                    $scope.refreshDict('rOKVED2EIS', null, true);
	                    $scope.refreshDict('rKTRU', null, true);
	                    _initDict(['fias_region'], false);

	                    function _initDict(dictList, reload) {
	                        angular.forEach(dictList, function (name) {
	                            dictionarySrv.getDictionaryRemote(name, reload);
	                        })
	                    }
	                    $scope.user = authSrv.getUser();

	                    if(this.subscriptionLimit && this.subscriptionLimit.data && angular.isDefined(this.subscriptionLimit.data.result))
							$scope.isView = this.subscriptionLimit.data.result<=0;

	                    if(this.subscriptionData && !this.subscriptionData.data.success && this.subscriptionData.data.errorCode == '421'){
	                    	$scope.showPage = false;
	                        var messageTxt = this.subscriptionData.data.message;
	                        dialogSrv.openModal('_dialogMessage.html',
	                            {message: messageTxt}).then(function () {
	                            if ($scope.cabType === 'participant') {
	                                $state.go('myProcedures')
	                            } else {
	                                $state.go('home')
	                            }

	                        })
	                    }else{
		                    var model = this.subscriptionData?this.subscriptionData.data.result:{};
		                    if(!model.email) model.email = $scope.user.email;

		                    if(model.purchaseId){
		                        $scope.planned = true;
		                    }
		                    if(this.procedureData){
		                        $scope.planned = true;
		                        $scope.procedure = this.procedureData.data.items[0];
		                        model.name = 'Публикация планируемой закупки №'+$scope.procedure.purchase_number;
		                        model.purchaseId = $scope.procedure.id;
		                        if($scope.procedure.lots && $scope.procedure.lots[0]){
		                        	 model.positionGuid = $scope.procedure.lots[0].positionGuid;
		                        }
		                    }

		                    $scope.model = subscriptionSrv.getModelFromView(model);
	                   }
                    }else{
                    	$scope.showPage = false;
                        var messageTxt = 'Доступ к подпискам Реестра закупок ЕИС закрыт.';
                        if ($scope.cabType === 'participant') {
                            messageTxt = 'Раздел доступен только для пользователей тарифного плана "Годовой"';
                        }
                        dialogSrv.openModal('_dialogMessage.html',
                            {message: messageTxt}).then(function () {
                            if ($scope.cabType === 'participant') {
                                $state.go('myProcedures')
                            } else {
                                $state.go('home')
                            }

                        })
                    }
                };
                function refreshDictFn(object, value, refreshScrolling,needToAdd) {
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling,null,needToAdd);
                }

				/*
                * Проверка условно обязательных полей.
                * true - хотя бы одно заполнено
                * */
				function checkModelFn(model) {
					if($scope.planned) return false;
					$scope.valided = (model.startPrice == null || model.startPrice === '')
						&& (model.endPrice == null || model.endPrice === '')
						&& (model.customer == null || model.customer.length === 0)
						&& (model.deff__okpd2 == null || model.deff__okpd2.length === 0)
						&& (model.regnum == null || model.regnum.length === 0)
						&& (model.deff__okved2 == null || model.deff__okved2.length === 0)
						&& (model.deff__ktru == null || model.deff__ktru.length === 0)
					return $scope.valided;
				}

                function getTitleFn() {
                    return checkModelFn($scope.model) ?'Необходимо заполнить хотя бы один параметр подписки. Например, ОКПД2':'';
                }
                /*
                * Подписаться/изменить подписку
                * */
                function subscribeFn(data) {

                    $scope.disabledBtn = true;

                    if(isDemoFn()){
                        var msg = 'Данный функционал не доступен в демонстрационной версии.';
                        $scope.alertObjSaveSubscription = alertSrv.getErrorMsg(msg);
                        return;
                    }

                    var model = subscriptionSrv.getModelFromSave(angular.copy(data));

                    if($scope.user){
                    	model.userFio = $scope.user.lastName + ($scope.user.firstName ? ' ' + $scope.user.firstName : '') + ($scope.user.middleName ?  ' ' + $scope.user.middleName : '');
                    }

                    propertiesService.getProperty("otherProcedures.url").then(function(host){
	                    subscriptionSrv.subscribe(model, host, $scope).then(function (response) {
	                        if (response.data.success) {
	                            $scope.successSubscription = true;
	                            if(!data.id)
	                                $state.go('subscription', {id: response.data.result, successSave: true}, {location: 'replace'});

	                        }else{
	                        	var msg = response.data.errorCode === '996' ? 'Достигнуто максимальное количество пробных подписок — 5. Для восстановления доступа к сервису необходимо подключить тарифный план "Безопасность закупок" или "Годовой".' : "Подписка \"" + $scope.model.name + "\" на данный адрес электронной почты уже существует.";
	                            $scope.alertObjSaveSubscription = alertSrv.getErrorMsg(msg);
                                $scope.disabledBtn = false;
	                        }
	                    }, function (reason) {
	                        $scope.alertObjSaveSubscription = alertSrv.getAlertObj(reason);
	                    })
                    })



                }
                function goBackFn() {
                    history.back();
                }

                function isDemoFn(){
                    return $rootScope.properties && $rootScope.properties['demo.mode']==="true";
                }
            }]
    });

