/**
 * Ценовые предложения
 */
angular.module('uetp')
    .component('priceOffersList', {
        templateUrl: 'app/components/procedures/priceOffers/priceOffers.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'proceduresSrv', 'authSrv', 'tablesSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, proceduresSrv, authSrv, tablesSrv) {
                var ctrl = this;
                this.$onInit = function () {
                    ctrl.alerts = {};
                    $scope.ctrl = ctrl;
                    $scope.auth = authSrv.getAuth();
                    priceOffersByProcedureLoadFn();
                };
                $scope.sortType = 'sendDate';
                $scope.sortReverse = true;
                $scope.loading = false;

                $scope.changeSort = changeSortFn;
                $scope.priceOffersByProcedureLoad = priceOffersByProcedureLoadFn;

                function priceOffersByProcedureLoadFn() {
                    $scope.loading = true;
                    proceduresSrv.getPriceOffersByProcedure().then(function successCallback(response) {
                        $scope.loading = false;
                        if (response.data.success) {
                            $scope.offersData = response.data.result;
                            $scope.offersData.forEach(data=>{
                                data.sortType = 'sendDate';
                                data.sortReverse = true;
                            });
                        } else {
                            $scope.offersData = [];
                        }
                    }, function errorCallback(response) {
                        $scope.loading = false;
                        console.log(response);
                    });
                }

                function changeSortFn(data, $event) {
                    tablesSrv.changeSortLocal(data, $event.currentTarget);
                }

                $scope.isStateClaiming = function() {
                    return 'claiming'===proceduresSrv.getProcedureData()?.data?.result?.state?.id;
                }
            }]
    });

